import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgreeNriCountryTerms,
  setAgreeNriTerms,
} from "../../../modules/EligibilityPage/eligibilityCard.slice";
import CriteriaList from "../PopupWithTitle/CriteriaList/CriteriaList";
import CriteriaListItem from "../../Popup/PopupWithTitle/CriteriaListItem";
import PopupWithTitle from "../PopupWithTitle/PopupWithTitle";

import "./nri-popup-two.scss";
import { ALL_COUNTRIES, COUNTRIES } from "../../EligibilityCard/options";
// import { getMin } from "./../../../modules/RiderPage/helper";

export const nriPopupEvents = {
  nriToggle: "1",
  nriCountrySelect: "2",
  residingConfirm: "3",
};

function NriCriteriaPopup({ event, ...props }) {
  const {
    setCheckNRI,
    selectedEducation,
    selectedCountry,
    setSelectedCountry,
    setShowNriPopup,
    showNriPopup,
    setSelectedUSAstate,
    selectedUSAstate,
    companyAlias,
    selectedOccupation,
    sumAssured,
  } = props;

  const dispatch = useDispatch();

  const { annualIncome, age } = useSelector(
    ({ greeting }) => greeting.customerDetails,
  );

  const { additionalRiders, selectedAddOns, riderGst } = useSelector(
    state => state.rider,
  );

  const getMinAnnualIncome = companyAlias => {
    switch (companyAlias) {
      case "icici_pru":
        return "20 Lakhs";
      case "kotak_life":
        return "5 Lakhs";
      case "max_life":
        return "5 Lakhs";
      case "bajaj_life":
        return "10 Lakhs";
      case "bandhan_life":
        return "10 Lakhs";
      case "tata_aia_life":
        return;
      case "pnb_metlife":
        return "10 Lakhs";
      case "hdfc_life":
        return "10 lakhs";
      default:
        return "20 Lakhs";
    }
  };

  const getAnnualIncomeMatch = (companyAlias, annualIncome) => {
    switch (companyAlias) {
      case "icici_pru":
        return annualIncome >= 2000000;
      case "kotak_life":
        return annualIncome >= 500000;
      case "max_life":
        return annualIncome >= 500000;
      case "bajaj_life":
        return annualIncome >= 1000000;
      case "bandhan_life":
        return annualIncome >= 1000000;
      case "tata_aia_life":
        return;
      case "pnb_metlife":
        return annualIncome >= 1000000;
      case "hdfc_life":
        return annualIncome >= 1000000;
      default:
        return "20 Lakhs";
    }
  };

  const getCompanyName = companyAlias => {
    switch (companyAlias) {
      case "icici_pru":
        return "ICICI prudential";
      case "kotak_life":
        return "Kotak";
      case "max_life":
        return "Max";
      case "bajaj_life":
        return "Bajaj";
      case "bandhan_life":
        return "Bandhan";
      case "tata_aia_life":
        return "Tata";
      case "pnb_metlife":
        return "Pnb";
      case "hdfc_life":
        return "HDFC";
      default:
        return "Company Name";
    }
  };

  const getSumAssuredMatches = (companyAlias, sumAssured) => {
    switch (companyAlias) {
      case "icici_pru":
        return sumAssured <= 50000000;
      case "kotak_life":
        return sumAssured <= 50000000;
      case "max_life":
        return sumAssured <= 40000000;
      case "bajaj_life":
        return sumAssured <= 50000000;
      case "bandhan_life":
        return sumAssured <= 50000000;
      case "tata_aia_life":
      case "hdfc_life":
        return sumAssured <= 50000000;

      case "pnb_metlife":
        return "Pnb";
      default:
        return "Company Name";
    }
  };

  const getEducationMatches = (companyAlias, selectedEducation) => {
    switch (companyAlias) {
      case "icici_pru":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate"
        );
      case "kotak_life":
        return (
          selectedEducation === "Post Graduate" ||
          selectedEducation === "Graduate"
        );
      case "max_life":
        return (
          selectedEducation === "Post Graduate" ||
          selectedEducation === "Graduate"
        );
      case "bajaj_life":
        // return (
        //   selectedEducation === "Graduation" ||
        //   selectedEducation === "Masters and Above"
        // );
        return ["Post Graduate", "Graduate"].includes(selectedEducation);
      case "bandhan_life":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate" ||
          selectedEducation === "Professional Degree"
        );
      case "tata_aia_life":
        return "Tata";
      case "pnb_metlife":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate & Above"
        );
      case "hdfc_life":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate"
        );
      default:
        return "Company Name";
    }
  };

  const getOccupationMatches = (companyAlias, selectedOccupation) => {
    switch (companyAlias) {
      case "icici_pru":
        return selectedOccupation === "Salaried";
      case "kotak_life":
      case "hdfc_life":
        return (
          selectedOccupation === "Salaried" ||
          selectedOccupation === "Self Employed"
        );
      case "max_life":
        return (
          selectedOccupation === "Salaried" ||
          selectedOccupation === "Professional"
        );
      case "bajaj_life":
        // return (
        //   selectedOccupation === "Salaried (Govt.)" ||
        //   selectedOccupation === "Salaried (Non Govt.)" ||
        //   selectedOccupation === "Business" ||
        //   selectedOccupation === "Professional"
        // );
        return [
          "Salaried - Government",
          "Salaried - Non Government",
          "Business",
          "Professional",
        ].includes(selectedOccupation);
      case "bandhan_life":
        return (
          selectedOccupation === "Salaried" ||
          selectedOccupation === "Self Employed (Business)" ||
          selectedOccupation === "Self Employed (Professional)"
        );
      case "tata_aia_life":
        return "Tata";
      case "pnb_metlife":
        return (
          selectedOccupation === "Business" ||
          selectedOccupation === "Self Employed" ||
          selectedOccupation === "Professinal"
        );
      default:
        return "Company Name";
    }
  };

  const handleClose = () => {
    switch (showNriPopup) {
      case nriPopupEvents.nriToggle:
        setCheckNRI(false);
        break;

      case nriPopupEvents.nriCountrySelect:
        setSelectedCountry("Select Country");
        setSelectedUSAstate("Select State");
        break;

      default:
        break;
    }
    setShowNriPopup(false);
  };

  const educationMathces = getEducationMatches(companyAlias, selectedEducation);
  const occupationMatches = getOccupationMatches(
    companyAlias,
    selectedOccupation,
  );
  const annualIncomeMatches = getAnnualIncomeMatch(companyAlias, annualIncome);

  const sumAssuredMatches = getSumAssuredMatches(companyAlias, sumAssured);

  // const riderMatches = additionalRiders === 0;
  const riderMatches = !additionalRiders || additionalRiders === 0;

  const ageMatches = age > 18 && age < 60;
  //Replace this after we get the list of non acceptable countires of ICICI
  const countryMatches =
    companyAlias === "icici_pru" ? COUNTRIES[selectedCountry] : selectedCountry;

  const handleAgreeNriTerm = fadeOut => {
    fadeOut(() => {
      setShowNriPopup(false);
      dispatch(setAgreeNriTerms(true));
    });
  };

  const handleAgreeCountry = () => {
    setShowNriPopup(false);
    dispatch(setAgreeNriCountryTerms(true));
  };

  const title =
    showNriPopup === nriPopupEvents.residingConfirm
      ? "Confirmation for purchase of Term Life policy"
      : `As an NRI, you should satisfy below eligibility criterias to buy the policy`;

  console.log(
    {
      sumAssuredMatches,
      annualIncomeMatches,
      educationMathces,
      occupationMatches,
      riderMatches,
      companyAlias,
      isToggle: showNriPopup === nriPopupEvents.nriToggle,
    },
    "TTTTTT",
  );

  return (
    <PopupWithTitle title={title} onClose={handleClose} {...props}>
      {fadeOut => (
        <>
          <CriteriaList>
            {companyAlias === "icici_pru" && (
              <CriteriaListItem matches>
                You should be in India continuously for last 20 days
                {/* <strong className="criteria-list-text-strong">20 days</strong> */}
              </CriteriaListItem>
            )}
            <CriteriaListItem matches={educationMathces}>
              You must be a graduate
            </CriteriaListItem>
            {companyAlias !== "icici_pru" && (
              <CriteriaListItem matches={occupationMatches}>
                You should be Salaried or Self Employed
              </CriteriaListItem>
            )}
            <CriteriaListItem matches={annualIncomeMatches}>
              Your annual income should be greater than{" "}
              {companyAlias !== "icici_pru" && "or equal to"}{" "}
              <strong className="criteria-list-text-strong">
                {getMinAnnualIncome(companyAlias)}
              </strong>
            </CriteriaListItem>
            {companyAlias === "icici_pru" && (
              <>
                <CriteriaListItem matches={ageMatches}>
                  Your age should be between{" "}
                  <strong className="criteria-list-text-strong">
                    18 - 60 years
                  </strong>
                </CriteriaListItem>
                <CriteriaListItem matches>
                  <strong className="criteria-list-text-strong">
                    {" "}
                    COVID-19 Questions + Video MER + Medicals{" "}
                  </strong>
                  are mandatory in all the cases
                </CriteriaListItem>
              </>
            )}
            {companyAlias !== "icici_pru" && (
              <>
                <CriteriaListItem matches={sumAssuredMatches}>
                  Sum assured must be less than{" "}
                  <strong className="criteria-list-text-strong">
                    {companyAlias === "max_life" ? "4 Crore" : "5 Crore"}
                  </strong>
                </CriteriaListItem>
                <CriteriaListItem matches>
                  <strong className="criteria-list-text-strong">
                    {" "}
                    Medicals{" "}
                  </strong>
                  are mandatory in all the cases
                </CriteriaListItem>
              </>
            )}

            {/* {showNriPopup === nriPopupEvents.nriToggle && (
              <CriteriaListItem matches>
                Your selected country should come under acceptable list of
                countries by {getCompanyName(companyAlias)}
              </CriteriaListItem>
            )} */}
            {companyAlias === "kotak_life" && (
              <>
                <CriteriaListItem matches>
                  Note - Video MER mandatory for NRI lives who are outside India
                  at the time of application.
                </CriteriaListItem>
                <CriteriaListItem matches>
                  Any adverse medical history will warrant calling for complete
                  medicals as per medical grid applicable.
                </CriteriaListItem>
              </>
            )}
            {companyAlias === "bajaj_life" && (
              <CriteriaListItem matches={riderMatches}>
                Rider not allowed
              </CriteriaListItem>
            )}
            {/* {(showNriPopup === nriPopupEvents.nriCountrySelect ||
              showNriPopup === nriPopupEvents.residingConfirm) && (
              <CriteriaListItem matches={countryMatches}>
                Your selected country is{" "}
                <strong className="criteria-list-text-strong">
                  {`${selectedCountry}${
                    selectedCountry === ALL_COUNTRIES.USA
                      ? `, ${selectedUSAstate}`
                      : ""
                  }`}
                </strong>{" "}
                {companyAlias === "icici_pru" &&
                  !countryMatches &&
                  "- is under non acceptable list of countries by ICICI prudential"}
              </CriteriaListItem>
            )} */}
            {/* {companyAlias === "icici_pru" &&
              showNriPopup === nriPopupEvents.residingConfirm && (
                <CriteriaListItem matches>
                  Your selected non acceptable countries are which you intend to
                  travel in coming future.
                </CriteriaListItem>
              )} */}
          </CriteriaList>
          {companyAlias === "icici_pru" &&
            showNriPopup === nriPopupEvents.nriCountrySelect && (
              <p className="nri-popup-accept-nri-terms-text">
                Please confirm, above terms & condition’s of ICICI prudential
                are satisfied by you.
              </p>
            )}

          <div className="nri-popup-btn-group">
            {showNriPopup === nriPopupEvents.nriToggle &&
              educationMathces &&
              annualIncomeMatches &&
              sumAssuredMatches &&
              (companyAlias !== "icici_pru" ? occupationMatches : true) &&
              (companyAlias !== "icici_pru" ? sumAssuredMatches : true) &&
              (companyAlias === "bajaj_life" ? riderMatches : true) &&
              (companyAlias === "icici_pru" ? ageMatches : true) && (
                <button
                  className="nri-popup-btn nri-popup-btn-primary"
                  onClick={() => {
                    handleAgreeNriTerm(fadeOut);
                  }}
                >
                  OK, GOT IT
                </button>
              )}
            {(showNriPopup === nriPopupEvents.nriCountrySelect ||
              !educationMathces ||
              !annualIncomeMatches ||
              !sumAssuredMatches ||
              (companyAlias !== "icici_pru" ? !occupationMatches : false) ||
              (companyAlias !== "icici_pru" ? !sumAssuredMatches : false) ||
              (companyAlias === "bajaj_life" ? !riderMatches : false) ||
              (companyAlias === "icici_pru" ? !ageMatches : false)) && (
              <>
                {/* <button
                  className="nri-popup-btn"
                  onClick={() => {
                    fadeOut(() => {
                      handleClose();
                    });
                  }}
                >
                  CANCEL
                </button> */}
                <button
                  className="nri-popup-btn nri-popup-btn-primary"
                  onClick={() => {
                    showNriPopup === nriPopupEvents.nriCountrySelect
                      ? fadeOut(() => {
                          showNriPopup === nriPopupEvents.nriCountrySelect &&
                            handleAgreeCountry();
                        })
                      : (showNriPopup === nriPopupEvents.nriToggle ||
                          !countryMatches) &&
                        fadeOut(() => {
                          handleClose();
                        });
                  }}
                >
                  CONFIRM &{" "}
                  {showNriPopup === nriPopupEvents.nriToggle || !countryMatches
                    ? "EXIT"
                    : "PROCEED"}
                </button>
              </>
            )}
            {showNriPopup === nriPopupEvents.residingConfirm && (
              <button
                className="nri-popup-btn nri-popup-btn-primary nri-popup-btn-proceed"
                onClick={() => fadeOut(() => setShowNriPopup(false))}
              >
                CONFIRM & PROCEED TO PURCHASE
              </button>
            )}
          </div>
        </>
      )}
    </PopupWithTitle>
  );
}

export default NriCriteriaPopup;
