import { createSlice } from "@reduxjs/toolkit";
import {
  getAlcoholConsumed,
  getAlcoholQuantity,
  getTobacooConsumed,
  getTobacooQuantity,
  heightInCm,
  removeKg,
} from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const medicalDetailsOne = createSlice({
  name: "medicalDetailsOne",
  initialState: {
    medicalDetailsOne: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.medicalDetailsOne = action.payload;
    },
  },
});

export const { saveDetails } = medicalDetailsOne.actions;

export const medicalDetailsOneSendData = (data = {}) => ({
  height: data.height,
  heightInCm: heightInCm(data.height),
  weight: removeKg(data.weight),
  tobacco: data.tobacco ? "Y" : "N",
  alcohol: data.alcohol ? "Y" : "N",
  narcotics: data.narcotics ? "Y" : "N",
  tobaccoConsumedAs: getTobacooConsumed(data.tobaccoConsumedAs),
  tobaccoQuantity: getTobacooQuantity(data.tobaccoQuantity),
  tobaccoStartDate: data.tobaccoStartDate,
  alcoholStartDate: Number(data.years),
  alcoholConsumedAs: getAlcoholConsumed(data.consumedAs),
  alcoholQuantity: getAlcoholQuantity(data.quantityPerDay),
  proposalJourneyStatus: iciciProposalForms.medicalQuestion1,
});

export const saveMedicalDetailsOne = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(medicalDetailsOneSendData(data)));
      }
    } catch (err) {
      // alert("Something went wrong");
      console.error(err);
    }
  };
};

export default medicalDetailsOne.reducer;
