import HttpClient from "../../../api/httpClient";

export const saveProposal = data =>
  HttpClient("common/save_proposal", { method: "POST", data });

export const submitProposal = data =>
  HttpClient("common/submit_proposal", { method: "POST", data });

export const singleQuote = data =>
  HttpClient("common/single_product", {
    method: "POST",
    data,
  });

export const pinCodeMaster = data =>
  HttpClient("common/get_ic_pincode_master", { method: "POST", data });

export const updateQuotation = data =>
  HttpClient("common/update_quotation", { method: "POST", data });

export const getBankDetails = data =>
  HttpClient("common/get_bank_details", { method: "POST", data });
export const checkCKYC = data =>
  HttpClient("common/get_bajaj_life_ckyc", { method: "POST", data });
