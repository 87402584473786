import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Edit, envelope } from "../SvgComp";
import Share from "../../assets/img/share.svg";
// import logo from "../../assets/img/logo.svg";
// import callButton from "../../assets/img/callButton.svg";
// import phone from "../../assets/img/phone.svg";
import ContactPopup from "../Popup/ContactPopup";
import SendQuotes from "../Popup/SendQuotes";
import EmailPopup from "../Popup/EmailPopup";
import SecureLS from "secure-ls";
import "./NavBar2.scss";
import "./NavBar2quote.scss";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "../../modules/QuoteFilter/quoteFilterSlice";
import { getAffinityPreference } from "./../../modules/GreetingPage/greeting.slice";
const NavBar = () => {
  const dispatch = useDispatch();

  const ls = new SecureLS();

  const initialStyle = {
    display: "block",
    color: "#f6f6f6",
    expert: "inline-block",
  };

  const { pathname, search } = useLocation();
  const [conPopup, setConPopup] = useState(false);
  const [quoPopup, setQuoPopup] = useState(false);
  const [comparePop, setComparePop] = useState(false);
  const [navCheck, setNavCheck] = useState(false);
  const [navStyle, setNavStyle] = useState(initialStyle);
  const location = useLocation();

  const { isQuotesLoading } = useSelector(({ quote }) => quote);
  const { customerDetails, isPnbPreference } = useSelector(
    state => state.greeting,
  );
  useEffect(() => {
    if (
      location.pathname.includes("/loader") ||
      location.pathname.includes("/success") ||
      location.pathname.includes("/error")
    ) {
      setNavStyle({
        display: "none",
        color: "transparent",
        expert: "none",
      });
    } else if (JSON.stringify(navStyle) !== JSON.stringify(initialStyle)) {
      setNavStyle(initialStyle);
    }
    setNavCheck(false);
  }, [location.pathname]);

  const getClasses = () => {
    if (
      location.pathname?.toLowerCase() === "/quotes" ||
      location.pathname?.toLowerCase() === "/plan-compare" ||
      location.pathname?.toLowerCase() === "/riderPage" ||
      location.pathname?.toLowerCase() === "/eligibilitypage" ||
      location.pathname?.toLowerCase() === "/proposalpage"
    )
      return "quotesNavbar2";
    else return "navbar2";
  };
  useEffect(() => {
    var emailId =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      location.pathname !== "/" &&
      customerDetails.email &&
      customerDetails.mobileNo &&
      !isPnbPreference
    ) {
      dispatch(
        getAffinityPreference({
          email: customerDetails?.workEmail || customerDetails?.work_email,
          mobile_no: customerDetails.mobileNo,
          personal_email: customerDetails.email,
        }),
      );
    }
  }, [
    customerDetails.work_email,
    customerDetails.workEmail,
    customerDetails.mobileNo,
    customerDetails.email,
    isPnbPreference,
    location.pathname,
  ]);
  const getClasses2 = () => {
    if (
      location.pathname?.toLowerCase() === "/quotes" ||
      location.pathname?.toLowerCase() === "/plan-compare" ||
      location.pathname?.toLowerCase() === "/riderPage" ||
      location.pathname?.toLowerCase() === "/eligibilitypage" ||
      location.pathname?.toLowerCase() === "/proposalpage"
    )
      return "header2";
  };
  useEffect(() => {}, []);
  return (
    <Fragment>
      <header className={getClasses2()}>
        <div className={getClasses()}>
          <a href="https://elephant.in/">
            {/* <img 
              className='mainLogo'
              src={logo}
              alt='Logo'
              width='160px'
              height='54px'
              style={{marginTop:"-6px"}}
            /> */}

            <svg
              className="mainLogo"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="1417.323px"
              height="381.923px"
              viewBox="521.589 523.5 1417.323 381.923"
              enableBackground="new 521.589 523.5 1417.323 381.923"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    fill="#19B2DC"
                    d="M592.25,644.227c16.841,0,14.24-21.558,1.201-21.558C578.374,622.668,578.671,644.227,592.25,644.227"
                  ></path>
                  <g>
                    <path
                      fill="#19B2DC"
                      d="M521.586,637.041c0,14.384,5.299,27.698,11.6,36.312c5.871,8.024,18.601,17.304,31.521,17.588
                        c-1.668-2.27-9.159-6.529-12.514-9.048c-26.187-19.641-29.924-56.018-7.195-84.925c8.522-10.847,24.204-17.419,41.269-17.419
                        c39.77,0,72.426,46.717,110.193,46.717c34.461,0,42.295-49.4,13.443-67.347c-14.781-9.191-29.696-8.572-34.999-16.498
                        c15.066-8.468,123.672,27.935,28.447,124.274c-21.703,21.965-55.302,26.271-77.596,69.726
                        c-2.383,4.644-9.053,21.657-9.547,27.583H580.28c0-12.076-6.966-29.94-17.972-29.94h-5.988c-11.121,0-31.424,18.718,0.276,50.026
                        c24.32,24.033,70.968,38.607,109.92,38.607c56.36,0,103.517-35.064,127.376-84.624c27.265-56.63,15.344-128.454-31.235-173.217
                        l-19.959-15.977c-53.614-35.443-126.49-34.383-175.235,7.083C543.773,576.111,521.586,602.069,521.586,637.041"
                    ></path>
                    <path
                      fill="#231F20"
                      d="M991.676,697.821c0-15.846-2.082-29.397-6.259-40.651c-4.166-11.261-9.586-20.479-16.26-27.683
                        c-6.675-7.19-14.123-12.403-22.355-15.635c-8.237-3.23-16.424-4.849-24.555-4.849c-11.255,0-21.573,1.93-30.961,5.786
                        c-9.376,3.86-17.353,9.223-23.919,16.109c-6.576,6.876-11.682,15.164-15.328,24.859c-3.644,9.693-5.468,20.276-5.468,31.744
                        c0,11.88,1.92,22.775,5.78,32.681c3.857,9.9,9.279,18.453,16.262,25.643c6.985,7.193,15.428,12.822,25.334,16.887
                        c9.902,4.068,20.897,6.105,32.992,6.105c11.883,0,23.092-2.248,33.615-6.732c10.53-4.48,20.065-10.158,28.614-17.039
                        l-17.823-31.275c-6.884,6.043-13.551,10.628-20.014,13.757c-6.464,3.133-13.552,4.688-21.271,4.688
                        c-5.831,0-11.257-0.833-16.262-2.496c-4.995-1.67-9.326-4.066-12.974-7.197c-3.65-3.123-6.466-6.772-8.445-10.947
                        c-1.98-4.167-2.869-8.752-2.657-13.757L991.676,697.821L991.676,697.821z M892.229,665.611
                        c2.084-6.256,5.522-11.414,10.317-15.485c4.794-4.063,10.632-6.095,17.515-6.095c6.881,0,12.453,2.189,16.731,6.568
                        c4.275,4.378,7.139,9.384,8.603,15.013L892.229,665.611L892.229,665.611z M1048.082,536.449l-40.968,12.821v173.569
                        c0,7.506,1.62,14.074,4.853,19.7c3.231,5.635,7.351,10.377,12.349,14.232c5.002,3.857,10.426,6.721,16.266,8.598
                        s11.366,2.818,16.576,2.818c3.955,0,8.236-0.467,12.821-1.406c4.587-0.941,8.127-2.451,10.632-4.533l3.441-40.034
                        c-3.755,2.709-7.715,5.107-11.887,7.192c-4.171,2.082-8.02,3.128-11.569,3.128c-2.921,0-5.733-0.886-8.441-2.659
                        c-2.717-1.773-4.07-5.992-4.07-12.667V536.449H1048.082z M1225.204,697.821h-101.946c-0.206,5.006,0.678,9.591,2.658,13.757
                        c1.981,4.174,4.799,7.823,8.442,10.948c3.651,3.131,7.977,5.526,12.973,7.196c5.016,1.664,10.427,2.498,16.271,2.498
                        c7.714,0,14.802-1.556,21.266-4.69c6.458-3.129,13.13-7.713,20.012-13.757l17.826,31.276
                        c-8.547,6.879-18.085,12.557-28.611,17.039c-10.532,4.482-21.737,6.732-33.621,6.732c-12.088,0-23.092-2.039-32.997-6.105
                        c-9.901-4.066-18.343-9.695-25.33-16.887c-6.984-7.192-12.399-15.745-16.266-25.645c-3.848-9.906-5.779-20.801-5.779-32.68
                        c0-11.468,1.823-22.051,5.468-31.745c3.655-9.695,8.764-17.984,15.327-24.859c6.567-6.886,14.543-12.249,23.925-16.109
                        c9.382-3.856,19.707-5.787,30.963-5.787c8.125,0,16.311,1.619,24.55,4.849c8.234,3.232,15.69,8.444,22.361,15.635
                        c6.667,7.203,12.094,16.422,16.26,27.682C1223.127,668.424,1225.204,681.975,1225.204,697.821 M1125.759,665.611h53.166
                        c-1.455-5.629-4.326-10.635-8.599-15.013c-4.272-4.379-9.852-6.568-16.73-6.568c-6.882,0-12.722,2.033-17.514,6.096
                        C1131.286,654.197,1127.845,659.355,1125.759,665.611 M1235.645,601.496V833.24h40.654v-66.93
                        c4.174,1.045,8.392,1.826,12.662,2.348c4.281,0.518,8.605,0.779,12.982,0.779c11.055,0,21.376-2.082,30.96-6.254
                        c9.595-4.172,17.936-9.854,25.021-17.049c7.093-7.19,12.663-15.686,16.731-25.483c4.063-9.802,6.09-20.329,6.09-31.588
                        c0-11.468-1.979-22.052-5.939-31.741c-3.959-9.699-9.379-18.085-16.255-25.178c-6.883-7.089-15.118-12.616-24.709-16.577
                        c-9.594-3.953-20.019-5.939-31.273-5.939c-6.676,0-13.5,0.521-20.484,1.566c-6.986,1.04-13.189,2.501-18.608,4.373
                        L1235.645,601.496z M1276.3,649.971c4.8-1.458,8.604-2.398,11.417-2.812c2.808-0.419,5.992-0.626,9.537-0.626
                        c6.043,0,11.566,1.091,16.572,3.284c5.009,2.186,9.328,5.212,12.981,9.068c3.646,3.856,6.51,8.498,8.599,13.918
                        c2.084,5.418,3.128,11.26,3.128,17.511c0,12.511-3.799,22.624-11.416,30.337c-7.606,7.709-17.672,11.567-30.183,11.567
                        c-3.749,0-7.135-0.311-10.159-0.937c-3.02-0.619-6.51-1.774-10.477-3.437V649.971L1276.3,649.971z M1435.907,532.697
                        l-40.649,16.885v216.104h40.649v-111.96c4.592-2.705,8.868-4.792,12.83-6.255c3.953-1.459,8.964-2.19,15.014-2.19
                        c8.122,0,14.174,3.025,18.137,9.07c3.959,6.043,5.935,14.383,5.935,25.019v86.316h40.664v-91.947
                        c0-10.628-1.567-20.013-4.694-28.146c-3.128-8.132-7.455-14.904-12.978-20.323c-5.526-5.425-12.094-9.488-19.704-12.198
                        c-7.61-2.714-15.897-4.069-24.861-4.069c-6.055,0-11.577,0.626-16.577,1.882c-5.004,1.246-9.594,2.6-13.765,4.059V532.697
                        L1435.907,532.697z M1662.135,773.186V653.414c0-9.175-1.716-16.724-5.165-22.676c-3.432-5.941-7.767-10.631-12.972-14.074
                        c-5.212-3.438-10.785-5.836-16.73-7.192c-5.941-1.359-11.52-2.031-16.731-2.031c-10.01,0-20.695,1.097-32.055,3.283
                        c-11.364,2.188-22.883,6.829-34.557,13.917l16.572,32.836c7.294-5.423,14.96-9.333,22.98-11.725
                        c8.033-2.399,15.489-3.6,22.365-3.6c4.794,0,8.604,1.252,11.42,3.754c2.804,2.501,4.22,5.628,4.22,9.377v6.572
                        c-8.965,0-18.193,0.63-27.678,1.875c-9.485,1.251-18.083,3.909-25.802,7.973c-7.721,4.063-14.074,9.745-19.078,17.045
                        c-4.999,7.296-7.503,16.886-7.503,28.772c0,6.045,1.304,11.984,3.901,17.82c2.608,5.837,6.148,11.159,10.643,15.952
                        c4.48,4.797,9.693,8.654,15.635,11.576c5.939,2.916,12.355,4.373,19.231,4.373c3.336,0,6.981-0.102,10.942-0.311
                        c3.958-0.207,7.873-0.521,11.729-0.939c3.857-0.416,7.449-0.885,10.784-1.406c3.334-0.52,5.946-0.994,7.824-1.406
                        L1662.135,773.186z M1621.482,729.407c-1.045,0.419-2.605,0.833-4.694,1.257c-2.078,0.413-4.32,0.779-6.728,1.094
                        c-2.392,0.305-4.892,0.568-7.5,0.777c-2.605,0.207-4.954,0.31-7.038,0.31c-3.542,0-6.519-1.51-8.913-4.529
                        c-2.394-3.025-3.6-6.625-3.6-10.792c0-4.586,1.097-8.337,3.295-11.26c2.182-2.917,5.097-5.167,8.746-6.726
                        c3.645-1.561,7.77-2.606,12.357-3.123c4.587-0.523,9.282-0.786,14.073-0.786L1621.482,729.407L1621.482,729.407z
                        M1712.915,617.135l-27.836-15.013v163.563h40.654V650.287c7.088-3.547,16.987-5.319,29.714-5.319
                        c7.504,0,13.543,2.922,18.141,8.758c4.586,5.836,6.877,14.384,6.877,25.644v86.316h40.658V674.05
                        c0-22.097-5.79-38.518-17.358-49.253c-11.575-10.738-26.944-16.104-46.126-16.104c-8.547,0-16.578,0.728-24.083,2.192
                        c-7.509,1.457-14.391,3.329-20.643,5.624v0.625H1712.915z M1891.287,559.592l-40.658,22.83v30.649h-25.013v33.771h25.013v81.622
                        c0,6.881,1.309,12.88,3.919,17.987c2.606,5.109,5.942,9.283,10.006,12.512c4.068,3.227,8.705,5.627,13.917,7.188
                        c5.211,1.57,10.423,2.348,15.629,2.348c2.513,0,5.584-0.361,9.224-1.09c3.66-0.736,7.508-1.723,11.581-2.975
                        c4.063-1.25,8.128-2.709,12.197-4.379c4.058-1.664,7.759-3.334,11.096-5.004l-15.636-31.895c-4.999,2.709-9.378,4.849-13.128,6.4
                        c-3.753,1.573-7.718,2.354-11.886,2.354c-3.126,0-4.953-1.515-5.479-4.535c-0.518-3.024-0.781-8.082-0.781-15.163v-65.369h36.911
                        v-33.771h-36.911V559.592z"
                    ></path>
                  </g>
                </g>
                <g enableBackground="new">
                  <path
                    fill="#231F20"
                    d="M1392.127,874.498l-25.789-40.893v-1.025h10.068l20.137,33.287h0.412l20.034-33.287h9.863v1.025
                        l-25.685,40.893v30h-9.041V874.498z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1425.826,878.299c0-15.104,7.5-27.227,23.733-27.227c16.232,0,23.732,12.123,23.732,27.227
                        c0,15-7.295,27.125-23.732,27.125C1433.121,905.424,1425.826,893.402,1425.826,878.299z M1464.559,878.299
                        c0-11.303-4.314-19.521-14.999-19.521c-10.686,0-15.001,8.322-15.001,19.521c0,11.301,4.419,19.418,15.001,19.418
                        C1460.244,897.717,1464.559,889.6,1464.559,878.299z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1529.595,851.998v33.389c0,12.021-8.733,19.934-21.474,19.934c-12.739,0-21.473-7.912-21.473-19.934
                        v-33.389h8.63v31.951c0,8.322,3.699,13.664,12.843,13.664c9.145,0,12.842-5.342,12.842-13.664v-31.951H1529.595z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1543.978,899.156c0-3.598,2.055-6.166,6.062-6.166c3.905,0,6.063,2.568,6.063,6.166
                        c0,3.699-2.157,6.268-6.063,6.268C1546.032,905.424,1543.978,902.855,1543.978,899.156z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1596.17,833.605v-1.025h8.731l13.768,57.945h0.411l15.821-57.945h9.248l16.027,57.945h0.41l13.665-57.945
                        h8.835v1.025l-17.876,70.893h-9.041l-16.337-57.432h-0.41l-16.644,57.432h-8.939L1596.17,833.605z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1687.919,878.299c0-15.205,7.499-27.227,23.732-27.227c14.795,0,22.705,10.994,22.705,25.582v4.213
                        h-37.396c0,9.965,5.033,16.85,14.794,16.85c6.884,0,11.815-2.877,13.253-8.426h8.63c-1.438,9.66-10.685,16.133-21.883,16.133
                        C1696.24,905.424,1687.919,893.709,1687.919,878.299z M1725.418,873.676c0-8.322-4.418-14.898-13.767-14.898
                        c-9.863,0-14.281,6.68-14.59,14.898H1725.418z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1745.658,899.156c0-3.598,2.055-6.166,6.063-6.166c3.904,0,6.063,2.568,6.063,6.166
                        c0,3.699-2.158,6.268-6.063,6.268C1747.713,905.424,1745.658,902.855,1745.658,899.156z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1804.014,879.121V832.58h8.939v46.334c0,11.096,6.062,18.289,18.391,18.289
                        c12.534,0,18.493-7.193,18.493-18.289V832.58h9.041v46.541c0,16.541-10.377,26.303-27.534,26.303
                        C1814.494,905.424,1804.014,895.662,1804.014,879.121z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1873.264,887.135h8.629c0,6.986,5.344,10.582,12.843,10.582c7.397,0,12.021-2.467,12.021-7.5
                        c0-3.494-2.362-6.268-8.013-7.5l-10.48-1.953c-8.63-1.746-13.355-6.984-13.355-14.691c0-8.732,7.499-15,19.109-15
                        c11.814,0,20.239,6.473,20.239,16.953h-8.425c-0.308-6.68-5.239-9.658-11.814-9.658c-6.371,0-10.788,2.568-10.788,7.705
                        c0,4.111,3.391,6.473,9.144,7.398l8.22,1.541c9.863,2.26,14.897,6.164,14.897,15.205c0,9.658-9.452,15.207-20.754,15.207
                        C1882.305,905.424,1873.572,898.641,1873.264,887.135z"
                  ></path>
                  <path
                    fill="#231F20"
                    d="M1926.79,899.156c0-3.598,2.055-6.166,6.062-6.166c3.904,0,6.062,2.568,6.062,6.166
                        c0,3.699-2.157,6.268-6.062,6.268C1928.845,905.424,1926.79,902.855,1926.79,899.156z"
                  ></path>
                </g>
              </g>
            </svg>
          </a>

          {/* <div className='u-float-r u-flex-nav'> */}
          {/* {pathname !== "/" && (
            <span className="qId">
              Quotes Id-{sessionStorage.getItem("traceId") || search.slice(11, 29)}
            </span>
          )} */}
          {/* <a href='tel:18002669639' className='navigation__contactbtn2'>
            <img src={callButton} alt='Logo'></img>
          </a>
           */}

          <a href="tel:18002669639" className="callBtn">
            {location.pathname?.toLowerCase() === "/quotes" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "relative" }}
                viewBox="0 0 24 24"
                // fill="#8d9194"
                fill="#656565"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "relative" }}
                viewBox="0 0 24 24"
                // fill="#8d9194"
                fill="#656565"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z"></path>
              </svg>
            )}
          </a>

          <ContactPopup show={conPopup} onClose={setConPopup} />
          <input
            type="checkbox"
            className="navigation__checkbox"
            id="navi-toggle"
            checked={navCheck}
            onChange={e => {
              setNavCheck(e.target.checked);
            }}
          />
          <label htmlFor="navi-toggle" className="openbtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#333333"
              width="38px"
              height="38px"
              className="navMargin"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </label>
          <button
            onClick={() => setConPopup(true)}
            className="talkExpert quotes-nav-btn"
          >
            {" "}
            <svg
              className="quoteTalkExpertSvg"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path d="M0,0h24v24h-24Z" fill="none"></path>
              <path
                fill="#333"
                d="M6.62,10.79c1.44,2.83 3.76,5.14 6.59,6.59l2.2,-2.2c0.27,-0.27 0.67,-0.36 1.02,-0.24c1.12,0.37 2.33,0.57 3.57,0.57c0.55,0 1,0.45 1,1v3.49c0,0.55 -0.45,1 -1,1c-9.39,0 -17,-7.61 -17,-17c0,-0.55 0.45,-1 1,-1h3.5c0.55,0 1,0.45 1,1c0,1.25 0.2,2.45 0.57,3.57c0.11,0.35 0.03,0.74 -0.25,1.02l-2.2,2.2Z"
              ></path>
            </svg>
            <span>Talk to Expert</span>
          </button>

          {pathname?.toLowerCase() === "/plan-compare" && (
            <>
              <button
                onClick={() => {
                  setComparePop(true);
                }}
                className="quoteTalkExpert tablet-hide"
              >
                {envelope}
                <span>Email comparison</span>
              </button>
              <EmailPopup show={comparePop} onClose={setComparePop} />
            </>
          )}
          {pathname?.toLowerCase() === "/quotes" && (
            <>
              <button
                onClick={() => {
                  setQuoPopup(true);
                }}
                className="quoteTalkExpert quoteSendQuote"
                disabled={isQuotesLoading}
              >
                <img src={Share} alt="share" />
                <span>Send Quotes</span>
              </button>
              <SendQuotes show={quoPopup} onClose={setQuoPopup} />
            </>
          )}

          {pathname?.toLowerCase() === "/quotes" && (
            <>
              <button
                style={{ backgroundColor: "#f2f3f7" }}
                onClick={() => {
                  dispatch(setPopup("modify"));
                }}
                className="quoteTalkExpert tabletHide"
                // disabled={isQuotesLoading}
              >
                <svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    d="M3,17.25v3.75h3.75l11.06,-11.06l-3.75,-3.75l-11.06,11.06Zm17.71,-10.21c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34c-0.39,-0.39 -1.02,-0.39 -1.41,0l-1.83,1.83l3.75,3.75l1.83,-1.83Z"
                    fill="#333"
                  ></path>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                {/* <svg version="1.1" viewBox="0 0 24 24" width="20" height="20" style ={{marginRight: "6px", marginBottom: "3px"}}><path d="M3,17.25v3.75h3.75l11.06,-11.06l-3.75,-3.75l-11.06,11.06Zm17.71,-10.21c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34c-0.39,-0.39 -1.02,-0.39 -1.41,0l-1.83,1.83l3.75,3.75l1.83,-1.83Z" fill="black"></path><path fill="none" d="M0,0h24v24h-24Z"></path></svg> */}
                <span>Modify Details</span>
              </button>
            </>
          )}

          <nav className="navigation__nav">
            <label htmlFor="navi-toggle" className="navigation__close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#fff"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </label>
            <div className="navigation__list">
              <a
                href="https://elephant.in/"
                className="navigation__link"
                target="_blank"
              >
                Home
              </a>

              <a
                href="https://elephant.in/about-us"
                className="navigation__link"
                target="_blank"
              >
                About Us
              </a>

              <a
                href="https://elephant.in/#whyelephant"
                className="navigation__link"
                target="_blank"
              >
                Why Elephant?
              </a>

              <a
                href="https://pos.elephant.in/"
                className="navigation__link"
                target="_blank"
              >
                Become an Agent
              </a>
              <a
                href="https://elephant.in/customer-login/claims"
                className="navigation__link"
                target="_blank"
              >
                Claims
              </a>
              <a
                href="https://elephant.in/corporate-signup"
                className="navigation__link"
                target="_blank"
              >
                Corporate Sign-Up
              </a>

              <a
                href="https://elephant.in/contact-us"
                className="navigation__link"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
          </nav>
          {/* </div> */}
          {pathname !== "/" && (
            <span className="qd">
              <div> Quotes Id:</div>
              <div>
                {search.slice(11, 29) || sessionStorage.getItem("traceId")}
              </div>
            </span>
          )}
        </div>
      </header>
    </Fragment>
  );
};

export default NavBar;
