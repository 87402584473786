import httpClient from "../../api/httpClient";
import axios from "axios";
export const getQuotes = data =>
  httpClient("common/get_products", { method: "POST", data });

export const uploadQuote = data =>
  httpClient("common/update_quotation", { method: "POST", data });

export const getRider = data =>
  httpClient("common/get_riders", { method: "POST", data });

export const sendQuote = data =>
  httpClient("common/send_email", { method: "POST", data });

export const getQuotesByIC = (ic, data, config = {}, time) =>
  httpClient(`common/${ic}`, { method: "POST", data, ...config, timeout:time});

export const getSourceScript = data =>
  axios.get(`https://elephant.in/api/get_soure_script?source_name=${data}`);
