import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Card from "../../components/Card/Card";
import CardTitle from "../../components/Card/CardTitle/CardTitle";
import { Form, FormGroup } from "../../components/Form/Form";
import CustomDropDown from "../../components/CutomDropDown/CustomDropDown";
import { getNextDays } from "../../utils/inputUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  initLoader,
  medicalCenterApi,
  // setMedicalFormDetails,
} from "./MedicalFormCenter.slice";
import { yupResolver } from "@hookform/resolvers";
import { medicalFormSchema } from "../../utils/validationSchema";
import SecureLS from "secure-ls";
import Loader from "../../components/Loader/Loader";
import { useHistory, useLocation } from "react-router-dom";
import { getAllData } from "../../api/getAllData";
import { useCustomLocation } from "../../custom-hooks";

const MedicalFormCenter = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, unregister } = useForm({
    resolver: yupResolver(medicalFormSchema),
  });
  const { response } = useSelector(state => state.iciciProposal);
  const { loading } = useSelector(state => state.medicalFormCenter);

  const { appNo } = useSelector(state => state.eligibilityCard);

  const { enquiryId } = useCustomLocation();

  const ls = new SecureLS();

  // const applicationNumber = sessionStorage.getItem("appNo");
  const applicationNumber = appNo;
  // const traceId = sessionStorage.getItem("traceId");
  const traceId = enquiryId;

  const [visitType, setVisitType] = useState(null);

  useEffect(() => {
    if (Object.keys(response).length === 0)
      dispatch(getAllData(history, null, search.slice(11, 29)));
  }, []);

  useEffect(() => {
    setVisitType(response?.medicalVisit && response.medicalVisit);
  }, [response]);

  const dateRef = useRef();

  const [medicalCenters, setMedicalCenters] = useState([]);
  const [medicalCenter, setMedicalCenter] = useState({});
  const [medicalObjects, setMedicalObjects] = useState([]);

  const timeIntervalsTU = [
    "Preferred Time",
    "9.30 AM to 10.30 AM",
    "10.30 AM to 11.30 AM",
    "11.30 AM to 12.30 PM",
    "12.30 PM to 1.30 PM",
    "1.30 PM to 2.30 PM",
    "2.30 PM to 3.30 PM",
    "3.30 PM to 4.30 PM",
    "4.30 PM to 5.30 PM",
    "5.30 PM to 6.30 PM",
    "6.30 PM to 7.30 PM",
  ];

  const timeIntervalNonTU = [
    "Preferred Time",
    "8 AM to 9 AM",
    "9 AM to 10 AM",
    "10 AM to 11 AM",
    "11 AM to 12 PM",
    "12 PM to 1 PM",
    "1 PM to 2 PM",
    "2 PM to 3 PM",
    "3 PM to 4 PM",
    "4 PM to 5 PM",
    "5 PM to 6 PM",
    "6 PM to 7 PM",
    "7 PM to 8 PM",
  ];

  useEffect(() => {
    setValue("breDecision", response?.BREDecision);
    if (response?.medicalCenter) {
      setMedicalCenters(
        JSON.parse(response?.medicalCenter).map(center => {
          return `${ center.CNTRMASTER_DIAGNOSTIC_CENTER } ${ center.CNTRMASTER_DC_CITY }`;
        }),
      );
    }

    if (response?.medicalCenter) {
      setMedicalObjects(JSON.parse(response?.medicalCenter));
    }
  }, [response, setValue]);

  const history = useHistory();

  const { search } = useLocation();

  useEffect(() => {
    register({ name: "breDecision" });
    register({ name: "preTime" });
    register({ name: "preferredDate" });
    register({ name: "visitType" });
    if (response?.medicalVisit === "Center Visit") {
      register({ name: "medicalCenterr" });
    }
  }, [register]);

  const visitTypes = ["Visit Type", "Center Visit", "Home Visit"];

  const onSubmit = data => {
    const newData = {
      traceId: traceId,
      appNo: applicationNumber,
      BREDecision: response?.BREDecision,
      dateOfAppointment: data.preferredDate,
      timeOfAppointment: data.preTime,
      companyAlias: "icici_pru",
      visitType: response?.BREDecision !== "TU" && visitType,
      centerId:
        response?.medicalVisit === "Center Visit"
          ? medicalCenter.CNTRMASTER_SRNO
          : undefined,
      centerDetails:
        response?.medicalVisit === "Center Visit"
          ? `${ medicalCenter?.CNTRMASTER_DIAGNOSTIC_CENTER } ${ medicalCenter?.CNTRMASTER_DC_ADDRESS1 } ${ medicalCenter?.CNTRMASTER_DC_ADDRESS2 } ${ medicalCenter?.CNTRMASTER_DC_CITY } ${ medicalCenter?.CNTRMASTER_DC_PINCODE } ${ medicalCenter?.CNTRMASTER_DC_STATE } Contact No:${ medicalCenter?.CNTRMASTER_DC_MOBILENO }`
            .split("_")
            .join(" ")
            .split(",")
            .join(" ")
          : undefined,
    };
    dispatch(medicalCenterApi(newData, history, search));
    dispatch(initLoader(true));
  };

  return (
    <>
      <div className="application_no">
        {<span>Application No: {sessionStorage.getItem("appNo")}</span>}
      </div>
      <Card
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CardTitle>Medical Form Center</CardTitle>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup md eligibilityPage>
            <CustomDropDown
              name="preferredDate"
              items={
                response?.BREDecision === "TU"
                  ? ["Preferred Date", ...getNextDays(3)]
                  : ["Preferred Date", ...getNextDays(8)]
              }
              onChange={value => setValue("preferredDate", value)}
            />
            {errors.preferredDate && (
              <p className="errorG">{errors.preferredDate.message}</p>
            )}
          </FormGroup>

          <FormGroup md eligibilityPage>
            <CustomDropDown
              name="timeInterval"
              items={
                response?.BREDecision === "TU"
                  ? timeIntervalsTU
                  : timeIntervalNonTU
              }
              onChange={value => setValue("preTime", value)}
            />
            {errors.preTime && (
              <p className="errorG">{errors.preTime.message}</p>
            )}
          </FormGroup>

          {response?.BREDecision !== "TU" && (
            <FormGroup md eligibilityPage>
              <CustomDropDown
                name="visitType"
                items={visitTypes}
                disabled={response?.medicalVisit ? true : false}
                selectedItem={response?.medicalVisit}
                currentSelected={response?.medicalVisit}
                onChange={setValue(
                  "visitType",
                  response?.medicalVisit && response.medicalVisit,
                )}
              />
              {errors.visitType && (
                <p className="errorG">{errors.visitType.message}</p>
              )}
            </FormGroup>
          )}
          {response?.BREDecision !== "TU" &&
            response?.medicalVisit === "Center Visit" ? (
            <FormGroup md eligibilityPage>
              <CustomDropDown
                name="medicalCenterr"
                items={["Medical Center", ...medicalCenters]}
                onChange={value => {
                  setMedicalCenter(
                    medicalObjects.find(
                      d =>
                        `${ d.CNTRMASTER_DIAGNOSTIC_CENTER } ${ d.CNTRMASTER_DC_CITY }` ===
                        value,
                    ),
                  );
                  setValue(
                    "medicalCenterr",
                    medicalObjects.find(
                      d =>
                        `${ d.CNTRMASTER_DIAGNOSTIC_CENTER } ${ d.CNTRMASTER_DC_CITY }` ===
                        value,
                    ),
                  );
                }}
              />
              {errors.medicalCenterr && (
                <p className="errorG">{errors.medicalCenterr.message}</p>
              )}
            </FormGroup>
          ) : null}
          <FormGroup xlg eligibilityPage>
            <button
              type="submit"
              className={"btn btn--highlighted btn--lg uppercase bold buttton"}
            >
              Submit
            </button>
          </FormGroup>
        </Form>
      </Card>
      {loading && <Loader />}
    </>
  );
};

export default MedicalFormCenter;
