export const medicalDetails4Data = data => [
  {
    id: "diseases8",
    label:
      "Genitourinary disorders related to Kidney,Prostate or uninary system?",
    items: ["Yes", "No"],
    value: data?.kidneyQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases8Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Genitourinary disorders related to Kidney",
                "Prostate or uninary system",
              ],
              value: data?.kidneyDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases8Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.kidneyDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases8Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.kidneyCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases8ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.kidneyControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases8Complication",
              label: "Any other complication",
              size: "md",
              value: data?.kidneyOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases9",
    label: "Cancer / Tumor / Unusual growth or cyst of any kind?",
    items: ["Yes", "No"],
    value: data?.cancerQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases9Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: ["Cancer", "Tumor", "Unusual growth or cyst of any kind"],
              value: data?.cancerDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases9Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.cancerDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases9Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.cancerCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases9ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.cancerControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases9Complication",
              label: "Any other complication",
              size: "md",
              value: data?.cancerOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases10",
    label:
      "HIV Infection or postive test of HIV for yourself / spouse / parents?",
    items: ["Yes", "No"],
    value: data?.hivQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases10Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: ["HIV for yourself", "HIV for spouse", "HIV for parents"],
              value: data?.hivDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is Required",
              },
            },
            {
              id: "diseases10Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.hivDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases10Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.hivCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases10ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.hivControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases10Complication",
              label: "Any other complication",
              size: "md",
              value: data?.hivOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases11",
    label: "Any blood disorders like Anemia, Thalassemia,etc?",

    items: ["Yes", "No"],
    value: data?.anemiaQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",

    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases11Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: ["Anemia", "Thalassemia"],
              value: data?.anemiaDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is Required",
              },
            },
            {
              id: "diseases11Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.anemiaDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases11Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.anemiaCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases11ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.anemiaControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases11Complication",
              label: "Any other complication",
              size: "md",
              value: data?.anemiaOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases12",
    label:
      "Any Physical deformity or handicap, joints or muscular disorder, congenital defect or mental / psychiatric disorder?",
    items: ["Yes", "No"],
    value: data?.handicapQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases12Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Physical deformity or handicap",
                "joints or muscular disorder",
                "congenital defect or mental / psychiatric disorder",
              ],
              value: data?.handicapDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases12Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.handicapDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases12Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.handicapCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases12ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.handicapControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases12Complication",
              label: "Any other complication",
              size: "md",
              value: data?.handicapOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases13",
    label:
      "Any Injury /Surgery / Medical condition requiring Hospitalization or any medical condition / disorder not covered above?",
    items: ["Yes", "No"],
    value: data?.surgeryQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases13Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Injury",
                "Surgery",
                "Medical condition requiring Hospitalization",
                "medical condition / disorder not covered above",
              ],
              value: data?.surgeryDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is Required",
              },
            },
            {
              id: "diseases13Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.surgeryDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases13Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.surgeryCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases13ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.surgeryControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases13Complication",
              label: "Any other complication",
              size: "md",
              value: data?.surgeryOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases14",
    label: "Any diseases and disorders of eye, ear, nose, throat?",
    items: ["Yes", "No"],
    value: data?.sensesQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases14Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Disorders of eye, ear, nose, throat",
                "Diseases of eye, ear, nose, throat",
                "Any diseases",
              ],
              value: data?.sensesDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases14Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.sensesDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases14Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.sensesCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases14ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.sensesControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases14Complication",
              label: "Any other complication",
              size: "md",
              value: data?.sensesOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases15",
    label:
      "In the last 5 years, have you ever had, or been advised to have, or are likely within the next 30 days to undergo medical examination or any investigations such as but not limited to blood test, urine test, x-ray, ECG or biopsy, CT scan or test by any other special instrument?",
    items: ["Yes", "No"],
    value: data.medicalExaminationQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases15text",
              label: "Please Specify",
              size: "md",
              value: data?.medicalExaminationQuestionText || "",
              type: "textArea",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
                maxLength: 500,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
];
