export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const BAJAJ_COUNTRIES = {
  AFGHANISTAN: "AFG",
  ANGOLA: "AGO",
  ANGUILLA: "AIA",
  ALBANIA: "ALB",
  ANDORRA: "AND",
  "NETHERLANDS ANTILLES": "ANT",
  "UNITED ARAB EMIRATES (WAS TRUCIAL STATES": "ARE",
  ARGENTINA: "ARG",
  ARMENIA: "ARM",
  "AMERICAN SAMOA": "ASM",
  Austria: "AT",
  ANTARCTICA: "ATA",
  "FRENCH SOUTHERN TERRITORIES": "ATF",
  "ANTIGUA AND BARBUDA": "ATG",
  AUSTRALIA: "AUS",
  AUSTRIA: "AUT",
  AZERBAIJAN: "AZE",
  BURUNDI: "BDI",
  BELGIUM: "BEL",
  "BENIN (WAS DAHOMEY)": "BEN",
  "BURKINA FASO (WAS UPPER VOLTA)": "BFA",
  BANGLADESH: "BGD",
  BULGARIA: "BGR",
  BAHRAIN: "BHR",
  BAHAMAS: "BHS",
  "BOSNIA AND HERZEGOVINA": "BIH",
  BELARUS: "BLR",
  BELIZE: "BLZ",
  BERMUDA: "BMU",
  BOLIVIA: "BOL",
  BRAZIL: "BRA",
  BARBADOS: "BRB",
  "BRUNEI DARUSSALAM": "BRN",
  BHUTAN: "BTN",
  "BOUVET ISLAND (BOUVETOYA)": "BVT",
  BOTSWANA: "BWA",
  "CENTRAL AFRICAN REPUBLIC": "CAF",
  CANADA: "CAN",
  "COCOS (KEELING) ISLANDS": "CCK",
  SWITZERLAND: "CHE",
  CHILE: "CHL",
  CHINA: "CHN",
  "COTE D IVOIRE": "CIV",
  CAMEROON: "CMR",
  "CONGO, DEMOCRATIC REPUBLIC OF (WAS ZAIRE": "COD",
  "COOK ISLANDS": "COK",
  COLOMBIA: "COL",
  COMOROS: "COM",
  "CAPE VERDE": "CPV",
  "COSTA RICA": "CRI",
  CUBA: "CUB",
  "CHRISTMAS ISLAND": "CXR",
  "CAYMAN ISLANDS": "CYM",
  CYPRUS: "CYP",
  "Czech Republic": "CZ",
  "CZECH REPUBLIC": "CZE",
  Germany: "D",
  GERMANY: "DEU",
  DJIBOUTI: "DJI",
  DOMINICA: "DMA",
  DENMARK: "DNK",
  "DOMINICAN REPUBLIC": "DOM",
  ALGERIA: "DZA",
  ECUADOR: "ECU",
  EGYPT: "EGY",
  ERITREA: "ERI",
  "WESTERN SAHARA (WAS SPANISH SAHARA)": "ESH",
  SPAIN: "ESP",
  ESTONIA: "EST",
  ETHIOPIA: "ETH",
  France: "F",
  FINLAND: "FIN",
  FIJI: "FJI",
  "FALKLAND ISLANDS (MALVINAS)": "FLK",
  FRANCE: "FRA",
  "FAEROE ISLANDS": "FRO",
  MICRONESIA: "FSM",
  GABON: "GAB",
  GEORGIA: "GEO",
  GHANA: "GHA",
  GIBRALTAR: "GIB",
  GUINEA: "GIN",
  GUADALOUPE: "GLP",
  GAMBIA: "GMB",
  "GUINEA-BISSAU": "GNB",
  "EQUATORIAL GUINEA": "GNQ",
  GREECE: "GRC",
  GRENADA: "GRD",
  GREENLAND: "GRL",
  GUATEMALA: "GTM",
  "FRENCH GUIANA": "GUF",
  GUAM: "GUM",
  GUYANA: "GUY",
  "HONG KONG": "HKG",
  "HEARD AND MCDONALD ISLANDS": "HMD",
  HONDURAS: "HND",
  Croatia: "HR",
  "HRVATSKA (CROATIA)": "HRV",
  HAITI: "HTI",
  Hungary: "HU",
  HUNGARY: "HUN",
  INDONESIA: "IDN",
  Ireland: "IE",
  India: "IN",
  "BRITISH INDIAN OCEAN TERRITORY (CHAGOS A": "IOT",
  IRELAND: "IRL",
  IRAN: "IRN",
  IRAQ: "IRQ",
  ICELAND: "ISL",
  ISRAEL: "ISR",
  Italy: "IT",
  ITALY: "ITA",
  JAMAICA: "JAM",
  JORDAN: "JOR",
  JAPAN: "JPN",
  KAZAKHSTAN: "KAZ",
  KENYA: "KEN",
  "KYRGYZ REPUBLIC": "KGZ",
  CAMBODIA: "KHM",
  KIRIBATI: "KIR",
  "ST. KITTS AND NEVIS": "KNA",
  "KOREA, REPUBLIC": "KOR",
  KUWAIT: "KWT",
  LEBANON: "LBN",
  LIBERIA: "LBR",
  "LIBYAN ARAB JAMAHIRIYA": "LBY",
  "ST. LUCIA": "LCA",
  LIECHTENSTEIN: "LIE",
  "SRI LANKA": "LKA",
  LESOTHO: "LSO",
  LITHUANIA: "LTU",
  LUXEMBOURG: "LUX",
  LATVIA: "LVA",
  MACAO: "MAC",
  MOROCCO: "MAR",
  MONACO: "MCO",
  MOLDOVA: "MDA",
  MADAGASCAR: "MDG",
  MALDIVES: "MDV",
  MEXICO: "MEX",
  "MARSHALL ISLANDS": "MHL",
  MACEDONIA: "MKD",
  MALI: "MLI",
  MALTA: "MLT",
  "MYANMAR (WAS BURMA)": "MMR",
  MONGOLIA: "MNG",
  "NORTHERN MARIANA ISLANDS": "MNP",
  MOZAMBIQUE: "MOZ",
  MAURITANIA: "MRT",
  MONTSERRAT: "MSR",
  MARTINIQUE: "MTQ",
  MAURITIUS: "MUS",
  MALAWI: "MWI",
  MALAYSIA: "MYS",
  MAYOTTE: "MYT",
  NAMIBIA: "NAM",
  "NEW CALEDONIA": "NCL",
  NIGER: "NER",
  "NORFOLK ISLAND": "NFK",
  NIGERIA: "NGA",
  NICARAGUA: "NIC",
  NIUE: "NIU",
  Holland: "NL",
  "NETHERLANDS, KINGDOM OF THE": "NLD",
  NORWAY: "NOR",
  NEPAL: "NPL",
  NAURU: "NRU",
  "NEW ZEALAND": "NZL",
  OMAN: "OMN",
  PAKISTAN: "PAK",
  PANAMA: "PAN",
  "PITCAIRN ISLAND": "PCN",
  PERU: "PER",
  PHILIPPINES: "PHL",
  Poland: "PL",
  PALAU: "PLW",
  "PAPUA NEW GUINEA": "PNG",
  POLAND: "POL",
  "PUERTO RICO": "PRI",
  "KOREA, DEMOCRATIC": "PRK",
  PORTUGAL: "PRT",
  PARAGUAY: "PRY",
  "PALESTINIAN TERRITORY": "PSE",
  "FRENCH POLYNESIA": "PYF",
  QATAR: "QAT",
  REUNION: "REU",
  ROMANIA: "ROU",
  "RUSSIAN FEDERATION": "RUS",
  RWANDA: "RWA",
  "SAUDI ARABIA": "SAU",
  "SERBIA AND MONTENEGRO": "SCG",
  SUDAN: "SDN",
  SENEGAL: "SEN",
  SINGAPORE: "SGP",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISL": "SGS",
  "ST. HELENA": "SHN",
  "SVALBARD AND JAN MAYEN ISLANDS": "SJM",
  "Slovak Republic": "SK",
  "SOLOMON ISLANDS (WAS BRITISH SOLOMON ISL": "SLB",
  "SIERRA LEONE": "SLE",
  Slovenia: "SLO",
  "EL SALVADOR": "SLV",
  "SAN MARINO": "SMR",
  SOMALIA: "SOM",
  "ST. PIERRE AND MIQUELON": "SPM",
  "SAO TOME AND PRINCIPE": "STP",
  SURINAME: "SUR",
  "SLOVAKIA (SLOVAK REPUBLIC)": "SVK",
  SLOVENIA: "SVN",
  SWEDEN: "SWE",
  SWAZILAND: "SWZ",
  SEYCHELLES: "SYC",
  "SYRIAN ARAB REPUBLIC": "SYR",
  "TURKS AND CAICOS ISLANDS": "TCA",
  CHAD: "TCD",
  TOGO: "TGO",
  THAILAND: "THA",
  TAJIKISTAN: "TJK",
  "TOKELAU (TOKELAU ISLANDS)": "TKL",
  TURKMENISTAN: "TKM",
  "TIMOR-LESTE": "TLS",
  TONGA: "TON",
  "TRINIDAD AND TOBAGO": "TTO",
  TUNISIA: "TUN",
  TURKEY: "TUR",
  "TUVALU (WAS PART OF GILBERT AND ELLICE I": "TUV",
  TAIWAN: "TWN",
  TANZANIA: "TZA",
  UGANDA: "UGA",
  "United Kingdom": "UK",
  UKRAINE: "UKR",
  "UNITED STATES MINOR OUTLYING ISLANDS": "UMI",
  "URUGUAY, EASTERN REPUBLIC OF": "URY",
  "UNITED STATES OF AMERICA": "USA",
  UZBEKISTAN: "UZB",
  "HOLY SEE (VATICAN CITY STATE)": "VAT",
  "ST. VINCENT AND THE GRENADINES": "VCT",
  VENEZUELA: "VEN",
  "BRITISH VIRGIN ISLANDS": "VGB",
  "US VIRGIN ISLANDS": "VIR",
  "VIET NAM": "VNM",
  "VANUATU (WAS NEW HEBRIDES)": "VUT",
  "WEST INDIES": "WED",
  "WALLIS AND FUTUNA ISLANDS": "WLF",
  SAMOA: "WSM",
  YEMEN: "YEM",
  "SOUTH AFRICA": "ZAF",
  ZAMBIA: "ZMB",
  "ZIMBABWE (WAS SOUTHERN RHODESIA)": "ZWE",
};
