export const data = [
  {
    type: "text",
    name: "firstName",
    vname: "firstName",
    id: "firstName",
    readOnly: true,
    placeHolder: " ",
    autoComplete: "none",
    label: "First Name",
  },
  {
    type: "text",
    name: "lastName",
    vname: "lastName",
    readOnly: true,
    id: "lastName",
    placeHolder: " ",
    autoComplete: "none",
    label: "Last Name",
  },
  {
    type: "email",
    name: "email",
    vname: "work_email",
    readOnly: true,
    id: "email",
    placeHolder: " ",
    autoComplete: "none",
    label: "Work Email ID",
  },
  {
    type: "email",
    name: "secondary_email",
    vname: "email",
    id: "secondary_email",
    readOnly: false,
    placeHolder: " ",
    autoComplete: "none",
    label: "Personal Email ID",
  },
  {
    type: "text",
    readOnly: true,
    name: "mobileNo",
    vname: "mobileNo",
    id: "mobileNo",
    placeHolder: " ",
    autoComplete: "none",
    label: "Mobile no.",
    maxLength: 10,
  },
  {
    type: "text",
    name: "pan",
    vname: "pan",
    id: "pan",
    readOnly: false,
    placeHolder: " ",
    autoComplete: "none",
    label: "PAN no.",
    maxLength: 10,
  },
  {
    type: "text",
    name: "dob",
    vname: "dob",
    id: "dob",
    readOnly: true,
    placeHolder: " ",
    autoComplete: "none",
    label: "Date of Birth",
    maxLength: 10,
  },
];
