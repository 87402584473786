import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModifyDetails from "../../components/Popup/ModifyDetails";

import { LumpSum, Monthly } from "../../components";
import {
  FilterMenuWrap,
  SubFilterWrap,
  MorePlans,
  SubFilterRight,
} from "./components";
import {
  AmountFilter,
  CoverUptoFilter,
  MorePlansFilter,
  NomineeGetsFilter,
  PaymentTermFilter,
} from "./components/Filters/Filters";

import {
  setCoverAmount,
  setCoverUpto,
  setCoverUptoValues,
  setNomineeGets,
  setPopup,
  setReturnOfPremium,
  updateFilters,
} from "./quoteFilterSlice";

import { getAge, getNumberfromString } from "../../utils/inputUtils";

import {
  amountInNumbers,
  createArrayOfNumbers,
  getCoverUptoForAge,
  getDefaultCoverAmount,
  numberToDigitWord,
  validateCoverAmount,
  validateCoverUpto,
} from "./helper";
import { setStartAnnualIncome } from "../GreetingPage/greeting.slice";
import "./quote-filter.scss";
import { fetchQuotes, setCameFromRiders } from "../QuotePage/quotePageSlice";
import { useCustomLocation, useDisplayNomineeGets } from "../../custom-hooks";

function QuoteFilter() {
  const dispatch = useDispatch();
  const [startingAnnualIncome, setStartingAnnualIncome] = useState(false);
  const {
    cameFromCompare,
    cameFromGreeting,
    cameFromRiders,
    quotes,
    corporateJourney,
  } = useSelector(state => state.quote);

  const {
    coverAmount,
    coverUpto,
    morePlans,
    paymentTerm: { option, term },
    nomineeGets,
    coverAmountMonthly,
    coverAmountLumpsumMonthly,
  } = useSelector(state => state.quoteFilter.filter);

  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );
  const cover_amount = amountInNumbers(coverAmount);
  const {
    dob,
    annualIncome,
    tobaccoHabit,
    gender,
    email,
    preference,
    startAnnualIncome,
    qualification,
  } = useSelector(({ greeting }) => greeting.customerDetails);

  const popup = useSelector(({ quoteFilter }) => quoteFilter.popup);

  const initialRef = useRef(true);

  const { returnOfPremium } = useSelector(({ quoteFilter }) => quoteFilter);

  useDisplayNomineeGets();

  const { enquiryId } = useCustomLocation();

  useEffect(() => {
    if (!initialRef.current && corporateJourney) {
      const age = getAge(dob);
      const isValidCoverUpto = validateCoverUpto(parseInt(coverUpto), age);
      const isValidCoverAmount = validateCoverAmount(
        parseInt(getNumberfromString(coverAmount)),
        age,
        parseInt(annualIncome),
        preference,
      );
      if (!isValidCoverUpto) {
        dispatch(setCoverUpto({ value: getCoverUptoForAge(age) }));
      }
      if (!isValidCoverAmount) {
        const newCoverAmount = numberToDigitWord(
          String(getDefaultCoverAmount(age, annualIncome, preference)),
          50000,
          2,
        );
        dispatch(setCoverAmount({ value: newCoverAmount }));
        dispatch(setNomineeGets({ value: "Lumpsum" }));
      }
      if (isValidCoverUpto && isValidCoverAmount) {
        dispatch(
          fetchQuotes({
            traceId: enquiryId,
            activeICs: corporateJourney?.gameskraft_ic,
            active: corporateJourney?.is_gameskraft === "active",
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dob, gender, tobaccoHabit, annualIncome, email, qualification]);
  const [paymentTermOption] = useState(
    useSelector(({ quoteFilter }) => quoteFilter.filter.paymentTerm.option),
  );

  useEffect(() => {
    if (!initialRef.current) {
      dispatch(
        setCoverUptoValues(
          createArrayOfNumbers(getAge(dob) + 5, 85, 1, "years"),
        ),
      );
    }
    if (paymentTermOption === "Single Pay") {
      dispatch(
        setCoverUptoValues(
          createArrayOfNumbers(getAge(dob) + 1, 85, 1, "years"),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dob]);

  useEffect(() => {
    if (
      (!cameFromCompare &&
        (!initialRef.current || cameFromGreeting) &&
        !cameFromRiders) ||
      quotes.length === 0
    ) {
      let newData = {
        sumAssured:
          cover_amount == 20400000 ? 20500000 : amountInNumbers(coverAmount),
        // policyTerm:
        // 	String(coverUpto).slice(0, 2) !== "99"
        // 		? String(coverUpto).slice(0, 2) - (dob ? getAge(dob) : 0)
        // 		: 99,
        // policyTerm:
        // 	parseInt(String(coverUpto)) !== 99
        // 		? parseInt(String(coverUpto)) - (dob ? getAge(dob) : 0)
        // 		: 99,
        policyTerm:
          coverUpto !== "Whole Life"
            ? parseInt(String(coverUpto)) - (dob ? getAge(dob) : 0)
            : 99,
        // coverUpto === "Whole Life"
        //   ? 99 - (dob ? getAge(dob) : 0)
        //   : parseInt(String(coverUpto)) - (dob ? getAge(dob) : 0),
        //			payOption: morePlans === "Survival Benefits" ? "Limited Pay" : option,

        payOption: option,

        limitedPayTerm:
          option === "Limited Pay"
            ? term === "Pay till 60"
              ? "P60"
              : term === "PT - 5 Years"
              ? "PT_5"
              : parseInt(term)
            : "",
        payfrequency: payPremium === "Half Yearly" ? "Half-Yearly" : payPremium,
        paytypeOption: coverUpto === "Whole Life" ? "WL" : "N",
        traceId: enquiryId,
      };

      if (morePlans === "Return of Premium")
        newData.morePlans = "return_of_premium";
      else if (morePlans === "Survival Benefits")
        newData.morePlans = "survival_benefits";
      else newData.morePlans = "";

      if (nomineeGets === "Lumpsum")
        newData = { ...newData, nomineeGets: "lumpsum" };
      if (nomineeGets === "Monthly")
        newData = {
          ...newData,
          nomineeGets: "monthly_income",
          monthlyIncome: getNumberfromString(coverAmountMonthly.value),
          nomineeIncomeTerm: parseInt(coverAmountMonthly.term),
          increasingMonthlyIncome:
            coverAmountMonthly.increaseIncome === "Please select"
              ? "No"
              : coverAmountMonthly.increaseIncome === "Yes"
              ? "Yes"
              : "No",
        };
      if (nomineeGets === "Lumpsum + Monthly Income")
        newData = {
          ...newData,
          nomineeGets: "lumpsum_monthly_income",
          nomineeCoverAmount: getNumberfromString(
            coverAmountLumpsumMonthly.coverAmount,
          ),
          lumpsumPercentage:
            coverAmountLumpsumMonthly.coverAmountPercent.replace("%", ""),
          monthlyIncome: coverAmountLumpsumMonthly.additionalIncome.amount
            .replace("₹", "")
            .replace(" ", "")
            .replace(",", ""),
          nomineeIncomeTerm: parseInt(
            coverAmountLumpsumMonthly.additionalIncome.period,
          ),
          lumpsumIncreasingMonthlyIncome:
            coverAmountLumpsumMonthly.increaseIncome === "Please select"
              ? "No"
              : coverAmountLumpsumMonthly.increaseIncome === "Yes"
              ? "Yes"
              : "No",
        };
      if (returnOfPremium && morePlans === "Choose your plans") {
        newData.requestType = "get_products";
        dispatch(setReturnOfPremium(false));
      } else newData.requestType = "filters";
      dispatch(updateFilters(newData));
    }

    dispatch(setCameFromRiders(false));
    initialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nomineeGets,
    coverAmountMonthly,
    coverAmountLumpsumMonthly,
    morePlans,
    coverAmount,
    coverUpto,
    option,
    term,
    payPremium,
  ]);
  useEffect(() => {
    dispatch(setStartAnnualIncome(startingAnnualIncome));
  }, [startingAnnualIncome]);
  const { customerDetails } = useSelector(({ greeting }) => greeting);

  if (Object.keys(customerDetails).length === 0)
    return <div>Loading filters...</div>;

  return (
    <div className="quote-filter">
      {coverAmountLumpsumMonthly.coverAmount && (
        <Monthly
          show={popup === "monthly"}
          onClose={() => dispatch(setPopup(""))}
        />
      )}
      {coverAmountLumpsumMonthly.coverAmount && (
        <LumpSum
          show={popup === "lumpsumMonthly"}
          onClose={() => dispatch(setPopup(""))}
        />
      )}
      <ModifyDetails
        startingAnnualIncome={startingAnnualIncome}
        setStartingAnnualIncome={setStartingAnnualIncome}
        show={popup === "modify"}
        onClose={() => dispatch(setPopup(""))}
      />
      <FilterMenuWrap>
        {nomineeGets === "Lumpsum" ? (
          <>
            <AmountFilter />
            <CoverUptoFilter />
            <NomineeGetsFilter />
          </>
        ) : (
          <>
            <CoverUptoFilter />
            <NomineeGetsFilter />
            <AmountFilter />
          </>
        )}
        <PaymentTermFilter />
        <MorePlansFilter />
      </FilterMenuWrap>
      <SubFilterWrap>
        {morePlans !== "Choose your plans" && (
          <MorePlans
            title={`Term plans for ${coverAmount}`}
            value={morePlans}
          />
        )}
        <SubFilterRight />
      </SubFilterWrap>
    </div>
  );
}

export default React.memo(QuoteFilter);
