export const educationOptions = [
  "Post Graduate",
  "Graduate",
  "12th Pass",
  "10th Pass",
  "Diploma",
];

export const educationOptionsHdfc = [
  "Post Graduate",
  "Graduate",
  "Diploma",
  "12th Pass",
  "10th Pass",
];

export const occupation = [
  "Salaried",
  "Self Employed",
  "Professional",
  "Agriculturist",
  "Student",
  "Others",
  "Retired",
  "Self Employed-CA",
  "Self Employed-Doctor",
  "Self Employed-Architect",
  "Self Employed-Businessman",
  "Self Employed-Consultant",
  "Self Employed-Lawyers",
  "Self Employed-Others",
];

export const occupationFemale = [
  "Salaried",
  "Self Employed",
  "Professional",
  "Agriculturist",
  "Student",
  "Others",
  "Housewife",
  "Retired",
  "Self Employed-CA",
  "Self Employed-Doctor",
  "Self Employed-Architect",
  "Self Employed-Businessman",
  "Self Employed-Consultant",
  "Self Employed-Lawyers",
  "Self Employed-Others",
];

export const occupationHdfcMale = ["Salaried", "Self Employed"];

export const occupationHdfcFemale = ["Salaried", "Self Employed", "Housewife"];

export const natureOfWork = ["Salaried", "Self employed"];
export const natureOfWorkICICI = [
  "CA",
  "Doctor",
  "Lawyer",
  "Architect",
  "Other",
];
export const country = [
  "Australia",
  "Bahrain",
  "Brunei",
  "China",
  "Fiji",
  "Hong Kong",
  "Maldives",
  "Mauritius",
  "New Zealand",
  "Oman",
  "Papua New Guinea",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Taiwan",
  "Thailand",
  "UAE",
  "USA",
  "Vietnam",
];
export const HDFC_COUNTRIES = [
  "Australia",
  "Austria",
  "Bangladesh",
  "Belogium",
  "Bhutan",
  "Brunei",
  "Bulgaria",
  "Canada",
  "China",
  "Czech Republic",
  "Denmark",
  "England",
  "Finland",
  "Germany",
  "Greece",
  "Greenland",
  "Holland",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Japan",
  "Kenya",
  "Korea (Republic of Korea)",
  "Luxembourg",
  "Mauritius",
  "Moldova",
  "Mongolia",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Northern Ireland",
  "Norway",
  "Oman",
  "Panama",
  "Paraguay",
  "Phillipines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Sardinia",
  "Scotland",
  "Sicily",
  "Singapore",
  "Slovak Republic",
  "Slovania",
  "Spain",
  "Suriname",
  "Sweden",
  "Switzerland",
  "United Arap Emirates",
  "United Kingdom",
  "United States of America",
  "Vatican City",
  "Wales",
];
export const COUNTRIES = {
  Australia: "Australia",
  Bahrain: "Bahrain",
  Brunei: "Brunei",
  China: "China",
  Fiji: "Fiji",
  France: "France",
  Germany: "Germany",
  Switzerland: "Switzerland",
  Austria: "Austria",
  Hungary: "Hungary",
  Canada: "Canada",
  "Hong Kong": "Hong Kong",
  Maldives: "Maldives",
  Mauritius: "Mauritius",
  "New Zealand": "New Zealand",
  Oman: "Oman",
  "Papua New Guinea": "Papua New Guinea",
  Qatar: "Qatar",
  "Saudi Arabia": "Saudi Arabia",
  Singapore: "Singapore",
  "South Korea": "South Korea",
  "Sri Lanka": "Sri Lanka",
  Taiwan: "Taiwan",
  Thailand: "Thailand",
  UAE: "UAE",
  USA: "USA",
  Vietnam: "Vietnam",
};

export const BAJAJCOUNTRIES = {
  Andorra: "Andorra",
  "Antigua & Barbuda": "Antigua & Barbuda",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Barbados: "Barbados",
  Belgium: "Belgium",
  Brazil: "Brazil",
  Brunei: "Brunei",
  Bulgaria: "Bulgaria",
  "Cabo Verde": "Cabo Verde",
  Canada: "Canada",
  Chile: "Chile",
  China: "China",
  Croatia: "Croatia",
  Curacao: "Curacao",
  Cyprus: "Cyprus",
  Czechia: "Czechia",
  Denmark: "Denmark",
  Dominica: "Dominica",
  Estonia: "Estonia",
  Finland: "Finland",
  France: "France",
  Germany: "Germany",
  Greece: "Greece",
  Grenada: "Grenada",
  "Holy See": "Holy See",
  "Hong Kong": "Hong Kong",
  Hungary: "Hungary",
  Iceland: "Iceland",
  Ireland: "Ireland",
  Italy: "Italy",
  Japan: "Japan",
  "Korea, South": "Korea, South",
  Latvia: "Latvia",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Macau: "Macau",
  Macedonia: "Macedonia",
  Maldives: "Maldives",
  Malta: "Malta",
  Mauritius: "Mauritius",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Montenegro: "Montenegro",
  Netherlands: "Netherlands",
  "New Zealand": "New Zealand",
  Norway: "Norway",
  Oman: "Oman",
  Panama: "Panama",
  Poland: "Poland",
  Portugal: "Portugal",
  Qatar: "Qatar",
  Romania: "Romania",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Maarten": "Saint Maarten",
  "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
  "San Marino": "San Marino",
  Seychelles: "Seychelles",
  Singapore: "Singapore",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  "South Korea": "South Korea",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  Sweden: "Sri Lanka",
  Switzerland: "Switzerland",
  Taiwan: "Taiwan",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States": "United States",
  Uruguay: "Uruguay",
  Vatican: "Vatican",
};

export const BANDHANCOUNTRIES = {
  Austria: "Austria",
  Germany: "Germany",
  Japan: "Japan",
  Oman: "Oman",
  Slovenia: "Slovenia",
  Australia: "Australia",
  Greece: "Greece",
  "Korea (Republic of Korea)": "Korea (Republic of Korea)",
  Poland: "Poland",
  Spain: "Spain",
  Belgium: "Belgium",
  Greenland: "Greenland",
  Kuwait: "Kuwait",
  Portugal: "Portugal",
  Sweden: "Sweden",
  Bulgaria: "Bulgaria",
  Holland: "Holland",
  Luxembourg: "Luxembourg",
  Romania: "Romania",
  Switzerland: "Switzerland",
  Canada: "Canada",
  "Hong Kong": "Hong Kong",
  Mauritius: "Mauritius",
  Sardinia: "Sardinia",
  "United Arab Emirates": "United Arab Emirates",
  "Czech Republic": "Czech Republic",
  Hungary: "Hungary",
  Netherlands: "Netherlands",
  Scotland: "Scotland",
  "United Kingdom": "United Kingdom",
  Denmark: "Denmark",
  Iceland: "Iceland",
  "New Zealand": "New Zealand",
  Sicily: "Sicily",
  "United States of America": "United States of America",
  England: "England",
  Ireland: "Ireland",
  "Northern Ireland": "Northern Ireland",
  Singapore: "Singapore",
  "Vatican City": "Vatican City",
  Finland: "Finland",
  France: "France",
  Italy: "Italy",
  Norway: "Norway",
  "Slovak Republic": "Slovak Republic",
  Wales: "Wales",
};

export const KOTAKCOUNTRIES = {
  Andorra: "Andorra",
  Anguilla: "Anguilla",
  "Antigua & Barbuda": "Antigua & Barbuda",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Barbados: "Barbados",
  Belgium: "Belgium",
  Belize: "Belize",
  Bermuda: "Bermuda",
  Brazil: "Brazil",
  "British Virgin Islands": "British Virgin Islands",
  Brunei: "Brunei",
  Bulgaria: "Bulgaria",
  Canada: "Canada",
  "Canary Islands": "Canary Islands",
  "Cape Verde": "Cape Verde",
  Chile: "Chile",
  China: "China",
  "Czech Republic": "Czech Republic",
  Denmark: "Denmark",
  England: "England",
  Estonia: "Estonia",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  Germany: "Germany",
  Gibraltar: "Gibraltar",
  Greece: "Greece",
  Greenland: "Greenland",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Holland: "Holland",
  "Hong Kong": "Hong Kong",
  "Hong Kong (Part of China)": "Hong Kong (Part of China)",
  Hungary: "Hungary",
  Iceland: "Iceland",
  Ireland: "Ireland",
  Italy: "Italy",
  Japan: "Japan",
  "Korea (Republic of Korea)": "Korea (Republic of Korea)",
  Kuwait: "Kuwait",
  Luxembourg: "Luxembourg",
  "Macau (part of China)": "Macau (part of China)",
  Madeira: "Madeira",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Malta: "Malta",
  "Martinique ( France)": "Martinique ( France)",
  Mauritius: "Mauritius",
  Mexico: "Mexico",
  "Netherland Antilles": "Netherland Antilles",
  Netherlands: "Netherlands",
  "New Caledonia": "New Caledonia",
  "New Zealand": "New Zealand",
  "Northern Ireland": "Northern Ireland",
  Norway: "Norway",
  Oman: "Oman",
  Poland: "Poland",
  Portugal: "Portugal",
  Qatar: "Qatar",
  "Reunion (France)": "Reunion (France)",
  Romania: "Romania",
  Sardinia: "Sardinia",
  Scotland: "Scotland",
  Sicily: "Sicily",
  Singapore: "Singapore",
  "Slovak Rep": "Slovak Rep",
  "Slovak Republic": "Slovak Republic",
  Slovenia: "Slovenia",
  "South Africa (Cape Town, Johannesburg, Pretoria, Bloemfontein, Durban)":
    "South Africa (Cape Town, Johannesburg, Pretoria, Bloemfontein, Durban)",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  "St Kitts and Nevis": "St Kitts and Nevis",
  "St Lucia": "St Lucia",
  "St Martin (France & Holland)": "St Martin (France & Holland)",
  "St Vincent and Grenadines": "St Vincent and Grenadines",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  Taiwan: "Taiwan",
  "Trinidad & Tobago": "Trinidad & Tobago",
  "Turks and Caicos": "Turks and Caicos",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States of America": "United States of America",
  USA: "USA",
  "Vatican City": "Vatican City",
  "Vietnam - Ho Chi Minh City": "Vietnam - Ho Chi Minh City",
  Wales: "Wales",
};
export const PNBCOUNTRIESARR = [
  "Acores",
  "Afghanistan",
  "Albania	",
  "Algeria	",
  "American Samoa	",
  "Andorra	",
  "Angola	",
  "Anguilla	",
  "Antigua &amp; Barbuda	",
  "Antilles	",
  "Argentina	",
  "Armenia	",
  "Aruba	",
  "Australia	",
  "Austria	",
  "Azerbaijan	",
  "Bahamas	",
  "Bahrain	",
  "Bangladesh	",
  "Barbados	",
  "Belarus	",
  "Belarus	",
  "Belgium	",
  "Belize	",
  "Benin	",
  "Bermuda	",
  "Bhutan	",
  "Bolivia	",
  "Bosnia-Herzegovina	",
  "Botswana	",
  "Brazil	",
  "British Virgin Islands	",
  "Brunei	",
  "Bulgaria	",
  "Burkina Faso	",
  "Burma (Myanmar)	",
  "Burma (Myanmar)	",
  "Burundi	",
  "Cambodia	",
  "Cameroon	",
  "Canada	",
  "Canary Islands	",
  "Cape Verde	",
  "Casamance	",
  "Cayman Islands	",
  "Central Africal Republic	",
  "Chad	",
  "Chechen Republic	",
  "Chile	",
  "China	",
  "Colombia	",
  "Comoros	",
  "Congo (Republic of Congo)	",
  "Cook Islands	",
  "Costa Rica	",
  "Cote dIvoire	",
  "Croatia	",
  "Cuba	",
  "Cyprus	",
  "Czech Republic	",
  "Democratic Republic of Congo	",
  "Denmark	",
  "Djibouti	",
  "Dominica	",
  "Dominican Republic	",
  "East Timor	",
  "Ecuador	",
  "Ecuador	",
  "Egypt	",
  "El Salvador	",
  "England	",
  "Equatorial Guinea	",
  "Eritrea	",
  "Estonia	",
  "Ethiopia	",
  "Faeroe Islands	",
  "Falkland Islands	",
  "Fiji	",
  "Finland	",
  "France	",
  "French Guiana	",
  "Gabon	",
  "Gambia	",
  "Gaza	",
  "Georgia	",
  "Germany	",
  "Ghana	",
  "Gibraltar	",
  "Gibraltar	",
  "Greece	",
  "Greenland	",
  "Grenada	",
  "Guadeloupe	",
  "Guatemala	",
  "Guernsey	",
  "Guinea	",
  "Guinea-Bissau	",
  "Guyana	",
  "Haiti	",
  "Hawaiian Island	",
  "Hebrides	",
  "Holland	",
  "Holland	",
  "Honduras	",
  "Hong Kong	",
  "Hungary	",
  "Iceland	",
  "India	",
  "Indonesia	",
  "Iran	",
  "Iraq	",
  "reland	",
  "Ireland	",
  "Israel	",
  "taly	",
  "Italy	",
  "Ivory Coast	",
  "Jamaica	",
  "Japan	",
  "Jersey	",
  "Jordan	",
  "Kazakhstan	",
  "Kenya	",
  "Kiribati	",
  "Kosovo	",
  "Kuwait	",
  "Kyrgyzstan Republic	",
  "Laos	",
  "Latvia	",
  "Lebanon	",
  "Lesotho	",
  "Liberia	",
  "Libya	",
  "Liechtenstein	",
  "Lithuania	",
  "Luxembourg	",
  "Macau	",
  "Macedonia	",
  "Madagascar	",
  "Madeira	",
  "Malawi	",
  "Malaysia	",
  "Maldives	",
  "Mali	",
  "Malta	",
  "Marshall Islands	",
  "Martinique	",
  "Mauritania	",
  "Mauritania	",
  "Mauritius	",
  "Mexico	",
  "Micronesia	",
  "Micronesia	",
  "Mindanao	",
  "Moldova	",
  "Monaco	",
  "Mongolia	",
  "Montenegro	",
  "Montserrat	",
  "Morocco	",
  "Mozambique	",
  "Namibia	",
  "Nauru	",
  "Negara Brunei Darussalam	",
  "Nepal	",
  "Netherlands	",
  "Netherlands Antilles	",
  "New Caledonia &amp; Dependencies	",
  "New Zealand	",
  "Nicaragua	",
  "Niger	",
  "Nigeria	",
  "Niue	",
  "North Korea	",
  "Northern Ireland	",
  "Northern Mariana Islands	",
  "Norway	",
  "Oman	",
  "Orkney Islands	",
  "Pakistan	",
  "Palau	",
  "Panama	",
  "Papua New Guinea	",
  "Paraguay	",
  "Peru	",
  "Philippines	",
  "Philippines	",
  "Pitcairn Islands	",
  "Poland	",
  "Polynesia	",
  "Porto Santo	",
  "Portugal	",
  "Portugal	",
  "Puerto Rico	",
  "Qatar	",
  "Reunion	",
  "Romania	",
  "Russia	",
  "Rwanda	",
  "Saint Helena	",
  "Saint Kitts &amp; Nevis	",
  "Saint Lucia	",
  "Saint Vincent &amp; Grenedines	",
  "Samoa Independent State of Samoa)	",
  "San Marino	",
  "Sao Tome &amp; Principe	",
  "Sardinia	",
  "Saudi Arabia	",
  "Scotland	",
  "Senegal	",
  "Serbia	",
  "Seychelles	",
  "Shetland Islands	",
  "Sicily	",
  "Sierra Leone	",
  "Singapore	",
  "Slovak Republic	",
  "Slovak Republic	",
  "Slovenia	",
  "Soloman Islands	",
  "Somalia	",
  "South Africa	",
  "South Georgia &amp; S Sandwich Islands	",
  "South Koera	",
  "Spain	",
  "Sri Lanka	",
  "Sudan	",
  "Suriname	",
  "Suriname	",
  "Swaziland	",
  "Sweden	",
  "Switzerland	",
  "Syria	",
  "Tahiti (Bora Bora)	",
  "Taiwan	",
  "Tajikistan	",
  "Tanzania	",
  "Thailand	",
  "Tibet	",
  "Timor-Leste	",
  "Togo	",
  "Tonga	",
  "Trinidad &amp; tobago	",
  "Tunisia	",
  "Turkey	",
  "Turkmenistan	",
  "Turks &amp; Caicos Islands	",
  "Tuvalu	",
  "Uganda	",
  "UK	",
  "Ukraine	",
  "United Arab Emirates	",
  "United States of America	",
  "Uruguay	",
  "Uzbekistan	",
  "Vanuatu	",
  "Vatican City	",
  "Venezuela	",
  "Vietnam	",
  "Virgin Islands	",
  "Wales	",
  "Wallis &amp; Futuna Islands	",
  "West Babk &amp; Gaza	",
  "Western Sahara	",
  "Western Samoa	",
  "Yemen	",
  "Zambia",
  "Zimbabwe",
];

export const educationOptionsKotak = [
  "Post Graduate",
  "Graduate",
  "12th Pass",
  "10th Pass ",
];

export const occupationKotak = [
  "Salaried",
  "Self Employed",
  "Housewife",
  "Student",
  "Minor",
];

export const educationMax = [
  "Post Graduate",
  "Graduate",
  "12th Pass",
  "10th Pass",
];

export const MAXLIFECOUNTRIES = {
  "Hong Kong": "Hong Kong",
  Singapore: "Singapore",
  Switzerland: "Switzerland",
  Australia: "Australia",
  "New Zealand": "New Zealand",
  Canada: "Canada",
  Bahrain: "Bahrain",
  Kuwait: "Kuwait",
  Oman: "Oman",
  Qatar: "Qatar",
  "Saudi Arabia": "Saudi Arabia",
  "United Arab Emirates": "United Arab Emirates",
  "Sri Lanka": "Sri Lanka",
  Maldives: "Maldives",
  Fiji: "Fiji",
  Brunei: "Brunei",
  Taiwan: "Taiwan",
  Mauritius: "Mauritius",
  "South Korea": "South Korea",
  China: "China",
  Germany: "Germany",
  France: "France",
  Austria: "Austria",
  Hungary: "Hungary",
  USA: "USA",
  "United Kingdom": "United Kingdom",
};

export const occupationMax = ["Salaried"];
export const occupationMaxFemale = ["Salaried"];
export const occupationMaxNo = ["Self Employed", "Professional", "Others"];
export const occupationMaxNoFemale = [
  "Self Employed",
  "Professional",
  "Housewife",
  "Others",
];
export const occupationBajajMale = [
  "Salaried (Govt.)",
  "Salaried (Non Govt.)",
  "Business",
  "Professional",

  "Retired",
  "Agriculture",
  "Unemployed",
  "Student",
];
export const occupationBajajFemale = [
  "Salaried (Govt.)",
  "Salaried (Non Govt.)",
  "Business",
  "Professional",
  "Housewife",
  "Retired",
  "Agriculture",
  "Unemployed",
  "Student",
];

export const educationBajaj = [
  "Post Graduate",
  "Graduate",
  "12th Pass",
  "10th Pass",
];

export const educationBandhan = [
  "Professional Degree",
  "Post Graduate",
  "Graduate",
  "Diploma",
  "12th Pass",
  "10th Pass",
];

export const occupationBandhanFemale = [
  "Salaried",
  "Self Employed (Business)",
  "Self Employed (Professional)",
  "Police",
  "Driver",
  "Agriculturist",
  "Armed Forces",
  "Woker/Labour",
  "Student",
  "Housewife",
];

export const occupationBandhanMale = [
  "Salaried",
  "Self Employed (Business)",
  "Self Employed (Professional)",
  "Police",
  "Driver",
  "Agriculturist",
  "Armed Forces",
  "Woker/Labour",
  "Student",
];

export const occupationTataMale = [
  "Salaried",
  "Self Employed",
  "Business Owner",
  "Retired",
  "Agriculturist",
  "Others",
];

export const occupationTataFemale = [
  "Salaried",
  "Self Employed",
  "Business Owner",
  "Housewife",
  "Retired",
  "Agriculturist",
  "Others",
];

export const educationTata = [
  "Post Graduate",
  "Graduate",
  "Diploma",
  "Vocational / ITI",
  "12th Pass",
  "10th Pass",
];

export const educationPNB = [
  "Post Graduate",
  "Graduate",
  "Diploma",
  "12th Pass",
  "10th Pass",
];

export const occupationPNBMale = [
  "Business",
  "Professional",
  "Self Employed",
  "Service - Public Sector",
  "Service - Private Sector",
  "Service - Government Sector",
  "Service - Others",
  "Others",
  "Student",
];

export const occupationPNBFemale = [
  "Business",
  "Housewife",
  "Professional",
  "Self Employed",
  "Service - Public Sector",
  "Service - Private Sector",
  "Service - Government Sector",
  "Service - Others",
  "Others",
  "Student",
];

// export const educationBajaj = [
//   "Masters & Above",
//   "Graduation",
//   "HSC",
//   "Matriculation",
//   "Non Matriculation",
// ];

// export const occupationBajaj = [
//   "Salaried (Govt.)",
//   "Salaried (Non Govt.)",
//   "Business",
//   "Professional",
//   "Housewife",
//   "Retired",
//   "Agriculture",
//   "Unemployed",
//   "Student",
// ];
export const USAStates = {
  Alabama: "Alabama",
  Alaska: "Alaska",
  "American Samoa": "American Samoa",
  Arizona: "Arizona",
  Arkansas: "Arkansas",
  California: "California",
  Colorado: "Colorado",
  Connecticut: "Connecticut",
  Delaware: "Delaware",
  "District of Columbia": "District of Columbia",
  "Federated States of Micronesia": "Federated States of Micronesia",
  Florida: "Florida",
  Georgia: "Georgia",
  Guam: "Guam",
  Hawaii: "Hawaii",
  Idaho: "Idaho",
  Illinois: "Illinois",
  Indiana: "Indiana",
  Iowa: "Iowa",
  Kansas: "Kansas",
  Kentucky: "Kentucky",
  Louisiana: "Louisiana",
  Maine: "Maine",
  "Marshall Islands": "Marshall Islands",
  Maryland: "Maryland",
  Massachusetts: "Massachusetts",
  Michigan: "Michigan",
  Minnesota: "Minnesota",
  Mississippi: "Mississippi",
  Missouri: "Missouri",
  Montana: "Montana",
  Nebraska: "Nebraska",
  Nevada: "Nevada",
  "New Hampshire": "New Hampshire",
  "New Jersey": "New Jersey",
  "New Mexico": "New Mexico",
  "New York": "New York",
  "North Carolina": "North Carolina",
  "North Dakota": "North Dakota",
  "Northern Mariana Islands": "Northern Mariana Islands",
  Ohio: "Ohio",
  Oklahoma: "Oklahoma",
  Oregon: "Oregon",
  Palau: "Palau",
  Pennsylvania: "Pennsylvania",
  "Puerto Rico": "Puerto Rico",
  "Rhode Island": "Rhode Island",
  "South Carolina": "South Carolina",
  "South Dakota": "South Dakota",
  Tennessee: "Tennessee",
  Texas: "Texas",
  Utah: "Utah",
  Vermont: "Vermont",
  "Virgin Island": "Virgin Island",
  Virginia: "Virginia",
  Washington: "Washington",
  "West Virginia": "West Virginia",
  Wisconsin: "Wisconsin",
  Wyoming: "Wyoming",
};

export const ALL_COUNTRIES = {
  Afghanistan: "Afghanistan",
  Albania: "Albania",
  Algeria: "Algeria",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  "Antigua and Barbuda": "Antigua and Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  Botswana: "Botswana",
  Brazil: "Brazil",
  "British Virgin Islands": "British Virgin Islands",
  Brunei: "Brunei",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  "Cape Verde": "Cape Verde",
  "Cayman Islands": "Cayman Islands",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  Colombia: "Colombia",
  Congo: "Congo",
  "Cook Islands": "Cook Islands",
  "Costa Rica": "Costa Rica",
  "Cote D Ivoire": "Cote D Ivoire",
  Croatia: "Croatia",
  "Cruise Ship": "Cruise Ship",
  Cuba: "Cuba",
  Cyprus: "Cyprus",
  "Czech Republic": "Czech Republic",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  "Dominican Republic": "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Equatorial Guinea",
  Estonia: "Estonia",
  Ethiopia: "Ethiopia",
  "Falkland Islands": "Falkland Islands",
  "Faroe Islands": "Faroe Islands",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  "French Polynesia": "French Polynesia",
  "French West Indies": "French West Indies",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Greece",
  Greenland: "Greenland",
  Grenada: "Grenada",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  "Guinea Bissau": "Guinea Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Hungary",
  Iceland: "Iceland",
  // India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Ireland",
  "Isle of Man": "Isle of Man",
  Israel: "Israel",
  Italy: "Italy",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jersey: "Jersey",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kuwait: "Kuwait",
  "Kyrgyz Republic": "Kyrgyz Republic",
  Laos: "Laos",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Macau: "Macau",
  Macedonia: "Macedonia",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mexico: "Mexico",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  Namibia: "Namibia",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  "Netherlands Antilles": "Netherlands Antilles",
  "New Caledonia": "New Caledonia",
  "New Zealand": "New Zealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palestine: "Palestine",
  Panama: "Panama",
  "Papua New Guinea": "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Poland: "Poland",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Qatar: "Qatar",
  Reunion: "Reunion",
  Romania: "Romania",
  Russia: "Russia",
  Rwanda: "Rwanda",
  "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  Satellite: "Satellite",
  "Saudi Arabia": "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  "South Africa": "South Africa",
  "South Korea": "South Korea",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  "St Kitts and Nevis": "St Kitts and Nevis",
  "St Lucia": "St Lucia",
  "St Vincent": "St Vincent",
  "St. Lucia": "St. Lucia",
  Sudan: "Sudan",
  Suriname: "Suriname",
  Swaziland: "Swaziland",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  Syria: "Syria",
  Taiwan: "Taiwan",
  Tajikistan: "Tajikistan",
  Tanzania: "Tanzania",
  Thailand: "Thailand",
  "Timor L'Este": "Timor L'Este",
  Togo: "Togo",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad and Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turkey",
  Turkmenistan: "Turkmenistan",
  "Turks and Caicos": "Turks and Caicos",
  UAE: "UAE",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  "United Kingdom": "United Kingdom",
  Uruguay: "Uruguay",
  USA: "USA",
  Uzbekistan: "Uzbekistan",
  Venezuela: "Venezuela",
  Vietnam: "Vietnam",
  "Virgin Islands (US)": "Virgin Islands (US)",
  Wales: "Wales",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
};
