import { useState, useCallback } from "react";

const useValidateSelect = ({
  companyAlias,
  checkedNri,
  selectedCountry,
  selectedEducation,
  selectedNatureOfWork,
  selectedOccupation,
  selectedCity,
  vaccinationStatus,
}) => {
  const [errorEducation, setErrorEducation] = useState(false);
  const [errorOccupation, setErrorOccupation] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorNatureOfWork, setErrorNatureOfWork] = useState(false);
  const [errorSelectCity, setErrorSelectCity] = useState(false);
  const [errorVaccinationStatus, setErrorVaccinationStatus] = useState(false);
  const validateSelect = useCallback(() => {
    if (!selectedEducation || selectedEducation === "Highest Education") {
      setErrorEducation(true);
    } else {
      setErrorEducation(false);
    }
    if (!selectedCity || selectedCity === "Select City") {
      setErrorSelectCity(true);
    } else {
      setErrorSelectCity(false);
    }
    if (!selectedOccupation || selectedOccupation === "Occupation") {
      setErrorOccupation(true);
    } else {
      setErrorOccupation(false);
    }
    if (
      companyAlias === "bajaj_life" &&
      (!vaccinationStatus || selectedCity === "Please Select")
    ) {
      setErrorVaccinationStatus(true);
    } else {
      setErrorVaccinationStatus(false);
    }
    if (companyAlias === "icici_pru" && selectedOccupation === "Professional") {
      if (!selectedNatureOfWork || selectedNatureOfWork === "Nature of work") {
        setErrorNatureOfWork(true);
      } else {
        setErrorNatureOfWork(false);
      }
    } else setErrorNatureOfWork(false);
    if (
      (checkedNri && !selectedCountry) ||
      (checkedNri && selectedCountry === "Select Country")
    ) {
      setErrorCountry(true);
    }
    if (checkedNri && selectedCountry) {
      setErrorCountry(false);
    }
  }, [
    checkedNri,
    selectedCountry,
    selectedEducation,
    selectedNatureOfWork,
    selectedOccupation,
    vaccinationStatus,
  ]);

  return {
    validateSelect,
    errorEducation,
    errorOccupation,
    errorCountry,
    errorNatureOfWork,
    errorSelectCity,
    errorVaccinationStatus,
  };
};

export default useValidateSelect;
