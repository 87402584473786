import { createSlice } from "@reduxjs/toolkit";
import {
  checkpinCode,
  BiGenerate,
  sendEligibilityDetails,
  // appNoGenerate,
  // icmaster,
  updateQuotation,
  getRedirectionUrl,
  checkEligibility,
  getEligibilityGrid,
  checkICICIEligibility,
} from "./serviceApi";
import swal from "sweetalert";
import { setIsError } from "../QuotePage/quotePageSlice";
import { getAge } from "../../utils/inputUtils";
// import { getEducationBajaj, getOccupBajaj } from "../../utils/bajajCodeUtils";
// import { act } from "react-dom/test-utils";

const eligibilityCardSlice = createSlice({
  name: "eligibilityCard",
  initialState: {
    eligibilityDetails: false,
    regionDetails: false,
    response: {},
    status: false,
    biUrl: false,
    appNo: false,
    loader: false,
    rePayLoader: false,
    isNegative: false,
    isSalaried: "Y",
    agreeNriTerms: false,
    agreeNriCountryTerms: false,
    redirectionUrl: false,
    area: [],
    saveLoading: true,
    biLoading: false,
    eligibilityFlag: true,
    eligibilityMessage: false,
    iciciValidationData: {},
    biLoader: false,
    paymentStatusLoader: false,
    eligibilityGridResponse: {
      isLoading: false,
      isUninitialized: true,
      data: {},
    },
  },

  reducers: {
    saveDetail: (state, action) => {
      state.eligibilityDetails = action.payload;
    },
    setRepayLoader: (state, action) => {
      state.rePayLoader = action.payload;
    },
    clearLoader: (state, action) => {
      state.rePayLoader = false;
    },
    createRegionData: (state, action) => {
      state.regionDetails = action.payload;
    },
    saveResponseData: (state, action) => {
      state.response = action.payload;
    },
    saveBiData: (state, action) => {
      state.biUrl = action.payload;
    },
    resetBiData: (state, action) => {
      state.biUrl = action.payload;
    },
    saveAppNo: (state, action) => {
      state.appNo = action.payload;
    },
    resetAppNo: (state, action) => {
      state.appNo = action.payload;
    },
    initLoader: (state, action) => {
      state.loader = action.payload;
    },
    saveResponse: (state, action) => {
      state.status = action.payload;
    },
    setIsSalaried: (state, action) => {
      state.isSalaried = action.payload;
    },
    setAgreeNriTerms: (state, action) => {
      state.agreeNriTerms = action.payload;
    },
    setAgreeNriCountryTerms: (state, action) => {
      state.agreeNriCountryTerms = action.payload;
    },
    setRedirectionUrl: (state, action) => {
      state.redirectionUrl = action.payload;
    },
    setRegionArea: (state, action) => {
      state.area = action.payload;
    },
    initBiLoader: (state, action) => {
      state.biLoading = action.payload;
    },
    setBiLoader: (state, action) => {
      state.biLoader = action.payload;
    },
    initSaveLoader: (state, action) => {
      state.saveLoading = action.payload;
    },
    saveEligibility: (state, action) => {
      state.eligibilityFlag = action.payload;
    },
    saveEligibilityMessage: (state, action) => {
      state.eligibilityMessage = action.payload;
    },
    setEligibilityGrid: (state, action) => {
      state.eligibilityGridResponse = action.payload;
    },
    setIsNegative: (state, action) => {
      state.isNegative = action.payload;
    },
    initPaymentStatusLoader: (state, action) => {
      state.paymentStatusLoader = action.payload;
    },
    setIciciValidationData: (state, action) => {
      state.iciciValidationData = action.payload;
    },
  },
});

export const {
  saveDetail,
  createRegionData,
  saveResponseData,
  saveBiData,
  saveResponse,
  saveAppNo,
  resetAppNo,
  initLoader,
  resetBiData,
  setBiLoader,
  setIsSalaried,
  setAgreeNriTerms,
  setAgreeNriCountryTerms,
  setRedirectionUrl,
  setRegionArea,
  initBiLoader,
  initSaveLoader,
  saveEligibility,
  saveEligibilityMessage,
  clearLoader,
  setRepayLoader,
  setEligibilityGrid,
  setIsNegative,
  initPaymentStatusLoader,
  setIciciValidationData,
} = eligibilityCardSlice.actions;

export const saveEligibilityDetails = (data, next) => {
  const {
    firstName,
    lastName,
    dob,
    email,
    isHeartPatient,
    isNRI,
    mobileNo,
    pan,
    pinCode,
    selectedCountry,
    selectedEducation = "",
    selectedOccupation = "",
    selectedNatureOfWork,
    secondary_email,
    isAgreeTerm,
    selectedArea,
    selectedCity,
    state,
    enquiryId,
  } = data;

  const newData = {
    traceId: enquiryId || sessionStorage.getItem("traceId"),
    firstName: firstName,
    lastName: lastName,
    dob: data["dob"].split("/").reverse().join("-"),
    age: getAge(dob),
    email: email,
    heartPatient: isHeartPatient,
    nri: isNRI,
    mobileNo: mobileNo,
    panNo: pan,
    pincode: pinCode,
    nriCountry: selectedCountry ? selectedCountry : "",
    // termEducation:
    //   selectedQuote?.company_alias === "bajaj_life"
    //     ? getEducationBajaj(selectedEducation)
    //     : selectedEducation,
    // termOccupation:
    //   selectedQuote?.company_alias === "bajaj_life"
    //     ? getOccupBajaj(selectedOccupation)
    //     : selectedOccupation,
    termEducation: data.termEducation,
    termOccupation: data.termOccupation,
    natureOfWork: selectedNatureOfWork,
    secondaryEmailId: secondary_email,
    eligibleStatus: isAgreeTerm,
    area: selectedArea,
    city: selectedCity,
    state: state,
    annualIncome: data.annualIncome,
    termEducation_text: selectedEducation,
    termOccupation_text: selectedOccupation,
    requestType: "eligibility_details",
  };

  return async dispatch => {
    dispatch(initSaveLoader(true));
    try {
      const res = await sendEligibilityDetails(newData);
      console.log(res);
      if (res.success) {
        dispatch(saveResponse(res.success));
        next();
        dispatch(initLoader(false));
        dispatch(initSaveLoader(false));
      } else {
        swal("Something went wrong", {
          icon: "error",
        }).then(() => window.location.reload());
      }

      if (data) {
        data["dob"] = data["dob"].split("-").reverse().join("/");
        dispatch(saveDetail(data));
        // dispatch(saveResponseData(response));
        // setTimeout(() => {
        //   dispatch(generateAppNo({ traceId: sessionStorage.getItem("traceId") }));
        // }, 1000);
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(
        setIsError("Something went wrong while saving eligibility details"),
      );
    }
  };
};

export const getRegion = data => {
  return async dispatch => {
    try {
      const response = await checkpinCode(data);
      if (response.data) {
        if (
          response.data.pincode_type === "negative" &&
          data.companyAlias === "bajaj_life"
        ) {
          dispatch(setIsNegative(response.data));
        } else if (data.companyAlias === "max_life") {
          dispatch(setIsNegative(response.data));
        } else {
          dispatch(setIsNegative(false));
        }
        dispatch(createRegionData(response.data));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(setIsError("Something went wrong while getting region"));
    }
  };
};

export const getArea = data => {
  return async dispatch => {
    try {
      const response = await checkpinCode(data);
      if (response.data) {
        dispatch(setRegionArea(response.data.area));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(setIsError("Something went wrong while getting region"));
    }
  };
};

export const generationBI = (data, next) => {
  return async dispatch => {
    dispatch(initBiLoader(true));
    if (!data.traceId) throw new Error("traceId is missing in data object");
    try {
      const response = await BiGenerate(data);
      if (response.data) {
        dispatch(saveBiData(response.data));
        dispatch(saveAppNo(response.data.applicationNo));
        sessionStorage.setItem("appNo", response.data.applicationNo);
        next(response.data);
        await updateQuotation({
          biPdfUrl: response.data.biPdfUrl,
          applicationNo: response.data.applicationNo,
          requestType: "eligibility",
          IpruRedirectionUrl: response.data.IpruRedirectionUrl,
          // traceId: data?.traceId || sessionStorage.getItem("traceId"),
          traceId: data?.traceId,
        });
        dispatch(initBiLoader(false));
      } else dispatch(setIsError("Something went wrong while generating BI"));
    } catch (err) {
      dispatch(setIsError("Something went wrong while generating BI"));
      console.error(err);
    }
  };
};
export const redirect = data => {
  return async dispatch => {
    if (!data.traceId) throw new Error("traceId is missing in data object");
    try {
      const response = await getRedirectionUrl(data);
      if (response.data.message === "Server Error") {
        dispatch(setIsError("Error in redirection Url"));
        console.error(response.data.message);
      }
      if (response.data) {
        dispatch(initLoader(false));
        dispatch(setRedirectionUrl(response.data.redirectionUrl));
        await updateQuotation({
          requestType: "eligibility",
          // traceId: data?.traceId || sessionStorage.getItem("traceId"),
          traceId: data?.traceId,
        }).then(() => {
          dispatch(initLoader(false));
        });
      }
    } catch (error) {
      dispatch(setIsError("Error in redirection Url"));
      console.error(error);
    }
  };
};
export const getEligibility = data => {
  return async dispatch => {
    try {
      const response = await checkEligibility(data);
      if (response.data) {
        dispatch(saveEligibility(response.data.status));
        dispatch(saveEligibilityMessage(response.data.message));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(setIsError("Something went wrong while getting region"));
    }
  };
};

export const getICICIEligibilityValidation = data => {
  return async dispatch => {
    try {
      const response = await checkICICIEligibility(data);
      if (response.data) {
        dispatch(setIciciValidationData(response.data));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const handleEligibilityGrid = data => async dispatch => {
  try {
    dispatch(setEligibilityGrid({ isLoading: true, data: {} }));
    const res = await getEligibilityGrid(data);
    if (res.data) {
      const { education_list, eligibility_grid, occupation_list } = res.data;
      const parsedResponse = {
        education_list: JSON.parse(education_list),
        eligibility_grid: JSON.parse(eligibility_grid),
        occupation_list: JSON.parse(occupation_list),
      };

      dispatch(setEligibilityGrid({ isLoading: false, data: parsedResponse }));
    }
  } catch (error) {
    dispatch(setEligibilityGrid({ isLoading: false, data: {}, error }));
  }
};

export default eligibilityCardSlice.reducer;

export const selectEligibilityGrid = state =>
  state.eligibilityCard.eligibilityGridResponse;

export const selectIsSalaried = state => state.eligibilityCard.isSalaried;
