import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import "./documentUpload.scss";
import { CustomDropDown, Error } from "../../components";
import { Form, FormGroup } from "../../components/Form/Form";
import {
  checkDocType,
  checkFormat,
  checkSize,
  convert,
} from "../../utils/inputUtils";
import {
  ADDRESS_PROOF,
  AGE_PROOF,
  ID_PROOF,
  INCOME_PROOF,
  IT_PROOF,
  PHOTO_PROOF,
  OTHERS,
} from "./data";
import { setJourneyComplete, uploadDoc } from "./documentUpload.slice";
import { getAllData } from "../../api/getAllData";
import Loader from "../../components/Loader/Loader";
import LaxmiGreeting from "../../components/LaxmiGreeting/LaxmiGreeting";
import { useCustomLocation } from "../../custom-hooks";
import { useNavigation } from "../../custom-hooks/useNavigation";

export default function DocumentUpload() {
  useNavigation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idDetails } = useSelector(state => state.idForm);
  const [countIncome, setCountIncome] = useState(0);
  const { search, pathname } = useLocation();

  const [ageProof, setAgeProof] = useState(
    idDetails.ageProof || "Select Age Proof",
  );
  const [idProof, setIdProof] = useState(
    idDetails.idProof || "Select ID Proof",
  );
  const [addressProof, setAddressProof] = useState(
    idDetails.addressProof || "Select Address Proof",
  );
  const [incomeProof, setIncomeProof] = useState(
    idDetails.incomeProof || "Select Income Proof",
  );
  const [itProof, setItProof] = useState(
    idDetails.insRepo || "Select IT Proof",
  );
  const [photoProof, setPhotoProof] = useState(
    idDetails.photoProof || "Scanned Photograph",
  );
  const [othersProof, setOthersProof] = useState(
    idDetails.othersProof || "Select Other Proofs",
  );

  const [ageProofDoc, setAgeProofDoc] = useState(false);
  const [idProofDoc, setIdProofDoc] = useState(false);
  const [addressProofDoc, setAddressProofDoc] = useState(false);
  const [incomeProofDoc, setIncomeProofDoc] = useState(false);
  const [incomeProofDoc1, setIncomeProofDoc1] = useState(false);
  const [incomeProofDoc2, setIncomeProofDoc2] = useState(false);
  const [itProofDoc, setItProofDoc] = useState(false);
  const [photoProofDoc, setPhotoProofDoc] = useState(false);
  const [othersProofDoc, setOthersProofDoc] = useState(false);

  const [ageErr, setAgeErr] = useState(false);
  const [idErr, setIdErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [incomeErr, setIncomeErr] = useState(false);
  const [incomeErr1, setIncomeErr1] = useState(false);
  const [incomeErr2, setIncomeErr2] = useState(false);
  const [itErr, setItErr] = useState(false);
  const [photoErr, setPhotoErr] = useState(false);
  const [othersErr, setOthersErr] = useState(false);

  const [ageUploaded, setAgeUploaded] = useState(false);
  const [idUploaded, setIdUploaded] = useState(false);
  const [addressUploaded, setAddressUploaded] = useState(false);
  const [incomeUploaded, setIncomeUploaded] = useState(false);
  const [incomeUploaded1, setIncomeUploaded1] = useState(false);
  const [incomeUploaded2, setIncomeUploaded2] = useState(false);
  const [itUploaded, setItUploaded] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const [othersUploaded, setOthersUploaded] = useState(false);

  const Age = useRef("");
  const Id = useRef("");
  const Address = useRef("");
  const Income = useRef("");
  const Income1 = useRef("");
  const Income2 = useRef("");
  const It = useRef("");
  const Photo = useRef("");
  const Others = useRef("");

  const { eligibilityDetails, appNo } = useSelector(
    state => state.eligibilityCard,
  );
  const { enquiryId } = useCustomLocation();
  useEffect(() => {
    if (Object.keys(eligibilityDetails).length === 0)
      dispatch(getAllData(null, pathname, enquiryId));
  }, []);

  const { uploading } = useSelector(({ documentUpload }) => documentUpload);
  const { selectedQuote } = useSelector(state => state.quote);
  const { isError } = useSelector(state => state.quote);

  const handleSubmit = async (e, name) => {
    e.preventDefault();
    try {
      let newData;
      if (name === "Age") {
        if (ageUploaded) {
          setAgeUploaded(false);
          setAgeProofDoc(false);
          e.target.querySelector("#ageProofDoc").value = "";
        } else {
          newData = await convert(ageProofDoc);
          setAgeUploaded(true);
          let blob = new Blob([ageProofDoc], { type: ageProofDoc.type });
          Age.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: ageProofDoc.name.replace(
                "_1.",
                `_${AGE_PROOF.indexOf(ageProof) + 1}.`,
              ),
              fileType: "age_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "Id") {
        if (idUploaded) {
          setIdUploaded(false);
          setIdProofDoc(false);
          e.target.querySelector("#idProof").value = "";
        } else {
          newData = await convert(idProofDoc);
          setIdUploaded(true);
          let blob = new Blob([idProofDoc], { type: idProofDoc.type });
          Id.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: idProofDoc.name.replace(
                "_2.",
                `_${ID_PROOF.indexOf(idProof) + 1}.`,
              ),
              fileType: "id_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "Income") {
        if (incomeUploaded) {
          setIncomeUploaded(false);
          setIncomeProofDoc(false);
          e.target.querySelector("#incomeProof").value = "";
        } else {
          newData = await convert(incomeProofDoc);
          setIncomeUploaded(true);
          let blob = new Blob([incomeProofDoc], { type: incomeProofDoc.type });
          Income.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc.name.replace(
                "_4.",
                `_${INCOME_PROOF.indexOf(incomeProof) + 1}.`,
              ),
              fileType: "income_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }
      if (name === "Income1") {
        if (incomeUploaded1) {
          setIncomeUploaded1(false);
          setIncomeProofDoc1(false);
          e.target.querySelector("#incomeProof").value = "";
        } else {
          newData = await convert(incomeProofDoc1);
          setIncomeUploaded1(true);
          let blob = new Blob([incomeProofDoc1], {
            type: incomeProofDoc1.type,
          });
          Income1.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc1.name.replace(
                "_4.",
                `_${INCOME_PROOF.indexOf(incomeProof) + 1}1.`,
              ),
              fileType: "income_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }
      if (name === "Income2") {
        if (incomeUploaded2) {
          setIncomeUploaded2(false);
          setIncomeProofDoc2(false);
          e.target.querySelector("#incomeProof").value = "";
        } else {
          newData = await convert(incomeProofDoc2);
          setIncomeUploaded2(true);
          let blob = new Blob([incomeProofDoc2], {
            type: incomeProofDoc2.type,
          });
          Income2.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc2.name.replace(
                "_4.",
                `_${INCOME_PROOF.indexOf(incomeProof) + 1}2.`,
              ),
              fileType: "income_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "Address") {
        if (addressUploaded) {
          setAddressUploaded(false);
          setAddressProofDoc(false);
          e.target.querySelector("#addressProof").value = "";
        } else {
          newData = await convert(addressProofDoc);
          let blob = new Blob([addressProofDoc], {
            type: addressProofDoc.type,
          });
          Address.current.href = URL.createObjectURL(blob);
          setAddressUploaded(true);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: addressProofDoc.name.replace(
                "_3.",
                `_${ADDRESS_PROOF.indexOf(addressProof) + 1}.`,
              ),
              fileType: "address_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "It") {
        if (itUploaded) {
          setItUploaded(false);
          setItProofDoc(false);
          e.target.querySelector("#itProof").value = "";
        } else {
          newData = await convert(itProofDoc);
          let blob = new Blob([itProofDoc], {
            type: itProofDoc.type,
          });
          It.current.href = URL.createObjectURL(blob);
          setItUploaded(true);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: itProofDoc.name.replace(
                "_5.",
                `_${IT_PROOF.indexOf(itProof) + 1}.`,
              ),
              fileType: "it_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "Photo") {
        if (photoUploaded) {
          setPhotoUploaded(false);
          setPhotoProofDoc(false);
          e.target.querySelector("#photoProof").value = "";
        } else {
          newData = await convert(photoProofDoc);
          let blob = new Blob([photoProofDoc], {
            type: photoProofDoc.type,
          });
          Photo.current.href = URL.createObjectURL(blob);
          setPhotoUploaded(true);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: photoProofDoc.name.replace(
                "_6.",
                `_${PHOTO_PROOF.indexOf(photoProof) + 1}.`,
              ),
              fileType: "scanned_photo",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }

      if (name === "Others") {
        if (othersUploaded) {
          setOthersUploaded(false);
          setOthersProofDoc(false);
          e.target.querySelector("#othersProof").value = "";
        } else {
          newData = await convert(othersProofDoc);
          let blob = new Blob([othersProofDoc], {
            type: othersProofDoc.type,
          });
          Others.current.href = URL.createObjectURL(blob);
          setOthersUploaded(true);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: othersProofDoc.name.replace(
                "_7.",
                `_${OTHERS.indexOf(othersProof) + 1}.`,
              ),
              fileType: "others_proof",
              fileBytes: newData.split(",")[1],
            }),
          );
        }
      }
    } catch (e) {}
  };
  const check = (e, proof, num) => {
    if (checkSize(e) && checkDocType(e)) {
      if (num === 1) {
        setAgeProofDoc(checkFormat(e, proof, num, appNo));
        setAgeErr(false);
      }
      if (num === 2) {
        setIdProofDoc(checkFormat(e, proof, num, appNo));
        setIdErr(false);
      }
      if (num === 3) {
        setAddressProofDoc(checkFormat(e, proof, num, appNo));
        setAddressErr(false);
      }
      if (num === 4) {
        setIncomeProofDoc(checkFormat(e, proof, num, appNo));
        setIncomeErr(false);
      }
      if (num === 8) {
        setIncomeProofDoc1(checkFormat(e, proof, num, appNo));
        setIncomeErr1(false);
      }
      if (num === 9) {
        setIncomeProofDoc2(checkFormat(e, proof, num, appNo));
        setIncomeErr2(false);
      }
      if (num === 5) {
        setItProofDoc(checkFormat(e, proof, num, appNo));
        setItErr(false);
      }
      if (num === 6) {
        setPhotoProofDoc(checkFormat(e, proof, num, appNo));
        setPhotoErr(false);
      }
      if (num === 7) {
        setOthersProofDoc(checkFormat(e, proof, num, appNo));
        setOthersErr(false);
      }
    } else {
      if (num === 1) {
        setAgeProofDoc(checkFormat(false));
        setAgeErr("File type or size is incorrect");
        setAgeUploaded(false);
      }
      if (num === 2) {
        setIdProofDoc(checkFormat(false));
        setIdErr("File type or size is incorrect");
        setIdUploaded(false);
      }
      if (num === 3) {
        setAddressProofDoc(checkFormat(false));
        setAddressErr("File type or size is incorrect");
        setAddressUploaded(false);
      }
      if (num === 4) {
        setIncomeProofDoc(checkFormat(false));
        setIncomeErr("File type or size is incorrect");
        setIncomeUploaded(false);
      }
      if (num === 8) {
        setIncomeProofDoc1(checkFormat(false));
        setIncomeErr1("File type or size is incorrect");
        setIncomeUploaded1(false);
      }
      if (num === 9) {
        setIncomeProofDoc2(checkFormat(false));
        setIncomeErr2("File type or size is incorrect");
        setIncomeUploaded2(false);
      }
      if (num === 5) {
        setItProofDoc(checkFormat(false));
        setItErr("File type or size is incorrect");
        setItUploaded(false);
      }
      if (num === 6) {
        setPhotoProofDoc(checkFormat(false));
        setPhotoErr("File type or size is incorrect");
        setPhotoUploaded(false);
      }
      if (num === 7) {
        setOthersProofDoc(checkFormat(false));
        setOthersErr("File type or size is incorrect");
        setOthersUploaded(false);
      }
    }
  };

  if (isError) return <Error errorMessage={isError} />;
  else if (Object.keys(eligibilityDetails).length === 0) return <Loader />;

  return (
    <Page>
      <Center>
        <div className="application_no_document">
          {<span>Application Number: {appNo}</span>}
        </div>
        <div className="docGreeting">
          <LaxmiGreeting />
        </div>
        <div className="eligibility__header ">
          <div className="eligibility__greeting">
            <h2 className="heading__secondary doc_header">
              Almost done! Please upload documents.
            </h2>
          </div>
        </div>
        {/* <Title className="mobhead uppercase">Upload Documents : </Title> */}
        <h3 className="mobsubhead text-danger">
          Please upload JPEG, JPG, PDF, PNG, TIF, TIFF file types only. Size
          (upto 5MB)
        </h3>
        <Form onSubmit={e => handleSubmit(e, "Age")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className=" filterMenuSelectWrap selectIncome">
                <label className="title-label">Age Proof</label>
                <CustomDropDown
                  items={AGE_PROOF}
                  selectedItem={ageProof}
                  className="dropmob"
                  notAllowed="Select Age Proof"
                  name="ageProof"
                  id="ageProof"
                  onChange={e => {
                    setAgeProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="ageProofDoc"
                  id="ageProofDoc"
                  title="Age Proof"
                  disabled={ageProof === "Select Age Proof"}
                  onChange={e => {
                    check(e, "AgeProof", 1);
                  }}
                />
                <label htmlFor="ageProofDoc">Choose File</label>
              </div>
              {ageErr != false ? <p className="text-danger">{ageErr}</p> : ""}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {ageProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!ageProofDoc}
              className={
                !ageProofDoc || uploading.includes("age_proof")
                  ? `btn btn--disabled btn--md uppercase bold buttton btn-upload`
                  : `btn ${
                      ageUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton btn-upload`
              }
            >
              {uploading.includes("age_proof")
                ? "Uploading..."
                : ageUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                target="_blank"
                rel="noopener noreferrer"
                ref={Age}
                style={{
                  display:
                    ageUploaded && !uploading.includes("age_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>
        <Form onSubmit={e => handleSubmit(e, "Id")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">ID Proof</label>
                <CustomDropDown
                  sm
                  items={ID_PROOF}
                  className="dropmob"
                  selectedItem={idProof}
                  notAllowed="Select ID Proof"
                  onChange={e => {
                    setIdProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="idProof"
                  id="idProof"
                  title="Id Proof"
                  disabled={idProof === "Select ID Proof"}
                  onChange={e => {
                    check(e, "PhotoIdProof", 2);
                  }}
                />
                <label htmlFor="idProof">Choose File</label>
              </div>
              {idErr != false ? <p className="text-danger">{idErr}</p> : ""}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {idProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!idProofDoc}
              className={
                !idProofDoc || uploading.includes("id_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      idUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload`
              }
            >
              {uploading.includes("id_proof")
                ? "Uploading..."
                : idUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                target="_blank"
                rel="noopener noreferrer"
                ref={Id}
                style={{
                  display:
                    idUploaded && !uploading.includes("id_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>
        <Form onSubmit={e => handleSubmit(e, "Address")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Address Proof</label>
                <CustomDropDown
                  sm
                  items={ADDRESS_PROOF}
                  className="dropmob"
                  selectedItem={addressProof}
                  notAllowed="Select Address Proof"
                  onChange={e => {
                    setAddressProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="addreaaProof"
                  id="addressProof"
                  title="Address Proof"
                  disabled={addressProof === "Select Address Proof"}
                  onChange={e => {
                    check(e, "AddressProof", 3);
                  }}
                />
                <label htmlFor="addressProof">Choose File</label>
              </div>
              {addressErr != false ? (
                <p className="text-danger">{addressErr}</p>
              ) : (
                ""
              )}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {addressProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!addressProofDoc}
              className={
                !addressProofDoc || uploading.includes("address_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      addressUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload`
              }
            >
              {uploading.includes("address_proof")
                ? "Uploading..."
                : addressUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={Address}
                style={{
                  display:
                    addressUploaded && !uploading.includes("address_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>

        <Form onSubmit={e => handleSubmit(e, "Income")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Income Proof</label>
                <CustomDropDown
                  sm
                  items={INCOME_PROOF}
                  className="dropmob"
                  selectedItem={incomeProof}
                  notAllowed="Select Income Proof"
                  onChange={e => {
                    setIncomeProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="incomeProof"
                  id="incomeProof"
                  title="Income Proof"
                  disabled={incomeProof === "Select Income Proof"}
                  onChange={e => {
                    check(e, "IncomeProof", 4);
                  }}
                />
                <label htmlFor="incomeProof">Choose File</label>
              </div>
              {incomeErr != false ? (
                <p className="text-danger">{incomeErr}</p>
              ) : (
                ""
              )}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {incomeProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!incomeProofDoc}
              className={
                !incomeProofDoc || uploading.includes("income_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      incomeUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload `
              }
            >
              {uploading.includes("income_proof")
                ? "Uploading..."
                : incomeUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={Income}
                style={{
                  display:
                    incomeUploaded && !uploading.includes("income_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>
        {countIncome > 0 && (
          <Form
            onSubmit={e => handleSubmit(e, "Income1")}
            formCenter
            uploadFile
          >
            <FormGroup>
              <Margin>
                <div className="uploadIcon">
                  <input
                    type="file"
                    name="incomeProof"
                    id="incomeProof"
                    title="Income Proof"
                    disabled={!incomeProofDoc}
                    onChange={e => {
                      check(e, "IncomeProof", 8);
                    }}
                  />
                  <label htmlFor="incomeProof">Choose File</label>
                </div>
                {incomeErr1 != false ? (
                  <p className="text-danger">{incomeErr1}</p>
                ) : (
                  ""
                )}
              </Margin>
            </FormGroup>
            <FormGroup sm uploadFile>
              <button
                type="submit"
                disabled={!incomeProofDoc1}
                className={
                  !incomeProofDoc1 || uploading.includes("income_proof")
                    ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload btn-icici"
                    : `btn ${
                        incomeUploaded1 ? "btn--remove" : "btn--highlighted"
                      } btn--md uppercase bold buttton  btn-upload btn-icici`
                }
              >
                {uploading.includes("income_proof")
                  ? "Uploading..."
                  : incomeUploaded1
                  ? "Remove"
                  : "Upload"}
              </button>
              <Preview className="uppercase btn-extra">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  ref={Income1}
                  style={{
                    display:
                      incomeUploaded1 && !uploading.includes("income_proof")
                        ? "contents"
                        : "none",
                  }}
                >
                  Preview
                </a>
              </Preview>
            </FormGroup>
          </Form>
        )}
        {countIncome > 1 && (
          <Form
            onSubmit={e => handleSubmit(e, "Income2")}
            formCenter
            uploadFile
          >
            <FormGroup>
              <Margin>
                <div className="uploadIcon">
                  <input
                    type="file"
                    name="incomeProof"
                    id="incomeProof"
                    title="Income Proof"
                    disabled={!incomeProofDoc1}
                    onChange={e => {
                      check(e, "IncomeProof", 9);
                    }}
                  />
                  <label htmlFor="incomeProof">Choose File</label>
                </div>
                {incomeErr2 != false ? (
                  <p className="text-danger">{incomeErr2}</p>
                ) : (
                  ""
                )}
              </Margin>
            </FormGroup>
            <FormGroup sm uploadFile>
              <button
                type="submit"
                disabled={!incomeProofDoc2}
                className={
                  !incomeProofDoc2 || uploading.includes("income_proof")
                    ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload btn-icici"
                    : `btn ${
                        incomeUploaded2 ? "btn--remove" : "btn--highlighted"
                      } btn--md uppercase bold buttton  btn-upload btn-icici`
                }
              >
                {uploading.includes("income_proof")
                  ? "Uploading..."
                  : incomeUploaded2
                  ? "Remove"
                  : "Upload"}
              </button>
              <Preview className="uppercase btn-extra">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  ref={Income2}
                  style={{
                    display:
                      incomeUploaded2 && !uploading.includes("income_proof")
                        ? "contents"
                        : "none",
                  }}
                >
                  Preview
                </a>
              </Preview>
            </FormGroup>
          </Form>
        )}
        {countIncome < 2 && (
          <div className="additionalDocWrapper mar-left">
            <button
              className="uploadIcon additionalDoc"
              onClick={() => {
                if (countIncome < 2) {
                  setCountIncome(countIncome + 1);
                }
              }}
            >
              ADD ADDITIONAL DOCUMENT
            </button>
          </div>
        )}
        <Form onSubmit={e => handleSubmit(e, "It")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">IT Proof</label>
                <CustomDropDown
                  sm
                  items={IT_PROOF}
                  className="dropmob"
                  selectedItem={itProof}
                  notAllowed="Select IT Proof"
                  onChange={e => {
                    setItProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="itProof"
                  id="itProof"
                  title="It Proof"
                  disabled={itProof === "Select IT Proof"}
                  onChange={e => {
                    check(e, "PANorFORM60or61(Attested)", 5);
                  }}
                />
                <label htmlFor="itProof">Choose File</label>
              </div>
              {itErr != false ? <p className="text-danger">{itErr}</p> : ""}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name file-Name-Large">
              {" "}
              {itProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!itProofDoc}
              className={
                !itProofDoc || uploading.includes("it_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      itUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload`
              }
            >
              {uploading.includes("it_proof")
                ? "Uploading..."
                : itUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={It}
                style={{
                  display:
                    itUploaded && !uploading.includes("it_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>

        <Form onSubmit={e => handleSubmit(e, "Photo")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Scanned Photograph</label>
                <CustomDropDown
                  sm
                  items={PHOTO_PROOF}
                  className="dropmob"
                  selectedItem={photoProof}
                  notAllowed="Scanned Photograph"
                  onChange={e => {
                    setPhotoProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="photoProof"
                  id="photoProof"
                  title="Photo Proof"
                  disabled={photoProof === "Scanned Photograph"}
                  onChange={e => {
                    check(e, "ScannedPhotograph", 6);
                  }}
                />
                <label htmlFor="photoProof">Choose File</label>
              </div>
              {photoErr != false ? (
                <p className="text-danger">{photoErr}</p>
              ) : (
                ""
              )}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {photoProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!photoProofDoc}
              className={
                !photoProofDoc || uploading.includes("scanned_photo")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      photoUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload`
              }
            >
              {uploading.includes("scanned_photo")
                ? "Uploading..."
                : photoUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={Photo}
                style={{
                  display:
                    photoUploaded && !uploading.includes("scanned_photo")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
        </Form>

        <Form onSubmit={e => handleSubmit(e, "Others")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Others</label>
                <CustomDropDown
                  sm
                  items={OTHERS}
                  className="dropmob"
                  selectedItem={othersProof}
                  notAllowed="Select Other Proofs"
                  onChange={e => {
                    setOthersProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="othersProof"
                  id="othersProof"
                  title="Others Proof"
                  disabled={othersProof === "Select Other Proofs"}
                  onChange={e => {
                    check(
                      e,
                      othersProof === "Payout Mandate"
                        ? "PayoutMandate"
                        : "Others",
                      7,
                    );
                  }}
                />
                <label htmlFor="othersProof">Choose File</label>
              </div>
              {othersErr != false ? (
                <p className="text-danger">{othersErr}</p>
              ) : (
                ""
              )}
            </Margin>
          </FormGroup>
          <FormGroup sm uploadFile>
            <div className="file-name">
              {" "}
              {othersProofDoc.name || "No file Selected"}
            </div>
            <button
              type="submit"
              disabled={!othersProofDoc}
              className={
                !othersProofDoc || uploading.includes("others_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                  : `btn ${
                      othersUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  btn-upload`
              }
            >
              {uploading.includes("others_proof")
                ? "Uploading..."
                : othersUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={Others}
                style={{
                  display:
                    othersUploaded && !uploading.includes("others_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup>
          <div className="mobsubhead text-danger">
            <div>* All fields required.</div>
            <div>* File should not be password protected.</div>
          </div>
        </Form>

        <button
          disabled={
            !(
              ageUploaded &&
              idUploaded &&
              addressUploaded &&
              incomeUploaded &&
              photoUploaded &&
              itUploaded
            )
          }
          onClick={() => {
            dispatch(
              setJourneyComplete(
                {
                  traceId: enquiryId,
                  companyAlias: selectedQuote.company_alias,
                },
                history,
              ),
            );
          }}
          className={
            !(
              ageUploaded &&
              idUploaded &&
              addressUploaded &&
              incomeUploaded &&
              photoUploaded &&
              itUploaded
            )
              ? "btn btn--disabled btn--md uppercase bold buttton btn-doc-submit"
              : "btn btn--highlighted btn--md uppercase bold buttton btn-doc-submit"
          }
        >
          submit
        </button>
      </Center>
    </Page>
  );
}

const Page = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const Center = styled.div`
  position: relative;
  padding: 50px;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #e4e4e5;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  text-align: center;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0 10px;
    width: 100%;
  }
`;

const Margin = styled.div`
  margin: 5px 10px;
`;

const Preview = styled.span`
  position: relative;
  top: 62px;
  margin-left: 20px;
  font-size: small;
  font-weight: bold;
  a {
    color: #f2581b;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const Title = styled.div`
  display: flex;
  justify-self: flex-start;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
`;
const FileLabel = styled.label`
  display: inline-block;
`;
