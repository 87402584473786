import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";
import { yupResolver } from "@hookform/resolvers";
import { insuredOccupationSchema } from "../../../../utils/validationSchema";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import { companyName } from "../ProposerOccupationForm/companyName";
import { saveinsuredOccupationDetails } from "./insuredOccupationForm.slice";
import {
  convertNumberToWords,
  getEducationDetails,
  getOrganizationType,
  getProposerOccupation,
  noCopyAndPaste,
  numOnly,
} from "../../../../utils/inputUtils";
import "./insuredOccupationForm.scss";
import { ToggleTile } from "../../components";
import { CustomDropDown } from "../../../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
const InsuredOccupationForm = ({
  insFor = "self",
  position,
  setPosition,
  index,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);
  const { selectedOccupation } = useSelector(
    ({ eligibilityCard }) => eligibilityCard.eligibilityDetails,
  );

  const occupationList = [
    "Select Occupation",
    "Housewife",
    "Salaried",
    "Self Employed",
    "Professional",
    "Agriculturist",
    "Student",
    "Others",
    "Retired",
    "ICICI Group Employee",
    "Self Employed-CA",
    "Self Employed-Doctor",
    "Self Employed-Architect",
    "Self Employed-Businessman",
    "Self Employed-Consultant",
    "Self Employed-Lawyers",
    "Self Employed-Others",
  ];
  const indTypeList = [
    "Select Industry type",
    "Import/Export",
    "Jewellery",
    " Real Estate",
    "Scrap Dealing",
    "Shipping",
    "Stock Broking",
  ];
  // const notAllowed = ["Housewife", "Agriculturist", "Student", "Retired"];
  const [notAllowed, setNotAllowed] = useState([
    "Housewife",
    "Agriculturist",
    "Student",
    "Retired",
  ]);

  useEffect(() => {
    if (selectedOccupation === "Housewife") {
      setNotAllowed(["Agriculturist", "Student", "Retired"]);
    } else if (selectedOccupation === "Agriculturist") {
      setNotAllowed(["Housewife", "Student", "Retired"]);
    }
  }, [selectedOccupation]);
  const { insuredFtcaDetails } = useSelector(state => state.insuredFtcaForm);
  const { insuredOccupationDetails } = useSelector(
    state => state.insuredOccupationForm,
  );
  const { proposalOccupationDetails } = useSelector(
    state => state.proposalOccupationForm,
  );
  const [occupation, setOccupation] = useState(
    insuredOccupationDetails.insOccupation,
  );
  const [engadedIn, setEngadedIn] = useState(
    proposalOccupationDetails.engadedIn,
  );
  const [orgName, setOrgName] = useState();
  const [orgType, setOrgType] = useState();

  useEffect(() => {
    if (insFor === "self") {
      setOccupation(proposalOccupationDetails.occupation);
      setEngadedIn(proposalOccupationDetails.engagedIn);
      setOrgName(proposalOccupationDetails.orgName);
      setOrgType(proposalOccupationDetails.orgType);
    }
  }, [proposalOccupationDetails, insFor]);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    unregister,
    trigger,
    watch,
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      insEngagedIn: insuredOccupationDetails.engagedIn || false,
      insOccupation: insuredOccupationDetails.occupation || "Select",
    },
    resolver: yupResolver(insuredOccupationSchema),
  });
  const professionList = [
    "Select Profession",
    "CA",
    "Doctor",
    "Lawyer",
    "Other",
  ];
  const [annualIncomeWord, setAnnualIncomeWord] = useState();
  const annualIncomeRef = useRef();
  const attachRef = ref => {
    register(ref);
    annualIncomeRef.current = ref;
  };
  const getInWord = () => {
    setAnnualIncomeWord(convertNumberToWords(annualIncomeRef.current.value));
  };
  const orgNameList = ["Select Organisation Name", ...companyName, "Others"];
  const orgNameList1 = ["Select Organisation Name", "Others"];
  const orgTypeList = [
    "Select Organisation Type",
    "Govt",
    "Pvt Ltd",
    "Public Ltd",
    "Trust",
    "Society",
    "Proprietor",
    "Partnership",
    "Others",
  ];
  const educationList = [
    "Select Education",
    "Post Graduate",
    "Graduate",
    "12th Pass",
    "10th Pass",
    "Diploma",
    "Below 10th",
  ];
  const [annualIncomeError, setAnnualIncomeError] = useState(false);

  const annualIncomeWatch = watch("insAnnualIncome");

  const { annualIncome } = useSelector(
    ({ greeting }) => greeting.customerDetails,
  );
  useEffect(() => {
    if (
      Number(annualIncomeWatch) > Number(annualIncome) + 99999 ||
      (Number(annualIncomeWatch) < Number(annualIncome) &&
        selectedOccupation !== "Housewife")
    ) {
      setAnnualIncomeError(
        `Annual income should be in between ${ annualIncome } and ${ Number(annualIncome) + 99999
        }`,
      );
    } else {
      setAnnualIncomeError(false);
    }
  }, [annualIncomeWatch]);
  useEffect(() => {
    if (occupation === "Self Employed") {
      //setOrgName("Others");
      //	reset({ insOrgName: "Others" });
    }
    if (occupation === "Self Employed-Others") {
      setOrgType("Others");
      setOrgName("Others");
      reset({
        insOccupation: "Self Employed-Others",
        insOrgNameDesc: "Others",
        insOrgTypeDesc: "Others",
      });
    } else if (
      insFor !== "self" &&
      !(
        occupation === "Salaried" ||
        occupation === "Self Employed" ||
        occupation === "Others"
      )
    ) {
      unregister(["orgName", "orgType", "engagedIn"]);
      setEngadedIn(false);
    }
    if (!engadedIn) {
      unregister("insIndType");
    }
  }, [occupation, reset, unregister, engadedIn]);
  useEffect(() => {
    register({ name: "insOccupation" });
    register({ name: "insEducation" });
    if (occupation === "Self Employed-Others") {
      register({ name: "insOrgName" });
      register({ name: "insOrgType" });
    }
    if (
      occupation === "Salaried" ||
      occupation === "Self Employed" ||
      occupation === "Others"
    ) {
      register({ name: "insEngagedIn" });
      register({ name: "insOrgName" });
      register({ name: "insOrgType" });
    }
    if (occupation === "Professional") register({ name: "insProfession" });
    if (engadedIn === true) register({ name: "insIndType" });
  }, [register, occupation, engadedIn]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    const sendData = {
      occupation: getProposerOccupation(data.insOccupation),
      education: getEducationDetails(data.insEducation),
      organizationName: data.insOrgName,
      organizationType: getOrganizationType(data.insOrgType),
      organizationNameDesc: data.insOrgNameDesc,
      income: data.insAnnualIncome,
      industryType: data.insIndType,
      // industryDesc: insFor === "self" ? data.indDesc : data.insIndDesc,
      industryDesc: data.insIndDesc,
      industryQuestion: data.insEngagedIn || engadedIn ? "Yes" : "No",
      proposalJourneyStatus: iciciProposalForms.insuredOccupationDetails,
    };
    saveProposalForm(sendData, () => {
      dispatch(saveinsuredOccupationDetails(data));
      setEdit(false);
      dispatch(setPosition(position + 1));
    });
  };

  const onInvalid = data => {
    console.log({ invalidData: data });
  };

  useEffect(() => {
    if (insuredOccupationDetails) {
      if (position === index) {
        setEdit(true);
      }
      if (position > index) {
        setEdit(false);
      }
    }
  }, [position]);
  useEffect(() => {
    if (insFor === "self")
      reset({
        insOccupation: proposalOccupationDetails.occupation,
        insEngagedIn: proposalOccupationDetails.engagedIn,
        insProfession: proposalOccupationDetails.profession,
        insOrgName: proposalOccupationDetails.orgName,
        insOrgType: proposalOccupationDetails.orgType,
        insIndType: proposalOccupationDetails.indType,
        insIndDesc: proposalOccupationDetails.indDesc,
        insEducation: proposalOccupationDetails.education,
        insAnnualIncome: proposalOccupationDetails.annualIncome,
      });
  }, [reset, insFor, proposalOccupationDetails]);
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Insured Occupation Details</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  else
    return edit ? (
      <>
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Insured Occupation Details
          </CardTitle>
          <Form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, onInvalid)}
          >
            <FormGroup proposalCard eligibilityPage>
              {/* <div className="upgradeDropList smallCustomDropDownSpace">
                <div className="upgradeDropLabel">Insured Occupation</div>
                <div className="filterMenuSelectWrap selectIncome"> */}
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Insured Occupation"
                  propDropDown
                  items={occupationList}
                  selectedItem={
                    insFor === "self"
                      ? proposalOccupationDetails.occupation
                      : insuredOccupationDetails.insOccupation
                  }
                  currentSelected={occupation}
                  style={{ pointerEvents: insFor === "self" && "none" }}
                  onChange={e => {
                    if (notAllowed.some(item => item === e)) {
                      alert(`The policy is not applicable for ${ e }`);
                      setValue("insOccupation", occupationList[0]);
                      trigger();
                      setOccupation(occupationList[0]);
                      return;
                    }
                    setValue("insOccupation", e);
                    trigger();
                    setOccupation(e);
                  }}
                />
              </CustomDropDownFont>
              {/* </div>
              </div> */}
              {errors.insOccupation && (
                <p className="errorG">{errors.insOccupation.message}</p>
              )}
            </FormGroup>
            {occupation === "Others" && (
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  type="text"
                  id="insOccuDesc"
                  name="insOccuDesc"
                  error={errors.insOccuDesc && "danger"}
                  onKeyDown={noCopyAndPaste}
                  defaultValue={
                    insFor === "self"
                      ? proposalOccupationDetails.occuDesc
                      : insuredOccupationDetails.insOccuDes
                  }
                  placeholder=" "
                  ref={register}
                  readOnly={insFor === "self"}
                />
                <Label md htmlFor="insOccuDesc">
                  Insured Occupation Description
                </Label>
                {errors.insOccuDesc && (
                  <p className="errorG">{errors.insOccuDesc.message}</p>
                )}
              </FormGroup>
            )}
            {(occupation === "Salaried" ||
              occupation === "Self Employed" ||
              occupation === "Others") && (
                <FormGroup style={{ textAlign: "left" }} xlg formPage>
                  <div style={{ marginBottom: 20 }}>
                    Are you engaged in any of the mentioned industries ? (Export,
                    Jewellery, Real Estate, Scrap Dealing, Shipping, Stock Broking
                    & Agriculture)
                  </div>
                  <RadioComponent
                    value={
                      insFor === "self"
                        ? proposalOccupationDetails.engagedIn
                        : engadedIn
                    }
                    name="insEngagedIn"
                    disableRadio={insFor === "self" ? true : false}
                    className="toggle--sb"
                    // label="Are you engaged in any of the mentioned industries ?
                    //   (Export, Jewellery, Real Estate, Scrap Dealing, Shipping,
                    //   Stock Broking & Agriculture)"
                    updateChecked={value => {
                      setValue("insEngagedIn", value);
                      setEngadedIn(value);
                      trigger();
                    }}
                  />
                </FormGroup>
              )}
            {engadedIn && (
              <>
                <FormGroup proposalCard eligibilityPage>
                  {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Industry Type</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      dropDownTitle="Industry Type"
                      propDropDown
                      items={indTypeList}
                      selectedItem={
                        insFor === "self"
                          ? proposalOccupationDetails.indType
                          : insuredOccupationDetails.insIndType
                      }
                      disabled={
                        proposalOccupationDetails.indType ? true : false
                      }
                      style={{ pointerEvents: insFor === "self" && "none" }}
                      onChange={e => {
                        setValue("insIndType", e);
                        trigger();
                      }}
                    />
                  </CustomDropDownFont>
                  {/* </div>
                  </div> */}
                  {errors.insIndType && (
                    <p className="errorG">{errors.insIndType.message}</p>
                  )}
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  <TextInputElg
                    proposal
                    type="text"
                    id="insIndDesc"
                    name="insIndDesc"
                    error={errors.insIndDesc && "danger"}
                    defaultValue={
                      insFor === "self"
                        ? proposalOccupationDetails.indDesc
                        : insuredOccupationDetails.insIndDesc
                    }
                    readOnly={proposalOccupationDetails.indDesc ? true : false}
                    onKeyDown={noCopyAndPaste}
                    placeholder=" "
                    ref={register}
                  />
                  <Label md htmlFor="insIndDesc">
                    Industry Description
                  </Label>
                  {errors.insIndDesc && (
                    <p className="errorG">{errors.insIndDesc.message}</p>
                  )}
                </FormGroup>
              </>
            )}
            {(occupation === "Salaried" ||
              occupation === "Self Employed" ||
              occupation === "Others" ||
              occupation === "Self Employed-Others") && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Organisation Name</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Organisation Name"
                        propDropDown
                        items={
                          occupation === "Self Employed"
                            ? orgNameList1
                            : orgNameList
                        }
                        searchable
                        style={{
                          pointerEvents:
                            (occupation === "Self Employed-Others" ||
                              insFor === "self") &&
                            "none",
                        }}
                        selectedItem={
                          insFor === "self"
                            ? proposalOccupationDetails.orgName
                            : insuredOccupationDetails.insOrgName
                        }
                        currentSelected={orgName}
                        onChange={e => {
                          setValue("insOrgName", e);
                          setOrgName(e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                  </div> */}
                    {errors.insOrgName && (
                      <p className="errorG">{errors.insOrgName.message}</p>
                    )}
                  </FormGroup>
                  {orgName === "Others" && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="insOrgNameDesc"
                        name="insOrgNameDesc"
                        error={errors.orgNameDesc && "danger"}
                        readOnly={
                          occupation === "Self Employed-Others" ||
                          insFor === "self"
                        }
                        defaultValue={
                          insFor === "self"
                            ? proposalOccupationDetails.orgNameDesc
                            : insuredOccupationDetails.insOrgNameDesc
                        }
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        ref={register}
                      />
                      <Label md htmlFor="insOrgNameDesc">
                        Organisation Name Description
                      </Label>
                      {errors.insOrgNameDesc && (
                        <p className="errorG">{errors.insOrgNameDesc.message}</p>
                      )}
                    </FormGroup>
                  )}
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Organisation Type</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Organisation Type"
                        propDropDown
                        items={orgTypeList}
                        style={{
                          pointerEvents:
                            (occupation === "Self Employed-Others" ||
                              insFor === "self") &&
                            "none",
                        }}
                        selectedItem={
                          insFor === "self"
                            ? proposalOccupationDetails.orgType
                            : insuredOccupationDetails.insOrgType
                        }
                        currentSelected={orgType}
                        onChange={e => {
                          setValue("insOrgType", e);
                          setOrgType(e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                  </div> */}
                    {errors.insOrgType && (
                      <p className="errorG">{errors.insOrgType.message}</p>
                    )}
                  </FormGroup>
                  {orgType === "Others" && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="insOrgTypeDesc"
                        name="insOrgTypeDesc"
                        error={errors.insOrgTypeDesc && "danger"}
                        defaultValue={
                          insFor === "self"
                            ? proposalOccupationDetails.orgTypeDesc
                            : insuredOccupationDetails.insOrgTypeDesc
                        }
                        placeholder=" "
                        ref={register}
                        onKeyDown={noCopyAndPaste}
                        readOnly={
                          occupation === "Self Employed-Others" ||
                          insFor === "self"
                        }
                      />
                      <Label md htmlFor="orgTypeDesc">
                        Organisation Type Description
                      </Label>
                      {errors.insOrgTypeDesc && (
                        <p className="errorG">{errors.insOrgTypeDesc.message}</p>
                      )}
                    </FormGroup>
                  )}
                </>
              )}
            {occupation === "Professional" && (
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">Profession</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Profession"
                    propDropDown
                    items={professionList}
                    selectedItem={
                      insFor === "self"
                        ? proposalOccupationDetails.profession
                        : insuredOccupationDetails.insProfession
                    }
                    style={{ pointerEvents: insFor === "self" && "none" }}
                    onChange={e => {
                      setValue("insProfession", e);
                      trigger();
                    }}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
                {errors.insProfession && (
                  <p className="errorG">{errors.insProfession.message}</p>
                )}
              </FormGroup>
            )}
            <FormGroup proposalCard eligibilityPage>
              {/* <div className="upgradeDropList smallCustomDropDownSpace">
                <div className="upgradeDropLabel">Education</div>
                <div className="filterMenuSelectWrap selectIncome"> */}
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Education"
                  propDropDown
                  items={educationList}
                  selectedItem={
                    insFor === "self"
                      ? proposalOccupationDetails.education
                      : insuredOccupationDetails.insEducation
                  }
                  style={{ pointerEvents: insFor === "self" && "none" }}
                  onChange={e => {
                    setValue("insEducation", e);
                    trigger();
                  }}
                />
              </CustomDropDownFont>
              {/* </div>
              </div> */}
              {errors.insEducation && (
                <p className="errorG">{errors.insEducation.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                type="text"
                id="annIncome"
                name="insAnnualIncome"
                error={errors.insAnnualIncome && "danger"}
                defaultValue={
                  insFor === "self"
                    ? proposalOccupationDetails.annualIncome
                    : insuredOccupationDetails.insAnnualIncome
                }
                placeholder=" "
                ref={attachRef}
                onInput={numOnly}
                onChange={getInWord}
                onKeyDown={noCopyAndPaste}
                readOnly={insFor === "self"}
              />
              <Label md htmlFor="annIncome">
                Annual Income
              </Label>
              {annualIncomeWord && (
                <p
                  className="annualIncomeText"
                  style={{ whiteSpace: "pre-wrap" }}
                >{`${ annualIncomeWord } per annum`}</p>
              )}
              {annualIncomeError && (
                <p className="errorP annualncomeError">{annualIncomeError}</p>
              )}
              {errors.insAnnualIncome && (
                <p className="errorG">{errors.insAnnualIncome.message}</p>
              )}
            </FormGroup>
            <FormGroup xlg formPage propSubWrap>
              <button
                type="submit"
                className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit isuredOccupationButton"
              >
                PROCEED TO OTHER INSURANCE DETAILS
              </button>
            </FormGroup>
          </Form>
        </Card>
      </>
    ) : (
      insuredOccupationDetails && (
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Insured Occupation Details
            <span
              style={{
                float: "right",
                fontSize: "14px",
                color: "#107591",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setPosition(index));
                setEdit(true);
              }}
            >
              <img src={editIcon} alt="editIcon" />
              &nbsp;EDIT
            </span>
          </CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <FormGroup md eligibilityPage>
              <Label md editMode>
                Occupation
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {insFor === "self"
                  ? proposalOccupationDetails.occupation
                  : occupation}
              </span>
            </FormGroup>
            {insuredOccupationDetails?.insOccupation === "Professional" && (
              <FormGroup md eligibilityPage>
                <Label md editMode>
                  Profession
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredOccupationDetails.insProfession}
                </span>
              </FormGroup>
            )}
            {"insOccuDesc" in insuredOccupationDetails &&
              insuredOccupationDetails.insOccuDesc && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Occupation Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredOccupationDetails.insOccuDesc}
                  </span>
                </FormGroup>
              )}
            {"insEngagedIn" in insuredOccupationDetails && (
              <FormGroup md eligibilityPage>
                <Label md editMode>
                  Are you Engaged In any mentioned Industries?
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredOccupationDetails.insEngagedIn ? "YES" : "NO"}
                </span>
              </FormGroup>
            )}
            {"insIndType" in insuredOccupationDetails &&
              insuredOccupationDetails.insIndType && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Industry Type
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredOccupationDetails.insIndType}
                  </span>
                </FormGroup>
              )}
            {"insIndDesc" in insuredOccupationDetails &&
              insuredOccupationDetails.insIndDesc && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Industry Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insFor === "self"
                      ? proposalOccupationDetails.indDesc
                      : insuredOccupationDetails.insIndDesc}
                  </span>
                </FormGroup>
              )}
            {"insOrgName" in insuredOccupationDetails &&
              insuredOccupationDetails.insOrgName && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Organisation Name
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredOccupationDetails.insOrgName}
                  </span>
                </FormGroup>
              )}
            {"insOrgNameDesc" in insuredOccupationDetails &&
              insuredOccupationDetails.insOrgNameDesc && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Organisation Name Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredOccupationDetails.insOrgNameDesc}
                  </span>
                </FormGroup>
              )}
            {"insOrgType" in insuredOccupationDetails &&
              insuredOccupationDetails.insOrgType && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Organisation Type
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgType ||
                      insuredOccupationDetails.insOrgType}
                  </span>
                </FormGroup>
              )}
            {"insOrgTypeDesc" in insuredOccupationDetails &&
              insuredOccupationDetails.insOrgTypeDesc && (
                <FormGroup md eligibilityPage>
                  <Label md editMode>
                    Organisation Type Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgTypeDesc ||
                      insuredOccupationDetails.orgTypeDesc}
                  </span>
                </FormGroup>
              )}
            <FormGroup md eligibilityPage>
              <Label md editMode>
                Education
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {insFor === "self"
                  ? proposalOccupationDetails.education
                  : insuredOccupationDetails.insEducation}
              </span>
            </FormGroup>
            <FormGroup md eligibilityPage>
              <Label md editMode>
                Annual Income
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {insuredOccupationDetails.insAnnualIncome}
              </span>
            </FormGroup>
          </div>
        </Card>
      )
    );
};

export default InsuredOccupationForm;
