const HEIGHT_LIST = [];
const WEIGHT_LIST = [];
const CONSUMED_AS = [
  "Beer (Bottles)",
  "Hard Liquor (Pegs)",
  "Wine (Glasses)",
  "All of the above",
];

const TOBACCO_CONSUMED_AS = [
  "Pan",
  "Cigarettes",
  "Bidi",
  "Cigar",
  "Gutka",
  "All of the above",
];

const TOBACCO_QUANTITY_PER_DAY = ["<5 Units", "6-10 Units", ">10 Units"];

const QUANTITY_PER_DAY = ["1-2", "Greater than 2", "Occasional"];
for (let i = 1; i <= 7; i++)
  for (let j = 0; j <= 11; j++) HEIGHT_LIST.push(`${i} feet ${j} inches`);

for (let i = 2; i <= 140; i++) WEIGHT_LIST.push(`${i} kgs`);

HEIGHT_LIST.push("8 feet 0 inches");

export {
  HEIGHT_LIST,
  WEIGHT_LIST,
  CONSUMED_AS,
  QUANTITY_PER_DAY,
  TOBACCO_CONSUMED_AS,
  TOBACCO_QUANTITY_PER_DAY,
};
