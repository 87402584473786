import styled from "styled-components";
import { SubHeader } from "./Form1.style";

export const SubHeader2 = styled(SubHeader)`
  margin-top: 24px;
  margin-bottom: 12px;
  line-height: 20px;
  padding: 0 78px;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;
export const LightHeader2 = styled.div`
  font-family: "Inter-Regular";
  color: #707070;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  text-align: center;
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
    line-height: 11px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 10px;
    line-height: 14px;
    margin: 3px 0 0;
  }
`;
export const BoldHeader = styled.div`
  font-family: "Inter-Medium";
  color: #333333;
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 8px 0;
  padding: ${props => (props.padding ? "0 106px" : "0 30px")};
  text-align: center;
  line-height: 1;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 24px;
    line-height: 24px;
  }
  @media only screen and (max-width: 767px) {
    font-family: "Inter-SemiBold";
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 0;
  }
`;
export const LightHeader = styled.div`
  font-family: "Inter-Regular";
  color: #333;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 6px 0;
  padding: 0 30px;
  text-align: center;
  line-height: 28px;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 551px;
    margin: 0 auto;
    padding: 0 0 8px 0;
  }
  @media only screen and (max-width: 767px) {
    font-size: 13px;
    line-height: 20px;
    margin: -8px 0 0 0;
  }
`;
export const Row = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  &::before,
  &::after {
    display: table;
    content: " ";
  }
`;
export const SmokingWrap = styled.div`
  width: 262px;
  margin: 0 auto;
  padding-top: 32px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0 auto;
    padding: 16px 15px 0;
  }
`;
export const Radio = styled.input`
  display: none;
  &:checked + label {
    background-color: #107591;
    border: 1px solid #107591 !important;
    & svg,
    & span {
      fill: #fff;
      color: #fff;
    }
    & span:last-child {
      background-color: #ffffff;
      border: solid 2px #107591;
      &:after {
        display: block;
      }
    }
  }
`;
export const RadioButton = styled.label`
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 100;
  box-shadow: none;
  user-select: none;
  float: left;
  text-align: center;
  padding: 20px 0 50px 0 !important;
  width: 124px;
  height: 154px !important;
  border-radius: 4px;
  border: solid 1px #e3e4e8;
  background-color: rgba(246, 246, 246, 0.1);
  & svg {
    padding-bottom: 8px;
    margin: 0 auto;
    display: block;
    width: 40px;
    height: 56px;
    padding-top: ${props => props.no && "10px"};
  }
  &:not(:last-child) {
    margin-right: 12px;
    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: #107591;
    border: solid 2px #107591;
    color: #fff;
    & svg,
    & span {
      fill: #fff;
      color: #fff;
    }
    & span:last-child {
      background-color: #fbfbfb;
      border: solid 2px #107591;
    }
  }
  @media only screen and (max-width: 767px) {
    display: inline;
    width: 100%;
    height: 85px !important;
    min-height: 85px;
    margin-bottom: 12px;
    padding: 18px 12px 18px 20px !important;
    overflow: hidden;
    & svg {
      float: left;
      padding-bottom: 0;
      padding-top: 0;
      margin: 0;
      width: 46px;
      min-height: 46px;
      height: 46px;
    }
  }
`;
export const RadioText = styled.span`
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-family: "Inter-Regular";
  color: #333;
  @media only screen and (max-width: 767px) {
    float: left;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    padding-top: 0;
    width: calc(100% - 80px);
    margin: 0;
    text-align: left;
    padding-left: 15px;
    min-height: 48px;
    justify-content: left;
  }
`;
export const CheckMark = styled.span`
  height: 22px;
  width: 22px;
  top: auto;
  left: calc(50% - 11px);
  margin-top: 14.5px;
  border: solid 2px #e3e4e8;
  border-radius: 50%;
  bottom: 22px;
  display: inline-block;
  position: absolute;
  &::after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: none;
    background: #107591;

    content: "";
    position: absolute;
    @media only screen and (max-width: 767px) {
      top: 3px;
      left: 3px;
    }
  }
  @media only screen and (max-width: 767px) {
    top: 13px;
    width: 20px;
    height: 20px;
    float: left;
    margin-top: 14px !important;
    position: sticky;
  }
`;
export const Continue = styled.button`
  display: block;
  padding: 1px 6px 1px;
  position: absolute;
  right: 36px;
  top: 140px;
  cursor: pointer;
  background-color: #ffffff;
  font-family: "Inter-Medium";
  font-size: 16px;
  color: #f2581b;
  line-height: 20px;
  width: 124px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #f2581b;
  & div {
    width: 110px;
    margin: 0px 0 0 6px;
  }
  & span {
    float: left;
    margin: 0;
    text-transform: uppercase;
  }
  & svg {
    width: 17px;
    margin-top: -3px;
    height: 15px;
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    right: 54px;
    top: 146px;
    height: 31px;
    width: 109px;
    padding: 0;
    & div {
      width: 92px;
      margin: 1px 0 0 11px;
    }
    & span {
      float: left;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
    & svg {
      width: 13px;
      margin-top: -1px;
    }
  }
  @media only screen and (max-width: 767px) {
    top: 102px;
    right: 8px;
    border: none;
    width: 85px;
    height: 22px;
    background: none;
    font-size: 14px;
    & div {
      margin: 0;
      width: 78px;
    }
    & span {
      font-size: 14px;
      display: inline-block;
      text-transform: capitalize;
    }
    & svg {
      width: 14px;
      margin-top: -1px;
    }
  }
`;
