import { createSlice } from "@reduxjs/toolkit";
import {
  getRidersPremium,
  submitRiders,
  updateQuotation,
  criticalIllness,
  spouseChildPremium,
  getQuotesBajaj,
  seriousIllness,
} from "./serviceApi";
import { setIsError } from "../QuotePage/quotePageSlice";

const riderPageSlice = createSlice({
  name: "rider",
  initialState: {
    ridersPremium: {},
    selectedAddOns: [],
    response: {},
    additionalRiders: 0,
    isLoading: false,
    totalPremium: 0,
    criticalIllness: "",
    allDetails: "",
    riderGst: 0,
    addOns: {},
    updatedRiders: [],
    inbuiltCovers: [],
    spousePremium: {},
    spouseData: {},
    childPremium: {},
    childData: {},
    bajajQuoteData: [],
    spouseVariantFlag: false,
    childVariantFlag: false,
    premiumLoader: [],
  },
  reducers: {
    setRidersPremium: (state, action) => {
      state.ridersPremium = action.payload;
      state.isLoading = false;
    },
    setSelectedAddOns: (state, action) => {
      state.selectedAddOns = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
      state.isLoading = false;
    },
    setPremium: (state, action) => {
      state.additionalRiders = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalPremium: (state, action) => {
      state.totalPremium = action.payload;
    },
    setCriticalIllness: (state, action) => {
      state.criticalIllness = action.payload;
    },
    clearResponse: state => {
      state.response = {};
    },
    saveDetails: (state, action) => {
      state.allDetails = action.payload;
    },
    setRiderGst: (state, action) => {
      state.riderGst = action.payload;
    },
    setUpdatedRider: (state, action) => {
      state.updatedRiders = action.payload;
    },
    setInbuiltCovers: (state, action) => {
      state.inbuiltCovers = action.payload;
    },
    setSpousePremium: (state, action) => {
      state.spousePremium = action.payload;
      state.isLoading = false;
    },
    setSpouseData: (state, action) => {
      state.spouseData = action.payload;
    },
    setChildPremium: (state, action) => {
      state.childPremium = action.payload;
      state.isLoading = false;
    },
    setChildData: (state, action) => {
      state.childData = action.payload;
    },
    setBajajQuoteData: (state, action) => {
      state.bajajQuoteData = action.payload;
    },
    setChildVariant: (state, action) => {
      state.childVariantFlag = action.payload;
    },
    setSpouseVariant: (state, action) => {
      state.spouseVariantFlag = action.payload;
    },
    setPremiumLoader: (state, action) => {
      state.premiumLoader = action.payload;
    },
    initPremiumLoader: (state, { payload }) => {
      state.premiumLoader[`${payload}`] = true;
    },
    removePremiumLoader: (state, { payload }) => {
      state.premiumLoader[`${payload}`] = false;
    },
  },
});

export const {
  setRidersPremium,
  setSelectedAddOns,
  setResponse,
  setPremium,
  setRiderGst,
  setLoading,
  setTotalPremium,
  setCover,
  setCriticalIllness,
  clearResponse,
  saveDetails,
  setUpdatedRider,
  setInbuiltCovers,
  setSpousePremium,
  setSpouseData,
  setChildPremium,
  setChildData,
  setBajajQuoteData,
  setSpouseVariant,
  setChildVariant,
  initPremiumLoader,
  setPremiumLoader,
  removePremiumLoader,
} = riderPageSlice.actions;

export const RidersPremium = riders => async dispatch => {
  try {
    dispatch(initPremiumLoader(riders?.riderId));

    const { data } = await getRidersPremium(riders);
    dispatch(setRidersPremium(data));
    // dispatch(setPremiumLoader(false));
    dispatch(removePremiumLoader(riders?.riderId));
  } catch (error) {
    console.error(error);
    dispatch(setIsError("Something went wrong while getting riders premium"));
  }
};

export const riderSubmit =
  (riders, TotalPremiumAmount, isDiabetic) => async dispatch => {
    try {
      dispatch(setLoading(true));
      const { data } = await submitRiders({
        termInsuId: sessionStorage.getItem("termInsuId") || riders.termInsuId,
        traceId: riders.traceId,
        ridersDetails: riders.ridersDetails,
      });
      if (data.status) dispatch(setResponse(data));

      await updateQuotation({
        termInsuId: riders.termInsuId,
        traceId: riders.traceId,
        ridersDetails: riders.ridersDetails,
        requestType: "rider",
        TotalPremiumAmount,
        isDiabetic,
      });
    } catch (error) {
      console.error(error);
      dispatch(setIsError("Something went wrong while adding rider"));
    }
  };
export const updateSelected = data => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { ridersData } = getState().rider.ridersPremium;
    let riderData = "";
    if (ridersData) {
      riderData = ridersData.data;
    }
    const ridersDetails = data.ridersDetails.map(item => {
      return {
        riderId: item.riderId,
        riderShortname: item.rider_shortname,
        riderTerm: item.term,
        riderName: item.rider_name,
        riderDescription: item.rider_description,
        riderSumAssured: item.sumAssured,
        ridersPremiumAmount: item.premium,
        gst: item.gst,
        riderPpt: item.riderPpt,
      };
    });
    await updateQuotation({
      ...data,
      ridersDetails: JSON.stringify(ridersDetails),
      requestType: "rider_add_remove_to_plan",
      annualPremium: riderData.annualPremium,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log("here");
    console.error(error);
  }
};

export const updateSkip = data => async dispatch => {
  try {
    await updateQuotation({
      ...data,

      requestType: "rider",
    });
  } catch (error) {
    console.error(error);
  }
};
export const getCriticalIllness = riderId => async dispatch => {
  try {
    const { data } = await criticalIllness(riderId);
    dispatch(setCriticalIllness(data));
  } catch (err) {
    console.error(err);
    dispatch(
      setIsError("Something went wrong while getting critical illness details"),
    );
  }
};
export const getSeriousIllness = riderId => async dispatch => {
  try {
    const { data } = await seriousIllness(riderId);
    data.criticalIllnessData = data.seriousIllnessData;
    dispatch(setCriticalIllness(data));
  } catch (err) {
    console.error(err);
    dispatch(
      setIsError("Something went wrong while getting serious illness details"),
    );
  }
};
export const getSpousePremium = req => async dispatch => {
  try {
    const { data } = await spouseChildPremium(req);
    dispatch(setLoading(true));
    dispatch(setSpouseData(req));
    dispatch(setSpousePremium(data));
  } catch (err) {
    console.error(err);
  }
};
export const getChildPremium = req => async dispatch => {
  try {
    const { data } = await spouseChildPremium(req);
    dispatch(setLoading(true));
    dispatch(setChildData(req));
    dispatch(setChildPremium(data));
  } catch (err) {
    console.error(err);
  }
};

export const getQuotesRemove = traceId => async dispatch => {
  try {
    //	dispatch(setLoading(true));
    const r = await getQuotesBajaj({ traceId });
    const quote_data = r.data?.QuoteData?.quote_data;
    if (quote_data) {
      dispatch(setBajajQuoteData(quote_data));
    }
  } catch (error) {
    console.error({ error });
  }
};
export default riderPageSlice.reducer;

export const selectSelectedAddOns = state => state.rider.selectedAddOns;
