import React, { useState, useRef, useEffect } from "react";
import Popup from "../Popup";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { ChildSchema } from "../../../utils/validationSchema";
import {
  dateUtil,
  getAge,
  getNumberfromString,
} from "../../../utils/inputUtils";
//import { modifyDetailSchema } from "../../utils/validationSchema";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../Card/Card";
import CardTitle from "../../Card/CardTitle/CardTitle";
import { useCustomLocation } from "../../../custom-hooks";
import "./childPopup.scss";
import {
  getChildPremium,
  setRidersPremium,
} from "../../../modules/RiderPage/RiderPage.slice";
import {
  AgeWrapper,
  Form,
  FormGroup,
  TextInputElg,
  Label,
  CustomDropDownFont,
} from "../../../components/Form/Form";

import CustomDropDown from "../../CutomDropDown/CustomDropDown";

const ChildCard = ({
  id,
  childNo,
  show,
  onClose,
  selected,
  riderTerm,
  riderId,
  termInsuId,
  riderShortname,
  onClick,
  setPrice,
  setGst,
  setCoverAmount,
  additionalRiders,
  added,
  cover,
}) => {
  const { enquiryId } = useCustomLocation();
  const dispatch = useDispatch();
  const [age, setAge] = useState("");
  const [childNos, setChildNos] = useState(1);
  const boolean = ["Yes", "No"];
  const education = [
    "Nursery",
    "Junior KG",
    "Senior KG",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];
  const Gender = ["Male", "Female"];
  const { childPremium, childData } = useSelector(state => state.rider);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    trigger,
    formState,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ChildSchema),
  });
  const { isValid } = formState;
  const { customerDetails } = useSelector(state => state.greeting);
  const dateRef = useRef();
  const childForm = useRef();
  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
    dateRef.current = ref;
  };

  const handleDobChange = event => {
    setAge(dateUtil(event, dateRef.current, true, false, true));
  };
  useEffect(() => {
    register({ name: "childEducation" });
    setValue("childEducation", childData.childEducation || "Select");
  }, [register]);
  useEffect(() => {
    if (childPremium.totalPremium_CB) {
      setCoverAmount("2600000"); //test coverAmount
      setPrice(childPremium.totalPremium_CB);
      setGst(childPremium.totalPremiumGst_CB);
      dispatch(setRidersPremium({ child: true }));
    }
  }, [childPremium]);

  useEffect(() => {
    if (isValid && show) {
      childForm.current.click();
    }
  }, [
    isValid,
    childForm,
    show,
    watch("annualSchoolFee"),
    watch("childEducation"),
    watch("dob"),
  ]);
  const onSubmit = data => {
    dispatch(
      getChildPremium({
        dob: data.dob.split(" / ").reverse().join("-"),
        //	riderCoveramt: getNumberfromString(data.riderCoveramt),
        riderCoveramt: "2600000", //testCover
        //	riderTerm: Number(riderTerm),
        riderTerm: 10,
        schoolFee: data.annualSchoolFee,
        education: data.childEducation,
        riderId,
        traceId: enquiryId,
        termInsuId: Number(termInsuId),
        riderShortname,
        type: "Child Benefit",
      }),
    );
  };

  const content = (
    <div className="childCard">
      <Card proposal popup>
        <div className="childCardHeader">
          <CardTitle eligibilityTitle className="spouseTitile">
            {selected?.company_alias === "bajaj_life"
              ? "	Child Details (No. 1)"
              : "   Child Education Support Benefit"}
          </CardTitle>
        </div>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormGroup proposalCard eligibilityPage>
            <TextInputElg
              proposal
              ref={register}
              //		defaultValue={}
              type="text"
              id="dob"
              name="dob"
              dobSpace
              ref={attachRef}
              defaultValue={
                childData.dob
                  ? childData.dob.split("-").reverse().join(" / ")
                  : ""
              }
              placeholder=" "
              onInput={e => {}}
              onChange={handleDobChange}
            />
            <Label md htmlFor="dob" eligibilityPage proposalInput>
              Date of Birth (DD/MM/YYYY)
            </Label>
            {age && (
              <AgeWrapper className="form-builder-age-wrapper ageWrapProposal">
                {age} years
              </AgeWrapper>
            )}
            {errors.dob && <p className="errorG">{errors.dob.message}</p>}
          </FormGroup>

          {selected?.company_alias === "pnb_metlife" && (
            <>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  ref={register({ required: true })}
                  defaultValue={
                    childData.annualSchoolFee ? childData.annualSchoolFee : ""
                  }
                  type="text"
                  id="annualSchoolFee"
                  name="annualSchoolFee"
                  placeholder=" "
                  onInput={e => {}}
                />
                <Label
                  md
                  htmlFor="annualSchoolFee"
                  eligibilityPage
                  proposalInput
                >
                  Child Annual School Fees
                </Label>

                {errors.annualSchoolFee && (
                  <p className="errorG">{errors.annualSchoolFee.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Child Education"
                    selectedItem={childData.childEducation || "Select"}
                    propDropDown
                    onChange={value => {
                      setValue("childEducation", value);
                      trigger("childEducation");
                    }}
                    items={education}
                  />
                </CustomDropDownFont>
                {errors.childEducation && (
                  <p className="errorG">{errors.childEducation.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  ref={register({ required: true })}
                  //		defaultValue={}
                  type="text"
                  id="coverValue"
                  name="coverValue"
                  placeholder=" "
                  onInput={e => {}}
                  //			onChange={handleDobChange}
                />
                <Label md htmlFor="coverValue" eligibilityPage proposalInput>
                  Cover Value
                </Label>

                {errors.coverValue && (
                  <p className="errorG">{errors.coverValue.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  ref={register({ required: true })}
                  //		defaultValue={}
                  type="text"
                  id="additionalPrem"
                  name="additionalPrem"
                  placeholder=" "
                  onInput={e => {}}
                  //			onChange={handleDobChange}
                />
                <Label
                  md
                  htmlFor="additionalPrem"
                  eligibilityPage
                  proposalInput
                >
                  Additional Premium
                </Label>

                {errors.additionalPrem && (
                  <p className="errorG">{errors.additionalPrem.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  ref={register({ required: true })}
                  //		defaultValue={}
                  type="text"
                  id="policyTerm"
                  name="policyTerm"
                  placeholder=" "
                  onInput={e => {}}
                  //			onChange={handleDobChange}
                />
                <Label md htmlFor="policyTerm" eligibilityPage proposalInput>
                  Policy Term
                </Label>

                {errors.policyTerm && (
                  <p className="errorG">{errors.policyTerm.message}</p>
                )}
              </FormGroup>
            </>
          )}
          <button
            type="submit"
            ref={childForm}
            style={{ display: "none" }}
          ></button>
        </Form>
      </Card>
    </div>
  );

  return content;
};

export default ChildCard;

const ChildBtnRemove = styled.button`
  width: 107px;
  height: 25px;
  padding: 7px 10px 5px 10px;
  border-radius: 4px;
  border: solid 1px #969799;
  background-color: #fff;
`;
