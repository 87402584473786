import React from "react";
import styled from "styled-components";

const RadioComponent = ({
  register,
  name,
  value,
  updateValue,
  updateChecked,
  disableRadio,
  radioWrapStyle,
  radioStyle,
  invert,
}) => {
  return invert ? (
    <RadioContainer>
      <RadioWrap style={radioWrapStyle || {}}>
        <Radio
          id={`${name}_Yes`}
          name={name}
          value="Yes"
          checked={Boolean(value)}
          ref={register}
          onChange={e => {
            //		updateValue(e.target.value);
            updateChecked(e.target.checked && true);
          }}
          disabled={disableRadio}
        />
        <RadioLabel style={radioStyle || {}} htmlFor={`${name}_Yes`}>
          Yes
        </RadioLabel>
      </RadioWrap>
      <RadioWrap style={radioWrapStyle || {}}>
        <Radio
          id={`${name}_No`}
          value="No"
          name={name}
          checked={Boolean(!value)}
          ref={register}
          onChange={e => {
            //		updateValue && updateValue(e.target.value);
            updateChecked && updateChecked(e.target.checked && false);
          }}
          disabled={disableRadio}
        />
        <RadioLabel style={radioStyle || {}} htmlFor={`${name}_No`}>
          No
        </RadioLabel>
      </RadioWrap>
    </RadioContainer>
  ) : (
    <RadioContainer>
      <RadioWrap style={radioWrapStyle || {}}>
        <Radio
          id={`${name}_No`}
          value="No"
          name={name}
          checked={Boolean(!value)}
          ref={register}
          onChange={e => {
            //		updateValue && updateValue(e.target.value);
            updateChecked && updateChecked(e.target.checked && false);
          }}
          disabled={disableRadio}
        />
        <RadioLabel style={radioStyle || {}} htmlFor={`${name}_No`}>
          No
        </RadioLabel>
      </RadioWrap>
      <RadioWrap style={radioWrapStyle || {}}>
        <Radio
          id={`${name}_Yes`}
          name={name}
          value="Yes"
          checked={Boolean(value)}
          ref={register}
          onChange={e => {
            //		updateValue(e.target.value);
            updateChecked(e.target.checked && true);
          }}
          disabled={disableRadio}
        />
        <RadioLabel style={radioStyle || {}} htmlFor={`${name}_Yes`}>
          Yes
        </RadioLabel>
      </RadioWrap>
    </RadioContainer>
  );
};

export default RadioComponent;

const RadioContainer = styled.div`
  width: 320px;
  height: 54px;
  @media (max-width: 767px) {
    width: 300px;
  }
  @media (max-width: 340px) {
    width: 270px;
  }
`;
const RadioWrap = styled.div`
  width: 154px;
  &:not(:last-child) {
    margin-right: 12px;
  }
  display: inline-block;
  @media (max-width: 767px) {
    width: 130px;
  }
  @media (max-width: 340px) {
    width: 110px;
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  display: none;
  &:checked + label {
    background-color: #107591;
    color: #fff;
    font-family: "Inter-Medium";
    font-weight: bold;
  }
`;

const RadioLabel = styled.label`
  display: block;
  padding: 13px 0px 15px 0px;
  text-align: center;
  box-sizing: border-box;
  width: 154px;
  height: 45px;
  border-radius: 8px;
  border: solid 1px #e3e4e8;
  font-size: 14px;
  @media (max-width: 767px) {
    width: 130px;
  }
  @media (max-width: 340px) {
    width: 110px;
  }
`;
