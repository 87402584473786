import { createSlice } from "@reduxjs/toolkit";
import { singleQuote } from "../../serviceApi";
import SecureLS from "secure-ls";
const ls = new SecureLS();
const kotakSingleProductSlice = createSlice({
  name: "kotakSingleProduct",
  initialState: {
    kotakSingleProduct: false,
    kotakPlanCard: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.kotakSingleProduct = action.payload;
    },
    saveKotakPlanDetails: (state, action) => {
      state.kotakPlanCard = action.payload;
    },
  },
});

export const { saveDetails, saveKotakPlanDetails } =
  kotakSingleProductSlice.actions;

export const saveKotakSingleProductDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveDetails(data.singleProductData.requestData.data));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export const saveKotakPlanCardDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveKotakPlanDetails(data.singleProductData));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default kotakSingleProductSlice.reducer;
