import { createSlice } from "@reduxjs/toolkit";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const medicalDetails4 = createSlice({
  name: "medicalDetailsFour",
  initialState: {
    medicalDetailsFour: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.medicalDetailsFour = action.payload;
    },
  },
});

export const { saveDetails } = medicalDetails4.actions;

export const medicalDetails4SendData = (data = {}) => ({
  diagnosedText: data?.otherDiagnosedDetails,
  medicalCondition: data.healthDeclaration ? data.medicalCondition : null,
  dateDiagnosis: data.healthDeclaration ? data.diagnosisDate : null,
  treatmentPeriod: data.healthDeclaration ? data.treatmenTerm : null,
  treatmentDetails: data.healthDeclaration ? data.treatmenDetails : null,
  treatmentTest: data.healthDeclaration ? data.treatmenTest : null,
  anyDetails: data.healthDeclaration ? data.otherDetails : null,
  diagnosed: data.disease ? "Y" : "N",
  hypertension: data.bp ? "Y" : "N",
  surgery: data.surgery ? "Y" : "N",
  stroke: data.nervousDisorder ? "Y" : "N",
  jaundice: data.liverDisorder ? "Y" : "N",
  anaemeia: data.bloodDisorder ? "Y" : "N",
  cancer: data.cancer ? "Y" : "N",
  chestPain: data.heartDisease ? "Y" : "N",
  diabetes: data.diabetes ? "Y" : "N",
  asthma: data.asthma ? "Y" : "N",
  pancreatitis: data.gastroIntestinal ? "Y" : "N",
  kidney: data.genitourinary ? "Y" : "N",
  hiv: data.hiv ? "Y" : "N",
  mental: data.psychiatric ? "Y" : "N",
  other: data.other ? "Y" : "N",
  healthDeclaration: data.healthDeclaration ? "Y" : "N",
  currentMedicalCondition: data.healthDeclaration ? data.condition : null,
  proposalJourneyStatus: iciciProposalForms.medicalQuestion4,
});

export const saveMedicalDetails4 = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(medicalDetails4SendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default medicalDetails4.reducer;
