import { useEffect } from "react";

function useOutsideClick(ref, callback, ref2) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref === "shouldClose") {
        return;
      }
      if (window.matchMedia("(max-width: 767px)").matches && ref2) {
        if (ref2.current && !ref2.current.contains(event.target)) {
          callback();
        }
      } else if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref, ref2]);
}

export default useOutsideClick;
