import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import {
  setOtherInsuranceDetails,
  setOtherInsuranceList,
  addOtherInsuranceList,
  otherInsuranceFormSendData,
} from "./otherInsuranceDetails.slice";
import { Form, FormGroup, Label } from "../../../../components/Form/Form";
import {
  EditButton,
  SummaryTile,
  TitleForm,
  ToggleTile,
} from "../../components";

import OtherInsurance from "./OtherInsurance";
import "./otherInsuranceDetails.scss";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const OtherInsuranceDetails = ({ position, setPosition, index }) => {
  const dispatch = useDispatch();

  const { otherInsuranceList } = useSelector(
    ({ otherInsuranceForm }) => otherInsuranceForm,
  );
  const otherInsuranceForm = useSelector(
    ({ otherInsuranceForm }) => otherInsuranceForm,
  );

  const [summary, setSummary] = useState(false);

  const [disable, setDisable] = useState(false);

  const [haveOtherInsurance, setHaveOtherInsurance] = useState(
    otherInsuranceForm.otherInsuranceDetails,
  );

  const { idDetails } = useSelector(({ idForm }) => idForm);

  useEffect(() => {
    if (otherInsuranceList.length === 0) {
      setHaveOtherInsurance(false);
      setDisable(false);
    }
  }, [otherInsuranceList]);

  const handleToggleChange = value => {
    setHaveOtherInsurance(value);
    if (value) {
      dispatch(
        setOtherInsuranceList([
          {
            insuranceCompany: "Select Insurance Company",
            sumAssured: "",
            id: Math.floor(Math.random() * 10000),
          },
        ]),
      );
      setDisable(true);
    } else {
      setOtherInsuranceList([]);
      setDisable(false);
    }
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const handleSubmit = evt => {
    evt.preventDefault();
    const data = {
      haveOtherInsurance,
      otherInsuranceList,
    };
    const sendData = otherInsuranceFormSendData(data);
    saveProposalForm(sendData, () => {
      dispatch(appendSendData(sendData));
      dispatch(setOtherInsuranceDetails(haveOtherInsurance));
      setSummary(true);
      dispatch(setPosition(position + 1));
    });
  };
  useEffect(() => {
    if (position === index) {
      setSummary(false);
    }
    if (position > index) {
      setSummary(true);
    }
  }, [position]);

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Other Insurance Details</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  return (
    <div className="other-insurance-details">
      <Card proposal>
        <CardTitle
          // EC={summary ? true : undefined}
          eligibilityTitle={summary ? true : undefined}
          className="cardTitleProposal"
        >
          Other Insurance Details
          {summary && (
            <EditButton
              onClick={() => {
                dispatch(setPosition(index));
                setSummary(false);
              }}
            />
          )}
        </CardTitle>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          {!summary ? (
            <FormGroup xlg className="flexAlignmentOther">
              <div className="radioTextOther">
                Did you hold any Life Insurance Policy(ies) other than ICICI
                Prudential?
              </div>
              <RadioComponent
                value={haveOtherInsurance}
                // className="toggle--sb"
                //   label="Did you hold any Life Insurance Policy(ies) other than ICICI
                // Prudential?"
                name="haveOtherInsurance"
                updateChecked={handleToggleChange}
              />
            </FormGroup>
          ) : (
            <FormGroup xlg style={{ textAlign: "left" }}>
              <SummaryTile
                label="Did you hold any Life Insurance Policy(ies) other than ICICI
              Prudential?"
                data={haveOtherInsurance ? "Yes" : "No"}
              />
            </FormGroup>
          )}

          {haveOtherInsurance && !summary && (
            <div className="insurance-list">
              {otherInsuranceList.map(
                ({ sumAssured, insuranceCompany, id }) => {
                  return (
                    <FormGroup
                      xlg
                      style={{ minHeight: "90px" }}
                      key={id}
                      OtherInsurance
                    >
                      <OtherInsurance
                        sumAssured={sumAssured}
                        insuranceCompany={insuranceCompany}
                        key={id}
                        id={id}
                        onError={setDisable}
                      />
                    </FormGroup>
                  );
                },
              )}

              {otherInsuranceList.length < 10 && (
                <div
                  className="add-policy-btn"
                  onClick={() => {
                    dispatch(
                      addOtherInsuranceList({
                        id: Math.floor(Math.random() * 10000),
                        insuranceCompany: "",
                        sumAssured: "",
                      }),
                    );

                    setDisable(true);
                  }}
                  id="otherInsurance"
                >
                  Add Another Policy
                </div>
              )}
            </div>
          )}
          {summary &&
            haveOtherInsurance &&
            otherInsuranceList.map(({ sumAssured, insuranceCompany, id }) => (
              <React.Fragment key={id}>
                <FormGroup className="mobDown" eligibilityPage md>
                  <Label md editMode htmlFor="companyName">
                    Company Name
                  </Label>
                  <span
                    className="data"
                    style={{
                      top: "32px",
                      left: "17px",
                      position: "relative",
                    }}
                  >
                    {insuranceCompany}
                  </span>
                </FormGroup>
                <FormGroup md eligibilityPage>
                  <Label md editMode htmlFor="sumAssured">
                    Sum Assured
                  </Label>
                  <span
                    className="data"
                    style={{
                      top: "32px",
                      left: "17px",
                      position: "relative",
                    }}
                  >
                    {sumAssured}
                  </span>
                </FormGroup>{" "}
              </React.Fragment>
            ))}
          {!summary && (
            <FormGroup xlg formPage propSubWrap>
              <button
                type="submit"
                disabled={disable}
                className={
                  disable
                    ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                    : "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                }
              >
                PROCEED TO NOMINEE DETAILS
              </button>
            </FormGroup>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default OtherInsuranceDetails;
