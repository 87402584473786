import React, { Fragment, useState, useEffect, useCallback } from "react";
import Popup from "./Popup";
import { TextInput, FormGroup, Label, Form } from "../Form/Form";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { numOnlyInput } from "./../../utils/inputUtils";
import sendTick from "../../assets/img/sendtick.svg";
import laxmi from "../../assets/img/girl-img.png";
import styled from "styled-components";
import SecureLS from "secure-ls";
import { callme } from "../../modules/GreetingPage/greeting.slice.js";
import { yupResolver } from "@hookform/resolvers";
import { talkToExpertSchema } from "../../utils/validationSchema";
import { useCustomLocation } from "../../custom-hooks";

const ContactPopup = ({ show, onClose }) => {
  const { customerDetails, traceId } = useSelector(state => state.greeting);
  const phone = customerDetails.mobileNo;
  const [flag, setFlag] = useState(true);
  const dispatch = useDispatch();
  const ls = new SecureLS();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(talkToExpertSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [length, setLength] = useState(0);
  const [msg, setMsg] = useState(false);
  const [phoneRef, setPhoneRef] = useState();
  const { selectedQuote } = useSelector(state => state.quote);
  const attachRef = useCallback(
    ref => {
      register(ref);
      setPhoneRef(ref);
      if (phoneRef && phone?.length === 10 && flag) {
        phoneRef.value = phone;
        setFlag(false);
        getLength();
      }
    },
    [flag, phoneRef, phone],
  );
  useEffect(() => {
    if (phoneRef?.value) getLength();
    else setLength(0);
  }, [phoneRef]);
  const getLength = () => {
    setLength(phoneRef.value.length);
  };
  const handleChange = () => {
    getLength();
  };

  useEffect(() => {
    if (flag === false) {
      setFlag(true);
    }
  }, [show]);

  const { enquiryId } = useCustomLocation();

  const onSubmit = data => {
    setMsg(true);
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 2000);
    dispatch(
      callme({
        user_id: sessionStorage.getItem("userId") || null,
        // user_journey_id: sessionStorage.getItem("traceId"),
        user_journey_id: enquiryId ? enquiryId || null : traceId || null,
        contact_no: phoneRef.value,
        product_name: selectedQuote?.master_product_name,
      }),
    );
  };
  const content2 = (
    <MessageContainer>
      <img src={sendTick} alt="send Tick"></img>
      <CallText>
        One of our experts will get back to you within few minutes
      </CallText>
    </MessageContainer>
  );
  const content1 = (
    <Fragment>
      <Content1>
        <div className="contact__imgbox">
          <ContactImg src={laxmi} alt="Lakshmi"></ContactImg>
        </div>
        <ContactText>
          <p>
            Hi{customerDetails.firstName ? " " + customerDetails.firstName : ""}
            , please confirm your mobile number and we will get back to you
            shortly.
          </p>
        </ContactText>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup lg style={{ width: "73%" }}>
            <TextInput
              lg
              pop
              type="tel"
              id="phone"
              name="phone"
              placeholder=" "
              ref={attachRef}
              onChange={handleChange}
              error={errors?.phone && "danger"}
              maxLength="10"
              // onInput={numOnly}
              onInput={numOnlyInput}
              contactPopUp
            />

            <Label form1Page lg contactPopUp htmlFor="phone">
              <PopupLabel>Mobile No.</PopupLabel>
            </Label>

            {errors.phone && <p className="errorG">{errors.phone.message}</p>}
          </FormGroup>
          <FormGroup sm style={{ width: "unset" }}>
            <Button dis={errors.phone || length < 10 ? true : false}>
              <span style={{ position: "relative", top: "-1px" }}>CALL ME</span>
            </Button>
          </FormGroup>
        </Form>
      </Content1>
      <Content2>
        <p>In a hurry? Call us on our toll free no. 1800 266 9693</p>
      </Content2>
    </Fragment>
  );
  return (
    <Fragment>
      <Popup
        height={msg ? "200px" : "340px"}
        width="640px"
        show={show}
        onClose={onClose}
        content={msg ? content2 : content1}
        topFixed={msg ? "189px" : "218px"}
      ></Popup>
    </Fragment>
  );
};

export default ContactPopup;

const CallText = styled.div`
  width: 340px;
  margin: 7px auto 37px;
  text-align: center;
  font-family: "Inter-Regular";
  font-size: 16px;
  color: #000000;
`;
const MessageContainer = styled.div`
  padding: 10px;
  & img {
    margin: 0 auto;
    width: 100%;
    height: 48px;
  }
`;

const Content1 = styled.div`
  height: 85%;
  padding: 54px 100px;
`;
const Content2 = styled.div`
  & p {
    color: #333333;
    font-family: "Inter-Regular";
    font-size: 14px;
    font-weight: 500;
  }
  border-top: solid 0.5px #e3e4e8;
  height: 74px;
  padding: 25px 0;
  background-color: #f1f2f6;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const ContactImg = styled.img`
  float: left;
  margin-right: 10px;
  height: 70px;
  border: solid 1px #d0d2d9;
  border-radius: 50%;
`;

const ContactText = styled.div`
  padding: 10px;
  font-family: Inter-Regular;
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  padding: 10px 14px 21px 14px;
  padding-left: 86px;
  width: 100%;
`;
const Button = styled.button`
  &:focus {
    outline: none;
  }
  display: inline-block;
  border-radius: 5px;
  padding: 0px 16px;
  width: 76px;
  box-sizing: content-box;
  font-size: 14px;
  font-family: Inter-Medium;
  background-color: ${ props => (props.dis ? "#dcdde0" : "#f2581b") };
  border: ${ props => (props.dis ? "solid 1px #dcdde0" : "solid 1px #f2581b") };
  color: ${ props => (props.dis ? "#969799" : "white") };
  height: 58px;
`;
const PopupLabel = styled.div`
  padding-top: 3px;
`;
