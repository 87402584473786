import styled from "styled-components";

export const LaxmiWrap = styled.div`
  text-align: center;
  position: relative;
`;
export const LaxmiImg = styled.img`
  margin-top: -52px;
  border: solid 0.5px #e3e4e8;
  border-radius: 50%;
  width: 96px;
  height: 96px;

  @media only screen and (max-width: 767px) {
    width: 70px;
    height: 70px;
    margin-top: -73px;
  }
`;

export const ThankWrap = styled.div`
  width: 1110px;
  margin: 0 auto 24px;
  & div {
    text-align: center;
  }
  @media only screen and (min-width: 768px) and (max-width: 1177px) {
    width: 710px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 24px auto 94px;
  }
`;

export const ThankCheck = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;
  @media only screen and (max-width: 767px) {
    margin-top: 26px;
    margin-bottom: 16px;
  }
`;
export const ThankTitle = styled.div`
  font-family: "Inter-SemiBold";
  font-size: 20px;
  line-height: 24px;
  color: #333;
  margin-bottom: 8px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }
`;
export const ThankSubTitle = styled.div`
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #333;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const DownloadButton = styled.button`
  display: inline-table;
  padding: 0 10px;
  background-color: #fff;
  width: 275px;
  height: 54px;
  padding: 0 16px;
  border-radius: 4px;
  border: solid 1px #dcdde0;
  outline: none;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #808080;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
