import React from "react";

import QuotePagePopup from "../../../modules/QuotePage/components/QuotePagePopup/QuotePagePopup";

import "./nri-criteria.scss";

function NriCriteriaPopup({ title, ...props }) {
  return (
    <QuotePagePopup {...props} id="test-popup">
      {fadeOut => (
        <>
          <header className="test-popup-header">
            <h1 className="test-popup-title">{title}</h1>
          </header>
          {props.children.type === "div"
            ? props.children
            : props.children(fadeOut)}
        </>
      )}
    </QuotePagePopup>
  );
}

export default NriCriteriaPopup;
