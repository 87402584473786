import styled from "styled-components";

export const HeadingWrapper = styled.div`
  text-align: center;
  margin: 25px 0 10px;
`;
export const Heading = styled.h1`
  font-size: 30px;
  font-family: "Inter-SemiBold";
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 0 50px;
`;
export const InnerWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 280px;
`;
export const Title = styled.h4`
  font-size: 16px;
  font-weight: 600;
  border-bottom: ${props => props.border && "1px solid #999999 "};
  padding-bottom: ${props => props.border && "5px"};
  color: ${props => props.primary && "#287692"};
`;
export const Value = styled.h5`
  font-size: ${props => (props.primary ? "25px" : "16px")};
  font-weight: 200;
  color: ${props => (props.primary ? "#287692" : "#333333")};
  white-space: no-wrap;
  ${props =>
    props.price && "display:flex;justify-content:space-between;width:100%"};
  margin-bottom: ${props => props.last && "30px !important"};
`;

export const WrapCenter = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
