import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import {
  FooterBold,
  FooterCopy,
  FooterInline,
  FooterWrap,
  Row,
} from "./FooterQuotes.style";
import "styled-components/macro";

const FooterQuotes = () => {
  const location = useLocation();
  const [mobileFooter, setMobileFooter] = useState(false);
  const { details } = useSelector(state => state.formCard);
  const [positionStyle, setPositionStyle] = useState(false);
  const { emailDiscount } = useSelector(state => state.greeting);

  useEffect(() => {
    if (
      location.pathname?.toLowerCase() === "/riderPage" ||
      ((location.pathname?.toLowerCase() === "/eligibilityPage" ||
        location.pathname?.toLowerCase() === "/eligibilitypage") &&
        details)
    ) {
      setMobileFooter(true);
    } else {
      setMobileFooter(false);
    }
    if (
      location.pathname?.toLowerCase() === "/proposalpage" ||
      location.pathname?.toLowerCase() === "/documentuploadpage"
    ) {
      setPositionStyle(true);
    } else {
      setPositionStyle(false);
    }
  }, [location.pathname, details]);
  return (
    <>
      <FooterWrap
        mobileFooter={mobileFooter}
        mobilePosition={positionStyle ? true : undefined}
        css={`
          margin-bottom: ${emailDiscount &&
          location.pathname?.toLowerCase() === "/"
            ? "73px"
            : "0"};
        `}
      >
        <Row>
          <FooterBold>
            <FooterInline>
              <span>Category: </span>
              Composite Broker
            </FooterInline>
            <FooterInline>
              <span>CIN No. </span>
              U67200MH2003PTC141621
            </FooterInline>
            <FooterInline>
              <span>IRDAI Registration No. </span>
              217
            </FooterInline>
          </FooterBold>
          <FooterCopy>
            Copyright © 2020<span></span> Alliance Insurance Brokers Pvt. Ltd.{" "}
          </FooterCopy>
        </Row>
      </FooterWrap>
    </>
  );
};

export default FooterQuotes;
