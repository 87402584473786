import React from "react";
import "./recommend-quote-card.scss";
import laxmiImg from "../../../../assets/img/laxmiImg.png";
import { useSelector } from "react-redux";
import "styled-components/macro";
function RecommendQuoteCard({ onCompare, ...props }) {
  const { firstName } = useSelector(({ greeting }) => greeting.customerDetails);
  const { isPnbPreference } = useSelector(({ greeting }) => greeting);
  const recommendQuoteCard = (
    <>
      <div
        className={`cardSuggestedTablet ${
          isPnbPreference ? "hide-recomended" : ""
        }`}
      >
        <img src={laxmiImg} alt="laxmi-img" />
        <div className="cardSugTabletTxt">RECOMMENDED FOR YOU</div>
      </div>
      <div className="card-suggested">
        <div className="card-suggested__bg" />
        <img src={laxmiImg} alt="laxmi-img" />
        <div className="card-suggested__suggest-text">
          Hi <span style={{ textTransform: "capitalize" }}>{firstName}</span>!
        </div>
        <div className="card-suggested__suggest-text-2">
          Here’s what I recommend basis the details you have provided
        </div>
      </div>
      {props.children}
    </>
  );

  if (onCompare) return recommendQuoteCard;

  return (
    <div
      className={isPnbPreference ? "borderWhiteList" : ""}
      css={`
        margin-bottom: ${props.mb ? "36px" : ""};
        width: 75%;
        @media (max-width: 1177px) {
          margin-bottom: ${props.mb ? "95px" : ""};
          width: 100%;
        }
        @media (min-width: 700px) and (max-width: 966px) {
          margin-bottom: ${props.mb ? "65px" : ""};
          width: 100%;
        }
      `}
    >
      <div
        className={`recommend-quote-card ${
          onCompare ? "recommend-quote-card--on-compare" : ""
        } ${isPnbPreference ? "whitelisted-card" : ""} `}
      >
        {recommendQuoteCard}
      </div>
    </div>
  );
}

export default RecommendQuoteCard;
