import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDropDown } from "../../../components";
import Card from "../../../components/Card/Card";
import CardTitle from "../../../components/Card/CardTitle/CardTitle";
import {
  AgeWrapper,
  CustomDropDownFont,
  Form,
  FormGroup as FG,
  Label,
  TextInputElg,
} from "../../../components/Form/Form";
import {
  generateRandomString,
  alphabetOnlyNoSpace,
  numOnlyWithShifDisable,
  dateUtil,
  getAge,
  onlyOneSpace,
  nameWithSpace,
  noNum,
} from "../../../utils/inputUtils";
import { EditButton } from "../components";
import { setPosition } from "../IcicProposalPage/IcicProposal.slice";
import styled from "styled-components";
import { nomineeShareList } from "./data";

const FormGroup = styled(FG)`
  height: auto !important;

  @media screen and (max-width: 767px) {
    min-height: max-content;

    display: flex;
    flex-direction: column;

    text-align: left;

    margin: 10px 0;

    margin-top: 20px;

    padding-left: 20px;
    padding-right: 20px;

    & label {
      position: static;
    }

    & .data {
      position: static !important;
    }
  }
`;

function NomineeForm({ proposalData = {}, index, expand, onSubmit }) {
  const [edit, setEdit] = useState(index === expand);

  useEffect(() => {
    if (index === expand) setEdit(true);
  }, [expand, index]);

  const [nominee, setNominee] = useState(
    proposalData?.nomineeDetails?.length > 0
      ? proposalData.nomineeDetails.map(c => ({
          nomineeName: c.nominee_name,
          nomineeBirthPlace: c.nominee_birth_place,
          nomineeDob: c.nominee_dob,
          relationWithInsured: c.nominee_relation,
          nomineeShare: c.nominee_share?.includes("Share")
            ? "Customize Your Share"
            : c.nominee_share,
          nomineeCustShare: c.nominee_share?.includes("Share")
            ? c.nominee_share
            : "",
          id: generateRandomString(10),
        }))
      : [
          {
            nomineeShare: "Customize your Share",
            nomineeCustShare: "",
            nomineeCustShareError: "",
            id: generateRandomString(10),
          },
        ],
  );

  const [appointee, setAppointeee] = useState({
    appointeeName: proposalData.appointeeName,
    appointeeDob: proposalData.appointeeDob,
    appointeeRelationToProposer: proposalData.appointeeRelationToProposer,
  });
  const [errors, setErrors] = useState({});
  const [hasAppointee, setHasAppointee] = useState(false);
  const [share, setShare] = useState(
    proposalData.nomineeDetails?.length > 0
      ? proposalData.nomineeDetails.reduce(
          (a, b) => a + parseInt(b.nominee_share),
          0,
        )
      : 0,
  );
  const dateRef = useRef();

  const attachRef = ref => {
    dateRef.current = ref;
  };
  const handleChange = event => {
    dateUtil(event, dateRef.current, true, false, false);
  };

  console.log("All array data clear", proposalData?.nomineeDetails);

  useEffect(() => {
    if (proposalData?.nomineeDetails?.length > 0) {
      setNominee(
        proposalData.nomineeDetails.map(c => ({
          nomineeName: c.nominee_name,
          nomineeBirthPlace: c.nominee_birth_place,
          nomineeDob: c.nominee_dob,
          relationWithInsured: c.nominee_relation,
          nomineeShare: c.nominee_share?.includes("Share")
            ? "Customize Your Share"
            : c.nominee_share,
          nomineeCustShare: c.nominee_share?.includes("Share")
            ? c.nominee_share
            : "",
          id: generateRandomString(10),
        })),
      );
    } else {
      setNominee([
        {
          nomineeShare: "Customize your Share",
          nomineeCustShare: "",
          nomineeCustShareError: "",
          id: generateRandomString(10),
        },
      ]);
    }
  }, [proposalData.nomineeDetails]);

  const validate = () => {
    let isValid = true;
    let newError = {
      appointeeNameError: "",
      appointeeDobErrror: "",
      appointeeRelationToProposerErrror: "",
    };
    const newOther = nominee.map(c => {
      let newNominee = { ...c };
      if (!c.nomineeName) {
        newNominee.nomineeNameError = "This field is required ";
        isValid = false;
      }
      if (
        c.nomineeName?.split(" ")?.length === 0 ||
        c.nomineeName?.split(" ")[1]?.length < 1
      ) {
        newNominee.nomineeNameError = "Please enter full name ";
        isValid = false;
      }
      if (!c.nomineeBirthPlace) {
        newNominee.nomineeBirthPlaceError = "This field is required ";
        isValid = false;
      }
      if (!c.nomineeDob) {
        newNominee.nomineeDobError = "This field is required ";
        isValid = false;
      }
      if (getAge(c.nomineeDob) > 100 || getAge(c.nomineeDob) < 1) {
        newNominee.nomineeDobError = "Please enter a valid value";
        isValid = false;
      }
      if (!c.relationWithInsured) {
        newNominee.relationWithInsuredError = "This field is required ";
        isValid = false;
      }
      if (!c.nomineeShare && share !== 100) {
        newNominee.nomineeShareError = "This field is required ";
        isValid = false;
      }
      if (
        (parseInt(c.nomineeShare) > 100 || parseInt(c.nomineeShare) < 1) &&
        share !== 100
      ) {
        newNominee.nomineeShareError = "Please enter a valid value ";
        isValid = false;
      }
      if (c.nomineeShare?.includes("Share")) {
        if (
          (!c.nomineeCustShare &&
            parseInt(c.nomineeShare) !== 100 &&
            share !== 100) ||
          (!c.nomineeCustShare && share === 100) ||
          parseInt(c.nomineeCustShare) === 0
        ) {
          newNominee.nomineeCustShareError = "This field is required ";
          isValid = false;
        }
        if (
          parseInt(c.nomineeShare) !== 100 &&
          share !== 100 &&
          (parseInt(c.nomineeCustShare) > 100 ||
            parseInt(c.nomineeCustShare) === 0)
        ) {
          newNominee.nomineeCustShareError = "Please enter a valid value ";
          isValid = false;
        }
      }

      return newNominee;
    });

    if (hasAppointee && !appointee.appointeeName) {
      newError.appointeeNameError = "This field is required ";
      isValid = false;
    }
    if (hasAppointee && !appointee.appointeeDob) {
      newError.appointeeDobErrror = "This field is required ";
      isValid = false;
    }
    if (
      hasAppointee &&
      (getAge(appointee.appointeeDob) > 100 ||
        getAge(appointee.appointeeDob) < 18)
    ) {
      newError.appointeeDobErrror = "Please enter a valid value";
      isValid = false;
    }
    if (hasAppointee && !appointee.appointeeRelationToProposer) {
      newError.appointeeRelationToProposerErrror = "This field is required";
      isValid = false;
    }

    setNominee(newOther);

    if (!isValid && hasAppointee)
      setErrors(prev => ({
        ...prev,
        ...newError,
      }));
    else setErrors(errors);
    return isValid;
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    console.log(validate(), errors, share);
    if (
      !validate() ||
      Object.keys(errors)?.every(item => item?.length === 0) ||
      share !== 100
    )
      return;
    setEdit(false);
    onSubmit({
      nominee,
      ...appointee,
      proposalJourneyStatus: "nomineeDetails",
    });
  };

  const removeNominee = id => {
    console.log("idddddddddddddddddddd", id);
    setNominee(nominee.filter(i => i.id !== id));
  };

  // values simple js
  const updateValue = (val, name, error, id) => {
    if (id !== "appointee") {
      setNominee(
        nominee.map(i =>
          i.id === id ? { ...i, [name]: val, [error]: "" } : i,
        ),
      );
    } else if (hasAppointee) {
      setAppointeee(prev => ({
        ...prev,
        [name]: val,
      }));
    }
  };

  const validateValue = (evt, error, id) => {
    let { name, value } = evt.target;
    if (name === "nomineeCustShare") {
      if (!value || value?.length === 0) {
        setNominee(
          nominee.map(i =>
            i.id === id ? { ...i, [error]: "This field is required" } : i,
          ),
        );
      }
    } else if (id !== "appointee") {
      if (!value || value?.length === 0) {
        setNominee(
          nominee.map(i =>
            i.id === id ? { ...i, [error]: "This field is required" } : i,
          ),
        );
      } else if (
        error === "nomineeNameError" &&
        (value?.split(" ")?.length < 2 || value?.split(" ")[1]?.length < 1)
      ) {
        setNominee(
          nominee.map(i =>
            i.id === id
              ? { ...i, nomineeNameError: "Please enter full name" }
              : i,
          ),
        );
      } else if (value?.length > 1) {
        setNominee(nominee.map(i => (i.id === id ? { ...i, [error]: "" } : i)));
      }
    } else if (hasAppointee) {
      if (!value || value?.length === 0) {
        setErrors(prev => ({
          ...prev,
          [error]: "This field is required",
        }));
      } else if (value?.length > 1) {
        setErrors(prev => ({
          ...prev,
          [error]: "",
        }));
      }
    }
  };

  // dob simple js
  const updateDob = (evt, name, error, id) => {
    handleChange(evt);
    setNominee(
      nominee.map(i =>
        i.id === id ? { ...i, [name]: evt.target.value, [error]: "" } : i,
      ),
    );
  };
  const validateDob = (evt, name, error, id) => {
    handleChange(evt);
    let { value } = evt.target;
    if (id !== "appointee") {
      if (name === "nomineeDob" && (getAge(value) > 100 || getAge(value) < 1)) {
        setNominee(
          nominee.map(i =>
            i.id === id ? { ...i, [error]: "Please enter a valid value" } : i,
          ),
        );
      } else if (name === "nomineeDob" && (!value || value?.length === 0)) {
        setNominee(
          nominee.map(i =>
            i.id === id ? { ...i, [error]: "This field is required" } : i,
          ),
        );
      } else if (name === "nomineeDob" && value?.length === 10) {
        setNominee(nominee.map(i => (i.id === id ? { ...i, [error]: "" } : i)));
      }
    } else if (hasAppointee) {
      if (
        name === "appointeeDob" &&
        (getAge(value) > 100 || getAge(value) < 18)
      ) {
        setErrors(prev => ({
          ...prev,
          [error]: "This field is required",
        }));
      } else if (name === "appointeeDob" && (!value || value?.length === 0)) {
        setErrors(prev => ({
          ...prev,
          [error]: "This field is required",
        }));
      } else if (name === "appointeeDob" && value?.length === 10) {
        setErrors(prev => ({
          ...prev,
          [error]: "",
        }));
      }
    }
  };

  // share simple js
  const updateNomineeShare = (nomineeShare, id) => {
    setNominee(
      nominee.map(i =>
        i.id === id ? { ...i, nomineeShare, nomineeShareError: "" } : i,
      ),
    );
  };

  const updateNomineeCustShare = (nomineeCustShare, id) => {
    setNominee(
      nominee.map(i =>
        i.id === id ? { ...i, nomineeCustShare, nomineeCustShareError: "" } : i,
      ),
    );
  };

  useEffect(() => {
    if (nominee.length > 0) {
      setShare(
        nominee.reduce((a, b) => {
          if (b.nomineeShare) {
            if (b.nomineeShare?.includes("Share")) {
              if (!b.nomineeCustShare || b.nomineeCustShare?.length === 0) {
                return a + 0;
              } else {
                return a + parseInt(b.nomineeCustShare);
              }
            } else {
              return a + parseInt(b.nomineeShare);
            }
          } else {
            return a + 0;
          }
        }, 0),
      );
      if (share > 100) {
        setErrors(prev => ({
          ...prev,
          nomineeShareError: "Total share can't be more than 100%",
        }));
      } else if (share < 100) {
        setErrors(prev => ({
          ...prev,
          nomineeShareError: "Total share can't be less than 100%",
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          nomineeShareError: "",
        }));
      }
    }
  }, [nominee, nominee.length, share]);

  useEffect(() => {
    setHasAppointee(
      nominee.some(
        i =>
          i.nomineeDob?.length === 10 &&
          getAge(i.nomineeDob) < 18 &&
          getAge(i.nomineeDob) > 0,
      ),
    );
  }, [nominee]);

  const TitleForm = (
    <div className="closeCardProposal">
      <Card className="proposalTitleCard">
        <CardTitle EC> Nominee Details</CardTitle>
      </Card>
    </div>
  );

  const dispatch = useDispatch();

  if (expand < index) return TitleForm;

  return edit ? (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => {
        dispatch(setPosition(index));
      }}
    >
      <Form onSubmit={handleSubmit}>
        <>
          {nominee.map((item, idx) => {
            console.log("All array", item);
            return (
              <Nominee
                {...item}
                idx={idx}
                nominee={nominee}
                updateDob={updateDob}
                updateValue={updateValue}
                updateNomineeShare={updateNomineeShare}
                updateNomineeCustShare={updateNomineeCustShare}
                removeNominee={removeNominee}
                setErrors={setErrors}
                attachRef={attachRef}
                validateValue={validateValue}
                validateDob={validateDob}
                errors={errors}
              />
            );
          })}
          {nominee.length < 4 && (
            <button
              type="button"
              onClick={() => {
                setNominee([
                  ...nominee,
                  {
                    id: generateRandomString(10),
                    nomineeShare: "Customize your Share",
                  },
                ]);
              }}
              style={{
                backgroundColor: "#107591",
                border: "none",
                outline: "none",
                fontSize: "10px",
                padding: "10px 50px",
                color: "#fff",
                fontFamily: "Inter-SemiBold",
                borderRadius: "5px",
                marginBottom: "10px",
                display: "flex",
                height: "100%",
                alignSelf: "center",
              }}
            >
              Add another nominee
            </button>
          )}
          {hasAppointee ? (
            <>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  placeholder=" "
                  proposal
                  type="text"
                  onKeyPress={e => {
                    onlyOneSpace(e);
                  }}
                  onKeyDown={nameWithSpace}
                  onInput={noNum}
                  maxLength="50"
                  id={"appointeeName"}
                  name={"appointeeName"}
                  value={appointee.appointeeName}
                  onChange={evt => {
                    updateValue(
                      evt.target.value,
                      "appointeeName",
                      "appointeeNameError",
                      "appointee",
                    );
                  }}
                  onBlur={e => {
                    validateValue(e, "appointeeNameError");
                  }}
                />
                <Label md htmlFor="appointeeName" formBuilerLabel>
                  Appointee Name
                </Label>
                <p className="errorG formbuilder__error">
                  {errors?.appointeeNameError}
                </p>
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  placeholder=" "
                  proposal
                  type="text"
                  id="appointeeDob"
                  name="appointeeDob"
                  maxLength="10"
                  ref={attachRef}
                  value={appointee.appointeeDob}
                  onChange={evt => {
                    dateUtil(evt, attachRef.current, true, false, false);
                    const { name, value } = evt.target;
                    setAppointeee(prev => ({
                      ...prev,
                      [name]: value,
                    }));
                  }}
                  onBlur={evt => {
                    validateDob(
                      evt,
                      "appointeeDob",
                      "appointeeDobErrror",
                      "appointee",
                    );
                  }}
                />
                <Label md htmlFor="appointeeDob" formBuilerLabel>
                  Appointee Date of Birth
                </Label>
                {appointee.appointeeDob?.length === 10 && (
                  <AgeWrapper
                    className="form-builder-age-wrapper ageWrapForm"
                    md
                  >{`${getAge(appointee.appointeeDob)} years`}</AgeWrapper>
                )}
                <p className="errorG formbuilder__error">
                  {errors?.appointeeDobError}
                </p>
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Appointee Relationship to Nominee"
                    propDropDown
                    selectedItem={appointee.appointeeRelationToProposer}
                    currentSelected={appointee.appointeeRelationToProposer}
                    onChange={value => {
                      updateValue(
                        value,
                        "appointeeRelationToProposer",
                        "appointeeRelationToProposerErrror",
                        "appointee",
                      );
                    }}
                    items={[
                      "Father",
                      "Mother",
                      "Brother",
                      "Sister",
                      "Grand Father",
                      "Grand Mother",
                      "Nephew",
                      "Niece",
                      "Employer",
                      "Not Mentioned",
                      "Guardian",
                      "Karta",
                      "Partnership Firm",
                      "Others",
                      "Legal",
                    ]}
                  />
                </CustomDropDownFont>
                <p className="errorG formbuilder__error">
                  {errors?.appointeeRelationToProposerErrror}
                </p>
              </FormGroup>
            </>
          ) : (
            <></>
          )}
        </>
        <FormGroup xlg formPages propSubWrap>
          <button
            type="submit"
            className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
          >
            PROCEED TO Bank Account Details
          </button>
        </FormGroup>
      </Form>
    </CardWrap>
  ) : (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => {
        dispatch(setPosition(index));
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          marginLeft: "-16px",
        }}
      >
        {nominee.map((item, idx) => (
          <NomineeSummary nominee={nominee} {...item} />
        ))}
        {hasAppointee ? (
          <>
            <SummaryTile
              label="Appointee Name"
              data={appointee.appointeeName}
              md
            />
            <SummaryTile
              label="Appointee Date of Birth"
              data={appointee.appointeeDob}
              md
            />
            <SummaryTile
              label="Appointee Relationship to Nominee"
              data={appointee.appointeeRelationToProposer}
              md
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </CardWrap>
  );
}

export default NomineeForm;

function SummaryTile({ label, data, ...props }) {
  return (
    <FormGroup formPage {...props}>
      <Label md editMode htmlFor="taxResident" style={{ textAlign: "left" }}>
        {label}
      </Label>
      <span
        className="data"
        style={{
          top: "37px",
          left: "17px",
          position: "relative",
          float: "left",
          ...props?.labelStyle,
        }}
      >
        {data}
      </span>
    </FormGroup>
  );
}

function CardWrap({ children, edit, setEdit, onEdit = () => {} }) {
  return (
    <Card proposal>
      <CardTitle eligibilityTitle className="cardTitleProposal propCardTitle">
        Nominee Details
        {!edit && (
          <EditButton
            onClick={() => {
              setEdit(true);
              onEdit();
            }}
          />
        )}
      </CardTitle>
      {children}
    </Card>
  );
}

function NomineeSummary({
  nomineeName = "",
  nomineeBirthPlace = "",
  nomineeDob = "",
  relationWithInsured = "Select",
  nomineeShare = "",
  nomineeCustShare = "",
}) {
  return (
    <>
      <SummaryTile label="Nominee Name" data={nomineeName} md />
      <SummaryTile label="Nominee Place of Birth" data={nomineeBirthPlace} md />
      <SummaryTile label="Nominee Date of Birth" data={nomineeDob} md />
      <SummaryTile
        label="Nominee Relationship to Proposed Insured"
        data={relationWithInsured}
        md
      />
      <SummaryTile label="Nominee Share (in %)" data={nomineeShare} md />
      {nomineeShare?.includes("Share") && (
        <SummaryTile
          label="Nominee Share Percentage"
          data={nomineeCustShare}
          md
        />
      )}
    </>
  );
}

function Nominee({
  nominee,
  nomineeName = "",
  nomineeBirthPlace = "",
  nomineeDob = "",
  relationWithInsured = "Select",
  nomineeShare = "",
  appointeeName = "",
  appointeeDob = "",
  appointeeRelationToProposer = "Select",
  id,
  updateValue,
  updateDob,
  updateNomineeShare,
  removeNominee,
  setErrors,
  idx,
  attachRef,
  validateValue,
  validateDob,
  nomineeCustShare,
  nomineeCustShareError,
  errors,
  updateNomineeCustShare,
  ...props
}) {
  console.log("All array data", id, nominee);
  return (
    <>
      <FormGroup proposalCard eligibilityPage>
        <TextInputElg
          proposal
          placeholder=" "
          type="text"
          id={`${nomineeName + id}`}
          name={`${nomineeName + id}`}
          maxLength="50"
          onKeyPress={e => {
            onlyOneSpace(e);
          }}
          onInput={nameWithSpace}
          value={nomineeName}
          onBlur={e => {
            validateValue(e, "nomineeNameError", id);
          }}
          onChange={evt => {
            updateValue(
              evt.target.value,
              "nomineeName",
              "nomineeNameError",
              id,
            );
          }}
        />
        <Label md htmlFor="nomineeName" formBuilerLabel>
          Nominee Name
        </Label>
        <p className="errorG formbuilder__error">{props.nomineeNameError}</p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <TextInputElg
          proposal
          placeholder=" "
          type="text"
          id={`${nomineeBirthPlace + id}`}
          name={`${nomineeBirthPlace + id}`}
          maxLength="30"
          onKeyPress={e => {
            onlyOneSpace(e);
          }}
          onKeyDown={nameWithSpace}
          onInput={nameWithSpace}
          value={nomineeBirthPlace}
          onBlur={e => {
            validateValue(e, "nomineeBirthPlaceError", id);
          }}
          onChange={evt => {
            updateValue(
              evt.target.value,
              "nomineeBirthPlace",
              "nomineeBirthPlaceError",
              id,
            );
          }}
        />
        <Label md htmlFor="nomineeBirthPlace" formBuilerLabel>
          Nominee Place of Birth
        </Label>
        <p className="errorG formbuilder__error">
          {props.nomineeBirthPlaceError}
        </p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <TextInputElg
          placeholder=" "
          proposal
          type="text"
          id={`${nomineeDob + id}`}
          name={`${nomineeDob + id}`}
          maxLength="10"
          ref={attachRef}
          value={nomineeDob}
          onChange={evt => {
            updateDob(evt, "nomineeDob", "nomineeDobError", id);
          }}
          onBlur={evt => {
            validateDob(evt, "nomineeDob", "nomineeDobError", id);
          }}
        />
        <Label md htmlFor="nomineeDob" formBuilerLabel>
          Nominee Date of Birth
        </Label>
        {nomineeDob?.length === 10 && (
          <AgeWrapper
            className="form-builder-age-wrapper ageWrapForm"
            md
          >{`${getAge(nomineeDob)} years`}</AgeWrapper>
        )}
        <p className="errorG formbuilder__error">{props.nomineeDobError}</p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Nominee Relationship to Proposed Insured"
            propDropDown
            selectedItem={relationWithInsured}
            currentSelected={relationWithInsured}
            onChange={val =>
              updateValue(
                val,
                "relationWithInsured",
                "relationWithInsuredError",
                id,
              )
            }
            items={[
              "Father",
              "Mother",
              "Grandfather",
              "Grandmother",
              "Wife",
              "Son",
              "Daughter",
              "Husband",
              "Others",
            ]}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">
          {props.relationWithInsuredError}
        </p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Nominee Share"
            propDropDown
            selectedItem={nomineeShare}
            currentSelected={nomineeShare}
            onChange={val => {
              updateValue(val, "nomineeShare", "nomineeShareError", id);
            }}
            items={nomineeShareList}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">
          {errors?.nomineeShareError && !nomineeShare?.includes("Share")
            ? errors?.nomineeShareError
            : props.nomineeShareError}
        </p>
      </FormGroup>

      {nomineeShare?.includes("Share") && (
        <FormGroup proposalCard eligibilityPage>
          <TextInputElg
            placeholder=" "
            proposal
            type="text"
            onInput={e => {
              numOnlyWithShifDisable(e);
            }}
            maxLength="3"
            id={`${nomineeCustShare + id}`}
            name={`${nomineeCustShare + id}`}
            value={nomineeCustShare}
            onChange={evt => {
              updateNomineeCustShare(evt.target.value, id);
            }}
            onBlur={evt => {
              validateValue(evt, "nomineeCustShareError", id);
            }}
          />
          <Label md htmlFor="nomineeCustShare" formBuilerLabel>
            Nominee Share Percentage
          </Label>
          <p className="errorG formbuilder__error">
            {errors?.nomineeShareError && nomineeCustShare?.length
              ? errors?.nomineeShareError
              : nomineeCustShareError}
          </p>
        </FormGroup>
      )}
      {nominee?.length > 1 && (
        <FormGroup proposalCardL>
          <button
            type="button"
            className="formbuilder__removeBtn"
            onClick={() => {
              removeNominee(id);
            }}
            style={{
              backgroundColor: "#f2581b",
              float: "right",
              border: "none",
              outline: "none",
              fontSize: "10px",
              padding: "10px 50px",
              color: "#fff",
              fontFamily: "Inter-SemiBold",
              borderRadius: "5px",
            }}
          >
            Remove
          </button>
        </FormGroup>
      )}
    </>
  );
}
