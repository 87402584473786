import React from "react";
import { CircleCheck, CircleCross } from "../SvgComp";

import "./criteria.scss";

function Criteria({ matches, ...props }) {
  return <div className="criteria">{props.children}</div>;
}

export default Criteria;

/*
 {matches ? <CircleCheck /> : <CircleCross />} 
 */
