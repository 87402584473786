import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDropDown } from "../../../components";
import Card from "../../../components/Card/Card";
import CardTitle from "../../../components/Card/CardTitle/CardTitle";
import {
  CustomDropDownFont,
  Form,
  FormGroup as FG,
  Label,
  TextInputElg,
} from "../../../components/Form/Form";
import RadioComponent from "../../../components/RadioContainer/RadioContainer";
import {
  generateRandomString,
  numOnly,
  upperCaseAll,
  numOnlyWithShifDisable,
  onlyAlphaNoSpace,
  upperCase,
} from "../../../utils/inputUtils";
import { EditButton } from "../components";
import { setPosition } from "../IcicProposalPage/IcicProposal.slice";
import { COMPANY_LIST } from "./bajajProposalPage.slice";
import { BAJAJ_COUNTRIES } from "./formsdata/Countries.utils";
import styled from "styled-components";
import { getAllData } from "../../../api/getAllData";
import { useHistory } from "react-router";

const FormGroup = styled(FG)`
  height: auto !important;

  @media screen and (max-width: 767px) {
    min-height: max-content;

    display: flex;
    flex-direction: column;

    text-align: left;

    margin: 10px 0;

    margin-top: 20px;

    padding-left: 20px;
    padding-right: 20px;

    & label {
      position: static;
    }

    & .data {
      position: static !important;
    }
  }
`;

function NriOtherInsurance({ proposalData = {}, index, enquiryId, expand, onSubmit }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const basicDetails = useSelector(
    ({ bajajProposalPageForm }) => bajajProposalPageForm,
  );
  const { isNRI } = useSelector(
    ({ eligibilityCard }) => eligibilityCard.eligibilityDetails,
  );
  useEffect(() => {
    dispatch(getAllData(history, "/proposalpage", enquiryId));
  }, [])
  useEffect(() => {
    if (isNRI === "YES") {
      setNriFlag(true);
    }
  }, [isNRI]);
  const [nriCountryText, setNriCountryText] = useState(
    proposalData?.nriCountryText || "INDIAN",
  );
  const [nriCountry, setNriCountry] = useState(
    proposalData?.nriCountry || "Select",
  );
  const [nriFlag, setNriFlag] = useState(proposalData?.nriFlag === "Y");
  const [nriCity, setNriCity] = useState(proposalData?.nriCity || "");
  const [nriDuration, setNriDuration] = useState(
    proposalData?.nriDuration || "",
  );
  const [modifiedTerm, setModifiedTerm] = useState(
    proposalData?.modifiedTerm === "Y",
  );
  const [annualPremiumPolicy, setAnnualPremiumPolicy] = useState(
    proposalData?.annualPremiumPolicy || "",
  );
  const [holdPolicy, setHoldPolicy] = useState(
    proposalData?.holdPolicy === "Y",
  );

  const [edit, setEdit] = useState(index === expand);

  useEffect(() => {
    if (index === expand) setEdit(true);
  }, [expand, index]);

  const [otherInsuranceCompany, setOtherInsuranceCompany] = useState(
    proposalData.otherInsuranceCompny || [],
  );
  // const [otherInsuranceCompany, setOtherInsuranceCompany] = useState(
  //   proposalData.otherInsuranceCompny
  //     ? JSON.parse(proposalData.otherInsuranceCompny).map((c) => ({
  //         ...c,
  //         companyError: "",
  //         sumError: "",
  //         policyNumberError: "",
  //         statusError: "",
  //       }))
  //     : []
  // );

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!otherInsuranceCompany.length) setHoldPolicy(false);
  }, [otherInsuranceCompany]);

  const validate = () => {
    let isValid = true;
    let newErrors = {};
    if (nriFlag) {
      if (!nriCity) {
        newErrors.nriCity = "This field is required ";
        isValid = false;
      }
      if (nriCountry === "Select") {
        newErrors.nriCountry = "This field is required ";
        isValid = false;
      }
      if (!nriDuration) {
        newErrors.nriDuration = "This field is required ";
        isValid = false;
      }
    }
    if (
      (proposalData.maritalStatusText &&
        proposalData?.maritalStatusText !== "Single") ||
      (basicDetails.maritalStatus && basicDetails?.meritalStatus !== "Single")
    ) {
      if (
        annualPremiumPolicy != 0 &&
        (!annualPremiumPolicy || parseInt(annualPremiumPolicy) < 10000)
      ) {
        newErrors.annualPremiumPolicy =
          parseInt(annualPremiumPolicy) < 10000
            ? "Premium should be more than 10000"
            : "This field is required ";
        isValid = false;
      }
    }
    if (holdPolicy) {
      const newOther = otherInsuranceCompany.map(c => {
        let newCompany = { ...c };
        if (!c.company) {
          newCompany.companyError = "This field is required ";
          isValid = false;
        }
        if (c.sum && Number(c.sum?.length) < 6) {
          newCompany.sumError = "Sum assured must be at least 6 digits";
          isValid = false;
        }
        if (!c.sum) {
          newCompany.sumError = "This field is required ";
          isValid = false;
        }
        if (!c.policyNumber) {
          newCompany.policyNumberError = "This field is required ";
          isValid = false;
        }
        if (!c.status) {
          newCompany.statusError = "This field is required ";
          isValid = false;
        }
        return newCompany;
      });
      setOtherInsuranceCompany(newOther);
    }
    if (!isValid) setErrors(newErrors);
    else setErrors({});
    return isValid;
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    if (!validate()) return;
    setEdit(false);
    onSubmit({
      nriFlag: nriFlag ? "Y" : "N",
      nriCountry,
      nriCountryText,
      nriCity,
      nriDuration,
      modifiedTerm: modifiedTerm ? "Y" : "N",
      annualPremiumPolicy,
      holdPolicy: holdPolicy ? "Y" : "N",
      otherInsuranceCompny: [...otherInsuranceCompany],
      proposalJourneyStatus: "nriAndPreviousInsuranceDetails",
      citizenship: isNRI === "YES" ? "NRI" : "IN",
    });
  };

  const removeInsurance = id => {
    setOtherInsuranceCompany(otherInsuranceCompany.filter(i => i.id !== id));
  };
  const updateInsuranceCompany = (company, id) => {
    setOtherInsuranceCompany(
      otherInsuranceCompany.map(i =>
        i.id === id ? { ...i, company, companyError: "" } : i,
      ),
    );
  };
  const updateInsuranceSum = (sum, id) => {
    setOtherInsuranceCompany(
      otherInsuranceCompany.map(i =>
        i.id === id ? { ...i, sum, sumError: "" } : i,
      ),
    );
  };
  const updateInsurancePolicyNumber = (policyNumber, id) => {
    setOtherInsuranceCompany(
      otherInsuranceCompany.map(i =>
        i.id === id ? { ...i, policyNumber, policyNumberError: "" } : i,
      ),
    );
  };
  const updateInsuranceStatus = (status, id) => {
    setOtherInsuranceCompany(
      otherInsuranceCompany.map(i =>
        i.id === id ? { ...i, status, statusError: "" } : i,
      ),
    );
  };

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC> NRI & Previous Insurance Details</CardTitle>
      </Card>
    </div>
  );


  if (expand < index) return TitleForm;

  return edit ? (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => {
        dispatch(setPosition(index));
      }}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup
          proposalCard
          eligibilityPage
          style={{ pointerEvents: "none" }}
        >
          <CustomDropDownFont rdr>
            <CustomDropDown
              dropDownTitle="Citizenship"
              propDropDown
              // items={Object.keys(BAJAJ_COUNTRIES)}
              items={[]}
              selectedItem={isNRI === "YES" ? "NRI" : "INDIAN"}
              currentSelected={isNRI === "YES" ? "NRI" : "INDIAN"}
              onChange={setNriCountryText}
            />
          </CustomDropDownFont>
        </FormGroup>
        {/* {true && ( */}
        {isNRI === "YES" && (
          <>
            <FormGroup proposalCard eligibilityPage mobheight lg>
              <div style={{ marginBottom: 20 }}>
                Do you presently or intended to travel or reside abroad?
              </div>
              <RadioComponent
                value={true}
                name="nriFlag"
              // updateChecked={setNriFlag}
              />
            </FormGroup>
            {nriFlag && (
              <>
                <FormGroup proposalCard eligibilityPage>
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      dropDownTitle="Please specify Country Name"
                      propDropDown
                      selectedItem={nriCountry}
                      currentSelected={nriCountry}
                      items={Object.keys(BAJAJ_COUNTRIES)}
                      searchStartsWith
                      searchable
                      onChange={val => {
                        setNriCountry(val);
                        setErrors({ ...errors, nriCountry: "" });
                      }}
                    />
                  </CustomDropDownFont>
                  <p className="error">{errors.nriCountry}</p>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  <TextInputElg
                    placeholder=" "
                    proposal
                    type="text"
                    id="nriCity"
                    name="nriCity"
                    value={nriCity}
                    onInput={upperCaseAll}
                    maxLength={50}
                    onChange={evt => {
                      setNriCity(evt.target.value);
                      setErrors({ ...errors, nriCity: "" });
                    }}
                  />
                  <Label md htmlFor="nriCity" formBuilerLabel>
                    Please specify City Name
                  </Label>
                  <p className="error">{errors.nriCity}</p>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage lg>
                  <TextInputElg
                    placeholder=" "
                    proposal
                    type="text"
                    id="nriDuration"
                    name="nriDuration"
                    value={nriDuration}
                    maxLength={20}
                    onChange={evt => {
                      setNriDuration(evt.target.value);
                      setErrors({ ...errors, nriDuration: "" });
                    }}
                  />
                  <Label md htmlFor="nriDuration" formBuilerLabel>
                    Purpose and planned duration of your stay
                  </Label>
                  <p className="errorG formbuilder__error">
                    {errors.nriDuration}
                  </p>
                </FormGroup>
              </>
            )}
          </>
        )}
        <FormGroup proposalCardL eligibilityPage mobheight>
          <div style={{ marginBottom: 20 }}>
            Has any of life and /Health Insurance or Critical Illness Insurance
            proposal or revival request been declined/postponed/ dropped/rated
            up or accepted with modified terms?
          </div>
          <RadioComponent
            value={modifiedTerm}
            name="modifiedTerm"
            updateChecked={setModifiedTerm}
          />
        </FormGroup>
        {((proposalData.maritalStatusText &&
          proposalData?.maritalStatusText !== "Single") ||
          (basicDetails.maritalStatus &&
            basicDetails?.meritalStatus !== "Single")) && (
            <FormGroup proposalCardL eligibilityPage lg style={{ width: "100%" }}>
              <TextInputElg
                placeholder=" "
                size="xlg"
                style={{ width: "100%" }}
                proposal
                type="text"
                maxLength="11"
                onInput={numOnlyWithShifDisable}
                id="annualPremiumPolicy"
                name="annualPremiumPolicy"
                value={annualPremiumPolicy}
                onChange={evt => {
                  setAnnualPremiumPolicy(evt.target.value);
                  setErrors({ ...errors, annualPremiumPolicy: "" });
                }}
              />
              <Label md htmlFor="nriDuration" formBuilerLabel>
                Annual premium paid by you for Insurance policies of your spouse /
                children / dependents?
              </Label>
              <p className="errorG formbuilder__error">
                {errors.annualPremiumPolicy}
              </p>
            </FormGroup>
          )}
        <FormGroup proposalCardL eligibilityPage mobheight>
          <div style={{ marginBottom: 20 }}>
            Did you hold any Life Insurance Policy(ies)?
          </div>
          <RadioComponent
            value={holdPolicy}
            name="holdPolicy"
            updateChecked={value => {
              setHoldPolicy(value);
              if (value)
                setOtherInsuranceCompany([{ id: generateRandomString(10) }]);
            }}
          />
        </FormGroup>
        {holdPolicy && (
          <>
            {otherInsuranceCompany.map(item => (
              <OtherInsurance
                {...item}
                updateInsuranceCompany={updateInsuranceCompany}
                updateInsuranceStatus={updateInsuranceStatus}
                updateInsurancePolicyNumber={updateInsurancePolicyNumber}
                updateInsuranceSum={updateInsuranceSum}
                removeInsurance={removeInsurance}
                setErrors={setErrors}
              />
            ))}
            {otherInsuranceCompany.length < 10 && (
              <button
                type="button"
                onClick={() =>
                  setOtherInsuranceCompany([
                    ...otherInsuranceCompany,
                    { id: generateRandomString(10) },
                  ])
                }
                style={{
                  backgroundColor: "#107591",
                  border: "none",
                  outline: "none",
                  fontSize: "10px",
                  padding: "10px 50px",
                  color: "#fff",
                  fontFamily: "Inter-SemiBold",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                Add another insurance
              </button>
            )}
          </>
        )}
        <FormGroup xlg formPages propSubWrap>
          <button
            type="submit"
            className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
          >
            PROCEED TO FATCA DETAILS
          </button>
        </FormGroup>
      </Form>
    </CardWrap>
  ) : (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => {
        dispatch(setPosition(index));
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          marginLeft: "-16px",
        }}
      >
        <SummaryTile
          label={"Citizenship"}
          data={isNRI === "YES" ? "NRI" : "INDIA"}
          md
        />
        <SummaryTile
          label={"Do you presently or intended to travel or reside abroad?"}
          data={nriFlag ? "Yes" : "No"}
          md
        />
        {nriFlag && (
          <>
            <SummaryTile label={"Country Name"} data={nriCountry} md />
            <SummaryTile label={"City Name"} data={nriCity} md />
            <SummaryTile
              label={" Purpose and planned duration of your stay"}
              data={nriDuration}
              md
            />
          </>
        )}
        <SummaryTile
          label={`Has any of life and /Health Insurance or Critical Illness Insurance proposal or revival request been declined/postponed/ dropped/rated up or accepted with modified terms?`}
          data={modifiedTerm ? "Yes" : "No"}
          xlg
          style={{ meginBottom: "10px" }}
          labelStyle={{ top: "47px" }}
        />
        {((proposalData.maritalStatusText &&
          proposalData?.maritalStatusText !== "Single") ||
          (basicDetails.maritalStatus &&
            basicDetails?.meritalStatus !== "Single")) && (
            <SummaryTile
              label="Annual premium paid by you for Insurance policies of your spouse /
          children / dependents?"
              data={annualPremiumPolicy}
              xlg
            />
          )}
        <SummaryTile
          label="Did you hold any Life Insurance Policy(ies) other than Bajaj Life?"
          data={holdPolicy ? "Yes" : "No"}
          xlg
        />
        {holdPolicy &&
          otherInsuranceCompany.map(item => (
            <OtherInsuranceSummary {...item} />
          ))}
      </div>
    </CardWrap>
  );
}

export default NriOtherInsurance;

function SummaryTile({ label, data, ...props }) {
  return (
    <FormGroup formPage {...props}>
      <Label md editMode htmlFor="taxResident" style={{ textAlign: "left" }}>
        {label}
      </Label>
      <span
        className="data"
        style={{
          top: "37px",
          left: "17px",
          position: "relative",
          float: "left",
          ...props?.labelStyle,
        }}
      >
        {data}
      </span>
    </FormGroup>
  );
}

function CardWrap({ children, edit, setEdit, onEdit = () => { } }) {
  return (
    <Card proposal>
      <CardTitle eligibilityTitle className="cardTitleProposal propCardTitle">
        NRI & Previous Insurance Details
        {!edit && (
          <EditButton
            onClick={() => {
              setEdit(true);
              onEdit();
            }}
          />
        )}
      </CardTitle>
      {children}
    </Card>
  );
}

function OtherInsuranceSummary({
  company = "Select",
  sum = "",
  policyNumber = "",
  status = "Select",
}) {
  return (
    <>
      <SummaryTile label="Insurance company" data={company} md />
      <SummaryTile label="Sum assured" data={sum} md />
      <SummaryTile label="Policy number" data={policyNumber} md />
      <SummaryTile label="Policy status" data={status} md />
    </>
  );
}

function OtherInsurance({
  company = "Select",
  sum = "",
  policyNumber = "",
  status = "Select",
  id,
  updateInsuranceSum,
  updateInsuranceStatus,
  updateInsurancePolicyNumber,
  updateInsuranceCompany,
  removeInsurance,
  setErrors,
  ...props
}) {
  return (
    <>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Insurance company"
            propDropDown
            selectedItem={company}
            currentSelected={company}
            items={
              COMPANY_LIST ? COMPANY_LIST.sort(Intl.Collator().compare) : []
            }
            searchStartsWith
            searchable
            onChange={val => updateInsuranceCompany(val, id)}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">{props.companyError}</p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <TextInputElg
          proposal
          placeholder=" "
          type="text"
          id={sum + id}
          name={sum + id}
          maxLength="11"
          onInput={numOnlyWithShifDisable}
          value={sum}
          onChange={evt => {
            updateInsuranceSum(evt.target.value, id);
          }}
        />
        <Label md htmlFor="nriCity" formBuilerLabel>
          Sum assured
        </Label>
        <p className="errorG formbuilder__error">{props.sumError}</p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <TextInputElg
          placeholder=" "
          proposal
          type="text"
          onInput={e => {
            upperCase(e);
            onlyAlphaNoSpace(e);
          }}
          id={policyNumber + id}
          name={policyNumber + id}
          value={policyNumber}
          onChange={evt => {
            updateInsurancePolicyNumber(evt.target.value, id);
          }}
        />
        <Label md htmlFor="nriCity" formBuilerLabel>
          Policy number
        </Label>
        <p className="errorG formbuilder__error">{props.policyNumberError}</p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Policy status"
            propDropDown
            selectedItem={status}
            currentSelected={status}
            onChange={val => updateInsuranceStatus(val, id)}
            items={["Issued", "Lapsed", "Decline", "Others"]}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">{props.statusError}</p>
      </FormGroup>
      <FormGroup proposalCardL>
        <button
          type="button"
          className="formbuilder__removeBtn"
          onClick={() => removeInsurance(id)}
          style={{
            backgroundColor: "#f2581b",
            float: "right",
            border: "none",
            outline: "none",
            fontSize: "10px",
            padding: "10px 50px",
            color: "#fff",
            fontFamily: "Inter-SemiBold",
            borderRadius: "5px",
          }}
        >
          Remove
        </button>
      </FormGroup>
    </>
  );
}
