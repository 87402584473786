import { createSlice } from "@reduxjs/toolkit";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const medicalDetailsTwo = createSlice({
  name: "medicalDetailsTwo",
  initialState: {
    medicalDetailsTwo: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.medicalDetailsTwo = action.payload;
    },
  },
});

export const { saveDetails } = medicalDetailsTwo.actions;

export const medicalDetailsTwoSendData = (data = {}) => ({
  hazardText: data.toggleOccupationHazard ? data.occupationHazard : null,
  armedForcesText: data.toggleEmployedInArmy ? data.employedInArmy : null,
  weightLossText: data.toggleLostWeight ? data.lostWeight : null,
  familyDiseaseText: data.toggleFamilyDisorder ? data.familyDisorder : null,
  selfDiseaseText: data.toggleHospitalized ? data.hospitalized : null,
  hazard: data.toggleOccupationHazard ? "Y" : "N",
  weightLoss: data.toggleLostWeight ? "Y" : "N",
  armedForces: data.toggleEmployedInArmy ? "Y" : "N",
  familyDisease: data.toggleFamilyDisorder ? "Y" : "N",
  selfDisease: data.toggleHospitalized ? "Y" : "N",
  proposalJourneyStatus: iciciProposalForms.medicalQuestion2,
});

export const saveMedicalDetailsTwo = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(medicalDetailsTwoSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default medicalDetailsTwo.reducer;
