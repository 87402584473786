import React, { useEffect } from "react";
import Card from "../../../components/Card/Card";
import CardTitle from "../../../components/Card/CardTitle/CardTitle";
import CustomDropDown from "../../../components/CutomDropDown/CustomDropDown";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
} from "../../../components/Form/Form";
import { getNextDays } from "../../../utils/inputUtils";
import { yupResolver } from "@hookform/resolvers";
import SecureLS from "secure-ls";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { initLoader, maxMedicalCenterApi } from "./MaxMedicalCenter.slice";
import { Title } from "../../../components/Popup/LumpSum.style";

import { maxMedicalFormSchema } from "../../../utils/validationSchema";
import { useCustomLocation } from "../../../custom-hooks";

const MaxMedicalCenter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const ls = new SecureLS();

  const { appNo } = useSelector(state => state.eligibilityCard);

  const { enquiryId } = useCustomLocation();

  // const applicationNumber = sessionStorage.getItem("appNo");
  // const traceId = sessionStorage.getItem("traceId");

  const applicationNumber = appNo;
  const traceId = enquiryId;

  const { register, handleSubmit, errors, setValue, unregister } = useForm({
    resolver: yupResolver(maxMedicalFormSchema),
  });

  const { response, error } = useSelector(
    state => state.maxLifeProposalPageForm,
  );

  const timeInterval = [
    "Preferred Time",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
  ];

  useEffect(() => {
    register({ name: "preferredTime" });
    register({ name: "preferredDate" });
    register({ name: "visitType" });
    setValue("visitType", response?.medicalVisit && response.medicalVisit);
    register({ name: "medicalCenter" });
  }, [register, response]);

  const visitTypes = ["Visit Type", "Center Visit", "Home Visit"];

  const singleMedicalCenter = labName => {
    return response.medicalCenter.original.data.response.payload.filter(
      d => d.labName === labName,
    );
  };

  const { maxMedicalResponse, loading } = useSelector(
    state => state.maxMedicalCenter,
  );

  const onSubmit = data => {
    const newData = {
      traceId: traceId,
      appNo: applicationNumber,
      medicalScheduleDate: data?.preferredDate,
      medicalScheduleTime: data?.preferredTime,
      companyAlias: "max_life",
      visitType:
        response?.medicalVisit === "Center Visit"
          ? "Centre Visit"
          : "Home Visit",
      medicalCenter:
        data.medicalCenter && singleMedicalCenter(data.medicalCenter)[0].labId,
    };
    dispatch(maxMedicalCenterApi(newData, history, search));
    dispatch(initLoader(true));
  };

  const medicalConduct = (
    <>
      <div className="application_no">
        {<span>Application No: {sessionStorage.getItem("appNo")}</span>}
      </div>
      <Card
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CardTitle>Medical Center Form</CardTitle>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup md eligibilityPage>
            <TextInput
              md
              type="text"
              id="pkgName"
              name="pkgName"
              readOnly
              // ref={register}
              defaultValue={response?.medicalPackage}
            />
            <Label md htmlFor="pkgName">
              Package Name
            </Label>
          </FormGroup>

          <FormGroup md eligibilityPage>
            <CustomDropDown
              name="preferredDate"
              items={["Preferred Date", ...getNextDays(8)]}
              onChange={value => setValue("preferredDate", value)}
            />
            {errors.preferredDate && (
              <p className="errorG">{errors.preferredDate.message}</p>
            )}
          </FormGroup>
          <FormGroup md eligibilityPage>
            <CustomDropDown
              name="timeInterval"
              items={timeInterval}
              onChange={value => setValue("preferredTime", value)}
            />
            {errors.preferredTime && (
              <p className="errorG">{errors.preferredTime.message}</p>
            )}
          </FormGroup>

          <FormGroup md eligibilityPage>
            <CustomDropDown
              name="visitType"
              items={visitTypes}
              disabled={response?.medicalVisit ? true : false}
              selectedItem={response?.medicalVisit}
              currentSelected={response?.medicalVisit}
              onChange={setValue(
                "visitType",
                response?.medicalVisit && response.medicalVisit,
              )}
            />
          </FormGroup>
          {response.medicalVisit === "Center Visit" && (
            <FormGroup md eligibilityPage>
              <CustomDropDown
                name="medicalCenter"
                items={[
                  "-Select Medical Center-",
                  ...response.medicalCenter.original.data.response.payload.map(
                    item => item.labName,
                  ),
                ]}
                // items={[
                //   "-Select Medical Center-",
                //   ...centers.data.response.payload.map((item) => item.labName),
                // ]}
                onChange={value => setValue("medicalCenter", value)}
              />
              {errors.medicalCenter && (
                <p className="errorG">{errors.medicalCenter.message}</p>
              )}
            </FormGroup>
          )}
          <FormGroup xlg eligibilityPage>
            <button
              type="submit"
              className={"btn btn--highlighted btn--lg uppercase bold buttton"}
            >
              Submit
            </button>
          </FormGroup>
        </Form>
      </Card>
    </>
  );

  const teleMedical = (
    <>
      <div className="application_no">
        {<span>Application No: {sessionStorage.getItem("appNo")}</span>}
      </div>
      <Card
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CardTitle>Max Medical Center Form</CardTitle>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title style={{ padding: 50, fontSize: 20 }}>
            Congratulations !!! You are eligible for Tele-Medical where no
            medical will be conducted.
          </Title>
          <FormGroup xlg eligibilityPage>
            <button
              type="submit"
              className={"btn btn--highlighted btn--lg uppercase bold buttton"}
            >
              Proceed
            </button>
          </FormGroup>
        </Form>
      </Card>
    </>
  );

  if (response.medicalCase === "Medical Conduct") {
    return (
      <>
        {medicalConduct} {loading && <Loader />}
      </>
    );
  } else {
    return <></>;
  }
};

export default MaxMedicalCenter;
