import { createSlice } from "@reduxjs/toolkit";
import { getFreq } from "../../../../../utils/kotakCodeUtils";
import { appendSendData } from "../../../IcicProposalPage/IcicProposal.slice";
import { kotakProposalForms } from "../../kotakProposalPage.slice";

const healthDetailsTwo = createSlice({
  name: "healthDetailsTwo",
  initialState: {
    healthDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.healthDetails = action.payload;
    },
  },
});
export const { saveDetails } = healthDetailsTwo.actions;

export const saveHealthDetailsTwo = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(healthDetailsTwoSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};
export const healthDetailsTwoSendData = newData => {
  return {
    proposalJourneyStatus: kotakProposalForms.healthDetailsTwo,
    isDiseaseRespiratory: newData.digestiveDisorder ? "Y" : "N",
    asthamaOrBronchitis:
      newData.digestiveDisorder && newData.resDisease.Asthma_Bronchitis
        ? "Y"
        : "N",
    pulmonarytbOrLung:
      newData.digestiveDisorder &&
      newData.resDisease.Pulmonary_Tuberculosis_Lung_disorders
        ? "Y"
        : "N",
    kidneyOrUrinary:
      newData.digestiveDisorder && newData.resDisease.Kidney_Urinary
        ? "Y"
        : "N",
    gallBladder:
      newData.digestiveDisorder && newData.resDisease.Gall_bladder_Intestine
        ? "Y"
        : "N",
    ulcers:
      newData.digestiveDisorder && newData.resDisease.Ulcers_Haemorrhoids
        ? "Y"
        : "N",
    astAddremark2: newData.digDetails && newData.digDetails,
    isDiseaseCancer: newData.hiv ? "Y" : "N",
    cancerTumorCyst:
      newData.hiv && newData.chsDisease.Cancer_tumor_cyst ? "Y" : "N",
    hivAids: newData.hiv && newData.chsDisease.HIV_AIDS ? "Y" : "N",
    sexuallyTransDiseases:
      newData.hiv && newData.chsDisease.Sexually_transmitted_disease
        ? "Y"
        : "N",
    anyUnusualGrowth:
      newData.hiv && newData.chsDisease.Any_other_unusual_growth ? "Y" : "N",
    canAddremark3: newData.hivDetail && newData.hivDetail,
    isMentalNervousOther: newData.otherDisease ? "Y" : "N",
    mentalDisorder:
      newData.otherDisease && newData.mncDisease.Mental_disorder ? "Y" : "N",
    nervousDisorder:
      newData.otherDisease && newData.mncDisease.Nervous_disorder ? "Y" : "N",
    congentialDisorder:
      newData.otherDisease && newData.mncDisease.Congenital_disease ? "Y" : "N",
    physicalDeformity:
      newData.otherDisease && newData.mncDisease.Physical_deformity ? "Y" : "N",
    otherOilments:
      newData.otherDisease && newData.mncDisease.Any_other_ailment ? "Y" : "N",
    mtAddremark4: newData.otherDiseaseDetail && newData.otherDiseaseDetail,
    isHospitalizedFor5years: newData.hospitalized ? "Y" : "N",
    mlpAddremarks5: newData.hospitalized && newData.hospitalizedDetails,
    isPregnancyComplication: newData.preg ? "Y" : "N",
    currentPregnant:
      newData.preg && newData.pregDisease.currently_Preg ? "Y" : "N",
    pregRelatedComplic:
      newData.preg && newData.pregDisease.preg_Pul ? "Y" : "N",
    alignment: newData.preg && newData.pregDisease.Ailment_Cervix ? "Y" : "N",
    pgntAddremark6: newData.preg && newData.pregDetails,
    healthFamilyNone: newData.familyDisorder ? "Y" : "N",
    cancer: newData.familyDisorder && newData.familyDisease.Cancer ? "Y" : "N",
    heartHypertenStroke:
      newData.familyDisorder &&
      newData.familyDisease.Heart_disease_Hypertension_Stroke
        ? "Y"
        : "N",
    pep: newData.polticallyExposed ? "Y" : "N",
    geneDiabetes:
      newData.familyDisorder && newData.familyDisease.Diabetes ? "Y" : "N",
    hepatitisB:
      newData.familyDisorder && newData.familyDisease.Hepatitis_B ? "Y" : "N",
    geneHivAids:
      newData.familyDisorder && newData.familyDisease.HIV_AIDS ? "Y" : "N",
    anyGeneticFamilial:
      newData.familyDisorder &&
      newData.familyDisease.Any_other_genetic_familial_disease
        ? "Y"
        : "N",
    kidneyDisorder:
      newData.familyDisorder && newData.familyDisease.Kidney_Disorder
        ? "Y"
        : "N",
    fmAddremark7: newData.familyDisorder && newData.otherfDiseaseDetail,
    alreadyLifeInsuApplied: newData.policy ? "Y" : "N",
    isLifeInsuComKotak: newData.policy
      ? newData.kotakpolicy
        ? "Y"
        : "N"
      : null,
    isLifeInsuComOther: newData.policy
      ? newData.kotakpolicy
        ? "N"
        : "Y"
      : null,
    saPayDeathKotak: newData.policy
      ? newData.kotakpolicy && newData.coverageAmount
      : null,
    saPayDeathOtherIns: newData.policy
      ? !newData.kotakpolicy && newData.coverageAmount
      : null,
    electronicPolicyIns: newData.eia ? "Y" : "N",
    insRepositoryName:
      newData.eia &&
      newData.eiaNameText !== "Select insurance repository for EIA"
        ? newData.eiaName
        : null,
    eiaNo: newData.eia && newData.eiaNumber,
    eiaName: newData.eia && newData.eiaNameText,
    openAccount: newData.openEiaAccount ? "Y" : "N",
    policyProposalDeclined: newData.applicationFailed ? "Y" : "N",
    selectedInsRepository:
      newData.openEiaAccount &&
      newData.insrepo !== "Select insurance repository for EIA"
        ? newData.insrepo &&
          newData.insrepo.replace("- ", "").replace("&", "and")
        : null,
    hazardous: newData.hobbyHazard ? "Y" : "N",
    takeIntendActScuba:
      newData.hobbyHazard && newData.hazardous.Scuba_Underwater_diving
        ? "Scuba/underwater Diving"
        : null,
    takeIntendActMount:
      newData.hobbyHazard && newData.hazardous.Mountaineering_Rockclimbing
        ? "Mountaineering/Rock climbing"
        : null,
    takeIntendActSky:
      newData.hobbyHazard &&
      newData.hazardous.Sky_diving_Parachuting_Bungee_jumping
        ? "Sky Diving/Parachuting/Bungee"
        : null,
    takeIntendActWater:
      newData.hobbyHazard && newData.hazardous.Watersports_Rafting_Canoeing
        ? `Water sports \(rafting/canyoning\)`
        : null,
    takeIntendActParaglide:
      newData.hobbyHazard && newData.hazardous.Paragliding_Parasailing
        ? "Paragliding/Parasailing"
        : null,
    takeIntendActRacing:
      newData.hobbyHazard && newData.hazardous.Racingbikes_car_powerboats
        ? "Racing Bikes/Cars/Power Boat"
        : null,
    takeIntendActJump:
      newData.hobbyHazard && newData.hazardous.Flying_Handgliding
        ? "Jumping Flying/Hand Gliding"
        : null,
    takeIntendActOther:
      newData.hobbyHazard && newData.hazardous.Other_high_risk_sports_activities
        ? "Other high risk sports/activities"
        : null,
    isCriminal: newData.criminal ? "Y" : "N",
    isDrinker: newData.alcohol ? "Y" : "N",
    isBeer: newData.alcohol && newData.drinks.Beer ? "Y" : "N",
    isWine: newData.alcohol && newData.drinks.Wine ? "Y" : "N",
    isHardLiquor: newData.alcohol && newData.drinks.Hard_Liquor ? "Y" : "N",
    beerConsumedInMl: newData.drinks.Beer ? newData.bquantity : null,
    beerFreq: newData.drinks.Beer ? getFreq(newData.bdrinkFreq) : null,
    wineConsumedInMl: newData.drinks.Wine ? newData.wquantity : null,
    wineFreq: newData.drinks.Wine ? getFreq(newData.wdrinkFreq) : null,
    liquorConsumedInMl: newData.drinks.Hard_Liquor ? newData.hquantity : null,
    liquorFreq: newData.drinks.Hard_Liquor ? getFreq(newData.hdrinkFreq) : null,
    drugsInPast5Years: newData.illegalDrugs ? "Y" : "N",
    loseGain10kgs: newData.lostWeight ? "Y" : "N",
    remarksTxnEion: newData.lostWeight && newData.lostWeightDetail,
  };
};
export default healthDetailsTwo.reducer;
