/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, Redirect } from "react-router-dom";
import SecureLS from "secure-ls";
import PlanCard from "./../../components/PlanCard/PlanCard";
import { TransparentButton } from "../../components/StyledButton/Button";
import AddOn from "../../assets/img/addon.svg";
import Forward from "../../assets/img/forward.svg";
import LaxmiGreeting from "./../../components/LaxmiGreeting/LaxmiGreeting";
import { Button } from "./../../components/StyledButton/Button";
// import { useLocation } from "react-router-dom";
import CriticalPopup from "../../components/Popup/CriticalPopup";
import AddonCard from "./AddonCard";
import "styled-components/macro";
import { Error } from "../../components";
import { useDocumentTitle, useUrlQuery } from "../../custom-hooks";
import {
  getRiderDetails,
  setCameFromRiders,
  setPreviousSelectedQuoteId,
  setRiderDetails,
} from "../../modules/QuotePage/quotePageSlice";
import {
  riderSubmit,
  setPremium,
  setSelectedAddOns,
  clearResponse,
  updateSelected,
  updateSkip,
  setRiderGst,
} from "./RiderPage.slice";
import {
  setOpenKotakDiscount,
  setBackFromRiders,
} from "../../components/Popup/kotakDiscount.slice";
// import { useCustomLocation } from "./../../custom-hooks";
import { getAllData } from "../../api/getAllData";
import AllNewLoaderForRiderPage from "../../components/AllNewLoader/AllNewLoaderForRiderPage";
import "./riderPage.scss";
import { showCard } from "./helper";
import {
  saveAppNo,
  saveBiData,
  saveDetail,
  saveResponse,
} from "../EligibilityPage/eligibilityCard.slice";
import { createRegionData } from "../GreetingPage/greeting.slice";
import { useNavigation } from "../../custom-hooks/useNavigation";

import "styled-components/macro";
import SpuseAddonIcon from "../../assets/img/Spouse-addon";
import { updateQuotation } from "./serviceApi";
import { setOpenBajajDiscount } from "../../components/Popup/bajajDiscount.slice";
import { setOpenIciciDiscount } from "../../components/Popup/iciciDiscount.slice";
const RiderPage = ({ history }) => {
  useDocumentTitle("Life Term Plan Details");
  useNavigation();
  const searchParams = new URLSearchParams(window.location.search);
  const afflid = searchParams.get("afflid");
  const utm_medium = searchParams.get("utm_medium");
  const c_id = searchParams.get("c_id");
  const { customerDetails } = useSelector(({ greeting }) => greeting);
  // const { search } = useLocation();
  const enquiryId = useUrlQuery("enquiryId");
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [checkedDiabetic, setCheckedDiabetic] = useState(false);
  const [count, setCount] = useState(0);
  const [submit, setSubmit] = useState(false);
  const ls = new SecureLS();
  const { selectedQuote, riderDetails, isError, uploadStatus } = useSelector(
    state => state.quote,
  );

  const [comeBack, setComeBack] = useState(false);
  const { filter } = useSelector(({ quoteFilter }) => quoteFilter);
  const isDiabetic = customerDetails?.isDiabetic;
  useEffect(() => {
    if (isDiabetic === true) {
      setCheckedDiabetic(true);
    }
  }, [isDiabetic]);
  useEffect(() => {
    dispatch(getAllData(history, "/riderpage", enquiryId)).then(data => {
      if (!data?.last_visited?.save_logs) {
        updateQuotation({ requestType: "rider_landing", traceId: enquiryId });
      }
    });
    dispatch(setBackFromRiders(true));
    dispatch(setOpenBajajDiscount(false));
    dispatch(setOpenKotakDiscount(false));
    dispatch(setOpenIciciDiscount(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(saveDetail(false));
    dispatch(saveBiData(false));
    dispatch(saveAppNo(false));
    dispatch(createRegionData(false));
    dispatch(saveResponse(false));
  }, []);

  useEffect(() => {
    if (selectedQuote.insurance_code && uploadStatus) {
      dispatch(setPreviousSelectedQuoteId(selectedQuote.insurance_code));
      dispatch(
        getRiderDetails({
          termInsuId: selectedQuote.insurance_code,
          traceId: enquiryId || sessionStorage.getItem("traceId"),
        }),
      );
    }
    return () => {
      setRiderDetails([]);
    };
  }, [selectedQuote]);
  const [add_ons, setAdd_ons] = useState();
  const [additionalRiders, setAdditionalRiders] = useState(0);
  const [additionalGst, setAdditionalGst] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const { response, selectedAddOns, inbuiltCovers } = useSelector(
    state => state.rider,
  );

  useEffect(() => {
    if (triggerUpdate) {
      dispatch(
        updateSelected({
          traceId: enquiryId,
          termInsuId: selectedQuote?.insurance_code,
          ridersDetails: addOns,
          isDiabetic: checkedDiabetic,
        }),
      );
      setTriggerUpdate(false);
    }
  }, [triggerUpdate]);

  useEffect(() => {
    if (selectedAddOns.length) {
      setAddOns(
        selectedAddOns.map(item => ({
          rider_shortname: item.riderShortname || item.rider_shortname,
          rider_description: item.riderDescription || item.rider_description,
          riderId: item.riderId || item.riderId,
          rider_name: item.riderName || item.rider_name,
          riderPpt: item.riderPpt || item.ppt,
          sumAssured: item.riderSumAssured || item.sumAssured,
          term: item.riderTerm || item.term,
          premium: item.ridersPremiumAmount || item.premium,
          gst: item.gst,
        })),
      );
      setComeBack(true);
    } else {
      setAddOns([]);
      setComeBack(true);
    }
  }, [selectedAddOns]);

  const dispatch = useDispatch();
  // dispatch(setloaderForQuoteToRider(false));
  useEffect(() => {
    // const { riders } = riderDetails;
    setAdd_ons(riderDetails?.riders?.add_ons);
  }, [riderDetails]);

  useEffect(() => {
    if (response?.status) {
      dispatch(clearResponse());
      history.push({
        pathname: "/eligibilitypage",
        search: `${
          afflid
            ? "afflid=" + afflid + "&"
            : utm_medium
            ? "utm_medium=" + utm_medium + "&"
            : c_id
            ? "c_id" + c_id + "&"
            : ""
        }enquiryId=${enquiryId}`,
      });
    }
  }, [response, history]);
  const { premiumLoader } = useSelector(state => state.rider);
  const addOnContent =
    add_ons && selectedQuote?.company_alias !== "tata_aia_life" ? (
      <>
        <div className="heading__headTitleRight addOnTitle addOnHeading">
          <img src={AddOn} alt="addon" className="addonImg" />
          &nbsp;<span className="addOnLogoText">Add-ons available</span>
        </div>

        <div className="gst__price">
          All prices are inclusive of <span>GST</span>
        </div>

        <div className="addOnCardsPart">
          {add_ons?.map((item, index) => (
            <div
              className="addon_card"
              style={{
                display:
                  !showCard(
                    selectedQuote.company_alias,
                    item.rider_shortname,
                    item?.rider_max_maturity_age,
                    selectedQuote.differentTenureCU ||
                      filter.coverUpto === "Whole Life" ||
                      Number(filter.coverUpto.match(/(\d+)/)[0]),
                  ) && "none",
              }}
              key={index}
            >
              <AddonCard
                key={index}
                index={index}
                rider_name={item?.rider_name}
                rider_description={item?.rider_description}
                cover_amt_limit={item?.rider_max_cover_amt}
                cover_amt_min={item?.rider_min_cover_amt}
                term_min={item?.rider_min_term}
                term_limit={item?.rider_max_term}
                diabetic={item?.are_you_diabetics}
                setCount={setCount}
                count={count}
                status={riderDetails.status}
                riderAddOnsDetails={riderDetails.riders.add_ons}
                checkDiabetic={checkedDiabetic}
                setCheckDiabetic={setCheckedDiabetic}
                rider_shortname={item?.rider_shortname}
                totalPremium={item[`totalPremium_${item?.rider_shortname}`]}
                totalPremiumGst={
                  item[`totalPremiumGst_${item?.rider_shortname}`]
                }
                addOnsList={addOns}
                setAddOns={setAddOns}
                term_insu_id={item?.term_insu_id}
                riderId={item?.rider_id}
                rider_min_ppt={item?.rider_min_ppt}
                rider_max_ppt={item?.rider_max_ppt}
                setAdditionalRiders={setAdditionalRiders}
                additionalRiders={additionalRiders}
                setAdditionalGst={setAdditionalGst}
                selected={selectedQuote}
                setTriggerUpdate={setTriggerUpdate}
                maturity={item?.rider_max_maturity_age}
                comeBack={comeBack}
                setComeBack={setComeBack}
                coverAmountArray={item?.rider_cover_amount_array}
              />
            </div>
          ))}
          {selectedQuote.company_alias === "pnb_metlife" && (
            <PnbSpouseAddonDisclaimer />
          )}
        </div>
      </>
    ) : (
      <div style={{ textAlign: "center", marginTop: "150px" }}>
        {!riderDetails ? (
          <>
            <div className="fetching-riders" style={{ height: "auto" }}>
              {/* <p>We are fetching Add-ons for the selected plan.</p> */}
              {/* <Loader /> */}
              {/* { customerDetails?.workEmail?.toLowerCase() === "aarti@wipro.com" &&<AllNewLoaderForRiderPage contentKey="RidersPageLoader" />} */}
            </div>
          </>
        ) : (
          <div className="fetching-riders no-addon">
            <p>Sorry, No Add-ons found for this plan.</p>
          </div>
        )}
      </div>
    );

  const proceedButton = (
    <div className="detailsBottomBtn rider__floatingBtn btnWidth">
      <Button
        proceedBtn
        secondary
        lg
        padding
        style={{ fontSize: "14px" }}
        onClick={() => {
          dispatch(saveDetail(false));
          dispatch(saveBiData(false));
          dispatch(saveAppNo(false));
          dispatch(createRegionData(false));
          dispatch(saveResponse(false));
          const ridersDetails = addOns.map(item => {
            return {
              riderId: item.riderId,
              riderShortname: item.rider_shortname,
              riderTerm: item.term,
              riderSumAssured: item.sumAssured || "",
              ridersPremiumAmount: item.premium,
              gst: item.gst,
              riderPpt: item.riderPpt,
              riderName: item.rider_name,
            };
          });

          dispatch(setPremium(additionalRiders));
          dispatch(setRiderGst(additionalGst));
          dispatch(setSelectedAddOns(addOns));

          if (addOns.length) {
            dispatch(
              riderSubmit(
                {
                  termInsuId: selectedQuote.insurance_code,
                  traceId: enquiryId,
                  ridersDetails: JSON.stringify(ridersDetails),
                  isDiabetic: checkedDiabetic,
                },
                Number(selectedQuote.premium_amount) + Number(additionalRiders),
              ),
            );
            setSubmit(true);
          } else {
            dispatch(
              updateSkip({
                termInsuId: selectedQuote.insurance_code,
                traceId: enquiryId,
                ridersDetails: "[]",
                TotalPremiumAmount: Number(selectedQuote.premium_amount),
                isDiabetic: checkedDiabetic,
              }),
            );
            history.push({
              pathname: "/eligibilitypage",
              search: `${
                afflid
                  ? "afflid=" + afflid + "&"
                  : utm_medium
                  ? "utm_medium=" + utm_medium + "&"
                  : c_id
                  ? "c_id" + c_id + "&"
                  : ""
              }enquiryId=${enquiryId}`,
            });
          }
        }}
        disabled={premiumLoader?.includes(true)}
      >
        PROCEED TO FINAL STEP
        <br />
        {count >= 1 && (
          <p className="addons">
            {count === 1 ? `(WITH ${count} ADD-ON)` : `(WITH ${count} ADD-ONS)`}
          </p>
        )}
      </Button>
    </div>
  );
  const riderContent = (
    <h1 className="riderPage">
      <span className="skip-button desktopSkipRider">
        {addOns.length ? (
          <div className="skipToFinalButtonActive">
            <Button
              style={{
                width: "185px",
                height: "50px",
                fontSize: "12px",
                marginTop: 0,
                padding: "10px 0px",
                lineHeight: "14px",
              }}
              proceedBtn
              secondary
              lg
              padding
              mt
              onClick={() => {
                const ridersDetails = addOns.map(item => {
                  return {
                    riderId: item.riderId,
                    riderShortname: item.rider_shortname,
                    riderTerm: item.term,
                    riderSumAssured: item.sumAssured || "",
                    ridersPremiumAmount: item.premium,
                    riderPpt: item.riderPpt,
                    gst: item.gst,
                    riderName: item.rider_name,
                  };
                });
                dispatch(setPremium(additionalRiders));
                dispatch(setRiderGst(additionalGst));
                dispatch(setSelectedAddOns(addOns));
                dispatch(
                  riderSubmit(
                    {
                      termInsuId: selectedQuote.insurance_code,
                      traceId: enquiryId,
                      ridersDetails: JSON.stringify(ridersDetails),
                      isDiabetic: checkedDiabetic,
                    },
                    Number(selectedQuote.premium_amount) +
                      Number(additionalRiders),
                  ),
                );
                setSubmit(true);
              }}
              disabled={premiumLoader?.includes(true)}
            >
              <span className="proceedTextRider"> PROCEED TO FINAL STEP</span>

              <br className="prcdBtnBr" />

              {count >= 1 && (
                <p className="addons">
                  {count === 1
                    ? `(WITH ${count} ADD-ON)`
                    : `(WITH ${count} ADD-ONS)`}
                </p>
              )}
            </Button>
          </div>
        ) : (
          <div className="skipToFinalButton">
            <TransparentButton
              rider
              onClick={() => {
                dispatch(saveDetail(false));
                dispatch(saveBiData(false));
                dispatch(saveAppNo(false));
                dispatch(createRegionData(false));
                dispatch(saveResponse(false));
                dispatch(
                  updateSkip({
                    termInsuId: selectedQuote.insurance_code,
                    traceId: enquiryId,
                    ridersDetails: "[]",
                    TotalPremiumAmount: Number(selectedQuote.premium_amount),
                    isDiabetic: checkedDiabetic,
                  }),
                );
                history.push({
                  pathname: "/eligibilitypage",
                  search: `${
                    afflid
                      ? "afflid=" + afflid + "&"
                      : utm_medium
                      ? "utm_medium=" + utm_medium + "&"
                      : c_id
                      ? "c_id" + c_id + "&"
                      : ""
                  }enquiryId=${enquiryId}`,
                });
              }}
            >
              SKIP TO FINAL STEP
            </TransparentButton>
          </div>
        )}
      </span>
      <div
        className="rider__back"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0",
          width: "97%",
          zIndex: "9",
        }}
      >
        <span className="backBtnPc">
          <div
            className="btn--tertiary backBtn"
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={e => {
              e.preventDefault();
              // history.goBack();
              history.replace(`/quotes?enquiryId=${enquiryId}`);
              // {
              // 	pathname: "/quotes",
              // 	search: `enquiryId=${sessionStorage.getItem("traceId")}`,
              // }
              dispatch(setCameFromRiders(true));
            }}
          >
            <svg
              style={{ height: "15px" }}
              xmlns="http://www.w3.org/2000/svg"
              className="backSvg"
              viewBox="0 0 24 24"
              fill="#7a7d80"
            >
              <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <div className="svgBackBtn">Back</div>
          </div>
        </span>
      </div>
      <div className="container riderConatiner">
        <LaxmiGreeting />
        <div className="rider__header">
          <div className="rider__rider">
            <h2 className="heading__secondary">
              Great choice, customize your plan with Add-ons
            </h2>
          </div>
        </div>
        <div className="rider__body__m">
          <div className="rider__side rSide">
            <div className="heading__headTitleLeft heading__headTitleLeftRider">
              Your Selected Plan
            </div>
            {addOns.length ? (
              <div
                className="skipToFinalButtonActive skipTablet"
                css={`
                  margin-top: -24px;
                  right: 29px;
                `}
              >
                <Button
                  style={{
                    width: "185px",
                    height: "50px",
                    fontSize: "12px",
                    marginTop: 0,
                    padding: "10px 0px",
                    lineHeight: "14px",
                  }}
                  proceedBtn
                  secondary
                  lg
                  padding
                  mt
                  onClick={() => {
                    const ridersDetails = addOns.map(item => {
                      return {
                        riderId: item.riderId,
                        riderShortname: item.rider_shortname,
                        riderTerm: item.term,
                        riderSumAssured: item.sumAssured || "",
                        ridersPremiumAmount: item.premium,
                        riderPpt: item.riderPpt,
                        gst: item.gst,
                        riderName: item.rider_name,
                      };
                    });
                    dispatch(setPremium(additionalRiders));
                    dispatch(setRiderGst(additionalGst));
                    dispatch(setSelectedAddOns(addOns));
                    dispatch(
                      riderSubmit(
                        {
                          termInsuId: selectedQuote.insurance_code,
                          traceId: enquiryId,
                          ridersDetails: JSON.stringify(ridersDetails),
                          isDiabetic: checkedDiabetic,
                        },
                        Number(selectedQuote.premium_amount) +
                          Number(additionalRiders),
                      ),
                    );
                    setSubmit(true);
                  }}
                  disabled={premiumLoader?.includes(true)}
                >
                  <span className="proceedTextRider">
                    PROCEED TO FINAL STEP
                  </span>

                  <br className="prcdBtnBr" />

                  {count >= 1 && (
                    <p className="addons">
                      {count === 1
                        ? `(WITH ${count} ADD-ON)`
                        : `(WITH ${count} ADD-ONS)`}
                    </p>
                  )}
                </Button>
              </div>
            ) : (
              <div
                onClick={() => {
                  dispatch(
                    updateSkip({
                      termInsuId: selectedQuote.insurance_code,
                      ridersDetails: "[]",
                      traceId: enquiryId,
                      TotalPremiumAmount: Number(selectedQuote.premium_amount),
                      isDiabetic: checkedDiabetic,
                    }),
                  );
                  history.push({
                    pathname: "/eligibilitypage",
                    search: `${
                      afflid
                        ? "afflid=" + afflid + "&"
                        : utm_medium
                        ? "utm_medium=" + utm_medium + "&"
                        : c_id
                        ? "c_id" + c_id + "&"
                        : ""
                    }enquiryId=${sessionStorage.getItem("traceId")}`,
                  });
                }}
                className="skipTablet"
              >
                SKIP TO FINAL STEP
                <img src={Forward} alt="forward" className="fowrardSvg" />
              </div>
            )}

            <PlanCard
              selectedQuote={selectedQuote}
              inbuiltCovers={inbuiltCovers}
              buyNowBtn={true}
              history={history}
              ls={ls}
              nextPage="Eligibilitypage"
              addOnsList={addOns}
              additionalRiders={additionalRiders}
              additionalGst={additionalGst}
            />
            <div className="nonTabletUi">{proceedButton}</div>
          </div>
          <div className="rider__main rider__main">{addOnContent}</div>
          <div className="tabletUi">{proceedButton}</div>
        </div>
        <CriticalPopup />
      </div>
      {/* {isLoading || premiumLoader?.length ? (
        // <AllNewLoaderForRiderPage contentKey="RidersPageLoader" />
        // <Loader />
      ) : (
        <></>
      )} */}
      {<AllNewLoaderForRiderPage contentKey="RidersPageLoader" />}
    </h1>
  );

  if (isError) return <Error errorMessage={isError} />;
  // else if (!filter.coverUpto && customerDetails?.workEmail?.toLowerCase() === "aarti@wipro.com")
  //   return <AllNewLoaderForRiderPage contentKey="RidersPageLoader" />;

  return riderContent;
};

export default props => {
  const { isDocumentUploaded, isProposalSubmitted, isPaymentSuccess } =
    useNavigation();
  if (isDocumentUploaded || isPaymentSuccess || isProposalSubmitted)
    return <AllNewLoaderForRiderPage contentKey="RidersPageLoader" />;
  return <RiderPage {...props} />;
};

function PnbSpouseAddonDisclaimer() {
  return (
    <div
      css={`
        background-color: #fff1eb;
        width: 100%;
        height: 54px;
        margin: 10px 9px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <SpuseAddonIcon />
      <div
        css={`
          font-size: 14px;
          color: #000;
          margin-left: 12px;
        `}
      >
        You can avail spouse and child education cover once you are redirected
        on to insurance company's portal.
      </div>
    </div>
  );
}
