import React from "react";
import CustomTooltip from "../../../../../components/Tooltip/CustomTooltip";
import "./SpecialOffer.scss";
import "styled-components/macro";
const BajajSpecialOffer = ({
  offer,

  cardType,
}) => {
  return (
    <div
      className={"excl-bannerr"}
      css={
        offer.length !== 3
          ? "excl-bannerr"
          : `@media screen and (max-width: 420px) {
          bottom: -80px !important;
        }`
      }
    >
      <div className="ribbon-banner">
        {"Special Offer"}

        <CustomTooltip
          id={`quoteCard_customTooltip_special_offer_${cardType}`}
          place={"left"}
          Position={{ top: 3, left: 0 }}
          customClassName="quotePageTooltip"
        >
          <span
            style={{ position: "relative", left: "2px", bottom: "2px" }}
            data-for={`quoteCard_customTooltip_special_offer_${cardType}`}
            data-tip={`<h3>${"Special Offers"}</h3><div>${"Use your corporate email ID and avail the exclusive offerings, specially curated for You!"}</div>`}
            data-html={true}
          >
            <span
              className="cardTooltipSvg"
              data-toggle="popover"
              title=""
              data-content='No KYC is applicable provided "know your customer" (KYC) details check is successful, subject to clear information being available with "Central KYC registry"'
              data-original-title="No KYC"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
              >
                <g transform="translate(-.5 -.5)">
                  <g transform="translate(1 1)">
                    <circle
                      cx="6"
                      cy="6"
                      r="6"
                      fill="none"
                      stroke="#000"
                    ></circle>
                    <path
                      fill="#000"
                      d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                      data-name="?-copy"
                      transform="translate(-2.056 -1.37)"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
          </span>
        </CustomTooltip>
      </div>
      <div
        className={`excl-banner-col`}
        // className={` ${
        //   offer.length === 3 ?  "excl-banner-col" :"excl1-banner-col"
        // }`}
        css={
          offer.length !== 3
            ? "excl-banner-col"
            : `@media screen and (max-width: 420px) {
                display: flex !important;
                flex-direction: column;
                align-items: flex-start;
            }`
        }
      >
        {console.log(
          "offer.length",
          window.matchMedia("(max-width: 420px)").matches,
          window.matchMedia("(max-width: 767px)").matches,
        )}
        {offer.map((item, index) => (
          <div className="excl-ul-banner">
            <li>
              {item}{" "}
              <CustomTooltip
                id={`quoteCard_customTooltip_banner_${item}_${index}_${cardType}`}
                place={
                  window.matchMedia("(max-width: 420px)").matches
                    ? "right"
                    : "left"
                }
                Position={{ top: 3, left: 0 }}
                mobilePlace1={"right"}
                customClassName="quotePageTooltip"
                //    css={`  .recommend-quote-card {
                //     & .excl-banner::before {
                //       bottom: 0px !important;
                //     }
                //   }
                // }`}
              >
                <span
                  style={{ position: "relative", left: "2px", bottom: "2px" }}
                  data-for={`quoteCard_customTooltip_banner_${item}_${index}_${cardType}`}
                  data-tip={
                    item === "No KYC"
                      ? `<h3>No KYC</h3><div> No KYC is applicable provided “Know your customer” (KYC) details check is successful, subject to clear information being available with “Central KYC Registry”</div>`
                      : item === "No Physical Medical"
                      ? `<h3>No Physical Medicals</h3><div> You don't need to undergo any physical medical check-ups, tele-medicals or video medicals if you are in pink of your health.`
                      : item === "Options of Video Medical"
                      ? `<h3>Option to switch to Tele Medicals</h3><div>You don't need to undergo any physical medical check-ups, tele-medicals or video medicals if you are in pink of your health.</div>`
                      : item === "No Income Proof"
                      ? `<h3>No Income Proof</h3><div>You are eligible for this benefit incase the CKY check is successful at insurance company's end profile is found favorable to issue the policy without income proofs.</div>`
                      : ""
                  }
                  data-html={true}
                >
                  <span
                    className="cardTooltipSvg"
                    data-toggle="popover"
                    title=""
                    data-content='No KYC is applicable provided "know your customer" (KYC) details check is successful, subject to clear information being available with "Central KYC registry"'
                    data-original-title="No KYC"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                    >
                      <g transform="translate(-.5 -.5)">
                        <g transform="translate(1 1)">
                          <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="none"
                            stroke="#000"
                          ></circle>
                          <path
                            fill="#000"
                            d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                            data-name="?-copy"
                            transform="translate(-2.056 -1.37)"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                </span>
              </CustomTooltip>
            </li>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BajajSpecialOffer;
