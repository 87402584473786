import React, { useEffect, useState } from "react";
import MobileKnowYOurPLanContent from "./MobileKnowYourPlanContent";

const MobileKnowYourPlan = ({
  inbuiltCoverages,
  additionalCoverages,
  additionalFeatures,
  onClick,
  tab,
}) => {
  const handleOpenTab = () => {
    if (openTab === 0) {
      document.querySelector("#gsfdg").classList.add("show");
      document.querySelector("#fda").classList.remove("show");
      document.querySelector("#afd").classList.remove("show");
    } else if (openTab === 1) {
      document.querySelector("#fda").classList.add("show");
      document.querySelector("#gsfdg").classList.remove("show");
      document.querySelector("#afd").classList.remove("show");
    } else if (openTab === 2) {
      document.querySelector("#afd").classList.add("show");
      document.querySelector("#gsfdg").classList.remove("show");
      document.querySelector("#fda").classList.remove("show");
    }
  };

  const [expandIcon, setExpandIcon] = useState(tab === 0 ? "minus" : "plus");
  const handleClick = () => {
    const openTabs = document.querySelectorAll(
      ".mobile-Knowmore .collapse.show",
    );
    openTabs.forEach(tab => tab.classList.remove("show"));
    setExpandIcon(expandIcon === "plus" ? "minus" : "plus");
    onClick();
    handleOpenTab();
  };

  useEffect(() => {
    const mobileKnowMore = document.querySelector(".mobile-Knowmore div");
    const tabs = mobileKnowMore.querySelectorAll(".knowyourplan__content");
    tabs.forEach(tab => tab.classList.add("show"));
    const gsfdg = document.querySelector("#gsfdg");
    gsfdg.classList.add("show");
  }, []);

  useEffect(() => {
    if (tab === 0) setExpandIcon("minus");
    else setExpandIcon("plus");
  }, [tab]);

  const [openTab, setOpenTab] = useState(0);

  useEffect(() => {
    handleOpenTab();
  }, [openTab]);

  return (
    <div>
      <button
        type="button"
        className={`knowyourplan__header ${expandIcon}`}
        onClick={handleClick}
        data-toggle="collapse"
        data-target="#knowyourplan__content"
      >
        Know Your Plan
      </button>

      <MobileKnowYOurPLanContent
        open={openTab === 0}
        onClick={() => {
          setOpenTab(0);
        }}
        title={"Inbuilt Coverages"}
        content={inbuiltCoverages}
        id={"gsfdg"}
      />
      {!!additionalCoverages?.length ? (
        <MobileKnowYOurPLanContent
          open={openTab === 1}
          onClick={() => {
            setOpenTab(1);
          }}
          title={"Accidental Coverages"}
          content={additionalCoverages}
          id={"fda"}
        />
      ) : (
        <div id="fda"></div>
      )}
      <MobileKnowYOurPLanContent
        open={openTab === 2}
        onClick={() => {
          setOpenTab(2);
        }}
        title={"Additional Features"}
        content={additionalFeatures}
        id={"afd"}
      />
    </div>
  );
};

export default MobileKnowYourPlan;
