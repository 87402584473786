import React, { Fragment, useState } from "react";
import Popup from "./Popup";
import { TextInput, Form, FormGroup, Label } from "../Form/Form";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import sendTick from "../../assets/img/sendtick.svg";
import laxmi from "../../assets/img/girl-img.png";
// import SecureLS from "secure-ls";
import { sendQuoteToEmail } from "../../modules/QuotePage/quotePageSlice";
import "./email-popup.scss";
import { useCustomLocation } from "../../custom-hooks";
const EmailPopup = ({ show, onClose }) => {
  const { customerDetails } = useSelector(state => state.greeting);
  const [msg, setMsg] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(!customerDetails.email);
  const [emailError, setEmailError] = useState(false);
  // const ls = new SecureLS();
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const { enquiryId } = useCustomLocation();

  const onSubmit = data => {
    dispatch(
      sendQuoteToEmail({
        email: data.email,
        // user_journey_id: sessionStorage.getItem("traceId"),
        user_journey_id: enquiryId,
        name: customerDetails.firstName,
        page:
          window.location.pathname.toLowerCase().slice(1, 7) === "quotes"
            ? "plan"
            : "compare",
      }),
    );
    setMsg(true);
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 5000);
  };
  const content2 = (
    <MessageContainer>
      <img src={sendTick} alt="send Tick"></img>
      <CallText>
        Your Comparison has been successfully sent to your email id.
      </CallText>
    </MessageContainer>
  );
  const mobileTitle = (
    <div className="email-popup-mobile-title">
      <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20,4h-16c-1.1,0 -1.99,0.9 -1.99,2l-0.01,12c0,1.1 0.9,2 2,2h16c1.1,0 2,-0.9 2,-2v-12c0,-1.1 -0.9,-2 -2,-2Zm0,4l-8,5l-8,-5v-2l8,5l8,-5v2Z"
          fill="#333"
        ></path>
        <path fill="none" d="M0,0h24v24h-24Z"></path>
      </svg>
      <span>Email Quotes</span>
    </div>
  );
  const mobileModalBody = (
    <div className="email-popup-mobile-modal-body">
      <div className="email-popup-row">
        <div className="modelWrap" style={{ display: msg ? "none" : "block" }}>
          <div className="expertImg">
            <img src={laxmi} alt="expert" />
            <span className="expertTxt sendQuotesPopupWrap">
              Hi {customerDetails.firstName}, comparison of life insurance plans
              will be shared with you shortly.
            </span>
          </div>
          <div className="expertForm">
            <form id="sendCompareForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group validExpertNo float-label">
                <input
                  onBlur={evt => {
                    evt.target.value.length
                      ? setIsEmailEmpty(false)
                      : setIsEmailEmpty(true);
                    if (evt.target.value.length === 0)
                      setEmailError("Email id is required");
                    else if (
                      !evt.target.value.match(
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      )
                    )
                      setEmailError("Please enter valid email id");
                    else setEmailError(false);
                  }}
                  name="workId"
                  autocomplete="off"
                  type="text"
                  defaultValue={customerDetails.email}
                  className={`form-control ${ emailError ? "email-error" : "" }`}
                  required={true}
                />
                <label for="workId">Email ID</label>
                <div className="fieldError">{emailError}</div>
              </div>
              <button
                type="submit"
                disabled={isEmailEmpty || emailError}
                className={emailError || isEmailEmpty ? "" : "active"}
              >
                Send
              </button>
            </form>
          </div>
        </div>
        <div
          className="sendQuotesSubmitWrap"
          style={{ display: msg ? "block" : "none" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: "0 auto", width: "100%" }}
            viewBox="0 0 24 24"
            fill="#4fcc6e"
            width="48px"
            height="48px"
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
          </svg>
          <div className="callMeText">
            Your comparison have been successfully sent to your email id.
          </div>
        </div>
      </div>
    </div>
  );
  const content1 = (
    <Fragment>
      {mobileTitle}
      {mobileModalBody}
      <Content1 emailError={emailError} className="email-popup-content-1">
        <LogoTextWrap className="email-popup-logo-text-wrap">
          <div className="contact__imgbox">
            <ContactImg src={laxmi} alt="Lakshmi"></ContactImg>
          </div>
          <ContactText>
            <p className="email-popup-text">
              Hi {customerDetails.firstName}, comparison of life insurance plans
              will be shared with you shortly.
            </p>
          </ContactText>
        </LogoTextWrap>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{ margin: "21px auto 63px auto" }}
        >
          <FormGroup
            lg
            style={{
              width: "346.64px",
            }}
          >
            <TextInput
              className={`email-popup-input ${ emailError ? "email-popup-input-error" : ""
                }`}
              lg
              type="email"
              name="email"
              placeholder=" "
              ref={register({
                required: "Email id is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter valid email id",
                },
              })}
              defaultValue={customerDetails.email}
              error={errors?.email && "danger"}
              onBlur={evt => {
                evt.target.value.length
                  ? setIsEmailEmpty(false)
                  : setIsEmailEmpty(true);
                if (evt.target.value.length === 0)
                  setEmailError("Email id is required");
                else if (
                  !evt.target.value.match(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  )
                )
                  setEmailError("Please enter valid email id");
                else setEmailError(false);
              }}
            />
            <Label lg htmlFor="email">
              Email ID
            </Label>
            {emailError && (
              <p
                style={{
                  fontSize: "16px",
                  color: "#d43d3d",
                  fontFamily: "Inter-Regular",
                  marginTop: "4px",
                }}
                className="errorG"
              >
                {emailError}
              </p>
            )}
          </FormGroup>
          <FormGroup sm style={{ width: "auto" }}>
            <Button
              type="submit"
              isEmailEmpty={isEmailEmpty || emailError}
              disabled={isEmailEmpty || emailError}
            >
              Send
            </Button>
          </FormGroup>
        </Form>
      </Content1>
      <Content2 className="email-popup-footer">
        <p>*Please note that the premium may vary in future.</p>
      </Content2>
    </Fragment>
  );
  return (
    <Popup
      customClassPopupC="email-popup-modal"
      customClass="email-popup"
      height={"auto"}
      width="640px"
      show={show}
      onClose={onClose}
      content={msg ? content2 : content1}
      topFixed={!emailError ? "195px" : "204px"}
      closeButtonStyle={{
        marginRight: "13px",
        marginTop: "1px",
      }}
      closeBtnClass="email-popup-close-btn"
    />
  );
};

export default EmailPopup;

const CallText = styled.div`
  width: 340px;
  margin: 7px auto 37px;
  text-align: center;
  font-family: "Inter-Regular";
  font-size: 16px;
  color: #000000;
`;
const MessageContainer = styled.div`
  padding: 10px;
  & img {
    margin: 0 auto;
    width: 100%;
    height: 48px;
  }
`;

const Content1 = styled.div`
  margin-top: 55px;
  margin-bottom: ${ props => (props.emailError ? "91px" : "67px") };
  height: auto;
  width: 468px;
  margin-left: auto;
  margin-right: auto;
`;
const LogoTextWrap = styled.div`
  margin-bottom: 21px;
`;
const Content2 = styled.div`
  text-align: center;
  color: #333;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 500;
  background-color: #f1f2f6;
  height: 74px;
  line-height: 20px;
  padding: 25px 0;
  border-top: 1px solid #e5e5e5;
`;
const ContactImg = styled.img`
  width: 70px;
  height: 70px;
  display: block;
  float: left;
  border: solid 1px #e3e4e8;
  border-radius: 50%;
`;

const ContactText = styled.div`
  & p {
    position: relative;
    padding: 10px 14px;
    padding-top: 0;
    font-family: "Inter-Regular";
    display: block;
    ${ "" /* padding-left: 86px; */ }
    padding-left: 76px;
    width: 100%;
    font-size: 16px;
    color: #333;
    line-height: 24px;
  }
  padding: 10px;
  font-weight: 400;
  font-family: Inter-Regular;
  font-size: 16px;
  margin-bottom: 20px;
  color: #111;
`;
// const Button = styled.button`
//   display: inline-block;
//   border-radius: 5px;
//   padding: 0px 25px;
//   box-sizing: content-box;
//   font-size: 17px;
//   background-color: #f2581b;
//   font-weight: 600;
//   border: none;
//   color: white;
//   height: 60px;
// `;

const Button = styled.button`
  width: 112px;
  height: 60px;
  border-radius: 4px;
  border: ${ props => (props.isEmailEmpty ? "solid 1px #e3e4e8" : "none") };
  background-color: ${ props => (props.isEmailEmpty ? "#dcdde0" : "#f2581b") };
  color: ${ props => (props.isEmailEmpty ? "#969799" : "#ffffff") };
  font-family: "Inter-SemiBold";
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;
