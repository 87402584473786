import React, { useEffect, useState, useRef } from "react";
import SearchBox from "../../SearchBox/SearchBox";

import {
  numberToDigitWord,
  numberToWords,
} from "../../../modules/QuoteFilter/helper";

import "./drop-down-items.scss";

function DropDownItems({
  items,
  customInput,
  min,
  max,
  multiple,
  selected,
  handleClick,
  searchable,
  notAllowed,
  addOnBtn,
  roundTo,
  scrollActiveToMiddle,
  dropDownRef,
  searchStartsWith,
  annualIncome = false,
  setOpen,
  wholeLife,
}) {
  const [list, setList] = useState(items);
  const [noResult, setNoResult] = useState("");

  const [toMiddle, setToMiddle] = useState(scrollActiveToMiddle);

  const [inWords, setInWords] = useState("");
  const [filterUpdated, setFilterUpdated] = useState(false);

  const handleSearchChange = (value = "") => {
    let filteredItems = [];
    if (!searchStartsWith)
      filteredItems = items.filter(item =>
        item.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
      );
    if (searchStartsWith)
      filteredItems = items.filter(item =>
        item.toLocaleLowerCase().startsWith(value.toLocaleLowerCase()),
      );
    if (annualIncome && value === "0") {
      setList([]);
      setNoResult(value);
      return;
    }
    if (filteredItems.length > 0) {
      setList(filteredItems);
      setFilterUpdated(true);
    } else {
      setList([]);
      setNoResult(value);
    }
  };

  useEffect(() => {
    if (filterUpdated) {
      setActiveItem(0);
      setFilterUpdated(false);
    }
  }, [filterUpdated]);

  const handleInputChange = evt => {
    const enteredValue = evt.target.value;
    const value = enteredValue.replaceAll(",", "");
    if (isNaN(value)) evt.target.value = "";
    else {
      evt.target.value = Number(value).toLocaleString("en-IN");
      setInWords(numberToWords(value));
      if (Number(value) >= min && Number(value) <= max) {
        setList([numberToDigitWord(value, multiple, roundTo)]);
      } else if (value === "") {
        setList(items);
        setInWords("");
      } else {
        setList([value]);
        // setNoResult(
        //   `Please enter between ${numberToDigitWord(
        //     min,
        //     multiple,
        //   )} to ${numberToDigitWord(
        //     max,
        //     multiple,
        //   )} in multiples of ${multiple}`,
        // );
      }
    }
  };

  const dropDownOptionsRef = useRef(null);
  const [activeItem, setActiveItem] = useState(list.indexOf(selected));

  useEffect(() => {
    if (!filterUpdated) {
      setActiveItem(list.indexOf(selected));
    }
  }, [list, selected]);

  const handleKeyDown = evt => {
    evt.stopPropagation();
    setToMiddle(false);
    if (!searchable) evt.preventDefault();
    switch (evt.key) {
      case "ArrowDown":
        if (activeItem === list.length - 1) setActiveItem(0);
        else setActiveItem(activeItem + 1);
        break;
      case "ArrowUp":
        if (!activeItem) setActiveItem(list.length - 1);
        else setActiveItem(activeItem - 1);
        break;
      case "Enter":
        if (list.length > 0) {
          handleClick(list[activeItem]);
          setTimeout(() => {
            dropDownRef.current.focus();
          }, 0);
        }
        break;
      default:
        break;
    }
  };

  const itemsRef = useRef(null);

  useEffect(() => {
    if (!searchable) itemsRef.current.focus();
    if (dropDownOptionsRef.current) {
      dropDownOptionsRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeItem]);

  return (
    <div
      className={`drop-down-items ${
        addOnBtn ? "drop-down-items--with-btn" : ""
      }`}
      tabIndex={1}
      ref={itemsRef}
      onKeyDown={handleKeyDown}
    >
      {(customInput || searchable) && (
        <div
          className="drop-down-items__search"
          style={{ marginBottom: `${customInput ? "0" : "10px"} ` }}
        >
          {customInput ? (
            <>
              <input
                type="text"
                placeholder="Custom Value"
                maxLength="14"
                autoFocus
                style={{ width: "100%" }}
                onChange={evt => handleInputChange(evt)}
              />
              <p
                style={{
                  color: "#000",
                  whiteSpace: "pre-wrap",
                  fontFamily: "Inter-Medium",
                  marginBottom: "0",
                  fontSize: "12px",
                }}
              >
                {inWords}
              </p>
            </>
          ) : (
            <SearchBox handleChange={handleSearchChange} defaultValue="" />
          )}
        </div>
      )}
      <div
        className={`drop-down-items__overflow ${
          addOnBtn ? "drop-down-items__overflow--with-btn" : ""
        }`}
      >
        {notAllowed && (
          <div
            className={`${
              notAllowed === selected && "drop-down-items__item--selected"
            } drop-down-items__item--not-allowed`}
          >
            {notAllowed}
          </div>
        )}
        {list.length > 0 ? (
          list.map((item, id) => (
            <div
              tabIndex="0"
              // onBlur={() => {
              //   if (id === list.length - 1) {
              //     setOpen(false);
              //   }
              // }}
              onFocus={() => {
                setActiveItem(id);
              }}
              key={item}
              style={{ whiteSpace: "pre-wrap" }}
              onClick={() => handleClick(item)}
              ref={
                id === (toMiddle ? activeItem + 1 : activeItem)
                  ? dropDownOptionsRef
                  : null
              }
              className={`${
                id === activeItem
                  ? "drop-down-items__item--selected"
                  : "drop-down-items__item"
              } `}
            >
              {item}
            </div>
          ))
        ) : customInput ? (
          <div
            className="drop-down-items__no-results"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {noResult}
          </div>
        ) : (
          <div
            className="drop-down-items__no-results"
            style={{ whiteSpace: "pre-wrap" }}
          >{`"${noResult}" not found`}</div>
        )}
        {wholeLife && (
          <div
            tabIndex="0"
            // onBlur={() => {
            //   if (id === list.length - 1) {
            //     setOpen(false);
            //   }
            // }}
            onFocus={() => {
              setActiveItem("Whole Life");
            }}
            ref={
              "Whole Life" === (toMiddle ? activeItem + 1 : activeItem)
                ? dropDownOptionsRef
                : null
            }
            key={"Whole Life"}
            className={`${
              "Whole Life" === activeItem
                ? "drop-down-items__item--selected"
                : "drop-down-items__item"
            } `}
            style={{ whiteSpace: "pre-wrap" }}
            onClick={() => handleClick("Whole Life")}
          >
            Whole Life
          </div>
        )}
      </div>
      {addOnBtn && addOnBtn}
    </div>
  );
}

export default DropDownItems;
