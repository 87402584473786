import React, { Fragment, useState } from "react";
import Popup from "./Popup";
import styled from "styled-components";
import { Button } from "../StyledButton/Button";
import { useSelector, useDispatch } from "react-redux";
// import sendTick from "../../assets/img/sendtick.svg";
import SecureLS from "secure-ls";
import laxmiImg from "../../assets/img/girl-img.png";
import Share from "../../assets/img/share.svg";
import { sendQuoteToEmail } from "../../modules/QuotePage/quotePageSlice";
import "./send-quotes.scss";
import { useCustomLocation } from "../../custom-hooks";
const SendQuotes = ({ show, onClose }) => {
  const { customerDetails } = useSelector(state => state.greeting);
  const { enquiryId } = useCustomLocation();
  const [msg, setMsg] = useState(false);
  const ls = new SecureLS();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      sendQuoteToEmail({
        email: customerDetails.email,
        // user_journey_id: sessionStorage.getItem("traceId"),
        user_journey_id: enquiryId,
        name: customerDetails.firstName,
        page:
          window.location.pathname.toLowerCase().slice(1, 7) === "quotes"
            ? "plan"
            : "compare",
      }),
    );
    setMsg(true);
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 5000);
  };
  const content2 = (
    <MessageContainer className="send-quotes-message-container ">
      {/* <div className="send-quotes-send-tick-svg">
			<img style={{ height: "48px", width: "48px" }} src={sendTick} alt="send Tick"></img>
		</div>
		<CallText>
			<p>
				Thank you! Your quotes has been sent via SMS on
				<span className="send-quotes-bold send-quotes-phone">{customerDetails.mobileNo}</span> and also email to
				<span className="send-quotes-bold send-quotes-email"> {customerDetails.email}</span>
			</p>
		</CallText> */}
      <div className="sendQuotesModelWrap sendSuccesWrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "0 auto", marginBottom: "6px", width: "100%" }}
          viewBox="0 0 24 24"
          fill="#4fcc6e"
          width="48px"
          height="48px"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
        </svg>

        <div className="expertImg">
          <img src={laxmiImg} alt="expert" />
          <span className="expertTxt sendQuotesPopupWrap">
            Thank you! Your quotes has been sent via SMS on{" "}
            <span>{customerDetails.mobileNo}</span> and also email to{" "}
            <span>{customerDetails.email}</span>
          </span>
        </div>
      </div>
    </MessageContainer>
  );
  const content1 = (
    <Fragment>
      <Content1>
        <SendLogo>
          <img className="smallSend" src={Share} alt="share" />
          &nbsp; Send Quotes
        </SendLogo>

        <div className="contact__imgbox">
          <ContactImg src={laxmiImg} alt="Lakshmi"></ContactImg>
        </div>
        <ContactText>
          <p className="send-quotes-text">
            Hi {customerDetails.firstName}, we will send you this quote via SMS
            on{" "}
            <span className="send-quotes-bold send-quotes-phone">
              {customerDetails.mobileNo}
            </span>{" "}
            and also email to
            <span className="send-quotes-bold send-quotes-email">
              {" "}
              {customerDetails.email}
            </span>
          </p>
        </ContactText>

        <div className="u-tcenter">
          <Button
            className="send-quotes-btn"
            secondary
            padding
            lg
            onClick={handleSubmit}
          >
            SEND
          </Button>
        </div>
      </Content1>
      <Content2>
        <p>*Please note that the premium may vary in future.</p>
      </Content2>
    </Fragment>
  );
  let width;
  let height;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    height = "100%";
  } else {
    width = "640px";
    height = "326px";
  }

  return (
    <Fragment>
      <Popup
        sendQuotes={"true"}
        lrgClose="true"
        height={msg ? "205px" : height}
        width={width}
        show={show}
        onClose={onClose}
        content={msg ? content2 : content1}
        topFixed={msg ? "129px" : "178px"}
        closeBtnClass="send-quotes-close"
      ></Popup>
    </Fragment>
  );
};

export default SendQuotes;

const CallText = styled.div`
  width: 340px;
  margin: 7px auto 37px;
  text-align: center;
  font-family: "Inter-Regular";
  font-size: 16px;
  color: #000000;
`;
const MessageContainer = styled.div`
  padding: 10px;
  & img {
    margin: 0 auto;
    width: 100%;
    height: 48px;
  }
  @media (max-width: 767px) {
    margin-top: 100px;
  }
`;

const Content1 = styled.div`
  height: 250px;
  padding: 55px 85px 45px 85px;
  @media (max-width: 767px) {
    .contact__imgbox {
      display: flex;
      margin-top: 26px;
      justify-content: center;
      & img {
        margin-right: unset;
      }
    }
    display: flex;
    flex-direction: column;
    padding: 10px 15px 25px 15px;
    margin-left: 0px;
    min-width: 95vw;
  }
  @media (max-width: 767px) {
    height: unset;
    padding: 10px 14px 25px 14px;
  }
`;
const Content2 = styled.div`
  & p {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    font-family: "Inter-Regular";
    @media (max-width: 767px) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: -35px;
      font-family: "Inter-Medium";
      font-size: 13px !important;
    }
  }
  height: 74px;
  padding: 25px 0;
  background-color: #f1f2f6;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  @media (max-width: 767px) {
    background-color: #fff;
    border-top: none;
    font-size: 13px;
    height: unset;
  }
`;
const ContactImg = styled.img`
  margin-right: 16px;
  width: 70px;
  height: 70px;
  display: block;
  float: left;
  border: solid 1px #e3e4e8;
  border-radius: 50%;
`;

const ContactText = styled.div`
  padding: 10px 23px;
  line-height: 24px;
  font-weight: 400;
  font-family: Inter-Regular;
  font-size: 14px;
  margin-bottom: 11px;
  color: #111;

  @media (max-width: 767px) {
    p {
      text-align: left;
      margin-bottom: -13px;
      span {
        font-size: 14px;
      }
    }
    padding: 12px 19px;
    margin-bottom: 12px;
  }
`;

const SendLogo = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block !important;
    position: relative;
    top: 3px;
    font-family: "Inter-SemiBold";
    font-size: 16px;
    line-height: 24px;
    color: #333;
  }
`;
