/* eslint-disable eqeqeq */
import {
  setTraceId,
  setUserId,
  updateUserData,
} from "../modules/GreetingPage/greeting.slice";
import {
  createArrayOfNumbers,
  getDefaultCoverAmount,
  numberToDigitWord,
} from "../modules/QuoteFilter/helper";
import {
  setCoverUptoValues,
  setDisplayNomineeGets,
  setFilter,
  setPayPremium,
} from "../modules/QuoteFilter/quoteFilterSlice";
import { singleProduct, getToken } from "../modules/QuoteFilter/serviceApi";
import {
  setIsError,
  setCorporateJourney,
  uploadSelectedQuote,
  setQuotes,
} from "../modules/QuotePage/quotePageSlice";
import {
  setInbuiltCovers,
  setPremium,
  setRiderGst,
  setSelectedAddOns,
} from "../modules/RiderPage/RiderPage.slice";
import {
  getRegion,
  saveAppNo,
  saveBiData,
  saveDetail,
  setIsSalaried,
} from "../modules/EligibilityPage/eligibilityCard.slice";
import { saveResponse } from "../modules/ProposalPage/IcicProposalPage/IcicProposal.slice";
import { getAge } from "../utils/inputUtils";
import { storeIciciProposalData } from "../modules/ProposalPage/IcicProposalPage/iciciProposalData";
import { saveReciept } from "../components/ReviewPopUp/payment.slice";
import { storeBajajProposalData } from "../modules/ProposalPage/BajaProposalPage/bajajProposalPage.slice";
import { storeKotakProposalData } from "../modules/ProposalPage/KotakLifeProposalPage/kotakProposalData";
import {
  setIsPaymentSuccess,
  setStatusLogged,
} from "../modules/EligibilityPage/eligibilityPage.slice";
import { setIsDocumentUploaded } from "../modules/DocumentUploadForm/documentUpload.slice";
import {
  setIsFeedbackGiven,
  setIsProposalSubmitted,
} from "../modules/ProposalPage/proposalPage.slice";
import { extractNumbers } from "../utils";
import { verified, notVerified, setCompany } from "../modules/otp/otp-slice";
import moment from "moment";
import { updateQuotation } from "../modules/EligibilityPage/serviceApi";

export const getRefreshToken =
  (history, pathname, traceId) => async dispatch => {
    try {
      const { data } = await getToken({ traceId });
      if (data && data.status) {
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("traceId", data.traceId);
        sessionStorage.setItem("userId", data.userId);
        dispatch(setUserId(data.userId));
        dispatch(setTraceId(data.traceId));
        dispatch(getAllData(history, pathname, traceId));
      }
    } catch (error) {
      dispatch(setIsError("Something went wrong while getting all data"));
    }
  };

export const getAllData = (history, pathname, traceId) => async dispatch => {
  if (
    !sessionStorage.getItem("token") ||
    !sessionStorage.getItem("traceId") ||
    !sessionStorage.getItem("userId")
  ) {
    return dispatch(getRefreshToken(history, pathname, traceId));
  }
  try {
    const {
      data: { singleProductData },
    } = await singleProduct({
      traceId: traceId || sessionStorage.getItem("traceId"),
    });
    const dob = singleProductData?.requestData?.data?.quote_data?.term_lead_dob;
    const age = moment().diff(moment(dob, "YYYY-MM-DD"), "year");
    const singleProductDataa =
      singleProductData?.requestData?.data?.selected_product;
    if (singleProductData?.is_ageChanged === true) {
      updateQuotation({
        age,
        dob,
        traceId: traceId || sessionStorage.getItem("traceId"),
        requestType: "get_products",
      }).then(() => {
        dispatch(setQuotes([]));
        // dispatch(setCoverAmount({ value: "₹ 1 Crore" }));
        // dispatch(setCoverUpto({ value: "60 years" }));
        // dispatch(setNomineeGets({ value: "Lumpsum" }));

        history.push({
          pathname: "/quotes",
          search: `enquiryId=${sessionStorage.getItem("traceId")}`,
        });
      });
    }
    if (
      window.location.pathname == "/riderPage" ||
      window.location.pathname == "/eligibilitypage"
    ) {
      if (singleProductDataa?.length == 0) {
        updateQuotation({
          traceId: traceId || sessionStorage.getItem("traceId"),
          requestType: "get_products",
        }).then(() => {
          dispatch(setQuotes([]));
          // dispatch(setCoverAmount({ value: "₹ 1 Crore" }));
          // dispatch(setCoverUpto({ value: "60 years" }));
          // dispatch(setNomineeGets({ value: "Lumpsum" }));
          history.push({
            pathname: "/quotes",
            search: `enquiryId=${sessionStorage.getItem("traceId")}`,
          });
        });
      }
    }
    if (!singleProductData.selectedProduct && history) {
      console.log("Entered");
      history.push({
        pathname: "/quotes",
        search: `enquiryId=${sessionStorage.getItem("traceId")}`,
      });
    }

    if (!singleProductData.requestData.data.last_visited && history) {
      history.push({
        pathname: "/quotes",
        search: `enquiryId=${traceId}`,
      });
    }
    console.log({
      isTrue1: singleProductData?.is_ageChanged === true,
      isTrue2:
        window.location.pathname == "/riderPage" ||
        window.location.pathname == "/eligibilitypage",
      isTrue2_1: singleProductDataa?.length == 0,
      isTrue3: !singleProductData.selectedProduct && history,
      isTrue4: !singleProductData.requestData.data.last_visited && history,
    });
    const details = singleProductData.requestData.data.quote_data;

    const { last_visited } = singleProductData.requestData.data;

    if (singleProductData) {
      const postPaymentJourney = JSON.parse(
        singleProductData?.proposal_details?.postPaymentJourney ||
          JSON.stringify({}),
      );

      if (last_visited?.customer_feedback === "true") {
        dispatch(setIsFeedbackGiven(true));
      }

      if (last_visited?.save_logs === "true") {
        dispatch(setStatusLogged(true));
      }

      //otp
      if (singleProductData?.selectedProduct?.company_alias) {
        let company = singleProductData?.selectedProduct?.company_alias;
        dispatch(setCompany({ company_alias: company }));
      }

      if (
        last_visited?.otp === "true" &&
        (singleProductData?.selectedProduct?.company_alias === "bajaj_life" ||
          singleProductData?.selectedProduct?.company_alias === "icici_pru")
      ) {
        dispatch(verified());
      }

      if (
        last_visited?.otp === "false" ||
        (singleProductData?.selectedProduct?.company_alias !== "bajaj_life" &&
          singleProductData?.selectedProduct?.company_alias !== "icici_pru")
      ) {
        dispatch(notVerified());
      }
      //

      if (
        postPaymentJourney?.documentFormStatus ||
        last_visited?.document_upload === "true"
      )
        dispatch(setIsDocumentUploaded(true));

      if (
        postPaymentJourney?.proposalFormStatus ||
        last_visited?.proposal === "true"
      ) {
        dispatch(setIsProposalSubmitted(true));
      }
      if (
        singleProductData.requestData.data?.payment_details?.status ===
        "success"
      ) {
        dispatch(setIsPaymentSuccess(true));
      }
      if (
        singleProductData.requestData.data?.selected_product?.isMaxLifeDiscount
      ) {
        dispatch(
          setIsSalaried(
            singleProductData.requestData.data?.selected_product
              ?.isMaxLifeDiscount,
          ),
        );
      }
      if (
        singleProductData.requestData.data?.selected_product
          ?.isKotakLifeDiscount
      ) {
        dispatch(
          setIsSalaried(
            singleProductData.requestData.data?.selected_product
              ?.isKotakLifeDiscount,
          ),
        );
      }

      if (details.eligible_status) {
        const eligibilityDetails = {
          firstName: details.term_lead_first_name,
          lastName: details.term_lead_last_name,
          dob: details.term_lead_dob.split("-").reverse().join("/"),
          email: details.term_lead_workemail,
          mobileNo: details.term_lead_mobile,
          secondary_email: details.secondary_email_id,
          pan: details.pan_no,
          pinCode: details.pincode,
          selectedNatureOfWork: details.nature_of_work,
          // selectedEducation:
          //   singleProductData.selectedProduct.company_alias === "bajaj_life"
          //     ? getEducationFromCodeBajaj(details.term_education)
          //     : details.term_education,
          // selectedEducation: details.term_education,
          selectedEducation:
            details.termEducation_text || details.qualification,
          // selectedOccupation:
          //   singleProductData.selectedProduct.company_alias === "bajaj_life"
          //     ? getOccupFromCodeBajaj(details.term_occupation)
          //     : details.term_occupation,
          // selectedOccupation: details.term_occupation,
          selectedOccupation: details.termOccupation_text || details.occupation,

          selectedCountry: details.nri_country?.country_name,
          isHeartPatient: details.heart_patient,
          isNRI: details.nri,
          isAgreeTerm: details.eligible_status,
          age: details.term_lead_age,
          state: details.state,
          selectedArea: details.area,
          selectedCity: details.city,
        };

        if (details.pincode)
          dispatch(
            getRegion({
              pinCode: details.pincode,
              companyAlias: singleProductData.selectedProduct.company_alias,
              traceId: traceId || sessionStorage.getItem("traceId"),
            }),
          );

        if (singleProductData.requestData.data?.eligibility_details)
          dispatch(
            saveBiData({
              status: true,
              applicationNo:
                singleProductData.requestData.data?.eligibility_details
                  ?.applicationNo,
              biPdfUrl:
                singleProductData.requestData.data?.eligibility_details
                  ?.biPdfUrl,
              IpruRedirectionUrl:
                singleProductData.requestData.data?.eligibility_details
                  ?.IpruRedirectionUrl,
            }),
          );
        sessionStorage.setItem(
          "appNo",
          singleProductData.requestData.data?.eligibility_details
            ?.applicationNo,
        );
        dispatch(
          saveAppNo(
            singleProductData.requestData.data?.eligibility_details
              ?.applicationNo,
          ),
        );
        dispatch(saveDetail(eligibilityDetails));
      }
      dispatch(setInbuiltCovers(singleProductData.inbuiltCovers));
      dispatch(
        setSelectedAddOns(
          singleProductData?.requestData?.data?.added_rider?.rider_details ||
            [],
        ),
      );

      dispatch(
        setPremium(
          singleProductData?.requestData?.data?.added_rider?.rider_details?.reduce(
            (total, addOn) => total + Number(addOn.ridersPremiumAmount),
            0,
          ),
        ),
      );
      dispatch(
        setRiderGst(
          singleProductData.addOns.reduce(
            (total, addOn) => total + Number(addOn.gst),
            0,
          ),
        ),
      );
      let newFilters = {
        coverUpto:
          details.term_policy_term === 99 ||
          details.term_paytype_option === "WL"
            ? "Whole Life"
            : Number(details.term_policy_term) +
              getAge(details.term_lead_dob?.split("-").reverse().join("/")) +
              " years",
        paymentTerm: {
          option: details.term_pay_option,
          term:
            details.limited_pay_term === "PT_5"
              ? "PT - 5 Years"
              : details.limited_pay_term === "P60"
              ? "Pay till 60"
              : details.limited_pay_term
              ? details.limited_pay_term + " Years"
              : "5 Years",
        },
        morePlans:
          details.more_plans.type === "return_of_premium"
            ? "Return of Premium"
            : details.more_plans.type === "survival_benefits"
            ? "Survival Benefits"
            : "Choose your plans",
      };

      if (details.nominee_gets.type.toLowerCase() === "lumpsum") {
        newFilters = {
          ...newFilters,
          nomineeGets: "Lumpsum",
          coverAmount: numberToDigitWord(
            Number(details.term_sum_assured),
            50000,
            2,
          ),
          coverAmountLumpsumMonthly: {
            coverAmount: numberToDigitWord(
              String(
                getDefaultCoverAmount(
                  details.term_lead_age,
                  details.term_annual_income,
                  details.term_preferance,
                ),
              ),
            ),
            coverAmountPercent: "25%",
            increaseIncome: "Please select",
            additionalIncome: {
              amount: "50000",
              period: "1 years",
            },
          },
        };
        dispatch(
          setDisplayNomineeGets(
            numberToDigitWord(Number(details.term_sum_assured), 50000, 2),
          ),
        );
      } else if (details.nominee_gets.type.toLowerCase() === "monthly_income") {
        newFilters = {
          ...newFilters,
          nomineeGets: "Monthly",
          coverAmount: numberToDigitWord(
            String(
              getDefaultCoverAmount(
                details.term_lead_age,
                details.term_annual_income,
                details.term_preferance,
              ),
            ),
          ),
          coverAmountMonthly: {
            value: numberToDigitWord(
              Number(details.nominee_gets.monthly_income),
              Number(details.nominee_gets.monthly_income) < 100000
                ? 5000
                : 50000,
              2,
            ),
            term: details.nominee_gets.income_term + " years",
            increaseIncome: details.nominee_gets.increasing_monthly_income,
          },
          coverAmountLumpsumMonthly: {
            coverAmount: numberToDigitWord(
              String(
                getDefaultCoverAmount(
                  details.term_lead_age,
                  details.term_annual_income,
                  details.term_preferance,
                ),
              ),
            ),
            coverAmountPercent: "25%",
            increaseIncome: "Please select",
            additionalIncome: {
              amount: "50000",
              period: "1 years",
            },
          },
        };
        dispatch(
          setDisplayNomineeGets(
            numberToDigitWord(
              Number(details.nominee_gets.monthly_income),
              Number(details.nominee_gets.monthly_income) < 100000
                ? 5000
                : 50000,
              2,
            ) + "/month",
          ),
        );
      } else if (
        details.nominee_gets.type.toLowerCase() === "lumpsum_monthly_income"
      ) {
        newFilters = {
          ...newFilters,
          nomineeGets: "Lumpsum + Monthly Income",
          coverAmountLumpsumMonthly: {
            coverAmount: numberToDigitWord(
              Number(details.nominee_gets.cover_amount),
              50000,
              2,
            ),
            coverAmountPercent: details.nominee_gets.lumpsum_percentage + "%",
            increaseIncome:
              details.nominee_gets.lumpsum_increasing_monthly_income,
            additionalIncome: {
              amount: String(details.nominee_gets.monthly_income),
              period: String(details.nominee_gets.income_term),
            },
          },
          coverAmount: numberToDigitWord(
            String(
              getDefaultCoverAmount(
                details.term_lead_age,
                details.term_annual_income,
                details.term_preferance,
              ),
            ),
          ),
        };
        dispatch(
          setDisplayNomineeGets(
            `${numberToDigitWord(
              Number(
                details.nominee_gets.lumpsum_percentage === "100"
                  ? details.nominee_gets.cover_amount
                  : (details.nominee_gets.cover_amount *
                      details.nominee_gets.lumpsum_percentage) /
                      85,
              ),
              1,
              2,
            )} (lumpsum) + ${numberToDigitWord(
              details.nominee_gets.monthly_income,
              1,
              2,
            )}/month. for ${details.nominee_gets.income_term} ${
              details.nominee_gets.income_term === 100 ? "months" : "years"
            } `,
          ),
        );
      }
      const customerDetails = {
        firstName: details.term_lead_first_name,
        lastName: details.term_lead_last_name,
        dob: details.term_lead_dob?.split("-")?.reverse().join(" / "),
        gender: details.term_lead_gender,
        email: details.term_lead_email,
        mobileNo: details.term_lead_mobile,
        tobaccoHabit: details.term_tobacco_habit,
        annualIncome: details.term_annual_income,
        preferance: details.term_preferance,
        age: details.term_lead_age,
        qualification: details.qualification,
        termOccupation: details?.term_occupation,
        workEmail: details.work_email,
        companyName: details?.CorporateName,
        traceId: singleProductData?.requestData?.enquiry_id,
        userId: singleProductData?.requestData?.user_id,
        isDiabetic: details?.isDiabetic,
        whatsapp: singleProductData?.is_whatsapp == "Yes" ? true : false,
      };
      dispatch(updateUserData(customerDetails));
      dispatch(setFilter(newFilters));
      if (newFilters.paymentTerm.option === "Limited Pay") {
        dispatch(
          setCoverUptoValues(
            createArrayOfNumbers(
              parseInt(details.term_lead_age) +
                parseInt(extractNumbers(newFilters.paymentTerm.term)),
              85,
              1,
              "years",
            ),
          ),
        );
      } else {
        dispatch(
          setCoverUptoValues(
            createArrayOfNumbers(
              parseInt(details.term_lead_age) + 5,
              85,
              1,
              "years",
            ),
          ),
        );
      }
      dispatch(
        setPayPremium(
          details.term_pay_frequency === "Half-Yearly"
            ? "Half Yearly"
            : details.term_pay_frequency,
        ),
      );
      if (singleProductData.selectedProduct)
        dispatch(
          uploadSelectedQuote({
            ...singleProductData.selectedProduct,
          }),
        );

      if (singleProductData.proposal_details) {
        dispatch(
          saveResponse({
            medicalVisit: singleProductData.proposal_details.medicalVisitType,
            medicalCenter: singleProductData.proposal_details.medicalCenterList,
            BREDecision: singleProductData.proposal_details.bREDecision,
          }),
        );
      }
      if (singleProductData.corporate_journey_data) {
        dispatch(setCorporateJourney(singleProductData.corporate_journey_data));
      }

      if (
        pathname?.toLowerCase() === "/proposalpage" ||
        pathname?.toLowerCase() === "/documentuploadpage"
      ) {
        switch (singleProductData.selectedProduct.company_alias) {
          case "icici_pru":
            dispatch(storeIciciProposalData(singleProductData));
            break;
          case "bajaj_life":
            dispatch(storeBajajProposalData(singleProductData));
            break;
          case "kotak_life":
            dispatch(storeKotakProposalData(singleProductData));
            break;
          default:
            break;
        }
      }

      if (singleProductData?.proposal_details?.receiptUrl) {
        dispatch(
          saveReciept({
            receipt_url: singleProductData.proposal_details.receiptUrl,
          }),
        );
      }
    }
    return {
      last_visited,
      payment_status:
        singleProductData?.requestData.data?.payment_details?.status,
      receipt_Url: singleProductData?.proposal_details?.receiptUrl,
      company_alias: singleProductData?.selectedProduct?.company_alias,
      app_no:
        singleProductData?.requestData?.data?.eligibility_details
          ?.applicationNo,
    };
  } catch (error) {
    dispatch(setIsError("Something went wrong while getting all data"));
  }
};
