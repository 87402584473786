/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { RecommendQuoteCard, CardHighlight } from "..";
import "styled-components/macro";
import { addQuoteToCompare, removeQuoteToCompare } from "../../quotePageSlice";
import Knowmore from "../../../../components/KnowMorePopup/knowmore";
import "./quote-card.scss";
import OnCompare from "./OnCompare";
import CriticalPopup from "../../../../components/Popup/CriticalPopup";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import { useWindowResize } from "../../../../custom-hooks";
import SwitchToMedical from "../SwitchToMedical/SwitchToMedical";
import PlanLabel from "../PlanLabel/PlanLabel";
import BajajSpecialOffer from "./SpecialOffer/BajajSpecialOffer";

function QuoteCard({
  logoText,
  logoUrl,
  price,
  id,
  coverUpto,
  nomineeGets,
  coverAmount,
  claimSettlementRatio,
  inBuiltRiders,
  additionalRiders,
  medicalExamination,
  history,
  onBuyNowClicked,
  setShowBenefitsPopup,
  showBenefitsPopup,
  recommended,
  payPremiumMode,
  childName,
  quote,
  showSwitchButton,
}) {
  const filteredInbuiltRiders = quote?.inbuilt_riders?.filter((item, idx) => {
    return true;
  });
  const { quotes } = useSelector(state => state.quote);

  const bajajVideoMdical = quotes?.find(
    item =>
      item.medicalCondition === "Video Medical" &&
      item.company_alias === "bajaj_life",
  );
  const bandhanNormalQuote = quotes?.find(
    item =>
      item?.company_alias === "bandhan_life" && item?.sumAssured <= "5000000",
  );
  const inBuiltRidersToolTip = (
    <ol type="1" style={{ marginLeft: "20px" }}>
      {filteredInbuiltRiders?.map((inbuilt_rider, idx) => {
        return <li key={idx}>{inbuilt_rider?.rider_name}</li>;
      })}
    </ol>
  );

  const additionalRidersToolTip = (
    <ol type="1" style={{ marginLeft: "20px" }}>
      {quote?.addon_riders?.map((addon_rider, idx) => (
        <li key={idx}>{addon_rider?.rider_name}</li>
      ))}
    </ol>
  );

  const { width } = useWindowResize();
  const quoteLength = width <= 767 ? 2 : 3;
  const quotesOnCompare = useSelector(({ quote }) => quote?.quotesOnCompare);
  const quotesForCompare = useSelector(({ quote }) => quote?.quotesForCompare);
  const { preferredInsurance, customerDetails, isPnbPreference } = useSelector(
    state => state.greeting,
  );
  const { eligibilityDetails } = useSelector(state => state?.eligibilityCard);
  const [checkForCompare, setCheckForCompare] = useState(
    quotesForCompare.includes(id),
  );
  const [showKnowMorePopup, setShowKnowMorePopup] = useState(false);
  const [offers, setOffers] = useState([]);
  const { switchToMedical } = useSelector(state => state.quote);
  const newSumAssured = bandhanNormalQuote?.sumAssured;
  let bajajOffers = ["No KYC"];
  // Check if all conditions for "No income proof required" are met
  const showNoIncomeProofRequired =
    ["Post Graduate", "Graduate"].includes(customerDetails.qualification) &&
    customerDetails?.annualIncome >= 500000 &&
    quote?.sumAssured >= 5000000 &&
    quote?.sumAssured <= 20000000 &&
    customerDetails.age >= 18 &&
    customerDetails.age <= 45;
  // Check if all conditions for "No physical medicals required" are met
  const showNoPhysicalMedicalsRequired =
    medicalExamination === "Tele Medical" ||
    medicalExamination === "Video Medical";
  if (showNoIncomeProofRequired) {
    bajajOffers.push("No Income Proof");
  }
  if (showNoPhysicalMedicalsRequired) {
    bajajOffers.push("No Physical Medical");
  }
  if (showSwitchButton && !showNoPhysicalMedicalsRequired)
    bajajOffers.push("Options of Video Medical");
  useEffect(() => {
    if (showNoPhysicalMedicalsRequired) {
      bajajOffers.push("No Physical Medical");
    }
    if (bajajOffers.includes("No Physical Medical")) {
      const index = bajajOffers.indexOf("Options of Video Medical");
      if (index !== -1) {
        bajajOffers.splice(index, 1);
      }
    }
  }, [switchToMedical]);
  useEffect(() => {
    let offers = [];
    const PHYSICAL_MEDICAL = "No Physical Medical Test";

    if (
      preferredInsurance.includes(quote?.company_alias) &&
      ["Post Graduate", "Graduate"].includes(customerDetails.qualification)
    ) {
      if (
        quote?.company_alias === "bajaj_life" &&
        customerDetails?.annualIncome >= 500000
      ) {
        if (
          switchToMedical == true &&
          bajajVideoMdical?.medicalCondition === "Video Medical"
        ) {
          offers.push("No Physical Medical Test");
        }
        if (customerDetails.age <= 55) {
          offers.push("No KYC");
          offers.push("No Income Proof");
        }
      }
      if (
        quote?.company_alias === "max_life" &&
        customerDetails?.annualIncome >= 500000 &&
        ["Post Graduate", "Graduate"].includes(customerDetails.qualification) &&
        (eligibilityDetails == false ||
          customerDetails?.termOccupation == "Salaried")
      ) {
        if (medicalExamination === "Video Medical") {
          offers.push(PHYSICAL_MEDICAL);
        }
        if (
          (customerDetails.age <= 41 || customerDetails.age <= 45) &&
          quote?.sumAssured <= 15000000
        ) {
          offers.push("No KYC");
          offers.push("No Income Proof");
        } else if (customerDetails.age <= 40 && quote?.sumAssured <= 10000000) {
          offers.push("No KYC");
          offers.push("No Income Proof");
        }
      }
      if (quote?.company_alias === "bandhan_life") {
        if (medicalExamination === "Video Medical") {
          offers.push(PHYSICAL_MEDICAL);
        }
        if (customerDetails.age <= 55 && newSumAssured >= 5000000) {
          offers.push("No Income Proof");
          offers.push("No KYC");
        } else if (
          customerDetails.age <= 55 &&
          coverAmount != "₹ 50 Lakh" &&
          coverAmount != "₹ 45 Lakh" &&
          coverAmount != "₹ 40 Lakh" &&
          coverAmount != "₹ 35 Lakh" &&
          coverAmount != "₹ 30 Lakh" &&
          coverAmount != "₹ 25 Lakh"
        ) {
          offers.push("No Medicals");
          offers.push("No Income Proof");
          offers.push("No KYC");
          offers.push("Instant Policy");
        }
      }
    }
    setOffers(offers);
  }, [switchToMedical]);
  const dispatch = useDispatch();

  const { filter } = useSelector(({ quoteFilter }) => quoteFilter);

  useEffect(() => {
    if (checkForCompare) dispatch(addQuoteToCompare(id));
    else dispatch(removeQuoteToCompare(id));
  }, [checkForCompare, id, dispatch]);

  useEffect(
    () => setCheckForCompare(quotesForCompare.includes(id)),
    [quotesForCompare, id],
  );

  const quoteCard = (
    <>
      {showSwitchButton && quote?.company_alias === "bajaj_life" ? (
        <SwitchToMedical key="medical" />
      ) : (
        <></>
      )}
      {quote?.company_alias === "max_life" && (
        <PlanLabel company={quote?.company_alias} recommended={recommended} />
      )}
      {quote?.company_alias === "hdfc_life" && (
        <PlanLabel company={quote?.company_alias} recommended={recommended} />
      )}
      {quote?.company_alias === "bandhan_life" &&
        quote?.sumAssured >= "2500000" &&
        quote?.sumAssured <= "5000000" && (
          <PlanLabel company={quote?.company_alias} recommended={recommended} />
        )}
      {quote?.company_alias === "icici_pru" && (
        <PlanLabel company={quote?.company_alias} recommended={recommended} />
      )}
      {quote?.company_alias === "bajaj_life" &&
        quote?.master_product_name === "eTouch" && (
          <PlanLabel company={quote?.company_alias} recommended={recommended} />
        )}
      <CriticalPopup />
      <CardHighlight
        text={
          quote?.planTenure === "same"
            ? "PLAN WITH SAME TENURE AND INCOME"
            : "PLAN WITH DIFFERENT TENURE AND INCOME"
        }
      />
      <div className="quote-card__top">
        <div className="quote-card__top-left">
          <div className={`quote-card__plan-logo ${quote?.company_alias}`}>
            <img src={logoUrl} alt="brand-logo" />
          </div>
          <div className="quote-card__logo-text">
            <div className="quote-card__title">
              {`${logoText ? logoText + " - " + childName : childName}`}
            </div>
            <div
              className="quote-card__know-more"
              onClick={() => {
                setShowKnowMorePopup(true);
              }}
            >
              Know more
            </div>
            <Knowmore
              logoUrl={logoUrl}
              onClose={setShowKnowMorePopup}
              show={showKnowMorePopup}
              price={`₹ ${Number(price).toLocaleString(
                "en-IN",
              )}${payPremiumMode}`}
              logoText={logoText}
              childName={quote?.child_product_name}
              history={history}
              onBuyNowClicked={onBuyNowClicked}
              coverUpto={coverUpto}
              nomineeGets={"₹50 LACKS (LUMPSUM)"}
              buynow={true}
              nextPage="riderpage"
              quote={quote}
              policyURL={quote?.brochure}
            />
          </div>
        </div>
        <div className="quote-card__top-right">
          {!recommended ? (
            <>
              <div>
                <div className="quote-card__price">
                  {`₹ ${Number(price).toLocaleString(
                    "en-IN",
                  )}${payPremiumMode}`}
                </div>
                {["kotak_life", "max_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes{" "}
                      <span>
                        {quote?.company_alias === "max_life"
                          ? "12% OFF"
                          : "5% OFF"}
                      </span>{" "}
                      for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Salaried Customers)
                    </p>
                  </>
                )}
                {["bajaj_life"].includes(quote.company_alias) &&
                  quote?.master_product_name !== "eTouch" && (
                    <>
                      <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                        Includes <span>11% OFF</span> for 1<sup>st</sup> year
                      </p>
                      <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                        (Upto 6% applied for online customers And Upto 5%
                        applied for salaried customers)
                      </p>
                    </>
                  )}
                {["bajaj_life"].includes(quote.company_alias) &&
                  quote?.master_product_name === "eTouch" && (
                    <>
                      <p class="cardTopRightOfferText cardTopRightOfferDeskTab cardGetUpTo20Percent">
                        {quote?.disclaimer} <span>{quote?.span}</span>
                        <CustomTooltip
                          id={"quoteCard_customTooltip_bajaj_eTouch"}
                          place={"left"}
                          Position={{ top: 3, left: 0 }}
                          customClassName="quotePageTooltip"
                        >
                          <span
                            style={{
                              position: "relative",
                              left: "5px",
                              top: "-1px",
                            }}
                            data-for={"quoteCard_customTooltip_bajaj_eTouch"}
                            data-tip={`<div>${quote?.tooltip}</div>`}
                            data-html={true}
                          >
                            <span
                              className="cardTooltipSvg"
                              data-toggle="popover"
                              title=""
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <g transform="translate(-.5 -.5)">
                                  <g transform="translate(1 1)">
                                    <circle
                                      cx="6"
                                      cy="6"
                                      r="6"
                                      fill="none"
                                      stroke="#5b6466"
                                    ></circle>
                                    <path
                                      fill="#5b6466"
                                      d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                      data-name="?-copy"
                                      transform="translate(-2.056 -1.37)"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </span>
                        </CustomTooltip>
                      </p>
                    </>
                  )}
                {["icici_pru"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab cardGetUpTo20Percent">
                      {quote?.disclaimer} <span>{quote?.span}</span>
                      <CustomTooltip
                        id={"quoteCard_customTooltip_icici_pru"}
                        place={"left"}
                        Position={{ top: 3, left: 0 }}
                        customClassName="quotePageTooltip"
                      >
                        <span
                          style={{
                            position: "relative",
                            left: "5px",
                            top: "-1px",
                          }}
                          data-for={"quoteCard_customTooltip_icici_pru"}
                          data-tip={`<div>${quote?.tooltip}</div>`}
                          data-html={true}
                        >
                          <span
                            className="cardTooltipSvg"
                            data-toggle="popover"
                            title=""
                            data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                            data-original-title="Nominee gets"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                            >
                              <g transform="translate(-.5 -.5)">
                                <g transform="translate(1 1)">
                                  <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    fill="none"
                                    stroke="#5b6466"
                                  ></circle>
                                  <path
                                    fill="#5b6466"
                                    d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                    data-name="?-copy"
                                    transform="translate(-2.056 -1.37)"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </CustomTooltip>
                    </p>
                  </>
                )}
                {["tata_aia_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes <span>7% OFF</span> for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Online Customers)
                    </p>
                  </>
                )}
                {["hdfc_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes <span>5% OFF</span> for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Online Customers)
                    </p>
                  </>
                )}
              </div>
              <button className="quote-card__btn" onClick={onBuyNowClicked}>
                BUY NOW
              </button>
            </>
          ) : (
            <div className="cardTopRightCenter">
              <div className="cardTopRightOfferDiv">
                <div className="cardTopRate">
                  {`₹ ${Number(price).toLocaleString(
                    "en-IN",
                  )}${payPremiumMode}`}
                </div>
                {["kotak_life", "max_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes
                      <span>
                        {quote?.company_alias === "max_life"
                          ? "12% OFF"
                          : "5% OFF"}
                      </span>
                      for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Salaried Customers)
                    </p>
                  </>
                )}
                {["bajaj_life"].includes(quote.company_alias) &&
                  quote?.master_product_name !== "eTouch" && (
                    <>
                      <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                        Includes <span>11% OFF</span> for 1<sup>st</sup> year
                      </p>
                      <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                        (Upto 6% applied for online customers And Upto 5%
                        applied for salaried customers)
                      </p>
                    </>
                  )}
                {["bajaj_life"].includes(quote.company_alias) &&
                  quote?.master_product_name === "eTouch" && (
                    <>
                      <p class="cardTopRightOfferText cardTopRightOfferDeskTab cardGetUpTo20Percent">
                        {quote?.disclaimer} <span>{quote?.span}</span>
                        <CustomTooltip
                          id={
                            "quoteCard_customTooltip_bajaj_eTouch_recommended"
                          }
                          place={"left"}
                          Position={{ top: 3, left: 0 }}
                          customClassName="quotePageTooltip"
                        >
                          <span
                            style={{
                              position: "relative",
                              left: "5px",
                              top: "-1px",
                            }}
                            data-for={
                              "quoteCard_customTooltip_bajaj_eTouch_recommended"
                            }
                            data-tip={`<div>${quote?.tooltip}</div>`}
                            data-html={true}
                          >
                            <span
                              className="cardTooltipSvg"
                              data-toggle="popover"
                              title=""
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <g transform="translate(-.5 -.5)">
                                  <g transform="translate(1 1)">
                                    <circle
                                      cx="6"
                                      cy="6"
                                      r="6"
                                      fill="none"
                                      stroke="#5b6466"
                                    ></circle>
                                    <path
                                      fill="#5b6466"
                                      d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                      data-name="?-copy"
                                      transform="translate(-2.056 -1.37)"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </span>
                        </CustomTooltip>
                      </p>
                    </>
                  )}
                {["icici_pru"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab cardGetUpTo20Percent">
                      {quote?.disclaimer} <span>{quote?.span}</span>
                      <CustomTooltip
                        id={"quoteCard_customTooltip_icici_pru_recommended"}
                        place={"left"}
                        Position={{ top: 3, left: 0 }}
                        customClassName="quotePageTooltip"
                      >
                        <span
                          style={{
                            position: "relative",
                            left: "5px",
                            top: "-1px",
                          }}
                          data-for={
                            "quoteCard_customTooltip_icici_pru_recommended"
                          }
                          data-tip={`<div>${quote?.tooltip}</div>`}
                          data-html={true}
                        >
                          <span
                            className="cardTooltipSvg"
                            data-toggle="popover"
                            title=""
                            data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                            data-original-title="Nominee gets"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                            >
                              <g transform="translate(-.5 -.5)">
                                <g transform="translate(1 1)">
                                  <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    fill="none"
                                    stroke="#5b6466"
                                  ></circle>
                                  <path
                                    fill="#5b6466"
                                    d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                    data-name="?-copy"
                                    transform="translate(-2.056 -1.37)"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </CustomTooltip>
                    </p>
                  </>
                )}
                {["tata_aia_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes <span>7% OFF</span> for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Online Customers)
                    </p>
                  </>
                )}
                {["hdfc_life"].includes(quote.company_alias) && (
                  <>
                    <p class="cardTopRightOfferText cardTopRightOfferDeskTab">
                      Includes <span>5% OFF</span> for 1<sup>st</sup> year
                    </p>
                    <p class="cardTopRightOfferSubText cardTopRightOfferDeskTab">
                      (Exclusively for Online Customers)
                    </p>
                  </>
                )}
              </div>
              <button className="cardBuyBtn" onClick={onBuyNowClicked}>
                BUY NOW
              </button>
            </div>
          )}
        </div>
        <div className="mobileCardTopRight">
          <div className="cardTopRightCenter">
            <div className="cardTopRate">
              {`₹ ${Number(price).toLocaleString("en-IN")}${payPremiumMode}`}
            </div>
            <button className="cardBuyBtn" onClick={onBuyNowClicked}>
              BUY NOW
            </button>
          </div>
        </div>
      </div>
      {["kotak_life", "max_life"].includes(quote.company_alias) && (
        <div class="cardTopRightOfferMob">
          <p class="cardTopRightOfferText">
            Includes
            <span>
              {quote?.company_alias === "max_life" ? "12% OFF" : "5% OFF"}
            </span>
            for 1<sup>st</sup> year
          </p>
          <p class="cardTopRightOfferSubText">
            (Exclusively for Salaried Customers)
          </p>
        </div>
      )}
      {["bajaj_life"].includes(quote.company_alias) &&
        quote?.master_product_name !== "eTouch" && (
          <div class="cardTopRightOfferMob">
            <p class="cardTopRightOfferText">
              Includes <span>11% OFF</span> for 1<sup>st</sup> year
            </p>
            <p class="cardTopRightOfferSubText">
              (Upto 6% applied for online customers And Upto 5% applied for
              salaried customers)
            </p>
          </div>
        )}
      {["bajaj_life"].includes(quote.company_alias) &&
        quote?.master_product_name === "eTouch" && (
          <div class="cardTopRightOfferMob">
            <p class="cardTopRightOfferText cardGetUpTo20Percent">
              {quote?.disclaimer} <span>{quote?.span}</span>
              <CustomTooltip
                id={"quoteCard_customTooltip_bajaj_eTouch_outer"}
                place={"left"}
                Position={{ top: 3, left: 0 }}
                customClassName="quotePageTooltip"
              >
                <span
                  style={{ position: "relative", left: "5px", top: "-1px" }}
                  data-for={"quoteCard_customTooltip_bajaj_eTouch_outer"}
                  data-tip={`<div>${quote?.tooltip}</div>`}
                  data-html={true}
                >
                  <span
                    className="cardTooltipSvg"
                    data-toggle="popover"
                    title=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                    >
                      <g transform="translate(-.5 -.5)">
                        <g transform="translate(1 1)">
                          <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="none"
                            stroke="#5b6466"
                          ></circle>
                          <path
                            fill="#5b6466"
                            d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                            data-name="?-copy"
                            transform="translate(-2.056 -1.37)"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                </span>
              </CustomTooltip>
            </p>
          </div>
        )}
      {["tata_aia_life"].includes(quote.company_alias) && (
        <div class="cardTopRightOfferMob">
          <p class="cardTopRightOfferText">
            Includes <span>7% OFF</span> for 1<sup>st</sup> year
          </p>
          <p class="cardTopRightOfferSubText">
            (Exclusively for Online Customers)
          </p>
        </div>
      )}
      {["icici_pru"].includes(quote.company_alias) && (
        <div class="cardTopRightOfferMob">
          <p class="cardTopRightOfferText cardGetUpTo20Percent">
            {quote?.disclaimer} <span>{quote?.span}</span>
            <CustomTooltip
              id={"quoteCard_customTooltip_icici_pru_outer"}
              place={"left"}
              Position={{ top: 3, left: 0 }}
              customClassName="quotePageTooltip"
            >
              <span
                style={{ position: "relative", left: "5px", top: "-1px" }}
                data-for={"quoteCard_customTooltip_icici_pru_outer"}
                data-tip={`<div>${quote?.tooltip}</div>`}
                data-html={true}
              >
                <span
                  className="cardTooltipSvg"
                  data-toggle="popover"
                  title=""
                  data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                  data-original-title="Nominee gets"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                  >
                    <g transform="translate(-.5 -.5)">
                      <g transform="translate(1 1)">
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="none"
                          stroke="#5b6466"
                        ></circle>
                        <path
                          fill="#5b6466"
                          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                          data-name="?-copy"
                          transform="translate(-2.056 -1.37)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </p>
        </div>
      )}
      {["hdfc_life"].includes(quote.company_alias) && (
        <div class="cardTopRightOfferMob">
          <p class="cardTopRightOfferText">
            Includes <span>5% OFF</span> for 1<sup>st</sup> year
          </p>
          <p class="cardTopRightOfferSubText">
            (Exclusively for Online Customers)
          </p>
        </div>
      )}
      <div className="cardMiddleSection">
        {filter.nomineeGets === "Lumpsum" && (
          <div className="cardMiddleLeft">
            <div className="cardMiddleTerm">Cover Amount</div>
            <div className="cardMiddleTermYr">{coverAmount}</div>
          </div>
        )}
        <div className="cardMiddleLeft">
          <div className="cardMiddleTerm">Cover upto</div>
          <div className="cardMiddleTermYr">{coverUpto}</div>
        </div>
        <div
          className={`cardMiddleMiddle ${
            filter.nomineeGets === "Lumpsum + Monthly Income" ||
            filter.morePlans === "Survival Benefits"
              ? "no-border-right"
              : ""
          }`}
        >
          <div className="cardMiddleNominee">
            {filter.morePlans === "Survival Benefits"
              ? "Life Assured Gets"
              : "	Nominee gets"}
            <CustomTooltip
              id={"quoteCard_customTooltip_hdfc_life"}
              place={"left" === "yes" ? "left" : "right"}
              Position={
                "left" === "yes" ? { top: 3, left: 15 } : { top: 3, right: 18 }
              }
              customClassName="quotePageTooltip"
            >
              <span
                style={{ position: "relative", left: "5px", top: "-1px" }}
                data-for={"quoteCard_customTooltip_hdfc_life"}
                data-tip={`<h3>${
                  filter.morePlans === "Survival Benefits"
                    ? "Life Assured Gets"
                    : "	Nominee gets"
                }</h3><div>${
                  filter.morePlans === "Survival Benefits"
                    ? "Life Insured Gets - 5% of the Base Sum Assured as lumpsum on the 60th Birthday Policy Anniversary plus regular income of 0.1% of Base Sum Assured Monthly in arrears till maturity or date of death, whichever happens first."
                    : filter.nomineeGets === "Lumpsum"
                    ? "Your Nominee will receive an Immediate one time payout, equal to the sum assured."
                    : filter.nomineeGets === "Monthly"
                    ? "Your nominee will receive monthly income for the said tenure."
                    : "Your nominee will receive an immediate one time payout along with the monthly income for the said tenure."
                }</div>`}
                data-html={true}
              >
                <span
                  className="cardTooltipSvg"
                  data-toggle="popover"
                  title=""
                  data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                  data-original-title="Nominee gets"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                  >
                    <g transform="translate(-.5 -.5)">
                      <g transform="translate(1 1)">
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="none"
                          stroke="#5b6466"
                        ></circle>
                        <path
                          fill="#5b6466"
                          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                          data-name="?-copy"
                          transform="translate(-2.056 -1.37)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </div>
          <div
            className="cardMiddleDuration"
            // style={{
            //   textTransform: quote?.company_alias === "tata_aia_life" && "unset",
            // }}
          >
            {nomineeGets}
            {/* quote?.company_alias === "tata_aia_life"
              ? quote?.tata_rider_sum_assured !== "0"
                ? `${getValue(quote?.tata_basic_sum_assured)} + ${getValue(
                    quote?.tata_rider_sum_assured,
                  )} (CPR benefit amount)`
                : getValue(quote?.tata_basic_sum_assured)
              :  */}
          </div>
        </div>
        {filter.nomineeGets !== "Lumpsum + Monthly Income" &&
          filter.morePlans !== "Survival Benefits" && (
            <div className={recommended ? "cardMiddleRight" : "cardBtmLeft"}>
              <div className="cardMiddleTerm">
                Claim Settlement Ratio
                <CustomTooltip
                  id={"quoteCard_customTooltip_csr"}
                  place={"left"}
                  Position={{ top: 3, left: 0 }}
                  customClassName="quotePageTooltip"
                >
                  <span
                    style={{ position: "relative", left: "5px", top: "-1px" }}
                    data-for={"quoteCard_customTooltip_csr"}
                    data-tip={`<h3>${"Claim Settlement Ratio"}</h3><div>${"Claim Settled against the number of claims received."}</div>`}
                    data-html={true}
                  >
                    <span
                      className="cardTooltipSvg"
                      data-toggle="popover"
                      title=""
                      data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                      data-original-title="Nominee gets"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                      >
                        <g transform="translate(-.5 -.5)">
                          <g transform="translate(1 1)">
                            <circle
                              cx="6"
                              cy="6"
                              r="6"
                              fill="none"
                              stroke="#5b6466"
                            ></circle>
                            <path
                              fill="#5b6466"
                              d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                              data-name="?-copy"
                              transform="translate(-2.056 -1.37)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
                </CustomTooltip>
              </div>
              <div className="cardMiddleTermYr">
                {claimSettlementRatio +
                  (!claimSettlementRatio?.includes("%") ? "%" : "")}
              </div>
            </div>
          )}
        {(filter.nomineeGets === "Lumpsum + Monthly Income" ||
          filter.morePlans === "Survival Benefits") && (
          <div
            className={`${
              recommended ? "cardMiddleRight" : "cardBtmLeft"
            } quote-tablet-show crr`}
          >
            <div className="cardMiddleTerm">
              Claim Settlement Ratio
              <CustomTooltip
                id={"quoteCard_customTooltip3"}
                place={"left"}
                Position={{ top: 3, left: 0 }}
                customClassName="quotePageTooltip"
              >
                <span
                  style={{ position: "relative", left: "5px", top: "-1px" }}
                  data-for={"quoteCard_customTooltip3"}
                  data-tip={`<h3>${"Claim Settlement Ratio"}</h3><div>${"Claim Settled against the number of claims received."}</div>`}
                  data-html={true}
                >
                  <span
                    className="cardTooltipSvg"
                    data-toggle="popover"
                    title=""
                    data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                    data-original-title="Nominee gets"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                    >
                      <g transform="translate(-.5 -.5)">
                        <g transform="translate(1 1)">
                          <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="none"
                            stroke="#5b6466"
                          ></circle>
                          <path
                            fill="#5b6466"
                            d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                            data-name="?-copy"
                            transform="translate(-2.056 -1.37)"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                </span>
              </CustomTooltip>
            </div>
            <div className="cardMiddleTermYr">
              {claimSettlementRatio +
                (!claimSettlementRatio?.includes("%") ? "%" : "")}
            </div>
          </div>
        )}
      </div>
      <div className="mobileCardMiddleSection">
        <div className="cardMiddleMiddle">
          <div className="cardMiddleNominee">
            Nominee gets
            <CustomTooltip
              id={"quoteCard_customTooltip4"}
              place={"left" === "yes" ? "left" : "right"}
              Position={
                "left" === "yes" ? { top: 3, left: 15 } : { top: 3, right: 18 }
              }
              customClassName="quotePageTooltip"
            >
              <span
                style={{ position: "relative", left: "5px", top: "-1px" }}
                data-for={"quoteCard_customTooltip4"}
                data-tip={`<h3>${"Nominee gets"}</h3><div>${
                  filter.nomineeGets === "Lumpsum"
                    ? "Your Nominee will receive an Immediate one time payout, equal to the sum assured."
                    : filter.nomineeGets === "Monthly"
                    ? "Your nominee will receive monthly income for the said tenure."
                    : "Your nominee will receive an immediate one time payout along with the monthly income for the said tenure."
                }</div>`}
                data-html={true}
              >
                <span
                  className="cardTooltipSvg"
                  data-toggle="popover"
                  title=""
                  data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                  data-original-title="Nominee gets"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                  >
                    <g transform="translate(-.5 -.5)">
                      <g transform="translate(1 1)">
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="none"
                          stroke="#5b6466"
                        ></circle>
                        <path
                          fill="#5b6466"
                          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                          data-name="?-copy"
                          transform="translate(-2.056 -1.37)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </div>
          <div className="cardMiddleDuration">
            {nomineeGets}
            {/* quote?.company_alias === "tata_aia_life"
              ? quote?.tata_rider_sum_assured !== "0"
                ? `${getValue(quote?.tata_basic_sum_assured)} + ${getValue(
                    quote?.tata_rider_sum_assured,
                  )} (CPR benefit amount)`
                : getValue(quote?.tata_basic_sum_assured)
              :  */}
          </div>
        </div>
      </div>
      <div
        className={`cardBottomSection ${
          filter.nomineeGets === "Lumpsum + Monthly Income" ||
          filter.morePlans === "Survival Benefits"
            ? "lumpsum-monthly"
            : ""
        }`}
      >
        <div className={recommended ? "cardBtmLeft" : "cardBtmMiddle"}>
          <div className="cardMiddleTerm">
            In-built Benefits
            <CustomTooltip
              id={"quoteCard_customTooltip5"}
              place={"left" === "yes" ? "left" : "right"}
              Position={
                "left" === "yes" ? { top: 3, left: 15 } : { top: 3, right: 18 }
              }
              customClassName="quotePageTooltip"
            >
              <span
                style={{ position: "relative", left: "5px", top: "-1px" }}
                data-for={"quoteCard_customTooltip5"}
                data-tip={`<h3>${"In-built Benefits"}</h3><div>${renderToStaticMarkup(
                  inBuiltRidersToolTip,
                )}</div>`}
                data-html={true}
              >
                <span
                  className="cardTooltipSvg"
                  data-toggle="popover"
                  title=""
                  data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                  data-original-title="Nominee gets"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                  >
                    <g transform="translate(-.5 -.5)">
                      <g transform="translate(1 1)">
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="none"
                          stroke="#5b6466"
                        ></circle>
                        <path
                          fill="#5b6466"
                          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                          data-name="?-copy"
                          transform="translate(-2.056 -1.37)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </div>
          <div className={recommended ? "cardMiddleClaim" : "cardMiddleTermYr"}>
            {filteredInbuiltRiders?.length}
          </div>
        </div>
        <div className={recommended ? "cardBtmMiddle" : "cardBtmRight"}>
          <div className="cardMiddleTerm">
            Add-On Benefits
            <CustomTooltip
              id={"quoteCard_customTooltip6"}
              place={"left" === "yes" ? "left" : "right"}
              Position={
                "left" === "yes" ? { top: 3, left: 15 } : { top: 3, right: 18 }
              }
              customClassName="quotePageTooltip"
            >
              <span
                style={{ position: "relative", left: "5px", top: "-1px" }}
                data-for={"quoteCard_customTooltip6"}
                data-tip={`<h3>${"Add-On Benefits"}</h3><div>${renderToStaticMarkup(
                  additionalRidersToolTip,
                )}</div>`}
                data-html={true}
              >
                <span
                  className="cardTooltipSvg"
                  data-toggle="popover"
                  title=""
                  data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                  data-original-title="Nominee gets"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                  >
                    <g transform="translate(-.5 -.5)">
                      <g transform="translate(1 1)">
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          fill="none"
                          stroke="#5b6466"
                        ></circle>
                        <path
                          fill="#5b6466"
                          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                          data-name="?-copy"
                          transform="translate(-2.056 -1.37)"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </div>
          <div
            className={recommended ? "cardMiddleTermNbr" : "cardMiddleTermYr"}
          >
            {renderToStaticMarkup(additionalRiders)}
          </div>
        </div>
        {recommended ? (
          <>
            <div className={recommended ? "cardBtmRight" : "cardBtmRight"}>
              <div className="cardMiddleTerm">
                Medical Exam
                <CustomTooltip
                  id={"quoteCard_customTooltip7"}
                  place={"left"}
                  Position={{ top: 3, left: 0 }}
                  customClassName="quotePageTooltip"
                >
                  <span
                    style={{ position: "relative", left: "5px", top: "-1px" }}
                    data-for={"quoteCard_customTooltip7"}
                    data-tip={`<h3>${"Disclaimer"}</h3><div>${
                      medicalExamination === "Video Medical"
                        ? "Video Medical examination is subject to customer’s good health declaration, occupation, education."
                        : medicalExamination === "Tele-medical + Video Medical"
                        ? "Video Medical examination is subject to customer’s good health declaration, occupation, education."
                        : medicalExamination === "Tele Medical"
                        ? "Tele-medical limits are subject to customer's good health declaration, occupation and education"
                        : "Medical examination is subject to customer's good health, occupation and education."
                    }</div>`}
                    data-html={true}
                  >
                    <span
                      className="cardTooltipSvg"
                      data-toggle="popover"
                      title=""
                      data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                      data-original-title="Nominee gets"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                      >
                        <g transform="translate(-.5 -.5)">
                          <g transform="translate(1 1)">
                            <circle
                              cx="6"
                              cy="6"
                              r="6"
                              fill="none"
                              stroke="#5b6466"
                            ></circle>
                            <path
                              fill="#5b6466"
                              d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                              data-name="?-copy"
                              transform="translate(-2.056 -1.37)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
                </CustomTooltip>
              </div>
              <div
                className={
                  recommended ? "cardMiddleTermNbr" : "cardMiddleTermYr"
                }
              >
                {medicalExamination}
              </div>
            </div>
            {(filter.nomineeGets === "Lumpsum + Monthly Income" ||
              filter.morePlans === "Survival Benefits") && (
              <div className="cardBtmRight desktopCellShow">
                <div className="cardMiddleTerm">
                  Claim Settlement Ratio
                  <CustomTooltip
                    id={"quoteCard_customTooltip8"}
                    place={"left"}
                    Position={{ top: 3, left: 0 }}
                    customClassName="quotePageTooltip"
                  >
                    <span
                      style={{ position: "relative", left: "5px", top: "-1px" }}
                      data-for={"quoteCard_customTooltip8"}
                      data-tip={`<h3>${"Claim Settlement Ratio"}</h3><div>${"Claim Settled against the number of claims received."}</div>`}
                      data-html={true}
                    >
                      <span
                        className="cardTooltipSvg"
                        data-toggle="popover"
                        title=""
                        data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                        data-original-title="Nominee gets"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <g transform="translate(-.5 -.5)">
                            <g transform="translate(1 1)">
                              <circle
                                cx="6"
                                cy="6"
                                r="6"
                                fill="none"
                                stroke="#5b6466"
                              ></circle>
                              <path
                                fill="#5b6466"
                                d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                data-name="?-copy"
                                transform="translate(-2.056 -1.37)"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </CustomTooltip>
                </div>
                <div className="cardMiddleTermYr">
                  {claimSettlementRatio +
                    (!claimSettlementRatio?.includes("%") ? "%" : "")}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {(filter.nomineeGets === "Lumpsum + Monthly Income" ||
              filter.morePlans === "Survival Benefits") && (
              <div className="cardBtmRight desktopCellShow">
                <div className="cardMiddleTerm">
                  Claim Settlement Ratio
                  <CustomTooltip
                    id={"quoteCard_customTooltip9"}
                    place={"left"}
                    Position={{ top: 3, left: 0 }}
                    customClassName="quotePageTooltip"
                  >
                    <span
                      style={{ position: "relative", left: "5px", top: "-1px" }}
                      data-for={"quoteCard_customTooltip9"}
                      data-tip={`<h3>${"Claim Settlement Ratio"}</h3><div>${"Claim Settled against the number of claims received."}</div>`}
                      data-html={true}
                    >
                      <span
                        className="cardTooltipSvg"
                        data-toggle="popover"
                        title=""
                        data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                        data-original-title="Nominee gets"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <g transform="translate(-.5 -.5)">
                            <g transform="translate(1 1)">
                              <circle
                                cx="6"
                                cy="6"
                                r="6"
                                fill="none"
                                stroke="#5b6466"
                              ></circle>
                              <path
                                fill="#5b6466"
                                d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                                data-name="?-copy"
                                transform="translate(-2.056 -1.37)"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </CustomTooltip>
                </div>
                <div className="cardMiddleTermYr">
                  {claimSettlementRatio +
                    (!claimSettlementRatio?.includes("%") ? "%" : "")}
                </div>
              </div>
            )}
            <div className={recommended ? "cardBtmRight" : "cardBtmRight"}>
              <div className="cardMiddleTerm">
                Medical Exam
                <CustomTooltip
                  id={"quoteCard_customTooltip_medical"}
                  place={"left"}
                  Position={{ top: 3, left: 0 }}
                  customClassName="quotePageTooltip"
                >
                  <span
                    style={{ position: "relative", left: "5px", top: "-1px" }}
                    data-for={"quoteCard_customTooltip_medical"}
                    data-tip={`<h3>${"Disclaimer"}</h3><div>${
                      medicalExamination === "Video Medical"
                        ? "Video Medical examination is subject to customer’s good health declaration, occupation, education."
                        : medicalExamination === "Tele-medical + Video Medical"
                        ? "Video Medical examination is subject to customer’s good health declaration, occupation, education."
                        : medicalExamination === "Tele Medical"
                        ? "Tele-medical limits are subject to customer's good health declaration, occupation and education"
                        : "Medical examination is subject to customer's good health, occupation and education."
                    }</div>`}
                    data-html={true}
                  >
                    <span
                      className="cardTooltipSvg"
                      data-toggle="popover"
                      title=""
                      data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                      data-original-title="Nominee gets"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                      >
                        <g transform="translate(-.5 -.5)">
                          <g transform="translate(1 1)">
                            <circle
                              cx="6"
                              cy="6"
                              r="6"
                              fill="none"
                              stroke="#5b6466"
                            ></circle>
                            <path
                              fill="#5b6466"
                              d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                              data-name="?-copy"
                              transform="translate(-2.056 -1.37)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
                </CustomTooltip>
              </div>
              <div
                className={
                  recommended ? "cardMiddleTermNbr" : "cardMiddleTermYr"
                }
              >
                {medicalExamination}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

  const recommendedQuoteCard = (
    //mb={offers?.length}
    <RecommendQuoteCard onCompare={quotesOnCompare}>
      <div className="quote-card">{quoteCard}</div>
      {quote?.company_alias === "bajaj_life" && isPnbPreference && (
        <BajajSpecialOffer
          offer={bajajOffers}
          cardType={"recommendedQuoteCard"}
        />
      )}
    </RecommendQuoteCard>
  );

  if (quotesOnCompare) {
    return (
      <OnCompare
        recommended={recommended}
        checked={checkForCompare}
        onClick={() =>
          checkForCompare
            ? setCheckForCompare(false)
            : quotesForCompare.length < quoteLength &&
              setCheckForCompare(!checkForCompare)
        }
      >
        {recommended ? recommendedQuoteCard : quoteCard}
        {/* {quote?.company_alias === "bajaj_life" && !quotesOnCompare && (
          <BajajSpecialOffer
            offer={bajajOffers}
            cardType={recommended ? "recommendedQuoteCard" : "quoteCard"}
          />
        )} */}
      </OnCompare>
    );
  }

  if (recommended) return recommendedQuoteCard;

  return (
    <>
      <div className={`quote-card`}>
        {quoteCard}
        {quote?.company_alias === "bajaj_life" && isPnbPreference && (
          <BajajSpecialOffer offer={bajajOffers} cardType={"quoteCard"} />
        )}
      </div>
    </>
  );
}

export default QuoteCard;
