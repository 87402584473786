export const getMaritalStatusBajaj = status => {
  switch (status) {
    case "Single":
      return "S";
    case "Married":
      return "M";
    case "Widowed":
      return "W";
    case "Divorced":
      return "D";
    default:
      return null;
  }
};

export const getCountryOfResidence = status => {
  switch (status) {
    case "India":
      return "IN";

    default:
      return null;
  }
};

export const getNationKotak = status => {
  switch (status) {
    case "INDIAN":
      return "IN";
    default:
      return null;
  }
};

export const getPremiumPayerBajaj = value => {
  switch (value) {
    case "Proposed Insured":
      return "PI";
    case "Proposer":
      return "P";
    case "Others":
      return "O";
    default:
      return null;
  }
};

export const getRelationBajaj = value => {
  switch (value) {
    case "Father":
      return "Father";
    case "Mother":
      return "Mother";
    case "Grand Father":
      return "Grandfather";
    case "Grand Mother":
      return "Grandmother";
    case "Wife":
      return "Wife";
    case "Son":
      return "Son";
    case "Daughter":
      return "Daughter";
    case "Husband":
      return "Husband";
    case "Others":
      return "Others";
    case "Brother":
      return "Brother";
    case "Sister":
      return "Sister";
    case "Father in Law":
      return "Father in Law";
    case "Mother in Law":
      return "Mother in Law";
    case "Not Mentioned":
      return "NM";
    case "Spouse":
      return "Spouse";
    case "Karta":
      return "Karta";
    case "Legal Guardian":
      return "Legal Guardian";
    case "Partnership":
      return "Partnership";
    case "Other":
      return "Other";
    case "Self":
      return "Self";
    default:
      return null;
  }
};

export const getRelationAppointeeBajaj = value => {
  switch (value) {
    case "Father":
      return "Father";
    case "Mother":
      return "Mother";
    case "Grand Father":
      return "Grandfather";
    case "Grand Mother":
      return "Grandmother";
    case "Wife":
      return "Wife";
    case "Son":
      return "Son";
    case "Daughter":
      return "Daughter";
    case "Husband":
      return "Husband";
    case "Others":
      return "Others";
    case "Brother":
      return "Brother";
    case "Sister":
      return "Sister";
    case "Father in Law":
      return "Father in Law";
    case "Mother in Law":
      return "Mother in Law";
    case "Not Mentioned":
      return "NM";
    case "Employer":
      return "Employer";
    case "Karta":
      return "Karta";
    case "Guardian":
      return "Guardian";
    case "Partnership Firm":
      return "Partnership Firm";
    case "Neice":
      return "Neice";
    case "Nephew":
      return "Nephew";
    default:
      return null;
  }
};
export const getEducationBajaj = education => {
  switch (education) {
    case "Not Mentioned":
      return "NMT";
    case "None":
      return "NA";
    case "Non Matriculation":
      return "NM";
    case "10th Pass":
      return "MT";
    case "Graduate":
      return "GR";
    case "Post Graduate":
      return "PG";
    case "12th Pass":
      return "HSC";
    default:
      return null;
  }
};

export const getEducationFromCodeBajaj = education => {
  switch (education) {
    case "NMT":
      return "Not Mentioned";
    case "NA":
      return "None";
    case "NM":
      return "Non Matriculation";
    case "MT":
      return "10th Pass";
    case "GR":
      return "Graduate";
    case "PG":
      return "Post Graduate";
    case "HSC":
      return "12th Pass";
    default:
      return null;
  }
};
export const getOccupBajaj = value => {
  switch (value) {
    case "Salaried - Government":
      return "SG";
    case "Business":
      return "B";
    case "Professional":
      return "P";
    case "Retired":
      return "R";
    case "Agriculture":
      return "A";
    case "Student":
      return "T";
    case "Housewife":
      return "H";
    case "Unemployed":
      return "U";
    case "Salaried - Non Government":
      return "SN";
    case "Not Applicable":
      return "NA";
    default:
      return null;
  }
};

export const getOccupFromCodeBajaj = value => {
  switch (value) {
    case "SG":
      return "Salaried - Government";
    case "B":
      return "Business";
    case "P":
      return "Professional";
    case "R":
      return "Retired";
    case "A":
      return "Agriculture";
    case "T":
      return "Student";
    case "H":
      return "Housewife";
    case "U":
      return "Unemployed";
    case "SN":
      return "Salaried - Non Government";
    case "NA":
      return "Not Applicable";
    default:
      return null;
  }
};
export const getIDProofBajaj = value => {
  switch (value) {
    case "Aadhaar Card Standard":
      return "ACS";
    case "BFL Standard":
      return "BFL";
    case "Permanent Driving License":
      return "DL";
    case "Job card issued by NREGA duly signed by an officer of the State Government":
      return "JC";
    case "Pan Card":
      return "PC";
    case "PAN Card":
      return "PC";
    case "Passport":
      return "PP";
    case "Voters Id":
      return "VI";
    default:
      return null;
  }
};

export const getAddressProofBajaj = value => {
  switch (value) {
    case "Aadhaar Card Standard":
      return "ACS";
    case "BFL Standard":
      return "BFL";
    case "Permanent Driving License":
      return "DL";
    case "Passport":
      return "PP";
    case "Voters Id":
      return "VI";
    default:
      return null;
  }
};

export const getIncomeProofBajaj = value => {
  switch (value) {
    case "Audited company accounts":
      return "ACA";
    case "Audited firm accounts and Partnership deeds":
      return "AFA";
    case "Current Passbook or Statement of Bank account with details of permanent / present residence address updated to prev month":
      return "BAS";
    case "Bank cash flow statements. Passbook":
      return "BCF&P";
    case "Investment proofs":
      return "IP";
    case "Income tax assessment orders/returns":
      return "ITR";
    default:
      return null;
  }
};

export const getAgeProofBajaj = value => {
  switch (value) {
    case "Aadhaar Card Standard":
      return "ACS";
    case "Affidavit on Stamp paper":
      return "AF";
    case "Birth Certificate":
      return "BC";
    case "Birth Certificate And School Certificate":
      return "BC_SC";
    case "BFL Standard":
      return "BFL";
    case "Baptism Certificate":
      return "BPC";
    case "Baptism Certificate And School Certificate":
      return "BPC_SC";
    case "Bonafide School Certificate":
      return "BSC";
    case "Certified from Service register of Govt. and Quasi Govt Org":
      return "CI";
    case "Domicile Certificate":
      return "DC";
    case "Permenant Driving licence":
      return "DL";
    case "Service Record Or Employer Certificate":
      return "EC";
    case "E.S.I.S Card":
      return "ESIS";
    case "Hospital Discharge Card":
      return "HDC";
    case "Higher Secondary Mark List Or certified extract from school":
      return "HSM";
    case "Identity Cards Issued by Defence Department":
      return "IDC";
    case "Pan Card":
      return "PC";
    case "Passport":
      return "PP";
    case "Ration Card":
      return "RC";
    case "School or college certificate from school or college":
      return "SC";
    case "School Leaving Certificate":
      return "SL";
    case "School Transfer certificate":
      return "STC";
    case "Life Insurance Policy from LIC or Private Insurance Company":
      return "LIC";
    case "Marriage Certificate of Christians by Roman Catholic Church":
      return "MC";
    case "Voter's Id.":
      return "VI";
    default:
      return null;
  }
};

export const getIndTypeBajaj = value => {
  switch (value) {
    case "CRPF":
      return "167";
    case "DEFENCE":
      return "168";
    case "MERCHANT MARINE":
      return "169";
    case "MINING":
      return "170";
    case "OTHERS":
      return "171";
    case "OIL & NATURAL GAS":
      return "172";
    default:
      return null;
  }
};

export const getRenewBajaj = value => {
  switch (value) {
    case "Online Mandate(BOB,KOTAK,PNB & OTHERS":
      return "NACH";
    case "Credit card standing instruction":
      return "CCSI";
    case "Online Mandate(AXIS,HDFC,ICICI & SBI)":
      return "EMAND";
    case "Cash/Cheque/DD":
      return "CA";

    default:
      return null;
  }
};

export const getTobaccoBajaj = value => {
  switch (value) {
    case "Cigar/ Cigarette/ Bidi sticks":
      return "CIGR_BIDI";
    case "Gutka/ Tobacco pouch":
      return "GUTKA_TOB";
    case "Others":
      return "OTHERS";
    default:
      return null;
  }
};
export const getTobaccoBajajRev = value => {
  switch (value) {
    case "CIGR_BIDI":
      return "Cigar/ Cigarette/ Bidi sticks";
    case "GUTKA_TOB":
      return "Gutka/ Tobacco pouch";
    case "OTHERS":
      return "Others";
    default:
      return null;
  }
};

export const BajajAgeDocCode = value => {
  switch (value) {
    case "Aadhaar card":
      return "ACS";
    case "Birth Certificate":
      return "BC";
    case "BFL Standard":
      return "BFL";
    case "Baptism certificate":
      return "BPC";
    case "Bonafide School Certificate":
      return "BSC";
    case "Domicile certificate with communication address and photograph":
      return "DC";
    case "Permanent Driving License":
      return "DL";
    case "Service Record/Employer Certificate":
      return "EC";
    case "E.S.I.S Card":
      return "ESIS";
    case "Hospital Discharge card":
      return "HDC";
    case "Higher Secondary Mark List/certified extract from school":
      return "HSM";
    case "Id Cards issued by Defence":
      return "IDC";
    case "Life Insurance Policy from LIC or any other private Life insurance company":
      return "LIC";
    case "Marriage Certificate of Christians issued by a Roman Catholic Church":
      return "MC";
    case "Pan Card":
      return "PC";
    case "Passport":
      return "PP";
    case "Ration Card":
      return "RC";
    case "School/college certificate":
      return "SC";
    case "School Leaving certificate":
      return "SL";
    case "Voters Id":
      return "VI";
    case "Village Panchayat":
      return "VP";
    case "Written confirmation from the banks":
      return "WCBA";
    case "Not Required":
      return "NR";
    default:
      return null;
  }
};

export const BajajAddressDocCode = value => {
  switch (value) {
    case "Aadhaar card":
      return "ACS";
    case "Leave and Licence Agreement":
      return "LLA";
    case "House Allotment Letter":
      return "HAL";
    case "Utility Bill Gas":
      return "GB";
    case "Identity Cards Issued By Defence Department":
      return "IDC";
    case "Certified From Service Register Of Govt. And Quasi Govt Org":
      return "CI";
    case "Employers Certificate":
      return "EC";
    case "Bank Account Statement Not Older Than 6 Months":
      return "BAS";
    case "Telephone Bill Not Older Than 6 Months":
      return "TB";
    case "Electricity Bill":
      return "EB";
    case "Passport":
      return "PP";
    case "Driving License":
      return "DL";
    case "Written Confirmation From The Banks Certifying Identity Proof":
      return "BCIP";
    case "Lease Agreement Along With Rent Receipt Not Older Than 3 Months":
      return "LA";
    case "Central or State Government Certified Id Proof":
      return "CCIP";
    case "Domiciles Certificate With Communication Address And Photograph":
      return "DC";
    case "B.C From Village Panchayat Councillor or Corporator":
      return "VP";
    case "Document with address issued by Central or State Gov":
      return "SGA";
    case "Gram Panchayat Certificate(Rural)":
      return "GPC";
    default:
      return null;
  }
};

export const BajajIdDocCode = value => {
  switch (value) {
    case "Aadhaar card":
      return "ACS";
    case "Bank Account Statement Not Older Than 6 Months":
      return "BAS";
    case "Pan Card":
      return "PC";
    case "B.C From Village Panchayat Councillor or Corporator":
      return "VP";
    case "School or College Certificate":
      return "SC";
    case "School Transfer Certificate":
      return "STC";
    case "Domiciles Certificate With Communication Address And Photograph":
      return "DC";
    case "Central or State Government Certified Id Proof":
      return "CCIP";
    case "Driving License":
      return "DL";
    case "Written Confirmation From The Banks Certifying Identity Proof":
      return "BCIP";
    case "PIO Booklet For Returning NRIS":
      return "PIO";
    case "Passport":
      return "PP";
    case "School Leaving":
      return "SL";

    default:
      return null;
  }
};
