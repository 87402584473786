import httpClient from "../../api/httpClient";  

const generateOTP = (data) =>  httpClient("common/send-otp", { method: "POST", data });
const verfiyOTP = (data) => httpClient("common/verify-otp", { method: "POST", data });
const resendOTP = (data) => httpClient("common/resend-otp", { method: "POST", data });



export default {
    generateOTP,
    verfiyOTP,
    resendOTP,
  };