import { useSelector } from "react-redux";

function useCompareProductList() {
  const { quotes, quotesForCompare, recommendedQuote, switchToMedical } =
    useSelector(({ quote }) => quote);

  const { displayNomineeGets } = useSelector(({ quoteFilter }) => quoteFilter);
  const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const isSinglePay = paymentTerm?.option === "Single Pay";
  const compareQuotes = [];
  const switchConditionBajaj = quotes => {
    return quotes.filter(
      item =>
        item.company_alias !== "bajaj_life" ||
        (item.company_alias === "bajaj_life" &&
          item?.master_product_name === "eTouch") ||
        (item.company_alias === "bajaj_life" &&
          item.medicalCondition ===
            (switchToMedical ? "Video Medical" : "Physical Medical")),
    );
  };
  const filteredQuotes = switchConditionBajaj(quotes);
  filteredQuotes.forEach(quote => {
    if (quotesForCompare.includes(quote.insurance_code)) {
      if (quote?.insurance_code === recommendedQuote)
        compareQuotes.unshift(quote);
      else compareQuotes.push(quote);
    }
  });
  const discountedPremium = (company_alias, premium_amount) => {
    return Number(premium_amount);
  };
  const { coverAmount, coverUpto } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter,
  );

  const { option, term } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.paymentTerm,
  );

  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );

  const maxInbuiltRidersReducer = (max, quote) => {
    const currentLength = quote.inbuilt_riders.length;
    if (currentLength > max) max = currentLength;
    return max;
  };

  const maxAddonRidersReducer = (max, quote) => {
    const currentLength = quote.addon_riders.length;
    if (currentLength > max) max = currentLength;
    return max;
  };

  const maxInbuiltRiders = compareQuotes.reduce(maxInbuiltRidersReducer, 0);
  const maxAddonRiders = compareQuotes.reduce(maxAddonRidersReducer, 0);

  return {
    displayNomineeGets,
    quotes,
    compareQuotes,
    coverAmount,
    coverUpto,
    option,
    term,
    payPremium,
    maxInbuiltRiders,
    maxAddonRiders,
    recommendedQuote,
    discountedPremium,
    isSinglePay,
  };
}

export default useCompareProductList;
