import React, { useEffect, useState } from "react";
import "./ToggleButton.css";
const ToggleButton = ({
  checked,
  onChange,
  disabled,
  elgToggle,
  autoPaySlider,
  noMargin,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className="toggle-wrapper"
      style={{
        pointerEvents: disabled ? "none" : "all",
        cursor: disabled ? "not-allowed" : "pointer",
        margin: noMargin && "0",
      }}
    >
      <label className={elgToggle ? "switch switchElg" : "switch"}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={e => {
            setIsChecked(e.target.checked);
            onChange(e.target.checked);
          }}
        />
        <span
          className={
            elgToggle
              ? `slider round sliderElg`
              : `slider ${autoPaySlider && "sliderDark"} round`
          }
        ></span>
      </label>
    </div>
  );
};

export default ToggleButton;
