import { createSlice } from "@reduxjs/toolkit";
// import SecureLS from "secure-ls";
import { createOrderId, paymentDetailsToIc } from "./serviceApi";

// const ls = new SecureLS();

const payment = createSlice({
  name: "payment",
  initialState: {
    response: false,
    isLoading: false,
    err: false,
    status: false,
    paymentStatus: false,
    open: false,
    reciept: "",
  },
  reducers: {
    paymentResponseData: (state, action) => {
      state.response = action.payload;
      state.isLoading = false;
      state.open = true;
      state.status = !state.status;
    },

    paymentSuccess: (state, action) => {
      state.paymentSuccess = action.payload;
    },

    paymentPopUpOpen: (state, action) => {
      state.open = action.payload;
    },
    paymentFailed: (state, action) => {
      state.isLoading = false;
    },
    saveReciept: (state, action) => {
      state.reciept = action.payload;
    },
  },
});

export const {
  paymentPopUpOpen,
  paymentFailed,
  paymentResponseData,
  paymentSuccess,
  saveReciept,
} = payment.actions;

export const paymentStart = data => {
  return async dispatch => {
    try {
      const response = await createOrderId(data);
      const {
        pgKey,
        pgOrderId,
        currency,
        paymentAmount,
        companyName,
        productName,
        PgCustomerId,
      } = response.data;

      if (response.data) {
        const responseData = {
          pgKey,
          pgOrderId,
          currency,
          paymentAmount,
          companyName,
          productName,
          PgCustomerId,
        };
        dispatch(paymentResponseData(responseData));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(paymentFailed(err));
    }
  };
};

export const paymentSuccessful = data => {
  return async dispatch => {
    try {
      await paymentDetailsToIc(data);
    } catch {
      alert("Failed to send application Number to IC");
    }
  };
};

export default payment.reducer;
