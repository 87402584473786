import { yupResolver } from "@hookform/resolvers";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FamilyImg from "../../assets/img/heroFamily.png";
import Whatsapp from "../../assets/img/whatsapp.svg";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { config } from "./config";

import EmailPopup from "./EmailPopup";
import {
  AgeWrapper,
  CheckMark,
  Container,
  CustomInput,
  CustomWrap,
  Form,
  FormGroup,
  GenderFieldCust,
  Label,
  LaxmiImg,
  LaxmiWrap,
  MainHeader,
  ProceedButton,
  ProceedWrap,
  Radio,
  RadioButton,
  RadioGroup,
  RadioText,
  Row,
  SubHeader,
  TermCond,
  TextInput,
  Tooltip,
  ErrorMessage,
  CmnTopWrap,
  CmnBlue,
  CmnYellow,
  CmnSkyBlue,
  CmnTitle,
  CmnImgWrap,
  CmnFamilyImg,
  BlueSub,
  CmnBottomWrap,
  CmnRibbonBanner,
  CmnBottomTitle,
  CmnTerms,
} from "./Form1.style";
import { Button } from "../../components/StyledButton/Button";
import LaxmiGreeting from "../../assets/img/laxmiImg.png";
import tooltip from "../../assets/img/tooltip.svg";
import {
  dateUtil,
  numOnly,
  noNum,
  noSpecial,
  getAge,
  getNumberfromString,
  lowerCase,
  nameWithSpace,
} from "../../utils/inputUtils";
import { form3Schema, greetingSchema } from "../../utils/validationSchema";
import Swal from "sweetalert2";
import {
  saveUserDetails,
  getRegion,
  initCreateUser,
  createUserSuccess,
  setMobileNo,
  setPrevious,
  createUserResponse,
  createUserData,
  setShowContinueButton,
  getAffinityPreference,
} from "./greeting.slice";
import ReactTooltip from "react-tooltip";
import Loader from "../../components/Loader/Loader";
import { CustomDropDown, Error } from "../../components";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";

import { getAllData } from "../../api/getAllData";
import SecureLS from "secure-ls";
import styled, { keyframes } from "styled-components";
import Particle from "./Particle";
import { BoldHeader, Continue, SubHeader2 } from "./Form2style";
import { Step } from "./Step";
import { Link } from "react-router-dom";
import { BackButton } from "./BackButton";
import {
  setCoverAmount,
  setCoverAmountLumpsumMonthly,
  setCoverUpto,
  setCoverUptoValues,
  setPayPremium,
} from "../QuoteFilter/quoteFilterSlice";
import {
  createArrayOfNumbers,
  getCoverUptoForAge,
  getDefaultCoverAmount,
  numberToDigitWord,
} from "../QuoteFilter/helper";
import { setCameFromGreeting } from "../QuotePage/quotePageSlice";
import {
  InputInfo,
  SocialUpdateWrap,
  TermAndConditions,
  WhatsappTxt,
} from "./Landing.style";
import { DomainFooter } from "../../components/DomainFooter";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import VerifyEmail from "./VerifyEmail.js";
import { useUrlQuery } from "../../custom-hooks";
import Checkbox from "./CheckBox/Checkbox";

const Form1 = ({ history, push }) => {
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [dobb, setDob] = useState(false);
  const [email, setEmail] = useState(false);
  const [mob, setMob] = useState(false);

  const { isError } = useSelector(({ quote }) => quote);
  const { register, handleSubmit, errors, trigger, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(form3Schema),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const firstNameGiven = watch("firstName");
  const lastNameGiven = watch("lastName");
  const emailGiven = watch("email");
  const mobileNoGiven = watch("mobileNo");

  const {
    customerDetails,
    previous,
    showContinueButton,
    emailDiscount,
    isPnbPreference,
  } = useSelector(state => state.greeting);

  useEffect(() => {
    dispatch(
      createUserData({
        ...customerDetails,
        firstName: firstNameGiven,
        lastName: lastNameGiven,
        email: emailGiven,
        mobileNo: mobileNoGiven,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNameGiven, lastNameGiven, emailGiven, mobileNoGiven]);

  const phone = useRef();
  const attachPhoneRef = ref => {
    register(ref);
    phone.current = ref;
  };
  const ls = new SecureLS();
  const handlePhone = () => {
    //dispatch(setMobileNo(phone.current.value));
    dispatch(setMobileNo(phone.current.value));
  };

  const [withContinue, setWithContinue] = useState(false);
  useEffect(() => {
    if (customerDetails.firstName) {
      setWithContinue("true");
    }
  }, []);
  const dispatch = useDispatch();
  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };
  const {
    erro,
    regionDetails,
    isLoading,
    status,
    showModal,
    userCreate,
    showFooter,
  } = useSelector(state => state.greeting);
  useEffect(() => {
    dispatch(createUserSuccess());
  }, []);
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (query.get("enquiryId")) {
      sessionStorage.setItem("traceId", query.get("enquiryId"));
      dispatch(getAllData("", "/", query.get("enquiryId")));
    }
  }, []);
  useEffect(() => {
    if (status && userCreate) {
    }
  }, [dispatch, history, isLoading, status, userCreate]);
  // const query = new URLSearchParams(window.location.search);
  // useEffect(() => {
  //   if (query.get("enquiryId")) {
  //     sessionStorage.setItem("traceId", query.get("enquiryId"));
  //     dispatch(getAllData("", "/", query.get("enquiryId")));
  //   }
  // }, []);
  const [trig, setTrigger] = useState(false);
  const [whatsapp, setWhatsapp] = useState(true);
  const time = new Date();
  time.setSeconds(time.getSeconds() + showModal);
  Particle(
    userCreate,
    status,
    () => {
      if (status)
        push({
          pathname: "/quotes",
          // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
          search: quotesPagePath,
        });
      sessionStorage.setItem("lastVisited", true);
      dispatch(createUserSuccess());
    },
    emailGiven,
    mobileNoGiven,
    isPnbPreference,
  );

  const afflid = useUrlQuery("afflid");
  const utm_medium = useUrlQuery("utm_medium");
  const c_id = useUrlQuery("c_id");

  let quotesPagePath = `enquiryId=${sessionStorage.getItem("traceId")}`;

  if (afflid)
    quotesPagePath = `enquiryId=${sessionStorage.getItem(
      "traceId",
    )}&afflid=${afflid}`;
  if (utm_medium && c_id)
    quotesPagePath = `enquiryId=${sessionStorage.getItem(
      "traceId",
    )}&utm_medium=${utm_medium}&c_id=${c_id}`;
  if (afflid && utm_medium && c_id)
    quotesPagePath = `enquiryId=${sessionStorage.getItem(
      "traceId",
    )}&afflid=${afflid}&utm_medium=${utm_medium}&c_id=${c_id}`;

  useEffect(() => {
    console.log(status, userCreate);
    if (status && userCreate) {
      // push({
      //   pathname: "/quotes",
      //   // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
      //   search: quotesPagePath,
      // });
      // sessionStorage.setItem("lastVisited", true);
      // dispatch(createUserSuccess());

      setTrigger("mobile");
    }
  }, [dispatch, history, isLoading, status, userCreate]);
  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);
  //   if (query.get("enquiryId")) {
  //     if (
  //       customerDetails.firstName &&
  //       customerDetails.lastName &&
  //       customerDetails.dob &&
  //       customerDetails.gender &&
  //       customerDetails.email &&
  //       customerDetails.mobileNo &&
  //       previous
  //     ) {
  //       dispatch(setPrevious(false));
  //       reset({ gender: customerDetails.gender });
  //       if (!customerDetails.tobaccoHabit) {
  //         history.push({
  //           pathname: "/form2",
  //         });
  //       } else if (!customerDetails.annualIncome) {
  //         history.push({
  //           pathname: "/form3",
  //         });
  //       }
  //       // else if (!customerDetails.preferance) {
  //       //   history.push({
  //       //     pathname: "/Form4",
  //       //   });
  //       // }
  //     }
  //   }
  // }, [customerDetails]);

  const onSubmit = data => {
    dispatch(
      saveUserDetails({
        ...customerDetails,
        ...data,
        firstName:
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
        lastName:
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
        whatsapp,
        is_sms_opt: true,
        is_privacy_policy: true,
        create: false,
      }),
    ).then(() => {
      dispatch(setShowContinueButton(true));
    });
    dispatch(initCreateUser());
    const age = getAge(customerDetails.dob);

    dispatch(setPayPremium("Monthly"));

    dispatch(setCoverUpto({ value: getCoverUptoForAge(age) }));

    const defauleCoverAmount = getDefaultCoverAmount(
      age,
      customerDetails.annualIncome,
    );
    dispatch(
      setCoverAmount({
        value: numberToDigitWord(
          String(getDefaultCoverAmount(age, customerDetails.annualIncome)),

          50000,
          2,
        ),
      }),
    );
    dispatch(
      setCoverAmountLumpsumMonthly({
        coverAmount: numberToDigitWord(
          String(getDefaultCoverAmount(age, customerDetails.annualIncome)),
        ),
        coverAmountPercent: "25%",
        increaseIncome: "Please select",
        additionalIncome: {
          amount: "50000",
          period: "1 years",
        },
      }),
    );

    dispatch(setCoverUptoValues(createArrayOfNumbers(age + 5, 85, 1, "years")));

    dispatch(setCameFromGreeting(true));
  };

  if (isError) return <Error errorMessage={isError} />;

  return (
    <Container>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <LaxmiWrap>
          <LaxmiImg src={LaxmiGreeting}></LaxmiImg>
        </LaxmiWrap>
        {showContinueButton && (
          <Continue
            onClick={() => {
              push({
                pathname: "/quotes",
                // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
                search: `enquiryId=${customerDetails.traceId}`,
              });
              sessionStorage.setItem("lastVisited", true);
            }}
          >
            <div>
              <span>continue</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#f2581b"
              >
                <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </Continue>
        )}
        <div>
          <SubHeader2>
            {
              "Fantastic! I have worked out some exciting deals for your term life insurance."
            }
          </SubHeader2>
          <BoldHeader padding>
            {emailDiscount
              ? "Unlock your exclusive discounts and corporate offers now."
              : "Just one last step and see your personalised quotes."}
          </BoldHeader>
        </div>
        <div style={{ paddingTop: "32px" }}>
          <CustomWrap>
            <CustomInput>
              <FormGroup>
                <TextInput
                  autoFocus
                  type="text"
                  capitalise
                  className="form-control"
                  name="firstName"
                  error={errors.firstName && "danger"}
                  defaultValue={customerDetails.firstName}
                  onChange={e => setFirstName(e.target.value)}
                  maxLength={25}
                  id="firstName"
                  ref={register}
                  onInput={nameWithSpace}
                  required
                ></TextInput>
                <Label>First Name</Label>
                {errors.firstName && (
                  <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
            <CustomInput>
              <FormGroup>
                <TextInput
                  type="text"
                  className="form-control"
                  name="lastName"
                  defaultValue={customerDetails.lastName}
                  error={errors.lastName && "danger"}
                  id="lastName"
                  ref={register}
                  capitalise
                  maxLength={25}
                  onInput={nameWithSpace}
                  required
                ></TextInput>
                <Label>Last Name</Label>
                {errors.lastName && (
                  <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
          </CustomWrap>
          <CustomWrap>
            <CustomInput>
              <FormGroup>
                <TextInput
                  noBottom
                  type="text"
                  className="form-control"
                  name="email"
                  defaultValue={customerDetails.email}
                  error={errors.email && "danger"}
                  id="email"
                  maxLength="52"
                  ref={register}
                  required
                  onBlur={() => {
                    // setTrigger("email");
                  }}
                  onChange={e => {
                    e.target.value = e.target.value.replace(" ", "");
                    lowerCase(e);
                  }}
                  style={{ textTransform: "lowercase" }}
                ></TextInput>
                <Label>Personal Email ID</Label>
                <InputInfo>
                  Your quote, policy and all communications will be sent on it.
                </InputInfo>
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
            <CustomInput>
              <FormGroup>
                <TextInput
                  noBottom
                  type="tel"
                  className="form-control"
                  name="mobileNo"
                  defaultValue={customerDetails.mobileNo}
                  error={errors.mobileNo && "danger"}
                  id="mobileNo"
                  onBlur={() => {
                    // setTrigger("mobile");
                  }}
                  maxLength={10}
                  onInput={numOnly}
                  ref={register}
                  required
                ></TextInput>
                <Label>Mobile No.</Label>
                <InputInfo>Mobile No. will be your Customer Login ID</InputInfo>
                {errors.mobileNo && (
                  <ErrorMessage>{errors.mobileNo.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
          </CustomWrap>
          <ProceedWrap margin>
            <ProceedButton width type="submit">
              <div>
                <span>View Quotes </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                </svg>
              </div>
            </ProceedButton>
            <TermAndConditions>
              Your information stays between us. We never, ever sell your info
              to third parties - we hate spam, too.
              <br />
              By clicking on “View Quotes”, you agree to our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://elephant.in/privacy-policy"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://elephant.in/terms-and-condition"
              >
                Terms &amp; Conditions
              </a>
            </TermAndConditions>
            <SocialUpdateWrap>
              <img src={Whatsapp} alt="whatsapp" />
              <WhatsappTxt>Get updates on WhatsApp</WhatsappTxt>
              <Checkbox
                checked={whatsapp}
                onChange={() => {
                  setWhatsapp(!whatsapp);
                }}
                noMargin
              />
            </SocialUpdateWrap>
          </ProceedWrap>
        </div>
        {/* <EmailPopup email={email && email.split("@")[1]} /> */}
      </Form>
      {/* {isLoading && <Loader />} */}
      <Step count={3} />
      <Link to="/form2">
        <BackButton></BackButton>
      </Link>
      {(emailDiscount || showFooter) && <DomainFooter />}
      <EmailPopup email={email && email.split("@")[1]} />
      {showModal && (
        <VerifyEmail
          email={email}
          resend={() =>
            dispatch(
              getAffinityPreference({
                personal_email: emailGiven,
                mobile_no: mobileNoGiven,
                email: false,
              }),
            )
          }
          expiryTimestamp={time}
          showModal={showModal}
        />
      )}
    </Container>
  );
};

export default Form1;

function EmailAnimationTooltip(props) {
  return (
    <TooltipBase {...props}>
      <svg
        // class="tooltip__shape"
        width="100%"
        height="100%"
        viewBox="0 0 400 300"
        fill="#107591"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
        }}
      >
        <path d="M 22,74.2 22,202 C 22,202 82,202 103,202 124,202 184,202 184,202 L 200,219 216,202 C 216,202 274,202 297,202 320,202 378,202 378,202 L 378,74.2 C 378,74.2 318,73.7 200,73.7 82,73.7 22,74.2 22,74.2 Z"></path>
      </svg>
      <TooltipContent>
        <LaxmiImageForTooltip src={LaxmiGreeting} alt="laxmi" />
        You get superpowers with your work email ID. Please enter your work
        email ID to get unbelievable quotes.
      </TooltipContent>
    </TooltipBase>
  );
}

const tooltipBaseAnime = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TooltipBase = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -173px;
  width: 345px;
  height: 287px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  bottom: -4.5em;
  opacity: 1;
  transform: translateY(0px) scale(1);
  margin-bottom: 37px;
  margin-left: -37px;
  animation: ${tooltipBaseAnime} 0.3s;
  z-index: 999;
`;

const LaxmiImageForTooltip = styled.img`
  width: 96px;
  background-color: #fff;
  border: solid 0.5px #e3e4e8;
  border-radius: 50%;
  width: 58px;
  margin-top: 11px;
  float: left;
  margin-right: 15px;
  border: none;
`;

const moveUp = keyframes`
  0% {
    transform: translateY(16px);
    opacity: 0;
  }
  50% {
    transform: translateY(-7px);
    opacity: 1;
  }
  100% {
    transform: translateY(-6px);
  }
`;

const TooltipContent = styled.div`
  color: #fff;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1px 40px 15px 34px;
  opacity: 0;
  font-size: 15px;
  opacity: 1;
  transform: translateY(-6px);
  animation: ${moveUp} 0.67s;
`;
