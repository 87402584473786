import styled from "styled-components";

export const HeadingWrapper = styled.div`
  text-align: center;
  margin: 32px 2px 0 32px;
  margin-bottom: ${props => {
    if (props.msize) {
      return "25px";
    }
  }};
`;
export const Heading = styled.h1`
  font-size: ${props => (props.reviewPopUp ? "24px" : "30px")};
  font-family: Inter-SemiBold;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

export const Wrapper = styled.div`
  display: ${props => (props.reviewPopUpHeaderTitle ? "inline-flex" : "flex")};
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 56px;
  @media (max-width: 1199px) {
    padding: 0 16px;
  }
  @media (max-width: 767px) {
    flex-direction: ${props => {
      if (props.popupBody) {
        return "column";
      }
    }};
    padding: 0 15px;
    margin-top: 0px;
    margin-bottom: 55px;
    width: 100%;
  }
`;
export const InnerWrapper = styled.div`
  display: inline-flex;
  justify-content: left;
  width: ${props => props.reviewPopupImg && "280px"};
  margin-right: ${props => props.reviewPopUpHeaderTitle && "60px"};

  @media (max-width: 767px) {
    width: unset;
  }
`;
export const Title = styled.h4`
  font-size: ${props =>
    props.reviewPopUp ? "14px" : props.premiumTitle ? "12px" : "16px"};
  font-size: ${props => props.reviewPopUpTitleAlt && "12px"};
  // font-weight: 600;
  font-family: "Inter-SemiBold";
  border-bottom: ${props => props.border && "1px solid #e3e4e8 "};
  padding-bottom: ${props => props.border && "5px"};
  color: ${props => (props.primary ? "#287692" : "#333")};
  margin-bottom: ${props => props.reviewPopUp && "6px"};
  margin-bottom: ${props => props.reviewPopUp2 && "17px"};
  width: ${props => props.reviewPopUpTitle && "290px"};

  @media (max-width: 767px) {
    font-size: ${props =>
      props.nomReview ? "12px" : props.premiumTitle ? "12px" : "13px"};
  }
`;
export const Value = styled.h5`
  font-size: ${props =>
    props.premiumValue ? "20px" : props.primary ? "25px" : "14px"};

  font-weight: 200;
  color: ${props => (props.primary ? "#287692" : "#333333")};
  white-space: no-wrap;
  ${props =>
    props.price && "display:flex;justify-content:space-between;width:100%"};
  margin-bottom: ${props => props.last && "30px !important"};
  margin-bottom: ${props => props.reviewPopUpValue && "10px !important"};

  @media (max-width: 767px) {
    font-size: ${props =>
      props.sm ? "14px" : props.premiumValue ? "20px" : "13px"};
    margin-bottom: ${props => props.mobileLast && "0px !important"};
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.premiumValue && "8px !important"};
  }
`;

export const WrapCenter = styled.div`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 0px;
    position: sticky;
    z-index: 1;

    align-content: center;
    /* margin-top: 20px; */
    background: white;
    box-sizing: border-box;
    border: solid 1px #e4e4e5;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 10px 15px 10px 15px;
    bottom: 0;
    margin-left: ${props => (props.mobileWrap ? "0%" : "1%")};
  }
`;

export const ReviewPopDisclaimer = styled.div`
  font-family: "Inter-Regular";
  font-size: 11px;
  line-height: 15px;
  color: #808080;
  width: 505px;

  margin: 0 auto;
  margin-top: 8px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
