import React from "react";

import "./error.scss";
const searchParams = new URLSearchParams(window.location.search);
const utm_medium = searchParams.get("utm_medium");
function Error({ errorMessage, onRetry, retryButton }) {
  return (
    <div className="error-page">
      <p>{errorMessage}</p>
      <button
        className="reload"
        // onClick={() => {
        //   if (onRetry) {
        //     console.error(onRetry);
        //     onRetry();
        //   } else window.location.reload();
        // }}
        onClick={() =>
          (window.location.href =
            utm_medium == "gameskraft"
              ? "https://elephant.in/gameskraft/"
              : "https://elephant.in/term-life-Insurance")
        }
      >
        {retryButton || "Reload"}
      </button>
    </div>
  );
}

export default Error;
