const AGE_PROOF = [
  "Defence I Card",
  "Driving License (Current)",
  "Passport (Current)",
  "Employers Certificate",
  "Goverment Employee ID",
  "Government Registered document or ID Proof",
  "Municipal Record",
  "PAN card",
  "School/College Record",
];
// const ID_PROOF = [
//   "Aadhaar Card Copy",
//   "Driving License (Current)",
//   "Existing Client - Photo Id Proof Verified",
//   "Job Card Issued by NREGA)",
//   "Bankers Confirmation (With PAN)-Ekyc",
//   "Bankers Confirmation (Without PAN)-Ekyc",
//   "Passport (Current)",
//   "Pan Card",
//   "Voters ID Card",
//   "Bankers Confirmation (With PAN)",
// ];
const ID_PROOF = [
  "Masked Aadhaar Card Copy",
  "Driving License",
  "Job Card Issued by NREGA",
  "Pan Card",
  "Passport (Current)",
  "Voters ID Card",
];
// const ADDRESS_PROOF = [
//   "Aadhaar Card Copy",
//   "Bankers Confirmation (With PAN)",
//   "Bankers Confirmation (With PAN)-Ekyc",
//   "Voters ID Card",
//   "Driving License (Current)",
//   "Existing Client - Address Proof Verified",
//   "Passport (Current)",
//   "Bankers Confirmation (Without PAN)-Ekyc",
// ];
const ADDRESS_PROOF = [
  "Masked Aadhaar Card Copy",
  "Driving License",
  "Passport (Current)",
  "Voters ID Card",
];
// const INCOME_PROOF = [
//   "Defence I Card",
//   "Driving License (Current)",
//   "Passport (Current)",
//   "Employers Certificate",
//   "Goverment Employee ID",
//   "Government Registered document or ID Proof",
//   "Municipal Record",
//   "PAN card",
//   "School/College Record",
// ];
const INCOME_PROOF = [
  "Income Tax Returns (Last 3 Years)",
  "Bank Cash Flow Stmt,Pass Book (Last 6 Months)",
  "Salary Slip Signed by authorized signatory of Employer (Last 3 Months)",
  "Land Records",
  "Chartered Accnt Certificate",
  "Audited Firm Accounts",
  "Audited Company Accounts",
  "Agricultural Income Certificate",
  "Employers Certificate",
  "None",
  "Form 16",
];
export const IT_PROOF = ["PAN", "FORM 60"];
export const OTHERS = ["Payout Mandate", "Others"];
const PHOTO_PROOF = ["Photo"];
export { AGE_PROOF, ID_PROOF, ADDRESS_PROOF, INCOME_PROOF, PHOTO_PROOF };
