import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./VerifyEmail.scss";
import "styled-components/macro";
import { useTimer } from "react-timer-hook";
import { useDispatch } from "react-redux";
import {
  createUserSuccess,
  getAffinityPreference,
  setShowModal,
} from "./greeting.slice";

const VerifyEmail = ({ email, showModal, expiryTimestamp }) => {
  const [clicked, setClicked] = useState(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + showModal);
  useEffect(() => {
    if (clicked)
      setTimeout(() => {
        setClicked(false);
      }, 2000);
  }, [clicked]);
  useEffect(() => {
    if (showModal === true) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "";
      document.body.style.width = "";
    }
  }, [showModal]);

  const { seconds, minutes, isRunning, start, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
  });
  useEffect(() => {
    if (showModal) {
      dispatch(createUserSuccess());
      start();
    }
  }, [showModal]);

  const dispatch = useDispatch();
  const handleCloseClick = () => {
    $("#emailConfirModal").css("display", "none").css("opacity", "0");
    dispatch(setShowModal(false));
  };
  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  const [breath, setBreath] = useState(false);
  useEffect(() => {
    if (breath) {
      setTimeout(() => setBreath(false), 500);
    }
  }, [breath]);
  return (
    <>
      <div
        class={`modal fade in ${breath ? "breathe" : ""}`}
        id="emailConfirModal"
        style={{ display: "block", overflowY: "scroll", opacity: "1" }}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
        onClick={e => {
          setBreath(true);
          e.stopPropagation();
        }}
      >
        {" "}
        <div
          class="modal-dialog"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {" "}
          <div class="modal-content">
            {" "}
            <div class="modal-body">
              <button
                css={`
                  position: absolute;
                  top: 16px;
                  right: 15px;
                  width: 26px;
                  background: none;
                  border: none;
                  font-size: 20px;
                  line-height: 1;
                  padding: 0;
                  border-radius: 50%;
                `}
                onClick={() => {
                  handleCloseClick();
                }}
              >
                <svg
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  {" "}
                  <path
                    d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                    fill="#000"
                  ></path>{" "}
                  <path fill="none" d="M0,0h24v24h-24Z"></path>{" "}
                </svg>
              </button>
              <div class="emailConfBodyWrap">
                {" "}
                <svg
                  class="emailConfSVG"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                >
                  {" "}
                  <title>Email Confirmation</title>{" "}
                  <polygon
                    class="emailC-cls-1"
                    points="248.78 18.41 363.44 103.37 478.29 188.33 248.78 188.33 19.46 188.33 134.12 103.37 248.78 18.41"
                  ></polygon>{" "}
                  <polygon
                    class="emailC-cls-2"
                    points="378.48 188.33 248.78 188.33 134.5 188.33 134.5 122.92 378.1 122.92 378.48 188.33"
                  ></polygon>{" "}
                  <path
                    class="emailC-cls-1"
                    d="M25.1,187H472.65a14,14,0,0,1,13.91,14.09V466.9A14,14,0,0,1,472.65,481H25.1A14.2,14.2,0,0,1,11,466.9V201.11A14.2,14.2,0,0,1,25.1,187Z"
                  ></path>{" "}
                  <path
                    class="emailC-cls-3"
                    d="M473.22,481H24.35a12.26,12.26,0,0,1-3.76-.75l113.53-85.9,114.66-86.66,114.66,86.66,113.72,85.9a14.82,14.82,0,0,1-3.94.75Z"
                  ></path>{" "}
                  <path
                    class="emailC-cls-2"
                    d="M25.1,187H472.65a18.58,18.58,0,0,1,4.51.75L363.44,272l-114.66,85L134.12,272,20.59,187.77A16,16,0,0,1,25.1,187Z"
                  ></path>{" "}
                  <polygon
                    class="emailC-cls-4"
                    points="134.5 187.02 248.78 187.02 378.48 187.02 378.85 260.7 363.44 271.98 279.04 334.57 256.3 318.6 230.17 337.02 134.5 257.69 134.5 187.02"
                  ></polygon>{" "}
                  <path
                    class="emailC-cls-5"
                    d="M477.16,480.25a12.68,12.68,0,0,1-4.51.75H25.1a11.43,11.43,0,0,1-4.51-.75L134.12,400l114.66-81,114.66,81Z"
                  ></path>{" "}
                  <polygon
                    class="emailC-cls-6"
                    points="126.98 131.94 370.58 131.94 371.34 266.15 275.1 337.58 248.78 318.97 222.65 337.58 126.98 266.71 126.98 131.94"
                  ></polygon>{" "}
                  <path
                    class="emailC-cls-7"
                    d="M143,199.8H355.36v5.26H143V199.8ZM319.27,304.87l-7,5.08H185.43l-7-5.08ZM143,234.76H355.36V240H143v-5.26Zm0,35.15H355.36V275H143v-5.08Zm33.84-105.07H355.36v5.26H176.79Z"
                  ></path>{" "}
                </svg>{" "}
                <div class="emailConfTitle">Email Confirmation</div>{" "}
                <div class="emailConfTxt">
                  We have sent an email to{" "}
                  <span id="confirmation_email">{email}</span> to verify your
                  email address. Click on the link provided in the email to
                  complete the sign-up process and enjoy exclusive discounts and
                  offers with us.
                </div>{" "}
                <div class="emailConfQue">Didn’t receive email?</div>{" "}
                {isRunning && (
                  <div class="resendTimer">
                    <span class="unit">Min</span>{" "}
                    <span id="timerCountDown">
                      {pad(minutes)}:{pad(seconds)}
                    </span>{" "}
                    <span class="unit">Sec</span>
                  </div>
                )}
                <button
                  class="emailConfBtn"
                  disabled={isRunning}
                  onClick={() => {
                    setClicked(true);
                    dispatch(
                      getAffinityPreference({
                        email: email,
                      }),
                    ).then(data => {
                      const time = new Date();
                      time.setSeconds(
                        time.getSeconds() + (data?.data?.time || 180),
                      );
                      restart(time);
                    });
                  }}
                >
                  RESEND EMAIL
                </button>
                {clicked && (
                  <div class="emailSentMsg">Email has been sent again</div>
                )}
                <div class="emailConfStep">
                  Check your junk/spam folder as sometimes the email may land up
                  there.
                </div>{" "}
                <div class="emailConfDisc">
                  {" "}
                  <div>Need Help?</div>{" "}
                  <div>
                    Call us on toll free no.{" "}
                    <a href="tel:18002669693">1800 266 9693</a> or write to us
                    at{" "}
                    <a href="mailto:support@elephant.in">support@elephant.in</a>{" "}
                    and we would be happy to assist you.
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </>
  );
};

export default VerifyEmail;
