import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../../custom-hooks";

import "./quote-page-popup.scss";

function QuotePagePopup({ show, id, onClose, ...props }) {
  const [showIn, setShowIn] = useState(false);

  const [closeTransition, setCloseTransition] = useState(false);

  function handleClose() {
    setCloseTransition(true);
    setTimeout(() => {
      setShowIn(false);
      setCloseTransition(false);
      onClose && onClose(false);
    }, 300);
  }

  function fadeOut(callback) {
    setCloseTransition(true);
    setTimeout(() => {
      setShowIn(false);
      callback && callback();
      setCloseTransition(false);
    }, 300);
  }

  const popupRef = useRef(null);

  useOutsideClick(popupRef, () => {
    if (show) handleClose();
  });

  useEffect(() => {
    if (show)
      setTimeout(() => {
        setShowIn(true);
      }, 10);
  }, [show]);

  return (
    <div
      className={`quote-page-popup-modal quote-page-popup-fade ${
        show ? "in" : ""
      } ${closeTransition ? "close-transition" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      {showIn && (
        <div
          ref={popupRef}
          className={`quote-page-popup-wrap ${
            showIn ? "quote-page-popup-wrap-slide-in" : ""
          }`}
          id={id || ""}
          style={props?.style}
        >
          <button
            className="quote-page-popup-close knowMore-close"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
              fill="#333"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </button>
          {props.children.type === "div"
            ? props.children
            : props.children(fadeOut)}
        </div>
      )}
    </div>
  );
}

export default QuotePagePopup;
