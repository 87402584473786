import { createSlice } from "@reduxjs/toolkit";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";
import { checkPincodeMaster } from "./serviceApi";
const nomineeForm = createSlice({
  name: "nomineeForm",
  initialState: {
    nomineeDetails: false,
    trusteeDetails: false,
    nomineeRegionDetails: false,
    nomineePincodeMaster: false,
    beneficiaryList: [
      { firstName: "", lastName: "", dob: "", share: "", relation: "" },
    ],
  },

  reducers: {
    saveDetails: (state, action) => {
      state.nomineeDetails = action.payload;
    },
    saveDetailsT: (state, action) => {
      state.trusteeDetails = action.payload;
    },
    createRegionData: (state, action) => {
      state.nomineeRegionDetails = action.payload;
    },
    createPincodeData: (state, action) => {
      state.nomineePincodeMaster = action.payload;
    },
    updateBeneficiaryDetails: (state, action) => {
      state.beneficiaryList.map(beneficiary => {
        if (beneficiary.id === action.payload.id) {
          beneficiary.firstName = action.payload.firstName;
          beneficiary.lastName = action.payload.lastName;
          beneficiary.share = action.payload.share;
          beneficiary.relation = action.payload.relation;
          beneficiary.dob = action.payload.dob;
        }
      });
    },
    addBeneficiaryDetails: (state, action) => {
      state.beneficiaryList.push(action.payload);
    },
    removeBeneficiaryDetails: (state, action) => {
      state.beneficiaryList = state.beneficiaryList.filter(
        beneficiary => beneficiary.id !== action.payload,
      );
    },
    setBeneficiaryDetailsList: (state, action) => {
      state.beneficiaryList = action.payload;
    },
  },
});
export const {
  saveDetails,
  saveDetailsT,
  createRegionData,
  createPincodeData,
  updateBeneficiaryDetails,
  addBeneficiaryDetails,
  removeBeneficiaryDetails,
  setBeneficiaryDetailsList,
} = nomineeForm.actions;

export const nomineeDetailsSendData = (data = {}) => ({
  mwpa: data.mwpa ? "Yes" : "No",
  nomineeGender: data.nomGender === "M" ? "Male" : "Female",
  nomineeRelationToProposer: data.relation,
  nomineeFirstName: data.fname,
  nomineeLastName: data.lname,
  nomineeDob: data["dob"]?.split("/").join("-"),
  appointeeDob: data?.adob ? data.adob.split("/").join("-") : "",
  appointeeFirstName: data?.afname,
  appointeeLastName: data?.alname,
  appointeeGender: data?.agender
    ? data?.agender === "M"
      ? "Male"
      : "Female"
    : null,
  appointeeRelationToProposer: data.arelation,
  proposalJourneyStatus: iciciProposalForms.nomineeDetails,
});

export const saveNomineeDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        const dob = data["dob"];
        data["dob"] = data["dob"].split("-").reverse().join("/");
        let test = data["dob"];
        delete data.dob;
        if (data["adob"]) {
          if (data["adob"].split("-")[0].length === 4)
            data["adob"] = data["adob"].split("-").reverse().join("/");
          else data["adob"] = data["adob"].split("-").join("/");
        }
        if (test.split("/")[0].length === 4)
          test = test.split("/").reverse().join("/");
        dispatch(saveDetails({ ...data, nomDob: test }));
        dispatch(appendSendData(nomineeDetailsSendData({ ...data, dob })));
      }
    } catch (err) {
      alert("Something went wrong");
      console.error(err);
    }
  };
};

export const trusteeDetailsSendData = (data = {}) => ({
  mwpa: data.mwpa ? "Yes" : "No",
  trusteeName: data.mwpa ? data.tname : null,
  trusteeDob: data.mwpa ? data.tdob : null,
  trusteeType: data.mwpa ? data.ttype : null,
  trusteePincode: data.mwpa ? data.tpincode : null,
  trusteeState: data?.state,
  trusteeCity: data?.city,
  trusteeAddress: data.mwpa ? data.taddress : null,
  trusteePanno: data.mwpa ? data.tpan : null,
  trusteeMobileNo: data.mwpa ? data.tmob : null,
  trusteeEmail: data.mwpa ? data.temail : null,
  mwpaDetails: data.beneficiaryList,
  proposalJourneyStatus: iciciProposalForms.nomineeDetails,
});

export const saveTrusteeDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetailsT(data));

        dispatch(appendSendData(trusteeDetailsSendData({ ...data })));
      }
    } catch (err) {
      alert("Something went wrong");
      console.error(err);
    }
  };
};

export const getRegion = data => {
  return async dispatch => {
    try {
      const response = await checkPincodeMaster(data);
      if (data) {
        dispatch(createRegionData(response.data.pinCodeMaster));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};
export const getPincodeMaster = data => {
  return async dispatch => {
    try {
      const response = await checkPincodeMaster(data);
      if (data) {
        dispatch(createPincodeData(response.data.pinCodeMaster));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};
export default nomineeForm.reducer;
