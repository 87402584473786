import React from "react";
import { Container, Row } from "../modules/GreetingPage/Form1.style";
import "styled-components/macro";
import { useSelector } from "react-redux";

function DomainFooter() {
  const { emailDiscount, showFooter } = useSelector(state => state.greeting);

  let companyName = "";

  if (emailDiscount && emailDiscount?.data?.signCompDom) {
    const { signCompDom, signComp } = emailDiscount.data;
    companyName = signComp || signCompDom;
  }

  if (!companyName && showFooter) {
    companyName = showFooter;
  }

  return (
    <div
      css={`
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: #ebefff;
        width: 100%;
        padding: 15px 0;
        border-top: solid 1px #c0ccfa;
        z-index: 999;
      `}
    >
      <Container>
        <Row>
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              font-family: "Inter-Regular";
              font-size: 17px;
              line-height: 22px;
              color: #000;

              @media only screen and (min-width: 320px) and (max-width: 767px) {
                font-size: 10px;
                line-height: 16px;
              }
            `}
          >
            <CrownSvg />
            <div
              css={`
                text-transform: uppercase;
              `}
            >
              <span
                css={`
                  font-family: "Inter-SemiBold";
                  margin-right: 10px;
                  font-size: inherit;
                `}
              >
                {companyName}
              </span>
              IS A PREFERRED PARTNER ORGANISATION AT ELEPHANT.IN
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export { DomainFooter };

function CrownSvg() {
  return (
    <svg
      class="benifitSvg"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 32.02"
      css={`
        width: 32px;
        height: 42px;
        margin-right: 10px;
      `}
    >
      <defs></defs>
      <path
        css={`
          fill: #1bb2dd;
        `}
        d="M35.11,15.36h0l0,0h0a.51.51,0,0,1-.24.07.43.43,0,0,1-.22-.06s0,0-.07-.05l-.1-.08a0,0,0,0,1,0,0h0L25,.68,15.62,15.24h0a0,0,0,0,1,0,0,.28.28,0,0,1-.11.08s0,0-.06.05a.43.43,0,0,1-.22.06A.48.48,0,0,1,15,15.4h0l0,0h0L2.15,5.19l4.2,20.3h37.3l4.2-20.3Z"
      ></path>
      <rect
        css={`
          fill: #1bb2dd;
        `}
        x="6.39"
        y="26.49"
        width="37.22"
        height="5.17"
      ></rect>
    </svg>
  );
}
