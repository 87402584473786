import React from "react";
import "styled-components/macro";

export default function SpouseSvgIcon() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={`
        fill: #231f20;
        width: 26px;
        height: 33px;
      `}
    >
      <g id="noun_Family_49846" data-name="noun Family 49846">
        <circle
          id="Ellipse_339"
          data-name="Ellipse 339"
          class="cls-1"
          cx="9.66"
          cy="10.67"
          r="1.32"
        />
        <path
          id="Path_1579"
          data-name="Path 1579"
          class="cls-1"
          d="M16,13.12H13.67a.73.73,0,0,0-.74.69v0l-.42,4.65a.76.76,0,0,0,.74.76h.24l.57,3.9a.42.42,0,0,0,.43.42h.67a.43.43,0,0,0,.45-.4v0l.57-3.9h.23a.76.76,0,0,0,.75-.76l-.42-4.65a.73.73,0,0,0-.72-.71Z"
        />
        <circle
          id="Ellipse_340"
          data-name="Ellipse 340"
          class="cls-1"
          cx="14.83"
          cy="11.38"
          r="1.24"
        />
        <path
          id="Path_1582"
          data-name="Path 1582"
          class="cls-1"
          d="M11.13,12.55a.67.67,0,0,0-.24,0H8.43a.75.75,0,0,0-.78.73v0l-.45,3a.81.81,0,0,0,.79.8h.24l.6,6a.46.46,0,0,0,.46.45H10a.46.46,0,0,0,.48-.42v0l.6-6h.25a.79.79,0,0,0,.78-.8l0-.34V7.69A2.52,2.52,0,0,1,14,8.88h.35a2.75,2.75,0,0,1,2.47-1.23,2.75,2.75,0,0,1,2.47,1.23h.08a7.83,7.83,0,0,0-7.19-7.81.64.64,0,0,0-.64-.63.63.63,0,0,0-.63.63A7.83,7.83,0,0,0,3.7,8.88h.08A2.73,2.73,0,0,1,6.24,7.65,2.75,2.75,0,0,1,8.71,8.88h.36a2.57,2.57,0,0,1,2.06-1.21v4.88"
        />
      </g>
    </svg>
  );
}
