import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";

import {
  Form,
  FormGroup,
  Label,
  TextInput,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import { yupResolver } from "@hookform/resolvers";
import { proposerOccupationFormSchema } from "../../../../utils/validationSchema";
import { companyName } from "./companyName";
import {
  proposerOccupationSendData,
  saveProposalOccupationDetails,
} from "./ProposerOccupation.slice";
import {
  convertNumberToWords,
  getEducationDetails,
  getOrganizationType,
  getProposerIndustryType,
  getProposerOccupation,
  noCopyAndPaste,
  numOnly,
} from "../../../../utils/inputUtils";
import { ToggleTile } from "../../components";
import { CustomDropDown } from "../../../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
const ProposerOccupation = ({ position, setPosition, insFor, index }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);

  const [occupationList, setOccupationList] = useState([
    "Self Employed-CA",
    "Self Employed-Doctor",
    "Self Employed-Architect",
    "Self Employed-Businessman",
    "Self Employed-Consultant",
    "Self Employed-Lawyers",
    "Self Employed-Others",
  ]);
  const indTypeList = [
    "Select Industry type",
    "Import/Export",
    "Jewellery",
    "Real Estate",
    "Scrap Dealing",
    "Shipping",
    "Stock Broking",
  ];
  const [notAllowed, setNotAllowed] = useState([
    "Housewife",
    "Agriculturist",
    "Student",
    "Retired",
  ]);

  const { proposerDetails } = useSelector(state => state.proposalForm);
  const { proposalOccupationDetails } = useSelector(
    state => state.proposalOccupationForm,
  );

  const { selectedOccupation, selectedEducation, selectedNatureOfWork } =
    useSelector(({ eligibilityCard }) => eligibilityCard.eligibilityDetails);

  const { annualIncome } = useSelector(
    ({ greeting }) => greeting.customerDetails,
  );

  const [occupation, setOccupation] = useState(selectedOccupation);
  const [engadedIn, setEngadedIn] = useState(
    proposalOccupationDetails?.engagedIn,
  );
  const [orgName, setOrgName] = useState(proposalOccupationDetails.orgName);
  const [orgType, setOrgType] = useState();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    unregister,
    trigger,
    formState,
    watch,
  } = useForm({
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      engagedIn: proposalOccupationDetails.engagedIn || false,
      occupation: proposalOccupationDetails.occupation || selectedOccupation,
      education: selectedEducation || proposalOccupationDetails.education,
    },
    resolver: yupResolver(proposerOccupationFormSchema),
  });

  const annualIncomeWatch = watch("annualIncome");

  const [annualIncomeError, setAnnualIncomeError] = useState(false);
  useEffect(() => {
    if (
      Number(annualIncomeWatch) > Number(annualIncome) + 99999 ||
      Number(annualIncomeWatch) < Number(annualIncome)
    ) {
      setAnnualIncomeError(
        `Annual income should be in between ${ annualIncome } and ${ Number(annualIncome) + 99999
        }`,
      );
    } else {
      setAnnualIncomeError(false);
    }
  }, [annualIncomeWatch]);

  const { isValid } = formState;
  const professionList = [
    "Select Profession",
    "CA",
    "Doctor",
    "Lawyer",
    "Other",
  ];
  const [annualIncomeWord, setAnnualIncomeWord] = useState(
    annualIncome && convertNumberToWords(annualIncome),
  );

  const annualIncomeRef = useRef();
  const attachRef = ref => {
    register(ref);
    annualIncomeRef.current = ref;
  };
  const getInWord = () => {
    setAnnualIncomeWord(convertNumberToWords(annualIncomeRef.current.value));
  };
  const orgNameList = [
    "Select Organisation Name",
    ...companyName.map(a => a.toUpperCase()),
    "Others",
  ];
  const orgTypeList = [
    "Select Organisation Type",
    "Govt",
    "Pvt Ltd",
    "Public Ltd",
    "Trust",
    "Society",
    "Proprietor",
    "Partnership",
    "Others",
  ];
  const orgTypeListSelf = [
    "Select Oragnisation Type",
    "Proprietor",
    "Partnership",
    "Others",
  ];
  const educationList = [
    "Select Education",
    "Post Graduate",
    "Graduate",
    "12th Pass",
    "10th Pass",
    "Diploma",
    "Below 10th",
  ];

  useEffect(() => {
    if (occupation === "Self Employed") {
      //setOrgName("Others");
      //	reset({ occupation: "Self Employed", orgName: "Others" });
    }
    if (occupation === "Self Employed-Others") {
      setOrgType("Others");
      setOrgName("Others");
      reset({
        occupation: "Self Employed-Others",
        orgNameDesc: "Others",
        orgTypeDesc: "Others",
      });
    } else if (
      !(
        occupation === "Salaried" ||
        occupation === "Self Employed" ||
        occupation === "Others"
      )
    ) {
      unregister(["orgName", "orgType", "engagedIn"]);
      setEngadedIn(false);
    }
    if (!engadedIn) {
      unregister("indType");
    }
  }, [occupation, reset, unregister, engadedIn]);
  useEffect(() => {
    register({ name: "occupation" });
    register({ name: "education" });
    if (occupation === "Self Employed-Others") {
      register({ name: "orgName" });
      register({ name: "orgType" });
      setValue("orgName", orgName);
      setValue("orgType", proposalOccupationDetails.orgType);
    }
    if (
      occupation === "Salaried" ||
      occupation === "Self Employed" ||
      occupation === "Others"
    ) {
      register({ name: "engagedIn" });
      register({ name: "orgName" });
      register({ name: "orgType" });
      setValue("orgName", orgName);
      setValue("orgType", proposalOccupationDetails.orgType);
    }
    if (occupation === "Professional") {
      register({ name: "profession" });
      setValue("profession", selectedNatureOfWork);
    }
    if (engadedIn === true) {
      register({ name: "indType" });
      setValue("indType", proposalOccupationDetails.indType);
    }
  }, [register, occupation, engadedIn]);
  useEffect(() => {
    if (occupation === "Self Employed") {
      setOrgName("Others");
      //	register({ name: "orgName" });
      setValue("orgName", "Others");
    }
  }, [occupation, register]);
  useEffect(() => {
    if (proposalOccupationDetails) {
      if (position === index) {
        setEdit(true);
      }
      if (position > index) {
        setEdit(false);
      }
    }
  }, [position]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  // console.log({ ...formState });

  const onSubmit = data => {
    data["education"] =
      proposalOccupationDetails.education || selectedEducation;

    const sendData = proposerOccupationSendData(data);

    saveProposalForm(sendData, () => {
      dispatch(saveProposalOccupationDetails(data));
      setEdit(false);
      dispatch(setPosition(position + 1));
    });
  };

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Proposer Occupation Details</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  else
    return edit ? (
      <>
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Proposer Occupation Details
          </CardTitle>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup proposalCard eligibilityPage>
              {/* <div className="upgradeDropList smallCustomDropDownSpace">
                <div className="upgradeDropLabel">Proposer Occupation</div>
                <div className="filterMenuSelectWrap selectIncome"> */}
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Proposer Occupation"
                  propDropDown
                  items={[
                    "Salaried",
                    "Professional",
                    ...notAllowed,
                    ...occupationList,
                  ]}
                  selectedItem={
                    proposalOccupationDetails.occupation || selectedOccupation
                  }
                  currentSelected={occupation}
                  onChange={e => {
                    if (notAllowed.some(item => item === e)) {
                      alert(`The policy is not applicable for ${ e }`);
                      setValue("occupation", occupationList[0]);
                      setOccupation(occupationList[0]);
                      trigger("occupation");
                      return;
                    }
                    setValue("occupation", e);
                    trigger("occupation");
                    setOccupation(e);
                  }}
                  disabled={
                    (insFor === "self" &&
                      !occupation?.includes("Self Employed")) ||
                    (insFor === "dependent" && false)
                  }
                />
              </CustomDropDownFont>
              {/* </div>
              </div> */}
              {errors.occupation && (
                <p className="errorP">{errors.occupation.message}</p>
              )}
            </FormGroup>
            {occupation === "Others" && (
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  type="text"
                  id="occuDesc"
                  name="occuDesc"
                  onKeyDown={noCopyAndPaste}
                  error={errors.occuDesc && "danger"}
                  defaultValue={proposalOccupationDetails.occuDesc}
                  placeholder=" "
                  ref={register}
                />
                <Label md htmlFor="occuDesc">
                  Proposer Occupation Description
                </Label>
                {errors.occuDesc && (
                  <p className="errorP">{errors.occuDesc.message}</p>
                )}
              </FormGroup>
            )}
            {(occupation === "Salaried" ||
              occupation === "Self Employed" ||
              occupation === "Others") && (
                <FormGroup style={{ textAlign: "left" }} xlg formPages mobileSm>
                  <div style={{ marginBottom: 20 }}>
                    Are you engaged in any of the mentioned industries ? (Export,
                    Jewellery, Real Estate, Scrap Dealing, Shipping, Stock Broking
                    & Agriculture)
                  </div>
                  <RadioComponent
                    // checked={proposalOccupationDetails.engagedIn}
                    // className="toggle--sb"
                    value={engadedIn}
                    name="engagedIn"
                    // label="Are you engaged in any of the mentioned industries ?
                    //  (Export, Jewellery, Real Estate, Scrap Dealing, Shipping,
                    //  Stock Broking & Agriculture)"
                    updateChecked={value => {
                      setValue("engagedIn", value);
                      trigger("engagedIn");
                      setEngadedIn(value);
                    }}
                  />
                </FormGroup>
              )}
            {engadedIn && (
              <>
                <FormGroup proposalCard eligibilityPage>
                  {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Industry Type</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      searchable={true}
                      dropDownTitle="Industry Type"
                      propDropDown
                      items={indTypeList}
                      selectedItem={proposalOccupationDetails.indType}
                      onChange={e => {
                        setValue("indType", e);
                        trigger("indType");
                      }}
                    />
                  </CustomDropDownFont>
                  {/* </div>
                  </div> */}
                  {errors.indType && (
                    <p className="errorP">{errors.indType.message}</p>
                  )}
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  <TextInputElg
                    proposal
                    type="text"
                    id="indDesc"
                    name="indDesc"
                    error={errors.indDesc && "danger"}
                    onKeyDown={noCopyAndPaste}
                    defaultValue={proposalOccupationDetails.indDesc}
                    placeholder=" "
                    ref={register}
                  />
                  <Label md htmlFor="indDesc">
                    Industry Description
                  </Label>
                  {errors.indDesc && (
                    <p className="errorP">{errors.indDesc.message}</p>
                  )}
                </FormGroup>
              </>
            )}
            {(occupation === "Salaried" ||
              occupation === "Self Employed" ||
              occupation === "Others" ||
              occupation === "Self Employed-Others") && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Organisation Name</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Organisation Name"
                        propDropDown
                        items={orgNameList}
                        searchable
                        style={{
                          pointerEvents:
                            (occupation === "Self Employed-Others" ||
                              occupation === "Self Employed") &&
                            "none",
                        }}
                        selectedItem={proposalOccupationDetails.orgName}
                        currentSelected={orgName}
                        onChange={e => {
                          setValue("orgName", e);
                          trigger("orgName");
                          setOrgName(e);
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                  </div> */}
                    {errors.orgName && (
                      <p className="errorP">{errors.orgName.message}</p>
                    )}
                  </FormGroup>
                  {orgName === "Others" && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="orgNameDesc"
                        name="orgNameDesc"
                        error={errors.orgNameDesc && "danger"}
                        readOnly={occupation === "Self Employed-Others"}
                        onKeyDown={noCopyAndPaste}
                        defaultValue={proposalOccupationDetails.orgNameDesc}
                        placeholder=" "
                        ref={register}
                      />
                      <Label md htmlFor="orgNameDesc">
                        Organisation Name Description*
                      </Label>
                      {errors.orgNameDesc && (
                        <p className="errorP">{errors.orgNameDesc.message}</p>
                      )}
                    </FormGroup>
                  )}
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                    <div className="upgradeDropLabel">Organisation Type*</div>
                    <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Organisation Type"
                        propDropDown
                        searchable={true}
                        items={
                          occupation === "Self Employed"
                            ? orgTypeListSelf
                            : orgTypeList
                        }
                        style={{
                          pointerEvents:
                            occupation === "Self Employed-Others" && "none",
                        }}
                        selectedItem={proposalOccupationDetails.orgType}
                        currentSelected={orgType}
                        onChange={e => {
                          setValue("orgType", e);
                          trigger("orgType");
                          setOrgType(e);
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                  </div> */}
                    {errors.orgType && (
                      <p className="errorP">{errors.orgType.message}</p>
                    )}
                  </FormGroup>
                  {orgType === "Others" && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="orgTypeDesc"
                        name="orgTypeDesc"
                        error={errors.orgTypeDesc && "danger"}
                        onKeyDown={noCopyAndPaste}
                        defaultValue={proposalOccupationDetails.orgTypeDesc}
                        placeholder=" "
                        ref={register}
                        readOnly={occupation === "Self Employed-Others"}
                      />
                      <Label md htmlFor="orgTypeDesc">
                        Organisation Type Description
                      </Label>
                      {errors.orgTypeDesc && (
                        <p className="errorP">{errors.orgTypeDesc.message}</p>
                      )}
                    </FormGroup>
                  )}
                </>
              )}
            {occupation === "Professional" && (
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">Profession</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    searchable={true}
                    dropDownTitle="Profession"
                    propDropDown
                    items={professionList}
                    selectedItem={
                      proposalOccupationDetails.profession ||
                      selectedNatureOfWork
                    }
                    style={{ pointerEvents: "none" }}
                    onChange={e => {
                      setValue("profession", e);
                      trigger("profession");
                    }}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
                {errors.profession && (
                  <p className="errorP">{errors.profession.message}</p>
                )}
              </FormGroup>
            )}
            <FormGroup proposalCard eligibilityPage>
              {/* <div className="upgradeDropList smallCustomDropDownSpace">
                <div className="upgradeDropLabel">Education</div>
                <div className="filterMenuSelectWrap selectIncome"> */}
              <CustomDropDownFont rdr>
                <CustomDropDown
                  searchable={true}
                  dropDownTitle="Education"
                  propDropDown
                  items={educationList}
                  currentSelected={selectedEducation}
                  selectedItem={
                    proposalOccupationDetails.education || selectedEducation
                  }
                  onChange={e => {
                    setValue("education", e);
                    trigger("education");
                  }}
                  disabled
                />
              </CustomDropDownFont>
              {/* </div>
              </div> */}
              {errors.education && (
                <p className="errorP">{errors.education.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                type="text"
                id="annIncome"
                name="annualIncome"
                onKeyDown={noCopyAndPaste}
                error={errors.annualIncome && "danger"}
                defaultValue={
                  proposalOccupationDetails.annualIncome || annualIncome
                }
                placeholder=" "
                ref={attachRef}
                onInput={numOnly}
                onChange={getInWord}
              />
              <Label md htmlFor="annIncome">
                Annual Income
              </Label>
              {!annualIncomeError && annualIncomeWord && (
                <p
                  className="annualIncomeText"
                  style={{ whiteSpace: "pre-wrap" }}
                >{`${ annualIncomeWord } per annum`}</p>
              )}
              {/* {errors.annualIncome && (
								<p className="errorP">{errors.annualIncome.message}</p>
							)} */}
              {annualIncomeError && (
                <p className="errorP annualncomeError">{annualIncomeError}</p>
              )}
            </FormGroup>
            <FormGroup xlg formPages propSubWrap>
              <button
                // disabled={!isValid || annualIncomeError}
                disabled={annualIncomeError}
                type="submit"
                className={
                  // !isValid
                  annualIncomeError
                    ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit proceedOccupation"
                    : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit proceedOccupation"
                }
              >
                PROCEED TO INSURED DETAILS 1
              </button>
            </FormGroup>
          </Form>
        </Card>
      </>
    ) : (
      proposalOccupationDetails && (
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Proposer Occupation Details
            <span
              style={{
                float: "right",
                fontSize: "14px",
                color: "#107591",
                cursor: "pointer",
              }}
              onClick={() => {
                setEdit(true);
                dispatch(setPosition(index));
              }}
            >
              <img src={editIcon} alt="editIcon" />
              &nbsp;EDIT
            </span>
          </CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <FormGroup md formPages>
              <Label md editMode>
                Occupation
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {proposalOccupationDetails.occupation}
              </span>
            </FormGroup>
            {"occuDesc" in proposalOccupationDetails &&
              proposalOccupationDetails.occuDesc && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Occupation Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.occuDesc}
                  </span>
                </FormGroup>
              )}
            {"profession" in proposalOccupationDetails &&
              proposalOccupationDetails.profession && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Profession
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.profession}
                  </span>
                </FormGroup>
              )}
            {"engagedIn" in proposalOccupationDetails && (
              <FormGroup md formPages>
                <Label md editMode>
                  Are you Engaged In any mentioned Industries?
                </Label>
                <p
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {proposalOccupationDetails.engagedIn ? "YES" : "NO"}
                </p>
              </FormGroup>
            )}
            {"indType" in proposalOccupationDetails &&
              proposalOccupationDetails.indType && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Industry Type
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.indType}
                  </span>
                </FormGroup>
              )}
            {"indDesc" in proposalOccupationDetails &&
              proposalOccupationDetails.indDesc && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Industry Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.indDesc}
                  </span>
                </FormGroup>
              )}
            {"orgName" in proposalOccupationDetails &&
              proposalOccupationDetails.orgName && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Organisation Name
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgName}
                  </span>
                </FormGroup>
              )}
            {"orgNameDesc" in proposalOccupationDetails &&
              proposalOccupationDetails.orgNameDesc && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Organisation Name Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgNameDesc}
                  </span>
                </FormGroup>
              )}
            {"orgType" in proposalOccupationDetails &&
              proposalOccupationDetails.orgType && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Organisation Type
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgType}
                  </span>
                </FormGroup>
              )}
            {"orgTypeDesc" in proposalOccupationDetails &&
              proposalOccupationDetails.orgTypeDesc && (
                <FormGroup md formPages>
                  <Label md editMode>
                    Organisation Type Description
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposalOccupationDetails.orgTypeDesc}
                  </span>
                </FormGroup>
              )}
            <FormGroup md formPages>
              <Label md editMode>
                Education
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {selectedEducation || proposalOccupationDetails.education}
              </span>
            </FormGroup>
            <FormGroup md formPages>
              <Label md editMode>
                Annual Income
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {proposalOccupationDetails.annualIncome}
              </span>
            </FormGroup>
          </div>
        </Card>
      )
    );
};

export default ProposerOccupation;
