import React from "react";
import { Redirect } from "react-router-dom";
import { CompareProductsWrap } from "./components";
import "./compare-page.scss";
import EmailPopup from "../../components/Popup/EmailPopup";
import { BackArrow } from "./SvgComp";
import LaxmiGreeting from "../../components/LaxmiGreeting/LaxmiGreeting";
import useComparePage from "./useComparePage";

function ComparePage() {
  const {
    quotesForCompare,
    quotesPagePath,
    isEmailQuotesPopup,
    handleBackButtonClick,
    showEmailQuotesPopup,
    hideEmailQuotesPopup,
  } = useComparePage();

  if (quotesForCompare.length === 0) return <Redirect to={quotesPagePath} />;

  return (
    <>
      <button
        className="compare-page__back-btn bck"
        onClick={handleBackButtonClick}
      >
        {BackArrow} <span className="compare-page__back-text">Back</span>
      </button>
      <div className="compare-page compare-page-container">
        <EmailPopup show={isEmailQuotesPopup} onClose={hideEmailQuotesPopup} />
        <button
          className="compare-page__email-quotes-btn"
          onClick={showEmailQuotesPopup}
        >
          Email Quotes
        </button>
        <div className="laksmi_logo">
          <LaxmiGreeting />
          <div className="compare-page__laxmi-text">
            Compare to get better understanding of plans
          </div>
        </div>
        <CompareProductsWrap />
      </div>
    </>
  );
}

export default ComparePage;
