import React from "react";
import "./WhiteListed.scss";
import { useSelector } from "react-redux";
import laxmiImg from "./../../../../assets/img/girl-img.png";
const WhiteListed = () => {
  const { customerDetails } = useSelector(state => state.greeting);
  return (
    <>
      <div class="whiteListCupWrap desktopLable">
        <svg
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M23,1h-4.5v-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-12c-0.276,0 -0.5,0.224 -0.5,0.5v0.5h-4.5c-0.276,0 -0.5,0.224 -0.5,0.5v4.5c0,3.309 2.691,6 6,6c0.02,0 0.037,-0.009 0.056,-0.011c0.901,1.38 2.305,2.412 3.93,2.818c-0.063,1.25 -0.382,3.855 -1.344,4.845l-0.272,0.278c-1.376,1.412 -2.37,2.432 -2.37,3.57c0,0.276 0.224,0.5 0.5,0.5h10c0.276,0 0.5,-0.224 0.5,-0.5c0,-1.138 -0.994,-2.158 -2.37,-3.57l-0.271,-0.279c-0.962,-0.99 -1.281,-3.595 -1.344,-4.845c1.625,-0.405 3.029,-1.438 3.93,-2.818c0.018,0.003 0.035,0.012 0.055,0.012c3.309,0 6,-2.691 6,-6v-4.5c0,-0.276 -0.224,-0.5 -0.5,-0.5Zm-21.5,5v-4h4v6.5c0,0.861 0.18,1.689 0.5,2.45c-2.519,-0.255 -4.5,-2.364 -4.5,-4.95Zm21,0c0,2.586 -1.981,4.695 -4.5,4.95c0.32,-0.76 0.5,-1.588 0.5,-2.45v-6.5h4v4Z"
            fill="#107591"
          ></path>
        </svg>
        <span>Congratulations!</span> Your work email id from{" "}
        <span>
          {
            (customerDetails.work_email || customerDetails.workEmail || customerDetails.email).split(
              "@",
            )[1]
          }
        </span>{" "}
        has unlocked your <span>corporate superpower!</span>
      </div>
      <div className="whiteListCupWrap mobileLable">
        <img src={laxmiImg} alt="logo laxmi" />
        <div className="white-list-text">
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M23,1h-4.5v-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-12c-0.276,0 -0.5,0.224 -0.5,0.5v0.5h-4.5c-0.276,0 -0.5,0.224 -0.5,0.5v4.5c0,3.309 2.691,6 6,6c0.02,0 0.037,-0.009 0.056,-0.011c0.901,1.38 2.305,2.412 3.93,2.818c-0.063,1.25 -0.382,3.855 -1.344,4.845l-0.272,0.278c-1.376,1.412 -2.37,2.432 -2.37,3.57c0,0.276 0.224,0.5 0.5,0.5h10c0.276,0 0.5,-0.224 0.5,-0.5c0,-1.138 -0.994,-2.158 -2.37,-3.57l-0.271,-0.279c-0.962,-0.99 -1.281,-3.595 -1.344,-4.845c1.625,-0.405 3.029,-1.438 3.93,-2.818c0.018,0.003 0.035,0.012 0.055,0.012c3.309,0 6,-2.691 6,-6v-4.5c0,-0.276 -0.224,-0.5 -0.5,-0.5Zm-21.5,5v-4h4v6.5c0,0.861 0.18,1.689 0.5,2.45c-2.519,-0.255 -4.5,-2.364 -4.5,-4.95Zm21,0c0,2.586 -1.981,4.695 -4.5,4.95c0.32,-0.76 0.5,-1.588 0.5,-2.45v-6.5h4v4Z"
              fill="#fff"
            ></path>
          </svg>
          <span className="nameWrapMob">
            Congratulations, {customerDetails.firstName}!{" "}
          </span>
          Your work email id from
          <span>
            {" "}
            {
              (customerDetails.work_email || customerDetails.workEmail || customerDetails.email).split(
                "@",
              )[1]
            }
          </span>{" "}
          has unlocked your <span>corporate superpower!</span>
          <div className="recomendedText">
            Here’s what I recommend basis the details you have provided.
          </div>
        </div>
      </div>
    </>
  );
};

export default WhiteListed;
