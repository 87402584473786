/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAmount,
  getTerm,
  getPPTerm,
  getValue,
} from "../../utils/inputUtils";
import { FormGroup, CustomDropDownFont } from "./../../components/Form/Form";
import Card from "../../components/Card/Card";
import CardTitle from "../../components/Card/CardTitle/CardTitle";
import SmallCustomDropDown from "../../components/SmallCustomDropDown/SmallCustomDropDown";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import "./addonCard.scss";
import moment from "moment";
import SpousePopup from "../../components/Popup/spousePopup";
import ChildDetails from "../../components/Popup/AddonChildPopup/childPopup";
import {
  RidersPremium,
  setLoading,
  getCriticalIllness,
  getSeriousIllness,
} from "./RiderPage.slice";
import DOMpurify from "dompurify";
import SecureLS from "secure-ls";
import { getNumberfromString } from "../../utils/inputUtils";
import addTick from "../../assets/img/addon-tick.svg";
import swal from "sweetalert";
import { getPayPremiumMode } from "../QuotePage/helper.js";
import {
  getCostPer,
  getMax,
  getMin,
  getTermLimit,
  shouldBeDisabled,
  shouldDisplay,
  showCard,
  showPPT,
  validateSelection,
} from "./helper";
import { useCustomLocation } from "../../custom-hooks";
import Loader from "../../components/Loader/Loader.js";
import { numberToDigitWord } from "../QuoteFilter/helper.js";
const AddonCard = ({
  rider_name,
  rider_description,
  cover_amt_limit,
  term_limit,
  term_min,
  cover_amt_min,
  diabetic,
  setCount,
  rider_shortname,
  addOnsList,
  riderId,
  term_insu_id,
  setAddOns,
  setAdditionalRiders,
  setAdditionalGst,
  selected,
  rider_min_ppt,
  rider_max_ppt,
  maturity,
  comeBack,
  setComeBack,
  checkDiabetic,
  setCheckDiabetic,
  additionalRiders,
  totalPremium,
  totalPremiumGst,
  setTriggerUpdate,
  riderAddOnsDetails,
  coverAmountArray,
}) => {
  const CIRiderArray = addOnsList.filter(
    item => item?.rider_shortname === "C4CI",
  );

  const CIRiderVal = CIRiderArray[0]?.sumAssured;
  const dispatch = useDispatch();
  const { enquiryId } = useCustomLocation();
  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );
  const { filter } = useSelector(state => state.quoteFilter);
  const [modeOfPayment, setModeOfPayment] = useState(
    getPayPremiumMode(
      filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );
  const [showSpouse, setShowSpouse] = useState(false);
  const [showChild, setShowChild] = useState(false);
  const [childOrSpouse, setChildOrSpouse] = useState(false);
  const [variantDisabled, setVariantDisabled] = useState(false);
  const { customerDetails } = useSelector(({ greeting }) => greeting);
  const [checkedDiabetic, setCheckedDiabetic] = useState(false);
  const age = moment().diff(moment(customerDetails.dob, "DD/MM/YYYY"), "year");
  const userAge = customerDetails?.age;
  const { ridersPremium, spouseVariantFlag, childVariantFlag, premiumLoader } =
    useSelector(state => state.rider);
  const [clicked, setClicked] = useState("remove");
  const [compulsory, setCompulsory] = useState(false);
  const desc = useRef();
  const [errorMsg, setErrorMsg] = useState(false);

  const buttonText = `${modeOfPayment}`;
  const [errorCover, setErrorCover] = useState();
  const [PPTList, setPPTList] = useState([]);
  const [PPTerm, setPPTerm] = useState(PPTList[0]);
  const [gst, setGst] = useState(totalPremiumGst);
  const [localTrigger, setLocalTrigger] = useState(false);
  const newAddonListWop = riderAddOnsDetails?.find(
    item => item.rider_shortname === "WOP",
  );
  const isDiabetic = customerDetails?.isDiabetic;
  useEffect(() => {
    if (isDiabetic === true) {
      setCheckedDiabetic(true);
    }
  }, [isDiabetic]);
  const shouldAutoSelectCIBF = false;
  // Cover Amount List generate
  const COVER_AMOUNT =
    coverAmountArray && selected?.master_product_name === "eTouch"
      ? coverAmountArray
          ?.map(e => numberToDigitWord(e?.display_name))
          ?.reverse()
      : [
          ...getAmount(
            getMin(
              rider_shortname,
              selected,
              filter,
              shouldAutoSelectCIBF ? 500000 : cover_amt_min,
            ),
            getMax(
              rider_shortname,
              selected,
              age,
              cover_amt_limit,
              selected.differentTenureCU ||
                filter.coverUpto === "Whole Life" ||
                filter.coverUpto.match(/(\d+)/)[0],
              customerDetails?.annualIncome,
            ),
            filter.nomineeGets !== "Lumpsum"
              ? selected.sumAssured
              : Number(selected.cover_amount),
            40000,
            selected?.company_alias,
          ),
        ];
  const [coverAmount, setCoverAmount] = useState("Select");
  const [reFetch, setRefetch] = useState(false);
  const [reFetch1, setRefetch1] = useState(false);
  useEffect(() => {
    if (selected?.company_alias === "hdfc_life") {
      if (addOnsList.some(item => item.rider_shortname === "ADB")) {
        setRefetch(true);
      } else setRefetch(false);
      if (addOnsList.some(item => item.rider_shortname === "WP")) {
        setRefetch1(true);
      } else setRefetch1(false);
    }
  }, [addOnsList]);

  useEffect(() => {
    if (!PPTList.includes(PPTerm)) {
      setPPTerm(PPTList[0]);
    }
  }, [PPTList, filter, PPTerm]);

  // RiderTerm list generate
  const RIDER_TERM = getTerm(
    term_min,
    getTermLimit(selected, rider_shortname, filter, term_limit, age),
    (selected.differentTenureCU ||
      filter.coverUpto === "Whole Life" ||
      filter.coverUpto.match(/(\d+)/)[0]) - age,
    selected?.company_alias,
    selected?.mode_Of_Payment,
    rider_shortname,
    maturity - age,
  );

  useEffect(() => {
    if (diabetic === "Y") {
      setCheckedDiabetic(checkDiabetic);
    }
  }, [checkDiabetic, diabetic]);
  const fetchPremium = (value, type) => {
    setErrorCover("");
    dispatch(setLoading(true));
    setClicked("add");

    let ppt;
    if (type === "ppTerm") {
      ppt = getNumberfromString(value);
    } else if (
      Number(getNumberfromString(PPTerm)) >=
      (type === "riderTerm"
        ? Number(getNumberfromString(value))
        : Number(getNumberfromString(riderTerm)))
    ) {
      ppt = getNumberfromString((type === "riderTerm" && value) || riderTerm);
    } else ppt = getNumberfromString(PPTerm);

    if (
      rider_shortname === "INAD" &&
      selected?.company_alias === "hdfc_life" &&
      filter.paymentTerm.option !== "Single Pay"
    ) {
      if (parseInt((type === "riderTerm" && value) || riderTerm) <= 5) {
        ppt = parseInt((type === "riderTerm" && value) || riderTerm);
      } else if (
        parseInt(PPTerm) < 5 &&
        parseInt((type === "riderTerm" && value) || riderTerm) > 5
      ) {
        ppt = parseInt((type === "riderTerm" && value) || riderTerm);
      }
    }
    if (addOnsList.some(item => item.rider_shortname === rider_shortname)) {
      let temp = addOnsList.map(item => {
        if (item.rider_shortname === rider_shortname) {
          if (type === "riderTerm") {
            item.term = getNumberfromString(value);
          }
          if (type === "cvrAmt") {
            item.sumAssured = getNumberfromString(value);
          }
          if (type === "ppTerm") {
            item.riderPpt = getNumberfromString(ppt);
          }
          return item;
        } else return item;
      });

      if (
        !validateSelection(
          (type === "cvrAmt" && value) || coverAmount,
          rider_shortname,
          selected?.company_alias,
          temp,
        )
      ) {
        setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
        setCount(prev => prev - 1);
        for (let i in addOnsList) {
          if (addOnsList[i].rider_name === rider_name) {
            setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
            setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
          }
        }
      } else {
        setAddOns(temp);
      }
    }
    dispatch(
      RidersPremium({
        addedRiders: addOnsList,
        traceId: enquiryId,
        termInsuId: term_insu_id,
        riderId: riderId,
        riderCoveramt: getNumberfromString(
          (type === "cvrAmt" && value) || coverAmount,
        ),
        riderTerm: getNumberfromString(
          (type === "riderTerm" && value) || riderTerm,
        ),
        riderPpt:
          selected?.company_alias === "hdfc_life" &&
          (rider_shortname === "PPR_CC" || rider_shortname === "C4CI")
            ? filter?.paymentTerm?.option == "Limited Pay" &&
              filter?.paymentTerm?.term == "5 Years" &&
              rider_shortname === "C4CI"
              ? getNumberfromString(
                  ppt || PPTerm || (type === "riderTerm" && value),
                )
              : getNumberfromString(
                  (type === "riderTerm" && value) || ppt || PPTerm,
                )
            : ppt,

        riderShortname: rider_shortname,
      }),
    );
    if (addOnsList.some(item => item.rider_shortname === rider_shortname)) {
      let temp = addOnsList.map(item => {
        if (item.rider_shortname === rider_shortname) {
          if (type === "riderTerm") {
            item.term = getNumberfromString(value);
          }
          if (type === "cvrAmt") {
            item.sumAssured = getNumberfromString(value);
          }
          if (type === "ppTerm") {
            item.riderPpt = getNumberfromString(ppt);
          }
          return item;
        } else return item;
      });
      if (
        !validateSelection(
          (type === "cvrAmt" && value) || coverAmount,
          rider_shortname,
          selected?.company_alias,
          temp,
        )
      ) {
        setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
        setCount(prev => prev - 1);
        for (let i in addOnsList) {
          if (addOnsList[i].rider_name === rider_name) {
            setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
            setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
          }
        }
      } else {
        setAddOns(temp);
      }
    }
  };

  useEffect(() => {
    if (
      rider_shortname === "WP" &&
      selected?.company_alias === "hdfc_life" &&
      (selected?.differentTenureCU ||
        filter.coverUpto === "Whole Life" ||
        Number(filter.coverUpto.match(/(\d+)/)[0])) <= 85
    ) {
      dispatch(setLoading(true));
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: 0,
          riderTerm: 0,
          riderShortname: "WP",
          riderPpt: 0,
        }),
      );
    }
  }, [reFetch]);

  useEffect(() => {
    for (let i in addOnsList) {
      if (addOnsList[i].rider_name === rider_name) {
        if (selected?.company_alias === "hdfc_life") {
          setriderTerm(`${addOnsList[i].term} years`);
          setPPTerm(`${addOnsList[i].riderPpt} years`);
        }
        setPrice(addOnsList[i].premium);
        setGst(addOnsList[i].gst);
        setCoverAmount(getValue(addOnsList[i].sumAssured));
      }
    }
    setCount(addOnsList.length);
  }, [comeBack, addOnsList, selected, rider_name, setCount, rider_shortname]);
  useEffect(() => {
    if (
      rider_shortname === "ADB" &&
      selected?.company_alias === "hdfc_life" &&
      (selected?.differentTenureCU ||
        filter.coverUpto === "Whole Life" ||
        Number(filter.coverUpto.match(/(\d+)/)[0])) <= 75
    ) {
      dispatch(setLoading(true));
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: 0,
          riderTerm: 0,
          riderShortname: "ADB",
          riderPpt: 0,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (rider_shortname === "CIBF");
    desc.current.addEventListener("click", () => {
      if (rider_shortname === "SI") {
        dispatch(getSeriousIllness({ companyAlias: selected.company_alias }));
      } else
        dispatch(
          getCriticalIllness({
            companyAlias: selected.company_alias,
            rider: rider_shortname,
          }),
        );
    });
  }, []);
  useEffect(() => {
    if (
      (selected?.company_alias === "kotak_life" ||
        selected?.company_alias === "max_life") &&
      (rider_shortname === "ADB" || rider_shortname === "WOP")
    ) {
      setCoverAmount("");
      setPrice(totalPremium);
      setGst(totalPremiumGst);
    }
    if (
      selected?.company_alias === "hdfc_life" &&
      (rider_shortname === "WP" || rider_shortname === "ADB")
    ) {
      setCoverAmount("");
      setPrice(selected[`totalPremium_${rider_shortname}`] || 0);
      setGst(selected[`totalPremiumGst_${rider_shortname}`] || 0);
    }
    if (
      (selected?.company_alias === "pnb_metlife" ||
        selected?.company_alias === "tata_aia_life") &&
      rider_shortname === "LSB"
    ) {
      setCoverAmount("");
      setPrice(selected[`totalPremium_${rider_shortname}`] || 0);
      setGst(selected[`totalPremiumGst_${rider_shortname}`] || 0);
    }
    if (
      selected?.company_alias === "pnb_metlife" &&
      (rider_shortname === "WOP" || rider_shortname === "ACI")
    ) {
      setCoverAmount("");
      setPrice(selected[`totalPremium_${rider_shortname}`]);
      setGst(selected[`totalPremiumGst_${rider_shortname}`]);
    }
    if (selected?.company_alias === "bajaj_life" && rider_shortname === "WOP") {
      setCoverAmount("");
      setPrice(newAddonListWop[`totalPremium_${rider_shortname}`]);

      setGst(newAddonListWop[`totalPremiumGst_${rider_shortname}`]);
    }

    if (shouldAutoSelectCIBF) {
      setCoverAmount(getValue(Math.min(selected.cover_amount / 10, 500000)));
      setPrice(selected[`totalPremium_${rider_shortname}`]);
      setGst(selected[`totalPremiumGst_${rider_shortname}`]);
      fetchPremium("500000", "cvrAmt");
    }
    if (selected.company_alias === "hdfc_life" && rider_shortname === "ADB") {
      setCoverAmount(getValue(selected.cover_amount * 2));
    }
  }, [selected, rider_shortname, filter]);

  useEffect(() => {
    if (
      !addOnsList.some(item => item.rider_shortname === rider_shortname) &&
      shouldAutoSelectCIBF
    ) {
      setAddOns([
        {
          rider_name,
          rider_description,
          rider_shortname,
          sumAssured: Math.min(selected.cover_amount / 10, 500000),
          term: getNumberfromString(riderTerm),
          riderId: riderId,
        },
      ]);
      setCompulsory(true);
      setCount(1);
    }
    if (shouldAutoSelectCIBF) {
      setCompulsory(true);
    }
    return () => setCompulsory(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected,
    filter,
    coverAmount,
    addOnsList,
    rider_shortname,
    setAddOns,
    rider_name,
    rider_description,
    riderId,
    setCount,
  ]);

  const [riderTerm, setriderTerm] = useState(
    `${
      filter?.coverUpto && selected?.company_alias === "icici_pru"
        ? `${
            filter.coverUpto !== "Whole Life" &&
            (typeof filter.coverUpto === "number"
              ? filter.coverUpto
              : filter.coverUpto.match(/(\d+)/)[0]) -
              moment().diff(moment(customerDetails.dob, "DD/MM/YYYY"), "year")
          } years`
        : RIDER_TERM[0]
    }`,
  );
  useEffect(() => {
    if (!selected) return setriderTerm(RIDER_TERM[0]);
  }, [term_limit, selected]);

  useEffect(() => {
    if (
      selected?.company_alias === "hdfc_life" ||
      selected?.company_alias === "bajaj_life"
    )
      setPPTList(
        getPPTerm(
          rider_min_ppt,
          rider_max_ppt,
          rider_shortname,
          selected?.company_alias,
          selected?.mode_Of_Payment,
          riderTerm,
          filter?.paymentTerm,
          age,
        ),
      );
  }, [riderTerm, filter?.paymentTerm]);

  useEffect(() => {
    if (
      addOnsList.some(item => item.rider_shortname === rider_shortname) &&
      shouldBeDisabled(
        addOnsList,
        rider_shortname,
        selected?.company_alias,
        checkedDiabetic,
        compulsory,
      )
    ) {
      setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
      setCount(prev => prev - 1);
      for (let i in addOnsList) {
        if (addOnsList[i].rider_name === rider_name) {
          setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
          setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
        }
      }
    }
  }, [checkedDiabetic, addOnsList, rider_shortname]);
  const onClick = () => {
    if (rider_shortname === "SC" && !showSpouse) {
      setShowSpouse(true);
      return;
    }
    if (rider_shortname === "CB" && !showChild) {
      setShowChild(true);
      return;
    }

    if (coverAmount === "Select" || coverAmount?.includes("select")) {
      setErrorCover("Please select cover amount");
      setCoverAmount("Please select cover amount");
    } else if (
      !addOnsList.some(item => item.rider_shortname === rider_shortname)
    ) {
      setLocalTrigger(true);
      if (
        !validateSelection(
          coverAmount,
          rider_shortname,
          selected?.company_alias,
          addOnsList,
          discountedPremium(selected?.premium_amount),
          price,
        )
      )
        return;
      setAddOns([
        ...addOnsList,
        {
          rider_name,
          rider_description,
          rider_shortname,
          sumAssured: getNumberfromString(coverAmount),
          term: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(PPTerm),
          riderId: riderId,
          premium: price,
          gst: gst,
        },
      ]);

      setCount(prev => prev + 1);
    } else {
      setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
      setLocalTrigger(true);
      setCount(prev => prev - 1);
      for (let i in addOnsList) {
        if (addOnsList[i].rider_name === rider_name) {
          setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
          setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
        }
      }
    }
  };
  let temp = addOnsList.find(item => item.rider_shortname.includes("CI"));
  let temp2 = addOnsList.find(item => item.rider_shortname.includes("ADB"));
  let temp3 = addOnsList.find(item => item.rider_shortname.includes("CAB"));
  let TPDB = addOnsList.find(item => item.rider_shortname.includes("TPDB"));
  let ATPD = addOnsList.find(item => item.rider_shortname.includes("ATPD"));

  useEffect(() => {
    if (selected?.company_alias === "max_life") {
      if (
        !addOnsList.some(item => item.rider_shortname.includes("CI")) &&
        addOnsList.some(item => item.rider_shortname === "TPDB")
      ) {
        setAddOns(addOnsList.filter(item => item.rider_shortname !== "TPDB"));
        setLocalTrigger(true);
        setCount(prev => prev - 1);
        for (let i in addOnsList) {
          if (addOnsList[i].rider_shortname === "TPDB") {
            setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
            setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
          }
        }
      }

      let TPDB = addOnsList.find(item => item.rider_shortname.includes("TPDB"));

      if (TPDB && rider_shortname.includes("CI") && RIDER_TERM) {
        setriderTerm(TPDB.term + " years");
      } else {
        setriderTerm(RIDER_TERM[0]);
      }
    }
  }, [addOnsList]);

  useEffect(() => {
    if (
      temp &&
      temp?.premium &&
      rider_shortname === "TPDB" &&
      selected?.company_alias === "max_life"
    ) {
      setErrorCover("");
      dispatch(setLoading(true));
      setClicked("add");
      setCoverAmount(getValue(temp.sumAssured));
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: temp.sumAssured,
          riderTerm: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(riderTerm),
          riderShortname: rider_shortname,
        }),
      );
    }
  }, [temp?.premium]);

  useEffect(() => {
    if (rider_shortname === "WOP" && selected?.company_alias === "bajaj_life") {
      dispatch(setLoading(true));
      setClicked("add");

      dispatch(
        RidersPremium({
          addedRiders: JSON.parse(JSON.stringify(addOnsList)),
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: 0,
          riderTerm: 0,
          riderShortname: "WOP",
          riderPpt: 0,
        }),
      );
    }
  }, [temp2?.premium]);
  useEffect(() => {
    if (
      rider_shortname === "ADB" &&
      selected?.company_alias === "bandhan_life"
    ) {
      dispatch(setLoading(true));
      setCoverAmount("0");
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: JSON.parse(JSON.stringify(addOnsList)),
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: 0,
          riderTerm: 0,
          riderShortname: "ADB",
          riderPpt: 0,
        }),
      );
    }
  }, [temp2?.premium, ATPD?.premium]);
  useEffect(() => {
    if (
      temp &&
      selected?.company_alias === "max_life" &&
      rider_shortname === temp.rider_shortname
    ) {
      setErrorCover("");
      dispatch(setLoading(true));
      setClicked("add");
      setCoverAmount(getValue(temp.sumAssured));
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: temp.sumAssured,
          riderTerm: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(riderTerm),
          riderShortname: rider_shortname,
        }),
      );
    }
  }, [riderTerm]);

  const [price, setPrice] = useState(totalPremium);
  const [costPer, setCostPer] = useState();
  const discountedPremium = () => {
    return Number(selected?.premium_amount);
  };
  useEffect(() => {
    setCostPer(
      getCostPer(
        rider_shortname,
        coverAmount,
        ridersPremium,
        filter,
        price,
        selected,
        buttonText,
        comeBack,
        setComeBack,
        compulsory,
        newAddonListWop,
      ),
    );
  }, [
    buttonText,
    coverAmount,
    filter,
    price,
    rider_shortname,
    ridersPremium,
    selected,
    setComeBack,
  ]);
  useEffect(() => {
    if (
      ridersPremium?.ridersData?.data[`totalPremium_${rider_shortname}`] &&
      clicked === "add"
    ) {
      setPrice(
        ridersPremium?.ridersData?.data[`totalPremium_${rider_shortname}`],
      );
      setGst(
        ridersPremium?.ridersData?.data[`totalPremiumGst_${rider_shortname}`],
      );
      setClicked("remove");
      setLocalTrigger(true);
    }
    if (
      ridersPremium?.ridersData?.data?.status === "false" &&
      ridersPremium?.ridersData?.data?.message &&
      clicked === "add" &&
      selected?.company_alias !== "hdfc_life"
    ) {
      swal("Oops!", ridersPremium?.ridersData?.data?.message, "error"); //Before "cannot remove rider after selecting invalid rider amount" issue
      swal("Oops!", ridersPremium?.ridersData?.data?.message, "error").then(
        () => {
          setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
          setLocalTrigger(true);
          setCount(prev => prev - 1);
          for (let i in addOnsList) {
            if (addOnsList[i].rider_name === rider_name) {
              setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
              setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
            }
          }
        },
      );
      setCoverAmount("Select");
      setPrice("");
      setGst(0);
    }
  }, [ridersPremium]);
  useEffect(() => {
    if (
      ridersPremium?.ridersData?.data?.status === "false" &&
      ridersPremium?.ridersData?.data?.message &&
      clicked === "add" &&
      selected?.company_alias == "hdfc_life"
    ) {
      setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
      setLocalTrigger(true);
      setCount(prev => prev - 1);
      for (let i in addOnsList) {
        if (addOnsList[i].rider_name === rider_name) {
          setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
          setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
        }
      }
      setCoverAmount("Select");
      setPrice("");
      setGst(0);
    }
  }, [ridersPremium]);

  useEffect(() => {
    let temp = addOnsList;

    for (let i in temp) {
      if (temp[i].rider_shortname === rider_shortname) {
        temp[i].premium = price;
        temp[i].gst = gst;
        if (selected?.company_alias === "max_life") {
          temp[i].term = riderTerm;
          temp[i].riderPpt = riderTerm;
        }
      }
    }
    if (localTrigger) {
      setTriggerUpdate(true);
      setLocalTrigger(false);
    }
    let sum = 0;
    let Gst = 0;
    for (let i in temp) {
      sum += Number(temp[i].premium);
      Gst += Number(temp[i].gst);
    }

    setAdditionalRiders(sum);
    setAdditionalGst(Gst);
  }, [price, addOnsList, localTrigger]);
  useEffect(() => {
    if (rider_shortname === "WOP" && selected?.company_alias === "max_life") {
      setErrorCover("");
      dispatch(setLoading(true));
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: getNumberfromString(coverAmount),
          riderTerm: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(riderTerm),
          riderShortname: rider_shortname,
        }),
      );
    }
  }, [temp?.premium]);

  useEffect(() => {
    if (
      temp &&
      TPDB &&
      rider_shortname === "TPDB" &&
      selected?.company_alias === "max_life"
    ) {
      const i = addOnsList.findIndex(
        obj => obj.rider_shortname === rider_shortname,
      );
      addOnsList[i].sumAssured = temp?.sumAssured;
    }
  }, [temp?.sumAssured]);
  useEffect(() => {
    if (rider_shortname === "WOP" && selected?.company_alias === "max_life") {
      setErrorCover("");
      dispatch(setLoading(true));
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: getNumberfromString(coverAmount),
          riderTerm: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(riderTerm),
          riderShortname: rider_shortname,
        }),
      );
    }
  }, [temp3?.premium]);

  useEffect(() => {
    if (rider_shortname === "WOP" && selected?.company_alias === "max_life") {
      setErrorCover("");
      dispatch(setLoading(true));
      setClicked("add");
      dispatch(
        RidersPremium({
          addedRiders: addOnsList,
          traceId: enquiryId,
          termInsuId: term_insu_id,
          riderId: riderId,
          riderCoveramt: getNumberfromString(coverAmount),
          riderTerm: getNumberfromString(riderTerm),
          riderPpt: getNumberfromString(riderTerm),
          riderShortname: rider_shortname,
        }),
      );
    }
  }, [TPDB?.premium, temp?.premium]);

  useEffect(() => {
    if (selected?.company_alias === "kotak_life") {
      if (
        addOnsList.some(item => item.rider_shortname === "ADB") &&
        rider_shortname === "WOP"
      ) {
        setErrorMsg(
          "Waiver of Premium add-on is not available when Accidental Death Benifit add-on is selected",
        );
      } else if (
        addOnsList.some(item => item.rider_shortname === "WOP") &&
        rider_shortname === "ADB"
      ) {
        setErrorMsg(
          "Accidental Death Benifit add-on is not available when Waiver of Premium add-on is selected",
        );
      } else setErrorMsg(false);
    }
    if (selected?.company_alias === "bajaj_life") {
      if (
        addOnsList.some(item => item.rider_shortname === "ATPD") &&
        rider_shortname === "CI"
      ) {
        setErrorMsg(
          "Critical Illness add-on is not available when Accidental Total Permanent Benefit add-on is selected",
        );
      } else if (
        addOnsList.some(item => item.rider_shortname === "CI") &&
        rider_shortname === "ATPD"
      ) {
        setErrorMsg(
          "Accidental Total Permanent Benefit add-on is not available when Critical Illness add-on is selected",
        );
      } else setErrorMsg(false);
    }
    if (selected?.company_alias === "hdfc_life") {
      if (
        addOnsList.some(item => item.rider_shortname === "PPR_PAC") &&
        rider_shortname === "PPR_ADC"
      ) {
        setErrorMsg(
          "Accidental Death Cover add-on is not available when Personal Accidental Cover add-on is selected",
        );
      } else if (
        addOnsList.some(item => item.rider_shortname === "PPR_ADC") &&
        rider_shortname === "PPR_PAC"
      ) {
        setErrorMsg(
          "Personal Accidental Cover add-on is not available when Accidental Death Cover add-on is selected",
        );
      } else setErrorMsg(false);
    }
    if (selected?.company_alias === "max_life") {
      if (
        addOnsList.some(
          item =>
            item.rider_shortname.includes("CI") &&
            item.rider_shortname !== rider_shortname,
        ) &&
        rider_shortname.includes("CI")
      ) {
        setErrorMsg(
          "Only one variant of Critical Illness can be selected at once",
        );
      } else if (
        !addOnsList.some(item => item.rider_shortname.includes("CI")) &&
        rider_shortname === "TPDB"
      ) {
        setErrorMsg(
          "This add-on can be availed only with either of Critical illness Gold or Platinum",
        );
      } else setErrorMsg(false);
    }
    if (selected?.company_alias === "bandhan_life") {
      if (
        addOnsList.some(item => item.rider_shortname === "CI-CPC") &&
        rider_shortname.includes("CI") &&
        rider_shortname !== "CI-CPC"
      ) {
        setErrorMsg(
          "Other Critical Illness Addons are not available with Comprehensive Care",
        );
      } else if (
        addOnsList.some(
          item =>
            item.rider_shortname.includes("CI") &&
            item.rider_shortname !== "CI-CPC",
        ) &&
        rider_shortname === "CI-CPC"
      ) {
        return setErrorMsg(
          "Comprehensive care is not available with any other critical illness add-on",
        );
      } else setErrorMsg(false);
    }
  }, [addOnsList, rider_shortname, selected]);

  useEffect(() => {
    if (selected?.company_alias === "bajaj_life") {
      if (spouseVariantFlag && rider_shortname === "CB") {
        setErrorMsg("Child and Spouse Variants cannot be added together");
        setVariantDisabled(true);
      } else if (childVariantFlag && rider_shortname === "SC") {
        setErrorMsg("Child and Spouse Variants cannot be added together");
        setVariantDisabled(true);
      } else {
        setErrorMsg(false);
        setVariantDisabled(false);
      }
    }
  }, [childOrSpouse, rider_shortname, spouseVariantFlag, childVariantFlag]);
  useEffect(() => {
    if (selected?.company_alias === "bandhan_life" && clicked === "remove") {
      if (addOnsList.some(item => item.rider_shortname === rider_shortname)) {
        if (
          !validateSelection(
            coverAmount,
            rider_shortname,
            selected?.company_alias,
            addOnsList,
            discountedPremium(selected?.premium_amount),
            additionalRiders,
          )
        ) {
          setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
          setCount(prev => prev - 1);
          for (let i in addOnsList) {
            if (addOnsList[i].rider_name === rider_name) {
              setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
              setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
            }
          }
        }
      }
    }
    if (selected?.company_alias === "max_life" && clicked === "remove") {
      let x = addOnsList.find(item => item.rider_shortname.includes("CI"));
      if (
        !validateSelection(
          coverAmount,
          rider_shortname,
          selected?.company_alias,
          addOnsList,
          discountedPremium(selected?.premium_amount),
          x && x.premium,
        ) &&
        clicked === "remove"
      ) {
        setAddOns(addOnsList.filter(item => item.rider_name !== rider_name));
        setCount(prev => prev - 1);
        for (let i in addOnsList) {
          if (addOnsList[i].rider_name === rider_name) {
            setAdditionalRiders(prev => prev - Number(addOnsList[i].premium));
            setAdditionalGst(prev => prev - Number(addOnsList[i].gst));
          }
        }
      }
    }
  }, [additionalRiders, clicked]);

  return (
    <>
      <Card
        rider="true"
        //style={{ margin: "9px" }}
        addCard={
          addOnsList.some(item => item.rider_shortname === rider_shortname)
            ? true
            : false
        }
        style={{
          display:
            !showCard(
              selected?.company_alias,
              rider_shortname,
              maturity,
              selected?.differentTenureCU ||
                filter.coverUpto === "Whole Life" ||
                Number(filter.coverUpto.match(/(\d+)/)[0]),
            ) && "none",
        }}
        compulsory={compulsory}
        disabled={shouldBeDisabled(
          addOnsList,
          rider_shortname,
          selected?.company_alias,
          false,
          false,
        )}
      >
        <CardTitle>
          <div className="ct cardTitle">{rider_name}</div>
          {addOnsList.some(
            item => item.rider_shortname === rider_shortname,
          ) && <img src={addTick} alt="addTick" className="addTick" />}
        </CardTitle>
        <div
          className="pa para"
          ref={desc}
          dangerouslySetInnerHTML={{
            __html: DOMpurify.sanitize(`<div>${rider_description}</div>`),
          }}
        ></div>
        {selected?.company_alias === "max_life" &&
          rider_shortname === "WOP" && (
            <div className="applicable-till-age">
              Applicable till age: {age + parseInt(riderTerm)} years
            </div>
          )}
        {diabetic === "Y" ? (
          <>
            <div className="diabetic">
              <div>Are you a Diabetic</div>
              <div style={{ marginRight: "80px" }}>
                <span className="diabOptions">NO</span>
                <ToggleButton
                  checked={checkedDiabetic}
                  elgToggle={true}
                  onChange={value => {
                    setCheckedDiabetic(value);
                    setLocalTrigger(value);
                    setCheckDiabetic(value);
                  }}
                />
                <span className="diabOptions">YES</span>
              </div>
            </div>
            {checkedDiabetic && !errorMsg && (
              <h3 className="diabeticErr">
                <strong>You are not eligible to buy this Add-on</strong>
              </h3>
            )}
          </>
        ) : (
          <>
            {selected?.company_alias === "max_life" ? (
              <div className="diabetic">
                <div>Are you a Diabetic</div>
                <div style={{ marginRight: "80px" }}>
                  <span className="diabOptions">NO</span>
                  <ToggleButton
                    checked={checkedDiabetic}
                    elgToggle={true}
                    onChange={value => {
                      setCheckedDiabetic(value);
                      setLocalTrigger(value);
                      setCheckDiabetic(value);
                    }}
                  />
                  <span className="diabOptions">YES</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        <div
          className={`row rw riderMobile addOn__dropdown dropDownTab ${
            !showPPT(selected, rider_shortname) && "only-cover"
          }`}
          style={{
            opacity:
              shouldBeDisabled(
                addOnsList,
                rider_shortname,
                selected?.company_alias,
                checkedDiabetic,
                false,
              ) && 0.7,
            pointerEvents:
              shouldBeDisabled(
                addOnsList,
                rider_shortname,
                selected?.company_alias,
                checkedDiabetic,
                false,
              ) && "none",
          }}
        >
          <FormGroup
            lg
            mob
            className="mcol mcol-lg"
            style={{
              display:
                shouldDisplay(selected, rider_shortname, errorMsg) && "none",
            }}
          >
            {selected?.company_alias === "bandhan_life" ? (
              <></>
            ) : (
              <div
                className={`dropdownRider upgradeDropList__rider full-width ${
                  errorCover && "red__border"
                } `}
              >
                <div className=" upgradeDropLabel">
                  Cover amount with {riderTerm} Rider term
                </div>

                <div
                  className="filterMenuSelectWrap selectIncome"
                  style={{
                    pointerEvents:
                      ((selected.company_alias === "max_life" &&
                        rider_shortname === "TPDB") ||
                        (selected.company_alias === "hdfc_life" &&
                          rider_shortname === "ADB")) &&
                      "none",
                  }}
                >
                  <CustomDropDownFont rdr>
                    <SmallCustomDropDown
                      items={COVER_AMOUNT.reverse()}
                      selectedItem={coverAmount}
                      currentSelected={coverAmount}
                      searchable
                      hideArrow={
                        (selected.company_alias === "max_life" &&
                          rider_shortname === "TPDB") ||
                        (selected.company_alias === "hdfc_life" &&
                          rider_shortname === "ADB")
                      }
                      onChange={e => {
                        if (selected?.master_product_name === "eTouch") {
                          const value = coverAmountArray?.filter(
                            item => numberToDigitWord(item?.display_name) === e,
                          );
                          setCoverAmount(
                            numberToDigitWord(value?.[0]?.display_name),
                          );
                        } else {
                          setCoverAmount(e);
                        }
                        if (e !== "Select" && !e.includes("select")) {
                          fetchPremium(e, "cvrAmt");
                        }
                      }}
                    />
                  </CustomDropDownFont>
                </div>
              </div>
            )}
          </FormGroup>

          {showPPT(selected, rider_shortname, errorMsg) && (
            <>
              <FormGroup
                md
                mob
                className="mcol"
                style={{
                  display: shouldDisplay(selected, rider_shortname) && "none",
                }}
              >
                <div className=" dropdownRider upgradeDropList__rider">
                  <div className="upgradeDropLabel">Rider term</div>
                  <div className="filterMenuSelectWrap selectIncome">
                    <CustomDropDownFont rdr>
                      <SmallCustomDropDown
                        items={RIDER_TERM}
                        selectedItem={riderTerm}
                        currentSelected={riderTerm}
                        searchable
                        onChange={e => {
                          setriderTerm(e);
                          if (
                            coverAmount !== "Select" &&
                            !coverAmount?.includes("select")
                          ) {
                            fetchPremium(e, "riderTerm");
                          }
                        }}
                        style={{
                          pointerEvents:
                            selected?.company_alias !== "hdfc_life" &&
                            selected?.company_alias !== "bajaj_life" &&
                            "none",
                        }}
                      />
                    </CustomDropDownFont>
                  </div>
                </div>
              </FormGroup>
              <FormGroup md mob>
                <div className=" dropdownRider upgradeDropList__rider">
                  <div className="upgradeDropLabel">Premium paying term</div>
                  <div className="filterMenuSelectWrap selectIncome">
                    <CustomDropDownFont rdr>
                      <SmallCustomDropDown
                        items={PPTList}
                        selectedItem={PPTerm}
                        currentSelected={PPTerm}
                        style={{
                          pointerEvents:
                            filter?.paymentTerm?.option === "Single Pay" &&
                            "none",
                        }}
                        searchable
                        onChange={e => {
                          setPPTerm(e);
                          if (
                            coverAmount !== "Select" &&
                            !coverAmount?.includes("select")
                          ) {
                            fetchPremium(e, "ppTerm");
                          }
                        }}
                      />
                    </CustomDropDownFont>
                  </div>
                </div>
              </FormGroup>
            </>
          )}
        </div>
        {errorMsg && (
          <span className="rider__error__wrapper">
            <p className="rider__error">{errorMsg}</p>
          </span>
        )}

        {(rider_shortname === "SC" || rider_shortname === "CB") &&
        selected?.company_alias === "bajaj_life" ? (
          <div className="addOn__button">
            <button
              onClick={onClick}
              disabled={variantDisabled}
              className={childOrSpouse ? "upgradeAdded" : "btn-add-plan"}
              style={{
                opacity: variantDisabled && 0.7,
              }}
            >
              {childOrSpouse ? `REMOVE FROM PLAN ` : `ADD TO PLAN `}
            </button>
          </div>
        ) : (
          <>
            <div className="addOn__button">
              <button
                onClick={onClick}
                disabled={
                  shouldBeDisabled(
                    addOnsList,
                    rider_shortname,
                    selected?.company_alias,
                    checkedDiabetic,
                    compulsory,
                  ) || premiumLoader[riderId]
                }
                className={
                  addOnsList.some(
                    item => item.rider_shortname === rider_shortname,
                  )
                    ? "upgradeAdded"
                    : checkedDiabetic
                    ? "btn-add-plan"
                    : "btn-add-plan"
                }
                style={{
                  backgroundColor:
                    shouldBeDisabled(
                      addOnsList,
                      rider_shortname,
                      selected?.company_alias,
                      checkedDiabetic,
                    ) ||
                    (premiumLoader[riderId] && "#107591"),
                }}
              >
                {premiumLoader[riderId] ? (
                  <Loader
                    style={{
                      position: "absolute",
                      // color: "#fff !important",
                      background: "transparent ",
                      bottom: "9 !important",
                      left: "48% !important",
                      borderRadius: "5px",
                      border: "5px",
                      // width: "220px !important",
                      height: "55px",
                      marginBottom: "1.5rem",
                    }}
                    bounceStyle={{ backgroundColor: "#fff" }}
                  />
                ) : addOnsList.some(
                    item => item.rider_shortname === rider_shortname,
                  ) ? (
                  `${compulsory ? costPer : "REMOVE FROM PLAN" + costPer}`
                ) : (
                  `ADD TO PLAN  ${costPer}`
                )}
              </button>
            </div>
          </>
        )}
      </Card>
      {ridersPremium?.ridersData?.data?.status === "false" &&
        ridersPremium?.ridersData?.data?.message &&
        clicked === "add" &&
        rider_shortname == ridersPremium?.ridersData?.data?.riderShortname &&
        selected?.company_alias == "hdfc_life" && (
          <p
            style={{
              color: "red",
              fontSize: "14px",
              marginLeft: "12px",
              paddingRight: "10px",
            }}
          >
            {ridersPremium?.ridersData?.data?.message}
          </p>
        )}
      {rider_shortname == "C4CI" &&
        selected?.medicalCondition === "Video Medical" &&
        selected?.company_alias === "hdfc_life" &&
        addOnsList.some(item => item.rider_shortname === "C4CI") &&
        ((userAge >= 18 && userAge <= 30 && CIRiderVal >= 5100000) ||
          (userAge >= 31 && CIRiderVal >= 2600000)) && (
          <>
            <div className="medical__rider">
              <i className="fas fa-exclamation-triangle"></i> For Selected Sum
              Insured, you need to go for Physical Medicals.
            </div>
          </>
        )}
      {rider_shortname === "SC" && (
        <SpousePopup
          show={showSpouse}
          additionalRiders={additionalRiders}
          onClose={setShowSpouse}
          selected={selected}
          riderTerm={getNumberfromString(riderTerm)}
          riderId={riderId}
          onClick={onClick}
          setPrice={setPrice}
          setGst={setGst}
          setCoverAmount={setCoverAmount}
          costPer={costPer}
          termInsuId={term_insu_id}
          riderShortname={rider_shortname}
          added={addOnsList.some(
            item => item.rider_shortname === rider_shortname,
          )}
          cover={coverAmount}
          setChildOrSpouse={setChildOrSpouse}
        />
      )}

      {rider_shortname === "CB" && (
        <ChildDetails
          show={showChild}
          additionalRiders={additionalRiders}
          onClose={setShowChild}
          selected={selected}
          riderTerm={getNumberfromString(riderTerm)}
          riderId={riderId}
          onClick={onClick}
          setPrice={setPrice}
          setGst={setGst}
          setCoverAmount={setCoverAmount}
          costPer={costPer}
          termInsuId={term_insu_id}
          riderShortname={rider_shortname}
          added={addOnsList.some(
            item => item.rider_shortname === rider_shortname,
          )}
          cover={coverAmount}
          setChildOrSpouse={setChildOrSpouse}
        />
      )}
    </>
  );
};

export default AddonCard;
