import { createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import service from "./otp-service";



export const otpSlice = createSlice({
    name: "otp-verification",
    initialState: {
        verified:false,
        company_alias:"",
      },
      reducers: {
          verified:(state,action)=>{
            state.verified=true;
          },
          notVerified:(state)=>{
            state.verified=false;
          },
          setCompany:(state,action)=>{
            state.company_alias = action.payload.company_alias || "";
          }
        
      }

});

export const {
  verified,notVerified,setCompany
  } = otpSlice.actions;


export const generateOTP = (payload) => {
    return async (dispatch) => {
      try {
         const data = await service.generateOTP(payload);
        console.log(data.data);
         if(data.data && data.data.status===false){

          swal(data.data.message);
        }
      } catch (err) {
        console.error("Error", err);
      }
    };
  };
  
  // verify otp
  export const verfiyOTP = (payload) => {
    return async (dispatch) => {
      try {
        const data = await service.verfiyOTP(payload);
        console.log(data.data);
        if(data.data && data.data.status === false){
          if(data.data.errors==="Invalid OTP"){
            swal("Please enter valid OTP");
          }
          else{
          swal(data.data.errors);
        }
          return false;
        }
        return true;
      } catch (err) {
     
        console.error("Error", err);
      }
    };
  };
  
  // resend otp
  export const resendOTP = (payload) => {
    return async (dispatch) => {
      try {
        service.resendOTP(payload);
      } catch (err) {
        console.error("Error", err);
      }
    };
  };

  export default otpSlice.reducer;