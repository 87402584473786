import { getTobaccoBajajRev } from "../../../../utils/bajajCodeUtils";

const weightGainReasons = {
  Male: ["-Select-", "Diet", "Exercise", "Others"],
  Female: ["-Select-", "Diet", "Exercise", "Pregnant", "Others"],
};

export const medicalDetails1FormData = data => [
  {
    id: "height",
    label: "Height (cms)",
    value: data?.height || "",
    size: "md",
    validations: {
      required: "Please fill height in cms",
      numOnly: true,
      maxLength: 3,
      custom: [
        {
          min: 1,
          message: "Please enter a valid height in cms",
        },
      ],
    },
    alignLeft: true,
  },
  {
    id: "weight",
    label: "Weight (kgs)",
    value: data?.weight || "",
    size: "md",
    validations: {
      required: "Please fill weight in kgs",
      numOnly: true,
      maxLength: 3,
      custom: [
        {
          min: 1,
          message: "Please enter a valid weight in kgs",
        },
      ],
    },
    alignLeft: true,
  },
  {
    id: "weightChanged",
    label: "In the past six months has your body weight changed?",
    size: "xlg",
    value: data?.changedWeight || "Select",
    type: "select",
    items: ["SAME", "GAINED", "LOST"],
    alignLeft: true,
    extraMarginTop: true,
    validations: {
      required: "This field is required",
    },
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "GAINED":
          return [
            {
              id: "gained1",
              label: "Change in Weight",
              size: "md",
              value: data?.changeInWeight || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Please fill change in Weight ",
                numOnly: true,
                maxLength: 3,
              },
            },
            {
              id: "gained2",
              label: "Cause Of Change in Weight",
              size: "md",
              value: data?.causeChangeInWeight || "",
              type: "select",

              searchable: true,
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
                numOnly: true,
                maxLength: 3,
              },
              items: weightGainReasons[data?.gender] || [],
            },
          ];
        case "LOST":
          return [
            {
              id: "gained1",
              label: "Change in Weight",
              size: "md",
              value: data?.changeInWeight || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Please fill change in Weight ",
                numOnly: true,
                maxLength: 3,
              },
            },
            {
              id: "gained2",
              label: "Cause Of Change in Weight",
              size: "md",
              value: data?.causeChangeInWeight || "",
              type: "select",
              searchable: true,
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
              },
              items: weightGainReasons[data?.gender] || [],
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "consuming",
    label: "Have you ever consumed tobacco in any form during last 5 years?",
    items: ["Yes", "No"],
    size: "xlg",
    type: "toggle",
    value: data.tobaccoHabit,
    nextLine: true,
    extraMargin: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "usedAsProduct",
              label: "Used As(Name of the tobacco product)",
              size: "md",
              value: data.tobaccoProduct
                ? getTobaccoBajajRev(data.tobaccoProduct)
                : "",
              type: "select",
              items: [
                "-Select-",
                "Cigar/ Cigarette/ Bidi sticks",
                "Gutka/ Tobacco pouch",
                "Others",
              ],
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
              },
            },
            {
              id: "quantityT",
              label: "Quantity Per Day",
              size: "md",
              value: data?.tobaccoQuantity || "",
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Quantity Per Day is required",
                numOnly: true,
                maxLength: 2,
              },
            },
            {
              id: "ifQuit",
              label: "IF Quit Since When(mm/yyyy)",
              size: "md",
              value: data?.tobaccoQuitSince || "",
              type: "dob2",
              alignLeft: true,
              validations: {
                required: "Quit Date is required ",
                invalid: "Please Enter Valid Date",
                shouldBePast: "Please Enter Valid Date",
              },
            },
          ];
        default:
          return [];
      }
    },
  },

  {
    id: "alcohol",
    label: "Do you regularly consume alcohol?",
    items: ["Yes", "No"],
    value: data?.alcoholHabit === "Y" ? "Yes" : "No",
    size: "xlg",
    smallToggle: true,
    type: "toggle",
    nextLine: true,
    extraMargin: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "frequency",
              label: "Frequency of consumption per week",
              size: "md",
              value: data?.alcoholQuantity || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Please fill Frequency",
                numOnly: true,
                maxLength: 15,
              },
            },
            {
              id: "quantity",
              label: "Quantity of consumption per week",
              size: "md",
              value: data?.alcoholConsumption || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                maxLength: 5,
                required: "Please fill Quantity",
                numOnly: true,
              },
            },
            {
              id: "increased",
              label: "Has the consumption increased in last 6 months",
              size: "md",
              value: data?.alcoholIncrease || "",
              type: "select",
              searchable: true,
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
              },
              items: ["-Select-", "EQUALLY", "LESS"],
            },
          ];
        default:
          return [];
      }
    },
  },
];

export const medicalDetails1FormData2 = data => [
  {
    id: "height",
    label: "Height (cms)",
    value: data?.height || "",
    size: "md",
    validations: {
      required: "Please fill height in cms",
      numOnly: true,
      maxLength: 3,
    },
    alignLeft: true,
  },
  {
    id: "weight",
    label: "Weight (kgs)",
    value: data?.weight || "",
    size: "md",
    validations: {
      required: "Please fill weight in kgs",
      numOnly: true,
      maxLength: 3,
    },
    alignLeft: true,
  },
  {
    id: "weightChanged",
    label: "In the past six months has your body weight changed?",
    size: "xlg",
    value: data?.changedWeight || "Select",
    type: "select",
    items: ["SAME", "GAINED", "LOST"],
    alignLeft: true,
    extraMarginTop: true,
    validations: {
      required: "This Field is required",
    },
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "GAINED":
          return [
            {
              id: "gained1",
              label: "Change in Weight",
              size: "md",
              value: data?.changeInWeight || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Please fill Change in Weight ",
                numOnly: true,
                maxLength: 3,
              },
            },
            {
              id: "gained2",
              label: "Cause Of Change in Weight",
              size: "md",
              value: data?.causeChangeInWeight || "",
              type: "select",

              searchable: true,
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
                numOnly: true,
                maxLength: 3,
              },
              items: ["-Select-", "Diet", "Exercise", "Pregnant", "Others"],
            },
          ];
        case "LOST":
          return [
            {
              id: "gained1",
              label: "Change in Weight",
              size: "md",
              value: data?.changeInWeight || "",
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Please fill Change in Weight ",
                numOnly: true,
                maxLength: 3,
              },
            },
            {
              id: "gained2",
              label: "Cause Of Change in Weight",
              size: "md",
              value: data?.causeChangeInWeight || "",
              type: "select",
              searchable: true,
              extraMargin: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
              },
              items: ["-Select-", "Diet", "Exercise", "Pregnant", "Others"],
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "consuming",
    label: "Have you ever consumed tobacco in any form during last 5 years?",
    items: ["Yes", "No"],
    size: "xlg",
    type: "toggle",
    value: data.tobaccoHabit,
    nextLine: true,
    readOnly: true,
    alignLeft: true,
    extraMarginTop: true,
    extraMarginBottom: true,
  },
  {
    id: "usedAsProduct",
    label: "Used As(Name of the tobacco product)",
    size: "md",
    value: data.tobaccoProduct ? getTobaccoBajajRev(data.tobaccoProduct) : "",
    type: "select",
    items: [
      "-Select-",
      "Cigar/ Cigarette/ Bidi sticks",
      "Gutka/ Tobacco pouch",
      "Others",
    ],
    alignLeft: true,
    extraMarginTop: true,
    validations: {
      required: "This Field is required",
    },
  },
  {
    id: "quantityT",
    label: "Quantity Per Day",
    size: "md",
    value: data?.tobaccoQuantity || "",
    alignLeft: true,
    extraMarginTop: true,
    validations: {
      maxLength: 2,
      required: "Quantity Per Day is required",
      numOnly: true,
    },
  },
  {
    id: "ifQuit",
    label: "IF Quit Since When(mm/yyyy)",
    size: "md",
    value: data?.tobaccoQuitSince || "",
    type: "dob2",
    alignLeft: true,
    validations: {
      invalid: "Please Enter Valid Date",
      shouldBePast: "Please Enter Valid Date",
    },
  },

  {
    id: "alcohol",
    label: "Do you regularly consume alcohol?",
    items: ["Yes", "No"],
    value: data?.alcoholHabit === "Y" ? "Yes" : "No",
    size: "xlg",
    smallToggle: true,
    type: "toggle",
    nextLine: true,
    extraMargin: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "frequency",
              label: "Frequency of consumption per week",
              size: "md",
              value: data?.alcoholQuantity || "",
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Frequency of consumption is required",
                numOnly: true,
                maxLength: 15,
              },
            },
            {
              id: "quantity",
              label: "Quantity of consumption per week",
              size: "md",
              value: data?.alcoholConsumption || "",
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                maxLength: 5,
                required: "Quantity of consumption is required",
                numOnly: true,
              },
            },
            {
              id: "increased",
              label: "Has the consumption increased in last 6 months",
              size: "md",
              value: data?.alcoholIncrease || "",
              type: "select",
              searchable: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is required",
              },
              items: ["-Select-", "EQUALLY", "LESS"],
            },
          ];
        default:
          return [];
      }
    },
  },
];
