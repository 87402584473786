import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DocumentUpload from "./documentUpload";
import DocumentUploadKotak from "./DocumentUploadKotak/DocumentUploadKotak";
import DocumentUploadMax from "./documentUploadMax";
import DocumentUploadBajaj from "./documentUploadBajaj";
import { useNavigation } from "../../custom-hooks/useNavigation";
import Loader from "../../components/Loader/Loader";
const getDocumentUploadPage = companyAlias => {
  switch (companyAlias) {
    case "kotak_life":
      return <DocumentUploadKotak />;
    case "max_life":
      return <DocumentUploadMax />;
    case "bajaj_life":
      return <DocumentUploadBajaj />;

    default:
      return <DocumentUpload />;
  }
};
const DocumentUploadPage = () => {
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);

  return getDocumentUploadPage(company_alias);
};
export default props => {
  const { isDocumentUploaded } = useNavigation();
  if (isDocumentUploaded) return <Loader />;
  return <DocumentUploadPage {...props} />;
};
