/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LaxmiImage from "../../assets/img/laxmiImg.png";
import { useDispatch, useSelector } from "react-redux";
import { useCustomLocation, useWindowResize } from "../../custom-hooks";
import "./BajajDiscountPopup.css";
// import fireworks from "../../assets/img/fireworks.json";

import {
  setSalariedKotak,
  resetKotakDiscountData,
} from "./kotakDiscount.slice.js";
import { updateQuotation } from "../../modules/EligibilityPage/serviceApi";
import { getAge } from "../../utils/inputUtils";
import { useHistory } from "react-router";
import Loader from "../Loader/Loader";
// import Lottie from "react-lottie-player";
import { useRef } from "react";

const KotakDiscountPopup = ({ show, onClose, quote }) => {
  const { isSalaried, backfromRiders } = useSelector(
    state => state.kotakDiscount,
  );
  const {
    filter: { coverUpto },
  } = useSelector(state => state.quoteFilter);

  const salariedRefKotak = useRef();
  const modalContentRef = useRef();
  const { customerDetails } = useSelector(state => state.greeting);
  const { payPremium } = useSelector(state => state.quoteFilter.subFilter);
  const age = getAge(customerDetails.dob);
  // const [isBajajData, setIsBajajData] = useState(false);
  const [apiStatus, setApiStatus] = useState("");
  const { selectedQuote } = useSelector(state => state.quote);

  console.log({ quote });

  const dispatch = useDispatch();
  const { enquiryId } = useCustomLocation();
  const history = useHistory();

  const { width } = useWindowResize();
  const handleClose = () => {
    console.log("handleClose");
    onClose(false);
    dispatch(resetKotakDiscountData());
    salariedRefKotak.current.style.display = "flex";
  };
  console.log("SAHIL", selectedQuote);
  let premium = 0;
  const isSalaried1 = isSalaried === "Y";
  if (isSalaried1) {
    premium = selectedQuote?.premium_amount || quote?.premium_amount || 0;
  } else {
    premium =
      selectedQuote?.self_employed_premium || quote?.self_employed_premium || 0;
  }
  useEffect(() => {
    if (backfromRiders) {
      dispatch(setSalariedKotak("Y"));
    }
  }, [backfromRiders]);

  return (
    <>
      <div
        className="modal fade show bajajLifeModal"
        isSalaried
        id="maxLifeModal"
        role="dialog"
        style={{
          display: show && "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="modal-dialog">
          <div
            className="modal-content expertPopupWrap"
            ref={modalContentRef}
            style={{
              width: width > 768 ? "85%" : "100%",
              borderRadius: "8px",
              marginLeft: width > 768 ? "5rem" : "0px",
            }}
          >
            <div
              className="expertTopWrap"
              style={{
                position: "absolute",
                top: "8px",
                zIndex: "999",
              }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                  fill="#333"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>

              {/* <div
                className="back "
                style={{
                  cursor: "pointer",
                  display: "flex",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  handleClose();

                  // handleBack();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div> */}
            </div>
            <div
              // className="modal-body"
              style={{
                marginTop: "1.5rem",
              }}
              className="modal-body containerbajaj"
            >
              <div className="components-containerbajaj">
                <div className={`offerBuyNowWrapper`}>
                  <div className="salariedWrapper" ref={salariedRefKotak}>
                    <img
                      className="offerBuyNowWrapperLaxmi"
                      src={LaxmiImage}
                      alt="expert"
                    />
                    <p className="offerBuyNowTitle">Are You Salaried?</p>
                    <div className="offerBuyNowBtnWrapper">
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="yes1"
                            name="isSalaried"
                            disabled={apiStatus === "Pending"}
                            checked={isSalaried === "Y"}
                            // checked={true}
                            onClick={() => {
                              dispatch(setSalariedKotak("Y"));
                            }}
                          />
                          <label for="yes1">Yes</label>
                        </div>
                        <p className="offerBuyNowBtnValue">
                          ₹{" "}
                          {Number(quote?.premium_amount).toLocaleString(
                            "en-IN",
                          ) ||
                            Number(
                              selectedQuote?.premium_amount,
                            ).toLocaleString("en-IN")}{" "}
                          {payPremium}
                        </p>
                      </div>
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="no1"
                            disabled={apiStatus === "Pending"}
                            checked={isSalaried === "N"}
                            name="isSalaried"
                            onClick={() => {
                              dispatch(setSalariedKotak("N"));
                            }}
                          />
                          <label for="no1">No</label>
                        </div>
                        <p className="offerBuyNowBtnValue">
                          ₹{" "}
                          {Number(quote?.self_employed_premium).toLocaleString(
                            "en-IN",
                          ) ||
                            Number(
                              selectedQuote?.self_employed_premium,
                            ).toLocaleString("en-IN")}{" "}
                          {payPremium}
                        </p>

                        {console.log("SAHIL", isSalaried, isSalaried === "Y")}
                      </div>
                    </div>
                    {isSalaried === "N" ? (
                      <p style={{ color: "rgb(16, 117, 145)" }}>
                        {" "}
                        As you are non-salaried the premium is revised{" "}
                      </p>
                    ) : (
                      ""
                    )}
                    <button
                      type="submit"
                      className="cardBuyBtn"
                      onClick={() => {
                        setApiStatus("Pending");
                        updateQuotation({
                          annualPremium: premium,
                          childProductName: quote.child_product_name,
                          cover_amount: quote.cover_amount,
                          integrationType: quote.integration_type,
                          masterProductName: quote.master_product_name,
                          medicalCondition: quote.medicalCondition,
                          paytypeOption:
                            quote.planTenure === "same" &&
                            coverUpto === "Whole Life"
                              ? "WL"
                              : "N",
                          planTenure: quote.planTenure,
                          policyTerm: `${parseInt(coverUpto) - age}`,
                          premiumAmount: premium,
                          sumAssured: quote.sumAssured,
                          termInsuId: quote.insurance_code,
                          totalPremiumGst: quote.premium_amount_gst,
                          isKotakLifeDiscount: isSalaried,
                          // isMaxLifeDiscount: salaried,
                          companyName: quote.company_name,
                          companyAlias: quote.company_alias,
                          requestType: "select_product",
                          traceId: enquiryId,
                        }).then(() => {
                          setApiStatus("Success");
                          history.push({
                            pathname: "/riderPage",
                            search: `enquiryId=${enquiryId}`,
                          });
                        });
                      }}
                    >
                      {apiStatus === "Pending" ? (
                        <Loader
                          style={{
                            position: "absolute",
                            // color: "#fff !important",
                            background: "transparent ",
                            bottom: "9 !important",
                            left: "48% !important",
                            borderRadius: "5px",
                            border: "5px",
                            width: "220px !important",
                            height: "65px",
                            marginBottom: "1.5rem",
                          }}
                          bounceStyle={{ backgroundColor: "#fff" }}
                        />
                      ) : (
                        "CONTINUE"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KotakDiscountPopup;
