import { createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { medicalCenterSubmit } from "./serviceApi";

const medicalFormCenterSlice = createSlice({
  name: "medicalFormCenter",
  initialState: {
    medicalFormDetails: {},
    medicalResponse: false,
    loading: false,
    isMedicalCompleted: false,
  },
  reducers: {
    setMedicalFormDetails: (state, { payload }) => {
      state.medicalFormDetails = payload;
    },
    saveResponse: (state, { payload }) => {
      state.medicalResponse = payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
    setIsMedicalCompleted: (state, action) => {
      state.isMedicalCompleted = action.payload;
    },
  },
});

export const {
  setMedicalFormDetails,
  saveResponse,
  initLoader,
  setIsMedicalCompleted,
} = medicalFormCenterSlice.actions;

export const medicalCenterApi = (data, history, search) => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(setMedicalFormDetails(data));
        await medicalCenterSubmit(data).then(res => {
          dispatch(saveResponse(res));
          if (res.data.status) {
            dispatch(initLoader(false));
            // dispatch(saveResponse(res.data));
            swal(res.data.message, {
              icon: "success",
            }).then(() => {
              dispatch(setIsMedicalCompleted(true));
              history.push(
                `/documentuploadpage?enquiryId=${search.slice(11, 29)}`,
              );
            });
          } else {
            dispatch(initLoader(false));
            swal(res.data.message, {
              icon: "error",
            });
          }
        });
      }
    } catch (error) {
      console.error("Error MediApi", error);
    }
  };
};

export default medicalFormCenterSlice.reducer;

//for commit purpose
