import httpClient from "../../api/httpClient";

export const updateFilterOptions = data =>
  httpClient("common/update_quotation", { method: "POST", data });

export const singleProduct = data =>
  httpClient("common/single_product", { method: "POST", data });

export const getToken = data =>
  httpClient("common/get_trace_id", { method: "POST", data });
