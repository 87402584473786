import React, { Fragment, useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
// import { useOutsideClick, useKeydownEvent } from "../../custom-hooks";

export const useDisableBodyScroll = open => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);
};

const Popup = ({
  transparentBackground,
  show,
  onClose,
  content,
  height,
  reviewPopUp,
  width,
  size,
  msize,
  shouldClose,
  float,
  topFixed,
  marginTop,
  lrgClose,
  mdClose,
  paddingLeft,
  sendQuotes,
  customClass,
  customClassPopupC,
  closeButtonStyle,
  closeBtnClass,
  setCheckNRI,
  checkNRI,
  elgCri,
  paddingMob,
  overflowAuto = false,
}) => {
  const [position, setPosition] = useState("nottop");
  const popupRef = useRef(null);

  const handleCloseButtonClick = () => {
    setPosition("top");
    setTimeout(() => {
      onClose && onClose(false);
      checkNRI && setCheckNRI(false);

      setPosition("nottop");
    }, 500);
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "relative";
      document.body.style.width = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "relative";
      document.body.style.width = "unset";
    };
  }, [show]);
  //useOutsideClick(shouldClose || popupRef, handleCloseButtonClick);

  //useKeydownEvent("Escape", handleCloseButtonClick);

  return (
    <Fragment>
      {show && (
        <PopupC
          className={customClassPopupC}
          sendQuotes={sendQuotes === "true" ? true : undefined}
          visible={show}
          paddingLeft={paddingLeft}
          transparentBackground={
            transparentBackground === "false"
              ? transparentBackground
              : undefined
          }
        >
          <Content
            elgCri={elgCri === "true" ? true : undefined}
            sendQuotes={sendQuotes === "true" ? true : undefined}
            className={customClass}
            style={{
              height: height,
              width: width,
              overflow: overflowAuto && "auto",
            }}
            float={float}
            position={position}
            maxwidth={width}
            size={size ? size : undefined}
            ref={popupRef}
            topFixed={topFixed ? topFixed : undefined}
            msize={msize ? msize : undefined}
            marginTop={marginTop ? marginTop : undefined}
          >
            <CloseButton
              sendQuotes={sendQuotes === "true" ? true : undefined}
              style={closeButtonStyle && closeButtonStyle}
              lrgClose={lrgClose === "true" ? lrgClose : undefined}
              mdClose={mdClose === "true" ? mdClose : undefined}
              onClick={handleCloseButtonClick}
              className={closeBtnClass}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "relative",
                  top: reviewPopUp ? "1px" : "-3",
                  left: reviewPopUp ? "-7px" : "1px",
                }}
                //    position: relative;   top: 2px; left: -9px;
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                fill="#333"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
              {/* &times; */}
            </CloseButton>
            {content}
          </Content>
        </PopupC>
      )}
    </Fragment>
  );
};

export default Popup;

const moveDown = keyframes`
from{
  opacity:0;
  top:0;
}

to {
    top:${props => (props.size ? "48px" : "35%")};
    opacity:1;
}
`;

const PopupC = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: ${props => props.paddingLeft || "0"};

  @media screen and (min-width: 768px) and (max-width: 1177px) {
    padding-left: ${props => props.paddingMob || "0"};
  }

  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  opacity: ${props => (props.visible === true ? "1" : "0")};
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  transition: all 0.3s, width 0s;
  @media (max-width: 767px) {
    min-height: ${props => (props.sendQuotes ? "100%" : "unset")};
    max-height: 100%;
    top: unset;
    bottom: 0;
    height: 100%;
    overflow-y: unset;
    overflow: scroll;
    background-color: ${props => {
      if (props.transparentBackground || props.sendQuotes) {
        return "#fff";
      }
    }};
  }
`;

const Content = styled.div`
  & .drop-down-items__item {
    & {
      color: #333333;
    }
    &,
    &--selected {
      font-family: "Inter-Regular";
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      padding: 10px;
    }
  }

  position: relative;
  animation: ${moveDown} 0.6s;
  top: ${props =>
    props.position === "top" ? "0" : props.size ? "48px" : "35%"};
  top: ${props => {
    if (props.position === "top") return "0";
    if (props.topFixed) {
      return props.topFixed;
    }
    if (props.msize) {
      return "50%";
    }
  }};
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  left: 50%;
  opacity: ${props => (props.position === "top" ? "0" : "1")};
  transform: translate(-50%, ${props => (props.size ? "0" : "-40%")});
  transform: ${props => {
    if (props.msize) {
      return "translate(-50%,-50%)";
    }
  }};
  margin-top: ${props => (props.marginTop ? props.marginTop : "")};
  background-color: #fff;
  transition: all 0.5s, width 0s;
  border-radius: 6px;
  margin-bottom: 96px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  ${
    "" /* @media (max-width: ${(props) => props.maxwidth}) {
    width: 100% !important;
    height: auto !important;
  } */
  }
  @media (max-width: 767px) {
    transform: translate(
      -50%,
      ${props =>
        props.elgCri
          ? "-35%"
          : props.sendQuotes
          ? "0"
          : props.size
          ? "0"
          : "-40%"}
    );

    position: ${props => (props.float ? "fixed" : "absolute")};
    border: none;
    box-shadow: none;
    margin-bottom: 0px;
    bottom: 0;
    top: ${props =>
      props.sendQuotes
        ? "-55px"
        : props.position === "top"
        ? "0"
        : props.size
        ? props.float
          ? "auto"
          : "0%"
        : "35%"};
  }
`;

const CloseButton = styled.a`
  float: right;
  font-size: 32px;
  margin-right: 9px;
  margin-top: -3px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  z-index: 99;
  position: relative;
  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
    color: #666;
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    position: relative;
    top: ${props => (props.sendQuotes ? "52px !important" : " 12px")};
    left: ${props => (props.sendQuotes ? "0px !important" : " -6px")};
    & svg {
      top: unset !important;
      left: unset !important;
      width: ${props => props.elgCri && "26px !important"};
      height: ${props => props.elgCri && "26px !important"};
    }
    font-size: ${props => {
      if (props.lrgClose) {
        return "55px";
      } else if (props.mdClose) {
        return "28px";
      } else {
        return "22px";
      }
    }};
    margin-right: ${props => {
      if (props.lrgClose) {
        return "5px";
      } else {
        return "11px";
      }
    }};
    margin-top: ${props => {
      if (props.lrgClose) {
        return "-17px";
      } else {
        return "2px";
      }
    }};
    cursor: pointer;
  }
`;

// position: relative;
// top: 12px !important;
// left: -6px !important;
