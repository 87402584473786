import React, { Component } from "react";
import Button from "../Button/Button";
import "./errorBoundary.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
    console.error(error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <section className="container">
          <div className="loading-quotes">
            <p>Something went wrong</p>
            <p>
              Sorry for inconvenience, check console for more information about
              the error.
            </p>
            <Button
              className="downloadBroucher retry"
              onClick={() => window.location.href = "https://elephant.in/"}
            >
              <span> Go to Home page</span>
            </Button>
          </div>
        </section>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
