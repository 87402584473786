/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LaxmiImage from "../../assets/img/laxmiImg.png";
import { useDispatch, useSelector } from "react-redux";
import { useCustomLocation, useWindowResize } from "../../custom-hooks";
import "./BajajDiscountPopup.css";
import fireworks from "./../../assets/img/fireworks.json";

import {
  resetBajajDiscountData,
  setExistingPolicy,
  setExistingPolicyBajaj,
  setNewBajajDiscount,
  setSalaried,
} from "./bajajDiscount.slice";
import {
  sendEligibilityDetails,
  updateQuotation,
} from "../../modules/EligibilityPage/serviceApi";
import { getQuotesBajaj } from "../../modules/RiderPage/serviceApi";
import { getAge } from "../../utils/inputUtils";
import { useHistory } from "react-router";
import Loader from "../Loader/Loader";
import Lottie from "react-lottie-player";
import { useRef } from "react";
import {
  setQuotes,
  setUploadStatus,
  uploadSelectedQuote,
} from "../../modules/QuotePage/quotePageSlice";

const BajajDiscountPopup = ({ show, onClose, quote }) => {
  const { salaried, existingPolicy, existingPolicyBajaj } = useSelector(
    state => state.bajajDiscount,
  );
  const {
    filter: { coverUpto },
  } = useSelector(state => state.quoteFilter);
  const congratsRef = useRef();
  const salariedRef = useRef();
  const existingPolicyRef = useRef();
  const existingPolicyBajajRef = useRef();
  const modalContentRef = useRef();
  const { customerDetails } = useSelector(state => state.greeting);
  const { payPremium } = useSelector(state => state.quoteFilter.subFilter);
  const age = getAge(customerDetails.dob);
  const [isBajajData, setIsBajajData] = useState(false);
  const [apiStatus, setApiStatus] = useState("");
  const { selectedQuote } = useSelector(state => state.quote);
  const [currentComponent, setCurrentComponent] = useState(1);
  const { switchToMedical } = useSelector(state => state.quote);
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  useEffect(() => {
    if (customerDetails?.annualIncome < 400000 && salaried === "N") {
      setIsNotAllowed(true);
    } else setIsNotAllowed(false);
  }, [customerDetails, salaried]);
  const handleNext = () => {
    if (currentComponent === 1) {
      salariedRef.current.style.display = "none";
      existingPolicyRef.current.style.display = "flex";
      existingPolicyRef.current.classList.add(
        "animate__animated",
        "animate__slideInRight",
        "animate__fast",
      );
    } else if (currentComponent === 2) {
      existingPolicyRef.current.style.display = "none";
      existingPolicyRef.current.classList.remove(
        "animate__animated",
        "animate__slideInRight",
        "animate__fast",
      );
      if (quote?.master_product_name === "eTouch" && existingPolicy === "Y") {
        existingPolicyBajajRef.current.style.display = "flex";
        existingPolicyBajajRef.current.classList.add(
          "animate__animated",
          "animate__slideInRight",
          "animate__fast",
        );
      } else {
        congratsRef.current.style.display = "flex";
        modalContentRef.current.classList.remove(
          "animate__animated",
          "animate__flipOutY2",
          "animate__fast",
        );
        modalContentRef.current.classList.add(
          "animate__flipInY",
          "animate__animated",
        );
      }
    } else if (currentComponent === 3) {
      existingPolicyBajajRef.current.style.display = "none";
      existingPolicyBajajRef.current.classList.remove(
        "animate__animated",
        "animate__slideInRight",
        "animate__fast",
      );
      congratsRef.current.style.display = "flex";
      modalContentRef.current.classList.remove(
        "animate__animated",
        "animate__flipOutY2",
        "animate__fast",
      );
      modalContentRef.current.classList.add(
        "animate__flipInY",
        "animate__animated",
      );
    }
    if (quote?.master_product_name === "eTouch" && existingPolicy === "Y") {
      if (currentComponent < 4) {
        setCurrentComponent(currentComponent + 1);
      }
    } else {
      if (currentComponent < 3) {
        setCurrentComponent(currentComponent + 1);
      }
    }
  };
  const handleBack = () => {
    if (currentComponent > 1) {
      setCurrentComponent(currentComponent - 1);
    }
  };

  const dispatch = useDispatch();
  const { enquiryId } = useCustomLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(resetBajajDiscountData());
  }, []);

  const { width } = useWindowResize();

  const handleClose = () => {
    onClose(false);
    setCurrentComponent(1);
    dispatch(resetBajajDiscountData());
    salariedRef.current.style.display = "flex";
    existingPolicyRef.current.style.display = "none";
    existingPolicyBajajRef.current.style.display = "none";
    congratsRef.current.style.display = "none";
    salariedRef.current.classList.remove(
      "animate__animated",
      "animate__slideInLeft",
      "animate__fast",
    );
    modalContentRef.current.classList.remove(
      "animate__animated",
      "animate__flipInY",
      "animate__fast",
      "animate__flipOutY2",
    );
    if (quote?.master_product_name === "eTouch") {
      const payload = {
        traceId: enquiryId,
        requestType: "eligibility_details",
        eligibleStatus: "YES",
        company_alias: "bajaj_life",
        first_time_buyer: "N",
        existing_customer: "N",
        loyalty_flag: "N",
      };
      sendEligibilityDetails(payload);
    }
  };

  const hideCongratsPopup =
    quote?.master_product_name === "eTouch"
      ? salaried === "N" &&
        existingPolicy === "Y" &&
        existingPolicyBajaj === "N"
        ? true
        : false
      : salaried === "N" && existingPolicy === "Y"
      ? true
      : false;
  const premium = selectedQuote?.premium_amount || quote?.premium_amount || 0;
  const selfEmployedPremium =
    selectedQuote?.self_employed_premium || quote?.self_employed_premium || 0;
  const isSalaried = salaried === "Y";

  return (
    <>
      <div
        className="modal fade show bajajLifeModal"
        isSalaried
        id="maxLifeModal"
        role="dialog"
        style={{
          display: show && "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="modal-dialog">
          <div
            className="modal-content expertPopupWrap"
            ref={modalContentRef}
            style={{
              width: width > 768 ? "85%" : "100%",
              borderRadius: "8px",
              marginLeft: width > 768 ? "5rem" : "0px",
            }}
          >
            <div
              className="expertTopWrap"
              style={{
                position: "absolute",
                top: "8px",
                zIndex: "999",
              }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={apiStatus === "Pending"}
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                  fill="#333"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              {currentComponent !== 1 && (
                <button
                  className={`back`}
                  disabled={apiStatus === "Pending"}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginLeft: "10px",
                    border: "none",
                    background: "transparent",
                  }}
                  onClick={() => {
                    if (currentComponent === 1) {
                      handleClose();
                    } else if (currentComponent === 2) {
                      handleBack();
                      salariedRef.current.style.display = "flex";
                      salariedRef.current.classList.add(
                        "animate__animated",
                        "animate__slideInLeft",
                        "animate__fast",
                      );
                      existingPolicyRef.current.style.display = "none";
                      existingPolicyRef.current.classList.remove(
                        "animate__animated",
                        "animate__slideInRight",
                        "animate__slideInLeft",
                        "animate__fast",
                      );
                    } else if (currentComponent === 3) {
                      handleBack();

                      if (
                        quote?.master_product_name === "eTouch" &&
                        existingPolicy === "Y"
                      ) {
                        const payload = {
                          traceId: enquiryId,
                          requestType: "eligibility_details",
                          eligibleStatus: "YES",
                          company_alias: "bajaj_life",
                          first_time_buyer: "Y",
                          existing_customer: "N",
                          loyalty_flag: "N",
                        };
                        setApiStatus("Pending");
                        sendEligibilityDetails(payload).then(() => {
                          getQuotesBajaj({
                            traceId: enquiryId,
                          }).then(res => {
                            dispatch(setNewBajajDiscount(res.data.QuoteData));
                            dispatch(
                              uploadSelectedQuote(
                                res?.data?.QuoteData?.quote_data.find(
                                  item =>
                                    item.master_product_name ===
                                    quote.master_product_name,
                                ),
                              ),
                            );
                            setIsBajajData(true);
                            setApiStatus("Success");
                            existingPolicyRef.current.style.display = "flex";
                            existingPolicyRef.current.classList.add(
                              "animate__animated",
                              "animate__slideInLeft",
                              "animate__fast",
                            );
                            existingPolicyBajajRef.current.style.display =
                              "none";
                            existingPolicyBajajRef.current.classList.remove(
                              "animate__animated",
                              "animate__slideInRight",
                              "animate__slideInLeft",
                              "animate__fast",
                            );
                          });
                        });
                      } else {
                        existingPolicyRef.current.style.display = "flex";
                        existingPolicyRef.current.classList.add(
                          "animate__animated",
                          "animate__slideInLeft",
                          "animate__fast",
                        );
                        congratsRef.current.style.display = "none";
                        modalContentRef.current.classList.remove(
                          "animate__flipInY",
                          "animate__animated",
                        );
                        modalContentRef.current.classList.add(
                          "animate__animated",
                          "animate__flipOutY2",
                          "animate__fast",
                        );
                      }
                    } else if (currentComponent === 4) {
                      handleBack();
                      if (
                        quote?.master_product_name === "eTouch" &&
                        existingPolicyBajaj === "N"
                      ) {
                        const payload = {
                          loyalty_flag: "Y",
                          traceId: enquiryId,
                          requestType: "eligibility_details",
                          eligibleStatus: "YES",
                          company_alias: "bajaj_life",
                        };
                        setApiStatus("Pending");
                        sendEligibilityDetails(payload).then(() => {
                          getQuotesBajaj({
                            traceId: enquiryId,
                          }).then(res => {
                            dispatch(setNewBajajDiscount(res.data.QuoteData));
                            dispatch(
                              uploadSelectedQuote(
                                res?.data?.QuoteData?.quote_data.find(
                                  item =>
                                    item.master_product_name ===
                                    quote.master_product_name,
                                ),
                              ),
                            );
                            setIsBajajData(true);
                            setApiStatus("Success");
                            existingPolicyBajajRef.current.style.display =
                              "flex";
                            existingPolicyBajajRef.current.classList.add(
                              "animate__animated",
                              "animate__slideInLeft",
                              "animate__fast",
                            );
                            congratsRef.current.style.display = "none";
                            modalContentRef.current.classList.remove(
                              "animate__flipInY",
                              "animate__animated",
                            );
                            modalContentRef.current.classList.add(
                              "animate__animated",
                              "animate__flipOutY2",
                              "animate__fast",
                            );
                          });
                        });
                      } else {
                        existingPolicyBajajRef.current.style.display = "flex";
                        existingPolicyBajajRef.current.classList.add(
                          "animate__animated",
                          "animate__slideInLeft",
                          "animate__fast",
                        );
                        congratsRef.current.style.display = "none";
                        modalContentRef.current.classList.remove(
                          "animate__flipInY",
                          "animate__animated",
                        );
                        modalContentRef.current.classList.add(
                          "animate__animated",
                          "animate__flipOutY2",
                          "animate__fast",
                        );
                      }
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div
              style={{ marginTop: "1.5rem" }}
              className="modal-body containerbajaj"
            >
              <div className="components-containerbajaj">
                <div className={`offerBuyNowWrapper`}>
                  <div ref={salariedRef} className="salariedWrapper">
                    <img
                      className="offerBuyNowWrapperLaxmi"
                      src={LaxmiImage}
                      alt="expert"
                    />
                    <p className="offerBuyNowTitle">Are You Salaried?</p>
                    <div className="offerBuyNowBtnWrapper">
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="yes"
                            name="salaried"
                            disabled={apiStatus === "Pending"}
                            checked={salaried === "Y"}
                            onClick={() => {
                              dispatch(setSalaried("Y"));
                            }}
                          />
                          <label for="yes">Yes</label>
                        </div>
                        <p className="offerBuyNowBtnValue">
                          ₹{" "}
                          {Number(quote?.premium_amount).toLocaleString(
                            "en-IN",
                          ) ||
                            Number(
                              selectedQuote?.premium_amount,
                            ).toLocaleString("en-IN")}{" "}
                          {payPremium}
                        </p>
                      </div>

                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="no"
                            disabled={apiStatus === "Pending"}
                            checked={salaried === "N"}
                            name="salaried"
                            onClick={() => {
                              dispatch(setSalaried("N"));
                            }}
                          />
                          <label for="no">No</label>
                        </div>
                        <p className="offerBuyNowBtnValue">
                          ₹{" "}
                          {Number(quote?.self_employed_premium).toLocaleString(
                            "en-IN",
                          ) ||
                            Number(
                              selectedQuote?.self_employed_premium,
                            ).toLocaleString("en-IN")}{" "}
                          {payPremium}
                        </p>
                      </div>
                    </div>
                    {isNotAllowed && (
                      <div
                        style={{
                          textAlign: "center",
                          color: "#107591",
                          marginBottom: "15px",
                        }}
                      >
                        You can proceed ahead only if you’re a salaried
                      </div>
                    )}

                    <button
                      type="submit"
                      className={`cardBuyBtn`}
                      style={{
                        backgroundColor: isNotAllowed && "#dcdde0",
                        color: isNotAllowed && "#969799",
                        border: isNotAllowed && "none",
                      }}
                      disabled={apiStatus === "Pending" || isNotAllowed}
                      onClick={() => {
                        setApiStatus("Pending");
                        updateQuotation({
                          insurance_code: quote?.insurance_code,
                          annualPremium: premium,
                          childProductName: quote.child_product_name,
                          cover_amount: quote.cover_amount,
                          integrationType: quote.integration_type,
                          masterProductName: quote.master_product_name,
                          medicalCondition: quote.medicalCondition,
                          paytypeOption:
                            quote.planTenure === "same" &&
                            coverUpto === "Whole Life"
                              ? "WL"
                              : "N",
                          planTenure: quote.planTenure,
                          policyTerm: `${
                            parseInt(
                              quote.planTenure === "same"
                                ? coverUpto === "Whole Life"
                                  ? 99
                                  : coverUpto
                                : quote?.differentTenureCU,
                            ) - age
                          }`,
                          premiumAmount: isSalaried
                            ? quote?.premium_amount
                            : quote?.self_employed_premium,
                          sumAssured: quote.sumAssured,
                          termInsuId: quote.insurance_code,
                          totalPremiumGst: quote.premium_amount_gst,
                          isMaxLifeDiscount: salaried,
                          companyName: quote.company_name,
                          companyAlias: quote.company_alias,
                          requestType: "select_product",
                          traceId: enquiryId,
                        }).then(() => {
                          const payload =
                            quote?.master_product_name === "eTouch"
                              ? {
                                  termOccupation: isSalaried
                                    ? "Salaried"
                                    : "Self Employed",
                                  termOccupation_text: isSalaried
                                    ? "Salaried"
                                    : "Self Employed",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                  first_time_buyer: "Y",
                                }
                              : {
                                  termOccupation: isSalaried
                                    ? "Salaried"
                                    : "Self Employed",
                                  termOccupation_text: isSalaried
                                    ? "Salaried"
                                    : "Self Employed",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                };
                          sendEligibilityDetails(payload).then(() => {
                            if (quote?.master_product_name === "eTouch") {
                              getQuotesBajaj({
                                traceId: enquiryId,
                              }).then(res => {
                                dispatch(
                                  setNewBajajDiscount(res.data.QuoteData),
                                );
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.master_product_name ===
                                        quote.master_product_name,
                                    ),
                                  ),
                                );
                                setIsBajajData(true);
                                setApiStatus("Success");
                                handleNext();
                              });
                            }
                          });
                          if (quote?.master_product_name !== "eTouch") {
                            setApiStatus("Success");
                            handleNext();
                          }
                        });
                      }}
                    >
                      {apiStatus === "Pending" ? (
                        <Loader
                          style={{
                            position: "absolute",
                            background: "transparent ",
                            bottom: "9 !important",
                            left: "48% !important",
                            borderRadius: "5px",
                            border: "5px",
                            width: "220px !important",
                            height: "65px",
                            marginBottom: "1.5rem",
                          }}
                          bounceStyle={{ backgroundColor: "#fff" }}
                        />
                      ) : (
                        "NEXT"
                      )}
                    </button>
                  </div>
                  <div
                    className="existingPolicyWrapper"
                    ref={existingPolicyRef}
                  >
                    <img
                      className="offerBuyNowWrapperLaxmi"
                      src={LaxmiImage}
                      alt="expert"
                    />
                    <p className="offerBuyNowSubTitle">
                      Let’s get you some more discounts!!!
                    </p>
                    <p className="offerBuyNowTitle2">
                      Do you have any existing life insurance policy?
                    </p>
                    <p className="offerBuyNowSubTitle2 max-width-none">
                      (Like Term Plan, Endowment Plan , ULIP, Child etc.)
                    </p>

                    <div className="offerBuyNowBtnWrapper">
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="existingPolicyYes"
                            name="existingPolicy"
                            disabled={apiStatus === "Pending"}
                            checked={existingPolicy === "Y"}
                            onClick={() => {
                              dispatch(setExistingPolicy("Y"));
                            }}
                          />
                          <label for="existingPolicyYes">Yes</label>
                        </div>
                        {quote?.master_product_name === "eTouch" && (
                          <p className="offerBuyNowBtnValue">
                            ₹{" "}
                            {Number(
                              selectedQuote?.self_employed_premium,
                            ).toLocaleString("en-IN")}{" "}
                            {payPremium}
                          </p>
                        )}
                      </div>
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="existingPolicyNo"
                            name="existingPolicy"
                            checked={existingPolicy === "N"}
                            disabled={apiStatus === "Pending"}
                            onClick={() => {
                              dispatch(setExistingPolicy("N"));
                            }}
                          />
                          <label for="existingPolicyNo">No</label>
                        </div>
                        {quote?.master_product_name === "eTouch" && (
                          <p className="offerBuyNowBtnValue">
                            ₹{" "}
                            {Number(
                              selectedQuote?.premium_amount,
                            ).toLocaleString("en-IN")}{" "}
                            {payPremium}
                          </p>
                        )}
                      </div>
                    </div>
                    <button
                      className="cardBuyBtn"
                      style={{
                        marginBottom: "1.5rem",
                      }}
                      disabled={apiStatus === "Pending" || isNotAllowed}
                      onClick={() => {
                        if (existingPolicy === "N") {
                          setApiStatus("Pending");
                          const payload =
                            quote?.master_product_name === "eTouch"
                              ? {
                                  existing_customer: "N",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                }
                              : {
                                  first_time_buyer: "Y",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                };
                          sendEligibilityDetails(payload).then(() => {
                            getQuotesBajaj({
                              traceId: enquiryId,
                            }).then(res => {
                              dispatch(setNewBajajDiscount(res.data.QuoteData));
                              if (quote?.master_product_name === "eTouch") {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.master_product_name ===
                                        quote.master_product_name,
                                    ),
                                  ),
                                );
                              } else {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.medical_status ===
                                        quote.medical_status,
                                    ),
                                  ),
                                );
                              }
                              setIsBajajData(true);
                              setApiStatus("Success");
                              handleNext();
                            });
                          });
                        } else if (existingPolicy === "Y") {
                          setApiStatus("Pending");
                          const payload =
                            quote?.master_product_name === "eTouch"
                              ? {
                                  existing_customer: "Y",
                                  loyalty_flag: "Y",
                                  first_time_buyer: "N",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                }
                              : {
                                  first_time_buyer: "N",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                };
                          sendEligibilityDetails(payload).then(() => {
                            getQuotesBajaj({
                              traceId: enquiryId,
                            }).then(res => {
                              dispatch(setNewBajajDiscount(res.data.QuoteData));
                              if (quote?.master_product_name === "eTouch") {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.master_product_name ===
                                        quote.master_product_name,
                                    ),
                                  ),
                                );
                              } else {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.medical_status ===
                                        quote.medical_status,
                                    ),
                                  ),
                                );
                              }
                              setIsBajajData(true);
                              setApiStatus("Success");
                              handleNext();
                              if (hideCongratsPopup) {
                                if (quote.planTenure === "different") {
                                  dispatch(setQuotes([]));
                                }
                                handleNext();
                                history.push({
                                  pathname: "/riderPage",
                                  search: `enquiryId=${enquiryId}`,
                                });
                              }
                            });
                          });
                        }
                      }}
                    >
                      {hideCongratsPopup ? (
                        apiStatus === "Pending" ? (
                          <Loader
                            style={{
                              position: "absolute",
                              background: "transparent ",
                              bottom: "9 !important",
                              left: "48% !important",
                              borderRadius: "5px",
                              border: "5px",
                              width: "220px !important",
                              height: "65px",
                              marginBottom: "1.5rem",
                            }}
                            bounceStyle={{ backgroundColor: "#fff" }}
                          />
                        ) : (
                          "Continue"
                        )
                      ) : apiStatus === "Pending" ? (
                        <Loader
                          style={{
                            position: "absolute",
                            background: "transparent ",
                            bottom: "9 !important",
                            left: "48% !important",
                            borderRadius: "5px",
                            border: "5px",
                            width: "220px !important",
                            height: "65px",
                            marginBottom: "1.5rem",
                          }}
                          bounceStyle={{ backgroundColor: "#fff" }}
                        />
                      ) : (
                        "NEXT"
                      )}
                    </button>
                  </div>
                  <div
                    className="existingPolicyWrapper"
                    ref={existingPolicyBajajRef}
                  >
                    <img
                      className="offerBuyNowWrapperLaxmi"
                      src={LaxmiImage}
                      alt="expert"
                    />
                    <p className="offerBuyNowSubTitle">
                      Let's get you some more discounts!!!
                    </p>
                    <p className="offerBuyNowTitle2">
                      Do you have an existing Bajaj Allianz Life Insurance
                      policy?
                    </p>
                    <p className="offerBuyNowSubTitle2 max-width-none">
                      (Like Term Plan, Endowment Plan , ULIP, Child etc.)
                    </p>

                    <div className="offerBuyNowBtnWrapper">
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="existingPolicyBajajYes"
                            name="existingPolicyBajaj"
                            disabled={apiStatus === "Pending"}
                            checked={existingPolicyBajaj === "Y"}
                            onClick={() => {
                              dispatch(setExistingPolicyBajaj("Y"));
                            }}
                          />
                          <label for="existingPolicyBajajYes">Yes</label>
                        </div>
                        {quote?.master_product_name === "eTouch" && (
                          <p className="offerBuyNowBtnValue">
                            ₹{" "}
                            {Number(
                              selectedQuote?.premium_amount,
                            ).toLocaleString("en-IN")}{" "}
                            {payPremium}
                          </p>
                        )}
                      </div>
                      <div className="offerBuyNowBtnCol">
                        <div className="offerBuyNowBtn">
                          <input
                            type="radio"
                            id="existingPolicyBajajNo"
                            name="existingPolicyBajaj"
                            checked={existingPolicyBajaj === "N"}
                            disabled={apiStatus === "Pending"}
                            onClick={() => {
                              dispatch(setExistingPolicyBajaj("N"));
                            }}
                          />
                          <label for="existingPolicyBajajNo">No</label>
                        </div>
                        {quote?.master_product_name === "eTouch" && (
                          <p className="offerBuyNowBtnValue">
                            ₹{" "}
                            {Number(
                              selectedQuote?.self_employed_premium,
                            ).toLocaleString("en-IN")}{" "}
                            {payPremium}
                          </p>
                        )}
                      </div>
                    </div>
                    <button
                      className="cardBuyBtn"
                      style={{
                        marginBottom: "1.5rem",
                      }}
                      disabled={apiStatus === "Pending" || isNotAllowed}
                      onClick={() => {
                        if (existingPolicyBajaj === "N") {
                          const payload =
                            quote?.master_product_name === "eTouch"
                              ? {
                                  loyalty_flag: "N",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                }
                              : {
                                  first_time_buyer: "Y",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                };
                          setApiStatus("Pending");
                          sendEligibilityDetails(payload).then(() => {
                            getQuotesBajaj({
                              traceId: enquiryId,
                            }).then(res => {
                              dispatch(setNewBajajDiscount(res.data.QuoteData));
                              if (quote?.master_product_name === "eTouch") {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.master_product_name ===
                                        quote.master_product_name,
                                    ),
                                  ),
                                );
                              } else {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.medical_status ===
                                        quote.medical_status,
                                    ),
                                  ),
                                );
                              }
                              setIsBajajData(true);
                              setApiStatus("Success");
                              handleNext();
                            });
                          });
                        } else if (existingPolicyBajaj === "Y") {
                          const payload =
                            quote?.master_product_name === "eTouch"
                              ? {
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                }
                              : {
                                  first_time_buyer: "N",
                                  traceId: enquiryId,
                                  requestType: "eligibility_details",
                                  eligibleStatus: "YES",
                                  company_alias: "bajaj_life",
                                };
                          setApiStatus("Pending");
                          sendEligibilityDetails(payload).then(() => {
                            getQuotesBajaj({
                              traceId: enquiryId,
                            }).then(res => {
                              dispatch(setNewBajajDiscount(res.data.QuoteData));
                              if (quote?.master_product_name === "eTouch") {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.master_product_name ===
                                        quote.master_product_name,
                                    ),
                                  ),
                                );
                              } else {
                                dispatch(
                                  uploadSelectedQuote(
                                    res?.data?.QuoteData?.quote_data.find(
                                      item =>
                                        item.medical_status ===
                                        quote.medical_status,
                                    ),
                                  ),
                                );
                              }
                              setIsBajajData(true);
                              setApiStatus("Success");
                              handleNext();
                            });
                          });
                        }
                        if (hideCongratsPopup) {
                          if (quote.planTenure === "different") {
                            dispatch(setQuotes([]));
                          }
                          handleNext();
                          dispatch(setUploadStatus(true));
                          history.push({
                            pathname: "/riderPage",
                            search: `enquiryId=${enquiryId}`,
                          });
                        }
                      }}
                    >
                      {hideCongratsPopup ? (
                        apiStatus === "Pending" ? (
                          <Loader
                            style={{
                              position: "absolute",
                              background: "transparent ",
                              bottom: "9 !important",
                              left: "48% !important",
                              borderRadius: "5px",
                              border: "5px",
                              width: "220px !important",
                              height: "65px",
                              marginBottom: "1.5rem",
                            }}
                            bounceStyle={{ backgroundColor: "#fff" }}
                          />
                        ) : (
                          "Continue"
                        )
                      ) : apiStatus === "Pending" ? (
                        <Loader
                          style={{
                            position: "absolute",
                            background: "transparent ",
                            bottom: "9 !important",
                            left: "48% !important",
                            borderRadius: "5px",
                            border: "5px",
                            width: "220px !important",
                            height: "65px",
                            marginBottom: "1.5rem",
                          }}
                          bounceStyle={{ backgroundColor: "#fff" }}
                        />
                      ) : (
                        "NEXT"
                      )}
                    </button>
                  </div>
                  <div ref={congratsRef} className="congratsWrapper ">
                    <img
                      className="offerBuyNowWrapperLaxmi"
                      src={LaxmiImage}
                      alt="expert"
                    />
                    <p className="offerBuyNowTitle2">Congratulations!!!</p>
                    <p className="offerBuyNowSubTitle3">
                      You have unlocked extra discount of{" "}
                      <span style={{ textWrap: "nowrap" }}>
                        ₹ {Math.abs(selfEmployedPremium - premium)}
                        &nbsp; {payPremium}
                      </span>
                    </p>

                    <div className="congratsValueWrapper">
                      {((currentComponent === 3 &&
                        quote?.master_product_name !== "eTouch") ||
                        (currentComponent === 4 &&
                          quote?.master_product_name === "eTouch" &&
                          existingPolicy === "Y") ||
                        (currentComponent === 3 &&
                          quote?.master_product_name === "eTouch" &&
                          existingPolicy === "N")) && (
                        <Lottie
                          animationData={fireworks}
                          className="fireworks"
                          play
                          loop={0}
                        />
                      )}
                      <p className="congratsValueOld">
                        ₹{Number(selfEmployedPremium).toLocaleString("en-IN")}
                      </p>
                      <p className="congratsValueNew">
                        ₹ {premium.toLocaleString("en-IN")}
                        &nbsp;
                        {payPremium}
                      </p>
                    </div>

                    <p className="offerBuyNowSubTitle2">
                      Applicable discount is only for first year
                    </p>
                    <button
                      className="cardBuyBtn"
                      style={{
                        marginBottom: "1.5rem",
                      }}
                      onClick={() => {
                        setApiStatus("Pending");
                        handleNext();
                        updateQuotation({
                          insurance_code: quote?.insurance_code,
                          annualPremium: premium,
                          childProductName: quote.child_product_name,
                          cover_amount: quote.cover_amount,
                          integrationType: quote.integration_type,
                          masterProductName: quote.master_product_name,
                          medicalCondition: quote.medicalCondition,
                          paytypeOption:
                            quote.planTenure === "same" &&
                            coverUpto === "Whole Life"
                              ? "WL"
                              : "N",
                          planTenure: quote.planTenure,
                          policyTerm: `${
                            parseInt(
                              quote.planTenure === "same"
                                ? coverUpto === "Whole Life"
                                  ? 99
                                  : coverUpto
                                : quote?.differentTenureCU,
                            ) - age
                          }`,
                          premiumAmount: premium,
                          sumAssured: quote.sumAssured,
                          termInsuId: quote.insurance_code,
                          totalPremiumGst: quote.premium_amount_gst,
                          isMaxLifeDiscount: salaried,
                          companyName: quote.company_name,
                          companyAlias: quote.company_alias,
                          requestType: "select_product",
                          traceId: enquiryId,
                        }).then(() => {
                          setApiStatus("Success");
                          handleNext();
                          if (quote.planTenure === "different") {
                            dispatch(setQuotes([]));
                          }
                          if (
                            switchToMedical ||
                            customerDetails.qualification === "10th Pass" ||
                            customerDetails.qualification === "12th Pass"
                          ) {
                            history.push({
                              pathname: "/eligibilitypage",
                              search: `enquiryId=${enquiryId}`,
                            });
                          } else {
                            dispatch(setUploadStatus(true));

                            history.push({
                              pathname: "/riderPage",
                              search: `enquiryId=${enquiryId}`,
                            });
                          }
                        });
                      }}
                      disabled={apiStatus === "Pending" || isNotAllowed}
                    >
                      {apiStatus === "Pending" ? (
                        <Loader
                          style={{
                            position: "absolute",
                            background: "transparent ",
                            bottom: "9 !important",
                            left: "48% !important",
                            borderRadius: "5px",
                            border: "5px",
                            width: "220px !important",
                            height: "65px",
                            marginBottom: "1.5rem",
                          }}
                          bounceStyle={{ backgroundColor: "#fff" }}
                        />
                      ) : (
                        "CONTINUE"
                      )}
                    </button>
                    {(quote?.master_product_name ||
                      selectedQuote?.master_product_name) === "eTouch" &&
                      payPremium !== "Yearly" && (
                        <p className="offerBuyNowSubTitle2">
                          1% Auto pay discount is applicable only then when you
                          opt for auto pay.
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BajajDiscountPopup;
