import React, { useEffect, useState } from "react";
import "styled-components/macro";
import "./loader.scss";

const AllNewLoader = ({
  contentKey,
  lines,
  style = {},
  isQuotesLoading,
  quotesLength,
}) => {
  // const [timmer, setTimmer] = useState(1000);
  // if(contentKey === "QuotesPageLoader"){
  //   setTimmer(3000);
  // }else if(contentKey === "RiderPgaeLoader"){
  //   setTimmer(2000);
  // }
  const [showLoader, setShowloader] = useState(false);
  const [timeLapsed, setTimeLapsed] = useState(false);
  const isUpdatingFilter = isQuotesLoading === "updating-filters";
  console.log("isQuotesLoading", isQuotesLoading);
  useEffect(() => {
    let timer;
    if (showLoader) {
      timer = setTimeout(() => {
        // if(quotesLength){
        setShowloader(false);
        // }
        setTimeLapsed(true);
      }, 9000);
    }
    return () => clearTimeout(timer);
  }, [showLoader]);

  useEffect(() => {
    if (timeLapsed && quotesLength) {
      setShowloader(false);
    }
  }, [timeLapsed, quotesLength]);

  useEffect(() => {
    if (isUpdatingFilter) {
      setShowloader(true);
      setTimeLapsed(false);
    }
  }, [isUpdatingFilter]);
  const [QuotePageLoaderLines, setQuotePageLoaderLines] = useState([
    "Fetching best possible quotes for you, kindly wait!",
    "Life insurance is all about taking care of your loved one’s",
    "Pay Less, Save More with limited pay option!",
    "Save premiums by choosing to pay yearly.",
    "Buying Term Insurance Made Easy - Expert Guidance All the Way!",
    "Buy Term life insurance that gives all your premiums back.",
    "Pay Less, Save More - Calculate Your Savings with Limited Pay!",
    "Spend smarter, Save bigger with Limited Pay Option!",
    "Enhance your covers by adding add-ons to your plan.",
    "With Elephant.in, You're Never Alone in this Journey.",
  ]);
  const [riderPageLoaderLine, setriderPageLoaderLine] = useState([
    "Fetching best possible quotes for you, kindly wait!",
    "Life insurance is all about taking care of your loved one’s",
    "Enhance your covers by adding add-ons to your plan.",
    "With Elephant.in, You're Never Alone in this Journey.",
  ]);

  if (lines === "FORQUOTEPAGE") {
    lines = QuotePageLoaderLines;
  } else if (lines === "FORRIDERPAGE") {
    lines = riderPageLoaderLine;
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const storedIndex = parseInt(
      localStorage.getItem(`currentIndex_${contentKey}`),
      10,
    );

    if (!isNaN(storedIndex) && storedIndex < lines.length - 1) {
      setCurrentIndex(storedIndex);
      // setIsLoading(true);
    }

    const timer = setTimeout(
      () => {
        if (currentIndex < lines.length - 1) {
          setCurrentIndex(prevIndex => prevIndex + 1);
          localStorage.setItem(`currentIndex_${contentKey}`, currentIndex + 1);
        } else {
          setCurrentIndex(0); // Reset to the first line
          localStorage.setItem(`currentIndex_${contentKey}`, 0);
          // setIsLoading(true);
        }
      },
      contentKey === "QuotesPageLoader"
        ? 3000
        : contentKey === "RidersPageLoader"
        ? 2000
        : 0,
    ); // Adjust the delay between lines as needed

    return () => clearTimeout(timer);
  }, [currentIndex, contentKey, lines]);
  let Ncss = ``;
  if (contentKey === "RidersPageLoader") {
    Ncss = `width: max-content;
    @media only screen and (max-width: 767px){
      width: 95%;
    }`;
  } else if (contentKey === "QuotesPageLoader") {
    Ncss = `
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50;
    width: max-content;
    height: auto;
    margin: 0;
    @media only screen and (max-width: 767px){
      width: 95%;
    }
  `;
  }
  if (!showLoader) {
    return <></>;
  }
  return (
    <div className="loader" style={style}>
      {/* {message && <div>{message}</div>} */}
      <div className="spinner" css={Ncss}>
        <p
          css={`
            color: #333333;
            font-size: 18px;
            font-family: Inter-Regular;
            margin: 0 0 20px 0;
            @media only screen and (max-width: 767px){
              font-size: 16px;
            }
            
          `}
        >
          {lines[currentIndex]}
        </p>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <p
          css={`
            width: 100%;
          `}
        >
          {/* {children} */}
        </p>
      </div>
    </div>
  );
};

export default AllNewLoader;
