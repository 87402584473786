import { createSlice } from "@reduxjs/toolkit";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const insuredDetails2Slice = createSlice({
  name: "insuredDetails2Form",
  initialState: {
    insuredDetails2: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.insuredDetails2 = action.payload;
    },
  },
});

export const { saveDetails } = insuredDetails2Slice.actions;

export const insuredDetails2SendData = (data = {}) => ({
  pNationality: data.insNationality,
  country: data.insResidentStatus || data.isBirthCountry,
  proposalJourneyStatus: iciciProposalForms.insuredDetails2,
});

export const saveInsuredDetails2 = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(insuredDetails2SendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default insuredDetails2Slice.reducer;
