export const data = [
  {
    type: "text",
    name: "firstName",
    id: "firstName",
    placeHolder: " ",
    autoComplete: "none",
    label: "First Name",
    readOnly: true,
  },
  {
    type: "text",
    name: "lastName",
    id: "lastName",
    placeHolder: " ",
    autoComplete: "none",
    label: "Last Name",
    readOnly: true,
  },
  {
    type: "email",
    name: "email",
    id: "email",
    placeHolder: " ",
    autoComplete: "none",
    label: "Personal Email ID",
    readOnly: true,
  },
  {
    type: "tel",
    name: "mobileNo",
    id: "mobileNo",
    placeHolder: " ",
    autoComplete: "none",
    label: "Mobile no.",
    maxLength: "10",
    readOnly: true,
  },
  {
    type: "text",
    name: "dob",
    id: "dob",
    placeHolder: " ",
    autoComplete: "none",
    label: "Date of Birth (DD/MM/YYYY)",
    maxLength: "10",
    readOnly: true,
  },
  {
    type: "tel",
    name: "pinCode",
    id: "pinCode",
    placeHolder: " ",
    autoComplete: "none",
    label: "Pincode",
    maxLength: "6",
    readOnly: true,
  },

  {
    type: "text",
    name: "state",
    id: "state",
    placeHolder: " ",
    autoComplete: "none",
    label: "State",
    maxLength: "20",
    readOnly: true,
  },

  {
    type: "text",
    name: "city",
    id: "city",
    placeHolder: " ",
    autoComplete: "none",
    label: "City",
    maxLength: "20",
  },
  {
    type: "text",
    name: "addressLine1",
    id: "addressLine1",
    placeHolder: " ",
    autoComplete: "none",
    label: "Address Line 1",
    maxLength: "30",
  },
  {
    type: "text",
    name: "addressLine2",
    id: "addressLine2",
    placeHolder: " ",
    autoComplete: "none",
    label: "Address Line 2",
    maxLength: "30",
  },
];

export const p_data = [
  {
    type: "tel",
    name: "p_pincode",
    id: "p_pincode",
    placeHolder: " ",
    autoComplete: "none",
    label: "Pincode",
    maxLength: "6",
  },

  {
    type: "text",
    name: "p_state",
    id: "p_state",
    placeHolder: " ",
    autoComplete: "none",
    label: "State",
    maxLength: "20",
    readOnly: true,
  },

  {
    type: "text",
    name: "p_city",
    id: "p_city",
    placeHolder: " ",
    autoComplete: "none",
    label: "City",
    maxLength: "20",
  },
  {
    type: "text",
    name: "p_address_line_1",
    id: "p_address_line_1",
    placeHolder: " ",
    autoComplete: "none",
    label: "Address Line 1",
    maxLength: "30",
  },
  {
    type: "text",
    name: "p_address_line_2",
    id: "p_address_line_2",
    placeHolder: " ",
    autoComplete: "none",
    label: "Address Line 2",
    maxLength: "30",
  },
];
