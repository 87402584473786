import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { insuredFtcaSchema } from "../../../../utils/validationSchema";
import { data } from "./data";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import {
  proposerFatcaSendData,
  saveinsuredFtcaDetails,
} from "./InsuredFtcaForm.slice";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  CustomDropDownFont,
  RadioContainer,
  RadioLabel,
  RadioButton,
  Radio,
  TextInputElg,
} from "../../../../components/Form/Form";
import "./insuredFtcaForm.scss";
import {
  dateUtil,
  iciciResidentStatusCode,
  noCopyAndPaste,
  numOnly,
  upperCase,
} from "../../../../utils/inputUtils";
import SecureLS from "secure-ls";
import { ToggleTile } from "../../components";
import { CustomDropDown } from "../../../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import NonAcceptCountriesPopup from "../../../../components/Popup/CountriesPopup/NonAcceptCountriesPopup";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import { proposerSendData } from "../ProposerForm/proposal.slice";
import "styled-components/macro";

const InsuredFtcaForm = ({ position, setPosition, index }) => {
  const ls = new SecureLS();

  const PAN_ISSUING_COUNTRY = ["India"];
  const NATIONALITY = ["Indian"];
  const NATIONALITY_NRI = ["Indian", "Non Indian"];
  const RESIDENT_STATUS = ["Resident Indian", "Non Resident Indian"];
  const RESIDENT_STATUS_NRI = ["PIO/OCI", "Foreign National"];
  const COUNTRIES = [
    "Select Country",
    "Australia",
    "Bahrain",
    "Brunei",
    "China",
    "Fiji",
    "Hong Kong",
    "Maldives",
    "Mauritius",
    "New Zealand",
    "Oman",
    "Papua New Guinea",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "South Korea",
    "Sri Lanka",
    "Taiwan",
    "Thailand",
    "UAE",
    "Vietnam",
  ];

  const dispatch = useDispatch();
  const { proposerDetails } = useSelector(state => state.proposalForm);
  const { insuredFtcaDetails } = useSelector(state => state.insuredFtcaForm);
  const { insuredDetails2 } = useSelector(state => state.insuredDetails2Form);
  const { iciciSingleProduct } = useSelector(state => state.iciciSingleProduct);

  // const details = sessionStorage.getItem("eligibilityDetails");

  const { eligibilityDetails: details } = useSelector(
    state => state.eligibilityCard,
  );

  const [edit, setEdit] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [taxResident, setTaxResident] = useState(
    details.isNRI === "NO" || insuredFtcaDetails.isTaxResident === "yes",
  );
  const [panIssuingCountry, setPanIssuingCountry] = useState(
    PAN_ISSUING_COUNTRY[0],
  );
  const [birthCountry, setBirthCountry] = useState(
    details.isNRI === "NO" || insuredFtcaDetails.isBithCountry === "yes",
  );
  const [travelNonAccCont, setTravelNonAccCont] = useState(
    insuredFtcaDetails["travelNonAccCont"],
  );
  const [nationality, setNationality] = useState(
    insuredFtcaDetails.nationality || NATIONALITY[0],
  );
  const [disable, setDisable] = useState(true);
  const [agreeTerm, setAgreeTerm] = useState(insuredFtcaDetails.agreeTerm);
  const [residentStatus, setResidentStatus] = useState(
    insuredFtcaDetails.residentStatus || RESIDENT_STATUS[0],
  );
  const [durationStayAbroadMonths, setDurationStayAbroadMonths] = useState("0");
  const [
    intendeddurationStayAbroadMonths,
    setIntendedDurationStayAbroadMonths,
  ] = useState("0");
  const [nriTravelDetails, setNriTravelDetails] = useState(false);
  const [bankType, setBankType] = useState("NRE");

  const { register, handleSubmit, errors, formState, setValue, trigger } =
    useForm({
      resolver: yupResolver(insuredFtcaSchema),
      mode: "onChange",
    });
  const { isValid, ...rest } = formState;

  const onClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (agreeTerm) {
      setDisable(false);
    } else setDisable(true);
  }, [agreeTerm, isValid]);

  // useEffect(() => {
  //   !birthCountry && setNationality("Indian");
  // }, [birthCountry]);

  useEffect(() => {
    register({ name: "residentStatus" });
    setValue("residentStatus", residentStatus);
    if (iciciSingleProduct?.quote_data?.nri === "YES") {
      register({ name: "travelNonAccCont" });
    }
    if (residentStatus !== "Resident Indian") {
      register({ name: "passport" });
      setValue("passport", insuredFtcaDetails.passport);
      register({ name: "purposeOfStay" });
      setValue("purposeOfStay", insuredFtcaDetails.purposeOfStay);
      register({ name: "employUniDetails" });
      setValue("employUniDetails", insuredFtcaDetails.employUniDetails);
      register({ name: "arrivingDate" });
      setValue("arrivingDate", insuredFtcaDetails.arrivingDate);
      register({ name: "leavingDate" });
      setValue("leavingDate", insuredFtcaDetails.leavingDate);
      register({ name: "durationStayAbroad" });
      setValue("durationStayAbroad", insuredFtcaDetails.durationStayAbroad);
      register({ name: "intendedDurationStayAbroad" });
      setValue(
        "intendedDurationStayAbroad",
        insuredFtcaDetails.intendedDurationStayAbroad,
      );
      register({ name: "nriNationality" });
      setValue("nriNationality", insuredFtcaDetails.nriNationality);
      register({ name: "nriCountryResidence" });
      setValue("nriCountryResidence", insuredFtcaDetails.nriCountryResidence);
      if (nriTravelDetails) {
        register({ name: "nriDetails" });
        setValue("nriDetails", insuredFtcaDetails.nriDetails);
      }
    }
  }, [register, residentStatus]);

  const [tin, setTin] = useState(insuredFtcaDetails.tin || "");

  useEffect(() => {
    register({ name: "birthCountry" });
    setValue("birthCountry", birthCountry);
    if (!birthCountry) {
      register({ name: "birthPlace" });
      register({ name: "countryOfBirth" });
      setValue("birthPlace", insuredFtcaDetails.birthPlace);
      setValue("countryOfBirth", insuredFtcaDetails.countryOfBirth);
    }
  }, [birthCountry, register]);

  useEffect(() => {
    register({ name: "taxResident" });
    setValue("taxResident", taxResident);
    if (!taxResident) {
      // register({ name: "panIssuingCountry2" });
      // setValue("panIssuingCountry2", insuredFtcaDetails.panIssuingCountry2);
      // register({ name: "pan2" });
      // setValue("pan2", tin);
    }
  });

  useEffect(() => {
    if (nationality !== "Indian") {
      setResidentStatus("Foreign National");
    } else {
      setResidentStatus("Resident Indian");
    }
  }, [nationality]);

  useEffect(() => {
    if (taxResident && birthCountry) {
      setNationality("Indian");
      setResidentStatus("Resident Indian");
    }
  }, [taxResident, birthCountry]);

  const dateRef = useRef();

  const attachRef = ref => {
    register(ref);
    dateRef.current = ref;
  };

  const handleChange = event => {
    dateUtil(event, dateRef.current, true);
    // setAge(val);
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const [panIssuingCountry2, setPanIssuingCountry2] = useState(
    insuredFtcaDetails.panIssuingCountry2 || "Select",
  );

  const onSubmit = data => {
    let isTaxResident = taxResident ? "yes" : "no";
    let isBithCountry = birthCountry ? "yes" : "no";
    if (taxResident) {
      isTaxResident = "yes";
    }

    if (!taxResident) {
      isTaxResident = "no";
    }
    data["arrivingDate"] =
      residentStatus !== "Resident Indian" &&
      data["arrivingDate"].replace(/ /g, "");
    data["leavingDate"] =
      residentStatus !== "Resident Indian" &&
      data["leavingDate"].replace(/ /g, "");

    // const arrivingDate = data.arrivingDate.split("/").reverse().join("-");
    // const leavingDate = data.leavingDate.split("/").reverse().join("-");
    setEdit(false);

    const newData = {
      ...data,
      panIssuingCountry,
      tin,
      panIssuingCountry2,
      isTaxResident,
      isBithCountry,
      nationality,
      residentStatus,
      agreeTerm,
      travelNonAccCont,

      durationStayAbroadMonthsData:
        residentStatus !== "Resident Indian" && durationStayAbroadMonths,
      intendeddurationStayAbroadMonthsData:
        residentStatus !== "Resident Indian" &&
        intendeddurationStayAbroadMonths,
      nriTravelDetailsData:
        residentStatus !== "Resident Indian" && nriTravelDetails ? "Yes" : "No",
      bankTypeData: residentStatus !== "Resident Indian" && bankType,
    };

    const sendData = proposerFatcaSendData(newData);
    // console.log({ fatcaSendData: sendData });
    saveProposalForm(sendData, () => {
      dispatch(saveinsuredFtcaDetails(newData));
      dispatch(setPosition(position + 1));
    });
  };

  useEffect(() => {
    if (insuredFtcaDetails) {
      if (position === index) {
        setEdit(true);
      }
    }
  }, [position]);
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Proposer FATCA Details</CardTitle>
      </Card>
    </div>
  );

  // console.log({ disable, isValid, ...rest });

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  if (proposerDetails) {
    return (
      <>
        {insuredFtcaDetails && !edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer FATCA Details
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEdit(true);
                  dispatch(setPosition(index));
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
            </CardTitle>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormGroup md formPage>
                <Label md editMode htmlFor="taxResident">
                  I/we are a tax resident of ONLY India?
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["isTaxResident"]}
                </span>
              </FormGroup>
              {data.map(d => (
                <FormGroup md formPage key={d.name}>
                  <Label md editMode htmlFor={d.name}>
                    {d.label}
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredFtcaDetails[d.name]}
                  </span>
                </FormGroup>
              ))}

              <FormGroup md formPage>
                <Label md editMode htmlFor="birthCountry">
                  My Birth Country is India?
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["isBithCountry"]}
                </span>
              </FormGroup>
              <FormGroup md formPage>
                <Label md editMode htmlFor="taxResident">
                  PAN Issuing Country
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["panIssuingCountry"]}
                </span>
              </FormGroup>
              <FormGroup md formPage>
                <Label md editMode htmlFor="travelNonAccCont">
                  Residing in India but intend to travel to non acceptable
                  countries?
                </Label>
                <span
                  className="data"
                  style={{ top: "48px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["travelNonAccCont"] ? "yes" : "no"}
                </span>
              </FormGroup>

              {!taxResident && (
                <>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      PAN Issuing Country 2
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["panIssuingCountry2"] ||
                        panIssuingCountry2}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      TIN/PAN 2
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["tin"]}
                    </span>
                  </FormGroup>
                </>
              )}

              {!birthCountry && (
                <>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Birth Country
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["countryOfBirth"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Birth Place
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["birthPlace"]}
                    </span>
                  </FormGroup>
                </>
              )}

              <FormGroup md formPage>
                <Label md editMode htmlFor="taxResident">
                  Select Your Nationality
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["nationality"]}
                </span>
              </FormGroup>
              <FormGroup md formPage>
                <Label md editMode htmlFor="taxResident">
                  Select Your Resident Status
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["residentStatus"]}
                </span>
              </FormGroup>
              {(residentStatus === "PIO/OCI" ||
                residentStatus === "Foreign National" ||
                residentStatus === "Non Resident Indian") && (
                <>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Country of Nationality
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["nriNationality"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Country of Residence
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["nriCountryResidence"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Passport No
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["passport"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Purpose of your stay Abroad
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["purposeOfStay"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Employer/University Details
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["employUniDetails"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Date of Arriving in India
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["arrivingDate"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Date of leaving India After current visit
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["leavingDate"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Duration of Stay Abroad(Years)
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["durationStayAbroad"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Duration of Stay Abroad(Months)
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["durationStayAbroadMonthsData"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Intended Duration of Stay(Years)
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["intendedDurationStayAbroad"]}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Intended Duration of Stay(Months)
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {
                        insuredFtcaDetails[
                          "intendeddurationStayAbroadMonthsData"
                        ]
                      }
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      NRI Travel Details
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["nriTravelDetailsData"]}
                    </span>
                  </FormGroup>
                  {nriTravelDetails && (
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        My travel Details
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["nriDetails"]}
                      </span>
                    </FormGroup>
                  )}
                  <FormGroup md formPage>
                    <Label md editMode htmlFor="taxResident">
                      Type Of Bank Account Held
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {insuredFtcaDetails["bankTypeData"]}
                    </span>
                  </FormGroup>
                </>
              )}
            </div>
          </Card>
        ) : edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer FATCA Details
            </CardTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              tabIndex="0"
            >
              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">
                  I/we are a tax resident of ONLY India?
                </div>
                <RadioComponent
                  value={taxResident}
                  // className="toggle--sb"
                  disableRadio={
                    iciciSingleProduct?.quote_data?.nri === "NO" ? true : false
                  }
                  name="taxResidentt"
                  // block
                  // label="I/we are a tax resident of ONLY India?"
                  updateChecked={() => setTaxResident(!taxResident)}
                />
              </FormGroup>

              {/* ------------------------ */}
              {/* <div style={{ width: "100%" }}>
                <h3 style={{ margin: 13, display: "inline-block" }}>
                  I/we are a tax resident of ONLY India?
                </h3>
                <FormGroup
                  md
                  style={{ display: "inline-block", float: "right" }}
                >
                  <RadioContainer xlg proposer>
                    <Radio
                      type="radio"
                      id="taxResidentNo"
                      name="taxResidentt"
                      value="no"
                      defaultChecked={!taxResident}
                      onChange={() => setTaxResident(false)}
                      ref={register}
                    />
                    <RadioLabel
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      // error={errors.gender && "danger"}
                      sm
                      proposer
                      htmlFor="taxResidentNo"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          No
                        </span>
                      </div>
                    </RadioLabel>
                    <Radio
                      type="radio"
                      id="taxResidentYes"
                      name="taxResidentt"
                      defaultChecked={taxResident}
                      ref={register}
                      onChange={() => setTaxResident(true)}
                      value="yes"
                    />
                    <RadioLabel
                      // error={errors.policyFor && "danger"}
                      sm
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      proposer
                      htmlFor="taxResidentYes"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          Yes
                        </span>
                      </div>
                    </RadioLabel>
                    {errors.policyFor && (
                      <p className="errorG">{errors.policyFor.message}</p>
                    )}
                  </RadioContainer>
                </FormGroup>
              </div> */}
              {/* ------------------------ */}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">PAN Issuing Country</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="PAN issuing Country"
                    propDropDown
                    items={PAN_ISSUING_COUNTRY}
                    selectedItem={insuredFtcaDetails.panIssuingCountry}
                    onChange={e => setPanIssuingCountry(e)}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {data.map(d => (
                <FormGroup proposalCard eligibilityPage key={d.id}>
                  <TextInputElg
                    proposal
                    readOnly={d.readOnly}
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    onKeyDown={noCopyAndPaste}
                    style={{ textTransform: d.name === "pan" && "uppercase" }}
                    defaultValue={insuredFtcaDetails[d.name]}
                    placeholder={d.placeHolder}
                    // autoComplete={d.autoComplete}
                    autoComplete="none"
                    maxLength={d.maxLength}
                    ref={register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}
              {!taxResident && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        PAN Issuing Country 2
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="PAN Issuing Country 2"
                        propDropDown
                        items={COUNTRIES}
                        searchable={true}
                        selectedItem={
                          insuredFtcaDetails?.panIssuingCountry2 ||
                          panIssuingCountry2
                        }
                        onChange={e => {
                          // setValue("panIssuingCountry2", e);
                          setPanIssuingCountry2(e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      // name="pan2"
                      // id="pan2"
                      onKeyDown={noCopyAndPaste}
                      style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails?.pan2}
                      value={tin}
                      onChange={evt => setTin(evt.target.value)}
                      placeholder=" "
                      autoComplete="none"
                      // ref={register}
                      maxLength={11}
                      // onInput={numOnly}
                    />

                    <Label md htmlFor="pan2">
                      TIN/PAN 2
                    </Label>
                    {errors["pan2"] && (
                      <p className="error">{errors["pan2"].message}</p>
                    )}
                  </FormGroup>
                </>
              )}

              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">My Birth Country is India?</div>
                {/* <div> */}
                {/* <span>NO</span> */}
                {/* <span> */}
                <RadioComponent
                  disableRadio={
                    iciciSingleProduct?.quote_data?.nri === "NO" ? true : false
                  }
                  value={birthCountry}
                  name="birthIndiaa"
                  updateChecked={() => setBirthCountry(!birthCountry)}
                />
                {/* </span> */}
                {/* <span>YES</span> */}
                {/* </div> */}
              </FormGroup>

              {/* ------------------------ */}
              {/* <div style={{ width: "100%" }}>
                <h3 style={{ margin: 13, display: "inline-block" }}>
                  My Birth Country is India?
                </h3>
                <FormGroup
                  md
                  style={{ float: "right", display: "inline-block" }}
                >
                  <RadioContainer xlg proposer>
                    <Radio
                      type="radio"
                      id="birthIndiaNo"
                      name="birthIndiaa"
                      value="no"
                      defaultChecked={!birthCountry}
                      onChange={() => setBirthCountry(false)}
                      ref={register}
                    />
                    <RadioLabel
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      // error={errors.gender && "danger"}
                      sm
                      proposer
                      htmlFor="birthIndiaNo"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          No
                        </span>
                      </div>
                    </RadioLabel>
                    <Radio
                      type="radio"
                      id="birthIndiaYes"
                      name="birthIndiaa"
                      defaultChecked={birthCountry}
                      ref={register}
                      onChange={() => setBirthCountry(true)}
                      value="yes"
                    />
                    <RadioLabel
                      // error={errors.policyFor && "danger"}
                      sm
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      proposer
                      htmlFor="birthIndiaYes"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          Yes
                        </span>
                      </div>
                    </RadioLabel>
                    {errors.policyFor && (
                      <p className="errorG">{errors.policyFor.message}</p>
                    )}
                  </RadioContainer>
                </FormGroup>
              </div> */}
              {/* ------------------------ */}

              {!birthCountry && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">Country Of Birth</div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country Of Birth"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.countryOfBirth}
                        onChange={e => {
                          setValue("countryOfBirth", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="birthPlace"
                      id="birthPlace"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails.birthPlace}
                      placeholder=" "
                      autoComplete="none"
                      maxLength={30}
                      ref={register}
                    />

                    <Label md htmlFor="birthPlace">
                      Birth Place
                    </Label>
                    {errors["birthPlace"] && (
                      <p className="error">{errors["birthPlace"].message}</p>
                    )}
                  </FormGroup>
                </>
              )}
              <div className="d-flex justify-content-center">
                <div className="form-group">
                  <div className="form-check d-flex align-items-center m-4">
                    <input
                      className="form-check-input"
                      checked={agreeTerm}
                      type="checkbox"
                      id="gridCheck"
                      onChange={e => setAgreeTerm(e.target.checked)}
                    />
                    <div className="form-check-label inffo">
                      This information is required to be collected as per The
                      Income Tax Act, 1961 to comply with FATCA and CRS
                      regulations.We will continue to report the above
                      information 'As is' till we receive any change request
                      from you.
                    </div>
                  </div>
                </div>
              </div>
              {iciciSingleProduct?.quote_data?.nri === "YES" && (
                <FormGroup
                  xlg
                  className="form-group--1 mhealth"
                  style={{ textAlign: "left" }}
                >
                  <div className="healthQuestion">
                    Residing in India but intend to travel to{" "}
                    <span
                      style={{ color: "red" }}
                      onClick={() => setShowPopup(true)}
                    >
                      Non Acceptable Countries
                    </span>
                    ?
                  </div>
                  {/* <div> */}
                  {/* <span>NO</span> */}
                  {/* <span> */}
                  <RadioComponent
                    value={travelNonAccCont}
                    name="travelNonAccCont"
                    updateChecked={() => setTravelNonAccCont(!travelNonAccCont)}
                  />
                  {/* </span> */}
                  {/* <span>YES</span> */}
                  {/* </div> */}
                </FormGroup>
              )}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Nationality
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Nationality"
                    propDropDown
                    items={
                      !taxResident || !birthCountry
                        ? NATIONALITY_NRI
                        : NATIONALITY
                    }
                    // selectedItem={insuredFtcaDetails.nationality}
                    onChange={e => setNationality(e)}
                    selectedItem={nationality}
                    currentSelected={nationality}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Resident Status
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Resident Status"
                    propDropDown
                    items={
                      nationality === "Indian"
                        ? RESIDENT_STATUS
                        : RESIDENT_STATUS_NRI
                    }
                    disabled={taxResident && birthCountry ? true : false}
                    selectedItem={residentStatus}
                    onChange={e => setResidentStatus(e)}
                    currentSelected={residentStatus}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {(residentStatus === "PIO/OCI" ||
                residentStatus === "Foreign National" ||
                residentStatus === "Non Resident Indian") && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Nationality
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country of Nationality"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.nriNationality}
                        onChange={e => {
                          setValue("nriNationality", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                    {errors["nriNationality"] && (
                      <p className="error">
                        {errors["nriNationality"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Residence
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country of Residence"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.nriCountryResidence}
                        onChange={e => {
                          setValue("nriCountryResidence", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                    {errors["nriCountryResidence"] && (
                      <p className="error">
                        {errors["nriCountryResidence"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="passport"
                      id="passport"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.passport}
                      placeholder=" "
                      autoComplete="none"
                      maxLength={15}
                      minLength={4}
                      ref={register}
                    />

                    <Label md htmlFor="passport">
                      Passport No
                    </Label>
                    {errors["passport"] && (
                      <p className="error">{errors["passport"].message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="purposeOfStay"
                      id="purposeOfStay"
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.purposeOfStay}
                      placeholder=" "
                      onKeyDown={noCopyAndPaste}
                      autoComplete="none"
                      ref={register}
                      maxLength="25"
                    />

                    <Label md htmlFor="purposeOfStay">
                      Purpose of your stay Abroad
                    </Label>
                    {errors["purposeOfStay"] && (
                      <p className="error">{errors["purposeOfStay"].message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="employUniDetails"
                      id="employUniDetails"
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.employUniDetails}
                      placeholder=" "
                      onKeyDown={noCopyAndPaste}
                      autoComplete="none"
                      // maxLength={10}
                      ref={register}
                      maxLength="25"
                    />

                    <Label md htmlFor="employUniDetails">
                      Employer/University Details
                    </Label>
                    {errors["employUniDetails"] && (
                      <p className="error">
                        {errors["employUniDetails"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      type="text"
                      id="arrdate"
                      name="arrivingDate"
                      placeholder=" "
                      maxLength="10"
                      defaultValue={insuredFtcaDetails?.arrivingDate}
                      ref={attachRef}
                      onKeyDown={noCopyAndPaste}
                      error={errors.arrivingDate && "danger"}
                      onChange={handleChange}
                    />
                    <Label md htmlFor="arrdate">
                      Date of Arriving in India
                    </Label>

                    {errors.arrivingDate && (
                      <p className="error">{errors.arrivingDate.message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      type="text"
                      id="leavedate"
                      name="leavingDate"
                      placeholder=" "
                      maxLength="10"
                      defaultValue={insuredFtcaDetails?.leavingDate}
                      ref={attachRef}
                      onKeyDown={noCopyAndPaste}
                      error={errors.leavingDate && "danger"}
                      onChange={handleChange}
                    />
                    <Label md htmlFor="leavedate">
                      Date of leaving India After current visit
                    </Label>

                    {errors.leavingDate && (
                      <p className="error">{errors.leavingDate.message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="durationStayAbroad"
                      id="durationStayAbroad"
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.durationStayAbroad}
                      placeholder=" "
                      autoComplete="none"
                      onInput={numOnly}
                      onKeyDown={noCopyAndPaste}
                      maxLength={2}
                      ref={register}
                      numOnly
                    />

                    <Label md htmlFor="durationStayAbroad">
                      Duration of Stay Abroad(Years)
                    </Label>
                    {errors["durationStayAbroad"] && (
                      <p className="error">
                        {errors["durationStayAbroad"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Duration of Stay Abroad(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Duration of Stay Abroad(Months)"
                        propDropDown
                        items={[
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                        ]}
                        selectedItem={durationStayAbroadMonths}
                        onChange={e => setDurationStayAbroadMonths(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="intendedDurationStayAbroad"
                      id="intendedDurationStayAbroad"
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={
                        insuredFtcaDetails?.intendedDurationStayAbroad
                      }
                      onKeyDown={noCopyAndPaste}
                      placeholder=" "
                      autoComplete="none"
                      onInput={numOnly}
                      maxLength={2}
                      ref={register}
                      numOnly
                    />

                    <Label md htmlFor="intendedDurationStayAbroad">
                      Intended Duration of Stay(Years)
                    </Label>
                    {errors["intendedDurationStayAbroad"] && (
                      <p className="error">
                        {errors["intendedDurationStayAbroad"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Intended Duration of Stay(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Intended Duration of Stay(Months)"
                        propDropDown
                        items={[
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                        ]}
                        selectedItem={intendeddurationStayAbroadMonths}
                        onChange={e => setIntendedDurationStayAbroadMonths(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup style={{ textAlign: "left" }} xlg formPage>
                    <ToggleTile
                      checked={nriTravelDetails}
                      // className="toggle--sb"
                      disabled={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? true
                          : false
                      }
                      block
                      label="NRI Travel Details"
                      onChange={() => setNriTravelDetails(!nriTravelDetails)}
                    />
                  </FormGroup>
                  {nriTravelDetails && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        // error={errors[d.id] && "danger"}
                        type="text"
                        name="nriDetails"
                        id="nriDetails"
                        // style={{ textTransform: "pan" && "uppercase" }}
                        defaultValue={insuredFtcaDetails?.nriDetails}
                        placeholder=" "
                        autoComplete="none"
                        onKeyDown={noCopyAndPaste}
                        maxLength={25}
                        ref={register}
                        numOnly
                      />

                      <Label md htmlFor="nriDetails">
                        My travel Details
                      </Label>
                      {errors["nriDetails"] && (
                        <p className="error">{errors["nriDetails"].message}</p>
                      )}
                    </FormGroup>
                  )}
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Type Of Bank Account Held
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Type Of Bank Account Held"
                        propDropDown
                        items={["NRE", "NRO"]}
                        selectedItem={bankType}
                        onChange={e => setBankType(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                </>
              )}

              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  disabled={disable}
                  className={
                    disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                  }
                  css={`
                    @media screen and (max-width: 390px) {
                      font-size: 12px;
                    }
                  `}
                >
                  PROCEED TO PROPOSER OCCUPATION DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        ) : (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer FATCA Details
            </CardTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              tabIndex="0"
            >
              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">
                  I/we are a tax resident of ONLY India?
                </div>
                <RadioComponent
                  value={taxResident}
                  // className="toggle--sb"
                  name="taxResidentt"
                  disableRadio={
                    iciciSingleProduct?.quote_data?.nri === "NO" ? true : false
                  }
                  // label="I/we are a tax resident of ONLY India?"
                  updateChecked={() => setTaxResident(!taxResident)}
                />
              </FormGroup>

              {/* ------------------------ */}
              {/* <div style={{ width: "100%" }}>
                <h3 style={{ margin: 13, display: "inline-block" }}>
                  I/we are a tax resident of ONLY India?
                </h3>
                <FormGroup
                  md
                  style={{ display: "inline-block", float: "right" }}
                >
                  <RadioContainer xlg proposer>
                    <Radio
                      type="radio"
                      id="taxResidentNo"
                      name="taxResidentt"
                      value="no"
                      defaultChecked={false}
                      onChange={() => setTaxResident(false)}
                      ref={register}
                    />
                    <RadioLabel
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      // error={errors.gender && "danger"}
                      sm
                      proposer
                      htmlFor="taxResidentNo"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          No
                        </span>
                      </div>
                    </RadioLabel>
                    <Radio
                      type="radio"
                      id="taxResidentYes"
                      name="taxResidentt"
                      defaultChecked={
                        iciciSingleProduct?.quote_data?.nri === "YES"
                          ? false
                          : true
                      }
                      ref={register}
                      onChange={() => setTaxResident(true)}
                      value="yes"
                    />
                    <RadioLabel
                      // error={errors.policyFor && "danger"}
                      sm
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      proposer
                      htmlFor="taxResidentYes"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          Yes
                        </span>
                      </div>
                    </RadioLabel>

                    {errors.policyFor && (
                      <p className="errorG">{errors.policyFor.message}</p>
                    )}
                  </RadioContainer>
                </FormGroup>
              </div> */}
              {/* ------------------------ */}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">PAN Issuing Country</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="PAN Issuing Country"
                    propDropDown
                    items={PAN_ISSUING_COUNTRY}
                    selected
                    onChange={e => setPanIssuingCountry(e)}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {data.map(d => (
                <FormGroup proposalCard eligibilityPage key={d.id}>
                  <TextInputElg
                    proposal
                    readOnly={d.readOnly}
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    onChange={d.name === "pan" ? upperCase : " "}
                    defaultValue={details[d.name]}
                    onKeyDown={noCopyAndPaste}
                    placeholder={d.placeHolder}
                    // autoComplete={d.autoComplete}
                    autoComplete="none"
                    maxLength={d.maxLength}
                    ref={register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}
              {!taxResident && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        PAN Issuing Country 2
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="PAN Issuing Country 2"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={
                          insuredFtcaDetails?.panIssuingCountry2 ||
                          panIssuingCountry2
                        }
                        onChange={e => {
                          // setValue("panIssuingCountry2", e);
                          setPanIssuingCountry2(e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      // name="pan2"
                      // id="pan2"
                      style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails?.pan2}
                      value={tin}
                      onChange={evt => setTin(evt.target.value)}
                      onKeyDown={noCopyAndPaste}
                      placeholder=" "
                      autoComplete="none"
                      // ref={register}
                      maxLength={11}
                      // onInput={numOnly}
                    />

                    <Label md htmlFor="pan2">
                      TIN/PAN 2
                    </Label>
                    {errors["pan2"] && (
                      <p className="error">{errors["pan2"].message}</p>
                    )}
                  </FormGroup>
                </>
              )}

              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">My Birth Country is India?</div>
                {/* <span>NO</span>
                <span> */}
                <RadioComponent
                  disableRadio={
                    iciciSingleProduct?.quote_data?.nri === "NO" ? true : false
                  }
                  name="birthIndiaa"
                  value={birthCountry}
                  updateChecked={() => setBirthCountry(!birthCountry)}
                />
                {/* </span>
                <span>YES</span> */}
              </FormGroup>

              {/* ------------------------ */}
              {/* <div style={{ width: "100%" }}>
                <h3 style={{ margin: 13, display: "inline-block" }}>
                  My Birth Country is India?
                </h3>
                <FormGroup
                  md
                  style={{ display: "inline-block", float: "right" }}
                >
                  <RadioContainer xlg proposer>
                    <Radio
                      type="radio"
                      id="birthIndiaNo"
                      name="birthIndiaa"
                      value="no"
                      defaultChecked={false}
                      onChange={() => setBirthCountry(false)}
                      ref={register}
                    />
                    <RadioLabel
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      // error={errors.gender && "danger"}
                      sm
                      proposer
                      htmlFor="birthIndiaNo"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          No
                        </span>
                      </div>
                    </RadioLabel>
                    <Radio
                      type="radio"
                      id="birthIndiaYes"
                      name="birthIndiaa"
                      defaultChecked={
                        iciciSingleProduct?.quote_data?.nri === "YES"
                          ? false
                          : true
                      }
                      ref={register}
                      onChange={() => setBirthCountry(true)}
                      value="yes"
                    />
                    <RadioLabel
                      // error={errors.policyFor && "danger"}
                      sm
                      style={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? {
                              pointerEvents: "none",
                              textAlign: "unset",
                            }
                          : { pointerEvents: "" }
                      }
                      proposer
                      htmlFor="birthIndiaYes"
                    >
                      <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                        <RadioButton
                          className="radioBtnHover"
                          marginsm
                        ></RadioButton>
                        <span className="radiolabel__text radiolabel__formbuilder-text">
                          Yes
                        </span>
                      </div>
                    </RadioLabel>
                    {errors.policyFor && (
                      <p className="errorG">{errors.policyFor.message}</p>
                    )}
                  </RadioContainer>
                </FormGroup>
              </div> */}
              {/* ------------------------ */}
              {!birthCountry && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">Country Of Birth</div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country Of Birth"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.countryOfBirth}
                        onChange={e => {
                          setValue("countryOfBirth", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="birthPlace"
                      id="birthPlace"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails[d.name]}
                      placeholder=" "
                      autoComplete="none"
                      maxLength={30}
                      ref={register}
                    />

                    <Label md htmlFor="birthPlace">
                      Birth Place
                    </Label>
                    {errors["birthPlace"] && (
                      <p className="error">{errors["birthPlace"].message}</p>
                    )}
                  </FormGroup>
                </>
              )}
              <div className="d-flex justify-content-center">
                <div className="form-group">
                  <div className="form-check d-flex align-items-center m-4">
                    <input
                      className="form-check-input"
                      value={agreeTerm}
                      type="checkbox"
                      id="gridCheck"
                      onChange={e => setAgreeTerm(e.target.checked)}
                    />
                    <div className="form-check-label inffo">
                      This information is required to be collected as per The
                      Income Tax Act, 1961 to comply with FATCA and CRS
                      regulations.We will continue to report the above
                      information 'As is' till we receive any change request
                      from you.
                    </div>
                  </div>
                </div>
              </div>
              {iciciSingleProduct?.quote_data?.nri === "YES" && (
                <FormGroup
                  xlg
                  className="form-group--1 mhealth"
                  style={{ textAlign: "left" }}
                >
                  <div className="healthQuestion">
                    Residing in India but intend to travel to{" "}
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => setShowPopup(true)}
                    >
                      Non Acceptable Countries
                    </span>
                    ?
                  </div>
                  {/* <div> */}
                  {/* <span>NO</span> */}
                  {/* <span> */}
                  <RadioComponent
                    value={travelNonAccCont}
                    name="travelNonAccCont"
                    updateChecked={() => setTravelNonAccCont(!travelNonAccCont)}
                  />
                  {/* </span> */}
                  {/* <span>YES</span> */}
                  {/* </div> */}
                </FormGroup>
              )}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Nationality
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Nationality"
                    propDropDown
                    items={
                      !taxResident || !birthCountry
                        ? NATIONALITY_NRI
                        : NATIONALITY
                    }
                    selected
                    onChange={e => setNationality(e)}
                    selectedItem={nationality}
                    currentSelected={nationality}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Resident Status
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Resident Status"
                    propDropDown
                    items={
                      nationality === "Indian"
                        ? RESIDENT_STATUS
                        : RESIDENT_STATUS_NRI
                    }
                    disabled={taxResident && birthCountry ? true : false}
                    selectedItem={residentStatus}
                    onChange={e => setResidentStatus(e)}
                    currentSelected={residentStatus}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {(residentStatus === "PIO/OCI" ||
                residentStatus === "Foreign National" ||
                residentStatus === "Non Resident Indian") && (
                <>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Nationality
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country of Nationality"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.nriNationality}
                        onChange={e => {
                          setValue("nriNationality", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                    {errors["nriNationality"] && (
                      <p className="error">
                        {errors["nriNationality"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Residence
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Country of Residence"
                        propDropDown
                        searchable={true}
                        items={COUNTRIES}
                        selectedItem={insuredFtcaDetails?.nriCountryResidence}
                        onChange={e => {
                          setValue("nriCountryResidence", e);
                          trigger();
                        }}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                    {errors["nriCountryResidence"] && (
                      <p className="error">
                        {errors["nriCountryResidence"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="passport"
                      id="passport"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.passport}
                      placeholder=" "
                      autoComplete="none"
                      maxLength={15}
                      minLength={4}
                      ref={register}
                    />

                    <Label md htmlFor="passport">
                      Passport No
                    </Label>
                    {errors["passport"] && (
                      <p className="error">{errors["passport"].message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="purposeOfStay"
                      id="purposeOfStay"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails[d.name]}
                      placeholder=" "
                      autoComplete="none"
                      // maxLength={10}
                      ref={register}
                      maxLength="25"
                    />

                    <Label md htmlFor="purposeOfStay">
                      Purpose of your stay Abroad
                    </Label>
                    {errors["purposeOfStay"] && (
                      <p className="error">{errors["purposeOfStay"].message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="employUniDetails"
                      id="employUniDetails"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      defaultValue={insuredFtcaDetails?.employUniDetails}
                      placeholder=" "
                      autoComplete="none"
                      // maxLength={10}
                      ref={register}
                      maxLength="25"
                    />

                    <Label md htmlFor="employUniDetails">
                      Employer/University Details
                    </Label>
                    {errors["employUniDetails"] && (
                      <p className="error">
                        {errors["employUniDetails"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      type="text"
                      id="arrdate"
                      name="arrivingDate"
                      placeholder=" "
                      onKeyDown={noCopyAndPaste}
                      maxLength="10"
                      // defaultValue={nomineeDetails.nomDob}
                      ref={attachRef}
                      error={errors.dob && "danger"}
                      onChange={handleChange}
                    />
                    <Label md htmlFor="arrdate">
                      Date of Arriving in India
                    </Label>

                    {errors.arrivingDate && (
                      <p className="error">{errors.arrivingDate.message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      type="text"
                      id="leavedate"
                      name="leavingDate"
                      placeholder=" "
                      onKeyDown={noCopyAndPaste}
                      maxLength="10"
                      // defaultValue={nomineeDetails.nomDob}
                      ref={attachRef}
                      error={errors.dob && "danger"}
                      onChange={handleChange}
                    />
                    <Label md htmlFor="leavedate">
                      Date of leaving India After current visit
                    </Label>

                    {errors.leavingDate && (
                      <p className="error">{errors.leavingDate.message}</p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="durationStayAbroad"
                      id="durationStayAbroad"
                      onKeyDown={noCopyAndPaste}
                      // style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails[d.name]}
                      placeholder=" "
                      autoComplete="none"
                      onInput={numOnly}
                      maxLength={2}
                      ref={register}
                      numOnly
                    />

                    <Label md htmlFor="durationStayAbroad">
                      Duration of Stay Abroad(Years)
                    </Label>
                    {errors["durationStayAbroad"] && (
                      <p className="error">
                        {errors["durationStayAbroad"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Duration of Stay Abroad(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Duration of Stay Abroad(Months)"
                        propDropDown
                        items={[
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                        ]}
                        selectedItem={durationStayAbroadMonths}
                        onChange={e => setDurationStayAbroadMonths(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    <TextInputElg
                      proposal
                      // error={errors[d.id] && "danger"}
                      type="text"
                      name="intendedDurationStayAbroad"
                      id="intendedDurationStayAbroad"
                      // style={{ textTransform: "pan" && "uppercase" }}
                      // defaultValue={insuredFtcaDetails[d.name]}
                      placeholder=" "
                      autoComplete="none"
                      onKeyDown={noCopyAndPaste}
                      onInput={numOnly}
                      maxLength={2}
                      ref={register}
                      numOnly
                    />

                    <Label md htmlFor="intendedDurationStayAbroad">
                      Intended Duration of Stay(Years)
                    </Label>
                    {errors["intendedDurationStayAbroad"] && (
                      <p className="error">
                        {errors["intendedDurationStayAbroad"].message}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Intended Duration of Stay(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Intended Duration of Stay(Months)"
                        propDropDown
                        items={[
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                          "11",
                        ]}
                        selectedItem={intendeddurationStayAbroadMonths}
                        onChange={e => setIntendedDurationStayAbroadMonths(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                  <FormGroup style={{ textAlign: "left" }} xlg formPage>
                    <ToggleTile
                      checked={nriTravelDetails}
                      // className="toggle--sb"
                      disabled={
                        iciciSingleProduct?.quote_data?.nri === "NO"
                          ? true
                          : false
                      }
                      block
                      label="NRI Travel Details"
                      onChange={() => setNriTravelDetails(!nriTravelDetails)}
                    />
                  </FormGroup>
                  {nriTravelDetails && (
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        // error={errors[d.id] && "danger"}
                        type="text"
                        name="nriDetails"
                        id="nriDetails"
                        // style={{ textTransform: "pan" && "uppercase" }}
                        // defaultValue={insuredFtcaDetails[d.name]}
                        placeholder=" "
                        autoComplete="none"
                        onKeyDown={noCopyAndPaste}
                        maxLength={25}
                        ref={register}
                        numOnly
                      />

                      <Label md htmlFor="nriDetails">
                        My travel Details
                      </Label>
                      {errors["nriDetails"] && (
                        <p className="error">{errors["nriDetails"].message}</p>
                      )}
                    </FormGroup>
                  )}
                  <FormGroup proposalCard eligibilityPage>
                    {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Type Of Bank Account Held
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        dropDownTitle="Type Of Bank Account Held"
                        propDropDown
                        items={["NRE", "NRO"]}
                        selectedItem={bankType}
                        onChange={e => setBankType(e)}
                      />
                    </CustomDropDownFont>
                    {/* </div>
                    </div> */}
                  </FormGroup>
                </>
              )}

              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  disabled={disable}
                  className={
                    disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                  }
                  css={`
                    @media screen and (max-width: 390px) {
                      font-size: 12px;
                    }
                  `}
                >
                  PROCEED TO PROPOSER OCCUPATION DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        )}

        <NonAcceptCountriesPopup show={showPopup} onClose={onClosePopup} />
      </>
    );
  }
};

export default InsuredFtcaForm;
