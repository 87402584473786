import React from "react";

export default function KnowYourInsurer({
  insurerDescription,
  existenceInMarket,
  solvencyRatio,
  numberOfBranches,
  clamSettlementRatio,
  company_alias,
}) {
  return (
    <div className="kyi-wrap">
      <table id="kyi">
        <tbody>
          <tr className="detailDataWrap">
            <th className="detailTitle">INSURER’S DESCRIPTION</th>
            <td className="detailDivider">:</td>
            <td className="detailData">{insurerDescription}</td>
          </tr>
          <tr className="detailDataWrap">
            <th className="detailTitle">EXISTENCE IN MARKET</th>
            <td className="detailDivider">:</td>
            <td className="detailData">Since {existenceInMarket}</td>
          </tr>
          <tr className="detailDataWrap">
            <th className="detailTitle">SOLVENCY RATIO AS ON DATE</th>
            <td className="detailDivider">:</td>
            <td className="detailData">{solvencyRatio}</td>
          </tr>
          <tr className="detailDataWrap">
            <th className="detailTitle">CLAIM SETTLEMENT RATIO AS ON DATE</th>
            <td className="detailDivider">:</td>
            <td className="detailData">{clamSettlementRatio}</td>
          </tr>
          {company_alias !== "bandhan_life" && (
            <tr className="detailDataWrap">
              <th className="detailTitle">NUMBER OF BRANCHES ACROSS INDIA</th>
              <td className="detailDivider">:</td>
              <td className="detailData">{numberOfBranches} branches</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
