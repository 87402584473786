import styled from "styled-components";
export const Form = styled.form`
  background-color: #ffffff;
  min-width: 300px;
  margin-bottom: 120px;
  margin-top: -56px;
  float: left;
  width: 100%;
  font-family: "Inter-Regular";
  @media only screen and (max-width: 767px) {
    margin-top: 0;
  }
`;
export const LaxmiWrap = styled.div`
  position: relative;
  text-align: center;
  width: 116px;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    margin-top: -38px;
  }
`;
export const CmnTerms = styled.div`
  position: absolute;
  right: 28px;
  bottom: 70px;
  font-family: "Inter-Regular";
  font-size: 11px;
  line-height: 24px;
  color: #808080;
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    right: auto;
    left: calc(50% - 250px);
  }
  @media only screen and (max-width: 767px) {
    right: auto;
    left: 15px;
    font-size: 11px;
    line-height: 14px;
    bottom: 172px;
    width: 300px;
  }
`;
export const CmnTopWrap = styled.div`
  position: absolute;
  width: 268px;
  right: 0;
  top: 96px;
  z-index: 0;

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    position: relative;
    width: 714px;
    right: auto;
    top: auto;
    margin: -36px 0 40px 8px;
    height: 48px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1240px) {
    width: 962px;
    margin: -8px 0px 40px 8px;
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    right: auto;
    top: auto;
    margin: 0 0 40px 0;
    height: 160px;
  }
`;
export const CmnBlue = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 140px;
  width: 140px;
  background-color: #152981;
  border-radius: 50%;
  font-family: "WorkSans-SemiBold";
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  padding: 20px 0;
  & span {
    display: block;
    text-align: center;
    @media only screen and (max-width: 1240px) {
      display: contents;
      font-size: 11px;
      line-height: 16px;
    }
    @media only screen and (max-width: 767px) {
      line-height: 14px;
    }
  }
  @media only screen and (max-width: 1240px) {
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: 0;
      border-width: 24px;
      border-style: solid;
      border-color: #152981;
      z-index: -1;
    }

    &:before {
      left: -16px;
      border-right-width: 6px;
      border-left-width: 12px;
      border-left-color: transparent;
    }
    &:after {
      right: -16px;
      border-left-width: 6px;
      border-right-width: 12px;
      border-right-color: transparent;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    float: left;
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    width: 198px;
    text-align: center;
    border-radius: 0;
    padding: 8px 0;
    margin-right: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    width: 242px;
    &:before {
      left: -16px;
      border-right-width: 6px;
      border-left-width: 12px;
      border-left-color: transparent;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1240px) {
    width: 320px;
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    float: none;
    text-align: center;
    width: calc(100% - 30px);
    border-radius: 0;
    padding: 9px 0;
    margin: 0 auto 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;
export const CmnBottomWrap = styled.div`
  position: absolute;
  top: 144px;
  left: 16px;
  width: 246px;
  background-color: #f4f6ff;
  & ul,
  & li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  & ul {
    margin: 0 0 16px 0;
    @media only screen and (min-width: 768px) and (max-width: 1240px) {
      float: left;
      width: 100%;
      padding: 0 35px 24px;
      margin: 0;
    }
    @media only screen and (max-width: 767px) {
      float: left;
      width: 100%;
      margin: 0 0 24px;
    }
  }
  & li {
    margin: 0 22px 9px 14px;
    font-family: "Inter-Medium";
    font-size: 13px;
    line-height: 17px;
    color: #080808;
    & svg {
      margin-right: 6px;
      width: 10px;
      height: 10px;
      vertical-align: baseline;
    }
    &::after {
      float: left;
      margin-left: -14px;
      color: rgb(51, 51, 51);
    }
    @media only screen and (min-width: 768px) and (max-width: 1240px) {
      float: left;
      width: 50%;
      padding: 0 24px 10px 14px;
      margin: 0;

      &:first-child {
        padding: 0 24px 6px 14px;
      }
      &:nth-child(2) {
        padding: 0 24px 12px 14px;
      }
    }
    @media only screen and (max-width: 767px) {
      float: left;
      width: 100%;
      padding: 0 24px 12px 14px;
      /* margin: 0; */
      & span {
        font-size: 13px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    position: relative;
    display: table;
    width: 595px;
    left: auto;
    top: auto;
    margin: 26px auto 0;
    padding-top: 22px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1240px) {
    margin: 26px auto 64px;
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    display: table;
    width: 100%;
    left: auto;
    top: auto;
    margin: 20px auto 20px;
    padding: 22px 15px 0;
  }
`;
export const CmnRibbonBanner = styled.div`
  display: table;
  width: 255px;
  font-family: "WorkSans-SemiBold";

  font-size: 13px;
  line-height: 12px;
  padding: 10px 6px 10px 16px;
  position: relative;
  background: #152981;
  color: #fff;
  margin: 0 0 16px 0;
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    width: 255px;
    margin: 0 auto 21px auto;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    width: 255px;
    padding: 10px 6px 10px 16px;
    margin: 0 auto 21px auto;
    text-align: center;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    border-width: 16px;
    border-style: solid;
    border-color: #152981;
  }
  &:after {
    right: -16px;
    border-right-color: transparent;
  }
  &:before {
    display: none;
    left: -16px;
    border-right-width: 12px;
    border-left-width: 6px;
    border-left-color: transparent;
  }
  @media only screen and (max-width: 1240px) {
    &:before,
    &:after {
      border-width: 16px;
    }
  }
  @media only screen and (max-width: 1240px) {
    &::before {
      display: block;
    }
  }
`;
export const CmnBottomTitle = styled.div`
  font-family: "Inter-SemiBold";
  font-size: 15px;
  line-height: 12px;
  color: #333;
  margin: 0 0 12px 11px;
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    margin: 0 0 10px 35px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 0 12px 0px;
  }
`;
export const BlueSub = styled.span`
  font-family: "WorkSans-Regular";
  font-size: 11px;
  line-height: 14px;
  margin-top: ${props => props.first && "7px"};
`;
export const CmnYellow = styled.div`
  position: absolute;
  left: 132px;
  top: 70px;
  height: 106px;
  width: 106px;
  background-color: #f2db0c;
  border-radius: 50%;
  font-family: "WorkSans-SemiBold";
  font-size: 9px;
  line-height: 12px;
  color: #363636;
  padding: 20px 8px;
  & span {
    display: block;
    text-align: center;
    @media only screen and (max-width: 1240px) {
      display: contents;
      font-size: 11px;
      line-height: 16px;
    }
  }
  @media only screen and (max-width: 1240px) {
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: 0;
      border-width: 24px;
      border-style: solid;
      border-color: #f2db0c;
      z-index: -1;
    }

    &:before {
      left: -16px;
      border-right-width: 6px;
      border-left-width: 12px;
      border-left-color: transparent;
    }
    &:after {
      right: -16px;
      border-left-width: 6px;
      border-right-width: 12px;
      border-right-color: transparent;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    float: left;
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    width: 198px;
    text-align: center;
    border-radius: 0;
    padding: 8px 0;
    margin-right: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    width: 266px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1240px) {
    width: 370px;
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    float: none;
    text-align: center;
    width: calc(100% - 30px);
    border-radius: 0;
    padding: 9px 0;
    margin: 0 auto 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;
export const CmnSkyBlue = styled.div`
  position: absolute;
  top: 139px;
  left: 64px;
  height: 82px;
  width: 82px;
  background-color: #19c2f2;
  border-radius: 50%;
  font-family: "WorkSans-SemiBold";
  font-size: 10px;
  line-height: 11px;
  color: #000;
  padding: 15px 13px;
  & span {
    display: block;
    text-align: center;
    &:first-child {
      margin-bottom: 4px;
    }
    @media only screen and (max-width: 1240px) {
      display: contents;
      font-size: 11px;
      line-height: 16px;
    }
    @media only screen and (max-width: 767px) {
      line-height: 14px;
    }
  }
  @media only screen and (max-width: 1240px) {
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: 0;
      border-width: 24px;
      border-style: solid;
      border-color: #19c2f2;
      z-index: -1;
    }

    &:before {
      left: -16px;
      border-right-width: 6px;
      border-left-width: 12px;
      border-left-color: transparent;
    }
    &:after {
      right: -16px;
      border-left-width: 6px;
      border-right-width: 12px;
      border-right-color: transparent;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    float: left;
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    width: 198px;
    text-align: center;
    border-radius: 0;
    padding: 8px 0;
    margin-right: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    width: 132px;
    margin-right: 0;
  }
  @media only screen and (min-width: 992px) and (max-width: 1240px) {
    width: 176px;
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    left: auto;
    top: auto;
    height: 48px;
    float: none;
    text-align: center;
    width: calc(100% - 30px);
    border-radius: 0;
    padding: 9px 0;
    margin: 0 auto 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;
export const CmnTitle = styled.div`
  position: absolute;
  top: 246px;
  left: 20px;
  font-family: "WorkSans-Bold";
  font-size: 24px;
  line-height: 28px;
  color: #152981;
  text-align: center;
  & span {
    display: inline-block;
  }
  @media only screen and (min-width: 768px) and (max-width: 1240px) {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
export const CmnImgWrap = styled.div`
  background: #f4f6ff;
  overflow: hidden;
  width: 136%;
  border-radius: 149% 9% 28% 75%;
  height: 610px;
  position: relative;
  z-index: -1;
  margin-top: 20px;
  @media only screen and (max-width: 1240px) {
    display: none;
  }
`;
export const CmnFamilyImg = styled.img`
  position: absolute;
  top: 300px;
  left: 0;
  width: 258px;
  height: auto;
  @media only screen and (max-width: 1240px) {
    display: none;
  }
`;
export const LaxmiImg = styled.img`
  width: 96px;
  border: solid 0.5px #e3e4e8;
  border-radius: 50%;
  box-shadow: none;
  @media only screen and (max-width: 767px) {
    width: 72px;
  }
`;
export const MainHeader = styled.div`
  width: 100%;
  font-family: "Inter-Medium";
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  color: #333333;
  line-height: 28px;
  margin-top: 16px;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    margin-top: 41px;
    font-size: 28px;
    font-weight: 500;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 12px;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: -8px;
  }
`;
export const SubHeader = styled.div`
  font-family: "Inter-Light";
  color: #333333;
  font-size: 20px;
  font-weight: normal;
  margin: 8px 0 24px 0;
  padding: 0 30px;
  text-align: center;
  line-height: 24px;
  & span {
    display: block;
    line-height: 30px;
    @media only screen and (max-width: 767px) {
      line-height: 24px;
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
    margin: 8px 0 48px 0;
  }
  @media only screen and (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
    padding: 0;
    width: 260px;
    margin: 12px auto 8px;
    width: ${props => props.fullWidth && "100%"};
  }
`;
export const CustomWrap = styled.div`
  width: ${props => (props.full ? "100%" : "666px")};
  margin: 0 auto;
  display: table;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const CustomInput = styled.div`
  &:first-child {
    margin-right: 24px;

    @media only screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 12px;
  }
  width: ${props => (props.full ? "100%" : "320px")};
  float: left;
  position: ${props => props.radio && "relative"};
  margin-bottom: ${props => (props.full ? "16px" : "24px")};
`;
export const RadioGroup = styled.div`
  margin: 0;
`;
export const Row = styled.div`
  margin: 0 -7px;
  @media only screen and (max-width: 767px) {
    margin: 0;
  }
  &:before,
  &:after {
    display: table;
    content: "";
  }
`;
export const FormGroup = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  box-shadow: none;
  position: relative;
  height: ${props => props.height && "60px"};
  & .custom-drop-down__head {
    height: 60px;
    font-family: "Inter-Light";
    padding: 12px 20px;
    @media (max-width: 767px) {
      height: 48px;
    }
  }
  & .drop-down-items__item,
  .drop-down-items__item--selected {
    padding: 14px 14px;
    @media (max-width: 767px) {
      padding: 10px 14px;
    }
  }
  & .custom-drop-down__selected-text {
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
  & .custom-drop-down__head {
    @media (max-width: 767px) {
      padding: 9px 8px 5px 15px;
      width: 100%;
    }
  }
  & .custom-drop-down__arrow {
    width: 16px;
    height: 16px;
    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 16px;
    }
  }
`;
export const TextInput = styled.input`
  z-index: 1;
  height: 60px;
  margin-bottom: ${props => (props.noBottom ? "0px" : "4px")};
  border: ${props =>
    props.error === "danger" ? "solid 2px #d43d3d " : "solid 1px #e3e4e8"};

  font-size: 18px;
  font-family: "Inter-Light";
  color: #333;
  border-radius: 4px;
  box-shadow: none;
  word-spacing: ${props => props.dob && "-3px"};
  text-transform: ${props => props.capitalise && "capitalize"};
  background-color: #fff !important;
  &:focus,
  &:valid {
    padding: 20px 8px 5px 20px;
    & + label {
      font-family: "Inter-Medium";
      color: #107591;
      font-size: 12px;
      transform: translate3d(0, -10px, 0);
      & > span {
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 20px 8px 5px 15px;
      height: 48px;
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 20px 8px 5px 15px;
    height: 48px;
    font-size: 14px;
  }
  &:active,
  &:focus {
    border-radius: 4px;
    border-color: ${props =>
      props.error === "danger" ? "#d43d3d" : "#107591"};
    border-width: 2px;
    box-shadow: none;
    background-color: rgba(246, 246, 246, 0.2);
  }
`;
export const ErrorMessage = styled.span`
  position: relative;
  margin-top: 4px;
  display: block;
  color: #d43d3d;
  font-size: 14px;
  @media only screen and (max-width: 767px) {
    position: relative;
    padding-left: 0;
    font-size: 12px;
  }
`;
export const Label = styled.label`
  z-index: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 18px;
  left: 20px;
  font-weight: 500;
  color: #333;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  font-family: "Inter-Light";
  pointer-events: none;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  & span {
    font-size: 14px;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    top: 14px;
    left: 16px;
    font-size: 14px;
    width: 94%;
  }
`;
export const Container = styled.div`
  &:before,
  &:after {
    display: table;
    content: " ";
    clear: both;
  }
  line-height: 1.42857143;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: ${props => props.padTop && "32px"};
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    width: 992px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media only screen and (max-width: 767px) {
    padding: ${props => props.firstPage && "0"};
    margin-top: ${props => props.firstPage && "20px"};
  }
`;

export const AgeWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 11px;
  background-color: #f1f2f6;
  font-family: "Inter-Medium";
  font-size: 16px;
  border-radius: 4px;
  width: 83px;
  height: 35px;
  color: #808080 !important;
  text-align: center;
  padding: 6px 0;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    font-size: 14px;
    width: 75px;
    height: 36px;
    top: 12px;
    padding: 7px 0;
    text-align: center;
    right: 17px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    width: 59px;
    height: 25px;
    padding: 3px 0;
    top: 12px;
  }
`;
export const GenderFieldCust = styled.div`
  width: 155px;
  display: inline-block;
  margin: 0 6px;
  text-align: left;
  outline: none;
  @media only screen and (max-width: 767px) {
    width: 48%;
    margin: 0;
  }
  &:first-child {
    @media only screen and (max-width: 767px) {
      margin-right: 4%;
    }
  }
  &:hover > label {
    background-color: #107591;
    fill: #fff;
    & > span:first-child {
      color: white;
    }
  }
  &:hover span:last-child {
    background-color: white;
    border: solid 2px #107591;
  }
`;
export const RadioButton = styled.label`
  height: 60px;
  font-size: 18px;
  font-family: "Inter-Light";
  color: #333;

  display: block;
  position: relative;
  padding-top: 17px;
  padding-left: 44px;
  margin-bottom: 0;
  border: solid 1px #e3e4e8;

  cursor: pointer;
  border-radius: 4px;
  font-weight: 100;
  box-shadow: none;
  user-select: none;
  &:focus {
    border: solid 1px #107591;
    box-shadow: none;
  }
  &,
  &:focus {
    border: ${props => props.error === "danger" && "solid 2px #d43d3d"};
  }
  @media only screen and (max-width: 767px) {
    height: 48px;
    font-size: 14px;
    border: solid 1px #e3e4e8;
    padding-top: 13px;
    &,
    &:focus {
      border: ${props => props.error === "danger" && "solid 2px #d43d3d"};
    }
  }
`;
export const RadioText = styled.span`
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 4px 0 0;
  &:checked + label {
    background-color: #107591 !important;
    border-color: #107591 !important;
    & span:first-child {
      color: white;
    }
    & span:last-child {
      background-color: #ffffff;
      border: solid 2px #107591;
      &:after {
        display: block;
      }
    }
  }
`;
export const CheckMark = styled.span`
  position: absolute;
  top: 18px;
  left: 15px;
  height: 22px;
  width: 22px;
  border: solid 2px #e3e4e8;
  border-radius: 50%;
  bottom: 22px;
  &::after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #107591;
    display: none;
    content: "";
    position: absolute;
    @media only screen and (max-width: 767px) {
      top: 3px;
      left: 3px;
    }
  }
  @media only screen and (max-width: 767px) {
    top: 13px;
    width: 20px;
    height: 20px;
  }
`;

export const ProceedWrap = styled.div`
  width: 100%;
  float: left;
  text-align: center;
  margin-top: ${props => (props.margin ? "9px" : "8px")};
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 22px;
  }
`;
export const ProceedButton = styled.button`
  position: relative;
  width: ${props => (props.full ? "100%" : "320px")};
  height: ${props => (props.full ? "60px" : "54px")};
  border-radius: 4px;
  background-color: #f2581b;
  color: #ffffff;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  outline: none !important;
  border: none;
  margin: 0 auto;
  text-transform: uppercase;
  & div {
    width: ${props => (props.full || props.width ? "136px" : "104px")};
    position: relative;
    margin: 0 auto;
    height: 28px;
  }
  & span {
    float: left;
  }
  & svg {
    width: 32px;
    transition: 0.5s;
    position: absolute;
    right: 0;
    top: -2px;
  }
  &:hover {
    background-color: #ff4d07;
    & svg {
      right: -8px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 345px;
    height: 60px;
    width: ${props => props.mobFull && "100%"};
  }
  @media only screen and (max-width: 767px) {
    & span {
      font-size: 14px;
    }
  }
`;
export const TermCond = styled.div`
  font-size: 12px;
  margin-top: 12px;
  font-family: "Inter-Regular";
  color: #808080;
  & a {
    color: #107591;
    text-decoration: underline;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    width: 246px;
    margin: 12px auto;
  }
`;
export const Tooltip = styled.div`
  position: absolute;
  width: 24px;
  height: 23.5px;
  top: 18px;
  right: 12px;
  & img {
    position: absolute;
  }
  @media only screen and (max-width: 767px) {
    top: 13px;
  }
`;
