import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomDropDownTwo } from "../../../../components";
import "./sub-filter-right.scss";
import { setQuotesOnCompare } from "../../../QuotePage/quotePageSlice";
import {
  setMorePlans,
  setNomineeGets,
  setPaymentTermOption,
  setPayPremium,
  setSortBy,
} from "../../quoteFilterSlice";
import FilterMenu from "../FilterMenu/FilterMenu";
import { useWindowResize } from "../../../../custom-hooks";

function SubFilterRight() {
  const quotesOnCompare = useSelector(({ quote }) => quote.quotesOnCompare);
  const paymentTerm = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.paymentTerm.option,
  );

  const { payPremium, sortBy } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );

  const dispatch = useDispatch();

  // const { isQuotesLoading } = useSelector(({ quote }) => quote);

  const { width } = useWindowResize();

  const { quotes } = useSelector(({ quote }) => quote);

  const filters = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const { corporateJourney } = useSelector(state => state.quote);

  let flag = false;
  // if (customerDetails && customerDetails.work_email) {
  //   let temp = customerDetails.work_email;
  //   let domain = temp.split('@')[1];
  //   if (domain === "gameskraft.com") {
  //     flag = true;
  //   }
  // }
  if (corporateJourney.is_gameskraft === "active") {
    flag = true;
  }
  const isDefaultFilters =
    filters.nomineeGets === "Lumpsum" &&
    payPremium === "Monthly" &&
    sortBy === "Price (low to high)" &&
    paymentTerm === "Regular Pay" &&
    filters.morePlans === "Choose your plans";

  const resetFilter = () => {
    dispatch(setPayPremium("Monthly"));
    dispatch(setNomineeGets({ value: "Lumpsum" }));
    dispatch(setMorePlans({ value: "Choose your plans" }));
    dispatch(setPaymentTermOption({ value: "Regular Pay" }));
    dispatch(setSortBy("Price (low to high)"));
  };
  return (
    <div
      className="sub-filter-right"
      // style={{ pointerEvents: isQuotesLoading ? "none" : "all" }}
    >
      <div className="sub-filter-right__buttons">
        {!isDefaultFilters && (
          <div
            className="reset_filters"
            onClick={() => {
              resetFilter();
            }}
          >
            Reset Filters
          </div>
        )}
        {quotes.length > 1 && !flag ? (
          <button
            className={`${
              quotesOnCompare ? "btn--highlighted" : "compare-plans-btn"
            }`}
            onClick={() => dispatch(setQuotesOnCompare())}
          >
            {quotesOnCompare ? "View All" : "Compare Plans"}
          </button>
        ) : (
          <></>
        )}
        {paymentTerm !== "Single Pay" &&
          (width <= 767 ? (
            <div className="subFilterRightWrap subFilterRightWrap1">
              <FilterMenu
                label="Pay Premium:"
                items={["Monthly", "Quarterly", "Half Yearly", "Yearly"]}
                onChange={value => dispatch(setPayPremium(value))}
                selected={payPremium}
              />
            </div>
          ) : (
            <CustomDropDownTwo
              // disabled={isQuotesLoading}
              label="Pay premium"
              items={["Monthly", "Quarterly", "Half Yearly", "Yearly"]}
              key={Math.random() * 1000 + 6}
              selectedItem={payPremium}
              onChange={value => dispatch(setPayPremium(value))}
            />
          ))}
        {width <= 767 ? (
          <div className="subFilterRightWrap subFilterRightWrap2">
            <FilterMenu
              label="Sort by:"
              items={[
                "Price (low to high)",
                "Price (high to low)",
                "Claims Settlement",
              ]}
              onChange={value => dispatch(setSortBy(value))}
              selectedItem={sortBy}
            />
          </div>
        ) : (
          <CustomDropDownTwo
            // disabled={isQuotesLoading}
            label="Sort by"
            items={[
              "Price (low to high)",
              "Price (high to low)",
              "Claims Settlement",
            ]}
            key={Math.random() * 1000 + 6}
            selectedItem={sortBy}
            onChange={value => dispatch(setSortBy(value))}
          />
        )}
      </div>
      <div className="sub-filter-right__text">
        All prices are inclusive of <span className="gst-text">GST</span>
        <div className="dashed-underline"></div>
      </div>
    </div>
  );
}

export default SubFilterRight;
