import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
import { setSwitchToMedical } from "../../quotePageSlice";
const SwitchToMedical = () => {
  const { quotes, switchToMedical } = useSelector(state => state.quote);
  const dispatch = useDispatch();
  const telemedicalPremium = quotes.find(
    item =>
      item.medicalCondition === "Video Medical" &&
      item.company_alias === "bajaj_life",
  )?.premium_amount;
  const physicalMedicalPremium = quotes.find(
    item =>
      item.medicalCondition === "Physical Medical" &&
      item.company_alias === "bajaj_life",
  )?.premium_amount;
  return (
    <div
      css={`
        position: absolute;
        background-color: #107591;
        padding: 3px 9px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
        color: #fff;
        right: 5px;
        top: -6px;
        font-size: 10px;
        border-radius: 12px;
        cursor: pointer;
      `}
      onClick={() => dispatch(setSwitchToMedical(!switchToMedical))}
    >
      {switchToMedical
        ? `Switch to Physical Medical with ₹ ${Number(
            physicalMedicalPremium,
          ).toLocaleString("en-IN")} `
        : `Switch To Video medical with ₹ ${Number(
            telemedicalPremium,
          ).toLocaleString("en-IN")} `}{" "}
      <i class="fa fa-angle-right"></i>
    </div>
  );
};

export default SwitchToMedical;
