import React, { useEffect, useState } from "react";
import MobileKnowYOurPLanContent from "./MobileKnowYourPlanContent";

const MobileKnowYourInsurer = ({
  insurerDescription,
  existenceInMarket,
  solvencyRatio,
  numberOfBranches,
  clamSettlementRatio,
  onClick,
  tab,
  company_alias,
}) => {
  const [expandIcon, setExpandIcon] = useState(tab === 1 ? "minus" : "plus");
  const handleClick = () => {
    const openTabs = document.querySelectorAll(
      ".mobile-Knowmore .collapse.show",
    );
    openTabs.forEach(tab => tab.classList.remove("show"));
    setExpandIcon(expandIcon === "plus" ? "minus" : "plus");
    onClick();
  };

  useEffect(() => {
    if (tab === 1) setExpandIcon("minus");
    else setExpandIcon("plus");
  }, [tab]);

  return (
    <div>
      <button
        type="button"
        className={`knowyourplan__header ${expandIcon}`}
        onClick={handleClick}
        data-toggle="collapse"
        data-target="#knowyourInsurer__content"
      >
        Know Your Insurer
      </button>
      <div
        id="knowyourInsurer__content"
        className="knowyourplan__content collapse"
      >
        <div>
          <div className="tab__Header">INSURER'S DESCRIPTION:</div>
          <div className="tab__answer">{insurerDescription}</div>
        </div>
        <div>
          <div className="tab__Header">EXISTENCE IN MARKET:</div>
          <div className="tab__answer">{existenceInMarket}</div>
        </div>
        <div>
          <div className="tab__Header">SOLVENCY RATIO AS ON DATE:</div>
          <div className="tab__answer">{solvencyRatio}</div>
        </div>
        {company_alias !== "bandhan_life" && (
          <div>
            <div className="tab__Header">NUMBER OF BRANCHES ACROSS INDIA:</div>
            <div className="tab__answer">{numberOfBranches}</div>
          </div>
        )}
        <div>
          <div className="tab__Header">CLAIM SETTLEMENT RATIO AS ON DATE:</div>
          <div className="tab__answer">{clamSettlementRatio}</div>
        </div>
      </div>
    </div>
  );
};

export default MobileKnowYourInsurer;
