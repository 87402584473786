export function comparePageWindowScrollEvent() {
  window.scrollTo(0, 0);
  const topInfos = document.querySelectorAll(
    ".compare-products-wrap .top-info",
  );
  const topInfoPosition = document
    .querySelector(".top-info")
    .getBoundingClientRect().y;
  const handleBodyScroll = () => {
    if (topInfoPosition < window.scrollY)
      topInfos.forEach(topInfo => topInfo.classList.add("planStickyHeader"));
    else
      topInfos.forEach(topInfo => topInfo.classList.remove("planStickyHeader"));
  };
  window.addEventListener("scroll", handleBodyScroll);
  return () => {
    window.removeEventListener("scroll", handleBodyScroll);
  };
}
