import { saveInsuredDetails1 } from "./InsuredDetails1/insuredDetails1.slice";
import { saveInsuredDetails2 } from "./InsuredDetails2/insuredDetails2.slice";
import { saveinsuredFtcaDetails } from "./InsuredFtcaForm/InsuredFtcaForm.slice";
import { saveinsuredOccupationDetails } from "./InsuredOccupationForm/insuredOccupationForm.slice";
import { saveProposerDetails } from "./ProposerForm/proposal.slice";
import { saveProposalOccupationDetails } from "./ProposerOccupationForm/ProposerOccupation.slice";
import {
  capitalize,
  getAddressProofDisplay,
  getAgeProofDisplay,
  getAlcoholConsumedDisplay,
  getAlcoholQuantityDisplay,
  getEducationDetailsDisplay,
  getEiaRepoDisplay,
  getIdentificationDetailsDisplay,
  getIncomeProofDisplay,
  getItProofDIisplay,
  getMaritalStatusString,
  getOrganizationTypeDisplay,
  getProposerIndustryTypeDisplay,
  getProposerOccupationDisplay,
  getTobacooConsumedDisplay,
  getTobacooQuantityDisplay,
  iciciResidentStatusCodeDisplay,
} from "../../../utils/inputUtils";
import {
  otherInsuranceFormSendData,
  setOtherInsuranceDetails,
  setOtherInsuranceList,
} from "./otherInsuranceDetails/otherInsuranceDetails.slice";
import {
  saveNomineeDetails,
  saveTrusteeDetails,
  setBeneficiaryDetailsList,
  trusteeDetailsSendData,
  saveDetailsT,
} from "./NomineeDetailsForm/NomineeDetailsForm.slice";
import { saveMedicalDetailsOne } from "./MedicalDetailsOne/MedicalDetailsOne.slice";
import { saveMedicalDetailsTwo } from "./MedicalDetailsTwo/MedicalDetailsTwo.slice";
import { saveMedicalDetails3 } from "./Medical Details 3/MedicalDetails3.slice";
import { saveMedicalDetails4 } from "./Medical Details 4/MedicalDetails4.slice";
import { setIsLoading } from "../proposalPage.slice";
import { saveIdDetails } from "./IdentificationForm/IdentificationForm.slice";
import { appendSendData, setPosition } from "./IcicProposal.slice";

export const storeProposerFormData = proposalData => dispatch => {
  const proposerFormData = {
    firstName: proposalData.proposerFirstName,
    lastName: proposalData.proposerLastName,
    email: proposalData.proposerEmail,
    mobileNo: proposalData.proposerMobNo,
    pinCode: proposalData.proposerPincode,
    addressLine1: proposalData.proposerAddressline1,
    addressLine2: proposalData.proposerAddressline2,
    gender: proposalData.proposerGenderCode === "Female" ? "F" : "M",
    martialStatus: getMaritalStatusString(
      parseInt(proposalData.proposerMaritalStatus),
    ),
    comm_per: proposalData.commPer,
    p_address_line_1: proposalData.pAddressLine1,
    p_address_line_2: proposalData.pAddressLine2,
    p_pincode: proposalData.pPincode,
    p_state: proposalData.pState,
    p_city: proposalData.pCity,
    isPoliticallyExposed: proposalData.pep,
    dob: proposalData.proposerBirthDate,
    state: proposalData.proposerState,
    city: proposalData.proposerCity,
  };
  dispatch(saveProposerDetails(proposerFormData));
};

export const storeProposerFatcaData = proposalData => dispatch => {
  const proposerFatcaData = {
    nriCountryResidence: proposalData?.proposerCountryOfResidence,
    nriNationality: proposalData?.proposerCountryOfNationality,
    passport: proposalData?.proposerPassportNo,
    purposeOfStay: proposalData?.proposerPurposeStay,
    employUniDetails: proposalData?.proposerEmployerDetails,
    durationStayAbroad: proposalData?.proposerDurationOfStayYear,
    intendedDurationStayAbroad: proposalData?.proposerIntendedOfStayYear,
    nriDetails: proposalData?.proposerTravelDetailsDesc,
    arrivingDate: proposalData?.proposerDateOfArrivedInIndia,
    leavingDate: proposalData?.proposerDateOfLeavingInIndia,
    isTaxResident: proposalData.proposerTaxResident,
    nationality: proposalData.proposerNationality,
    residentStatus: iciciResidentStatusCodeDisplay(
      proposalData.proposerResidentStatus,
    ),
    pan: proposalData.proposerPanNo,
    birthPlace: proposalData.pPlaceOfBirth,
    panIssuingCountry2: proposalData.otherCountry,
    tin: proposalData.pTinPanTwo,
    agreeTerm: proposalData.pDisclaimerFatca === "on",
    durationStayAbroadMonthsData: proposalData.proposerDurationOfStayMonth,
    intendeddurationStayAbroadMonthsData:
      proposalData.proposerIntendedOfStayYear,
    nriTravelDetailsData: proposalData.proposerTravelDetails,
    isBithCountry: proposalData.pBirthCountry === "Y" ? "yes" : "no",
    countryOfBirth: proposalData.pCountryOfBirth,
    bankTypeData: proposalData.proposerBankAccountHelds,
    travelNonAccCont: proposalData.travelToNonacceptableCountry === "Y",
    panIssuingCountry:
      proposalData.nriTINIssuingCountry ||
      proposalData.pOtherCountry ||
      "India",
  };

  dispatch(saveinsuredFtcaDetails(proposerFatcaData));
};

export const storeProposerOccupationData = proposalData => dispatch => {
  const proposerOccupationData = {
    occupation: getProposerOccupationDisplay(proposalData.pOccup),
    education: getEducationDetailsDisplay(parseInt(proposalData.pEducation)),
    orgName: proposalData.pOrganizationName,
    orgTypeDesc: proposalData.pOrganizationDesc,
    orgType: getOrganizationTypeDisplay(proposalData.pOrganizationType),
    engagedIn: proposalData.pIndustryQuestion === "Yes",
    annualIncome: proposalData.pIncome,
    indDesc: proposalData.pIndustryDesc,
    indType: getProposerIndustryTypeDisplay(proposalData.pIndustryType),
  };
  dispatch(saveProposalOccupationDetails(proposerOccupationData));
};

export const storeInsuredDetails1 = proposalData => dispatch => {
  const insuredDetails1 = {
    idGender: proposalData.gender === "Female" ? "F" : "M",
    idFirstName: proposalData.firstName,
    idLastName: proposalData.lastName,
    idmartialStatus: getMaritalStatusString(
      parseInt(proposalData.maritalStatus),
    ),
    proposalLa: proposalData.relationship,
    idDob: proposalData.dateOfBirth,
  };

  dispatch(saveInsuredDetails1(insuredDetails1));
};

export const storeInsuredDetails2 = proposalData => dispatch => {
  const insuredDetails2 = {
    insNationality: proposalData.pNationality,
    insResidentStatus: proposalData.country,
    isBirthCountry: proposalData.country,
  };
  dispatch(saveInsuredDetails2(insuredDetails2));
};

export const storeInsuredOccupationDetails = proposalData => dispatch => {
  const insuredOccupationData = {
    insEngagedIn: proposalData.industryQuestion === "Yes",
    insIndDesc: proposalData.industryDesc,
    insIndType: proposalData.industryType,
    insOccupation: getProposerOccupationDisplay(proposalData.occup),
    insEducation: getEducationDetailsDisplay(parseInt(proposalData.education)),
    insOrgName: proposalData.organizationName,
    insOrgType: getOrganizationTypeDisplay(proposalData.organizationType),
    insOrgNameDesc: proposalData.organizationNameDesc,
    insAnnualIncome: proposalData.income,
  };
  dispatch(saveinsuredOccupationDetails(insuredOccupationData));
};
export const storeIdentificationData = proposalData => dispatch => {
  const identificationData = {
    ageProof: getAgeProofDisplay(proposalData.ageProof),
    addressProof: getAddressProofDisplay(proposalData.addressProof),
    incomeProof: getIncomeProofDisplay(proposalData.incomeProof),
    EIA: proposalData.isEiaOpted === "Yes",
    idProof: getIdentificationDetailsDisplay(proposalData.idProof),
    insRepo: getItProofDIisplay(proposalData.itProof),
    aadhar: proposalData.proposerAadharNo,
    panNo: proposalData.proposerPanNo,
    preferredInsurance: getEiaRepoDisplay(proposalData.eiaDetails),
    eiaNumber: proposalData.eiaAccNo || "",
    identificationDetails: proposalData.proposalJourneyStatus,
  };
  dispatch(saveIdDetails(identificationData));
};

const storeOtherInsuranceDetails = proposalData => dispatch => {
  dispatch(setOtherInsuranceDetails(proposalData.holdPolicy === "Y"));
  const { cmpany, sum } = proposalData;
  const companies = cmpany ? JSON.parse(cmpany) : [];
  const sumAssured = sum ? JSON.parse(sum) : "";

  const data = {
    haveOtherInsurance: proposalData.holdPolicy === "Y",
    otherInsuranceList: companies.map((c, i) => {
      return {
        id: Math.floor(Math.random() * 12 + 10000),
        insuranceCompany: c,
        sumAssured: sumAssured[i],
      };
    }),
  };

  dispatch(setOtherInsuranceDetails(data.haveOtherInsurance));
  dispatch(setOtherInsuranceList(data.otherInsuranceList));

  dispatch(appendSendData(otherInsuranceFormSendData(data)));
};

const storeNomineeDetails = proposalData => dispatch => {
  const nomineeData = {
    mwpa: proposalData.mwpa === "Yes" ? true : false,
    fname: capitalize(proposalData.nomineeFirstName),
    lname: capitalize(proposalData.nomineeLastName),
    nomGender:
      proposalData.nomineeGender === "Male"
        ? "M"
        : proposalData.nomineeGender === "Female"
          ? "F"
          : null,
    relation: proposalData.nomineeRelationToProposer,
    dob: proposalData.nomineeDob,
    adob: proposalData.appointeeDob,
    arelation: proposalData.appointeeRelationToProposer,
    agender: proposalData.appointeeGender === "Female" ? "F" : "M",
    afname: capitalize(proposalData.appointeeFirstName),
    alname: capitalize(proposalData.appointeeLastName),
  };
  dispatch(saveNomineeDetails(nomineeData));
};
const storeTrusteeDetails = proposalData => dispatch => {
  const trusteeData = {
    mwpa: proposalData.mwpa === "Yes" ? true : false,
    tname: proposalData.trusteeName,
    tdob: proposalData.trusteeDob,
    ttype: proposalData.trusteeType,
    tpincode: proposalData.trusteePincode,
    taddress: proposalData.trusteeAddress,
    tpan: proposalData.trusteePanno,
    tmob: proposalData.trusteeMobileNo,
    temail: proposalData.trusteeEmail,
    beneficiaryList: proposalData.beneficiaryDetails
      ? JSON.parse(proposalData.beneficiaryDetails)
      : [],
  };
  console.log(trusteeData?.beneficiaryList);
  //dispatch(saveDetailsT(trusteeData));
  dispatch(setBeneficiaryDetailsList(trusteeData?.beneficiaryList));
  dispatch(saveTrusteeDetails(trusteeData));
  //	dispatch(appendSendData(trusteeDetailsSendData(trusteeData)));
};
const storeMedicalSet1 = proposalData => dispatch => {
  const medicalDetailsOneData = {
    height: proposalData.height,
    weight: proposalData.weight + " kgs",
    tobacco: proposalData.tobacco === "Y",
    alcohol: proposalData.alcohol === "Y",
    narcotics: proposalData.narcotics === "Y",
    tobaccoConsumedAs: getTobacooConsumedDisplay(
      proposalData.tobaccoConsumedAs,
    ),
    tobaccoQuantity: getTobacooQuantityDisplay(proposalData.tobaccoQuantity),
    tobaccoStartDate: proposalData.tobaccoStartDate,
    years: proposalData.alcoholStartDate,
    quantityPerDay: getAlcoholQuantityDisplay(proposalData.alcoholQuantity),
    consumedAs: getAlcoholConsumedDisplay(proposalData.alcoholConsumedAs),
    medicalQuestion1: proposalData.proposalJourneyStatus,
  };
  dispatch(saveMedicalDetailsOne(medicalDetailsOneData));
};

const storeMedicalSet2 = proposalData => dispatch => {
  const medicalSet2Data = {
    occupationHazard: proposalData.hazardText,
    employedInArmy: proposalData.armedForcesText,
    lostWeight: proposalData.weightLossText,
    familyDisorder: proposalData.familyDiseaseText,
    hospitalized: proposalData.selfDiseaseText,
    toggleOccupationHazard: proposalData.hazard === "Y",
    toggleLostWeight: proposalData.weightLoss === "Y",
    toggleEmployedInArmy: proposalData.armedForces === "Y",
    toggleFamilyDisorder: proposalData.familyDisease === "Y",
    toggleHospitalized: proposalData.selfDisease === "Y",
  };
  dispatch(saveMedicalDetailsTwo(medicalSet2Data));
};

const storeMedicalSet3 = proposalData => dispatch => {
  const medicalDetails3Data = {
    gynecologicalComplicationsText: proposalData.gynecologicalDiseasText,
    disabilityText: proposalData.physicalDisabiltyText,
    pregnentDuration: proposalData.durationWeeksNo,
    injuryText: proposalData.selfAccidentText,
    injury: proposalData.selfAccident === "Y",
    disability: proposalData.physicalDisabilty === "Y",
    pregnent: proposalData.selfPregnant === "Y",
    gynecologicalComplications: proposalData.gynecologicalDiseas === "Y",
    covidIsolateWithSymptom: proposalData.covidTwo === "Y",
    covid: proposalData.covidOne === "Y",
    covidIsolate: proposalData.covidThree === "Y",
    covidSymptom: proposalData.covidFour === "Y",
    cOccupation: proposalData.covidFive === "Y",
    covidOneDescription: proposalData.covidOneDescription,
    covidTwoDescription: proposalData.covidTwoDescription,
    covidThreeDescription: proposalData.covidThreeDescription,
    covidFourDescription: proposalData.covidFourDescription,
    covidFiveDescription: proposalData.covidFiveDescription,

    chq14: proposalData.covidSix === "Y",
    chq15: proposalData.covidSixSubone === "Y",
    chq16: proposalData.covidSixSuboneA,
    chq17: proposalData.covidSixSuboneB,
    chq18: proposalData.covidSixSuboneC === "Y",
    chq19: proposalData.covidSeven === "Y",
    chq20: proposalData.covidEight === "Y",
    chq21: proposalData.covidNine === "Y",
    chq22: proposalData.covidTen === "Y",
    chq23: proposalData.covidTenSubOne,
    chq24: proposalData.covidTenSubTwo,
    chq25: proposalData.covidTenSubThree,
    chq26: proposalData.covidEleven === "Y",
    chq27: proposalData.covidTwelve === "Y",
    chq28: proposalData.covidTwelveSubone === "Y",
    chq29: proposalData.covidTwelveSuboneText,

    currentlyInvestigated: proposalData.currentlyInvestigated === "Yes",
    investigationDtls: proposalData.investigationDtls,
  };
  console.log({ medicalDetails3Data });
  dispatch(saveMedicalDetails3(medicalDetails3Data));
};

const storeMedicalSet4 = proposalData => dispatch => {
  const medicalSet4Data = {
    otherDiagnosedDetails: proposalData.diagnosedText,
    medicalCondition: proposalData.medicalCondition,
    diagnosisDate: proposalData.dateDiagnosis,
    treatmenTerm: proposalData.treatmentPeriod,
    treatmenDetails: proposalData.treatmentDetails,
    treatmenTest: proposalData.treatmentTest,
    otherDetails: proposalData.anyDetails,
    disease: proposalData.diagnosed === "Y",
    bp: proposalData.hypertension === "1",
    surgery: proposalData.surgery === "1",
    nervousDisorder: proposalData.stroke === "1",
    liverDisorder: proposalData.jaundice === "1",
    bloodDisorder: proposalData.anaemeia === "1",
    cancer: proposalData.cancer === "1",
    heartDisease: proposalData.chestPain === "1",
    diabetes: proposalData.diabetes === "1",
    asthma: proposalData.asthma === "1",
    gastroIntestinal: proposalData.pancreatitis === "1",
    genitourinary: proposalData.kidney === "1",
    hiv: proposalData.hiv === "1",
    psychiatric: proposalData.mental === "1",
    other: proposalData.other === "1",
    healthDeclaration: proposalData.healthDeclaration === "Y",
    condtion: proposalData.currentMedicalCondition,
  };
  dispatch(saveMedicalDetails4(medicalSet4Data));
};

const getLastPosition = proposalData => {
  const { proposalJourneyStatus } = JSON.parse(
    proposalData.postPaymentJourney || JSON.stringify({}),
  );
  if (!proposalJourneyStatus) return 0;
  let lastPosition = 0;
  if (proposalJourneyStatus.proposerDetails) lastPosition++;
  if (proposalJourneyStatus.proposerFatcaDetails) lastPosition++;
  if (proposalJourneyStatus.proposerOccupationDetails) lastPosition++;
  if (proposalJourneyStatus.insuredDetails1) lastPosition++;
  if (proposalJourneyStatus.insuredDetails2) lastPosition++;
  if (proposalJourneyStatus.insuredOccupationDetails) lastPosition++;
  if (proposalJourneyStatus.otherInsuranceDetails) lastPosition++;
  if (proposalJourneyStatus.nomineeDetails) lastPosition++;
  if (proposalJourneyStatus.identificationDetails) lastPosition++;
  if (proposalJourneyStatus.medicalQuestion1) lastPosition++;
  if (proposalJourneyStatus.medicalQuestion2) lastPosition++;
  if (proposalJourneyStatus.medicalQuestion3) lastPosition++;
  if (proposalJourneyStatus.medicalQuestion4) lastPosition++;
  return lastPosition;
};

export const storeIciciProposalData = singleProductData => dispatch => {
  const proposalData = singleProductData.proposal_details;
  const lastPosition = getLastPosition(proposalData);

  dispatch(setIsLoading(true));
  if (lastPosition > 0) dispatch(storeProposerFormData(proposalData));
  if (lastPosition > 1) dispatch(storeProposerFatcaData(proposalData));
  if (lastPosition > 2) dispatch(storeProposerOccupationData(proposalData));
  if (lastPosition > 3) dispatch(storeInsuredDetails1(proposalData));
  if (lastPosition > 4) dispatch(storeInsuredDetails2(proposalData));
  if (lastPosition > 5) dispatch(storeInsuredOccupationDetails(proposalData));
  if (lastPosition > 6) dispatch(storeOtherInsuranceDetails(proposalData));
  if (lastPosition > 7) {
    {
      proposalData.mwpa === "Yes"
        ? dispatch(storeTrusteeDetails(proposalData))
        : dispatch(storeNomineeDetails(proposalData));
    }
  }
  if (lastPosition > 8) dispatch(storeIdentificationData(proposalData));
  if (lastPosition > 9) dispatch(storeMedicalSet1(proposalData));
  if (lastPosition > 10) dispatch(storeMedicalSet2(proposalData));
  if (lastPosition > 11) dispatch(storeMedicalSet3(proposalData));
  if (lastPosition > 12) dispatch(storeMedicalSet4(proposalData));
  dispatch(setPosition(lastPosition));
  dispatch(setIsLoading(false));
};
