import { checkpinCode } from "../../../EligibilityPage/serviceApi";

const enquiryId = new URLSearchParams(window.location.search).get("enquiryId");
const bajajContactResidentialDetailsList = data => [
  {
    id: "0",
    type: "title",
    value: "Permanent Address",
  },
  {
    id: "contactRDoorNo",
    label: "Door No. (optional)",
    size: "md",
    value: data?.permanentAddressDoorNo || "",
    alignLeft: true,
    maxLength: 50,
    validations: {
      // required: "Please fill this!",
      // required: "Door No is required!",
      capitalize: true,
      maxLength: 50,
      freeText: true,
    },
  },
  {
    id: "contactRBuilding",
    label: "Building Name",
    size: "md",
    alignLeft: true,
    value: data?.permanentAddressBuildingName || "",
    validations: {
      //  required: "Please fill this!",
      maxLength: 50,
      required: "Building Name is required!",
      freeText: true,
    },
  },
  {
    id: "crstreet",
    label: "Plot No./Street Name",
    size: "md",
    alignLeft: true,

    value: data?.permanentAddressPlotNo || "",
    validations: {
      maxLength: 50,
      //required: "Please fill this!",
      required: "Plot No./Street is required!",
      freeText: true,
    },
  },
  {
    id: "crplace",
    label: "Place",
    size: "md",

    alignLeft: true,
    value: data?.permanentAddressPlace || "",
    validations: {
      maxLength: 50,
      //required: "Please fill this!",
      required: "Place is required!",
      capitalize: true,
      freeText: true,
    },
  },
  {
    id: "cpincode",
    label: "Pincode ",
    size: "md",
    alignLeft: true,
    value: data?.permanentAddressPincode || "",
    city: data.regionDetails?.city,
    state: data.regionDetails?.state,
    stateCode: data.regionDetails?.state_code,
    type: "pincode",
    readOnly: true,
    setOtherItemValueOnChange: enteredPincode => {
      if (enteredPincode?.length < 6) {
        return [
          {
            ref: "cstate1",
            value: "",
          },
          {
            ref: "cdistrict1",
            value: "Select",
          },
          {
            ref: "clandmark1",
            value: "Select",
          },
        ];
      }
      return [];
    },
    scaRef: {
      state: "cstate1",
      city: "cdistrict1",
      area: "clandmark1",
    },
  },
  {
    id: "cstate",
    label: "State",
    size: "md",
    value: data?.permanentAddressState || "",
    alignLeft: true,
    readOnly: true,
    validations: {
      //  required: "Please fill this!",
      required: "State is required!",
    },
  },
  {
    id: "cdistrict",
    label: "City/District",
    size: "md",
    value: data?.permanentAddressCity || "Select",
    type: "select",
    items: [],
    searchable: true,
    alignLeft: true,
    // readOnly: true,
    validations: {
      //  required: "Please fill this!",
      required: "City/District is required!",
    },
    setOtherItemValueOnChangeAsync: async (selectedCity, data) => {
      const getRegion = async data => {
        try {
          const response = await checkpinCode(data);
          if (response.data) return response.data;
          alert("Something went wrong while fetching region details.");
        } catch (error) {
          alert("Something went wrong while fetching region details.");
          console.error(error);
        }
      };
      const regionDetails = await getRegion({
        pinCode: data?.cpincode1.pincode,
        city: selectedCity,
        companyAlias: "bajaj_life",
        traceId: enquiryId,
      });

      if (regionDetails.status)
        return [
          {
            ref: "clandmark1",
            value: "Select",
            items: regionDetails.area,
          },
        ];

      return [];
    },
  },
  {
    id: "clandmark",
    label: "Area",
    type: "select",
    size: "md",
    searchable: true,
    value: data?.permanentAddressArea || "Select",
    alignLeft: true,
    // readOnly: true,
    validations: {
      //  required: "Please fill this!",
      required: "Area is required!",
    },
  },
  {
    id: "clandmark12",
    label: "Landmark",
    type: "text",
    size: "md",
    value: data?.permanentAddressLandmark || "",
    alignLeft: true,
    // readOnly: true,
    validations: {
      maxLength: "50",
      //  required: "Please fill this!",
      required: "Landmark is required!",
    },
  },
];
export const bajajContactDetailsData = data => [
  {
    id: "email",
    label: "Email Address",
    size: "md",
    alignLeft: true,
    value: data?.email,
    readOnly: true,
    type: "email",

    validations: {
      required: "Please fill email",
      invalid: "Please fill valid email",
      maxLength: 50,
    },
  },
  {
    id: "mobileNo",
    size: "md",
    type: "mobile",
    alignLeft: true,
    label: "Mobile No ",
    value: data?.phone,
    readOnly: true,
  },

  {
    id: "1",
    type: "title",
    value: "Communication Address",
  },
  {
    id: "contactDoorNo",
    label: "Door No. (optional)",
    size: "md",
    value: data?.commAddressDoorNo || "",
    alignLeft: true,
    validations: {
      maxLength: 50,
      // required: "Please fill this!",
      // required: "Door No is required!",
      capitalize: true,
      freeText: true,
    },
  },
  {
    id: "contactBuilding",
    label: "Building Name",
    size: "md",
    alignLeft: true,
    value: data?.commAddressBuildingName || "",
    validations: {
      // required: "Please fill this!",
      maxLength: 50,
      required: " Building Name is required!",
      freeText: true,
    },
  },
  {
    id: "cstreet",
    label: "Plot No./Street Name",
    size: "md",
    value: data?.commAddressPlotNo || "",
    alignLeft: true,
    validations: {
      //required: "Please fill this!",
      maxLength: 50,
      required: "Plot No./Street is required!",
      freeText: true,
    },
  },
  {
    id: "cplace",
    label: "Place",
    size: "md",
    value: data?.commAddressPlace || "",
    alignLeft: true,
    validations: {
      //required: "Please fill this!",
      maxLength: 50,
      required: "Place is required!",
      capitalize: true,
      freeText: true,
    },
  },
  {
    id: "cpincode",
    label: "Pincode ",
    size: "md",
    alignLeft: true,
    readOnly: true,
    value: data?.commAddressPincode || data?.pincode || "",
    city: "",
    state: data?.state,
    stateCode: data.regionDetails?.state_code,
    type: "pincode",
    // setOtherItemValueOnChange: (enteredPincode) => {
    // 	if (enteredPincode?.length < 6) {
    // 		return [
    // 			{
    // 				ref: "cstate",
    // 				value: "",
    // 			},
    // 			{
    // 				ref: "cdistrict",
    // 				value: "Select",
    // 			},
    // 			{
    // 				ref: "clandmark",
    // 				value: "Select",
    // 			},
    // 		];
    // 	}
    // 	return [];
    // },
    // scaRef: {
    // 	state: "cstate",
    // 	city: "cdistrict",
    // 	area: "clandmark",
    // },
  },
  {
    id: "cstate",
    label: "State",
    size: "md",
    readOnly: true,
    value: data?.commAddressState || data?.state || "",
    alignLeft: true,
    validations: {
      //  required: "Please fill this!",
      required: "State is required!",
    },
  },
  {
    id: "cdistrict",
    label: "City/District",
    size: "md",
    value: data?.commAddressCity || data?.city || "Select",
    alignLeft: true,
    type: "select",
    items: [],
    searchable: true,
    readOnly: true,
    validations: {
      required: "City/District is required!"
    }
    // validations: {
    //  required: "Please fill this!",
    // required: "City/District is required!",
    // },
    // setOtherItemValueOnChangeAsync: async (selectedCity, data) => {
    // 	const getRegion = async (data) => {
    // 		try {
    // 			const response = await checkpinCode(data);
    // 			if (response.data) return response.data;
    // 			alert("Something went wrong while fetching region details.");
    // 		} catch (error) {
    // 			alert("Something went wrong while fetching region details.");
    // 			console.error(error);
    // 		}
    // 	};
    // 	const regionDetails = await getRegion({
    // 		pinCode: data?.cpincode.pincode,
    // 		city: selectedCity,
    // 		companyAlias: "bajaj_life",
    // 	});

    // 	if (regionDetails.status)
    // 		return [
    // 			{
    // 				ref: "clandmark",
    // 				value: "Select",
    // 				items: regionDetails.area,
    // 			},
    // 		];

    // 	return [];
    // },
  },
  {
    id: "clandmark",
    label: "Area",
    type: "select",
    size: "md",
    searchable: true,
    value: data?.commAddressArea || "Select",
    items: data?.areaList,
    alignLeft: true,
    validations: {
      //  required: "Please fill this!",
      required: "Area is required!",
    },
  },
  {
    id: "clandmark2",
    label: "Landmark",
    size: "md",
    value: data?.commAddressLandmark || "",
    alignLeft: true,
    // readOnly: true,
    validations: {
      maxLength: 50,
      //  required: "Please fill this!",
      required: "Landmark is required!",
    },
  },
  {
    id: "contactSamePermanentAddress",
    label: "Permanent address is same as mentioned above",
    items: ["Yes", "No"],
    // value: "Yes",
    alignLeft: true,
    value: data?.commPer === "N" ? "No" : "Yes",
    smallToggle: true,
    size: "lg",
    type: "toggle",
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "No":
          return [
            ...bajajContactResidentialDetailsList(data).map(item => {
              if (
                ["clandmark", "cdistrict", "cstate", "cpincode"].includes(
                  item.id,
                )
              ) {
                if (item.id === "cpincode") {
                  return {
                    ...item,
                    id: `${ item.id }1`,
                    readOnly: false,
                    validations: {
                      required: "Please provide pincode",
                      invalid: "Please provide valid pincode",
                    },
                    value: data?.permanentAddressPincode || "",
                    // city: data?.permanentAddressArea || "",
                    state: data?.permanentAddressState || "",
                    stateCode: "",
                    // onPincodeFetch(pincodeDetails, setFormItems, formItems) {
                    //   const { state, district, city } = pincodeDetails;
                    //   const newFormItems = [...formItems];
                    //   setFormItems(
                    //     newFormItems.map((formItem) => {
                    //       if (formItem.id === "clandmark1")
                    //         return { ...formItem, value: city };
                    //       if (formItem.id === "cdistrict1")
                    //         return { ...formItem, value: district };
                    //       if (formItem.id === "cstate1")
                    //         return { ...formItem, value: state };
                    //       return formItem;
                    //     })
                    //   );
                    // },
                  };
                }
                if (item.id === "clandmark")
                  return {
                    ...item,
                    id: `${ item.id }1`,
                    value: data?.permanentAddressArea || "Select",
                    validations: {
                      required: "This field is required",
                    },
                  };
                if (item.id === "cdistrict")
                  return {
                    ...item,
                    id: `${ item.id }1`,
                    value: data?.permanentAddressCity || "Select",
                    validations: {
                      required: "This field is required",
                    },
                  };
                if (item.id === "cstate")
                  return {
                    ...item,
                    id: `${ item.id }1`,
                    value: data?.permanentAddressState || "",
                    validations: {
                      required: false,
                    },
                  };
              }
              return { ...item, id: `${ item.id }1` };
            }),
          ];
        default:
          return [];
      }
    },
  },
];
