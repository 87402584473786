import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormGroup,
  Label,
  TextInput,
  AgeWrapper,
} from "../../../../components/Form/Form";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";
import "./BenefeciaryDetails.scss";
import {
  updateBeneficiaryDetails,
  removeBeneficiaryDetails,
} from "./NomineeDetailsForm.slice";
import { dateUtil } from "../../../../utils/inputUtils";
import { numOnly, noNum } from "./../../../../utils/inputUtils";
import { Button } from "../../../../components/StyledButton/Button";
import { useForm } from "react-hook-form";
const Benificiary = ({
  share,
  relation,
  id,
  onError,
  fName,
  lname,
  dob,
  sumShare,
}) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lname);
  const [shareText, setSharetext] = useState(share);
  const [dobText, setDobtext] = useState(dob);
  const [relationText, setRelationText] = useState(relation);
  const [shareError, setShareError] = useState("");
  const [relationError, setRelationError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lasttNameError, setLastNameError] = useState("");
  const [dobError, setDobError] = useState("");

  const relationsList = ["select", "Son", "Daughter", "Wife"];
  const [bAge, setbAge] = useState("");
  const bdateRef = useRef();

  const { register } = useForm();
  useEffect(() => {
    sumShare(Number(shareText));
  }, [shareText]);

  useEffect(() => {
    if (
      firstNameError !== "" &&
      lasttNameError !== "" &&
      shareError !== "" &&
      dobError !== "" &&
      relationError !== ""
    ) {
      onError(true);
    }
  }, [firstNameError, lasttNameError, shareError, dobError, relationError]);
  useEffect(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      shareText === "" ||
      dobText === "" ||
      relationText === ""
    ) {
      onError(true);
    } else {
      setFirstNameError("");
      setLastNameError("");
      setShareError("");
      setDobError("");
      setRelationError("");
      onError(false);
    }
  }, [firstName, lastName, shareText, dobText, relationText]);

  return (
    <div className="other-beneficiary">
      <div className="benificiary_colomn">
        <FormGroup className="beneficiaryInputBox">
          <TextInput
            md
            type="text"
            maxLength="20"
            name={id}
            id={id}
            onInput={noNum}
            autoComplete="off"
            placeholder=" "
            onChange={evt => {
              setFirstName(evt.target.value);
            }}
            value={firstName}
            onBlur={evt => {
              if (!evt.target.value) {
                setFirstNameError("Please Enter First Name ");
                onError(true);
              } else if (lastName === "") {
                setLastNameError("Please Enter Last Name ");
                onError(true);
              } else if (!(shareText >= 0 && shareText <= 100)) {
                setShareError("Share must be between 0 and 100");
                onError(true);
              } else if (dobText == "" || dobText.length < 10) {
                setDobError("Please Enter Dob ");
                onError(true);
              } else if (
                !(
                  relationText === "Son" ||
                  relationText === "Daughter" ||
                  relationText === "Wife"
                )
              ) {
                setRelationError("Please select  Relation");
                onError(true);
              } else {
                setFirstNameError("");
                setRelationError("");
                setLastNameError("");
                setShareError("");
                setDobError("");
                onError(false);
                dispatch(
                  updateBeneficiaryDetails({
                    id,
                    firstName: evt.target.value,
                    lastName: lastName,
                    share: shareText,
                    relation: relationText,
                    dob: dobText,
                  }),
                );
              }
            }}
          />
          <Label md htmlFor={id} style={{ pointerEvents: "none" }}>
            First Name
          </Label>
          {firstNameError && <p className="error">{firstNameError}</p>}
        </FormGroup>
        <FormGroup className="beneficiaryInputBox">
          <TextInput
            md
            type="text"
            maxLength="20"
            name={id}
            id={id}
            onInput={noNum}
            autoComplete="off"
            placeholder=" "
            onChange={evt => {
              setLastName(evt.target.value);
            }}
            value={lastName}
            onBlur={evt => {
              if (!evt.target.value) {
                setLastNameError("Please Enter Last Name ");
                onError(true);
              } else if (firstName === "") {
                setFirstNameError("Please Enter First Name ");
                onError(true);
              } else if (!(shareText >= 0 && shareText <= 100)) {
                setShareError("Share must be between 0 and 100");
                onError(true);
              } else if (dobText == "" || dobText.length < 10) {
                setDobError("Please Enter Dob ");
                onError(true);
              } else if (
                !(
                  relationText === "Son" ||
                  relationText === "Daughter" ||
                  relationText === "Wife"
                )
              ) {
                setRelationError("Please select  Relation");
                onError(true);
              } else {
                setFirstNameError("");
                setRelationError("");
                setLastNameError("");
                setShareError("");
                setDobError("");
                onError(false);
                dispatch(
                  updateBeneficiaryDetails({
                    id,
                    firstName: firstName,
                    lastName: evt.target.value,
                    share: shareText,
                    relation: relationText,
                    dob: dobText,
                  }),
                );
              }
            }}
          />
          <Label md htmlFor={id} style={{ pointerEvents: "none" }}>
            Last Name
          </Label>
          {lasttNameError && <p className="error">{lasttNameError}</p>}
        </FormGroup>
      </div>
      <div className="benificiary_colomn">
        <FormGroup className="beneficiaryInputBox">
          <TextInput
            md
            type="text"
            maxLength="10"
            name={id}
            id={id}
            placeholder=" "
            onChange={evt => {
              //	bhandleChange();
              //	setDobtext(evt.target.value);

              const val = dateUtil(evt, 1, true, setDobtext);
              setbAge(val);
            }}
            value={dobText}
            onBlur={evt => {
              if (!evt.target.value || evt.target.value.length < 10) {
                setDobError("Please Enter Dob ");
                onError(true);
              } else if (bAge > 70 || bAge < 0) {
                setDobError("Please Enter Age between 0 and 70 ");
                onError(true);
              } else if (lastName === "") {
                setLastNameError("Please Enter Last Name ");
                onError(true);
              } else if (firstName === "") {
                setFirstNameError("Please Enter First Name ");
                onError(true);
              } else if (!(shareText >= 0 && shareText <= 100)) {
                setShareError("Share must be between 0 and 100");
                onError(true);
              } else if (
                !(
                  relationText === "Son" ||
                  relationText === "Daughter" ||
                  relationText === "Wife"
                )
              ) {
                setRelationError("Please select  Relation");
                onError(true);
              } else {
                setFirstNameError("");
                setRelationError("");
                setLastNameError("");
                setShareError("");
                setDobError("");
                onError(false);
                dispatch(
                  updateBeneficiaryDetails({
                    id,
                    firstName: firstName,
                    dob: evt.target.value,
                    share: shareText,
                    relation: relationText,
                    lastName: lastName,
                  }),
                );
              }
            }}
          />
          <Label md htmlFor={dob} style={{ pointerEvents: "none" }}>
            DOB
          </Label>

          {dobError && <p className="error">{dobError}</p>}
        </FormGroup>
        <FormGroup className="beneficiaryInputBox">
          <TextInput
            md
            type="text"
            maxLength="3"
            name={id}
            id={id}
            onInput={numOnly}
            autoComplete="off"
            placeholder=" "
            onChange={evt => {
              setSharetext(evt.target.value);
            }}
            value={shareText}
            onBlur={evt => {
              if (!(evt.target.value >= 0 && evt.target.value <= 100)) {
                setShareError("Share must be between 0 and 100");
                onError(true);
              } else if (!evt.target.value) {
                setShareError("Please Enter share ");
                onError(true);
              } else if (lastName === "") {
                setLastNameError("Please Enter Last Name ");
                onError(true);
              } else if (firstName === "") {
                setFirstNameError("Please Enter First Name ");
                onError(true);
              } else if (dobText == "" || dobText.length < 10) {
                setDobError("Please Enter Dob ");
                onError(true);
              } else if (
                !(
                  relationText === "Son" ||
                  relationText === "Daughter" ||
                  relationText === "Wife"
                )
              ) {
                setRelationError("Please select  Relation");
                onError(true);
              } else {
                setFirstNameError("");
                setRelationError("");
                setLastNameError("");
                setShareError("");
                setDobError("");
                onError(false);
                dispatch(
                  updateBeneficiaryDetails({
                    id,
                    firstName: firstName,
                    lastName: lastName,
                    share: evt.target.value,
                    relation: relationText,
                    dob: dobText,
                  }),
                );
              }
            }}
          />
          <Label md htmlFor={id} style={{ pointerEvents: "none" }}>
            share
          </Label>
          {shareError && <p className="error">{shareError}</p>}
        </FormGroup>
      </div>
      <div className="benificiary_colomn">
        <FormGroup className="beneficiaryInputBox">
          <div className="upgradeDropList smallCustomDropDownSpacin">
            <div className="upgradeDropLabel">Relation </div>
            <div className=" selectIncome">
              <SmallCustomDropDown
                items={relationsList}
                selectedItem={relationText || "select"}
                onChange={value => {
                  setRelationText(value);
                  if (!(shareText >= 0 && shareText <= 100)) {
                    setShareError("Share must be between 0 and 100");
                    onError(true);
                  } else if (!value) {
                    setRelationError("Please select Relation");
                    onError(true);
                  } else if (lastName === "") {
                    setLastNameError("Please Enter Last Name ");
                    onError(true);
                  } else if (firstName === "") {
                    setFirstNameError("Please Enter First Name ");
                    onError(true);
                  } else if (dobText == "" || dobText.length < 10) {
                    setDobError("Please Enter Dob ");
                    onError(true);
                  } else if (
                    !(
                      value === "Son" ||
                      value === "Daughter" ||
                      value === "Wife"
                    )
                  ) {
                    setRelationError("Please select  Relation");
                    onError(true);
                  } else {
                    setFirstNameError("");
                    setRelationError("");
                    setLastNameError("");
                    setShareError("");
                    setDobError("");
                    onError(false);
                    dispatch(
                      updateBeneficiaryDetails({
                        id,
                        firstName: firstName,
                        lastName: lastName,
                        share: shareText,
                        relation: value,
                        dob: dobText,
                      }),
                    );
                  }
                }}
              />
            </div>
            {relationError && <p className="error">{relationError}</p>}
          </div>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            className="benbtn--red"
            onClick={() => {
              dispatch(removeBeneficiaryDetails(id));
            }}
          >
            Remove
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

export default Benificiary;
