import { useLocation, useHistory } from "react-router-dom";
import SecureLS from "secure-ls";

function useCustomLocation() {
  const location = useLocation();
  const history = useHistory();
  const ls = new SecureLS();
  const { search } = location;
  const urlSearchParams = new URLSearchParams(search);
  const enquiryId = urlSearchParams.get("enquiryId");
  const afflid = urlSearchParams.get("afflid");
  const utm_medium = urlSearchParams.get("utm_medium");
  const c_id = urlSearchParams.get("c_id");
  if (urlSearchParams.get("enquiryId")) {
    sessionStorage.setItem("traceId", enquiryId);
  }

  const gotoPage = (page, id = enquiryId) => {
    if (!page) throw new Error("gotoPage missing a required parameter 'page'");
    history.push({
      pathname: `/${ page }`,
      search: `enquiryId=${ id }`,
    });
  };

  const redirectTo = (page, id = enquiryId) => {
    if (!page)
      throw new Error("redirectTo missing a required parameter 'page'");
    window.location = `/${ page }?enquiryId=${ id }`;
  };

  const pages = {
    quotes: "quotes",
    riderPage: "riderPage",
    planCompare: "plan-compare",
    thankyou: "thankyou",
    proposalPage: "proposalpage",
    eligibilitypage: "eligibilitypage",
    documentuploadpage: "documentuploadpage",
    transactionsuccessful: "transactionsuccessful",
    transactionunsuccessful: "transactionunsuccessful",
    otpverification:"otp-verification",
  };

  redirectTo.pages = pages;
  gotoPage.pages = pages;

  return {
    enquiryId,
    redirectTo,
    gotoPage,
    ...location,
    history,
    afflid,
    utm_medium,
    c_id
  };
}

export default useCustomLocation;
