import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useDisable = ({
  details,
  agreeTerm,
  selectedEducation,
  selectedOccupation,
  selectedNatureOfWork,
  selectedCountry,
  test,
  validateSelect,
  isValid,
  checkedHeart,
  checkNRI,
  loader,
  notEligibleEducation,
  notEligibleOccupation,
  notEligibleAnnualIncome,
  notEligibleSumAssured,
  errorNatureOfWork,
  annualIncome,
  intentToTravel,
  sumAssured,
  notEligiblePinCode,
  notEligibleCountry,
  stateDisable,
  husbandHoldsPolicy,
  review,
  selectedCity,
  selectedArea,
  area,
  riderNotAllowed,
  notEligibleADBURider,
  notEligibleCIBFRider,
  ageNotAllowed,
  checkMax,
  vaccinationStatus,
}) => {
  const [disable, setDisable] = useState(true);

  const { selectedQuote } = useSelector(state => state.quote);

  const { company_alias } = selectedQuote;

  console.log(
    company_alias === "icici_pru"
      ? selectedOccupation === "Agriculturist" && riderNotAllowed
      : true,
  );

  useEffect(() => {
    if (
      details &&
      // agreeTerm &&
      isValid &&
      !checkedHeart &&
      !loader &&
      (!checkNRI ||
        (checkNRI &&
          selectedCountry &&
          selectedCountry !== "Select Country" &&
          !stateDisable)) &&
      !notEligibleAnnualIncome &&
      !notEligibleSumAssured &&
      !notEligibleEducation &&
      !notEligibleOccupation &&
      !notEligiblePinCode &&
      !ageNotAllowed &&
      !notEligibleCountry &&
      (company_alias === "icici_pru" && selectedOccupation === "Agriculturist"
        ? !riderNotAllowed
        : true) &&
      (company_alias === "icici_pru" && selectedOccupation === "Housewife"
        ? !notEligibleADBURider
        : true) &&
      (company_alias === "icici_pru" && selectedOccupation === "Housewife"
        ? !notEligibleCIBFRider
        : true) &&
      selectedOccupation !== "Occupation" &&
      selectedEducation !== "Highest Education" &&
      selectedCity !== "Select City" &&
      (company_alias === "bajaj_life"
        ? selectedArea !== "Select Area"
        : true) &&
      (company_alias === "bajaj_life" ? !riderNotAllowed : true) &&
      !errorNatureOfWork &&
      husbandHoldsPolicy &&
      !review &&
      (company_alias === "bajaj_life"
        ? vaccinationStatus !== "Please Select"
        : true)
    ) {
      setDisable(false);
    } else setDisable(true);
    // if (
    //   (checkNRI &&
    //     (selectedEducation !== "Graduate" ||
    //       selectedEducation !== "Post Graduate") &&
    //     selectedCountry &&
    //     selectedCountry !== "Select Country" &&
    //     notEligibleCountry &&
    //     stateDisable &&
    //     review &&
    //     // (!agreeTerm || agreeTerm) &&
    //     intentToTravel &&
    //     sumAssured >= 50000000) ||
    //   // (checkNRI && !agreeTerm && Number(annualIncome) <= 2000000) // removed agreeTerm
    //   (checkNRI && Number(annualIncome) <= 2000000)
    // ) {
    //   setDisable(true);
    // }

    if (
      checkNRI &&
      (selectedEducation === "Graduate" ||
        selectedEducation === "Post Graduate") &&
      Number(annualIncome) > 2000000 &&
      !intentToTravel &&
      selectedCountry &&
      !stateDisable &&
      !review &&
      !notEligibleCountry &&
      selectedCountry !== "Select Country"
      // &&
      // agreeTerm
    ) {
      setDisable(false);
    }

    if (test) {
      validateSelect();
    }
    if (company_alias === "max_life" && checkMax) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    details,
    // agreeTerm,
    selectedEducation,
    selectedOccupation,
    selectedNatureOfWork,
    selectedCountry,
    selectedCity,
    selectedArea,
    test,
    validateSelect,
    isValid,
    checkedHeart,
    checkNRI,
    checkMax,
    loader,
    notEligibleEducation,
    notEligibleOccupation,
    notEligibleAnnualIncome,
    notEligibleSumAssured,
    notEligiblePinCode,
    annualIncome,
    disable,
    sumAssured,
    intentToTravel,
    notEligibleCountry,
    stateDisable,
    husbandHoldsPolicy,
    review,
    ageNotAllowed,
  ]);

  return { disable, setDisable };
};

export default useDisable;
