import React from "react";
import LaxmiImage from "../../../assets/img/laxmiImg2.png";
import CheckImage from "../../../assets/img/check-animation.gif";
import CheckImageLoading from "../../../assets/img/checkLoad-animation.gif";

import "./loading-bi.scss";
import { useSelector } from "react-redux";

const LoadingBi = ({ loading1, loading2 }) => {
  const customerDetailsg = useSelector(state => state.greeting.customerDetails);
  const { corporateJourney } = useSelector(state => state.quote);
  let flag = false;
  // if (customerDetailsg && customerDetailsg.work_email) {
  //   let temp = customerDetailsg.work_email;
  //   let domain = temp.split("@")[1];
  //   if (domain === "gameskraft.com") {
  //     flag = true;
  //   }
  // }

  if (corporateJourney.is_gameskraft === "active") {
    flag = true;
  }
  return (
    <section class="loaderingFeed">
      <div class="container">
        <div class="loading-bi-row">
          <img class="loadFedLaxImg" src={LaxmiImage} alt="Laxmi" />
          <div class="loadFedTitle">
            Thank you for submitting your term life insurance application.
            <br /> Please wait while we are:
          </div>
          <ul class="loadFedWrap">
            <li>
              <div>
                <span>Generating your application number</span>
                <span class="loadicon">
                  <img
                    src={loading1 ? CheckImageLoading : CheckImage}
                    alt="Checked"
                  />
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>Generating your benefit illustration</span>
                <span class="loadicon">
                  <img
                    src={loading2 ? CheckImageLoading : CheckImage}
                    alt="Checked"
                  />
                </span>
              </div>
            </li>
            {!flag && (
              <li>
                <div>
                  <span>Getting you ready for payment</span>
                  <span className="loadicon">
                    <img
                      src={loading2 ? CheckImageLoading : CheckImage}
                      alt="Checked"
                    />
                  </span>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LoadingBi;
