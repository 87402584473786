import React from "react";
import CriteriaList from "../../../Popup/PopupWithTitle/CriteriaList/CriteriaList";
import CriteriaListItem from "../../../Popup/PopupWithTitle/CriteriaListItem";
import PopupWithTitle from "../../../Popup/PopupWithTitle/PopupWithTitle";

function HousewifeEligibilityCriteriaPopup({ onClose, ...props }) {
  const handleClose = () => {
    onClose && onClose();
  };
  return (
    <PopupWithTitle
      title="You are eligible for this cover if"
      onClose={handleClose}
      {...props}
    >
      {fadeOut => (
        <>
          <CriteriaList>
            <CriteriaListItem>
              You are a "Post Graduate / Graduate".
            </CriteriaListItem>
            <CriteriaListItem>
              Your husband is a graduate with a minimum income of 10 Lacs.
            </CriteriaListItem>
            <CriteriaListItem>
              Selected insurance cover is limited to 50% of husband's insurance
              cover (up to 50 Lakhs).
            </CriteriaListItem>
            <CriteriaListItem>
              If you click "ACCEPT", you are eligible for basic life plan
              without critical illness & disability rider and waiver of premium.
            </CriteriaListItem>
          </CriteriaList>
          <div className="nri-popup-btn-group">
            <button
              className="nri-popup-btn nri-popup-btn-primary nri-popup-btn-proceed"
              onClick={() => fadeOut(handleClose)}
            >
              Accept
            </button>
          </div>
        </>
      )}
    </PopupWithTitle>
  );
}

export { HousewifeEligibilityCriteriaPopup };
