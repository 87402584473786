import { createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { medicalCenterSubmit } from "../serviceApi";
const maxMedicalCenterSlice = createSlice({
  name: "maxMedicalFormCenter",
  initialState: {
    maxMedicalFormDetails: {},
    maxMedicalResponse: false,
    loading: false,
  },
  reducers: {
    setMaxMedicalFormDetails: (state, { payload }) => {
      state.maxMedicalFormDetails = payload;
    },
    saveMaxResponse: (state, { payload }) => {
      state.maxMedicalResponse = payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setMaxMedicalFormDetails, saveMaxResponse, initLoader } =
  maxMedicalCenterSlice.actions;

export const maxMedicalCenterApi = (data, history, search) => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(setMaxMedicalFormDetails(data));
        await medicalCenterSubmit(data).then(res => {
          dispatch(saveMaxResponse(res));
          if (res.data.original.status) {
            dispatch(initLoader(false));
            // dispatch(saveResponse(res.data));
            swal(res.data.original.message, {
              icon: "success",
            }).then(() => {
              history.push(
                `/documentuploadpage?enquiryId=${search.slice(11, 29)}`,
              );
            });
          } else {
            dispatch(initLoader(false));
            swal(res.data.original.message, {
              icon: "error",
            });
          }
        });
      }
    } catch (error) {
      console.error("Error MediApi", error);
    }
  };
};

export default maxMedicalCenterSlice.reducer;
