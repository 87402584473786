/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { QuoteFilter } from "../";
import { CompareContainer, QuoteCard } from "./components";
import { ReviewProduct, Error } from "../../components";
import AllNewLoader from "../../components/AllNewLoader/AllNewLoader";
import QuotesToRiderLoader from "../../components/AllNewLoader/QuotesToRiderLoader";
import Share from "../../assets/img/share.svg";
import WhiteListed from "./components/WhiteListed/WhiteListed";
import {
  setCameFromCompare,
  setCameFromRiders,
  fetchQuotes,
  setIsQuotesError,
  // IC_LOGO_URLS,
  setRiderDetails,
  addQuoteToCompare,
  setQuotesForCompare,
  setRecommendedQuote,
  selectCameFromRiders,
  uploadSelectedQuote,
} from "./quotePageSlice";

import { getPayPremiumMode, runScript, sortQuotes } from "./helper";

import "./quote-page.scss";
import { setPopup, updateFilters } from "../QuoteFilter/quoteFilterSlice";

import { getAllData } from "../../api/getAllData";
import {
  useCustomLocation,
  useDidMountEffect,
  useDisplayNomineeGets,
  useDocumentTitle,
  useQuoteBuyNowButton,
  useWindowResize,
} from "../../custom-hooks";
import SendQuotes from "../../components/Popup/SendQuotes";
import MaxDiscountPopup from "../../components/Popup/MaxDiscountPopup";
import { setSelectedAddOns } from "../RiderPage/RiderPage.slice";
import { useNavigation } from "../../custom-hooks/useNavigation";
import { updateQuotation } from "../../components/ReviewPopUp/serviceApi";
import QuotesLoader from "./components/QuotesLoader/QuotesLoader";
import {
  setForm1Submitted,
  setShowContinueButton,
  updateUserDetails2,
  createUserFailed,
} from "../GreetingPage/greeting.slice";
import swal from "sweetalert";
import ExclusiveQuoteCard from "./components/ExclusiveQuoteCard/ExclusiveQuoteCard";
import BandhanContent from "./components/QuoteCard/BandhanContent";
import { useLocation } from "react-router";
import BajajDiscountPopup from "../../components/Popup/BajajDiscountPopup";
import KotakDiscountPopup from "../../components/Popup/KotakDiscountPopup";
import IciciDiscountPopup from "../../components/Popup/IciciDiscountPopup";
const updateRecommended = async data => {
  await updateQuotation({
    ...data,
  });
};
function QuotePage({ history }) {
  useEffect(() => {
    sessionStorage.setItem("lastVisited", true);
    dispatch(setShowContinueButton(true));
    dispatch(setForm1Submitted(true));
    dispatch(createUserFailed());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  useDocumentTitle("Life quote");

  // const { notApplicableICs } = useSelector(({ quote }) => quote);

  useNavigation();

  const { width } = useWindowResize();

  const { enquiryId, pathname } = useCustomLocation();

  const {
    quotes,
    quotesOnCompare,
    isQuotesLoading,
    isError,
    isQuotesError,
    switchToMedical,
    corporateJourney,
    loaderForQuoteToRider,
  } = useSelector(state => state.quote);
  const [showLoader, setShowLoader] = useState(isQuotesLoading);
  const [selectedQuote, setSelectedQuote] = useState();
  const { coverUpto, coverAmount, paymentTerm } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter,
  );
  const { sortBy, payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );
  const [maxPopup, setMaxPopup] = useState(false);
  const [bajajPopup, setBajajPopup] = useState(false);
  const [kotakPopup, setKotakPopup] = useState(false);
  const [iciciPopup, setIciciPopup] = useState(false);

  const [displayQuotes, setDisplayQuotes] = useState(
    quotes.filter(q => q.planTenure !== "different"),
  );
  const [displayDifferentTenureQuotes, setDisplayDifferentTenureQuotes] =
    useState(
      quotes.filter(q => {
        return q.planTenure !== "same";
      }),
    );
  //survivalBenefits
  const { filter } = useSelector(({ quoteFilter }) => quoteFilter);
  const [survivalNoQuotes, setSurvivalNoQuotes] = useState(false);
  const [showBenefitsPopup, setShowBenefitsPopup] = useState(false);

  // const [showBenefitsPopup, setShowBenefitsPopup] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const handleClick = event => {
    setIsShown(current => !current);
  };

  const resetState = () => {
    setIsShown(false);
  };

  useEffect(() => {
    if (coverAmount == "₹ 50 Lakh") {
      dispatch(getAllData(null, pathname, enquiryId));
    }
  }, []);
  useEffect(() => {
    if (quotes.length !== 0) setShowLoader(false);
  }, [quotes]);

  useEffect(() => {
    if (filter?.morePlans === "Survival Benefits") {
      if (
        filter?.paymentTerm?.option !== "Limited Pay" ||
        filter?.paymentTerm?.option !== "Single Pay"
      ) {
        setSurvivalNoQuotes(true);
      } else if (
        filter?.paymentTerm?.option !== "Limited Pay" &&
        (filter?.paymentTerm?.term !== "10 Years" ||
          filter?.paymentTerm?.term !== "5 Years")
      ) {
        setSurvivalNoQuotes(true);
      } else {
        setSurvivalNoQuotes(false);
      }
    } else {
      setSurvivalNoQuotes(false);
    }
  }, [filter]);

  const handleBuyNowClick = useQuoteBuyNowButton();
  const [updated, setUpdated] = useState(false);
  const [quoPopup, setQuoPopup] = useState(false);
  const [seo, setSeo] = useState(false);
  const { customerDetails, isPnbPreference, preferredInsurance } = useSelector(
    state => state?.greeting,
  );
  const query = new URLSearchParams(window.location.search);
  const utm_medium = query.get("utm_medium");

  const c_id = query.get("c_id");

  const afflid = query.get("afflid");
  const utm_source = query.get("utm_source");

  const token = query.get("token");
  const src = query.get("src");
  const afflidParams = {
    afflid,
    utm_source,
    utm_medium: query.get("utm_medium"),
    utm_campaign: query.get("utm_campaign"),
    aff_userid: query.get("user_id"),
    utm_url: window.location.href,
    c_id,
    token,
    src,
  };
  useEffect(() => {
    dispatch(setSelectedAddOns([]));
    dispatch(setRiderDetails(false));
  }, [dispatch]);
  useEffect(() => {
    if (Object.keys(customerDetails).length === 0) {
      // to get games kraft IC on first instance and not just on refresh
      dispatch(getAllData(null, pathname, enquiryId)).then(data => {
        if (!data?.quoteData?.seo_update) {
          setSeo(true);
        } else {
          setSeo(false);
        }
      });
      setCameFromRiders(false);
    }
  }, [enquiryId, pathname, customerDetails]);

  useDidMountEffect(() => {
    if (
      customerDetails?.dob &&
      (utm_source || c_id || afflid || token || src) &&
      seo
    ) {
      dispatch(
        updateUserDetails2({
          ...customerDetails,
          ...afflidParams,
          isSeo: false,
        }),
      );
    }
  }, [customerDetails, seo]);

  const { displayNomineeGets } = useDisplayNomineeGets();
  const sortOptions = {
    preferredInsurance,
    age: customerDetails.age,
  };
  useEffect(() => {
    dispatch(setCameFromCompare(false));
    console.log({
      quotes: quotes.filter(
        q => q.planTenure !== "different" && q.company_alias !== "bandhan_life",
      ),
    });
    setDisplayQuotes(
      sortQuotes(
        quotes.filter(q =>
          coverAmount == "₹ 50 Lakh"
            ? q.planTenure !== "different"
            : q.planTenure !== "different" &&
              q.company_alias !== "bandhan_life",
        ),
        sortBy,
        sortOptions,
      ),
    );
    setDisplayDifferentTenureQuotes(
      sortQuotes(
        quotes.filter(q => q.planTenure !== "same"),
        sortBy,
        sortOptions,
      ),
    );
  }, [quotes, dispatch, sortBy, customerDetails.preferance]);

  useEffect(() => {
    if (quotes.length !== 0) {
      const bandhan = quotes.filter(
        q => q.company_alias === "bandhan_life" && q.sumAssured == "5000000",
      );
      console.log("sortedQuotes", bandhan);
      let sortedQuotes = sortQuotes(
        quotes.filter(q =>
          coverAmount == "₹ 50 Lakh"
            ? q.planTenure !== "different"
            : q.planTenure !== "different" && q.sumAssured != "5000000",
        ),

        sortBy,
        sortOptions,
      );
      console.log("sortedQuotes", sortedQuotes);
      if (coverAmount != "₹ 50 Lakh") sortedQuotes.splice(1, 0, ...bandhan);
      dispatch(setQuotesForCompare([]));
      dispatch(addQuoteToCompare(sortedQuotes[0]?.insurance_code));
      setDisplayQuotes(sortedQuotes);
      dispatch(setRecommendedQuote(sortedQuotes[0]?.insurance_code));
    }
    setDisplayDifferentTenureQuotes(
      sortQuotes(
        quotes.filter(q => q.planTenure !== "same"),
        sortBy,
        sortOptions,
      ),
    );
  }, [sortBy, quotes, customerDetails.preferance, dispatch]);

  const bajajData = quotes.filter(val => val.company_alias === "bajaj_life");
  const bajajLifeData = bajajData[0];

  const [modeOfPayment, setModeOfPayment] = useState(
    getPayPremiumMode(
      paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );
  useEffect(() => {
    setModeOfPayment(
      getPayPremiumMode(
        paymentTerm.option === "Single Pay" ? "none" : payPremium,
      ),
    );
  }, [paymentTerm, payPremium]);
  useEffect(() => {
    if (isQuotesLoading)
      setModeOfPayment(
        getPayPremiumMode(
          paymentTerm.option === "Single Pay" ? "none" : payPremium,
        ),
      );
  }, [isQuotesLoading, payPremium, paymentTerm.option]);
  useEffect(() => {
    if (isQuotesLoading === "update-quotation") {
      setUpdated(true);
    }
    if (!isQuotesLoading && quotes.length && !updated) {
      updateRecommended({
        traceId: enquiryId,
        recommended_premium: displayQuotes[0].premium_amount,
        requestType: "recommended_premium",
      });
      setUpdated(true);
    }
  }, [isQuotesLoading]);

  const cameFromRiders = useSelector(selectCameFromRiders);
  const hrf = useLocation();
  const href = hrf.pathname;
  useEffect(() => {
    if (
      !isQuotesLoading &&
      !cameFromRiders &&
      displayQuotes[0] &&
      href === "/riderPage"
    ) {
      updateRecommended({
        traceId: enquiryId,

        recommended_premium: displayQuotes[0].premium_amount,
        requestType: "recommended_premium",
      });
      setUpdated(true);
    }
  }, [displayQuotes, enquiryId, isQuotesLoading]);

  useEffect(() => {
    const src = query.get("utm_source");
    if (src) {
      runScript(src);
    }
  }, []);
  const getQuoteCards = quotes =>
    quotes?.map((quote, idx) => {
      return quote?.company_alias === "bandhan_life" &&
        isPnbPreference == true &&
        quote?.sumAssured == "5000000" &&
        coverAmount != "₹ 50 Lakh" &&
        coverAmount != "₹ 45 Lakh" &&
        coverAmount != "₹ 40 Lakh" &&
        coverAmount != "₹ 35 Lakh" &&
        coverAmount != "₹ 30 Lakh" &&
        coverAmount != "₹ 25 Lakh" ? (
        <ExclusiveQuoteCard
          setShowBenefitsPopup={setShowBenefitsPopup}
          showBenefitsPopup={showBenefitsPopup}
          quote={quote}
          quotes={quotes}
          payPremiumMode={modeOfPayment}
          price={quote.premium_amount}
          onBuyNowClicked={() => {
            if (quote.company_alias === "max_life") {
              setSelectedQuote(quote);
              setMaxPopup(true);
            }
            if (quote.company_alias === "kotak_life") {
              setSelectedQuote(quote);
              setKotakPopup(true);
            } else handleBuyNowClick(quote);
          }}
        />
      ) : (
        <QuoteCard
          coverAmount={coverAmount}
          coverUpto={
            quote?.planTenure === "different"
              ? quote?.differentTenureCU + " years"
              : coverUpto
          }
          nomineeGets={displayNomineeGets}
          payPremiumMode={modeOfPayment}
          additionalRiders={quote.addon_riders?.length}
          medicalExamination={quote.medicalCondition || "As Per Underwriting"}
          claimSettlementRatio={quote.claim_settlement_ratio.slice(0, 6).trim()}
          logoUrl={quote.company_logo}
          setShowBenefitsPopup={setShowBenefitsPopup}
          showBenefitsPopup={showBenefitsPopup}
          inBuiltRiders={quote.inbuilt_riders.length}
          logoText={quote.master_product_name}
          childName={quote.child_product_name}
          price={quote.premium_amount}
          id={quote.insurance_code}
          history={history}
          key={quote.insurance_code}
          showSwitchButton={quote?.isSwitchToVideo || false}
          onBuyNowClicked={() => {
            if (quote.company_alias === "max_life") {
              setSelectedQuote(quote);
              setMaxPopup(true);
            } else if (quote.company_alias === "bajaj_life") {
              setSelectedQuote(quote);
              dispatch(uploadSelectedQuote(quote));
              setBajajPopup(true);
            } else if (
              quote.company_alias === "icici_pru" &&
              quote?.self_employed_premium != 0 &&
              paymentTerm.option !== "Single Pay"
            ) {
              setSelectedQuote(quote);
              dispatch(uploadSelectedQuote(quote));
              setIciciPopup(true);
            } else if (quote.company_alias === "kotak_life") {
              setSelectedQuote(quote);
              dispatch(uploadSelectedQuote(quote));
              setKotakPopup(true);
            } else handleBuyNowClick(quote);
          }}
          recommended={idx === 0 && quote.planTenure === "same"}
          quote={quote}
        />
      );
    });

  const switchConditionBajaj = quotes => {
    if (!switchToMedical)
      return quotes.filter(
        item =>
          item.company_alias !== "bajaj_life" ||
          (item.company_alias === "bajaj_life" &&
            item?.master_product_name === "eTouch") ||
          (item.company_alias === "bajaj_life" &&
            item.medicalCondition === "Physical Medical"),
      );
    else {
      const temp = [...quotes];
      const indexOfPhysicalMedical = temp.findIndex(
        item =>
          item.company_alias === "bajaj_life" &&
          item.medicalCondition === "Physical Medical" &&
          item?.master_product_name !== "eTouch",
      );
      const indexOfVideoMedical = temp.findIndex(
        item =>
          item.company_alias === "bajaj_life" &&
          item.medicalCondition === "Video Medical",
      );
      console.log({ indexOfPhysicalMedical, indexOfVideoMedical });
      temp[indexOfPhysicalMedical] = temp[indexOfVideoMedical];
      temp.splice(indexOfVideoMedical, 1);
      return temp;
    }
  };

  const quoteCards = getQuoteCards(switchConditionBajaj(displayQuotes));

  const differentTenureQuoteCards = getQuoteCards(
    switchConditionBajaj(displayDifferentTenureQuotes),
  );
  const loadingQuotes = (
    <AllNewLoader
      contentKey="QuotesPageLoader"
      lines={"FORQUOTEPAGE"}
      quotesLength={quotes?.length}
      isQuotesLoading={isQuotesLoading}
    />
  );

  const error = (
    <div className="loading-quotes">
      <p>Something went wrong while fetching quotes!</p>
      <p>
        Sorry for inconvenience, check console for more information about the
        error.
      </p>
    </div>
  );

  const noQuotes = () => {
    if (survivalNoQuotes) {
      return (
        <div className="loading-quotes">
          <p className="survival-benifit-noquotes">
            Survival benefit is available only on Limited Pay (5 & 10) and
            Single Payment Mode. To view previously selected plan please untick
            the checkbox.
          </p>
        </div>
      );
    } else if (quotes.length === 0) {
      return (
        <div className="loading-quotes">
          <p>Sorry, no Quotes found for your criteria!</p>
        </div>
      );
    }
  };

  useEffect(() => {
    if (
      displayDifferentTenureQuotes.length > 0 &&
      displayQuotes.length === 0 &&
      !isQuotesLoading
    ) {
      swal("Plans available with different tenure");
    }
  }, [
    displayDifferentTenureQuotes,
    displayQuotes,
    isQuotesLoading,
    quotes.length,
  ]);

  const mobilePopupCta = (
    <div className="modifyMobWrap">
      <button
        onClick={() => {
          setQuoPopup(true);
        }}
        className="quote-page__mobile-modify quotes-page__sendQuotes"
      >
        <img src={Share} alt="share" />

        <span>Send Quotes</span>
      </button>
      <SendQuotes show={quoPopup} onClose={setQuoPopup} />
      <button
        onClick={() => {
          dispatch(setPopup("modify"));
        }}
        className="quote-page__mobile-modify"
      >
        <svg
          version="1.1"
          viewBox="0 0 24 24"
          width="12"
          height="12"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M3,17.25v3.75h3.75l11.06,-11.06l-3.75,-3.75l-11.06,11.06Zm17.71,-10.21c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34c-0.39,-0.39 -1.02,-0.39 -1.41,0l-1.83,1.83l3.75,3.75l1.83,-1.83Z"
            fill="#333"
          ></path>
          <path fill="none" d="M0,0h24v24h-24Z"></path>
        </svg>
        Modify Personal Details
      </button>
    </div>
  );

  const { lastFiltersData } = useSelector(({ quoteFilter }) => quoteFilter);

  if (isError) return <Error errorMessage={isError} />;
  else if (!payPremium)
    return (
      <AllNewLoader contentKey="QuotesPageLoader" lines={"FORQUOTEPAGE"} />
    );

  const LoadingWithText = props => (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        zIndex: "999",
        background: "rgba(255,255,255,0.9)",
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {props.children}
      <div style={{ height: "100px", width: "100%" }}>
        {/* <Loader style={{ position: "static", background: "transparent" }} /> */}
        {
          <AllNewLoader
            contentKey="QuotesPageLoader"
            lines={"FORQUOTEPAGE"}
            style={{ position: "static", background: "transparent" }}
          />
        }
      </div>
    </div>
  );

  return (
    <div className="quote-page quote-page-container">
      {isQuotesLoading === "tata-redirection" && (
        <LoadingWithText>
          <p>
            You are now being redirected to <strong>TATA AIA Life</strong>{" "}
            Portal
          </p>
        </LoadingWithText>
      )}
      {isQuotesLoading === "update-quotation" && (
        <LoadingWithText></LoadingWithText>
      )}
      <ReviewProduct />
      {selectedQuote?.company_alias === "kotak_life" && (
        <KotakDiscountPopup
          show={kotakPopup}
          onClose={setKotakPopup}
          handleClick={handleBuyNowClick}
          quote={selectedQuote}
        />
      )}
      {selectedQuote?.company_alias === "max_life" && (
        <MaxDiscountPopup
          show={maxPopup}
          onClose={setMaxPopup}
          handleClick={handleBuyNowClick}
          quote={selectedQuote}
        />
      )}
      {selectedQuote?.company_alias === "bajaj_life" && (
        <BajajDiscountPopup
          show={bajajPopup}
          onClose={setBajajPopup}
          handleClick={handleBuyNowClick}
          quote={selectedQuote}
          bajajLifeData={bajajLifeData}
        />
      )}
      {selectedQuote?.company_alias === "icici_pru" &&
        selectedQuote?.self_employed_premium != 0 &&
        paymentTerm.option !== "Single Pay" && (
          <IciciDiscountPopup
            show={iciciPopup}
            onClose={setIciciPopup}
            handleClick={handleBuyNowClick}
            quote={selectedQuote}
          />
        )}

      {width <= 767 && mobilePopupCta}
      {width >= 768 && width <= 1177 && (
        <button
          onClick={() => {
            dispatch(setPopup("modify"));
          }}
          className="quote-page__modify-details-btn"
          disabled={isQuotesLoading}
        >
          MODIFY PERSONAL DETAILS
        </button>
      )}
      <QuoteFilter />

      {isQuotesError ? (
        <div
          style={{
            position: "relative",
            width: "90%",
            height: "30px",
            margin: "auto",
            marginTop: "120px",
          }}
        >
          <Error
            retryButton={
              isQuotesError.from === "upload-selected-quote"
                ? "Reselect"
                : "Retry"
            }
            errorMessage={isQuotesError.message}
            onRetry={
              isQuotesError.from === "quotes"
                ? () =>
                    dispatch(
                      fetchQuotes({
                        traceId: enquiryId,
                        activeICs: corporateJourney?.gameskraft_ic,
                        active: corporateJourney?.is_gameskraft === "active",
                      }),
                    )
                : isQuotesError.from === "upload-selected-quote"
                ? () => dispatch(setIsQuotesError(false))
                : () => dispatch(updateFilters(lastFiltersData))
            }
          />
        </div>
      ) : (
        <>
          <div
            className={`quote-page__quote-cards ${
              quotesOnCompare ? "quote-page__quote-cards--on-compare" : ""
            }`}
          >
            {isError ? (
              error
            ) : displayQuotes.length === 0 && !isQuotesLoading ? (
              noQuotes()
            ) : (
              <>
                {(isPnbPreference ||
                  utm_medium === "santoba" ||
                  utm_medium === "ida" ||
                  utm_source ||
                  c_id ||
                  (c_id && !customerDetails?.work_email) ||
                  afflid === "80938970-9k81-5f8l-a35d-9a4950d9adhz") &&
                  displayQuotes.length !== 0 && <WhiteListed></WhiteListed>}
                <>{quoteCards}</>
              </>
            )}
          </div>
          {displayDifferentTenureQuotes.length > 0 && (
            <>
              {" "}
              {displayQuotes.length === 0 ? (
                <></>
              ) : (
                <hr style={{ marginBottom: "54px" }} />
              )}
              <div
                className={`quote-page__quote-cards ${
                  quotesOnCompare ? "quote-page__quote-cards--on-compare" : ""
                }`}
              >
                {isError
                  ? error
                  : displayDifferentTenureQuotes.length === 0 &&
                    !isQuotesLoading
                  ? noQuotes()
                  : differentTenureQuoteCards}
              </div>
            </>
          )}
        </>
      )}

      <QuotesToRiderLoader
        contentKey="RidersPageLoader"
        showLoader={loaderForQuoteToRider}
      />

      <QuotesLoader
        show={showLoader}
        load={isQuotesLoading}
        setShowLoader={setShowLoader}
      />
      <div
        className={`modal modal-insta-cov fade show ${
          showBenefitsPopup ? "in" : ""
        }`}
        id="excl-offer-popup"
        role="dialog"
        style={{ height: "100%", display: showBenefitsPopup && "block" }}
      >
        <div
          className={`modal-dialog ${
            coverAmount != "₹ 50 Lakh"
              ? "modal-insta-cov-expl"
              : "modal-insta-cov-expl-left-card"
          }`}
        >
          <div
            className={`${
              coverAmount != "₹ 50 Lakh"
                ? "modal-content insta-cov-expl-wrap"
                : "modal-content insta-cov-expl-wrap-left"
            }`}
            id="instaCovExplWrap"
          >
            <div className="modal-body">
              <div className="row">
                <BandhanContent
                  isShown={isShown}
                  handleClick={handleClick}
                  showBenefitsPopup={showBenefitsPopup}
                  setShowBenefitsPopup={setShowBenefitsPopup}
                  resetState={resetState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!showLoader && loadingQuotes}

      {quotesOnCompare && <CompareContainer />}
    </div>
  );
}

export default QuotePage;
