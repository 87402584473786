import HttpClient from "../../api/httpClient";

export const createOrderId = data =>
  HttpClient("common/create_order_id", { method: "POST", data });

export const paymentDetailsToIc = data =>
  HttpClient("common/paymentDetailsToIc", { method: "POST", data });

export const savePaymentDetails = data =>
  HttpClient("common/save_transaction", { method: "POST", data });

export const updateQuotation = data =>
  HttpClient("common/update_quotation", { method: "POST", data });
export const enrollEmail = data =>
  HttpClient("common/send_enroll_email", { method: "POST", data });
