import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import "./documentUpload.scss";
import swal from "sweetalert";
import { CustomDropDown, Error } from "../../components";
import {
  Form,
  FormGroup,
  TextInput,
  CustomDropDownFont,
} from "../../components/Form/Form";
import SecureLS from "secure-ls";
import {
  checkDocType,
  checkFormat,
  checkSize,
  convert,
} from "../../utils/inputUtils";
import {
  ADDRESS_PROOF,
  AGE_PROOF,
  ID_PROOF,
  INCOME_PROOF_SN,
  INCOME_PROOF_SG,
  INCOME_PROOF_R,
  INCOME_PROOF_P,
  INCOME_PROOF_B,
  INCOME_PROOF_A,
} from "./dataBajaj";
import {
  setIsDocumentUploaded,
  setJourneyComplete,
  uploadDoc,
} from "./documentUpload.slice";
import { getAllData } from "../../api/getAllData";
import Loader from "../../components/Loader/Loader";
import { getOccupBajaj, bajajIncomeDocCode } from "../../utils/bajajCodeUtils";
import LaxmiGreeting from "../../components/LaxmiGreeting/LaxmiGreeting";
import { useCustomLocation } from "../../custom-hooks";
import { updateQuotation } from "../EligibilityPage/serviceApi";
import { useNavigation } from "../../custom-hooks/useNavigation";
import { selectCustomerDetails } from "../GreetingPage/greeting.slice";
import { getCKYCData } from "../ProposalPage/BajaProposalPage/bajajProposalPage.slice";
import moment from "moment";
async function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.blob();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
export default function DocumentUpload() {
  useNavigation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { occupationDetails, bajajProposalData, ckyc } = useSelector(
    state => state.bajajProposalPageForm,
  );
  // const
  const { search, pathname } = useLocation();
  console.log();
  const [countIncome, setCountIncome] = useState(0);
  const [ageProof, setAgeProof] = useState(
    occupationDetails.ageProof || bajajProposalData.ageProofText,
  );
  const [idProof, setIdProof] = useState(
    occupationDetails.idProof || bajajProposalData.idProofText,
  );
  const [addressProof, setAddressProof] = useState(
    occupationDetails.addressProof || bajajProposalData.addressProofText,
  );
  const [incomeProof, setIncomeProof] = useState(
    occupationDetails.incomeProof || bajajProposalData.incomeProofText,
  );
  useEffect(() => {
    setAgeProof(occupationDetails.ageProof || bajajProposalData.ageProofText);
    setIdProof(occupationDetails.idProof || bajajProposalData.idProofText);
    setAddressProof(
      occupationDetails.addressProof || bajajProposalData.addressProofText,
    );
    setIncomeProof(
      occupationDetails.incomeProof || bajajProposalData.incomeProofText,
    );
  }, [bajajProposalData, occupationDetails]);
  const [ageProofDoc, setAgeProofDoc] = useState(false);
  const [idProofDoc, setIdProofDoc] = useState(false);
  const [addressProofDoc, setAddressProofDoc] = useState(false);
  const [incomeProofDoc, setIncomeProofDoc] = useState(false);
  const [incomeProofDoc1, setIncomeProofDoc1] = useState(false);
  const [incomeProofDoc2, setIncomeProofDoc2] = useState(false);
  const [ageErr, setAgeErr] = useState(false);
  const [idErr, setIdErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [incomeErr, setIncomeErr] = useState(false);
  const [incomeErr1, setIncomeErr1] = useState(false);
  const [incomeErr2, setIncomeErr2] = useState(false);
  const [ageUploaded, setAgeUploaded] = useState(false);
  const [idUploaded, setIdUploaded] = useState(false);
  const [addressUploaded, setAddressUploaded] = useState(false);
  const [incomeUploaded, setIncomeUploaded] = useState(false);
  const [incomeUploaded1, setIncomeUploaded1] = useState(false);
  const [incomeUploaded2, setIncomeUploaded2] = useState(false);
  const documents =
    ckyc?.ckyc_response?.A51DownloadCkycResponse
      ?.DownloadFromCkycResponseDetails?.CkycDownloadResponseDetail
      ?.CKYCImageDetails?.CKYCImageDetails;

  const [currentUploaded, setCurrentUploaded] = useState(false);
  const Age = useRef("");
  const Id = useRef("");
  const Address = useRef("");
  const Income = useRef("");
  const Income1 = useRef("");
  const Income2 = useRef("");
  const [canProceed, setCanProceed] = useState(false);
  const { eligibilityDetails, appNo } = useSelector(
    state => state.eligibilityCard,
  );
  const { selectedEducation: education, selectedOccupation: occupation } =
    eligibilityDetails;
  const { annualIncome, age } = useSelector(
    ({ greeting }) => greeting.customerDetails,
  );
  const { isPnbPreference } = useSelector(state => state.greeting);
  const { selectedQuote } = useSelector(state => state.quote);
  const { enquiryId } = useCustomLocation();
  useEffect(() => {
    if (Object.keys(eligibilityDetails).length === 0)
      dispatch(getAllData(null, pathname, enquiryId));
  }, []);

  const { uploading, uploadResponse } = useSelector(
    ({ documentUpload }) => documentUpload,
  );
  const cd = useSelector(selectCustomerDetails);

  useEffect(() => {
    if (bajajProposalData?.pan && bajajProposalData?.kycFlag === "Y")
      dispatch(
        getCKYCData(
          { traceId: enquiryId, inputiidno: bajajProposalData?.pan },
          true,
        ),
      );
  }, [bajajProposalData]);

  const isNotRequired =
    isPnbPreference &&
    age <= 55 &&
    annualIncome >= 500000 &&
    occupation.includes("Salaried") &&
    (education === "Graduate" || education === "Post Graduate") &&
    Number(selectedQuote.sumAssured || selectedQuote.cover_amount) <= 10000000;
  // console.log(isNotRequired);
  const [hideButton, setHideButton] = useState(false);
  const ageRef = useRef();
  const addressRef = useRef();
  const idRef = useRef();
  const exToMime = {
    JPG: "image/jpeg",
    TIF: "image/tiff",
    PDF: "application/pdf",
  };

  const aadhar =
    documents && documents.find(item => item.CKYCImageType === "AadharCard");
  useEffect(() => {
    if (documents) {
      var date = moment().format("DDMMYYYY");
      const pan = documents.find(item => item.CKYCImageType === "PAN");
      if (pan)
        urltoFile(
          `data:${exToMime[pan.CKYCImageExtension]};base64,` +
            pan.CKYCImageData,
          `${appNo}_IdProof_${date}_2.${pan.CKYCImageExtension}`,
          `${exToMime[pan.CKYCImageExtension]}`,
        ).then(res => {
          setIdProofDoc(res);
          setHideButton(true);
          idRef.current.click();
        });
      const photo = documents.find(item => item.CKYCImageType === "Photograph");
      dispatch(
        uploadDoc({
          traceId: enquiryId,
          applicationNo: appNo,
          fileName: `${appNo}_Photograph_${date}`,
          fileType: "photograph",
          imageBase64: photo.CKYCImageData,
          fileExtension: photo.CKYCImageExtension,
          DocumentType: "45",
          DocumentName: "VP",
        }),
      );

      urltoFile(
        `data:${exToMime[aadhar.CKYCImageExtension]};base64,` +
          aadhar.CKYCImageData,
        `${appNo}_AgeProof_${date}_1.${aadhar.CKYCImageExtension}`,
        `${exToMime[aadhar.CKYCImageExtension]}`,
      ).then(res => {
        setAgeProofDoc(res);
        ageRef.current.click();
      });
      urltoFile(
        `data:${exToMime[aadhar.CKYCImageExtension]};base64,` +
          aadhar.CKYCImageData,
        `${appNo}_AddressProof_${date}_3.${aadhar.CKYCImageExtension}`,
        `${exToMime[aadhar.CKYCImageExtension]}`,
      ).then(res => {
        setAddressProofDoc(res);
        addressRef.current.click();
      });
    }
  }, [documents]);

  useEffect(() => {
    if (
      ageUploaded ||
      idUploaded ||
      addressUploaded ||
      incomeUploaded ||
      incomeUploaded1 ||
      incomeUploaded2
    ) {
      if (uploadResponse?.status === false) {
        swal("Upload Failed please reupload", {
          icon: "error",
        });
        setCanProceed(false);
        if (currentUploaded === "Age") {
          setAgeUploaded(false);
          setAgeProofDoc(false);
        } else if (currentUploaded === "Id") {
          setIdUploaded(false);
          setIdProofDoc(false);
        } else if (currentUploaded === "Income") {
          setIncomeUploaded(false);
          setIncomeProofDoc(false);
        } else if (currentUploaded === "Income1") {
          setIncomeUploaded1(false);
          setAddressProofDoc(false);
        } else if (currentUploaded === "Income2") {
          setIncomeUploaded2(false);
          setAddressProofDoc(false);
        } else if (currentUploaded === "Address") {
          setAddressUploaded(false);
          setAddressProofDoc(false);
        }
      } else if (uploadResponse?.status === true) {
        setCanProceed(true);
      }
    }
  }, [
    uploadResponse?.status,
    ageUploaded,
    idUploaded,
    addressUploaded,
    incomeUploaded,
    uploading.length,
  ]);

  const { isError } = useSelector(state => state.quote);
  const [occupCode, setOccupCode] = useState(
    getOccupBajaj(eligibilityDetails.selectedOccupation),
  );
  useEffect(() => {
    setOccupCode(getOccupBajaj(eligibilityDetails.selectedOccupation));
  }, [eligibilityDetails.selectedOccupation]);
  console.log(occupCode, eligibilityDetails.selectedOccupation);
  const [INCOME_PROOF, setINCOME_PROOF] = useState(INCOME_PROOF_A.slice());
  useEffect(() => {
    if (occupCode === "A") {
      setINCOME_PROOF(INCOME_PROOF_A.slice());
    } else if (occupCode === "B") {
      setINCOME_PROOF(INCOME_PROOF_B.slice());
    } else if (occupCode === "P") {
      setINCOME_PROOF(INCOME_PROOF_P.slice());
    } else if (occupCode === "R") {
      setINCOME_PROOF(INCOME_PROOF_R.slice());
    } else if (occupCode === "SG") {
      setINCOME_PROOF(INCOME_PROOF_SG.slice());
    } else if (occupCode === "SN") {
      setINCOME_PROOF(INCOME_PROOF_SN.slice());
    }
  }, [occupCode]);
  const handleSubmit = async (e, name) => {
    e.preventDefault();
    try {
      let newData;
      if (name === "Age") {
        if (ageUploaded) {
          setAgeUploaded(false);
          setAgeProofDoc(false);
          e.target.querySelector("#ageProofDoc").value = "";
        } else {
          newData = await convert(ageProofDoc);
          setAgeUploaded(true);
          setCurrentUploaded(name);
          let blob = new Blob([ageProofDoc], { type: ageProofDoc.type });
          Age.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: ageProofDoc.name?.split(".")[0],
              fileType: "age_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: ageProofDoc.type.split("/")[1],
              DocumentType: "45",
              DocumentName: "VP",
            }),
          );
        }
      }

      if (name === "Id") {
        if (idUploaded) {
          setIdUploaded(false);
          setIdProofDoc(false);
          e.target.querySelector("#idProof").value = "";
        } else {
          newData = await convert(idProofDoc);
          setIdUploaded(true);
          setCurrentUploaded(name);
          let blob = new Blob([idProofDoc], { type: idProofDoc.type });
          Id.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: idProofDoc.name?.split(".")[0],
              fileType: "id_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: idProofDoc.type.split("/")[1],
              DocumentType: "45",
            }),
          );
        }
      }

      if (name === "Income") {
        if (incomeUploaded) {
          setIncomeUploaded(false);
          setIncomeProofDoc(false);
          e.target.querySelector("#incomeProof").value = "";
        } else {
          newData = await convert(incomeProofDoc);
          setIncomeUploaded(true);
          setCurrentUploaded(name);
          let blob = new Blob([incomeProofDoc], { type: incomeProofDoc.type });
          Income.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc.name,
              fileType: "income_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: incomeProofDoc.type.split("/")[1],
              DocumentType: "46",
            }),
          );
        }
      }
      if (name === "Income1") {
        if (incomeUploaded1) {
          setIncomeUploaded1(false);
          setIncomeProofDoc1(false);
          e.target.querySelector("#incomeProof1").value = "";
        } else {
          newData = await convert(incomeProofDoc1);
          setIncomeUploaded1(true);
          setCurrentUploaded(name);
          let blob = new Blob([incomeProofDoc1], {
            type: incomeProofDoc1.type,
          });
          Income1.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc1.name,
              fileType: "income_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: incomeProofDoc1.type.split("/")[1],
              DocumentType: "46",
            }),
          );
        }
      }
      if (name === "Income2") {
        if (incomeUploaded2) {
          setIncomeUploaded2(false);
          setIncomeProofDoc2(false);
          e.target.querySelector("#incomeProof2").value = "";
        } else {
          newData = await convert(incomeProofDoc2);
          setIncomeUploaded2(true);
          setCurrentUploaded(name);
          let blob = new Blob([incomeProofDoc2], {
            type: incomeProofDoc2.type,
          });
          Income2.current.href = URL.createObjectURL(blob);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: incomeProofDoc2.name,
              fileType: "income_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: incomeProofDoc2.type.split("/")[1],
              DocumentType: "46",
            }),
          );
        }
      }
      if (name === "Address") {
        if (addressUploaded) {
          setAddressUploaded(false);
          setAddressProofDoc(false);

          e.target.querySelector("#addressProof").value = "";
        } else {
          newData = await convert(addressProofDoc);
          setCurrentUploaded(name);
          let blob = new Blob([addressProofDoc], {
            type: addressProofDoc.type,
          });
          Address.current.href = URL.createObjectURL(blob);
          setAddressUploaded(true);
          dispatch(
            uploadDoc({
              traceId: enquiryId,
              applicationNo: appNo,
              fileName: addressProofDoc.name?.split(".")[0],
              fileType: "address_proof",
              imageBase64: newData.split(",")[1],
              fileExtension: addressProofDoc.type.split("/")[1],
              DocumentType: "46",
            }),
          );
        }
      }
    } catch (e) {}
  };
  const check = (e, proof, num) => {
    if (checkSize(e) && checkDocType(e)) {
      if (num === 1) {
        setAgeProofDoc(checkFormat(e, proof, num, appNo));
        setAgeErr(false);
      }
      if (num === 2) {
        setIdProofDoc(checkFormat(e, proof, num, appNo));
        setIdErr(false);
      }
      if (num === 3) {
        setAddressProofDoc(checkFormat(e, proof, num, appNo));
        setAddressErr(false);
      }
      if (num === 4) {
        setIncomeProofDoc(checkFormat(e, proof, num, appNo));
        setIncomeErr(false);
      }
      if (num === 5) {
        setIncomeProofDoc1(checkFormat(e, proof, num, appNo));
        setIncomeErr1(false);
      }
      if (num === 6) {
        setIncomeProofDoc2(checkFormat(e, proof, num, appNo));
        setIncomeErr2(false);
      }
    } else {
      if (num === 1) {
        setAgeProofDoc(checkFormat(false));
        setAgeErr("File type or size is incorrect");
        setAgeUploaded(false);
      }
      if (num === 2) {
        setIdProofDoc(checkFormat(false));
        setIdErr("File type or size is incorrect");
        setIdUploaded(false);
      }
      if (num === 3) {
        setAddressProofDoc(checkFormat(false));
        setAddressErr("File type or size is incorrect");
        setAddressUploaded(false);
      }
      if (num === 4) {
        setIncomeProofDoc(checkFormat(false));
        setIncomeErr("File type or size is incorrect");
        setIncomeUploaded(false);
      }
      if (num === 5) {
        setIncomeProofDoc1(checkFormat(false));
        setIncomeErr1("File type or size is incorrect");
        setIncomeUploaded1(false);
      }
      if (num === 6) {
        setIncomeProofDoc2(checkFormat(false));
        setIncomeErr2("File type or size is incorrect");
        setIncomeUploaded2(false);
      }
    }
  };
  if (bajajProposalData?.kycFlag === "Y" && !ckyc?.status) return <Loader />;
  if (isError) return <Error errorMessage={isError} />;
  else if (Object.keys(eligibilityDetails).length === 0) return <Loader />;
  const ls = new SecureLS();
  return (
    <Page>
      <Center>
        <div className="application_no_document">
          {<span>Application Number: {appNo}</span>}
        </div>
        <div className="docGreeting">
          <LaxmiGreeting />
        </div>
        <div className="eligibility__header ">
          <div className="eligibility__greeting">
            <h2 className="heading__secondary doc_header">
              Almost done! Please upload documents.
            </h2>
          </div>
        </div>
        <h3
          className={`mobsubhead ${!documents ? "text-danger" : ""}`}
          style={{ color: documents && "green" }}
        >
          {!documents ? (
            <>
              Please upload JPEG, JPG, PDF, PNG, TIF, TIFF file types only. Size
              (upto 5MB)
            </>
          ) : (
            <>
              You can preview your uploaded CKYC documents and click on submit
            </>
          )}
        </h3>
        {bajajProposalData?.kycFlag === "Y" && (
          <Form formCenter uploadFile>
            <FormGroup>
              <Margin>
                <div className="filterMenuSelectWrap selectIncome">
                  <label className="title-label"></label>
                  <CustomDropDown
                    items={AGE_PROOF}
                    currentSelected={"Photograph"}
                    className="dropmob"
                    notAllowed="Select Age Proof"
                    name="ageProof"
                    id="ageProof"
                    onChange={e => {
                      setAgeProof(e);
                    }}
                    // disabled
                  />
                </div>
              </Margin>
            </FormGroup>

            <UploadWrap>
              <FormGroup sm uploadFile>
                <div className="file-name">{`${appNo}_Photograph}`}</div>
                <Preview className="uppercase">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={Age}
                    href={
                      "data:image/tiff;base64," +
                      documents?.find(
                        item => item.CKYCImageType === "Photograph",
                      )?.CKYCImageData
                    }
                    download
                  >
                    Preview
                  </a>
                </Preview>
              </FormGroup>
            </UploadWrap>
          </Form>
        )}
        <Form onSubmit={e => handleSubmit(e, "Age")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Age Proof</label>
                <CustomDropDown
                  items={AGE_PROOF}
                  currentSelected={ageProof}
                  className="dropmob"
                  notAllowed="Select Age Proof"
                  name="ageProof"
                  id="ageProof"
                  onChange={e => {
                    setAgeProof(e);
                  }}
                  // disabled
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="ageProofDoc"
                  id="ageProofDoc"
                  title="Age Proof"
                  disabled={ageProof === "Select Age Proof"}
                  onChange={e => {
                    check(e, "AgeProof", 1);
                  }}
                />
                <label
                  htmlFor="ageProofDoc"
                  style={{ display: aadhar && "none" }}
                >
                  Choose File
                </label>
              </div>
              {ageErr != false ? <p className="text-danger">{ageErr}</p> : ""}
            </Margin>
          </FormGroup>
          {/* <FormGroup md>
            <Margin>
              <DropDownLabel>Select Age Proof</DropDownLabel>
              <div className=' filterMenuSelectWrap selectIncome'>
                <CustomDropDownFont CustomFontBold>
                  <CustomDropDown
                    items={AGE_PROOF}
                    currentSelected={ageProof}
                    className='dropmob'
                    //	notAllowed="Select Age Proof"
                    name='ageProof'
                    id='ageProof'
                    onChange={(e) => {
                      setAgeProof(e);
                    }}
                  />
                </CustomDropDownFont>
              </div>
              <div className=' uploadIconMax'>
                <TextInput
                  upl
                  type='file'
                  name='ageProofDoc'
                  id='ageProofDoc'
                  title='Age Proof'
                  disabled={ageProof === "Select Age Proof"}
                  onChange={(e) => {
                    check(e, "AgeProof", 1);
                  }}
                />
              </div>
              {ageErr != false ? <p className='text-danger'>{ageErr}</p> : ""}
            </Margin>
          </FormGroup> */}
          <UploadWrap>
            <FormGroup sm uploadFile>
              <div className="file-name">
                {ageProofDoc.name || "No file Selected"}
              </div>
              <button
                type="submit"
                disabled={!ageProofDoc}
                ref={ageRef}
                style={{ display: aadhar && "none" }}
                className={
                  !ageProofDoc || uploading.includes("age_proof")
                    ? `btn btn--disabled btn--md uppercase bold buttton btn-upload`
                    : `btn ${
                        ageUploaded ? "btn--remove" : "btn--highlighted"
                      } btn--md uppercase bold buttton btn-upload`
                }
              >
                {uploading.includes("age_proof")
                  ? "Uploading..."
                  : ageUploaded
                  ? "Remove"
                  : "Upload"}
              </button>
              <Preview className="uppercase">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  ref={Age}
                  style={{
                    display:
                      ageUploaded && !uploading.includes("age_proof")
                        ? "contents"
                        : "none",
                  }}
                >
                  Preview
                </a>
              </Preview>
            </FormGroup>
          </UploadWrap>
          {/* <FormGroup sm className="btnuploadwrapper wrapMobileBajaj">
            <button
              type="submit"
              disabled={!ageProofDoc}
              className={
                !ageProofDoc || uploading.includes("age_proof")
                  ? `btn btn--disabled btn--md uppercase bold buttton `
                  : `btn ${
                      ageUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton `
              }
            >
              {uploading.includes("age_proof")
                ? "Uploading..."
                : ageUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                target="_blank"
                rel="noopener noreferrer"
                ref={Age}
                style={{
                  display:
                    ageUploaded && !uploading.includes("age_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup> */}
        </Form>
        <Form onSubmit={e => handleSubmit(e, "Id")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">ID Proof</label>
                <CustomDropDown
                  sm
                  items={ID_PROOF}
                  className="dropmob"
                  currentSelected={idProof}
                  notAllowed="Select ID Proof"
                  onChange={e => {
                    setIdProof(e);
                  }}
                  // disabled
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="idProof"
                  id="idProof"
                  title="Id Proof"
                  disabled={idProof === "Select ID Proof"}
                  onChange={e => {
                    check(e, "IdProof", 2);
                  }}
                />
                <label
                  htmlFor="idProof"
                  style={{ display: hideButton && "none" }}
                >
                  Choose File
                </label>
              </div>
              {idErr != false ? <p className="text-danger">{idErr}</p> : ""}
            </Margin>
          </FormGroup>
          {/* <FormGroup md>
            <Margin>
              <DropDownLabel>Select ID Proof</DropDownLabel>
              <div className="filterMenuSelectWrap selectIncome">
                <CustomDropDownFont CustomFontBold>
                  <CustomDropDown
                    sm
                    items={ID_PROOF}
                    className="dropmob"
                    currentSelected={idProof}
                    //	notAllowed="Select ID Proof"
                    onChange={(e) => {
                      setIdProof(e);
                    }}
                  />
                </CustomDropDownFont>
              </div>
              <div className=" uploadIconMax">
                <TextInput
                  upl
                  type="file"
                  name="idProof"
                  id="idProof"
                  title="Id Proof"
                  disabled={idProof === "Select ID Proof"}
                  onChange={(e) => {
                    check(e, "IdProof", 2);
                  }}
                />
                {idErr != false ? <p className="text-danger">{idErr}</p> : ""}
              </div>
            </Margin>
          </FormGroup> */}
          <UploadWrap>
            <FormGroup sm uploadFile>
              <div className="file-name">
                {idProofDoc.name || "No file Selected"}
              </div>
              <button
                type="submit"
                ref={idRef}
                disabled={!idProofDoc}
                style={{ display: hideButton && "none" }}
                className={
                  !idProofDoc || uploading.includes("id_proof")
                    ? "btn btn--disabled btn--md uppercase bold buttton btn-upload"
                    : `btn ${
                        idUploaded ? "btn--remove" : "btn--highlighted"
                      } btn--md uppercase bold buttton  btn-upload`
                }
              >
                {uploading.includes("id_proof")
                  ? "Uploading..."
                  : idUploaded
                  ? "Remove"
                  : "Upload"}
              </button>
              <Preview className="uppercase">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  ref={Id}
                  style={{
                    display:
                      idUploaded && !uploading.includes("id_proof")
                        ? "contents"
                        : "none",
                  }}
                >
                  Preview
                </a>
              </Preview>
            </FormGroup>
          </UploadWrap>
          {/* <FormGroup sm className="btnuploadwrapper wrapMobileBajaj">
            <button
              type="submit"
              disabled={!idProofDoc}
              className={
                !idProofDoc || uploading.includes("id_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  "
                  : `btn ${
                      idUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  `
              }
            >
              {uploading.includes("id_proof")
                ? "Uploading..."
                : idUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                target="_blank"
                rel="noopener noreferrer"
                ref={Id}
                style={{
                  display:
                    idUploaded && !uploading.includes("id_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup> */}
        </Form>
        <Form onSubmit={e => handleSubmit(e, "Address")} formCenter uploadFile>
          <FormGroup>
            <Margin>
              <div className="filterMenuSelectWrap selectIncome">
                <label className="title-label">Address Proof</label>
                <CustomDropDown
                  sm
                  items={ADDRESS_PROOF}
                  className="dropmob"
                  currentSelected={addressProof}
                  notAllowed="Select Address Proof"
                  // disabled
                  onChange={e => {
                    setAddressProof(e);
                  }}
                />
              </div>
              <div className="uploadIcon">
                <input
                  type="file"
                  name="addreaaProof"
                  id="addressProof"
                  title="Address Proof"
                  disabled={addressProof === "Select Address Proof"}
                  onChange={e => {
                    check(e, "AddressProof", 3);
                  }}
                />
                <label
                  htmlFor="addressProof"
                  style={{ display: aadhar && "none" }}
                >
                  Choose File
                </label>
              </div>
              {addressErr != false ? (
                <p className="text-danger">{addressErr}</p>
              ) : (
                ""
              )}
            </Margin>
          </FormGroup>
          {/* <FormGroup md>
            <Margin>
              <DropDownLabel>Select Address Proof</DropDownLabel>
              <div className="filterMenuSelectWrap selectIncome">
                <CustomDropDownFont CustomFontBold>
                  <CustomDropDown
                    sm
                    items={ADDRESS_PROOF}
                    className="dropmob"
                    currentSelected={addressProof}
                    //	notAllowed="Select Address Proof"
                    onChange={(e) => {
                      setAddressProof(e);
                    }}
                  />
                </CustomDropDownFont>
              </div>
              <div className=" uploadIconMax">
                <TextInput
                  upl
                  type="file"
                  name="addreaaProof"
                  id="addressProof"
                  title="Address Proof"
                  disabled={addressProof === "Select Address Proof"}
                  onChange={(e) => {
                    check(e, "AddressProof", 3);
                  }}
                />
                {addressErr != false ? (
                  <p className="text-danger">{addressErr}</p>
                ) : (
                  ""
                )}
              </div>
            </Margin>
          </FormGroup> */}
          <UploadWrap>
            <FormGroup sm uploadFile>
              <div className="file-name">
                {" "}
                {addressProofDoc.name || "No file Selected"}
              </div>
              <button
                type="submit"
                ref={addressRef}
                disabled={!addressProofDoc}
                style={{ display: aadhar && "none" }}
                className={
                  !addressProofDoc || uploading.includes("address_proof")
                    ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                    : `btn ${
                        addressUploaded ? "btn--remove" : "btn--highlighted"
                      } btn--md uppercase bold buttton  btn-upload`
                }
              >
                {uploading.includes("address_proof")
                  ? "Uploading..."
                  : addressUploaded
                  ? "Remove"
                  : "Upload"}
              </button>
              <Preview className="uppercase">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  ref={Address}
                  style={{
                    display:
                      addressUploaded && !uploading.includes("address_proof")
                        ? "contents"
                        : "none",
                  }}
                >
                  Preview
                </a>
              </Preview>
            </FormGroup>
          </UploadWrap>
          {/* <FormGroup sm className="btnuploadwrapper wrapMobileBajaj">
            <button
              type="submit"
              disabled={!addressProofDoc}
              className={
                !addressProofDoc || uploading.includes("address_proof")
                  ? "btn btn--disabled btn--md uppercase bold buttton  "
                  : `btn ${
                      addressUploaded ? "btn--remove" : "btn--highlighted"
                    } btn--md uppercase bold buttton  `
              }
            >
              {uploading.includes("address_proof")
                ? "Uploading..."
                : addressUploaded
                ? "Remove"
                : "Upload"}
            </button>
            <Preview className="uppercase">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                ref={Address}
                style={{
                  display:
                    addressUploaded && !uploading.includes("address_proof")
                      ? "contents"
                      : "none",
                }}
              >
                Preview
              </a>
            </Preview>
          </FormGroup> */}
        </Form>
        {incomeProof !== "Select" ? (
          <>
            <Form
              onSubmit={e => handleSubmit(e, "Income")}
              formCenter
              uploadFile
            >
              <FormGroup>
                <Margin>
                  <div className="filterMenuSelectWrap selectIncome">
                    <label className="title-label">Income Proof</label>
                    <CustomDropDown
                      sm
                      items={INCOME_PROOF}
                      className="dropmob"
                      currentSelected={incomeProof}
                      notAllowed="Select Income Proof"
                      onChange={e => {
                        setIncomeProof(e);
                      }}
                      // disabled
                    />
                  </div>
                  <div className="uploadIcon">
                    <input
                      type="file"
                      name="incomeProof"
                      id="incomeProof"
                      title="Income Proof"
                      disabled={incomeProof === "Select Income Proof"}
                      onChange={e => {
                        check(e, "IncomeProof", 4);
                      }}
                    />
                    <label htmlFor="incomeProof">Choose File</label>
                  </div>
                  {incomeErr != false ? (
                    <p className="text-danger">{incomeErr}</p>
                  ) : (
                    ""
                  )}
                </Margin>
              </FormGroup>
              <UploadWrap>
                <FormGroup sm uploadFile>
                  <div className="file-name">
                    {" "}
                    {incomeProofDoc.name || "No file Selected"}
                  </div>
                  <button
                    type="submit"
                    disabled={!incomeProofDoc}
                    className={
                      !incomeProofDoc || uploading.includes("income_proof")
                        ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload"
                        : `btn ${
                            incomeUploaded ? "btn--remove" : "btn--highlighted"
                          } btn--md uppercase bold buttton  btn-upload`
                    }
                  >
                    {incomeProofDoc &&
                    !incomeUploaded &&
                    uploading.includes("income_proof")
                      ? "Uploading..."
                      : incomeUploaded
                      ? "Remove"
                      : "Upload"}
                  </button>
                  <Preview className="uppercase">
                    <a
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                      ref={Income}
                      style={{
                        display:
                          incomeUploaded && !uploading.includes("income_proof")
                            ? "contents"
                            : "none",
                      }}
                    >
                      Preview
                    </a>
                  </Preview>
                </FormGroup>
              </UploadWrap>
            </Form>

            {countIncome > 0 && (
              <Form
                onSubmit={e => handleSubmit(e, "Income1")}
                formCenter
                uploadFile
                topMar
              >
                <FormGroup>
                  <Margin>
                    <div className="uploadIcon">
                      <input
                        type="file"
                        name="incomeProof"
                        id="incomeProof1"
                        title="Income Proof"
                        disabled={!incomeProofDoc}
                        onChange={e => {
                          check(e, "IncomeProof", 5);
                        }}
                      />
                      <label htmlFor="incomeProof">Choose File</label>
                    </div>
                    {incomeErr1 != false ? (
                      <p className="text-danger">{incomeErr1}</p>
                    ) : (
                      ""
                    )}
                  </Margin>
                </FormGroup>
                <UploadWrap>
                  <FormGroup sm uploadFile add_doc>
                    <button
                      type="submit"
                      disabled={!incomeProofDoc1}
                      className={
                        !incomeProofDoc1 || uploading.includes("income_proof")
                          ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload btn-extra"
                          : `btn ${
                              incomeUploaded1
                                ? "btn--remove"
                                : "btn--highlighted"
                            } btn--md uppercase bold buttton  btn-upload btn-extra`
                      }
                    >
                      {uploading.includes("income_proof")
                        ? "Uploading..."
                        : incomeUploaded1
                        ? "Remove"
                        : "Upload"}
                    </button>
                    <Preview className="uppercase" incomePrev>
                      <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        ref={Income1}
                        style={{
                          display:
                            incomeUploaded1 &&
                            !uploading.includes("income_proof")
                              ? "contents"
                              : "none",
                        }}
                      >
                        Preview
                      </a>
                    </Preview>
                  </FormGroup>
                </UploadWrap>
              </Form>
            )}
            {countIncome > 1 && (
              <Form
                onSubmit={e => handleSubmit(e, "Income2")}
                formCenter
                uploadFile
                topMar
              >
                <FormGroup>
                  <Margin>
                    <div className="uploadIcon">
                      <input
                        type="file"
                        name="incomeProof"
                        id="incomeProof2"
                        title="Income Proof"
                        disabled={!incomeProofDoc1}
                        onChange={e => {
                          check(e, "IncomeProof", 6);
                        }}
                      />
                      <label htmlFor="incomeProof">Choose File</label>
                    </div>
                    {incomeErr2 != false ? (
                      <p className="text-danger">{incomeErr2}</p>
                    ) : (
                      ""
                    )}
                  </Margin>
                </FormGroup>
                <UploadWrap>
                  <FormGroup sm uploadFile add_doc>
                    <button
                      type="submit"
                      disabled={!incomeProofDoc2}
                      className={
                        !incomeProofDoc2 || uploading.includes("income_proof")
                          ? "btn btn--disabled btn--md uppercase bold buttton  btn-upload btn-extra"
                          : `btn ${
                              incomeUploaded2
                                ? "btn--remove"
                                : "btn--highlighted"
                            } btn--md uppercase bold buttton  btn-upload btn-extra`
                      }
                    >
                      {uploading.includes("income_proof")
                        ? "Uploading..."
                        : incomeUploaded2
                        ? "Remove"
                        : "Upload"}
                    </button>
                    <Preview className="uppercase" incomePrev>
                      <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        ref={Income2}
                        style={{
                          display:
                            incomeUploaded2 &&
                            !uploading.includes("income_proof")
                              ? "contents"
                              : "none",
                        }}
                      >
                        Preview
                      </a>
                    </Preview>
                  </FormGroup>
                </UploadWrap>
              </Form>
            )}

            {countIncome < 2 && (
              <div className="additionalDocWrapper">
                <button
                  className="uploadIcon additionalDoc"
                  onClick={() => {
                    if (countIncome < 2) {
                      setCountIncome(countIncome + 1);
                    }
                  }}
                >
                  ADD ADDITIONAL DOCUMENT
                </button>
              </div>
            )}
          </>
        ) : (
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <div style={{ width: "480px", margin: "0 auto" }}>
              As you are eligible for "Income Proof Waiver" your policy will be
              issued basis a copy of your Official Identity Card and Visiting
              card.
            </div>
          </div>
        )}
        <div className="mobsubhead text-danger">
          <div>* All fields required.</div>
          <div>* File should not be password protected.</div>
        </div>

        <button
          disabled={
            !(
              ageUploaded &&
              idUploaded &&
              addressUploaded &&
              (isNotRequired || incomeUploaded) &&
              canProceed
            )
          }
          onClick={() => {
            updateQuotation({
              traceId: enquiryId,
              requestType: "document_upload",
            }).then(() => {
              dispatch(setIsDocumentUploaded(true));
              dispatch(
                setJourneyComplete(
                  {
                    traceId: enquiryId,
                    companyAlias: selectedQuote.company_alias,
                  },
                  history,
                ),
              ).then(() => {
                history.push({
                  pathname: "/thankyou",
                  search: `enquiryId=${enquiryId}`,
                });
              });
            });
          }}
          className={
            !(
              ageUploaded &&
              idUploaded &&
              addressUploaded &&
              (isNotRequired || incomeUploaded) &&
              canProceed
            )
              ? "btn btn--disabled btn--md uppercase bold buttton   submitBajaj"
              : "btn btn--highlighted btn--md uppercase bold buttton   submitBajaj"
          }
        >
          submit
        </button>
      </Center>
    </Page>
  );
}

const Page = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;
const Center = styled.div`
  position: relative;
  padding: 50px;
  min-width: 900px;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #e4e4e5;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  text-align: center;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 10px;
    width: 100% !important;
  }
`;

const Margin = styled.div`
  margin: 15px 10px;
`;

const Preview = styled.span`
  margin-left: 20px;
  font-size: small;
  position: relative;
  top: 25px;
  top: ${props => props.incomePrev && "-32px"};
  left: 160px;
  font-weight: bold;
  a {
    color: #f2581b;
  }
`;
const Title = styled.div`
  display: flex;
  justify-self: flex-start;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
`;

const DropDownLabel = styled.div`
  font-family: "Inter-Medium";
  font-size: 15px;
  line-height: 15px;
  color: #107591;
  padding: 0 10px;
  position: relative;
  top: -3px;
  z-index: 1;
`;

const UploadWrap = styled.div`
  position: relative;
  top: 10px;
`;
