import React from "react";
import { useLocation } from "react-router";
// import FooterGreeting from "./FooterGreeting";
import FooterQuotes from "./FooterQuotes";

const Footer = () => {
  const location = useLocation();

  if (location.pathname?.toLowerCase() === "/quotes") return <FooterQuotes />;
  //else if (location.pathname?.toLowerCase() === "/") return <FooterGreeting />;
  else return <FooterQuotes />;
};
export default Footer;
