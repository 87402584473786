import React from "react";
import "./Checkbox.scss";
import "styled-components/macro";

const Checkbox = ({
  id,
  checked,
  setCheck,
  elg,
  onChange,
  readOnly,
  proposal,
  onClick,
  defaultChecked,
  name,
  disabled = false,
}) => {
  return (
    <>
      <span>
        <input
          className="checkbox"
          name={name}
          checked={checked}
          //	onChange={(e) => setCheck(e.target.checked)}
          onChange={onChange}
          id={id}
          readOnly={readOnly}
          disabled={disabled}
          type="checkbox"
          onClick={onClick}
          defaultChecked={defaultChecked}
        ></input>
        <label
          css={`
            background-color: ${disabled ? "#dcdde0" : "auto"};
            border-color: ${disabled ? "#dcdde0" : "auto"};
          `}
          htmlFor={id}
          className={`checkbox__label ${elg ? "checkbox__label_elg" : ""} ${
            proposal ? "checkbox__label_proposal" : ""
          }`}
        ></label>
      </span>
    </>
  );
};

export default Checkbox;
