import { bajajNationality } from "../data";

export const basicDetailsData = data => {
  return [
    {
      alignLeft: true,
      id: "title",
      type: "select",
      value: data?.title || "Select",
      size: "md",
      label: "Title",
      items:
        data.gender === "Male"
          ? ["Mr", "Dr", "Master", "Lord", "Professor", "Sir"]
          : ["Ms", "Mrs", "Miss", "Dr", "Professor"],
      validations: {
        required: "Please Select Title",
      },
    },
    {
      alignLeft: true,
      id: "firstName",
      type: "text",
      size: "md",
      label: "First Name",
      value: data?.firstName,
      // readOnly: true,
      validations: {
        required: "First Name is required",
        maxLength: 50,
        onlyAlphaSpace: true,
      },
    },
    {
      alignLeft: true,
      id: "middleName",
      type: "text",
      size: "md",
      label: "Middle Name (optional)",
      value: data?.middleName || "",
      validations: {
        onlyAlphaSpace: true,
        maxLength: 30,
      },
    },
    {
      alignLeft: true,
      id: "lastName",
      type: "text",
      size: "md",
      label: "Last Name",
      value: data?.lastName,
      // readOnly: true,
      validations: {
        onlyAlphaSpace: true, 
        required: "Last Name is required",
        maxLength: 30,
        // onlyAlphaSpace: true,
      },
    },
    {
      alignLeft: true,
      id: "gender",
      type: "select",
      items: ["Male", "Female"],
      value: data?.gender,
      size: "md",
      readOnly: true,
      label: "Gender",
    },
    {
      id: "dob",
      alignLeft: true,
      type: "dob",
      size: "md",
      value: data?.dob,
      showAgeLabel: true,
      readOnly: true,
      label: "Date of Birth",
    },
    {
      alignLeft: true,
      id: "placeOfBirth",
      type: "text",
      size: "md",
      label: "Place of Birth (optional)",
      value: data?.birthPlace || "",
      validations: {
        maxLength: 50,
        onlyAlphaSpace: true,
        capitalize: true,
      },
    },
    {
      alignLeft: true,
      id: "nationality",
      type: "select",
      value: "Indian" || data?.nationalityText || "Select",
      validations: {
        required: "Please select your Nationality",
      },
      items: Object.keys(bajajNationality)
        ? Object.keys(bajajNationality).sort()
        : [],
      searchable: true,
      size: "md",
      label: "Nationality",
    },
    {
      alignLeft: true,
      id: "countryOfResidence",
      type: "select",
      size: "md",
      // value: data?.selectedCountry || data?.nationalityText || "India",
      value:
        data?.selectedCountry && data?.selectedCountry !== "Select Country"
          ? data?.selectedCountry
          : "India",
      readOnly: true,
      label: "Country of Residence",
    },
    {
      id: "meritalStatus",
      type: "select",
      items: ["Ms", "Miss"].includes(data.title)
        ? ["Single"]
        : ["Single", "Married", "Widowed", "Divorced"],
      label: "Marital Status ",
      size: "md",
      value: data?.maritalStatusText || "Select",
      alignLeft: true,
      validations: {
        required: "Marital Status is required",
      },
      addCorrespondingItemsOnChange: value => {
        if (value === "Married")
          return [
            {
              id: "spouseName",
              type: "text",
              label: "Spouse's Name",
              value: data?.spouseName,
              size: "md",
              alignLeft: true,
              validations: {
                maxLength: 50,
                required: "Spouse's Name is required",
                onlyAlphaSpace: true,
              },
            },
            {
              id: "spouseDob",
              label: "Spouse Date of Birth",
              size: "md",
              type: "dob",
              // value: "",
              alignLeft: true,
              showAgeLabel: true,
              value: data?.spouseDob?.split("-").reverse().join("/") || "",
              ageValidation: [18, 75],
              validations: {
                required: "Spouse Date of Birth is required",
                invalid: "Please fill valid dob",
              },
            },
            {
              id: "spousePlaceOfBirth",
              type: "text",
              label: "Spouse Place of Birth",
              size: "md",
              value: data?.spouseBirthPlace,
              alignLeft: true,
              validations: {
                maxLength: 50,
                required: "Spouse Place of Birth is required",
                capitalize: true,
                onlyAlphaSpace: true,
              },
            },
          ];
        return [];
      },
    },
    {
      id: "fatherName",
      type: "text",
      label: "Father's Name",
      size: "md",
      alignLeft: true,
      value: data?.fatherName,
      validations: {
        required: "Father's Name is required",
        fullName: true,
        maxLength: 50,
        onlyAlphaSpace: true,
      },
    },
    {
      id: "motherName",
      type: "text",
      label: "Mother's Name",
      value: data?.motherName,
      validations: {
        required: "Mother's Name is required",
        fullName: true,
        maxLength: 50,
        onlyAlphaSpace: true,
      },
      size: "md",
      alignLeft: true,
    },
    {
      id: "citizenship",
      type: "select",
      label: "Citizenship ",
      value: data?.isNri === "YES" ? "NRI" : "INDIAN",
      items: [],
      size: "md",
      readOnly: true,
      alignLeft: true,
    },
    // {
    //   id: "accedentalDeathBenefitPayout",
    //   type: "select",
    //   value: "Select",
    //   items: [
    //     "100 % lumpsum",
    //     "100 % Level Instalments",
    //     "100 % Increasing Instalments",
    //     "50 % Lumpsum + 50 % increasing Instalments",
    //     "50 % Lumpsum + 50 % Level Instalments",
    //   ],
    //   label: "Accidental Death Benefit Payout",
    //   size: "md",
    //   alignLeft: true,
    //   validations: {
    //     required: "Accidental Death Benefit is required",
    //   },
    // },
    {
      id: "IsPoliticallyExposed",
      type: "toggle",
      items: ["Yes", "No"],

      smallToggle: true,
      label: "Are you Politically Exposed Person (PEP)?",
      size: "lg",
      alignLeft: true,
      value: data?.pep === "Y" ? "Yes" : "No",
      alignCenter: true,
      validations: {
        required: "This field is required",
      },
      addCorrespondingItemsOnChange: value => {
        if (value === "Yes")
          return [
            {
              id: "politicalDescription",
              type: "text",
              label: "Description",
              size: "md",
              type: "textArea",
              value: data?.pepDesc || "",
              alignLeft: true,
              validations: {
                required: "Description is required",
                maxLength: 100,
              },
            },
          ];
        return [];
      },
    },
  ];
};
