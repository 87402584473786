import React from "react";

import CriteriaList from "../PopupWithTitle/CriteriaList/CriteriaList";
import CriteriaListItem from "../../Popup/PopupWithTitle/CriteriaListItem";
import PopupWithTitle from "../PopupWithTitle/PopupWithTitle";

function HouseWifeCriteriaPopup({ event, ...props }) {
  const { setShowHouseWifePopup } = props;

  const handleClose = () => {
    setShowHouseWifePopup(false);
  };

  const title = `Eligibility criteria for Housewife`;

  return (
    <PopupWithTitle title={title} onClose={handleClose} {...props}>
      {fadeOut => (
        <>
          <CriteriaList>
            <CriteriaListItem>
              You and your husband should be Graduate.
            </CriteriaListItem>

            <CriteriaListItem>
              Your husband's annual income should be at least INR{" "}
              <strong className="criteria-list-text-strong">5 lakhs</strong>
            </CriteriaListItem>

            <CriteriaListItem>
              Maximum Sum assured allowed is 50% of your spouse's cover subject
              to maximum of INR{" "}
              <strong className="criteria-list-text-strong"> 50 Lakh</strong>
            </CriteriaListItem>
          </CriteriaList>

          <div className="nri-popup-btn-group">
            <button
              className="nri-popup-btn nri-popup-btn-primary nri-popup-btn-proceed"
              onClick={() => fadeOut(() => setShowHouseWifePopup(false))}
            >
              CONFIRM & PROCEED
            </button>
          </div>
        </>
      )}
    </PopupWithTitle>
  );
}

export default HouseWifeCriteriaPopup;
