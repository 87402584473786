import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import {
  medicalDetails3SendData,
  saveMedicalDetails3,
} from "./MedicalDetails3.slice";
import {
  CustomDropDownFont,
  Form,
  FormGroup as FG,
  Label,
  TextAreaProposal,
  TextInputElg,
} from "../../../../components/Form/Form";
import { useForm } from "react-hook-form";
import "./medicalDetails3.scss";
import styled from "styled-components/macro";
import { SummaryTile, ToggleTile } from "../../components";
import {
  dateUtil,
  noCopyAndPaste,
  numOnly,
  numOnlyInput,
} from "../../../../utils/inputUtils";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import CustomDropDown from "../../../../components/CutomDropDown/CustomDropDown";
import { countries } from "../../BajaProposalPage/formsdata/Countries.utils";
import moment from "moment";

const FormGroup = styled(FG)`
  height: auto !important;
`;

const getYear = (dateString = "") => parseInt(dateString.split("/")[2]);

const isValidDateRange = (dateString = "") => {
  let isValidDate = true;
  const givenYear = getYear(dateString);
  if (givenYear > 2050 || givenYear < 1960) isValidDate = false;
  return isValidDate;
};

const isFutureDate = (dateString = "") => {
  const givenDate = moment(dateString, "DD/MM/YYYY");

  const today = moment();

  const isGivenDateIsFuture = today.isBefore(givenDate);

  return isGivenDateIsFuture;
};

const isPastDate = (dateString = "") => {
  const givenDate = moment(dateString, "DD/MM/YYYY");

  const today = moment();

  const isGivenDateIsPast = today.isAfter(givenDate);

  return isGivenDateIsPast;
};

// window.moment = moment;

const validateMaxDate = (dateString = "", maxDays, { invert = false }) => {
  const givenDate = moment(dateString, "DD/MM/YYYY");

  const today = moment();

  const differenceFromToday = today.diff(givenDate, "days");

  if (invert) return differenceFromToday <= parseInt(maxDays);

  return differenceFromToday >= parseInt(maxDays) * -1;
};

const MedicalDetails3 = ({ position, setPosition, index }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, formState, setError, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const { isValid } = formState;
  const { gender } = useSelector(({ greeting }) => greeting.customerDetails);
  const { medicalDetailsTwo } = useSelector(state => state.medicalDetailsTwo);
  const { medicalDetailsThree } = useSelector(state => state.medicalDetails3);
  const { medicalDetailsFour } = useSelector(state => state.medicalDetails4);
  const LastIndex = useSelector(state => state.iciciProposal?.position);
  const [edit, setEdit] = useState(false);
  const [disability, setDisability] = useState(medicalDetailsThree?.disability);
  const [gynecologicalComplications, setGynecologicalComplications] = useState(
    medicalDetailsThree?.gynecologicalComplications,
  );
  const [pregnent, setPregnent] = useState(medicalDetailsThree?.pregnent);
  const [injury, setInjury] = useState(medicalDetailsThree?.injury);
  const [covid, setCovid] = useState(medicalDetailsThree?.covid);
  const [covidIsolateWithSymptom, setCovidIsolateWithSymptom] = useState(
    medicalDetailsThree?.covidIsolateWithSymptom,
  );
  const [covidIsolate, setcovidIsolate] = useState(
    medicalDetailsThree?.covidIsolate,
  );
  const [covidSymptom, setCovidSymptom] = useState(
    medicalDetailsThree?.covidSymptom,
  );
  const [cOccupation, setOccupation] = useState(
    medicalDetailsThree?.cOccupation,
  );

  const [chq14, setChq14] = useState(medicalDetailsThree?.chq14);
  const [chq15, setChq15] = useState(medicalDetailsThree?.chq15);
  const [chq18, setChq18] = useState(medicalDetailsThree?.chq18);
  const [chq19, setChq19] = useState(medicalDetailsThree?.chq19);
  const [chq20, setChq20] = useState(medicalDetailsThree?.chq20);
  const [chq21, setChq21] = useState(medicalDetailsThree?.chq21);
  const [chq22, setChq22] = useState(medicalDetailsThree?.chq22);
  const [chq23, setChq23] = useState(
    medicalDetailsThree?.chq23 || countries[0],
  );
  const [chq26, setChq26] = useState(medicalDetailsThree?.chq26);
  const [chq27, setChq27] = useState(medicalDetailsThree?.chq27);
  const [chq28, setChq28] = useState(medicalDetailsThree?.chq28);

  const [errorChq23, setErrorChq23] = useState(false);

  const [chq16Error, setChq16Error] = useState(false);
  const [chq17Error, setChq17Error] = useState(false);
  const [chq24Error, setChq24Error] = useState(false);
  const [chq25Error, setChq25Error] = useState(false);

  const [currentlyInvestigated, setCurrentlyInvestigated] = useState(
    medicalDetailsThree?.currentlyInvestigated,
  );

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    setEdit(false);
    const newData = {
      gynecologicalComplicationsText: data.gynecologicalComplicationsText,
      pregnentDuration: data.pregnentDuration,
      disabilityText: data.disabilityText,
      injuryText: data.injuryText,
      injury,
      disability,
      pregnent,
      gynecologicalComplications,
      covidIsolateWithSymptom,
      covid,
      covidIsolate,
      covidSymptom,
      cOccupation,
      covidOneDescription: data.covidOneDescription,
      covidTwoDescription: data.covidTwoDescription,
      covidThreeDescription: data.covidThreeDescription,
      covidFourDescription: data.covidFourDescription,
      covidFiveDescription: data.covidFiveDescription,
      covidSix: chq14,
      covidSixSubone: chq15,
      covidSixSuboneA: data.chq16,
      covidSixSuboneB: data.chq17,
      covidSixSuboneC: chq18,
      covidSeven: chq19,
      covidEight: chq20,
      covidNine: chq21,
      covidTen: chq22,
      covidTenSubOne: chq23,
      covidTenSubTwo: data.chq24,
      covidTenSubThree: data.chq25,
      covidEleven: chq26,
      covidTwelve: chq27,
      covidTwelveSubone: chq28,
      covidTwelveSuboneText: data.chq29,
      currentlyInvestigated: currentlyInvestigated,
      investigationDtls: data.investigationDtls,
    };
    const sendData = medicalDetails3SendData(newData);
    saveProposalForm(sendData, () => {
      dispatch(saveMedicalDetails3(newData));
      dispatch(setPosition(position + 1));
    });
  };

  useEffect(() => {
    if (medicalDetailsThree) {
      if (position === index) {
        setEdit(true);
      }
      if (position > index) {
        setEdit(false);
      }
    }
  }, [position]);
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Medical Question - Set 3</CardTitle>
      </Card>
    </div>
  );

  let chq16Date = "";
  let chq17Date = "";
  let chq24Date = "";
  let chq25Date = "";

  const attachRefChq16 = ref => {
    register(ref);
    chq16Date = ref;
  };
  const attachRefChq17 = ref => {
    register(ref);
    chq17Date = ref;
  };
  const attachRefChq24 = ref => {
    register(ref);
    chq24Date = ref;
  };
  const attachRefChq25 = ref => {
    register(ref);
    chq25Date = ref;
  };

  const handleChange = (event, refDate) => {
    dateUtil(event, refDate);
    const { value: givenDate } = event.target;
    return givenDate;
  };

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  const a = medicalDetailsTwo?.occupationHazard?.length
    ? medicalDetailsTwo?.occupationHazard?.length
    : 0;
  const b = medicalDetailsTwo?.employedInArmy?.length
    ? medicalDetailsTwo?.employedInArmy?.length
    : 0;
  const c = medicalDetailsTwo?.lostWeight?.length
    ? medicalDetailsTwo?.lostWeight?.length
    : 0;
  const d = medicalDetailsTwo?.familyDisorder?.length
    ? medicalDetailsTwo?.familyDisorder?.length
    : 0;
  const e = medicalDetailsTwo?.hospitalized?.length
    ? medicalDetailsTwo?.hospitalized?.length
    : 0;
  const f = medicalDetailsFour?.otherDiagnosedDetails?.length
    ? medicalDetailsFour?.otherDiagnosedDetails?.length
    : 0;
  const g = medicalDetailsThree?.disabilityText?.length
    ? medicalDetailsThree?.disabilityText?.length
    : 0;
  const h = medicalDetailsThree?.injuryText?.length
    ? medicalDetailsThree?.injuryText?.length
    : 0;
  const i = medicalDetailsThree?.gynecologicalComplicationsText?.length
    ? medicalDetailsThree?.gynecologicalComplicationsText?.length
    : 0;

  const totalCount = a + b + c + d + e + f + g + h + i;
  if (medicalDetailsTwo) {
    return (
      <div className="medicalDetailsThree">
        {medicalDetailsThree && !edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Medical Question - Set 3
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(setPosition(index));
                  setEdit(true);
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
              {totalCount > 250 && LastIndex >= 13 ? (
                <h6
                  style={{
                    color: "red",
                    marginLeft: "2rem",
                    padding: "15px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to
                  mention the descriptions is 250 characters and{" "}
                  {totalCount <= 250 ? 250 - totalCount : 0} character available
                </h6>
              ) : (
                <></>
              )}
            </CardTitle>

            {gender === "F" && (
              <SummaryTile
                label=" Have you ever suffered/are suffering from or have undergone
    investigations or treatment for any gynecological
    complications such as disorders of
    cervix,uterus,ovaries,breast, breast lump,cyst etc."
                data={gynecologicalComplications ? "Yes" : "No"}
                detail={medicalDetailsThree.gynecologicalComplicationsText}
              />
            )}
            <SummaryTile
              label=" Do you have any congenital defect/ abnormality/ physical
    deformity/ handicap?"
              data={disability ? "Yes" : "No"}
              detail={medicalDetailsThree.disabilityText}
            />
            <SummaryTile
              label=" Did you have any ailment/injury/accident requiring
    treatment/medication for more than<br></br>a week or have you
    availed leave for more than 5 days on medical grounds in the
    last two years?"
              data={injury ? "Yes" : "No"}
              detail={medicalDetailsThree.injuryText}
            />
            {gender === "F" && (
              <SummaryTile
                label="Are you pregnant at present?"
                data={pregnent ? "Yes" : "No"}
                detail={medicalDetailsThree.pregnentDuration}
              />
            )}
            <SummaryTile
              label="In the last 3 months have you been tested positive for COVID-19"
              data={covid ? "Yes" : "No"}
              detail={medicalDetailsThree.covidOneDescription}
            />
            <SummaryTile
              label=" In the last 3 months have you been self-isolated with symptoms
          on medical advice?"
              data={covidIsolateWithSymptom ? "Yes" : "No"}
              detail={medicalDetailsThree.covidTwoDescription}
            />
            <SummaryTile
              label=" In the last 1 month have you been advised to self-isolate due to
          COVID-19 (excluding mandatory government orders to remain at
          home)"
              data={covidIsolate ? "Yes" : "No"}
              detail={medicalDetailsThree.covidThreeDescription}
            />
            <SummaryTile
              label=" In the last 1 month did you have persistent cough, fever, raised
          temperature or been in contact with an individual suspected or
          confirmed to have COVID-19?"
              data={covidSymptom ? "Yes" : "No"}
              detail={medicalDetailsThree.covidFourDescription}
            />
            <SummaryTile
              label="Do you work in an occupation, where you have a higher risk to
          get in close contact with COVID-19 patients or with coronavirus
          contaminated material?"
              data={cOccupation ? "Yes" : "No"}
              detail={medicalDetailsThree.covidFiveDescription}
            />
            <SummaryTile
              label="Have you ever been tested positive for COVID 19"
              data={chq14 ? "Yes" : "No"}
            />

            {chq14 && (
              <SummaryTile
                label="Are you fully recovered?"
                data={chq15 ? "Yes" : "No"}
              />
            )}

            {chq15 && (
              <SummaryTile
                label="Date of diagnosis"
                data={
                  medicalDetailsThree?.chq16 ||
                  medicalDetailsThree?.covidSixSuboneA ||
                  ""
                }
              />
            )}

            {chq15 && (
              <SummaryTile
                label="Date of recovery"
                data={
                  medicalDetailsThree?.chq17 ||
                  medicalDetailsThree?.covidSixSuboneB ||
                  ""
                }
              />
            )}

            {chq15 && (
              <SummaryTile
                label="Were you  hospitalized for Covid19 treatment"
                data={chq18 ? "Yes" : "No"}
              />
            )}

            <SummaryTile
              label="In the last 1 months have you or any of your family member been self-isolated with symptoms on medical advice?(excluding mandatory government orders to remain at home)"
              data={chq19 ? "Yes" : "No"}
            />

            <SummaryTile
              label="In the last 1 month did you have persistent cough, fever ,sore throat, nausea, vomiting ,diarrhea, difficulty in breathing ,loss of smell and taste any other symptoms of coronavirus (COVID-19) and advised to do a Covid test or you/your family member have been in contact with an individual suspected or confirmed  to have COVID-19?"
              data={chq20 ? "Yes" : "No"}
            />

            <SummaryTile
              label=" Do you work in an occupation like health care worker/Corona warrior Include (General Practitioners, Doctors, Hospital Doctors, Surgeons, Therapists, Nurses, Pathologist, paramedics, Pharmacist, Ward helpers, Individuals working in Hospitals/ Clinics having novel coronavirus (SARS-CoV-2/COVID-19) Ward ?)  where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material ?"
              data={chq21 ? "Yes" : "No"}
            />

            <SummaryTile
              label="Have you travelled abroad in last 15 days  or intend to travel abroad in next 15 days"
              data={chq22 ? "Yes" : "No"}
            />

            {chq22 && <SummaryTile label="Country" data={chq23} />}

            {chq22 && (
              <SummaryTile
                label="Expected date of arrival in India"
                data={
                  medicalDetailsThree?.chq24 ||
                  medicalDetailsThree?.covidTenSubTwo ||
                  ""
                }
              />
            )}

            {chq22 && (
              <SummaryTile
                label="Expected date of leaving India"
                data={
                  medicalDetailsThree?.chq25 ||
                  medicalDetailsThree?.covidTenSubThree ||
                  ""
                }
              />
            )}

            <SummaryTile
              label="Have you taken Covid 19 vaccine in last 7 days"
              data={chq26 ? "Yes" : "No"}
            />
            <SummaryTile
              label="Have you experienced any side effects post Covid vaccination"
              data={chq27 ? "Yes" : "No"}
            />
            {chq27 && (
              <SummaryTile
                label="Was hospitalization required to treat side effects"
                data={chq28 ? "Yes" : "No"}
              />
            )}
            {chq27 && (
              <SummaryTile
                label="Details of side effect experienced"
                detail={
                  medicalDetailsThree?.chq29 ||
                  medicalDetailsThree?.covidTwelveSuboneText ||
                  ""
                }
              />
            )}
            <SummaryTile
              label="Have you ever been or currently being investigated, charge sheeted, prosecuted or convicted or acquittal or having pending charges in respect of any criminal/civil offences in any court of law in India or abroad?"
              data={currentlyInvestigated ? "Yes" : "No"}
            />
            {currentlyInvestigated && (
              <SummaryTile
                label="Investigation details ?"
                detail={medicalDetailsThree?.investigationDtls || ""}
              />
            )}
          </Card>
        ) : (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Medical Question - Set 3
            </CardTitle>
            <h6
              style={{
                color: totalCount <= 250 ? "#107591" : "red",
                marginLeft: "2rem",
                padding: "15px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to mention
              the descriptions is 250 characters and{" "}
              {totalCount <= 250 ? 250 - totalCount : 0} character available
            </h6>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              {gender === "F" && (
                <>
                  <FormGroup style={{ textAlign: "left" }} xlg formPage>
                    <div className="healthQuestion">
                      Have you ever suffered/are suffering from or have
                      undergone investigations or treatment for any
                      gynecological complications such as disorders of
                      cervix,uterus,ovaries,breast, breast lump,cyst etc.
                    </div>
                    <RadioComponent
                      value={gynecologicalComplications}
                      name="gynecologicalComplications"
                      updateChecked={() =>
                        setGynecologicalComplications(
                          !gynecologicalComplications,
                        )
                      }
                    />
                  </FormGroup>
                  {gynecologicalComplications && (
                    <FormGroup proposalCardL eligibilityPage>
                      <TextAreaProposal
                        md
                        name="gynecologicalComplicationsText"
                        placeholder=" "
                        ref={register({ required: true })}
                      />
                      <Label md htmlFor="gynecologicalComplicationsText">
                        Please Specify
                      </Label>
                      {errors.gynecologicalComplicationsText && (
                        <p style={{ color: "red", textAlign: "left" }}>
                          This field is required
                        </p>
                      )}
                    </FormGroup>
                  )}
                </>
              )}
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  Do you have any congenital defect/ abnormality/ physical
                  deformity/ handicap?
                </div>
                <RadioComponent
                  value={disability}
                  name="disability"
                  updateChecked={() => setDisability(!disability)}
                />
              </FormGroup>
              {disability && (
                <FormGroup proposalCardL eligibilityPage>
                  <TextAreaProposal
                    md
                    name="disabilityText"
                    placeholder=" "
                    defaultValue={
                      medicalDetailsThree
                        ? medicalDetailsThree.disabilityText
                        : ""
                    }
                    ref={register({ required: true })}
                  />
                  <Label md htmlFor="disabilityText">
                    Please Specify
                  </Label>
                  {errors.disabilityText && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      This field is required
                    </p>
                  )}
                </FormGroup>
              )}
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  Did you have any ailment/ injury/ accident requiring
                  treatment/medication for more than a week or have you availed
                  leave for more than 5 days on medical grounds in the last two
                  years?
                </div>
                <RadioComponent
                  value={injury}
                  name="injury"
                  updateChecked={() => setInjury(!injury)}
                />
              </FormGroup>
              {injury && (
                <FormGroup proposalCardL eligibilityPage>
                  <TextAreaProposal
                    md
                    name="injuryText"
                    placeholder=" "
                    defaultValue={
                      medicalDetailsThree ? medicalDetailsThree.injuryText : ""
                    }
                    ref={register({ required: true })}
                  />
                  <Label md htmlFor="injuryText">
                    Please Specify
                  </Label>
                  {errors.injuryText && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      This field is required
                    </p>
                  )}
                </FormGroup>
              )}
              {gender === "F" && (
                <>
                  <FormGroup style={{ textAlign: "left" }} xlg formPage>
                    <div className="healthQuestion">
                      Are you pregnant at present?
                    </div>
                    <RadioComponent
                      value={pregnent}
                      name="pregnent"
                      updateChecked={() => setPregnent(!pregnent)}
                    />
                  </FormGroup>
                  {pregnent && (
                    <FormGroup proposalCardL eligibilityPage>
                      <TextAreaProposal
                        md
                        name="pregnentDuration"
                        placeholder=" "
                        // maxLength="2"
                        defaultValue={
                          medicalDetailsThree
                            ? medicalDetailsThree.pregnentDuration
                            : ""
                        }
                        onInput={numOnlyInput}
                        ref={register({ required: true })}
                      />
                      <Label md htmlFor="pregnentDuration">
                        Confirm the Duration (in weeks)
                      </Label>
                      {errors.pregnentDuration && (
                        <p style={{ color: "red", textAlign: "left" }}>
                          This field is required
                        </p>
                      )}
                    </FormGroup>
                  )}
                </>
              )}
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  In the last 3 months have you been tested positive for
                  COVID-19
                </div>
                <RadioComponent
                  value={covid}
                  name="covid"
                  updateChecked={() => setCovid(!covid)}
                />
                {/* added covid text */}
                {covid && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="covidOneDescription"
                      placeholder=" "
                      defaultValue={
                        medicalDetailsThree
                          ? medicalDetailsThree.covidOneDescription
                          : ""
                      }
                      ref={register({ required: true })}
                    />
                    <Label md htmlFor="covidOneDescription">
                      Please Specify
                    </Label>
                    {errors.covidOneDescription && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
                {/* added covid text end------ */}
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  In the last 3 months have you been self-isolated with symptoms
                  on medical advice?
                </div>
                <RadioComponent
                  value={covidIsolateWithSymptom}
                  name="covidIsolateWithSymptom"
                  updateChecked={() =>
                    setCovidIsolateWithSymptom(!covidIsolateWithSymptom)
                  }
                />
                {/* added covid text */}
                {covidIsolateWithSymptom && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="covidTwoDescription"
                      placeholder=" "
                      defaultValue={
                        medicalDetailsThree
                          ? medicalDetailsThree.covidTwoDescription
                          : ""
                      }
                      ref={register({ required: true })}
                    />
                    <Label md htmlFor="covidTwoDescription">
                      Please Specify
                    </Label>
                    {errors.covidTwoDescription && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
                {/* added covid text end------ */}
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  In the last 1 month have you been advised to self-isolate due
                  to COVID-19 (excluding mandatory government orders to remain
                  at home)
                </div>
                <RadioComponent
                  value={covidIsolate}
                  name="covidIsolate"
                  updateChecked={() => setcovidIsolate(!covidIsolate)}
                />
                {covidIsolate && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="covidThreeDescription"
                      placeholder=" "
                      defaultValue={
                        medicalDetailsThree
                          ? medicalDetailsThree.covidThreeDescription
                          : ""
                      }
                      ref={register({ required: true })}
                    />
                    <Label md htmlFor="covidThreeDescription">
                      Please Specify
                    </Label>
                    {errors.covidThreeDescription && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
                {/* added covid text end------ */}
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  In the last 1 month did you have persistent cough, fever,
                  raised temperature or been in contact with an individual
                  suspected or confirmed to have COVID-19?
                </div>
                <RadioComponent
                  value={covidSymptom}
                  name="covidSymptom"
                  updateChecked={() => setCovidSymptom(!covidSymptom)}
                />
                {covidSymptom && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="covidFourDescription"
                      placeholder=" "
                      defaultValue={
                        medicalDetailsThree
                          ? medicalDetailsThree.covidFourDescription
                          : ""
                      }
                      ref={register({ required: true })}
                    />
                    <Label md htmlFor="covidFourDescription">
                      Please Specify
                    </Label>
                    {errors.covidFourDescription && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
                {/* added covid text end------ */}
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  Do you work in an occupation, where you have a higher risk to
                  get in close contact with COVID-19 patients or with
                  coronavirus contaminated material?
                </div>
                <RadioComponent
                  value={cOccupation}
                  name="cOccupation"
                  updateChecked={() => setOccupation(!cOccupation)}
                />
                {cOccupation && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="covidFiveDescription"
                      placeholder=" "
                      defaultValue={
                        medicalDetailsThree
                          ? medicalDetailsThree.covidFiveDescription
                          : ""
                      }
                      ref={register({ required: true })}
                    />
                    <Label md htmlFor="covidFiveDescription">
                      Please Specify
                    </Label>
                    {errors.covidFiveDescription && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
                {/* added covid text end------ */}
              </FormGroup>

              <FormGroup style={{ textAlign: "left" }} xlg formPage>
                <div className="healthQuestion">
                  Have you ever been tested positive for COVID 19
                </div>
                <RadioComponent
                  value={chq14}
                  name="chq14"
                  updateChecked={() => setChq14(!chq14)}
                />
                {chq14 && (
                  <FormGroup style={{ textAlign: "left" }} xlg formPage>
                    <div className="healthQuestion">
                      Are you fully recovered?
                    </div>
                    <RadioComponent
                      value={chq15}
                      name="chq15"
                      updateChecked={() => setChq15(!chq15)}
                    />
                    {chq15 && (
                      <>
                        <FormGroup style={{ textAlign: "left" }} md FormGroup>
                          <TextInputElg
                            name="chq16"
                            id="chq16"
                            proposal
                            ref={register({ required: true, minLength: 10 })}
                            onKeyDown={noCopyAndPaste}
                            onChange={evt => {
                              const givenDate = handleChange(evt, chq16Date);
                              if (givenDate.length === 10) {
                                if (
                                  !isValidDateRange(givenDate) ||
                                  isFutureDate(givenDate)
                                ) {
                                  setError("chq16", {
                                    type: "manual",
                                    message: "Invalid date",
                                  });
                                } else {
                                  clearErrors("chq16");
                                }
                              }
                            }}
                            maxLength={10}
                            defaultValue={
                              medicalDetailsThree?.chq16 ||
                              medicalDetailsThree?.covidSixSuboneA ||
                              ""
                            }
                          />
                          <Label md htmlFor="chq16">
                            Date of diagnosis
                          </Label>
                          {errors?.chq16 && (
                            <p style={{ color: "red", textAlign: "left" }}>
                              Invalid date
                            </p>
                          )}
                        </FormGroup>
                        <FormGroup style={{ textAlign: "left" }} md FormGroup>
                          <TextInputElg
                            id="chq17"
                            proposal
                            name="chq17"
                            ref={register({ required: true, minLength: 10 })}
                            maxLength={10}
                            onKeyDown={noCopyAndPaste}
                            onChange={evt => {
                              const givenDate = handleChange(evt, chq17Date);
                              if (givenDate.length === 10) {
                                if (
                                  !isValidDateRange(givenDate) ||
                                  isFutureDate(givenDate)
                                ) {
                                  setError("chq17", {
                                    type: "manual",
                                    message: "Invalid date",
                                  });
                                } else clearErrors("chq17");
                              }
                            }}
                            defaultValue={
                              medicalDetailsThree?.chq17 ||
                              medicalDetailsThree?.covidSixSuboneB ||
                              ""
                            }
                          />
                          <Label htmlFor="chq17">Date of recovery</Label>
                          {errors?.chq17 && (
                            <p style={{ color: "red", textAlign: "left" }}>
                              Invalid date
                            </p>
                          )}
                        </FormGroup>
                        <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                          <div className="healthQuestion">
                            Were you hospitalized for Covid19 treatment
                          </div>
                          <RadioComponent
                            value={chq18}
                            name="chq18"
                            updateChecked={() => setChq18(!chq18)}
                          />
                        </FormGroup>
                      </>
                    )}
                  </FormGroup>
                )}
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  In the last 1 months have you or any of your family member
                  been self-isolated with symptoms on medical advice?(excluding
                  mandatory government orders to remain at home)
                </div>
                <RadioComponent
                  value={chq19}
                  name="chq19"
                  updateChecked={() => setChq19(!chq19)}
                />
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  In the last 1 month did you have persistent cough, fever ,sore
                  throat, nausea, vomiting ,diarrhea, difficulty in breathing
                  ,loss of smell and taste any other symptoms of coronavirus
                  (COVID-19) and advised to do a Covid test or you/your family
                  member have been in contact with an individual suspected or
                  confirmed  to have COVID-19?
                </div>
                <RadioComponent
                  value={chq20}
                  name="chq20"
                  updateChecked={() => setChq20(!chq20)}
                />
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  Do you work in an occupation like health care worker/Corona
                  warrior Include (General Practitioners, Doctors, Hospital
                  Doctors, Surgeons, Therapists, Nurses, Pathologist,
                  paramedics, Pharmacist, Ward helpers, Individuals working in
                  Hospitals/ Clinics having novel coronavirus
                  (SARS-CoV-2/COVID-19) Ward ?)  where you have a higher risk to
                  get in close contact with COVID-19 patients or with
                  coronavirus contaminated material ?
                </div>
                <RadioComponent
                  value={chq21}
                  name="chq21"
                  updateChecked={() => setChq21(!chq21)}
                />
              </FormGroup>

              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  Have you travelled abroad in last 15 days  or intend to travel
                  abroad in next 15 days
                </div>
                <RadioComponent
                  value={chq22}
                  name="chq22"
                  updateChecked={() => setChq22(!chq22)}
                />
                {chq22 && (
                  <>
                    <FormGroup proposalCard md formPage>
                      <CustomDropDownFont rdr CustomFontBold>
                        <CustomDropDown
                          dropDownTitle="Country"
                          propDropDown
                          searchable={true}
                          items={countries}
                          selectedItem={chq23}
                          onChange={e => setChq23(e)}
                        />
                      </CustomDropDownFont>
                      {errorChq23 ? <p className="error">Country</p> : null}
                    </FormGroup>
                    <FormGroup style={{ textAlign: "left" }} md FormGroup>
                      <TextInputElg
                        id="chq24"
                        name="chq24"
                        proposal
                        ref={register({ required: true, minLength: 10 })}
                        maxLength={10}
                        onKeyDown={noCopyAndPaste}
                        onChange={evt => {
                          const givenDate = handleChange(evt, chq24Date);
                          if (givenDate.length === 10) {
                            if (
                              !isValidDateRange(givenDate) ||
                              !isPastDate(givenDate) ||
                              !validateMaxDate(givenDate, 364, { invert: true })
                            ) {
                              setError("chq24", {
                                type: "manual",
                                message: "Invalid date",
                              });
                            }
                          }
                        }}
                        defaultValue={
                          medicalDetailsThree?.chq24 ||
                          medicalDetailsThree?.covidTenSubTwo ||
                          ""
                        }
                      />
                      <Label htmlFor="chq24">
                        Expected date of arrival in India
                      </Label>
                      {errors?.chq24 && (
                        <p style={{ color: "red", textAlign: "left" }}>
                          Invalid date
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup style={{ textAlign: "left" }} md FormGroup>
                      <TextInputElg
                        id="chq25"
                        name="chq25"
                        proposal
                        onKeyDown={noCopyAndPaste}
                        ref={register({ required: true, minLength: 10 })}
                        onChange={evt => {
                          const givenDate = handleChange(evt, chq25Date);
                          if (givenDate.length === 10) {
                            if (
                              !isValidDateRange(givenDate) ||
                              isPastDate(givenDate) ||
                              !validateMaxDate(givenDate, 364)
                            ) {
                              setError("chq25", {
                                type: "manual",
                                message: "Invalid date",
                              });
                            }
                          }
                        }}
                        maxLength={10}
                        defaultValue={
                          medicalDetailsThree?.chq25 ||
                          medicalDetailsThree?.covidTenSubThree ||
                          ""
                        }
                      />
                      <Label htmlFor="chq17">
                        Expected date of leaving India
                      </Label>
                      {errors?.chq25 && (
                        <p style={{ color: "red", textAlign: "left" }}>
                          Invalid date
                        </p>
                      )}
                    </FormGroup>
                  </>
                )}
              </FormGroup>

              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  Have you taken Covid 19 vaccine in last 7 days
                </div>
                <RadioComponent
                  value={chq26}
                  name="chq26"
                  updateChecked={() => setChq26(!chq26)}
                />
              </FormGroup>
              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  Have you experienced any side effects post Covid vaccination
                </div>
                <RadioComponent
                  value={chq27}
                  name="chq27"
                  updateChecked={() => setChq27(!chq27)}
                />
                {chq27 && (
                  <>
                    <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                      <div className="healthQuestion">
                        Was hospitalization required to treat side effects
                      </div>
                      <RadioComponent
                        value={chq28}
                        name="chq28"
                        updateChecked={() => setChq28(!chq28)}
                      />
                    </FormGroup>
                    <FormGroup proposalCardL eligibilityPage>
                      <TextAreaProposal
                        placeholder=" "
                        id="chq29"
                        name="chq29"
                        ref={register({ required: true })}
                        defaultValue={
                          medicalDetailsThree?.chq29 ||
                          medicalDetailsThree?.covidTwelveSuboneText ||
                          ""
                        }
                      />
                      <Label md htmlFor="chq29">
                        Details of side effect experienced
                      </Label>
                      {errors?.chq29 && (
                        <p style={{ color: "red", textAlign: "left" }}>
                          This field is required
                        </p>
                      )}
                    </FormGroup>
                  </>
                )}
              </FormGroup>

              <FormGroup style={{ textAlign: "left" }} xlg FormGroup>
                <div className="healthQuestion">
                  Have you ever been or currently being investigated, charge
                  sheeted, prosecuted or convicted or acquittal or having
                  pending charges in respect of any criminal/civil offences in
                  any court of law in India or abroad?
                </div>
                <RadioComponent
                  value={currentlyInvestigated}
                  name="currentlyInvestigated"
                  updateChecked={() =>
                    setCurrentlyInvestigated(!currentlyInvestigated)
                  }
                />
                {currentlyInvestigated && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      placeholder=" "
                      id="investigationDtls"
                      name="investigationDtls"
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsThree?.investigationDtls || ""
                      }
                      maxLength={250}
                    />
                    <Label md htmlFor="investigationDtls">
                      Investigation details ?
                    </Label>
                    {errors?.investigationDtls && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </FormGroup>

              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  disabled={!isValid}
                  className={
                    !isValid
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                  }
                >
                  PROCEED TO MEDICAL QUESTION - SET 4
                </button>
              </FormGroup>
            </Form>
          </Card>
        )}
      </div>
    );
  }
};

const Details = styled.div`
  white-space: normal;
  font-size: 16px;
  color: #0c7896;
  .data {
    margin: 5px 0;
    font-size: medium;
    color: black;
  }
  @media only screen and (max-width: 760px) {
    font-size: 12px;
  }
`;

export default MedicalDetails3;
