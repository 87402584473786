export const FATCADetailsFormData = ({ countries, ...data } = {}) => {
  return [
    {
      id: "resident",
      label: "Are you resident of any country outside India?",
      readOnly: true,
      items: ["Yes", "No"],
      // value: data?.residentOutside === "Y" ? "Yes" : "No",
      value: data?.isNri === "YES" ? "Yes" : "No",
      size: "xlg",
      smallToggle: true,
      type: "toggle",
      nextLine: true,
      extraMargin: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "resident1",
                label: "Name of Country",
                size: "md",
                value:
                  data?.selectedCountry || data?.residentCountry || "Select",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Name of Country is required",
                },
                type: "select",
                items: countries,
                searchable: true,
                readOnly: true,
              },
              {
                id: "resident2",
                label: "Address",
                size: "md",
                value: data?.residentCountryAddress || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill address",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "tax",
      label:
        "Are you a Tax Resident of a country (or countries)outside India (Country in which you are taxed because of your Residence/ Service/ Trade/ Business etc)?",
      items: ["Yes", "No"],
      // value: data?.taxresidentOutside === "Y" ? "Yes" : "No",
      value: data?.isNri === "YES" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      readOnly: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "tax1",
                label: "TIN/Funtional Equivalent No. Issuing Country",
                size: "md",
                value: data?.taxresidentCountry || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                },
              },
              {
                id: "tax2",
                label: "TIN/Funtional Equivalent No ",
                size: "md",
                value: data?.taxresidentCountryTin || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                  custom: [
                    {
                      pattern: /^(?=.*\d).{1,}$/,
                      message: "Minimum 1 digit is required",
                    },
                  ],
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "telephoneNo",
      label: "Are you holding Telephone Number in Jurisdiction outside India?",
      items: ["Yes", "No"],
      value: data?.telephoneOutside === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "telephoneNo1",
                label: "Mobile No with country code",
                size: "md",
                value: data?.telephoneMobile || "",
                type: "text",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                  invalid: "Please fill valid number",
                  numOnly: true,
                  maxLength: 15,
                },
              },
              {
                id: "telephoneNo2",
                label: "Landline no with ISD Code",
                size: "md",
                value: data?.telephoneLandline || "",
                type: "text",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  maxLength: 15,
                  numOnly: true,
                  required: "Please fill this field",
                  invalid: "Please fill valid number",
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "instructions",
      label:
        "Have you given standing instructions (other than with respect to a depository account) to transfer funds to an account maintained in a jurisdiction outside India)?",
      items: ["Yes", "No"],
      value: data?.accountOutside === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      extraMargin: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "instructions1",
                label: "Please specify",
                size: "md",
                type: "textArea",
                value: data?.accountOutsideText || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "attorney",
      label:
        "Have you executed currently effective power of attorney or signatory authority granted to a person with an address in a jurisdiction outside India?",
      items: ["Yes", "No"],
      value: data?.attorneyOutside === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "attorney1",
                label:
                  "Name of the person whom power of attorney or signatory authority granted ",
                size: "xlg",
                value: data?.attorneyPerson || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  onlyAlphaSpace: true,
                  required: "Please fill this field",
                },
              },
              {
                id: "attorney2",
                label: "Address",
                size: "md",
                value: data?.attorneyAddress || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                  freeText: true,
                },
              },
              {
                id: "attorney3",
                label: "Contact No",
                size: "md",
                value: data?.attorneyContact || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                  numOnly: true,
                  maxLength: 10,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "holdMail",
      label:
        "Have you given a “hold mail” instruction or “in-care-of”address in a jurisdiction outside India?",
      items: ["Yes", "No"],
      value: data?.holdmailOutside === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "holdMail1",
                label: "Details",
                size: "md",
                type: "textArea",
                value: data?.holdmailText || "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Please fill this field",
                },
              },
            ];
          default:
            return [];
        }
      },
    },
  ];
};
