import React, { useState, useRef } from "react";
import { useOutsideClick } from "../../custom-hooks";
import DropDownHeadTwo from "./DropDownHeadTwo/DropDownHeadTwo";
import DropDownItems from "./DropDownItems/DropDownItems";

import "./drop-down.scss";

function DropDown({ label, items }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(items[0]);

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setOpen(false));

  const handleItemClick = item => {
    setSelected(item);
    setOpen(false);
  };

  return (
    <div className="drop-down" ref={dropDownRef}>
      <DropDownHeadTwo
        label={label}
        select={selected}
        handleClick={() => setOpen(!open)}
      />
      {open && (
        <DropDownItems
          items={items}
          handleClick={handleItemClick}
          selected={selected}
        />
      )}
    </div>
  );
}

export default DropDown;
