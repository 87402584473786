import { useState } from "react";
import { useSelector } from "react-redux";
import { useCustomLocation } from "../../custom-hooks";
// import { appendSendData } from "./IcicProposalPage/IcicProposal.slice";
import { saveProposal } from "./serviceApi";
import { swal } from "sweetalert";

function useSaveProposalForm() {
  const { enquiryId } = useCustomLocation();
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const [isLoading, setIsLoading] = useState(false);
  // const dispatch = useDispatch();
  const { previousSendData } = useSelector(
    ({ iciciProposal }) => iciciProposal,
  );
  const { appNo } = useSelector(({ eligibilityCard }) => eligibilityCard);
  const prevSendData = { ...previousSendData };
  if (company_alias === "bajaj_life") {
    Object.entries(prevSendData).forEach(val => {
      const key = val[0];
      const value = val[1];
      if (typeof value === "string") {
        if (value?.includes("/") && value?.length === 10) {
          prevSendData[key] = value?.split("/").reverse().join("-");
        }
      }
    });
    if (!prevSendData.familyDetails) {
      if (prevSendData.familyHealthDetailsJson)
        prevSendData.familyDetails = JSON.parse(
          prevSendData.familyHealthDetailsJson,
        );
    }
  }
  async function saveProposalForm(sendData, onSuccess = () => {}) {
    setIsLoading(true);
    try {
      const response = await saveProposal({
        ...prevSendData,
        ...sendData,
        traceId: enquiryId,
        applicationNo: appNo,
      });
      setIsLoading(false);
      if (response.data) onSuccess(response);
      return response;
    } catch (error) {
      console.error(error);
      swal("something went wrong");
      setIsLoading(false);
    }
  }
  return { saveProposalForm, isLoading };
}

export default useSaveProposalForm;
