import React, { useState, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import {
  dateUtil,
  getMaritalStatus,
  noCopyAndPaste,
  numOnly,
  numOnlyInput,
} from "../../../../utils/inputUtils";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  AgeWrapper,
  RadioContainer,
  Radio,
  RadioLabel,
  RadioButton,
  TextInputElg,
  CustomDropDownFont,
} from "../../../../components/Form/Form";
import { insuredDetails1Schema } from "../../../../utils/validationSchema";
import { data } from "./data";
import "./insuredDetails1.scss";
import {
  insuredDetails1SendData,
  saveInsuredDetails1,
} from "./insuredDetails1.slice";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";
import { CustomDropDown } from "../../../../components";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";

const ProposerForm = ({ position, setPosition, index, insFor }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [age, setAge] = useState("");
  const PROPOSER_LA = ["Self"];
  const PROPOSER_LA_DEPENDENT = [
    "Father",
    "Grand Father",
    "Grand Mother",
    "Mother",
    "Spouse",
  ];
  const { proposerDetails } = useSelector(state => state.proposalForm);

  const [proposalLa, setProposalLa] = useState(
    insFor === "self" ? PROPOSER_LA[0] : PROPOSER_LA_DEPENDENT[0],
  );

  const [idmartialStatus, setMartialStatus] = useState(
    proposerDetails.martialStatus ? proposerDetails.martialStatus : null,
  );
  const [errorMartialStatus, setErrorMartialStatus] = useState(null);
  const [errorProposalLa, setErrorProposalLa] = useState(false);
  const [test, setTest] = useState(false);
  const { insuredDetails1 } = useSelector(state => state.insuredDetails1Form);
  const { proposalOccupationDetails } = useSelector(
    state => state.proposalOccupationForm,
  );
  const { selectedPlanDetails } = useSelector(state => state.selectedPlanForm);

  useEffect(() => {
    if (selectedPlanDetails === "Dependent") setProposalLa("Select");
    else if (selectedPlanDetails === "self") setProposalLa("Self");
    else setProposalLa(selectedPlanDetails);
  }, [selectedPlanDetails]);

  const { customerDetails } = useSelector(state => state.greeting);
  const { eligibilityDetails } = useSelector(state => state.eligibilityCard);

  const MARTIAL_STATUS = ["Select", "Single", "Married", "Widowed", "Divorced"];

  const { register, handleSubmit, errors, formState, setValue } = useForm({
    resolver: yupResolver(insuredDetails1Schema),
    mode: "onChange",
    defaultValues: {
      idGender: customerDetails["gender"],
    },
  });

  useEffect(() => {
    setValue("idGender", customerDetails["gender"]);
    register({ name: "idGender" });
  }, []);
  const validateSelect = useCallback(() => {
    if (!idmartialStatus || idmartialStatus === "Self") {
      setErrorMartialStatus(true);
    } else {
      setErrorMartialStatus(false);
      setTest(false);
    }
    if (proposalLa === "Select") {
      setErrorProposalLa(true);
    } else {
      setErrorProposalLa(false);
    }
  }, [idmartialStatus, proposalLa]);

  const { isValid } = formState;

  useEffect(() => {
    if (test) {
      validateSelect();
    }
  }, [test, validateSelect]);
  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };

  const handleChange = event => {
    setAge(dateUtil(event, date));
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    setEdit(false);
    data["idDob"] = data["idDob"].replace(/ /g, "");
    const da = data.idDob.split("/").reverse().join("-");
    delete data.idDob;

    setTest(true);

    const newData = {
      ...data,
      idmartialStatus: proposerDetails.martialStatus,
      proposalLa,
      idDob: da,
    };

    if (proposalLa !== "Select") {
      const sendData = insuredDetails1SendData(newData);
      saveProposalForm(sendData, () => {
        dispatch(saveInsuredDetails1(newData));
        dispatch(setPosition(position + 1));
      });
    }
  };

  useEffect(() => {
    if (insuredDetails1) {
      if (position === index) {
        setEdit(true);
      }
    }
  }, [position]);

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC> Insured Details 1</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  if (proposalOccupationDetails) {
    return (
      <>
        {insuredDetails1 && !edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 1
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEdit(true);
                  dispatch(setPosition(index));
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
            </CardTitle>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {data.map(d => (
                <FormGroup md eligibilityPage key={d.name}>
                  <Label md editMode htmlFor={d.name}>
                    {d.label}
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {insuredDetails1[d.name]}
                  </span>
                  {d.name === "idDob"
                    ? age && (
                      <AgeWrapper
                        style={{
                          top: "50px",
                          right: "0px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        {age} years
                      </AgeWrapper>
                    )
                    : null}
                </FormGroup>
              ))}
              <FormGroup md eligibilityPage>
                <Label md editMode htmlFor="gender">
                  Gender
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredDetails1["idGender"] === "M" ? "Male" : "Female"}
                </span>
              </FormGroup>
              <FormGroup md eligibilityPage>
                <Label md editMode htmlFor="idmartialStatus">
                  Marital Status
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredDetails1["idmartialStatus"]}
                </span>
              </FormGroup>
              <FormGroup md eligibilityPage>
                <Label md editMode htmlFor="proposalLa">
                  Relation of Proposer to LA
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {proposalLa}
                </span>
              </FormGroup>
            </div>
          </Card>
        ) : edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 1
            </CardTitle>

            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              {data.map(d => (
                <FormGroup proposalCard eligibilityPage key={d.id}>
                  <TextInputElg
                    onKeyDown={noCopyAndPaste}
                    proposal
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    readOnly={insFor === "self" ? true : false}
                    defaultValue={eligibilityDetails[d.ename]}
                    onChange={d.name === "idDob" ? handleChange : null}
                    onInput={
                      d.name === "mobileNo" || d.name === "pinCode"
                        ? numOnlyInput
                        : null
                    }
                    placeholder={d.placeHolder}
                    //  autoComplete={d.autoComplete}
                    autoComplete="none"
                    ref={d.name === "idDob" ? attachRef : register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {d.name === "idDob"
                    ? age && (
                      <AgeWrapper
                        style={{
                          top: "50px",
                          right: "0px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        {age} years
                      </AgeWrapper>
                    )
                    : null}
                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Gender"
                    propDropDown
                    items={["Male", "Female"]}
                    selectedItem={
                      customerDetails["gender"] === "M" ? "Male" : "Female"
                    }
                    currentSelected={
                      customerDetails["gender"] === "M" ? "Male" : "Female"
                    }
                    // disabled={insFor === "self" ? true : false}
                    ref={register}
                    // onChange={(e) => setGender(e)}
                    disabled
                  />
                </CustomDropDownFont>
              </FormGroup>
              {/* <FormGroup md Icici>
                <RadioContainer md proposer mob>
                  <Radio
                    type="radio"
                    id="Male"
                    name="idGender"
                    value="M"
                    readOnly={insFor === "self" ? true : false}
                    defaultChecked={customerDetails["gender"] === "M"}
                    ref={register}
                  />
                  <RadioLabel
                    error={errors.idGender && "danger"}
                    sm
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none", textAlign: "unset" }
                        : { pointerEvents: "all", textAlign: "unset" }
                    }
                    proposer
                    htmlFor="Male"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Male
                      </span>
                    </div>
                  </RadioLabel>
                  <Radio
                    type="radio"
                    id="Female"
                    name="idGender"
                    ref={register}
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none" }
                        : { pointerEvents: "all" }
                    }
                    readOnly={insFor === "self" ? true : false}
                    value="F"
                    defaultChecked={customerDetails["gender"] === "F"}
                  />
                  <RadioLabel
                    error={errors.idGender && "danger"}
                    sm
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none", textAlign: "unset" }
                        : { pointerEvents: "all", textAlign: "unset" }
                    }
                    proposer
                    htmlFor="Female"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Female
                      </span>
                    </div>
                  </RadioLabel>
                  {errors.idGender && (
                    <p className="errorG">{errors.idGender.message}</p>
                  )}
                </RadioContainer>
              </FormGroup> */}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">MARITAL STATUS</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="MARITAL STATUS"
                    propDropDown
                    items={MARTIAL_STATUS}
                    selectedItem={proposerDetails.martialStatus}
                    onChange={e => setMartialStatus(e)}
                    disabled={insFor === "self" ? true : false}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Relation of Proposer to LA
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Relation of Proposer to LA"
                    propDropDown
                    items={
                      insFor === "self" ? PROPOSER_LA : PROPOSER_LA_DEPENDENT
                    }
                    selectedItem={proposalLa}
                    onChange={e => {
                      setProposalLa(e);
                    }}
                  />
                </CustomDropDownFont>

                {errorProposalLa ? (
                  <p className="error">Please Select Proposer LA</p>
                ) : null}
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup xlg eligibilityPage propSubWrap>
                <button
                  type="submit"
                  disabled={!isValid}
                  className={
                    isValid
                      ? "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                      : "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                  }
                >
                  PROCEED TO INSURED DETAILS 2
                </button>
              </FormGroup>
            </Form>
          </Card>
        ) : (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 1
            </CardTitle>

            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              {data.map(d => (
                <FormGroup proposalCard eligibilityPage key={d.id}>
                  <TextInputElg
                    proposal
                    onKeyDown={noCopyAndPaste}
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    readOnly={insFor === "self" ? true : false}
                    defaultValue={eligibilityDetails[d.ename]}
                    onChange={d.name === "idDob" ? handleChange : null}
                    onInput={
                      d.name === "mobileNo" || d.name === "pinCode"
                        ? numOnlyInput
                        : null
                    }
                    placeholder={d.placeHolder}
                    // autoComplete={d.autoComplete}
                    autoComplete="none"
                    ref={d.name === "idDob" ? attachRef : register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {d.name === "idDob"
                    ? age && (
                      <AgeWrapper
                        style={{
                          top: "50px",
                          right: "0px",
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        {age} years
                      </AgeWrapper>
                    )
                    : null}
                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Gender"
                    propDropDown
                    items={["Male", "Female"]}
                    selectedItem={
                      customerDetails["gender"] === "M" ? "Male" : "Female"
                    }
                    currentSelected={
                      customerDetails["gender"] === "M" ? "Male" : "Female"
                    }
                    // disabled={insFor === "self" ? true : false}
                    ref={register}
                    // onChange={(e) => setGender(e)}
                    disabled
                  />
                </CustomDropDownFont>
              </FormGroup>
              {/* <FormGroup md Icici>
                <RadioContainer md proposer mob>
                  <Radio
                    type="radio"
                    id="Male"
                    name="idGender"
                    value="M"
                    readOnly={insFor === "self" ? true : false}
                    defaultChecked={customerDetails["gender"] === "M"}
                    ref={register}
                  />
                  <RadioLabel
                    error={errors.idGender && "danger"}
                    sm
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none", textAlign: "unset" }
                        : { pointerEvents: "all", textAlign: "unset" }
                    }
                    proposer
                    htmlFor="Male"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Male
                      </span>
                    </div>
                  </RadioLabel>
                  <Radio
                    type="radio"
                    id="Female"
                    name="idGender"
                    ref={register}
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none" }
                        : { pointerEvents: "all" }
                    }
                    readOnly={insFor === "self" ? true : false}
                    value="F"
                    defaultChecked={customerDetails["gender"] === "F"}
                  />
                  <RadioLabel
                    error={errors.idGender && "danger"}
                    sm
                    style={
                      insFor === "self"
                        ? { pointerEvents: "none", textAlign: "unset" }
                        : { pointerEvents: "all", textAlign: "unset" }
                    }
                    proposer
                    htmlFor="Female"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Female
                      </span>
                    </div>
                  </RadioLabel>
                  {errors.idGender && (
                    <p className="errorG">{errors.idGender.message}</p>
                  )}
                </RadioContainer>
              </FormGroup> */}
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">MARTIAL STATUS</div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="MARTIAL STATUS"
                    propDropDown
                    items={MARTIAL_STATUS}
                    selectedItem={proposerDetails.martialStatus}
                    currentSelected={proposerDetails.martialStatus}
                    disabled={
                      insFor === "self"
                        ? proposerDetails.martialStatus
                          ? true
                          : false
                        : false
                    }
                    onChange={e => setMartialStatus(e)}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
                {errorMartialStatus ? (
                  <p className="error">Please Select Martial Status</p>
                ) : null}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Relation of Proposer to LA
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Relation of Proposer to LA"
                    propDropDown
                    items={
                      insFor === "self" ? PROPOSER_LA : PROPOSER_LA_DEPENDENT
                    }
                    selectedItem={proposalLa}
                    onChange={e => {
                      setProposalLa(e);
                    }}
                  />
                </CustomDropDownFont>
                {errorProposalLa ? (
                  <p className="error">Please Select Proposer LA</p>
                ) : null}
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup xlg eligibilityPage propSubWrap>
                <button
                  type="submit"
                  disabled={!isValid}
                  className={
                    isValid
                      ? "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                      : "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                  }
                >
                  PROCEED TO INSURED DETAILS 2
                </button>
              </FormGroup>
            </Form>
          </Card>
        )}
      </>
    );
  }
};

export default ProposerForm;
