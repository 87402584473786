import { createSlice } from "@reduxjs/toolkit";
import { setIsError } from "../QuotePage/quotePageSlice";
import {
  createUser,
  checkpinCode,
  quotationData,
  contactme,
  emailPNB,
  updateUser,
} from "./serviceApi";

const greeting = createSlice({
  name: "greeting",
  initialState: {
    customerDetails: {},
    startAnnualIncome: false,
    regionDetails: false,
    response: false,
    isLoading: false,
    err: false,
    status: false,
    userCreate: false,
    erro: false,
    isPnbPreference: false,
    previous: true,
    userId: false,
    traceId: false,
    showContinueButton: false,
    emailDiscount: false,
    form1Submitted: false,
    showFooter: false,
    isWipro: false,
    preferredInsurance: "",
    showModal: false,
  },
  reducers: {
    setMobileNo: (state, action) => {
      state.customerDetails.mobileNo = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setStartAnnualIncome: (state, action) => {
      state.startAnnualIncome = action.payload;
    },
    createUserData: (state, action) => {
      state.customerDetails = action.payload;
    },
    addUserData: (state, action) => {
      state.customerDetails = action.payload;
    },
    createRegionData: (state, action) => {
      state.regionDetails = action.payload;
    },
    saveResponseData: (state, action) => {
      state.response = action.payload;
      state.isLoading = false;
      state.status = !state.status;
    },
    updateUserData: (state, action) => {
      state.customerDetails = action.payload;
    },
    initCreateUser: (state, action) => {
      state.isLoading = true;
    },
    createUserFailed: (state, action) => {
      state.isLoading = false;
    },
    createUserSuccess: (state, action) => {
      state.status = false;
    },
    createUserResponse: (state, action) => {
      state.userCreate = action.payload;
    },
    setErrors: (state, action) => {
      state.erro = action.payload;
    },
    setIsPnbPreference: (state, action) => {
      state.isPnbPreference = action.payload;
    },
    setPrevious: (state, action) => {
      state.previous = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setTraceId: (state, action) => {
      state.traceId = action.payload;
    },
    setShowContinueButton: (state, action) => {
      state.showContinueButton = action.payload;
    },
    setEmailDiscount: (state, action) => {
      state.emailDiscount = action.payload;
    },
    setForm1Submitted: (state, action) => {
      state.form1Submitted = action.payload;
    },
    setShowFooter: (state, action) => {
      state.showFooter = action.payload;
    },
    setPreference: (state, action) => {
      state.preferredInsurance = action.payload.preferredInsurance;
      state.isWipro = action.payload.isWipro;
    },
  },
});

export const {
  createUserData,
  addUserData,
  createRegionData,
  setStartAnnualIncome,
  saveResponseData,
  initCreateUser,
  createUserSuccess,
  updateUserData,
  createUserFailed,
  setMobileNo,
  createUserResponse,
  setErrors,
  setIsPnbPreference,
  setPrevious,
  setUserId,
  setTraceId,
  setShowContinueButton,
  setEmailDiscount,
  setForm1Submitted,
  setShowFooter,
  setPreference,
  setShowModal,
} = greeting.actions;

export const saveUserDetails = data => {
  return async dispatch => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const afflidParams = {
        afflid: searchParams.get("afflid"),
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_url: window.location.href,
        token: searchParams.get("token"),
        c_id: searchParams.get("c_id"),
      };
      if (!data.dob || !!data?.create) {
        const response = await createUser({
          ...data,
          dob: data["dob"]?.split(" / ").reverse().join("-"),
          afflid: searchParams.get("afflid"),
          utm_source: searchParams.get("utm_source"),
          utm_medium: searchParams.get("utm_medium"),
          utm_campaign: searchParams.get("utm_campaign"),
          utm_url: window.location.href,
          token: searchParams.get("token"),
          c_id: searchParams.get("c_id"),
        });
        const { token, traceId, userId } = response.data;
        dispatch(createUserResponse(response.data.status));
        if (!response.data.status) {
          dispatch(setErrors(Object.values(response.data.errors)));
        }

        if (response.data) {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("traceId", traceId);
          sessionStorage.setItem("userId", userId);
          dispatch(setUserId(userId));
          dispatch(setTraceId(traceId));
          const responseData = {
            token,
            traceId,
            userId,
          };
          dispatch(createUserData(data));

          await quotationData({
            ...data,
            dob: data["dob"]?.split(" / ").reverse().join("-"),
            traceId,
            userId,
            afflid: searchParams.get("afflid"),
            token: searchParams.get("token") || token,
            utm_source: searchParams.get("utm_source"),
            utm_medium: searchParams.get("utm_medium"),
            utm_campaign: searchParams.get("utm_campaign"),
            utm_url: window.location.href,
            c_id: searchParams.get("c_id"),
            requestType: "get_products",
          });
          // if (data["dob"]) {
          //   data["dob"] = data["dob"].split(" / ").reverse().join("-");
          // }
          dispatch(saveResponseData(responseData));
          dispatch(addUserData({ ...data }));
        }
      } else {
        // if (!data.traceId)
        // 		throw new Error("'traceId' is missing in data object");

        const traceId = sessionStorage.getItem("traceId");

        const newData = {
          ...data,
          dob: data["dob"]?.split(" / ").reverse().join("-"),
          traceId,
          requestType: "get_products",
        };
        if (afflidParams.afflid && !data.tobaccoHabit) {
          const createUserRes = await createUser({
            ...data,
            dob: data["dob"]?.split(" / ").reverse().join("-"),
            traceId,
            ...afflidParams,
            workEmail: data.workEmail || data.work_email || "",
          });

          if (createUserRes.data) {
            const { token, traceId, userId } = createUserRes.data;

            sessionStorage.setItem("token", token);
            sessionStorage.setItem("traceId", traceId);
            sessionStorage.setItem("userId", userId);
            dispatch(setUserId(userId));
            dispatch(setTraceId(traceId));

            const response = await quotationData({
              ...newData,
              ...afflidParams,
              token,
              traceId,
              userId,
              workEmail: data.workEmail || data.work_email || "",
            });
            dispatch(createUserResponse(true));
            if (response.data) {
              data["dob"] = data["dob"].split("-").reverse().join(" / ");
              dispatch(addUserData(data));
              dispatch(saveResponseData(response.data));
            }
            return;
          }
        } else {
          dispatch(
            updateUserDetails2({
              ...data,
              dob: data["dob"]?.split(" / ").reverse().join("-"),
              traceId,
              ...afflidParams,
              userId: sessionStorage.getItem("userId"),
              token: sessionStorage.getItem("token"),

              workEmail: data.workEmail || data.work_email || "",
            }),
          );
        }
        const response = await quotationData({
          ...newData,
          ...afflidParams,
          traceId: sessionStorage.getItem("traceId"),
          userId: sessionStorage.getItem("userId"),
          token: sessionStorage.getItem("token"),
          workEmail: data.workEmail || data.work_email || "",
        });
        dispatch(createUserResponse(true));
        if (response.data) {
          data["dob"] = data["dob"].split("-").reverse().join(" / ");
          dispatch(addUserData(data));
          dispatch(saveResponseData(response.data));
        }
      }
    } catch (err) {
      dispatch(createUserFailed(err));
      console.error(err);
      dispatch(setIsError("Something went wrong while creating user"));
    }
  };
};

export const updateUserDetails =
  (data, onSuccess) => async (dispatch, getState) => {
    if (!data.traceId) throw new Error("traceId is missing in data Object");
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const afflidParams = {
        afflid: searchParams.get("afflid"),
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_url: window.location.href,
        token: searchParams.get("token"),
      };
      const store = getState();
      console.log({ store });
      const oldMobileNO = store.greeting.customerDetails.mobileNo;
      dispatch(initCreateUser());
      // if (data.dob) data["dob"] = data["dob"].split(" / ").reverse().join("-");

      const newData = {
        ...data,
        dob: data["dob"]?.split(" / ").reverse().join("-"),
        // traceId: sessionStorage.getItem("traceId"),
        requestType: "get_products",
      };

      const isMobileNoChanged = data.mobileNo !== oldMobileNO;

      const { success } = await quotationData({ ...newData, ...afflidParams });

      if (isMobileNoChanged) {
        dispatch(
          updateUserDetails2({
            ...newData,
            workEmail: data.work_email,
            ...afflidParams,
          }),
        );
      }

      // if (success) dispatch(fetchQuotes(sessionStorage.getItem("traceId")));

      if (success) {
        onSuccess && onSuccess();
      }

      if (data.dob) {
        data["dob"] = data["dob"].split("-").reverse().join(" / ");
        dispatch(updateUserData(data));
      }

      dispatch(createUserFailed());
    } catch (err) {
      dispatch(createUserFailed());
      alert("Something went wrong");
      console.error(err);
      dispatch(setIsError("Something went wrong while updating user details"));
    }
  };

export const getRegion = data => {
  return async dispatch => {
    try {
      const response = await checkpinCode(data);
      if (data) {
        dispatch(createRegionData(response.data));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(setIsError("Something went wrong while getting region details"));
    }
  };
};

export const callme = userData => async dispatch => {
  try {
    await contactme(userData);
  } catch (error) {
    alert("Error sending quote");
    console.error("ERROR", error);
  }
};
export const getAffinityPreference = email => async dispatch => {
  try {
    const response = await emailPNB(email);
    dispatch(setIsPnbPreference(response.data.isPnbDomain || false));
    dispatch(
      setPreference({
        isWipro: !!response.data.preferred_ic,
        preferredInsurance: response.data.preferred_ic,
      }),
    );
    if (response?.data?.email_activation === "Y") {
      dispatch(setShowModal(response?.data?.data?.time));
    }
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
export const updateUserDetails2 = data => async () => {
  try {
    const res = await updateUser({
      ...data,
      dob: data["dob"]?.split(" / ").reverse().join("-"),
    });
    console.log(res);
  } catch (error) {
    console.error(error);
  }
};
export default greeting.reducer;

export const selectCustomerDetails = state => state.greeting.customerDetails;
