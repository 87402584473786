import React from "react";
// import { Link, useParams } from "react-router-dom";
import { useDocumentTitle } from "../../custom-hooks";
import "./page-not-found.scss";

function PageNotFound() {
  useDocumentTitle("404 | Page Not Found");

  return (
    <div className="page-not-found">
      <h1>404 | Page Not Found</h1>
      <a href="https://preprod.elephant.in/term-life-Insurance">
        Goto Greeting Page
      </a>
    </div>
  );
}

export default PageNotFound;
