import styled from "styled-components";

export const Button1 = styled.button`
  padding: 1px 6px;
  font-size: 14px;
  height:54px;
  font-weight: bold;
  letter-spacing: 0;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  border: none;
  color: #000;
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-image: none;
  }
  &:disabled{
    cursor: not-allowed;
  }

  ${({
    buttonStyle,
    backgroundhex,
    hoverhex,
    theme,
    bordercolor,
    boxShadow,
    borderRadius,
    color,
  }) => {
    switch (buttonStyle) {
      case "yellow":
        return `
         border-color: #efbf00;
         outline-color: #efbf00;
         background-color: #f8cf39;
          &:focus{
            outline: none;
            box-shadow: none;
          }
          white-space: nowrap;
          &:hover{
            box-shadow: 6.994px 5.664px 21px rgba(255, 204, 15, .8)!important;
            border-bottom:3px solid #efbf00;
            border-color:#efbf00;

          }
      `;
      case "custom":
        return `
         border:${bordercolor ? `1px solid ${bordercolor}` : "none"};
         color:${color ? color : "black"};
         border-color: ${bordercolor || "#efbf00"};
         outline-color: ${bordercolor || "#efbf00"};
         background-color: ${backgroundhex || "#f8cf39"};
         border-radius: ${borderRadius ? borderRadius : "none"};
          &:focus{
            outline: none;
            box-shadow: none;
          }
          white-space: nowrap;
          &:hover{
            background-color: ${hoverhex || "#f8cf39"};
            box-shadow: ${
              boxShadow ? `6.994px 5.664px 21px ${boxShadow}!important` : "none"
            };

          }
      `;
      case "button-group":
        return `
         border:${bordercolor ? `0.1px solid ${bordercolor}` : "none"};
         border-color: ${bordercolor || "#efbf00"};
         outline-color: ${bordercolor || "#efbf00"};
         background-color: ${backgroundhex || "#f8cf39"};
         border-radius: ${borderRadius ? borderRadius : "none"};
         color:black;
          &:focus{
            outline: none;
            box-shadow: none;
            color:white;
            background-color: ${hoverhex || "#f8cf39"};
          }
          white-space: nowrap;
          &:hover{
            color:white;
            background-color: ${hoverhex || "#f8cf39"};
            box-shadow: ${
              boxShadow ? `6.994px 5.664px 21px ${boxShadow}!important` : "none"
            };

          }
      `;
      default:
        return `
         border-color: #efbf00;
         outline-color: #efbf00;
         background-color: #f8cf39;
          &:focus{
            outline: none;
            box-shadow: none;
          }
          white-space: nowrap;

          &:hover{
            box-shadow: 6.994px 5.664px 21px rgba(255, 204, 15, .8)!important;
            border-color:#efbf00;

          }
      `;
    }
  }}
`;

export default Button1;
