import moment from "moment";
import React from "react";
import { numberToDigitWord } from "../modules/QuoteFilter/helper";

export const getObjectFromArray = arr =>
  arr.reduce((obj, key) => ({ ...obj, [`${key.trim()}`]: `${key}` }), {});

export const checkValue = (str, max) => {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
};

export const madantory = () => {
  return <span className="mandatory">*</span>;
};

export const capitalize = str => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeList = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeSentence = str => {
  if (str && str.length === 0) return "";
  return str
    ?.toLowerCase()
    .split(" ")
    .map(
      (word, idx, arr) =>
        capitalize(word) + (idx !== arr.length - 1 ? " " : ""),
    );
};

export const capitalizeSentenceJoin = str => {
  if (str && str.length === 0) return "";
  return str
    ?.toLowerCase()
    .split(" ")
    .map(
      (word, idx, arr) =>
        capitalize(word) + (idx !== arr.length - 1 ? " " : ""),
    )
    .join("");
};

export const upperCase = event => {
  event.target.value = event.target.value.toUpperCase();
};
export const lowerCase = event => {
  event.target.value = event.target.value.replace(" ", "");
  event.target.value = event.target.value.toLowerCase();
};
export const upperCaseAll = event => {
  if (event.keyCode === 8) return;
  let newValue = capitalizeSentence(event.target.value);
  if (newValue.length > 0) newValue = newValue.join("");
  event.target.value = newValue;
};
export const noSpecial = evt => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (!(charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
  } else evt.preventDefault();
};

export const noSpclChars = event => {
  // let key = event.keyCode || event.which;
  // if (
  //   (key >= 65 && key <= 90) ||
  //   (key >= 97 && key <= 122) ||
  //   (key >= 48 && key <= 57)
  // ) {
  // } else {
  //   event.preventDefault();
  // }
  event.target.value = event.target.value.replace(/[^0-9a-zA-Z]/g, "");
};

export const numOnly = event => {
  // let key = event.keyCode || event.which;
  // if (
  //   (key >= 48 && key <= 58) ||
  //   (key >= 96 && key <= 105) ||
  //   key === 8 ||
  //   key === 9 ||
  //   key === 13 ||
  //   key === 37 ||
  //   key === 39
  // ) {
  // } else {
  //   event.preventDefault();
  // }
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
};
export const numOnlyInput = e => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const numOnlyWithShifDisable = event => {
  let key = event.keyCode || event.which;

  if (event.shiftKey) {
    event.preventDefault();
  }

  // if (
  //   (key >= 48 && key <= 58) ||
  //   (key >= 96 && key <= 105) ||
  //   key === 8 ||
  //   key === 9 ||
  //   key === 13 ||
  //   key === 37 ||
  //   key === 39
  // ) {
  // } else {
  //   event.preventDefault();
  // }
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
};

export const numOnlyWithShifDisableWithDelete = event => {
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
};

export const spaceNotAllowedAtFirstPos = event => {
  var startPos = event.currentTarget.selectionStart;
  if (event.which === 32 && startPos === 0) event.preventDefault();
  if ((event.which === 190 || event.which === 110) && startPos == 0)
    event.preventDefault();
};

export const onlyOneSpace = evt => {
  var startPos = evt.currentTarget.selectionStart;
  if (evt.which === 32) {
    //Prevents spaces in Beginning and more than on at the end
    if (
      startPos === 0 ||
      (startPos === evt.target.value.length &&
        evt.target.value[evt.target.value.length - 1] === " ")
    ) {
      evt.preventDefault();
    }
  }
};

export const noSpelicalCharacter = event => {
  let key = event.keyCode;

  if (
    // !(key > 47 && key < 58) && // numeric (0-9)
    !(key > 64 && key < 91) && // upper alpha (A-Z)
    !(key > 96 && key < 123) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40) &&
    !(key === 191 || key === 189 || key === 32 || key === 9)
  ) {
    // lower alpha (a-z)
    event.preventDefault();
  }

  if (key === 106 || key === 107 || key === 110) {
    event.preventDefault();
  }

  if (event.shiftKey) {
    event.preventDefault();
  }
};

export const alphaNumeric = event => {
  let key = event.keyCode;

  if (
    !(key > 47 && key < 58) && // numeric (0-9)
    !(key > 64 && key < 91) && // upper alpha (A-Z)
    !(key > 96 && key < 123) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40) &&
    !(key === 191 || key === 189 || key === 32 || key === 9) &&
    !(key === 96)
  ) {
    // lower alpha (a-z)
    event.preventDefault();
  }

  if (key === 106 || key === 107 || key === 110) {
    event.preventDefault();
  }

  if (event.shiftKey && key > 47 && key < 58) {
    event.preventDefault();
  }
};

export const alphabetOnly = event => {
  let key = event.keyCode;

  if (
    !(key === 32 || key === 9) &&
    !(key > 64 && key < 91) &&
    !(key > 96 && key < 123) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40)
  ) {
    event.preventDefault();
  }

  if (key === 106 || key === 107 || key === 110 || key === 111 || key === 109) {
    event.preventDefault();
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    event.preventDefault();
  }
};

export const alphabetOnlyNoSpace = event => {
  let key = event.keyCode;

  if (
    !(key === 9) &&
    !(key > 64 && key < 91) &&
    !(key > 96 && key < 123) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40)
  ) {
    event.preventDefault();
  }

  if (key === 106 || key === 107 || key === 110 || key === 111 || key === 109) {
    event.preventDefault();
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    event.preventDefault();
  }
};
export const alphabetOnlySpaceDot = event => {
  let key = event.keyCode;

  if (
    !(key === 9) &&
    !(key > 64 && key < 91) &&
    !(key > 96 && key < 123) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40) &&
    !(key === 32 || key === 190 || key === 110)
  ) {
    event.preventDefault();
  }

  if (key === 106 || key === 107 || key === 111 || key === 109) {
    event.preventDefault();
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    event.preventDefault();
  }
};

export const noCopyAndPaste = e => {
  if (e.ctrlKey) {
    e.preventDefault();
  }
};

export const alphabetOnlySpaceDotSpcl = event => {
  let key = event.keyCode || event.which;
  if (
    event.shiftKey &&
    !(
      key === 51 ||
      key === 55 ||
      key === 186 ||
      key === 222 ||
      key === 191 ||
      (key > 64 && key < 91)
    )
  ) {
    event.preventDefault();
  }
  if (!event.shiftKey && key === 186) {
    event.preventDefault();
  }

  if (
    !(key === 9) &&
    !(key === 96) &&
    !(key === 109) &&
    !(key > 64 && key < 91) &&
    !(key > 96 && key < 123) &&
    !(key > 47 && key < 58) &&
    !(key === 8 || key === 46) &&
    !(key === 37 || key === 38 || key === 39 || key === 40) &&
    !(
      key === 32 ||
      key === 190 ||
      key === 110 ||
      key === 222 ||
      key === 111 ||
      key === 188 ||
      key === 189 ||
      key === 186 ||
      key === 191
    )
  ) {
    event.preventDefault();
  }
  if (key === 106 || key === 107 || key === 16) {
    event.preventDefault();
  }
  if (key === 16 && key === 49) {
    event.preventDefault();
  }
};
export const getAge = value => {
  if (value) {
    let date = value.replace(/ /g, "");
    let a = moment();
    date = moment(value, "DD/MM/YYYY");
    return a.diff(date, "y");
  } else {
    return 0;
  }
};

export const getAgeYear = value => {
  if (value) {
    let date = value.replace(/ /g, "");
    let a = moment();
    date = moment(value, "MM/YYYY");
    return a.diff(date, "y");
  } else {
    return 0;
  }
};
// export const getAge = (value) => {
//   let userDob = moment(value, "DD/MM/YYYY");

//   let userDay = userDob.date();
//   let userMonth = userDob.month();
//   let userYear = userDob.year();

//   let currentDate = moment();

//   let currentDay = currentDate.date();
//   let currentMonth = currentDate.month();
//   let currentYear = currentDate.year();

//   let age = currentYear - userYear;

//   if (
//     currentMonth < userMonth ||
//     (currentMonth === userMonth && currentDay < userDay)
//   ) {
//     age--;
//   }

//   return age;
// };

export const getDate = value => {
  if (value) {
    let date = moment();
    return date.format("MM-DD-YYYY");
  } else {
    return 0;
  }
};
export const dateUtil = (event, refDate, allow, updateControl, insertSpace) => {
  let input = event.target.value;
  if (insertSpace)
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  if (!insertSpace)
    if (/\/$/.test(input)) input = input.substr(0, input.length - 2);
  var values = input.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 31);
  if (values[1]) values[1] = checkValue(values[1], 12);
  var output = "";
  if (!insertSpace) {
    output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + "/" : v;
    });
  }
  if (insertSpace)
    output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + " / " : v;
    });

  event.target.value = output.join("").substr(0, 14);
  if (event.target.value[event.target.value?.length - 1] === "/") {
    event.target.value += " ";
  }
  if (refDate === 1) {
    updateControl(event.target.value);
  }

  if (refDate) {
    if (!insertSpace && event.target.value.length === 10) {
      let age = getAge(event.target.value);
      // refDate.blur();
      if ((age >= 18 && age <= 70) || allow) {
        return age;
      }
    } else if (insertSpace && event.target.value.length === 14) {
      let age = getAge(event.target.value);
      event.target.blur();
      if ((age >= 18 && age <= 70) || (allow && age >= 0 && age <= 90)) {
        return age;
      }
    }
  }
};

export const monthUtil = event => {
  let input = event.target.value;
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  let values = input.split("/").map(function (value) {
    return value.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 12);
  let output;
  output = values.map((v, i) => {
    return v.length === 2 && i < 1 ? v + " / " : v;
  });
  event.target.value = output.join("").substr(0, 9);
};
export const getValue = value => {
  if (value) {
    const digits = value.toString().length;
    if (digits >= 8) {
      return `₹ ${value / Math.pow(10, 7)} Crore`;
    }
    if (digits >= 6) {
      let temp = value / Math.pow(10, 5);
      return `₹ ${temp} Lakh`;
    }
    if (digits >= 4) {
      return `₹ ${value / Math.pow(10, 3)} Thousand`;
    }
  }
};

export const yearUtils = event => {
  const value = event.target.value;
  if (value.length === 2) {
    event.target.value =
      event.target.value.substring(0, 2) +
      " /" +
      event.target.value.substring(3, 8);
  }
};

export const normalYearUtils = e => {
  if (e.which !== 8) {
    let input = e.target.value;
    let out = input.replace(/\D/g, "");
    let len = out.length;

    if (len > 1 && len < 8) {
      out = out.substring(0, 2) + " /" + out.substring(2, len);
    }

    e.target.value = out;
  }
};

export const normalDateUtils = e => {
  if (e.which !== 8) {
    let input = e.target.value;
    let out = input.replace(/\D/g, "");
    let len = out.length;

    if (len > 1 && len < 8) {
      out = out.substring(0, 2) + "/" + out.substring(2, 3);
    } else if (len >= 8) {
      out =
        out.substring(0, 2) +
        "/" +
        out.substring(2, 4) +
        "/" +
        out.substring(4, len);
      out = out.substring(0, 10);
    }
    e.target.value = out;
  }
};
export const getMultipleRiderCover = alias => {
  switch (alias) {
    case "hdfc_life":
      return 500;
    case "kotak_life":
      return 1000;
    default:
      return 5000;
  }
};
export const getAmount = (
  min_value,
  max_value,
  cover_amount,
  step,
  type,
  age,
) => {
  const coverAmount = [];
  let max = max_value <= cover_amount ? max_value : cover_amount;
  let min = min_value;
  if (!max_value) max = 10;
  let steps = step;
  if (min_value < 100000 && type.includes("life")) steps = min;

  for (let i = min ? min : 100; i <= max; i += steps) {
    if (i >= 100000 && type !== "coverQuotes") steps = 100000;
    if (type === "coverQuotes") steps = 500000;
    if (type === "annualIncome" && i % 100000) {
      steps = 50000;
    } else if (type === "annualIncome" && !i % 100000) steps = 100000;
    if (i >= 100000 && type === "monthlyIncome") steps = 100000;
    if (i >= 10000000 && type === "annualIncome") steps = 2500000;
    if (type === "max_life") {
      if (i <= 100000) steps = 400000;
      else steps = 500000;
    }
    if (type === "coverQuotes") {
      coverAmount.unshift(numberToDigitWord(i, 50000, 2));
    } else coverAmount.unshift(getValue(i));
  }

  return coverAmount;
};

export const getProposerIndustryType = industryType => {
  switch (industryType) {
    case "Import/Export":
      return "IMPEXP";
    case "Jewellery":
      return "JEWL";
    case "Real Estate":
      return "REALESTATE";
    case "Scrap Dealing":
      return "SCRP";
    case "Shipping":
      return "SHIPPING";
    case "Stock Broking":
      return "STOCK";
    default:
      return null;
  }
};

export const getProposerIndustryTypeDisplay = industryType => {
  switch (industryType) {
    case "IMPEXP":
      return "Import/Export";
    case "JEWL":
      return "Jewellery";
    case "REALESTATE":
      return "Real Estate";
    case "SCRP":
      return "Scrap Dealing";
    case "SHIPPING":
      return "Shipping";
    case "STOCK":
      return "Stock Broking";
    default:
      return null;
  }
};

export const getMaritalStatus = status => {
  switch (status) {
    case "Single":
      return 697;
    case "Married":
      return 696;
    case "Widowed":
      return 694;
    case "Divorced":
      return 695;
    default:
      return null;
  }
};

export const getMaritalStatusString = status => {
  switch (status) {
    case 697:
      return "Single";
    case 696:
      return "Married";
    case 694:
      return "Widowed";
    case 695:
      return "Divorced";
    default:
      return null;
  }
};

export const getProposerOccupation = occupation => {
  switch (occupation) {
    case "Housewife":
      return "HSWF";
    case "Salaried":
      return "SPVT";
    case "Self Employed":
      return "BSEM";
    case "Professional":
      return "PROF";
    case "Agriculturist":
      return "AGRI";
    case "Student":
      return "STDN";
    case "Others":
      return "SPRO";
    case "Retired":
      return "RETD";
    case "Self Employed-CA":
      return "SECA";
    case "Self Employed-Doctor":
      return "SEDO";
    case "Self Employed-Architect":
      return "SEAR";
    case "Self Employed-Businessman":
      return "SEBU";
    case "Self Employed-Consultant":
      return "SECO";
    case "Self Employed-Lawyer":
      return "SELA";
    case "Self Employed-Others":
      return "SEOT";
    default:
      return null;
  }
};

export const getProposerOccupationDisplay = occupation => {
  switch (occupation) {
    case "HSWF":
      return "Housewife";
    case "SPVT":
      return "Salaried";
    case "BSEM":
      return "Self Employed";
    case "PROF":
      return "Professional";
    case "AGRI":
      return "Agriculturist";
    case "STDN":
      return "Student";
    case "SPRO":
      return "Others";
    case "RETD":
      return "Retired";
    case "SECA":
      return "Self Employed-CA";
    case "SEDO":
      return "Self Employed-Doctor";
    case "SEAR":
      return "Self Employed-Architect";
    case "SEBU":
      return "Self Employed-Businessman";
    case "SECO":
      return "Self Employed-Consultant";
    case "SELA":
      return "Self Employed-Lawyer";
    case "SEOT":
      return "Self Employed-Others";
    default:
      return null;
  }
};

export const getTobacooConsumed = value => {
  switch (value) {
    case "Pan":
      return "pan";
    case "Cigarettes":
      return "cigarettes";
    case "Bidi":
      return "bidi";
    case "Gutka":
      return "gutka";
    case "Cigar":
      return "cigar";
    case "All of the above":
      return "All of the above";
    default:
      return null;
  }
};
export const getTobacooConsumedDisplay = value => {
  switch (value) {
    case "pan":
      return "Pan";
    case "cigarettes":
      return "Cigarettes";
    case "bidi":
      return "Bidi";
    case "gutka":
      return "Gutka";
    case "cigar":
      return "Cigar";
    case "All of the above":
      return "All of the above";
    default:
      return null;
  }
};
export const getTobacooQuantity = value => {
  switch (value) {
    case "6-10 Units":
      return "6_to_10units";
    case ">10 Units":
      return "greater_than_10units";
    case "<5 Units":
      return "less_than_5units";
    default:
      return null;
  }
};
export const getTobacooQuantityDisplay = value => {
  switch (value) {
    case "6_to_10units":
      return "6-10 Units";
    case "greater_than_10units":
      return ">10 Units";
    case "less_than_5units":
      return "<5 Units";
    default:
      return null;
  }
};

export const getAlcoholQuantity = value => {
  switch (value) {
    case "1-2":
      return "1-2";
    case "Greater than 2":
      return "greater_than_2";
    case "Occasional":
      return "Occasional";
    default:
      return null;
  }
};
export const getAlcoholQuantityDisplay = value => {
  switch (value) {
    case "1-2":
      return "1-2";
    case "greater_than_2":
      return "Greater than 2";
    case "Occasional":
      return "Occasional";
    default:
      return null;
  }
};
export const getAlcoholConsumed = value => {
  switch (value) {
    case "Beer (Bottles)":
      return "Beer(Bottles)";
    case "Hard Liquor (Pegs)":
      return "Liquor(Pegs)";
    case "Wine (Glasses)":
      return "Wine(Glasses)";
    case "All of the above":
      return "All of the above";
    default:
      return null;
  }
};
export const getAlcoholConsumedDisplay = value => {
  switch (value) {
    case "Beer(Bottles)":
      return "Beer (Bottles)";
    case "Liquor(Pegs)":
      return "Hard Liquor (Pegs)";
    case "Wine(Glasses)":
      return "Wine (Glasses)";
    case "All of the above":
      return "All of the above";
    default:
      return null;
  }
};

export const getOrganizationType = OrganizationType => {
  switch (OrganizationType) {
    case "Govt":
      return "GOVT";
    case "Pvt Ltd":
      return "PRIVATE LTD";
    case "Public Ltd":
      return "PUBLIC LTD";
    case "Trust":
      return "TRST";
    case "Society":
      return "SOCIETY";
    case "Proprietor":
      return "PROPREITOR";
    case "Partnership":
      return "PARTNER";
    case "Others":
      return "NOT ANSWERED";
    default:
      return null;
  }
};

export const getOrganizationTypeDisplay = OrganizationType => {
  switch (OrganizationType) {
    case "GOVT":
      return "Govt";
    case "PRIVATE LTD":
      return "Pvt Ltd";
    case "PUBLIC LTD":
      return "Public Ltd";
    case "TRST":
      return "Trust";
    case "SOCIETY":
      return "Society";
    case "PROPREITOR":
      return "Proprietor";
    case "PARTNER":
      return "Partnership";
    case "NOT ANSWERED":
      return "Others";
    default:
      return null;
  }
};

export const getTerm = (
  min_value,
  max_value,
  coverTerm,
  companyAlias,
  modeOfPayment,
  shortName,
  maxMaturity,
) => {
  const term = [];

  let min = min_value?.match(/\d+/);
  let [number] = max_value?.match(/\d+/);

  let max = Math.min(number, maxMaturity, coverTerm);
  if (maxMaturity < 0) max = Math.min(number, coverTerm);

  let step = 1;
  let time = "years";
  if (min_value.includes("month") && companyAlias === "hdfc_life") {
    if (modeOfPayment === "Monthly") step = 1;
    else if (modeOfPayment === "Annual") {
      step = 12;
      min = [12];
    } else if (modeOfPayment === "Half Yearly") {
      step = 6;
      min = [6];
    } else if (modeOfPayment === "Quarterly") {
      step = 3;
      min = [3];
    }
    if (shortName === "PPR_PAC" || shortName === "PPR_ADC") {
      step = 1;
      min = [1];
      max = Math.min(number, maxMaturity * 12, coverTerm * 12);
    }
    time = "months";
  }
  if (shortName == "C4CI" && max_value == 5) {
    return ["5 years", "6 years", "7 years", "8 years", "9 years", "10 years"];
  }
  for (let i = Number(min[0]); i <= Number(max); i += step) {
    term.unshift(`${i} ${time}`);
  }
  console.log("modeOfPayment", term);

  return term;
};

export const getPPTerm = (
  min_term,
  max_term,
  shortName,
  company,
  modeOfPayment,
  riderTerm,
  paymentTerm,
  age,
) => {
  let min = min_term?.match(/\d+/);
  let max = riderTerm?.match(/\d+/);
  // max = [Math.min(max_term, max[0])];
  // console.log(max, riderTerm, max_term);
  let step = 1;
  let list = [];
  let time = "years";
  if (paymentTerm?.option === "Single Pay") {
    if (min_term?.includes("month") && company === "hdfc_life")
      return ["1 month"];
    else return ["1 year"];
  }
  //Limited Pay
  if (paymentTerm?.option === "Limited Pay") {
    max = paymentTerm.term && paymentTerm.term.match(/\d+/);

    if (company === "bajaj_life" && shortName === "CI") return [riderTerm];
    if (
      company === "hdfc_life" &&
      shortName === "C4CI" &&
      paymentTerm.term &&
      Number(paymentTerm.term.match(/\d+/)[0]) > 10 &&
      Number(riderTerm.match(/\d+/)[0]) > 10
    ) {
      return [riderTerm];
    }
    if (
      shortName === "INAD" &&
      company === "hdfc_life" &&
      paymentTerm?.option === "Limited Pay" &&
      paymentTerm?.term === "Pay till 60"
    )
      max = [60 - age];
    if (company === "hdfc_life" && shortName === "PPR_CC") return [riderTerm];
    if (company === "bajaj_life") {
      step = 5;
    }
    if (min_term?.includes("month") && company === "hdfc_life") {
      max = [max[0] * 12];
    }
    max = [Math.min(Number(riderTerm.match(/\d+/)[0]), max)];

    if (min_term === "Fixed") return [`${max[0]} years`];
    console.log("riderTerm", shortName);

    if (shortName === "C4CI") {
      if (
        paymentTerm.term &&
        Number(paymentTerm.term.match(/\d+/)[0]) > 10 &&
        Number(riderTerm.match(/\d+/)[0]) > 10
      ) {
        if (
          getNumberfromString(paymentTerm.term)[0] >=
          getNumberfromString(riderTerm)
        )
          return ["5 years", "10 years", riderTerm].reverse();
        return ["5 years", "10 years"].reverse();
      } else if (
        riderTerm === "5 years" ||
        (paymentTerm.term.includes("5") && paymentTerm.term != "15 Years")
      ) {
        return ["5 years"];
      } else return ["5 years", riderTerm].reverse();
    } else {
      if (min_term?.includes("month") && company === "hdfc_life") {
        if (modeOfPayment === "Monthly") step = 1;
        else if (modeOfPayment === "Annual") {
          step = 12;
          min = [12];
        } else if (modeOfPayment === "Half Yearly") {
          step = 6;
          min = [6];
        } else if (modeOfPayment === "Quarterly") {
          step = 3;
          min = [3];
        }

        time = "months";
      }
    }
  }
  //Regular Pay
  if (paymentTerm?.option === "Regular Pay") {
    if (min_term === "Fixed") return [riderTerm];
    if (company === "bajaj_life") {
      step = 5;
      max =
        getNumberfromString(riderTerm) >= 30
          ? [30]
          : [getNumberfromString(riderTerm)];
    }
    if (company === "bajaj_life" && shortName === "CI") return [riderTerm];
    if (
      company === "hdfc_life" &&
      shortName === "C4CI" &&
      Number(riderTerm?.match(/\d+/)[0]) > 10
    )
      return [riderTerm];
    if (company === "hdfc_life" && shortName === "PPR_CC") return [riderTerm];
    if (shortName === "INAD" && company === "hdfc_life") {
      min = [5];

      if (Number(riderTerm?.match(/\d+/)[0]) <= 5) return [riderTerm];
    }
    if (shortName === "C4CI") {
      if (Number(riderTerm?.match(/\d+/)[0]) > 10) {
        if (company === "hdfc_life") return [riderTerm];
        return ["5 years", "10 years", riderTerm].reverse();
      } else if (riderTerm?.match(/\d+/)[0] === "5") return ["5 years"];
      else return ["5 years", riderTerm].reverse();
    } else {
      if (min_term?.includes("month") && company === "hdfc_life") {
        if (modeOfPayment === "Monthly") step = 1;
        else if (modeOfPayment === "Annual") {
          step = 12;
          min = [12];
        } else if (modeOfPayment === "Half Yearly") {
          step = 6;
          min = [6];
        } else if (modeOfPayment === "Quarterly") {
          step = 3;
          min = [3];
        }
        time = "months";
      }
    }
  }
  if (min && max)
    for (let i = Number(min[0]); i <= Number(max[0]); i += step) {
      list.unshift(`${i} ${time}`);
      console.log(min, max);
    }

  console.log({ list, riderTerm });
  if (!list.includes(riderTerm.trim()) && paymentTerm?.option === "Regular Pay")
    list.unshift(riderTerm);
  if (
    !list.includes(riderTerm.trim()) &&
    paymentTerm?.option === "Limited Pay" &&
    Number(getNumberfromString(riderTerm)) <= Number(max[0])
  )
    list.unshift(riderTerm);

  return list;
};
export const getNumberfromString = value => {
  if (!value) return "";
  const number = value.toString().match(/(\d+(\.\d+)?)|(\.\d+)/);
  if (!number) {
    return 0;
  }
  if (
    value.toString().includes("Thousand") ||
    value.toString().includes("thousand")
  )
    return String(Number(number[0]) * 1000);
  else if (
    value.toString().includes("Lakh") ||
    value.toString().includes("lakh")
  )
    return String(Number(number[0]) * 100000);
  else if (
    value.toString().includes("Crore") ||
    value.toString().includes("crore")
  )
    return String(Number(number[0]) * 10000000);
  else if (value.toString().includes("months")) return number[0];
  else if (value.toString().includes("year")) return number[0];
  else return number[0];
};

export const removeKg = weight => {
  if (!weight) return null;
  return weight?.replace(/\D/g, "");
};
export const weightInKgs = weight => {
  return `${weight} kgs`;
};
export const heightInCm = height => {
  if (!height) return null;
  height = height?.split(" ");
  const inchesFromFeet = parseInt(height[0]) * 12;
  const totalInches = inchesFromFeet + parseInt(height[2]);
  return Math.round(totalInches * 2.54);
};

export const heightInQuote = height => {
  height = height.split(" ");
  const quoteHeight = height[0] + "'" + height[2];
  return quoteHeight;
};
export const getHeightinFeetInchesFromQuote = height => {
  const splitHeight = height.split("'");
  return `${splitHeight[0]} feet ${splitHeight[1]} inches`;
};
export const getMaritalStatusStringKotak = maritalStatus => {
  switch (maritalStatus) {
    case "S":
      return "Single";
    case "M":
      return "Married";
    case "W":
      return "Widowed";
    case "D":
      return "Divorced";
    default:
      return null;
  }
};
export const convertNumberToWords = amount => {
  var price = amount.replace(/,/g, "");
  var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 === dgt
        ? ""
        : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
        (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
      );
    };
  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((price += ""), isNaN(parseInt(price)))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      // eslint-disable-next-line default-case
      switch (
        ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 !== digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2]
                    ? " and"
                    : "")
              : "",
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 !== digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2]
                    ? " and"
                    : " Crore")
              : "",
          );
      }
    str = words.reverse().join("");
  } else str = "";
  return str;
};

export const checkDocType = e => {
  try {
    var type = e.target.files[0].name.split(".");
    const types = ["jpeg", "pdf", "png", "tif", "tiff", "jpg"];
    return types.includes(type[type.length - 1]);
  } catch (e) {
    return false;
  }
};
export const checkDocTypeMax = (e, num) => {
  try {
    var type = e.target.files[0].name.split(".");
    var types = ["jpeg", "pdf", "png", "tif", "tiff", "jpg"];
    if (num == 2) {
      var types = ["jpeg", "png", "tif", "tiff", "jpg"];
    }
    return types.includes(type[type.length - 1]);
  } catch (e) {
    return false;
  }
};
export const checkDocTypeKotak = e => {
  try {
    var type = e.target.files[0].name.split(".");
    const types = ["jpeg", "pdf", "tif", "tiff", "jpg"];
    return types.includes(type[type.length - 1]);
  } catch (e) {
    return false;
  }
};

export const checkSize = e => {
  try {
    var size = e.target.files[0].size / 1024 / 1024;
    return size < 5;
  } catch (e) {
    return false;
  }
};

export const checkSizeKotak = e => {
  try {
    var size = e.target.files[0].size / 1024 / 1024;
    return size < 10;
  } catch (e) {
    return false;
  }
};

export const checkFormat = (value, proof, num, appNo) => {
  try {
    var document = value.target.files[0];
    var type = value.target.files[0].name.split(".");

    var date = moment().format("DDMMYYYY");
    var new_file = new File(
      [document],
      `${appNo}_${proof}_${date}_${num}.${type[type.length - 1]}`,
      { type: document.type },
    );
    return new_file;
  } catch (e) {
    return false;
  }
};

export const convert = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
export const getEducationDetails = status => {
  switch (status) {
    case "Post Graduate":
      return 676;
    case "Graduate":
      return 680;
    case "12th Pass":
      return 679;
    case "10th Pass":
      return 678;
    case "Diploma":
      return 675;
    case "Below 10th":
      return 677;
    default:
      return null;
  }
};

export const getEducationDetailsDisplay = status => {
  switch (status) {
    case 676:
      return "Post Graduate";
    case 680:
      return "Graduate";
    case 679:
      return "12th Pass";
    case 678:
      return "10th Pass";
    case 675:
      return "Diploma";
    case 677:
      return "Below 10th";
    default:
      return null;
  }
};

export const getIdentificationDetails = data => {
  switch (data) {
    case "Masked Aadhaar Card Copy":
      return "Adhr";
    case "Driving License (Current)":
      return "Driving license";
    case "Job Card Issued by NREGA":
      return "Card Issued by NREGA";
    case "Passport (Current)":
      return "Passport";
    case "Pan Card":
      return "PAN";
    case "Voters ID Card":
      return "Voters ID";
    default:
      return null;
  }
};
export const getIdentificationDetailsDisplay = data => {
  switch (data) {
    case "Adhr":
      return "Masked Aadhaar Card Copy";
    case "Driving license":
      return "Driving License (Current)";
    case "Card Issued by NREGA":
      return "Job Card Issued by NREGA";
    case "Passport":
      return "Passport (Current)";
    case "PAN":
      return "Pan Card";
    case "Voters ID":
      return "Voters ID Card";
    default:
      return null;
  }
};

export const getItProof = data => {
  switch (data) {
    case "PAN":
      return "pancard";
    case "FORM 60":
      return "form 60";
    default:
      return null;
  }
};
export const getItProofDIisplay = data => {
  switch (data) {
    case "pancard":
      return "PAN";
    case "form 60":
      return "FORM 60";
    default:
      return null;
  }
};
export const noNum = event => {
  // let key = event.keyCode;

  // if (
  //   (key >= 65 && key <= 90) ||
  //   key === 8 ||
  //   key === 9 ||
  //   key === 20 ||
  //   key === 16 ||
  //   key === 13 ||
  //   key === 37 ||
  //   key === 39 ||
  //   key === 46
  // ) {
  // } else {
  //   event.preventDefault();
  // }
  event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, "");
};

export const nameWithSpace = event => {
  event.target.value = capitalizeSentenceJoin(
    event.target.value.replace(/[^a-zA-Z\s]/g, ""),
  );
  if (event.target.value[event.target.value.length - 1] === " ") {
    event.target.value = event.target.value.replace("  ", " ");
  }
};
export const noNumSpace = event => {
  let key = event.keyCode;

  if (
    (key >= 65 && key <= 90) ||
    key === 8 ||
    key === 9 ||
    key === 20 ||
    key === 16 ||
    key === 13 ||
    key === 37 ||
    key === 39 ||
    key === 32 ||
    key === 46
  ) {
  } else {
    event.preventDefault();
  }
};
export const onlyAlphaNoSpace = e => {
  const key = e.keyCode;
  if (e.shiftKey && key >= 48 && key <= 58) {
    e.preventDefault();
  }
  // if (
  //   (key >= 48 && key <= 58) ||
  //   (key >= 96 && key <= 105) ||
  //   key === 8 ||
  //   key === 9 ||
  //   key === 13 ||
  //   key === 37 ||
  //   key === 39
  // ) {
  // } else {
  //   e.preventDefault();
  // }

  e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, "");
};

export const getAgeProof = data => {
  switch (data) {
    case "Defence I Card":
      return "DEF";
    case "Driving License(Current)":
      return "DL";
    case "Passport(Current)":
      return "PSPT";
    case "Employers Certificate":
      return "EMPCRT";
    case "Goverment Employee ID":
      return "GOVTID";
    case "Government Registered document or ID Proof":
      return "GSR";
    case "Municipal Record":
      return "MUN";
    case "PAN card":
      return "PAN";
    case "School/College Record":
      return "SC";
    default:
      return null;
  }
};

export const getAgeProofDisplay = data => {
  switch (data) {
    case "DEF":
      return "Defence I Card";
    case "DL":
      return "Driving License(Current)";
    case "PSPT":
      return "Passport(Current)";
    case "EMPCRT":
      return "Employers Certificate";
    case "GOVTID":
      return "Goverment Employee ID";
    case "GSR":
      return "Government Registered document or ID Proof";
    case "MUN":
      return "Municipal Record";
    case "PAN":
      return "PAN card";
    case "SC":
      return "School/College Record";
    default:
      return null;
  }
};

export const getAddressProof = data => {
  switch (data) {
    case "Masked Aadhaar Card Copy":
      return "Adhr";
    case "Voters ID Card":
      return "Voters ID";
    case "Driving License (Current)":
      return "Driving License";
    case "Passport (Current)":
      return "Passport";
    default:
      return null;
  }
};

export const getAddressProofDisplay = data => {
  switch (data) {
    case "Adhr":
      return "Masked Aadhaar Card Copy";
    case "Voters ID":
      return "Voters ID Card";
    case "Driving License":
      return "Driving License (Current)";
    case "Passport":
      return "Passport (Current)";
    default:
      return null;
  }
};

export const getIncomeProof = data => {
  switch (data) {
    case "Income Tax Returns (Last 3 Years)":
      return "ITRETN";
    case "Bank Cash Flow Stmt,Pass Book (Last 6 Months)":
      return "NIL";
    case "Salary Slip Signed by authorized signatory of Employer (Last 3 Months)":
      return "PAYSLP";
    case "Land Records":
      return "NIL3";
    case "Chartered Accounts Certificate":
      return "CACERT";
    case "Audited Firm Accounts":
      return "PLBS";
    case "Audited Company Accounts":
      return "CMP ACCT";
    case "Agricultural Income Certificate":
      return "NIL1";
    case "Employers Certificate":
      return "SALCER";
    case "None":
      return "NIL4";
    case "Form 16":
      return "FORM16";
    default:
      return null;
  }
};
export const getIncomeProofDisplay = data => {
  switch (data) {
    case "ITRETN":
      return "Income Tax Returns (Last 3 Years)";
    case "NIL":
      return "Bank Cash Flow Stmt,Pass Book (Last 6 Months)";
    case "PAYSLP":
      return "Salary Slip Signed by authorized signatory of Employer (Last 3 Months)";
    case "NIL3":
      return "Land Records";
    case "CACERT":
      return "Chartered Accounts Certificate";
    case "PLBS":
      return "Audited Firm Accounts";
    case "CMP ACCT":
      return "Audited Company Accounts";
    case "NIL1":
      return "Agricultural Income Certificate";
    case "SALCER":
      return "Employers Certificate";
    case "NIL4":
      return "None";
    case "FORM16":
      return "Form 16";
    default:
      return null;
  }
};
export const getEiaRepo = value => {
  switch (value) {
    case "NSDL Database Management Limited":
      return "NDML";
    case "Central Insurance Repository Limited (CDSL Group)":
      return "CIRL";
    case "Karvy Insurance Repository Limited":
      return "KARVY";
    case "CAMS Repository Services Ltd":
      return "CAMSRep";
    default:
      return null;
  }
};

export const getEiaRepoDisplay = value => {
  switch (value) {
    case "NDML":
      return "NSDL Database Management Limited";
    case "CIRL":
      return "Central Insurance Repository Limited (CDSL Group)";
    case "KARVY":
      return "Karvy Insurance Repository Limited";
    case "CAMSRep":
      return "CAMS Repository Services Ltd";
    default:
      return null;
  }
};

export const iciciResidentStatusCode = value => {
  switch (value) {
    case "Resident Indian":
      return "Resident Indian";
    case "Non Resident Indian":
      return "NRI";
    case "PIO/OCI":
      return "PIO";
    case "Foreign National":
      return "Foreign National";
    default:
      return null;
  }
};

export const iciciResidentStatusCodeDisplay = value => {
  switch (value) {
    case "Resident Indian":
      return "Resident Indian";
    case "NRI":
      return "Non Resident Indian";
    case "PIO":
      return "PIO/OCI";
    case "Foreign National":
      return "Foreign National";
    default:
      return null;
  }
};

export const randomString = (length = 6) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getNextDays = noOfDays => {
  const array = [];
  const daysRequired = noOfDays;

  for (let i = 1; i <= daysRequired; i++) {
    array.push(moment().add(i, "d").format("DD-MM-YYYY"));
  }
  return array;
};

export const generateRandomString = length => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
