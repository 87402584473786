import React, { useState, useRef, useEffect } from "react";
import Popup from "../Popup";
import styled from "styled-components";
// import { yupResolver } from "@hookform/resolvers";
// import { useForm } from "react-hook-form";
// import { ChildSchema } from "../../../utils/validationSchema";
import {
  dateUtil,
  getNumberfromString,
  generateRandomString,
  numOnly,
  getAge,
  numOnlyWithShifDisable,
} from "../../../utils/inputUtils";
//import { modifyDetailSchema } from "../../utils/validationSchema";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../Card/Card";
import CardTitle from "../../Card/CardTitle/CardTitle";
import { useCustomLocation } from "../../../custom-hooks";
import "./childPopup.scss";
import {
  getChildPremium,
  setRidersPremium,
} from "../../../modules/RiderPage/RiderPage.slice";
import {
  AgeWrapper,
  Form,
  FormGroup,
  TextInputElg,
  Label,
  CustomDropDownFont,
} from "../../../components/Form/Form";

import CustomDropDown from "../../CutomDropDown/CustomDropDown";

const ChildCardBajaj = ({
  id,
  show,
  onClose,
  selected,
  riderTerm,
  riderId,
  termInsuId,
  riderShortname,
  onClick,
  setPrice,
  setGst,
  setCoverAmount,
  additionalRiders,
  added,
  cover,
}) => {
  const { enquiryId } = useCustomLocation();
  const dispatch = useDispatch();

  const { childPremium, childData } = useSelector(state => state.rider);
  const [coverError, setCoverError] = useState(false);

  const { customerDetails } = useSelector(state => state.greeting);
  const dateRef = useRef();
  const childForm = useRef();

  useEffect(() => {
    if (childPremium) {
      if (childPremium[1]?.totalPremium_CB) {
        setPrice(childPremium[1]?.totalPremium_CB);
        setGst(childPremium[1]?.totalPremiumGst_CB);
      } else {
        setPrice(childPremium[0]?.totalPremium_CB);
        setGst(childPremium[0]?.totalPremiumGst_CB);
      }
      dispatch(setRidersPremium({ child: true }));
    }
  }, [childPremium]);

  const [childList, setChildList] = useState(
    childData?.childDetails || [{ id: generateRandomString(10) }],
  );

  const [errors, setErrors] = useState({});
  const [childNo, setChildNo] = useState();
  const [coverAmt, setCoverAmt] = useState("0");
  // useEffect(() => {
  // 	const newList = childList.map((c) => {
  // 		let riderCoveramt =
  // 			Number(c.coverPercent / 100) * Number(selected.cover_amount);
  // 		return Number(riderCoveramt);
  // 	});
  // 	const add = (arr) => arr.reduce((a, b) => a + b, 0);
  // 	var sum = add(newList);
  // 	console.log(sum);
  // 	if (sum) {
  // 		setCoverAmount("100000");
  // 	}
  // }, [childPremium, childList.length]);

  useEffect(() => {
    const newList = childList.map(c => {
      let riderCoveramt = Number(c.coverPercent);
      return Number(riderCoveramt);
    });
    const add = arr => arr.reduce((a, b) => a + b, 0);
    var sum = add(newList);
    console.log(sum);
    if (sum > 100) {
      setCoverError(
        "Total cover percentage should be less than or equal to 100",
      );
    } else {
      setCoverError(false);

      setCoverAmt(`${(sum / 100) * selected.cover_amount}`);
      console.log(coverAmt);
      setCoverAmount(coverAmt);
    }
  }, [childList.length, childList]);

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    const newList = childList.map(c => {
      let newChild = { ...c };

      if (!c.name) {
        newChild.nameError = "This field is required ";
        isValid = false;
      }
      if (!c.genderC) {
        newChild.genderError = "This field is required ";
        isValid = false;
      }
      if (!c.dobC) {
        newChild.dobError = "This field is required ";
        isValid = false;
      }
      if (c.dobC?.length < 14) {
        newChild.dobError = "Age should be between 0 and 25 ";
        isValid = false;
      }

      // if (!c.premiumTerm) {
      // 	newChild.premiumTermError = "This field is required ";
      // 	isValid = false;
      // }
      if (!c.coverPercent) {
        newChild.coverPercentError = "This field is required ";
        isValid = false;
      }
      if (Number(c.coverPercent) > 100 || Number(c.coverPercent) < 10) {
        newChild.coverPercentError = "Percentage should be between 10 and 100";
        isValid = false;
      }

      if (Number(c.coverPercent) % 10 != 0) {
        newChild.coverPercentError = "Percentage should be in multiples of 10";
        isValid = false;
      }

      return newChild;
    });
    setChildList(newList);

    if (!isValid) setErrors(newErrors);
    else {
      setErrors({});
      return isValid;
    }
  };

  const removeChild = id => {
    setChildList(childList.filter(i => i.id !== id));
  };

  const updateName = (name, id) => {
    setChildList(
      childList.map(i => (i.id === id ? { ...i, name, nameError: "" } : i)),
    );
  };

  const updateGender = (genderC, id) => {
    setChildList(
      childList.map(i =>
        i.id === id ? { ...i, genderC, genderError: "" } : i,
      ),
    );
  };

  const updatePremTerm = (premiumTerm, id) => {
    setChildList(
      childList.map(i =>
        i.id === id ? { ...i, premiumTerm, premiumTermError: "" } : i,
      ),
    );
  };
  const updateDob = (dobC, id) => {
    setChildList(
      childList.map(i => (i.id === id ? { ...i, dobC, dobError: "" } : i)),
    );
  };
  const updateCoverPercent = (coverPercent, id) => {
    setChildList(
      childList.map(i =>
        i.id === id ? { ...i, coverPercent, coverPercentError: "" } : i,
      ),
    );
  };

  const updateAge = (age, id) => {
    setChildList(childList.map(i => (i.id === id ? { ...i, age } : i)));
  };

  const handleChange = () => {
    // setTimeout(() => {
    // 	handleSubmit();
    // }, 1000);
    handleSubmit();
  };

  const handleSubmit = evt => {
    //	evt.preventDefault();
    if (!validate()) return;
    if (coverError) return;
    const newList = childList.map(c => {
      let newChild = { ...c };

      newChild.termInsuId = termInsuId;
      newChild.riderId = enquiryId;
      newChild.riderTerm = 25 - c.age; //chnage
      newChild.riderShortname = "CB";
      newChild.riderPpt = "Y";
      newChild.percentage = c.coverPercent;
      newChild.riderCoveramt =
        Number(c.coverPercent / 100) * Number(selected.cover_amount);
      newChild.type = "Child Benefit";
      newChild.dob = c.dobC.split(" / ").reverse().join("-");
      newChild.name = c.name; //change
      newChild.gender = c.genderC === "Male" ? "M" : "F";

      return newChild;
    });

    dispatch(
      getChildPremium({
        traceId: enquiryId,
        childDetails: newList,
        // traceId: "202011230431299878",
        // childDetails: newChildList,
      }),
    );
  };
  const content = (
    <>
      {childList.map(item => (
        <ChildCard
          {...item}
          updateName={updateName}
          updateGender={updateGender}
          updatePremTerm={updatePremTerm}
          updateDob={updateDob}
          updateCoverPercent={updateCoverPercent}
          removeChild={removeChild}
          updateAge={updateAge}
          childListLength={childList.length}
          setErrors={setErrors}
          childList={childList}
          setChildList={setChildList}
          handleSubmit={handleSubmit}
          validate={validate}
          handleChange={handleChange}
          customerAge={customerDetails.age}
          coverError={coverError}
        />
      ))}

      {childList.length < 5 && (
        <div className="addChildBtnWrap">
          <ContentBtn
            onClick={() =>
              setChildList([...childList, { id: generateRandomString(10) }])
            }
          >
            <div className="addChildBtn">ADD ANOTHER CHILD</div>
          </ContentBtn>
        </div>
      )}
    </>
  );
  return content;
};
export default ChildCardBajaj;

function ChildCard({
  name = "",
  genderC = "Select",
  dobC = "",
  premiumTerm = "Select",
  coverPercent = "",
  age = "",
  id,
  updateName,
  updateGender,
  updatePremTerm,
  updateDob,
  updateCoverPercent,
  removeChild,
  updateAge,
  childListLength,
  childList,
  setChildList,
  handleSubmit,
  handleChange,
  customerAge,
  coverError,
  ...props
}) {
  const [childNumber, setChildNumber] = useState(1);
  const [ageC, setAgeC] = useState(false);
  const [term, setTerm] = useState("Select Term");
  const [ageError, setAgeError] = useState("Age should be in between 0 and 25");
  const dateRef = useRef();
  let date = "";
  const attachRef = ref => {
    date = ref;
    dateRef.current = ref;
  };
  const handleDobChange = event => {
    setAgeC(dateUtil(event, dateRef.current, true, false, true));
    updateDob(event.target.value, id);
  };

  useEffect(() => {
    if (!ageError) {
      handleChange();
    }
  }, [
    name,
    genderC,
    dobC,
    premiumTerm,
    coverPercent,
    childListLength,
    ageError,
  ]);

  useEffect(() => {
    if (ageC) {
      setTerm(`${25 - ageC} years`);
    } else {
      setTerm(`25  years`);
    }
    updateAge(ageC, id);
    if (customerAge - ageC < 18) {
      setAgeError("Age should be 18 years less than insurer age");
    } else if (typeof ageC === "undefined") {
      setAgeError("Age should be in between 0 and 25");
    } else if (ageC >= 0 || ageC < 25) {
      setAgeError(false);
    } else {
      setAgeError(false);
    }
  }, [ageC]);

  return (
    <>
      <div className="childCard childCardBajaj">
        <Card proposal popup>
          <div className="childCardHeader">
            <CardTitle eligibilityTitle className="spouseTitile">
              Child Details
            </CardTitle>
            {childListLength > 1 && (
              <ChildBtnRemove
                onClick={() => {
                  removeChild(id);
                }}
              >
                <div className="removeBtnChild">REMOVE CHILD</div>
              </ChildBtnRemove>
            )}
          </div>
          <Form onSubmit={handleSubmit}>
            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                type="text"
                id={name + id}
                name={name + id}
                maxLength="60"
                style={{ textTransform: "capitalize" }}
                value={name}
                onChange={evt => {
                  updateName(evt.target.value, id);
                }}
              />
              <Label md htmlFor={name + id} eligibilityPage proposalInput>
                Full Name
              </Label>
              <p className="errorG formbuilder__error">{props.nameError}</p>
            </FormGroup>

            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Gender"
                  propDropDown
                  selectedItem={genderC}
                  currentSelected={genderC}
                  items={["Male", "Female"]}
                  onChange={val => updateGender(val, id)}
                />
              </CustomDropDownFont>
              <p className="errorG formbuilder__error">{props.genderError}</p>
            </FormGroup>

            {/* <FormGroup proposalCard eligibilityPage>
							<CustomDropDownFont rdr>
								<CustomDropDown
									dropDownTitle="Premium paying Term"
									propDropDown
									selectedItem={premiumTerm}
									currentSelected={premiumTerm}
									items={["Yes", "No"]}
									onChange={(val) => updatePremTerm(val, id)}
								/>
							</CustomDropDownFont>
							<p className="errorG formbuilder__error">
								{props.premiumTermError}
							</p>
						</FormGroup> */}

            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Premium paying Term"
                  propDropDown
                  selectedItem={term}
                  currentSelected={term}
                  disabled
                  items={[]}
                />
              </CustomDropDownFont>
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                type="text"
                id={dobC + id}
                name={dobC + id}
                maxLength="14"
                value={dobC}
                ref={attachRef}
                onChange={handleDobChange}
              />
              <Label md htmlFor={dobC + id} eligibilityPage proposalInput>
                Date of Birth (DD/MM/YYYY)
              </Label>
              {ageC !== 0 && ageC && (
                <AgeWrapper className="form-builder-age-wrapper ageWrapProposal">
                  {ageC} years
                </AgeWrapper>
              )}
              {ageError ? (
                <p className="errorG formbuilder__error">{ageError}</p>
              ) : (
                <p className="errorG formbuilder__error">{props.dobError}</p>
              )}
            </FormGroup>

            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                type="text"
                id={coverPercent + id}
                name={coverPercent + id}
                maxLength="60"
                onInput={numOnlyWithShifDisable}
                value={coverPercent}
                onChange={evt => {
                  updateCoverPercent(evt.target.value, id);
                }}
              />
              <Label
                md
                htmlFor={coverPercent + id}
                eligibilityPage
                proposalInput
              >
                Percentage (%) of Cover Amount
              </Label>
              {coverError ? (
                <p className="errorG formbuilder__error">{coverError}</p>
              ) : (
                <p className="errorG formbuilder__error">
                  {props.coverPercentError}
                </p>
              )}
            </FormGroup>

            {/* <FormGroup>
							<button
								type="submit"
								className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
							>
								Calculate
							</button>
						</FormGroup> */}
          </Form>
        </Card>
      </div>
    </>
  );
}

const ChildBtnRemove = styled.button`
  width: 107px;
  height: 25px;
  padding: 7px 10px 5px 10px;
  border-radius: 4px;
  border: solid 1px #969799;
  background-color: #fff;
`;

const ContentBtn = styled.button`
  position: relative;
  top: 20px;
  width: 196px;
  height: 48px;
  padding: 16px 16px 16px 16px;
  border-radius: 4px;
  border: solid 1px #107591;
  margin-bottom: 15px;
  display: block;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  font-family: "Inter-regular";
`;
