import { useEffect } from "react";

function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title;
    return () => (document.title = "Life Protect");
  }, [title]);
}

export default useDocumentTitle;
