import React, { useEffect, useRef, useState } from "react";
import FamilyImg from "../../assets/img/heroFamily.png";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Form,
  LaxmiImg,
  LaxmiWrap,
  MainHeader,
  Row,
  SubHeader,
  TermCond,
  CmnTopWrap,
  CmnBlue,
  CmnYellow,
  CmnSkyBlue,
  CmnTitle,
  CmnImgWrap,
  CmnFamilyImg,
  BlueSub,
  CmnBottomWrap,
  CmnRibbonBanner,
  CmnBottomTitle,
  CmnTerms,
  CustomWrap,
  CustomInput,
  FormGroup,
  TextInput,
  Label,
  ProceedButton,
  ErrorMessage,
} from "./Form1.style";
import LaxmiGreeting from "../../assets/img/laxmiImg.png";
import Power from "./../../assets/img/power.svg";
import Best from "./../../assets/img/bestPrice.svg";
import Hand from "./../../assets/img/handpicked.svg";
import Assist from "./../../assets/img/assistance.svg";
import Digital from "./../../assets/img/digital.svg";
import bestClass from "./../../assets/img/bestClass.svg";
import {
  ExecTable,
  ExecTableCell,
  ExecWrap,
  ExecWrap2,
  ExecWrapHr,
  ExecWrapText,
  ExecWrapTitle,
  LandingTitle,
  LandingWrapper,
} from "./Landing.style";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {
  addUserData,
  createUserFailed,
  createUserSuccess,
  getAffinityPreference,
  initCreateUser,
  saveUserDetails,
  setEmailDiscount,
  setPrevious,
  setShowFooter,
} from "./greeting.slice";
import { landingSchema } from "../../utils/validationSchema";
import Particle, { checkEmail } from "./Particle";
import EmailPopup from "./EmailPopup";
import SecureLS from "secure-ls";
import { getAllData } from "../../api/getAllData";
import VerifyEmail from "./VerifyEmail";
import "styled-components/macro";
import { useUrlQuery } from "../../custom-hooks";
import { emailPNB, micrositeData } from "./serviceApi";
import Loader from "../../components/Loader/Loader";
import { initLoader } from "../EligibilityPage/eligibilityCard.slice";

const SVG = () => {
  return (
    <svg
      className="cmnBottomCheckSvg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" dataName="Path 5"></path>
      <path
        fill="#4fcc6e"
        d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"
        dataName="Path 6"
      ></path>
    </svg>
  );
};
const Landing = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(landingSchema),
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const {
    erro,
    regionDetails,
    isLoading,
    status,
    userCreate,
    customerDetails,
    previous,
    showModal,
  } = useSelector(state => state.greeting);
  const { loader } = useSelector(state => state.eligibilityCard);
  const [email, setEmail] = useState(
    customerDetails.work_email || customerDetails.workEmail,
  );
  const [cId, setCID] = useState();
  const time = new Date();
  time.setSeconds(time.getSeconds() + Number(showModal));
  const dispatch = useDispatch();
  Particle(
    userCreate,
    status,
    () => {
      dispatch(createUserSuccess());
      history.push("/form1");
    },
    email,
  );
  const afflid = useUrlQuery("afflid");
  const utm_medium = useUrlQuery("utm_medium");
  const utm_source = useUrlQuery("utm_source");
  const c_id = useUrlQuery("c_id");
  const enquiryId = useUrlQuery("enquiryId");
  const returnEmail = useUrlQuery("email_id");

  const fetchMicrositeData = async () => {
    dispatch(initLoader(true));
    await micrositeData({
      utm_source,
      c_id,
    }).then(({ data }) => {
      if (data?.data?.redirect_page === "seo_page") {
        dispatch(initLoader(false));
        window.location.href = "https://www.elephant.in/term-life-insurance";
      } else {
        setCID(data?.data);
        dispatch(
          addUserData({
            ...customerDetails,
            c_id,
            c_name: data?.data?.name,
            c_product_type: data?.data?.product_type,
            c_redirect_page: data?.data?.redirect_page,
          }),
        );
        dispatch(initLoader(false));
        history.push("/form1");
      }
    });
  };

  useEffect(() => {
    if (!enquiryId && c_id && utm_source) {
      dispatch(initLoader(true));
      fetchMicrositeData();
    }
  }, []);

  useEffect(() => {
    const lastVisited = sessionStorage.getItem("lastVisited");
    if (lastVisited === "true") {
      history.push("/form3");
      sessionStorage.removeItem("lastVisited");
    }
  }, []);
  useEffect(() => {
    if (userCreate && status) {
    }
  }, [dispatch, history, isLoading, status, userCreate]);

  useEffect(() => {
    if (returnEmail) {
      setValue("workEmail", returnEmail);
      setEmail(returnEmail);
    }
  }, [returnEmail]);

  const emailRef = useRef();
  useEffect(() => {
    if (returnEmail) {
      setValue("workEmail", returnEmail);
      setEmail(returnEmail);
    }
  }, [returnEmail]);

  const onSubmit = data => {
    emailRef.current.blur();
    dispatch(
      saveUserDetails({ ...customerDetails, ...data, create: true }),
    ).then(() => {
      // checkEmail(
      //   { email: data.workEmail },
      //   (_, __, ___, emailCheckResponse) => {
      //     let displayName = true;
      //     if (emailCheckResponse) {
      //       const signComp = emailCheckResponse?.data?.signComp;
      //       const signCompDom = emailCheckResponse?.data?.signCompDom;
      //       displayName = signComp || signCompDom;
      //     }
      //     dispatch(setShowFooter(displayName));
      //     history.push("/form1");
      //   },
      //   () => {
      //     dispatch(setShowFooter(false));
      //     history.push("/form1");
      //   },
      // );
      // dispatch(createUserSuccess());
    });
    dispatch(initCreateUser());
  };
  const attachEmail = ref => {
    emailRef.current = ref;
    register(ref);
  };
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("enquiryId")) {
      sessionStorage.setItem("traceId", query.get("enquiryId"));
      dispatch(getAllData("", "/", query.get("enquiryId")));
      dispatch(initCreateUser());
    }
  }, []);
  useEffect(() => {
    const click = e => {
      if (e.key === "Enter") {
        document.getElementById("disableProceed").click();
      }
    };
    window.addEventListener("keydown", click);
    return () => {
      window.removeEventListener("keydown", click);
    };
  });
  const ls = new SecureLS();

  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (query.get("enquiryId") && !previous) {
      dispatch(initCreateUser());
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("enquiryId")) {
      if (customerDetails.work_email && previous) {
        dispatch(setPrevious(false));
        emailPNB({
          email: customerDetails.work_email,
          traceId: sessionStorage.getItem("traceId"),
        }).then(({ data }) => {
          dispatch(createUserFailed());
          if (data.isPnbDomain) dispatch(setEmailDiscount(data));
        });
        if (!customerDetails.annualIncome) {
          // dispatch(createUserFailed());
          history.push({
            pathname: "/form1",
          });
        } else if (!customerDetails.tobaccoHabit) {
          // dispatch(createUserFailed());
          history.push({
            pathname: "/form2",
          });
        } else if (!customerDetails.firstName) {
          // dispatch(createUserFailed());
          history.push({
            pathname: "/form3",
          });
        }
      }
    }
  }, [customerDetails]);

  if (
    afflid === "ac5cc5bf-19cb-45dc-afad-ea47a764bc74" ||
    afflid === "80938970-9k81-5f8l-a35d-9a4950d9adhz"
  ) {
    history.push({
      pathname: "/form1",
    });
    return null;
  }
  if (utm_medium === "santoba" || utm_medium === "ida") {
    history.push({
      pathname: "/form1",
    });
    return null;
  }

  if (loader) return <Loader />;

  return (
    <>
      <Container>
        <Form
          id="proceed_landing"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <LaxmiWrap>
            <LaxmiImg src={LaxmiGreeting}></LaxmiImg>
          </LaxmiWrap>
          <div>
            <MainHeader>Hi! I'm Laxmi.</MainHeader>
            <SubHeader fullWidth>
              <span>
                {" "}
                I’ll get you the right term life insurance plan at the best
                price.
              </span>
            </SubHeader>
          </div>
          <CmnTopWrap>
            <CmnBlue>
              <span>Rs. 1 crore</span>
              <span> life cover</span>
              <span> at ₹ 500/month*.</span>
              <BlueSub first> Get insured from</BlueSub>
              <BlueSub> the comfort of</BlueSub>
              <BlueSub> your home</BlueSub>
            </CmnBlue>
            <CmnYellow>
              <span>
                Tax savings on premiums u/s 80C of Rs. 1,50,000 and health
                premium u/s 80D of
              </span>
              <span> Rs.25,000.</span>
            </CmnYellow>
            <CmnSkyBlue>
              <span>No medical required*</span>
              <span> Covers COVID-19 claims</span>
            </CmnSkyBlue>
            <CmnTitle>
              Compare & Buy <span>Life insurance</span>
            </CmnTitle>
            <CmnImgWrap>
              <CmnFamilyImg src={FamilyImg}></CmnFamilyImg>
            </CmnImgWrap>
          </CmnTopWrap>
          <LandingWrapper>
            <LandingTitle
              css={`
                & > svg {
                  float: none;
                }
              `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1227.59 1351.72"
              >
                <rect
                  class="cls-1-gift"
                  x="49.12"
                  y="404.2"
                  width="536.15"
                  height="216.12"
                ></rect>
                <rect
                  class="cls-1-gift"
                  x="655.93"
                  y="404.2"
                  width="536.15"
                  height="216.12"
                ></rect>
                <rect
                  class="cls-1-gift"
                  x="119.78"
                  y="686.82"
                  width="469.86"
                  height="615.12"
                ></rect>
                <rect
                  class="cls-1-gift"
                  x="651.56"
                  y="686.82"
                  width="469.86"
                  height="615.12"
                ></rect>
                <rect
                  class="cls-1-gift"
                  x="577.34"
                  y="228.17"
                  width="99.73"
                  height="103.89"
                  rx="23.55"
                ></rect>
                <path
                  class="cls-1-gift"
                  d="M702,306.09s147.53,95.58,407.25,64.41l-27-47.79,31.17-43.63S959.66,308.17,893.17,295.7c0,0,251.41-51.94,110.12-214-108.13-124-299.2,157.91-299.2,157.91Zm18.7-47.79S919,45.7,968,162.72c37.37,89.35-247.29,95.58-247.29,95.58Z"
                ></path>
                <path
                  class="cls-1-gift"
                  d="M544.1,239.6S353-42.35,244.9,81.69C103.61,243.76,355,295.7,355,295.7c-66.49,12.47-220.24-16.62-220.24-16.62l31.16,43.63-27,47.79c259.72,31.17,407.25-64.41,407.25-64.41ZM280.22,162.72c49-117,247.26,95.58,247.26,95.58S242.82,252.07,280.22,162.72Z"
                ></path>
              </svg>
              <span
                css={`
                  @media screen and (max-width: 365px) {
                    display: block;
                  }
                `}
              >
                Exclusive discounts and offers only for Corporate Employees
              </span>
            </LandingTitle>
            <CustomWrap full>
              <CustomInput full>
                <FormGroup>
                  <TextInput
                    ref={attachEmail}
                    name="workEmail"
                    maxLength={52}
                    id="email__landingPage"
                    error={errors.workEmail && "danger"}
                    onChange={e => {
                      e.target.value = e.target.value.replace(" ", "");
                      setEmail(e.target.value.toLowerCase());
                    }}
                    type="text"
                    defaultValue={
                      customerDetails.work_email || customerDetails.workEmail
                    }
                    // value={email}
                    className="form-control"
                    required
                    style={{ textTransform: "lowercase" }}
                  />
                  <Label>Work Email ID</Label>
                  {errors.workEmail && (
                    <ErrorMessage>{errors.workEmail.message}</ErrorMessage>
                  )}
                </FormGroup>
              </CustomInput>
            </CustomWrap>
            <ProceedButton type="submit" id="disableProceed" full mobFull>
              <div>
                <span>Get Started </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                </svg>
              </div>
            </ProceedButton>
          </LandingWrapper>
          <ExecWrap>
            <ExecWrapText>Corporate Employee Engagement Program</ExecWrapText>
          </ExecWrap>
          <ExecWrap2>
            <ExecWrapHr></ExecWrapHr>
            <ExecWrapTitle>
              Your Work Email ID can unlock exclusive benefits
            </ExecWrapTitle>
            <ExecTable
              css={`
                padding: 0 2.61px;
              `}
            >
              <ExecTableCell>
                <img src={Power} alt="power"></img>
                <div>Corporate Superpower</div>
              </ExecTableCell>
              <ExecTableCell>
                <img src={Best} alt="best"></img>
                <div>Best Price</div>
              </ExecTableCell>
              <ExecTableCell>
                <img src={Hand} alt="hand"></img>
                <div>Handpicked Insurers</div>
              </ExecTableCell>
              <ExecTableCell>
                <img src={Assist} alt="hand"></img>
                <div>Assistance from Experts</div>
              </ExecTableCell>
              <ExecTableCell>
                <img src={Digital} alt="hand"></img>
                <div>Digital Relationship Manager</div>
              </ExecTableCell>
              <ExecTableCell>
                <img src={bestClass} alt="hand"></img>
                <div>Best In-Class Claim Service</div>
              </ExecTableCell>
            </ExecTable>
          </ExecWrap2>
          <CmnBottomWrap
            css={`
              & ul li svg {
                margin-right: 10px;
              }
            `}
          >
            <CmnRibbonBanner>Your life insurance covers:</CmnRibbonBanner>
            <ul>
              <li>
                <SVG />
                <span>Death Benefits</span>
              </li>
              <li>
                <SVG />
                <span>Critical Illness Cover</span>
              </li>
              <li>
                <SVG />
                <span>Accidental Death Benefit</span>
              </li>
              <li>
                <SVG />
                <span>Terminal Illness</span>
              </li>
            </ul>
          </CmnBottomWrap>
          <CmnTerms>
            *All discounts provided by insurers as per IRDAI approved insurance
            plans. Standard T&C apply.
          </CmnTerms>
        </Form>
      </Container>
      <EmailPopup email={email && email.split("@")[1]} />
      {showModal && (
        <VerifyEmail
          email={email}
          expiryTimestamp={time}
          showModal={showModal}
        />
      )}
    </>
  );
};

export default Landing;
