import React, { useState, useRef, useEffect } from "react";
import "./filter-menu.scss";
const MobileFilter = ({
  setOpen,
  items,
  handleChange,
  label,
  callBackRef,
  open,
  wholeLife,
}) => {
  const handleCloseButtonClick = () => {
    setCloseAnim(true);
    setTimeout(() => {
      setOpen(false);
    }, 290);
  };
  const [closeAnim, setCloseAnim] = useState(false);
  const [list, setList] = useState(items);
  const mobileRef = useRef(null);
  useEffect(() => {
    callBackRef(mobileRef);
  }, [mobileRef]);

  const popupHeight = () => {
    const listSize = list.length;
    if (listSize > 3) {
      return "50%";
    }
    if (listSize > 1) {
      return "40%";
    }
    if (listSize === 1) {
      return "20%";
    }
  };
  return (
    <>
      <div className="mobile-filter__overlay">&nbsp;</div>
      <div
        className={
          closeAnim
            ? "mobile-filter filterCloseMob"
            : "mobile-filter filterOpenMob"
        }
        style={{ height: `${popupHeight()}` }}
        ref={mobileRef}
      >
        <button
          type="button"
          className="mobile-filter__closeBtn "
          onClick={handleCloseButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            fill="#333"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
        <div className="forRef">
          {/* <h2 className="mobile-filter__popup-header">Select {label}</h2> */}
          <h2 className="mobile-filter__popup-header">{label}</h2>
        </div>
        {/* <div style={{ overflow: "scroll", height: "800px" }}> */}
        <div style={{ overflow: "scroll" }}>
          <ul>
            {list.map((data, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleChange(data);
                    setOpen(false);
                  }}
                  className="mobile-filter__select-option"
                >
                  {data}
                </div>
              );
            })}
            {wholeLife && (
              <div
                onClick={() => {
                  handleChange("Whole Life");
                  setOpen(false);
                }}
                className="mobile-filter__select-option"
              >
                Whole Life
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileFilter;
