import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCustomLocation } from ".";

function useNavigation() {
  const { redirectTo, pathname, gotoPage } = useCustomLocation();

  const { isDocumentUploaded } = useSelector(
    ({ documentUpload }) => documentUpload,
  );

  const { isPaymentSuccess, logged } = useSelector(
    ({ eligibilityPage }) => eligibilityPage,
  );

  const { isProposalSubmitted } = useSelector(
    ({ proposalPage }) => proposalPage,
  );

  const { verified, company_alias } = useSelector(state => state.otp);
  const { selectedQuote } = useSelector(({ quote }) => quote);

  const afterPayment = [
    "/proposalpage",
    "/documentUploadBajaj",
    "/documentuploadpage",
    "/thankyou",
  ];

  const beforePayment = [
    "/quotes",
    "/riderpage",
    "/riderPage",
    "/eligibilitypage",
  ];

  useEffect(() => {
    if (isDocumentUploaded) {
      if (pathname !== "/thankyou") {
        gotoPage(redirectTo.pages.thankyou);
      }
    } else if (
      isProposalSubmitted &&
      verified === false &&
      (company_alias === "bajaj_life" || company_alias === "icici_pru")
    ) {
      gotoPage(redirectTo.pages.otpverification);
    } else if (isProposalSubmitted && verified === true) {
      if (pathname !== "/documentuploadpage") {
        // if (selectedQuote?.company_alias !== "icici_pru") {
        gotoPage(redirectTo.pages.documentuploadpage);
        // }
      }
    } else if (isPaymentSuccess || logged) {
      if (
        pathname.toLocaleLowerCase() === "/riderpage" ||
        pathname.toLocaleLowerCase() === "/eligibilitypage" ||
        pathname.toLocaleLowerCase() === "/transactionunsuccessful"
      ) {
        gotoPage(redirectTo.pages.transactionsuccessful);
      } else if (
        pathname !== "/proposalpage" &&
        pathname !== "/transactionsuccessful"
      ) {
        gotoPage(redirectTo.pages.proposalPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentUploaded, isPaymentSuccess, isProposalSubmitted, logged]);

  return { isPaymentSuccess, isDocumentUploaded, isProposalSubmitted, logged };

  // if (isPaymentSuccess) {
  //   if (beforePayment.includes(pathname))
  //     gotoPage(redirectTo.pages.transactionsuccessful);
  // }

  //   useEffect(() => {
  //     if (isDocumentUploaded) {
  //       if (pathname !== "/thankyou") {
  //         gotoPage(gotoPage.pages.thankyou);
  //       }
  //     } else if (isProposalSubmitted) {
  //       if (selectedQuote?.company_alias !== "icici_pru")
  //         gotoPage(gotoPage.pages.documentuploadpage);
  //     } else if (isPaymentSuccess) {
  //       if (beforePayment.includes(pathname)) {
  //         gotoPage(gotoPage.pages.proposalPage);
  //       }
  //     } else if (!isPaymentSuccess) {
  //       if (afterPayment.includes(pathname)) {
  //         gotoPage(gotoPage.pages.eligibilitypage);
  //       }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [
  //     afterPayment,
  //     beforePayment,
  //     gotoPage,
  //     isDocumentUploaded,
  //     isPaymentSuccess,
  //     pathname,
  //     isProposalSubmitted,
  //   ]);
}

export { useNavigation };
