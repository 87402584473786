/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from "@hookform/resolvers";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { eligiblityCardSchema } from "../../utils/validationSchema";
import Card from "../Card/Card";
import Checkbox from "../Checkbox/Checkbox";
import CardTitle from "../Card/CardTitle/CardTitle";
import CustomTooltip from "./../Tooltip/CustomTooltip";
import tooltip from "../../assets/img/tooltipElig.svg";
import ReviewProduct from "../ReviewPopUp/ReviewPopUp";
import CustomDropDown from "./../CutomDropDown/CustomDropDown";
import ToggleButton from "./../ToggleButton/ToggleButton";
import location from "../../assets/img/location.svg";
import Loader from "../../components/Loader/Loader";
import { extractNumbers } from "../../utils";
import "./EligibilityCard.scss";
import {
  ALL_COUNTRIES,
  COUNTRIES,
  BANDHANCOUNTRIES,
  KOTAKCOUNTRIES,
  BAJAJCOUNTRIES,
  MAXLIFECOUNTRIES,
  natureOfWork,
  natureOfWorkICICI,
  USAStates,
  PNBCOUNTRIESARR,
  HDFC_COUNTRIES,
} from "./options";
import {
  numOnly,
  getValue,
  capitalizeSentence,
  getObjectFromArray,
  capitalizeSentenceJoin,
} from "./../../utils/inputUtils";
import HouseWifeCriteriaPopup from "./../Popup/HouseWifePopup/HouseWifeCriteriaPopup";
import {
  useCheckUserEligibility,
  useValidateSelect,
  useDisable,
} from "./validation-hooks";
import {
  Form,
  FormGroup,
  Label,
  CustomDropDownFont,
  TextInputElg,
  AgeWrapper,
} from "./../Form/Form";
import {
  saveEligibilityDetails,
  getRegion,
  generationBI,
  initLoader,
  resetBiData,
  createRegionData,
  setAgreeNriTerms,
  saveBiData,
  redirect,
  saveEligibilityMessage,
  setBiLoader,
  handleEligibilityGrid,
  getICICIEligibilityValidation,
  setIciciValidationData,
} from "../../modules/EligibilityPage/eligibilityCard.slice";
import NriCriteriaPopup, {
  nriPopupEvents,
} from "../Popup/NriCriteriaPopup/NriCriteriaPopup";
import NriResidingPopup from "../Popup/NriResidingPopup/NriResidingPopup";
import LoadingBi from "../../modules/ThankYouPage/LoadingBi/LoadingBi";
import { useAmountInput } from "../../custom-hooks/useAmountInput";
import { useCustomLocation } from "../../custom-hooks";
import {
  educationValuesMap,
  useEligibilityGrid,
} from "./validation-hooks/useEligibilityGrid";
import styled from "styled-components";
import { HousewifeEligibilityCriteriaPopup } from "./components/maxlife/HousewifeEligibilityCriteriaPopup";
import { selectSelectedAddOns } from "../../modules/RiderPage/RiderPage.slice";
import AnnualIncomePopup, {
  NriMedicalPopup,
} from "../Popup/AnnualIncomePopup/AnnualIncomePopup";
import "styled-components/macro";

const terms_condition_max_wipro = (
  <>
    I confirm all the details shared are correct and accurate as per my
    knowledge, and I agree with all the{" "}
    <a
      target="_blank"
      href="https://elephant.in/terms-and-condition"
      style={{ textDecoration: "underline" }}
    >
      Terms &amp; Conditions
    </a>
    . I also declare that the information provided above is true and accept that
    if it is found false, it may impact claims and/or the company reserves the
    right to terminate the policy immediately. I agree the policy will be issued
    on meeting specific parameters and subject to Company's underwriting and
    acceptance. Any adverse findings/disclosure in Video Medicals (if
    applicable) will trigger a complete medical grid requirement. “No KYC” is
    applicable provided “know your customer” (KYC) details check is successful,
    subject to the clear information being available with “Central KYC
    registry”. I agree any changes to the details post payment might require
    additional payment. Alliance Insurance Brokers Pvt. Ltd. (including its
    representatives) shall not be held liable for any changes due to incorrect
    information
  </>
);
const terms_condition_max_mobile =
  "I confirm all the details shared are correct and accurate as per my knowledge, and I agree with all the Terms & Conditions. I also declare that the information provided above is true and accept that if it is found false, it may impact claims and/or the company reserves the right to terminate the policy immediately. I agree the policy will be issued on meeting specific parameters and subject to Company’s underwriting and acceptance. Any adverse findings/disclosure in Video Medicals (if applicable) will trigger a complete medical grid requirement. “No KYC” is applicable provided “know your customer” (KYC) details check is successful, subject to the clear information being available with “Central KYC registry”.  I agree any changes to the details post payment might require additional payment. Alliance Insurance Brokers Pvt. Ltd. (including its representatives) shall not be held liable for any changes due to incorrect information";
const terms_condition = (
  <>
    I confirm all the details shared are correct and accurate as per my
    knowledge, and I agree with all the{" "}
    <a
      target="_blank"
      href="https://elephant.in/terms-and-condition"
      style={{ textDecoration: "underline" }}
    >
      Terms &amp; Conditions
    </a>
    . I also declare that the information provided above is true and accept that
    if it is found false, it may impact claims and/or the company reserves the
    right to terminate the policy immediately. I agree the policy will be issued
    on meeting specific parameters and subject to Company’s underwriting and
    acceptance. Any adverse findings/disclosure in Video Medicals (if
    applicable) will trigger a complete medical grid requirement. I agree any
    changes to the details post payment might require additional payment.
    Alliance Insurance Brokers Pvt. Ltd. (including its representatives) shall
    not be held liable for any changes due to incorrect information.
  </>
);

const EligibilityCard = ({
  history,
  selectedQuote,
  inbuiltCovers,
  addOnsList,
  additionalrider,
  annualIncome,
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const companyAlias = selectedQuote?.company_alias;
  const [checkedHeart, setCheckedHeart] = useState(false);
  const [negativePincode, setNegativePincode] = useState(false);
  const [checkNRI, setCheckNRI] = useState(false);
  const [hdfcQuestion3, sethdfcQuestion3] = useState(false);
  const [checkMax, setCheckMax] = useState(false);
  const [intentToTravel, setIntendToTravel] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState();
  const [review, setReview] = useState(false);
  const [negativePincodeErrors, setNegativePincodeErrors] = useState({
    status: false,
    error: "",
  });
  const [selectedOccupation, setSelectedOccupation] = useState("Occupation");
  const [selectedNatureOfWork, setSelectedNatureOfWork] =
    useState("Nature of work");
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [selectedUSAstate, setSelectedUSAstate] = useState("Select State");
  const [test, setTest] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [notEligiblePinCode, setNotEligiblePinCode] = useState(false);
  const [color, setColor] = useState(false);
  const [generateBI, setGenerateBI] = useState(true);
  const [agreeNriTerm, setAgreeNriTerm] = useState(false);
  const [notEligiblePinCodeText, setNotEligiblePinCodeText] = useState("");
  const [notEligibleCountry, setNotEligibleCountry] = useState(false);
  const [stateDisable, setStateDisable] = useState(false);
  const [husbandHoldsPolicy, setHusbandHoldsPolicy] = useState(true);
  const [selectedCity, setSelectedCity] = useState("Select City");
  const [showAnnualLabel, setShowAnnualLabel] = useState(true);
  const { details } = useSelector(state => state.formCard);
  const { customerDetails, isWipro } = useSelector(state => state.greeting);
  const [selectedEducation, setSelectedEducation] = useState(
    customerDetails.qualification,
  );
  const [nriEligibility, setNriEligibility] = useState(true);
  const [showNRIMedical, setShowNRIMedical] = useState(false);
  const customerName =
    customerDetails?.firstName + " " + customerDetails?.lastName;
  const company = customerDetails?.work_email;
  const {
    isSalaried,
    regionDetails,
    appNo,
    biUrl,
    loader,
    status,
    agreeNriTerms,
    redirectionUrl,
    eligibilityDetails,
    saveLoading,
    biLoading,
    isNegative,
    iciciValidationData,
    paymentStatusLoader,
  } = useSelector(state => state.eligibilityCard);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [isTriggered, setIsTriggered] = useState(false);
  const notAllowed = isNegative?.status;
  const {
    value: annualIncomeText,
    handleChange: handleAnnualIncomeTextChange,
    valueInDigits: annualIncomeDigits,
  } = useAmountInput(
    appNo ? customerDetails.annualIncome : customerDetails.annualIncome || "",
    { isEligibility: true },
  );

  useEffect(() => {
    if (saveLoading) {
      setLoading1(true);
    } else {
      setLoading1(false);
    }
    if (biLoading) {
      setLoading2(true);
    } else {
      setLoading2(false);
    }
  }, [saveLoading, biLoading]);

  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(eligiblityCardSchema),
  });

  const { isValid } = formState;

  const enteredPincode = watch("pinCode");

  //CustomHooks
  const [vaccinationStatus, setVaccinationStatus] = useState("Please Select");

  const gender = customerDetails?.gender;
  const sumAssured = selectedQuote.sumAssured || selectedQuote.cover_amount;
  const {
    notEligibleEducation,
    notEligibleAnnualIncome,
    notEligibleSumAssured,
    notEligibleOccupation,
    annualIncomeErrorMessage,
    sumInsuredErrorMessage,
    isErrorForNRI,
    setNotEligibleEducation,
    setNotEligibleOccupation,
    setSumInsuredErrorMessage,
    setNotEligibleAnnualIncome,
    setAnnualIncomeErrorMessage,
    setNotEligibleSumAssured,
    riderNotAllowed,
    riderNotAllowedMessage,
    setRiderNotAllowedMessage,
    setRiderNotAllowed,
    notEligibleADBURider,
    notEligibleCIBFRider,
    setNotEligibleADBURider,
    setNotEligibleCIBFRider,
    ageNotAllowed,
    ageNotAllowedMessage,
    setAgeNotAllowed,
    setAgeNotAllowedMessage,
  } = useCheckUserEligibility({
    companyAlias,
    selectedOccupation,
    selectedEducation,
    annualIncome,
    checkNRI,
    husbandHoldsPolicy,
    gender,
    sumAssured,
    customerDetails,
  });

  const { enquiryId } = useCustomLocation();
  // const { isPnbPreference } = useSelector(({ greeting }) => greeting);
  // dispatch(setloaderForQuoteToRider(false));
  // const isInstaCardBandhan =
  //   companyAlias === "bandhan_life" &&
  //   isPnbPreference == true &&
  //   selectedQuote?.sub_cover_name == "insta_cover"
  //     ? true
  //     : false;
  const { filter } = useSelector(state => state.quoteFilter);
  useEffect(() => {
    if (companyAlias)
      dispatch(
        handleEligibilityGrid({
          company_alias: companyAlias,
          trace_id: enquiryId,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  const {
    validateSelect,
    errorEducation,
    errorOccupation,
    errorCountry,
    errorNatureOfWork,
    errorSelectCity,
    errorVaccinationStatus,
  } = useValidateSelect({
    companyAlias,
    checkNRI,
    selectedCountry,
    selectedEducation,
    selectedNatureOfWork,
    selectedOccupation,
    selectedCity,
    vaccinationStatus,
    //	selectedArea,
    //area,
  });
  const [disclaimer, setDisclaimer] = useState(false);

  const { disable, setDisable } = useDisable({
    details,
    // agreeTerm,
    vaccinationStatus,
    selectedEducation,
    selectedOccupation,
    selectedNatureOfWork,
    checkNRI,
    selectedCountry,
    test,
    validateSelect,
    isValid,
    checkMax,
    checkedHeart,
    loader,
    notEligibleEducation,
    notEligibleOccupation,
    notEligibleAnnualIncome,
    notEligibleSumAssured,
    notEligiblePinCode,
    notEligibleCountry,
    errorNatureOfWork,
    annualIncome,
    intentToTravel,
    sumAssured,
    stateDisable,
    husbandHoldsPolicy,
    review,
    selectedCity,
    //selectedArea,
    //area,
    riderNotAllowed,
    notEligibleADBURider,
    notEligibleCIBFRider,
    ageNotAllowed,
  });
  useEffect(() => {
    if (review) {
      setNotEligibleCountry(false);
      setDisable(false);
    }
  }, [disable, review]);
  useEffect(() => {
    if (hdfcQuestion3 === true) {
      setAgreeTerm(false);
    }
  }, [hdfcQuestion3]);

  useEffect(() => {
    if (selectedCountry === "Select Country") setNotEligibleCountry(false);
    if (selectedCountry !== "Select Country") {
      const countries = getCountryList(companyAlias);
      if (!countries.includes(selectedCountry)) {
        setNotEligibleCountry(true);
      }
      if (countries.includes(selectedCountry)) {
        setNotEligibleCountry(false);
      }
      if (
        companyAlias === "icici_pru" &&
        vaccinationStatus === "Fully Vaccinated"
      ) {
        setNotEligibleCountry(false);
      }
    }
  }, [
    selectedCountry,
    checkNRI,
    vaccinationStatus,
    companyAlias,
    notEligibleCountry,
  ]);

  useEffect(() => {
    if (
      companyAlias === "icici_pru" &&
      selectedCountry === "USA" &&
      selectedUSAstate === "Select State"
    ) {
      setStateDisable(true);
    }
  }, [selectedCountry, selectedUSAstate]);

  useEffect(() => {
    // setReview(false);
    setGenerateBI(false);
    dispatch(saveBiData(false));
    dispatch(createRegionData(false));
    setClicked(false);
  }, []);

  useEffect(() => {
    if (
      companyAlias === "icici_pru" &&
      pinCode?.length === 6 &&
      selectedQuote?.medicalCondition === "Physical + Video Medical"
    ) {
      setCheckNRI(true);
    }
  }, [checkNRI, pinCode, selectedOccupation]);

  // const occupation = eligibilityDetails?.selectedOccupation;
  // const education = eligibilityDetails?.selectedEducation;
  useEffect(() => {
    if (selectedQuote.company_alias === "hdfc_life") {
      if (
        selectedEducation === "Illiterate" ||
        selectedEducation === "Below 10th" ||
        selectedEducation === "10th Pass" ||
        selectedEducation === "12th Pass" ||
        selectedEducation === "Diploma"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (
      selectedQuote.company_alias === "bajaj_life" &&
      selectedQuote?.medicalCondition === "Video Medical"
    ) {
      if (
        selectedEducation === "HSC" ||
        selectedEducation === "Matriculation" ||
        selectedEducation === "Non Matriculation"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (selectedQuote.company_alias === "bandhan_life") {
      if (
        selectedEducation === "Diploma" ||
        selectedEducation === "10th Pass" ||
        selectedEducation === "12th Pass"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (selectedQuote.company_alias === "tata_aia_life") {
      if (
        selectedOccupation === "Agriculturist" &&
        (selectedEducation === "Graduate" ||
          (selectedEducation === "Post Graduate" &&
            annualIncomeDigits >= 1000000))
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (selectedQuote.company_alias === "icici_pru") {
      if (
        selectedEducation === "12th Pass" ||
        selectedEducation === "10th Pass" ||
        selectedEducation === "Diploma" ||
        selectedEducation === "Below 10th"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (
      selectedQuote.company_alias === "kotak_life" &&
      selectedQuote?.medicalCondition === "Video Medical"
    ) {
      if (
        selectedEducation === "12th Pass" ||
        selectedEducation === "Below 10th" ||
        selectedOccupation === "Housewife"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
    if (selectedQuote.company_alias === "pnb_metlife") {
      if (
        selectedEducation === "12th Pass" ||
        selectedEducation === "Diploma"
      ) {
        setDisclaimer(true);
      } else setDisclaimer(false);
    }
  }, [selectedEducation, selectedQuote, selectedOccupation]);

  const { fullyVaccinated } = useSelector(state => state.bajajDiscount);

  const vaccinationOptionForBajaLife = companyAlias === "bajaj_life" &&
    fullyVaccinated === "N" && ["Partially", "Not Vaccinated"];

  const pin = eligibilityDetails?.pinCode;

  useEffect(() => {
    if (selectedQuote?.company_alias !== "bandhan_life") {
      setSelectedCity("Select City");
      //	setSelectedArea("Select Area");
      dispatch(createRegionData(false));
      // if (pin) setPinCode(pin);
      if (pinCode?.length === 6) {
        setNotEligiblePinCode(false);
        var data = {
          pinCode: pinCode || eligibilityDetails?.pinCode,
          companyAlias: selectedQuote?.company_alias,
          traceId: enquiryId,
        };
        // if (selectedQuote?.company_alias === "bandhan_life") {
        // 	data = {
        // 		pinCode: pinCode || eligibilityDetails?.pinCode,
        // 		companyAlias: selectedQuote?.company_alias,
        // 		traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
        // 	};
        // }
        dispatch(getRegion(data));
      }
      // if (pin) {
      //   setNotEligiblePinCode(false);
      //   const data = {
      //     pinCode: eligibilityDetails?.pinCode,
      //     companyAlias: selectedQuote?.company_alias,
      //   };
      //   dispatch(getRegion(data));
      // }
    }
  }, [pinCode, pin]);

  //checkPincodeBandhan
  useEffect(() => {
    if (
      selectedQuote?.company_alias === "bandhan_life" &&
      selectedOccupation !== "Occupation" &&
      selectedEducation !== "Highest Education"
    ) {
      dispatch(createRegionData(false));
      // if (pin) setPinCode(pin);
      if (pinCode?.length === 6) {
        setNotEligiblePinCode(false);
        var data = {
          pinCode: pinCode || eligibilityDetails?.pinCode,
          companyAlias: selectedQuote?.company_alias,
          annualIncome: annualIncome,
          occupation: selectedOccupation,
          education: selectedEducation,
          traceId: enquiryId,
        };

        dispatch(getRegion(data));
      }
    }
  }, [pinCode, pin, selectedOccupation, selectedEducation]);

  useEffect(() => {
    if (regionDetails?.city) {
      setSelectedCity(capitalizeSentenceJoin(regionDetails?.city[0]));
      // const data = {
      // 	pinCode: pinCode || eligibilityDetails?.pinCode,
      // 	companyAlias: selectedQuote?.company_alias,
      // 	city: regionDetails?.city[0],
      // };
      //	dispatch(getArea(data));
    } else {
      setSelectedCity("Select City");
    }
  }, [regionDetails]);

  useEffect(() => {
    dispatch(resetBiData(false));
    // dispatch(resetAppNo(false));
  }, []);

  useEffect(() => {
    if (clicked && appNo) {
      dispatch(initLoader(false));
      setClicked(false);
    }
    dispatch(setBiLoader(false));
    if (clicked && redirectionUrl) {
      dispatch(initLoader(false));
      setClicked(false);
    }
  }, [appNo, redirectionUrl, biUrl]);

  useEffect(() => {
    dispatch(saveBiData(false));
    dispatch(createRegionData(false));
  }, []);

  useEffect(() => {}, [selectedQuote?.company_alias, status]);

  useEffect(() => {
    if (!checkNRI) {
      dispatch(setAgreeNriTerms(false));
      setNotEligibleCountry(false);
    }
  }, [checkNRI]);

  useEffect(() => {
    if (companyAlias === "icici_pru" && !iciciValidationData.status) {
      setNotEligiblePinCodeText(iciciValidationData.errors);
    }
  }, [pinCode]);

  useEffect(() => {
    if (regionDetails?.status === false && companyAlias !== "icici_pru") {
      setNotEligiblePinCode(true);
      // setNotEligiblePinCodeText(regionDetails.errors);
      setNotEligiblePinCodeText("Please put valid pincode");
      // setPinCode("");
      // setValue("pinCode", "");
      dispatch(createRegionData(false));
    }
  }, [regionDetails?.status]);
  useEffect(() => {}, [biUrl]);

  // useEffect(() => {
  //   if (!eligibilityDetails) {
  //     setReview(false);
  //   }
  // }, [review]);

  //If user changes their education ofter they agree for nri terms
  useEffect(() => {
    setCheckNRI(false);
    dispatch(setAgreeNriTerms(false));
  }, [selectedEducation, dispatch]);

  // eligibilit check icici

  // useEffect(() => {
  //   if (
  //     selectedEducation !== "Highest Education" &&
  //     selectedOccupation !== "Occupation" &&
  //     annualIncomeText
  //   ) {
  //     const data = {
  //       occupation: selectedOccupation,
  //       education: selectedEducation,
  //       annualIncome: extractNumbers(annualIncomeText),
  //       traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
  //     };
  //     debouncedSave(data);
  //   } else {
  //     saveEligibility(true);
  //   }
  // }, [selectedEducation, selectedOccupation, annualIncomeText]);
  const [isEducationErr, setIsEducationErr] = useState(false);

  const {
    isLoading: isEligibilityGridLoading,
    educationDropDown,
    occupationDropDown,
    eligibilityFlag,
    eligibilityErrors,
    setEligibilityErrors,
    errorMessages,
    checkEligibility,
    education_list,
    occupation_list,
    getEducationICValue,
    getOccupationICValue,
    annualIncomeLimit,
    setAnnualIncomeLimit,
    annualIncomeFlag,
    newCover,
    setNewCover,
    showDisclaimer,
    filterBySalary,
    coverAmount,
  } = useEligibilityGrid(gender, isEducationErr, setColor);
  const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);

  useEffect(() => {
    if (
      pinCode &&
      pinCode?.length === 6 &&
      isNegative &&
      companyAlias === "bajaj_life" &&
      (selectedEducation === "Graduate" ||
        selectedEducation === "Post Graduate") &&
      (selectedOccupation.includes("Salaried") ||
        selectedOccupation === "Business" ||
        selectedOccupation === "Professional") &&
      annualIncomeDigits >= 500000
    ) {
      setNegativePincode(true);
    } else {
      setNegativePincode(false);
      setNegativePincodeErrors({
        status: false,
        error: "",
      });
    }
  }, [
    isNegative,
    selectedOccupation,
    pinCode,
    selectedEducation,
    annualIncomeDigits,
    companyAlias,
  ]);

  useEffect(() => {
    if (
      companyAlias === "max_life" &&
      pinCode?.length === 6 &&
      selectedOccupation !== "Occupation"
    ) {
      if (
        selectedEducation === "12th Pass" &&
        pinCode &&
        isNegative?.pincode_type !== undefined
      ) {
        setNotEligiblePinCode(true);
        setNotEligiblePinCodeText(
          "The mentioned pin code is not serviceable by Max Life",
        );
      } else if (
        selectedOccupation === "Others" &&
        pinCode &&
        isNegative.pincode_type !== undefined
      ) {
        setNotEligiblePinCode(false);
        setNegativePincode(false);
        setNotEligiblePinCodeText("");
        setNegativePincodeErrors({
          status: false,
          error: "",
        });
      } else {
        if (pinCode && isNegative?.pincode_type === undefined) {
          setNotEligiblePinCode(false);
          setNotEligiblePinCodeText("");
        }
        if (pinCode && isNegative.pincode_type === "negative") {
          setNegativePincode(true);
          setNotEligiblePinCode(false);
          setNotEligiblePinCodeText("");
        } else if (
          pinCode &&
          isNegative.pincode_type === "positive" &&
          isNegative.allowed_sa === "1Cr"
        ) {
          if (parseInt(sumAssured) > 10000000) {
            if (
              selectedOccupation === "Self Employed" ||
              selectedOccupation === "Professional"
            ) {
              setNegativePincode(false);
              setNotEligiblePinCode(true);
              setNotEligiblePinCodeText(
                "The mentioned pin code is not serviceable by Max Life",
              );
            } else {
              setNegativePincode(true);
              setNotEligiblePinCode(false);
              setNotEligiblePinCodeText("");
            }
          } else if (
            parseInt(sumAssured) <= 10000000 &&
            (selectedEducation === "Graduate" ||
              selectedEducation === "Post Graduate")
          ) {
            if (
              selectedOccupation !== "Salaried" &&
              selectedOccupation !== "Occupation"
            ) {
              setNotEligiblePinCode(false);
              setNotEligiblePinCodeText(
                "The mentioned pin code is not serviceable by Max Life",
              );
            } else if (
              selectedOccupation === "Salaried" &&
              annualIncomeDigits >= 1000000
            ) {
              setNegativePincode(false);
              setNegativePincodeErrors({
                status: false,
                error: "",
              });
              setNotEligiblePinCode(false);
              setNotEligiblePinCodeText("");
            } else {
              if (
                selectedOccupation === "Salaried" &&
                annualIncomeDigits < 1000000
              ) {
                setNegativePincodeErrors({
                  status: true,
                  error:
                    "Your annual income should be minimum 10 lakhs as you are a salaried",
                });
              } else {
                setNotEligiblePinCode(false);
                setNotEligiblePinCodeText("");
              }
            }
          }
        } else if (
          pinCode &&
          isNegative.pincode_type === "positive" &&
          isNegative.allowed_sa === "2Cr"
        ) {
          if (parseInt(sumAssured) > 20000000) {
            setNegativePincode(true);
            setNotEligiblePinCode(false);
            setNotEligiblePinCodeText("");
          } else if (
            parseInt(sumAssured) <= 20000000 &&
            (selectedEducation === "Graduate" ||
              selectedEducation === "Post Graduate")
          ) {
            if (
              (selectedOccupation === "Salaried" &&
                annualIncomeDigits >= 600000) ||
              (selectedOccupation === "Self Employed" &&
                annualIncomeDigits >= 1000000) ||
              (selectedOccupation === "Professional" &&
                annualIncomeDigits >= 1000000)
            ) {
              setNegativePincode(false);
              setNotEligiblePinCode(false);
              setNotEligiblePinCodeText("");
            } else {
              if (
                selectedOccupation === "Salaried" &&
                annualIncomeDigits < 600000
              ) {
                setNegativePincodeErrors({
                  status: true,
                  error:
                    "Your annual income should be minimum 6 lakhs as you are a salaried",
                });
              } else if (
                (selectedOccupation === "Self Employed" &&
                  annualIncomeDigits < 1000000) ||
                (selectedOccupation === "Professional" &&
                  annualIncomeDigits < 1000000)
              ) {
                setNegativePincodeErrors({
                  status: true,
                  error: `Your annual income should be minimum 10 lakhs as you are a ${selectedOccupation}`,
                });
              } else {
                setNegativePincode(false);
                setNotEligiblePinCode(true);
                setNotEligiblePinCodeText(
                  "The mentioned pin code is not serviceable by Max Life",
                );
              }
            }
          }
        }
      }
    }
  }, [
    isNegative,
    pinCode,
    sumAssured,
    selectedEducation,
    selectedOccupation,
    annualIncomeDigits,
    companyAlias,
  ]);

  useEffect(() => {
    if (education_list) {
      const educationValue = educationValuesMap[selectedEducation];
      const educationObject = education_list.find(
        education => education.education_value === educationValue,
      );

      if (educationObject) {
        const { education } = educationObject;
        setSelectedEducation(education);
      }
    }
  }, [education_list]);

  useEffect(() => {
    if (
      selectedEducation !== "Highest Education" &&
      // selectedOccupation !== "Occupation" &&
      annualIncomeText &&
      !isEligibilityGridLoading
      //  &&
      // isTriggered
    ) {
      if (
        (companyAlias === "icici_life" ||
          companyAlias === "max_life" ||
          companyAlias === "bajaj_life") &&
        pinCode
      ) {
        checkEligibility({
          education: selectedEducation,
          occupation: selectedOccupation,
          annualIncome: annualIncomeDigits,
          pincode: pinCode,
          vaccinationStatus,
        });
        setIsTriggered(false);
      } else {
        checkEligibility({
          education: selectedEducation,
          occupation: selectedOccupation,
          annualIncome: annualIncomeDigits,
          pincode: pinCode,
          vaccinationStatus,
        });
        setIsTriggered(false);
      }
    }
  }, [
    selectedEducation,
    selectedOccupation,
    annualIncomeDigits,
    vaccinationStatus,
    pinCode,
  ]);

  useEffect(() => {
    if (companyAlias === "icici_pru" && pinCode.length === 6) {
      dispatch(
        getICICIEligibilityValidation({
          education_text: selectedEducation,
          vaccinationStatus,
          occupation: occupation_list?.find(
            i => i.occupation === selectedOccupation,
          )?.occupation_value,
          education: education_list?.find(
            i => i.education === selectedEducation,
          )?.education_value,
          occupation_text: selectedOccupation,
          is_nri: checkNRI,
          annualIncome: annualIncomeDigits,
          pincode: pinCode,
          traceId: enquiryId,
        }),
      );
    } else {
      dispatch(setIciciValidationData(false));
    }
  }, [
    annualIncomeDigits,
    checkNRI,
    pinCode,
    selectedEducation,
    selectedOccupation,
    vaccinationStatus,
  ]);

  const handleClick = status => {
    if (
      selectedQuote.integration_type !== "R" &&
      companyAlias !== "kotak_life"
    ) {
      dispatch(initLoader(true));
      setClicked(true);

      dispatch(setBiLoader(true));
      dispatch(
        generationBI(
          {
            traceId: enquiryId || sessionStorage.getItem("traceId"),
          },
          biUrl => {
            if (biUrl) {
              if (selectedQuote?.company_alias !== "kotak_life")
                if (biUrl.biPdfUrl) {
                  let url = biUrl.biPdfUrl;
                  if (!biUrl.biPdfUrl.includes("http")) {
                    url = "https://" + url;
                  }
                  window.open(url, "_blank");
                  window.focus();
                }

              setReview(true);
            }
          },
        ),
      );
    } else {
      dispatch(initLoader(true));
      setClicked(true);
      dispatch(redirect({ traceId: enquiryId }));
    }
  };
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (query.get("redirect")) {
      dispatch(redirect({ traceId: enquiryId }));
    }
  }, []);
  async function kotakRedirection(redirectionUrlResponse) {
    try {
      const Form = document.createElement("form");
      Form.action = redirectionUrlResponse;
      Form.method = "post";
      Form.target = "_blank";

      Form.style.display = "none";

      document.body.appendChild(Form);

      Form.submit();

      document.body.removeChild(Form);
    } catch (error) {
      console.error({ tataRedirectionError: error });
    }
  }
  //tataRediretion
  async function tataRedirection(redirectionUrlResponse) {
    try {
      const tataForm = document.createElement("form");
      tataForm.action =
        "https://sellonlineuat.tataaia.com/wps/PA_TATAAIA_SO/GenericRedirection";
      tataForm.method = "post";
      tataForm.target = "_blank";
      tataForm.style.display = "none";

      const input = document.createElement("input");
      input.name = "REQUEST";
      input.value = redirectionUrlResponse;

      tataForm.appendChild(input);
      document.body.appendChild(tataForm);

      tataForm.submit();

      document.body.removeChild(tataForm);
    } catch (error) {
      console.error({ tataRedirectionError: error });
    }
  }
  useEffect(() => {
    const doRedirection = async () => {
      if (
        redirectionUrl &&
        selectedQuote?.company_alias !== "tata_aia_life" &&
        selectedQuote?.company_alias !== "kotak_life"
      ) {
        window.open(redirectionUrl);
      } else if (selectedQuote?.company_alias === "tata_aia_life") {
        dispatch(initLoader(true));
        // tataRef.current.click();
        await tataRedirection(redirectionUrl);
      } else if (selectedQuote?.company_alias === "kotak_life") {
        dispatch(initLoader(true));
        await kotakRedirection(redirectionUrl);
      }
    };
    if (query.get("redirect") && redirectionUrl) doRedirection();
  }, [redirectionUrl]);
  const onSubmit = _formData => {
    let data = { ..._formData };
    data.annualIncome = extractNumbers(data.annualIncome);
    data.termEducation =
      getEducationICValue(selectedEducation) || selectedEducation;
    data.termOccupation =
      getOccupationICValue(selectedOccupation) || selectedOccupation;
    // dispatch(updateUserData({ value: data.termEducation }));
    // const age = getAge(data.dob);

    // const annualIncomeError = validateAnnualIncome(data.annualIncome, {
    //   age,
    //   education,
    // });

    // if (annualIncomeError) {
    //   setError("annualIncome", {
    //     type: "manual",
    //     message: annualIncomeError,
    //   });
    //   return;
    // }

    let isHeartPatient = "NO";
    let isNRI = "NO";
    let isAgreeTerm = "NO";
    if (checkedHeart) {
      isHeartPatient = "YES";
    }
    if (checkNRI) {
      isNRI = "YES";
    }
    if (!checkNRI) {
      setSelectedCountry(false);
    }

    if (agreeTerm) {
      isAgreeTerm = "YES";
    }
    setTest(true);

    const formData = {
      ...details,
      ...data,
      vaccinationStatus,
      selectedNatureOfWork,
      selectedEducation,
      selectedOccupation,
      selectedCountry,
      selectedUSAstate,
      isHeartPatient,
      isNRI,
      isAgreeTerm,
      companyAlias,
      search,
      enquiryId,
      //	selectedArea,
      selectedCity,

      state: regionDetails?.state
        ? capitalizeSentenceJoin(regionDetails?.state)
        : "",
      selectedQuote,
    };

    console.log({
      selectedCountry,
      loader,
      companyAlias: selectedQuote?.company_alias,
    });
    const checkCountry = () => {
      if (selectedCountry && selectedCountry !== "Select Country") {
        dispatch(
          saveEligibilityDetails(formData, () => {
            if (
              selectedQuote?.company_alias === "kotak_life" ||
              selectedQuote?.company_alias === "bajaj_life" ||
              selectedQuote?.company_alias === "icici_pru" ||
              selectedQuote?.company_alias === "max_life" ||
              selectedQuote?.company_alias === "bandhan_life" ||
              selectedQuote?.company_alias === "tata_aia_life" ||
              selectedQuote?.company_alias === "pnb_metlife" ||
              selectedQuote.company_alias === "hdfc_life"
            ) {
              handleClick();
            }
          }),
        );
        if (
          selectedQuote?.company_alias === "kotak_life" ||
          selectedQuote?.company_alias === "bajaj_life" ||
          selectedQuote?.company_alias === "icici_pru" ||
          selectedQuote?.company_alias === "max_life" ||
          selectedQuote?.company_alias === "bandhan_life" ||
          selectedQuote?.company_alias === "tata_aia_life" ||
          selectedQuote?.company_alias === "pnb_metlife" ||
          selectedQuote.company_alias === "hdfc_life"
        ) {
          // handleClick();
          setReview(true);
        }
        dispatch(initLoader(true));
        setGenerateBI(false);
      }
    };
    if (
      selectedEducation &&
      selectedEducation !== "Highest Education" &&
      selectedOccupation &&
      selectedOccupation !== "Occupation"
      // selectedNatureOfWork &&
      // selectedNatureOfWork !== "Nature of work"
    ) {
      if (checkNRI) {
        checkCountry();
      } else {
        dispatch(
          saveEligibilityDetails(formData, () => {
            if (
              selectedQuote?.company_alias
              //  === "kotak_life" ||
              // selectedQuote?.company_alias === "bajaj_life" ||
              // selectedQuote?.company_alias === "icici_pru" ||
              // selectedQuote?.company_alias === "max_life" ||
              // selectedQuote?.company_alias === "bandhan_life" ||
              // selectedQuote?.company_alias === "tata_aia_life" ||
              // selectedQuote?.company_alias === "pnb_metlife" ||
              // selectedQuote.company_alias === "hdfc_life"
            ) {
              handleClick();
            }
          }),
        );
        setReview(true);
        dispatch(initLoader(true));
        setGenerateBI(false);
      }
    }
  };

  const getCountryList = companyAlias => {
    switch (companyAlias) {
      case "icici_pru":
        return Object.values(COUNTRIES);
      case "kotak_life":
        return Object.values(KOTAKCOUNTRIES);
      case "max_life":
        return Object.values(MAXLIFECOUNTRIES);
      case "bajaj_life":
        return Object.values(BAJAJCOUNTRIES);
      case "bandhan_life":
        return Object.values(BANDHANCOUNTRIES);
      case "tata_aia_life":
        return Object.values({});
      case "pnb_metlife":
        return Object.values(getObjectFromArray(PNBCOUNTRIESARR));
      case "hdfc_life":
        return HDFC_COUNTRIES;
      default:
        return Object.values(COUNTRIES);
    }
  };

  const getCompanyName = companyAlias => {
    switch (companyAlias) {
      case "icici_pru":
        return "ICICI prudential";
      case "kotak_life":
        return "Kotak";
      case "max_life":
        return "Max";
      case "bajaj_life":
        return "Bajaj";
      case "bandhan_life":
        return "Bandhan";
      case "tata_aia_life":
        return "Tata";
      case "pnb_metlife":
        return "Pnb";
      case "hdfc_life":
        return "HDFC";
      default:
        return "Company Name";
    }
  };

  const getEducationMatches = (companyAlias, selectedEducation) => {
    switch (companyAlias) {
      case "icici_pru":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate"
        );
      case "kotak_life":
        return "Kotak";
      case "max_life":
        return "Max";
      case "bajaj_life":
        // return (
        //   selectedEducation === "Graduation" ||
        //   selectedEducation === "Masters and Above"
        // );
        return ["Post Graduate", "Graduate"].includes(selectedEducation);
      case "bandhan_life":
        return (
          selectedEducation === "Graduate" ||
          selectedEducation === "Post Graduate" ||
          selectedEducation === "Professional Degree"
        );
      case "tata_aia_life":
        return "Tata";
      case "pnb_metlife":
        return "Pnb";
      default:
        return "Company Name";
    }
  };
  const [nonAcceptableCountry, setNonAcceptableCountry] =
    useState("Select country");
  const [showNriPopup, setShowNriPopup] = useState(false);
  const [showNriResidingPopup, setShowNriResidingPopup] = useState(false);

  //houseWifePopup
  const [showHouseWifePopup, setShowHouseWifePopup] = useState(false);
  useEffect(() => {
    if (
      checkNRI &&
      companyAlias === "icici_pru" &&
      selectedQuote.medicalCondition === "Tele Medical"
    ) {
      setShowNRIMedical(true);
    } else setShowNRIMedical(false);
  }, [checkNRI]);

  useEffect(() => {
    // setSelectedEducation("Highest Education");
    if (isSalaried === "Y" && companyAlias === "max_life")
      setSelectedOccupation("Salaried");
    if (
      isSalaried === "Y" &&
      companyAlias === "icici_pru" &&
      coverAmount >= 15000000 &&
      paymentTerm?.option !== "Single Pay"
    )
      setSelectedOccupation("Salaried");
    setReview(false);
  }, [selectedOccupation]);

  useEffect(() => {
    if (
      isSalaried === "Y" &&
      companyAlias === "icici_pru" &&
      coverAmount >= 15000000 &&
      paymentTerm?.option !== "Single Pay"
    )
      setSelectedOccupation("Salaried");
  });

  useEffect(() => {
    if (selectedOccupation === "Housewife") {
      if (selectedQuote?.company_alias === "bandhan_life") {
        setShowHouseWifePopup("bandhan_life");
        return;
      }
      if (
        selectedQuote?.company_alias === "max_life" &&
        pinCode?.length &&
        parseInt(sumAssured) <= 500000
      ) {
        setShowHouseWifePopup("max_life");
        return;
      }
    } else {
      setShowHouseWifePopup(false);
    }
  }, [selectedOccupation, pinCode]);

  useEffect(() => {
    if (pinCode?.length === 6) {
      if (
        companyAlias === "icici_pru" &&
        !iciciValidationData?.status &&
        iciciValidationData?.pincode_type === "top_city"
      ) {
        setNotEligiblePinCode(false);
        setAnnualIncomeLimit(
          `Basis the Occupation Details provided by you, ${iciciValidationData.errors}. Please go back to quotes page to see updated cover.`,
        );
      } else if (
        companyAlias === "icici_pru" &&
        !iciciValidationData?.status &&
        (iciciValidationData?.pincode_type === "negative" ||
          iciciValidationData?.pincode_type === "invalid_pincode")
      ) {
        setAnnualIncomeLimit(false);
        setNotEligiblePinCode(true);
        setNotEligiblePinCodeText(
          "The mentioned pin code is not serviceable by ICICI",
        );
      } else {
        setNotEligiblePinCode(false);
      }
    }
  }, [iciciValidationData, pinCode]);

  useEffect(() => {
    if (companyAlias === "icici_pru" && annualIncome < 500000) {
      if (selectedOccupation === "Self Employed") {
        setEligibilityErrors({ occupation: errorMessages.invalidOccupation });
        return;
      } else if (
        (selectedOccupation === "Salaried" ||
          selectedOccupation === "Professional") &&
        pinCode?.length === 6 &&
        iciciValidationData?.pincode_type === "other_city"
      ) {
        setEligibilityErrors({
          annualIncome: errorMessages.invalidAnnualIncome,
        });
        return;
      }
    } else if (
      companyAlias === "icici_pru" &&
      iciciValidationData?.pincode_type === "other_city" &&
      pinCode?.length === 6 &&
      iciciValidationData?.eligibility === "Failed" &&
      parseInt(sumAssured) > 10000000
    ) {
      setNotEligiblePinCode(false);
      setAnnualIncomeLimit(
        errorMessages.exceedsMaxCover2(Math.floor(10000000)),
      );
      setNewCover(10000000);
    } else if (
      companyAlias === "icici_pru" &&
      iciciValidationData?.pincode_type === "other_city" &&
      pinCode?.length === 6 &&
      iciciValidationData?.eligibility === "Failed" &&
      selectedQuote?.medicalCondition !== "Physical Medical"
    ) {
      setNotEligiblePinCode(true);
      setNotEligiblePinCodeText(
        "You need to undergo physical medical tests, for the mentioned pincode",
      );
      setColor(true);
    } else if (
      companyAlias === "tata_aia_life" &&
      selectedOccupation === "Housewife" &&
      filter.morePlans === "Return of Premium"
    ) {
      setEligibilityErrors({
        occupation:
          "For selected occupation you cannot proceed with return of premium. Kindly proceed with the base plan.",
      });
      setColor(true);
      return;
    } else {
      setColor(false);
    }
  }, [pinCode, selectedOccupation, iciciValidationData]);

  useEffect(() => {
    if (
      companyAlias === "kotak_life" &&
      400000 <= annualIncome &&
      annualIncome < 500000 &&
      selectedOccupation === "Self Employed"
    ) {
      setEligibilityErrors({
        occupation: "Physical test is mandatory for Selected Occupation",
      });
      setColor(true);
    }
  }, [
    selectedOccupation,
    pinCode,
    selectedEducation,
    annualIncome,
    companyAlias,
  ]);

  useEffect(() => {
    if (companyAlias === "max_life") {
      if (
        (selectedEducation === "12th Pass" ||
          selectedEducation === "10th Pass") &&
        parseInt(annualIncome) >= 1000000 &&
        selectedQuote?.medicalCondition === "Video Medical" &&
        parseInt(sumAssured) >= 5000000 &&
        customerDetails?.age >= 18 &&
        customerDetails?.age <= 60 &&
        setEligibilityErrors
      ) {
        setEligibilityErrors({
          education: "Physical test is mandatory for Selected Education",
        });
        setColor(true);
      } else {
        setColor(false);
      }
    }
  }, [
    selectedOccupation,
    customerDetails,
    sumAssured,
    selectedEducation,
    annualIncome,
    companyAlias,
    selectedQuote,
    pinCode,
    setEligibilityErrors,
  ]);

  useEffect(() => {
    if (appNo) {
      // setReview(true);
    }
  }, [appNo]);
  const [disabledError, setShowDisabledError] = useState(false);
  const { isValid: isMaxLifeAndHousewifeValid } = useMaxLifeHouswifeValidation({
    selectedOccupation,
    companyAlias,
  });

  const {
    isValid: isBajajRidersEducationValid,
    isOccupationValid: isBajajOccupationValid,
  } = useBajajRidersValidation({
    selectedEducation,
    selectedOccupation,
    companyAlias,
    annualIncome,
    sumAssured,
  });
  const {
    isValid: isHDFCRidersEducationValid,
    isOccupationValid: isHDFCOccupationValid,
  } = useHDFCRidersValidation({
    selectedEducation,
    selectedOccupation,
    companyAlias,
  });

  const disableAgreeTermCheckbox =
    (companyAlias === "hdfc_life" &&
      selectedOccupation === "Housewife" &&
      Number(sumAssured) > 10000000) ||
    (companyAlias === "tata_aia_life" &&
      selectedOccupation === "Housewife" &&
      (selectedEducation === "12th Pass" ||
        selectedEducation === "10th Pass" ||
        selectedEducation === "Diploma" ||
        selectedEducation === "Vocational / ITI" ||
        selectedEducation === "Below 10th")) ||
    (companyAlias === "kotak_life" && riderNotAllowed) ||
    (companyAlias === "kotak_life" &&
      (selectedOccupation === "Housewife" ||
        selectedOccupation === "Unemployed") &&
      selectedEducation === "12th Pass") ||
    (!eligibilityFlag &&
      selectedEducation !== "12th Pass" &&
      selectedEducation !== "10th Pass") ||
    (companyAlias === "max_life" &&
      ((selectedEducation === "10th Pass" && annualIncomeDigits < 1000000) ||
        (selectedEducation === "12th Pass" && annualIncomeDigits < 500000) ||
        negativePincodeErrors.status ||
        (checkNRI && isErrorForNRI))) ||
    (companyAlias === "icici_pru" &&
      // ((!iciciValidationData.status && !color) ||
      ((checkNRI && vaccinationStatus === "Please Select") ||
        (selectedOccupation === "Professional" &&
          selectedNatureOfWork === "Nature of work"))) ||
    disable ||
    !husbandHoldsPolicy ||
    !eligibilityFlag ||
    (!eligibilityFlag && !color) ||
    // !annualIncomeText ||
    !isMaxLifeAndHousewifeValid ||
    !isBajajOccupationValid ||
    !isHDFCOccupationValid ||
    !isBajajRidersEducationValid ||
    !isHDFCRidersEducationValid ||
    negativePincode ||
    !pinCode ||
    pinCode.length != 6 ||
    isNegative?.status === false ||
    !!isNegative?.errors ||
    notEligiblePinCode ||
    notEligibleADBURider ||
    notEligibleCIBFRider ||
    annualIncomeFlag ||
    selectedOccupation === "Occupation" ||
    !selectedOccupation ||
    (companyAlias === "bajaj_life" && riderNotAllowed) ||
    (checkNRI && (notEligibleCountry || selectedCountry === "Select Country"));
  console.log({
    disableAgreeTermCheckbox,
    finalTrue:
      companyAlias === "icici_pru" &&
      // ((!iciciValidationData.status && !color) ||
      ((checkNRI && vaccinationStatus === "Please Select") ||
        (selectedOccupation === "Professional" &&
          selectedNatureOfWork === "Nature of work")),
    disable,
    husbandHoldsPolicy: !husbandHoldsPolicy,
    eligibilityFlag: !eligibilityFlag,
    true1: !eligibilityFlag && !color,
    annualIncomeText: !annualIncomeText,
    isMaxLifeAndHousewifeValid: !isMaxLifeAndHousewifeValid,
    isBajajOccupationValid: !isBajajOccupationValid,
    isHDFCOccupationValid: !isHDFCOccupationValid,
    isBajajRidersEducationValid: !isBajajRidersEducationValid,
    isHDFCRidersEducationValid: !isHDFCRidersEducationValid,
    negativePincode,
    pinCode: !pinCode,
    pinTrue: pinCode.length != 6,
    isNegative: isNegative?.status === false,
    isNegativeErrors: !!isNegative?.errors,
    notEligiblePinCode,
    notEligibleADBURider,
    notEligibleCIBFRider,
    annualIncomeFlag,
    Occupation: selectedOccupation === "Occupation",
    selectedOccupation: !selectedOccupation,
    isCountry:
      checkNRI && (notEligibleCountry || selectedCountry === "Select Country"),
  });

  useEffect(() => {
    if (agreeTerm && disableAgreeTermCheckbox) setAgreeTerm(false);
  }, [disableAgreeTermCheckbox, agreeTerm]);

  const isMaxLifeAndHousewife =
    companyAlias === "max_life" && selectedOccupation === "Housewife";
  // <-------------------------------------------------------------------------------------------------> //

  if (isEligibilityGridLoading) return <Loader />;

  return (
    <>
      <ReviewProduct
        show={review}
        onClose={setReview}
        history={history}
        selected={selectedQuote}
        inbuiltCovers={inbuiltCovers}
        addOnsList={addOnsList}
        additionalrider={additionalrider}
      />

      {checkNRI && !isErrorForNRI && (
        <NriCriteriaPopup
          show={showNriPopup}
          checkNRI={checkNRI}
          setNriEligibility={setNriEligibility}
          setCheckNRI={setCheckNRI}
          selectedEducation={selectedEducation}
          selectedOccupation={selectedOccupation}
          setSelectedEducation={setSelectedEducation}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          showNriPopup={showNriPopup}
          setShowNriPopup={setShowNriPopup}
          setAgreeNriTerm={setAgreeNriTerm}
          agreeNriTerm={agreeNriTerm}
          annualIncomeDigits={annualIncomeDigits}
          setSelectedUSAstate={setSelectedUSAstate}
          selectedUSAstate={selectedUSAstate}
          companyAlias={selectedQuote?.company_alias}
          sumAssured={Number(sumAssured)}
        />
      )}
      {intentToTravel && (
        <NriResidingPopup
          show={showNriResidingPopup}
          setIntendToTravel={setIntendToTravel}
          setShowNriResidingPopup={setShowNriResidingPopup}
          setShowNriPopup={setShowNriPopup}
          nonAcceptableCountry={nonAcceptableCountry}
          sumAssured={Number(sumAssured)}
        />
      )}
      <HouseWifeCriteriaPopup
        show={showHouseWifePopup === "bandhan_life"}
        setShowHouseWifePopup={setShowHouseWifePopup}
      />
      {companyAlias === "max_life" &&
        pinCode?.length === 6 &&
        !annualIncomeLimit && (
          <HousewifeEligibilityCriteriaPopup
            show={showHouseWifePopup === "max_life"}
            onClose={() => setShowHouseWifePopup(null)}
          />
        )}
      {(annualIncomeLimit ||
        (annualIncomeLimit &&
          companyAlias === "icici_pru" &&
          pinCode?.length === 6)) && (
        <AnnualIncomePopup
          selectedOccupation={selectedOccupation}
          history={history}
          show={annualIncomeLimit}
          setShow={setAnnualIncomeLimit}
          annualIncome={annualIncomeDigits}
          newCover={newCover}
          vaccinationStatus={vaccinationStatus}
        />
      )}
      {showNRIMedical && (
        <NriMedicalPopup
          setCheckNRI={setCheckNRI}
          show={showNRIMedical}
          setShow={setShowNRIMedical}
          annualIncome={annualIncomeDigits}
        />
      )}
      {details ? (
        <div>
          <Card elg className="elgDownCard cardContainerDown">
            <CardTitle
              eligibilityTitle
              className="title-eligibility mobTitle-elig"
            >
              Eligibility details
            </CardTitle>

            <Form>
              <FormGroup eligibilityCard cardElig>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle={
                      selectedEducation !== "Highest Education"
                        ? "Education Details"
                        : false
                    }
                    eligibilityCard={selectedEducation !== "Highest Education"}
                    elgMob="true"
                    // items={eduArr}
                    items={educationDropDown}
                    searchable
                    currentSelected={selectedEducation}
                    // selectedItem={selectedEducation}
                    // currentSelected={
                    //   eligibilityDetails?.isAgreeTerm === "YES"
                    //     ? eligibilityDetails?.selectedEducation
                    //     : selectedEducation
                    // }
                    onChange={e => {
                      setSelectedEducation(e);
                      dispatch(saveEligibilityMessage(""));
                      setNotEligibleEducation(false);
                      setNotEligibleOccupation(false);
                      setNotEligibleAnnualIncome(false);
                      setAnnualIncomeErrorMessage(false);
                      setNotEligibleSumAssured(false);
                      setSumInsuredErrorMessage(false);
                      setAgeNotAllowed(false);
                      setAgeNotAllowedMessage(false);
                      setIsTriggered(true);
                      setIsEducationErr(true);
                    }}
                  />
                </CustomDropDownFont>

                <>
                  {errorEducation ? (
                    <p className="error errorElg">Please Highest Education</p>
                  ) : !!eligibilityErrors?.education ? (
                    // <p className="error errorElg">{eligibilityMessage}</p>
                    <p
                      style={{ color: color ? "#107591" : "" }}
                      className="error errorElg"
                    >
                      {eligibilityErrors.education}
                    </p>
                  ) : notEligibleEducation ? (
                    <p className="error errorElg">
                      {selectedQuote?.company_alias === "icici_pru" &&
                      selectedOccupation === "Housewife"
                        ? "Selected Education is not eligible"
                        : selectedQuote?.company_alias === "max_life"
                        ? null
                        : "Policy not available for selected qualification"}
                    </p>
                  ) : null}
                </>
              </FormGroup>
              <FormGroup eligibilityCard cardElig>
                <CustomDropDownFont
                  rdr
                  onClick={() => {
                    if (companyAlias === "max_life" && isSalaried === "Y") {
                      setShowDisabledError(
                        "You have already selected your Occupation as “Salaried”",
                      );
                    }
                  }}
                >
                  <CustomDropDown
                    eligibilityCard={selectedOccupation !== "Occupation"}
                    elgMob="true"
                    dropDownTitle={
                      selectedOccupation !== "Occupation"
                        ? "Occupation Details"
                        : false
                    }
                    // items={occArr}
                    items={
                      companyAlias === "bajaj_life" ||
                      companyAlias === "kotak_life"
                        ? filterBySalary
                        : occupationDropDown
                    }
                    searchable
                    selectedItem={selectedOccupation}
                    currentSelected={
                      // eligibilityDetails?.isAgreeTerm === "YES"
                      //   ? eligibilityDetails?.selectedOccupation
                      //   : selectedOccupation
                      selectedOccupation
                    }
                    disabled={
                      (companyAlias === "max_life" && isSalaried === "Y") ||
                      (companyAlias === "icici_pru" &&
                        isSalaried === "Y" &&
                        coverAmount >= 15000000 &&
                        paymentTerm?.option !== "Single Pay" &&
                        customerDetails?.workEmail?.toLowerCase() ===
                          "aarti@wipro.com")
                    }
                    onChange={e => {
                      setSelectedOccupation(e);
                      setNotEligibleOccupation(false);
                      dispatch(saveEligibilityMessage(""));
                      if (selectedOccupation === !selectedOccupation)
                        setNotEligibleAnnualIncome(false);
                      setAnnualIncomeErrorMessage(false);
                      if (companyAlias !== "icici_pru") {
                        setAnnualIncomeLimit("");
                      }
                      if (e !== "Agriculturist") {
                        setNotEligibleSumAssured(false);
                        setSumInsuredErrorMessage(false);
                        setRiderNotAllowedMessage(false);
                        setRiderNotAllowed(false);
                        setNotEligibleADBURider(false);
                        setNotEligibleCIBFRider(false);
                      }
                      if (e !== "Income from other sources") {
                        setAgeNotAllowed(false);
                        setAgeNotAllowedMessage(false);
                      }
                      setIsTriggered(true);
                    }}
                  />
                </CustomDropDownFont>

                {errorOccupation ||
                (!selectedOccupation &&
                  selectedEducation !== "Highest Education") ||
                (selectedOccupation === "Occupation" &&
                  selectedEducation !== "Highest Education" &&
                  agreeTerm) ? (
                  <>
                    <p className="error errorElg">Please Select Occupation</p>
                  </>
                ) : notEligibleOccupation && !isErrorForNRI ? (
                  <p className="error errorElg">
                    Policy is not available for sel
                  </p>
                ) : notEligibleAnnualIncome && !isErrorForNRI ? (
                  <p className="error errorElg">{annualIncomeErrorMessage}</p>
                ) : notEligibleSumAssured && !isErrorForNRI ? (
                  <p className="error errorElg">{sumInsuredErrorMessage}</p>
                ) : riderNotAllowed &&
                  !eligibilityErrors.education &&
                  !isErrorForNRI ? (
                  <p className="error errorElg">{riderNotAllowedMessage}</p>
                ) : // ) : ageNotAllowed ? (
                // <p className="error errorElg">{ageNotAllowedMessage}</p>
                !eligibilityFlag ? (
                  // <p className="error errorElg">{eligibilityMessage}</p>
                  <p
                    style={{ color: color ? "#107591" : "" }}
                    className="error errorElg"
                  >
                    {eligibilityErrors.occupation}
                  </p>
                ) : disabledError &&
                  companyAlias === "max_life" &&
                  isSalaried === "Y" ? (
                  <p style={{ color: "#107591" }} className="error errorElg">
                    {disabledError}
                  </p>
                ) : disabledError ? (
                  <p className="error errorElg">{disabledError}</p>
                ) : notEligibleADBURider ? (
                  <p className="error errorElg" style={{ marginTop: "0" }}>
                    Accidental Death Benefit Rider Not Allowed
                  </p>
                ) : notEligibleCIBFRider ? (
                  <p className="error errorElg" style={{ marginTop: "0" }}>
                    Accelerated Critical Illness Rider sum assured should be
                    maximum 10 Lakhs
                  </p>
                ) : (companyAlias === "icici_pru" ||
                    companyAlias === "hdfc_life") &&
                  selectedOccupation === "Housewife" &&
                  selectedQuote?.medicalCondition !== "Physical Medical" ? (
                  <p style={{ color: "#107591" }} className="error errorElg">
                    As you have selected occupation as housewife you will have
                    to undergo physical medical
                  </p>
                ) : null}
              </FormGroup>
              {selectedQuote.company_alias === "icici_pru" &&
                selectedOccupation === "Professional" && (
                  <FormGroup eligibilityCard cardElig>
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        eligibilityCard={
                          selectedNatureOfWork !== "Nature of work"
                        }
                        elgMob="true"
                        dropDownTitle={
                          selectedNatureOfWork !== "Nature of work"
                            ? "Nature of Work"
                            : false
                        }
                        items={
                          selectedQuote.company_alias === "icici_pru"
                            ? natureOfWorkICICI
                            : natureOfWork
                        }
                        selectedItem={selectedNatureOfWork}
                        selected
                        onChange={e => setSelectedNatureOfWork(e)}
                      />
                    </CustomDropDownFont>
                    {errorNatureOfWork ||
                    (selectedNatureOfWork === "Nature of work" && agreeTerm) ? (
                      <p className="error errorElg">
                        Please Select Nature of Work
                      </p>
                    ) : null}
                  </FormGroup>
                )}

              <FormGroup eligibilityCard cardElig>
                <TextInputElg
                  md
                  error={
                    (errors.pinCode ||
                      (!notEligiblePinCode && !negativePincodeErrors.status)) &&
                    enteredPincode?.length === 6
                    //  &&
                    // "danger"
                  }
                  type="text"
                  name="pinCode"
                  maxLength={6}
                  onInput={numOnly}
                  // defaultValue={
                  //   regionDetails?.status ? regionDetails?.pincode : pinCode
                  // }
                  id="pinCode"
                  placeholder=" "
                  autoComplete="none"
                  onChange={e => {
                    setPinCode(e.target.value);
                  }}
                  ref={register}
                  title="Please fill in this field"
                />
                <Label md eligibilityPage elig htmlFor="pinCode" elgText="true">
                  Pin Code
                </Label>

                {regionDetails?.status === true &&
                  pinCode.length === 6 &&
                  !negativePincode &&
                  !notEligiblePinCode && (
                    <span
                      className={
                        " regionElig" +
                        (regionDetails?.city?.length > 10
                          ? " regionEligSmall"
                          : "")
                      }
                      style={{
                        marginBottom: "16px",
                        height: "0",
                        position: "relative",
                        top: "-25px",
                        right: "10px",
                        display: "flex",
                        justifyContent: "right",
                        color: "#107591",
                        fontFamily: "Inter-Medium",
                        fontSize: "14px",
                        lineHeight: "18px",
                      }}
                    >
                      <img
                        src={location}
                        alt="location"
                        className="pincodeImg"
                      />
                      {/* {capitalizeSentence(regionDetails?.city)}, */}
                      {capitalizeSentence(regionDetails?.state)}
                    </span>
                  )}

                {negativePincode &&
                !negativePincodeErrors.status &&
                annualIncomeDigits ? (
                  <p className="error errorElg">{isNegative?.errors}</p>
                ) : errors?.pinCode || enteredPincode?.length !== 6 ? (
                  <p className="error errorElg">{errors?.pinCode?.message}</p>
                ) : notEligiblePinCode && !negativePincodeErrors.status ? (
                  <p
                    // style={{ color: color ? "#107591" : "" }}
                    className="error errorElg"
                  >
                    {isNegative?.errors || notEligiblePinCodeText}
                  </p>
                ) : regionDetails.status ? (
                  <p
                    // style={{ color: color ? "#107591" : "" }}
                    className="error errorElg"
                  >
                    {regionDetails.errors}
                  </p>
                ) : null}
              </FormGroup>

              {regionDetails?.city &&
                pinCode?.length === 6 &&
                selectedQuote?.company_alias !== "icici_pru" &&
                selectedQuote.company_alias !== "tata_aia_life" && (
                  <FormGroup eligibilityCard cardElig>
                    <CustomDropDownFont rdr>
                      <CustomDropDown
                        eligibilityCard
                        dropDownTitle={"Select City"}
                        elgMob="true"
                        searchable={
                          regionDetails?.city.length > 4 ? true : false
                        }
                        items={
                          regionDetails?.city
                            ? regionDetails?.city.map(item =>
                                capitalizeSentenceJoin(item),
                              )
                            : []
                        }
                        selectedItem={
                          regionDetails?.city[0]
                            ? capitalizeSentenceJoin(regionDetails?.city[0])
                            : selectedCity
                        }
                        selected
                        onChange={e => {
                          setSelectedCity(e);
                          // const data = {
                          //   pinCode: pinCode || eligibilityDetails?.pinCode,
                          //   companyAlias: selectedQuote?.company_alias,
                          //   city: e,
                          // };
                          // dispatch(getArea(data));
                        }}
                      />
                    </CustomDropDownFont>
                    {errorSelectCity ? (
                      <p className="error errorElg">Please Select City.</p>
                    ) : null}
                  </FormGroup>
                )}

              <FormGroup eligibilityCard cardElig>
                <TextInputElg
                  md
                  type="text"
                  name="annualIncome"
                  maxLength={20}
                  id="annualIncome"
                  placeholder=" "
                  autoComplete="none"
                  disabled
                  value={annualIncomeText}
                  onChange={e => {
                    handleAnnualIncomeTextChange(e);
                    setShowAnnualLabel(false);
                  }}
                  ref={register}
                  title="Please fill in this field"
                  error={errors?.annualIncome && "danger"}
                  onBlur={e => {
                    setIsTriggered(true);
                  }}
                />
                <Label md eligibilityPage elig htmlFor="pinCode" elgText="true">
                  {isMaxLifeAndHousewife
                    ? "Husband’s Annual Income"
                    : "Annual Income"}
                </Label>
                {showAnnualLabel && (
                  <AgeWrapper className="ageWrap card-dob-agewrapper">
                    {getValue(annualIncomeDigits)}
                  </AgeWrapper>
                )}
                {!annualIncomeText ? (
                  <p className="errorG errorElg error">
                    Annual Income is Required
                  </p>
                ) : errors?.annualIncome ? (
                  <p className="errorG errorElg">
                    {errors?.annualIncome?.message}
                  </p>
                ) : negativePincodeErrors.status ? (
                  <p className="errorG errorElg error">
                    {negativePincodeErrors.error}
                  </p>
                ) : !eligibilityFlag && annualIncomeText ? (
                  // <p className="errorG errorElg error">{eligibilityMessage}</p>
                  <p className="errorG errorElg error">
                    {eligibilityErrors.annualIncome}
                  </p>
                ) : selectedEducation == "Post Graduate" &&
                  selectedOccupation == "Agriculturist" &&
                  annualIncomeDigits < 500000 ? (
                  <p className="errorG errorElg error">
                    This product is not eligibile for the given annual income
                  </p>
                ) : null}
              </FormGroup>

              {((selectedQuote.company_alias === "bajaj_life" &&
                fullyVaccinated !== "Y") ||
                (selectedQuote.company_alias === "icici_pru" && checkNRI)) && (
                <FormGroup eligibilityCard cardElig>
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      eligibilityCard
                      elgMob="true"
                      dropDownTitle={"Vaccination Status"}
                      items={
                        selectedQuote.company_alias === "bajaj_life"
                          ? vaccinationOptionForBajaLife
                          : [
                              "Partially Vaccinated",
                              "Fully Vaccinated",
                              "Not Vaccinated",
                            ]
                      }
                      currentSelected={vaccinationStatus}
                      selected
                      onChange={e => {
                        setVaccinationStatus(e);
                        setIsTriggered(true);
                      }}
                    />
                  </CustomDropDownFont>
                  {errorVaccinationStatus ||
                  (vaccinationStatus === "Please Select" && agreeTerm) ? (
                    <p className="error errorElg">
                      Please Select Vaccination Status
                    </p>
                  ) : null}
                </FormGroup>
              )}

              {/* { selectedQuote.company_alias === "icici_pru" && checkNRI && (
                <FormGroup eligibilityCard cardElig>
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      eligibilityCard
                      elgMob="true"
                      dropDownTitle={"Vaccination Status"}
                      items={[
                        "Partially Vaccinated",
                        "Fully Vaccinated",
                        "Not Vaccinated",
                      ]}
                      currentSelected={vaccinationStatus}
                      selected
                      onChange={e => {
                        setVaccinationStatus(e);
                        setIsTriggered(true);
                      }}
                    />
                  </CustomDropDownFont>
                  {errorVaccinationStatus ||
                  (vaccinationStatus === "Please Select" && agreeTerm) ? (
                    <p className="error errorElg">
                      Please Select Vaccination Status
                    </p>
                  ) : null}
                </FormGroup>
              )} */}
              {/* {selectedCity !== "Select City" && area?.length > 0 && (
								<FormGroup eligibilityCard cardElig>
									<CustomDropDownFont rdr>
										<CustomDropDown
											searchable={area?.length > 4 ? true : false}
											elgMob="true"
											items={
												area
													? area.map((item) => capitalizeSentenceJoin(item))
													: []
											}
											selectedItem={selectedArea}
											selected
											onChange={(e) => setSelectedArea(e)}
										/>
									</CustomDropDownFont>
									{errorNatureOfWork ? (
										<p className="error errorElg">Please Select City</p>
									) : null}
								</FormGroup>
							)} */}
              {customerDetails?.gender === "F" &&
                (selectedQuote?.company_alias === "icici_pru" ||
                  selectedQuote?.company_alias === "bajaj_life" ||
                  selectedQuote?.company_alias === "max_life" ||
                  //   selectedQuote?.company_alias === "kotak_life" ||
                  selectedQuote?.company_alias === "tata_aia_life") &&
                selectedOccupation === "Housewife" && (
                  <FormGroup
                    cardElig
                    eligibilityCard
                    style={{ width: "640px" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      Does your <strong>husband</strong> hold any existing term
                      life policy worth 1 crore sum assured?
                    </div>
                    <ToggleStyled>
                      <span className="mo-5 tg-5">NO</span>
                      <ToggleButton
                        elgToggle="true"
                        checked={husbandHoldsPolicy}
                        onChange={value => {
                          setHusbandHoldsPolicy(value);
                        }}
                      />
                      <span className="mo-5 tg-5">YES</span>
                    </ToggleStyled>
                    {/* <RadioComponent
                      invert
                      value={husbandHoldsPolicy}
                      name="eia"
	@@ -2324,11 +2338,13 @@ const EligibilityCard = ({
                        cursor: "pointer",
                        padding: "0",
                      }}
                    /> */}

                    {!husbandHoldsPolicy && (
                      <p className="error errorElg" style={{ marginTop: "0" }}>
                        Husband should have existing Term Life Policy.
                        {/* Husband should have existing Policy{" "}
                        {houswifeCompany(companyAlias)} */}
                      </p>
                    )}

                    {/* {notEligibleADBURider ? (
                      <p className="error errorElg" style={{ marginTop: "0" }}>
                        Accidental Death Benefit Rider Not Allowed
                      </p>
                    ) : notEligibleCIBFRider ? (
                      <p className="error errorElg" style={{ marginTop: "0" }}>
                        Accelerated Critical Illness Rider Maximum 10 Lakh Cover
                        Allowed
                      </p>
                    ) : null} */}
                  </FormGroup>
                )}
              {companyAlias !== "tata_aia_life" &&
                companyAlias !== "kotak_life" &&
                ((companyAlias === "max_life" &&
                  (selectedOccupation === "Professional" ||
                    selectedOccupation === "Salaried") &&
                  (selectedEducation === "Post Graduate" ||
                    selectedEducation === "Graduate") &&
                  pinCode.length === 6 &&
                  parseInt(sumAssured) <= 40000000) ||
                  // (companyAlias === "icici_pru" && pinCode.length === 6 ) ||
                  (companyAlias === "hdfc_life" &&
                    (selectedOccupation === "Salaried" ||
                      selectedOccupation === "Self Employed") &&
                    (selectedEducation === "Post Graduate" ||
                      selectedEducation === "Graduate") &&
                    pinCode.length === 6 &&
                    parseInt(annualIncome) >= 1000000) ||
                  (companyAlias === "bajaj_life" &&
                    fullyVaccinated === "Y" &&
                    selectedQuote?.master_product_name !== "eTouch")) && (
                  <>
                    <FormGroup
                      cardElig={selectedQuote.company_alias !== "max_life"}
                      eligibilityCard
                      className={
                        "nriCards" + (checkNRI ? " nriCardMobile" : "")
                      }
                    >
                      {companyAlias === "max_life" ? (
                        <></>
                      ) : (
                        <div className="mt-4 nriQues">
                          <span className="mr-4 mo-4 mUi4">Are you NRI?</span>
                          <span className="mo-5 tg-5">NO</span>
                          <span>
                            <ToggleButton
                              elgToggle="true"
                              checked={checkNRI}
                              disabled={
                                (companyAlias === "icici_pru" &&
                                  annualIncomeDigits < 1000000) ||
                                (companyAlias === "icici_pru" &&
                                  selectedQuote?.medicalCondition ===
                                    "Physical + Video Medical")
                              }
                              onChange={value => {
                                // selectedQuote?.company_alias === "icici_pru" &&
                                setSelectedCountry("Select Country");
                                setCheckNRI(value);
                                setShowNriPopup(
                                  companyAlias !== "icici_pru" &&
                                    nriPopupEvents.nriToggle,
                                );
                                setAgreeNriTerm(true);
                              }}
                            />
                          </span>
                          <span className="mo-5 tg-5">YES</span>
                          {checkNRI &&
                            isErrorForNRI &&
                            (ageNotAllowed ? (
                              <p className="mt-2 error errorElg">
                                {ageNotAllowedMessage}{" "}
                              </p>
                            ) : notEligibleSumAssured ? (
                              <p className="mt-2 error errorElg">
                                {sumInsuredErrorMessage}{" "}
                              </p>
                            ) : notEligibleEducation ? (
                              <p className="mt-2 error errorElg">
                                Only Graduate/Post-Graduate is allowed for NRI
                              </p>
                            ) : riderNotAllowed ? (
                              <p className="mt-2 error errorElg">
                                {riderNotAllowedMessage}
                              </p>
                            ) : notEligibleAnnualIncome ||
                              (selectedOccupation == "Agriculturist" &&
                                selectedEducation !== "Graduate" &&
                                selectedEducation !== "Post Graduate") ? (
                              <p className="mt-2 error errorElg">
                                {annualIncomeErrorMessage}
                              </p>
                            ) : null)}
                          {checkNRI &&
                          !isErrorForNRI &&
                          companyAlias === "max_life" &&
                          15000000 >= parseInt(sumAssured) &&
                          parseInt(sumAssured) > 10000000 ? (
                            <p
                              style={{ color: "#107591" }}
                              className="mt-2 error errorElg"
                            >
                              Your policy can be issued basis video medicals.
                            </p>
                          ) : null}
                          {/* {checkNRI &&
                  selectedEducation !== "Graduate" &&
                  selectedEducation !== "Post Graduate" ? (
                    <p className="mt-2 error errorElg">
                      Applicant must be atleast Graduate
                    </p>
                  ) : checkNRI && Number(annualIncome) <= 2000000 ? (
                    <p className="mt-2 error errorElg">
                      Annual Income must be greater than 20 Lacs
                    </p>
                  ) : null} */}
                        </div>
                      )}

                      <div className="nriCountryDrop">
                        {checkNRI &&
                          !isErrorForNRI &&
                          (companyAlias === "hdfc_life" ||
                            companyAlias === "max_life" ||
                            companyAlias === "bajaj_life" ||
                            (companyAlias !== "max_life" &&
                              vaccinationStatus !== "Please Select")) &&
                          (companyAlias === "icici_pru" || agreeNriTerms) &&
                          getEducationMatches(
                            companyAlias,
                            selectedEducation,
                          ) && (
                            <FormGroup cardElig eligibilityCard>
                              <CustomDropDownFont rdr eligHeight>
                                <CustomDropDown
                                  eligibilityCard={
                                    selectedCountry !== "Select Country"
                                  }
                                  dropDownTitle={
                                    selectedCountry !== "Select Country"
                                      ? "Select Country"
                                      : false
                                  }
                                  elgMob="true"
                                  // items={country}
                                  // items={getCountryList(companyAlias)}
                                  items={
                                    companyAlias === "icici_pru" &&
                                    vaccinationStatus !== "Fully Vaccinated"
                                      ? Object.values(COUNTRIES).sort()
                                      : companyAlias === "max_life"
                                      ? Object.values(MAXLIFECOUNTRIES).sort()
                                      : Object.values(ALL_COUNTRIES).sort()
                                  }
                                  searchable
                                  searchStartsWith={true}
                                  currentSelected={selectedCountry}
                                  onChange={e => {
                                    setSelectedCountry(e);
                                    setNotEligibleCountry(false);
                                    if (
                                      companyAlias !== "bajaj_life" &&
                                      companyAlias !== "hdfc_life"
                                    ) {
                                      setShowNriPopup(
                                        nriPopupEvents.nriCountrySelect,
                                      );
                                    }
                                  }}
                                />
                              </CustomDropDownFont>
                              {errorCountry ? (
                                <p className="error errorElg">
                                  Please Select Country
                                </p>
                              ) : notEligibleCountry ? (
                                <p className="error errorElg">
                                  This Country is not acceptable by{" "}
                                  {getCompanyName(companyAlias)}
                                </p>
                              ) : null}
                            </FormGroup>
                          )}

                        {checkNRI &&
                          companyAlias === "icici_pru" &&
                          agreeNriTerms &&
                          selectedCountry === "USA" && (
                            <FormGroup cardElig eligibilityCard>
                              <CustomDropDownFont rdr eligHeight>
                                <CustomDropDown
                                  eligibilityCard={
                                    selectedUSAstate !== "Select State"
                                  }
                                  elgMob="true"
                                  dropDownTitle={
                                    selectedUSAstate !== "Select State"
                                      ? "Select State"
                                      : false
                                  }
                                  items={Object.values(USAStates)}
                                  searchable
                                  currentSelected={selectedUSAstate}
                                  onChange={e => {
                                    setSelectedUSAstate(e);
                                    setStateDisable(false);
                                    // setShowNriPopup(
                                    //   nriPopupEvents.nriCountrySelect
                                    // );
                                  }}
                                />
                              </CustomDropDownFont>
                              {errorCountry ? (
                                <p className="error errorElg">
                                  Please Select state.
                                </p>
                              ) : null}
                            </FormGroup>
                          )}
                      </div>
                    </FormGroup>

                    {/* {selectedQuote.company_alias === "max_life" && (
                      <>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-40px",
                          }}
                        >
                          <div
                            className="mt-4"
                            style={{
                              display: "block",
                              width: "60%",
                              maxWidth: "60%",
                            }}
                          >
                            <span className="mr-4 mo-4 mUi4">
                              Are you suffering from Diabetes, Asthma, Heart
                              Disease, Hypertension, Hepatitis, cancer or any
                              other long standing chronic disease or had any
                              recent surgery or diagnosed positive for Covid-19
                              in last 3 months?
                            </span>
                          </div>
                          <div style={{ marginLeft: "12px" }}>
                            <span className="mo-5 tg-5">NO</span>
                            <span>
                              <ToggleButton
                                elgToggle="true"
                                checked={checkMax}
                                onChange={value => {
                                  // selectedQuote?.company_alias === "icici_pru" &&
                                  setCheckMax(value);
                                }}
                              />
                            </span>
                            <span className="mo-5 tg-5">YES</span>
                          </div>
                        </div>
                        {checkMax && (
                          <p className="mt-2 error errorElg">
                            You are not eligible to buy
                          </p>
                        )}
                      </>
                    )} */}
                    {/* {checkNRI && selectedQuote?.company_alias === "icici_pru" && (
                    <FormGroup
                      lg
                      eligibilityCard
                      className={`travelQues ${
                        selectedCountry === "USA" ? "travelQuesUSA" : ""
                      }`}
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className='mt-4 '>
                        <span className='mr-4 mo-4 mUi4'>
                          Residing in India but intend to travel to non
                          acceptable countries?
                        </span>
                        <span className='mo-5 tg-5'>NO</span>
                        <span>
                          <ToggleButton
                            elgToggle='true'
                            checked={intentToTravel}
                            disabled={
                              selectedQuote?.company_alias !== "icici_pru"
                            }
                            onChange={(value) => {
                              selectedQuote?.company_alias === "icici_pru" &&
                                setIntendToTravel(value);
                              setShowNriResidingPopup(value);
                            }}
                          />
                        </span>
                        <span className="mo-5 tg-5">YES</span>
                        {checkNRI &&
                          intentToTravel &&
                          selectedQuote.sumAssured >= 50000000 && (
                            <p className="mt-2 error errorElg">
                              Sum Assured Must be less than 5 Crore
                            </p>
                          )}
                        {intentToTravel && Number(sumAssured) < 50000000 && (
                          <>
                            <div className='mr-4 mo-4 mUi4 travel-non-acceptable'>
                              Please select below non-acceptable countries
                              planning to travel in future
                            </div>
                            <FormGroup cardElig eligibilityCard>
                              <CustomDropDownFont rdr eligHeight>
                                <CustomDropDown
                                  elgMob='true'
                                  items={Object.values(ALL_COUNTRIES).filter(
                                    (c) => !country.includes(c)
                                  )}
                                  searchable
                                  currentSelected={nonAcceptableCountry}
                                  onChange={(country) => {
                                    setNonAcceptableCountry(country);
                                    setShowNriResidingPopup(true);
                                  }}
                                />
                              </CustomDropDownFont>
                              {errorCountry ? (
                                <p className="error errorElg">
                                  Please Select state.
                                </p>
                              ) : null}
                              <button className="add-country-btn">
                                + Add country
                              </button>
                            </FormGroup>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  )} */}
                    {disclaimer && !showDisclaimer && (
                      <>
                        {eligibilityErrors?.education?.length > 1 ? (
                          <div></div>
                        ) : (
                          <div className="physical_medical_disclaimer">
                            Basis the details provided by you, there will be a
                            physical medical test required
                          </div>
                        )}
                      </>
                    )}
                    {showDisclaimer &&
                      selectedQuote?.medicalCondition === "Video Medical" && (
                        <>
                          <div className="physical_medical_disclaimer">
                            Basis on your Education, Occupation and Income, you
                            have to undergo for Physical medical test
                          </div>
                        </>
                      )}
                    {/* {selectedQuote?.company_alias !== "icici_pru" ||
                    (selectedQuote?.company_alias !== "bandhan_life" && (
                      <FormGroup lg eligibilityCard>
                        <div className="heartQues">
                          <span className="mr-4 mo-4 mUi4">
                            Are you Heart Patient?
                          </span>
                          <span className="mo-5 tg-5">NO</span>
                          <span>
                            <ToggleButton
                              elgToggle="true"
                              checked={checkedHeart}
                              onChange={(value) => setCheckedHeart(value)}
                            />
                          </span>
                          <span className="mo-5 tg-5">YES</span>
                          
                        </div>
                      </FormGroup>
                    ))} */}

                    <FormGroup
                      lg
                      eligibilityCard
                      style={{ minHeight: "26px" }}
                    ></FormGroup>
                  </>
                )}

              {(selectedQuote.company_alias === "tata_aia_life" ||
                selectedQuote.company_alias === "kotak_life") &&
                selectedQuote.company_alias === "tata_aia_life" &&
                selectedOccupation !== "Housewife" &&
                (selectedEducation !== "Diploma" ||
                  selectedEducation !== "Vocational / ITI") &&
                Number(annualIncome) >= 1000000 &&
                selectedQuote?.medicalCondition === "Video Medical" &&
                disclaimer && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required
                    </div>
                    <FormGroup
                      lg
                      eligibilityCard
                      style={{ minHeight: "26px" }}
                    ></FormGroup>
                  </>
                )}
              {/* {selectedQuote.company_alias === "tata_aia_life" &&
                selectedOccupation === "Housewife" &&
                (selectedEducation === "Graduate" ||
                  selectedEducation === "Post Graduate") &&
                Number(annualIncome) >= 500000 &&
                selectedQuote?.medicalCondition === "Video Medical" && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required
                    </div>
                  </>
                )} */}
              {selectedQuote.company_alias === "tata_aia_life" &&
                (selectedOccupation === "Self Employed" ||
                  selectedOccupation === "Professional" ||
                  selectedOccupation === "Business") &&
                (selectedEducation === "Graduate" ||
                  selectedEducation === "Post Graduate") &&
                annualIncomeDigits >= 500000 &&
                annualIncomeDigits < 749000 &&
                selectedQuote?.medicalCondition === "Video Medical" && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required
                    </div>
                  </>
                )}
              {selectedQuote.company_alias === "tata_aia_life" &&
                (selectedOccupation === "Self Employed" ||
                  selectedOccupation === "Professional" ||
                  selectedOccupation === "Business") &&
                (selectedEducation === "Graduate" ||
                  selectedEducation === "Post Graduate") &&
                annualIncomeDigits >= 750000 &&
                annualIncomeDigits <= 999000 &&
                selectedQuote?.medicalCondition === "Video Medical" &&
                sumAssured >= 10000000 && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required
                    </div>
                  </>
                )}
              {selectedQuote.company_alias === "tata_aia_life" &&
                selectedOccupation === "Housewife" &&
                selectedQuote?.medicalCondition !== "Physical Medical" &&
                (selectedEducation === "Graduate" ||
                  selectedEducation === "Post Graduate") && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required
                    </div>
                  </>
                )}
              {/* {selectedQuote.company_alias === "tata_aia_life" &&
                (selectedOccupation === "Self Employed"|| selectedOccupation === "Professional" ||
                selectedOccupation === "Business") &&
                annualIncomeDigits < 750000 &&
                selectedQuote?.medicalCondition !== "Physical Medical" && (
                  <>
                    <div className="physical_medical_disclaimer">
                      Basis the details provided by you, there will be a
                      physical medical test required +1
                    </div>
                  </>
                )} */}
              {eligibilityErrors.generic && (
                <Disclaimer>{eligibilityErrors.generic}</Disclaimer>
              )}

              {!isMaxLifeAndHousewifeValid && (
                <Disclaimer>
                  Critical Illness, Disability Rider and Waiver of Premium
                  riders are not available for Housewife.
                </Disclaimer>
              )}

              {!isBajajRidersEducationValid && (
                <>
                  {notAllowed === false ? (
                    <div></div>
                  ) : (
                    <>
                      {eligibilityErrors?.annualIncome?.length > 1 ||
                      eligibilityErrors?.education?.length > 1 ? (
                        <></>
                      ) : (
                        <Disclaimer>
                          Riders are not allowed for selected Education.
                        </Disclaimer>
                      )}
                    </>
                  )}
                </>
              )}
              {!isBajajOccupationValid && (
                <Disclaimer>
                  Riders are not allowed for selected Occupation.
                </Disclaimer>
              )}
              {!isHDFCOccupationValid && companyAlias === "hdfc_life" && (
                <>
                  {eligibilityErrors?.annualIncome?.length > 1 ||
                  eligibilityErrors?.education?.length > 1 ? (
                    <></>
                  ) : (
                    <Disclaimer>
                      Riders are not allowed for selected Occupation.
                    </Disclaimer>
                  )}
                </>
              )}
              {companyAlias === "hdfc_life" ? (
                <div>
                  {/* <div className="questionOneparent">
                    <span className="questionOne">
                      Is there any personal medical history of Diabetes /
                      Cardiomyopathy / Any cancer /Stroke / Brain Tumour/
                      Poliomyelitis /Deafness/Muscular dystrophy / Blindness /
                      Loss of Limbs / Organ transplant / Loss of speech/ Chronic
                      kidney disease/ Kidney failure/ Chronic liver disease?
                    </span>
                    <div>
                      <span className="mo-5 tg-5">NO</span>
                      <span>
                        <ToggleButton
                          elgToggle="true"
                          // checked={checkNRI}

                          onChange={value => {
                            sethdfcQuestion1(value);
                          }}
                        />
                      </span>
                      <span className="mo-5 tg-5">YES</span>
                    </div>
                  </div> */}
                  {/* <div className="questionOneparent">
                    <span className="questionOne">
                      Is there any personal medical history of Heart Disease /
                      Open Chest CABG / Open heart replacement or repair of
                      Heart Valves / Heart Surgery / Angioplasty?
                    </span>
                    <div>
                      <span className="mo-5 tg-5">NO</span>
                      <span>
                        <ToggleButton
                          elgToggle="true"
                          // checked={checkNRI}
                          onChange={value => {
                            sethdfcQuestion2(value);
                          }}
                        />
                      </span>
                      <span className="mo-5 tg-5">YES</span>
                    </div>
                  </div> */}
                  <div className="questionOneparent">
                    <span className="questionOne">
                      Are you currently suffering from or in the last 1 month,
                      have suffered from fever, persistent dry cough, sore
                      throat, breathing difficulties, bodypain, fatigue,
                      conjunctivitis, gastro-intestinal symptoms
                      (vomiting/diarrhoea) or been in contact with an individual
                      suspected or confirmed to have COVID- 19^?
                    </span>
                    <div>
                      <span className="mo-5 tg-5">NO</span>
                      <span>
                        <ToggleButton
                          elgToggle="true"
                          // checked={checkNRI}

                          onChange={value => {
                            sethdfcQuestion3(value);
                          }}
                        />
                      </span>
                      <span className="mo-5 tg-5">YES</span>
                    </div>
                    <span className="errorMessagee">
                      {hdfcQuestion3 === true ? (
                        <p className="error errorElg">
                          Your profile is not eligible for this plan
                        </p>
                      ) : (
                        // <span>Your profile is not eligible for this plan</span>
                        <></>
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Form>
          </Card>
          <div className="justify-content-center">
            <div className="form-group">
              <div className="m-4 mo-1 form-check d-flex checkBoxWrapper">
                <div className="checkBoxElg">
                  <Checkbox
                    elg
                    checked={agreeTerm}
                    id="gridCheck"
                    onChange={e =>
                      disableAgreeTermCheckbox || setAgreeTerm(e.target.checked)
                    }
                    disabled={
                      disableAgreeTermCheckbox || hdfcQuestion3 === true
                    }
                  ></Checkbox>
                </div>
                <div
                  className="form-check-label eligibility__footer dv elgTerms"
                  style={{ textAlign: "justify" }}
                >
                  {(companyAlias === "max_life" && isWipro) ||
                  companyAlias === "bajaj_life" ? (
                    terms_condition_max_wipro
                  ) : companyAlias === "bandhan_life" ? (
                    <>
                      I {customerName} currently employed with{" "}
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {customerDetails?.companyName?.toLowerCase()}
                      </span>
                      . My official employee e-mail id is {company}, and I am
                      providing my consent to receive the policy documents on my
                      e-mail id shared. In case of any change in my registered
                      email ID, I shall immediately inform Alliance Insurance
                      Brokers Pvt. Ltd. and/or the Insurance Company of the
                      same. I confirm all the details shared are correct and
                      accurate as per my knowledge, and I agree with all the{" "}
                      <a
                        target="_blank"
                        href="https://elephant.in/terms-and-condition"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms & Conditions.
                      </a>{" "}
                      I also declare that the information provided above is true
                      and accept that if it is found false, it may impact claims
                      and/or the company reserves the right to terminate the
                      policy immediately. I agree the policy will be issued on
                      meeting specific parameters and subject to Company’s
                      underwriting and acceptance. I agree any changes to the
                      details post payment might require additional payment.
                      Alliance Insurance Brokers Pvt. Ltd. (including its
                      representatives) shall not be held liable for any changes
                      due to incorrect information.
                    </>
                  ) : (
                    terms_condition
                  )}
                </div>
                <div
                  className="form-check-label eligibility__footer mv termsMob"
                  style={{ textAlign: "justify" }}
                >
                  I accept Terms & Conditions
                  <CustomTooltip
                    rider="true"
                    id="RiderInbuilt__Tooltip"
                    place={"top"}
                    customClassName="mt-3  elgToolTipData"
                    Position={{ top: 20, left: 4 }}
                  >
                    <img
                      data-tip={`<h3 >Terms & Conditions</h3> <div>${
                        (companyAlias === "max_life" && isWipro) ||
                        companyAlias === "bajaj_life"
                          ? terms_condition_max_mobile
                          : `I ${customerName} currently employed with  <h6>
                        ${customerDetails?.companyName?.toLowerCase()}
                      </h6>
                      . My official employee e-mail id is ${company}, and I am
                      providing my consent to receive the policy documents on my
                      e-mail id shared. In case of any change in my registered
                      email ID, I shall immediately inform Alliance Insurance
                      Brokers Pvt. Ltd. and/or the Insurance Company of the
                      same. I confirm all the details shared are correct and
                      accurate as per my knowledge, and I agree with all the
                      <a
                        target="_blank"
                        href="https://elephant.in/terms-and-condition"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms & Conditions.
                      </a>
                      I also declare that the information provided above is true
                      and accept that if it is found false, it may impact claims
                      and/or the company reserves the right to terminate the
                      policy immediately. I agree the policy will be issued on
                      meeting specific parameters and subject to Company’s
                      underwriting and acceptance. I agree any changes to the
                      details post payment might require additional payment.
                      Alliance Insurance Brokers Pvt. Ltd. (including its
                      representatives) shall not be held liable for any changes
                      due to incorrect information.`
                      }</div>`}
                      data-html={true}
                      data-for="RiderInbuilt__Tooltip"
                      src={tooltip}
                      alt="tooltip"
                      className="termsTooltip"
                    />
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 mo-3 d-flex justify-content-center elgFinalBtnWrap">
            <div className="floating ubmitBtnWrap ubmitBtnWrapElg">
              <button
                type="submit"
                className={
                  disable ||
                  !agreeTerm ||
                  (!eligibilityFlag && !color) ||
                  hdfcQuestion3 === true
                    ? "btn btn--disabled btn--lg btnf uppercase bold mobBtnElg proceedBtnElg elgBtnDisbaled"
                    : "btn btn--highlighted btn--lg btnf uppercase bold proceedBtnElg"
                }
                onClick={handleSubmit(onSubmit)}
                disabled={
                  disable ||
                  (!eligibilityFlag && !color) ||
                  !agreeTerm ||
                  hdfcQuestion3 === true
                }
              >
                <span className="reviewandsubmit">Review and Submit </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Card className="cardContainerDown ClosedCardDown">
            <CardTitle EC eligibilityTitle className="title-eligibility-header">
              Eligibility details
            </CardTitle>
          </Card>
        </div>
      )}
      {(clicked || loader) && !biLoading && <Loader />}
      {biLoading && (
        <LoadingBi
          loading1={loading1 ? true : false}
          loading2={loading2 ? true : false}
        />
      )}
      {paymentStatusLoader && companyAlias === "tata_aia_life" ? (
        <Loader
          message={
            <PaymentLoadingMessage message="You are now being redirected to Tata AIA Life" />
          }
        />
      ) : null}
      {paymentStatusLoader && companyAlias !== "tata_aia_life" ? (
        <Loader message={<PaymentLoadingMessage />} />
      ) : null}
    </>
  );
};

export default EligibilityCard;

function useMaxLifeHouswifeValidation({ selectedOccupation, companyAlias }) {
  const selectedAddOns = useSelector(selectSelectedAddOns);

  const hasRidersOtherThanADB = selectedAddOns.some(rider =>
    rider.riderName
      ? rider.riderName !== "Accidental Death Benefit"
      : rider.rider_name !== "Accidental Death Benefit",
  );

  return {
    isValid: !(
      selectedOccupation === "Housewife" &&
      hasRidersOtherThanADB &&
      companyAlias === "max_life"
    ),
  };
}
function useBajajRidersValidation({
  selectedEducation,
  selectedOccupation,
  companyAlias,
  annualIncome,
  sumAssured,
}) {
  const selectedAddOns = useSelector(selectSelectedAddOns);
  const isRidersSelected = selectedAddOns.length > 0;
  const isValid = !(
    (
      companyAlias === "bajaj_life" &&
      isRidersSelected &&
      !(
        selectedEducation == "Post Graduate" ||
        selectedEducation == "Graduate" ||
        (selectedEducation == "HSC" &&
          annualIncome >= 500000 &&
          sumAssured <= 10000000) ||
        (selectedEducation == "Matriculation" &&
          annualIncome >= 1000000 &&
          sumAssured <= 10000000)
      )
    )
    // !["Post Graduate", "Graduate"].includes(selectedEducation)
  );
  const isOccupationValid = !(
    companyAlias === "bajaj_life" &&
    isRidersSelected &&
    ["Housewife"].includes(selectedOccupation)
  );

  return {
    isValid,
    isOccupationValid,
  };
}
function useHDFCRidersValidation({
  selectedEducation,
  selectedOccupation,
  companyAlias,
}) {
  const selectedAddOns = useSelector(selectSelectedAddOns);

  const isRidersSelected = selectedAddOns.length > 0;

  const isValid = !(
    companyAlias === "hdfc_life" &&
    isRidersSelected &&
    !["Post Graduate", "Graduate", "10th Pass", "12th Pass"].includes(
      selectedEducation,
    )
  );
  const isOccupationValid = !(
    companyAlias === "hdfc_life" &&
    isRidersSelected &&
    ["Housewife"].includes(selectedOccupation)
  );

  return {
    isValid,
    isOccupationValid,
  };
}
function Disclaimer({ children, ...props }) {
  return (
    <>
      <div className="physical_medical_disclaimer" {...props}>
        {children}
      </div>
      <FormGroup lg eligibilityCard style={{ minHeight: "26px" }}></FormGroup>
    </>
  );
}

export function PaymentLoadingMessage({ message = false, style }) {
  return (
    <div style={style} className="payment_loader">
      <div>
        {message
          ? message
          : "Please wait while we are processing your payment. Do not refresh, close the window, or click back button"}
      </div>
    </div>
  );
}

export const ToggleStyled = styled.span`
  position: absolute;
  right: 2px;
  @media screen and (max-width: 430px) {
    position: static;
  }
`;
