import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { CustomDropDown } from "../../../components";
import Card from "../../../components/Card/Card";
import CardTitle from "../../../components/Card/CardTitle/CardTitle";
import {
  CustomDropDownFont,
  Form,
  FormGroup as FG,
  Label,
  TextInputElg,
  TextAreaProposal,
} from "../../../components/Form/Form";
import RadioComponent from "../../../components/RadioContainer/RadioContainer";
import { generateRandomString, numOnly } from "../../../utils/inputUtils";
import { EditButton, SummaryTile as Summary } from "../components";
import { MemberList, CauseList } from "./data";
import { setPosition } from "../IcicProposalPage/IcicProposal.slice";
import styled from "styled-components";

const FormGroup = styled(FG)`
  height: auto !important;

  @media screen and (max-width: 767px) {
    min-height: max-content;

    display: flex;
    flex-direction: column;

    text-align: left;

    margin: 10px 0;

    padding-left: 20px;
    padding-right: 20px;

    & label {
      position: static;
    }

    & .data {
      position: static !important;
    }
  }
`;

function MedicalDetails2({ proposalData = {}, index, expand, onSubmit }) {
  const [haveFamilyDiseaseHistory, setHaveFamilyDiseaseHistory] = useState(
    proposalData?.familyHealthQuestion === "Y",
  );
  const [narcoticTreatment, setNarcoticTreatment] = useState(
    proposalData?.narcoticsHabit === "Y",
  );
  const [narcoticTreatmentText, setNarcoticTreatmentText] = useState(
    proposalData?.narcoticsHabitText || "",
  );
  const [criminalCharges, setCriminalCharges] = useState(
    proposalData?.criminalConvicted === "Y",
  );
  const [criminalChargesText, setCriminalChargesText] = useState(
    proposalData?.criminalConvictedText || "",
  );
  const [extremeSports, setExtremeSports] = useState(
    proposalData?.adventureAvocation === "Y",
  );
  const [extremeSportsText, setExtremeSportsText] = useState(
    proposalData?.adventureAvocationText || "",
  );
  const { customerDetails } = useSelector(state => state.greeting);
  const [diagnosed, setDiagnosed] = useState(
    proposalData?.insuredHealthQuestion === "Y",
  );
  const [diagnosedtext, setDiagnosedtext] = useState(
    proposalData?.insuredHealthQuestionText || "",
  );
  const [pregnantQuestion, setPregnantQuestion] = useState(
    proposalData?.pregnantQuestion === "Y",
  );
  const [gynecologicalQuestion, setGynecologicalQuestion] = useState(
    proposalData?.gynecologicalQuestion === "Y",
  );
  const [insuranceCoverage, setInsuranceCoverage] = useState(
    proposalData?.insuranceCoverage || "",
  );
  const [annualIncomeHusband, setAnnualIncomeHusband] = useState(
    proposalData?.annualIncomeHusband || customerDetails?.annualIncome,
  );

  const [preg, setPreg] = useState(false);
  const [familyNotAlive, setFamilyNotAlive] = useState(false);
  const [familyNotAliveList, setFamilyNotAliveList] = useState([]);
  const { eligibilityDetails } = useSelector(state => state.eligibilityCard);
  const { basicDetails } = useSelector(state => state.bajajProposalPageForm);

  useEffect(() => {
    if (customerDetails.gender === "F") {
      setPreg(true);
    } else {
      setPreg(false);
    }
  }, [basicDetails, customerDetails]);

  const [edit, setEdit] = useState(index === expand);
  const [familyDiseaseHistoryList, setFamilyDiseaseHistoryList] = useState(
    proposalData.familyHealthDetailsJson
      ? JSON.parse(proposalData.familyHealthDetailsJson).map(c => ({
        ...c,
        familyMemberTextError: "",
        familyAgeError: "",
        familyHealthStatusError: "",
        familyAgeDieError: "",
        familyDeathCauseError: "",
      }))
      : [],
  );

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (familyDiseaseHistoryList.length === 0)
      setHaveFamilyDiseaseHistory(false);
  }, [familyDiseaseHistoryList]);

  useEffect(() => {
    familyDiseaseHistoryList.map(c => {
      if (c.familyHealthStatus === "Not Alive") {
        // familyNotAliveList.push(c.id);
        setFamilyNotAliveList(prev => [...prev, c.id]);
      }
    });
  }, [proposalData.familyHealthDetailsJson]);

  // useEffect(() => {
  //   if (familyNotAlive === false) setFamilyNotAliveList([]);
  // }, [familyNotAlive]);

  // console.log(proposalData);

  const validate = () => {
    let isValid = true;
    let newErrors = {};
    if (preg) {
      // if (!insuranceCoverage) {
      //   newErrors.insuranceCoverage = "Invalid";
      //   isValid = false;
      // }
      if (eligibilityDetails?.selectedOccupation === "Housewife") {
        if (!annualIncomeHusband || annualIncomeHusband?.length < 6) {
          newErrors.annualIncomeHusband =
            annualIncomeHusband?.length < 6
              ? "Annual Income should be at least 6 digits long"
              : "This field is required ";
          isValid = false;
        }
        if (insuranceCoverage && parseInt(insuranceCoverage) < 10000000) {
          newErrors.insuranceCoverage =
            "Minimum Insurance Coverage should be 1 Crore";
          isValid = false;
        }
      }
    }
    if (diagnosed) {
      if (!diagnosedtext) {
        newErrors.diagnosedtext = "This field is required ";
        isValid = false;
      }
    }

    if (extremeSports) {
      if (!extremeSportsText) {
        newErrors.extremeSportsText = "This field is required ";
        isValid = false;
      }
    }

    if (criminalCharges) {
      if (!criminalChargesText) {
        newErrors.criminalChargesText = "This field is required ";
        isValid = false;
      }
    }

    if (narcoticTreatment) {
      if (!narcoticTreatmentText) {
        newErrors.diagnosedtext = "This field is required ";
        isValid = false;
      }
    }

    if (preg) {
      if (eligibilityDetails?.selectedOccupation === "Housewife") {
        if (!insuranceCoverage) {
          newErrors.insuranceCoverage = "This field is required ";
          isValid = false;
        }
        if (!annualIncomeHusband) {
          newErrors.annualIncomeHusband = "This field is required ";
          isValid = false;
        }
      }
    }

    if (haveFamilyDiseaseHistory) {
      const newOther = familyDiseaseHistoryList.map(c => {
        let newFamily = { ...c };
        console.log(newFamily);
        if (!c.familyMemberText) {
          newFamily.familyMemberTextError = "This field is required ";
          isValid = false;
        }
        if (!familyNotAliveList.includes(newFamily.id)) {
          if (!c.familyAge) {
            newFamily.familyAgeError = "This field is required ";
            isValid = false;
          }
        }
        if (!c.familyHealthStatus) {
          newFamily.familyHealthStatusError = "This field is required ";
          isValid = false;
        }
        if (familyNotAliveList.includes(newFamily.id)) {
          if (!c.familyAgeDie) {
            newFamily.familyAgeDieError = "This field is required ";
            isValid = false;
          }
          if (!c.familyDeathCause) {
            newFamily.familyDeathCauseError = "This field is required ";
            isValid = false;
          }
        }
        return newFamily;
      });
      setFamilyDiseaseHistoryList(newOther);
    }
    if (!isValid) setErrors(newErrors);
    else setErrors({});
    return isValid;
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    if (!validate()) return;
    setEdit(false);
    onSubmit({
      narcoticTreatment: narcoticTreatment ? "Y" : "N",
      narcoticTreatmentText,
      criminalCharges: criminalCharges ? "Y" : "N",
      criminalChargesText,
      extremeSports: extremeSports ? "Y" : "N",
      extremeSportsText,
      diagnosed: diagnosed ? "Y" : "N",
      diagnosedtext,
      pregnantQuestion: pregnantQuestion ? "Y" : "N",
      gynecologicalQuestion: gynecologicalQuestion ? "Y" : "N",
      insuranceCoverage,
      annualIncomeHusband,
      haveFamilyDiseaseHistory: haveFamilyDiseaseHistory ? "Y" : "N",
      familyDiseaseHistoryList: [...familyDiseaseHistoryList],
      proposalJourneyStatus: "medicalDetails2",
    });
  };

  console.log(familyDiseaseHistoryList);

  useEffect(() => {
    if (index === expand) setEdit(true);
  }, [index, expand]);

  const removeFamily = id => {
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.filter(i => i.id !== id),
    );
  };
  const updateMember = (familyMemberText, id) => {
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.map(i =>
        i.id === id ? { ...i, familyMemberText, familyMemberTextError: "" } : i,
      ),
    );
  };
  const updateAge = (familyAge, id) => {
    if (familyAge <= 0 && familyAge !== "") return;
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.map(i =>
        i.id === id ? { ...i, familyAge, familyAgeError: "" } : i,
      ),
    );
  };
  const updateStatus = (familyHealthStatus, id) => {
    if (familyHealthStatus === "Not Alive") {
      setFamilyNotAlive(true);
      // familyNotAliveList.push(id);

      setFamilyNotAliveList(prev => [...prev, id]);
    } else {
      setFamilyNotAlive(false);
      setFamilyNotAliveList(familyNotAliveList.filter(item => item !== id));
    }
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.map(i =>
        i.id === id
          ? { ...i, familyHealthStatus, familyHealthStatusError: "" }
          : i,
      ),
    );
  };
  const updateDiedAge = (familyAgeDie, id) => {
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.map(i =>
        i.id === id ? { ...i, familyAgeDie, familyAgeDieError: "" } : i,
      ),
    );
  };

  const updateDeathCause = (familyDeathCause, id) => {
    setFamilyDiseaseHistoryList(
      familyDiseaseHistoryList.map(i =>
        i.id === id ? { ...i, familyDeathCause, familyDeathCauseError: "" } : i,
      ),
    );
  };
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC> Medical Questions - Set 2</CardTitle>
      </Card>
    </div>
  );

  const dispatch = useDispatch();

  if (expand < index) return TitleForm;

  return edit ? (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => dispatch(setPosition(index))}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup proposalCardL eligibilityPage mobheight>
          <div style={{ marginBottom: 20 }}>
            Have you ever taken or undergone treatment for narcotics or any
            addictive drug?
          </div>
          <RadioComponent
            value={narcoticTreatment}
            name="narcoticTreatment"
            updateChecked={setNarcoticTreatment}
          />
        </FormGroup>
        {narcoticTreatment && (
          <>
            <FormGroup proposalCardL eligibilityPage>
              <TextAreaProposal
                md
                type="text"
                id="narcoticTreatmentText"
                name="narcoticTreatmentText"
                value={narcoticTreatmentText}
                onChange={evt => {
                  setNarcoticTreatmentText(evt.target.value);
                  setErrors({ ...errors, narcoticTreatmentText: "" });
                }}
              />
              <Label md htmlFor="narcoticTreatmentText" formBuilerLabel>
                Please specify
              </Label>
              <p className="error">{errors.narcoticTreatmentText}</p>
            </FormGroup>
          </>
        )}
        <FormGroup proposalCardL eligibilityPage mobheight>
          <div style={{ marginBottom: 20 }}>
            Have you ever been convicted in the court of law or are there any
            criminal proceeding pending against you before a court?
          </div>
          <RadioComponent
            value={criminalCharges}
            name="criminalCharges"
            updateChecked={setCriminalCharges}
          />
        </FormGroup>
        {criminalCharges && (
          <>
            <FormGroup proposalCardL eligibilityPage>
              <TextAreaProposal
                md
                type="text"
                id="criminalChargesText"
                name="criminalChargesText"
                value={criminalChargesText}
                onChange={evt => {
                  setCriminalChargesText(evt.target.value);
                  setErrors({ ...errors, criminalChargesText: "" });
                }}
              />
              <Label md htmlFor="criminalChargesText" formBuilerLabel>
                Please specify
              </Label>
              <p className="error">{errors.criminalChargesText}</p>
            </FormGroup>
          </>
        )}
        <FormGroup proposalCardL eligibilityPage lg mobheight>
          <div style={{ marginBottom: 20 }}>
            Do you plan to or were involved in any adventurous avocation such
            that but not limited to flying or travelling in a non-commercial
            aeroplane, automobile racing, horse riding, boat race, scuba diving?
          </div>
          <RadioComponent
            value={extremeSports}
            name="extremeSports"
            updateChecked={setExtremeSports}
          />
        </FormGroup>
        {extremeSports && (
          <>
            <FormGroup proposalCardL eligibilityPage>
              <TextAreaProposal
                md
                type="text"
                id="extremeSportsText"
                name="extremeSportsText"
                value={extremeSportsText}
                onChange={evt => {
                  setExtremeSportsText(evt.target.value);
                  setErrors({ ...errors, extremeSportsText: "" });
                }}
              />
              <Label md htmlFor="extremeSportsText" formBuilerLabel>
                Please specify
              </Label>
              <p className="error">{errors.extremeSportsText}</p>
            </FormGroup>
          </>
        )}
        <FormGroup proposalCardL eligibilityPage mobheight lg>
          <div style={{ marginBottom: 20 }}>
            Have you ever been diagnosed with, received any treatment or been
            referred for investigations related to any disease or disorder?
          </div>
          <RadioComponent
            value={diagnosed}
            name="diagnosed"
            updateChecked={setDiagnosed}
          />
        </FormGroup>
        {diagnosed && (
          <>
            <FormGroup proposalCardL eligibilityPage>
              <TextAreaProposal
                md
                type="text"
                id="diagnosedtext"
                name="diagnosedtext"
                value={diagnosedtext}
                onChange={evt => {
                  setDiagnosedtext(evt.target.value);
                  setErrors({ ...errors, diagnosedtext: "" });
                }}
              />
              <Label md htmlFor="diagnosedtext" formBuilerLabel>
                Please specify
              </Label>
              <p className="error">{errors.diagnosedtext}</p>
            </FormGroup>
          </>
        )}
        {preg && (
          <>
            <FormGroup proposalCardL eligibilityPage mobheight lg>
              <div style={{ marginBottom: 20 }}>
                Have you suffered / are suffering from or have undergone
                investigation or treatment for any gynecological complications
                such as disorders of Cervix, uterus,ovaries,breast, breast
                lump,cyst etc.
              </div>
              <RadioComponent
                value={gynecologicalQuestion}
                name="gynecologicalQuestion"
                updateChecked={setGynecologicalQuestion}
              />
            </FormGroup>
            <FormGroup proposalCardL eligibilityPage mobheight lg>
              <div style={{ marginBottom: 20 }}>
                Are you pregnant or undergone miscarriage or ectopic pregnancy
                or abortion in last 3 months?
              </div>
              <RadioComponent
                value={pregnantQuestion}
                name="pregnantQuestion"
                updateChecked={setPregnantQuestion}
              />
            </FormGroup>
          </>
        )}
        {preg && eligibilityDetails?.selectedOccupation === "Housewife" && (
          <>
            <FormGroup
              proposalCardL
              eligibilityPage
              style={{ marginTop: "12px" }}
            >
              <TextInputElg
                proposal
                type="number"
                maxLength="20"
                id="insuranceCoverage"
                name="insuranceCoverage"
                value={insuranceCoverage}
                onChange={evt => {
                  setInsuranceCoverage(evt.target.value);
                  setErrors({ ...errors, insuranceCoverage: "" });
                }}
              />
              <Label md htmlFor="insuranceCoverage" formBuilerLabel>
                Total Life Insurance coverage on Husband Sum assured*
              </Label>
              <p className="error">{errors.insuranceCoverage}</p>
            </FormGroup>
            <FormGroup proposalCardL eligibilityPage>
              <TextInputElg
                proposal
                type="number"
                maxLength="10"
                id="annualIncomeHusband"
                readOnly
                name="annualIncomeHusband"
                value={annualIncomeHusband}
                onChange={evt => {
                  setAnnualIncomeHusband(evt.target.value);
                  setErrors({ ...errors, annualIncomeHusband: "" });
                }}
              />
              <Label md htmlFor="annualIncomeHusband" formBuilerLabel>
                Annual income of Husband*
              </Label>
              <p className="error">{errors.annualIncomeHusband}</p>
            </FormGroup>
          </>
        )}
        <FormGroup proposalCardL eligibilityPage mobheight>
          <div style={{ marginBottom: 20 }}>
            Has any of your family member (parent & sibling) ever been diagnosed
            before the age of 60 with Diabetes, Hypertension, Kidney failure,
            Cancer, Heart attack or any hereditary disorder?
          </div>
          <RadioComponent
            value={haveFamilyDiseaseHistory}
            name="haveFamilyDiseaseHistory"
            updateChecked={value => {
              setHaveFamilyDiseaseHistory(value);
              if (value)
                setFamilyDiseaseHistoryList([{ id: generateRandomString(10) }]);
            }}
          />
        </FormGroup>
        {haveFamilyDiseaseHistory && (
          <>
            {familyDiseaseHistoryList.map(item => (
              <FamilyDetails
                {...item}
                updateMember={updateMember}
                updateAge={updateAge}
                updateStatus={updateStatus}
                updateDiedAge={updateDiedAge}
                updateDeathCause={updateDeathCause}
                removeFamily={removeFamily}
                familyNotAlive={familyNotAlive}
                familyNotAliveList={familyNotAliveList}
                setErrors={setErrors}
              />
            ))}
            {familyDiseaseHistoryList.length < 5 && (
              <FormGroup>
                <button
                  type="button"
                  onClick={() =>
                    setFamilyDiseaseHistoryList([
                      ...familyDiseaseHistoryList,
                      { id: generateRandomString(10) },
                    ])
                  }
                  style={{
                    backgroundColor: "#107591",
                    border: "none",
                    outline: "none",
                    fontSize: "10px",
                    padding: "10px 50px",
                    color: "#fff",
                    fontFamily: "Inter-SemiBold",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Add another member
                </button>
              </FormGroup>
            )}
          </>
        )}
        <FormGroup xlg formPages propSubWrap>
          <button
            type="submit"
            className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
          >
            PROCEED TO MEDICAL QUESTION SET 3
          </button>
        </FormGroup>
      </Form>
    </CardWrap>
  ) : (
    <CardWrap
      edit={edit}
      setEdit={setEdit}
      onEdit={() => {
        dispatch(setPosition(index));
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          marginLeft: "-16px",
        }}
      >
        <SummaryTile
          label={`	Have you ever taken or undergone treatment for narcotics or any
        addictive drug?`}
          data={narcoticTreatment ? "Yes" : "No"}
          xlg
          labelStyle={{ top: "30px" }}
        />
        {narcoticTreatment && (
          <SummaryTile label="Details" data={narcoticTreatmentText} />
        )}

        <SummaryTile
          label={`	Have you ever been convicted in the court of law or are there any
        criminal proceeding pending against you before a court?`}
          data={criminalCharges ? "Yes" : "No"}
          xlg
          style={{ marginBottom: "10px" }}
          labelStyle={{ top: "47px" }}
        />
        {criminalCharges && (
          <SummaryTile label="Details" xlg data={criminalChargesText} />
        )}

        <SummaryTile
          label={`Have you ever been diagnosed with, received any treatment or been
        referred for investigations related to any disease or disorder?`}
          data={diagnosed ? "Yes" : "No"}
          xlg
          style={{ marginBottom: "10px" }}
          labelStyle={{ top: "47px" }}
        />
        {diagnosed && <SummaryTile label="Details" xlg data={diagnosedtext} />}

        <SummaryTile
          label={`	Do you plan to or were involved in any adventurous avocation such
        that but not limited to flying or travelling in a non-commercial
        aeroplane, automobile racing, horse riding, boat race, scuba diving?`}
          data={extremeSports ? "Yes" : "No"}
          xlg
          style={{ marginBottom: "10px" }}
          labelStyle={{ top: "47px" }}
        />
        {extremeSports && (
          <SummaryTile label="Details" xlg data={extremeSportsText} />
        )}

        {preg && (
          <>
            <SummaryTile
              label={`	Have you suffered / are suffering from or have undergone
        investigation or treatment for any gynecological complications
        such as disorders of Cervix, uterus,ovaries,breast, breast
        lump,cyst etc.`}
              data={gynecologicalQuestion ? "Yes" : "No"}
              xlg
              style={{ marginBottom: "10px" }}
              labelStyle={{ top: "47px" }}
            />

            <SummaryTile
              label={`Are you pregnant or undergone miscarriage or ectopic pregnancy
        or abortion in last 3 months?`}
              data={pregnantQuestion ? "Yes" : "No"}
              xlg
              style={{ marginBottom: "10px" }}
              labelStyle={{ top: "47px" }}
            />
          </>
        )}
        {preg && eligibilityDetails?.selectedOccupation === "Housewife" && (
          <>
            <SummaryTile
              label={"Total Life Insurance coverage on Husband Sum assured*"}
              data={insuranceCoverage}
              xlg
              xlg
              style={{ marginBottom: "10px" }}
              labelStyle={{ top: "47px" }}
            />
            <SummaryTile
              label={"Annual income of Husband*"}
              data={annualIncomeHusband}
              xlg
            />
          </>
        )}

        <SummaryTile
          label="Has any of your family member (parent & sibling) ever been diagnosed before the age of 60 with Diabetes, Hypertension, Kidney failure, Cancer, Heart attack or any hereditary disorder?"
          data={haveFamilyDiseaseHistory ? "Yes" : "No"}
          xlg
          style={{ marginBottom: "10px" }}
          labelStyle={{ top: "47px" }}
        />
        {haveFamilyDiseaseHistory &&
          familyDiseaseHistoryList.map(item => (
            <FamilyDetailsSummary
              {...item}
              familyNotAliveList={familyNotAliveList}
            />
          ))}
      </div>
    </CardWrap>
  );
}

export default MedicalDetails2;

function SummaryTile({ label, data, ...props }) {
  return (
    <FormGroup formPage {...props}>
      <Label md editMode htmlFor="taxResident" style={{ textAlign: "left" }}>
        {label}
      </Label>
      <span
        className="data"
        style={{
          top: "37px",
          left: "17px",
          position: "relative",
          float: "left",
          ...props?.labelStyle,
        }}
      >
        {data}
      </span>
    </FormGroup>
  );
}

function CardWrap({ children, edit, setEdit, onEdit = () => { } }) {
  return (
    <Card proposal>
      <CardTitle eligibilityTitle className="cardTitleProposal propCardTitle">
        Medical Questions - Set 2
        {!edit && (
          <EditButton
            onClick={() => {
              setEdit(true);
              onEdit();
            }}
          />
        )}
      </CardTitle>
      {children}
    </Card>
  );
}

function FamilyDetailsSummary({
  familyMemberText = "Select",
  familyAge = "",
  familyHealthStatus = "Select",
  familyAgeDie = "",
  familyDeathCause = "Select",
  familyNotAlive,
  familyNotAliveList,
  id,
}) {
  return (
    <>
      <SummaryTile label="Please Select Member" data={familyMemberText} md />
      <SummaryTile label="Status" data={familyHealthStatus} md />
      {familyNotAliveList.includes(id) ? (
        <>
          <SummaryTile label="Age When Died" data={familyAgeDie} md />
          <SummaryTile label="Death Cause" data={familyDeathCause} md />
        </>
      ) : (
        <SummaryTile label="Age" data={familyAge} md />
      )}
      <SummaryTile label="" data={""} md />
    </>
  );
}

function FamilyDetails({
  familyMemberText = "Select",
  familyAge = "",
  familyHealthStatus = "Select",
  familyAgeDie = "",
  familyDeathCause = "Select",
  id,
  updateMember,
  updateAge,
  updateStatus,
  updateDiedAge,
  updateDeathCause,
  removeFamily,
  familyNotAlive,
  familyNotAliveList,
  ...props
}) {
  return (
    <>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Member"
            propDropDown
            selectedItem={familyMemberText}
            currentSelected={familyMemberText}
            items={MemberList}
            searchable
            onChange={val => updateMember(val, id)}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">
          {props.familyMemberTextError}
        </p>
      </FormGroup>
      <FormGroup proposalCard eligibilityPage>
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Status"
            propDropDown
            selectedItem={familyHealthStatus}
            currentSelected={familyHealthStatus}
            items={["Good", "Bad", "Average", "Not Alive"]}
            onChange={val => updateStatus(val, id)}
          />
        </CustomDropDownFont>
        <p className="errorG formbuilder__error">
          {props.familyHealthStatusError}
        </p>
      </FormGroup>
      {familyNotAliveList.includes(id) ? (
        <>
          <FormGroup proposalCard eligibilityPage>
            <TextInputElg
              proposal
              maxLength="3"
              type="text"
              id={familyAgeDie + id}
              name={familyAgeDie + id}
              onInput={numOnly}
              value={familyAgeDie}
              onChange={evt => {
                updateDiedAge(evt.target.value, id);
              }}
            />
            <Label md htmlFor={familyAgeDie + id} formBuilerLabel>
              Age When Died
            </Label>
            <p className="errorG formbuilder__error">
              {props.familyAgeDieError}
            </p>
          </FormGroup>
          <FormGroup proposalCard eligibilityPage>
            <CustomDropDownFont rdr>
              <CustomDropDown
                dropDownTitle="Death Cause"
                propDropDown
                searchable
                selectedItem={familyDeathCause}
                currentSelected={familyDeathCause}
                onChange={val => updateDeathCause(val, id)}
                items={CauseList}
              />
            </CustomDropDownFont>
            <p className="errorG formbuilder__error">
              {props.familyDeathCauseError}
            </p>
          </FormGroup>
        </>
      ) : (
        <FormGroup proposalCard eligibilityPage>
          <TextInputElg
            proposal
            type="text"
            id={familyAge + id}
            name={familyAge + id}
            maxLength="3"
            onInput={numOnly}
            value={familyAge}
            onChange={evt => {
              updateAge(evt.target.value, id);
            }}
          />
          <Label md htmlFor={familyAge + id} formBuilerLabel>
            Age
          </Label>
          <p className="errorG formbuilder__error">{props.familyAgeError}</p>
        </FormGroup>
      )}
      <FormGroup proposalCardL>
        <button
          type="button"
          className="formbuilder__removeBtn "
          onClick={() => removeFamily(id)}
          style={{
            backgroundColor: "#f2581b",
            float: "right",
            border: "none",
            outline: "none",
            fontSize: "10px",
            padding: "10px 50px",
            color: "#fff",
            fontFamily: "Inter-SemiBold",
            borderRadius: "5px",
          }}
        >
          Remove
        </button>
      </FormGroup>
    </>
  );
}
