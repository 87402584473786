/* eslint-disable eqeqeq */
import React from "react";
import "./product.scss";
import { useDispatch, useSelector } from "react-redux";
import BajajDiscountPopup from "../../../../components/Popup/BajajDiscountPopup";
import KotakDiscountPopup from "../../../../components/Popup/KotakDiscountPopup";
import { setOpenBajajDiscount } from "../../../../components/Popup/bajajDiscount.slice";
import { setOpenKotakDiscount } from "../../../../components/Popup/kotakDiscount.slice";
import { setOpenMaxDiscount } from "../../../../components/Popup/maxDiscount.slice";
import { useQuoteBuyNowButton } from "../../../../custom-hooks";
import MaxDiscountPopup from "../../../../components/Popup/MaxDiscountPopup";
import Loader from "../../../../components/Loader/Loader";
import IciciDiscountPopup from "../../../../components/Popup/IciciDiscountPopup";
import { setOpenIciciDiscount } from "../../../../components/Popup/iciciDiscount.slice";
function Product({
  recommended,
  maxInbuiltRiders,
  maxAddonRiders,
  quote,
  planAmount,
  coverAmount,
  quotesLength,
  coverUpto,
  nomineeGets,
  paymentTerm,
  payPremium,
  medicalExamination,
  onBuyNowClick,
}) {
  const { openBajajDiscount } = useSelector(state => state.bajajDiscount);
  const { openIciciDiscount } = useSelector(state => state.iciciDiscount);
  const { openKotakDiscount } = useSelector(state => state.kotakDiscount);
  const { openMaxDiscount } = useSelector(state => state.maxDiscount);
  const { selectedQuote } = useSelector(state => state.quote);
  const { isQuotesLoading } = useSelector(({ quote }) => quote);
  const dispatch = useDispatch();
  const handleBuyNowClick = useQuoteBuyNowButton();

  const handleCloseBajajDiscount = () => {
    dispatch(setOpenBajajDiscount(false));
  };
  const handleCloseKotakDiscount = () => {
    dispatch(setOpenKotakDiscount(false));
  };
  const handleCloseIciciDiscount = () => {
    dispatch(setOpenIciciDiscount(false));
  };
  const handleCloseMaxDiscount = () => {
    dispatch(setOpenMaxDiscount(false));
  };
  return (
    <li
      className={`compare-page-product ${
        quotesLength < 3 ? `compare-page-product--${quotesLength}` : ""
      } ${recommended ? "productRec" : ""}`}
    >
      {recommended && (
        <div className="productRecLabel">RECOMMENDED FOR YOU</div>
      )}
      <div className="top-info">
        <div className="compare-page-product__logo-wrap">
          <img
            src={quote.company_logo}
            alt="plan"
            className={quote.company_alias === "kotak_life" ? "LogoKotak" : ""}
          />
        </div>
        <div className="planName">
          {quote.master_product_name || quote.child_product_name}
        </div>
        <div className="planAmt">{planAmount}</div>
        <button
          className="recPlanBuyBtn"
          disabled={
            isQuotesLoading === "update-quotation" &&
            selectedQuote?.company_alias !== quote?.company_alias
          }
          onClick={onBuyNowClick}
        >
          {isQuotesLoading === "update-quotation" &&
          selectedQuote?.company_alias === quote?.company_alias ? (
            <Loader
              style={{
                position: "relative",
                background: "transparent ",
                top: "0 !important",
                left: "48% !important",
                borderRadius: "5px",
                border: "5px",
                width: "220px !important",
                height: "50px",
              }}
              position={"smallBox"}
              bounceStyle={{ backgroundColor: recommended ? "#fff" : "" }}
            />
          ) : (
            "BUY NOW"
          )}
        </button>
      </div>
      {quote.company_alias === "max_life" && openMaxDiscount && (
        <MaxDiscountPopup
          show={openMaxDiscount}
          onClose={handleCloseMaxDiscount}
          handleClick={handleBuyNowClick}
          quote={selectedQuote}
        />
      )}
      {quote.company_alias === "bajaj_life" && openBajajDiscount && (
        <BajajDiscountPopup
          show={openBajajDiscount}
          onClose={handleCloseBajajDiscount}
          quote={selectedQuote}
        />
      )}
      {quote.company_alias === "kotak_life" && openKotakDiscount && (
        <KotakDiscountPopup
          show={openKotakDiscount}
          onClose={handleCloseKotakDiscount}
          quote={selectedQuote}
        />
      )}

      {quote.company_alias === "icici_pru" &&
        quote?.self_employed_premium != 0 &&
        paymentTerm.option !== "Single Pay" &&
        openIciciDiscount && (
          <IciciDiscountPopup
            show={openIciciDiscount}
            onClose={handleCloseIciciDiscount}
            quote={selectedQuote}
          />
        )}

      <ul className="cd-features-list">
        <li>
          <div className="planSubOptionValue">Since {quote.formation_year}</div>
          <div className="planSubOptionValue">
            {quote.company_alias === "kotak_life"
              ? quote.solvency_ratio?.substr(
                  0,
                  quote.solvency_ratio?.indexOf("("),
                )
              : quote.company_alias === "tata_aia_life"
              ? quote.solvency_ratio
              : quote.solvency_ratio?.substr(
                  0,
                  quote.solvency_ratio?.indexOf("%"),
                ) + "%"}
          </div>
          <div className="planSubOptionValue">{`${quote.claim_settlement_ratio.slice(
            0,
            4,
          )}%`}</div>
        </li>
        <li>
          <div className="planSubOptionValue">{coverAmount}</div>
          <div className="planSubOptionValue">{coverUpto}</div>
          <div className="planSubOptionValue">{nomineeGets}</div>
          <div className="planSubOptionValue">{paymentTerm}</div>
          <div className="planSubOptionValue">{payPremium}</div>
          <div className="planSubOptionValue">{medicalExamination}</div>
        </li>
        <li>
          {quote.inbuilt_riders.map((rider, idx) => (
            <div
              className="planSubOptionValue"
              key={idx + Math.random() * 1000}
            >
              <div className="planSubOptionWrap">
                <div className="planSubOptionVal">{rider.rider_name}</div>
              </div>
            </div>
          ))}
          {quote.inbuilt_riders.length < maxInbuiltRiders ? (
            [...Array(maxInbuiltRiders - quote.inbuilt_riders.length)].map(
              (_, idx, arr) => (
                <div
                  key={idx + Math.random() * 1000}
                  className={`planSubOptionValue ${
                    idx === arr.length - 1 ? "productDividerPadding" : ""
                  }`}
                >
                  <div className="planSubOptionWrap">
                    <div className="planSubOptionVal"></div>
                  </div>
                </div>
              ),
            )
          ) : (
            <div className="productDividerPadding"></div>
          )}
          {quote.addon_riders.map((rider, idx) => (
            <div
              className="planSubOptionValue"
              key={idx + Math.random() * 1000}
            >
              <div className="planSubOptionWrap">
                <div className="planSubOptionVal">{rider.rider_name}</div>
              </div>
            </div>
          ))}
          {quote.addon_riders.length < maxAddonRiders ? (
            [...Array(maxAddonRiders - quote.addon_riders.length)].map(
              (_, idx, arr) => (
                <div
                  key={idx + Math.random() * 1000}
                  className={`planSubOptionValue ${
                    idx === arr.length - 1 ? "productDividerPadding" : ""
                  }`}
                >
                  <div className="planSubOptionWrap">
                    <div className="planSubOptionVal"></div>
                  </div>
                </div>
              ),
            )
          ) : (
            <div className="productDividerPadding"></div>
          )}
        </li>
      </ul>
    </li>
  );
}

export default Product;
