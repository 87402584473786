import React, { useState, useEffect, useRef } from "react";
import Card from "../../../../components/Card/Card";
import { yupResolver } from "@hookform/resolvers";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import CustomDropDown from "../../../../components/CutomDropDown/CustomDropDown";
import location from "../../../../assets/img/location.svg";
import Benificiary from "./Benificiary";
import swal from "sweetalert";
import {
  saveNomineeDetails,
  saveTrusteeDetails,
  getRegion,
  getPincodeMaster,
  addBeneficiaryDetails,
  nomineeDetailsSendData,
  trusteeDetailsSendData,
} from "./NomineeDetailsForm.slice";
import {
  noNum,
  numOnly,
  noSpclChars,
  upperCase,
  getAge,
  noCopyAndPaste,
} from "../../../../utils/inputUtils";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  Radio,
  RadioButton,
  RadioContainer,
  RadioLabel,
  AgeWrapper,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import { dateUtil } from "../../../../utils/inputUtils";
import { nomineeFormSchema } from "../../../../utils/validationSchema";
import "./BenefeciaryDetails.scss";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { pinCodeMaster } from "../../serviceApi";
import { iciciProposalForms } from "../IcicProposal.slice";

const NomineeDetailsForm = ({ position, setPosition, index }) => {
  const [edit, setEdit] = useState(true);
  const [age, setAge] = useState(false);
  const [appointeeAge, setAppointeeAge] = useState("");
  const [trusteeAge, setTrusteeAge] = useState("");
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const { selectedQuote } = useSelector(state => state.quote);
  const [mwpaQ, setMwpaQ] = useState(false);

  const [shareSum, setShareSum] = useState(0);
  const [shareSumError, setShareSumError] = useState(false);
  const { proposerDetails } = useSelector(state => state.proposalForm);

  const [pinCode, setPinCode] = useState(null);
  const { insuredOccupationDetails } = useSelector(
    state => state.insuredOccupationForm,
  );

  const {
    nomineeDetails,
    trusteeDetails,
    nomineeRegionDetails,
    nomineePincodeMaster,
    beneficiaryList,
  } = useSelector(state => state.nomineeForm);
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    trigger,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mwpa: false,
    },
    resolver: yupResolver(nomineeFormSchema),
    shouldUnregister: true,
  });
  const { isValid } = formState;
  const trusteeType = ["Select Trustee Type", "Entity", "Individual"];
  let sharess = 0;
  const [nomRel, setNomRel] = useState(
    nomineeDetails?.relation ? nomineeDetails?.relation : "Select Relation",
  );
  const [appRel, setAppRel] = useState(
    nomineeDetails?.arelation ? nomineeDetails?.arelation : "Select Relation",
  );
  const [nomGen, setNomGen] = useState(
    nomineeDetails?.nomGender === "M"
      ? "Male"
      : nomineeDetails?.nomGender === "F"
      ? "Female"
      : "Select Gender",
  );
  const [appGen, setAppGen] = useState(
    nomineeDetails?.agender === "M"
      ? "Male"
      : nomineeDetails?.agender === "F"
      ? "Female"
      : "Select Gender",
  );
  const [mwpa, setMwpa] = useState(
    nomineeDetails.mwpa || trusteeDetails.mwpa ? true : false,
  );
  const relationshipsMale = [
    "Select Relation",
    "Son",
    "Father",
    "Brother",
    "Brother in law",
    "Grand Father",
    "Grand son",
    "Husband",
    "Nephew",
    "Uncle",
  ];

  const relationshipsFemale = [
    "Select Relation",
    "Daughter",
    "Wife",
    "Mother",
    "Aunt",
    "Sister in law",
    "Grand Daughter",
    "Grand Mother",
    "Niece",
    "Sister",
  ];
  useEffect(() => {
    sharess = beneficiaryList.reduce(
      (sum, { share }) => sum + Number(share),
      0,
    );
    if (sharess !== 100) {
      setShareSumError(true);
    } else {
      setShareSumError(false);
    }
  }, [beneficiaryList, sharess]);

  useEffect(() => {
    if (
      proposerDetails.gender === "M" &&
      proposerDetails.martialStatus === "Married"
    ) {
      setMwpaQ(true);
    } else {
      setMwpaQ(false);
    }
  }, [register, mwpaQ, proposerDetails]);

  useEffect(() => {
    register({ name: "mwpa" });
    setValue("mwpa", mwpa);
    register({ name: "nomGender" });
    setValue("nomGender", nomineeDetails.nomGender);
  }, [register]);

  useEffect(() => {
    if (mwpa) {
      register({ name: "ttype" });
      setValue("ttype", trusteeDetails.ttype);
      unregister(["adob"]);
      unregister(["relation"]);
      unregister(["arelation"]);
    } else {
      unregister(["ttype"]);
      setDisable(false);
      register(["relation"]);
      setValue("relation", nomineeDetails.relation);
      register(["arelation"]);
      setValue("arelation", nomineeDetails.arelation);
    }
  }, [register, mwpa, unregister, nomineeDetails, trusteeDetails]);
  /*
  useEffect(() => {
    if (beneficiaryList.length < 1) {
      setMwpa(false);
    }
  }, [beneficiaryList.length]);
*/

  const enquiryId = new URLSearchParams(window.location.search).get(
    "enquiryId",
  );
  useEffect(() => {
    if (pinCode && pinCode.length === 6) {
      const data = { pinCode: pinCode };
      const dataMaster = {
        pinCode: pinCode,
        companyAlias: selectedQuote?.company_alias,
        traceId: enquiryId,
      };
      dispatch(getPincodeMaster(dataMaster));
    }
  }, [dispatch, pinCode]);
  useEffect(() => {
    register({ name: "relation" });
    setValue("relation", nomineeDetails.relation);
    if (age < 18) {
      register({ name: "arelation" });
      setValue("arelation", nomineeDetails.arelation);
      register({ name: "agender" });
      setValue("agender", nomineeDetails.agender);
    } else {
      unregister({ name: "agender" });
    }
  }, [register, age]);
  const [first, setFirst] = useState(true);
  useEffect(() => {
    setFirst(false);
  }, []);
  useEffect(() => {
    if (!first) {
      setValue("relation", "Select Relation");
      setNomRel("Select Relation");
      trigger("relation");
    }
  }, [nomGen]);

  useEffect(() => {
    if (!first) {
      setValue("arelation", "Select Relation");
      setAppRel("Select Relation");
      trigger("arelation");
    }
  }, [appGen]);

  useEffect(() => {
    if (age >= 18) {
      unregister(["arelation", "adob"]);
    }
  }, [unregister, age]);

  const dateRef = useRef();
  const adateRef = useRef();
  const tdateRef = useRef();

  const attachRef = ref => {
    register(ref);
    dateRef.current = ref;
  };
  const aRef = ref => {
    register(ref);
    adateRef.current = ref;
  };
  const tRef = ref => {
    register(ref);
    tdateRef.current = ref;
  };

  const handleChange = event => {
    setAge(dateUtil(event, dateRef.current, true, false, true));
  };
  const ahandleChange = event => {
    setAppointeeAge(dateUtil(event, adateRef.current, true, false, true));
  };
  const thandleChange = event => {
    setTrusteeAge(dateUtil(event, tdateRef.current, true));
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    if (!mwpa) {
      data["dob"] = data["dob"].replace(/ /g, "");
      const da = data.dob.split("/").reverse().join("-");
      let ada;
      if (data["adob"]) {
        data["adob"] = data["adob"].replace(/ /g, "");
        ada = data.adob.split("/").reverse().join("-");
      }
      let newData = {
        ...data,
        dob: da,
        adob: ada ? ada : false,
      };

      const sendData = nomineeDetailsSendData(newData);

      saveProposalForm(sendData, () => {
        dispatch(saveNomineeDetails(newData));
        dispatch(setPosition(position + 1));
        setEdit(false);
      });
    } else {
      if (shareSumError) {
        swal(`sum of Benefeciary shares should be 100`, {
          icon: "warning",
        });
      } else {
        data = {
          ...data,
          state: nomineePincodeMaster?.state,
          city: nomineePincodeMaster?.city,
          beneficiaryList: data.mwpa ? beneficiaryList : null,
        };
        const sendData = trusteeDetailsSendData(data);
        saveProposalForm(sendData, () => {
          dispatch(saveTrusteeDetails(data));
          dispatch(setPosition(position + 1));
          setEdit(false);
        });
      }
    }
  };

  useEffect(() => {
    if (nomineeDetails || trusteeDetails) {
      if (position === index) {
        setEdit(true);
      }
      if (position > index) {
        setEdit(false);
      }
    }
  }, [index, nomineeDetails, position, trusteeDetails]);
  useEffect(() => {
    if (dateRef?.current?.value) setAge(getAge(dateRef.current?.value));
  }, []);
  const TitleForm = (
    //	<div>
    <Card className="proposalTitleCard">
      <CardTitle EC>Nominee Details</CardTitle>
    </Card>
    //</div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  if (insuredOccupationDetails) {
    return (
      <>
        {(nomineeDetails && !edit) || (!edit && trusteeDetails) ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Nominee or Trustee Details
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(setPosition(index));
                  setEdit(true);
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
            </CardTitle>

            {mwpa ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee name
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.tname}
                  </span>
                </FormGroup>

                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Date Of Birth
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.tdob}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Email
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.temail}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Mobile Number
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.tmob}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Pan No
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.tpan}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Type
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.ttype}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Address
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.taddress}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Trustee Pincode
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {trusteeDetails.tpincode}
                  </span>
                </FormGroup>
                <CardTitle>Beneficiary Details</CardTitle>
                <FormGroup md hid></FormGroup>
                {beneficiaryList.map(
                  ({ share, relation, id, firstName, lastName, dob }) => (
                    <React.Fragment key={id}>
                      <FormGroup className="mobDown" formPage md>
                        <Label md editMode htmlFor="firstName">
                          First Name
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {firstName}
                        </span>
                      </FormGroup>
                      <FormGroup md formPage>
                        <Label md editMode htmlFor="lastName">
                          Last Name
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {lastName}
                        </span>
                      </FormGroup>
                      <FormGroup md formPage>
                        <Label md editMode htmlFor="share">
                          share
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {share}
                        </span>
                      </FormGroup>
                      <FormGroup md formPage>
                        <Label md editMode htmlFor="dob">
                          DOB
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {dob}
                        </span>
                      </FormGroup>
                      <FormGroup md formPage>
                        <Label md editMode htmlFor="relation">
                          Relation
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {relation}
                        </span>
                      </FormGroup>{" "}
                      <FormGroup md formPage hid></FormGroup>
                    </React.Fragment>
                  ),
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <FormGroup md formPage>
                  <Label md editMode>
                    Nominee First name
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {nomineeDetails.fname}
                  </span>
                </FormGroup>

                <FormGroup md formPage>
                  <Label md editMode>
                    Nominee Last name
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {nomineeDetails.lname}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Gender
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {nomineeDetails.nomGender === "M" ? "Male" : "Female"}
                  </span>
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Nominee DOB
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {nomineeDetails.nomDob}
                  </span>
                  {age && (
                    <AgeWrapper className="form-builder-age-wrapper">
                      {age} years
                    </AgeWrapper>
                  )}
                </FormGroup>
                <FormGroup md formPage>
                  <Label md editMode>
                    Relationship with Life Assured
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {nomineeDetails.relation}
                  </span>
                </FormGroup>
                <FormGroup md hid></FormGroup>
              </div>
            )}
            {!mwpa && age < 18 && nomineeDetails.afname && (
              <>
                <CardTitle EC>Appointee Details</CardTitle>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormGroup md formPage>
                    <Label md editMode>
                      Appointee First name
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {nomineeDetails.afname}
                    </span>
                  </FormGroup>

                  <FormGroup md formPage>
                    <Label md editMode>
                      Appointee Last name
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {nomineeDetails.alname}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode>
                      Gender
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {nomineeDetails.agender === "M" ? "Male" : "Female"}
                    </span>
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode>
                      Appointee DOB
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {nomineeDetails.adob}
                    </span>
                    {appointeeAge && (
                      <AgeWrapper className="form-builder-age-wrapper">
                        {appointeeAge} years
                      </AgeWrapper>
                    )}
                  </FormGroup>
                  <FormGroup md formPage>
                    <Label md editMode>
                      Relationship with Life Assured
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {nomineeDetails.arelation}
                    </span>
                  </FormGroup>
                </div>
              </>
            )}
          </Card>
        ) : (
          edit && (
            <Card proposal>
              {mwpaQ && (
                <CardTitle eligibilityTitle className="cardTitleProposal">
                  Nominee or Trustee Details
                </CardTitle>
              )}
              <Form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                {mwpaQ && (
                  <FormGroup xlg formPage>
                    <span className="mr-4">
                      I would like to take this policy under MWPA
                    </span>
                    <span>NO</span>
                    <span>
                      <ToggleButton
                        checked={mwpa}
                        //     id="mwpa"
                        //		ref={register({ name: "mwpa" })}
                        onChange={e => {
                          setMwpa(e);
                          setValue("mwpa", e);
                          trigger();
                        }}
                      />
                    </span>
                    <span>YES</span>
                  </FormGroup>
                )}

                {mwpa && (
                  <>
                    {" "}
                    <CardTitle>Trustee Details</CardTitle>
                    <FormGroup md hid></FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="tname"
                        error={errors.tname && "danger"}
                        defaultValue={trusteeDetails.tname}
                        onKeyDown={noCopyAndPaste}
                        name="tname"
                        placeholder=" "
                        ref={register}
                        style={{ textTransform: "capitalize" }}
                        maxLength="20"
                        onInput={noNum}
                      />
                      <Label md htmlFor="tname">
                        Trustee Name
                      </Label>
                      {errors.tname && (
                        <p className="error">{errors.tname.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="tdob"
                        name="tdob"
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        defaultValue={trusteeDetails.tdob}
                        ref={tRef}
                        error={errors.tdob && "danger"}
                        onChange={thandleChange}
                        maxLength="10"
                      />
                      <Label md htmlFor="tdob">
                        Trustee DOB
                      </Label>
                      {trusteeAge && (
                        <AgeWrapper className="form-builder-age-wrapper">
                          {trusteeAge} years
                        </AgeWrapper>
                      )}
                      {errors.tdob && (
                        <p className="error">{errors.tdob.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="email"
                        id="temail"
                        error={errors.temail && "danger"}
                        defaultValue={trusteeDetails.temail}
                        name="temail"
                        onKeyDown={noCopyAndPaste}
                        placeholder=" "
                        ref={register}
                        maxLength="100"
                      />
                      <Label md htmlFor="temail">
                        Trustee Email
                      </Label>
                      {errors.temail && (
                        <p className="error">{errors.temail.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="tel"
                        id="tmob"
                        error={errors.tmob && "danger"}
                        defaultValue={trusteeDetails.tmob}
                        name="tmob"
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        ref={register}
                        style={{ textTransform: "capitalize" }}
                        maxLength="10"
                        onInput={numOnly}
                      />
                      <Label md htmlFor="tmob">
                        Trustee Phone
                      </Label>
                      {errors.tmob && (
                        <p className="error">{errors.tmob.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="tpan"
                        error={errors.tpan && "danger"}
                        defaultValue={trusteeDetails.tpan}
                        onInput={noSpclChars}
                        onChange={upperCase}
                        onKeyDown={noCopyAndPaste}
                        name="tpan"
                        style={{
                          textTransform: "uppercase",
                        }}
                        placeholder=" "
                        ref={register}
                        maxLength="10"
                      />
                      <Label md htmlFor="tpan">
                        Trustee PAN
                      </Label>
                      {errors.tpan && (
                        <p className="error">{errors.tpan.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Trustee Type"
                          propDropDown
                          items={trusteeType}
                          selectedItem={trusteeDetails.ttype}
                          onChange={e => {
                            setValue("ttype", e);
                            trigger("ttype");
                          }}
                        />
                      </CustomDropDownFont>
                      {errors.ttype && (
                        <p className="error">{errors.ttype.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="taddress"
                        error={errors.taddress && "danger"}
                        defaultValue={trusteeDetails.taddress}
                        name="taddress"
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        ref={register}
                        maxLength="25"
                      />
                      <Label md htmlFor="taddress">
                        Trustee Address
                      </Label>
                      {errors.taddress && (
                        <p className="error">{errors.taddress.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="tel"
                        id="tpincode"
                        name="tpincode"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={trusteeDetails.tpincode}
                        error={errors.tpincode && "danger"}
                        placeholder=" "
                        ref={register}
                        onChange={e => setPinCode(e.target.value)}
                        onInput={numOnly}
                        maxLength="6"
                      />
                      <Label md htmlFor="tpincode">
                        Pincode
                      </Label>
                      {nomineePincodeMaster ? (
                        <span className="region-ec">
                          <img src={location} alt="location" />
                          {nomineePincodeMaster.city},
                          {nomineePincodeMaster.state}
                        </span>
                      ) : null}
                      {errors.tpincode && (
                        <p className="error">{errors.tpincode.message}</p>
                      )}
                    </FormGroup>
                    <CardTitle>Beneficiary Details</CardTitle>
                    <FormGroup md hid></FormGroup>
                    <div className="beneficiary-details">
                      <div className="beneficiary-list">
                        {beneficiaryList.map(
                          ({
                            share,
                            relation,
                            id,
                            firstName,
                            lastName,
                            dob,
                          }) => {
                            return (
                              <FormGroup
                                xlg
                                style={{ minHeight: "90px" }}
                                key={id}
                                OtherInsurance
                                BenificiaryDetails
                              >
                                <Benificiary
                                  share={share}
                                  relation={relation}
                                  fName={firstName}
                                  lname={lastName}
                                  dob={dob}
                                  key={id}
                                  id={id}
                                  onError={setDisable}
                                  sumShare={setShareSum}
                                />
                              </FormGroup>
                            );
                          },
                        )}

                        {beneficiaryList.length < 6 && (
                          <div
                            className="add-policy-btnb"
                            onClick={() => {
                              dispatch(
                                addBeneficiaryDetails({
                                  id: Math.floor(Math.random() * 10000),
                                  share: "",
                                  firstName: "",
                                  lastName: "",
                                  dob: "",
                                  relation: "",
                                }),
                              );
                            }}
                            id="otherInsurance"
                          >
                            Add Another Beneficiary
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {!mwpa && (
                  <>
                    <CardTitle>Nominee Details</CardTitle>
                    <FormGroup md hid />
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="fname"
                        error={errors.fname && "danger"}
                        defaultValue={nomineeDetails.fname}
                        name="fname"
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        ref={register}
                        style={{ textTransform: "capitalize" }}
                        maxLength="20"
                        onInput={noNum}
                      />
                      <Label md htmlFor="fname">
                        Nominee First name
                      </Label>
                      {errors.fname && (
                        <p className="error">{errors.fname.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="lname"
                        name="lname"
                        error={errors.lname && "danger"}
                        defaultValue={nomineeDetails.lname}
                        placeholder=" "
                        ref={register}
                        onKeyDown={noCopyAndPaste}
                        style={{ textTransform: "capitalize" }}
                        maxLength="20"
                        onInput={noNum}
                      />
                      <Label md htmlFor="lname">
                        Nominee Last name
                      </Label>
                      {errors.lname && (
                        <p className="error">{errors.lname.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Gender"
                          propDropDown
                          onChange={gender => {
                            setValue(
                              "nomGender",
                              gender === "Male" ? "M" : "F",
                            );
                            setNomGen(gender);
                            trigger("nomGender");
                          }}
                          name="nomGender"
                          items={["Male", "Female"]}
                          currentSelected={nomGen}
                          ref={register}
                        />
                      </CustomDropDownFont>
                      {errors.nomGender && (
                        <p className="error">{errors.nomGender.message}</p>
                      )}
                    </FormGroup>
                    {/* <FormGroup md formPage>
                      <RadioContainer md proposer mob>
                        <Radio
                          type="radio"
                          id="Male"
                          name="nomGender"
                          defaultChecked={nomineeDetails.nomGender === "M"}
                          value="M"
                          onChange={(e) => setNomGen("M")}
                          ref={register}
                        />
                        <RadioLabel
                          sm
                          proposer
                          htmlFor="Male"
                          error={errors.nomGender && "danger"}
                          style={{ textAlign: "unset" }}
                        >
                          <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                            <RadioButton
                              className="radioBtnHover"
                              marginsm
                            ></RadioButton>
                            <span className="radiolabel__text radiolabel__formbuilder-text">
                              Male
                            </span>
                          </div>
                        </RadioLabel>
                        <Radio
                          type="radio"
                          id="Female"
                          value="F"
                          defaultChecked={nomineeDetails.nomGender === "F"}
                          ref={register}
                          onChange={(e) => setNomGen("F")}
                          name="nomGender"
                        />
                        <RadioLabel
                          sm
                          proposer
                          htmlFor="Female"
                          error={errors.nomGender && "danger"}
                          style={{ textAlign: "unset" }}
                        >
                          <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                            <RadioButton
                              className="radioBtnHover"
                              marginsm
                            ></RadioButton>
                            <span className="radiolabel__text radiolabel__formbuilder-text">
                              Female
                            </span>
                          </div>
                        </RadioLabel>
                        
                      </RadioContainer>
                    </FormGroup> */}
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        dobSpace
                        type="text"
                        id="nomidob"
                        name="dob"
                        placeholder=" "
                        defaultValue={nomineeDetails.nomDob}
                        ref={attachRef}
                        error={errors.dob && "danger"}
                        onChange={handleChange}
                        onKeyDown={noCopyAndPaste}
                      />
                      <Label md htmlFor="nomidob">
                        DOB
                      </Label>
                      {age && (
                        <AgeWrapper className="form-builder-age-wrapper">
                          {age} years
                        </AgeWrapper>
                      )}
                      {errors.dob && (
                        <p className="error">{errors.dob.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Nominee Relation"
                          propDropDown
                          searchable={true}
                          items={
                            nomGen === "Male"
                              ? relationshipsMale
                              : relationshipsFemale
                          }
                          currentSelected={nomRel}
                          onChange={e => {
                            setValue("relation", e);
                            trigger("relation");
                            setNomRel(e);
                          }}
                        />
                      </CustomDropDownFont>
                      {errors.relation && (
                        <p className="error">{errors.relation.message}</p>
                      )}
                    </FormGroup>
                  </>
                )}
                {!mwpa && age < 18 && age ? (
                  <div>
                    <CardTitle>Appointee Details</CardTitle>
                    <Form
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormGroup proposalCard eligibilityPage>
                        <TextInputElg
                          proposal
                          type="text"
                          id="afname"
                          error={errors.afname && "danger"}
                          defaultValue={nomineeDetails.afname}
                          name="afname"
                          placeholder=" "
                          ref={register}
                          style={{ textTransform: "capitalize" }}
                          maxLength="20"
                          onInput={noNum}
                          onKeyDown={noCopyAndPaste}
                        />
                        <Label md htmlFor="afname">
                          Appointee First name
                        </Label>
                        {errors.afname && (
                          <p className="error">{errors.afname.message}</p>
                        )}
                      </FormGroup>
                      <FormGroup proposalCard eligibilityPage>
                        <TextInputElg
                          proposal
                          type="text"
                          id="alname"
                          name="alname"
                          defaultValue={nomineeDetails.alname}
                          error={errors.alname && "danger"}
                          placeholder=" "
                          ref={register}
                          style={{ textTransform: "capitalize" }}
                          maxLength="20"
                          onInput={noNum}
                          onKeyDown={noCopyAndPaste}
                        />
                        <Label md htmlFor="alname">
                          Appointee Last name
                        </Label>
                        {errors.alname && (
                          <p className="error">{errors.alname.message}</p>
                        )}
                      </FormGroup>
                      <FormGroup proposalCard eligibilityPage>
                        <CustomDropDownFont rdr>
                          <CustomDropDown
                            dropDownTitle="Gender"
                            propDropDown
                            items={["Male", "Female"]}
                            currentSelected={appGen}
                            onChange={gender => {
                              setValue(
                                "agender",
                                gender === "Male" ? "M" : "F",
                              );
                              setAppGen(gender);
                            }}
                            ref={register}
                          />
                        </CustomDropDownFont>
                      </FormGroup>
                      {/* <FormGroup md formPage>
                        <RadioContainer md proposer mob>
                          <Radio
                            type="radio"
                            id="aMale"
                            name="agender"
                            defaultChecked={nomineeDetails.agender === "M"}
                            value="M"
                            onChange={(e) => setAppGen("M")}
                            ref={register}
                          />
                          <RadioLabel
                            sm
                            proposer
                            htmlFor="aMale"
                            error={errors.agender && "danger"}
                            style={{ textAlign: "unset" }}
                          >
                            <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                              <RadioButton
                                className="radioBtnHover"
                                marginsm
                              ></RadioButton>
                              <span className="radiolabel__text radiolabel__formbuilder-text">
                                Male
                              </span>
                            </div>
                          </RadioLabel>
                          <Radio
                            type="radio"
                            id="aFemale"
                            value="F"
                            onChange={(e) => setAppGen("F")}
                            defaultChecked={nomineeDetails.agender === "F"}
                            ref={register}
                            name="agender"
                          />
                          <RadioLabel
                            sm
                            proposer
                            htmlFor="aFemale"
                            error={errors.agender && "danger"}
                            style={{ textAlign: "unset" }}
                          >
                            <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                              <RadioButton
                                className="radioBtnHover"
                                marginsm
                              ></RadioButton>
                              <span className="radiolabel__text radiolabel__formbuilder-text">
                                Female
                              </span>
                            </div>
                          </RadioLabel>
                          {errors.agender && (
                            <p className="error">{errors.agender.message}</p>
                          )}
                        </RadioContainer>
                      </FormGroup> */}
                      <FormGroup proposalCard eligibilityPage>
                        <TextInputElg
                          proposal
                          dobSpace
                          type="text"
                          id="appoidob"
                          name="adob"
                          placeholder=" "
                          defaultValue={
                            nomineeDetails.adob ? nomineeDetails.adob : ""
                          }
                          ref={aRef}
                          error={errors.adob && "danger"}
                          onChange={ahandleChange}
                          onKeyDown={noCopyAndPaste}
                        />
                        <Label md htmlFor="appoidob">
                          Appointee DOB
                        </Label>
                        {appointeeAge && (
                          <AgeWrapper className="form-builder-age-wrapper">
                            {appointeeAge} years
                          </AgeWrapper>
                        )}
                        {errors.adob && (
                          <p className="error">{errors.adob.message}</p>
                        )}
                      </FormGroup>
                      <FormGroup proposalCard eligibilityPage>
                        <CustomDropDownFont rdr>
                          <CustomDropDown
                            dropDownTitle="Appointee Relation"
                            propDropDown
                            searchable={true}
                            items={
                              appGen === "Male"
                                ? relationshipsMale
                                : relationshipsFemale
                            }
                            currentSelected={appRel}
                            onChange={e => {
                              setValue("arelation", e);
                              trigger();
                              setAppRel(e);
                            }}
                          />
                        </CustomDropDownFont>
                        {errors.arelation && (
                          <p className="error">{errors.arelation.message}</p>
                        )}
                      </FormGroup>
                      <FormGroup xlg formPage propSubWrap>
                        <button
                          //disabled={!isValid}
                          type="submit"
                          className={
                            !isValid
                              ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                              : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                          }
                        >
                          PROCEED TO IDENTIFICATION DETAILS
                        </button>
                      </FormGroup>
                    </Form>
                  </div>
                ) : (
                  <FormGroup xlg formPage propSubWrap>
                    <button
                      type="submit"
                      // disabled={disable || !isValid}
                      className={
                        !isValid || disable
                          ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                          : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                      }
                    >
                      PROCEED TO IDENTIFICATION DETAILS
                    </button>
                  </FormGroup>
                )}
              </Form>
            </Card>
          )
        )}
      </>
    );
  }
};

export default NomineeDetailsForm;
