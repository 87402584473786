import React from "react";

import { Download } from "../../SvgComp";

import "./compare-page-header.scss";

function ComparePageHeader() {
  return (
    <div className="compare-page-header">
      <div className="subFilterBtn">
        <button className="comparisonBtn">
          <span className="comparisonBtnTxt">Download Comparision</span>
          {Download}
        </button>
      </div>
      <div className="subFilterBtn">
        <button className="comparisonOffBtn">View All Plans</button>
      </div>
    </div>
  );
}

export default ComparePageHeader;
