import { createSlice } from "@reduxjs/toolkit";
import {
  getEducationDetails,
  getOrganizationType,
  getProposerOccupation,
} from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const insuredOccupationForm = createSlice({
  name: "insuredOccupationForm",
  initialState: {
    insuredOccupationDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.insuredOccupationDetails = action.payload;
    },
  },
});

export const { saveDetails } = insuredOccupationForm.actions;

export const insuredOccupationSendData = (data = {}) => ({
  occupation: getProposerOccupation(data.insOccupation),
  education: getEducationDetails(data.insEducation),
  organizationName: data.insOrgName,
  organizationType: getOrganizationType(data.insOrgType),
  organizationNameDesc: data.insOrgNameDesc,
  income: data.insAnnualIncome,
  industryType: data.insIndType,
  // industryDesc:
  //   data.selectedPlanDetails === "Self" ? data.indDesc : data.insIndDesc,
  industryDesc: data.insIndDesc,
  industryQuestion: data.insEngagedIn ? "Yes" : "No",
  proposalJourneyStatus: iciciProposalForms.insuredOccupationDetails,
});

export const saveinsuredOccupationDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(insuredOccupationSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default insuredOccupationForm.reducer;
