import moment from "moment";

export const createArrayOfNumbers = (start, end, interval = 1, append) => {
  const numbersArray = [];
  for (let i = start; i <= end; i += interval)
    numbersArray.push(`${i} ${append && append}`);
  return numbersArray;
};
export const getMaxLumpsumCover = (max, factor) => {
  let amount = max / 1.3876;
  if (factor === "Yes") amount = max / 1.54796;
  if (amount >= 10000000) {
    return Math.floor(amount / 1000000) * 1000000;
  }
  if (amount >= 100000) {
    return Math.floor(amount / 100000) * 100000;
  }
};
export const numberToWords = price => {
  var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 === dgt
        ? ""
        : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
        (0 !== nxtDgt || dgt > 0 ? " " + denom : "")
      );
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((price += ""), isNaN(parseInt(price)))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch (
        ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                    ? " and"
                    : "")
              : "",
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                    ? " and"
                    : " Crore")
              : "",
          );
      }
    str = words.reverse().join("");
  } else str = "";
  return str;
};

export const getCoverUptoForAge = age => {
  if (age >= 18 && age <= 24) return "60 years";
  if (age >= 25 && age <= 55) return "65 years";
  if (age >= 56 && age <= 60) return "70 years";
  if (age >= 61 && age <= 70) return `${age + 10} years`;
};

export const getMaxDisplayCoverMedical = (age, annualIncome) => {
  if (
    age >= 18 &&
    age <= 35 &&
    Number(annualIncome) >= 500000 &&
    Number(annualIncome) < 700000
  )
    return 10000000;

  if (
    age >= 36 &&
    age <= 40 &&
    Number(annualIncome) >= 500000 &&
    Number(annualIncome) < 700000
  )
    return 7500000;

  if (age >= 18 && age <= 35 && Number(annualIncome) >= 700000) return 20000000;

  if (age >= 36 && age <= 40 && Number(annualIncome) >= 700000) return 15000000;
};

const getDefault = (age, annualIncome, pref) => {
  if (annualIncome == 250000) {
    if (age >= 18 && age <= 20) return 5000000;
    if (age >= 21 && age <= 45) return 5000000;
    if (age >= 46 && age <= 65) return 5000000;
  }
  if (annualIncome == 300000) {
    if (age >= 18 && age <= 20) return 5000000;
    if (age >= 21 && age <= 45) return 5000000;
    if (age >= 46 && age <= 65) return 2500000;
  }
  if (annualIncome == 400000) {
    if (age >= 18 && age <= 20) return 5000000;
    if (age >= 21 && age <= 45) return 5000000;
    if (age >= 46 && age <= 55) return 4000000;
    if (age >= 56 && age <= 65) return 2500000;
  }
  // if (pref === "test" && annualIncome) {
  //   if (age >= 18 && age <= 29) return annualIncome * 25;

  //   if (age >= 30 && age <= 35) return annualIncome * 25;

  //   if (age >= 36 && age <= 39) return annualIncome * 20;

  //   if (age === 40) return annualIncome * 20;

  //   if (age >= 41 && age <= 45) return annualIncome * 20;

  //   if (age >= 46 && age <= 50) return annualIncome * 15;

  //   if (age >= 51 && age <= 55) return annualIncome * 15;

  //   if (age >= 56 && age <= 60) return annualIncome * 10;

  //   if (age >= 61 && age <= 65) return annualIncome * 5;
  // }
  if (annualIncome) {
    if (age >= 18 && age <= 29) return Math.min(annualIncome * 25, 10000000);

    if (age >= 30 && age <= 35) return Math.min(annualIncome * 25, 10000000);

    if (age >= 36 && age <= 39) return Math.min(annualIncome * 20, 10000000);

    if (age === 40) return Math.min(annualIncome * 20, 10000000);

    if (age >= 41 && age <= 45) return Math.min(annualIncome * 15, 10000000);

    if (age >= 46 && age <= 50) return Math.min(annualIncome * 12, 10000000);

    if (age >= 51 && age <= 55) return Math.min(annualIncome * 10, 10000000);

    if (age >= 56 && age <= 60) return Math.min(annualIncome * 5, 10000000);

    if (age >= 61 && age <= 65) return Math.min(annualIncome * 5, 10000000);
  }
};
export const getDefaultCoverAmount = (age, annualIncome, pref) => {
  // if (annualIncome < 500000) {
  //   if (age >= 18 && age <= 29) return annualIncome * 15;

  //   if (age >= 30 && age <= 35) return annualIncome * 15;

  //   if (age >= 36 && age <= 39) return annualIncome * 12;

  //   if (age === 40) return annualIncome * 12;

  //   if (age >= 41 && age <= 45) return annualIncome * 10;

  //   if (age >= 46 && age <= 50) return annualIncome * 10;

  //   if (age >= 51 && age <= 55) return annualIncome * 10;

  //   if (age >= 56 && age <= 60) return annualIncome * 5;

  //   if (age >= 61 && age <= 65) return annualIncome * 5;
  // } else

  return Math.floor(getDefault(age, annualIncome, pref) / 500000) * 500000;
  // if (preferance === "Medical" && annualIncome) {
  //   if (
  //     age >= 18 &&
  //     age <= 35 &&
  //     annualIncome >= 500000 &&
  //     annualIncome < 700000
  //   )
  //     return 10000000;

  //   if (
  //     age >= 36 &&
  //     age <= 40 &&
  //     annualIncome >= 500000 &&
  //     annualIncome < 700000
  //   )
  //     return 7500000;

  //   if (age >= 18 && age <= 35 && annualIncome >= 700000) return 20000000;

  //   if (age >= 36 && age <= 40 && annualIncome >= 700000) return 15000000;
  // }
};

export const amountInNumbers = coverAmount => {
  let newCoverAmount = coverAmount
    ?.replace("₹", "")
    ?.replace(/\,/g, "")
    ?.replace(/\s/g, "")
    ?.replace("Lakh", "00000")
    ?.replace("s", "")
    ?.replace("Crore", "0000000");
  if (coverAmount) {
    if (coverAmount.includes("₹ 1 Crore"))
      newCoverAmount = coverAmount[2] + "0000000";
    else if (coverAmount.includes("100 Crore"))
      newCoverAmount = coverAmount.slice(2, 5) + "0000000";
    else if (coverAmount.includes("Crore") && coverAmount.includes("1."))
      newCoverAmount =
        parseInt(parseFloat(coverAmount.substr(1)) * 100) + "00000";
    else if (coverAmount.includes(".") && coverAmount.includes("Crore"))
      newCoverAmount =
        parseInt(parseFloat(coverAmount.slice(2, 7)) * 100) + "00000";
    else if (coverAmount.includes(".") && coverAmount.includes("Lakhs"))
      newCoverAmount = coverAmount.slice(2, 4) * 100 + "000";
    else if (coverAmount.includes("Lakhs"))
      newCoverAmount = coverAmount.slice(2, 4) + "00000";
    else if (coverAmount.includes("Crore"))
      newCoverAmount = parseInt(parseFloat(coverAmount.substr(1)) * 10000000);
  }
  return newCoverAmount;
};

export const numberToDigitWord = (
  number,
  multiple = 50000,
  roundTo = false,
) => {
  let rounded = Math.round(number / multiple) * multiple;
  const value = String(rounded);
  if (value === "1000000000") return `₹ 100 Crore`;
  if (value.length > 7) {
    let temp = rounded / Math.pow(10, 7);
    if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
    return temp === 1 ? `₹ ${temp} Crore` : `₹ ${temp} Crore`;
  }
  if (value.length > 5) {
    let temp = rounded / Math.pow(10, 5);
    if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
    return `₹ ${temp} Lakh`;
  }
  if (value.length > 3) {
    let temp = rounded / Math.pow(10, 3);
    if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
    return `₹ ${temp} Thousand`;
  }
};

const getBand = dob => {
  const age = moment().diff(moment(dob, "DD/MM/YYYY"), "year");
  if (age >= 18 && age <= 29) return "BAND1";
  if (age >= 30 && age <= 35) return "BAND2";
  if (age >= 36 && age <= 39) return "BAND3";
  if (age >= 40 && age <= 40) return "BAND4";
  if (age >= 41 && age <= 45) return "BAND5";
  if (age >= 46 && age <= 50) return "BAND6";
  if (age >= 51 && age <= 55) return "BAND7";
  if (age >= 56 && age <= 60) return "BAND8";
  if (age >= 61 && age <= 65) return "BAND9";
};
export const getMultiplier = (dob, annualIncome) => {
  const band = getBand(dob);

  switch (band) {
    case "BAND1":
    case "BAND2":
      return 25;
    case "BAND3":
    case "BAND4":
      return 20;
    case "BAND5":
      return 15;
    case "BAND6":
      return 12;
    case "BAND7":
      return 10;
    case "BAND8":
    case "BAND9":
      return 5;
    default:
      return 0;
  }
};
export const getMultiplierMonthly = (dob, annualIncome) => {
  const band = getBand(dob);
  let maxSumAssured = {};
  const income = Number(annualIncome);
  if (income >= 500000) {
    switch (band) {
      case "BAND1":
        maxSumAssured = {
          icici: Math.min(25 * income, 1000000000),
          max_life: Math.min(25 * income, 1000000000),
          kotak_life: Math.min(25 * income, 1000000000),
          bandhan_life: Math.min(25 * income, 1000000000),
        };
        break;
      case "BAND2":
        maxSumAssured = {
          icici: Math.min(25 * income, 1000000000),
          max_life: Math.min(25 * income, 1000000000),
          kotak_life: Math.min(25 * income, 1000000000),
          bandhan_life: Math.min(25 * income, 1000000000),
        };
        break;
      case "BAND3":
        maxSumAssured = {
          icici: Math.min(20 * income, 1000000000),
          max_life: Math.min(20 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(20 * income, 1000000000),
        };
        break;
      case "BAND4":
        maxSumAssured = {
          icici: Math.min(20 * income, 1000000000),
          max_life: Math.min(20 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(15 * income, 1000000000),
        };
        break;

      case "BAND5":
        maxSumAssured = {
          icici: Math.min(15 * income, 1000000000),
          max_life: Math.min(15 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(15 * income, 1000000000),
        };
        break;
      case "BAND6":
        maxSumAssured = {
          icici: Math.min(12 * income, 1000000000),
          max_life: Math.min(15 * income, 1000000000),
          kotak_life: Math.min(15 * income, 1000000000),
          bandhan_life: Math.min(12 * income, 1000000000),
        };
        break;

      case "BAND7":
        maxSumAssured = {
          icici: Math.min(10 * income, 1000000000),
          max_life: Math.min(10 * income, 1000000000),
          kotak_life: Math.min(15 * income, 1000000000),
          bandhan_life: Math.min(10 * income, 1000000000),
        };
        break;

      case "BAND8":
        maxSumAssured = {
          icici: Math.min(5 * income, 1000000000),
          max_life: Math.min(10 * income, 1000000000),
          kotak_life: Math.min(10 * income, 1000000000),
          bandhan_life: Math.min(5 * income, 1000000000),
        };
        break;

      case "BAND9":
        maxSumAssured = {
          icici: Math.min(5 * income, 1000000000),
          max_life: 0,
          kotak_life: Math.min(5 * income, 1000000000),
          bandhan_life: Math.min(5 * income, 1000000000),
        };
        break;

      default:
        maxSumAssured = {};
    }
  } else if (income < 500000) {
    switch (band) {
      case "BAND1":
        maxSumAssured = {
          icici: Math.min(25 * income, 1000000000),
          max_life: Math.min(15 * income, 1000000000),
          kotak_life: Math.min(25 * income, 1000000000),
          bandhan_life: Math.min(25 * income, 1000000000),
        };
        break;
      case "BAND2":
        maxSumAssured = {
          icici: Math.min(25 * income, 1000000000),
          max_life: Math.min(15 * income, 1000000000),
          kotak_life: Math.min(25 * income, 1000000000),
          bandhan_life: Math.min(25 * income, 1000000000),
        };
        break;
      case "BAND3":
        maxSumAssured = {
          icici: Math.min(20 * income, 1000000000),
          max_life: Math.min(12 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(20 * income, 1000000000),
        };
        break;
      case "BAND4":
        maxSumAssured = {
          icici: Math.min(20 * income, 1000000000),
          max_life: Math.min(12 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(15 * income, 1000000000),
        };
        break;

      case "BAND5":
        maxSumAssured = {
          icici: Math.min(15 * income, 1000000000),
          max_life: Math.min(10 * income, 1000000000),
          kotak_life: Math.min(20 * income, 1000000000),
          bandhan_life: Math.min(15 * income, 1000000000),
        };
        break;
      case "BAND6":
        maxSumAssured = {
          icici: Math.min(12 * income, 1000000000),
          max_life: Math.min(10 * income, 1000000000),
          kotak_life: Math.min(15 * income, 1000000000),
          bandhan_life: Math.min(12 * income, 1000000000),
        };
        break;

      case "BAND7":
        maxSumAssured = {
          icici: Math.min(10 * income, 1000000000),
          max_life: 0,
          kotak_life: Math.min(15 * income, 1000000000),
          bandhan_life: Math.min(10 * income, 1000000000),
        };
        break;

      case "BAND8":
        maxSumAssured = {
          icici: Math.min(5 * income, 1000000000),
          max_life: 0,
          kotak_life: Math.min(10 * income, 1000000000),
          bandhan_life: Math.min(5 * income, 1000000000),
        };
        break;

      case "BAND9":
        maxSumAssured = {
          icici: Math.min(5 * income, 1000000000),
          max_life: 0,
          kotak_life: Math.min(5 * income, 1000000000),
          bandhan_life: Math.min(5 * income, 1000000000),
        };
        break;

      default:
        maxSumAssured = {};
    }
  }
  return maxSumAssured;
};

/**
 * @param {number} coverUpto coverUpto to be validated
 * @param {number} age age
 */
export const validateCoverUpto = (coverUpto, age) => {
  [coverUpto, age].forEach(arg => typeCheckNumber(arg));

  if (coverUpto < age + 5) return false;
  return true;
};

/**
 * @param {number} coverAmount coverAmount to be validated
 * @param {number} age age
 * @param {number} annualIncome annualIncome
 */
export const validateCoverAmount = (
  coverAmount,
  age,
  annualIncome,
  preference,
) => {
  [coverAmount, age, annualIncome].forEach(arg => typeCheckNumber(arg));

  const maxCover = getDefaultCoverAmount(age, annualIncome, "test");

  if (coverAmount > maxCover) return false;
  return true;
};

export function typeCheckNumber(value, error = `${value} is not a number`) {
  if (typeof value !== "number") throw new TypeError(error);
}

//const value = String(number);
// if (value === "1000000000") return `₹ 100 Crores`;
// if (Number(value.slice(Number(String(multiple).length * -1))) <= multiple) {
//     if (value.length === 6) return `₹ ${value.slice(0, 1)} Lakhs`;
//     if (value.length == 7) return `₹ ${value.slice(0, 2)} Lakhs`;
//     else if (value.length === 8) {
//         if (value.startsWith("1"))
//             return `₹ ${value.slice(0, 1)}${value.slice(1, 3) !== "00" ? "." + value.slice(1, 3) : ""
//                 } Crore`;
//         else
//             return `₹ ${value.slice(0, 1)}${value.slice(1, 3) !== "00" ? "." + value.slice(1, 3) : ""
//                 } Crores`;
//     } else if (value.length == 9)
//         return `₹ ${value.slice(0, 2)}${value.slice(2, 4) !== "00" ? "." + value.slice(2, 4) : ""
//             } Crores`;
// } else if (Number(value.slice(Number(String(multiple).length * -1))) > multiple) {
//     if (value.length === 6) return `₹ ${value.slice(0, 1)}.${Number(value.slice(1, 3)) + 1} Lakhs`;
//     if (value.slice(0, 2) == "99" && value.length == 7)
//         return `₹ 1 Crore`;
//     else if (value.length == 7)
//         return `₹ ${Number(value.slice(0, 2)) + 1} Lakhs`;
//     else if (value.length == 8)
//         if (value.startsWith("1"))
//             return `₹ ${value.slice(0, 1)}${value.slice(1, 3).startsWith("0")
//                 ? ".0" + (Number(value.slice(1, 3)) + 1)
//                 : "." + (Number(value.slice(1, 3)) + 1)
//                 } Crore`;
//         else
//             return `₹ ${Number(value.slice(1, 3)) + 1 == 100
//                 ? Number(value.slice(0, 1) + 1)
//                 : value.slice(0, 1)
//                 }${value.slice(1, 3).startsWith("0")
//                     ? ".0" + (Number(value.slice(1, 3)) + 1)
//                     : "." + String(Number(value.slice(1, 3)) + 1).length == 3
//                         ? Number(value.slice(1, 3)).slice(-2)
//                         : Number(value.slice(1, 3)) + 1 == 100
//                             ? ""
//                             : "." + (Number(value.slice(1, 3)) + 1)
//                 } Crores`;
//     else if (value.length == 9)
//         return `₹ ${Number(value.slice(2, 4)) + 1 == 100
//             ? Number(value.slice(0, 2)) + 1
//             : value.slice(0, 2)
//             }${"." + String(Number(value.slice(2, 4)) + 1).length == 3
//                 ? Number(value.slice(2, 4)).slice(-2)
//                 : value.slice(2, 4).startsWith("0")
//                     ? ".0" + (Number(value.slice(2, 4)) + 1)
//                     : Number(value.slice(2, 4)) + 1 == 100
//                         ? ""
//                         : "." + (Number(value.slice(2, 4)) + 1)
//             } Crores`;
// }

// return number;
