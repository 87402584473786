import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import arrow from "../../../assets/img/arrow_selectBox.png";

const MobileKnowYOurPLanContent = ({ title, content, id, open, onClick }) => {
  const arrowRef = useRef();
  const [arrowClassName, setArrowClassName] = useState(
    open ? "DownArrow" : "arrow",
  );
  const stringToHTML = str => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return { __html: DOMPurify.sanitize(doc.body.innerHTML) };
  };

  const handleClick = () => {
    setArrowClassName(arrowClassName === "arrow" ? "DownArrow" : "arrow");
    onClick();
  };

  useEffect(() => {
    setArrowClassName(open ? "DownArrow" : "arrow");
    if (open) {
      document.querySelector(`#${id}`).classList.add("show");
    }
  }, [open, id]);

  return (
    <div id="knowyourplan__content" className="knowyourplan__content collapse">
      <button
        data-toggle="collapse"
        data-target={`#${id}`}
        className="knowyourplan__subheader"
        type="button"
        onClick={handleClick}
      >
        {/* <span >
          <img src={arrow} className={arrowClassName} ref={arrowRef} />
        </span> */}
        <span className={arrowClassName}></span>
        {title}
      </button>
      <div id={`${id}`} className="knowyourplan__tab collapse">
        {content?.map((data, idx) => {
          return (
            <div key={idx}>
              <div className="tab__Header">{data.plan_title}</div>
              <div
                className="tab__answer"
                dangerouslySetInnerHTML={stringToHTML(data.plan_desc)}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileKnowYOurPLanContent;
