import httpClient from "../../api/httpClient";

export const getRidersPremium = data => {
  return httpClient("common/get_riders_premium", { method: "POST", data });
};

export const submitRiders = data =>
  httpClient("common/add_riders", { method: "POST", data });

export const updateQuotation = data =>
  httpClient("common/update_quotation", { method: "POST", data });

export const criticalIllness = data =>
  httpClient("common/critical_illness", { method: "POST", data });

export const seriousIllness = data =>
  httpClient("common/serious_illness", { method: "POST", data });

export const spouseChildPremium = data =>
  httpClient("common/get_spouse_child_premium", { method: "POST", data });

export const getQuotesBajaj = data =>
  httpClient(`common/get_bajaj_life_products`, { method: "POST", data });

export const getQuotesIcici = data =>
  httpClient(`common/get_icici_pru_products`, { method: "POST", data });
