import React from "react";

export const Edit = (
  <svg
    className="edit-icon"
    version="1.1"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    fill="#333"
  >
    <path d="M3,17.25v3.75h3.75l11.06,-11.06l-3.75,-3.75l-11.06,11.06Zm17.71,-10.21c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34c-0.39,-0.39 -1.02,-0.39 -1.41,0l-1.83,1.83l3.75,3.75l1.83,-1.83Z"></path>
    <path fill="none" d="M0,0h24v24h-24Z"></path>
  </svg>
);

export const envelope = (
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M20,4h-16c-1.1,0 -1.99,0.9 -1.99,2l-0.01,12c0,1.1 0.9,2 2,2h16c1.1,0 2,-0.9 2,-2v-12c0,-1.1 -0.9,-2 -2,-2Zm0,4l-8,5l-8,-5v-2l8,5l8,-5v2Z"
      fill="#333"
    ></path>
    <path fill="none" d="M0,0h24v24h-24Z"></path>
  </svg>
);
