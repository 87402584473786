import React from "react";
import "./card.scss";
import "styled-components/macro";
const Card = ({
  children,
  addCard,
  style,
  compulsory,
  disabled,
  rider,
  elg,
  className,
  proposal,
  popup,
}) => {
  return (
    <div
      style={{ ...style }}
      className={
        addCard
          ? rider
            ? `card-container__addCard card-containerRider ${className}`
            : `card-container__addCard ${className}`
          : rider
          ? `card-container card-containerRider ${className}`
          : elg
          ? `card-container card-containerElg ${className}`
          : `card-container ${className} ${proposal ? "proposalCard" : ""} `
      }
    >
      {compulsory && (
        <span className="compulsory__wrapper">
          <p className="compulsory">COMPULSORY ADD-ON</p>
        </span>
      )}
      <div
        css={`
          @media only screen and (min-width: 768px) and (max-width: 1177px) {
            padding: ${rider && " 24px 18px 24px 14px !important"};
          }
        `}
        className={
          rider
            ? "card-contentRider card-content"
            : elg
            ? "card-contentElg"
            : `card-content ${proposal ? "proposalCardContent" : ""} ${
                popup ? "popupCard" : ""
              }`
        }
        style={{ opacity: disabled && 0.65 }}
      >
        <div className={rider ? "card-body riderBody" : "card-body"}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
