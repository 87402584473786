import { createSlice } from "@reduxjs/toolkit";
import { iciciResidentStatusCode } from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const insuredFtcaForm = createSlice({
  name: "insuredFtcaForm",
  initialState: {
    insuredFtcaDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.insuredFtcaDetails = action.payload;
    },
  },
});

export const { saveDetails } = insuredFtcaForm.actions;

export const proposerFatcaSendData = (data = {}) => ({
  proposerCountryOfResidence: data?.nriCountryResidence,
  proposerCountryOfNationality: data?.nriNationality,
  proposerPassportNo: data?.passport,
  proposerPurposeStay: data?.purposeOfStay,
  proposerEmployerDetails: data?.employUniDetails,
  proposerDurationOfStayYear: data?.durationStayAbroad,
  proposerIntendedDurationOfStayYear: data?.intendedDurationStayAbroad,
  proposerTravelDetailsDesc: data?.nriDetails,
  proposerResidentStatus: iciciResidentStatusCode(data.residentStatus),
  proposerPanNo: data.pan,
  proposerDateOfArrivedInIndia: data?.arrivingDate,
  proposerDateOfLeavingInIndia: data?.leavingDate,
  proposerTaxResident: data.isTaxResident,
  proposerNationality: data.nationality,
  pDisclaimerFatca: data.agreeTerm ? "on" : "off",
  pBirthCountry: data.isBithCountry === "yes" ? "Y" : "N",
  proposerDurationOfStayMonth: data?.durationStayAbroadMonthsData,
  proposerIntendedDurationOfStayMonth:
    data?.intendeddurationStayAbroadMonthsData,
  proposerTravelDetails: data?.nriTravelDetailsData,
  proposerBankAccountHelds: data?.bankTypeData,
  nriTINIssuingCountry: data?.panIssuingCountry,
  pOtherCountry: data?.panIssuingCountry,
  pTinPanTwo: data.tin,
  otherCountry:
    data.panIssuingCountry2 === "Select" ? "" : data.panIssuingCountry2,
  pCountryOfBirth: data.countryOfBirth,
  pPlaceOfBirth: data.birthPlace,
  travelToNonacceptableCountry: data.travelNonAccCont ? "Y" : "N",
  proposalJourneyStatus: iciciProposalForms.proposerFatcaDetails,
});

export const saveinsuredFtcaDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(proposerFatcaSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default insuredFtcaForm.reducer;
