import styled from "styled-components";

export const HealthPolicy = styled.div`
  white-space: normal;
  .label {
    margin-left: 10px;
    white-space: normal;
  }
  .form-group--1 {
    white-space: normal;
  }
  .error {
    color: red;
  }
`;

export const Question = styled.div`
  white-space: normal;
  width: 100%;
  font-size: 16px;
  margin-left: ${props => {
    if (props.subSum) return "12px";
  }};
  margin-bottom: 20px;
`;

export const Summary = styled.div`
  width: 100%;
  margin-left: ${props => {
    if (props.subSum) return "12px";
  }};
  .label {
    color: #107591;
    font-size: 12px;
    font-family: Inter-Medium;
    & ol {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
  .p {
    white-space: normal;
    word-break: break-all;
    margin-left: 10px;
    font-size: 15px;
  }
  .phealth {
    white-space: normal;
    word-break: break-all;
    margin-left: 21px;
    font-size: 15px;
  }
  @media only screen and (max-width: 767px) {
    .label {
      font-size: 12px;
    }
    .p {
      font-size: 14px;
    }
  }
`;
