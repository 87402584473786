import {
  SalGov,
  SalNonGov,
  SelfEmpBus,
  SelfEmpProf,
  selfEmpRet,
} from "../data";

const defenceCrpf = data => [
  {
    id: "branchOfArmedForces",
    alignLeft: true,
    size: "md",
    type: "text",
    value: data.armedForceBranch || "",
    // label: "In what branch of armed forces are you serving?",
    label: "Branch of armed forces are you serving?",
    validations: {
      required: "This field is required",
    },
  },
  {
    id: "rank",
    alignLeft: true,
    size: "md",
    type: "text",
    label: "What is your Rank?",
    value: data.armedForceRank || "",
    validations: {
      required: "This field is required",
    },
  },
  {
    id: "currentLocationOfPosting",
    alignLeft: true,
    size: "md",
    type: "text",
    value: data.armedForceLocation || "",
    label: "Current location of posting?",
    validations: {
      required: "This field is required",
      capitalize: true,
      freeText: true,
    },
  },
  {
    id: "jobInvolvesCombactRole",
    alignLeft: true,
    size: "lg",
    type: "toggle",
    smallToggle: true,
    value: data.combactRoleFlag === "Y" ? "Yes" : "No",
    items: ["Yes", "No"],
    label: "Does your job involves Combact role?",
  },
  {
    id: "isPartOfBombDisposalSquad",
    alignLeft: true,
    size: "lg",
    type: "toggle",
    smallToggle: true,
    items: ["Yes", "No"],
    value: data.bombDisposalFlag === "Y" ? "Yes" : "No",
    label: "Are you a are part of Bomb disposal squad?",
  },
];

export const occupationDetailsData = data => {
  const ckycNumber =
    data.ckyc?.ckyc_response?.A51DownloadCkycResponse
      ?.DownloadFromCkycResponseDetails?.CkycDownloadResponseDetail
      ?.CKYCPersonalDetail?.CKYCNumber || data.ckyc_number;
  return [
    {
      id: "education",
      alignLeft: true,
      size: "md",
      type: "select",
      readOnly: true,
      value: data.education || data.educationText || "Select",
      label: "Education",
      validations: {
        required: "This field is required",
      },
    },
    {
      id: "occupation",
      alignLeft: true,
      size: "md",
      type: "select",
      value: data.occupation || data.occupationText || "Select",
      readOnly: true,
      label: "Occupation",
      validations: {
        required: "This field is required",
      },
    },
    {
      id: "pan",
      alignLeft: true,
      size: "md",
      type: "pan",
      value: data.pan,
      label: "PAN Number",
      verifyCKYC: data.isPnbPreference && data.age <= 55,
      message:
        data.ckyc?.status === "false"
          ? "Kindly proceed with your manual KYC process"
          : false,
      validations: {
        required: "PAN Number is required.",
        invalid: "Please enter a valid PAN number.",
        noSpecialCharacters: true,
        maxLength: 10,
        minLength: 10,
        uppercase: true,
        custom: [
          {
            pattern: /^([a-zA-Z]{3}[P]{1}[a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$/,
            message: "Please enter a valid PAN number.",
          },
        ],
      },
    },
    ckycNumber
      ? {
          id: "ckycNumber",
          type: "text",
          alignLeft: true,
          size: "md",
          value: ckycNumber,
          label: "CKYC Number",
          readOnly: true,
        }
      : null,
    {
      id: "idProof",
      alignLeft: true,
      size: "md",
      type: "select",
      readOnly: !!ckycNumber,
      searchable: true,
      value: ckycNumber ? "PAN Card" : data.idProofText || "Select",
      items: [
        "Aadhaar Card Standard",
        "BFL Standard",
        "Permanent Driving License",
        "Job card issued by NREGA duly signed by an officer of the State Government",
        "PAN Card",
        "Passport",
        "Voters Id",
      ],
      label: "ID proof",
      validations: {
        required: "Please Select ID proof",
      },
    },
    {
      id: "annualIncome",
      alignLeft: true,
      size: "md",
      type: "text",
      value: data.annualIncome || "",
      label: "Annual Income",
      readOnly: true,
      validations: {
        required: "Please enter annual income",
        maxLength: 9,
        custom: [
          {
            test(value) {
              const annualIncome = parseInt(data.annualIncome);
              let maxIncome = annualIncome + 100000;
              if (annualIncome >= 10000000) maxIncome = annualIncome + 2500000;
              const givenValue = parseInt(value);
              return givenValue >= annualIncome && givenValue < maxIncome;
            },
            message: "Please enter valid annual income",
          },
        ],
      },
    },
    {
      id: "incomeProof",
      alignLeft: true,
      size: "md",
      searchable: true,
      type: "select",
      items:
        data?.occupation || data?.occupationText === "Salaried - Government"
          ? SalGov
          : data?.occupation ||
            data?.occupationText === "Salaried - Non Goverment"
          ? SalNonGov
          : data?.occupation || data?.occupationText === "Professional"
          ? SelfEmpProf
          : data?.occupation || data?.occupationText === "Business"
          ? SelfEmpBus
          : data?.occupation || data?.occupationText === "Retired"
          ? selfEmpRet
          : [],
      label: "Income Proof",
      value: data.incomeProofText || "Select",
      message:
        data.isPnbPreference &&
        data.age <= 55 &&
        data.annualIncome >= 500000 &&
        data.occupation?.includes("Salaried") &&
        (data.education === "Graduate" || data.education === "Post Graduate") &&
        data.coverAmount <= 10000000
          ? "No Income Proof required for preferred employee"
          : false,
      validations: {
        required:
          data.isPnbPreference &&
          data.age <= 55 &&
          data.annualIncome >= 500000 &&
          data.occupation?.includes("Salaried") &&
          (data.education === "Graduate" ||
            data.education === "Post Graduate") &&
          data.coverAmount <= 10000000
            ? false
            : "Please Select Income Proof",
      },
    },
    {
      id: "ageProof",
      alignLeft: true,
      size: "md",
      label: "Age proof",
      searchable: true,
      type: "select",
      readOnly: !!ckycNumber,
      items: [
        "Aadhaar Card Standard",
        "Affidavit on Stamp paper",
        "Birth Certificate",
        "Birth Certificate And School Certificate",
        "BFL Standard",
        "Baptism Certificate",
        "Baptism Certificate And School Certificate",
        "Bonafide School Certificate",
        "Certified from Service register of Govt. and Quasi Govt Org",
        "Domicile Certificate",
        "Permenant Driving licence",
        "Service Record Or Employer Certificate",
        "E.S.I.S Card",
        "Hospital Discharge Card",
        "Higher Secondary Mark List Or certified extract from school",
        "Identity Cards Issued by Defence Department",
        "Pan Card",
        "Passport",
        "Ration Card",
        "School or college certificate from school or college",
        "School Leaving Certificate",
        "School Transfer certificate",
        "Life Insurance Policy from LIC or Private Insurance Company",
        "Marriage Certificate of Christians by Roman Catholic Church",
        "Voter's Id.",
      ],

      value: ckycNumber
        ? "Aadhaar Card Standard"
        : data.ageProofText || "Select",
      validations: {
        required: "Please Select Age proof",
      },
    },
    {
      id: "addressProof",
      alignLeft: true,
      size: "md",
      searchable: true,
      readOnly: !!ckycNumber,
      type: "select",
      label: "Address proof",
      items: [
        "Aadhaar Card Standard",
        "BFL Standard",
        "Permanent Driving License",
        "Passport",
        "Voters Id",
      ],
      value: ckycNumber
        ? "Aadhaar Card Standard"
        : data.addressProofText || "Select",
      validations: {
        required: "Please Select Address proof",
      },
    },
    {
      id: "industryType",
      alignLeft: true,
      size: "md",
      type: "select",
      searchable: true,
      label: "Industry Type",
      items: [
        "CRPF",
        "DEFENCE",
        "MERCHANT MARINE",
        "MINING",
        "OTHERS",
        "OIL & NATURAL GAS",
      ],

      value: data.isWipro ? "OTHERS" : data.industryTypeText || "Select",
      validations: {
        required: "Please Select Industry Type",
      },
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "CRPF":
            return [
              ...defenceCrpf(data).map(item => {
                return { ...item, id: `${item.id}1` };
              }),
            ];
          case "DEFENCE":
            return [
              ...defenceCrpf(data).map(item => {
                return { ...item, id: `${item.id}1` };
              }),
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "employersName",
      alignLeft: true,
      size: "md",
      type: "select",
      label: "Employer's Name",
      searchable: true,
      items: [
        "3M India Ltd",
        "ABB India Ltd",
        "ABG Shipyard Ltd",
        "ACC Ltd",
        "AIA Engineering Ltd",
        "APL Apollo Tubes Ltd",
        "Aarti Industries Ltd",
        "Aban Offshore Ltd",
        "Abbott India Ltd",
        "Adani Enterprises Ltd",
        "Adani Ports & Special Economic Zone Ltd",
        "Adani Power Ltd",
        "Adhunik Metaliks Ltd",
        "Aditya Birla Nuvo Ltd",
        "Advinus Therapeutics",
        "Aegis Logistics Ltd",
        "Akzo Nobel India Ltd",
        "Alembic Pharmaceuticals Ltd",
        "Allahabad Bank",
        "Allcargo Logistics Ltd",
        "Alok Industries Ltd",
        "Alstom India Ltd",
        "Alstom T India Ltd",
        "Amara Raja Batteries Ltd",
        "Ambuja Cements Ltd",
        "Amtek Auto Ltd",
        "Amtek India Ltd",
        "Andhra Bank",
        "Apar Industries Ltd",
        "Apollo Hospitals Enterprise Ltd",
        "Apollo Tyres Ltd",
        "Arvind Ltd",
        "Asahi India Glass Ltd",
        "Ashok Leyland Ltd",
        "Ashoka Buildcon Ltd",
        "Asian Paints Ltd",
        "Asian Star Company Ltd",
        "Associated Building Company",
        "Atul Ltd",
        "Aurobindo Pharma Ltd",
        "Axis Bank Ltd",
        "B L Kashyap & Sons Ltd",
        "BASF India Ltd",
        "BEML Ltd",
        "BGR Energy Systems Ltd",
        "BS Ltd",
        "Bajaj Auto Ltd",
        "Bajaj Electricals Ltd",
        "Bajaj Finance Ltd",
        "Bajaj Finserv Ltd",
        "Bajaj Hindustan Ltd",
        "Balkrishna Industries Ltd",
        "Ballarpur Industries Ltd",
        "Balmer Lawrie & Company Ltd",
        "Balrampur Chini Mills Ltd",
        "Bank of Baroda",
        "Bank of India",
        "Bank of Maharashtra",
        "Bata India Ltd",
        "Bayer Cropscience Ltd",
        "Berger Paints (India) Ltd",
        "Bharat Electronics Ltd",
        "Bharat Forge Ltd",
        "Bharat Heavy Electricals Ltd",
        "Bharat Petroleum Corporation Ltd",
        "Bharti Airtel Ltd",
        "Bharti Infratel Ltd",
        "Bhushan Steel Ltd",
        "Bilcare Ltd",
        "Binani Industries Ltd",
        "Biocon Ltd",
        "Birla Corporation Ltd",
        "Blue Dart Express Ltd",
        "Blue Star Ltd",
        "Bombay Burmah Trading Corporation Ltd",
        "Bombay Dyeing & Manufacturing Company",
        "Bombay Rayon Fashions Ltd",
        "Bosch Ltd",
        "Britannia Industries Ltd",
        "C Mahendra Exports Ltd",
        "CESC Ltd",
        "CMC",
        "CMC Ltd",
        "CORE Education & Technologies Ltd",
        "Cadila Healthcare Ltd",
        "Cairn India Ltd",
        "Canara Bank",
        "Capri Global Capital Ltd",
        "Carborundum Universal Ltd",
        "Casa Dcor",
        "Castrol India Ltd",
        "Ceat Ltd",
        "Central Bank of India",
        "Centrum Capital Ltd",
        "Century Enka Ltd",
        "Century Textiles & Industries Ltd",
        "Chambal Fertilisers & Chemicals Ltd",
        "Chennai Petroleum Corporation Ltd",
        "Cholamandalam Investment & Finance Co",
        "Cipla Ltd",
        "City Union Bank Ltd",
        "Coal India Ltd",
        "Colgate-Palmolive (India) Ltd",
        "Consolidated Construction Consortium",
        "Container Corporation of India Ltd",
        "Coromandel International Ltd",
        "Corporation Bank",
        "Cox & Kings Ltd",
        "Crompton Greaves Ltd",
        "Cummins India Ltd",
        "Cyient Ltd",
        "DB Corp Ltd",
        "DCM Shriram Ltd",
        "DLF Ltd",
        "Dabur India Ltd",
        "Dalmia Bharat Ltd",
        "Deepak Fertilisers & Petrochemicals Corp",
        "Deloitte",
        "Dena Bank",
        "Dewan Housing Finance Corporation Ltd",
        "Dhampur Sugar Mills Ltd",
        "Dhunseri Petrochem Ltd",
        "Diamond Power Infrastructure Ltd",
        "Dish TV India Ltd",
        "Disha Financial Counselling",
        "Divi's Laboratories Ltd",
        "Dr Reddy's Laboratories Ltd",
        "Drive India Enterprise Solutions",
        "EID Parry (India) Ltd",
        "EIH Ltd",
        "Edelweiss Financial Services Ltd",
        "Eicher Motors Ltd",
        "Elecon Engineering Company Ltd",
        "Electrosteel Castings Ltd",
        "Emami Ltd",
        "Emmsons International Ltd",
        "Engineers India Ltd",
        "Era Infra Engineering Ltd",
        "Escorts Ltd",
        "Essar Oil Ltd",
        "Essar Shipping Ltd",
        "Essel Propack Ltd",
        "Exide Industries Ltd",
        "Fag Bearings India Ltd",
        "Federal Bank Ltd",
        "Fertilisers and Chemicals Travancore",
        "Finolex Cables Ltd",
        "Finolex Industries Ltd",
        "Firstsource Solutions Ltd",
        "Forbes & Company Ltd",
        "Force Motors Ltd",
        "Fortis Healthcare Ltd",
        "Future Retail Ltd",
        "GAIL (India) Ltd",
        "GHCL Ltd",
        "GMR Infrastructure Ltd",
        "GTL Ltd",
        "GVK Power & Infrastructure Ltd",
        "Gammon India Ltd",
        "Garden Silk Mills Ltd",
        "Gayatri Projects Ltd",
        "Gitanjali Gems Ltd",
        "GlaxoSmithKline Consumer Healthcare Ltd",
        "GlaxoSmithKline Pharmaceuticals Ltd",
        "Glenmark Pharmaceuticals Ltd",
        "Glodyne Technoserve Ltd",
        "Godawari Power & Ispat Ltd",
        "Godfrey Philips India Ltd",
        "Godrej Consumer Products Ltd",
        "Godrej Industries Ltd",
        "Gokul Refoils & Solvent Ltd",
        "Goodyear India Ltd",
        "Graphite India Ltd",
        "Grasim Industries Ltd",
        "Great Eastern Shipping Company Ltd",
        "Greaves Cotton Ltd",
        "Greenply Industries Ltd",
        "Gujarat Alkalies & Chemicals Ltd",
        "Gujarat Ambuja Exports Ltd",
        "Gujarat Fluorochemicals Ltd",
        "Gujarat Gas Company Ltd",
        "Gujarat Industries Power Company Ltd",
        "Gujarat Mineral Development Corporation",
        "Gujarat NRE Coke Ltd",
        "Gujarat Narmada Valley Fertilizrs & Chem",
        "Gujarat State Fertilizer & Chemicals",
        "HBL Power Systems Ltd",
        "HCL Infosystems Ltd",
        "HCL Technologies Ltd",
        "HDFC Bank Ltd",
        "HEG Ltd",
        "HSIL Ltd",
        "HT Media Ltd",
        "Hanung Toys & Textiles Ltd",
        "Hatsun Agro Products Ltd",
        "Havells India Ltd",
        "Heritage Foods Ltd",
        "Hero MotoCorp Ltd",
        "Hexaware Technologies Ltd",
        "Himatsingka Seide Ltd",
        "Hindalco Industries Ltd",
        "Hinduja Global Solutions Ltd",
        "Hindustan Construction Company Ltd",
        "Hindustan Copper Ltd",
        "Hindustan Petroleum Corporation Ltd",
        "Hindustan Unilever Ltd",
        "Hindustan Zinc Ltd",
        "Hindusthan National Glass & Industries",
        "Honeywell Automation India Ltd",
        "Hooghly Met Coke and Power Company",
        "Housing Development Finance Corporation",
        "ICICI Bank",
        "ICICI Direct",
        "ICICI Foundation",
        "ICICI Home Finance Company",
        "ICICI Lombard General Insurance Company",
        "ICICI Prudential Asset Management Co",
        "ICICI Prudential Life Insurance Company",
        "ICICI Securities",
        "ICICI Securities Primary Dealership",
        "ICICI Venture",
        "IDBI Bank Ltd",
        "IDFC Ltd",
        "IFCI Ltd",
        "IIFL Holdings Ltd",
        "IL Engineering & Construction Company",
        "IL Transportation Networks Ltd",
        "ING Vysya Bank Ltd",
        "IRB Infrastructure Developers Ltd",
        "ISGEC Heavy Engineering Ltd",
        "ISMT Ltd",
        "ITC Ltd",
        "ITD Cementation India Ltd",
        "IVRCL Ltd",
        "Idea Cellular Ltd",
        "India Cements Ltd",
        "India Glycols Ltd",
        "Indiabulls Housing Finance Ltd",
        "Indian Bank",
        "Indian Hotels Company Ltd",
        "Indian Oil Corporation Ltd",
        "Indian Overseas Bank",
        "Indian Steel and Wire Products",
        "Indo Rama Synthetics (India) Ltd",
        "Indraprastha Gas Ltd",
        "IndusInd Bank Ltd",
        "Infiniti Retails",
        "Infosys Ltd",
        "Ipca Laboratories Ltd",
        "JAMIPOL",
        "JBF Industries Ltd",
        "JK Cement Ltd",
        "JK Lakshmi Cement Ltd",
        "JK Paper Ltd",
        "JK Tyre & Industries Ltd",
        "JMC Projects (India) Ltd",
        "JSW Energy Ltd",
        "JSW Steel Ltd",
        "JVL Agro Industries Ltd",
        "Jagran Prakashan Ltd",
        "Jaguar Land Rover",
        "Jai Balaji Industries Ltd",
        "Jain Irrigation Systems Ltd",
        "Jaiprakash Associates Ltd",
        "Jaiprakash Power Ventures Ltd",
        "Jammu & Kashmir Bank Ltd",
        "Jamshedour utilities & services corp.",
        "Jayant Agro Organics Ltd",
        "Jayaswal Neco Industries Ltd",
        "Jaypee Infratech Ltd",
        "Jet Airways (India) Ltd",
        "Jindal Poly Films Ltd",
        "Jindal Saw Ltd",
        "Jindal Stainless Ltd",
        "Jindal Steel & Power Ltd",
        "Jubilant Life Sciences Ltd",
        "Jyoti Structures Ltd",
        "K S Oils Ltd",
        "KEC International Ltd",
        "KEI Industries Ltd",
        "KPIT Technologies Ltd",
        "KPR Mill Ltd",
        "KRBL Ltd",
        "KSK Energy Ventures Ltd",
        "Kajaria Ceramics Ltd",
        "Kalpataru Power Transmissions Ltd",
        "Kama Holdings Ltd",
        "Kansai Nerolac Paints Ltd",
        "Karnataka Bank Ltd",
        "Karur Vysya Bank Ltd",
        "Kesoram Industries Ltd",
        "Kirloskar Brothers Investments Ltd",
        "Kirloskar Brothers Ltd",
        "Kirloskar Oil Engines Ltd",
        "Kotak Mahindra Bank Ltd",
        "Kwality Ltd",
        "L Finance Holdings Ltd",
        "LIC Housing Finance Ltd",
        "LT Foods Ltd",
        "Lakshmi Machine Works Ltd",
        "Lakshmi Vilas Bank Ltd",
        "Lanco Infratech Ltd",
        "Landmark",
        "Larsen & Toubro Ltd",
        "Lloyd Electric and Engineering Ltd",
        "Lupin Ltd",
        "MMTC Ltd",
        "MRF Ltd",
        "Madras Fertilizers Ltd",
        "Magma Fincorp Ltd",
        "Mahanagar Telephone Nigam Ltd",
        "Maharashtra Seamless Ltd",
        "Mahindra & Mahindra Financial Services",
        "Mahindra & Mahindra Ltd",
        "Mahindra CIE Automotive Ltd",
        "Mahindra Ugine Steel Company Ltd",
        "Man Industries (India) Ltd",
        "Manaksia Ltd",
        "Manappuram Finance Ltd",
        "Mangalore Chemicals & Fertilizers Ltd",
        "Mangalore Refinery And Petrochemicals",
        "Marico Ltd",
        "Maruti Suzuki India Ltd",
        "Max India Ltd",
        "McLeod Russel (India) Ltd",
        "McNally Bharat Engineering Company Ltd",
        "Mercator Ltd",
        "MindTree Ltd",
        "Monnet Ispat & Energy Ltd",
        "Moser Baer India Ltd",
        "Motherson Sumi Systems Ltd",
        "MphasiS Ltd",
        "Mukand Ltd",
        "Munjal Showa Ltd",
        "Muthoot Finance Ltd",
        "NCC Ltd",
        "NHPC Ltd",
        "NIIT Technologies Ltd",
        "NMDC Ltd",
        "NTPC Ltd",
        "Nahar Industrial Enterprises Ltd",
        "Nahar Spinning Mills Ltd",
        "Nakoda Ltd",
        "NatSteel Holdings",
        "National Aluminium Company Ltd",
        "National Buildings Construction Corp",
        "National Fertilizers Ltd",
        "National Steel and Agro Industries Ltd",
        "Nectar Lifesciences Ltd",
        "Nelco",
        "Nelito Systems",
        "Nestle India Ltd",
        "Network 18 Media & Investments Ltd",
        "Neyveli Lignite Corporation Ltd",
        "Nilkamal Ltd",
        "OCL India Ltd",
        "Oil And Natural Gas Corporation Ltd",
        "Oil India Ltd",
        "Omaxe Ltd",
        "Opto Circuits India Ltd",
        "Oracle Financial Services Software Ltd",
        "Orient Cement Ltd",
        "Oriental Bank of Commerce",
        "Others",
        "PC Jeweller Ltd",
        "PSL Ltd",
        "Patel Engineering Ltd",
        "Pearl Global Industries Ltd",
        "Petronet LNG Ltd",
        "Pfizer Ltd",
        "Phillips Carbon Black Ltd",
        "Pidilite Industries Ltd",
        "Pipavav Defence & Offshore Engg Company",
        "Piramal Enterprises Ltd",
        "Piramal Glass Ltd",
        "Plethico Pharmaceuticals Ltd",
        "Polaris Financial Technology Ltd",
        "Polyplex Corporation Ltd",
        "Power Finance Corporation Ltd",
        "Power Grid Corporation of India Ltd",
        "Powerlinks Transmission",
        "Prakash Industries Ltd",
        "Pratibha Industries Ltd",
        "Prestige Estates Projects Ltd",
        "Prism Cement Ltd",
        "Prithvi Information Solutions Ltd",
        "Procter & Gamble Hygiene & Healthcare",
        "Punj Lloyd Ltd",
        "Punjab & Sind Bank",
        "Punjab National Bank",
        "REI Agro Ltd",
        "RSWM Ltd",
        "Rain Industries Ltd",
        "Rallis India",
        "Ramky Infrastructure Ltd",
        "Ranbaxy Laboratories Ltd",
        "Rane Holdings Ltd",
        "Rashtriya Chemicals & Fertilizers Ltd",
        "Raymond Ltd",
        "Reliance Capital Ltd",
        "Reliance Communications Ltd",
        "Reliance Industries Ltd",
        "Reliance Infrastructure Ltd",
        "Reliance Power Ltd",
        "Religare Enterprises Ltd",
        "Responsive Industries Ltd",
        "Rico Auto Industries Ltd",
        "Rohit Ferro-Tech Ltd",
        "Rolta India Ltd",
        "Roots Corporation",
        "Ruchi Infrastructure Ltd",
        "Ruchi Soya Industries Ltd",
        "Rural Electrification Corporation Ltd",
        "S Kumars Nationwide Ltd",
        "SEL Manufacturing Company Ltd",
        "SJVN Ltd",
        "SKF India Ltd",
        "SREI Infrastructure Finance Ltd",
        "SRF Ltd",
        "SRS Ltd",
        "Sadbhav Engineering Ltd",
        "Sakthi Sugars Ltd",
        "Sangam (India) Ltd",
        "Sanofi India Ltd",
        "Sanwaria Agro Oils Ltd",
        "Savita Oil Technologies Ltd",
        "Sesa Sterlite Ltd",
        "Shipping Corporation of India Ltd",
        "Shoppers Stop Ltd",
        "Shree Cements Ltd",
        "Shree Ganesh Jewellery House (I) Ltd",
        "Shree Renuka Sugars Ltd",
        "Shrenuj & Company Ltd",
        "Shri Lakshmi Cotsyn Ltd",
        "Shriram City Union Finance Ltd",
        "Shriram EPC Ltd",
        "Shriram Transport Finance Company Ltd",
        "Siemens Ltd",
        "Simplex Infrastructures Ltd",
        "Sintex Industries Ltd",
        "Sobha Ltd",
        "South Indian Bank Ltd",
        "Southern Petrochemicals Industries Corp",
        "Spicejet Ltd",
        "State Bank Of Bikaner and Jaipur",
        "State Bank Of Mysore",
        "State Bank of India",
        "State Bank of Travancore",
        "Steel Authority of India (SAIL) Ltd",
        "Sterlite Technologies Ltd",
        "Sujana Metal Products Ltd",
        "Sujana Towers Ltd",
        "Sujana Universal Industries Ltd",
        "Sun Pharmaceutical Industries Ltd",
        "Sun TV Network Ltd",
        "Sundaram Clayton Ltd",
        "Sundaram Finance Ltd",
        "Sundram Fasteners Ltd",
        "Sunflag Iron & Steel Company Ltd",
        "Supreme Industries Ltd",
        "Supreme Infrastructure India Ltd",
        "Supreme Petrochem Ltd",
        "Surana Industries Ltd",
        "Surya Roshni Ltd",
        "Sutlej Textiles & Industries Ltd",
        "Suzlon Energy Ltd",
        "Syndicate Bank",
        "TAL Manufacturing Solutions",
        "TAS-AGT Systems",
        "TKM Global Logistics",
        "TM International Logistics",
        "TRF",
        "TRL Krosaki Refractories",
        "TV18 Broadcast Ltd",
        "TVS Motor Company Ltd",
        "TVS Srichakra Ltd",
        "Taj Air",
        "Tamil Nadu Newsprint And Papers Ltd",
        "Tara Jewels Ltd",
        "Tata AG",
        "Tata AIA Life Insurance",
        "Tata AIG General Insurance",
        "Tata Advanced Materials",
        "Tata Advanced Systems",
        "Tata Africa Holdings",
        "Tata Asset Management",
        "Tata AutoComp Systems",
        "Tata BlueScope Steel",
        "Tata Business Support Services",
        "Tata Capital",
        "Tata Ceramics",
        "Tata Chemicals",
        "Tata Chemicals Europe",
        "Tata Chemicals Magadi",
        "Tata Chemicals North America",
        "Tata Coffee Ltd",
        "Tata Communications",
        "Tata Consultancy Services",
        "Tata Consulting Engineers",
        "Tata Cummins",
        "Tata Daewoo Commercial Vehicle Company",
        "Tata Elxsi",
        "Tata Global Beverages",
        "Tata Hitachi Construction Machinery",
        "Tata Housing Development Company",
        "Tata Industrial Services",
        "Tata Industries",
        "Tata Interactive Systems",
        "Tata International",
        "Tata International AG",
        "Tata Investment Corporation",
        "Tata Limited",
        "Tata Metaliks",
        "Tata Motors",
        "Tata Motors European Technical Centre",
        "Tata NYK",
        "Tata Petrodyne",
        "Tata Pigments",
        "Tata Power Company",
        "Tata Power Delhi Distribution",
        "Tata Power Solar Systems",
        "Tata Power Trading",
        "Tata Projects",
        "Tata Quality Management Services",
        "Tata Realty and Infrastructure",
        "Tata Services",
        "Tata Sky",
        "Tata Sons",
        "Tata Sponge Iron",
        "Tata Steel",
        "Tata Steel Europe",
        "Tata Steel KZN",
        "Tata Steel Processing and Distribution",
        "Tata Steel Thailand",
        "Tata Strategic Management Group",
        "Tata Technologies",
        "Tata Teleservices",
        "Tata Teleservices (Maharashtra)",
        "Tatanet",
        "Tayo Rolls",
        "Tech Mahindra Ltd",
        "Tecpro Systems Ltd",
        "The Ramco Cements Ltd",
        "The Tinplate Company of India",
        "Thermax Ltd",
        "Time Technoplast Ltd",
        "Titan Company",
        "Titan Company Ltd",
        "Torrent Pharmaceuticals Ltd",
        "Torrent Power Ltd",
        "Transport Corporation of India Ltd",
        "Trent",
        "Trent Ltd",
        "Trident Ltd",
        "Triveni Engineering & Industries Ltd",
        "Tube Investments of India Ltd",
        "Tulip Telecom Ltd",
        "UCO Bank",
        "UPL Ltd",
        "Uflex Ltd",
        "UltraTech Cement Ltd",
        "Union Bank of India",
        "Unitech Ltd",
        "United Bank of India",
        "United Breweries Ltd",
        "United Spirits Ltd",
        "Unity Infraprojects Ltd",
        "Usha Martin Ltd",
        "Uttam Galva Steels Ltd",
        "Uttam Value Steels Ltd",
        "Va Tech Wabag Ltd",
        "Vakrangee Ltd",
        "Vardhman Textiles Ltd",
        "Videocon Industries Ltd",
        "Vijaya Bank",
        "Vikas WSP Ltd",
        "Vimal Oil & Foods Ltd",
        "Voltas",
        "Voltas Ltd",
        "Welspun Corp Ltd",
        "Welspun India Ltd",
        "Westland",
        "Wheels India Ltd",
        "Whirlpool of India Ltd",
        "Wipro Ltd",
        "Wockhardt Ltd",
        "YES Bank Ltd",
        "Ybrant Digital Ltd",
        "Zee Entertainment Enterprises Ltd",
        "Zensar Technologies Ltd",
        "Zuari Agro Chemicals Ltd",
        "Zylog Systems Ltd",
        "e-nxt Financials",
        "mjunction services",
      ],
      value: data.isWipro ? "Wipro Ltd" : data.employersName || "Select",
      validations: {
        required: "Please Select Employer's Name",
      },
    },
    {
      id: "natureofDuties",
      alignLeft: true,
      size: "md",
      type: "text",
      value: data.natureOfDuties || "",
      label: "Nature of Duties",
      validations: {
        required: "Nature of Duties is required",
        maxLength: 50,
        onlyAlphaSpace: true,
      },
    },
    {
      id: "employersAddress",
      alignLeft: true,
      size: "md",
      type: "text",
      value: data.employersAddress || "",
      label: "Employer's Address",
      validations: {
        required: "Employer's Address is required",
        maxLength: 100,
        freeText: true,
      },
    },
    {
      id: "haveEIA",
      alignLeft: true,
      size: "lg",
      smallToggle: true,
      type: "toggle",
      items: ["Yes", "No"],
      value: data.eiaFlag === "Y" ? "Yes" : "No",
      label: "Do you have E-Insurance Account(EIA)? ",
      addCorrespondingItemsOnChange: value => {
        if (value === "Yes")
          return [
            {
              id: "eiaInsuranceRepository",
              alignLeft: true,
              size: "md",
              type: "select",
              label: "EIA insurance repository",
              items: ["CAMS", "CDSL", "NSDL", "KARVY"],
              value: data.eiaRepository || "Select",
              searchable: true,
              validations: {
                required: "Please Select EIA insurance repository",
              },
            },
          ];
        return [];
      },
    },
  ].filter(formItem => !!formItem);
};
