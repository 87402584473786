import styled from "styled-components";

const primary = "#ececec";
const secondary = "#f2581b";
const primaryBlue = "#107591";
/* props are 
@primary , secondary for colors
@padding for padded
@lg , md for heights
*/
export const Button = styled.button`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid;
  padding: 10px 20px;
  font-family: "Inter-SemiBold";
  border: 0.5px solid $color-primary-dark;
  font-size: ${props => (props.proceedBtn ? "12px" : "18px")};
  font-weight: ${props => {
    if (props.lg || props.md) return 600;
    else return 400;
  }};
  white-space: nowrap;
  background-color: ${props => {
    if (props.primary) return primary;
    if (props.secondary) return secondary;
    if (props.primaryBlue) return primaryBlue;
  }};
  color: ${props => {
    if (props.primary) return "#111";
    if (props.secondary || props.primaryBlue) return "#fff";
  }};
  padding: ${props => {
    if (props.padding) return "10px 50px";
    if (props.proceed) return "0px 60px";
  }};
  height: ${props => {
    if (props.lg) return "60px";
    if (props.md) return "54px";
  }};
  width: ${props => {
    if (props.proceedBtn) return "100%";
    if (props.width) return "140px";
  }};
  margin-top: ${props => (props.mt ? "10px" : null)};
  margin-bottom: ${props => (props.mb ? "10px" : null)};

  width: ${props => {
    if (props.proceedBtn) return "100%";
    if (props.width) return "140px";
  }};
  align-self: ${props => props.center && "center"};
  &:hover {
    background-color: ${props => (props.animate ? " #ff4d07" : "")};
  }
  &:active,
  &:focus {
    outline: none;
    /* transform: translateY(-1px);*/
    /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);*/
  }
  & span {
    display: inline-block;
    margin-left: 5px;
    width: 30px;
    height: 20px;
    transition: all 0.6s;
    margin-right: 37px;
  }
  &:hover .animate {
    transform: translateX(10px);
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    padding: 10px 10px 20px 10px;
    width: 100%;
    padding: ${props => {
      if (props.padding) return "14px 0";
      else return " 10px 10px 20px 10px";
    }};
  }
`;

export const TransparentButton = styled.button`
  position: absolute;
  right: ${props => (props.rider ? "0px" : "10px")};
  top: ${props => (props.orange ? "139px" : "0")};
  cursor: pointer;
  background-color: transparent;
  font-family: "Inter-Medium";
  font-size: ${props => (props.orange ? "16px" : "12px")};
  color: ${props =>
    props.orange ? "#f2581b" : props.rider ? "#107591" : "#fff"};
  line-height: 20px;
  width: ${props => (props.orange ? "124px" : "185px")};
  height: 40px;
  font-weight: 600;
  border-radius: 4px;
  border: ${props => (props.rider ? "1px" : "1px")} solid
    ${props => (props.orange ? "#f2581b" : props.rider ? "#107591" : "#d2d3d4")};
  margin-right: ${props => (props.rider ? "0" : "24px")};
  margin-top: ${props => (props.rider ? "0" : "")};

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    right: 0px;
    top: 154px;
    margin-right: 53px;
    height: 31px;
    width: 109px;
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    right: 0px;
    top: 100px;
    font-size: 14px;
    margin-right: 0px;
    height: 25px;
    width: 100px;
    border: none;
  }
`;
