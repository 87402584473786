import React from "react";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
// import Form4 from "./Form4";
import "./greeting.scss";
import ScrollToTop from "../../components/Scroll/ScrollToTop";
// import FormAffinity from "./FormAffinity";
import Landing from "./Landing";

const GreetingPage = ({ push }) => {
  return (
    <MemoryRouter>
      <ScrollToTop>
        <Switch>
          {/* <Route
            path='/Form4'
            component={() => {
              return <FormAffinity push={push}></FormAffinity>;
            }} */}
          {/* /> */}
          {/* <Route
            path='/Form4'
            component={() => {
              return <Form4 push={push}></Form4>;
            }}
          /> */}
          <Route
            path="/form3"
            component={() => {
              return <Form3 push={push} />;
            }}
          />
          <Route path="/form2" component={Form2} />
          <Route path="/form1" component={Form1} />
          <Route exact path="/" component={Landing} />
        </Switch>
      </ScrollToTop>
    </MemoryRouter>
  );
};

export default GreetingPage;
