import React, { useState, useEffect } from "react";
import Card from "../../../../components/Card/Card";
import { yupResolver } from "@hookform/resolvers";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import CustomDropDown from "../../../../components/CutomDropDown/CustomDropDown";
import {
  TextInput,
  FormGroup,
  Form,
  Label,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import { saveIdDetails } from "./IdentificationForm.slice";
import { idFormSchema } from "../../../../utils/validationSchema";
import {
  getAddressProof,
  getAgeProof,
  getEiaRepo,
  getIdentificationDetails,
  getIncomeProof,
  getItProof,
  noCopyAndPaste,
  numOnly,
  numOnlyInput,
  numOnlyWithShifDisable,
  upperCase,
} from "./../../../../utils/inputUtils";
import { ToggleTile } from "../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
const IdentificationForm = ({ position, setPosition, index }) => {
  const [eia, setEia] = useState(false);

  const { eligibilityDetails } = useSelector(state => state.eligibilityCard);

  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);
  const { nomineeDetails } = useSelector(state => state.nomineeForm);
  const { idDetails } = useSelector(state => state.idForm);
  const [InsRepoList, setInsRepoList] = useState(idDetails.insRepo || false);
  const ageProofList = [
    "Select Age Proof",
    "Defence I Card",
    "Driving License(Current)",
    "Passport(Current)",
    "Employers Certificate",
    "Goverment Employee ID",
    "Government Registered document or ID Proof",
    "Municipal Record",
    "PAN card",
    "School/College Record",
  ];
  const addressProofList = [
    "Select Address Proof",
    "Masked Aadhaar Card Copy",
    "Voters ID Card",
    "Driving License (Current)",
    "Passport (Current)",
  ];
  const incomeProofList = [
    "Select Income Proof",
    "None",
    "Income Tax Returns (Last 3 Years)",
    "Bank Cash Flow Stmt,Pass Book (Last 6 Months)",
    "Salary Slip Signed by authorized signatory of Employer (Last 3 Months)",
    "Land Records",
    "Chartered Accounts Certificate",
    "Audited Firm Accounts",
    "Audited Company Accounts",
    "Agricultural Income Certificate",
    "Employers Certificate",
    "Form 16",
  ];
  const idProofList = [
    "Select ID proof",
    "Masked Aadhaar Card Copy",
    "Driving License (Current)",
    "Job Card Issued by NREGA",
    "Passport (Current)",
    // "Pan Card",
    "Voters ID Card",
  ];
  const PreferredInsurance = [
    "Select Insurance repository",
    "NSDL Database Management Limited",
    "Central Insurance Repository Limited (CDSL Group)",
    "Karvy Insurance Repository Limited",
    "CAMS Repository Services Ltd",
  ];
  const insRepoList = ["Select IT Proof", "PAN", "FORM 60"];
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    unregister,
    trigger,
    formState,
  } = useForm({
    defaultValues: {
      ageProof: "Select",
      addressProof: "Select",
      incomeProof: "Select",
      EIA: false,
      idProof: "Select",
      insRepo: "Select",
    },
    mode: "onChange",
    resolver: yupResolver(idFormSchema),
  });
  const { isValid } = formState;
  useEffect(() => {
    register({ name: "ageProof" });
    register({ name: "addressProof" });
    register({ name: "incomeProof" });
    register({ name: "EIA" });
    register({ name: "idProof" });
    register({ name: "insRepo" });
    if (!eia) {
      register({ name: "preferredInsurance" });
    }
  }, [eia, register]);

  useEffect(() => {
    if (eia) unregister(["preferredInsurance"]);
  }, [eia, unregister]);

  const [aadharNumber, setAadharNumber] = useState(0);
  const [aadharNumberError, setAadharNumberError] = useState(false);

  useEffect(() => {
    if (aadharNumber && aadharNumber.length < 12) {
      setAadharNumberError("please provide valid aadhar number");
    } else {
      setAadharNumberError(false);
    }
  }, [aadharNumber]);

  useEffect(() => {
    if (idDetails) reset(idDetails);
  }, [reset, idDetails]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    const sendData = {
      ageProof: getAgeProof(data.ageProof),
      addressProof: getAddressProof(data.addressProof),
      incomeProof: getIncomeProof(data.incomeProof),
      isEiaOpted: data.EIA ? "Yes" : "No",
      idProof: getIdentificationDetails(data.idProof),
      itProof: getItProof(data.insRepo),
      proposerAadharNo: data.aadhar ? data.aadhar : "",
      proposerPanNo: data.panNo,
      eiaDetails: getEiaRepo(data.preferredInsurance),
      eiaAccNo: data.eiaNumber || "",
      proposalJourneyStatus: iciciProposalForms.identificationDetails,
    };
    saveProposalForm(sendData, () => {
      dispatch(saveIdDetails(data));
      setEdit(false);
      dispatch(setPosition(position + 1));
    });
  };
  useEffect(() => {
    if (idDetails) {
      if (position === index) {
        setEdit(true);
      }
      if (position > index) {
        setEdit(false);
      }
    }
  }, [position]);

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Identification Details</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  if (!edit && idDetails)
    return (
      <>
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Identification Details
            <span
              style={{
                float: "right",
                fontSize: "14px",
                color: "#107591",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setPosition(index));
                setEdit(true);
              }}
            >
              <img src={editIcon} alt="editIcon" />
              &nbsp;EDIT
            </span>
          </CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <FormGroup md formPage>
              <Label md editMode>
                IT Proof
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.insRepo}
              </span>
            </FormGroup>
            {idDetails.aadhar && (
              <FormGroup md formPage>
                <Label md editMode>
                  Aadhar
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {idDetails.aadhar}
                </span>
              </FormGroup>
            )}

            <FormGroup md formPage>
              <Label md editMode>
                Age Proof
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.ageProof}
              </span>
            </FormGroup>
            <FormGroup md formPage>
              <Label md editMode>
                Address Proof
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.addressProof}
              </span>
            </FormGroup>
            <FormGroup md formPage>
              <Label md editMode>
                Income Proof
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.incomeProof}
              </span>
            </FormGroup>
            <FormGroup md formPage>
              <Label md editMode>
                Id Proof
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.idProof}
              </span>
            </FormGroup>
            <FormGroup md formPage>
              <Label md editMode>
                EIA
              </Label>
              <span
                className="data"
                style={{ top: "32px", left: "17px", position: "relative" }}
              >
                {idDetails.EIA ? "YES" : "NO"}
              </span>
            </FormGroup>
            {idDetails.panNo && (
              <FormGroup md formPage>
                <Label md editMode>
                  PAN number
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {idDetails.panNo}
                </span>
              </FormGroup>
            )}
            {idDetails.preferredInsurance && (
              <FormGroup md formPage>
                <Label md editMode>
                  Insurance Repository
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {idDetails.preferredInsurance}
                </span>
              </FormGroup>
            )}
            {idDetails.eiaNumber && (
              <FormGroup md formPage>
                <Label md editMode>
                  EIA account number
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {idDetails.eiaNumber}
                </span>
              </FormGroup>
            )}
          </div>
        </Card>
      </>
    );
  else if (edit) {
    return (
      <>
        <Card proposal>
          <CardTitle eligibilityTitle className="cardTitleProposal">
            Identification Details
          </CardTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="IT Proof"
                  propDropDown
                  onChange={e => {
                    setValue("insRepo", e);
                    setInsRepoList(e);
                    trigger("insRepo");
                  }}
                  items={insRepoList}
                  selectedItem={idDetails?.insRepo}
                />
              </CustomDropDownFont>
              {errors.insRepo && (
                <p className="error">{errors.insRepo.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <TextInputElg
                proposal
                onKeyDown={noCopyAndPaste}
                ref={register}
                type="text"
                name="aadhar"
                defaultValue={idDetails.aadhar}
                id="aadhar"
                placeholder=" "
                maxLength="12"
                onInput={numOnlyInput}
                onChange={e => {
                  setAadharNumber(e.target.value);
                }}
              />

              <Label md htmlFor="aadhar">
                Aadhar Number (optional)
              </Label>
              {aadharNumberError && (
                <p className="errorG">{aadharNumberError}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Age Proof"
                  searchable={true}
                  propDropDown
                  items={ageProofList}
                  selectedItem={idDetails.ageProof}
                  onChange={e => {
                    setValue("ageProof", e);
                    trigger("ageProof");
                  }}
                />
              </CustomDropDownFont>
              {errors.ageProof && (
                <p className="error">{errors.ageProof.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Adress Proof"
                  propDropDown
                  searchable={true}
                  items={addressProofList}
                  selectedItem={idDetails?.addressProof}
                  onChange={e => {
                    setValue("addressProof", e);
                    trigger("addressProof");
                  }}
                />
              </CustomDropDownFont>
              {errors.addressProof && (
                <p className="error">{errors.addressProof.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Income Proof"
                  propDropDown
                  searchable={true}
                  items={incomeProofList}
                  selectedItem={idDetails?.incomeProof}
                  onChange={e => {
                    setValue("incomeProof", e);
                    trigger("incomeProof");
                  }}
                />
              </CustomDropDownFont>
              {errors.incomeProof && (
                <p className="error">{errors.incomeProof.message}</p>
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle="Identity Proof"
                  propDropDown
                  searchable={true}
                  items={idProofList}
                  selectedItem={idDetails?.idProof}
                  onChange={e => {
                    setValue("idProof", e);
                    trigger("idProof");
                  }}
                />
              </CustomDropDownFont>
              {errors.idProof && (
                <p className="error">{errors.idProof.message}</p>
              )}
            </FormGroup>
            {InsRepoList === "PAN" ? (
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  onKeyDown={noCopyAndPaste}
                  proposal
                  error={errors["panNo"] && "danger"}
                  type="text"
                  name="panNo"
                  defaultValue={eligibilityDetails.pan}
                  readOnly
                  id="panNo"
                  onChange={upperCase}
                  placeholder=" "
                  maxLength="10"
                  ref={register}
                />

                <Label md htmlFor="panNo">
                  Pan No
                </Label>
                {errors.panNo && (
                  <p className="error">{errors.panNo.message}</p>
                )}
              </FormGroup>
            ) : null}
            <FormGroup className="flexAlignment" xlg formPage>
              <div className="radioText">
                Do you have Electronic Insurance Account
              </div>
              <RadioComponent
                value={eia}
                name="eia"
                //   className="toggle--sb"
                // label="Do you have Electronic Insurance Account"
                updateChecked={e => {
                  setEia(e);
                  setValue("EIA", e);
                  trigger("EIA");
                }}
              />
            </FormGroup>
            {!eia ? (
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Preferred Insurance"
                    propDropDown
                    searchable={true}
                    items={PreferredInsurance}
                    selectedItem={idDetails?.preferredInsurance}
                    onChange={e => {
                      setValue("preferredInsurance", e);
                      trigger("preferrredInsurance");
                    }}
                  />
                </CustomDropDownFont>
                {errors.preferredInsurance && (
                  <p className="error">{errors.preferredInsurance.message}</p>
                )}
              </FormGroup>
            ) : (
              <FormGroup proposalCard eligibilityPagee>
                <TextInputElg
                  onKeyDown={noCopyAndPaste}
                  proposal
                  error={errors["eiaNumber"] && "danger"}
                  type="text"
                  name="eiaNumber"
                  defaultValue={idDetails?.eiaNumber}
                  id="eiaNumber"
                  placeholder=" "
                  maxLength="13"
                  ref={register}
                  onInput={numOnlyInput}
                />
                <Label md htmlFor="eiaNumber">
                  EIA account Number
                </Label>
                {errors.eiaNumber && (
                  <p className="error">{errors.eiaNumber.message}</p>
                )}
              </FormGroup>
            )}
            <FormGroup xlg formPage propSubWrap>
              <button
                type="submit"
                disabled={!isValid || aadharNumberError}
                className={
                  !isValid || aadharNumberError
                    ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                    : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                }
              >
                PROCEED TO MEDICAL QUESTION - SET 1
              </button>
            </FormGroup>
          </Form>
        </Card>
      </>
    );
  }
};

export default IdentificationForm;
