import React, { useState, useRef, useEffect } from "react";
import Popup from "./Popup";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
// import bajajLogo from "../../assets/img/logo8xbajaj.png";

import {
  dateUtil,
  // getAge,
  getAmount,
  getNumberfromString,
  getValue,
} from "../../utils/inputUtils";
import { setSelectedQuote } from "../../modules/QuotePage/quotePageSlice";
import { useSelector, useDispatch } from "react-redux";
import Card from "../Card/Card";
import CardTitle from "../Card/CardTitle/CardTitle";

import "./spousePopup.scss";

import {
  AgeWrapper,
  Form,
  FormGroup,
  TextInputElg,
  Label,
  CustomDropDownFont,
} from "../../components/Form/Form";

import CustomDropDown from "../CutomDropDown/CustomDropDown";
import { SpouseSchema } from "../../utils/validationSchema";
import { useCustomLocation } from "../../custom-hooks";
import {
  getSpousePremium,
  setRidersPremium,
  getQuotesRemove,
  // setBajajQuoteData,
  setSpouseVariant,
} from "../../modules/RiderPage/RiderPage.slice";

const SpouseDetails = ({
  show,
  onClose,
  selected,
  riderTerm,
  riderId,
  termInsuId,
  riderShortname,
  onClick,
  setPrice,
  setGst,
  setCoverAmount,
  costPer,
  additionalRiders,
  added,
  cover,
  setChildOrSpouse,
}) => {
  const { enquiryId } = useCustomLocation();
  const dispatch = useDispatch();
  const [age, setAge] = useState("");
  const boolean = ["Yes", "No"];
  const Gender = ["Male", "Female"];
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    trigger,
    formState,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(SpouseSchema),
  });

  const { spousePremium, spouseData, bajajQuoteData } = useSelector(
    state => state.rider,
  );
  const { isValid } = formState;
  const { customerDetails } = useSelector(state => state.greeting);
  const dateRef = useRef();
  const spouseForm = useRef();
  const workingStatus = watch("working");
  const coverAmount = watch("riderCoveramt");
  const [max, setMax] = useState(selected.cover_amount);
  const [coverError, setCoverError] = useState(false);

  //bajajSpouseVariant
  const [bajajSpouse, setBajajSpouse] = useState(false);

  useEffect(() => {
    if (selected?.company_alias === "pnb_metlife") {
      if (workingStatus === "Y") {
        setMax(selected.cover_amount);
      } else
        setMax(
          selected.cover_amount / 2 <= 4900000
            ? selected.cover_amount / 2
            : 4900000,
        );
    } else {
      if (workingStatus === "N") {
        if (selected.cover_amount < 10000000) {
          setCoverError(
            "For non-working spouse, life cover has to be minimum 1 cr for opting spouse add-on",
          );
        } else {
          setCoverError(false);
        }
        setMax(5000000);
      } else {
        setMax(selected.cover_amount);
        setCoverError(false);
      }
    }
  }, [workingStatus]);
  useEffect(() => {
    if (Number(getNumberfromString(coverAmount)) > Number(max)) {
      setCoverAmount(getValue(max));
      setValue("riderCoveramt", getValue(max));
    }
  }, [coverAmount, max]);
  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
    dateRef.current = ref;
  };
  useEffect(() => {
    if (
      spousePremium.totalPremium_SC &&
      selected?.company_alias === "pnb_metlife"
    ) {
      setPrice(spousePremium?.totalPremium_SC);
      setGst(spousePremium?.totalPremiumGst_SC);
      dispatch(setRidersPremium({ spouse: true }));
    }
  }, [spousePremium]);

  useEffect(() => {
    if (isValid && show) {
      spouseForm.current.click();
    }
  }, [
    isValid,
    spouseForm,
    show,
    watch("gender"),
    watch("working"),
    watch("riderCoveramt"),
    watch("smoker"),
    watch("education"),
    watch("dob"),
  ]);

  useEffect(() => {
    register({ name: "gender" });
    setValue("gender", customerDetails.gender === "M" ? "F" : "M");
    register({ name: "working" });
    setValue("working", spouseData.working || "Select");
    register({ name: "riderCoveramt" });
    setValue("riderCoveramt", cover);
    register({ name: "smoker" });
    setValue("smoker", spouseData.smoker || "Select");
    register({ name: "education" });
    setValue("education", spouseData.education || "Select");
  }, [register]);
  const handleDobChange = event => {
    setAge(dateUtil(event, dateRef.current, false, false, true));
  };
  const onSubmit = data => {
    if (!coverError) {
      dispatch(
        getSpousePremium({
          ...data,
          dob: data.dob.split(" / ").reverse().join("-"),
          riderCoveramt: getNumberfromString(data.riderCoveramt),
          riderTerm,
          riderId,
          traceId: enquiryId,
          termInsuId,
          riderShortname,
          type: "Spouse Benefit",
        }),
      );
    }
  };

  console.log(bajajQuoteData);
  const setQuote = async () => {
    setBajajSpouse(!bajajSpouse);

    if (!bajajSpouse) {
      if (spousePremium && spousePremium?.status) {
        const newData = { ...spousePremium, traceId: enquiryId };
        dispatch(setSelectedQuote(newData));
        dispatch(getQuotesRemove(enquiryId));
        setChildOrSpouse(true);
        dispatch(setSpouseVariant(true));
      }
    } else {
      //dispatch(getQuotesRemove(enquiryId));
      if (bajajQuoteData) {
        if (bajajQuoteData[0]?.premium_amount) {
          const data = { ...bajajQuoteData[0], traceId: enquiryId };
          dispatch(setSelectedQuote(data));
          setChildOrSpouse(false);
          //setSpouseVariant("false");
          dispatch(setSpouseVariant(false));
        }
      }
    }
  };

  const { displayNomineeGets } = useSelector(state => state.quoteFilter);
  const content = (
    <Main>
      <Header>
        <Heading>Add-on: Life Cover for Self & Spouse </Heading>
      </Header>
      <div className="contentWrapSpouse">
        <div className="spouseCard">
          <Card proposal popup>
            <CardTitle eligibilityTitle className="spouseTitile">
              Spouse Details
            </CardTitle>

            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Gender"
                    propDropDown
                    items={Gender}
                    currentSelected={
                      customerDetails.gender === "M" ? "Female" : "Male"
                    }
                    style={{ pointerEvents: "none" }}
                  />
                </CustomDropDownFont>
                {errors.gender && (
                  <p className="errorG">{errors.gender.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <TextInputElg
                  proposal
                  //		defaultValue={}
                  type="text"
                  id="dob"
                  name="dob"
                  dobSpace
                  defaultValue={
                    spouseData.dob
                      ? spouseData.dob.split("-").reverse().join(" / ")
                      : ""
                  }
                  ref={attachRef}
                  placeholder=" "
                  onInput={e => {}}
                  onChange={handleDobChange}
                />
                <Label md htmlFor="dob" eligibilityPage proposalInput>
                  Date of Birth (DD/MM/YYYY)
                </Label>
                {age && (
                  <AgeWrapper className="form-builder-age-wrapper ageWrapProposal">
                    {age} years
                  </AgeWrapper>
                )}
                {errors.dob && <p className="errorG">{errors.dob.message}</p>}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Smoker"
                    selectedItem={
                      spouseData.smoker
                        ? spouseData.smoker === "Y"
                          ? "Yes"
                          : "No"
                        : "Select"
                    }
                    propDropDown
                    onChange={value => {
                      setValue("smoker", value === "Yes" ? "Y" : "N");
                      trigger("smoker");
                    }}
                    items={boolean}
                  />
                </CustomDropDownFont>
                {errors.smoker && (
                  <p className="errorG">{errors.smoker.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Working status of Spouse"
                    selectedItem={
                      spouseData.working
                        ? spouseData.working === "Y"
                          ? "Yes"
                          : "No"
                        : "Select"
                    }
                    onChange={value => {
                      setValue("working", value === "Yes" ? "Y" : "N");
                      trigger("working");
                    }}
                    propDropDown
                    items={boolean}
                  />
                </CustomDropDownFont>
                {errors.working && (
                  <p className="errorG">{errors.working.message}</p>
                )}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Life cover amount (for spouse)"
                    currentSelected={cover}
                    propDropDown
                    items={
                      selected?.company_alias === "pnb_metlife"
                        ? getAmount(2500000, max, max, 500000).reverse()
                        : getAmount(
                            5000000,
                            selected.cover_amount,
                            selected.cover_amount,
                          ).reverse()
                    }
                    onChange={value => {
                      setValue("riderCoveramt", value);
                      setCoverAmount(value);
                      trigger("riderCoveramt");
                    }}
                  />
                </CustomDropDownFont>
                {errors.riderCoveramt && (
                  <p className="errorG">{errors.riderCoveramt.message}</p>
                )}
                {coverError && <p className="errorG">{coverError}</p>}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Education"
                    selectedItem={spouseData.education || "Select"}
                    propDropDown
                    onChange={value => {
                      setValue("education", value);
                      trigger("education");
                    }}
                    items={[
                      "Post Grad & Above",
                      "Graduate",
                      "Diploma",
                      "12th Pass",
                      "10th Pass",
                      "Below 10th Pass",
                      "Illiterate",
                    ]}
                  />
                </CustomDropDownFont>
                {errors.education && (
                  <p className="errorG">{errors.education.message}</p>
                )}
              </FormGroup>
              <button
                type="submit"
                ref={spouseForm}
                style={{ display: "none" }}
              ></button>
            </Form>
          </Card>
        </div>
        {selected?.company_alias === "pnb_metlife" ? (
          <div className="btnWrapSpouse">
            <ContentBtn added={added}>
              <div className="btnTxtSpouse" onClick={onClick}>
                {added
                  ? `REMOVE FROM PLAN ${costPer}`
                  : `ADD TO PLAN ${costPer}`}
              </div>
            </ContentBtn>
          </div>
        ) : (
          <div className="btnWrapSpouse">
            <ContentBtn
              bajajSpouse={bajajSpouse}
              disabled={!(spousePremium && spousePremium?.status)}
            >
              <div className="btnTxtSpouse" onClick={setQuote}>
                {bajajSpouse ? `REMOVE FROM PLAN ` : `ADD TO PLAN `}
              </div>
            </ContentBtn>
          </div>
        )}
      </div>
      <Footer>
        <img src={selected?.company_logo} alt="logo" className="bajajLogo" />
        <div className="footerData">
          <div>{selected.master_product_name}</div>
          <div>Cover: {displayNomineeGets}</div>
        </div>
        <FooterBtn>
          <div className="btnTxtSpouse footerBtnTxt">
            <div className="premPaySpouse">TOTAL PAYABLE PREMIUM</div>
            <div className="premAmountSpouse">
              ₹{" "}
              {(
                Number(selected.premium_amount) + Number(additionalRiders)
              ).toLocaleString("en-IN")}
            </div>
          </div>
        </FooterBtn>
      </Footer>
    </Main>
  );
  let width;
  let height;

  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    height = "100%";
  } else if (window.matchMedia("(max-width: 1177px)").matches) {
    width = "760px";
    height = "auto";
  } else {
    width = "810px";
    height = "auto";
  }

  return (
    <Popup
      lrgClose="true"
      transparentBackground={"false"}
      show={show}
      onClose={onClose}
      content={content}
      height={height}
      width={width}
      size="true"
      topFixed={"48px"}
    />
  );
};

export default SpouseDetails;

const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  height: 72px;
  width: 100%;
`;

const Heading = styled.div`
  font-family: "Inter-SemiBold";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
    padding: 36px 84px 6px 19px;
  }
`;

const Footer = styled.div`
  display: flex;
  height: 104px;
  padding: 27px 26px 27px 24px;
  box-shadow: 50px -50px 99px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #d6d6d6;
  background-color: #efefef;
  width: 100%;
  position: relative;
`;

const FooterBtn = styled.div`
  width: 194px;
  height: 50px;
  padding: 6px 18px;
  border-radius: 4px;
  background-color: #f2581b;
  position: absolute;
  right: 20px;
`;

const ContentBtn = styled.div`
  width: 356px;
  height: 60px;
  display: block;
  pointer-events: ${props => (props.disabled ? "none" : "")};
  border-radius: 4px;
  border: ${props =>
    props.added || props.bajajSpouse ? "solid 1px #d43d3d" : "none"};
  background-color: ${props =>
    props.added || props.bajajSpouse ? "#fff" : "#107591"};
  padding: 21px 0px 20px;
  text-align: center;
  & div {
    color: ${props => (props.added || props.bajajSpouse ? "#d43d3d" : "#fff")};
  }
`;
const Main = styled.div``;
