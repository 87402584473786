import React from "react";
import Criteria from "./Criteria/Criteria";

function CriteriaListItem({ matches, ...props }) {
  return (
    <li className="criteria-list-criteria" type="1">
      <Criteria matches={matches}>
        <span className="criteria-list-text">{props.children}</span>
      </Criteria>
    </li>
  );
}

export default CriteriaListItem;
