import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import useCustomLocation from "../../../custom-hooks/useCustomLocation";
import { numberToDigitWord } from "../../../modules/QuoteFilter/helper";

export default function Header({
  onBuyNowClicked,
  price,
  coverUpto,
  logoText,
  nomineeGets,
  history,
  ls,
  buynow,
  nextPage,
  logoUrl,
  childName,
  policyURL,
  quote,
}) {
  const { displayNomineeGets } = useSelector(({ quoteFilter }) => quoteFilter);

  const { path: currentPath } = useRouteMatch();

  const { enquiryId } = useCustomLocation();

  return (
    <div className="cardHeader">
      <div className="mobileHeader">Know more</div>
      <div className="planContent tablet-hide">
        <div className="benefitLogo">
          <div className="planImg">
            <img src={logoUrl} alt="logo" />
          </div>
          <div className="planName">
            <div className="know-more-header-companyName">{logoText}</div>
            <div className="childName">{childName}</div>
            <a
              href={policyURL}
              target="_blank"
              rel="noopener noreferrer"
              className="download__brochure"
            >
              Download Plan Brochure
            </a>
          </div>
        </div>
        <div className="coverTerm">
          <div className="coverTerm-coverUpto">COVER UPTO</div>
          <div className="planDescription">{coverUpto}</div>
        </div>

        <div className="planDetails">
          <div className="planDetails-nomineeGets">NOMINEE GETS</div>
          <div className="planDescription">
            {/* {quote.company_alias === "tata_aia_life" ? (
              <>
                <div>{getValue(quote.tata_basic_sum_assured)} + </div>
                <div>
                  {getValue(quote.tata_rider_sum_assured)} (CPR benefit amount)
                </div>
              </>
            ) : displayNomineeGets.includes("+") ? (
              displayNomineeGets
                .split("+")
                .map((item, idx) => (
                  <div>{`${ item }${ idx === 0 ? "+" : "" }`}</div>
                ))
              ) : */}
            {quote.company_alias === "bandhan_life"
              ? numberToDigitWord(parseInt(quote?.sumAssured)) + " (Lumpsum)"
              : displayNomineeGets}
          </div>
        </div>

        <div className={buynow ? "price" : "size-big"}>
          <div
            className={`know-more-price-price ${
              currentPath !== "/quotes" ? "know-more-price-price-only" : ""
            }`}
          >
            {price}
          </div>

          {currentPath === "/quotes" ? (
            <button
              className="buyNow"
              onClick={() => {
                onBuyNowClicked
                  ? onBuyNowClicked()
                  : history.push({
                      pathname: "/" + nextPage,
                      // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
                      search: `enquiryId=${enquiryId}`,
                    });
              }}
            >
              BUY NOW
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="detailsPopHeadWrap tablet-show">
        <div className="detailsPopLogo">
          <img src={logoUrl} alt="Plan logo" />
        </div>
        <div className="detailsPopPlanName">
          <div className="detailsPopPlanN">{logoText}</div>
          <div className="detailsPopPlanT">{childName}</div>
        </div>
        {currentPath === "/quotes" && (
          <div className="detailsPopPlanAmt">
            <div className="detailsPopPlanAmtN">{price}</div>
            <button
              className="detailsPopPlanAmtT"
              onClick={() => {
                onBuyNowClicked
                  ? onBuyNowClicked()
                  : history.push({
                      pathname: "/" + nextPage,
                      // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
                      search: `enquiryId=${enquiryId}`,
                    });
              }}
            >
              BUY NOW
            </button>
          </div>
        )}
        <div className="knowMoreHeaderTabChange tabletShow">
          <div className="detailsPopPlanCT">
            <div className="detailsPopPlanCTN">COVER UPTO</div>
            <div className="detailsPopPlanCTT">{coverUpto}</div>
          </div>
          <div className="detailsPopPlanNG">
            <div className="detailsPopPlanCTN">NOMINEE GETS</div>
            <div className="detailsPopPlanCTT">
              {" "}
              {/* {quote.company_alias === "tata_aia_life" ? (
                <>
                  {getValue(quote.tata_basic_sum_assured)} +
                  {getValue(quote.tata_rider_sum_assured)} (CPR benefit amount)
                </>
              ) : ( */}
              {quote.company_alias === "bandhan_life"
                ? numberToDigitWord(parseInt(quote?.sumAssured))
                : displayNomineeGets}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
