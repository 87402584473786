import React, { useEffect, useState } from "react";
import "styled-components/macro";
import "./loader.scss";
import { useSelector } from "react-redux";

const QuotesToRiderLoader = ({ contentKey, style = {}, showLoader }) => {
  //   const [showLoader, setShowloader] = useState(true);
  const [timeLapsed, setTimeLapsed] = useState(false);

  const { riderLoading } = useSelector(state => state.quote);
  console.log("riderLoading", riderLoading);

  //   useEffect(() => {
  //     let timer;
  //     if (showLoader) {
  //       timer = setTimeout(() => {
  //         setTimeLapsed(true);
  //       }, 3000);
  //     }
  //     return () => clearTimeout(timer);
  //   }, [showLoader]);

  const lines = [
    "Fetching best possible quotes for you, kindly wait!",
    "Life insurance is all about taking care of your loved one’s",
    "Enhance your covers by adding add-ons to your plan.",
    "With Elephant.in, You're Never Alone in this Journey.",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const storedIndex = parseInt(
      localStorage.getItem(`currentIndex_${contentKey}`),
      4,
    );

    if (!isNaN(storedIndex) && storedIndex < lines.length - 1) {
      setCurrentIndex(storedIndex);
    }

    const timer = setTimeout(() => {
      if (currentIndex < lines.length - 1) {
        setCurrentIndex(prevIndex => prevIndex + 1);
        localStorage.setItem(`currentIndex_${contentKey}`, currentIndex + 1);
      } else {
        setCurrentIndex(0); // Reset to the first line
        localStorage.setItem(`currentIndex_${contentKey}`, 0);
        // setIsLoading(true);
      }
    }, 3000); // Adjust the delay between lines as needed

    return () => clearTimeout(timer);
  }, [currentIndex, contentKey, lines]);

  const Ncss = `width: max-content;
  @media only screen and (max-width: 767px){
    width: 95%;
  }`;
  if (!showLoader) {
    return <></>;
  }
  return (
    <div className="loader" style={style}>
      {/* {message && <div>{message}</div>} */}
      <div className="spinner" css={Ncss}>
        <p
          css={`
            color: #333333;
            font-size: 18px;
            font-family: Inter-Regular;
            margin: 0 0 20px 0;
          `}
        >
          {lines[currentIndex]}
        </p>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <p
          css={`
            width: 100%;
          `}
        >
          {/* {children} */}
        </p>
      </div>
    </div>
  );
};

export default QuotesToRiderLoader;
