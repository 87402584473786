import { createSlice } from "@reduxjs/toolkit";
import { iciciProposalForms } from "../IcicProposal.slice";

const otherInsuranceForm = createSlice({
  name: "otherInsuranceForm",
  initialState: {
    otherInsuranceDetails: false,
    otherInsuranceList: [],
  },

  reducers: {
    setOtherInsuranceDetails: (state, action) => {
      state.otherInsuranceDetails = action.payload;
    },
    updateOtherInsuranceList: (state, action) => {
      state.otherInsuranceList.map(insurance => {
        if (insurance.id === action.payload.id) {
          insurance.insuranceCompany = action.payload.insuranceCompany;
          insurance.sumAssured = action.payload.sumAssured;
        }
      });
    },
    addOtherInsuranceList: (state, action) => {
      state.otherInsuranceList.push(action.payload);
    },
    removeFromOtherInsuranceList: (state, action) => {
      state.otherInsuranceList = state.otherInsuranceList.filter(
        insurance => insurance.id !== action.payload,
      );
    },
    setOtherInsuranceList: (state, action) => {
      state.otherInsuranceList = action.payload;
    },
  },
});

export const {
  addOtherInsuranceList,
  setOtherInsuranceDetails,
  setOtherInsuranceList,
  updateOtherInsuranceList,
  removeFromOtherInsuranceList,
} = otherInsuranceForm.actions;

// export const saveOtherInsuranceDetails = (data) => {
//   return async (dispatch) => {
//     try {
//       if (data) {
//         dispatch(saveDetails(data));
//       }
//     } catch (err) {
//       alert("Something went wrong");
//     }
//   };
// };

export default otherInsuranceForm.reducer;

export const otherInsuranceFormSendData = (data = {}) => ({
  holdPolicy: data.haveOtherInsurance ? "Y" : "N",
  otherInsuranceCompany: data.haveOtherInsurance
    ? data.otherInsuranceList
    : null,
  proposalJourneyStatus: iciciProposalForms.otherInsuranceDetails,
});
