import React, { useState, forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomDropDown } from "../..";

import {
  setCoverUptoValues,
  setPaymentTermOption,
  setPaymentTermTerm,
} from "../../../modules/QuoteFilter/quoteFilterSlice";

import { Close } from "../../../modules/QuoteFilter/components/SvgComp";

import "./payment-term-popup.scss";
import { ErrorMessage } from "../../../modules/GreetingPage/Form1.style";
import { createArrayOfNumbers } from "../../../modules/QuoteFilter/helper";
import { extractNumbers } from "../../../utils";

const PaymentTermPopup = forwardRef(({ handleClose, selected }, ref) => {
  const dispatch = useDispatch();

  const [paymentTerm, setPaymentTerm] = useState(selected);

  const [limitedPayTerm, setLimitedPayTerm] = useState(
    useSelector(({ quoteFilter }) => quoteFilter.filter.paymentTerm.term),
  );
  const coverUpto = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.coverUpto,
  );
  const age = useSelector(({ greeting }) => greeting.customerDetails.age);
  const handleRadioChange = value => setPaymentTerm(value);
  const [errorMsg, setErrorMsg] = useState();
  const { corporateJourney } = useSelector(state => state.quote);

  let temp = corporateJourney?.is_gameskraft;
  const onConfirm = () => {
    handleClose();
    if (paymentTerm === "Limited Pay" && limitedPayTerm !== "Pay till 60") {
      dispatch(
        setCoverUptoValues(
          createArrayOfNumbers(
            age + parseInt(extractNumbers(limitedPayTerm)),
            85,
            1,
            "years",
          ),
        ),
      );
    }
    // else if (
    //   paymentTerm === "Limited Pay" &&
    //   limitedPayTerm === "Pay till 60"
    // ) {
    //   dispatch(
    //     setCoverUptoValues(
    //       createArrayOfNumbers(
    //         parseInt(extractNumbers(limitedPayTerm)),
    //         85,
    //         1,
    //         "years",
    //       ),
    //     ),
    //   );
    // }
    else {
      dispatch(
        setCoverUptoValues(createArrayOfNumbers(age + 5, 85, 1, "years")),
      );
    }
    dispatch(setPaymentTermOption({ value: paymentTerm }));
    dispatch(
      setPaymentTermTerm({ value: limitedPayTerm.replace("Yrs.", "Years") }),
    );
  };

  useEffect(() => {
    let cover = parseInt(coverUpto);
    let PT = cover - age;
    if (limitedPayTerm === "Pay till 60" && age > 55) {
      setErrorMsg("Maximum entry age should be 55 years");
    } else if (limitedPayTerm === "Pay till 60" && cover < 60) {
      setErrorMsg(" Payment term cannot be more than policy term.");
    } else if (parseInt(limitedPayTerm) > PT) {
      setErrorMsg(" Payment term cannot be more than policy term.");
    } else setErrorMsg("");
  }, []);

  return (
    <div className="payment-term-popup" ref={ref}>
      <div className="payment-term-popup__close-btn" onClick={handleClose}>
        {Close}
      </div>
      <div className="payment-term-popup__title">Payment Terms</div>
      <RadioContent
        radioText="Regular Pay"
        content="Pay premiums regularly till your Policy term continues"
        name="paymentTermRadio"
        checked={selected === "Regular Pay"}
        onChange={handleRadioChange}
      />
      {temp == "active" ? (
        <RadioContent
          radioText="Limited Pay"
          content="Pay premiums for a limited term and stay covered for the chosen policy term"
          name="paymentTermRadio"
          dropDown={
            <CustomDropDown
              items={[
                "5 Yrs.",
                // "7 Yrs.",
                // "8 Yrs.",
                "10 Yrs.",
                // "12 Yrs.",
                "15 Yrs.",
                "20 Yrs.",
                // "25 Yrs.",
                "30 Yrs.",
                // "Pay till 60",
                // "PT - 5 Yrs.",
              ]}
              selectedItem={limitedPayTerm.replace("Years", "Yrs.")}
              onChange={value => {
                let cover = parseInt(coverUpto);
                let PT = cover - age;

                if (value === "Pay till 60" && age > 55) {
                  setErrorMsg("Maximum entry age should be 55 years");
                } else if (value === "Pay till 60" && cover < 60) {
                  setErrorMsg(" Payment term cannot be more than policy term.");
                } else if (parseInt(value) > PT) {
                  setErrorMsg(" Payment term cannot be more than policy term.");
                } else setErrorMsg("");

                setLimitedPayTerm(value);
              }}
            />
          }
          checked={selected === "Limited Pay"}
          onChange={handleRadioChange}
          error={errorMsg}
        />
      ) : (
        <RadioContent
          radioText="Limited Pay"
          content="Pay premiums for a limited term and stay covered for the chosen policy term"
          name="paymentTermRadio"
          dropDown={
            <CustomDropDown
              items={[
                "5 Yrs.",
                // "7 Yrs.",
                // "8 Yrs.",
                "10 Yrs.",
                // "12 Yrs.",
                "15 Yrs.",
                "20 Yrs.",
                // "25 Yrs.",
                "30 Yrs.",
                "Pay till 60",
                // "PT - 5 Yrs.",
              ]}
              selectedItem={limitedPayTerm.replace("Years", "Yrs.")}
              onChange={value => {
                let cover = parseInt(coverUpto);
                let PT = cover - age;

                if (value === "Pay till 60" && age > 55) {
                  setErrorMsg("Maximum entry age should be 55 years");
                } else if (value === "Pay till 60" && cover < 60) {
                  setErrorMsg(" Payment term cannot be more than policy term.");
                } else if (parseInt(value) > PT) {
                  setErrorMsg(" Payment term cannot be more than policy term.");
                } else setErrorMsg("");

                setLimitedPayTerm(value);
              }}
            />
          }
          checked={selected === "Limited Pay"}
          onChange={handleRadioChange}
          error={errorMsg}
        />
      )}

      <RadioContent
        radioText="Single Pay"
        content="Pay once in the policy (at the issuance stage) and stay covered for the chosen policy term"
        name="paymentTermRadio"
        checked={selected === "Single Pay"}
        onChange={handleRadioChange}
      />
      <div className="payment-term-popup__confirm-btn-container">
        <button
          type="button"
          className="payment-term-popup__confirm-btn"
          onClick={onConfirm}
          disabled={errorMsg}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
});

const RadioContent = ({
  radioText,
  name,
  value,
  content,
  checked,
  dropDown,
  onChange,
  error,
}) => (
  <div className="radio-content">
    <RadioButton
      text={radioText}
      name={name}
      value={value ? value : radioText}
      checked={checked}
      onChange={onChange}
    />
    <div className="radio-content__content-text">
      {content}
      {dropDown && <div className="radio-content__drop-down">{dropDown}</div>}
    </div>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);

const RadioButton = ({ text, name, value, checked, onChange }) => (
  <label className="radio-btn">
    <input
      type="radio"
      name={name}
      value={value}
      defaultChecked={checked}
      onChange={e => onChange(e.target.value)}
    />
    <span className="radio-btn__checkmark"></span>
    <span className="radio-btn__text"> {text}</span>
  </label>
);

export default PaymentTermPopup;
