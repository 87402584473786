import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FilterMenu } from "..";
import PaymentTermPopup from "../../../../components/Popup/PaymentTermPopup/PaymentTermPopup";
import {
  setMorePlans,
  setNomineeGets,
  setCoverAmount,
  setCoverUpto,
  setPopup,
  setPaymentTermOption,
  setPaymentTermTerm,
  setCoverAmountLumpsumMonthly,
  setCoverUptoValues,
} from "../../../QuoteFilter/quoteFilterSlice";

import { useOutsideClick } from "../../../../custom-hooks";
import {
  getMultiplier,
  createArrayOfNumbers,
  numberToDigitWord,
} from "../../helper";
import SecureLS from "secure-ls";
import { getAmount, getAge } from "../../../../utils/inputUtils";

import "./filters.scss";
import { extractNumbers } from "../../../../utils";

export function NomineeGetsFilter() {
  const dispatch = useDispatch();
  const { nomineeGets } = useSelector(({ quoteFilter }) => quoteFilter.filter);

  const handleChange = value => {
    if (value === "Lumpsum") dispatch(setNomineeGets({ value }));
    if (value === "Monthly") dispatch(setPopup("monthly"));
    if (value === "Lumpsum + Monthly Income")
      dispatch(setPopup("lumpsumMonthly"));
  };

  return (
    <FilterMenu
      className={`filter-menu__nominee-gets ${
        nomineeGets === "Lumpsum + Monthly Income"
          ? "lumpsum-monthly-width"
          : ""
      }`}
      selected={nomineeGets}
      label={"Nominee gets"}
      singleOption={true}
      // items={["Lumpsum", "Monthly", "Lumpsum + Monthly Income"]}
      items={["Lumpsum"]}
      onChange={handleChange}
    />
  );
}

export function AmountFilter() {
  const nomineegets = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.nomineeGets,
  );

  if (nomineegets === "Monthly") return <CoverAmountMonthlyFilter />;
  if (nomineegets === "Lumpsum + Monthly Income")
    return <CoverAmountLumpsumMonthlyFilter />;
  return <CoverAmountLumpsumFilter />;
}

export function CoverAmountLumpsumFilter() {
  const ls = new SecureLS();
  const dispatch = useDispatch();
  const { customerDetails } = useSelector(state => state.greeting);
  const coverAmount = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.coverAmount,
  );
  //	const handleChange = (value) => dispatch(setCoverAmount({ value }));

  const handleChange = value => {
    console.log(value);
    dispatch(setCoverAmount({ value }));
    dispatch(
      setCoverAmountLumpsumMonthly({
        coverAmount: value,
        coverAmountPercent: "25%",
        increaseIncome: "Please select",
        additionalIncome: {
          amount: "50000",
          period: "1 years",
        },
      }),
    );
  };

  const [multiplier, setMultiplier] = useState(
    getMultiplier(customerDetails?.dob, customerDetails?.annualIncome),
  );

  const [max, setMax] = useState(
    Number(customerDetails?.annualIncome) * multiplier,
  );

  useEffect(() => {
    if (customerDetails.annualIncome)
      setMultiplier(
        getMultiplier(customerDetails.dob, customerDetails.annualIncome),
      );
  }, [customerDetails.annualIncome, customerDetails.dob]);
  useEffect(() => {
    if (customerDetails.annualIncome)
      setMax(Number(customerDetails.annualIncome) * multiplier);
  }, [multiplier, customerDetails]);

  useEffect(() => {
    // if (max) {
    //   dispatch(
    //     setCoverAmount({ value: numberToDigitWord(Number(max), 50000, 2) })
    //   );
    // }
  }, [max, customerDetails?.preferance]);
  const { corporateJourney } = useSelector(state => state.quote);

  let temp = corporateJourney?.is_gameskraft;
  return (
    <FilterMenu
      style={
        {
          // width: '24.73%'
          // width: '19.586%'
        }
      }
      className="filter-menu__lumpsum"
      selected={coverAmount}
      label={"Cover amount"}
      items={
        temp == "active" && customerDetails?.annualIncome < 500000
          ? ["₹ 50 Lakh"]
          : getAmount(
              temp == "active" ? 5000000 : 2500000,
              max,
              max,
              500000,
              "coverQuotes",
            ).reverse()
      }
      searchable
      //customInput
      //min={2500000}
      //max={max}
      //multiple={50000}
      //roundTo={2}
      onChange={handleChange}
    />
  );
}

export function CoverAmountMonthlyFilter() {
  const dispatch = useDispatch();
  const { value, term } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.coverAmountMonthly,
  );

  const handleClick = () => dispatch(setPopup("monthly"));

  return (
    <FilterMenu
      style={
        {
          // width: '24.369%'
        }
      }
      className="filter-menu__monthly"
      selected={`${value} (${term})`}
      label={"Monthly Amount"}
      items={[value]}
      handleOnClick={handleClick}
    />
  );
}

export function CoverAmountLumpsumMonthlyFilter() {
  const dispatch = useDispatch();

  const { displayNomineeGets } = useSelector(({ quoteFilter }) => quoteFilter);

  const [nomineeGets, setNomineeGets] = useState(displayNomineeGets);

  const handleClick = () => dispatch(setPopup("lumpsumMonthly"));

  useEffect(() => {
    setNomineeGets(displayNomineeGets);
  }, [displayNomineeGets]);

  return (
    <FilterMenu
      style={
        {
          // width: '24.369%'
        }
      }
      className="filter-menu__lumpsum-monthly"
      selected={nomineeGets
        .replace(" Crore", "Cr")
        .replace("for ", "(")
        .replace("years", "yrs.)")
        .replace(" Thousand", "K")
        .replace("+ ₹", "+")
        .replace(" (Lumpsum)", "")
        .replace("monthly", "")}
      label={"Lumpsum + Monthly Amt"}
      items={[]}
      handleOnClick={handleClick}
    />
  );
}

export function MorePlansFilter() {
  const dispatch = useDispatch();
  const morePlans = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.morePlans,
  );
  const { coverUpto } = useSelector(({ quoteFilter }) => quoteFilter.filter);

  const handleChange = value => {
    dispatch(setMorePlans({ value }));
    dispatch(setNomineeGets({ value: "Lumpsum" }));
    if (value === "Survival Benefits") {
      dispatch(setPaymentTermOption({ value: "Limited Pay" }));
      dispatch(setPaymentTermTerm({ value: "10 years" }));
      dispatch(setCoverUpto({ value: "70 years" }));
    }
  };
  const {
    customerDetails: { age },
  } = useSelector(state => state.greeting);
  useEffect(() => {
    if (coverUpto === "Whole Life" && morePlans === "Return of Premium") {
      dispatch(setMorePlans({ value: "Choose your plans" }));
    }
  }, [coverUpto]);
  return (
    <FilterMenu
      style={
        {
          // width: '18.593%'
        }
      }
      className="filter-menu__more-plans"
      selected={morePlans}
      label={"More plans"}
      items={
        age >= 30 && coverUpto !== "Whole Life"
          ? [
              "Return of Premium",
              // , "Survival Benefits"
            ]
          : age < 30 && coverUpto !== "Whole Life"
          ? ["Return of Premium"]
          : []
      }
      handleOnClick={coverUpto !== "Whole Life" ? false : () => {}}
      // notAllowed={"Choose your plans"}
      onChange={handleChange}
    />
  );
}

export function CoverUptoFilter() {
  const dispatch = useDispatch();
  const coverUpto = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.coverUpto,
  );

  const { limitedPayTerm, policyTerm } = useSelector(
    ({ quoteFilter }) => quoteFilter.lastFiltersData,
  );
  const age = useSelector(({ greeting }) => greeting.customerDetails.age);

  const { nomineeGets } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const { coverUptoValues } = useSelector(
    ({ quoteFilter }) => quoteFilter.filterValues,
  );
  const paymentTerms = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.paymentTerm.term,
  );
  let wholeLife = true;

  // useEffect(() => {
  //   if (
  //     (policyTerm + age === 99 || policyTerm + age === 100) &&
  //     coverUpto !== "Whole Life"
  //   ) {
  //     dispatch(setCoverUpto({ value: "Whole Life" }));
  //   }
  // }, [policyTerm, age]);

  const handleChange = value => dispatch(setCoverUpto({ value }));

  // useEffect(() => {
  //   if ((policyTerm + age === 99 || policyTerm + age === 100) && coverUpto !== "Whole Life") {
  //     dispatch(setCoverUpto({ value: "Whole Life" }));
  //   }
  // }, [policyTerm, age]);
  const paymentTermsFinal = parseInt(paymentTerms.slice(8, 11));
  const differentForAge = parseInt(coverUpto.slice(0, 2)) - age;
  const isPaymentDiff = parseInt(paymentTerm?.term.slice(0, 2));
  const isSame = differentForAge == isPaymentDiff;
  const isSixty = parseInt(coverUpto.slice(0, 2));

  useEffect(() => {
    if (isSame == true || isSixty == paymentTermsFinal) {
      console.log("Hellooooooooo");
      dispatch(setPaymentTermOption({ value: "Regular Pay" }));
      return;
    }
  });

  useEffect(() => {
    if (
      policyTerm &&
      limitedPayTerm &&
      (policyTerm === limitedPayTerm ||
        (parseInt(extractNumbers(coverUpto)) === 60 &&
          limitedPayTerm === "P60"))
    ) {
      dispatch(setPaymentTermOption({ value: "Regular Pay" }));
      dispatch(
        setCoverUptoValues(createArrayOfNumbers(age + 5, 85, 1, "years")),
      );
    }
  }, [coverUpto, limitedPayTerm, policyTerm, age]);

  return (
    <FilterMenu
      className={`filter-menu__cover-upto ${
        nomineeGets === "Lumpsum + Monthly Income"
          ? "lumpsum-monthly-width"
          : ""
      }`}
      wholeLife={wholeLife}
      searchStartsWith={true}
      searchable
      selected={coverUpto}
      label={"Cover upto"}
      items={coverUptoValues}
      onChange={handleChange}
      // addOnBtn='Switch to Whole Life (99 Years)'
      // addOnBtnOnClick={() => dispatch(setCoverUpto({ value: "99 years" }))}
      scrollActiveToMiddle
    />
  );
}

export function PaymentTermFilter() {
  const dispatch = useDispatch();
  const [paymentTermOption, setPaymentTerm] = useState(
    useSelector(({ quoteFilter }) => quoteFilter.filter.paymentTerm.option),
  );

  const { option, term } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter.paymentTerm,
  );
  const { customerDetails } = useSelector(state => state.greeting);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = value => {
    setPaymentTerm(value);
    if (value === "Single Pay")
      dispatch(
        setCoverUptoValues(
          createArrayOfNumbers(getAge(customerDetails.dob) + 1, 85, 1, "years"),
        ),
      );
    else {
      dispatch(
        setCoverUptoValues(
          createArrayOfNumbers(getAge(customerDetails.dob) + 5, 85, 1, "years"),
        ),
      );
    }
    if (value === "Single Pay" || value === "Regular Pay") {
      dispatch(setPaymentTermOption({ value: value }));
    } else {
      setShowPopup(true);
    }
  };

  const handleClose = () => {
    setPaymentTerm(option);
    setShowPopup(false);
  };

  const paymentTermPopupRef = useRef(null);
  useOutsideClick(paymentTermPopupRef, handleClose);

  useEffect(() => {
    setPaymentTerm(option);
  }, [option]);

  const termText = (
    <span className="payment-term-term-text">
      ({term.replace("Years", "Yrs.")})
    </span>
  );

  const headText =
    paymentTermOption === "Limited Pay" ? (
      <span>
        {" "}
        {paymentTermOption}
        {termText}{" "}
      </span>
    ) : (
      paymentTermOption
    );

  return (
    <FilterMenu
      title={paymentTermOption}
      className="filter-menu__payment-term"
      selected={paymentTermOption}
      headText={headText}
      label={"Payment term"}
      items={["Regular Pay", "Limited Pay", "Single Pay"]}
      onChange={handleChange}
      popup={
        showPopup && (
          <PaymentTermPopup
            selected={paymentTermOption}
            ref={paymentTermPopupRef}
            handleClose={handleClose}
          />
        )
      }
    />
  );
}
