export const COMPANY = [
  "Aditya Birla SunLife Insurance Co. Ltd.",
  "Bandhan Life Insurance Co. Ltd.",
  "Aviva Life Insurance Co. India Ltd.",
  "Bajaj Allianz Life Insurance Co. Ltd.",
  "Bharti AXA Life Insurance Co. Ltd.",
  "Canara HSBC Oriental Bank of Commerce Life Insurance Co. Ltd",
  "Edelweiss Tokio Life Insurance Co. Ltd",
  "Exide Life Insurance Co. Ltd.",
  "Future Generali India Life Insurance Co. Ltd",
  "HDFC Life Insurance Co. Ltd",
  "ICICI Prudential Life Insurance Co. Ltd.",
  "India First Life Insurance Co. Ltd.",
  "Kotak Mahindra Life Insurance Co. Ltd.",
  "Life Insurance Corporation of India",
  "Max Life Insurance Co. Ltd.",
  "PNB MetLife India Insurance Co. Ltd.",
  "Pramerica Life Insurance Co. Ltd.",
  "Reliance Nippon Life Insurance Co. Ltd",
  "SBI Life Insurance Co. Ltd.",
  "Shriram Life Insurance Co. Ltd.",
  "Star Union Dai-Ichi Life Insurance Co. Ltd.",
  "TATA AIA Life Insurance Co. Ltd.",
];
