import React, { memo, useEffect, useState } from "react";
import LaxmiImage from "../../../../assets/img/laxmiImg.png";
// import CheckImage from "../../../../assets/img/check-animation.gif";
import CheckImage from "../../../../assets/img/greentick.svg";
import CheckImageLoading from "../../../../assets/img/checkLoad-animation.gif";
import svg from "../../../../assets/img/newLoderSvg.svg";

import "./QuotesLoader.scss";
import { useDispatch } from "react-redux";
import { setIsQuotesLoading } from "../../quotePageSlice";

const QuotesLoader = ({ show, load, setShowLoader }) => {
  const [visible, setVisible] = useState(show);
  const [time, setTime] = useState(0);
  const [loaders, setLoaders] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!show || time >= 10) {
      setTimeout(() => {
        setVisible(false);
        setTime(false);
        setShowLoader(false);
        setLoaders(0);
        clearInterval(x);
        dispatch(setIsQuotesLoading(false));
      }, 3000 * 3);

      const x = setInterval(() => {
        setLoaders(prevLoaders => prevLoaders + 1);
      }, 3000);

      return;
    }

    setVisible(show);
  }, [show, time]);

  useEffect(() => {
    const x = setInterval(() => {
      setTime(prev => (prev || 0) + 1);
    }, 1000);
    if (!show) {
      clearInterval(x);
    }
    return () => {
      clearInterval(x);
    };
  }, [show]);
  return (
    <>
      <div
        className="modal fade show"
        id="pleaseConfirmPolicy"
        role="dialog"
        style={{ display: visible && "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content expertPopupWrap">
            <div className="modal-body">
              <div className="expertForm">
                <form id="confirmPolicyForm" action="">
                  <div className="popTab">
                    <section className="loaderingFeed">
                      <div className="polDataPopHeadWrap">
                        <img
                          className="polDataLaxImg"
                          src={LaxmiImage}
                          alt="laxmi"
                        />
                        <div className="confirmTitle">
                          You will see your personalised quotes in just a
                          moment…
                        </div>
                      </div>
                      <ul className="loadFedWrap">
                        <LoadingIcon loading={loaders < 1}>
                          Calculating cover amount
                        </LoadingIcon>
                        <LoadingIcon loading={loaders < 2}>
                          Recommending the right insurance at best price
                        </LoadingIcon>
                        <LoadingIcon loading={loaders < 3}>
                          Your personalised quotes are now ready
                        </LoadingIcon>
                      </ul>
                    </section>
                  </div>

                  <div className="confirmPopFooter">
                    <img src={svg} style={{ width: "200px" }} alt="svg"></img>
                    <div className="confirmMainTitle dotAnimation">
                      Your personalised quotes are being calculated
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotesLoader;

const LoadingIcon = memo(function LoadingIcon({
  loading = true,
  children,
  ...props
}) {
  return (
    <li {...props}>
      <span className={`stepLoad ${loading ? "dotAnimation" : ""}`}>
        <>
          {children}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </>
      </span>
      <span className="loadicon">
        <img src={loading ? CheckImageLoading : CheckImage} alt="Checked" />
      </span>
    </li>
  );
});
