import { createSlice } from "@reduxjs/toolkit";

const eligibilityPageSlice = createSlice({
  name: "eligibilityPage",
  initialState: {
    isPaymentSuccess: false,
    logged: false,
  },

  reducers: {
    setIsPaymentSuccess: (state, action) => {
      state.isPaymentSuccess = action.payload;
    },
    setStatusLogged: (state, action) => {
      state.logged = action.payload;
    }
  },
});

export const { setIsPaymentSuccess, setStatusLogged } = eligibilityPageSlice.actions;

export const { saveDetails } = eligibilityPageSlice.actions;

export default eligibilityPageSlice.reducer;
