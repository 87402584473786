/* eslint-disable eqeqeq */
import React from "react";
import Product from "../Product/Product";
import useQuoteBuyNowButton from "../../../../custom-hooks/useQuoteBuyNowButton";
import useCompareProductList from "./useCompareProductList";
import "./compare-products-list.scss";
import { setOpenMaxDiscount } from "../../../../components/Popup/maxDiscount.slice";
import { useDispatch } from "react-redux";
import { setOpenBajajDiscount } from "../../../../components/Popup/bajajDiscount.slice";
import { setOpenIciciDiscount } from "../../../../components/Popup/iciciDiscount.slice";
import { setSelectedQuote } from "../../../QuotePage/quotePageSlice";

function CompareProductsList() {
  const {
    coverAmount,
    compareQuotes,
    coverUpto,
    displayNomineeGets,
    maxAddonRiders,
    maxInbuiltRiders,
    option,
    payPremium,
    term,
    recommendedQuote,
    discountedPremium,
    isSinglePay,
  } = useCompareProductList();
  const dispatch = useDispatch();
  const handleBuyNowClick = useQuoteBuyNowButton();
  return (
    <div className="compare-products-list">
      <ul className="cd-products-columns">
        {compareQuotes.map(quote => (
          <Product
            key={quote.insurance_code}
            recommended={quote?.insurance_code === recommendedQuote}
            quotesLength={compareQuotes.length}
            quote={quote}
            maxInbuiltRiders={maxInbuiltRiders}
            maxAddonRiders={maxAddonRiders}
            planAmount={`₹${discountedPremium(
              quote.company_alias,
              quote.premium_amount,
            )}${
              option === "Single Pay"
                ? ""
                : payPremium === "Monthly"
                ? "/month"
                : payPremium === "Yearly"
                ? "/year"
                : `/` + payPremium
            }`}
            nomineeGets={displayNomineeGets}
            coverAmount={coverAmount}
            coverUpto={
              quote?.differentTenureCU
                ? quote?.differentTenureCU + " Years"
                : coverUpto
            }
            // coverUpto={coverUpto}
            paymentTerm={
              option === "Limited Pay" ? option + ` (${term})` : option
            }
            payPremium={option !== "Single Pay" ? payPremium : "Single Pay"}
            medicalExamination={quote.medicalCondition || "As Per Underwriting"}
            onBuyNowClick={() => {
              dispatch(setSelectedQuote(quote));
              if (quote.company_alias === "max_life") {
                dispatch(setOpenMaxDiscount(true));
              } else if (
                quote.company_alias === "icici_pru" &&
                quote?.self_employed_premium != 0 &&
                !isSinglePay
              ) {
                dispatch(setOpenIciciDiscount(true));
              } else if (quote?.company_alias === "bajaj_life") {
                dispatch(setOpenBajajDiscount(true));
              } else handleBuyNowClick(quote);
            }}
          />
        ))}
      </ul>
    </div>
  );
}

export default CompareProductsList;
