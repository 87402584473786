import { createSlice } from "@reduxjs/toolkit";

// import SecureLS from "secure-ls";
//import swal from "sweetalert";
//import { saveProposal, submitProposal, updateQuotation } from "../serviceApi";
// const ls = new SecureLS();
const birlaProposalPageForm = createSlice({
  name: "birlaProposalPageForm",
  initialState: {
    contactDetails: false,
    basicDetails: false,
    familyDetails: false,
    kycDetails: false,
    professionalDetails: false,
    pepDetails: false,
    permanentAddress: false,
    loading: false,
    nomineeDetails: false,
    ageProofDetails: false,
    personalMedicalDetails: false,
    communicatingAddress: false,
    panDetails: false,
  },

  reducers: {
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    setBasicDetails: (state, action) => {
      state.basicDetails = action.payload;
    },
    setFamilyDetails: (state, action) => {
      state.basicDetails = action.payload;
    },
    setKYCDetails: (state, action) => {
      state.kycDetails = action.payload;
    },
    setProfessionalDetails: (state, action) => {
      state.professionalDetails = action.payload;
    },
    setPEPDetails: (state, action) => {
      state.pepDetails = action.payload;
    },
    setPermanentAddress: (state, action) => {
      state.permanentAddress = action.payload;
    },
    setNomineeDetails: (state, action) => {
      state.nomineeDetails = action.payload;
    },
    setAgeProof: (state, action) => {
      state.ageProofDetails = action.payload;
    },
    setPersonalMedicalDetails: (state, action) => {
      state.personalMedicalDetails = action.payload;
    },
    setCommunicatingAddress: (state, action) => {
      state.communicatingAddress = action.payload;
    },
    setPanDetails: (state, action) => {
      state.panDetails = action.payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setContactDetails,
  setBasicDetails,
  setFamilyDetails,
  setKYCDetails,
  setProfessionalDetails,
  setPEPDetails,
  setPermanentAddress,
  setNomineeDetails,
  setAgeProof,
  setPersonalMedicalDetails,
  setCommunicatingAddress,
  setPanDetails,
  initLoader,
} = birlaProposalPageForm.actions;

export default birlaProposalPageForm.reducer;
