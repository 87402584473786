import { createSlice } from "@reduxjs/toolkit";
import { setIsError } from "../QuotePage/quotePageSlice";
import { sendEligibilityDetails } from "../EligibilityPage/serviceApi";
import SecureLS from "secure-ls";
import { getAge } from "../../utils/inputUtils";

const ls = new SecureLS();

const formCardSlice = createSlice({
  name: "formCard",
  initialState: {
    details: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.details = action.payload;
    },
    removeDetails: (state, action) => {
      state.details = false;
    },
  },
});

export const { saveDetails, removeDetails } = formCardSlice.actions;

export const savePersonalDetails = data => {
  const { firstName, dob, lastName, email, mobileNo, pan, secondary_email } =
    data;
  const newData = {
    ...data,
    traceId: sessionStorage.getItem("traceId"),
    firstName: firstName,
    lastName: lastName,
    dob: dob,
    age: getAge(dob.split("-").reverse().join("/")),
    email: email,
    mobileNo: mobileNo,
    panNo: pan,
    secondaryEmailId: secondary_email,
    requestType: "personal_details",
    eligibleStatus: "YES",
  };
  return async dispatch => {
    try {
      if (data) {
        await sendEligibilityDetails(newData);
        data["dob"] = data["dob"].split("-").reverse().join("/");
        dispatch(saveDetails(data));
      }
    } catch (err) {
      alert("Something went wrong");
      dispatch(
        setIsError("Something went wrong while saving personal details"),
      );
    }
  };
};

export default formCardSlice.reducer;
