import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import {
  medicalDetailsTwoSendData,
  saveMedicalDetailsTwo,
} from "./MedicalDetailsTwo.slice";
import {
  Form,
  FormGroup,
  Label,
  TextAreaProposal,
  TextInput,
} from "../../../../components/Form/Form";
import { useForm } from "react-hook-form";
import {
  EditButton,
  TitleForm,
  ToggleTile,
  SummaryTile,
} from "../../components";
import "./medical-details-two.scss";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";

const MedicalDetailsTwo = ({ position, setPosition, index }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors, formState } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  const { medicalDetailsOne } = useSelector(state => state.medicalDetailsOne);
  const { medicalDetailsTwo } = useSelector(state => state.medicalDetailsTwo);
  const { medicalDetailsThree } = useSelector(state => state.medicalDetails3);
  const { medicalDetailsFour } = useSelector(state => state.medicalDetails4);
  const [summary, setSummary] = useState(false);

  const [occupationHazard, setOccupationHazard] = useState(
    medicalDetailsTwo ? medicalDetailsTwo.occupationHazard : false,
  );

  const [employedInArmy, setEmployedInArmy] = useState(
    medicalDetailsTwo ? medicalDetailsTwo.employedInArmy : false,
  );

  const [lostWeight, setLostWeight] = useState(
    medicalDetailsTwo ? medicalDetailsTwo.lostWeight : false,
  );

  const [familyDisorder, setFamilyDisorder] = useState(
    medicalDetailsTwo ? medicalDetailsTwo.familyDisorder : false,
  );

  const [hospitalized, setHospitalized] = useState(
    medicalDetailsTwo ? medicalDetailsTwo.hospitalized : false,
  );

  // const handleSubmit = (evt) => {
  // evt.preventDefault();
  // dispatch(saveMedicalDetailsTwo({ occupationHazard }));
  // setSummary(true);
  // };

  const { saveProposalForm, isLoading } = useSaveProposalForm();
  const LastIndex = useSelector(state => state.iciciProposal?.position);
  const onSubmit = data => {
    const newData = {
      occupationHazard: data.occupationHazard,
      employedInArmy: data.employedInArmy,
      lostWeight: data.lostWeight,
      familyDisorder: data.familyDisorder,
      hospitalized: data.hospitalized,
      toggleOccupationHazard: occupationHazard,
      toggleLostWeight: lostWeight,
      toggleEmployedInArmy: employedInArmy,
      toggleFamilyDisorder: familyDisorder,
      toggleHospitalized: hospitalized,
    };
    const sendData = medicalDetailsTwoSendData(newData);
    saveProposalForm(sendData, () => {
      dispatch(saveMedicalDetailsTwo(newData));
      setSummary(true);
      dispatch(setPosition(position + 1));
    });
  };
  useEffect(() => {
    if (medicalDetailsTwo) {
      if (position === index) {
        setSummary(false);
      }
      if (position > index) {
        setSummary(true);
      }
    }
  }, [position]);

  if (isLoading) return <Loader />;
  const a = medicalDetailsTwo?.occupationHazard?.length
    ? medicalDetailsTwo?.occupationHazard?.length
    : 0;
  const b = medicalDetailsTwo?.employedInArmy?.length
    ? medicalDetailsTwo?.employedInArmy?.length
    : 0;
  const c = medicalDetailsTwo?.lostWeight?.length
    ? medicalDetailsTwo?.lostWeight?.length
    : 0;
  const d = medicalDetailsTwo?.familyDisorder?.length
    ? medicalDetailsTwo?.familyDisorder?.length
    : 0;
  const e = medicalDetailsTwo?.hospitalized?.length
    ? medicalDetailsTwo?.hospitalized?.length
    : 0;
  const f = medicalDetailsFour?.otherDiagnosedDetails?.length
    ? medicalDetailsFour?.otherDiagnosedDetails?.length
    : 0;
  const g = medicalDetailsThree?.disabilityText?.length
    ? medicalDetailsThree?.disabilityText?.length
    : 0;
  const h = medicalDetailsThree?.injuryText?.length
    ? medicalDetailsThree?.injuryText?.length
    : 0;
  const i = medicalDetailsThree?.gynecologicalComplicationsText?.length
    ? medicalDetailsThree?.gynecologicalComplicationsText?.length
    : 0;

  const totalCount = a + b + c + d + e + f + g + h + i;
  if (position < index)
    return (
      <Card className="proposalTitleCard">
        <CardTitle EC>Medical Question - Set 2</CardTitle>
      </Card>
    );

  if (medicalDetailsOne) {
    return (
      <div className="medical-details-two">
        <Card proposal>
          <CardTitle
            eligibilityTitle
            className="cardTitleProposal"
            // EC={summary ? true : undefined}
          >
            Medical Question - Set 2{" "}
            {summary && (
              <EditButton
                onClick={() => {
                  dispatch(setPosition(index));
                  setSummary(false);
                }}
              />
            )}
            {totalCount > 250 && LastIndex >= 13 ? (
              <h6
                style={{
                  color: "red",
                  marginLeft: "2rem",
                  padding: "15px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to
                mention the descriptions is 250 characters and{" "}
                {totalCount <= 250 ? 250 - totalCount : 0} character available
              </h6>
            ) : (
              <></>
            )}
          </CardTitle>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {!summary ? (
              <>
                <h6
                  style={{
                    color: totalCount <= 250 ? "#107591" : "red",
                    marginLeft: "2rem",
                    padding: "15px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to
                  mention the descriptions is 250 characters and{" "}
                  {totalCount <= 250 ? 250 - totalCount : 0} character available
                </h6>
                <FormGroup xlg formPage className="form-group--1 proposalInput">
                  <div className="healthQuestion">
                    Is your occupation associated with any specific hazard or do
                    you take part in activities or have hobbies that could be
                    dangerous in any way ? (e.g. - occupation - Chemical
                    factory, mines, explosives, radiation, corrosive chemicals &
                    hobbies - aviation other than as a fare paying passenger,
                    diving, mountaineering, any form of racing, etc.)
                  </div>
                  <RadioComponent
                    //     label="Is your occupation associated with any specific hazard
                    //  or do you take part in activities or have hobbies that could
                    //   be dangerous in any way ? (e.g. - occupation - Chemical factory,
                    //      mines, explosives, radiation, corrosive chemicals & hobbies -
                    //       aviation other than as a fare paying passenger, diving, mountaineering,
                    //        any form of racing, etc.)"
                    updateChecked={setOccupationHazard}
                    // block
                    value={occupationHazard}
                    name="occupationHazard"
                  />
                </FormGroup>
                {occupationHazard && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      placeholder=" "
                      name="occupationHazard"
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsTwo
                          ? medicalDetailsTwo.occupationHazard
                          : ""
                      }
                    />
                    <Label
                      md
                      htmlFor="occupationHazard"
                      style={{ width: "100%" }}
                    >
                      Please Specify
                    </Label>
                    {errors.occupationHazard && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </>
            ) : (
              <SummaryTile
                label="Is your occupation associated with any specific hazard or do you take part in activities or have hobbies that could be dangerous in any way ? (e.g. - occupation - Chemical factory, mines, explosives, radiation, corrosive chemicals & hobbies - aviation other than as a fare paying passenger, diving, mountaineering, any form of racing, etc.)"
                data={occupationHazard ? "Yes" : "No"}
                detailLabel="Please Specify"
                detail={medicalDetailsTwo.occupationHazard}
              />
            )}
            {!summary ? (
              <>
                <FormGroup
                  xlg
                  formPage
                  className="form-group--1"
                  style={{ textAlign: "left" }}
                >
                  <div className="healthQuestion">
                    Are you employed in the armed, para military or police
                    forces ?(If yes, please provide Rank, Department/Division,
                    Date of last medical & category after medical exam).
                  </div>
                  <RadioComponent
                    // label="Are you employed in the armed, para military or police forces ?(If yes, please provide Rank, Department/Division, Date of last medical & category after medical exam)."
                    updateChecked={setEmployedInArmy}
                    // block
                    value={employedInArmy}
                    name="employedInArmy"
                  />
                </FormGroup>
                {employedInArmy && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="employedInArmy"
                      placeholder=" "
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsTwo
                          ? medicalDetailsTwo.employedInArmy
                          : ""
                      }
                    />
                    <Label md htmlFor="employedInArmy">
                      Please Specify
                    </Label>
                    {errors.employedInArmy && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </>
            ) : (
              <SummaryTile
                label="Are you employed in the armed, para military or police forces ?(If yes, please provide Rank, Department/Division, Date of last medical & category after medical exam)."
                data={employedInArmy ? "Yes" : "No"}
                detailLabel="Please Specify"
                detail={medicalDetailsTwo.employedInArmy}
              />
            )}
            {!summary ? (
              <>
                <FormGroup
                  xlg
                  formPage
                  className="form-group--1"
                  style={{ textAlign: "left" }}
                >
                  <div className="healthQuestion">
                    Have you lost weight of 10 kgs or more in the last six
                    months?
                  </div>
                  <RadioComponent
                    // label="Have you lost weight of 10 kgs or more in the last six months?"
                    updateChecked={setLostWeight}
                    // block
                    value={lostWeight}
                    name="lostWeight"
                  />
                </FormGroup>
                {lostWeight && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="lostWeight"
                      placeholder=" "
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsTwo ? medicalDetailsTwo.lostWeight : ""
                      }
                    />
                    <Label md htmlFor="lostWeight">
                      Please Specify
                    </Label>
                    {errors.lostWeight && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </>
            ) : (
              <SummaryTile
                label="Have you lost weight of 10 kgs or more in the last six months?"
                data={lostWeight ? "Yes" : "No"}
                detailLabel="Please Specify"
                detail={medicalDetailsTwo.lostWeight}
              />
            )}
            {!summary ? (
              <>
                <FormGroup
                  xlg
                  formPage
                  className="form-group--1 proposalInputFamily"
                >
                  <div className="healthQuestion">
                    Family details of the life assured(include parents/sibling)
                    Are any of your family members suffering from /have suffered
                    from/have died of heart disease,Diabetes Mellitus, cancer or
                    any other hereditary/familial disorder, before 55 years of
                    age.if yes please provide details below.
                  </div>
                  <RadioComponent
                    // label="Family details of the life assured(include parents/sibling) Are any of your family members suffering from /have suffered from/have died of heart disease,Diabetes Mellitus, cancer or any other hereditary/familial disorder, before 55 years of age.if yes please provide details below."
                    updateChecked={setFamilyDisorder}
                    // block
                    value={familyDisorder}
                    name="familyDisorder"
                  />
                </FormGroup>
                {familyDisorder && (
                  <FormGroup proposalCardL eligibilityPage className="mobText">
                    <TextAreaProposal
                      md
                      name="familyDisorder"
                      placeholder=" "
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsTwo
                          ? medicalDetailsTwo.familyDisorder
                          : ""
                      }
                    />
                    <Label md htmlFor="familyDisorder">
                      Please Specify
                    </Label>
                    {errors.familyDisorder && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </>
            ) : (
              <SummaryTile
                label="Family details of the life assured(include parents/sibling) Are any of your family members suffering from /have suffered from/have died of heart disease,Diabetes Mellitus, cancer or any other hereditary/familial disorder, before 55 years of age.if yes please provide details below."
                data={familyDisorder ? "Yes" : "No"}
                detailLabel="Please Specify"
                detail={medicalDetailsTwo.familyDisorder}
              />
            )}
            {!summary ? (
              <div>
                <FormGroup
                  xlg
                  formPage
                  className="form-group--1"
                  style={{ textAlign: "left" }}
                >
                  <div className="healthQuestion">
                    Have you undergone or been advised to undergo any
                    tests/investigations or any surgery or hospitalized for
                    observation or treatment in the past?
                  </div>
                  <RadioComponent
                    // label="Have you undergone or been advised to undergo any tests/investigations or any surgery or hospitalized for observation or treatment in the past?"
                    updateChecked={setHospitalized}
                    // block
                    value={hospitalized}
                    name="hospitalized"
                  />
                </FormGroup>
                {hospitalized && (
                  <FormGroup proposalCardL eligibilityPage>
                    <TextAreaProposal
                      md
                      name="hospitalized"
                      placeholder=" "
                      ref={register({ required: true })}
                      defaultValue={
                        medicalDetailsTwo ? medicalDetailsTwo.hospitalized : ""
                      }
                    />
                    <Label md htmlFor="hospitalized">
                      Please Specify
                    </Label>
                    {errors.hospitalized && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        This field is required
                      </p>
                    )}
                  </FormGroup>
                )}
              </div>
            ) : (
              <SummaryTile
                label="Have you undergone or been advised to undergo any tests/investigations or any surgery or hospitalized for observation or treatment in the past?"
                data={hospitalized ? "Yes" : "No"}
                detailLabel="Please Specify"
                detail={medicalDetailsTwo.hospitalized}
              />
            )}
            {!summary && (
              <FormGroup xlg eligibilityPage propSubWrap>
                <button
                  type="submit"
                  disabled={!isValid}
                  className={
                    !isValid
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                  }
                >
                  PROCEED TO MEDICAL QUESTION - SET 3
                </button>
              </FormGroup>
            )}
          </Form>
        </Card>
      </div>
    );
  }
};

export default MedicalDetailsTwo;
