import React, { useState, useEffect } from "react";
import "./knowmore.scss";
import Header from "./components/header";
import PopupBody from "./components/popupBody";
import QuotePagePopup from "../../modules/QuotePage/components/QuotePagePopup/QuotePagePopup";
import SecureLS from "secure-ls";
export default function Knowmore({
  onBuyNowClicked,
  show,
  onClose,
  logoText,
  price,
  coverUpto,
  nomineeGets,
  history,
  buynow,
  nextPage,
  logoUrl,
  childName,
  quote,
  policyURL,
}) {
  const ls = new SecureLS();

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "relative";
      document.body.style.width = "unset";
    }
  }, [show]);

  const [height, setHeight] = useState("966px");
  let width;
  let id;

  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    id = undefined;
  } else if (window.matchMedia("(max-width: 1199px)").matches) {
    width = "740px";
    id = "know-more";
  } else {
    id = "know-more";
    width = "999px";
  }
  const content = (
    <div className="knowmorepopup">
      <div className="knowpopupcontent">
        <Header
          logoUrl={logoUrl}
          price={price}
          history={history}
          logoText={logoText}
          ls={ls}
          quote={quote}
          coverUpto={coverUpto}
          nomineeGets={nomineeGets}
          buynow={buynow}
          onBuyNowClicked={onBuyNowClicked}
          nextPage={nextPage}
          childName={childName}
          policyURL={policyURL}
        />
        <PopupBody quote={quote} onClose={onClose} setHeight={setHeight} />
      </div>
    </div>
  );
  return (
    <QuotePagePopup
      transparentBackground={"false"}
      show={show}
      id={id}
      onClose={onClose}
      content={content}
      width={width}
      height={height}
      size="true"
      customClass={`know-more-popup-modal ${show ? "in" : ""}`}
      closeBtnClass={"know-more-close-btn"}
    >
      {content}
    </QuotePagePopup>
  );
}
