import { createSlice } from "@reduxjs/toolkit";
import { getMaritalStatus } from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const insuredDetails1Slice = createSlice({
  name: "insuredDetails1Form",
  initialState: {
    insuredDetails1: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.insuredDetails1 = action.payload;
    },
  },
});

export const { saveDetails } = insuredDetails1Slice.actions;

export const insuredDetails1SendData = (data = {}) => ({
  gender: data.idGender === "M" ? "Male" : "Female",
  firstName: data.idFirstName,
  lastName: data.idLastName,
  maritalStatus: getMaritalStatus(data.idmartialStatus),
  relationship: data.proposalLa,
  dateOfBirth: data["idDob"].split("/").join("-"),
  proposalJourneyStatus: iciciProposalForms.insuredDetails1,
});

export const saveInsuredDetails1 = data => {
  return async dispatch => {
    try {
      if (data) {
        if (data["idDob"].split("-")[0].length === 4)
          data["idDob"] = data["idDob"].split("-").reverse().join("/");
        if (data["idDob"]?.includes("-"))
          data["idDob"] = data["idDob"].split("-").join("/");
        dispatch(saveDetails(data));
        dispatch(appendSendData(insuredDetails1SendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default insuredDetails1Slice.reducer;
