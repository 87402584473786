import { createSlice } from "@reduxjs/toolkit";

const iciciDiscountSlice = createSlice({
  name: "iciciDiscountSlice",
  initialState: {
    openIciciDiscount: false,
    salaried: "Y",
    existingPolicy: "N",
    fullyVaccinated: "Y",
    newIciciDiscount: {},
    iciciPremiumAmount: 0,
  },

  reducers: {
    setSalariedIcici: (state, action) => {
      state.salaried = action.payload;
    },
    setExistingPolicy: (state, action) => {
      state.existingPolicy = action.payload;
    },
    setFullyVaccinated: (state, action) => {
      state.fullyVaccinated = action.payload;
    },

    setNewIciciDiscount: (state, action) => {
      state.newIciciDiscount = action.payload;
    },

    setOpenIciciDiscount: (state, action) => {
      state.openIciciDiscount = action.payload;
    },

    setIciciPremiumAmount: (state, action) => {
      state.iciciPremiumAmount = action.payload;
    },

    resetIciciDiscountData: state => {
      state.salaried = "Y";
      state.existingPolicy = "N";
      state.fullyVaccinated = "Y";
      state.newIciciDiscount = {};
      state.iciciPremiumAmount = 0;
    },
  },
});

export const {
  setSalariedIcici,
  setExistingPolicy,
  setFullyVaccinated,
  setNewIciciDiscount,
  setOpenIciciDiscount,
  setIciciPremiumAmount,
  resetIciciDiscountData,
} = iciciDiscountSlice.actions;

export default iciciDiscountSlice.reducer;
