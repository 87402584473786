import React, { Fragment, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import HdfcLogo from "../../assets/img/hdfcLogo.png";
import Popup from "../Popup/Popup";
// import { Button } from "../StyledButton/Button";
import SecureLS from "secure-ls";
import "styled-components/macro";
import {
  paymentPopUpOpen,
  paymentStart,
  paymentSuccessful,
  saveReciept,
} from "./payment.slice";
import tick from "../../assets/img/tick.svg";
import "styled-components/macro";
import {
  Heading,
  HeadingWrapper,
  InnerWrapper,
  ReviewPopDisclaimer,
  Title,
  Value,
  WrapCenter,
  Wrapper,
} from "./style";
import {
  updateQuotation,
  paymentDetailsToIc,
  savePaymentDetails,
  enrollEmail,
} from "./serviceApi";
import "./ReviewPopUp.scss";
import {
  getPayPremiumModeMobile,
  getPayPremiumModeRider,
  getPayPremiumMode,
} from "../../modules/QuotePage/helper.js";
import laxmiImg from "../../assets/img/girl-img.png";
// import ToggleButton from "./../ToggleButton/ToggleButton";
// import { nomineeFormSchema } from "../../utils/validationSchema";
import {
  clearLoader,
  initLoader,
  setRepayLoader,
  initPaymentStatusLoader,
} from "../../modules/EligibilityPage/eligibilityCard.slice";
import {
  setIsPaymentSuccess,
  setStatusLogged,
} from "../../modules/EligibilityPage/eligibilityPage.slice";
import { useLocation } from "react-router-dom";
import { useCustomLocation } from "../../custom-hooks";
import greenTick from "../../assets/img/greentick.svg";
const ReviewProduct = ({
  show,
  onClose,
  history,
  selected,
  inbuiltCovers,
  addOnsList,
  additionalrider,
}) => {
  const { enquiryId } = useCustomLocation();
  const [btnDisable, setBtnDisable] = useState(false);
  const [recurringPayment, setRecurringPayment] = useState(false);
  const ls = new SecureLS();
  const { search } = useLocation();
  const { filter } = useSelector(state => state.quoteFilter);
  const tataRef = useRef();
  const dispatch = useDispatch();
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const searchParams = new URLSearchParams(window.location.search);
  const afflid = searchParams.get("afflid");
  const utm_medium = searchParams.get("utm_medium");
  const c_id = searchParams.get("c_id");
  // const { companyName, basePremiumAmount } = selected ? selected : " ";
  const { response, open } = useSelector(state => state.payment);
  const { selectedQuote } = useSelector(state => state.quote);
  const { customerDetails, userId } = useSelector(state => state.greeting);
  const { biUrl, redirectionUrl, appNo, eligibilityDetails, isSalaried } =
    useSelector(state => state.eligibilityCard);
  const additionalRiders = sessionStorage.getItem("additionalRiders");
  // const { companyLogo, coverUpto } = sessionStorage.getItem("selectedQuote");
  const { firstName, lastName, email, mobileNo } = customerDetails;

  const { displayNomineeGets } = useSelector(({ quoteFilter }) => quoteFilter);
  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );
  const [modeOfPayment, setModeOfPayment] = useState(
    getPayPremiumMode(
      filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    if (
      payPremium === "Monthly" &&
      filter.paymentTerm.option !== "Single Pay" &&
      // !recurringPayment &&
      (company_alias === "icici_pru" || company_alias === "tata_aia_life")
    ) {
      setShowDisclaimer(true);
    } else {
      setShowDisclaimer(false);
    }
  }, [payPremium, company_alias, recurringPayment]);

  // useEffect(() => {
  //   if (
  //     payPremium === "Monthly" &&
  //     filter.paymentTerm.option !== "Single Pay" &&
  //     company_alias === "icici_pru"
  //   ) {
  //     setRecurringPayment(true);
  //   }
  // }, [payPremium]);
  const tataHidden = (
    <>
      <form
        action={`https://sellonline.tataaia.com/wps/PA_TATAAIA_SO/#!/getTOpost`}
        method="post"
        style={{ display: "none" }}
        name="tata_aia"
        id="tata_aia"
        class="no_validate tata_aia hidden"
      >
        <input
          type="text"
          name="REQUEST"
          title="REQUEST"
          id="REQUEST"
          value={redirectionUrl}
        />
        <input
          type="submit"
          name="submit"
          id="submit_tata_aia_form"
          value="Submit"
          ref={tataRef}
        />
        <br />
        <br />
      </form>
    </>
  );
  useEffect(() => {
    if (open) {
      displayRazorPay(response);
      dispatch(paymentPopUpOpen(false));
    }
  }, [response]);
  async function kotakRedirection(redirectionUrlResponse) {
    try {
      const Form = document.createElement("form");
      Form.action = redirectionUrlResponse;
      Form.method = "post";
      Form.target = "_blank";

      Form.style.display = "none";

      document.body.appendChild(Form);

      Form.submit();

      document.body.removeChild(Form);
    } catch (error) {
      console.error({ tataRedirectionError: error });
    }
  }
  //tataRediretion
  async function tataRedirection(redirectionUrlResponse) {
    try {
      const tataForm = document.createElement("form");
      tataForm.action =
        "https://sellonline.tataaia.com/wps/PA_TATAAIA_SO/GenericRedirection?";
      tataForm.method = "post";
      tataForm.target = "_self";
      tataForm.style.display = "none";

      const input = document.createElement("input");
      input.name = "REQUEST";
      input.value = redirectionUrlResponse;

      tataForm.appendChild(input);
      document.body.appendChild(tataForm);

      tataForm.submit();

      document.body.removeChild(tataForm);
    } catch (error) {
      console.error({ tataRedirectionError: error });
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    updateQuotation({
      // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
      traceId: enquiryId,
      requestType: "proceed_to_payment",
    });
    setBtnDisable(true);
    if (
      selectedQuote.integration_type !== "R" &&
      company_alias !== "kotak_life"
    ) {
      const data = {
        // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
        traceId: enquiryId,
        // appNo: sessionStorage.getItem("appNo"),
        // userId: sessionStorage.getItem("userId"),
        userId: userId,
        appNo: appNo,
        recurringPayment: recurringPayment ? "Y" : "N",
      };
      if (company_alias === "icici_pru") {
        if (biUrl?.IpruRedirectionUrl) {
          window.location.assign(biUrl.IpruRedirectionUrl);
        }
      } else {
        dispatch(paymentStart(data));
      }
    } else {
      if (
        redirectionUrl &&
        company_alias !== "tata_aia_life" &&
        company_alias !== "kotak_life" &&
        company_alias !== "hdfc_life"
      ) {
        window.open(redirectionUrl);
      } else if (company_alias === "tata_aia_life") {
        // dispatch(initLoader(true));
        dispatch(initPaymentStatusLoader(true));
        // tataRef.current.click();
        await tataRedirection(redirectionUrl);
      } else if (company_alias === "kotak_life") {
        // dispatch(initLoader(true));
        dispatch(initPaymentStatusLoader(true));
        await kotakRedirection(redirectionUrl);
      } else if (company_alias === "hdfc_life") {
        window.open(redirectionUrl, "_self");
      }
    }
    setTimeout(() => {
      setBtnDisable(false);
    }, 3000);
    setTimeout(() => {
      // dispatch(initLoader(false));
      dispatch(initPaymentStatusLoader(false));
    }, 7000);
  };
  const reloadPage = url => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const displayRazorPay = async ({
    pgKey,
    pgOrderId,
    currency,
    paymentAmount,
    productName,
    PgCustomerId,
  }) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("failed");
      return;
    }

    const options = {
      key: pgKey,
      amount: recurringPayment ? paymentAmount : undefined,
      currency: recurringPayment ? currency : undefined,
      name: selected?.company_name || selected?.companyName,
      description: productName,
      image: selected?.company_logo || selected?.companyLogo,
      order_id: pgOrderId,

      customer_id: recurringPayment ? PgCustomerId : undefined,
      recurring: recurringPayment ? "1" : undefined,
      prefill: {
        name: firstName + " " + lastName,
        email: eligibilityDetails?.secondary_email,
        contact: mobileNo,
      },
      handler: async function (response) {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}common/save_logs`, {
          keepalive: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            traceId: enquiryId,
            paymentStatus: "success",
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            recurringPayment: recurringPayment ? "Y" : "N",
          }),
        });
        dispatch(setStatusLogged(true));
        // dispatch(initLoader(true));
        dispatch(initPaymentStatusLoader(true));
        const saveTransactionData = await savePaymentDetails({
          traceId: enquiryId,
          paymentStatus: "success",
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          recurringPayment: recurringPayment ? "Y" : "N",
        });
        // dispatch(initLoader(true));
        dispatch(initPaymentStatusLoader(true));
        // if (saveTransactionData.data.failureCount > 5) {
        //   reloadPage(`/eligibilitypage?enquiryId=${enquiryId}`);
        // }
        if (saveTransactionData.data.status) {
          // dispatch(initLoader(true));
          dispatch(initPaymentStatusLoader(true));
          dispatch(setRepayLoader(true));
          dispatch(setIsPaymentSuccess(true));
          const { data } = await paymentDetailsToIc({
            applicationNo: appNo,
            traceId: enquiryId,
          });
          dispatch(clearLoader());
          if (data?.status) {
            dispatch(saveReciept(data));
            history.replace({
              pathname: "/transactionsuccessful",
              // search: `enquiryId=${search.slice(11, 29) || sessionStorage.getItem("traceId")}`,
              search: `${
                afflid
                  ? "afflid=" + afflid + "&"
                  : utm_medium
                  ? "utm_medium=" + utm_medium + "&"
                  : c_id
                  ? "c_id" + c_id + "&"
                  : ""
              }enquiryId=${enquiryId}`,
            });
          }
          dispatch(initPaymentStatusLoader(false));
        }
      },
      notes: {
        "note_key 1": "Beam me up Scotty",
        "note_key 2": "Tea. Earl Gray. Hot.",
      },

      theme: {
        color: "#F37254",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", async function (response) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}common/save_logs`, {
        keepalive: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          traceId: enquiryId,
          paymentStatus: "failed",
          error_code: response.error.code,
          error_description: response.error.description,
          error_source: response.error.source,
          error_step: response.error.step,
          error_reason: response.error.reason,
          error_metadata: response.error.metadata,
          razorpayOrderId: response.error.metadata?.order_id,
        }),
      });
      const saveData = await savePaymentDetails({
        // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
        traceId: enquiryId,
        paymentStatus: "failed",
        error_code: response.error.code,
        error_description: response.error.description,
        error_source: response.error.source,
        error_step: response.error.step,
        error_reason: response.error.reason,
        error_metadata: response.error.metadata,
        razorpayOrderId: response.error.metadata?.order_id,
      });
      if (saveData.data.failureCount >= 5) {
        reloadPage(
          `/eligibilityPage?${
            afflid
              ? "afflid=" + afflid + "&"
              : utm_medium
              ? "utm_medium=" + utm_medium + "&"
              : c_id
              ? "c_id" + c_id + "&"
              : ""
          }enquiryId=${enquiryId}`,
        );
      }
      // await updateQuotation({
      //   // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
      //   traceId: enquiryId,
      //   requestType: "payment",
      //   paymentStatus: "failed",
      //   errorCode: response.error.code,
      //   errorDescription: response.error.description,
      //   errorSource: response.error.source,
      //   errorStep: response.error.step,
      //   errorReason: response.error.reason,
      //   errorMetadata: response.error.metadata,
      //   TotalAmount: paymentAmount,
      // });
      history.push({
        pathname: "/transactionunsuccessful",
        // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
        search: `${
          afflid
            ? "afflid=" + afflid + "&"
            : utm_medium
            ? "utm_medium=" + utm_medium + "&"
            : c_id
            ? "c_id" + c_id + "&"
            : ""
        }enquiryId=${enquiryId}`,
      });
    });

    rzp1.open();
  };

  // const sendResponseToIc = async (data) => {
  //   await paymentDetailsToIc(data);
  // };

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.append(script);
    });
  };

  const { premium_amount, premium_amount_gst } = selected;

  const discountedPremium = base => {
    if (base) {
      return Number(premium_amount - premium_amount_gst);
    } else {
      return Number(premium_amount);
    }
  };

  /// code for gameskraft

  // if (customerDetailsg && customerDetailsg.work_email) {
  //   let temp = customerDetailsg.work_email;
  //   let domain = temp.split("@")[1];
  //   if (domain === "gameskraft.com") {
  //     flag = true;
  //   }
  // }

  const handleGamesKraftClick = async () => {
    await updateQuotation({
      traceId: enquiryId,
      requestType: "proceed_to_payment",
    });
    await enrollEmail({
      enquiry_id: enquiryId,
    });
    setGamesKraftModal(true);
  };

  /// code for gameskraft
  const [gameskraftModal, setGamesKraftModal] = useState(false);
  const { corporateJourney } = useSelector(state => state.quote);
  let flag = false;
  // if (customerDetailsg && customerDetailsg.work_email) {
  //   let temp = customerDetailsg.work_email;
  //   let domain = temp.split("@")[1];
  //   if (domain === "gameskraft.com") {
  //     flag = true;
  //   }
  // }

  if (corporateJourney.is_gameskraft === "active") {
    flag = true;
  }

  const content = (
    <Fragment>
      <HeadingWrapper msize>
        <Heading style={{ fontSize: 24 }} reviewPopUp>
          <span>
            <img className="reviewPopTitleIcon" src={laxmiImg} alt="laxmi" />
          </span>
          <span className="reviewHeading">
            Done! Take a minute to review details
          </span>
        </Heading>
      </HeadingWrapper>
      <Wrapper
        reviewPopUpHeaderTitle
        style={{ position: "relative", top: "2px", right: "1px" }}
      >
        <InnerWrapper reviewPopupImg reviewPopUpHeaderTitle>
          <img
            className={`Review-popup__img review-image ${selectedQuote.company_alias}`}
            src={selected?.company_logo || selected?.companyLogo}
            alt="IC logo"
          />
          <div className="Review-popup__title">
            <Title reviewPopUp>
              {selected?.company_name || selected?.companyName}
            </Title>
            <Value>
              {selected?.masterProductName || selected?.master_product_name}
            </Value>
          </div>
        </InnerWrapper>
        <InnerWrapper reviewPopUpHeaderTitle>
          <div className="Review-popup__CoverTerm">
            <Title
              reviewPopUp
              reviewPopUpTitleAlt
              nomReview
              css={`
                @media (max-width: 767px) {
                  width: 90px;
                }
              `}
            >
              COVER UPTO
            </Title>
            <Value sm>
              {selected?.differentTenureCU
                ? selected?.differentTenureCU + " years"
                : filter?.coverUpto || selected?.coverUpto}
            </Value>
          </div>
        </InnerWrapper>
        <InnerWrapper reviewPopUpHeaderTitle>
          <div
            className={`Review-popup__NomineeGets ${company_alias}`}
            css={`
              @media (max-width: 767px) {
                top: 86px;
              }
            `}
          >
            <Title reviewPopUp reviewPopUpTitleAlt nomReview>
              NOMINEE GETS
            </Title>
            <Value sm>{displayNomineeGets}</Value>
          </div>
        </InnerWrapper>

        {appNo && (
          <div className="Review-popup__appNo">
            <Title reviewPopUp reviewPopUpTitleAlt nomReview>
              APPLICATION NUMBER
            </Title>
            <Value sm>{appNo}</Value>
          </div>
        )}
      </Wrapper>
      <Wrapper popupBody style={{ position: "relative", top: "18px" }}>
        <div
          className="Review-popup__inbuilt-cover"
          style={{ padding: "0 12px" }}
          css={`
            @media (max-width: 767px) {
              padding: 0 !important;
            }
          `}
        >
          <>
            <Title
              border
              reviewPopUpTitle
              reviewPopUp2
              css={`
                @media (max-width: 767px) {
                  width: 100%;
                }
              `}
            >
              Inbuilt Cover
            </Title>
            {inbuiltCovers?.length ? (
              inbuiltCovers?.map((e, i) => {
                return (
                  <Value sm key={i} reviewPopUpValue>
                    {e.rider_name}
                    <span className="reviewTick">
                      <img className="tickImage" src={tick} alt="tick" />
                    </span>
                  </Value>
                );
              })
            ) : (
              <Value sm reviewPopUpValue>
                No Inbuilt Covers
              </Value>
            )}
          </>
        </div>
        <div
          className="Review-popup__Addtional-cover"
          style={{ padding: "0 12px" }}
          css={`
            @media (max-width: 767px) {
              padding: 0 !important;
            }
          `}
        >
          <>
            <Title
              border
              reviewPopUpTitle
              reviewPopUp2
              css={`
                @media (max-width: 767px) {
                  width: 100%;
                }
              `}
            >
              Additional Cover
            </Title>
            {addOnsList.length ? (
              addOnsList.map((e, i) => {
                return (
                  <Value sm key={i} reviewPopUpValue>
                    {e.rider_name || e.riderName}
                    <span className="reviewTick">
                      <img className="tickImage" src={tick} alt="tick" />
                    </span>
                  </Value>
                );
              })
            ) : (
              <Value sm reviewPopUpValue>
                No Additional Cover
              </Value>
            )}
          </>
        </div>

        <div
          className="Review-popup__Premuim"
          css={`
            @media (max-width: 767px) {
              padding: 0;
              left: 0;
            }
          `}
        >
          <Title
            border
            reviewPopUpTitle
            reviewPopUp2
            css={`
              @media (max-width: 767px) {
                width: 100%;
              }
            `}
          >
            Premium
          </Title>
          <Value price reviewPopUpValue>
            <span className="basePrem">Base Plan Premium</span>
            <span className="basePrem">₹ {discountedPremium()}</span>
          </Value>
          {console.log({ additionalRiders, additionalrider })}
          <Value price last reviewPopUpValue>
            <span className="basePrem">Additional Riders</span>
            {additionalrider ? (
              <span className="basePrem">
                ₹{additionalrider && (additionalrider || additionalRiders)}
              </span>
            ) : (
              <span>No Additional Riders</span>
            )}
          </Value>

          <div
            className="mobReviewTotal"
            css={`
              @media (max-width: 767px) {
                margin-top: 20px;
              }
            `}
          >
            {" "}
            <Title primary premiumTitle>
              <div className="PaymentTextUi">TOTAL PREMIUM PAYABLE</div>
              <div className="PaymentTextMobUi">TOTAL PREMIUM </div>
              <div className="modePaymentMob">
                {getPayPremiumModeMobile(modeOfPayment)}
              </div>
            </Title>
            <Value primary last mobileLast premiumValue>
              ₹{" "}
              {(
                Number(discountedPremium()) +
                Number(additionalrider || additionalRiders)
              ).toLocaleString("en-IN")}{" "}
              <span className="modePayment">
                {" "}
                {getPayPremiumModeRider(
                  filter.paymentTerm.option === "Single Pay"
                    ? "none"
                    : payPremium,
                )}
              </span>
            </Value>
          </div>
          {/* {selectedQuote.company_alias === "icici_pru" &&
            filter.paymentTerm.option !== "Single Pay" && (
              <div className='mobReviewAutoPay'> */}
          {/* <div className="form-group autoPayToggle"> */}
          {/* <div className='d-flex align-items-center'>
                  <div className='eligibility__popup'>
                    Autopay for Renewal Premiums?
                  </div>
                  <span className='testAutoPay'>NO</span>
                  <div
                    style={{
                      marginTop: "6px",
                    }}
                    className='toggleReview'
                  >
                    <ToggleButton
                      disabled={
                        payPremium === "Monthly" &&
                        company_alias === "icici_pru"
                      }
                      autoPaySlider
                      checked={recurringPayment}
                      id='gridCheck'
                      onChange={(value) => setRecurringPayment(value)}
                    />
                  </div>
                  <span className='testAutoPay'>YES</span>
                </div>
              </div> */}
          {/* // </div> */}
        </div>
      </Wrapper>
      {company_alias === "tata_aia_life" && tataHidden}
      {flag ? (
        <WrapCenter float popupBody>
          <button
            type="button"
            style={{ margin: "0 auto", width: "auto" }}
            className={
              btnDisable
                ? "btn btn--disabled btn--lg uppercase bold reviewPopBtn "
                : "btn btn--highlighted btn--lg uppercase bold reviewPopBtn"
            }
            disabled={btnDisable}
            onClick={handleGamesKraftClick}
          >
            <span>{"PROCEED AND ENROLL"}</span>
          </button>
        </WrapCenter>
      ) : (
        <WrapCenter float popupBody>
          <button
            type="button"
            style={{ margin: "0 auto" }}
            className={
              btnDisable
                ? "btn btn--disabled btn--lg uppercase bold reviewPopBtn "
                : "btn btn--highlighted btn--lg uppercase bold reviewPopBtn"
            }
            onClick={handleSubmit}
            disabled={btnDisable}
          >
            <span>
              {["pnb_metlife", "bandhan_life", "tata_aia_life"].includes(
                company_alias,
              )
                ? "PROCEED"
                : "PROCEED TO PAYMENT"}
            </span>
          </button>
          {showDisclaimer && (
            <ReviewPopDisclaimer>
              *Depending on the payment methods/banks that you choose there may
              be upfront premium payment of
              {company_alias === "tata_aia_life" ? "3" : "2"} months charged,
              however in subsequent months monthly premium will be debited.
            </ReviewPopDisclaimer>
          )}
        </WrapCenter>
      )}
    </Fragment>
  );
  let gameskraftModalContent = (
    <>
      <div className="gmodal-wrapper">
        <img className="gmodal-tick" src={greenTick} />
        <div className="gmodal-text">
          <div className="gmodal-text-head">Congratulations!</div>
          <div className="gmodal-text-sub">
            Your enrollment into the Voluntary Individual Term Life Insurance
            Program has been successfully submitted.
          </div>
          <div className="gmodal-text-sub">
            Once the enrollment window is closed, we will contact you for
            further steps.
          </div>
          <div className="gmodal-text-sub">Have a nice day.</div>
        </div>

        <a
          href={
            utm_medium == "gameskraft"
              ? "https://preprod.elephant.in/gameskraft/"
              : "https://preprod.elephant.in/term-life-Insurance"
          }
          className="gmodal-link"
        >
          <button className="btn btn--highlighted">OK</button>
        </a>
      </div>
    </>
  );
  let width;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
  } else if (window.matchMedia("(max-width: 1177px)").matches) {
    width = "740px";
  } else {
    width = "1081px";
  }
  let gameskraftWidth;
  if (window.matchMedia("(max-width: 767px)").matches) {
    gameskraftWidth = "100%";
  } else if (window.matchMedia("(max-width: 1177px)").matches) {
    gameskraftWidth = "500px";
  } else {
    gameskraftWidth = "500px";
  }
  return (
    <Fragment>
      <Popup
        mdClose="true"
        show={show}
        onClose={onClose}
        content={content}
        height="auto"
        width={width}
        size="true"
        msize="true"
        transparentBackground="false"
        topFixed={"338px"}
        reviewPopUp
      ></Popup>
      <Popup
        mdClose="true"
        show={gameskraftModal}
        onClose={() =>
          (window.location.href =
            utm_medium == "gameskraft"
              ? "https://preprod.elephant.in/gameskraft/"
              : "https://preprod.elephant.in/term-life-Insurance")
        }
        content={gameskraftModalContent}
        height="auto"
        width={gameskraftWidth}
        size="true"
        msize="true"
        gamesKraft={true}
        transparentBackground="false"
        topFixed={"338px"}
      ></Popup>
    </Fragment>
  );
};
export default ReviewProduct;
