import { createSlice } from "@reduxjs/toolkit";
import { saveProposal, submitProposal, updateQuotation } from "../serviceApi";
import swal from "sweetalert";
import { setIsProposalSubmitted } from "../proposalPage.slice";

const kotakProposalPageForm = createSlice({
  name: "kotakProposalPageForm",
  initialState: {
    kotakProposalDetails: false,
    redirectURL: false,
    loading: false,
    pdfUrl: false,
    position: 0,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.kotakProposalDetails = action.payload;
    },
    saveResponse: (state, action) => {
      state.pdfUrl = action.payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
    setPosition: (state, action) => {
      state.position = action.payload;
    },
  },
});

export const { saveDetails, saveResponse, initLoader, setPosition } =
  kotakProposalPageForm.actions;

export const saveKotakProposalDetails = (data, history, search) => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        const response = await saveProposal(data);
        if (response.data.status) {
          const responseData = await submitProposal({
            traceId: sessionStorage.getItem("traceId"),
          });
          dispatch(saveResponse(responseData));
          if (responseData.data.status) {
            updateQuotation({
              traceId: sessionStorage.getItem("traceId"),
              requestType: "proposal",
            }).then(resp => {
              if (resp.data.status === true) {
                dispatch(setIsProposalSubmitted(true));
                history.push(
                  `/documentuploadpage?enquiryId=${search.slice(11, 29)}`,
                );
              }
            });
            dispatch(initLoader(false));
          } else {
            dispatch(initLoader(false));
            swal(responseData.data.message, {
              icon: "error",
            });
            // .then(() => window.location.reload());
          }
        } else {
          dispatch(initLoader(false));
          swal(response.data.message, {
            icon: "error",
          });
          // .then(() => window.location.reload());
        }
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export const kotakProposalForms = {
  personalInformation: "personalInformation",
  currentResidentialAddress: "currentResidentialAddress",
  permanentResidentialAddress: "permanentResidentialAddress",
  nomineeDetails: "nomineeDetails",
  healthDetails: "healthDetails",
  healthDetailsTwo: "healthDetailsTwo",
  healthDetailsTobacco: "healthDetailsTobacco",
};
export default kotakProposalPageForm.reducer;
