import React, { Fragment } from "react";
import { useHistory } from "react-router";
import "./popup.scss";
const PopupTarget = ({ id, content, height, width, onClose, maxHeight }) => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="popup" id={id}>
        <div
          className="popup__content"
          style={{ height: height, width: width, maxHeight: maxHeight }}
        >
          <button
            style={{ backgroundColor: "transparent", border: "none" }}
            className="popup__close"
            onClick={() => {
              history.replace(history.goBack());
              onClose && onClose();
            }}
          >
            &times;
          </button>
          {content}
        </div>
      </div>
    </Fragment>
  );
};

export default PopupTarget;
