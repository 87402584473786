const maleRelations = [
  "Father",
  "Grand Father",
  "Son",
  "Husband",
  "Brother",
  "Father in Law",
];

const femaleRelations = [
  "Mother",
  "Grand Mother",
  "Wife",
  "Daughter",
  "Sister",
  "Mother in Law",
  "Spouse",
];

export const bajajPayerDetailsData = data => [
  {
    id: "paidBy",
    label: "Premium Paid by",
    size: "xlg",
    value: data?.premiumPaidByText || "Select",
    alignLeft: true,
    type: "select",
    items: ["Proposed Insured", "Proposer", "Others"],
    validations: {
      required: "Please select payer",
    },
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Proposed Insured":
          return [
            {
              id: "PremiumPayername",
              label: "Premium Payer's name",
              size: "md",
              alignLeft: true,
              value:
                data?.premiumPayerName ||
                `${ data?.firstName } ${ data?.lastName }`,
              readOnly: true,
              validations: {
                fullName: true,
                required: "Please enter Premium Payer's full name",
                maxLength: 100,
                onlyAlphaSpace: true,
              },
            },
            {
              id: "payerRelation",
              label: "Relationship to Proposed Insured",
              size: "md",
              value:
                data?.premiumPaidByText === "Proposed Insured"
                  ? data?.premiumPayerRelationship || "Self"
                  : "Self",
              alignLeft: true,
              type: "select",
              items: ["Self"],
              validations: {
                required: "Please select relation",
              },
            },
            {
              id: "payerDob",
              label: "Premium Payer's DOB",
              type: "dob",
              value:
                data?.premiumPayerDob?.split("-").reverse().join("/") ||
                data.dob,
              showAgeLabel: true,
              size: "md",
              readOnly: true,
              alignLeft: true,
              validations: {
                required: "Please fill dob",
                invalid: "Please enter a valid value ",
                age: {
                  gt: [17, "Age should be greater than 18"],
                },
              },
            },
            {
              alignLeft: true,
              label: "Gender",
              id: "payerGender",
              value:
                data?.premiumPayerGender === "M"
                  ? "Male"
                  : data?.premiumPayerGender === "F"
                    ? "Female"
                    : data?.gender,
              type: "select",
              size: "md",
              items: ["Male", "Female"],
              // value: data.gender,
              readOnly: true,
            },
          ];

        default:
          return [
            {
              id: "PremiumPayername",
              label: "Premium Payer's name",
              value: data?.premiumPayerName,
              size: "md",
              alignLeft: true,
              validations: {
                maxLength: 100,
                fullName: true,
                required: "Please enter Premium Payer's full name",
                onlyAlphaSpace: true,
              },
            },
            {
              id: "payerRelation",
              label: "Relationship to Proposed Insured",
              size: "md",
              value:
                data?.premiumPaidByText !== "Proposed Insured"
                  ? data?.premiumPayerRelationship || "Select"
                  : "Select",
              alignLeft: true,
              type: "select",
              items: [
                "Father",
                "Mother",
                "Grand Father",
                "Grand Mother",
                "Wife",
                "Son",
                "Daughter",
                "Husband",
                "Others",
                "Brother",
                "Sister",
                "Father in Law",
                "Mother in Law",
                "Not Mentioned",
                "Spouse",
                "Karta",
                "Legal Guardian",
                "Partnership",
                // "Employer",
                // "Other",
              ],
              setOtherItemValueOnChange: (value, data) => {
                let newValue = data.payerGender;
                if (maleRelations.includes(value)) newValue = "Male";
                if (femaleRelations.includes(value)) newValue = "Female";

                return [
                  {
                    ref: "payerGender",
                    value: newValue,
                  },
                ];
              },
              validations: {
                required: "Please select relation",
              },
            },
            {
              id: "payerDob",
              label: "Premium Payer's DOB",
              type: "dob",
              value:
                data?.premiumPayerDob?.split("-").reverse().join("/") || "",
              showAgeLabel: true,
              size: "md",
              alignLeft: true,
              validations: {
                required: "Please fill dob",
                invalid: "Please enter a valid value ",
                age: {
                  gt: [17, "Age should be greater than 18"],
                },
              },
            },
            {
              id: "payerGender",
              label: "Gender",
              type: "select",
              value:
                data?.premiumPayerGender === "M"
                  ? "Male"
                  : data?.premiumPayerGender === "F"
                    ? "Female"
                    : "Select",
              size: "md",
              alignLeft: true,
              items: ["Male", "Female"],
              validations: {
                required: "Please select Gender",
              },
              setOtherItemValueOnChange: (value, data) => {
                let newValue = data.payerRelation;
                if (value === "Male") {
                  if (femaleRelations.includes(data.payerRelation)) {
                    newValue = "Select";
                  }
                }
                if (value === "Female") {
                  if (maleRelations.includes(data.payerRelation)) {
                    newValue = "Select";
                  }
                }
                return [
                  {
                    ref: "payerRelation",
                    value: newValue,
                  },
                ];
              },
            },
            {
              id: "PremiumPayerAddress",
              label: "Premium Payer's Address",
              size: "md",
              value: data?.premiumPayerAddress,
              alignLeft: true,
              validations: {
                required: "Please fill Address",
                capitalize: true,
                maxLength: 500,
                freeText: true,
              },
            },
          ];
      }
    },
  },
];
