import React from "react";
import styled from "styled-components";

const Div = styled.div`
  #mainMsg {
    display: none;
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    background: #152981;
    text-align: left;
    color: #fff;
    padding: 21px 0;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  }
  .mainMsgWrap {
    margin: 0 auto;
    display: table;
    width: 1022px;
  }
  .mainMsgWrap img {
    display: table-cell;
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 12px;
  }
  .mainMsgWrap span {
    font-family: "Inter-Regular";
    font-size: 16px;
    line-height: 20px;
    display: table-cell;
    vertical-align: middle;
  }
  @media only screen and (max-width: 767px) {
    .mainMsgWrap {
      width: 90%;
    }
    .mainMsgWrap span {
      font-size: 11px;
      line-height: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .mainMsgWrap {
      width: 60%;
    }
    .mainMsgWrap span {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export default function EmailPopup({ email = "vodafone.com" }) {
  return (
    <Div>
      <div id="mainMsg">
        <div class="mainMsgWrap">
          <img
            src={require("../../assets/img/clapping_hands.gif")}
            alt="clap hand"
          />
          <span id="mainMsgText">
            Congratulations! Your work email ID from {email} is eligible for
            exclusive discounts and corporate offers.
          </span>
        </div>
      </div>
    </Div>
  );
}
