import React, { useEffect, useState } from "react";

const MobileKnowYourProcess = ({ tab, onClick }) => {
  const [expandIcon, setExpandIcon] = useState(tab === 2 ? "minus" : "plus");
  const handleClick = () => {
    const openTabs = document.querySelectorAll(
      ".mobile-Knowmore .collapse.show",
    );
    openTabs.forEach(tab => tab.classList.remove("show"));
    setExpandIcon(expandIcon === "plus" ? "minus" : "plus");
    onClick();
  };

  useEffect(() => {
    if (tab === 2) setExpandIcon("minus");
    else setExpandIcon("plus");
  }, [tab]);

  return (
    <div>
      <button
        type="button"
        className={`knowyourplan__header ${expandIcon}`}
        onClick={handleClick}
        data-toggle="collapse"
        data-target="#knowyourprocess__content"
      >
        Know Your Claim Process
      </button>
      <div
        id="knowyourprocess__content"
        className="knowyourplan__content collapse"
      >
        <div className="tab__answer">
          Upon death of the life assured, the nominee can download the claim
          intimation form from Member Login or you can contact Elephant on 1800
          2669 693 or support@elephant.in we will assist you end to end. Upon
          receipt of intimation, the insurer shall contact the
          nominee/beneficiary for the required set of documents. Please keep the
          below list of documents handy for avoiding further delay.
        </div>
        <div className="tab__Header">List of Documents:</div>
        <div className="tab__answer">
          a. Death certificate issued by Govt. / Relevant Authority
        </div>
        <div className="tab__answer">
          b. Death claim form with claimant’s NEFT details
        </div>
        <div className="tab__answer">c. Original Policy Document</div>
        <div className="tab__answer">d. Claimant’s Identity Residence Prof</div>
        <div className="tab__answer">
          e. FIR, Police Inquest Report & Panchanama (in case of unnatural
          death)
        </div>
        <div className="tab__answer">
          f. Additional documentation might be requested by the insurer on a
          case-to-case basis
        </div>
        <div className="tab__answer">
          If all documents are received are in order, insurance company’s claims
          team will take decision on the claim.
        </div>
        <div className="tab__answer">
          Upon favorable decision, payment shall be made to nominee or legal
          heir of the insured.
        </div>
      </div>
    </div>
  );
};

export default MobileKnowYourProcess;
