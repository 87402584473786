/* eslint-disable eqeqeq */
import React from "react";
import instantPol from "./../../../../assets/img/instant-policy.svg";
import zeroDoc from "./../../../../assets/img/zero-doc.svg";
import noMed from "./../../../../assets/img/no-med-checkup.svg";
import { useOutsideClick } from "../../../../custom-hooks";
import { useRef } from "react";
const BandhanContent = ({
  isShown,
  resetState,
  setShowBenefitsPopup,
  showBenefitsPopup,
  handleClick,
}) => {
  React.useEffect(() => {
    if (showBenefitsPopup) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "relative";
      document.body.style.width = "unset";
    }
  }, [showBenefitsPopup]);
  const popupRef = useRef();
  useOutsideClick(popupRef, () => {
    setShowBenefitsPopup(false);
    resetState();
  });
  return (
    <div className="animation" ref={popupRef}>
      <div className="modalHead">
        <p className="insta-cov-expl-header">Insta Cover Exclusive Features</p>
        <button
          style={{ marginTop: "15px" }}
          className="quote-page-popup-close knowMore-close"
          onClick={() => {
            setShowBenefitsPopup(false);
            resetState();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            fill="#333"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>
      <div className="modelWrap">
        <div class="insta-cov-wrapper">
          <div class="insta-cov-col">
            <img src={noMed} alt="" />
            <h3>No Medical Check-Up</h3>
            <p>
              You don’t need to undergo any physical medical check-ups,
              tele-medicals or video medicals if you are in pink of your health.
            </p>
          </div>
          <div class="insta-cov-col">
            <img src={zeroDoc} alt="" />
            <h3>Zero Documentation</h3>
            <p>
              We don’t ask you for any income proof or personal documents. You
              buy a policy without uploading a single piece of paper.
            </p>
          </div>
          <div class="insta-cov-col" style={{ margin: "0px" }}>
            <img src={instantPol} alt="" />
            <h3>Instant Policy</h3>
            <p>
              No more waiting for your policy. Your policy is issued within 24
              hours.
              <span class="insta-cov-col-p-span">Isn’t that instant!</span>
            </p>
          </div>
        </div>
        {isShown == false ? (
          <p class="insta-cov-info-p viewMoreP" id="viewMoreP">
            No Medical Check-Up applies to customers who are in good health. In
            case of any adverse health declaration, the customer
            <span id="dots">...</span>{" "}
            <button onClick={handleClick} id="viewMoreBtn">
              View more
            </button>
          </p>
        ) : isShown == true ? (
          <p class="insta-cov-info-p viewMoreP" id="viewMoreP">
            No Medical Check-Up applies to customers who are in good health. In
            case of any adverse health declaration, the customer might have to
            undergo a medical check-up. <br /> Zero Documentation applies when a
            customer completes the ‘Know Your Customer (KYC) check, subject to
            clear information being available with the Central KYC Registry.
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BandhanContent;
