import React, { useRef, useState } from "react";
import KnowYourInsurer from "./KnowYourInsurer";
import KnowYourPlan from "./KnowYourPlan";
import KnowYourProcess from "./KnowYourProcess";
import MobileKnowYourInsurer from "./MobileKnowYourInsurer";
import MobileKnowYourPlan from "./MobileKnowYourPlan";
import MobileKnowYourProcess from "./MobileKnowYourProcess";

export default function PopupBody({ quote, onClose, setHeight }) {
  const nav = useRef("");
  const defaultOpen = useRef("");
  const kypl = useRef("");
  const kyin = useRef("");
  const kypr = useRef("");

  const show = (e, knowName) => {
    if (setHeight)
      if (knowName === "kypl") setHeight("966px");
      else setHeight("auto");
    kypl.current.style.display = knowName === "kypl" ? "block" : "none";
    kyin.current.style.display = knowName === "kyin" ? "block" : "none";
    kypr.current.style.display = knowName === "kypr" ? "block" : "none";
    for (let i = 0; i < nav.current.children.length; i++) {
      e - 1 !== i
        ? nav.current.children[i].classList.remove("active")
        : nav.current.children[i].classList.add("active");
    }
  };
  const [mobileTab, setMobileTab] = useState(0);
  return (
    <div>
      <div className="mobile-Knowmore">
        <MobileKnowYourPlan
          onClick={() => {
            setMobileTab(0);
          }}
          tab={mobileTab}
          inbuiltCoverages={
            quote?.plan_details?.InbuiltCoverages ||
            quote?.planDetails?.InbuiltCoverages
          }
          additionalCoverages={
            quote?.plan_details?.AdditionalCoverages ||
            quote?.planDetails?.AdditionalCoverages
          }
          additionalFeatures={
            quote?.plan_details?.AdditionalFeatures ||
            quote?.planDetails?.AdditionalFeatures
          }
        />
        <MobileKnowYourInsurer
          onClick={() => {
            setMobileTab(1);
          }}
          tab={mobileTab}
          clamSettlementRatio={quote.claim_settlement_ratio}
          existenceInMarket={quote.formation_year}
          insurerDescription={quote.fact_file}
          numberOfBranches={quote.branches_across_india}
          solvencyRatio={quote.solvency_ratio}
          company_alias={quote.company_alias}
        />
        <MobileKnowYourProcess
          onClick={() => {
            setMobileTab(2);
          }}
          tab={mobileTab}
        />
      </div>
      <div className="navigation" ref={nav}>
        <button
          className="knowBtn left active"
          ref={defaultOpen}
          onClick={() => {
            show("1", "kypl");
          }}
        >
          KNOW YOUR PLAN
        </button>
        <button
          className="knowBtn"
          onClick={() => {
            show("2", "kyin");
          }}
        >
          KNOW YOUR INSURER
        </button>
        <button
          className="knowBtn right"
          onClick={() => {
            show("3", "kypr");
          }}
        >
          KNOW YOUR CLAIM PROCESS
        </button>
      </div>
      <div className="cont" ref={kypl}>
        <KnowYourPlan
          inbuiltCoverages={
            quote?.plan_details?.InbuiltCoverages ||
            quote?.planDetails?.InbuiltCoverages
          }
          additionalCoverages={
            quote?.plan_details?.AdditionalCoverages ||
            quote?.planDetails?.AdditionalCoverages
          }
          additionalFeatures={
            quote?.plan_details?.AdditionalFeatures ||
            quote?.planDetails?.AdditionalFeatures
          }
          companyName={quote.company_name}
          // key={Math.random() * 1000}
          onClose={onClose}
          quote={quote}
        />
      </div>
      <div className="cont" style={{ display: "none" }} ref={kyin}>
        <KnowYourInsurer
          clamSettlementRatio={quote.claim_settlement_ratio}
          existenceInMarket={quote.formation_year}
          insurerDescription={quote.fact_file}
          numberOfBranches={quote.branches_across_india}
          solvencyRatio={quote.solvency_ratio}
          company_alias={quote.company_alias}
        />
      </div>
      <div className="cont" style={{ display: "none" }} ref={kypr}>
        <KnowYourProcess />
      </div>
    </div>
  );
}
