import { getSourceScript } from "./serviceApi";
export const sortQuotesOptions = {
  "Price (high to low)": "Price (high to low)",
  "Price (low to high)": "Price (low to high)",
  "Claims Settlement": "Claims Settlement",
  "Inbuilt Coverages": "Inbuilt Coverages",
  "Insurance Company Experience": "Insurance Company Experience",
  "Max Cover Amount": "Max Cover Amount",
  Price: "Price",
  Medical: "Medical",
};
export const runScript = async utm_source => {
  try {
    const { data } = await getSourceScript(utm_source);
    console.log(data);
    if (data.script_data) {
      const frag = document
        .createRange()
        .createContextualFragment(data.script_data);
      document.body.append(...frag.children);
    }
  } catch (error) {
    console.log(error);
  }
};
export const compareQuotesPrice = (quote1, quote2, options) => {
  console.log("quoteee", quote1);
  if (options.preferredInsurance) {
    let ics = options.preferredInsurance;
    const isMedical1 = quote1.medicalCondition === "Video Medical";
    const isMedical2 = quote2.medicalCondition === "Video Medical";
    if (options.age > 45) {
      ics = ics.filter(item => item !== "max_life");
    }
    if (
      quote1?.cover_amount == "5000000" &&
      quote1?.company_alias == "bandhan_life"
    ) {
      return -1;
    }
    if (
      quote2?.cover_amount == "5000000" &&
      quote2?.company_alias == "bandhan_life"
    ) {
      return 1;
    }
    if (
      ics.includes(quote1.company_alias) &&
      !ics.includes(quote2.company_alias)
    ) {
      return -1;
    } else if (
      !ics.includes(quote1.company_alias) &&
      ics.includes(quote2.company_alias)
    ) {
      return 1;
    } else if (
      parseInt(quote1?.premium_amount) > parseInt(quote2?.premium_amount)
    ) {
      return 1;
    } else {
      return -1;
    }
  }
  // if (quote1?.differentTenureCU !== quote2.differentTenureCU) {
  //   return quote1?.differentTenureCU < quote2.differentTenureCU ? 1 : -1;
  // }
  return parseInt(quote1?.premium_amount) > parseInt(quote2?.premium_amount)
    ? 1
    : -1;
};
export const compareQuotesCSR = (quote1, quote2) =>
  parseInt(Number(quote1?.claim_settlement_ratio?.slice(0, 4))) <
  parseInt(Number(quote2?.claim_settlement_ratio?.slice(0, 4)))
    ? 1
    : -1;

export const sortQuotes = (quotes, sortBy, options) => {
  console.log("sortinggg", options);
  switch (sortBy) {
    case sortQuotesOptions["Price (high to low)"]:
      return [...quotes].sort((a, b) => compareQuotesPrice(a, b, options) * -1);

    case sortQuotesOptions["Price (low to high)"]:
      return [...quotes].sort((a, b) => compareQuotesPrice(a, b, options));

    case sortQuotesOptions.Price:
    case "Relevance":
      return [...quotes].sort((a, b) => compareQuotesPrice(a, b, options));

    case sortQuotesOptions["Max Cover Amount"]:
      return [...quotes].sort((a, b) => compareQuotesPrice(a, b, options));

    case sortQuotesOptions["Claims Settlement"]:
      return [...quotes].sort((a, b) =>
        Number(a.claim_settlement_ratio.slice(0, 4)) <
        Number(b.claim_settlement_ratio.slice(0, 4))
          ? 1
          : -1,
      );

    case sortQuotesOptions["Inbuilt Coverages"]:
      return [...quotes].sort((a, b) => {
        if (a?.inbuilt_riders?.length === b?.inbuilt_riders?.length)
          return compareQuotesPrice(a, b);
        return a?.inbuilt_riders?.length < b?.inbuilt_riders?.length ? 1 : -1;
      });

    case sortQuotesOptions["Insurance Company Experience"]:
      return [...quotes].sort((a, b) => {
        if (parseInt(a?.formation_year) === parseInt(b?.formation_year))
          return compareQuotesCSR(a, b);
        return parseInt(a?.formation_year) > parseInt(b?.formation_year)
          ? 1
          : -1;
      });

    case sortQuotesOptions["Medical"]:
      return [...quotes].sort((a, b) => {
        // if (a?.medicalCondition !== "Physical Medical" && a?.is_pnb_domain)
        // 	return -1;
        if (
          a?.medicalCondition !== "Physical Medical" &&
          b?.medicalCondition !== "Physical Medical"
        )
          return compareQuotesPrice(a, b);
        if (
          a?.medicalCondition === "Physical Medical" &&
          b?.medicalCondition === "Physical Medical"
        )
          return compareQuotesPrice(a, b);
        return a?.medicalCondition !== "Physical Medical" &&
          b?.medicalCondition === "Physical Medical"
          ? -1
          : 1;
      });
    default:
      return quotes;
  }
};

export const filterByPremium = (quotes, premiumType) =>
  quotes.filter(quote => quote.mode_Of_Payment === premiumType);

export const getPayPremiumMode = payPremium => {
  if (payPremium === "Monthly") return " Monthly";
  if (payPremium === "Quarterly") return " Quarterly";
  if (payPremium === "Half Yearly") return " Half Yearly";
  if (payPremium === "Yearly") return " Yearly";
  if (payPremium === "none") return "";
  return "";
};

export const getPayPremiumModeMobile = payPremium => {
  if (payPremium === " Monthly") return "(per month)";
  if (payPremium === " Quarterly") return "(per quarter)";
  if (payPremium === " Half Yearly") return "(per half year)";
  if (payPremium === " Yearly") return "(per year)";
  if (payPremium === "none") return "";
};

export const getPayPremiumModeRider = payPremium => {
  if (payPremium === "Monthly") return " Monthly";
  if (payPremium === "Quarterly") return " Quarterly";
  if (payPremium === "Half Yearly") return " Half Yearly";
  if (payPremium === "Yearly") return " Yearly";
  if (payPremium === "none") return "";
};
export const getPayPremiumModeMobileRider = payPremium => {
  if (payPremium === " Monthly") return "(per month)";
  if (payPremium === " Quarterly") return "(per quarter)";
  if (payPremium === " Half Yearly") return "(per half year)";
  if (payPremium === " Yearly") return "(per year)";
  if (payPremium === "none") return "";
};

export const getPayPremiumModeTabRider = mode => {
  return mode ? mode.replace(" /", "/") : "";
};
