import React, { Fragment } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer2";
import NavBar from "./components/NavBar/NavBar";
import NavBar2 from "./components/NavBar/NavBar2";
import EligibilityPage from "./modules/EligibilityPage/EligibilityPage";
import GreetingPage from "./modules/GreetingPage/GreetingPage";
import QuotePage from "./modules/QuotePage/QuotePage";
import RiderPage from "./modules/RiderPage/RiderPage";
import ThankYou from "./modules/ThankYouPage/ThankYou";
import { ComparePage, PageNotFound } from "./modules";
import Unsuccessful from "./modules/PaymentUnsuccessful/Unsuccessful";
import ProposalPage from "./modules/ProposalPage/ProposalPage";
import { MainContent } from "./components/StyledComponent/style";
import DocumentUploadPage from "./modules/DocumentUploadForm/documentUploadForm";
import MedicalFormCenter from "./modules/MedicalFormCenter/MedicalFormCenter";
// import HdfcProposalPage from "./modules/ProposalPage/HdfcProposalPage/HdfcProposalPage";
import ScrollToTop from "./components/Scroll/ScrollToTop";
import { useLocation } from "react-router-dom";
import BajajProposalPage from "./modules/ProposalPage/BajaProposalPage/BajajProposalPage";
import MaxMedicalCenter from "./modules/MedicalFormCenter/MaxMedicalCenter/MaxMedicalCenter";
import { DocumentUploadBajaj } from "./modules/DocumentUploadForm";
import { FeedbackRatings } from "./components/Rating/Rating";
import OTP from "./modules/otp/OTP";

function App() {
  const history = useHistory();
  const push = link => {
    history.push(link);
  };

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.debug = () => {};
  }

  const location = useLocation();
  const getClasses = () => {
    if (
      location.pathname?.toLowerCase() === "/form2" ||
      location.pathname?.toLowerCase() === "/form3" ||
      location.pathname?.toLowerCase() === "/form4"
    )
      return "mobHeight";
  };
  return (
    <Fragment>
      <NavBar2 />
      <MainContent location={location} className={getClasses()}>
        <ScrollToTop>
          <Switch>
            <Route path="/proposalpage" component={ProposalPage} />
            <Route path="/transactionunsuccessful" component={Unsuccessful} />
            <Route path="/transactionsuccessful" component={ThankYou} />
            <Route path="/eligibilitypage" component={EligibilityPage} />
            <Route path="/riderpage" component={RiderPage} />
            <Route path="/plan-compare" component={ComparePage} />
            <Route path="/quotes" component={QuotePage} />
            <Route path="/documentuploadpage" component={DocumentUploadPage} />
            <Route path="/medical" component={MedicalFormCenter} />
            <Route path="/maxmedical" component={MaxMedicalCenter} />
            <Route path="/thankyou" component={FeedbackRatings} />
            <Route exact path="/otp-verification" component={OTP} />
            <Route
              path="/documentUploadBajaj"
              component={DocumentUploadBajaj}
            />
            {/* <Route path="/kotakproposalpage" component={kotakProposalPage} />
            <Route path="/maxproposalpage" component={MaxLifeProposalPage} /> */}
            <Route path="/bajajproposalpage" component={BajajProposalPage} />
            {/* <Route path="/birlaproposalpage" component={BirlaProposalPage} /> */}
            <Route
              exact
              path="/"
              component={() => <GreetingPage push={push}></GreetingPage>}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ScrollToTop>
      </MainContent>
      {location.pathname !==
        "/term-life-insurance/buy-online-term-insurance-plans" && <Footer />}
    </Fragment>
  );
}

export default App;
