/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import {
  // getQuotes,
  uploadQuote,
  getRider,
  sendQuote,
  getQuotesByIC,
} from "./serviceApi";
import {
  setRidersPremium,
  setSelectedAddOns,
} from "../RiderPage/RiderPage.slice";
import { generateRandomString, getAge } from "../../utils/inputUtils";
import Axios from "axios";
import { updateFiltersNotQuotes } from "../QuoteFilter/quoteFilterSlice";
import { setOpenBajajDiscount } from "../../components/Popup/bajajDiscount.slice";
import { setOpenKotakDiscount } from "../../components/Popup/kotakDiscount.slice";
import { setOpenIciciDiscount } from "../../components/Popup/iciciDiscount.slice";

export const ics = [
  "get_kotak_life_products",
  "get_bajaj_life_products",
  "get_icici_pru_products",
  "get_max_life_products",
  "get_bandhan_life_products",
  "get_pnb_metlife_products",
  "get_hdfc_life_products",
  "get_tata_aia_life_products",
];

const quotePageSlice = createSlice({
  name: "quote",
  initialState: {
    loaderForQuoteToRider: false,
    quotes: [],
    differentTenureQuotes: [],
    selectedQuote: {},
    previousSelectedQuoteId: false,
    isQuotesLoading: true,
    cameFromCompare: false,
    cameFromGreeting: false,
    cameFromEligibilty: false,
    cameFromRiders: false,
    isError: false,
    isQuotesError: false,
    quotesOnCompare: false,
    quotesForCompare: [],
    riderDetails: false,
    quotesRequested: 0,
    riderLoading: false,
    uploadStatus: true,
    quotesLoading: [...ics],
    notApplicableICs: [],
    recommendedQuote: false,
    switchToMedical: false,
    corporateJourney: false,
  },
  reducers: {
    setloaderForQuoteToRider: (state, action) => {
      state.loaderForQuoteToRider = action.payload;
    },
    setRecommendedQuote: (state, action) => {
      state.recommendedQuote = action.payload;
    },
    setDifferentTenureQuotes: (state, action) => {
      state.differentTenureQuotes = action.payload;
    },
    setIsQuotesError: (state, action) => {
      state.isQuotesError = action.payload;
    },
    setQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    setIsQuotesLoading: (state, action) => {
      state.isQuotesLoading = action.payload;
    },
    setCameFromCompare: (state, action) => {
      state.cameFromCompare = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setQuotesOnCompare: (state, action) => {
      state.quotesOnCompare = !state.quotesOnCompare;
    },
    addQuoteToCompare: ({ quotesForCompare }, action) => {
      for (let i = 0; i < 3; i++)
        if (!quotesForCompare.includes(action.payload)) {
          quotesForCompare.push(action.payload);
        }
    },
    removeQuoteToCompare: (state, action) => {
      state.quotesForCompare = state.quotesForCompare.filter(
        id => id !== action.payload,
      );
    },
    setQuotesForCompare: (state, action) => {
      state.quotesForCompare = action.payload;
    },
    uploadSelectedQuote: (state, action) => {
      state.selectedQuote = action.payload;
    },
    setRiderDetails: (state, action) => {
      state.riderDetails = action.payload;
      state.riderLoading = false;
    },
    setQuotesRequested: (state, action) => {
      state.quotesRequested++;
    },
    setRiderLoading: (state, action) => {
      state.riderLoading = action.payload;
    },
    setCameFromGreeting: (state, action) => {
      state.cameFromGreeting = action.payload;
    },
    setCameFromRiders: (state, action) => {
      state.cameFromRiders = action.payload;
    },

    setCameFromEligibility: (state, action) => {
      state.cameFromEligibilty = action.payload;
    },
    setPreviousSelectedQuoteId: (state, action) => {
      state.previousSelectedQuoteId = action.payload;
    },
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload;
    },
    setQuotesLoading: (state, action) => {
      state.quotesLoading = action.payload;
    },
    setNotApplicableICs: (state, action) => {
      state.notApplicableICs = action.payload;
    },
    addNotApplicableICs: (state, action) => {
      state.notApplicableICs = [...state.notApplicableICs, action.payload];
    },
    removeFromNotApplicableIC: (state, action) => {
      state.notApplicableICs = state.notApplicableICs.filter(
        ic => ic !== action.payload,
      );
    },
    setSwitchToMedical: (state, action) => {
      state.switchToMedical = action.payload;
    },
    setCorporateJourney: (state, action) => {
      state.corporateJourney = action.payload;
    },
  },
});

export const IC_LOGO_URLS = {
  // get_max_life_products:
  //   "https://apitermuat.elephant.in/public/uploads/company_logo/maxlife.svg",
  get_icici_pru_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/ipru.svg",
  get_kotak_life_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/kotak.png",
  get_bajaj_life_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/bajaj.png",
  get_bandhan_life_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/bandhanlife.png",
  get_tata_aia_life_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/tataaia_life.png",
  get_pnb_metlife_products:
    "https://apitermuat.elephant.in/public/uploads/company_logo/pnbmetlife.png",
};

let fetchQuotesId;

let source;

export const fetchQuotes =
  ({ traceId, activeICs, active }) =>
  async (dispatch, getState) => {
    // const customerDetails = getState().greeting.customerDetails;
    // let flag = false;
    // if (customerDetails && customerDetails.work_email) {
    //   let temp = customerDetails.work_email;
    //   let domain = temp.split('@')[1];
    //   if (domain === "gameskraft.com") {
    //     flag = true;
    //   }
    // }
    if (!active) {
      activeICs = ics;
    } else {
      activeICs = activeICs.map(item => `get_${item}_products`);
    }
    dispatch(setIsQuotesLoading(true));
    dispatch(setQuotesForCompare([]));
    dispatch(setIsQuotesError(false));
    dispatch(setNotApplicableICs([]));
    dispatch(setQuotes([]));
    dispatch(
      setQuotesLoading([...activeICs, "get_products_with_different_tenure"]),
    );
    let loading_ics = [...activeICs, "get_products_with_different_tenure"];
    try {
      let quotes = [];
      const endTime = Date.now() + 12000;
      const getQuotesForIC = async (ic, planTenure, config) => {
        try {
          if (endTime < Date.now()) {
            // for not callling new api's after loader is gone
            return;
          }
          let time = 9000;
          const r = await getQuotesByIC(ic, { traceId }, config, time);
          const quote_data = r.data?.QuoteData?.quote_data;
          if (quote_data) {
            if (quote_data.length === 0) {
              dispatch(addNotApplicableICs(ic));
            } else {
              if (
                quote_data[0]?.company_alias === "bajaj_life" &&
                quote_data.length < 2
              ) {
                dispatch(setSwitchToMedical(false));
              }
              quotes = [
                ...quotes,
                ...quote_data.map(q => {
                  q.planTenure = planTenure;
                  return q;
                }),
              ];
              dispatch(setQuotes(quotes));
              dispatch(removeFromNotApplicableIC(ic));
            }
          }
          loading_ics = loading_ics.filter(ic1 => ic1 !== ic);
          dispatch(setQuotesLoading(loading_ics));
          // if (planTenure === "different") differentTenureQuotes = true;
          // if (loading_ics.length === 0 && differentTenureQuotes) {
          if (loading_ics.length === 0) {
            dispatch(setIsQuotesLoading(false));
            dispatch(setPreviousSelectedQuoteId(false));
          }
        } catch (error) {
          console.error({ error });
        }
      };

      if (source) {
        source.cancel("Request cancelled due to new request initiated!");
      }

      const id = generateRandomString(10);

      fetchQuotesId = id;

      // if (flag) {
      //   if (fetchQuotesId === id) {
      //     const thisSource = Axios.CancelToken.source();
      //     source = thisSource;

      //     setTimeout(() => {
      //       thisSource.cancel("Request canceleled as it exceeded 15 seconds");
      //     }, 15 * 1000);

      //     // await getQuotesForIC(ic, "same", { cancelToken: source.token }); removing "await" to load all ICs simultaneously
      //     await getQuotesForIC("get_bajaj_life_products", "same", { cancelToken: source.token });
      //     getQuotesForIC("get_products_with_different_tenure", "different", {
      //       cancelToken: source.token,
      //     });
      //   }
      // }
      // else {
      for (let ic of activeICs) {
        if (fetchQuotesId === id) {
          const thisSource = Axios.CancelToken.source();
          source = thisSource;

          setTimeout(() => {
            thisSource.cancel("Request canceleled as it exceeded 15 seconds");
          }, 15 * 1000);

          // await getQuotesForIC(ic, "same", { cancelToken: source.token }); removing "await" to load all ICs simultaneously
          getQuotesForIC(ic, "same", { cancelToken: source.token });
        }
      }

      if (fetchQuotesId === id) {
        const thisSource = Axios.CancelToken.source();
        source = thisSource;
        getQuotesForIC("get_products_with_different_tenure", "different", {
          cancelToken: source.token,
        });
      }
      // }
    } catch (error) {
      dispatch(setIsQuotesLoading(false));
      dispatch(setQuotesRequested());
      console.error(error);
      dispatch(
        setIsQuotesError({
          from: "quotes",
          message: "Something went wrong while fetching quotes",
        }),
      );
    }
  };

export const sendQuoteToEmail = userData => async dispatch => {
  try {
    await sendQuote(userData);
  } catch (error) {
    alert("Error sending quote");
    console.error("ERROR", error);
    dispatch(setIsError("Something went wrong while sending quotes"));
  }
};

export const setSelectedQuote =
  (quotesData, history) => async (dispatch, getState) => {
    const {
      insurance_code,
      premium_amount,
      daily_premium,
      master_product_name,
      child_product_name,
      company_name,
      company_alias,
      traceId,
      annualPremium,
      integration_type,
      premium_amount_gst,
      cover_amount,
      sumAssured,
      planTenure,
      tata_basic_sum_assured,
      tata_rider_sum_assured,
      tata_final_ratio,
      medicalCondition,
      differentTenureCU,
      cgst,
      sgst,
      igst,
      ugst,
      self_employed_premium,
    } = quotesData;
    //for filter update
    const newData1 = {
      paytypeOption:
        planTenure === "same" &&
        getState().quoteFilter.filter.coverUpto === "Whole Life"
          ? "WL"
          : "N",
      policyTerm:
        getState().quoteFilter.filter.coverUpto !== "Whole Life"
          ? parseInt(String(getState().quoteFilter.filter.coverUpto)) -
            (getState().greeting.customerDetails.dob
              ? getAge(getState().greeting.customerDetails.dob)
              : 0)
          : differentTenureCU -
            (getState().greeting.customerDetails.dob
              ? getAge(getState().greeting.customerDetails.dob)
              : 0),
      traceId,
      planTenure,
      requestType: "filters",
    };

    const newData = {
      paytypeOption:
        planTenure === "same" &&
        getState().quoteFilter.filter.coverUpto === "Whole Life"
          ? "WL"
          : "N",
      policyTerm:
        getState().quoteFilter.filter.coverUpto !== "Whole Life"
          ? parseInt(String(getState().quoteFilter.filter.coverUpto)) -
            (getState().greeting.customerDetails.dob
              ? getAge(getState().greeting.customerDetails.dob)
              : 0)
          : differentTenureCU -
            (getState().greeting.customerDetails.dob
              ? getAge(getState().greeting.customerDetails.dob)
              : 0),
      termInsuId: insurance_code,
      premiumAmount: premium_amount,
      dailyPremiumAmount: daily_premium,
      sumAssured: sumAssured || "10000000",
      masterProductName: master_product_name,
      childProductName: child_product_name,
      companyName: company_name,
      companyAlias: company_alias,
      totalPremiumGst: premium_amount_gst,
      annualPremium: annualPremium,
      traceId,
      tata_basic_sum_assured,
      tata_rider_sum_assured,
      tata_final_ratio,
      isMaxLifeDiscount: getState().eligibilityCard.isSalaried,
      integrationType: integration_type,
      cover_amount: cover_amount,
      requestType: "select_product",
      planTenure,
      medicalCondition,
      sgst,
      cgst,
      igst,
      ugst,
    };
    console.log("===", differentTenureCU);
    try {
      dispatch(setIsQuotesLoading("update-quotation"));
      dispatch(uploadSelectedQuote(quotesData));

      dispatch(setUploadStatus(false));
      if (
        planTenure === "different" ||
        getState().quoteFilter.filter.coverUpto === "Whole Life"
      ) {
        dispatch(updateFiltersNotQuotes(newData1));
      }

      let data; // Define the variable outside the block
      if (company_alias !== "bajaj_life") {
        const response = await uploadQuote(newData);
        data = response.data; // Assign the value to the variable
        if (data) {
          dispatch(setloaderForQuoteToRider(false));
        }
      }
      dispatch(setIsQuotesLoading(false));
      const searchParams = new URLSearchParams(window.location.search);
      const afflid = searchParams.get("afflid");
      const utm_medium = searchParams.get("utm_medium");
      const c_id = searchParams.get("c_id");
      const newSumAssured = parseInt(sumAssured);
      if (company_alias === "bajaj_life") {
        dispatch(setOpenBajajDiscount(true));
      }
      if (data.status) {
        if (
          (company_alias === "bandhan_life" && newSumAssured <= 5000000) ||
          company_alias === "tata_aia_life"
        ) {
          history.push({
            pathname: "/eligibilityPage",
            search: `${
              afflid
                ? "afflid=" + afflid + "&"
                : utm_medium
                ? "utm_medium=" + utm_medium + "&"
                : c_id
                ? "c_id" + c_id + "&"
                : ""
            }enquiryId=${traceId}`,
          });
        } else if (company_alias === "bajaj_life") {
          dispatch(setOpenBajajDiscount(true));
        } else if (company_alias === "kotak_life") {
          dispatch(setOpenKotakDiscount(true));
        } else if (
          company_alias === "icici_pru" &&
          self_employed_premium != 0 &&
          getState().quoteFilter.filter.paymentTerm.option !== "Single Pay"
        ) {
          dispatch(setOpenIciciDiscount(true));
        } else {
          history.push({
            pathname: "/riderPage",
            search: `enquiryId=${traceId}`,
          });
        }
      }
      dispatch(setUploadStatus(true));
      dispatch(setRidersPremium({}));
      dispatch(setSelectedAddOns([]));
      dispatch(setIsQuotesLoading(false));
    } catch (error) {
      if (company_alias !== "bajaj_life") {
        dispatch(setIsQuotesLoading(false));
        console.error(error);
        dispatch(
          setIsQuotesError({
            from: "upload-selected-quote",
            message: "Something went wrong while updating quotation",
          }),
        );
      }
    }
  };

export const getRiderDetails = ({ termInsuId, traceId }) => {
  return async dispatch => {
    try {
      dispatch(setRiderLoading(true));
      const { data } = await getRider({
        termInsuId,
        traceId,
      });
      dispatch(setRiderDetails(data));
      return data;
    } catch (error) {
      dispatch(setIsError("Something went wrong while getting riders"));
    }
  };
};

export const {
  setQuotesOnCompare,
  addQuoteToCompare,
  removeQuoteToCompare,
  setQuotesForCompare,
  setIsQuotesLoading,
  setSwitchToMedical,
  uploadSelectedQuote,
  setRiderDetails,
  setQuotes,
  setIsError,
  setQuotesRequested,
  setRiderLoading,
  setCameFromCompare,
  setCameFromGreeting,
  setCameFromRiders,
  setCameFromEligibility,
  setPreviousSelectedQuoteId,
  setUploadStatus,
  setIsQuotesError,
  setQuotesLoading,
  setNotApplicableICs,
  removeFromNotApplicableIC,
  addNotApplicableICs,
  setRecommendedQuote,
  setCorporateJourney,
  setloaderForQuoteToRider,
} = quotePageSlice.actions;

export default quotePageSlice.reducer;

export const selectCameFromRiders = state => state.quote.cameFromRiders;
export const selectCameFromEligibilty = state => state.quote.cameFromEligibilty;
export const selectSelectedQuote = state => state.quote.selectedQuote;
