import { createSlice } from "@reduxjs/toolkit";
import { getMaritalStatus } from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const proposalFormSlice = createSlice({
  name: "proposalForm",
  initialState: {
    proposerDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.proposerDetails = action.payload;
    },
  },
});

export const { saveDetails } = proposalFormSlice.actions;

export const proposerSendData = data => {
  // console.log({ data });
  const sendData = {
    proposerFirstName: data.firstName,
    proposerLastName: data.lastName,
    proposerGenderCode: data.gender === "M" ? "Male" : "Female",
    proposerMaritalStatus: getMaritalStatus(data.martialStatus),
    proposerBirthDate: data["dob"]?.split("/").join("-"),
    proposerEmail: data.email,
    proposerMobNo: data.mobileNo,
    proposerPincode: data.pinCode,
    proposerAddressline1: data.addressLine1,
    proposerAddressline2: data.addressLine2,
    proposerState: data.state,
    proposerCity: data.city,
    pep: data.isPoliticallyExposed,
    proposalJourneyStatus: iciciProposalForms.proposerDetails,
    comm_per: data.comm_per,
    p_address_line_1: data.p_address_line_1,
    p_address_line_2: data.p_address_line_2,
    p_pincode: data.p_pincode,
    p_state: data.p_state,
    p_city: data.p_city,
  };
  return sendData;
};

export const saveProposerDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        data["dob"] = data["dob"].split("-").reverse().join("/");
        if (data["dob"].split("/")[0].length === 4)
          data["dob"] = data["dob"].split("/").reverse().join("/");
        dispatch(saveDetails(data));
        dispatch(appendSendData(proposerSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
      console.error(err);
    }
  };
};

export default proposalFormSlice.reducer;
