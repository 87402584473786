import moment from "moment";

// export const covid19 = ({ countries, ...data } = {}) => [
//   {
//     id: "travelledAbroad",
//     label: "Have you travelled abroad?",
//     items: ["Yes", "No"],
//     value: data?.travelAbroad === "Y" ? "Yes" : "No",
//     size: "xlg",
//     type: "toggle",
//     nextLine: true,
//     smallToggle: true,
//     alignLeft: true,
//     extraMarginTop: true,
//     addCorrespondingItemsOnChange: value => {
//       switch (value) {
//         case "Yes":
//           return [
//             {
//               id: "0",
//               value:
//                 "Please provide your travel patterns over the past 14 days:",
//               type: "title",
//             },
//             {
//               id: "pastTravelsCountries",
//               label: "Name Of Country",
//               size: "md",
//               type: "select",
//               items: countries,
//               searchable: true,
//               value: data?.abroadCountry || "Select",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "Name Of Country is Required",
//                 invalid: "Enter proper value",
//               },
//             },
//             {
//               id: "pastTravelsCities",
//               label: "City",
//               size: "md",
//               value: data?.abroadCity || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "City is Required",
//                 invalid: "Enter proper value",
//               },
//             },
//             {
//               id: "pastTravelsDateArrived",
//               label: "Date Arrived",
//               size: "md",
//               type: "normalDate",
//               nextLine: true,
//               value: data?.abroadDateArr.split("-").reverse().join("/"),
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "Date Arrived is Required",
//                 invalid: "Enter proper value",
//                 custom: [
//                   {
//                     test: value => {
//                       const today = moment();
//                       const arrivedDate = moment(value, "DD/MM/YYYY");
//                       const difference = today.diff(arrivedDate, "days");
//                       if (difference > 14 || difference < 0) return false;
//                       return true;
//                     },
//                     message: "Arrived date should be between past 14 days",
//                   },
//                 ],
//               },
//             },
//             {
//               id: "pastTravelsDateDeparted",
//               label: "Date Departed",
//               size: "md",
//               value: data?.abroadDateDep?.split("-").reverse().join("/"),
//               type: "normalDate",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "Date Departed is Required",
//                 invalid: "Enter proper value",
//                 custom: [
//                   {
//                     test: (value, data) => {
//                       const arrivedDate = moment(
//                         data["pastTravelsDateArrived"],
//                         "DD/MM/YYYY",
//                       );
//                       const departedDate = moment(value, "DD/MM/YYYY");
//                       const difference = arrivedDate.diff(departedDate, "days");
//                       if (difference < 0) return false;
//                       return true;
//                     },
//                     message: "Departed date should be before arrived date",
//                   },
//                 ],
//               },
//             },

//             {
//               id: "1",
//               value:
//                 "Please detail your intended future travel plans for the next 30 days:",
//               type: "title",
//             },

//             {
//               id: "futureTravelsCountries",
//               label: "Name Of Country",
//               size: "md",
//               type: "select",
//               searchable: true,
//               items: countries,
//               value: data?.futureCountry || "Select",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "Name Of Country is Required",
//                 invalid: "Enter proper value",
//               },
//             },
//             {
//               id: "futureTravelsCities",
//               label: "City",
//               size: "md",
//               value: data?.futureCity || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "City is Required",
//                 invalid: "Enter proper value",
//               },
//             },
//             {
//               id: "futureTravelsDateArrived",
//               label: "Date Arrived",
//               size: "md",
//               type: "normalDate",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               value: data?.futureDateArr.split("-").reverse().join("/"),
//               validations: {
//                 required: "Date Arrived is Required",
//                 invalid: "Enter proper value",
//                 custom: [
//                   {
//                     test: (value, data) => {
//                       const dateDeparting = moment(
//                         data["futureTravelsDateDeparted"],
//                         "DD/MM/YYYY",
//                       );
//                       const arrivingDate = moment(value, "DD/MM/YYYY");
//                       const difference = arrivingDate.diff(
//                         dateDeparting,
//                         "days",
//                       );
//                       if (difference < 0) return false;
//                       return true;
//                     },
//                     message: "Arriving date should be after departing date",
//                   },
//                 ],
//               },
//             },
//             {
//               id: "futureTravelsDateDeparted",
//               label: "Date Departed",
//               size: "md",
//               type: "normalDate",
//               nextLine: true,
//               value: data?.futureDateDep.split("-").reverse().join("/"),
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "Date Departed is Required",
//                 invalid: "Enter proper value",
//                 custom: [
//                   {
//                     test: value => {
//                       const today = moment();
//                       const dateDeparting = moment(value, "DD/MM/YYYY");
//                       const difference = dateDeparting.diff(today, "days");
//                       if (difference > 30 || difference < 0) return false;
//                       return true;
//                     },
//                     message:
//                       "Departing date should be between 30 days from today",
//                   },
//                 ],
//               },
//             },
//           ];

//         default:
//           return [];
//       }
//     },
//   },
//   {
//     id: "covid19symtoms",
//     label:
//       "Do you have symptoms like fever, cough, breathlessness, sore throat or diarrhoea; Or, Have you travelled to any foreign country in the last one month; Or, Are you currently in quarantine or self-isolation?",
//     items: ["Yes", "No"],
//     value: data?.covidOne === "Y" ? "Yes" : "No",
//     size: "xlg",
//     type: "toggle",
//     nextLine: true,
//     alignLeft: true,
//     extraMarginTop: true,
//     addCorrespondingItemsOnChange: value => {
//       switch (value) {
//         case "Yes":
//           return [
//             {
//               id: "covid19symtomstext",
//               label: "Please Specify",
//               size: "md",
//               type: "textArea",
//               value: data?.covidOneText || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "This Field is Required",
//               },
//             },
//           ];
//         default:
//           return [];
//       }
//     },
//   },
//   {
//     id: "covid19ContactWithInfected",
//     label:
//       "Are you, or have you been in close contact with anyone who is/was quarantined or self-isolated; or who has been diagnosed with novel coronavirus (SARS-CoV-2/COVID-19)?",
//     items: ["Yes", "No"],
//     value: data?.covidTwo === "Y" ? "Yes" : "No",
//     size: "xlg",
//     type: "toggle",
//     nextLine: true,
//     alignLeft: true,
//     extraMarginTop: true,
//     addCorrespondingItemsOnChange: value => {
//       switch (value) {
//         case "Yes":
//           return [
//             {
//               id: "covid19ContactWithInfectedtext",
//               label: "Please Specify",
//               size: "md",
//               type: "textArea",
//               value: data?.covidTwoText || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "This Field is Required",
//               },
//             },
//           ];
//         default:
//           return [];
//       }
//     },
//   },
//   {
//     id: "covid19Test",
//     label:
//       "Have you been advised to be tested to rule in, or rule out a diagnosis of novel coronavirus (SARSCoV-2/COVID-19); or are you awaiting the result of a test or have been tested positive for the novel coronavirus (SARS-CoV-2/COVID-19)?",
//     items: ["Yes", "No"],
//     value: data?.covidThree === "Y" ? "Yes" : "No",
//     size: "xlg",
//     type: "toggle",
//     nextLine: true,
//     alignLeft: true,
//     extraMarginTop: true,
//     addCorrespondingItemsOnChange: value => {
//       switch (value) {
//         case "Yes":
//           return [
//             {
//               id: "covid19Testtext",
//               label: "Please Specify",
//               size: "md",
//               type: "textArea",
//               value: data?.covidThreeText || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "This Field is Required",
//               },
//             },
//           ];
//         default:
//           return [];
//       }
//     },
//   },

//   {
//     id: "covid19Occupation",
//     label: "Please share your occupation and exact duties?",
//     items: ["Yes", "No"],
//     value: data?.covidFour === "Y" ? "Yes" : "No",
//     size: "xlg",
//     type: "toggle",
//     nextLine: true,
//     alignLeft: true,
//     extraMarginTop: true,
//     addCorrespondingItemsOnChange: value => {
//       switch (value) {
//         case "Yes":
//           return [
//             {
//               id: "covid19Occupationtext",
//               label: "Please Specify",
//               size: "md",
//               type: "textArea",
//               value: data?.covidFourText || "",
//               nextLine: true,
//               alignLeft: true,
//               extraMarginTop: true,
//               validations: {
//                 required: "This Field is Required",
//               },
//             },
//           ];
//         default:
//           return [];
//       }
//     },
//   },
// ];

export const covid19Form = ({ age, countries, ...data } = {}) => {
  // console.log(data);
  return [
    {
      id: "covidOne",
      label: "Have you travelled abroad?",
      items: ["Yes", "No"],
      value: data?.covidOne === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: val => {
        if (val === "Yes") {
          return [
            {
              id: "covidTwo",
              label:
                "Please provide your travel patterns over the past 14 days",
              items: ["Yes", "No"],
              value: data?.covidTwo === "Y" ? "Yes" : "No",
              size: "xlg",
              type: "toggle",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              addCorrespondingItemsOnChange: value => {
                switch (value) {
                  case "Yes":
                    return [
                      {
                        id: "covidTwoCountry",
                        label: "COUNTRY",
                        size: "md",
                        value: data?.covidTwoCountry,
                        nextLine: true,
                        alignLeft: true,
                        extraMarginTop: true,
                        validations: {
                          required: "Name Of Country is Required",
                          invalid: "Enter proper value",
                        },
                      },

                      {
                        id: "covidTwoCity",
                        label: "CITY",
                        size: "md",
                        value: data?.covidTwoCity || "",
                        nextLine: true,
                        alignLeft: true,
                        extraMarginTop: true,
                        validations: {
                          required: "City is Required",
                          invalid: "Enter proper value",
                        },
                      },
                      {
                        id: "covidTwoDateArrived",
                        label: "DATE OF ARRIVAL",
                        size: "md",
                        type: "normalDate",
                        nextLine: true,
                        value: data?.covidTwoDateArrived
                          ? data?.covidTwoDateArrived
                            ?.split("-")
                            .reverse()
                            .join("/")
                          : "",
                        alignLeft: true,
                        extraMarginTop: true,
                        validations: {
                          required: "Date of Arrival is Required",
                          invalid: "Enter proper value",
                          inRange: {
                            range: [moment().subtract(14, "days"), moment()],
                            message: "Please enter a valid date",
                          },
                          isBefore: {
                            id: ["covidTwoDateArrived", "covidTwoDateDeparted"],
                            message: "Arrival Date can't be before departure",
                          },
                          // custom: [
                          //   {
                          //     test: value => {
                          //       const today = moment();
                          //       const inputDate = moment(value, "DD/MM/YYYY");
                          //       const difference = today.diff(inputDate, "years");
                          //       const daydifference = today.diff(inputDate, "days");
                          //       if (difference > age || daydifference < 0) return false;
                          //       return true;
                          //     },
                          //     message: "Enter valid Date of Arrival ",
                          //   },
                          // ],
                        },
                      },

                      {
                        id: "covidTwoDateDeparted",
                        label: "DATE OF DEPARTURE",
                        size: "md",
                        type: "normalDate",
                        nextLine: true,
                        value: data?.covidTwoDateDeparted
                          ? data?.covidTwoDateDeparted
                            ?.split("-")
                            .reverse()
                            .join("/")
                          : "",
                        alignLeft: true,
                        extraMarginTop: true,
                        validations: {
                          required: "Date of Departure is Required",
                          invalid: "Enter proper value",
                          inRange: {
                            range: [moment().subtract(14, "days"), moment()],
                            message: "Please enter a valid date",
                          },
                          isAfter: {
                            id: ["covidTwoDateDeparted", "covidTwoDateArrived"],
                            message: "Depature Date can't be after arrival",
                          },
                          // custom: [
                          //   {
                          //     test: value => {
                          //       const today = moment();
                          //       const inputDate = moment(value, "DD/MM/YYYY");
                          //       const difference = today.diff(inputDate, "years");
                          //       const daydifference = today.diff(inputDate, "days");
                          //       if (difference > age || daydifference < 0) return false;
                          //       return true;
                          //     },
                          //     message: "Enter valid Date of Departure ",
                          //   },
                          // ],
                        },
                      },
                    ];
                  default:
                    return [];
                }
              },
            },
          ];
        } else return [];
      },
    },

    {
      id: "covidThree",
      items: ["Yes", "No"],
      value: data?.covidThree === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      label:
        "Please detail your intended future travel plans for the next 30 days:",
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidThreeCountry",
                label: "COUNTRY",
                size: "md",
                value: data?.covidThreeCountry || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Name Of Country is Required",
                  invalid: "Enter proper value",
                },
              },
              {
                id: "covidThreeCity",
                label: "CITY",
                size: "md",
                value: data?.covidThreeCity || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "City is Required",
                  invalid: "Enter proper value",
                },
              },
              {
                id: "covidThreeDateArrived",
                label: "DATE OF ARRIVAL",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidThreeDateArrived
                  ? data?.covidThreeDateArrived?.split("-").reverse().join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of Arrival is Required",
                  invalid: "Enter proper value",
                  inRange: {
                    range: [moment(), moment().add(30, "days")],
                    message: "Please enter a valid date",
                  },
                  isBefore: {
                    id: ["covidThreeDateArrived", "covidThreeDateDeparted"],
                    message: "Arrival Date can't be before departure",
                  },
                  // custom: [
                  //   {
                  //     test: value => {
                  //       const today = moment();
                  //       const inputDate = moment(value, "DD/MM/YYYY");
                  //       const difference = today.diff(inputDate, "years");
                  //       const daydifference = today.diff(inputDate, "days");
                  //       if (difference > age || daydifference < 0) return false;
                  //       return true;
                  //     },
                  //     message: "Enter valid Date of Arrival ",
                  //   },
                  // ],
                },
              },

              {
                id: "covidThreeDateDeparted",
                label: "DATE OF DEPARTURE",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidThreeDateDeparted
                  ? data?.covidThreeDateDeparted?.split("-").reverse().join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of Departure is Required",
                  invalid: "Enter proper value",
                  inRange: {
                    range: [moment(), moment().add(30, "days")],
                    message: "Please enter a valid date",
                  },

                  isAfter: {
                    id: ["covidThreeDateDeparted", "covidThreeDateArrived"],
                    message: "Depature Date can't be after arrival",
                  },
                  // custom: [
                  //   {
                  //     test: value => {
                  //       const today = moment();
                  //       const inputDate = moment(value, "DD/MM/YYYY");
                  //       const difference = today.diff(inputDate, "years");
                  //       const daydifference = today.diff(inputDate, "days");
                  //       if (difference > age || daydifference < 0) return false;
                  //       return true;
                  //     },
                  //     message: "Enter valid Date of Departure ",
                  //   },
                  // ],
                },
              },
            ];
          default:
            return [];
        }
      },
    },

    {
      id: "covidFour",
      label:
        "Do you have symptoms like fever, cough, breathlessness, sore throat or diarrhoea; Or, Have you travelled to any foreign country in the last one month; Or, Are you currently in quarantine or self-isolation? (if yes, please provide details)      ",
      items: ["Yes", "No"],
      value: data?.covidFour === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidFourText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidFourText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidFive",
      label:
        "Are you, or have you been in close contact with anyone who is/was quarantined or self-isolated; or who has been diagnosed with novel coronavirus (SARS-CoV-2/COVID-19)? If yes, please provide details.",
      items: ["Yes", "No"],
      value: data?.covidFive === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidFiveText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidFiveText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidSix",
      label:
        "Have you been advised to be tested to rule in, or rule out a diagnosis of novel coronavirus (SARSCoV-2/COVID-19)?; or are you awaiting the result of a test or have been tested positive for the novel coronavirus (SARS-CoV-2/COVID-19)?",
      items: ["Yes", "No"],
      value: data?.covidSix === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidSixText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidSixText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidSeven",
      label: "Please share your occupation and exact duties",
      items: ["Yes", "No"],
      value: data?.covidSeven === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidSevenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidSevenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidEight",
      label:
        "Are you working with Hospital, Health care center or having Private practice (Doctor)?",
      items: ["Yes", "No"],
      value: data?.covidEight === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidEightText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidEightText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
              {
                id: "covidNine",
                label: "Medical Specialty (if applicable)       ",
                items: ["Yes", "No"],
                value: data?.covidNine === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidNineText",
                          label: "please provide more details.",
                          size: "xlg",
                          type: "textArea",
                          value: data?.covidNineText || "",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          maxLength: 500,
                          validations: {
                            required: "This Field is Required",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
              {
                id: "covidTen",
                label:
                  "Exact nature of duties (including procedural or non-procedural duties)       ",
                items: ["Yes", "No"],
                value: data?.covidTen === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidTenText",
                          label: "please provide more details.",
                          size: "xlg",
                          type: "textArea",
                          value: data?.covidTenText || "",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          maxLength: 500,
                          validations: {
                            required: "This Field is Required",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
              {
                id: "covidEleven",
                label:
                  "Name and address of the healthcare facility or facilities in which you work",
                items: ["Yes", "No"],
                value: data?.covidEleven === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidElevenText",
                          label: "please provide more details.",
                          size: "xlg",
                          type: "textArea",
                          value: data?.covidElevenText || "",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          maxLength: 500,
                          validations: {
                            required: "This Field is Required",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
              {
                id: "covidTwelve",
                label:
                  "Name of the Health Authority under which you are registered      ",
                items: ["Yes", "No"],
                value: data?.covidTwelve === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidTwelveText",
                          label: "please provide more details.",
                          size: "xlg",
                          type: "textArea",
                          value: data?.covidTwelveText || "",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          maxLength: 500,
                          validations: {
                            required: "This Field is Required",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
              {
                id: "covidThirteen",
                label:
                  "Does your healthcare facility have sufficient Personal Protective Equipment (PPE) to provide to its workforce?       ",
                items: ["Yes", "No"],
                value: data?.covidThirteen === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidThirteenText",
                          label: "please provide more details.",
                          size: "xlg",
                          type: "textArea",
                          value: data?.covidThirteenText || "",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          maxLength: 500,
                          validations: {
                            required: "This Field is Required",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidFourteen",
      label:
        "Have you been or do your work duties involve close contact with anyone who has been quarantined or who has been diagnosed with novel coronavirus (SARS-CoV-2/COVID-19)?  If yes, please provide details including nature of work for patients with novel coronavirus (SARS-CoV-2/COVID-19).       ",
      items: ["Yes", "No"],
      value: data?.covidFourteen === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidFourteenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidFourteenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidFifteen",
      label:
        "Have you ever been on voluntary leave, or placed on compulsory leave of absence/sick leave, due to a possible exposure to novel coronavirus (SARS-CoV-2/COVID-19)? If yes, please provide relevant dates and details.       ",
      items: ["Yes", "No"],
      value: data?.covidFifteen === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidFifteenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidFifteenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidSixteen",
      label:
        "Have you ever tested positive for, or are you awaiting the rest results of novel coronavirus (SARS-CoV-2/COVID-19)? If yes, please provide relevant dates and details, including the results of any test(s) where known      ",
      items: ["Yes", "No"],
      value: data?.covidSixteen === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidSixteenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidSixteenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidSeventeen",
      label: "Are you currently in good health and actively at work?",
      items: ["Yes", "No"],
      value: !data.covidSeventeen
        ? "Yes"
        : data?.covidSeventeen === "Y"
          ? "Yes"
          : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "No":
            return [
              {
                id: "covidSeventeenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidSeventeenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidEighteen",
      label:
        "Currently Or In The Last 3 Months Have You Or Your Family Members Been Tested Positive for covid 19/Have Been Self Isolated With Symptoms On Medical Advice?",
      items: ["Yes", "No"],
      value: data?.covidEighteen === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidEighteenText",
                label: "please provide more details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidEighteenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidNineteen",
      label:
        "Advised to Undergo, Repeat Or awaiting Covid 19 Test/Do You Currently or in the past 1 month have symptoms like persistent cough, breathlessness,fever,raise temperature or flu like symptoms/been in contact with an individual suspected or quarantined or confirmed to have covid-19 or sars cov-2/ or does your or immediate family members occupation require you/them to come in close contact with covid-19 patients or with coronavirus contaminated material?",
      items: ["Yes", "No"],
      value: data?.covidNineteen === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidNineteenText",
                label: "please provide complete details.",
                size: "xlg",
                type: "textArea",
                value: data?.covidNineteenText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                maxLength: 500,
                validations: {
                  required: "This Field is Required",
                  freeText: true,
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidTwenty",
      label:
        "Have You Are Your Immediate family members travelled overseas in the last 45 days or plan to traval outside india during next 6 months?",
      items: ["Yes", "No"],
      value: data?.covidTwenty === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                type: "section",
                label:
                  "Please provide complete details in the declaration given below, if above QUESTIONS is answered yes:                ",
              },
              {
                id: "covidTwentyCountry",
                label: "COUNTRY",
                size: "md",
                value: data?.covidTwentyCountry,
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Name Of Country is Required",
                  invalid: "Enter proper value",
                },
              },

              {
                id: "covidTwentyCity",
                label: "CITY",
                size: "md",
                value: data?.covidTwentyCity || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "City is Required",
                  invalid: "Enter proper value",
                },
              },
              {
                id: "covidTwentyDateArrived",
                label: "DATE OF ARRIVAL",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidTwentyDateArrived
                  ? data?.covidTwentyDateArrived?.split("-").reverse().join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of Arrival is Required",
                  invalid: "Enter proper value",
                  inRange: {
                    range: [
                      moment().subtract(45, "days"),
                      moment().add(6, "months"),
                    ],
                    message: "Please enter a valid date",
                  },

                  isAfter: {
                    id: ["covidTwentyDateDeparted", "covidTwentyDateArrived"],
                    message: "Arrival Date can't be before departure",
                  },

                  // custom: [
                  //   {
                  //     test: value => {
                  //       const today = moment();
                  //       const inputDate = moment(value, "DD/MM/YYYY");
                  //       const difference = today.diff(inputDate, "years");
                  //       const daydifference = today.diff(inputDate, "days");
                  //       if (difference > age || daydifference < 0) return false;
                  //       return true;
                  //     },
                  //     message: "Enter valid Date of Arrival ",
                  //   },
                  // ],
                },
              },

              {
                id: "covidTwentyDateDeparted",
                label: "DATE OF DEPARTURE",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidTwentyDateDeparted
                  ? data?.covidTwentyDateDeparted
                    ?.split("-")
                    .reverse()
                    .join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of Departure is Required",
                  invalid: "Enter proper value",
                  inRange: {
                    range: [
                      moment().subtract(45, "days"),
                      moment().add(6, "months"),
                    ],
                    message: "Please enter a valid date",
                  },

                  isBefore: {
                    id: ["covidTwentyDateArrived", "covidTwentyDateDeparted"],
                    message: "Depature Date can't be after arrival",
                  },
                  // custom: [
                  //   {
                  //     test: value => {
                  //       const today = moment();
                  //       const inputDate = moment(value, "DD/MM/YYYY");
                  //       const difference = today.diff(inputDate, "years");
                  //       const daydifference = today.diff(inputDate, "days");
                  //       if (difference > age || daydifference < 0) return false;
                  //       return true;
                  //     },
                  //     message: "Enter valid Date of Departure ",
                  //   },
                  // ],
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidTwentyOne",
      label: "Have you been vaccinated for COVID19? ",
      items: ["Yes", "No"],
      value: data?.covidTwentyOne === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",

      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidTwentyFirstDose",
                label: "Date of administration of the first dose",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidTwentyFirstDose
                  ? data?.covidTwentyFirstDose.split("-").reverse().join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of First Dose is Required",
                  invalid: "Enter proper value",
                  disableFuture: true,
                  BeforeCovid: true,
                  isSameOrAfter: {
                    id: ["covidTwentyFirstDose", "covidTwentySecondDose"],
                    message: "First dose should be implemented first",
                  },
                  // custom: [
                  //   {
                  //     test: value => {
                  //       const today = moment();
                  //       const inputDate = moment(value, "DD/MM/YYYY");
                  //       const difference = today.diff(inputDate, "years");
                  //       const daydifference = today.diff(inputDate, "days");
                  //       if (difference > age || daydifference < 0) return false;
                  //       return true;
                  //     },
                  //     message: "Enter valid Date  ",
                  //   },
                  // ],
                },
              },

              {
                id: "covidTwentySecondDose",
                label: "Date of administration of the second dose",
                size: "md",
                type: "normalDate",
                nextLine: true,
                value: data?.covidTwentySecondDose
                  ? data?.covidTwentySecondDose.split("-").reverse().join("/")
                  : "",
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Date of Second Dose is required.",
                  invalid: "Enter proper value",
                  disableFuture: true,
                  BeforeCovid: true,
                  isSameOrBefore: {
                    id: ["covidTwentySecondDose", "covidTwentyFirstDose"],
                    message: "Second dose should be implemented after first",
                  },
                },
              },
              {
                id: "covidTwentyVaccine",
                label: "Name of vaccine ",
                size: "md",
                value: data?.covidTwentyVaccine || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "Vaccine Name Required",
                  invalid: "Enter proper value",
                },
              },

              {
                id: "covidTwentyPostVaccination",
                label:
                  "Have you experienced any adverse reaction post vaccination ?",
                items: ["Yes", "No"],
                value: data?.covidTwentyPostVaccination === "Y" ? "Yes" : "No",
                size: "xlg",
                type: "toggle",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                addCorrespondingItemsOnChange: value => {
                  switch (value) {
                    case "Yes":
                      return [
                        {
                          id: "covidTwentyPostVaccineText",
                          label:
                            "please share details including treatment taken for the same and date of complete recovery",

                          value: data?.covidTwentyPostVaccineText || "",
                          type: "textArea",
                          nextLine: true,
                          alignLeft: true,
                          extraMarginTop: true,
                          validations: {
                            required: "Details is Required",
                            invalid: "Enter proper value",
                            freeText: true,
                          },
                        },
                      ];
                    default:
                      return [];
                  }
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    {
      id: "covidTwentyTwo",
      label:
        "Are you, or your family have you ever been serving a notice of quarantine in any form imposed by local health authorities or government or airport authority for possible exposure to novel coronavirus (SARS- CoV2/COVID-19)?",
      items: ["Yes", "No"],
      value: data?.covidTwentyTwo === "Y" ? "Yes" : "No",
      size: "xlg",
      type: "toggle",
      nextLine: true,
      alignLeft: true,
      extraMarginTop: true,
      addCorrespondingItemsOnChange: value => {
        switch (value) {
          case "Yes":
            return [
              {
                id: "covidTwentyTwoText",
                label:
                  "please provide more details like location, dates, quarantine period.",
                size: "xlg",
                type: "textArea",
                value: data?.covidTwentyTwoText || "",
                nextLine: true,
                alignLeft: true,
                extraMarginTop: true,
                validations: {
                  required: "This Field is Required",
                },
              },
            ];
          default:
            return [];
        }
      },
    },
    // {
    //   id: "covidGoodHealth",
    //   label: "Are you currently in good health and actively at work?",
    //   items: ["Yes", "No"],
    //   value: !data.covidGoodHealth
    //     ? "Yes"
    //     : data?.covidGoodHealth === "Y"
    //     ? "Yes"
    //     : "No",
    //   size: "xlg",
    //   type: "toggle",
    //   nextLine: true,
    //   alignLeft: true,
    //   extraMarginTop: true,
    //   validations: {
    //     custom: [
    //       {
    //         notAllowed: true,
    //         value: "No",
    //         message: "You must be in good health to proceed.",
    //       },
    //     ],
    //   },
    // },
  ];
};
