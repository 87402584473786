import {
  getCountryOfResidence,
  getMaritalStatusBajaj,
  getNationKotak,
  getPremiumPayerBajaj,
  getRelationBajaj,
  getRelationAppointeeBajaj,
  getRenewBajaj,
  getEducationBajaj,
  getOccupBajaj,
  getIncomeProofBajaj,
  getAgeProofBajaj,
  getAddressProofBajaj,
  getIDProofBajaj,
  getIndTypeBajaj,
  getTobaccoBajaj,
} from "../../../utils/bajajCodeUtils";
import { checkpinCode } from "../../EligibilityPage/serviceApi";
import { bajajRenewalDeposit } from "./bajajProposalPage.slice";
import { bajajCountryCodes, bajajNationality } from "./data";
import { BAJAJ_COUNTRIES } from "./formsdata/Countries.utils";

export const basicDetailsSendData = (data = {}) => ({
  title: data?.title,
  firstName: data?.firstName,
  middleName: data?.middleName,
  lastName: data?.lastName,
  gender: data?.gender === "Male" ? "M" : "F",
  dob: data["dob"]?.split("/").reverse().join("-"),
  birthPlace: data?.placeOfBirth,
  nationality: bajajNationality[data?.nationality],
  nationalityText: data?.nationality,
  countryOfResidence:
    bajajCountryCodes[data?.countryOfResidence?.toUpperCase()] ||
    bajajCountryCodes[data?.countryOfResidence],
  countryOfResidenceText: data?.countryOfResidence,
  maritalStatus: getMaritalStatusBajaj(data?.meritalStatus),
  maritalStatusText: data?.meritalStatus,
  fatherName: data?.fatherName,
  motherName: data?.motherName,
  spouseName: data?.spouseName,
  spouseDob: data?.spouseDob,
  spouseBirthPlace: data?.spousePlaceOfBirth,
  pep: data?.IsPoliticallyExposed === "No" ? "N" : "Y",
  pepDesc: data?.politicalDescription,
  proposalJourneyStatus: "basicDetails",
  biCitizenship: data?.citizenship === "NRI" ? "NRI" : "IN",
});

export const insuredContactSendData = (data = {}) => ({
  email: data?.email,
  commPer: data?.contactSamePermanentAddress === "Yes" ? "Y" : "N",
  commAddressDoorNo: data?.contactDoorNo,
  commAddressBuildingName: data?.contactBuilding,
  commAddressPlotNo: data?.cstreet,
  commAddressPlace: data?.cplace,
  commAddressPincode: data?.cpincode.pincode,
  commAddressState: data?.cstate,
  commAddressCity: data?.cdistrict,
  commAddressArea: data?.clandmark,
  commAddressLandmark: data?.clandmark2,
  permanentAddressDoorNo:
    data?.contactSamePermanentAddress === "No" && data?.contactRDoorNo1,
  permanentAddressBuildingName:
    data?.contactSamePermanentAddress === "No" && data?.contactRBuilding1,
  permanentAddressPlotNo:
    data?.contactSamePermanentAddress === "No" && data?.crstreet1,
  permanentAddressPlace:
    data?.contactSamePermanentAddress === "No" && data?.crplace1,
  permanentAddressPincode:
    data?.contactSamePermanentAddress === "No" && data?.cpincode1.pincode,
  permanentAddressState:
    data?.contactSamePermanentAddress === "No" && data?.cstate1, //pincodemaste
  permanentAddressCity:
    data?.contactSamePermanentAddress === "No" && data?.cdistrict1, //pincodmaster
  permanentAddressArea:
    data?.contactSamePermanentAddress === "No" && data?.clandmark1,
  permanentAddressLandmark:
    data?.contactSamePermanentAddress === "No" && data?.clandmark121,
  proposalJourneyStatus: "insuredContactDetails",
});

export const payerSendData = (data = {}) => ({
  premiumPaidBy: getPremiumPayerBajaj(data?.paidBy),
  premiumPaidByText: data?.paidBy,
  premiumPayerName: data?.PremiumPayername,
  premiumPayerRelationship: getRelationBajaj(data?.payerRelation),
  premiumPayerRelationshipText: data?.payerRelation,
  premiumPayerDob: data["payerDob"]?.split("/").reverse().join("-"),
  premiumPayerGender: data?.payerGender === "Male" ? "M" : "F",
  premiumPayerAddress: data?.PremiumPayerAddress,
  proposalJourneyStatus: "premiumPayerDetails",
});

export const nomineeSendData = (data = {}) => ({
  nomineeName: data?.nomineeName,
  nomineeBirthPlace: data?.nomineeBirthPlace,
  nomineeDob: data["ndob"]?.split("/").reverse().join("-"),
  // nomineeRelationship: getRelationBajaj(data?.relationWithInsured),
  nomineeRelationship: data?.relationWithInsured,
  nomineeRelationshipText: data?.relationWithInsured,
  nomineeDetails: data?.nomineeDetails,
  appointeeName: data?.appointeeName,
  appointeeDob: data["adob"]?.split("/").reverse().join("-"),
  appointeeRelationToProposer: getRelationAppointeeBajaj(
    data?.arelationWithInsured,
  ),
  appointeeRelationToProposerText: data?.arelationWithInsured,
  proposalJourneyStatus: "nomineeDetails",
});
export const bankDetailsSendData = (data = {}) => ({
  renewalDeposit: bajajRenewalDeposit[data?.renewalDeposit],
  renewalDepositText: data?.renewalDeposit,
  bankName: data?.bankName,
  branchName: data?.branchName,
  accountNo: data?.accountNo,
  micrCode: data?.micrCode,
  accountType: data?.accountType,
  accountTypeText: data?.accountType,
  ifscCode: data?.ifscCode,
  proposalJourneyStatus: "bankAccountDetails",
});

export const occupationDetailsSendData = async (
  data = {},
  { educationICValues, occupationICValues },
) => {
  const permanentAddress = {};
  const enquiryId = new URLSearchParams(window.location.search).get(
    "enquiryId",
  );

  const ckycPersonalDetails =
    data?.ckyc?.ckyc_response?.A51DownloadCkycResponse
      ?.DownloadFromCkycResponseDetails?.CkycDownloadResponseDetail
      ?.CKYCPersonalDetail;

  if (data.ckycNumber) {
    const pincodeResponse = await checkpinCode({
      pinCode: ckycPersonalDetails?.CKYCPerAddPin,
      companyAlias: "bajaj_life",
      traceId: enquiryId,
    });
    permanentAddress.commPer = "N";
    permanentAddress.permanentAddressDoorNo = ckycPersonalDetails?.CKYCPerAdd1;
    permanentAddress.permanentAddressBuildingName =
      ckycPersonalDetails.CKYCPerAdd2;
    permanentAddress.permanentAddressPlotNo =
      ckycPersonalDetails.CKYCPerAdd3 || ckycPersonalDetails.CKYCPerAdd2;
    permanentAddress.permanentAddressPlace =
      ckycPersonalDetails.CKYCPerAdd3 || ckycPersonalDetails.CKYCPerAdd2;
    permanentAddress.permanentAddressPincode =
      ckycPersonalDetails?.CKYCPerAddPin;
    permanentAddress.permanentAddressState = pincodeResponse.data?.state;
    permanentAddress.permanentAddressCity = pincodeResponse.data?.city[0];
    permanentAddress.permanentAddressArea =
      ckycPersonalDetails.CKYCPerAddDistrict;
    permanentAddress.permanentAddressLandmark =
      ckycPersonalDetails.CKYCPerAdd3 || ckycPersonalDetails.CKYCPerAdd2;
  }
  return {
    // education: getEducationBajaj(data?.education),
    education: educationICValues[data?.education],
    educationText: data?.education,
    pan: data?.pan ? data?.pan.toUpperCase() : "",
    // occupation: getOccupBajaj(data?.occupation),
    occupation: occupationICValues[data?.occupation],
    occupationText: data?.occupation,
    annualIncome: data?.annualIncome,
    incomeProof: getIncomeProofBajaj(data?.incomeProof),
    incomeProofText: data?.incomeProof,
    ageProof: getAgeProofBajaj(data?.ageProof),
    ageProofText: data?.ageProof,
    addressProof: getAddressProofBajaj(data?.addressProof),
    addressProofText: data?.addressProof,
    idProof: getIDProofBajaj(data?.idProof),
    idProofText: data?.idProof,
    industryType: getIndTypeBajaj(data?.industryType),
    industryTypeText: data?.industryType,
    armedForceBranch: data?.branchOfArmedForces1,
    armedForceRank: data?.rank1,
    armedForceLocation: data?.currentLocationOfPosting1,
    combactRoleFlag: data?.jobInvolvesCombactRole1 === "Yes" ? "Y" : "N",
    bombDisposalFlag: data?.isPartOfBombDisposalSquad1 === "Yes" ? "Y" : "N",
    eiaFlag: data?.haveEIA === "Yes" ? "Y" : "N",
    eiaRepository: data?.eiaInsuranceRepository,
    natureOfDuties: data?.natureofDuties,
    employersName: data?.employersName, //doubt
    employerName: data?.employerName,
    employersAddress: data?.employersAddress,
    proposalJourneyStatus: "occupationDetails",
    ckyc_number: data.ckycNumber,
    ...permanentAddress,
  };
};

export const nriDetailsSendData = (data = {}) => ({
  nriFlag: data?.travelAbroad === "Yes" ? "Y" : "N",
  nriCountry: BAJAJ_COUNTRIES[data?.countryName] || "",
  nriCountryText: data?.countryName || "",
  nriCity: data?.cityName || "",
  nriDuration: data?.purpose || "",
  modifiedTerm: data?.requestDeclined === "Yes" ? "Y" : "N",
  annualPremiumPolicy: data?.annualPremium || "",
  holdPolicy: data?.haveOtherInsurance === "Yes" ? "Y" : "N",
  otherInsuranceCompany:
    data?.haveOtherInsurance === "Yes" ? data?.otherInsuranceList : null,
  proposalJourneyStatus: "nriAndPreviousInsuranceDetails",
});

export const factaSendData = (data = {}) => ({
  residentOutside: data?.resident === "Yes" ? "Y" : "N",
  residentCountry: data?.resident1,
  //	residentCountryText:  data?.resident1,
  residentCountryAddress: data?.resident2,
  taxresidentOutside: data?.tax === "Yes" ? "Y" : "N",
  taxresidentCountry: data?.tax1,
  taxresidentCountryTin: data?.tax2,
  telephoneOutside: data?.telephoneNo === "Yes" ? "Y" : "N",
  telephoneMobile: data?.telephoneNo1,
  telephoneLandline: data?.telephoneNo2,
  accountOutside: data?.instructions === "Yes" ? "Y" : "N", //doubt
  accountOutsideText: data?.instructions1,
  attorneyOutside: data?.attorney === "Yes" ? "Y" : "N",
  attorneyPerson: data?.attorney1,
  attorneyAddress: data?.attorney2,
  attorneyContact: data?.attorney3,
  holdmailOutside: data?.holdMail === "Yes" ? "Y" : "N",
  holdmailText: data?.holdMail1,
  proposalJourneyStatus: "fatcaDetails",
});

export const medSet1sendData = (data = {}) => ({
  height: data?.height,
  weight: data?.weight,
  changedWeight: data?.weightChanged,
  changeInWeight: data?.gained1,
  causeChangeInWeight: data?.gained2,
  tobaccoHabit: data?.consuming === "Yes" ? "Y" : "N",
  tobaccoProduct: getTobaccoBajaj(data?.usedAsProduct),
  tobaccoProductText: data?.usedAsProduct,
  tobaccoQuantity: data?.quantityT,
  tobaccoQuitSince: data?.ifQuit?.replace(/ /g, ""),
  alcoholHabit: data?.alcohol === "Yes" ? "Y" : "N",
  alcoholConsumption: data?.quantity,
  alcoholQuantity: data?.frequency,
  alcoholIncrease: data?.increased,
  alcoholIncreaseText: data?.increased,
  proposalJourneyStatus: "medicalDetails1",
});

export const medSet2sendData = (data = {}) => ({
  narcoticsHabit: data?.narcoticTreatment === "Y" ? "Y" : "N",
  narcoticsHabitText: data?.narcoticTreatmentText,
  criminalConvicted: data?.criminalCharges === "Y" ? "Y" : "N",
  criminalConvictedText: data?.criminalChargesText,
  adventureAvocation: data?.extremeSports === "Y" ? "Y" : "N",
  adventureAvocationText: data?.extremeSportsText,
  pregnantQuestion: data?.pregnantQuestion === "Y" ? "Y" : "N",
  gynecologicalQuestion: data?.gynecologicalQuestion === "Y" ? "Y" : "N",
  insuredHealthQuestion: data?.diagnosed === "Y" ? "Y" : "N", //doubt
  insuredHealthQuestionText: data?.diagnosedtext,
  insuranceCoverage: data?.insuranceCoverage,
  annualIncomeHusband: data?.annualIncomeHusband,
  familyHealthQuestion: data?.haveFamilyDiseaseHistory === "Y" ? "Y" : "N",
  familyDetails:
    data?.haveFamilyDiseaseHistory === "Y" //renameRequired
      ? data?.familyDiseaseHistoryList
      : null,
  proposalJourneyStatus: "medicalDetails2",
});

export const medSet3sendData = (data = {}) => ({
  chestPainQuestion: data?.Disease1 === "Yes" ? "Y" : "N",
  chestPainDiseaseName: data?.Disease1DropDown,
  chestPainDiseaseDiagnosed: data?.Disease1Time,
  chestPainCurrMedication: data?.Disease1Time,
  chestPainControlVal: data?.Disease1ControlValue,
  chestPainOtherComplication: data?.Disease1ControlComplication,
  angioplastyQuestion: data?.Disease2 === "Yes" ? "Y" : "N",
  angioplastyDiseaseName: data?.Disease2DropDown,
  angioplastyDiseaseDiagnosed: data?.Disease2Time,
  angioplastyCurrMedication: data?.Disease2Medication,
  angioplastyControlVal: data?.Disease2Value,
  angioplastyOtherComplication: data?.Disease2Complication,
  diabetesQuestion: data?.diseases3 === "Yes" ? "Y" : "N",
  diabetesDiseaseName: data?.diseases3Diseases,
  diabetesDiseaseDiagnosed: data?.diseases3Time,
  diabetesCurrMedication: data?.diseases3Medication,
  diabetesControlVal: data?.diseases3ControlValue,
  diabetesOtherComplication: data?.diseases3Complication,
  asthmaQuestion: data?.diseases4 === "Yes" ? "Y" : "N",
  asthmaDiseaseName: data?.diseases4Diseases,
  asthmaDiseaseDiagnosed: data?.diseases4Time,
  asthmaCurrMedication: data?.diseases4Medication,
  asthmaControlVal: data?.diseases4ControlValue,
  asthmaOtherComplication: data?.diseases4Complication,
  strokeQuestion: data?.diseases5 === "Yes" ? "Y" : "N",
  strokeDiseaseName: data?.diseases5Diseases,
  strokeDiseaseDiagnosed: data?.diseases5Time,
  strokeCurrMedication: data?.diseases5Medication,
  strokeControlVal: data?.diseases5ControlValue,
  strokeOtherComplication: data?.diseases5Complication,
  pancreaQuestion: data?.diseases6 === "Yes" ? "Y" : "N",
  pancreaDiseaseName: data?.diseases6Diseases,
  pancreaDiseaseDiagnosed: data?.diseases6Time,
  pancreaCurrMedication: data?.diseases6Medication,
  pancreaControlVal: data?.diseases6ControlValue,
  pancreaOtherComplication: data?.diseases6Complication,
  liverQuestion: data?.diseases7 === "Yes" ? "Y" : "N",
  liverDiseaseName: data?.diseases7Diseases,
  liverDiseaseDiagnosed: data?.diseases7Time,
  liverCurrMedication: data?.diseases7Medication,
  liverControlVal: data?.diseases7ControlValue,
  liverOtherComplication: data?.diseases7Complication,
  proposalJourneyStatus: "medicalDetails3",
});

export const medSet4sendData = (data = {}) => ({
  kidneyQuestion: data?.diseases8 === "Yes" ? "Y" : "N",
  kidneyDiseaseName: data?.diseases8Diseases,
  kidneyDiseaseDiagnosed: data?.diseases8Time,
  kidneyCurrMedication: data?.diseases8Medication,
  kidneyControlVal: data?.diseases8ControlValue,
  kidneyOtherComplication: data?.diseases8Complication,
  cancerQuestion: data?.diseases9 === "Yes" ? "Y" : "N",
  cancerDiseaseName: data?.diseases9Diseases,
  cancerDiseaseDiagnosed: data?.diseases9Time,
  cancerCurrMedication: data?.diseases9Medication,
  cancerControlVal: data?.diseases9ControlValue,
  cancerOtherComplication: data?.diseases9Complication,
  hivQuestion: data?.diseases10 === "Yes" ? "Y" : "N",
  hivDiseaseName: data?.diseases10Diseases,
  hivDiseaseDiagnosed: data?.diseases10Time,
  hivCurrMedication: data?.diseases10Medication,
  hivControlVal: data?.diseases10ControlValue,
  hivOtherComplication: data?.diseases10Complication,
  anemiaQuestion: data?.diseases11 === "Yes" ? "Y" : "N",
  anemiaDiseaseName: data?.diseases11Diseases,
  anemiaDiseaseDiagnosed: data?.diseases11Time,
  anemiaCurrMedication: data?.diseases11Medication,
  anemiaControlVal: data?.diseases11ControlValue,
  anemiaOtherComplication: data?.diseases11Complication,
  handicapQuestion: data?.diseases12 === "Yes" ? "Y" : "N",
  handicapDiseaseName: data?.diseases12Diseases,
  handicapDiseaseDiagnosed: data?.diseases12Time,
  handicapCurrMedication: data?.diseases12Medication,
  handicapControlVal: data?.diseases12ControlValue,
  handicapOtherComplication: data?.diseases12Complication,
  surgeryQuestion: data?.diseases13 === "Yes" ? "Y" : "N",
  surgeryDiseaseName: data?.diseases13Diseases,
  surgeryDiseaseDiagnosed: data?.diseases13Time,
  surgeryCurrMedication: data?.diseases13Medication,
  surgeryControlVal: data?.diseases13ControlValue,
  surgeryOtherComplication: data?.diseases13Complication,
  sensesQuestion: data?.diseases14 === "Yes" ? "Y" : "N",
  sensesDiseaseName: data?.diseases14Diseases,
  sensesDiseaseDiagnosed: data?.diseases14Time,
  sensesCurrMedication: data?.diseases14Medication,
  sensesControlVal: data?.diseases14ControlValue,
  sensesOtherComplication: data?.diseases14Complication,
  medicalExaminationQuestion: data?.diseases15 === "Yes" ? "Y" : "N",
  medicalExaminationQuestionText: data?.diseases15text,
  proposalJourneyStatus: "medicalDetails4",
});

export const covid19sendData = (data = {}) => ({
  // travelAbroad: data?.travelledAbroad === "Yes" ? "Y" : "N",
  // abroadCountry: data?.pastTravelsCountries,
  // abroadCity: data?.pastTravelsCities,
  // abroadDateArr: data["pastTravelsDateArrived"]?.split("/").reverse().join("-"),
  // abroadDateDep: data["pastTravelsDateDeparted"]
  // 	?.split("/")
  // 	.reverse()
  // 	.join("-"),
  // futureCountry: data?.futureTravelsCountries,
  // futureCity: data?.futureTravelsCities,
  // futureDateArr: data["futureTravelsDateArrived"]
  // 	?.split("/")
  // 	.reverse()
  // 	.join("-"),
  // futureDateDep: data["futureTravelsDateDeparted"]
  // 	?.split("/")
  // 	.reverse()
  // 	.join("-"),
  covidOne: data?.covidOne === "Yes" ? "Y" : "N",
  covidOneText: data?.covidOneText,
  covidTwo: data?.covidTwo === "Yes" ? "Y" : "N",
  covidTwoCountry: data?.covidTwoCountry,
  covidTwoCity: data?.covidTwoCity,
  covidTwoDateArrived: data?.covidTwoDateArrived,
  covidTwoDateDeparted: data?.covidTwoDateDeparted,
  covidThree: data?.covidThree === "Yes" ? "Y" : "N",
  covidThreeCountry: data?.covidThreeCountry,
  covidThreeCity: data?.covidThreeCity,
  covidThreeDateArrived: data?.covidThreeDateArrived,
  covidThreeDateDeparted: data?.covidThreeDateDeparted,
  covidFour: data?.covidFour === "Yes" ? "Y" : "N",
  covidFourText: data?.covidFourText,
  covidFive: data?.covidFive === "Yes" ? "Y" : "N",
  covidFiveText: data?.covidFiveText,
  covidSix: data?.covidSix === "Yes" ? "Y" : "N",
  covidSixText: data?.covidSixText,
  covidSeven: data?.covidSeven === "Yes" ? "Y" : "N",
  covidSevenText: data?.covidSevenText,
  covidEight: data?.covidEight === "Yes" ? "Y" : "N",
  covidEightText: data?.covidEightText,
  covidNine: data?.covidNine === "Yes" ? "Y" : "N",
  covidNineText: data?.covidNineText,
  covidTen: data?.covidTen === "Yes" ? "Y" : "N",
  covidTenText: data?.covidTenText,
  covidEleven: data?.covidEleven === "Yes" ? "Y" : "N",
  covidElevenText: data?.covidElevenText,
  covidTwelve: data?.covidTwelve === "Yes" ? "Y" : "N",
  covidTwelveText: data?.covidTwelveText,
  covidThirteen: data?.covidThirteen === "Yes" ? "Y" : "N",
  covidThirteenText: data?.covidThirteenText,
  covidFourteen: data?.covidFourteen === "Yes" ? "Y" : "N",
  covidFourteenText: data?.covidFourteenText,
  covidFifteen: data?.covidFifteen === "Yes" ? "Y" : "N",
  covidFifteenText: data?.covidFifteenText,
  covidSixteen: data?.covidSixteen === "Yes" ? "Y" : "N",
  covidSixteenText: data?.covidSixteenText,
  covidSeventeen: data?.covidSeventeen === "Yes" ? "Y" : "N",
  covidSeventeenText: data?.covidSeventeenText,
  covidEighteen: data?.covidEighteen === "Yes" ? "Y" : "N",
  covidEighteenText: data?.covidEighteenText,
  covidNineteen: data?.covidNineteen === "Yes" ? "Y" : "N",
  covidNineteenText: data?.covidNineteenText,
  covidTwenty: data?.covidTwenty === "Yes" ? "Y" : "N",
  covidTwentyCountry: data?.covidTwentyCountry,
  covidTwentyCity: data?.covidTwentyCity,
  covidTwentyDateArrived: data?.covidTwentyDateArrived,
  covidTwentyDateDeparted: data?.covidTwentyDateDeparted,
  covidTwentyOne: data?.covidTwentyOne === "Yes" ? "Y" : "N",
  covidTwentyFirstDose: data?.covidTwentyFirstDose,
  covidTwentySecondDose: data?.covidTwentySecondDose,
  covidTwentyVaccine: data?.covidTwentyVaccine,
  covidTwentyPostVaccination:
    data?.covidTwentyPostVaccination === "Yes" ? "Y" : "N",
  covidTwentyPostVaccineText: data?.covidTwentyPostVaccineText,
  covidTwentyTwo: data?.covidTwentyTwo === "Yes" ? "Y" : "N",
  covidTwentyTwoText: data?.covidTwentyTwoText,
  proposalJourneyStatus: "covid19Details",
});
