import React, { useState, useRef, useEffect } from "react";
import { useKeydownEvent, useOutsideClick } from "../../custom-hooks";
import { DropDownItems } from "..";
import arrowSelect from "../../assets/img/ic_keyboard_arrow_down_24px.svg";
import "./smallCustomDropDown.scss";

function CustomDropDown({
  items,
  searchable,
  selectedItem,
  className,
  customInput,
  expand,
  style,
  onChange,
  isOpen,
  notAllowed,
  validate,
  validateMessage,
  currentSelected,
  disabled,
  min,
  max,
  multiple,
  roundTo,
  formBuilderDropdown,
  hideArrow,
}) {
  const [open, setOpen] = useState(expand);
  const [selected, setSelected] = useState(
    selectedItem ? selectedItem : items[0],
  );

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setOpen(false));
  useKeydownEvent("Escape", () => setOpen(false));

  useEffect(() => {
    if (currentSelected) setSelected(currentSelected);
  }, [currentSelected]);
  const handleItemClick = item => {
    if (selected !== item) {
      setSelected(item);
      onChange && onChange(item);
    }
    setOpen(false);
  };

  useEffect(() => {
    isOpen && isOpen(open);
  }, [open, isOpen]);

  return (
    <div className="small-custom-drop-down" ref={dropDownRef}>
      {!open ? (
        <div
          className={`small-custom-drop-down__head ${
            validate && notAllowed && notAllowed === selected && "border-red"
          } ${className ? className : ""} ${
            disabled ? "small-custom-drop-down__disabled" : ""
          }`}
          style={style ? style : {}}
          onClick={() => setOpen(true)}
        >
          <span
            className={`small-custom-drop-down__selected-text ${
              formBuilderDropdown
                ? "small-custom-drop-down__selected-textFormBuilder"
                : ""
            }`}
          >
            {currentSelected || selected}
          </span>
          <img
            style={{ display: hideArrow && "none" }}
            src={arrowSelect}
            className="small-custom-drop-down__arrow"
            alt="arrow"
          />
        </div>
      ) : (
        <div className="small-custom-drop-down__wrapper">
          <div
            className="small-custom-drop-down__head small-custom-drop-down__head--open"
            style={style ? style : {}}
            onClick={() => setOpen(false)}
          >
            <span
              className={`small-custom-drop-down__selected-text ${
                formBuilderDropdown
                  ? "small-custom-drop-down__selected-textFormBuilder"
                  : ""
              }`}
            >
              {currentSelected || selected}
            </span>
            <img
              src={arrowSelect}
              className="small-custom-drop-down__arrow small-custom-drop-down__arrow--invert"
              alt="arrow"
            />
          </div>

          <DropDownItems
            notAllowed={notAllowed && notAllowed}
            items={items}
            handleClick={handleItemClick}
            selected={currentSelected || selected}
            searchable={searchable}
            customInput={customInput}
            roundTo={roundTo}
            min={min}
            max={max}
            multiple={multiple}
          />
        </div>
      )}
      {validate && notAllowed && notAllowed === selected && (
        <p
          style={{
            fontSize: "14px",
            color: "#d43d3d",
            position: "absolute",
            fontFamily: "Inter-Regular",
          }}
        >
          {validateMessage ? validateMessage : "This is required*"}
        </p>
      )}
    </div>
  );
}

export default CustomDropDown;
