import { createSlice } from "@reduxjs/toolkit";
import {
  getAddressProof,
  getAgeProof,
  getEiaRepo,
  getIdentificationDetails,
  getIncomeProof,
  getItProof,
} from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const idForm = createSlice({
  name: "idForm",
  initialState: {
    idDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.idDetails = action.payload;
    },
  },
});
export const { saveDetails } = idForm.actions;

export const idDetailsSendData = (data = {}) => ({
  ageProof: getAgeProof(data.ageProof),
  addressProof: getAddressProof(data.addressProof),
  incomeProof: getIncomeProof(data.incomeProof),
  isEiaOpted: data.EIA ? "Yes" : "No",
  idProof: getIdentificationDetails(data.idProof),
  itProof: getItProof(data.insRepo),
  proposerAadharNo: data.aadhar ? data.aadhar : "",
  proposerPanNo: data.panNo,
  eiaDetails: getEiaRepo(data.preferredInsurance),
  eiaAccNo: data.eiaNumber || "",
  proposalJourneyStatus: iciciProposalForms.identificationDetails,
});

export const saveIdDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(idDetailsSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default idForm.reducer;
