import React from "react";
import { useSelector } from "react-redux";
import "../RecommendQuoteCard/recommend-quote-card.scss";
import "./quote-card.scss";

function OnCompare({ checked, recommended, onClick, ...props }) {
  const { isPnbPreference } = useSelector(({ greeting }) => greeting);
  const mainClass = recommended ? "recommend-quote-card" : "quote-card";

  return (
    <div
      style={{ marginBottom: "50px" }}
      css={`
        width: ${recommended && "75%"};
        @media only screen and (min-width: 768px) and (max-width: 1177px) {
          width: ${recommended && "100%"};
        }
        @media only screen and (max-width: 768px) {
          width: ${recommended && "100%"};
        }
      `}
      className={isPnbPreference && recommended ? "borderWhiteList" : ""}
    >
      <div
        className={`${mainClass} ${mainClass}--on-compare
     ${checked ? `${mainClass}--selected` : ""} ${
          isPnbPreference && recommended ? "whitelisted-card" : ""
        } `}
      >
        <div
          style={{
            // width: "100%",
            // height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            cursor: "pointer",
            zIndex: "2",
          }}
          // onClick={onClick}
        />
        <div className="checkmark" onClick={onClick}>
          <div
            className="checked-icon"
            style={{ display: `${checked ? "block" : "none"}` }}
          ></div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default OnCompare;
