import React from "react";
import "./LaxmiGreeting.scss";
import laxmi from "../../assets/img/girl-img.png";
import { useLocation } from "react-router-dom";

const LaxmiGreeting = () => {
  const location = useLocation();
  const getClasses = () => {
    if (
      location.pathname?.toLowerCase() === "/eligibilitypage" ||
      location.pathname?.toLowerCase() === "/riderPage" ||
      location.pathname?.toLowerCase() === "/proposalpage"
    )
      return "laxmigreeting__wrapper eligibilitypage__laxmiGreeting laxmigreeting__wrapperRider";
    if (location.pathname?.toLowerCase() === "/form4")
      return "laxmigreeting__wrapper laxmigreeting__greetimg laxmigreeting__form4";
    if (location.pathname?.toLowerCase() === "/form2")
      return "laxmigreeting__wrapper laxmigreeting__greetimg laxmigreeting__form2";

    if (location.pathname?.toLowerCase() === "/form3")
      return "laxmigreeting__wrapper laxmigreeting__greetimg laxmigreeting__form3";
    else return "laxmigreeting__wrapper";
  };

  return (
    <div
      className={
        location.pathname?.toLowerCase() === "/riderPage"
          ? "u-tcentergreetingRider"
          : location.pathname?.toLowerCase() === "/eligibilitypage" ||
            location.pathname?.toLowerCase() === "/proposalpage"
          ? " u-tcentergreetingElig"
          : "u-tcentergreeting"
      }
    >
      <div className={getClasses()}>
        <img
          src={laxmi}
          alt="Laxmi"
          className={
            location.pathname?.toLowerCase() === "/riderPage" ||
            location.pathname?.toLowerCase() === "/eligibilitypage" ||
            location.pathname?.toLowerCase() === "/proposalpage"
              ? "laxmiGreeting__imgRider laxmiGreeting__img"
              : "laxmiGreeting__img"
          }
        ></img>
      </div>
    </div>
  );
};

export default LaxmiGreeting;
