import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberToDigitWord } from "../modules/QuoteFilter/helper";
import { setDisplayNomineeGets } from "../modules/QuoteFilter/quoteFilterSlice";
import { getNumberfromString } from "../utils/inputUtils";

function useDisplayNomineeGets() {
  const dispatch = useDispatch();

  const {
    coverAmount,
    nomineeGets,
    coverAmountMonthly,
    coverAmountLumpsumMonthly,
    morePlans,
  } = useSelector(({ quoteFilter }) => quoteFilter.filter);

  console.log(coverAmount);
  const displayNomineeGets =
    morePlans === "Survival Benefits"
      ? `${numberToDigitWord(
          (getNumberfromString(coverAmount) * 5) / 100,
          1,
          2,
        )} (Lumpsum) + ${numberToDigitWord(
          (getNumberfromString(coverAmount) * 0.1) / 100,
          1,
          2,
        )} (Monthly Income)
			  `
      : nomineeGets === "Lumpsum"
      ? coverAmount + " (Lumpsum)"
      : nomineeGets === "Monthly"
      ? `${coverAmountMonthly.value} monthly for ${coverAmountMonthly.term}`
      : nomineeGets === "Lumpsum + Monthly Income"
      ? coverAmountLumpsumMonthly.coverAmountPercent.includes("100")
        ? `${
            coverAmountLumpsumMonthly.coverAmount
          } (Lumpsum) + ${numberToDigitWord(
            Number(coverAmountLumpsumMonthly.additionalIncome.amount),
            1,
            2,
          )} monthly for ${coverAmountLumpsumMonthly.additionalIncome.period} ${
            !coverAmountLumpsumMonthly.additionalIncome.period.includes("years")
              ? coverAmountLumpsumMonthly.additionalIncome.period === "100"
                ? "months"
                : "years"
              : ""
          }`
        : `${numberToDigitWord(
            (getNumberfromString(coverAmountLumpsumMonthly.coverAmount) *
              parseInt(coverAmountLumpsumMonthly.coverAmountPercent)) /
              100,
            1,
            2,
          )} (Lumpsum) + ${numberToDigitWord(
            coverAmountLumpsumMonthly.additionalIncome.amount,
            1,
            2,
          )} monthly for ${coverAmountLumpsumMonthly.additionalIncome.period} ${
            !coverAmountLumpsumMonthly.additionalIncome.period.includes(
              "years",
            ) &&
            !coverAmountLumpsumMonthly.additionalIncome.period.includes(
              "months",
            )
              ? coverAmountLumpsumMonthly.additionalIncome.period === "100"
                ? "months"
                : "years"
              : ""
          }`
      : "";

  useEffect(() => {
    dispatch(setDisplayNomineeGets(displayNomineeGets));
  }, [nomineeGets, displayNomineeGets, dispatch]);

  return { displayNomineeGets };
}

export default useDisplayNomineeGets;
