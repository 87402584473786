import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCameFromCompare } from "../QuotePage/quotePageSlice";
import { useCustomLocation, useDocumentTitle } from "../../custom-hooks";
import { comparePageWindowScrollEvent } from "./comparePageServices";

function useComparePage() {
  const dispatch = useDispatch();

  useDocumentTitle("Life Term Plan Compare");

  const { gotoPage, enquiryId } = useCustomLocation();

  const quotesForCompare = useSelector(({ quote }) => quote.quotesForCompare);

  const [isEmailQuotesPopup, setIsEmailQuotesPopup] = useState(false);

  useEffect(() => {
    if (quotesForCompare.length !== 0) {
      comparePageWindowScrollEvent();
    }
  }, [quotesForCompare.length]);

  const handleBackButtonClick = () => {
    dispatch(setCameFromCompare(true));
    gotoPage(gotoPage.pages.quotes);
  };

  const showEmailQuotesPopup = () => setIsEmailQuotesPopup(true);
  const hideEmailQuotesPopup = () => setIsEmailQuotesPopup(false);

  const quotesPagePath = `/quotes?enquiryId=${enquiryId}`;

  return {
    quotesForCompare,
    quotesPagePath,
    isEmailQuotesPopup,
    handleBackButtonClick,
    showEmailQuotesPopup,
    hideEmailQuotesPopup,
  };
}

export default useComparePage;
