import { yupResolver } from "@hookform/resolvers";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FamilyImg from "../../assets/img/heroFamily.png";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { config } from "./config";
import EmailPopup from "./EmailPopup";
import {
  AgeWrapper,
  CheckMark,
  Container,
  CustomInput,
  CustomWrap,
  Form,
  FormGroup,
  GenderFieldCust,
  Label,
  LaxmiImg,
  LaxmiWrap,
  MainHeader,
  ProceedButton,
  ProceedWrap,
  Radio,
  RadioButton,
  RadioGroup,
  RadioText,
  Row,
  SubHeader,
  TermCond,
  TextInput,
  Tooltip,
  ErrorMessage,
  CmnTopWrap,
  CmnBlue,
  CmnYellow,
  CmnSkyBlue,
  CmnTitle,
  CmnImgWrap,
  CmnFamilyImg,
  BlueSub,
  CmnBottomWrap,
  CmnRibbonBanner,
  CmnBottomTitle,
  CmnTerms,
} from "./Form1.style";
import { Button } from "../../components/StyledButton/Button";
import LaxmiGreeting from "../../assets/img/laxmiImg.png";
import tooltip from "../../assets/img/tooltip.svg";
import {
  dateUtil,
  numOnly,
  noNum,
  noSpecial,
  getAge,
  getAmount,
  getNumberfromString,
  getValue,
} from "../../utils/inputUtils";
import { greetingSchema } from "../../utils/validationSchema";
import Swal from "sweetalert2";
import {
  saveUserDetails,
  getRegion,
  initCreateUser,
  createUserSuccess,
  setMobileNo,
  setPrevious,
  createUserResponse,
  createUserData,
  setErrors,
  setForm1Submitted,
  setEmailDiscount,
  setStartAnnualIncome,
} from "./greeting.slice";
import ReactTooltip from "react-tooltip";
import Loader from "../../components/Loader/Loader";
import { CustomDropDown, Error } from "../../components";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";

import { getAllData } from "../../api/getAllData";
import SecureLS from "secure-ls";
import styled, { keyframes } from "styled-components";
import Particle from "./Particle";
import { BoldHeader, Continue, SubHeader2 } from "./Form2style";
import { Step } from "./Step";
import { Link } from "react-router-dom";
import { BackButton } from "./BackButton";
import { DomainFooter } from "../../components/DomainFooter";
import { useAmountInput } from "../../custom-hooks/useAmountInput";
import { extractNumbers, isNumber } from "../../utils";
import { useUrlQuery } from "../../custom-hooks";
import { emailPNB } from "./serviceApi";

const Form1 = ({ history }) => {
  const {
    customerDetails,
    previous,
    emailDiscount,
    showFooter,
    form1Submitted,
  } = useSelector(state => state.greeting);

  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [dobb, setDob] = useState(false);
  const [email, setEmail] = useState(false);
  const [mob, setMob] = useState(false);
  const formRef = useRef();
  // const { value: annualIncome, handleChange: handleAnnualIncomeTextChange } =
  //   useAmountInput(customerDetails.annualIncome);

  const { isError } = useSelector(({ quote }) => quote);
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    reset,
    setValue,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(greetingSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const gender = watch("gender");
  const dob = watch("dob");
  const qualification = watch("qualification");
  const annualIncomeGiven = watch("annualIncome");
  // const home = useUrlQuery("src");
  useEffect(() => {
    dispatch(
      createUserData({
        ...customerDetails,
        gender,
        dob,
        qualification,
        annualIncome: isNumber(annualIncomeGiven)
          ? annualIncomeGiven
          : getNumberfromString(annualIncomeGiven),
        // annualIncome,
      }),
    );
    // }, [gender, dob, qualification, annualIncomeGiven]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender, dob, qualification, annualIncomeGiven]);

  const phone = useRef();
  const attachPhoneRef = ref => {
    register(ref);
    phone.current = ref;
  };
  const ls = new SecureLS();
  const handlePhone = () => {
    //dispatch(setMobileNo(phone.current.value));
    dispatch(setMobileNo(phone.current.value));
  };

  const dispatch = useDispatch();
  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };
  const { erro, regionDetails, isLoading, status, userCreate } = useSelector(
    state => state.greeting,
  );
  const [annualIncome, setAnnualIncome] = useState(
    isNumber(getValue(customerDetails.annualIncome))
      ? customerDetails.annualIncome
      : getValue(customerDetails.annualIncome),
  );
  const [annualIncomeError, setAnnualIncomeError] = useState();
  const [education, setEducation] = useState(customerDetails.qualification);
  Particle();

  const [age, setAge] = useState("");
  useEffect(() => {
    if (customerDetails.dob) {
      setAge(getAge(customerDetails.dob));
    }
  }, []);

  const afflid = useUrlQuery("afflid");
  const utm_medium = useUrlQuery("utm_medium");
  const c_id = useUrlQuery("c_id");
  const enquiryId = useUrlQuery("enquiryId");
  useEffect(() => {
    dispatch(createUserSuccess());
  }, []);
  useEffect(() => {
    if (status && !!customerDetails?.annualIncome && !!customerDetails?.dob) {
      history.push("/form2");
      dispatch(createUserSuccess());
    }
  }, [dispatch, history, isLoading, status, userCreate]);

  const handleChange = event => {
    const val = dateUtil(event, date.value, false, false, true);
    setAge(val);
  };
  const [startingAnnualIncome, setStartingAnnualIncome] = useState(250000);

  useEffect(() => {
    if (education === "Post Graduate") {
      if (age >= 18 && age <= 40) {
        let x = 250000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError("Select Annual Income");
        } else setAnnualIncomeError("");
      } else if (age >= 41 && age <= 60) {
        let x = 300000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 3Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 5Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      }
    } else if (education === "Graduate") {
      if (age >= 18 && age <= 40) {
        let x = 250000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 2.5Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      } else if (age >= 41 && age <= 60) {
        let x = 300000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 10 Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 5Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      }
    } else if (education === "12th Pass") {
      if (age >= 18 && age <= 60) {
        let x = 300000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 3Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 5Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      }
    } else if (education === "10th Pass") {
      if (age >= 18 && age <= 65) {
        let x = 1000000;
        if (
          annualIncome &&
          !annualIncome.includes("Select") &&
          Number(getNumberfromString(annualIncome)) < x
        ) {
          setAnnualIncomeError(
            "Minimum income should be 10Lacs based on selected education",
          );
        } else setAnnualIncomeError("");
      }
    } else {
      setStartingAnnualIncome(250000);
    }
    dispatch(setStartAnnualIncome(startingAnnualIncome));
  }, [age, education, annualIncome]);
  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);

  //   if (query.get("enquiryId")) {
  //     sessionStorage.setItem("traceId", query.get("enquiryId"));
  //     dispatch(getAllData("", "/", query.get("enquiryId")));
  //   }
  // }, []);
  // useEffect(() => {
  //   const query = new URLSearchPearams(window.location.search);
  //   if (query.get("enquiryId")) {
  //     if (
  //       customerDetails.firstName &&
  //       customerDetails.lastName &&
  //       customerDetails.dob &&
  //       customerDetails.gender &&
  //       customerDetails.email &&
  //       customerDetails.mobileNo &&
  //       previous
  //     ) {
  //       dispatch(setPrevious(false));
  //       reset({ gender: customerDetails.gender });
  //       if (!customerDetails.tobaccoHabit) {
  //         history.push({
  //           pathname: "/form2",
  //         });
  //       } else if (!customerDetails.annualIncome) {
  //         history.push({
  //           pathname: "/form3",
  //         });
  //       }
  //       // else if (!customerDetails.preferance) {
  //       //   history.push({
  //       //     pathname: "/Form4",
  //       //   });
  //       // }
  //     }
  //   }
  // }, [customerDetails]);

  const onSubmit = formData => {
    const data = { ...formData };

    data.annualIncome = isNumber(formData.annualIncome)
      ? formData.annualIncome
      : getNumberfromString(formData.annualIncome);

    const annualIncomeError = validateAnnualIncome(data.annualIncome, {
      age,
      education,
    });
    if (annualIncomeError) {
      setError("annualIncome", {
        type: "manual",
        message: annualIncomeError,
      });
      return;
    }
    data["dob"] = data["dob"].replace(/ /g, "").split("/").reverse().join("-");
    // const query = new URLSearchParams(window.location.search);
    // // if (query.get("enquiryId")) {
    // //   history.push("/form2");
    // //   return;
    // // }
    dispatch(
      saveUserDetails({
        ...customerDetails,
        ...data,
        age,
        create: enquiryId ? false : utm_medium,
        // annualIncome: getNumberfromString(data.annualIncome),
      }),
    ).then(() => dispatch(setForm1Submitted(true)));
    dispatch(initCreateUser());
  };
  useEffect(() => {
    const click = e => {
      if (e.key === "Enter" && !isLoading) {
        formRef.current.click();
      }
    };
    window.addEventListener("keydown", click);
    return () => {
      window.removeEventListener("keydown", click);
    };
  });
  useEffect(() => {
    register("qualification");
    setValue("qualification", customerDetails.qualification);
    register("annualIncome");
    setValue("annualIncome", getValue(customerDetails.annualIncome));
  }, [register]);

  if (isError) return <Error errorMessage={isError} />;

  return (
    <Container>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <LaxmiWrap>
          <LaxmiImg src={LaxmiGreeting}></LaxmiImg>
        </LaxmiWrap>

        <div>
          {form1Submitted && (
            <Link to="/form2">
              <Continue>
                <div>
                  <span>continue</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#f2581b"
                  >
                    <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>
              </Continue>
            </Link>
          )}
          <SubHeader2>I’ll make this fast and simple.</SubHeader2>
          <BoldHeader>
            Please share basic details of the life to be insured.
          </BoldHeader>
        </div>
        <div style={{ paddingTop: "32px" }}>
          <CustomWrap>
            <CustomInput radio>
              <RadioGroup>
                <Row>
                  <GenderFieldCust>
                    <Radio
                      type="radio"
                      name="gender"
                      id="male"
                      value="M"
                      error={errors.gender && "danger"}
                      onClick={() => trigger("gender")}
                      defaultChecked={customerDetails.gender === "M" && true}
                      ref={register}
                    ></Radio>
                    <RadioButton
                      error={errors.gender && "danger"}
                      htmlFor="male"
                      className="form-control"
                    >
                      <RadioText>Male</RadioText>
                      <CheckMark className="check-mark"></CheckMark>
                    </RadioButton>
                  </GenderFieldCust>
                  <GenderFieldCust>
                    <Radio
                      type="radio"
                      name="gender"
                      id="female"
                      value="F"
                      onClick={() => trigger("gender")}
                      defaultChecked={customerDetails.gender === "F" && true}
                      ref={register}
                    ></Radio>

                    <RadioButton
                      htmlFor="female"
                      error={errors.gender && "danger"}
                      className="form-control"
                    >
                      <RadioText>Female</RadioText>
                      <CheckMark className="check-mark"></CheckMark>
                    </RadioButton>
                  </GenderFieldCust>
                  {errors.gender && (
                    <ErrorMessage>{errors.gender.message}</ErrorMessage>
                  )}
                </Row>
              </RadioGroup>
            </CustomInput>
            <CustomInput>
              <FormGroup>
                <TextInput
                  type="text"
                  dob
                  className="form-control"
                  name="dob"
                  // onChange={e => setDob(e.target.value)}
                  defaultValue={customerDetails.dob}
                  error={errors.dob && "danger"}
                  onChange={handleChange}
                  title={dobb ? " " : "Please fill in this field."}
                  id="dob"
                  ref={attachRef}
                  required
                ></TextInput>
                <Label>
                  Date of Birth <span>(DD/MM/YYYY)</span>
                </Label>
                {age && <AgeWrapper>{age} Years</AgeWrapper>}
                {errors.dob && (
                  <ErrorMessage>{errors.dob.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
          </CustomWrap>
          <CustomWrap>
            <CustomInput style={{ marginBottom: "16px" }}>
              <FormGroup>
                <CustomDropDown
                  onBlur={() => {
                    trigger("qualification");
                  }}
                  items={[
                    "Post Graduate",
                    "Graduate",
                    "12th Pass",
                    "10th Pass",
                  ]}
                  error={errors.qualification}
                  searchable
                  currentSelected={education || "Your Highest Qualification"}
                  onChange={value => {
                    setValue("qualification", value);
                    setEducation(value);
                    trigger("qualification");
                  }}
                />
                {errors.qualification && (
                  <ErrorMessage>{errors.qualification.message}</ErrorMessage>
                )}
              </FormGroup>
            </CustomInput>
            <CustomInput>
              <FormGroup>
                <CustomDropDown
                  annualIncome
                  onBlur={() => {
                    trigger("annualIncome");
                  }}
                  error={annualIncomeError || errors?.annualIncome}
                  items={[
                    ...getAmount(
                      startingAnnualIncome,
                      100000000,
                      100000000,
                      100000,
                      "annualIncome",
                      age,
                    ).reverse(),
                  ]}
                  currentSelected={
                    annualIncome
                      ? !annualIncome.includes("₹")
                        ? getValue(annualIncome)
                        : annualIncome
                      : "Select Annual Income"
                  }
                  searchable
                  onChange={value => {
                    setValue("annualIncome", value);
                    setAnnualIncome(value);
                    trigger("annualIncome");
                  }}
                  // customInput                  -------------------------------to-do
                />
                {
                  <ErrorMessage>
                    {annualIncomeError || errors?.annualIncome?.message}
                  </ErrorMessage>
                }
              </FormGroup>
              {/* <FormGroup>
                <TextInput
                  type="text"
                  name="annualIncome"
                  id="annualIncome"
                  className="form-control"
                  required
                  value={annualIncome}
                  onChange={handleAnnualIncomeTextChange}
                  ref={register}
                />
                <Label htmlFor="annualIncome">Annual Income</Label>
                {errors.annualIncome && (
                  <ErrorMessage>{errors.annualIncome.message}</ErrorMessage>
                )}
              </FormGroup> */}
            </CustomInput>
          </CustomWrap>
          <ProceedWrap margin>
            <ProceedButton type="submit" ref={formRef}>
              <div>
                <span>Proceed</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
                </svg>
              </div>
            </ProceedButton>
          </ProceedWrap>
        </div>
        {/* <EmailPopup email={email && email.split("@")[1]} /> */}
      </Form>
      {isLoading && <Loader />}
      <Step count={1} />
      {(!afflid || !utm_medium || c_id) && (
        <Link to="/">
          <BackButton></BackButton>
        </Link>
      )}
      {(emailDiscount || showFooter) && <DomainFooter />}
    </Container>
  );
};

export default Form1;

function EmailAnimationTooltip(props) {
  return (
    <TooltipBase {...props}>
      <svg
        // class="tooltip__shape"
        width="100%"
        height="100%"
        viewBox="0 0 400 300"
        fill="#107591"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
        }}
      >
        <path d="M 22,74.2 22,202 C 22,202 82,202 103,202 124,202 184,202 184,202 L 200,219 216,202 C 216,202 274,202 297,202 320,202 378,202 378,202 L 378,74.2 C 378,74.2 318,73.7 200,73.7 82,73.7 22,74.2 22,74.2 Z"></path>
      </svg>
      <TooltipContent>
        <LaxmiImageForTooltip src={LaxmiGreeting} alt="laxmi" />
        You get superpowers with your work email ID. Please enter your work
        email ID to get unbelievable quotes.
      </TooltipContent>
    </TooltipBase>
  );
}

const tooltipBaseAnime = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TooltipBase = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -173px;
  width: 345px;
  height: 287px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  bottom: -4.5em;
  opacity: 1;
  transform: translateY(0px) scale(1);
  margin-bottom: 37px;
  margin-left: -37px;
  animation: ${tooltipBaseAnime} 0.3s;
  z-index: 999;
`;

const LaxmiImageForTooltip = styled.img`
  width: 96px;
  background-color: #fff;
  border: solid 0.5px #e3e4e8;
  border-radius: 50%;
  width: 58px;
  margin-top: 11px;
  float: left;
  margin-right: 15px;
  border: none;
`;

const moveUp = keyframes`
  0% {
    transform: translateY(16px);
    opacity: 0;
  }
  50% {
    transform: translateY(-7px);
    opacity: 1;
  }
  100% {
    transform: translateY(-6px);
  }
`;

const TooltipContent = styled.div`
  color: #fff;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1px 40px 15px 34px;
  opacity: 0;
  font-size: 15px;
  opacity: 1;
  transform: translateY(-6px);
  animation: ${moveUp} 0.67s;
`;

export function validateAnnualIncome(annualIncome, { age, education }) {
  if (!annualIncome) return "Please provide annual Income";
  let errorMessage = "";

  if (education === "Post Graduate") {
    if (age >= 18 && age <= 40) {
      let x = 250000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 2.5Lacs based on selected education";
      } else errorMessage = "";
    } else if (age >= 41 && age <= 60) {
      let x = 300000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 3Lacs based on selected education";
      } else errorMessage = "";
    } else if (age >= 61 && age <= 65) {
      let x = 500000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 5Lacs based on selected education";
      } else errorMessage = "";
    }
  } else if (education === "Graduate") {
    if (age >= 18 && age <= 40) {
      let x = 250000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 2.5Lacs based on selected education";
      } else errorMessage = "";
    } else if (age >= 41 && age <= 60) {
      let x = 300000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 10Lacs based on selected education";
      } else errorMessage = "";
    } else if (age >= 61 && age <= 65) {
      let x = 500000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 5Lacs based on selected education";
      } else errorMessage = "";
    }
  } else if (education === "12th Pass") {
    if (age >= 18 && age <= 60) {
      let x = 300000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 3Lacs based on selected education";
      } else errorMessage = "";
    } else if (age >= 61 && age <= 65) {
      let x = 500000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 5Lacs based on selected education";
      } else errorMessage = "";
    }
  } else if (education === "10th Pass") {
    if (age >= 18 && age <= 65) {
      let x = 1000000;
      if (
        annualIncome &&
        !annualIncome.includes("Select") &&
        Number(getNumberfromString(annualIncome)) < x
      ) {
        errorMessage =
          "Minimum income should be 10Lacs based on selected education";
      } else errorMessage = "";
    }
  } else {
  }

  return errorMessage;
}
