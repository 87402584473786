import React from "react";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import "styled-components/macro";

const PlanLabel = ({ company }) => {
  let tooltipData;
  if (company === "max_life") {
    tooltipData =
      "<h3>Zero Cost insurance</h3> <div>The benefit will allow you to get your total paid premium back (Excl GST and addon premium). You can exercise this option after completing 25 years of your policy term provided you have chosen a minimum policy term as 40 years and 30th policy year for policy terms greater than 44 years, whichever is earlier. To know more please read the sales/policy brochure.<div/>";
  } else if (company === "hdfc_life") {
    tooltipData =
      "<h3>Zero Cost insurance</h3> <div>The benefit will allow you to get your total paid premium back (Excl GST and addon premium). This can be exercised anytime during the period of one year once they attain age of 55 years (as on last birthday). To know more please read the sales/policy brochure.<div/>";
  } else if (company === "icici_pru") {
    tooltipData =
      "<h3>Zero Cost insurance</h3> <div>The benefit will allow you to get your total paid premium back any (Excl GST and addon premium). This can be exercised in any policy year greater than 25 but not during the last 5 policy years, provided the age of the life assured is 60 years or more. To know more please read the sales/policy brochure.<div/>";
  } else if (company === "bajaj_life") {
    tooltipData =
      "<h3>Zero Cost insurance</h3> <div>The benefit will allow you to get your total paid premium back any (Excl GST and addon premium). This can be exercised in any policy year greater than 25 but not during the last 5 policy years, provided the age of the life assured is 60 years or more. To know more please read the sales/policy brochure.<div/>";
  } else if (company === "bandhan_life") {
    tooltipData =
      "<h3>Zero Cost insurance</h3> <div>The benefit will allow you to get your total paid premium back (Excl GST and addon premium). This can be exercised in any policy year greater than 30 years, but not during the last 5 policy years. To know more please read the sales/policy brochure.<div/>";
  } else {
    return "";
  }

  return (
    <>
      <CustomTooltip
        id="PlanLabel__Tooltip"
        customClassName="quotePageTooltip no-after"
        // Position={{ top: 0, right: 15 }}
        // place={"left"}
      >
        <div
          css={`
            position: absolute;
            background-color: #107591;
            padding: 3px 9px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
            color: #fff;
            right: 30px;
            top: -12px;
            font-size: 10px;
            border-radius: 12px;
            cursor: pointer;
          `}
          data-tip={tooltipData}
          data-html={true}
          data-for={"PlanLabel__Tooltip"}
        >
          Zero Cost insurance
        </div>
      </CustomTooltip>
    </>
  );
};

export default PlanLabel;
