import React from "react";

import "./compare-box.scss";

import { CloseButton } from "../../SvgComp";
import { useDispatch, useSelector } from "react-redux";
import { removeQuoteToCompare } from "../../quotePageSlice";

function CompareBox({ id, logoUrl, plan }) {
  const dispatch = useDispatch();

  const { quotesForCompare } = useSelector(({ quote }) => quote);

  return (
    <div
      className="compare-box"
      style={{ border: plan && "solid 1px #e3e4e8" }}
    >
      {plan ? (
        <div className="compare-box__inside">
          {quotesForCompare.length >= 2 && (
            <div onClick={() => dispatch(removeQuoteToCompare(id))}>
              {CloseButton}
            </div>
          )}
          <img className="compare-box__logo" src={logoUrl} alt="quote-logo" />
          <div className="compare-box__plan-name">{plan}</div>
        </div>
      ) : (
        <div className="compare-box__inside no-plan">Add Plans</div>
      )}
    </div>
  );
}

export default CompareBox;
