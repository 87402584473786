import { useEffect } from "react";

function useKeydownEvent(key, callback, ref) {
  useEffect(() => {
    const handleKeydown = evt => {
      if (evt.keyCode === key || evt.key === key) callback();
    };
    if (ref && ref.current)
      ref.current.addEventListener("keydown", handleKeydown);
    else document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, [key, callback, ref]);
}

export default useKeydownEvent;
