export const data = [
  {
    type: "text",
    name: "pan",
    id: "pan",
    readOnly: true,
    placeHolder: " ",
    autoComplete: "none",
    label: "PAN no.",
    maxLength: "10",
  },
];
