import swal from "sweetalert";
import { getNumberfromString } from "../../utils/inputUtils";

export const shouldBeDisabled = (
  addOnsList,
  rider_shortname,
  company_alias,
  checkedDiabetic,
  compulsory,
) => {
  if (
    company_alias === "max_life" &&
    rider_shortname !== "CAB" &&
    checkedDiabetic
  )
    return true;
  if (checkedDiabetic && company_alias !== "max_life") return true;
  if (compulsory) return true;
  if (company_alias === "hdfc_life") {
    if (
      addOnsList.some(item => item.rider_shortname === "PPR_PAC") &&
      rider_shortname === "PPR_ADC"
    ) {
      return true;
    }
    if (
      addOnsList.some(item => item.rider_shortname === "PPR_ADC") &&
      rider_shortname === "PPR_PAC"
    ) {
      return true;
    }
  }
  if (company_alias === "kotak_life") {
    if (
      addOnsList.some(item => item.rider_shortname === "ADB") &&
      rider_shortname === "WOP"
    ) {
      return true;
    }
    if (
      addOnsList.some(item => item.rider_shortname === "WOP") &&
      rider_shortname === "ADB"
    ) {
      return true;
    }
  }
  if (company_alias === "max_life") {
    if (
      addOnsList.some(
        item =>
          item.rider_shortname.includes("CI") &&
          item.rider_shortname !== rider_shortname,
      ) &&
      rider_shortname.includes("CI")
    ) {
      return true;
    }
    if (
      !addOnsList.some(item => item.rider_shortname.includes("CI")) &&
      rider_shortname === "TPDB"
    ) {
      return true;
    }
  }
  if (company_alias === "bajaj_life") {
    if (
      addOnsList.some(item => item.rider_shortname === "ATPD") &&
      rider_shortname === "CI"
    ) {
      return true;
    }
    if (
      addOnsList.some(item => item.rider_shortname === "CI") &&
      rider_shortname === "ATPD"
    ) {
      return true;
    }
  }
  if (company_alias === "bandhan_life") {
    if (
      addOnsList.some(item => item.rider_shortname === "CI-CPC") &&
      rider_shortname.includes("CI") &&
      rider_shortname !== "CI-CPC"
    ) {
      return true;
    }
    if (
      addOnsList.some(
        item =>
          item.rider_shortname.includes("CI") &&
          item.rider_shortname !== "CI-CPC",
      ) &&
      rider_shortname === "CI-CPC"
    ) {
      return true;
    }
  }
};
export const validateSelection = (
  coverAmount,
  shortname,
  company_alias,
  addOnsList,
  basePremium = Infinity,
  additionalRider = 0,
) => {
  if (company_alias === "max_life") {
    if (Number(additionalRider) > Number(basePremium)) {
      swal({
        text: "Rider Premium should not be greater than base premium",
        buttons: {
          confirm: { text: "Ok", className: "confirm-swal" },
        },
      });
      return false;
    }
  }
  if (company_alias === "bandhan_life") {
    if (Number(additionalRider) > basePremium) {
      swal({
        text: "Total Rider Premium should not be greater than base premium",
        buttons: {
          confirm: { text: "Ok", className: "confirm-swal" },
        },
      });
      return false;
    }
  }
  if (company_alias === "bandhan_life") {
    let sumAssuredArray = [];
    addOnsList.forEach(el => {
      if (
        el.rider_shortname.includes("CI") &&
        el.rider_shortname !== "CI-CPC"
      ) {
        sumAssuredArray.push(Number(el.sumAssured));
      }
    });
    if (sumAssuredArray.reduce((a, b) => a + b, 0) > 10000000) {
      swal({
        text: "Critical Illness total cover Amount should not be greater than 1 crore",
        buttons: {
          confirm: { text: "Ok", className: "confirm-swal" },
        },
      });
      return false;
    }
  }
  if (company_alias === "bajaj_life") {
    if (
      addOnsList.some(item => item.rider_shortname === "ADB") &&
      shortname === "ATPD"
    ) {
      let temp = addOnsList.filter(item => item.rider_shortname === "ADB");

      if (
        Number(getNumberfromString(coverAmount)) + Number(temp[0].sumAssured) >
        20000000
      ) {
        swal({
          text: "Accidental Death Benefit + Accidental Total Permanent Disability Benefit total cover Amount should not be greater than 2 crore",
          buttons: {
            confirm: { text: "Ok", className: "confirm-swal" },
          },
        });
        return false;
      }
      if (
        Number(getNumberfromString(coverAmount)) > Number(temp[0].sumAssured)
      ) {
        swal({
          text: "Cover Amount for Accidental Total Permanent Disability Benefit should be less than or equal to Accidental Death Benefit",
          buttons: {
            confirm: { text: "Ok", className: "confirm-swal" },
          },
        });
        return false;
      }
    }
    if (
      addOnsList.some(item => item.rider_shortname === "ATPD") &&
      shortname === "ADB"
    ) {
      let temp = addOnsList.filter(item => item.rider_shortname === "ATPD");

      if (
        Number(getNumberfromString(coverAmount)) + Number(temp[0].sumAssured) >
        20000000
      ) {
        swal({
          text: "Accidental Death Benefit + Accidental Total Permanent Disability Benefit total cover Amount should not be greater than 2 crore",
          buttons: {
            confirm: { text: "Ok", className: "confirm-swal" },
          },
        });
        return false;
      }
      if (
        Number(getNumberfromString(coverAmount)) < Number(temp[0].sumAssured)
      ) {
        swal({
          text: "Cover Amount for Accidental Total Permanent Disability Benefit should be less than or equal to Accidental Death Benefit",
          buttons: {
            confirm: { text: "Ok", className: "confirm-swal" },
          },
        });
        return false;
      }
    }
  }
  return true;
};
export const shouldDisplay = (selected, shortname, errorMsg) => {
  if (
    selected?.company_alias === "pnb_metlife" &&
    (shortname === "SC" ||
      shortname === "CB" ||
      shortname === "WOP" ||
      shortname === "ACI" ||
      shortname === "CB")
  ) {
    return true;
  }
  if (
    (selected?.company_alias === "kotak_life" ||
      selected?.company_alias === "max_life") &&
    (shortname === "ADB" || shortname === "WOP")
  )
    return true;
  else if (
    (selected?.company_alias === "bajaj_life" && shortname === "WOP") ||
    shortname === "SC" ||
    shortname === "CB"
  )
    return true;
  else if (
    selected?.company_alias === "hdfc_life" &&
    (shortname === "WP" || shortname === "ADB")
  )
    return true;
  else if (
    (selected?.company_alias === "pnb_metlife" ||
      selected?.company_alias === "tata_aia_life") &&
    shortname === "LSB"
  )
    return true;
  if (errorMsg) return true;
  else return false;
};
export const showPPT = (selected, shortname, errorMsg) => {
  if (errorMsg) {
    return false;
  }
  if (
    (selected.company_alias === "bajaj_life" &&
      selected?.master_product_name !== "eTouch" &&
      shortname !== "WOP" &&
      shortname !== "CB" &&
      shortname !== "SC") ||
    (selected.company_alias === "hdfc_life" &&
      shortname !== "WP" &&
      shortname !== "ADB") ||
    (selected.company_alias === "bajaj_life" &&
      selected?.master_product_name === "eTouch" &&
      shortname !== "ADB")
  )
    return true;
  else return false;
};
export const getCostPer = (
  rider_shortname,
  coverAmount,
  ridersPremium,
  filter,
  price,
  selected,
  buttonText,
  comeBack,
  setComeBack,
  compulsory,
  newAddonListWop,
) => {
  if (
    coverAmount !== "Select" &&
    !coverAmount?.includes("select") &&
    (ridersPremium?.ridersData?.data?.status ||
      ridersPremium?.spouse ||
      ridersPremium?.child)
  )
    return compulsory
      ? `₹ ${Number(price).toLocaleString("en-IN")}${buttonText}`
      : `(₹ ${Number(price).toLocaleString("en-IN")}${buttonText})`;
  else if (
    (selected?.company_alias === "kotak_life" ||
      selected?.company_alias === "max_life") &&
    (rider_shortname === "ADB" || rider_shortname === "WOP")
  )
    return `(₹ ${Number(price).toLocaleString("en-IN")}${buttonText})`;
  else if (
    selected?.company_alias === "pnb_metlife" &&
    (rider_shortname === "LSB" ||
      rider_shortname === "WOP" ||
      rider_shortname === "ACI")
  )
    return `(₹ ${Number(price).toLocaleString("en-IN")}${buttonText})`;
  else if (
    selected?.company_alias === "bajaj_life" &&
    rider_shortname === "WOP"
  ) {
    return `(₹ ${Number(newAddonListWop?.totalPremium_WOP).toLocaleString(
      "en-IN",
    )}${buttonText})`;
  } else if (
    selected?.company_alias === "hdfc_life" &&
    (rider_shortname === "ADB" || rider_shortname === "WP")
  ) {
    return `(₹ ${Number(price).toLocaleString("en-IN")}${buttonText})`;
  }
  // else if (
  // 	selected?.company_alias === "icici_pru" &&
  // 	filter?.paymentTerm?.option === "Limited Pay" &&
  // 	(filter?.paymentTerm?.term.includes("7") ||
  // 		filter?.paymentTerm?.term.includes("5")) &&
  // 	rider_shortname === "CIBF"
  // )
  // 	return `₹ ${Number(price).toLocaleString("en-IN")}${buttonText}`;
  else if (comeBack && Number(price)) {
    return `(₹ ${Number(price).toLocaleString("en-IN")}${buttonText})`;
  } else return "";
};

export const getMax = (
  rider_shortname,
  selected,
  age,
  cover_amt_limit,
  coverUpto = 0,
  annualIncome,
) => {
  let max_compare = 500000;

  if (
    selected?.company_alias === "bandhan_life" &&
    rider_shortname === "ADB" &&
    coverUpto >= 81
  ) {
    return 10000000;
  }
  if (selected?.company_alias === "bajaj_life" && rider_shortname === "CI") {
    if (age >= 18 && age <= 35) return Math.min(12 * annualIncome, 5000000);
    if (age >= 36 && age <= 40) return Math.min(10 * annualIncome, 5000000);
    if (age >= 36 && age <= 45) return Math.min(10 * annualIncome, 5000000);
    if (age >= 46 && age <= 50) return Math.min(7 * annualIncome, 5000000);
    if (age >= 51 && age <= 55) return Math.min(5 * annualIncome, 5000000);
    if (age >= 56 && age <= 60) return Math.min(5 * annualIncome, 5000000);
    if (age >= 61 && age <= 64) return Math.min(3 * annualIncome, 5000000);
  }
  if (
    selected?.company_alias === "max_life" &&
    rider_shortname.includes("CI")
  ) {
    if (rider_shortname === "CI-G") {
      for (let i = 0; i <= 5; ++i) {
        if ((i + 1) * 93 > selected?.premium_amount) break;
        else max_compare = (i + 1) * 500000;
      }
    }
    if (rider_shortname === "CI-P") {
      for (let i = 0; i <= 5; ++i) {
        if ((i + 1) * 144 > selected?.premium_amount) break;
        else max_compare = (i + 1) * 500000;
      }
    }
    if (age >= 18 && age <= 35) return Math.min(3000000, max_compare);
    if (age >= 36 && age <= 45) return Math.min(2000000, max_compare);
    if (age >= 46 && age <= 50) return Math.min(1000000, max_compare);
    if (age >= 51 && age <= 55) return Math.min(500000, max_compare);
  }
  return Number(cover_amt_limit);
};
export const getMin = (rider_shortname, selected, filter, cover_amt_min) => {
  // if (
  // 	selected?.company_alias === "icici_pru" &&
  // 	filter?.paymentTerm?.option === "Limited Pay" &&
  // 	filter?.paymentTerm?.term.includes("7") &&
  // 	rider_shortname === "CIBF"
  // ) {
  // 	if (selected?.coverAmount < 25000000) return 0.1 * selected?.coverAmount;
  // 	else return 2500000;
  // }
  // else if (
  // 	selected?.company_alias === "icici_pru" &&
  // 	filter?.paymentTerm?.option === "Limited Pay" &&
  // 	filter?.paymentTerm?.term.includes("5") &&
  // 	rider_shortname === "CIBF"
  // ) {
  // 	return 500000;
  // }
  // else

  return Number(cover_amt_min);
};
export const getTermLimit = (
  selected,
  rider_shortname,
  filter,
  term_limit,
  age,
) => {
  if (
    (rider_shortname === "PPR_CC" || rider_shortname === "C4CI") &&
    filter?.paymentTerm?.term === "Pay till 60" &&
    filter?.paymentTerm?.option === "Limited Pay"
  ) {
    return Math.min(term_limit, 60 - age).toString();
  }
  if (
    (rider_shortname === "PPR_CC" ||
      (rider_shortname === "C4CI" &&
        filter?.paymentTerm?.term !== "Pay till 60")) &&
    filter?.paymentTerm?.option === "Limited Pay"
  )
    return filter?.paymentTerm?.term.match(/\d+/)[0];

  if (
    selected.company_alias === "bajaj_life" &&
    filter?.paymentTerm?.option === "Limited Pay" &&
    rider_shortname === "CI"
  ) {
    return filter.paymentTerm.term.match(/\d+/)[0];
  }
  console.log("term_limit", filter?.paymentTerm?.term.match(/\d+/));
  return term_limit;
};
export const showCard = (company, shortname, maturity, coverUpto) => {
  if (coverUpto > maturity && shortname === "WOP" && company === "bajaj_life") {
    return false;
  }

  if (company === "hdfc_life") {
    if (shortname === "ADB" && coverUpto > 75) return false;
    if (shortname === "WP" && coverUpto > 85) return false;
  }
  if (company === "bandhan_life") {
    if (coverUpto > 80 && shortname.includes("CI")) {
      return false;
    }
    if (coverUpto > 85 && shortname === "ADB") return false;
  }
  return true;
};
// if (selected?.company_alias === "hdfc_life") {
//   if (
//     addOnsList.some((item) => item.rider_shortname === "PPR_PAC") &&
//     rider_shortname === "PPR_ADC"
//   ) {
//     swal(
//       "Oops!",
//       "Personal Accidental Cover and Accidental Death Cover can't be availed together",
//       "warning"
//     );
//     return;
//   }
//   if (
//     addOnsList.some((item) => item.rider_shortname === "PPR_ADC") &&
//     rider_shortname === "PPR_PAC"
//   ) {
//     swal(
//       "Oops!",
//       "Personal Accidental Cover and Accidental Death Cover can't be availed together",
//       "warning"
//     );
//     return;
//   }
// }
// if (selected?.company_alias === "kotak_life") {
//   if (
//     addOnsList.some((item) => item.rider_shortname === "ADB") &&
//     rider_shortname === "WOP"
//   ) {
//     swal(
//       "Oops!",
//       "Accidental Death Benefit and Waiver of Premium on Personal Disability can't be availed together",
//       "warning"
//     );
//     return;
//   }
//   if (
//     addOnsList.some((item) => item.rider_shortname === "WOP") &&
//     rider_shortname === "ADB"
//   ) {
//     swal(
//       "Oops!",
//       "Accidental Death Benefit and Waiver of Premium on Personal Disability can't be availed together",
//       "warning"
//     );
//     return;
//   }
// }
