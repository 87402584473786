import styled, { createGlobalStyle } from "styled-components";
import { Row, Col } from "react-bootstrap";

export const Wrappero = styled.div`
  text-align: center;
  padding: 30px 0 50px;
`;

export const Rowso = styled(Row)`
  padding: 0;
  margin: 0;
`;

export const Colso = styled(Col)`
  padding: 0;
  margin: 0;
`;

export const Containero = styled.div`
  margin: 20px 0 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const FormContainero = styled.div`
  margin: 0 0 0;
  width: ${({ width }) => width || "690px"};
  .otp-avatar {
    width: 96px;
    height: 96px;
    margin: auto;
    margin-top: -72px;
    display:flex;
    justify-content:center;

    @media only screen and (max-width: 767px) {
      height: 70px;
      width: 116px;
    margin-top: -54px;

    }
  }
  @media (max-width: 991px) {
    width: 750px;
    margin: 0 15px 50px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 0 15px 50px;
  }
  @media (max-width: 450px) {
    margin: 0 15px 0px;
  }

  .stepper-boldHeader {
    line-height: 28px;
    margin-bottom: 8px;
    padding: 0;
    font-family: "Inter-Medium";
    color: #333;
    font-size: 24px;
    font-weight: normal;
    margin-top: 22px;
    padding: 0 30px;
    text-align: center;
  }
  .otpInputWrap {
    position: relative;
    width: 100%;
    height: 100px;
    text-align: center;
  }
  .mobOtpNumberInput {
    display: inline;
    margin-top: 22px;
    width: 320px;
    height: 60px;
    padding: 16px 0 16px 0;
    border-radius: 4px;
    border: solid 1px #e3e4e8;
    font-family: Inter-Light;
    font-size: 24px;
    line-height: 18px;
    color: #333 !important;
    box-shadow: none;
    text-align: center;
  }
  .mobOtpNumberInput:focus {
    outline: none;
    border-radius: 4px;
    border-color: #107591;
    border-width: 2px;
  }
  .mobOtpNumberInput::-webkit-input-placeholder,
  .mobOtpNumberInput:-ms-input-placeholder,
  .mobOtpNumberInput::placeholder {
    color: rgba(102, 102, 102, 0.3);
  }
  .mobOtpNumberInput[disabled] {
    background: #e3e4e8;
    border: none;
    border-radius: 2px;
    cursor: not-allowed;
  }

  .otpTitle {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    font-family: "Inter-SemiBold";
    margin-bottom: 12px;
    margin-top: 24px;
  }

  .otpWrap input {
    float: left;
    width: 65px;
    height: 65px;
    color: #555555;
    margin-right: 20px;
    border: solid 1px #e3e4e8;
    text-align: center;
  }
  .otpWrap input:nth-child(4) {
    margin-right: 0;
  }
  .otpWrap input:focus {
    outline: none;
    color: #555555;
    font-family: "Inter-SemiBold";
    border-radius: 4px;
    border-color: #107591;
    border-width: 2px;
  }

  .otpNumberEdit {
    display: none;
    position: absolute;
    top: 38px;
    width: 22px;
    height: 22px;
    text-align: center;
    margin: 0 0 0 -36px;
    cursor: pointer;
  }

  .otpWrap .fieldError {
    float: left;
    position: absolute;
    bottom: -28px;
    width: 320px;
    text-align: left;
  }

  .otpNotReceivedWrap {
    margin: 10px auto 25px;
    width: 320px;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  }

  .otpNotReceivedTxt {
    color: #333;
    font-family: "Inter-Regular";
  }

  .otpNotReceivedBtn {
    color: #197690;
    text-decoration: underline;
    float: right;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Inter-SemiBold";
  }

  .otpSentMsg {
    float: left;
    width: 100%;
    text-align: center;
    color: #107591;
    margin-top: 8px;
    font-size: 14px;
    font-family: "Inter-Regular";
    transition: visibility 1s ease-in-out, opacity 0.7s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  .resendShow {
    visibility: visible;
    opacity: 1;
  }
  input {
    font-family: "Inter-Regular";
  }
  .buttonSubmit {
    width: 320px;
    margin-top: 2px;
    font-family: "Inter-SemiBold";
  }
  .buttonSubmit2 {
    margin-top: 12px !important;
  }
  .buttonSubmit3 {
    margin-top: 10px !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .stepper-boldHeader {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 4px;
    }

    .buttonSubmit2 {
      margin-top: 24px !important;
    }
    .otpTitle {
      margin-top:32px;
    }
    .buttonSubmit {
      margin-top: 14px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .stepper-boldHeader {
      padding: 0 106px;
      margin-top: 24px;
      margin-bottom: 8px;
    }
    .otpTitle {
      margin-top:32px;
    }
  }

  @media only screen and (max-width: 767px) {
    .stepper-boldHeader {
      font-size: 13px;
      line-height: 18px;
      width: 220px;
      margin: 0px auto 0 !important;
      padding: 0;
      font-family: "Inter-Regular";
    }
    .mobOtpNumberInput {
      width: 100%;
    }
    .buttonSubmit {
      margin-top:10px;
      height:60px !important;
      width: 100%;
    }
    .otpWrap input {
      font-size: 20px;
    }

    .buttonSubmit2 {
      margin-top: 20px !important;

    }

    .stepper-boldHeader {
      margin-bottom: 2px !important;
    }

    .otpTitle {
      margin-top:32px;
    }
    .otpNotReceivedWrap {
      width:320px;
    }
    .tab{
      min-height:783px !important;
    }
  }
`;

export const Avataro = styled.img`
  width: 96px;
  height: 96px;
  border: solid 0.5px #e3e4e8;
  border-radius: 50%;

  @media only screen and (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
`;

export const ErrorMsg = styled.span`
  display: block;
  margin-top: 5px;
  line-height: 17px;
  font-size: ${props => props.fontSize || "14px"};
  color: #d43d3d;
  font-family: "Inter-Regular";
`;

export const ErrorNew = styled.div`
display:block;
margin:auto;
margin-top:30px;
font-size:16px;
font-family: "Inter-SemiBold";
color: #d43d3d;
`;

export const CustomInput = styled.input`
  border: ${props => props.red && "2px solid #d43d3d !important;"};
`;
