import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import LaxmiGreeting from "../../../components/LaxmiGreeting/LaxmiGreeting";
import { useSelector, useDispatch } from "react-redux";
import PlanCard from "../../../components/PlanCard/PlanCard";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";
import Checkbox from "../../../components/Checkbox/Checkbox";
import tooltip from "../../../assets/img/tooltipElig.svg";
import ProposerForm from "./ProposerForm/ProposerForm";
import InsuredFtcaForm from "./InsuredFtcaForm/InsuredFtcaForm";
import InsuredDetails1 from "./InsuredDetails1/InsuredDetails1";
import InsuredDetails2 from "./InsuredDetails2/InsuredDetails2";
import InsuredOccupationForm from "./InsuredOccupationForm/InsuredOccupationForm";
import NomineeDetailsForm from "./NomineeDetailsForm/NomineeDetailsForm";
import IdentificationForm from "./IdentificationForm/IdentificationForm";
import OtherInsuranceDetails from "./otherInsuranceDetails/OtherInsuranceDetails";
import MedicalDetails3 from "./Medical Details 3/MedicalDetails3";
import MedicalDetails4 from "./Medical Details 4/MedicalDetails4";
import MedicalDetailsOne from "./MedicalDetailsOne/MedicalDetailsOne";
import MedicalDetailsTwo from "./MedicalDetailsTwo/MedicalDetailsTwo";
import ProposerOccupation from "./ProposerOccupationForm/ProposerOccupation";

import { FormGroup } from "../../../components/Form/Form";
import SecureLS from "secure-ls";
import SelectedPlanForm from "./SelectedPlanForm/SelectedPlanForm";
import {
  getDetails,
  initLoader,
  setPosition,
  saveProposalDetails,
} from "./IcicProposal.slice";
import Loader from "../../../components/Loader/Loader";
import "./iciciProposal.scss";

import {
  getMaritalStatus,
  getProposerOccupation,
  getOrganizationType,
  removeKg,
  heightInCm,
  getEducationDetails,
  getIdentificationDetails,
  getItProof,
  getAgeProof,
  getAddressProof,
  getIncomeProof,
  getEiaRepo,
  getProposerIndustryType,
  getTobacooConsumed,
  getTobacooQuantity,
  getAlcoholConsumed,
  getAlcoholQuantity,
  iciciResidentStatusCode,
} from "../../../utils/inputUtils";
import swal from "sweetalert";
import { setIsProposalSubmitted } from "../proposalPage.slice";
import { useCustomLocation, useUrlQuery } from "../../../custom-hooks";

const ProposalPage = () => {
  const history = useHistory();
  const clickRef = useRef(null);
  const clickRef2 = useRef(null);
  const ls = new SecureLS();
  const [selected, setSelected] = useState();
  const [Riders, setRiders] = useState();
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { loading, pdfUrl } = useSelector(state => state.iciciProposal);

  const { response, error } = useSelector(state => state.iciciProposal);
  const { selectedQuote, riderDetails } = useSelector(state => state.quote);
  const { appNo } = useSelector(state => state.eligibilityCard);
  const { additionalRiders, selectedAddOns, riderGst, inbuiltCovers } =
    useSelector(state => state.rider);
  const { proposerDetails } = useSelector(state => state.proposalForm);
  const { proposalOccupationDetails } = useSelector(
    state => state.proposalOccupationForm,
  );
  const { insuredDetails1 } = useSelector(state => state.insuredDetails1Form);
  const { insuredDetails2 } = useSelector(state => state.insuredDetails2Form);
  const { insuredFtcaDetails } = useSelector(state => state.insuredFtcaForm);
  const { insuredOccupationDetails } = useSelector(
    state => state.insuredOccupationForm,
  );
  const { selectedPlanDetails } = useSelector(state => state.selectedPlanForm);

  const {
    nomineeDetails,
    trusteeDetails,
    nomineePincodeMaster,
    beneficiaryList,
  } = useSelector(state => state.nomineeForm);
  const { idDetails } = useSelector(state => state.idForm);
  const { otherInsuranceDetails, otherInsuranceList } = useSelector(
    state => state.otherInsuranceForm,
  );
  const { medicalDetailsOne } = useSelector(state => state.medicalDetailsOne);
  const { medicalDetailsTwo } = useSelector(state => state.medicalDetailsTwo);
  const { medicalDetailsThree } = useSelector(state => state.medicalDetails3);
  const { medicalDetailsFour } = useSelector(state => state.medicalDetails4);
  const { regionDetails } = useSelector(state => state.eligibilityCard);

  const { search } = useLocation();
  const enquiryId = useUrlQuery("enquiryId");
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { position } = useSelector(state => state.iciciProposal);
  useEffect(() => {
    if (response?.status === true && response?.medicalStatus === true) {
      dispatch(setIsProposalSubmitted(true));
      // history.push(`/documentuploadpage?enquiryId=${search.slice(11, 29)}`);
      history.push(`/medical?enquiryId=${enquiryId}`);
    } else if (response?.status === true) {
      dispatch(setIsProposalSubmitted(true));
      // history.push(`/documentuploadpage?enquiryId=${enquiryId}`);
      history.push(`/otp-verification?enquiryId=${enquiryId}`);
    }

    if (error?.status === false) {
      swal(error.message, {
        icon: "error",
      });
    }
  }, [response, error]);

  useEffect(() => {
    if (position !== 13 && agreeTerm) {
      setAgreeTerm(false);
    }
  }, [position]);

  useEffect(() => {
    setData({
      ...proposerDetails,
      ...proposalOccupationDetails,
      ...insuredDetails1,
      ...insuredDetails2,
      ...insuredFtcaDetails,
      ...nomineePincodeMaster,
      ...insuredOccupationDetails,
      ...trusteeDetails,
      ...nomineeDetails,
      ...idDetails,
      ...medicalDetailsOne,
      ...medicalDetailsTwo,
      ...medicalDetailsThree,
      ...medicalDetailsFour,
    });
  }, [medicalDetailsFour]);

  useEffect(() => {
    if (data) {
      setIsValid(true);
    }
  }, [data]);

  useEffect(() => {
    setSelected(selectedQuote);
  }, []);

  useEffect(() => {
    if (data.mwpa) {
      delete beneficiaryList["id"];
    }
  }, [data.mwpa]);

  useEffect(() => {
    const { riders } = riderDetails;
    setRiders(riders);
  }, []);
  const inbuilt_covers = Riders?.inbuilt_covers;
  const { previousSendData } = useSelector(
    ({ iciciProposal }) => iciciProposal,
  );

  useEffect(() => {
    if (response?.proposalPdfString1) {
      clickRef.current.click();
    }
    if (response?.proposalPdfString2) {
      clickRef2.current.click();
    }
  }, [response?.proposalPdfString1, response?.proposalPdfString2]);

  const handleSubmit = () => {
    if (data) {
      setFormSubmitted(true);
      // const sendData = {
      //   proposerFirstName: data.firstName,
      //   proposerLastName: data.lastName,
      //   proposerGenderCode: data.gender === "M" ? "Male" : "Female",
      //   proposerMaritalStatus: getMaritalStatus(data.martialStatus),
      //   proposerBirthDate: data["dob"]?.split("/").join("-"),
      //   proposerEmail: data.email,
      //   proposerMobNo: data.mobileNo,
      //   selfInsured: selectedPlanDetails === "Self" ? "Y" : "N",
      //   relationship: data.proposalLa,
      //   proposerPincode: data.pinCode,
      //   proposerAddressline1: data.addressLine1,
      //   proposerAddressline2: data.addressLine2,
      //   firstName: data.idFirstName,
      //   lastName: data.idLastName,
      //   gender: data.idGender === "M" ? "Male" : "Female",
      //   maritalStatus: getMaritalStatus(data.idmartialStatus),
      //   dateOfBirth: data["idDob"].split("/").join("-"),
      //   proposerState: regionDetails.pinCodeMaster.state,
      //   proposerCity: regionDetails.pinCodeMaster.city,
      //   pOccup: getProposerOccupation(data.occupation),
      //   pOccupDesc: data.occuDesc,
      //   pProfession: data.profession,
      //   pIndustryQuestion: data.engagedIn ? "Yes" : "No",
      //   pIndustryType: getProposerIndustryType(data.indType),
      //   pIndustryDesc: data.indDesc,
      //   pOrganizationName: data.orgName,
      //   pOrganizationDesc: data.orgNameDesc,
      //   pOrganizationType: getOrganizationType(data.orgType),
      //   pOrganizationTypeDesc: data.orgTypeDesc,
      //   pEducation: getEducationDetails(data.education),
      //   pIncome: data.annualIncome,
      //   occupation: getProposerOccupation(data.insOccupation),
      //   occupationDesc: data.insOccuDesc,
      //   profession: data.insProfesion,
      //   industryQuestion: data.insEngagedIn ? "Yes" : "No",
      //   industryType: data.insIndType,
      //   industryDesc:
      //     selectedPlanDetails === "Self" ? data.indDesc : data.insIndDesc,
      //   organizationName: data.insOrgName,
      //   organizationNameDesc: data.insOrgNameDesc,
      //   organizationType: getOrganizationType(data.insOrgType),
      //   organizationTypeDesc: data.insOrgTypeDesc,
      //   income: data.insAnnualIncome,
      //   education: getEducationDetails(data.insEducation),
      //   nomineeFirstName: data.fname,
      //   nomineeLastName: data.lname,
      //   nomineeGender: data.nomGender === "M" ? "Male" : "Female",
      //   nomineeDob: data["nomDob"]?.split("/").join("-"),
      //   nomineeRelationToProposer: data.relation,
      //   appointeeFirstName: data?.afname,
      //   appointeeLastName: data?.alname,
      //   appointeeGender: data?.agender
      //     ? data?.agender === "M"
      //       ? "Male"
      //       : "Female"
      //     : null,
      //   appointeeDob: data?.adob ? data.adob.split("/").join("-") : "",
      //   appointeeRelationToProposer: data.arelation,
      //   itProof: getItProof(data.insRepo),
      //   proposerPanNo: data.panNo,
      //   proposerAadharNo: data.aadhar ? data.aadhar : "",
      //   addressProof: getAddressProof(data.addressProof),
      //   incomeProof: getIncomeProof(data.incomeProof),
      //   idProof: getIdentificationDetails(data.idProof),
      //   isEiaOpted: data.EIA ? "Yes" : "No",
      //   eiaAccNo: data.eiaNumber,
      //   eiaDetails: getEiaRepo(data.preferredInsurance),
      //   heightInCm: data.height,
      //   height: heightInCm(data.height),
      //   weight: removeKg(data.weight),
      //   tobacco: data.tobacco ? "Y" : "N",
      //   narcotics: data.narcotics ? "Y" : "N",
      //   tobaccoConsumedAs: getTobacooConsumed(data.tobaccoConsumedAs),
      //   tobaccoQuantity: getTobacooQuantity(data.tobaccoQuantity),
      //   tobaccoStartDate: data.tobaccoStartDate,
      //   alcohol: data.alcohol ? "Y" : "N",
      //   alcoholConsumedAs: getAlcoholConsumed(data.consumedAs),
      //   alcoholQuantity: getAlcoholQuantity(data.quantityPerDay),
      //   alcoholStartDate: Number(data.years),
      //   pep: data.isPoliticallyExposed,
      //   biPdfUrl: selectedQuote?.brochureUrl || selectedQuote?.brochure,
      //   hazard: data.toggleOccupationHazard ? "Y" : "N",
      //   hazardText: data.toggleOccupationHazard ? data.occupationHazard : null,
      //   armedForces: data.toggleEmployedInArmy ? "Y" : "N",
      //   armedForcesText: data.toggleEmployedInArmy ? data.employedInArmy : null,
      //   weightLoss: data.toggleLostWeight ? "Y" : "N",
      //   weightLossText: data.toggleLostWeight ? data.lostWeight : null,
      //   familyDisease: data.toggleFamilyDisorder ? "Y" : "N",
      //   familyDiseaseText: data.toggleFamilyDisorder
      //     ? data.familyDisorder
      //     : null,
      //   // hospitalized: null,
      //   selfDisease: data.toggleHospitalized ? "Y" : "N",
      //   selfDiseaseText: data.toggleHospitalized ? data.hospitalized : null,
      //   proposerNationality: data.nationality,
      //   nationality: data.nationality,
      //   proposerPanno: data.pan,
      //   proposerResidentStatus: iciciResidentStatusCode(data.residentStatus),
      //   // insuredNationality: data.insNationality,
      //   insuredNationality: data.nationality,

      //   // insuredResidentStatus: iciciResidentStatusCode(data.insResidentStatus),
      //   insuredResidentStatus: iciciResidentStatusCode(data.residentStatus),

      //   country: data.insResidentStatus || data.isBirthCountry,
      //   pDisclaimerFatca: data.agreeTerm ? "on" : "off",
      //   pNationality: data.insNationality,
      //   proposerTaxResident: data.isTaxResident,
      //   pBirthCountry: data.isBirthCountry,
      //   physicalDisabilty: data.disability ? "Y" : "N",
      //   physicalDisabiltytext: data.disabilityText,
      //   selfAccident: data.injury ? "Y" : "N",
      //   selfAccidentText: data.injuryText,
      //   holdPolicy: otherInsuranceDetails ? "Y" : "N",
      //   otherInsuranceCompany: otherInsuranceDetails
      //     ? otherInsuranceList
      //     : null,
      //   gynecologicalDisease: data.gynecologicalComplications ? "Y" : "N",
      //   selfPregnant: data.pregnent ? "Y" : "N",
      //   gynecologicalDiseaseText: data.gynecologicalComplicationsText,
      //   durationWeeksNo: data.pregnentDuration,
      //   covidOne: data.covid ? "Y" : "N",
      //   ageProof: getAgeProof(data.ageProof),
      //   covidTwo: data.covidIsolateWithSymptom ? "Y" : "N",
      //   covidThree: data.covidIsolate ? "Y" : "N",
      //   covidFour: data.covidSymptom ? "Y" : "N",
      //   covidFive: data.cOccupation ? "Y" : "N",
      //   covidOneDescription: data.covidOneDescription,
      //   covidTwoDescription: data.covidTwoDescription,
      //   covidThreeDescription: data.covidThreeDescription,
      //   covidFourDescription: data.covidFourDescription,
      //   covidFiveDescription: data.covidFiveDescription,
      //   diagnosed: data.disease ? "Y" : "N",
      //   diagnosedText: data?.otherDiagnosedDetails,
      //   hypertension: data.bp ? "Y" : "N",
      //   surgery: data.surgery ? "Y" : "N",
      //   diabetes: data.diabetes ? "Y" : "N",
      //   asthma: data.asthma ? "Y" : "N",
      //   stroke: data.nervousDisorder ? "Y" : "N",
      //   pancreatitis: data.gastroIntestinal ? "Y" : "N",
      //   jaundice: data.liverDisorder ? "Y" : "N",
      //   kidney: data.genitourinary ? "Y" : "N",
      //   cancer: data.cancer ? "Y" : "N",
      //   hiv: data.hiv ? "Y" : "N",
      //   chestPain: data.heartDisease ? "Y" : "N",
      //   anaemeia: data.bloodDisorder ? "Y" : "N",
      //   mental: data.psychiatric ? "Y" : "N",
      //   other: data.other ? "Y" : "N",
      //   healthDeclaration: data.healthDeclaration ? "Y" : "N",
      //   medicalCondition: data.healthDeclaration ? data.medicalCondition : null,
      //   dateDiagnosis: data.healthDeclaration ? data.diagnosisDate : null,
      //   treatmentPeriod: data.healthDeclaration ? data.treatmenTerm : null,
      //   currentMedicalCondition: data.healthDeclaration ? data.condition : null,
      //   treatmentDetails: data.healthDeclaration ? data.treatmenDetails : null,
      //   treatmentTest: data.healthDeclaration ? data.treatmenTest : null,
      //   anyDetails: data.healthDeclaration ? data.otherDetails : null,
      //   applicationNo: sessionStorage.getItem("appNo"),
      //   traceId: sessionStorage.getItem("traceId"),
      //   mwpa: data.mwpa ? "Yes" : "No",
      //   trusteeName: data.mwpa ? data.tname : null,
      //   trusteeDob: data.mwpa ? data.tdob : null,
      //   trusteeType: data.mwpa ? data.ttype : null,
      //   trusteePincode: data.mwpa ? data.tpincode : null,
      //   trusteeState: data.mwpa ? data.state : null,
      //   trusteeCity: data.mwpa ? data.city : null,
      //   trusteeAddress: data.mwpa ? data.taddress : null,
      //   trusteePanno: data.mwpa ? data.tpan : null,
      //   trusteeMobileNo: data.mwpa ? data.tmob : null,
      //   trusteeEmail: data.mwpa ? data.temail : null,
      //   beneficiary: data.mwpa ? beneficiaryList : null,
      //   proposerPassportNo: data?.passport,
      //   proposerDateOfArrivedInIndia: data?.arrivingDate,
      //   proposerDateOfLeavingInIndia: data?.leavingDate,
      //   proposerDurationOfStayYear: data?.durationStayAbroad,
      //   proposerDurationOfStayMonth: data?.durationStayAbroadMonthsData,
      //   proposerIntendedDurationOfStayYear: data?.intendedDurationStayAbroad,
      //   proposerIntendedDurationOfStayMonth:
      //     data?.intendeddurationStayAbroadMonthsData,
      //   proposerEmployerDetails: data?.employUniDetails,
      //   proposerBankAccountHelds: data?.bankTypeData,
      //   proposerPurposeStay: data?.purposeOfStay,
      //   nriTINIssuingCountry: data?.panIssuingCountry2,
      //   placeOfBirthNRI: data?.birthPlace,
      //   proposerCountryOfResidence: data?.nriCountryResidence,
      //   nriBirthCountry: data?.countryOfBirth,
      //   proposerCountryOfNationality: data?.nriNationality,
      //   proposerTravelDetails: data?.nriTravelDetailsData,
      //   proposerTravelDetailsDesc: data?.nriDetails,
      //   pTinPanTwo: data?.pan2,
      //   proposalJourneyStatus: "",
      // };
      // dispatch(saveProposalDetails(sendData));
      dispatch(
        saveProposalDetails({
          ...previousSendData,
          traceId: enquiryId,
          applicationNo: appNo,
        }),
      );
      dispatch(initLoader(true));
      setFormSubmitted(false);
    }
  };
  const a = medicalDetailsTwo?.occupationHazard?.length
    ? medicalDetailsTwo?.occupationHazard?.length
    : 0;
  const b = medicalDetailsTwo?.employedInArmy?.length
    ? medicalDetailsTwo?.employedInArmy?.length
    : 0;
  const c = medicalDetailsTwo?.lostWeight?.length
    ? medicalDetailsTwo?.lostWeight?.length
    : 0;
  const d = medicalDetailsTwo?.familyDisorder?.length
    ? medicalDetailsTwo?.familyDisorder?.length
    : 0;
  const e = medicalDetailsTwo?.hospitalized?.length
    ? medicalDetailsTwo?.hospitalized?.length
    : 0;
  const f = medicalDetailsFour?.otherDiagnosedDetails?.length
    ? medicalDetailsFour?.otherDiagnosedDetails?.length
    : 0;
  const g = medicalDetailsThree?.disabilityText?.length
    ? medicalDetailsThree?.disabilityText?.length
    : 0;
  const h = medicalDetailsThree?.injuryText?.length
    ? medicalDetailsThree?.injuryText?.length
    : 0;
  const i = medicalDetailsThree?.gynecologicalComplicationsText?.length
    ? medicalDetailsThree?.gynecologicalComplicationsText?.length
    : 0;
  const totalCount = a + b + c + d + e + f + g + h + i;

  console.log({ totalCount });
  useEffect(() => {
    if (a + b + c + d + e + f + g + h + i > 250) {
      swal("You have exceeded the maximum limit of 250 characters", {
        closeOnClickOutside: false,
      }).then(() => {
        dispatch(setPosition(position));
      });
    }
  }, [a, c, d, e, f, g, h, i]);
  return (
    <>
      {response?.proposalPdfString1 && (
        <a
          href={`data:application/pdf;base64,${response?.proposalPdfString1}`}
          download={`Proposal_${appNo}1.pdf`}
          style={{ textDecoration: "none", color: "white", display: "hidden" }}
          ref={clickRef}
        />
      )}
      {response?.proposalPdfString2 && (
        <a
          href={`data:application/pdf;base64,${response?.proposalPdfString2}`}
          download={`Proposal_${appNo}2.pdf`}
          style={{ textDecoration: "none", color: "white", display: "hidden" }}
          ref={clickRef2}
        />
      )}
      <div className="eligibility__back">
        {/* <span>
          <Link to='/' className='btn--tertiary backBtn'>
            &lt;&nbsp;BACK
          </Link>
        </span> */}
      </div>
      <div className="application_no">
        {<span>Application No: {sessionStorage.getItem("appNo")}</span>}
      </div>
      <div className="container iciciContainer">
        <LaxmiGreeting />
        <div className="eligibility__header">
          <div className="eligibility__greeting">
            <h2 className="heading__secondary">
              Almost done! Help me with few more details.
            </h2>
          </div>
        </div>
        <div className="eligibility__body">
          <div className="eligibility__side">
            <PlanCard
              selectedQuote={selectedQuote}
              additionalRiders={additionalRiders}
              additionalGst={riderGst}
              buyNowBtn={false}
              inbuiltCovers={inbuiltCovers}
              addOnsList={selectedAddOns}
            />
          </div>
          <div className="eligibility__main">
            {/* <div>
              <SelectedPlanForm
                index={0}
                position={position}
                setPosition={setPosition}
              />
            </div> */}
            <div>
              <ProposerForm
                position={position}
                setPosition={setPosition}
                insFor={selectedPlanDetails?.toLowerCase()}
                index={0}
              />
            </div>
            <div>
              <InsuredFtcaForm
                index={1}
                position={position}
                setPosition={setPosition}
              />
              <div>
                <ProposerOccupation
                  position={position}
                  setPosition={setPosition}
                  index={2}
                  insFor={selectedPlanDetails?.toLowerCase()}
                />
              </div>
              <div>
                <InsuredDetails1
                  position={position}
                  setPosition={setPosition}
                  index={3}
                  insFor={selectedPlanDetails?.toLowerCase()}
                />
              </div>
              <div>
                <InsuredDetails2
                  position={position}
                  setPosition={setPosition}
                  index={4}
                  insFor={selectedPlanDetails?.toLowerCase()}
                />
              </div>
            </div>
            <div>
              <InsuredOccupationForm
                position={position}
                index={5}
                setPosition={setPosition}
                insFor={selectedPlanDetails?.toLowerCase()}
              />
            </div>
            <div>
              <OtherInsuranceDetails
                position={position}
                index={6}
                setPosition={setPosition}
              />
            </div>
            <div>
              <NomineeDetailsForm
                position={position}
                index={7}
                setPosition={setPosition}
              />
            </div>
            <div>
              <IdentificationForm
                position={position}
                index={8}
                setPosition={setPosition}
              />
            </div>

            <div>
              <MedicalDetailsOne
                position={position}
                index={9}
                setPosition={setPosition}
              />
            </div>
            <div>
              <MedicalDetailsTwo
                position={position}
                index={10}
                setPosition={setPosition}
              />
            </div>
            <div>
              <MedicalDetails3
                index={11}
                position={position}
                setPosition={setPosition}
              />
            </div>
            <div>
              <MedicalDetails4
                index={12}
                position={position}
                setPosition={setPosition}
              />
            </div>
            <div className="mo-2 d-flex justify-content-center">
              <div className="form-group">
                <div className="m-4 mo-1 form-check d-flex checkBoxWrapper checkBoxWrapProposal">
                  <div className="checkBoxElg">
                    <Checkbox
                      elg
                      checked={agreeTerm}
                      disabled={position !== 13}
                      id="gridCheck"
                      onChange={e => setAgreeTerm(e.target.checked)}
                    ></Checkbox>
                  </div>
                  <div
                    className="form-check-label eligibility__footer dv elgTerms "
                    style={{ textAlign: "justify" }}
                  >
                    I confirm all the details shared are correct and accurate as
                    per my knowledge and I agree with all the Terms and
                    Conditions. I also declare that the information provided
                    above is true and accept that if it is found to be false, it
                    may impact claims.
                  </div>
                  <div
                    className="form-check-label eligibility__footer mv termsMob"
                    style={{ textAlign: "justify" }}
                  >
                    I accept Terms & Conditions
                    <CustomTooltip
                      rider="true"
                      id="RiderInbuilt__Tooltip"
                      place={"top"}
                      customClassName="mt-3  elgToolTipData"
                      Position={{ top: 20, left: 4 }}
                    >
                      <img
                        data-tip="<h3 >Terms & Conditions</h3> <div>I confirm all the details shared are correct and accurate as
                per my knowledge and I agree with all the Terms and
                Conditions. I also declare that the information provided above
                is true and accept that if it is found to be false, it may
                impact claims. </div>"
                        data-html={true}
                        data-for="RiderInbuilt__Tooltip"
                        src={tooltip}
                        alt="tooltip"
                        className="termsTooltip"
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
            {agreeTerm && (
              <div style={{ marginTop: "10px" }}>
                <FormGroup xlg eligibilityPage className="mobileFinalBtn">
                  <button
                    disabled={
                      totalCount > 250 ||
                      !agreeTerm ||
                      position <= 12 ||
                      formSubmitted
                    }
                    type="submit"
                    onClick={handleSubmit}
                    className={
                      totalCount > 250 || !agreeTerm || position <= 12
                        ? "btn btn--disabled btn--lg uppercase bold buttton proc formCardBtn"
                        : "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn"
                    }
                  >
                    <span className="proceed">Submit</span>
                  </button>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loader>Your proposal details are getting submitted.</Loader>}
    </>
  );
};

export default ProposalPage;
