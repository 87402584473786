import { createSlice } from "@reduxjs/toolkit";

const maxDiscountSlice = createSlice({
  name: "maxDiscountSlice",
  initialState: {
    isSalaried: "Y",
    backfromRiders: false,
    openMaxDiscount: false,
  },

  reducers: {
    setSalariedmax: (state, action) => {
      state.isSalaried = action.payload;
    },
    resetmaxDiscountData: (state, action) => {
      state.isSalaried = "Y";
    },
    setBackFromRiders: (state, action) => {
      state.backfromRiders = action.payload;
    },
    setOpenMaxDiscount: (state, action) => {
      state.openMaxDiscount = action.payload;
    },
  },
});

export const {
  setSalariedMax,
  resetmaxDiscountData,
  setBackFromRiders,
  setOpenMaxDiscount,
} = maxDiscountSlice.actions;

export default maxDiscountSlice.reducer;
