import React, { useEffect, useRef, useState } from "react";
import Popup from "./Popup";
import { Edit } from "../SvgComp";
import { Heading } from "./ModifyDetails.style";

import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import {
  dateUtil,
  getAmount,
  numOnly,
  getNumberfromString,
  getValue,
  getAge,
  noNum,
  nameWithSpace,
  onlyOneSpace,
} from "../../utils/inputUtils";
import {
  modifyDetailSchema,
  modifyDetailSchema2,
} from "../../utils/validationSchema";
import { useSelector, useDispatch } from "react-redux";
import {
  setStartAnnualIncome,
  // setErrors,
  updateUserDetails,
} from "../../modules/GreetingPage/greeting.slice";

import {
  // amountInNumbers,
  // createArrayOfNumbers,
  getCoverUptoForAge,
  getDefaultCoverAmount,
  // numberToDigitWord,
  validateCoverAmount,
  validateCoverUpto,
} from "../../modules/QuoteFilter/helper";
import swal from "sweetalert";
import {
  AgeWrapper,
  Form,
  FormGroup,
  FormWrapper,
} from "../../components/Form/Form";
import { Label, TextInput, Button } from "../Form/ModifyForm";
// import {
//   setCoverAmount,
//   setCoverAmountLumpsumMonthly,
//   setCoverUpto,
//   setCoverUptoValues,
//   setMorePlans,
//   setNomineeGets,
//   setPaymentTermOption,
//   setPaymentTermTerm,
//   setPayPremium,
// } from "../../modules/QuoteFilter/quoteFilterSlice";
// import { setCameFromCompare } from "../../modules/QuotePage/quotePageSlice";
import "./modify-details.scss";
import CustomDropDown from "../CutomDropDown/CustomDropDown";
import Loader from "../Loader/Loader";
import { useCustomLocation } from "../../custom-hooks";
import { validateAnnualIncome } from "../../modules/GreetingPage/Form1";
import { useAmountInput } from "../../custom-hooks/useAmountInput";
import { isNumber } from "../../utils";

const ModifyDetails = ({
  show,
  onClose,
  startingAnnualIncome,
  setStartingAnnualIncome,
}) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(({ greeting }) => greeting);

  const { customerDetails, startAnnualIncome } = useSelector(
    state => state.greeting,
  );
  const { popup } = useSelector(state => state.quoteFilter);
  const { corporateJourney } = useSelector(state => state.quote);

  let temp = corporateJourney?.is_gameskraft;
  const { enquiryId } = useCustomLocation();

  const [age, setAge] = useState(getAge(customerDetails["dob"]));
  const [email, setEmail] = useState(customerDetails["email"]);
  const [gender, setGender] = useState(
    customerDetails["gender"] === "M" ? "Male" : "Female",
  );
  const [tobaccoHabit, setTobaccoHabit] = useState(
    customerDetails["tobaccoHabit"] === "Y" ? "Yes" : "No",
  );
  const [qualification, setQualification] = useState(
    customerDetails.qualification,
  );
  const [annualIncome, setAnnualIncome] = useState(
    isNumber(customerDetails["annualIncome"])
      ? Number(customerDetails["annualIncome"]) < 10000000
        ? Math.floor(Number(customerDetails["annualIncome"]) / 100000) *
            100000 <=
          startAnnualIncome
          ? String(startAnnualIncome)
          : String(
              Math.floor(Number(customerDetails["annualIncome"]) / 100000) *
                100000,
            )
        : String(
            Math.floor(Number(customerDetails["annualIncome"]) / 2500000) *
              2500000,
          )
      : getValue(Number(customerDetails["annualIncome"])),
  );
  const [isGenderValidated, setGenderValidated] = useState(false);
  const [isQualificationValidated, setQualificationValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSmokerValidated, setSmokerValidated] = useState(false);
  const [isIncomeValidated, setIncomeValidated] = useState(false);
  const [changedItems, setChangedItems] = useState(0);
  console.log(changedItems, "changedItems");

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(
      temp === "active" ? modifyDetailSchema2 : modifyDetailSchema,
    ),
    mode: "onBlur",
    shouldUnregister: false,
  });

  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };

  const handleDobChange = event => {
    setAge(dateUtil(event, date, false, false, true));
  };

  const { coverAmount, coverUpto } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter,
  );
  useEffect(() => {
    setAnnualIncome(getValue(annualIncome));
  }, []);
  useEffect(() => {
    var ChangedArray = [];
    if (customerDetails?.gender !== (gender === "Male" ? "M" : "F")) {
      //setChangedItems(changedItems + 1);
      ChangedArray.push("gender");
    } else {
      ChangedArray.push(false);
    }
    if (customerDetails?.age !== age) {
      //setChangedItems(changedItems + 1);
      ChangedArray.push("age");
    } else {
      ChangedArray.push(false);
    }
    if (
      customerDetails?.tobaccoHabit !== (tobaccoHabit === "Yes" ? "Y" : "N")
    ) {
      //setChangedItems(changedItems + 1);
      ChangedArray.push("tobaccoHabit");
    } else {
      ChangedArray.push(false);
    }

    var filteredChangedArray = ChangedArray?.filter(Boolean);

    setChangedItems(filteredChangedArray.length);
  }, [gender, tobaccoHabit, age]);

  // set dropdowns to default on opening/closing of the popup
  useEffect(() => {
    if (!formSubmitted) {
      setGender(customerDetails["gender"] === "M" ? "Male" : "Female");
      setTobaccoHabit(customerDetails["tobaccoHabit"] === "Y" ? "Yes" : "No");
      setQualification(customerDetails.qualification);
      setAnnualIncome(
        isNumber(customerDetails["annualIncome"])
          ? Number(customerDetails["annualIncome"]) < 10000000
            ? Math.floor(Number(customerDetails["annualIncome"]) / 100000) *
                100000 <=
              startAnnualIncome
              ? String(startAnnualIncome)
              : String(
                  Math.floor(Number(customerDetails["annualIncome"]) / 100000) *
                    100000,
                )
            : String(
                Math.floor(Number(customerDetails["annualIncome"]) / 2500000) *
                  2500000,
              )
          : getValue(Number(customerDetails["annualIncome"])),
      );
      setAge(getAge(customerDetails["dob"]));
    } else {
      setFormSubmitted(false);
    }
  }, [popup]);

  const onSubmit = formData => {
    const data = { ...formData };
    setFormSubmitted(true);
    data.annualIncome = isNumber(annualIncome)
      ? annualIncome
      : getNumberfromString(annualIncome);

    console.log({ TESTING: annualIncome });

    const annualIncomeError = validateAnnualIncome(annualIncome, {
      age,
      education: qualification,
    });

    if (annualIncomeError) {
      setError("annualIncome", {
        type: "manual",
        message: annualIncomeError,
      });
      return;
    }
    const newData = {
      ...customerDetails,
      ...data,
      qualification,
      gender: gender === "Male" ? "M" : "F",
      tobaccoHabit: tobaccoHabit === "Yes" ? "Y" : "N",
      // annualIncome: getNumberfromString(annualIncome),
      sumAssured: getDefaultCoverAmount(
        age,
        // getNumberfromString(annualIncome),
        data.annualIncome,
        customerDetails.preferance,
      ),
      policyTerm:
        getCoverUptoForAge(age) !== "99"
          ? getCoverUptoForAge(age).slice(0, 2) - age
          : "",
      age: age,
      preferance: customerDetails["preferance"],
      modifyDetails: "N",
    };

    // dispatch(setCameFromCompare(true));

    if (validateCoverUpto(parseInt(coverUpto), age)) {
      newData.policyTerm = parseInt(coverUpto) - age;
    } else {
      newData.policyTerm =
        getCoverUptoForAge(age) !== "99"
          ? getCoverUptoForAge(age).slice(0, 2) - age
          : "";
    }

    const isValidCoverAmount = validateCoverAmount(
      parseInt(getNumberfromString(coverAmount)),
      age,
      // parseInt(getNumberfromString(annualIncome)),
      parseInt(data.annualIncome),
      customerDetails.preferance,
    );

    if (isValidCoverAmount) {
      newData.sumAssured = getNumberfromString(coverAmount);
    } else {
      newData.sumAssured = getDefaultCoverAmount(
        age,
        // getNumberfromString(annualIncome),
        parseInt(data.annualIncome),
        customerDetails.preferance,
      );
    }

    if (
      customerDetails?.annualIncome !== data.annualIncome &&
      !isValidCoverAmount
    ) {
      //   customerDetails?.gender !== gender ||
      //   customerDetails?.tobaccoHabit !== tobaccoHabit
      // ) {
      newData.preferance = "Max Cover Amount";
    }

    newData.traceId = enquiryId;

    setTimeout(() => {
      if (changedItems > 1) {
        swal(`Premium amount changed due to change in inputs`);
        setChangedItems(0);
      } else if (changedItems === 1) {
        if (customerDetails?.gender !== (gender === "Male" ? "M" : "F")) {
          swal(`Premium amount changed due to change in Gender`);
        } else if (customerDetails?.age !== age) {
          swal(`Premium amount changed due to change in DOB`);
        } else if (
          customerDetails?.tobaccoHabit !== (tobaccoHabit === "Yes" ? "Y" : "N")
        ) {
          swal(`Premium amount changed due to change in Smoking Habits`);
        }
        setChangedItems(0);
      }
    }, 500);

    console.log({ newData });

    dispatch(updateUserDetails(newData, onClose));

    // dispatch(setCoverUpto({ value: getCoverUptoForAge(age) }));

    // dispatch(
    //   setCoverUptoValues(createArrayOfNumbers(age + 5, 100, 1, "years"))
    // );

    // dispatch(setMorePlans({ value: "Choose your plans" }));

    // dispatch(
    //   setCoverAmount({
    //     value: numberToDigitWord(
    //       String(getDefaultCoverAmount(age, getNumberfromString(annualIncome))),
    //       50000,
    //       2
    //     ),
    //   })
    // );

    // dispatch(
    //   setCoverAmountLumpsumMonthly({
    //     coverAmount: numberToDigitWord(
    //       String(getDefaultCoverAmount(age, getNumberfromString(annualIncome))),
    //       50000,
    //       2
    //     ),
    //     coverAmountPercent: "25%",
    //     increaseIncome: "Please select",
    //     additionalIncome: {
    //       amount: "50000",
    //       period: "10 years",
    //     },
    //   })
    // );

    // dispatch(setPaymentTermOption({ value: "Regular Pay" }));

    // dispatch(setPaymentTermTerm({ value: "5 years" }));

    // dispatch(setPayPremium("Monthly"));

    // dispatch(setNomineeGets({ value: "Lumpsum" }));

    // onClose();
  };

  //   const formRef = useRef(null);

  //   const initRef = useRef(true);

  //   useEffect(() => {
  //     if (!initRef.current) {
  //       const formEnter = (evt) => {
  //         console.dir(evt.target);
  //         if (evt.target.id === "modify-details-form") {
  //           formRef.submit();
  //           console.log("Hello");
  //         }
  //       };
  //       formRef.addEventListener("keydown", (evt) => console.log(evt));
  // 	  console.dir(formRef)
  //     }
  //     initRef.current = false;
  // 	console.dir(formRef)
  //   }, []);
  const handleFormEnter = evt => {
    if (evt.target.id === "modify-details-form" && evt.key === "Enter") {
      formRef.current.modifySubmit.click();
    }
  };

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) formRef.current.focus();
  }, [tobaccoHabit, annualIncome, gender]);

  const onInvalid = () => {
    if (annualIncome === "Annual Income") setIncomeValidated(true);
    else setIncomeValidated(false);
    if (gender === "Gender") setGenderValidated(true);
    if (tobaccoHabit === "Smoker?") setSmokerValidated(true);
  };
  useEffect(() => {
    if (qualification === "Post Graduate") {
      if (age >= 18 && age <= 40) {
        let x = 250000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 41 && age <= 60) {
        let x = 300000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      }
    } else if (qualification === "Graduate") {
      if (age >= 18 && age <= 40) {
        let x = 250000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 41 && age <= 60) {
        let x = 300000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      }
    } else if (qualification === "12th Pass" && temp === "active") {
      if (age >= 18 && age <= 60) {
        let x = 500000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      }
    } else if (qualification === "12th Pass") {
      if (age >= 18 && age <= 60) {
        let x = 300000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      } else if (age >= 61 && age <= 65) {
        let x = 500000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      }
    } else if (qualification === "10th Pass") {
      if (age >= 18 && age <= 65) {
        let x = 1000000;
        if (
          Number(getNumberfromString(annualIncome)) < x &&
          annualIncome &&
          !annualIncome.includes("Select")
        ) {
          setAnnualIncome("Annual Income");
        }
        setStartingAnnualIncome(x);
      }
    } else {
      setStartingAnnualIncome(300000);
    }
    dispatch(setStartAnnualIncome(startingAnnualIncome));
  }, [age, qualification]);
  useEffect(() => {
    onInvalid();
  }, [annualIncome]);

  const {
    value: annualIncomeText,
    handleChange: handleAnnualIncomeTextChange,
  } = useAmountInput(customerDetails["annualIncome"]);

  const content = (
    <FormWrapper modifyDetails>
      <Heading modifyDetails>{Edit}Modify details</Heading>
      <Form
        style={{ outline: "none" }}
        id="modify-details-form"
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        noValidate
        tabIndex="-1"
        onKeyDown={handleFormEnter}
        ref={formRef}
      >
        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="firstName"
            style={{
              fontWeight: "normal",
            }}
          >
            First Name
          </Label>

          <TextInput
            md
            capitalise
            error={errors.firstName && "danger"}
            type="text"
            name="firstName"
            id="firstName"
            defaultValue={customerDetails["firstName"]}
            onInput={nameWithSpace}
            onKeyPress={onlyOneSpace}
            autoComplete="none"
            placeholder=" "
            ref={register}
            maxLength={50}
          />
          {errors.firstName && (
            <p className="errorG">{errors.firstName.message}</p>
          )}
        </FormGroup>
        <FormGroup
          md
          modifyDetails
          style={{
            fontWeight: "normal",
          }}
          className="modify-details-form-group"
        >
          <Label
            md
            htmlFor="lastName"
            style={{
              fontWeight: "normal",
            }}
          >
            Last Name
          </Label>
          <TextInput
            md
            capitalise
            type="text"
            name="lastName"
            id="lastName"
            error={errors.lastName && "danger"}
            defaultValue={customerDetails["lastName"]}
            onInput={nameWithSpace}
            onKeyPress={onlyOneSpace}
            placeholder=" "
            autoComplete="none"
            ref={register}
            maxLength={50}
          />

          {errors.lastName && (
            <p className="errorG">{errors.lastName.message}</p>
          )}
        </FormGroup>
        {/* <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="dob"
            style={{
              fontWeight: "normal",
            }}
          >
            DOB (DD/MM/YYYY)
          </Label>

          <TextInput
            md
            type="text"
            name="dob"
            id="dob"
            style={{ wordSpacing: "-4px" }}
            maxLength="14"
            onChange={handleDobChange}
            defaultValue={customerDetails["dob"]}
            placeholder=" "
            autoComplete="none"
            error={errors.dob && "danger"}
            ref={attachRef}
          />

          {age && (
            <AgeWrapper className="modify-details-age-wrapper">
              {age} years
            </AgeWrapper>
          )}
          {errors.dob && <p className="errorG">{errors.dob.message}</p>}
        </FormGroup> */}
        {temp === "active" ? (
          <FormGroup md modifyDetails className="modify-details-form-group">
            <Label
              md
              htmlFor="dob"
              style={{
                fontWeight: "normal",
              }}
            >
              DOB (DD/MM/YYYY)
            </Label>

            <TextInput
              md
              type="text"
              name="dob"
              id="dob"
              style={{ wordSpacing: "-4px" }}
              maxLength="14"
              onChange={handleDobChange}
              defaultValue={customerDetails["dob"]}
              placeholder=" "
              autoComplete="none"
              error={errors.dob && "danger"}
              ref={attachRef}
            />

            {age && (
              <AgeWrapper className="modify-details-age-wrapper">
                {age} years
              </AgeWrapper>
            )}
            {errors.dob && <p className="errorG">{errors.dob.message}</p>}
          </FormGroup>
        ) : (
          <FormGroup md modifyDetails className="modify-details-form-group">
            <Label
              md
              htmlFor="dob"
              style={{
                fontWeight: "normal",
              }}
            >
              DOB (DD/MM/YYYY)
            </Label>

            <TextInput
              md
              type="text"
              name="dob"
              id="dob"
              style={{ wordSpacing: "-4px" }}
              maxLength="14"
              onChange={handleDobChange}
              defaultValue={customerDetails["dob"]}
              placeholder=" "
              autoComplete="none"
              error={errors.dob && "danger"}
              ref={attachRef}
            />

            {age && (
              <AgeWrapper className="modify-details-age-wrapper">
                {age} years
              </AgeWrapper>
            )}
            {errors.dob && <p className="errorG">{errors.dob.message}</p>}
          </FormGroup>
        )}
        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="gender"
            style={{
              fontWeight: "normal",
            }}
          >
            Gender
          </Label>
          <CustomDropDown
            mod="true"
            items={["Male", "Female"]}
            onChange={e => setGender(e)}
            validate={isGenderValidated}
            validateMessage="Gender is required"
            selectedItem={gender}
          />
        </FormGroup>

        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="annualIncome"
            style={{
              fontWeight: "normal",
            }}
          >
            Smoker
          </Label>
          <CustomDropDown
            mod="true"
            items={["Yes", "No"]}
            selectedItem={tobaccoHabit}
            validate={isSmokerValidated}
            validateMessage="This field is required"
            onChange={e => setTobaccoHabit(e)}
          />
        </FormGroup>
        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="annualIncome"
            style={{
              fontWeight: "normal",
            }}
          >
            Qualification
          </Label>
          <CustomDropDown
            mod="true"
            items={["Post Graduate", "Graduate", "12th Pass", "10th Pass"]}
            selectedItem={qualification}
            validate={isQualificationValidated}
            validateMessage="This field is required"
            onChange={e => {
              setQualification(e);
            }}
          />
        </FormGroup>

        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="annualIncome"
            style={{
              fontWeight: "normal",
            }}
          >
            Annual Income
          </Label>
          <CustomDropDown
            annualIncome
            mod="true"
            items={getAmount(
              startingAnnualIncome,
              100000000,
              100000000,
              100000,
              "annualIncome",
              age,
            ).reverse()}
            searchable
            currentSelected={annualIncome}
            validate={isIncomeValidated}
            validateMessage="Please specify your annual income"
            onChange={e => {
              setAnnualIncome(e);
              onInvalid(e);
            }}
            // customInput                          ---------------------- to-do
          />
          {/* <TextInput
            md
            type="text"
            id="annualIncome"
            name="annualIncome"
            value={annualIncomeText}
            onChange={handleAnnualIncomeTextChange}
            ref={register}
            placeholder=" "
            autoComplete="none"
            error={errors?.annualIncome && "danger"}
          /> */}

          {errors?.annualIncome && (
            <p className="errorG">{errors?.annualIncome?.message}</p>
          )}
        </FormGroup>

        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="email"
            style={{
              fontWeight: "normal",
            }}
          >
            EMAIL ID
          </Label>
          <TextInput
            md
            type="text"
            id="email"
            name="email"
            defaultValue={email}
            ref={register}
            placeholder=" "
            autoComplete="none"
            onChange={e => {
              e.target.value = e.target.value.replace(" ", "");
              setEmail(e.target.value);
            }}
            error={errors.email && "danger"}
            style={{ textTransform: "lowercase" }}
          />

          {errors.email && <p className="errorG">{errors.email.message}</p>}
        </FormGroup>

        <FormGroup md modifyDetails className="modify-details-form-group">
          <Label
            md
            htmlFor="con"
            style={{
              fontWeight: "normal",
            }}
          >
            Mobile No.
          </Label>{" "}
          <TextInput
            md
            type="text"
            id="con"
            name="mobileNo"
            ref={register}
            defaultValue={customerDetails["mobileNo"]}
            placeholder=" "
            maxLength="10"
            autoComplete="none"
            error={errors.mobileNo && "danger"}
            onInput={numOnly}
            pattern="[5-9]{1}[0-9]{9}"
          />
          {errors.mobileNo && (
            <p className="errorG">{errors.mobileNo.message}</p>
          )}
        </FormGroup>

        <div className="u-flex-col floatingbtnPop modifyDetailsButton">
          <Button
            type="submit"
            id="modifySubmit"
            md
            secondary
            center
            padding
            disabled={
              isGenderValidated || isIncomeValidated || isSmokerValidated
            }
            // className="modbtn monBtn monthly-popup-submit-btn"
            className="monthly-popup-submit-btn"
          >
            Confirm
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
  let PopupWidth;
  let height;

  if (window.matchMedia("(max-width: 767px)").matches) {
    PopupWidth = "100%";
    height = "100%";
  } else {
    PopupWidth = "630px";
    height = "auto";
  }

  const updatingUserDetails = (
    <div className="loading-quotes" style={{ height: "auto" }}>
      <p>
        {
          // isQuotesLoading === "update-quotation"
          //   ? "Please wait while we update your quotation"
          //   :
        }
      </p>
      <Loader />
    </div>
  );

  return (
    <Popup
      lrgClose="true"
      transparentBackground={"false"}
      show={show}
      onClose={onClose}
      content={isLoading ? updatingUserDetails : content}
      height={height}
      width={PopupWidth}
      size="true"
      topFixed={"48px"}
      // closeButtonStyle={{ marginRight: '13px', marginTop: '1px' }}
      closeBtnClass="send-quotes-close"
    />
  );
};

export default ModifyDetails;
