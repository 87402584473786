import { useState, useEffect } from "react";
import { debounce } from "../utils/debounce";

function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    const debouncedHandleResize = () => debounce(handleResize, 200)();
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);
  return { width };
}

export default useWindowResize;
