import { createSlice } from "@reduxjs/toolkit";
import { doUpload, endJourney } from "./serviceApi";
import swal from "sweetalert";
import { updateQuotation } from "../EligibilityPage/serviceApi";
const documentUploadSlice = createSlice({
  name: "documentUpload",
  initialState: {
    uploading: [],
    uploadResponse: false,
    loading: false,
    isDocumentUploaded: false,
  },
  reducers: {
    setUploading: (state, { payload }) => {
      if (payload[0] === "add") state.uploading.push(payload[1]);
      else if (payload[0] === "remove")
        state.uploading = state.uploading.filter(item => item !== payload[1]);
      else console.error("Action must be either add or remove");
    },
    createResponseData: (state, action) => {
      state.uploadResponse = action.payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
    setIsDocumentUploaded: (state, action) => {
      state.isDocumentUploaded = action.payload;
    },
  },
});

export const {
  setUploading,
  createResponseData,
  initLoader,
  setIsDocumentUploaded,
} = documentUploadSlice.actions;

export default documentUploadSlice.reducer;

export const uploadDoc = (data, ic, history) => async dispatch => {
  const { setDetails } = data;
  try {
    dispatch(setUploading(["add", data.fileType]));
    const response = await doUpload(data);

    dispatch(setUploading(["remove", data.fileType]));

    if (response.data.status) {
      dispatch(initLoader(false));
      dispatch(createResponseData(response.data));
      setDetails && setDetails();
      if (ic === "kotak_life" && history) {
        await updateQuotation({
          traceId: data.traceId,
          requestType: "document_upload",
        });
        dispatch(setIsDocumentUploaded(true));
        // history.replace(`/thankyou?enquiryId=${data.traceId}`);
      }
      return response;
    } else {
      setDetails && setDetails();
      dispatch(initLoader(false));
      swal(response.data.message, {
        icon: "error",
      });
    }
  } catch (err) {
    setDetails && setDetails();
    console.error(err);
  }
};
export const setJourneyComplete = (request, history) => async dispatch => {
  const { data } = await endJourney(request);
  if (data.status) {
    await updateQuotation({
      traceId: request.traceId,
      requestType: "document_upload",
    });
    dispatch(setIsDocumentUploaded(true));
    history.replace(`/thankyou?enquiryId=${request.traceId}`);
  }
};
