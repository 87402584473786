import * as yup from "yup";
import "yup-phone";
import { getAge, removeKg } from "./inputUtils";
import moment from "moment";

const panno = /^([a-zA-Z]{3}[P]{1}[a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$/;
const pannoOrBlank =
  /^([a-zA-Z]{3}[P]{1}[a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$|^$/;

const pinCode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
export const mobile = /[5-9]{1}[0-9]{9}$/;
const oneSpace =
  /^[a-zA-Z0-9]{1}([a-zA-Z0-9.\(\)\/\-\#\&\:\,\"\']*)((\s([a-zA-Z0-9.\(\)\/\-\#\&\:\,\"\'])+)*)$/;
// const nomineeKotak = /[a-zA-Z]{1}([a-zA-Z]*)((\s([a-zA-Z])+)*)$/;
export const acceptedEmailExtensions = [
  ".com",
  ".org",
  ".in",
  ".outlook",
  ".co.in",
  ".rediff",
  ".net",
  ".co",
  ".info",
  ".local",
  ".bike",
  ".jll.com",
  ".digital",
  ".quesscorp.com",
  ".jspl.com",
  ".nasscom.in",
  ".optumcare.com",
];

const isAlphaNumericLowercase = (str = "") => str.match(/[^0-9]/);

export const validateEmail = givenValue => {
  if (!givenValue) return true;
  const domain = givenValue.split("@")[1];
  if (!domain) return true;
  const count = (domain.match(/\./g) || []).length;
  if (count >= 3) return false;
  return true;
};

export const SpouseSchema = yup.object({
  gender: yup
    .string()
    .required("Gender is required.")
    .test("gender", "Gender is required", value => value !== "Select"),
  dob: yup
    .string()
    .required("Date of birth is required.")
    .test("dob", "Age should be between 18 and 60", value => {
      return getAge(value) >= "18" && getAge(value) <= "60";
    }),
  smoker: yup
    .string()
    .required("Smoker is required.")
    .test("smoker", "Smoker is required", value => value !== "Select"),
  working: yup
    .string()
    .required("working is required.")
    .test("working", "working is required", value => value !== "Select"),
  riderCoveramt: yup
    .string()
    .required("riderCoveramt is required.")
    .test(
      "riderCoveramt",
      "Rider cover amount is required",
      value => value !== "Select",
    ),
  education: yup
    .string()
    .required("Education is required.")
    .test("education", "Education is required", value => value !== "Select")
    .test("education", "Add-on not applicable for above education.", value => {
      if (value === "10th Pass") return false;
      else if (value === "Below 10th Pass") return false;
      else if (value === "Illiterate") return false;
      else return true;
    }),
});
export const ChildSchema = yup.object({
  dob: yup
    .string()
    .required("Date of birth is required.")
    .test("dob", "Age should be below 15", value => {
      return getAge(value) >= "0" && getAge(value) <= "15";
    }),
  childEducation: yup
    .string()
    .required("child Education is required.")
    .test(
      "childEducation",
      "Education is required",
      value => value !== "Select",
    ),
  annualSchoolFee: yup.string().required("School Fee is required."),
});
export const formCardSchema = yup.object({
  panCountry: yup.boolean(),
  affinityEmail: yup.boolean(),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter valid email id")
    .required("Email id is required"),
  secondary_email: yup
    .string()
    .email("Please enter valid personal email id")
    .required("Personal email id is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email id",
    )
    .test("email", "Please enter valid email id", validateEmail),
  // .when("affinityEmail", {
  //   is: (value) => value === true,
  //   then: yup
  //     .string()
  //     .notOneOf(
  //       [yup.ref("email")],
  //       "Personal email id should not be same as work email id"
  //     ),
  // }),
  mobileNo: yup
    .string()
    .required("Mobile no. is required")
    .matches(mobile, "Please provide a valid Mobile no.")
    .label("Mobile No."),

  // pan: yup.string().when("panCountry", {
  // 	is: (value) => value === true,
  // 	then: yup
  // 		.string()
  // 		.required("PAN no. is required")
  // 		.matches(panno, "Please enter valid PAN no.")
  // 		.max(10, "Please enter a valid pan number"),
  // }),

  pan: yup
    .string()
    .matches(pannoOrBlank, "Please enter valid PAN no.")
    .when("panCountry", {
      is: value => value === true,
      then: yup
        .string()
        .required("PAN no. is required")
        .matches(panno, "Please enter valid PAN no."),
    }),

  dob: yup
    .string()
    .test("dob", "age should be between 18 and 70", value => {
      return getAge(value) >= "18" && getAge(value) <= "70";
    })
    .required(),
});

export const selectedPlanFormSchema = yup.object({
  policyFor: yup.string().required().label("Policy"),
});

export const eligiblityCardSchema = yup.object({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(pinCode, "Please put a valid pincode"),
});

export const insuredFtcaSchema = yup.object({
  pan: yup
    .string()
    .required("Pan no. is required")
    .matches(panno, "Please enter valid PAN no.")
    .max(10, "please enter a valid pan number"),
  // taxResidentt: yup.string().required("Required"),
  // birthIndiaa: yup.string().required("Required"),
  residentStatus: yup.string().required(),
  passport: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup.string().required("Passport is Required"),
  }),
  purposeOfStay: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup.string().required("Required Field"),
  }),
  employUniDetails: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup.string().required("Required Field"),
  }),
  arrivingDate: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup
      .string()
      .required("Arriving date is required")
      .min(10, "Enter a Full Date"),
  }),
  leavingDate: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup
      .string()
      .required("Leaving date is required")
      .min(10, "Enter a Full Date")
      .test(
        "leavingDate",
        "Minimum days of stay should be 20",
        function (value) {
          return (
            moment(value, "DD/MM/YYYY").diff(
              moment(this.parent.arrivingDate, "DD/MM/YYYY"),
              "days",
            ) >= 20
          );
        },
      ),
  }),
  durationStayAbroad: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup.string().required("Duration of stay abroad is required"),
  }),

  intendedDurationStayAbroad: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup.string().required("Duration of intended stay abroad is required"),
  }),

  nriNationality: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup
      .string()
      .required("Nationality Required")
      .test("nriNationality", "Choose a correct Value", value => {
        if (!value) return true;
        else return !value.includes("Select Country");
      }),
  }),

  nriCountryResidence: yup.string().when("residentStatus", {
    is: value => value !== "Resident Indian",
    then: yup
      .string()
      .required("Country of Residence Required")
      .test("nriCountryResidence", "Choose a correct Value", value => {
        if (!value) return true;
        else return !value.includes("Select Country");
      }),
  }),

  birthCountry: yup.boolean().required(),

  countryOfBirth: yup.string().when("birthCountry", {
    is: value => value === false,
    then: yup
      .string()
      .required("Country of Birth Required")
      .test("countryOfBirth", "Choose a correct Value", value => {
        if (!value) return true;
        else return !value.includes("Select Country");
      }),
  }),

  birthPlace: yup.string().when("birthCountry", {
    is: value => value === false,
    then: yup.string().required("Birth Place is Required"),
  }),

  taxResident: yup.boolean().required(),

  // pan2: yup.string().when("taxResident", {
  // 	is: (value) => value === false,
  // 	then: yup.string()
  // }),

  // panIssuingCountry2: yup.string().when("taxResident", {
  // 	is: (value) => value === false,
  // 	then: yup
  // 		.string()
  // 		.required("Country of Birth Required")
  // 		.test("countryOfBirth", "Choose a correct Value", (value) => {
  // 			if (!value) return true;
  // 			else return !value.includes("Select Country");
  // 		}),
  // }),
});

export const proposerFormSchema = yup.object({
  firstName: yup.string().required().min(3),
  lastName: yup.string().required().min(3),

  email: yup.string().email().required(),
  addressLine1: yup
    .string()
    .required("Address Line 1 is required")
    .min(3, "Minimum 3 characters are required"),
  //addressLine2: yup.string().required().min(3),
  city: yup
    .string()
    .required("City is required")
    .min(3, "Minimum 3 characters are required"),
  mobileNo: yup
    .string()
    .required("Mobile No. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please provide a valid Mobile no.")
    .label("Mobile No."),
  dob: yup
    .string()
    .test("dob", "Age should be between 18 and 70", value => {
      return getAge(value) >= "18" && getAge(value) <= "70";
    })
    .required(),
  gender: yup.string().required().label("Gender"),
  pinCode: yup
    .string()
    .required()
    .matches(pinCode, "Please put a valid pincode"),
  p_address_line_1: yup
    .string()
    .required("Permanent Address Line 1 is required")
    .min(3, "Minimum 3 characters are required"),
  //p_address_line_2: yup.string().required().min(3),
  p_city: yup
    .string()
    .required("City is required")
    .min(3, "Minimum 3 characters are required"),
  p_pincode: yup
    .string()
    .required("Pincode is required")
    .matches(pinCode, "Please put a valid pincode"),
  p_state: yup
    .string()
    .required("State is required")
    .min(3, "Minimum 3 characters are required"),
  maritalStatus: yup
    .string()
    .required("This field is required")
    .test("", "Please select a valid status", val => {
      if (val === "Marital Status") {
        return false;
      } else return true;
    }),
});
export const talkToExpertSchema = yup.object({
  phone: yup
    .string()
    .required("Mobile No. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please provide a valid Mobile no."),
});

export const greetingSchema = yup.object({
  // firstName: yup
  //   .string()
  //   .required("First name is required")
  //   .min(1, "Minimum 1 character is required")
  //   .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  // lastName: yup
  //   .string()
  //   .required("Last name is required")
  //   .min(1, "Minimum 1 characters are required")
  // //   .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  // email: yup
  //   .string()
  //   .email("Please enter valid work email id")
  //   .matches(/.*\.[A-Za-z]{2,}$/, "Please enter valid work email id")
  //   .test("email", "Please enter valid email id", (givenValue) => {
  //     const value = givenValue.toLowerCase();
  //     if (acceptedEmailExtensions.find((ext) => value.endsWith(ext))) {
  //       if (!value.endsWith(".co.in") && !value.endsWith(".jll.com"))
  //         if (
  //           value
  //             .substr(
  //               value.indexOf("@") + 1,
  //               value.lastIndexOf(".") - (value.indexOf("@") + 1)
  //             )
  //             .includes(".")
  //         ) {
  //           return false;
  //         }
  //       return true;
  //     }
  //     return false;
  //   })
  //   .required("Work email id is required"),
  // mobileNo: yup
  //   .string()
  //   .required("Mobile No. is required")
  //   .length(10, "Mobile No. should be 10 digits")
  //   .matches(mobile, "Please provide a valid Mobile no."),
  dob: yup
    .string()
    .required("Date of Birth is required")
    .nullable()
    .label("Date of Birth")
    .test("dob", "Age should be between 18 and 65", value => {
      return getAge(value) >= "18" && getAge(value) <= "65";
    })
    .test(
      "dob",
      "Enter date of birth as of DD/MM/YYYY format",
      value => value.length === 14,
    ),
  gender: yup.string().required("Please select gender"),
  annualIncome: yup
    .string()
    .required("Please provide annual Income")
    .test("annualIncome", "Please provide annual Income", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  qualification: yup
    .string()
    .required("Please select education")
    .test("qualification", "Please select eduacation", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
});
export const form3Schema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .min(1, "Minimum 1 character is required"),
  lastName: yup
    .string()
    .required("Last name is required")
    .min(1, "Minimum 1 characters are required"),
  // .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  email: yup
    .string()
    .required("Personal email id is required")
    .email("Please enter valid personal email id")
    .matches(/.*\.[A-Za-z]{2,}$/, "Please enter valid personal email id")
    .test(
      "email",
      "Please enter valid personal email id",
      validateEmail,
      //  givenValue => {
      //   const value = givenValue.toLowerCase();
      //   if (acceptedEmailExtensions.find(ext => value.endsWith(ext))) {
      //     if (!value.endsWith(".co.in") && !value.endsWith(".jll.com"))
      //       if (
      //         value
      //           .substr(
      //             value.indexOf("@") + 1,
      //             value.lastIndexOf(".") - (value.indexOf("@") + 1),
      //           )
      //           .includes(".")
      //       ) {
      //         return false;
      //       }
      //     return true;
      //   }
      //   return false;
      // }
    )
    .required("Personal email id is required"),
  mobileNo: yup
    .string()
    .required("Mobile No. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please provide a valid Mobile no.")
    .test(
      "mobileNo",
      "Please provide a valid Mobile no.",
      value => value !== "9999999999",
    ),
});

const isAllDigit = (str = "") => !!str.match(/^[0-9]*$/);

export const landingSchema = yup.object({
  workEmail: yup
    .string()
    .required("Work email id is required")
    .email("Please enter valid work email id")
    // .matches(/.*\.[A-Za-z]{2,}$/, "Please enter valid work email id")
    .test("email", "Please enter valid work email id", validateEmail),
});
export const insuredDetails1Schema = yup.object({
  idFirstName: yup.string().required("First name is required"),
  idLastName: yup.string().required("Last name is required"),
  // idDob: yup
  //   .string()
  //   .required("Date of Birth is required")
  //   .nullable()
  //   .label("Date of Birth")
  //   .test("idDob", "Age should be between 18 and 70", (value) => {
  //     return getAge(value) >= "18" && getAge(value) <= "70";
  //   }),
  idGender: yup.string().required("Please select gender"),
});

export const insuredOccupationSchema = yup.object({
  insOccupation: yup
    .string()
    .required("Occupation is required field")
    .test("insOccupation", "Choose a correct Value", value => {
      return value !== "Select Occupation";
    }),

  insAnnualIncome: yup
    .string()
    .required()
    .label("Annual Income")
    .when("insOccupation", {
      is: value => value !== "Housewife",
      then: yup
        .string()
        .test(
          "insAnnualIncome",
          "Annual Income should be greater than 250000",
          value => Number(value) >= 250000,
        ),
    }),

  insEducation: yup
    .string()
    .required("Education is required")
    .test("insOccupation", "Choose a correct Value", value => {
      return value !== "Select Education";
    }),

  insOrgType: yup.string().when("insOccupation", {
    is: value =>
      value === "Salaried" || value === "Self Employed" || value === "Others",
    then: yup
      .string()
      .required()
      .test("insOrgType", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Organisation Type"),
  }),
  insOrgName: yup.string().when("insOccupation", {
    is: value =>
      value === "Salaried" || value === "Self Employed" || value === "Others",
    then: yup
      .string()
      .required()
      .test("insOrgName", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Organisation Name"),
  }),

  insOrgNameDesc: yup.string().when("insOccupation", {
    is: value => value === "Others" || value === "Self Employed",
    then: yup.string().required().label("Oragnisation Name Description"),
  }),

  insOrgTypeDesc: yup.string().when("insOccupation", {
    is: value => value === "others",
    then: yup.string().required().label("Organisation Type Description"),
  }),

  insOccuDesc: yup.string().when("insOccupation", {
    is: value => value === "Others",
    then: yup.string().required().label("Occupation Description"),
  }),

  insEngagedIn: yup.boolean(),
  insIndType: yup.string().when("insEngagedIn", {
    is: value => value === true,
    then: yup
      .string()
      .required()
      .test("insOrgName", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Industry Type"),
  }),

  insIndDesc: yup.string().when("insEngagedIn", {
    is: value => value === true,
    then: yup.string().required().label("Industry Description"),
  }),
  insProfession: yup.string().when("insOccupation", {
    is: "Professional",
    then: yup.string().required("Profession is required"),
  }),
});

// export const modifyDetailSchema = yup.object({
//   firstName: yup
//     .string()
//     .required("First name is required")
//     .label("First Name"),
//   lastName: yup.string().required("Last name is required").label("Last Name"),
//   email: yup
//     .string()
//     .required("Email id is required")
//     // .matches(/\S+@\S+\.\S+/, "Please enter valid email id")
//     .matches(
//       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//       "Please enter valid email id",
//     )
//     .test(
//       "email",
//       "Please enter valid email id",
//       validateEmail,
//       //  givenValue => {
//       //   const value = givenValue.toLowerCase();
//       //   if (acceptedEmailExtensions.find(ext => value.endsWith(ext))) {
//       //     if (!value.endsWith(".co.in"))
//       //       if (
//       //         value
//       //           .substr(
//       //             value.indexOf("@") + 1,
//       //             value.lastIndexOf(".") - (value.indexOf("@") + 1),
//       //           )
//       //           .includes(".")
//       //       ) {
//       //         return false;
//       //       }
//       //     return true;
//       //   }
//       //   return false;
//       // }
//     )
//     .label("Work Email"),
//   mobileNo: yup
//     .string()
//     .required("Mobile no. is required")
//     .length(10, "Mobile No. should be 10 digits")
//     .matches(mobile, "Please enter valid mobile no.")
//     .label("Mobile No."),
//   dob: yup
//     .string()
//     // .required("Date of Birth is required")
//     .nullable()
//     .label("Date of Birth")
//     .test("dob", "Age should be between 18 and 65", value => {
//       return getAge(value) >= 18 && getAge(value) <= 65;
//     })
//     .test(
//       "dob",
//       "Enter date of birth as of DD/MM/YYYY format",
//       value => value.length === 14,
//     ),
// });

export const modifyDetailSchema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .label("First Name"),
  lastName: yup.string().required("Last name is required").label("Last Name"),
  email: yup
    .string()
    .required("Email id is required")
    // .matches(/\S+@\S+\.\S+/, "Please enter valid email id")
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email id",
    )
    .test(
      "email",
      "Please enter valid email id",
      validateEmail,
      //  givenValue => {
      //   const value = givenValue.toLowerCase();
      //   if (acceptedEmailExtensions.find(ext => value.endsWith(ext))) {
      //     if (!value.endsWith(".co.in"))
      //       if (
      //         value
      //           .substr(
      //             value.indexOf("@") + 1,
      //             value.lastIndexOf(".") - (value.indexOf("@") + 1),
      //           )
      //           .includes(".")
      //       ) {
      //         return false;
      //       }
      //     return true;
      //   }
      //   return false;
      // }
    )
    .label("Work Email"),
  mobileNo: yup
    .string()
    .required("Mobile no. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please enter valid mobile no.")
    .label("Mobile No."),
  dob: yup
    .string()
    // .required("Date of Birth is required")
    .nullable()
    .label("Date of Birth")
    .test("dob", "Age should be between 18 and 65", value => {
      return getAge(value) >= 18 && getAge(value) <= 65;
    })
    .test(
      "dob",
      "Enter date of birth as of DD/MM/YYYY format",
      value => value.length === 14,
    ),
});
export const modifyDetailSchema2 = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .label("First Name"),
  lastName: yup.string().required("Last name is required").label("Last Name"),
  email: yup
    .string()
    .required("Email id is required")
    // .matches(/\S+@\S+\.\S+/, "Please enter valid email id")
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email id",
    )
    .test(
      "email",
      "Please enter valid email id",
      validateEmail,
      //  givenValue => {
      //   const value = givenValue.toLowerCase();
      //   if (acceptedEmailExtensions.find(ext => value.endsWith(ext))) {
      //     if (!value.endsWith(".co.in"))
      //       if (
      //         value
      //           .substr(
      //             value.indexOf("@") + 1,
      //             value.lastIndexOf(".") - (value.indexOf("@") + 1),
      //           )
      //           .includes(".")
      //       ) {
      //         return false;
      //       }
      //     return true;
      //   }
      //   return false;
      // }
    )
    .label("Work Email"),
  mobileNo: yup
    .string()
    .required("Mobile no. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please enter valid mobile no.")
    .label("Mobile No."),
  dob: yup
    .string()
    // .required("Date of Birth is required")
    .nullable()
    .label("Date of Birth")
    .test("dob2", "Age should be between 18 and 60", value => {
      return getAge(value) >= 18 && getAge(value) <= 60;
    })
    .test(
      "dob2",
      "Enter date of birth as of DD/MM/YYYY format",
      value => value.length === 14,
    ),
});
// const relationshipsMale = [
//   "Son",
//   "Father",
//   "Brother",
//   "Brother in law",
//   "Grand Father",
//   "Grand son",
//   "Husband",
//   "Nephew",
//   "Uncle",
// ];

// const relationshipsFemale = [
//   "Daughter",
//   "Wife",
//   "Mother",
//   "Aunt",
//   "Sister in law",
//   "Grand Daughter",
//   "Grand Mother",
//   "Niece",
//   "Sister",
// ];
export const nomineeFormSchema = yup.object({
  mwpa: yup.boolean(),
  fname: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup
      .string()
      .required("First name  is required")
      .min(3, "Minimum 3 characters are required")
      .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  }),
  lname: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup
      .string()
      .required("Last name  is required")
      .min(3, "Minimum 3 characters are required")
      .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  }),

  dob: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup
      .string()
      //.required()
      .nullable()
      .label("Date of Birth")
      .test("dob", "Age should be between 1 and 70", value => {
        return getAge(value) >= "1" && getAge(value) <= "70";
      }),
  }),

  nomGender: yup.string().when("mwpa", {
    is: value => Boolean(value) === false,
    then: yup
      .string()
      .required("Gender is required")
      // .test("nomGender", "Please select valid gender", function (value) {
      // 	if (value === "M" && relationshipsFemale.includes(this.parent.relation))
      // 		return false;
      // 	if (value === "F" && relationshipsMale.includes(this.parent.relation))
      // 		return false;
      // 	return true;
      // })
      // .label("Gender"),
      .test("nomGender", "Choose a correct Value", value => {
        return value !== "Select Gender";
      }),
  }),

  relation: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup
      .string()
      .required()
      .test("relation", "Choose a correct Value", value => {
        return value !== "Select Relation";
      }),
  }),

  afname: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup.string().when("dob", {
      is: value => getAge(value) < 18,
      then: yup.string().required("Appointee First name is required"),
    }),
  }),

  alname: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup.string().when("dob", {
      is: value => getAge(value) < 18,
      then: yup.string().required("Appointee Last name is required"),
    }),
  }),

  agender: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup.string().when("dob", {
      is: value => getAge(value) < 18,
      then: yup
        .string()
        .required("Gender is required")
        // .test("agender", "Please select valid gender", function (value) {
        // 	if (
        // 		value === "M" &&
        // 		relationshipsFemale.includes(this.parent.arelation)
        // 	)
        // 		return false;
        // 	if (
        // 		value === "F" &&
        // 		relationshipsMale.includes(this.parent.arelation)
        // 	)
        // 		return false;
        // 	return true;
        // })
        // .label("Gender"),
        .test("agender", "Choose a correct Value", value => {
          return value !== "Select Gender";
        }),
    }),
  }),

  adob: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup.string().when("dob", {
      is: value => getAge(value) < 18,
      then: yup
        .string()
        .required()
        .nullable()
        .label("Date of Birth")
        .test("adob", "age should be between 18 and 70", value => {
          return getAge(value) >= "18" && getAge(value) <= "70";
        }),
    }),
  }),

  arelation: yup.string().when("mwpa", {
    is: value => value === false,
    then: yup.string().when("dob", {
      is: value => getAge(value) < 18,
      then: yup
        .string()
        .required()
        .label("Relation")
        .test("arelation", "Choose a correct Value", value => {
          return value !== "Select Relation";
        }),
    }),
  }),

  tname: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .required("First name  is required")
      .min(3, "Minimum 3 characters are required")
      .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  }),
  tpincode: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .required("Trustee pincode is required")
      .matches(pinCode, "please enter valid value"),
  }),
  tpan: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .required("PAN no. is required")
      .matches(panno, "Please enter valid PAN no.")
      .max(10, "Please enter a valid pan number"),
  }),

  taddress: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup.string().required("Trustee address is required"),
  }),
  ttype: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .required("Type is a required field")
      .test("ttype", "Choose a correct value", value => {
        return value !== "Select Trustee Type";
      }),
  }),

  tdob: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      //		.required()
      .nullable()
      .label("Date of Birth")
      .test("tdob", "Age should be between 1 and 70", value => {
        return getAge(value) >= "1" && getAge(value) <= "70";
      }),
  }),

  tmob: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .required("Mobile no. is required")
      .matches(mobile, "Please provide a valid Mobile no.")
      .label("Mobile No."),
  }),

  temail: yup.string().when("mwpa", {
    is: value => value === true,
    then: yup
      .string()
      .email("Please enter valid email id")
      .required("Trustee email is required field"),
  }),
});
export const idFormSchema = yup.object({
  ageProof: yup
    .string()
    .test(
      "ageProof",
      "Please Select a valid value",
      value => !value.includes("Select"),
    ),
  addressProof: yup
    .string()
    .test("addressProof", "Please Select a valid value", value => {
      return !value.includes("Select");
    }),
  idProof: yup
    .string()
    .test(
      "idProof",
      "Please Select a valid value",
      value => !value.includes("Select"),
    ),
  incomeProof: yup
    .string()
    .test(
      "incomeProof",
      "Please Select a valid value",
      value => !value.includes("Select"),
    ),
  insRepo: yup
    .string()
    .test(
      "insRepo",
      "Please Select a valid value",
      value => !value.includes("Select"),
    ),
  panNo: yup.string().when("insRepo", {
    is: "PAN",
    then: yup
      .string()
      .required()
      .matches(panno, "Please enter valid PAN no.")
      .max(10, "Please enter a valid pan number"),
  }),
  EIA: yup.boolean(),
  preferredInsurance: yup.string().when("EIA", {
    is: value => value === false,
    then: yup
      .string()
      .required()
      .test("preferredInsurance", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Preffered Insurance"),
  }),
  eiaNumber: yup.string().when("EIA", {
    is: value => value === true,
    then: yup
      .string()
      .required()
      .matches(/[1,5]{1}[0-9]{12}/, "Please Enter Valid Value")
      .label("EIA number"),
  }),
});
export const proposerOccupationFormSchema = yup.object({
  occupation: yup
    .string()
    .required()
    .test(
      "occupation",
      "Please Select a valid value",
      value => !value.includes("Select"),
    ),
  annualIncome: yup
    .string()
    .required()
    .test(
      "annualIncome",
      "Annual Income should be greater than 250000",
      value => Number(value) >= 250000,
    )
    .label("Annual Income"),
  education: yup
    .string()
    // .required()
    .test("education", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    })
    .label("Education"),

  orgType: yup.string().when("occupation", {
    is: value =>
      value === "Salaried" || value === "Self Employed" || value === "Others",
    then: yup
      .string()
      .required()
      .test("orgType", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Organisation Type"),
  }),
  orgName: yup.string().when("occupation", {
    is: value =>
      value === "Salaried" || value === "Self Employed" || value === "Others",
    then: yup
      .string()
      .required()
      .test("orgName", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Organisation Name"),
  }),
  orgNameDesc: yup.string().when("orgName", {
    is: "Others",
    then: yup.string().required().label("Oragnisation Name Description"),
  }),
  orgTypeDesc: yup.string().when("orgType", {
    is: "Others",
    then: yup.string().required().label("Organisation Type Description"),
  }),
  occuDesc: yup.string().when("occupation", {
    is: "Others",
    then: yup.string().required().label("Occupation Description"),
  }),
  isEngaged: yup.boolean(),
  indType: yup.string().when("engagedIn", {
    is: value => value === true,
    then: yup
      .string()
      .required()
      .test("orgName", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      })
      .label("Industry Type"),
  }),
  indDesc: yup.string().when("engagedIn", {
    is: value => value === true,
    then: yup.string().required().label("Industry Description"),
  }),
  profession: yup.string().when("occupation", {
    is: "Professional",
    then: yup.string().required("Profession is required"),
  }),
});

export const medicalFormSchema = yup.object({
  preferredDate: yup
    .string()
    .required("Preferred Date is required")
    .test(
      "preferred_date",
      "The date should be within upcoming seven days from today",
      value => {
        return (
          moment(value, "DD/MM/YYYY").diff(moment(), "days") >= 0 &&
          moment(value, "DD/MM/YYYY").diff(moment(), "days") <= 7
        );
      },
    ),
  medicalCenterr: yup.string().when("visitType", {
    is: "Center Visit",
    then: yup
      .string()
      .required("Medical Center is required")
      .test("medicalCenter", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Medical Center");
      }),
  }),
  breDecision: yup.string(),
  visitType: yup.string().when("breDecision", {
    is: "PU",
    then: yup
      .string()
      .required()
      .test("visitType", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      }),
  }),
  preTime: yup
    .string()
    .required("Preferred Time is required")
    .test("preTime", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
});

export const maxMedicalFormSchema = yup.object({
  preferredDate: yup
    .string()
    .required()
    .test("preferredDate", "Please Select a Valid Value", value => {
      if (!value) {
        return true;
      } else {
        return !value.includes("preferredDate");
      }
    }),
  preferredTime: yup
    .string()
    .required()
    .test("preferredTime", "Please Select a Valid Value", value => {
      if (!value) {
        return true;
      } else {
        return !value.includes("preferredTime");
      }
    }),

  medicalCenter: yup.string().when("visitType", {
    is: "Center Visit",
    then: yup
      .string()
      .test("medicalCenter", "Please Select a Center", value => {
        if (!value) return true;
        else return !value.includes("-Select Medical Center-");
      }),
  }),
});

export const hdfcnomineeFormSchema = yup.object({
  nomineeFirstName: yup
    .string()
    .required("First name is required")
    .min(3, "Minimum 3 characters are required")
    .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  nomineeLastName: yup.string().required("Last name is required"),
  sameAddress: yup.boolean(),
  dob: yup
    .string()
    .required()
    .nullable()
    .label("Date of Birth")
    .test("dob", "age should be between 1 and 70", value => {
      return getAge(value) >= "1" && getAge(value) <= "70";
    }),
  nomGender: yup.string().required().label("Gender"),
  relation: yup
    .string()
    .required()
    .test("relation", "Choose a correct Value", value => {
      return value !== "Nominee Relationship";
    }),
  afname: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Appointee First name is required"),
  }),
  alname: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Appointee Last name is required"),
  }),
  agender: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required().label("Gender"),
  }),
  adob: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required()
      .nullable()
      .label("Date of Birth")
      .test("adob", "age should be between 18 and 70", value => {
        return getAge(value) >= "18" && getAge(value) <= "70";
      }),
  }),
  arelation: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required()
      .label("Relation")
      .test("arelation", "Choose a correct Value", value => {
        return value !== "Appointee Relationship";
      }),
  }),

  maritalstatus: yup
    .string()
    .required("Marital status required")
    .test("maritalstatus", "Choose a correct Value", value => {
      return value !== "Select Marital status";
    }),
  npincode: yup.string().when("sameAddress", {
    is: value => value === false,
    then: yup
      .string()
      .required("nominee pincode is required")
      .matches(pinCode, "please enter valid value"),
  }),

  nhousenumber: yup.string().when("sameAddress", {
    is: value => value === false,
    then: yup.string().required("House number is required"),
  }),
  nstreet: yup.string().when("sameAddress", {
    is: value => value === false,
    then: yup.string().required("Street is required"),
  }),
  narealandmark: yup.string().when("sameAddress", {
    is: value => value === false,
    then: yup.string().required("Landmark is required"),
  }),
  anhousenumber: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Housenumber  is required"),
  }),

  anstreet: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Street is required"),
  }),

  anarealandmark: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Landmark is required"),
  }),

  anpincode: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required("Appointe pincode is required")
      .matches(pinCode, "please enter valid value"),
  }),

  amaritalstatus: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required("Marital status required")
      .test("amaritalstatus", "Choose a correct Value", value => {
        return value !== "Select Marital status";
      }),
  }),
});
export const basicDetailsSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  birthPlace: yup.string().required("Birth place is required"),
  panNo: yup
    .string()
    .required("Pan no. is required")
    .matches(panno, "Please enter a valid PAN number"),
  // aadhar: yup
  //   .string()
  //   .matches(/[1-9]{1}[0-9]{11}/, "Please enter a valid aadhar number"),
  martialStatus: yup
    .string()
    .required()
    .test("martialStatus", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  higherEdu: yup
    .string()
    .required()
    .test("higherEdu", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
});

export const employmentDetailsSchema = yup.object({
  occupation: yup
    .string()
    .required()
    .test("occupation", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  occupationType: yup
    .string()
    .required()
    .test("occupation_type", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  industryType: yup
    .string()
    .required()
    .test("industry_type", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  designation: yup
    .string()
    .required()
    .test("designation", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  annualIncome: yup
    .string()
    .required()
    .test(
      "annualIncome",
      "Annual Income should be greater than 500000",
      value => Number(value) >= 500000,
    ),
  natureOfOccupation: yup.string().when("occupation", {
    is: value => value !== "Self Employed",
    then: yup
      .string()
      .required()
      .test("natureOfOccupation", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      }),
  }),

  occupationCategory: yup.string().when("occupation", {
    is: value => value !== "Self Employed",
    then: yup
      .string()
      .required()
      .test("occupationCategory", "Please Select a valid value", value => {
        if (!value) return true;
        else return !value.includes("Select");
      }),
  }),
});

export const hdfcCKYCFormSchema = yup.object({
  fathersFirstName: yup.string().required("Father First name is required"),
  fathersLastName: yup.string().required("Father Last name is required"),
  mothersFirstName: yup.string().required("Mother First name is required"),
  mothersLastName: yup.string().required("Mother Last name is required"),
  // spouseFirstName: yup.string().required("Spouse First Name is required"),
  // spouseLastName: yup.string().required("Spouse Last Name is required"),
});

export const contactDetailsSchema = yup.object({
  mobno: yup
    .string()
    .required("Mobile No. is required")
    .length(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please provide a valid Mobile no.")
    .label("Mobile No."),
  email: yup
    .string()
    .email("Please enter valid email id")
    .required("Email id is required"),
  pinCode: yup
    .string()
    .required()
    .matches(pinCode, "Please put a valid pincode"),
  houseno: yup.string().required().label("House Number"),
  street: yup.string().required(),
  area: yup.string().required(),
});

export const kotakPersonalForm1 = yup.object({
  addressLine1: yup
    .string()
    .required("Address Line1 is Required")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  addressLine2: yup
    .string()
    .required("Address Line2 is Required")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  landmark: yup
    .string()
    .required("Landmark is Required")
    .matches(oneSpace, "Please provide a valid value"),
  pincode: yup
    .string()
    .required()
    .matches(pinCode, "Please put a valid pincode"),
});

export const kotakPersonalForm2 = yup.object({
  // aadhar: yup
  //   .string()
  //   .matches(/[1-9]{1}[0-9]{11}/, "Please enter a valid aadhar number"),
  occupation: yup
    .string()
    .required()
    .test("occupation", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  martialStatus: yup
    .string()
    .required("Marital Status is required field")
    .test("martialStatus", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Select");
    }),
  weight: yup
    .string()
    .required("Weight is required field")
    .test("Weight", "Please Select a valid weight above 30 kg", value => {
      if (!value) return true;
      else if (Number(removeKg(value)) < 30);
      else return !value.includes("Weight");
    }),
  height: yup
    .string()
    .required("Height is required field")
    .test("Height", "Please Select a valid value", value => {
      if (!value) return true;
      else return !value.includes("Height");
    }),
  fatherFirstName: yup
    .string()
    .required("Father First Name is required field")
    .min(3, "Minimum 3 characters are required"),
  fatherLastName: yup
    .string()
    .required("Father Last Name is required field")
    .min(3, "Minimum 3 characters are required"),
  motherFirstName: yup
    .string()
    .required("Mothers First Name is required field")
    .min(3, "Minimum 3 characters are required"),
  motherLastName: yup
    .string()
    .required("Mothers Last Name is required field")
    .min(3, "Minimum 3 characters are required"),
  // spouseName: yup.string().when("martialStatus", {
  //   is: (value) => value === "Married",
  //   then: yup
  //     .string()
  //     .required("Spouse name is required")
  //     .min(3, "Minimum 3 characters are required"),
  // }),
});

export const kotakPersonalForm3 = yup.object({
  permanentAddressLine1: yup
    .string()
    .required("Permanent Address Line1 required field")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  permanentAddressLine2: yup
    .string()
    .required("Permanent Address Line2 required field")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  // city: yup.string().required(),
  // state: yup.string().required(),
  permanentLandmark: yup
    .string()
    .required("Permanent Landmark is required field")
    .matches(oneSpace, "Please provide a valid value"),
  permanentPincode: yup
    .string()
    .required("Pincode is required field")
    .matches(pinCode, "Please put a valid pincode"),
  country: yup.string().required("Country is required field"),
  // .matches("addressType", "Please Select an address"),
});

export const officeAddressValidation = yup.object({
  /*
  officeAddressLine1: yup
    .string()
    .required("Office Address Line1 is required field")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  officeAddressLine2: yup
    .string()
    .required("Office Address Line2 is required field")
    .min(3, "Minimum 3 characters are required")
    .matches(oneSpace, "Please provide a valid value"),
  // city: yup.string().required(),
  // state: yup.string().required(),
  officeLandmark: yup
    .string()
    .required("Office Landmark is required field")
    .matches(oneSpace, "Please provide a valid value"),
  officePincode: yup
    .string()
    .required("Office Pincode is required field")
    .matches(pinCode, "Please put a valid pincode"),
  country: yup.string().required("Country is required field"),
  */
  addressType: yup.string().required("Address Type is required field"),
  // .matches("addressType", "Please Select an address"),
});

export const kotaknomineeFormSchema = yup.object({
  nfname: yup
    .string()
    .required("First name  is required")
    .min(3, "Minimum 3 characters are required"),
  nlname: yup
    .string()
    .required("Last name  is required")
    .min(3, "Minimum 3 characters are required"),
  dob: yup
    .string()
    .required()
    .nullable()
    .label("Date of Birth")
    .test("dob", "Age should be between 1 and 70", value => {
      return getAge(value) >= "1" && getAge(value) <= "70";
    }),
  nomGender: yup.string().required().label("Gender"),
  nshare: yup
    .string()
    .required("Share is required")

    .test("nshare", "Total should be between 0 and 100", value1 => {
      return value1 <= 100 && value1 >= 1;
    }),

  relation: yup
    .string()
    .required("Relation is required")
    .test("relation", "Choose a correct Value", value => {
      return value !== "Select Relationship";
    }),

  appointteValid: yup.boolean(),
  afname: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Appointee First name is required")
      .min(3, "Minimum 3 characters are required"),
  }),
  alname: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Appointee Last name is required")
      .min(3, "Minimum 3 characters are required"),
  }),
  agender: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup.string().required("Gender is required").label("Gender"),
  }),
  adob: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Date of Birth is required")
      .nullable()
      .label("Date of Birth")
      .test("adob", "Age should be between 18 and 70", value => {
        return getAge(value) >= "18" && getAge(value) <= "70";
      }),
  }),

  arelation: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Relation is required")
      .label("Relation")
      .test("arelation", "Choose a correct Value", value => {
        return value !== "Select Relationship";
      }),
  }),

  npincode: yup
    .string()
    .required("Nominee pincode is required")
    .matches(pinCode, "please enter valid value"),

  naddress1: yup
    .string()
    .required("Address line 1 is required")
    .matches(oneSpace, "Please provide a valid value"),

  naddress2: yup
    .string()
    .required("Address line 2 is required")
    .matches(oneSpace, "Please provide a valid value"),

  nlandmark: yup
    .string()
    .required("Landmark is required")
    .matches(oneSpace, "Please provide a valid value"),

  anaddress1: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Address line 1  is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),

  anaddress2: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Address line 2 is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),

  anlandmark: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Landmark is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),

  anpincode: yup.string().when("appointteValid", {
    is: value => value === true,
    then: yup
      .string()
      .required("Appointe pincode is required")
      .matches(pinCode, "please enter valid value"),
  }),

  //second nomine

  secondNominee: yup.boolean(),

  ntwofname: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("First name  is required")
      .min(3, "Minimum 3 characters are required"),
  }),
  ntwolname: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("last name  is required")
      .min(3, "Minimum 3 characters are required"),
  }),
  ntwoshare: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Share is required")

      .test("ntwoshare", "Total Share should be between 1 and  100", value => {
        return value <= 100 && value >= 1;
      }),
  }),
  twodob: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Date of Birth is required")
      .nullable()
      .label("Date of Birth")
      .test("twodob", "Age should be between 1 and 70", value => {
        return getAge(value) >= "1" && getAge(value) <= "70";
      }),
  }),

  nomtwoGender: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup.string().required("Gender is required").label("Gender"),
  }),
  tworelation: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Relation is a required field")
      .test("relation", "Choose a correct Value", value => {
        return value !== "Select Relationship";
      }),
  }),

  ntwoaddress1: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Address line 1 is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),
  ntwoaddress2: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Address line 2 is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),
  ntwolandmark: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Landmark is required")
      .matches(oneSpace, "Please provide a valid value"),
  }),
  ntwopincode: yup.string().when("secondNominee", {
    is: value => value === true,
    then: yup
      .string()
      .required("Nominee pincode is required")
      .matches(pinCode, "please enter valid value"),
  }),
});

export const maxnomineeFormSchema = yup.object({
  nomineeFirstName: yup
    .string()
    .required("Nominee First name is required")
    .min(3, "Minimum 3 characters are required")
    .matches(/^[A-Za-z]+$/, "Please enter letters Only"),
  nomineeLastName: yup.string().required("Nominee Last name is required"),
  sameAddress: yup.boolean(),
  dob: yup
    .string()
    .required()
    .nullable()
    .label("Date of Birth")
    .test("dob", "age should be between 1 and 70", value => {
      return getAge(value) >= "1" && getAge(value) <= "70";
    }),
  nomGender: yup.string().required().label("Gender"),
  relation: yup
    .string()
    .required()
    .test("relation", "Choose a correct Value", value => {
      return value !== "- Select Relationship -";
    }),
  afname: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Appointee First name is required"),
  }),
  alname: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required("Appointee Last name is required"),
  }),
  agender: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup.string().required().label("Gender"),
  }),
  adob: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required()
      .nullable()
      .label("Date of Birth")
      .test("adob", "age should be between 18 and 70", value => {
        return getAge(value) >= "18" && getAge(value) <= "70";
      }),
  }),
  arelation: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required()
      .label("Relation")
      .test("arelation", "Choose a correct Value", value => {
        return value !== "- Select Relationship -";
      }),
  }),

  salutation: yup
    .string()
    .required()
    .label("salutation")
    .test("salutation", "Choose a correct Value", value => {
      return value !== "- Select Salutation -";
    }),

  aSalutation: yup.string().when("dob", {
    is: value => getAge(value) < 18,
    then: yup
      .string()
      .required()
      .label("salutation")
      .test("aSalutation", "Choose a correct Value", value => {
        return value !== "- Select Salutation -";
      }),
  }),
  nomineeEmail: yup.string().email().required("Last name is required"),

  nomineePhone: yup
    .string()
    .required("Mobile no. is required")
    .matches(mobile, "Please provide a valid Mobile no.")
    .label("Mobile No."),
});
