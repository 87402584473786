import React from "react";
import Popup from "../Popup";
import styled from "styled-components";
import { ALL_COUNTRIES, COUNTRIES } from "../../EligibilityCard/options";

const NonAcceptCountriesPopup = ({ show, onClose }) => {
  const onSubmit = e => {
    e.preventDefault();
    onClose();
  };

  const countries = Object.values(ALL_COUNTRIES).filter(
    country => !Object.values(COUNTRIES).includes(country),
  );

  const content = (
    <>
      <Wrapper onSubmit={onSubmit}>
        <Heading>Non Acceptable Countries</Heading>
        {countries.map(d => {
          let set1 = countries.splice(0, 21);
          let set2 = countries.splice(0, 21);
          let set3 = countries.splice(0, 21);
          let set4 = countries.splice(0, 21);
          let set5 = countries.splice(0, 21);
          let set6 = countries.splice(0, 21);
          let set7 = countries.splice(0, 21);
          let set8 = countries.splice(0, 21);
          let set9 = countries.splice(0, 18);

          return (
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <div>
                {set1.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set2.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set3.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set4.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set5.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set6.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set7.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set8.map(d => (
                  <p>{d}</p>
                ))}
              </div>
              <div>
                {set9.map(d => (
                  <p>{d}</p>
                ))}
              </div>
            </div>
          );
        })}

        {/* <Button type="submit">OK</Button> */}
      </Wrapper>
    </>
  );

  let width;
  let height;
  let size;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    height = "100%";
    size = "40%";
  } else {
    width = "90%";
    height = "100%";
    size = "35%";
  }
  return (
    <>
      <Popup
        show={show}
        onClose={onClose}
        content={content}
        width={width}
        height={height}
        size={size}
      />
    </>
  );
};

export default NonAcceptCountriesPopup;

const Wrapper = styled.form`
  position: relative;
  padding: 24px 62px 40px;
  z-index: -1;
  @media (max-width: 767px) {
    padding: 24px 22px 40px;
  }
`;
const Heading = styled.div`
  width: 582px;
  height: 50px;
  margin: 0 0 20px;
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: 600;

  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  @media (max-width: 767px) {
    width: unset;
    text-align: left;
  }
`;

const SubHeading = styled.div`
  width: 284px;
  height: 20px;
  margin: 0 auto 24px;
  font-family: Inter-Medium;
  font-size: 16px;
  font-weight: 500;

  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`;

const RadioText = styled.span`
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #333;
`;

const Button = styled.button`
  display: block;
  width: 190px;
  height: 54px;
  margin: 50px auto 12px;
  padding: 19px 58px 18px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #f2581b;
  color: #ffffff;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
