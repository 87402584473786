import React, { useState, useRef, useEffect } from "react";
import { useKeydownEvent, useOutsideClick } from "../../custom-hooks";

import SmallArrow from "../../assets/img/arrow_down_slate4.svg";

import "./custom-drop-down-two.scss";
import { DropDownHead, DropDownItems } from "../";

function CustomDropDownTwo({
  items,
  label,
  isOpen,
  onChange,
  selectedItem,
  style,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(
    selectedItem ? selectedItem : items[0],
  );

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setOpen(false));

  useKeydownEvent("Escape", () => setOpen(false));

  const handleItemClick = item => {
    setSelected(item);
    setOpen(false);
    onChange && onChange(item);
  };

  useEffect(() => {
    isOpen && isOpen(open);
  }, [open, isOpen]);

  return (
    <div
      className="custom-drop-down-two"
      ref={dropDownRef}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <div
        className="cddt__head"
        style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <span className="cddt__label">{label}:</span>
        <DropDownHead
          text={selected}
          onClick={() => setOpen(!open)}
          rotate={open && "rotate"}
          arrowIcon={SmallArrow}
        />
        {open && (
          <DropDownItems
            items={items}
            handleClick={handleItemClick}
            selected={selected}
          />
        )}
      </div>
    </div>
  );
}

export default CustomDropDownTwo;
