/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import EligibilityCard from "../../components/EligibilityCard/EligibilityCard";
import FormCard from "../../components/FormCard/FormCard";
import { useLocation } from "react-router-dom";
import PlanCard from "../../components/PlanCard/PlanCard";
import LaxmiGreeting from "./../../components/LaxmiGreeting/LaxmiGreeting";
import "./eligibilityPage.scss";
import "./eligibiltyPage2.scss";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { getAllData } from "../../api/getAllData";
import { Error } from "../../components";
import { useDocumentTitle } from "../../custom-hooks";
import { useNavigation } from "../../custom-hooks/useNavigation";
import { setCameFromEligibility } from "../QuotePage/quotePageSlice";
import { removeDetails } from "./formCard.slice";

const EligibilityPage = ({ history }) => {
  const { switchToMedical } = useSelector(state => state.quote);
  console.log("history", history);
  useDocumentTitle("Life Term Plan Form");
  const { isDocumentUploaded, isProposalSubmitted, isPaymentSuccess } =
    useNavigation();
  const { isError } = useSelector(({ quote }) => quote);

  const { selectedQuote } = useSelector(state => state.quote);
  const { additionalRiders, selectedAddOns, riderGst, inbuiltCovers } =
    useSelector(state => state.rider);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { details } = useSelector(state => state.formCard);
  const { customerDetails } = useSelector(({ greeting }) => greeting);
  const urlQuery = new URLSearchParams(search);
  const enquiryId = urlQuery.get("enquiryId");
  useEffect(() => {
    dispatch(getAllData(history, null, enquiryId));
    dispatch(removeDetails());
  }, []);
  const newSumAssured = parseInt(selectedQuote?.sumAssured);
  if (isDocumentUploaded || isPaymentSuccess || isProposalSubmitted)
    return <Loader />;

  if (isError) return <Error errorMessage={isError} />;
  else if (
    Object.keys(customerDetails).length === 0 ||
    Object.keys(selectedQuote).length === 0
  )
    return <Loader />;
  return (
    <Fragment>
      <div className="eligibility__back elgBackBtn">
        <span>
          <button
            onClick={() => {
              // history.goBack();
              if (
                selectedQuote?.company_alias === "tata_aia_life" ||
                (selectedQuote.company_alias === "bandhan_life" &&
                  newSumAssured <= 5000000)
              ) {
                history.replace(`/quotes?enquiryId=${enquiryId}`);
              } else if (
                (switchToMedical &&
                  selectedQuote?.company_alias === "bajaj_life") ||
                (customerDetails.qualification === "10th Pass" &&
                  selectedQuote?.company_alias === "bajaj_life") ||
                (customerDetails.qualification === "12th Pass" &&
                  selectedQuote?.company_alias === "bajaj_life")
              ) {
                history.replace(`/quotes?enquiryId=${enquiryId}`);
              } else {
                history.replace(`/riderPage?enquiryId=${enquiryId}`);
              }

              dispatch(setCameFromEligibility(true));
            }}
            className="btn--tertiary backBtn"
          >
            {" "}
            <svg
              style={{ height: "15px" }}
              className="backImg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#7a7d80"
            >
              <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            Back
          </button>
        </span>
      </div>
      <div className="container elg">
        <LaxmiGreeting />
        <div className="eligibility__header eligibility__main-header">
          <div className="eligibility__greeting">
            <h2 className="heading__secondary eligibilityHeading">
              Almost done! Help me with few more details.
            </h2>
          </div>
        </div>
        <div className="eligibility__body eligibility__mobile_body eligibilityBody__position">
          <div className="eligibility__side">
            <PlanCard
              selectedQuote={selectedQuote}
              additionalRiders={additionalRiders}
              additionalGst={riderGst}
              buyNowBtn={false}
              inbuiltCovers={inbuiltCovers}
              addOnsList={selectedAddOns}
            />
          </div>
          <div
            className={
              "eligibility__main elgForms elgMainContent" +
              (details ? " elgMainContentHeight" : "")
            }
          >
            <FormCard selectedQuote={selectedQuote} />
            <div id="eligibility-card">
              <EligibilityCard
                history={history}
                selectedQuote={selectedQuote}
                inbuiltCovers={selectedQuote.inbuilt_riders}
                addOnsList={selectedAddOns}
                additionalrider={additionalRiders}
                annualIncome={customerDetails.annualIncome}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EligibilityPage;
