import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ComparBox } from "..";
import { setQuotesOnCompare } from "../../quotePageSlice";
import { useCustomLocation, useWindowResize } from "../../../../custom-hooks";
import "./compare-container.scss";

function CompareContainer() {
  const dispatch = useDispatch();

  const { quotes, recommendedQuote, switchToMedical } = useSelector(
    ({ quote }) => quote,
  );
  const quotesForCompare = useSelector(state => state.quote.quotesForCompare);

  const { width } = useWindowResize();
  const { gotoPage } = useCustomLocation();

  const boxNumber = width <= 767 || quotes?.length < 3 ? 2 : 3;

  const compareBoxes = [];
  const switchConditionBajaj = quotes => {
    return quotes.filter(
      item =>
        item.company_alias !== "bajaj_life" ||
        (item.company_alias === "bajaj_life" &&
          item?.master_product_name === "eTouch") ||
        (item.company_alias === "bajaj_life" &&
          item.medicalCondition ===
            (switchToMedical ? "Video Medical" : "Physical Medical")),
    );
  };
  const filteredQuotes = switchConditionBajaj(quotes);
  const getCompareBox = quote => (
    <ComparBox
      key={quote.insurance_code}
      id={quote.insurance_code}
      logoUrl={quote.company_logo}
      plan={quote.company_name}
    />
  );

  filteredQuotes.forEach(quote => {
    if (quotesForCompare.includes(quote.insurance_code)) {
      if (quote?.insurance_code === recommendedQuote)
        compareBoxes.unshift(getCompareBox(quote));
      else compareBoxes.push(getCompareBox(quote));
    }
  });

  for (let i = 0; i < boxNumber; i++) {
    if (!compareBoxes[i])
      compareBoxes.push(<ComparBox key={Math.random() * 1000 + 1} />);
  }

  const handleClick = () => {
    gotoPage(gotoPage.pages.planCompare);
    dispatch(setQuotesOnCompare());
  };

  return (
    <div className="compare-container-wrap">
      <div className="compare-container">
        <div className="compare-boxes">{compareBoxes}</div>
        <button
          className={`${
            quotesForCompare.length < 2 ? "btn--disabled" : "btn--highlighted"
          }`}
          onClick={handleClick}
          disabled={quotesForCompare.length < 2}
        >
          {" "}
          COMPARE{" "}
        </button>
      </div>
    </div>
  );
}

export default CompareContainer;
