import React from "react";

import PopupWithTitle from "../PopupWithTitle/PopupWithTitle";
import CriteriaListItem from "../PopupWithTitle/CriteriaListItem";

import { nriPopupEvents } from "../NriCriteriaPopup/NriCriteriaPopup";

import "./nri-residing-popup.scss";
import CriteriaList from "../PopupWithTitle/CriteriaList/CriteriaList";

function NriResidingPopup({
  setShowNriPopup,
  setIntendToTravel,
  setShowNriResidingPopup,
  nonAcceptableCountry,
  sumAssured,
  ...props
}) {
  const title = "Confirmation for traveling to Non Acceptable countries";

  const countries = [nonAcceptableCountry];

  const handleClose = () => {
    setIntendToTravel(false);
    setShowNriResidingPopup(false);
  };

  const handleProceed = () => {
    setShowNriResidingPopup(false);
    countries[0] !== "Select country" &&
      setShowNriPopup(nriPopupEvents.residingConfirm);

    if (sumAssured >= 50000000) {
      setIntendToTravel(false);
    }
  };

  return (
    <PopupWithTitle title={title} onClose={handleClose} {...props}>
      {fadeOut => (
        <>
          {countries[0] !== "Select country" && (
            <>
              <CriteriaList>
                <CriteriaListItem matches>
                  Your selected countries are{" "}
                  {countries.map((country, idx) => (
                    <strong className="criteria-list-text-strong">
                      {idx === countries.length - 1 &&
                        countries.length !== 1 &&
                        " & "}
                      {country}
                      {idx < countries.length - 2 && ", "}
                    </strong>
                  ))}
                </CriteriaListItem>
              </CriteriaList>
              <div className="nri-residing-popup-info-text">
                Selected countries by you comes under list of non acceptable
                countries as per ICICI prudential terms & Conditions
              </div>
            </>
          )}
          <div className="nri-residing-popup-info-text nri-residing-popup-info-text-strong">
            <strong>
              As per Terms & conditions set for non acceptable countries,
              Customer is eligible to buy Term Life policy of not more than 5
              Crore
            </strong>
          </div>
          <div className="nri-residing-popup-info-text nri-residing-popup-info-text-confirm">
            Please confirm, above terms & condition’s of ICICI prudential are
            satisfied by you.
          </div>
          <div className="nri-popup-btn-group">
            <button
              className="nri-popup-btn"
              onClick={() => {
                fadeOut(handleClose);
              }}
            >
              CANCEL
            </button>
            <button
              className="nri-popup-btn nri-popup-btn-primary"
              onClick={() => {
                fadeOut(handleProceed);
              }}
            >
              CONFIRM & PROCEED
            </button>
          </div>
        </>
      )}
    </PopupWithTitle>
  );
}

export default NriResidingPopup;
