import React, { Fragment } from "react";
import Popup from "./Popup";

import {
  HeadingWrapper,
  Title,
  Wrapper,
  WrapCenter,
  InnerWrapper,
  Heading,
  Value,
} from "./ReviewProduct.style";
import HdfcLogo from "../../assets/img/hdfcLogo.png";
import { Button } from "../StyledButton/Button";
import { useSelector } from "react-redux";
const ReviewProduct = ({ show, onClose, history }) => {
  const { displayNomineeGets } = useSelector(({ quoteFilter }) => quoteFilter);
  const content = (
    <Fragment>
      <HeadingWrapper>
        <Heading>Review your Product</Heading>
      </HeadingWrapper>
      <Wrapper>
        <InnerWrapper>
          <img src={HdfcLogo} style={{ height: "70px" }} alt="IC logo" />
          <div>
            <Title>HDFC Ergo Term Insurance</Title>
            <Value>Smart Term Plan</Value>
          </div>
        </InnerWrapper>
        <div>
          <Title>COVER TERM</Title>
          <Value>72 years</Value>
        </div>
        <div>
          <Title>NOMINEE GETS</Title>
          <Value>{displayNomineeGets}</Value>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <Title border>Inbuilt Cover</Title>
          <Value>Inbuilt Cover</Value>
          <Value>Life Stage Benefit </Value>
          <Value>Terminal Illness</Value>
          <Value>Tax Benefit</Value>
        </div>
        <div>
          <Title border>Additional Cover</Title>
          <Value>Corona Protect</Value>
          <Value>Occupation Disability</Value>
        </div>
        <div style={{ width: "30%" }}>
          <Title border>Premium</Title>
          <Value price>
            <span>Base Plan Premium</span>
            <span>₹ 5000</span>
          </Value>
          <Value price>
            <span>Critical Illness</span>
            <span>₹ 50</span>
          </Value>
          <Value price>
            <span>Additional Riders</span>
            <span>₹ 5</span>
          </Value>
          <Value price last>
            <span>GST</span>
            <span>₹ 500</span>
          </Value>
          <Title primary>TOTAL PREMIUM PAYABLE</Title>
          <Value primary last>
            ₹ 6,812 / per month{" "}
          </Value>
        </div>
      </Wrapper>
      <WrapCenter>
        <Button secondary md>
          PROCEED TO PAYMENT
        </Button>
      </WrapCenter>
    </Fragment>
  );

  return (
    <Fragment>
      <Popup
        show={show}
        onClose={onClose}
        content={content}
        height="auto"
        width="80%"
      ></Popup>
    </Fragment>
  );
};

export default ReviewProduct;
