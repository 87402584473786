import styled from "styled-components";

export const InfoWrap = styled.div`
  width: 490px;
  height: 64px;
  padding: 8px;
  font-family: "Inter-SemiBold";
  font-size: 12px;
  text-transform: uppercase;
  background-color: #f1f2f6;
  margin-left: auto;
  margin-right: 52px;

  margin-top: ${props => {
    if (props.marginTop) return "32px";
  }};
  & .total-amt {
    color: #107591;

    &-value {
      color: #262626;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 7px;
    padding: 0px 2px 0px 10px;
    font-family: "Inter-SemiBold";
    font-size: 11px;
  }
`;
export const Wrapper = styled.div`
  margin: 0 16px;
  @media (max-width: 767px) {
    padding: ${props => {
      if (props.modifyDetails) {
        return "10px";
      }
    }}
  & .custom-drop-down__head,
  .custom-drop-down__head--open {
		padding: 15px 10px;
    border-color: #d2d3d4;
  }
`;
export const HeadingWrapper = styled.div`
  width: 520px;
  margin: 35px auto 0px auto;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e3e4e8;

  @media (max-width: 767px) {
    margin: 0px;
    width: 100%;
    margin-top: 23px;
    margin-bottom: -10px;
    border-bottom: ${props => {
      if (props.modifyDetails) {
        return "none";
      }
    }};
  }
`;
export const Title = styled.h1`
  font-family: "Inter-SemiBold";
  font-size: 24px;
  display: block;
  line-height: 29px;
  color: #333;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    display: flex;
  }
  @media (max-width: 390px) {
    display: none;
  }
`;
export const TitleMob = styled.h1`
  font-family: "Inter-SemiBold";
  font-size: 24px;
  display: block;
  line-height: 29px;
  color: #333;
  margin-bottom: 8px;
  display: none;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    display: none;
  }
  @media (max-width: 390px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    display: block;
  }
`;
export const SubTitle = styled.h3`
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 20px;
  color: #333;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 16px;
    text-align: left;
  }
`;
export const FormWrapper = styled.form`
  padding: 0 60px;
  & div > button {
    width: 290px;
    height: 56px;
    margin: 50px 0;
    font-size: 20px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    & div > button {
      width: 100%;
    }
  }
`;

export const Question = styled.p`
  display: table-cell;
  vertical-align: middle;
  padding: 0 12px 0 16px;
  min-height: 40px;
  ${"" /* width: ${(prop) => (prop.full ? "100%" : "59%")}; */}
  width: 310px;
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 20px;
  color: #262626;
  & .SmallTextQuestion {
    float: left;
    font-size: 12px;
    line-height: 15px;
    color: #8d9194;
    width: 86%;
    margin-top: 8px;
    margin-left: 2px;
  }
  @media (max-width: 767px) {
    padding: 0 0 0px 0;
    font-size: 13px;
    line-height: 16px;
    display: block;
    min-height: auto;
    width: 310px;
  }
`;

export const QuestionWrapper = styled.div`
  display: table;
  width: 520px;
  margin: 34px auto 0 auto;

  & > button {
    font-size: 20px;
  }
  & > span {
    width: 59%;
    display: table-cell;
  }
  & > div {
    width: 196px;
    height: 40px;
    & .custom-drop-down__head {
      width: 100%;
      height: 100%;
      padding: 10px 8px;

      & .custom-drop-down__selected-text {
        line-height: 18px;
      }

      &--open {
        height: 40px;
      }

      &--open .custom-drop-down__selected-text {
        color: #00000099;
      }

      & .custom-drop-down__arrow {
        &,
        &--invert {
          filter: invert(32%) sepia(22%) hue-rotate(163deg) saturate(4275%)
            brightness(76%) contrast(60%);
          height: 15px;
          width: 15px;
        }
        &--invert {
          margin-bottom: 1px;
        }
        margin-right: -2px;
      }
    }
    & > div {
      & div {
        font-size: 14px;
        font-family: "Inter-Medium";
      }
      & span {
        color: #287692;
      }
    }
  }

  & .cover-amt-percent {
    height: 40px;
    position: relative;
    border: 1px solid #e3e4e8;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Inter-Medium";
    color: #107591;
    width: 100%;
    padding: 10px 8px;
    z-index: 2;
    margin: auto;

    &:focus {
      outline: none;
      border: 2px solid #107591;
    }
  }

  & .custom-monthly-income-text {
    width: 200px;
    margin-right: 30px;
    padding-left: 10px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #e3e4e8;
    font-size: 14px;
    font-family: normal;
    color: #107591;

    &:focus {
      outline: none;
      border: 2px solid #107591;
    }
  }
  @media (max-width: 767px) {
    margin-top: 14px;
    display: table;
    width: 100% !important;
  }
`;

export const CoverAmountPercentWrap = styled.div`
  position: relative;
  @media (max-width: 767px) {
    width: 100% !important;
  }
`;

export const PercentDropWrap = styled.div`
  position: absolute;
  border: 1px solid #e4e4e4;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  z-index: 1;
  background: #fff;
  left: -6px;
  top: -6px;
  padding-top: 46px;
  height: 154px;
  width: 208px;

  & .percent-drop-title {
    color: #000;
    margin: 6px 0;
    padding: 0 6px;
    font-size: 14px;
    line-height: 18px;
  }

  & .percent-drop-option-wrap {
    display: inline-table;
    width: 100%;
    padding: 0 4px 4px;
  }

  & .percent-drop-option {
    display: inline-block;
    width: 92px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #e3e4e8;
    line-height: 18px;
    color: #107591;
    text-align: center;
    cursor: pointer;
    margin: 2px;
    padding: 6px 0;

    &:hover {
      background-color: #107591;
      color: #fff;
      border: solid 1px #107591;
    }
    @media (max-width: 767px) {
      width: 48% !important;
    }
  }
  @media (max-width: 767px) {
    width: 100% !important;
    left: 0px;
  }
`;
