import React, { useCallback } from "react";
import greenTick from "../../assets/img/greentick.svg";
import downloadIcon from "../../assets/img/downloadIcon.svg";
import proccedIcon from "../../assets/img/thankYouProceed.svg";
import LaxmiGreeting from "../../assets/img/laxmiImg.png";
import { Button } from "./../../components/StyledButton/Button";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../../api/getAllData";
// import SecureLS from "secure-ls";
import "./thankyou.scss";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import ICError from "../../assets/img/ic_error_48px.svg";
import {
  LaxmiImg,
  LaxmiWrap,
  ThankWrap,
  ThankCheck,
  ThankTitle,
  ThankSubTitle,
  DownloadButton,
} from "./ThankYou.style";
// import styled from "styled-components";
import {
  clearLoader,
  initLoader,
  initPaymentStatusLoader,
  saveAppNo,
  saveBiData,
  saveDetail,
  saveResponse,
  setRepayLoader,
} from "../EligibilityPage/eligibilityCard.slice";
import { createRegionData } from "../GreetingPage/greeting.slice";
import { saveSelectedPlanForm } from "../ProposalPage/IcicProposalPage/SelectedPlanForm/SelectedPlanForm.slice";
import { saveReciept } from "../../components/ReviewPopUp/payment.slice";
import { updateQuotation } from "./../ProposalPage/serviceApi";
import { useCustomLocation } from "../../custom-hooks";
import { paymentDetailsToIc } from "../../components/ReviewPopUp/serviceApi";
import Loader from "../../components/Loader/Loader";
import { PaymentLoadingMessage } from "../../components/EligibilityCard/EligibilityCard";
import { useState } from "react";
import { useNavigation } from "../../custom-hooks/useNavigation";
const ThankYou = ({ history }) => {
  useNavigation();
  const dispatch = useDispatch();
  // const ls = new SecureLS();
  const { enquiryId, gotoPage, pathname, redirectTo } = useCustomLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const afflid = searchParams.get("afflid");
  const utm_medium = searchParams.get("utm_medium");
  const c_id = searchParams.get("c_id");
  const { eligibilityDetails, paymentStatusLoader, loader } = useSelector(
    ({ eligibilityCard }) => eligibilityCard,
  );
  const { selectedQuote } = useSelector(state => state.quote);
  const { isPaymentSuccess } = useSelector(state => state.eligibilityPage);
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const { gender } = useSelector(({ greeting }) => greeting.customerDetails);
  const { reciept } = useSelector(state => state.payment);
  const [error, setError] = useState(false);
  const { corporateJourney } = useSelector(state => state.quote);

  let temp = corporateJourney?.is_gameskraft;
  useEffect(() => {
    dispatch(initPaymentStatusLoader(true));
    if (Object.keys(selectedQuote).length === 0) {
      dispatch(initLoader(true));
      dispatch(getAllData(history, null, enquiryId)).then(data => {
        dispatch(initPaymentStatusLoader(false));
        dispatch(initLoader(false));
        if (reciept?.receipt_url && isPaymentSuccess) {
          setError(false);
        } else if (!data?.receiptUrl && isPaymentSuccess) {
          setError(true);
        } else if (data?.last_visited?.otp === "true") {
          gotoPage(redirectTo.pages.documentuploadpage);
        } else if (data?.last_visited?.proposal === "true") {
          gotoPage(redirectTo.pages.otpverification);
        } else if (
          data?.payment_status !== "success" &&
          !data?.app_no &&
          data?.company_alias === "icici_pru"
        ) {
          gotoPage(redirectTo.pages.eligibilitypage);
        } else if (
          data?.payment_status !== "success" &&
          data?.app_no &&
          data?.company_alias === "icici_pru"
        ) {
          gotoPage(redirectTo.pages.transactionunsuccessful);
        } else if (
          data?.company_alias === "bajaj_life" &&
          !data?.receipt_Url &&
          data?.last_visited?.save_logs === "true"
        ) {
          gotoPage(redirectTo.pages.transactionsuccessful);
        } else if (
          data?.company_alias === "bajaj_life" &&
          !data?.receipt_Url &&
          data?.app_no &&
          data?.last_visited?.save_logs !== "true"
        ) {
          gotoPage(redirectTo.pages.transactionunsuccessful);
        } else if (
          data?.company_alias === "bajaj_life" &&
          !data?.app_no &&
          data?.last_visited?.save_logs !== "true"
        ) {
          gotoPage(redirectTo.pages.eligibilitypage);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (reciept?.receipt_url && isPaymentSuccess) {
      setError(false);
      dispatch(initPaymentStatusLoader(false));
      dispatch(initLoader(false));
    } else if (reciept?.receipt_url?.length === 0 || isPaymentSuccess) {
      setError(true);
      dispatch(initPaymentStatusLoader(false));
      dispatch(initLoader(false));
    }
  }, [reciept?.receipt_url]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const education = eligibilityDetails?.selectedEducation;
  const occupation = eligibilityDetails?.selectedOccupation;
  const handleClick = () => {
    if (
      company_alias === "icici_pru" &&
      gender === "F" &&
      occupation === "Housewife" &&
      ["Graduate", "Post Graduate"].includes(education)
    ) {
      dispatch(saveSelectedPlanForm("dependent"));
    } else {
      dispatch(saveSelectedPlanForm("self"));
    }
  };
  // const { search } = useLocation();

  useEffect(() => {
    dispatch(saveBiData(false));
    // dispatch(saveAppNo(false));
    dispatch(createRegionData(false));
    dispatch(saveResponse(false));
  }, []);

  useEffect(() => {
    const callApi = async () => {
      await updateQuotation({
        requestType: "payment",
        traceId: enquiryId,
      });
    };
    if (company_alias === "icici_pru") {
      callApi();
    }
  }, [company_alias]);
  // const companyName = selectedQuote.company_name;

  const success =
    selectedQuote.company_alias === "icici_pru" ||
    paymentStatusLoader ||
    temp == "active"
      ? isPaymentSuccess && reciept == ""
      : isPaymentSuccess && reciept?.receipt_url;
  console.log({ success, paymentStatusLoader, isPaymentSuccess }, reciept);
  if (loader) return <Loader />;
  return (
    <>
      <ThankWrap>
        <LaxmiWrap>
          <LaxmiImg src={LaxmiGreeting}></LaxmiImg>
        </LaxmiWrap>
        {success || paymentStatusLoader ? (
          <>
            <ThankCheck>
              <img src={greenTick} alt="greenTick" />
            </ThankCheck>
            <ThankTitle>Thank you for making the payment</ThankTitle>
            <ThankSubTitle>Your Transaction is Successful!</ThankSubTitle>
          </>
        ) : (
          <>
            <ThankCheck>
              <img className="" src={ICError} alt="error" />
            </ThankCheck>
            <ThankTitle>Uh ohh! Something went wrong...</ThankTitle>
          </>
        )}
        {paymentStatusLoader &&
        selectedQuote?.company_alias === "bajaj_life" ? (
          <Loader
            style={{ position: "inherit" }}
            message={
              <PaymentLoadingMessage
                style={{ fontWeight: "400", top: "44%", position: "absolute" }}
                message={"Please wait while we generate your proposal form"}
              />
            }
          />
        ) : success ? (
          <>
            <div className="thankBtnWrap">
              <Button
                primaryBlue
                padding
                md
                onClick={() => {
                  history.replace({
                    pathname: "/proposalpage",
                    search: `${
                      afflid
                        ? "afflid=" + afflid + "&"
                        : utm_medium
                        ? "utm_medium=" + utm_medium + "&"
                        : c_id
                        ? "c_id" + c_id + "&"
                        : ""
                    }enquiryId=${enquiryId}`,
                  });
                  handleClick();
                }}
              >
                PROCEED TO PROPOSAL
                <img src={proccedIcon} alt="proceed" className="proceedIcon" />
              </Button>
            </div>
            {reciept?.receipt_url && (
              <div className="downloadWrap">
                <DownloadButton>
                  <img
                    src={downloadIcon}
                    alt="download"
                    className="downloadIcon"
                  />
                  <span className="downloadText">
                    <a
                      href={reciept?.receipt_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download payment Receipt
                    </a>
                  </span>
                </DownloadButton>
              </div>
            )}
          </>
        ) : selectedQuote?.company_alias === "bajaj_life" &&
          reciept.receipt_url ? (
          <div className="thankBtnWrap">
            <ThankSubTitle>We are working on it.</ThankSubTitle>
            <div style={{ padding: "0 60px" }}>
              <ThankSubTitle>
                Please call us on
                <a style={{ textDecoration: "none" }} href="tel:18002669693">
                  <p
                    style={{
                      color: "#333",
                      fontFamily: "Inter-Semibold",
                      display: "inline",
                    }}
                  >
                    {" "}
                    1800 266 9693
                  </p>
                </a>{" "}
                for further assistance.
              </ThankSubTitle>
            </div>
          </div>
        ) : (
          <></>
        )}
      </ThankWrap>
    </>
  );
};

export default ThankYou;
