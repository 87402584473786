import React from "react";
import phone from "../../../assets/img/phone.svg";
import document from "../../../assets/img/fileSymbol.svg";
import hand from "../../../assets/img/hand.svg";
export default function KnowYourProcess() {
  return (
    <div>
      <div id="kycp" className="tab-pane mt-2">
        <div className="detailsPopStepWrap">
          <ul className="progressbar">
            <li>
              INTIMATE CLAIM
              <span className="detailsPopStepIcon">
                {/* <img src={phone} alt="phone" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.93"
                  height="22.06"
                  viewBox="0 0 21.93 22.06"
                >
                  <path
                    fill="#222"
                    d="M17.465 3.97a.5.5 0 1 0-.5-.5.5.5 0 0 0 .5.5zm1.069 12.569A10.863 10.863 0 0 1 15.126 16a1.461 1.461 0 0 0-1.483.314l-2.157 1.626a12.048 12.048 0 0 1-5.362-5.361l1.584-2.1a1.51 1.51 0 0 0 .371-1.531 10.861 10.861 0 0 1-.544-3.418 1.5 1.5 0 0 0-1.5-1.5h-3.5a1.5 1.5 0 0 0-1.5 1.5 17.521 17.521 0 0 0 17.5 17.5 1.5 1.5 0 0 0 1.5-1.5v-3.49a1.5 1.5 0 0 0-1.501-1.501zm.5 4.991a.5.5 0 0 1-.5.5 16.519 16.519 0 0 1-16.5-16.5.5.5 0 0 1 .5-.5h3.5a.5.5 0 0 1 .5.5 11.9 11.9 0 0 0 .592 3.722.55.55 0 0 1-.173.568l-1.818 2.41a.5.5 0 0 0-.046.528 13.107 13.107 0 0 0 6.218 6.218.5.5 0 0 0 .529-.046l2.464-1.866a.5.5 0 0 1 .509-.119 11.864 11.864 0 0 0 3.728.595.5.5 0 0 1 .5.5zm-.57-12.56h-.5v-3.5a.5.5 0 0 0-.237-.426.5.5 0 0 0-.486-.022l-1 .5a.5.5 0 1 0 .447.895l.276-.138V8.97h-.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm-1-8a5.5 5.5 0 1 0 5.5 5.5 5.506 5.506 0 0 0-5.499-5.5zm0 10a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1-4.499 4.5z"
                    data-name="Call Center 24h"
                    transform="translate(-1.035 -.97)"
                  ></path>
                </svg>
              </span>
            </li>
            <li>
              SUBMIT DOCUMENTS
              <span className="detailsPopStepIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="24"
                  viewBox="0 0 18 24"
                >
                  <defs></defs>
                  <g transform="translate(-3)">
                    <g data-name="Group 450">
                      <g data-name="Group 449">
                        <g data-name="Group 447">
                          <path
                            d="M20.5 24h-17a.5.5 0 0 1-.5-.5v-17a.5.5 0 0 1 .146-.354l6-6A.5.5 0 0 1 9.5 0h11a.5.5 0 0 1 .5.5v23a.5.5 0 0 1-.5.5zM4 23h16V1H9.707L4 6.707z"
                            className="cls-1"
                            data-name="Path 1067"
                          ></path>
                        </g>
                        <g data-name="Group 448">
                          <path
                            d="M9.5 7h-6a.5.5 0 0 1 0-1H9V.5a.5.5 0 0 1 1 0v6a.5.5 0 0 1-.5.5z"
                            className="cls-1"
                            data-name="Path 1068"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 451">
                      <path
                        d="M17.5 9h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1069"
                      ></path>
                    </g>
                    <g data-name="Group 452">
                      <path
                        d="M17.5 6h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1070"
                      ></path>
                    </g>
                    <g data-name="Group 453">
                      <path
                        d="M17.5 12h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1071"
                      ></path>
                    </g>
                    <g data-name="Group 454">
                      <path
                        d="M17.5 15h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1072"
                      ></path>
                    </g>
                    <g data-name="Group 455">
                      <path
                        d="M17.5 18h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1073"
                      ></path>
                    </g>
                    <g data-name="Group 456">
                      <path
                        id="M17.5 21h-11a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z"
                        className="cls-1"
                        data-name="Path 1074"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </li>
            <li>
              SETTLEMENT OF CLAIM
              <span className="detailsPopStepIcon">
                <img src={hand} alt="hand" />
              </span>
            </li>
          </ul>
        </div>
        <div className="detailsStepData1 mt-2">
          Upon death of the life assured, the nominee can download the claim
          intimation form from Member Login or you can contact Elephant on{" "}
          <nobr>1800 2669 693</nobr> or support@elephant.in we will assist you
          end to end. Upon receipt of intimation, the insurer shall contact the
          nominee/beneficiary for the required set of documents. Please keep the
          below list of documents handy for avoiding further delay.
        </div>
        <div className="detailsStepData2">List of Documents:</div>
        <div className="detailsStepData3">
          a. Death certificate issued by Govt. / Relevant Authority
        </div>
        <div className="detailsStepData3">
          b. Death claim form with claimant’s NEFT details
        </div>
        <div className="detailsStepData3">c. Original Policy Document</div>
        <div className="detailsStepData3">
          d. Claimant’s Identity Residence Prof
        </div>
        <div className="detailsStepData3">
          e. FIR, Police Inquest Report & Panchanama (in case of unnatural
          death)
        </div>
        <div className="detailsStepData3">
          f. Additional documentation might be requested by the insurer on a
          case-to-case basis
        </div>
        <div className="detailsStepData4">
          If all documents are received are in order, insurance company’s claims
          team will take decision on the claim.
        </div>
        <div className="detailsStepData4">
          Upon favorable decision, payment shall be made to nominee or legal
          heir of the insured.
        </div>
      </div>
    </div>
  );
}
