/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from "react-redux";
import { useCustomLocation } from ".";
import {
  setSelectedQuote,
  setloaderForQuoteToRider,
} from "../modules/QuotePage/quotePageSlice";
import { setOpenBajajDiscount } from "../components/Popup/bajajDiscount.slice";
import { setOpenKotakDiscount } from "../components/Popup/kotakDiscount.slice";
import { setOpenIciciDiscount } from "../components/Popup/iciciDiscount.slice";

function useQuoteBuyNowButton() {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const { enquiryId, history } = useCustomLocation();
  // console.log({ loading });
  const { previousSelectedQuoteId, selectedQuote } = useSelector(
    ({ quote }) => quote,
  );
  const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const handleBuyNowClick = quote => {
    const newData = { ...quote, traceId: enquiryId };
    const newSumAssured = parseInt(quote?.sumAssured);

    dispatch(setloaderForQuoteToRider(true)); // Set loading to true
    if (
      quote?.insurance_code !== previousSelectedQuoteId ||
      quote?.premium_amount !== selectedQuote?.premium_amount ||
      ["tata_aia_life", "max_life"].includes(quote?.company_alias) ||
      (quote?.company_alias === "bandhan_life" && newSumAssured <= 5000000)
    ) {
      dispatch(setSelectedQuote(newData, history));
    } else if (quote?.company_alias === "bajaj_life") {
      dispatch(setOpenBajajDiscount(true));
    } else if (quote?.company_alias === "kotak_life") {
      dispatch(setOpenKotakDiscount(true));
    } else if (
      quote?.company_alias === "icici_pru" &&
      quote?.self_employed_premium != 0 &&
      paymentTerm.option !== "Single Pay"
    ) {
      dispatch(setOpenIciciDiscount(true));
    } else {
      history.push({
        pathname: "/riderPage",
        search: `enquiryId=${newData.traceId}`,
      });
    }
  };

  return handleBuyNowClick;
}

export default useQuoteBuyNowButton;
