export const prodName = (IC, child, addOns, selectedQuoteToBuy) => {
  const list = addOns.map(item => item.rider_shortname || item.riderShortname);
  let riders = new Map();

  list.forEach(item => riders.set(item, true));
  if (IC === "kotak_life") {
    if (riders.get("WOP")) return `Life Secure`;
    if (riders.get("ADB")) return `Life Plus`;
    return `Life Option`;
  }
  if (
    IC === "bajaj_life" &&
    selectedQuoteToBuy?.master_product_name === "eTouch"
  ) {
    if (riders.get("ADB")) return `Life Shield Plus`;
    return child || `Life Shield`;
  }
  if (IC === "icici_pru") {
    if (riders.get("ADBU") && riders.get("CIBF")) return `All in One`;
    if (riders.get("ADBU")) return `Life Plus`;
    if (riders.get("CIBF")) return `Life & Health`;
    return `Life`;
  }
  if (IC === "hdfc_life") {
    if (riders.get("C4CI")) return `Life & CI Rebalance`;
  }
  if (IC === "pnb_metlife") {
    if (riders.get("ACI") && riders.get("WOP")) return `Life Plus Health`;
    if (riders.get("WOP")) return `Life Plus`;
    return `Life Plan`;
  }
  return child;
};

// Life - Addition of ci
// Life plus - ci + adb
// Life secure - ci + wop
// Life plus - adb
// Life secure - wop
