import React from "react";
import Lottie from "react-lottie-player";
import animationData from "./gift.json";
import "./ExclusiveQuoteCard.scss";
import logo from "./../../../../assets/partner_logos/bandhan_life.png";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import { useState } from "react";
import Knowmore from "../../../../components/KnowMorePopup/knowmore";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
const ExclusiveQuoteCard = ({
  quote,
  quotes,
  onBuyNowClicked,
  price,
  payPremiumMode,
  setShowBenefitsPopup,
  showBenefitsPopup,
}) => {
  const [showKnowMorePopup, setShowKnowMorePopup] = useState(false);
  const { coverUpto } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const history = useHistory();
  const isLengthOne = quotes?.length === 1;
  console.log({ quote });
  return (
    <>
      <div
        className={` ${
          isLengthOne === true ? "excl-card-coll" : "excl-card-col"
        }`}
      >
        <div className="excl-card-offer">
          <div className="ribbon-card-banner">Exclusive Offer</div>
          <div className="excl-card-offer-header">
            <div className="excl-card-offer-header-col">
              <p className="excl-card-title">Buy ₹ 50 Lakh Insta Cover</p>
              <p
                className="excl-card-offer-benefits"
                onClick={() => {
                  setShowBenefitsPopup(true);
                }}
              >
                View Benefits
              </p>
            </div>
            <Lottie loop animationData={animationData} play className="gift" />
          </div>
          <div className="excl-card-offer-body">
            <div className="excl-card-offer-body-top">
              <table
                cellPadding={0}
                cellSpacing={0}
                className="excl-card-offer-body-top-table"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "0px 5px 0px 0px",
                        verticalAlign: "middle",
                        width: "50%",
                      }}
                    >
                      <img
                        src={logo}
                        alt="Bandhan Life"
                        className="excl-card-offer-brand-logo"
                      />
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        width: "50%",
                        position: "relative",
                      }}
                    >
                      <p className="excl-card-offer-brand-name">
                        Bandhan Life iTerm Comfort
                      </p>
                      <p
                        className="excl-card-offer-knowmore"
                        onClick={() => {
                          setShowKnowMorePopup(true);
                        }}
                      >
                        Know more
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr class="excl-card-offer-line excl-card-offer-line-1" />
              <table
                style={{ width: "100%", borderCollapse: "collapse" }}
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "0px 5px 0px 0px",
                        verticalAlign: "middle",
                        width: "50%",
                      }}
                    >
                      <ul class="excl-ul">
                        <li>No Medicals</li>
                        <li>No KYC</li>
                      </ul>
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        width: "50%",
                      }}
                    >
                      <ul
                        class="excl-ul excl-ul-2"
                        style={{ padding: "0 0 0 3px" }}
                      >
                        <li>No Income Proofs</li>
                        <li>Instant Policy</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr class="excl-card-offer-line excl-card-offer-line-2" />
            </div>
            <div className="excl-card-offer-body-bottom">
              <p className="excl-claim-ratio-p">
                Claims Settlement Ratio{" "}
                <CustomTooltip
                  id={"quoteCard_customTooltip_csr"}
                  place={"left"}
                  Position={{ top: 3, left: 0 }}
                  customClassName="quotePageTooltip"
                >
                  <span
                    style={{
                      position: "relative",
                      margin: "0 3px",
                      top: "-1px",
                    }}
                    data-for={"quoteCard_customTooltip_csr"}
                    data-tip={`<h3>${"Claim Settlement Ratio"}</h3><div>${"Claim Settled against the number of claims received."}</div>`}
                    data-html={true}
                  >
                    <span
                      className="cardTooltipSvg"
                      data-toggle="popover"
                      title=""
                      data-content="Your Nominee will get the Sum Assured amount in monthly installments for a specified period."
                      data-original-title="Nominee gets"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                      >
                        <g transform="translate(-.5 -.5)">
                          <g transform="translate(1 1)">
                            <circle
                              cx="6"
                              cy="6"
                              r="6"
                              fill="none"
                              stroke="#5b6466"
                            ></circle>
                            <path
                              fill="#5b6466"
                              d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
                              data-name="?-copy"
                              transform="translate(-2.056 -1.37)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
                </CustomTooltip>
                <span>{quote?.claim_settlement_ratio?.split("%")?.[0]}%</span>
              </p>
              <p className="excl-claim-ratio-amt">{`₹ ${Number(
                price,
              ).toLocaleString("en-IN")}${payPremiumMode}`}</p>
              <button className="excl-card-buy-btn" onClick={onBuyNowClicked}>
                BUY NOW
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`modal modal-insta-cov fade show ${
          showBenefitsPopup ? "in" : ""
        }`}
        id="excl-offer-popup"
        role="dialog"
        style={{ display: showBenefitsPopup && "block" }}
      >
        <div className="modal-dialog modal-insta-cov-expl">
          <div
            className="modal-content insta-cov-expl-wrap"
            id="instaCovExplWrap"
          >
            <div className="modal-body">
              <div className="row">{bandhan_content}</div>
            </div>
          </div>
        </div>
      </div> */}
      <Knowmore
        onClose={setShowKnowMorePopup}
        onBuyNowClicked={onBuyNowClicked}
        nomineeGets={"₹50 LACKS (LUMPSUM)"}
        buynow={true}
        nextPage="eligibilitypage"
        quote={quote}
        policyURL={quote.brochure}
        price={`₹ ${Number(price).toLocaleString("en-IN")}${payPremiumMode}`}
        show={showKnowMorePopup}
        logoText={quote.master_product_name}
        logoUrl={quote.company_logo}
        childName={quote.child_product_name}
        history={history}
        coverUpto={
          quote.planTenure === "different"
            ? quote?.differentTenureCU + " years"
            : coverUpto
        }
      />
    </>
  );
};

export default ExclusiveQuoteCard;
