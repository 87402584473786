import React, { useState } from "react";
import Popup from "../Popup";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import "./childPopup.scss";
import ChildCard from "./childCard";
import { setSelectedQuote } from "../../../modules/QuotePage/quotePageSlice";
import ChildCardBajaj from "./childCardBajaj";
import {
  getChildPremium,
  getQuotesRemove,
  setBajajQuoteData,
  setChildVariant,
} from "../../../modules/RiderPage/RiderPage.slice";
import { useCustomLocation } from "../../../custom-hooks";
const ChildDetails = ({
  show,
  onClose,
  selected,
  riderTerm,
  riderId,
  termInsuId,
  riderShortname,
  onClick,
  setPrice,
  setGst,
  setCoverAmount,
  costPer,
  additionalRiders,
  added,
  cover,
  setChildOrSpouse,
  childVariant,
}) => {
  const dispatch = useDispatch();
  const [childNo, setChildNo] = useState(1);
  const { enquiryId } = useCustomLocation();
  const { bajajQuoteData, childPremium, childData } = useSelector(
    state => state.rider,
  );
  const { displayNomineeGets } = useSelector(state => state.quoteFilter);
  //ChildVariant
  const [bajajChild, setBajajChild] = useState(false);

  console.log(bajajQuoteData);
  const setQuote = async () => {
    setBajajChild(!bajajChild);

    if (!bajajChild && childPremium) {
      if (childPremium && childPremium[0]?.status) {
        const newData = { ...childPremium[0], traceId: enquiryId };
        dispatch(setSelectedQuote(newData));
        dispatch(getQuotesRemove(enquiryId));
        setChildOrSpouse(true);
        dispatch(setChildVariant(true));
      }
    } else {
      //dispatch(getQuotesRemove(enquiryId));
      if (bajajQuoteData) {
        if (bajajQuoteData[0]?.premium_amount) {
          const data = { ...bajajQuoteData[0], traceId: enquiryId };
          dispatch(setSelectedQuote(data));
          setChildOrSpouse(false);
          dispatch(setChildVariant(false));
        }
      }
    }
  };
  const content = (
    <Main>
      <Header>
        <Heading>Add-on: Child Education Extra Cover </Heading>
      </Header>
      <div
        className={`contentWrapSpouse  ${
          selected?.company_alias === "bajaj_life" ? "scrollWrap" : ""
        } `}
      >
        {selected?.company_alias === "pnb_metlife" && (
          <ChildCard
            show={show}
            riderTerm={riderTerm}
            riderId={riderId}
            termInsuId={termInsuId}
            riderShortname={riderShortname}
            setPrice={setPrice}
            setGst={setGst}
            setCoverAmount={setCoverAmount}
            selected={selected}
            childNo={childNo}
            id={Math.floor(Math.random() * 10000)}
          ></ChildCard>
        )}
        {selected?.company_alias === "bajaj_life" && (
          <ChildCardBajaj
            show={show}
            riderTerm={riderTerm}
            riderId={riderId}
            termInsuId={termInsuId}
            riderShortname={riderShortname}
            setPrice={setPrice}
            setGst={setGst}
            setCoverAmount={setCoverAmount}
            selected={selected}
            childNo={childNo}
            id={Math.floor(Math.random() * 10000)}
          ></ChildCardBajaj>
        )}
        {/* <div className="btnWrapSpouse">
					<ContentBtn added={added}>
						<div className="btnTxtSpouse" onClick={onClick}>
							{added
								? `REMOVE FROM PLAN `
								: `ADD TO PLAN ${costPer !== "(₹ NaN/Month)" ? costPer : ""}`}
						</div>
					</ContentBtn>
				</div> */}
        {selected?.company_alias === "pnb_metlife" ? (
          <div className="btnWrapSpouse">
            <ContentBtn added={added}>
              <div className="btnTxtSpouse" onClick={onClick}>
                {added
                  ? `REMOVE FROM PLAN ${costPer}`
                  : `ADD TO PLAN ${costPer}`}
              </div>
            </ContentBtn>
          </div>
        ) : (
          <div className="btnWrapSpouse">
            <ContentBtn
              bajajChild={bajajChild}
              disabled={!(childPremium && childPremium[0]?.status)}
            >
              <div className="btnTxtSpouse" onClick={setQuote}>
                {bajajChild ? `REMOVE FROM PLAN ` : `ADD TO PLAN `}
              </div>
            </ContentBtn>
          </div>
        )}
      </div>
      <Footer>
        <img src={selected?.company_logo} alt="logo" className="bajajLogo" />
        <div className="footerData">
          <div>{selected.master_product_name}</div>
          <div>Cover: {displayNomineeGets}</div>
        </div>
        <FooterBtn>
          <div className="btnTxtSpouse footerBtnTxt">
            <div className="premPaySpouse">TOTAL PAYABLE PREMIUM</div>
            <div className="premAmountSpouse">
              ₹{" "}
              {(
                Number(selected.premium_amount) + Number(additionalRiders)
              ).toLocaleString("en-IN")}
            </div>
          </div>
        </FooterBtn>
      </Footer>
    </Main>
  );
  let PopupWidth;
  let height;

  if (window.matchMedia("(max-width: 767px)").matches) {
    PopupWidth = "100%";
    height = "100%";
  } else {
    PopupWidth = "810px";
    height = "auto";
  }

  return (
    <Popup
      lrgClose="true"
      transparentBackground={"false"}
      show={show}
      onClose={onClose}
      content={content}
      height={height}
      width={PopupWidth}
      size="true"
      topFixed={"48px"}
    />
  );
};

export default ChildDetails;

const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  height: 72px;
  width: 100%;
`;

const Heading = styled.div`
  font-family: "Inter-SemiBold";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    padding: 36px 84px 6px 19px;
  }
`;

const Footer = styled.div`
  display: flex;
  height: 104px;
  padding: 27px 26px 27px 24px;
  box-shadow: 50px -50px 99px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #d6d6d6;
  background-color: #efefef;
  width: 100%;
  position: relative;
`;

const FooterBtn = styled.div`
  width: 194px;
  height: 50px;
  padding: 6px 18px;
  border-radius: 4px;
  background-color: #f2581b;
  position: absolute;
  right: 20px;
`;

const ContentBtn = styled.button`
  width: 356px;
  height: 60px;
  display: block;
  pointer-events: ${props => (props.disabled ? "none" : "")};
  border-radius: 4px;
  border: ${props =>
    props.added || props.bajajChild ? "solid 1px #d43d3d" : "none"};
  background-color: ${props =>
    props.added || props.bajajChild ? "#fff" : "#107591"};
  padding: 21px 0px 20px;
  text-align: center;
  & div {
    color: ${props => (props.added || props.bajajChild ? "#d43d3d" : "#fff")};
  }
`;

const ContentBtnRemove = styled.button`
  width: 356px;
  height: 60px;
  border-radius: 4px;
  border: solid 1px #969799;
  background-color: #fff;
`;

const Main = styled.div``;
