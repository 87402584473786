export const MemberList = [
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Wife",
  "Son",
  "Grand Father",
  "Grand Mother",
  "Daughter",
  "Husband",
  "Children",
];
export const CauseList = [
  "Diabetes",
  "Cancer",
  "High Blood Pressure",
  "Heart Disease",
  "Kidney Disease",
  "Tuberculosis",
  "Alcoholism",
  "Mental Illness",
  "Suicide",
  "Accident",
  "Normal",
  "Fever",
];

export const bajajNationality = {
  Indian: "IN",
  Tibetian: "TIB",
  Afghan: "AFG",
  Albanian: "ALB",
  Algerian: "ALG",
  American: "AM",
  Andorran: "AND",
  Angolan: "AGO",
  Argentinian: "ARG",
  Armenian: "ARM",
  Australian: "AUS",
  Austrian: "AUT",
  Azerbaijani: "AZE",
  Bahamian: "BHS",
  Bahraini: "BHR",
  Bangladeshi: "BGD",
  Barbadian: "BRB",
  Belarusian: "BLR",
  Belgian: "BEL",
  Belizean: "BLZ",
  Beninese: "BEN",
  Bhutanese: "BTN",
  Bolivian: "BOL",
  Bosnian: "BIH",
  Botswanan: "BWA",
  Brazilian: "BRA",
  British: "GB",
  Bruneian: "BRN",
  Bulgarian: "BGR",
  Burkinese: "BFA",
  Burundian: "BDI",
  Cambodian: "KHM",
  Cameroonian: "CMR",
  Canadian: "CAN",
  "Cape Verdean": "CPV",
  Chadian: "TCD",
  Chilean: "CHL",
  Chinese: "CHN",
  Colombian: "COL",
  Congolese: "COD",
  "Costa Rican": "CRI",
  Croatian: "CIV",
  Cuban: "CUB",
  Cypriot: "CYP",
  Czech: "CZ",
  Danish: "DNS",
  Djiboutian: "DJI",
  Dominican: "DMA",
  Dutch: "DTC",
  Ecuadorean: "ECU",
  Egyptian: "EGY",
  English: "ENG",
  Eritrean: "ERI",
  Estonian: "EST",
  Ethiopian: "ETH",
  Fijian: "FJI",
  Finnish: "FIN",
  French: "F",
  Gabonese: "GAB",
  Gambian: "GMB",
  Georgian: "GEO",
  German: "DEU",
  Ghanaian: "GHA",
  Greek: "GRC",
  Grenadian: "GRD",
  Guatemalan: "GTM",
  Guinean: "GIN",
  Haitian: "HTI",
  Honduran: "HND",
  Hungarian: "HUN",
  Icelandic: "ISL",
  Indonesian: "IDN",
  Iranian: "IRN",
  Iraqi: "IRQ",
  Irish: "IRS",
  Italian: "ITA",
  Jamaican: "JAM",
  Japanese: "JPN",
  Jordanian: "JOR",
  Kazakh: "KAZ",
  Kenyan: "KEN",
  Kuwaiti: "KWT",
  Latvian: "LVA",
  Lebanese: "LBN",
  Liberian: "LBR",
  Libyan: "LBY",
  Lithuanian: "LTU",
  Macedonian: "MKD",
  Madagascan: "MDG",
  Malawian: "MWI",
  Malaysian: "MYS",
  Maldivian: "MDV",
  Malian: "MLI",
  Maltese: "MLT",
  Mauritanian: "MRT",
  Mauritian: "MUS",
  Mexican: "MEX",
  Moldovan: "MDA",
  Monacan: "MCO",
  Mongolian: "MNG",
  Moroccan: "MAR",
  Mozambican: "MOZ",
  NEPALESE: "NEP",
  Namibian: "NAM",
  Nicaraguan: "NIC",
  Nigerian: "NER",
  Nigerien: "NGA",
  Norwegian: "NOR",
  Omani: "OMN",
  Pakistani: "PAK",
  Panama: "PN",
  Paraguayan: "PRY",
  Peruvian: "PER",
  Philippine: "PHL",
  Polish: "POL",
  Portuguese: "PRT",
  Qatari: "QAT",
  Romanian: "ROU",
  Russian: "RUS",
  Rwandan: "RWA",
  Salvadorean: "SMR",
  Saudi: "SAU",
  Senegalese: "SEN",
  Serbian: "SCG",
  Seychellois: "SYC",
  "Sierra Leonian": "SLE",
  Singaporean: "SGP",
  Slovak: "SVK",
  Slovenian: "SVN",
  Somali: "SOM",
  "South African": "ZAF",
  Spanish: "ESP",
  "Sri Lankan": "LKA",
  Sudanese: "SDN",
  Surinamese: "SUR",
  Swazi: "SWZ",
  Swedish: "SWE",
  Swiss: "CHE",
  Syrian: "SYR",
  Taiwanese: "TWN",
  Tajik: "TJK",
  Tanzanian: "TZA",
  Thai: "THA",
  Togolese: "TGO",
  Tunisian: "TUN",
  Turkish: "TUR",
  Turkmen: "TKM",
  Tuvaluan: "TUV",
  Ugandan: "UGA",
  Ukrainian: "UKR",
  Uruguayan: "URY",
  Uzbek: "UZB",
  Vanuatuan: "VUT",
  Venezuelan: "VEN",
  Vietnamese: "VNM",
  Yemeni: "YEM",
  Zambian: "ZMB",
  Zimbabwean: "ZWE",
};

export const bajajCountryCodes = {
  ARUBA: "ABW",
  AFGHANISTAN: "AFG",
  ANGOLA: "AGO",
  ANGUILLA: "AIA",
  ALBANIA: "ALB",
  ANDORRA: "AND",
  "NETHERLANDS ANTILLES": "ANT",
  "UNITED ARAB EMIRATES (WAS TRUCIAL STATES": "ARE",
  ARGENTINA: "ARG",
  ARMENIA: "ARM",
  "AMERICAN SAMOA": "ASM",
  Austria: "AT",
  ANTARCTICA: "ATA",
  "FRENCH SOUTHERN TERRITORIES": "ATF",
  "ANTIGUA AND BARBUDA": "ATG",
  AUSTRALIA: "AUS",
  AUSTRIA: "AUT",
  AZERBAIJAN: "AZE",
  BURUNDI: "BDI",
  BELGIUM: "BEL",
  "BENIN (WAS DAHOMEY)": "BEN",
  "BURKINA FASO (WAS UPPER VOLTA)": "BFA",
  BANGLADESH: "BGD",
  BULGARIA: "BGR",
  BAHRAIN: "BHR",
  BAHAMAS: "BHS",
  "BOSNIA AND HERZEGOVINA": "BIH",
  BELARUS: "BLR",
  BELIZE: "BLZ",
  BERMUDA: "BMU",
  BOLIVIA: "BOL",
  BRAZIL: "BRA",
  BARBADOS: "BRB",
  "BRUNEI DARUSSALAM": "BRN",
  BHUTAN: "BTN",
  "BOUVET ISLAND (BOUVETOYA)": "BVT",
  BOTSWANA: "BWA",
  "CENTRAL AFRICAN REPUBLIC": "CAF",
  CANADA: "CAN",
  "COCOS (KEELING) ISLANDS": "CCK",
  SWITZERLAND: "CHE",
  CHILE: "CHL",
  CHINA: "CHN",
  "COTE D IVOIRE": "CIV",
  CAMEROON: "CMR",
  "CONGO, DEMOCRATIC REPUBLIC OF (WAS ZAIRE": "COD",
  "COOK ISLANDS": "COK",
  COLOMBIA: "COL",
  COMOROS: "COM",
  "CAPE VERDE": "CPV",
  "COSTA RICA": "CRI",
  CUBA: "CUB",
  "CHRISTMAS ISLAND": "CXR",
  "CAYMAN ISLANDS": "CYM",
  CYPRUS: "CYP",
  "Czech Republic": "CZ",
  "CZECH REPUBLIC": "CZE",
  Germany: "D",
  GERMANY: "DEU",
  DJIBOUTI: "DJI",
  DOMINICA: "DMA",
  DENMARK: "DNK",
  "DOMINICAN REPUBLIC": "DOM",
  ALGERIA: "DZA",
  ECUADOR: "ECU",
  EGYPT: "EGY",
  ERITREA: "ERI",
  "WESTERN SAHARA (WAS SPANISH SAHARA)": "ESH",
  SPAIN: "ESP",
  ESTONIA: "EST",
  ETHIOPIA: "ETH",
  France: "F",
  FINLAND: "FIN",
  FIJI: "FJI",
  "FALKLAND ISLANDS (MALVINAS)": "FLK",
  FRANCE: "FRA",
  "FAEROE ISLANDS": "FRO",
  MICRONESIA: "FSM",
  GABON: "GAB",
  GEORGIA: "GEO",
  GHANA: "GHA",
  GIBRALTAR: "GIB",
  GUINEA: "GIN",
  GUADALOUPE: "GLP",
  GAMBIA: "GMB",
  "GUINEA-BISSAU": "GNB",
  "EQUATORIAL GUINEA": "GNQ",
  GREECE: "GRC",
  GRENADA: "GRD",
  GREENLAND: "GRL",
  GUATEMALA: "GTM",
  "FRENCH GUIANA": "GUF",
  GUAM: "GUM",
  GUYANA: "GUY",
  "HONG KONG": "HKG",
  "HEARD AND MCDONALD ISLANDS": "HMD",
  HONDURAS: "HND",
  Croatia: "HR",
  "HRVATSKA (CROATIA)": "HRV",
  HAITI: "HTI",
  Hungary: "HU",
  HUNGARY: "HUN",
  INDONESIA: "IDN",
  Ireland: "IE",
  India: "IN",
  "BRITISH INDIAN OCEAN TERRITORY (CHAGOS A": "IOT",
  IRELAND: "IRL",
  IRAN: "IRN",
  IRAQ: "IRQ",
  ICELAND: "ISL",
  ISRAEL: "ISR",
  Italy: "IT",
  ITALY: "ITA",
  JAMAICA: "JAM",
  JORDAN: "JOR",
  JAPAN: "JPN",
  KAZAKHSTAN: "KAZ",
  KENYA: "KEN",
  "KYRGYZ REPUBLIC": "KGZ",
  CAMBODIA: "KHM",
  KIRIBATI: "KIR",
  "ST. KITTS AND NEVIS": "KNA",
  "KOREA, REPUBLIC": "KOR",
  KUWAIT: "KWT",
  LEBANON: "LBN",
  LIBERIA: "LBR",
  "LIBYAN ARAB JAMAHIRIYA": "LBY",
  "ST. LUCIA": "LCA",
  LIECHTENSTEIN: "LIE",
  "SRI LANKA": "LKA",
  LESOTHO: "LSO",
  LITHUANIA: "LTU",
  LUXEMBOURG: "LUX",
  LATVIA: "LVA",
  MACAO: "MAC",
  MOROCCO: "MAR",
  MONACO: "MCO",
  MOLDOVA: "MDA",
  MADAGASCAR: "MDG",
  MALDIVES: "MDV",
  MEXICO: "MEX",
  "MARSHALL ISLANDS": "MHL",
  MACEDONIA: "MKD",
  MALI: "MLI",
  MALTA: "MLT",
  "MYANMAR (WAS BURMA)": "MMR",
  MONGOLIA: "MNG",
  "NORTHERN MARIANA ISLANDS": "MNP",
  MOZAMBIQUE: "MOZ",
  MAURITANIA: "MRT",
  MONTSERRAT: "MSR",
  MARTINIQUE: "MTQ",
  MAURITIUS: "MUS",
  MALAWI: "MWI",
  MALAYSIA: "MYS",
  MAYOTTE: "MYT",
  NAMIBIA: "NAM",
  "NEW CALEDONIA": "NCL",
  NIGER: "NER",
  "NORFOLK ISLAND": "NFK",
  NIGERIA: "NGA",
  NICARAGUA: "NIC",
  NIUE: "NIU",
  Holland: "NL",
  "NETHERLANDS, KINGDOM OF THE": "NLD",
  NORWAY: "NOR",
  NEPAL: "NPL",
  NAURU: "NRU",
  "NEW ZEALAND": "NZL",
  OMAN: "OMN",
  PAKISTAN: "PAK",
  PANAMA: "PAN",
  "PITCAIRN ISLAND": "PCN",
  PERU: "PER",
  PHILIPPINES: "PHL",
  Poland: "PL",
  PALAU: "PLW",
  "PAPUA NEW GUINEA": "PNG",
  POLAND: "POL",
  "PUERTO RICO": "PRI",
  "KOREA, DEMOCRATIC": "PRK",
  PORTUGAL: "PRT",
  PARAGUAY: "PRY",
  "PALESTINIAN TERRITORY": "PSE",
  "FRENCH POLYNESIA": "PYF",
  QATAR: "QAT",
  REUNION: "REU",
  ROMANIA: "ROU",
  "RUSSIAN FEDERATION": "RUS",
  RWANDA: "RWA",
  "SAUDI ARABIA": "SAU",
  "SERBIA AND MONTENEGRO": "SCG",
  SUDAN: "SDN",
  SENEGAL: "SEN",
  SINGAPORE: "SGP",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISL": "SGS",
  "ST. HELENA": "SHN",
  "SVALBARD AND JAN MAYEN ISLANDS": "SJM",
  "Slovak Republic": "SK",
  "SOLOMON ISLANDS (WAS BRITISH SOLOMON ISL": "SLB",
  "SIERRA LEONE": "SLE",
  Slovenia: "SLO",
  "EL SALVADOR": "SLV",
  "SAN MARINO": "SMR",
  SOMALIA: "SOM",
  "ST. PIERRE AND MIQUELON": "SPM",
  "SAO TOME AND PRINCIPE": "STP",
  SURINAME: "SUR",
  "SLOVAKIA (SLOVAK REPUBLIC)": "SVK",
  SLOVENIA: "SVN",
  SWEDEN: "SWE",
  SWAZILAND: "SWZ",
  SEYCHELLES: "SYC",
  "SYRIAN ARAB REPUBLIC": "SYR",
  "TURKS AND CAICOS ISLANDS": "TCA",
  CHAD: "TCD",
  TOGO: "TGO",
  THAILAND: "THA",
  TAJIKISTAN: "TJK",
  "TOKELAU (TOKELAU ISLANDS)": "TKL",
  TURKMENISTAN: "TKM",
  "TIMOR-LESTE": "TLS",
  TONGA: "TON",
  "TRINIDAD AND TOBAGO": "TTO",
  TUNISIA: "TUN",
  TURKEY: "TUR",
  "TUVALU (WAS PART OF GILBERT AND ELLICE I": "TUV",
  TAIWAN: "TWN",
  TANZANIA: "TZA",
  UGANDA: "UGA",
  "United Kingdom": "UK",
  UKRAINE: "UKR",
  "UNITED STATES MINOR OUTLYING ISLANDS": "UMI",
  "URUGUAY, EASTERN REPUBLIC OF": "URY",
  "UNITED STATES OF AMERICA": "USA",
  UZBEKISTAN: "UZB",
  "HOLY SEE (VATICAN CITY STATE)": "VAT",
  "ST. VINCENT AND THE GRENADINES": "VCT",
  VENEZUELA: "VEN",
  "BRITISH VIRGIN ISLANDS": "VGB",
  "US VIRGIN ISLANDS": "VIR",
  "VIET NAM": "VNM",
  "VANUATU (WAS NEW HEBRIDES)": "VUT",
  "WEST INDIES": "WED",
  "WALLIS AND FUTUNA ISLANDS": "WLF",
  SAMOA: "WSM",
  YEMEN: "YEM",
  "SOUTH AFRICA": "ZAF",
  ZAMBIA: "ZMB",
  "ZIMBABWE (WAS SOUTHERN RHODESIA)": "ZWE",
};

export const SelfEmpBus = [
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Certified copies of the Annual accounts of company for the last two years.",
  "Copies of latest audited profit and loss account and balance sheet for the last three years along with statement showing the capital account of the partners.",
  "Financial Assessment Report By Regional Head/General Manager",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
  "The latest three years ITRs of Life Assured.",
];

export const SelfEmpProf = [
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Certified copies of the Annual accounts of company for the last two years.",
  "Copies of latest audited profit and loss account and balance sheet for the last three years along with statement showing the capital account of the partners.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
  "The latest three years ITRs of Life Assured.",
];

export const selfEmpRet = [
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Bank account statement reflecting pension credit",
  "Copy of Land Documents in the name of Life Assured.",
  "Financial Assessment Report By Regional Head/General Manager",
  "Form J.",
  "Latest Form 16 or Form 16 A.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Proof of agriculture income � Land Documents in the name of Life Assured / From J / tehsildhar income certificate.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
  "The latest three years ITRs of Life Assured.",
];

export const SalGov = [
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Financial Assessment Report By Regional Head/General Manager",
  "Latest Employment contract letter on company letter head.",
  "Latest Form 16 or Form 16 A.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest three months salary slips of Life Assured.",
  "Proof of income of the employee in the form of latest Form 16, last 2 years individual income tax returns and last three months salary slips (certified by company).",
  "The latest three years ITRs of Life Assured.",
];

export const SalNonGov = [
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Financial Assessment Report By Regional Head/General Manager",
  "Latest Employment contract letter on company letter head.",
  "Latest Form 16 or Form 16 A.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest three months salary slips of Life Assured.",
  "Proof of income of the employee in the form of latest Form 16, last 2 years individual income tax returns and last three months salary slips (certified by company).",
  "The latest three years ITRs of Life Assured.",
];
export const nomineeShareList = [
  "Customize your Share",
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];
