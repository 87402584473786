import { createSlice } from "@reduxjs/toolkit";

const kotakDiscountSlice = createSlice({
  name: "kotakDiscountSlice",
  initialState: {
    isSalaried: "Y",
    backfromRiders: false,
    openKotakDiscount: false,
  },

  reducers: {
    setSalariedKotak: (state, action) => {
      state.isSalaried = action.payload;
    },
    resetKotakDiscountData: (state, action) => {
      state.isSalaried = "Y";
    },
    setBackFromRiders: (state, action) => {
      state.backfromRiders = action.payload;
    },
    setOpenKotakDiscount: (state, action) => {
      state.openKotakDiscount = action.payload;
    },
  },
});

export const {
  setSalariedKotak,
  resetKotakDiscountData,
  setBackFromRiders,
  setOpenKotakDiscount,
} = kotakDiscountSlice.actions;

export default kotakDiscountSlice.reducer;
