import React from "react";
import CompareProductsList from "../CompareProductsList/CompareProductsList";
import Features from "../Features/Features";

import "./compare-products-wrap.scss";

function CompareProductsWrap() {
  return (
    <div className="compare-products-wrap">
      <Features />
      <CompareProductsList />
    </div>
  );
}

export default CompareProductsWrap;
