import React from "react";
import editIcon from "../../../assets/img/editIcon.svg";
import { Label } from "../../../components/Form/Form";
import CardTitle from "../../../components/Card/CardTitle/CardTitle";
import Card from "../../../components/Card/Card";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";

export const TitleForm = ({ title }) => (
  <div>
    <Card>
      <CardTitle EC>{title}</CardTitle>
    </Card>
  </div>
);

export const EditButton = ({ onClick }) => (
  <span
    style={{
      float: "right",
      fontSize: "14px",
      color: "#107591",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <img className="smallEditBtn" src={editIcon} alt="editIcon" />
    &nbsp;EDIT
  </span>
);

export const SummaryTile = ({ label, data, detail }) => (
  <>
    <Label md sum editMode className="summary-tile-label">
      {label}
    </Label>
    <div className="summary-tile-data">{data}</div>
    {detail && (
      <div
        className="summary-title-data"
        style={{ width: "100%", marginLeft: "10px" }}
      >{`${detail}`}</div>
    )}
  </>
);

export const ToggleTile = ({
  isOrdered,
  label,
  checked,
  onChange,
  block,
  className,
  disabled,
}) => (
  <div className={`toggle-tile ${className}`}>
    <span style={{ marginLeft: isOrdered ? "-12px" : null }} className="mr-4">
      {label}
    </span>
    <div
      style={{
        display: `${block ? "block" : "flex"}`,
        margin: `${block ? "10px" : "0"}`,
      }}
    >
      <span>NO</span>
      <span>
        <ToggleButton
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </span>
      <span>YES</span>
    </div>
  </div>
);
