import React, { useState } from "react";

import "./search-box.scss";

function SearchBox({ defaultValue, handleChange }) {
  const [value, setValue] = useState(defaultValue || "");

  return (
    <div className="search-box">
      <input
        type="text"
        value={value}
        autoFocus
        onChange={e => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
      />
    </div>
  );
}

export default SearchBox;
