import styled from "styled-components";

export const TextInput = styled.input`
  width: 100%;
  border-radius: 4px;
  color: #333333;
  padding: 5px 8px 5px 8px;
  font-size: 14px;
  text-transform: ${props => props.capitalise && "capitalize"};
  height: 40px;
  font-family: Inter-Regular;
  border: ${props => (props.error === "danger" ? "2px" : "1px")} solid
    ${props => (props.error === "danger" ? "#d43d3d" : "#E3E4E8")};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border: 1px solid #107591;
    box-shadow: none;
    background-color: rgba(246, 246, 246, 0.2);
    outline: none;
  }
  @media (max-width: 760px) {
    height: 48px;
    padding: 5px 8px 5px 8px;
  }
`;
export const Label = styled.label`
  font-family: "Inter-Medium";
  font-size: 12px;
  line-height: 24px;
  color: #107591;
  width: 100%;
  text-transform: uppercase;
  @media (max-width: 760px) {
    font-family: "Inter-Medium";
    font-size: 12px;
    line-height: 24px;
    color: #107591;
    width: 100%;
  }
`;
export const DropDownWrap = styled.div`
  border: 1px solid #e3e4e8;
  height: 40px;
  padding-top: 10px;
  width: 100%;
  border-radius: 4px;
  @media (max-width: 760px) {
    height: 48px;
  }
`;
export const Button = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #f2581b;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 8px;
  outline: none;
  border: solid 1px #f2581b;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
`;
