import React from "react";
import { useWindowResize } from "../../../../custom-hooks";
import useCompareProductList from "../CompareProductsList/useCompareProductList";

import "./features.scss";

function Features() {
  const { width } = useWindowResize();

  const { maxInbuiltRiders, maxAddonRiders } = useCompareProductList();
  console.log("maxAddonRiders", maxAddonRiders);

  return (
    <div className="compare-page-features">
      <div className="top-info">
        <div className="planOptionHead tabletHide">Plan</div>
        <div className="planOptionFName tabletHide">Plan name</div>
      </div>
      <ul className="cd-features-list">
        <li>
          <div className="planOptionHead">Insurer Details</div>
          <div className="planOptionName">Existence in Market</div>
          <div className="planOptionName">Solvency Ratio</div>
          <div className="planOptionName">Claim Settlement Ratio</div>
        </li>
        <li>
          <div className="planOptionHead">Plan Details</div>
          <div className="planOptionName">Cover Amount</div>
          <div className="planOptionName">Cover upto</div>
          <div className="planOptionName">Nominee gets</div>
          <div className="planOptionName">Payment term</div>
          <div className="planOptionName">Pay premium</div>
          <div className="planOptionName">Medical Examination</div>
        </li>
        <li>
          <div className="planOptionHead">Benefits</div>
          <div className="planOptionName">In built riders (Included Free)</div>
          {maxInbuiltRiders > 0 &&
            [...Array(maxInbuiltRiders - 1)]?.map((_, idx, arr) => (
              <div
                key={idx + Math.random() * 1000}
                className={`planOptionName ${
                  idx === arr.length - 1 ? "productDividerPadding" : ""
                }`}
              ></div>
            ))}
          <div className="planOptionName">
            Additional riders (at extra cost)
          </div>
          <div className="planOptionName planOptionNameSub tablet-hide">
            These add-on covers can be added to your selected plan after you
            click <b>“BUY NOW”</b>
          </div>
          {
            //maxAddonRiders > 0 &&
            maxAddonRiders !== undefined &&
              maxAddonRiders >= 2 &&
              [...Array(maxAddonRiders - 2)]?.map((_, idx, arr) => (
                <div
                  key={idx + Math.random() * 1000}
                  className={`planOptionName ${
                    idx === arr?.length - 1 ? "productDividerPadding" : ""
                  }`}
                ></div>
              ))
          }
          {width <= 1177 && width >= 768 && (
            <div className="planOptionName productDividerPadding"></div>
          )}
          {/* <div className="planOptionName">Not offered</div> */}
        </li>
      </ul>
    </div>
  );
}

export default Features;
