import React from "react";

export const GreenCheck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" data-name="Path 5"></path>
    <path
      fill="#35b455"
      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"
      data-name="Path 6"
    ></path>
  </svg>
);

export const Danger = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 18 18"
  >
    <path fill="none" d="M0 0h18v18H0z" data-name="Path 1088"></path>
    <path
      fill="#d43d3d"
      d="M9 1.03a8 8 0 1 0 8 8 8 8 0 0 0-8-8zM10 13H8v-2h2zm0-3H8V5h2z"
      data-name="Path 1089"
    ></path>
  </svg>
);

export const Download = (
  <svg
    className="comparisonBtnIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 19.208 20"
  >
    <g transform="translate(.5 .5)">
      <path
        d="M17.021 22.542H1.188A1.189 1.189 0 0 1 0 21.354V17.4a.4.4 0 1 1 .792 0v3.958a.4.4 0 0 0 .4.4h15.829a.4.4 0 0 0 .4-.4V17.4a.4.4 0 1 1 .792 0v3.958a1.189 1.189 0 0 1-1.192 1.184z"
        style={{ fill: "#fff", stroke: "#fff" }}
        data-name="Path 1109"
        transform="translate(0 -3.542)"
      ></path>
      <path
        d="M11.4 15.833a.4.4 0 0 1-.4-.4V.4a.4.4 0 1 1 .792 0v15.038a.4.4 0 0 1-.392.395z"
        style={{ fill: "#fff", stroke: "#fff" }}
        data-name="Path 1110"
        transform="translate(-2.292)"
      ></path>
      <path
        d="M10.146 18.542a.4.4 0 0 1-.28-.116l-4.75-4.75a.4.4 0 1 1 .56-.56l4.47 4.47 4.47-4.47a.4.4 0 1 1 .56.56l-4.75 4.75a.391.391 0 0 1-.279.116z"
        style={{ fill: "#fff", stroke: "#fff" }}
        data-name="Path 1111"
        transform="translate(-1.042 -2.708)"
      ></path>
    </g>
  </svg>
);

export const BackArrow = (
  <svg
    style={{
      width: "15px",
      height: "15px",
      marginBottom: "1px",
      marginRight: "-1px",
    }}
    xmlns="http://www.w3.org/2000/svg"
    className="backSvg"
    viewBox="0 0 24 24"
    fill="#7a7d80"
  >
    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
