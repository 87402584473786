export const companyName = [
  "3 M GULF LTD",
  "3I INFOTECH",
  "3M INDIA LTD",
  "8K MILES SOFTWARE SERVICES LTD",
  "A&A DUKAAN FINANCIAL SERVICES PVT LTD",
  "A&A DUKAAN INSURANCE WEB AGGREGATOR PVT LTD",
  "A2ZMES (A2X MAITAINENCE AND ENGINEERING SERVICES )",
  "AARTI INDUSTRIES LTD",
  "ABAN OFFSHORE LTD",
  "ABB INDIA LTD",
  "ABBOTT INDIA LTD",
  "ABG SHIPYARD LTD",
  "ABU DHABI INVESTMENT AUTHORITY",
  "ABU DHABI INVESTMENT CO",
  "ABU DHABI ISLAMIC BANK",
  "ABUDABI NATIONAL OIL CO",
  "ACC LTD",
  "ACCENTURE",
  "ACER INDIA ",
  "ADANI AGRIFRESH",
  "ADANI AGRO LOGISTICS",
  "ADANI ENTERPRISES LTD",
  "ADANI GAS",
  "ADANI GLOBAL INDIA PVT LTD",
  "ADANI LOGISTICS",
  "ADANI MINING",
  "ADANI PORTS & SPECIAL ECONOMIC ZONE LTD",
  "ADANI POWER LTD",
  "ADANI REALTY",
  "ADANI SOLAR",
  "ADANI TRANSMISSION LTD",
  "ADANI WILMAR",
  "ADCB BANK",
  "ADHUNIK METALIKS LTD",
  "ADITYA BIRLA  NUVO",
  "ADITYA BIRLA CAPITAL ADVISORS PRIVATE LIMITED",
  "ADITYA BIRLA CHEMICALS (INDIA) LIMITED",
  "ADITYA BIRLA CHEMICALS (THAILAND) LIMITED",
  "ADITYA BIRLA FASHION & RETAIL LIMITED",
  "ADITYA BIRLA FASHION AND RETAIL LTD",
  "ADITYA BIRLA FINANCE LIMITED",
  "ADITYA BIRLA FINANCIAL SERVICES GROUP (ABFSG)",
  "ADITYA BIRLA HEALTH",
  "ADITYA BIRLA INSURANCE BROKERS",
  "ADITYA BIRLA MINERALS",
  "ADITYA BIRLA MONEY LIMITED",
  "ADITYA BIRLA NUVO LTD",
  "ADITYA BIRLA ONLINE FASHION PRIVATE LIMITED",
  "ADITYA BIRLA RETAIL",
  "ADITYA BIRLA SCIENCE AND TECHNOLOGY COMPANY LIMITED",
  "ADITYA MEDISALES ",
  "ADNOC ( ABU DHABHI NATIONAL OIL COMPANY ",
  "ADOBE INDIA",
  "ADVANCED ENZYME TECHNOLOGIES LTD",
  "ADVINUS THERAPEUTICS",
  "AEGIS LOGISTICS LTD",
  "BANDHAN LIFE INSURANCE CO. LTD",
  "AFCONS INFRASTRUCTURE ",
  "AGRICULTURE INSURANCE CORPORATION",
  "AHLUWALIA CONTRACTS LTD",
  "AIA ENGINEERING LTD",
  "AIRTEL AFRICA & SOUTH ASIA",
  "AIRTEL PAYMENTS BANK",
  "AJANTA PHARMA LIMITED",
  "AKZO NOBEL INDIA LTD",
  "AL ABBAS GROUP",
  "AL ABEER MEDICAL CENTRE",
  "AL ABERAH ELECTRICAL & MECHANICAL WORKS",
  "AL FUTTAIM",
  "AL FUTTAIM ENGINEERING",
  "AL FUTTAIM GROUP",
  "AL FUTTAIM MOTORS",
  "AL JANAHI  GROUP",
  "AL MUHANDIS GROUP",
  "AL NABOODAH CONSTRUCTION  GROUP LLC",
  "AL RAFA HOSPITAL",
  "AL SAGR NATIONAL INSURANCE COMPANY",
  "AL TAYER GROUP",
  "AL TAYER MOTORS",
  "ALBONAIR GMBH, GERMANY",
  "ALEMBIC PHARMACEUTICALS LTD",
  "ALEXANDRIA CARBON BLACK COMPANY SAE",
  "ALEXANDRIA FIBRE COMPANY SAE",
  "ALFA LAVAL ",
  "ALKEM LABORATORIES LTD",
  "ALLAHABAD BANK",
  "ALLANASONS  PVT LTD",
  "ALLCARGO  GLOBAL LOGISTICS LTD",
  "ALLIED DIGITAL",
  "ALOK INDUSTRIES LTD",
  "ALSTOM PROJECTS  INDIA LTD",
  "ALSTOM T&D INDIA LTD",
  "AMARA RAJA BATTERIES LTD",
  "AMAZON",
  "AMBER ENTERPRISES INDIA",
  "AMBUJA CEMENTS LTD",
  "AMERICAN HOSPITAL",
  "AMIRA PURE FOODS ",
  "AMLAK FINANCE",
  "AMTEK AUTO LTD",
  "AMTEK INDIA LTD",
  "ANAND RATHI ADVISORS LTD.",
  "ANAND RATHI CAPITAL ADVISORS PRIVATE LIMITED",
  "ANAND RATHI COMMODITIES LIMITED",
  "ANAND RATHI FINANCIAL SERVICES LIMITED",
  "ANAND RATHI GLOBAL FINANCE LIMITED",
  "ANAND RATHI INSURANCE BROKERS LTD.",
  "ANAND RATHI MARKETING SERVICES PRIVATE LTD.",
  "ANAND RATHI SHARE AND STOCK BROKERS LTD. ",
  "ANAND RATHI WEALTH ADVISORS LIMITED",
  "ANAND RATHI WEALTH SERVICES LIMITED",
  "ANANDRATHI HOUSING FINANCE LIMITED",
  "ANANT RAJ LTD",
  "ANDHRA BANK",
  "ANGEL BROKING PVT LTD",
  "ANGEL FINANCIAL ADVISORS PRIVATE LIMITED",
  "ANSAL INFRAS",
  "APAR INDUSTRIES LTD",
  "APL APOLLO TUBES LTD",
  "APOLLO HOSPITALS ENTERPRISE LTD",
  "APOLLO MUNICH ",
  "APOLLO TYRES LTD",
  "APTECH LTD",
  "AQUA LOGIST",
  "AR TRUSTEE COMPANY PVT. LTD.",
  "AR WEALTH MANAGEMENT PVT. LTD.",
  "AREVA",
  "ARIBA INDIA PVT. LTD.",
  "ARIBA TECHNOLOGIES INDIA PVT. LTD.",
  "ARSHYIA INTL",
  "ARSS INFRA",
  "ARVIND LIMITED",
  "ASAHI INDIA GLASS LTD",
  "ASHOK LEYLAND LTD",
  "ASHOK LEYLAND WIND ENERGY LTD",
  "ASHOKA BUILDCON LTD",
  "ASIAN COLOUR COATED ISPAT ",
  "ASIAN PAINTS LTD",
  "ASIAN STAR COMPANY LTD",
  "ASSAMCO",
  "ASSOCIATED BUILDING COMPANY",
  "ASTER DM HEALTHCARE",
  "ASTRA MICROWAVE PRODUCTS LTD.",
  "ASTRA ZENECA PHARMA INDIA (P) LIMITED",
  "ASTRAL POLYTECHNIK LTD",
  "ATLAS COPCO (INDIA) ",
  "ATUL LTD",
  "AU INSURANCE BROKING SERVICES PVT LTD",
  "AU SMALL FINANCE BANK",
  "AUROBINDO PHARMA LTD",
  "AVADH SUGAR & ENERGY",
  "AVANTI FEEDS LTD",
  "AVENTIS PHARMA LTD",
  "AVENUE SUPERMARTS LTD",
  "AVIVA LIFE INSURANCE COMPANY INDIA LIMITED",
  "AXIS BANK",
  "AXIS BANK LTD",
  "AXLES INDIA LIMITED",
  "B L KASHYAP & SONS LTD",
  "BACHHRAJ & COMPANY PVT. LTD.",
  "BACHHRAJ FACTORIES PVT. LTD.",
  "BADUR TRAVELS",
  "BAJAJ ALLIANZ GENERAL INSURANCE COMPANY LTD.",
  "BAJAJ ALLIANZ LIFE INSURANCE COMPANY LTD.",
  "BAJAJ AUTO FINANCE LTD.",
  "BAJAJ AUTO HOLDINGS LTD.",
  "BAJAJ AUTO LTD",
  "BAJAJ CAPITAL FINANCIAL SERVICES LTD",
  "BAJAJ CAPITAL INSURANCE BROKING LTD.",
  "BAJAJ CAPITAL INVESTMENT CENTRE LTD",
  "BAJAJ CAPITAL LTD",
  "BAJAJ CORP LTD",
  "BAJAJ ELECTRICALS LTD",
  "BAJAJ FINANCE LTD",
  "BAJAJ HINDUSTAN LTD",
  "BAJAJ HINDUSTHAN SUGAR LTD",
  "BAJAJ HOLDINGS  &  INVESTMENTS",
  "BAJAJ HOLDINGS & INVESTMENT LTD.",
  "BAJAJ HOLDINGS AND INVESTMENT LTD",
  "BAJAJ INTERNATIONAL PVT. LTD.",
  "BAJAJ SEVASHRAM PVT. LTD.",
  "BAJAJ VENTURES LTD.",
  "BAJAJHINDLTD",
  "BAJFINANCE",
  "BALKRISHNA INDUSTRIES LTD",
  "BALLARPUR INDUSTRIES LTD",
  "BALMER LAWRIE & COMPANY LTD",
  "BALRAMPUR CHINI MILLS LTD",
  "BANDHAN BANK",
  "BANGALORE ELECTRICITY SUPPLY CO ",
  "BANK MELLI IRAN",
  "BANK OF  MAHARASHTRA",
  "BANK OF AMERICA",
  "BANK OF BARODA",
  "BANK OF INDIA",
  "BARCLAYS BANK",
  "BARJEEL GEOJIT SECURITIES  LLC DUBAI",
  "BARODA INDUSTRIES PVT. LTD.",
  "BARTRONICS INDIA LTD",
  "BASF INDIA LIMITED",
  "BATA INDIA LTD",
  "BAYER CROPSCIENCE LTD",
  "BBK GEOJIT SECURITIES KSC",
  "BEETEL TELETECH",
  "BELHASA PROJECTS",
  "BEML LTD",
  "BENNETT, COLEMAN & CO ",
  "BERGER PAINTS (INDIA) LTD",
  "BERNHARD SCHULTE SHIP MGMENT (INDIA) ",
  "BF UTILITIES LIMITED",
  "BGR ENERGY SYSTEMS LTD",
  "BHARAT BIJLI",
  "BHARAT DYNAMICS ",
  "BHARAT ELECTRONICS LTD",
  "BHARAT FINANCIAL INCLUSION LTD",
  "BHARAT FORGE LTD",
  "BHARAT HEAVY ELECTRICALS LTD",
  "BHARAT PETROLEUM CORPORATION LTD",
  "BHARAT SANCHAR NIGAM ",
  "BHARATI SHIP",
  "BHARTI (SBM) HOLDINGS PVT LTD",
  "BHARTI (SBM) RESOURCES PVT LTD",
  "BHARTI (SBM) SERVICES PVT LTD",
  "BHARTI AIRTEL LTD",
  "BHARTI AXA GENERAL INSURANCE",
  "BHARTI AXA LIFE INSURANCE",
  "BHARTI ENTERPRISES (HOLDING) PVT LTD",
  "BHARTI INFRATEL LTD",
  "BHARTI OVERSEAS PVT LTD",
  "BHARTI REALTY",
  "BHARTI RETAIL ",
  "BHARTI TELECOM LTD",
  "BHEL",
  "BHUSHAN POWER & STEEL ",
  "BHUSHAN STEEL LTD",
  "BILCARE LTD",
  "BINANI CEMENT ",
  "BINANI INDUSTRIES LTD",
  "BIOCON LTD",
  "BIRLA CORPORATION LTD",
  "BIRLA JINGWEI FIBRES COMPANY LIMITED",
  "BIRLA LAOS PULP AND PLANTATIONS COMPANY LIMITED",
  "BIRLA SUN LIFE ASSET MANAGEMENT COMPANY LTD",
  "BIRLA SUNLIFE LIFE INSURANCE COMPANY",
  "BL KASHYAP",
  "BLISS GVS PHARMA LTD",
  "BLUE DART EXPRESS LTD",
  "BLUE STAR LTD",
  "BLUECHIP CORPORATE INVESTMENT CENTRE LTD",
  "BLUECHIP INSURANCE BROKING PRIVATE LTD",
  "BMW INDIA ",
  "BNP PARIBAS",
  "BOC INDIA LT",
  "BOMBAY BURMAH TRADING CORP LTD.",
  "BOMBAY DYEING & MANUFACTURING COMPANY",
  "BOMBAY RAYON FASHIONS LTD",
  "BOMBAY REALTY",
  "BOSCH LTD",
  "BOSTON CONSULTANCY GROUP",
  "BP MIDDLE EAST",
  "BRAKES INDIA  LTD",
  "BRIDGESTONE INDIA ",
  "BRIGADE",
  "BRIGADE ENTERPRISES",
  "BRIGHTCOM GROUP",
  "BRITANNIA INDUSTRIES LTD.",
  "BRITISH PETROLEUM",
  "BS LTD",
  "BUNGE INDIA LIMITED",
  "C MAHENDRA EXPORTS LTD",
  "CADILA HEALTHCARE LTD",
  "CAIRN INDIA LTD",
  "CAN FIN HOMES LTD",
  "CANARA BANK",
  "CANARA HSBC ORIENTAL BANK OF COMMERCE LIFE INSURANCE CO. LTD.",
  "CANON INDIA",
  "CAPGEMINI",
  "CAPITAL FIRST LTD.",
  "CAPITAL SMALL FINANCE BANK LTD",
  "CAPLIN POINT LABORATORIES LTD",
  "CAPRI GLOBAL CAPITAL LTD",
  "CARBORUNDUM UNIVERSAL LTD",
  "CARE RATINGS LTD",
  "CARGILL INDIA PRIVATE ",
  "CARREFOUR",
  "CASA DECOR",
  "CASTROL INDIA LTD",
  "CATERPILLAR INDIA ",
  "CATHOLIC SYRIAN BANK LTD",
  "CCL PRODUCTS (INDIA) LTD.",
  "CEAT LTD",
  "CELEBRATIONS APPAREL LTD. ",
  "CENTRAL BANK OF INDIA",
  "CENTRUM CAPITAL LTD",
  "CENTUM LEARNING",
  "CENTURY ENKA LTD",
  "CENTURY PLYBOARDS INDIA LTD",
  "CENTURY TEXTILE & INDUSTRIES LTD",
  "CENTURY TEXTILES & INDUSTRIES LTD",
  "CERA SANITARYWARE LTD",
  "CESC LTD",
  "CG POWER AND INDUSTRIAL SOLUTIONS LTD",
  "CHAMBAL FERTILIZERS & CHEMICALS LTD",
  "CHENNAI PETROLEUM CORP LTD",
  "CHENNAI PETROLEUM CORPORATION LTD",
  "CHINA NATIONAL PETROLIUM CORPORATION",
  "CHOLAMANDALAM INVESTMENT & FINANCE CO. LTD.",
  "CIGNA TTK",
  "CIPLA LTD",
  "CITI BANK",
  "CITY EXCHANGE",
  "CITY UNION BANK LTD",
  "CLEARTRIP",
  "CMC LTD",
  "COAL INDIA LTD",
  "COCA-COLA INDIA ",
  "COFFEE DAY ENTERPRISES LTD",
  "COFFEE DAY GLOBAL LTD.",
  "COFFEE DAY HOTELS AND RESORTS PVT. LTD.",
  "COFFEE DAY TRADING LTD.",
  "COGNIZANT TECHNOLOGIES",
  "COGNIZANT TECHNOLOGY SOLUTIONS INDIA ",
  "COLGATE-PALMOLIVE INDIA LTD",
  "COLORPLUS FASHIONS LTD. ",
  "COMMERICAL BANK OF DUBAI",
  "CONSOLIDATED CONSTRUCTION CONSORTIUM",
  "CONTAINER CORP OF INDIA LTD",
  "CORE EDUCATION & TECHNOLOGIES LTD",
  "CORE PROJECT",
  "CORETREE INSURANCE BROKERS (INDIA) PRIVATE LIMITED",
  "COROMANDEL INTERNATIONAL LTD",
  "CORPORATION BANK",
  "COSMO FILMS",
  "COVERFOX INSURANCE BROKING PVT LTD",
  "COX & KINGS LTD",
  "CREDIT ANALYSIS AND RESEARCH LIMITED",
  "CREDIT SUISSE",
  "CRISIL LIMITED",
  "CROMPTON GREAVES CONSUMER ELECTRICALS LTD",
  "CROMPTON GREAVES LTD",
  "CUMMINS INDIA LTD",
  "CYIENT LTD",
  "D B CORP LIMITED",
  "D B REALTY",
  "DABUR INDIA LTD",
  "DAHEJ HARBOUR & INFRASTRUCTURE LIMITED",
  "DAKSHIN GUJARAT VIJ CO ",
  "DALMIA BHARAT LTD",
  "DALMIA BHARAT SUGAR & INDUSTRIES",
  "DAMAC PROPERTIES",
  "DAMAS JEWELLERY",
  "DAMODAR VALLEY CORPORATION ",
  "DBS",
  "DCB BANK LIMITED",
  "DCM SHRIRAM LTD",
  "DECCAN CHRONICLE HOLDING  LTD",
  "DEEPAK FERTILISERS & PETROCHEMICALS CORP. LTD.",
  "DEEPAK NITRITE",
  "DEFENCE LAND SYSTEMS",
  "DELHI METRO RAIL CORPORATION ",
  "DELL",
  "DELOITTE",
  "DELPHI-TVS ",
  "DELTA CORP",
  "DELTA CORP LIMITED",
  "DEN NETWORKS LIMITED",
  "DENA BANK",
  "DEPA INTERIORS",
  "DESCO COPY & PRINT CENTRE",
  "DEUTSCHE BANK",
  "DEV CREDIT BANK ( DCB BANK )",
  "DHAMPUR SUGAR MILLS LTD",
  "DHANLAKSHMI  BANK",
  "DHANUKA AGRITECH LTD",
  "DHARAMPAL SATYAPAL ",
  "DHFL PRAMERICA LIFE INSURANCE CO. LTD.",
  "DHUNSERI INVESTMENTS",
  "DHUNSERI PETROCHEM LTD",
  "DIAMOND POWER INFRASTRUCTURE LTD",
  "DILIP BUILDCON LTD",
  "DISH TV INDIA LTD",
  "DISHA FINANCIAL COUNSELLING",
  "DISHMAN PHAR",
  "DIVI'S LABORATORIES LTD",
  "DIXON TECHNOLOGIES (INDIA)",
  "DLF LTD",
  "DNATA EMIRATES AIRLINES",
  "DOHA BANK",
  "DOOSAN POWER SYSTEMS INDIA ",
  "DOW CHEMICAL INTERNATIONAL ",
  "DR REDDY'S LABORATORIES LTD",
  "DR. LAL PATHLABS LTD",
  "DREDGING  CORPORATION OF INDIA",
  "DRIVE INDIA ENTERPRISE SOLUTIONS",
  "DRREDDY'S LABORATORIES LIMITED",
  "DU TELECOMMUNICATION",
  "DUBAI AIRPORT",
  "DUBAI BANK",
  "DUBAI ELECTRICITY AND WATER AUTHORITY",
  "DUBAI ISLAMIC BANK",
  "DUBAI MNC HOSPITAL",
  "DUBAI NATIONAL INSURANCE",
  "DUBAI PETROLEUM",
  "DUNLOP AUTO TYRES PVT. LTD., INDIA",
  "DUNLOP INDIA LTD., INDIA ",
  "DUNLOP POLYMERS PVT. LTD., INDIA",
  "E I DUPONT INDIA ",
  "E-NXT FINANCIALS",
  "EASTERN POWER DISTRIBUTION CO",
  "EASYPOLICY INSURANCE WEB AGGREGATORS PVT LTD",
  "ECGC ( EXPORT CREDIT GUARANTEE CORPORATION )",
  "ECLERX SERVICES LIMITED",
  "ECLERX SERVICES LTD",
  "EDELWEISS CAPITAL",
  "EDELWEISS FINANCIAL SERVICES LTD",
  "EDELWEISS TOKIO LIFE INSURANCE CO. LTD.",
  "EDUCOMP SOLUTION LTD",
  "EEW GULF STEEL",
  "EIC EXHIBIT WORKS LLC",
  "EICHER MOTORS LTD",
  "EID PARRY (INDIA) LTD",
  "EIH LTD",
  "ELECON ENGINEERING COMPANY LTD",
  "ELECTROSTEEL CASTINGS LTD",
  "ELECTROTHERM (INDIA)",
  "ELGI EQUIPMENTS LTD.",
  "EMAAR PROPERTIES",
  "EMAMI BIOTECH ",
  "EMAMI LTD",
  "EMARAT",
  "EMC ",
  "EMIRATES AIRLINES",
  "EMIRATES AYURVEDIC CENTRE",
  "EMIRATES BANK",
  "EMIRATES CEMENT",
  "EMIRATES ISLAMIC BANK",
  "EMIRATES STEEL",
  "EMKAY GLOBAL FINANCIAL SERVICES LIMITED",
  "EMKAY INSURANCE BROKERS LIMITED",
  "EMMSONS INTERNATIONAL LTD",
  "EMTEL",
  "ENDURANCE TECHNOLOGIES LTD",
  "ENGINEERS INDIA LTD",
  "ENT NETWORK",
  "ENTERTAINMENT NETWORK INDIA",
  "EPC MAHINDRA",
  "EQUITAS HOLDINGS LTD",
  "EQUITAS SMALL FINANCE BANK LIMITED",
  "ERA INFRA ENGINEERING LTD",
  "ERICSON INDIA PVT LTD",
  "ERIS LIFESCIENCES LTD",
  "ERNST AND YOUNG",
  "EROS INTERNATIONAL MEDIA LTD",
  "ESAF SMALL FINANCE BANK",
  "ESCORTS LTD",
  "ESS DEE ALUM",
  "ESSAR OIL LTD",
  "ESSAR PROJECTS INDIA ",
  "ESSAR SHIPPING LTD",
  "ESSAR STEEL ",
  "ESSEL FINANCE AMC LIMITED",
  "ESSEL FINANCE WEALTH SERVICES PRIVATE LIMITED",
  "ESSEL MINING AND INDUSTRIES LIMITED",
  "ESSEL PROPACK LTD",
  "ETISALAT",
  "EVERBLUE APPAREL LTD. ",
  "EVEREADY INDUSTRIES INDIA LTD",
  "EVEREST KANT",
  "EVERONN",
  "EXIDE INDUSTRIES LTD",
  "EXIDE LIFE INSURANCE CO. LTD.",
  "EXPORT-IMPORT BANK OF INDIA",
  "EZDAN HOLDING GROUP",
  "FACEBOOK",
  "FAG BEARINGS INDIA LTD",
  "FALCON TYRES LTD., INDIA",
  "FDC LIMITED",
  "FEDERAL BANK LTD",
  "FERTILISERS AND CHEMICALS TRAVANCORE",
  "FIAT INDIA AUTOMOBILES ",
  "FIBRE FOAM (BOMBAY) PVT. LTD. ",
  "FIELDFRESH FOODS",
  "FILATEX INDIA",
  "FINANCIAL  TECHNOLOGY",
  "FINCARE SMALL FINANCE BANK",
  "FINO FINANCE PRIVATE LIMITED",
  "FINO PAYMENT BANK LIMITED",
  "FINO PAYTECH LIMITED",
  "FINOLEX CABLES LTD",
  "FINOLEX INDUSTRIES LTD",
  "FIRESTONE TVS PRIVATE LIMITED ",
  "FIRST GULF BANK",
  "FIRSTSOURCE SOLUTIONS LTD",
  "FKONCO",
  "FLIPKART",
  "FLIPKART INDIA ",
  "FORBES & COMPANY LTD",
  "FORCE MOTORS LTD",
  "FORD INDIA ",
  "FORTIS HEALTHCARE LTD",
  "FRIGERIO CONSERVA ALLANA ",
  "FSL",
  "FULLERTON INDIA CREDIT COMPANY LIMITED",
  "FULLERTON INDIA HOME FINANCE COMPANY LIMITED",
  "FUTURE BRANDS",
  "FUTURE CAP",
  "FUTURE CAPITAL HOLDINGS (FOR INTERNAL FINANCIAL SERVICES)",
  "FUTURE CONSUMER LIMITED",
  "FUTURE ENTERPRISES LIMITED",
  "FUTURE GENERALI GENERAL INSURANCE",
  "FUTURE GENERALI LIFE INSURANCE",
  "FUTURE INNOVERSITY",
  "FUTURE LEARNING",
  "FUTURE LIFESTYLE FASHION LTD",
  "FUTURE LIFESTYLE FASHIONS LIMITED",
  "FUTURE RETAIL LTD",
  "FUTURE SUPPLY CHAINS",
  "FUTURE VENTURES",
  "GAIL (INDIA) LTD",
  "GAIL INDIA LTD",
  "GALAXY SURFACTANTS",
  "GAMMON INDIA LTD",
  "GAMMON INFRA",
  "GANNON DUNKERLEY & CO ",
  "GARDEN SILK MILLS LTD",
  "GASCO",
  "GATEWAY DIST",
  "GATEWAY DISTRIPARKS LIMITED",
  "GATEWAY DISTRIPARKS LTD",
  "GATI LTD",
  "GAYATRI PROJECTS LTD",
  "GE POWER INDIA LTD",
  "GE T&D INDIA LTD",
  "GENERAL TRADING & EQUIPMENT CO",
  "GENNEXT INSURANCE BROKERS PVT LTD",
  "GENPACT INDIA ",
  "GEOD LTD",
  "GEOJIT CREDITS PVT LTD",
  "GEOJIT FINANCIAL SERVICES",
  "GEOJIT TECHNOLOGIES PVT LTD",
  "GFL",
  "GHCL LTD",
  "GIC HOUSING FINANCE LTD",
  "GIC OF INDIA",
  "GILLETTE INDIA LTD",
  "GITANJALI GEMS LTD",
  "GLAXOSMITHKLINE CONSUMER HEALTHCARE LTD",
  "GLAXOSMITHKLINE PHARMACEUTICALS LTD",
  "GLENMARK PHARMACEUTICALS LTD",
  "GLOBAL GAS & OIL",
  "GLODYNE TECHNOSERVE LTD",
  "GMR INFRASTRUCTURE LTD",
  "GNFC",
  "GO AIRLINES INDIA  PVT LTD",
  "GODAWARI POWER & ISPAT LTD",
  "GODFREY PHILIPS INDIA LTD",
  "GODREJ & BOYCE MANUFACTURING CO ",
  "GODREJ AGROVET ",
  "GODREJ CONSUMER PRODUCTS LTD",
  "GODREJ INDUSTRIES LTD",
  "GODREJ PROPERTIES LTD",
  "GOIBIBO",
  "GOKUL AGRO RESOURCES",
  "GOKUL REFOILS & SOLVENT LTD",
  "GOODYEAR INDIA LTD",
  "GOOGLE",
  "GOOGLE INDIA ",
  "GOURMET INVESTMENTS",
  "GPPL",
  "GRANULES INDIA LTD",
  "GRAPHITE INDIA LTD.",
  "GRASIM INDUSTRIES LTD",
  "GREAT EASTERN SHIPPING COMPANY LTD",
  "GREAT OFFSHORE LIMITED",
  "GREAVES COTTON LTD",
  "GREENPLY INDUSTRIES LTD.",
  "GRINDWELL NORTON LTD",
  "GRUH FINANCE LIMITED",
  "GRUH FINANCE LTD.",
  "GSFC",
  "GSK CONSUMER HEALTHCARE",
  "GSK PHARMACEUTICALS",
  "GTL INFRAST",
  "GTL LTD",
  "GUJ MIN DEVL",
  "GUJ NARMADA",
  "GUJ PETRONET",
  "GUJARA NRE COKE LTD",
  "GUJARAT ALKALIES & CHEMICALS LTD",
  "GUJARAT AMBUJA EXPORTS LTD",
  "GUJARAT ENERGY TRANSMISSION CORP ",
  "GUJARAT FLUOROCHEMICALS LTD",
  "GUJARAT GAS COMPANY LTD",
  "GUJARAT INDUSTRIES POWER COMPANY LTD",
  "GUJARAT MINERAL DEVELOPMENT CORP LTD",
  "GUJARAT MINERAL DEVELOPMENT CORPORATION",
  "GUJARAT NARMADA VALLEY FERTILIZERS AND CHEMICALS LIMITED",
  "GUJARAT PIPAVAV PORT LTD",
  "GUJARAT STATE FERTILISERS & CHEMICALS LTD",
  "GUJARAT STATE PETROLEUM CORPORATION ",
  "GUJARAT STATE PETRONET LTD",
  "GUJARAT URJA VIKAS NIGAM ",
  "GUJARATH  IND  POWER",
  "GULF OIL CORPORATION LTD",
  "GULF OIL LUBRICANTS INDIA LIMITED",
  "GULF OIL LUBRICANTS INDIA LTD",
  "GULF OIL MIDDLE EAST LTD",
  "GULF STALLION GENERAL TRADING LLC",
  "GVK POWER & INFRASTRUCTURE LTD",
  "HALDIA PETROCHEMICALS ",
  "HANNOVER RE ",
  "HANUNG TOYS & TEXTILES LTD",
  "HARI KRISHNA EXPORTS ",
  "HARMAN MIDDLE EAST",
  "HARRISONS MALAYALAM LIMITED",
  "HATHWAY CABLES AND DATACOM LTD",
  "HATSUN AGRO PRODUCTS LTD",
  "HAVELLS INDIA LTD",
  "HBL POWER SYSTEMS LTD",
  "HCL INFOSYSTEMS LTD",
  "HCL TECHNOLOGIES LTD",
  "HDFC BANK LTD",
  "HDFC LTD",
  "HDFC STANDARD LIFE INSURANCE CO. LTD?",
  "HEG LTD",
  "HEIDEL CEM",
  "HEIDELBERGCEMENT INDIA LTD.",
  "HERCULES HOISTS LTD.",
  "HERITAGE FOODS LTD",
  "HERO CORPORATE SERVICE PRIVATE LTD",
  "HERO CYCLES LTD",
  "HERO FINCORP LTD",
  "HERO MOTOCORP LTD",
  "HETERO LABS ",
  "HEWLETT PACKARD",
  "HEWLETT-PACKARD GLOBALSOFT ",
  "HEWLETT-PACKARD INDIA SALES ",
  "HEXAWARE TECHNOLOGIES LTD",
  "HIKE GLOBAL LIMITED",
  "HIL",
  "HIM FUTR COM",
  "HIMACHAL FUTURISTIC COMMUNICATIONS LTD",
  "HIMADRI  CHEMICLALS  SPECIALITY LTD ",
  "HIMADRI SPECIALITY CHEMICAL",
  "HIMATSINGKA SEIDE LTD",
  "HIND LAMPS LTD.",
  "HIND MUSAFIR AGENCY LTD.",
  "HINDALCO INDUSTRIES LTD",
  "HINDUJA BANK (SWITZERLAND) LTD",
  "HINDUJA FOUNDRIES LTD",
  "HINDUJA GLOBAL SOLUTIONS LTD",
  "HINDUJA GROUP INDIA LIMITED",
  "HINDUJA HEALTHCARE LTD",
  "HINDUJA LEYLAND FINANCE LTD",
  "HINDUJA NATIONAL POWER CORPORATION LTD",
  "HINDUJA REALTY VENTURES LTD",
  "HINDUJA TECH LIMITED (FORMERLY DEFIANCE TECHNOLOGIES LIMITED)",
  "HINDUJA VENTURES LTD",
  "HINDUS CONST",
  "HINDUSTAN .OIL EXP",
  "HINDUSTAN AERONAUTICS ",
  "HINDUSTAN COCA-COLA BEVERAGES ",
  "HINDUSTAN CONSTRUCTION COMPANY LTD",
  "HINDUSTAN COPPER LTD",
  "HINDUSTAN COPPER LTD",
  "HINDUSTAN PETROLEUM CORPORATION LTD",
  "HINDUSTAN UNILEVER LIMITED",
  "HINDUSTAN ZINC LTD",
  "HINDUSTAN.ZINC",
  "HINDUSTHAN NATIONAL GLASS & INDUSTRIES",
  "HMT LTD",
  "HONDA CARS INDIA ",
  "HONDA MOTORCYCLE & SCOOTER INDIA ",
  "HONEYWELL",
  "HONEYWELL AUTOMATION INDIA LTD",
  "HOOGHLY MET COKE AND POWER COMPANY",
  "HOTEL LEELA VENTURES LTD",
  "HOUGHTON INTERNATIONAL",
  "HOUSING AND URBAN DEVELOPMENT CORPORATION LTD",
  "HOUSING DEVELOPMENT & INFRASTRUCTURE LTD",
  "HOUSING DEVELOPMENT FINANCE CORPORATION",
  "HP ",
  "HSBC",
  "HSBC ELECTRONIC DATA PROCESSING INDIA ",
  "HSBC SOFTWARE DEVELOPMENT INDIA ",
  "HSIL LTD",
  "HT MEDIA",
  "HUBLI ELECTRICITY SUPPLY CO ",
  "HUDCO",
  "HUHTAMAKI PPL",
  "HUNTSMAN INTERNATIONAL INDIA ",
  "HYUNDAI MOTOR INDIA ",
  "I T C LTD",
  "I.C.S.A. IND",
  "IB SECURITIE",
  "IBM",
  "IBM DAKSH BUSINESS PROCESS SERVICES ",
  "IBM INDIA ",
  "IBN18",
  "ICICI BANK LTD",
  "ICICI DIRECT",
  "ICICI FOUNDATION",
  "ICICI HOME FINANCE COMPANY",
  "ICICI LOMBARD GENERAL INSURANCE COMPANY",
  "ICICI PRUDENTIAL ASSET MANAGEMENT CO",
  "ICICI PRUDENTIAL LIFE INSURANCE COMPANY LTD",
  "ICICI SECURITIES",
  "ICICI SECURITIES PRIMARY DEALERSHIP",
  "ICICI VENTURE",
  "ICRA LIMITED",
  "ICRA LTD.",
  "IDBI BANK LTD",
  "IDBI FEDERAL LIFE INSURANCE CO. LTD.",
  "IDEA CELLULAR LTD",
  "IDFC BANK LTD",
  "IDFC LIMITED",
  "IDL SPECIALTY CHEMICALS LTD",
  "IFB INDUSTRIES",
  "IFCI LTD",
  "IGATE GLOBAL SOLUTIONS ",
  "IIFL ASSET MANAGEMENT COMPANY LTD",
  "IIFL HOLDINGS LTD",
  "IL&FS ENGINEERING & CONSTRUCTION COMPANY",
  "IL&FS TRANSPORTATION NETWORKS LTD",
  "IND INFOLINE",
  "INDIA CEMENTS LTD",
  "INDIA GLYCOLS LTD",
  "INDIA INFOLINE FINANCE LTD",
  "INDIA INFOLINE HOUSING FINANCE LTD",
  "INDIA INFOLINE INSURANCE BROKERS LTD",
  "INDIA INFOLINE LTD",
  "INDIA INFOLINE SECURITIES LTD",
  "INDIA INFOLINE WEALTH MANAGEMENT LTD",
  "INDIA INFOLINE WEALTH NBFC",
  "INDIA INFRASTRUCTURE FINANCE COMPANY",
  "INDIA JAPAN LIGHTING PRIVATE LIMITED",
  "INDIA MOTOR PARTS AND ACCESSORIES LIMITED",
  "INDIA NIPPON ELECTRICALS LIMITED",
  "INDIA TYRE & RUBBER COMPANY (INDIA) LIMITED, INDIA",
  "INDIA YAMAHA MOTOR ",
  "INDIAB POWER",
  "INDIABULLS HOUSING FINANCE LIMITED",
  "INDIABULLS HOUSING FINANCE LTD",
  "INDIABULLS REAL ESTATE LTD",
  "INDIABULLS VENTURES LTD",
  "INDIAFIRST LIFE INSURANCE CO. LTD.",
  "INDIAN BANK",
  "INDIAN FARMERS FERTILIZER COOPERATIVE (IFFCO)",
  "INDIAN HOTELS COMPANY LTD",
  "INDIAN METALS  AND FERRO ALLOYS LTD",
  "INDIAN OIL CORPORATION LTD",
  "INDIAN OVERSEAS BANK",
  "INDIAN POTASH  LTD ",
  "INDIAN STEEL AND WIRE PRODUCTS",
  "INDO COUNT INDUSTRIES LTD",
  "INDO RAMA SYNTHETICS (INDIA) LTD",
  "INDO SOLAR",
  "INDOCO REMEDIES LTD.",
  "INDRAPRASTHA GAS LTD",
  "INDUS TOWERS",
  "INDUSIND BANK LTD",
  "INDUSIND MEDIA AND COMMUNICATIONS LTD",
  "INDUSTRIES QATAR",
  "INFIBEAM INCORPORATION LTD",
  "INFINITE COMPUTER SOLUTIONS INDIA",
  "INFINITI RETAIL",
  "INFO EDGE (INDIA) LIMITED",
  "INFOSYS LTD",
  "INFOTECH ENR",
  "ING VYSYA BANK LTD",
  "INGERSOL RND",
  "INGERSOLL-RAND (INDIA) LTD",
  "INOX LEISURE",
  "INOX LINK FZCO",
  "INOX WIND LTD",
  "INTAS PHARMACEUTICALS ",
  "INTEGRATED ENTERPRISES (INDIA) PRIVATE LTD.",
  "INTEGRATED INSURANCE BROKING SERVICES PRIVATE LIMITED",
  "INTEL TECHNOLOGY INDIA ",
  "INTELLECT DESIGN ARENA LTD.",
  "INTERGLOBE AVIATION LTD",
  "INTERNATIONAL COLLEGE OF FINANCIAL PLANNING LTD",
  "INTERNATIONAL TRACTORS ",
  "INTEX TECHNOLOGIES (INDIA) ",
  "INZPERA HEALTH SCIENCES",
  "IOT INFRASTRUCTURE & ENERGY SERVICES ",
  "IPCA LABORATORIES LTD",
  "IRB INFRASTRUCTURE DEVELOPERS LTD",
  "IRIZAR TVS LIMITED",
  "ISGEC HEAVY ENGINEERING LTD",
  "ISMT LTD",
  "ISPAT INDUST",
  "ITC LTD",
  "ITD CEMENTATION INDIA LTD",
  "IVRCL LTD",
  "IVRCLAH",
  "J KUMAR INFRAPROJECTS LTD",
  "J P MORGAN",
  "J&K BANK",
  "J. K. TYRE INDUSTRIES LTD",
  "J.B.CHEMICALS & PHARMACEUTICALS LTD.",
  "J.K. CEMENT LTD.",
  "J.K. HELENE CURTIS LTD. ",
  "J.K. INVESTO TRADE (INDIA) LTD. ",
  "JAGRAN PRAKASHAN LTD",
  "JAGUAR LAND ROVER",
  "JAI BALAJI INDUSTRIES LTD",
  "JAI CORP LTD",
  "JAIBALA IND",
  "JAIN IRRIGATION SYSTEMS LTD",
  "JAIPRAKASH ASSOCIATES LTD",
  "JAIPRAKASH POWER VENTURES LTD",
  "JAMIPOL",
  "JAMMU & KASHMIR BANK LTD",
  "JAMNA AUTO INDUSTRIES",
  "JAMNALAL SONS PVT. LTD.",
  "JAMSHEDOUR UTILITIES & SERVICES CORP.",
  "JAMSHEDPUR CONTINUOUS ANNEALINGAND PROCESSING COMPANY",
  "JAMSHEDPUR UTILITIES AND SERVICS CO",
  "JANA SMALL FINANCE BANK",
  "JANALAKSHMI FINANCIAL SERVICES",
  "JAYANT AGRO ORGANICS LTD",
  "JAYASWAL NECO INDUSTRIES LTD",
  "JAYPEE INFRATECH LTD",
  "JBF INDUSTRIES LTD",
  "JCB INDIA ",
  "JEEVAN LTD.",
  "JERSEY AIRTEL LIMITED ",
  "JESSOP & COMPANY LTD.,INDIA",
  "JHAJJAR POWER ",
  "JINDAL FILMS",
  "JINDAL INDIA",
  "JINDAL INDIA THERMAL POWER",
  "JINDAL POLY FILMS LTD",
  "JINDAL POLY FILMS LTD.",
  "JINDAL POWER ",
  "JINDAL SAW LTD",
  "JINDAL SOUTH",
  "JINDAL STAINLESS (HISAR) LTD",
  "JINDAL STAINLESS LTD",
  "JINDAL STEEL & POWER LTD",
  "JINDAL WORLDWIDE",
  "JK CEMENT LTD",
  "JK FILES (INDIA) LTD. ",
  "JK LAKSHMI CEMENT LTD",
  "JK PAPER LTD",
  "JK TYRE & INDUSTRIES LTD",
  "JM FINANCIAL",
  "JM FINANCIAL LTD",
  "JMC PROJECTS (INDIA) LTD",
  "JMD OILS ",
  "JMD TELEFILM",
  "JOHNSON & JOHNSON ",
  "JOHNSON AND JOHNSON",
  "JOHNSON CONTROLS -HITACHI AIR CONDITIONING INDIA LTD",
  "JOYALUKKAS INDIA ",
  "JP MORGAN",
  "JP MORGAN CHASE BANK",
  "JPINFRATEC",
  "JPPOWER",
  "JSL",
  "JSW ENERGY LTD",
  "JSW STEEL LTD",
  "JUBILANT FOODWORKS LTD",
  "JUBILANT LIFE SCIENCES LTD",
  "JUBL FOOD",
  "JUMERICA HOTELS &  RETRECT CENTRE ",
  "JUSCO LTD",
  "JUST DIAL LIMITED",
  "JVC",
  "JVL AGRO INDUSTRIES LTD",
  "JYOTHY LABORATORIES LTD",
  "JYOTI STRUCTURES LTD",
  "K S OILS LTD",
  "KAILASH AUTO FINANCE LIMITED",
  "KAJARIA CERAMICS LTD",
  "KALEESUWARI REFINERY ",
  "KALPATARU POWER TRANSMISSION LTD",
  "KAMA HOLDINGS LTD",
  "KANAN DEVAN HILLS PLANTATIONS CI PVT LTD",
  "KANSAI NEROLAC PAINTS LTD",
  "KARNATAKA BANK LTD",
  "KARNATAKA POWER TRANSMISSION CORP ",
  "KARP IMPEX (TRF",
  "KARUR VYSYA BANK LTD",
  "KARUTURI GLO",
  "KAVERI SEED CO LTD",
  "KAVERI SEED COMPANY LIMITED",
  "KEC INTERNATIONAL LTD",
  "KEI INDUSTRIES LTD",
  "KESORAM INDUSTRIES LTD",
  "KFA",
  "KGN IND LTD",
  "KIRI DYES",
  "KIRLOSKAR BROTHERS INVESTMENTS LTD",
  "KIRLOSKAR BROTHERS LTD",
  "KIRLOSKAR FERROUS INDUSTRIES",
  "KIRLOSKAR INDUSTRIES",
  "KIRLOSKAR OIL ENGINES LTD",
  "KITEX GARMENTS LTD",
  "KNR CONSTRUCTIONS",
  "KONKAN RAILWAY CORPORATION",
  "KOTAK MAHINDRA ASSET MANAGEMENT COMPANY LIMITED",
  "KOTAK MAHINDRA BANK LTD",
  "KOTAK MAHINDRA CAPITAL COMPANY (KMCC)",
  "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LTD.",
  "KOTAK MAHINDRA LIFE INSURANCE LTD",
  "KOTAK MAHINDRA PRIME LIMITED ",
  "KOTAK PRIVATE EQUITY GROUP (KPEG)",
  "KOTAK REALTY FUND",
  "KOTAK SECURITIES LIMITED - INSTITUTIONAL EQUITIES",
  "KOTAK SECURITIES LTD",
  "KPB HINDUJA COLLEGE OF COMMERCE",
  "KPIT CUMMIN",
  "KPIT TECHNOLOGIES LTD",
  "KPMG",
  "KPR MILL LTD",
  "KRBL LTD",
  "KRISHAK BHARATI CO-OPERATIVE ",
  "KSK ENERGY VENTURES LTD",
  "KWA DIAR (I)",
  "KWALITY LTD",
  "L&T FINANCE HOLDINGS LTD",
  "L&T TECHNOLOGY SERVICES LTD",
  "L'OREAL INDIA ",
  "LA OPALA RG LTD.",
  "LAFARGE INDIA ",
  "LAKSHMI MACHINE WORKS LTD",
  "LAKSHMI OVER",
  "LAKSHMI VILAS BANK LTD",
  "LALITHAA JEWELLERY MART ",
  "LANCO INFRATECH LTD",
  "LAND MARK",
  "LANDMARK",
  "LANDMARK GROUP",
  "LANDMARK INSURANCE BROKERS PVT. LTD.",
  "LARSEN & TOUBRO INFOTECH LTD",
  "LARSEN & TOUBRO LTD",
  "LAURUS LABS LTD",
  "LAXMI DIAMOND ",
  "LEIGHTON INDIA CONTRACTORS ",
  "LG ELECTRONICS INDIA ",
  "LIC HOUSING FINANCE LTD",
  "LIC OF INDIA",
  "LIFE LINE HOSPITAL",
  "LINDE INDIA LTD",
  "LKP SECURITIES LIMITED",
  "LKP WEALTH ADVISORY PRIVATE LIMITED",
  "LLOYD ELECTRIC AND ENGINEERING LTD",
  "LOUIS DREYFUS COMMODITIES INDIA ",
  "LT FOODS LTD",
  "LU LU",
  "LUCAS INDIAN SERVICE LIMITED",
  "LUCAS-TVS LIMITED",
  "LUMINIOUS POWER TECHNOLOGIES ",
  "LUPIN LTD",
  "M M T C LTD.",
  "M&M FINANSER",
  "M.R.F LTD",
  "MACLEODS PHARMACEUTICALS ",
  "MACROTECH DEVELOPERS",
  "MADHUCON PRO",
  "MADHYA GUJARAT VIJ CO ",
  "MADRAS CEMNT",
  "MADRAS FERTILIZERS LTD",
  "MAGMA FINCORP LTD",
  "MAHANAGAR GAS ",
  "MAHANAGAR GAS LTD",
  "MAHANAGAR TELEPHONE NIGAM LTD",
  "MAHARASHTRA SCOOTERS LTD.",
  "MAHARASHTRA SEAMLESS LTD",
  "MAHARASHTRA STATE POWER GENERATION CO ",
  "MAHINDRA & MAHINDRA - ENERGY",
  "MAHINDRA & MAHINDRA - FARM EQUIPMENT",
  "MAHINDRA & MAHINDRA - MILITARY DEFENCE",
  "MAHINDRA & MAHINDRA FINANCIAL SERVICES",
  "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD",
  "MAHINDRA & MAHINDRA LTD",
  "MAHINDRA AEROSPACE",
  "MAHINDRA AGRIBUSINESS",
  "MAHINDRA CASTINGS",
  "MAHINDRA CIE AUTOMOTIVE LTD",
  "MAHINDRA COMPOSITES",
  "MAHINDRA COMVIVA",
  "MAHINDRA CONSULTING ENGINEERS",
  "MAHINDRA CONVEYOR SYSTEMS",
  "MAHINDRA ENGINEERING",
  "MAHINDRA FIRST CHOICE SERVICES",
  "MAHINDRA FORGINGS",
  "MAHINDRA GEARS AND TRANSMISSIONS",
  "MAHINDRA HINODAY LTD",
  "MAHINDRA HOLIDAYS & RESORTS INDIA LTD",
  "MAHINDRA HOLIDAYS AND RESORTS",
  "MAHINDRA INSURANCE BROKERS",
  "MAHINDRA INTERTRADE",
  "MAHINDRA LIFESPACE DEVELOPERS LTD",
  "MAHINDRA LIFESPACES",
  "MAHINDRA LOGISOFT",
  "MAHINDRA LOGISTICS",
  "MAHINDRA MARINE PRIVATE LIMITED",
  "MAHINDRA MUTUAL FUND",
  "MAHINDRA RACING",
  "MAHINDRA RETAI",
  "MAHINDRA REVA",
  "MAHINDRA RURAL HOUSING FINANCE",
  "MAHINDRA RURAL HOUSING FINANCE LTD",
  "MAHINDRA SOLAR ONE",
  "MAHINDRA SONA LTD.",
  "MAHINDRA SPECIAL SERVICES GROUP",
  "MAHINDRA STEEL SERVICE CENTRE",
  "MAHINDRA SWARAJ",
  "MAHINDRA SYSTECH",
  "MAHINDRA TRACTORS",
  "MAHINDRA TRUCK & BUS",
  "MAHINDRA TWO WHEELERS",
  "MAHINDRA UGINE STEEL COMPANY LTD",
  "MAHINDRA USA INC",
  "MAHINDRA WORLD CITY",
  "MAHINDRA YUEDA (YANCHENG) TRACTOR CO",
  "MAHINDRALIFE",
  "MAITHON POWER ",
  "MAKE MY TRIP",
  "MAKEMYTRIP",
  "MAN INDUSTRIES (INDIA) LTD",
  "MANAKSIA LTD",
  "MANAP GEN FI",
  "MANAPPURAM FINANCE",
  "MANGALORE CHEMICALS & FERTILIZERS LTD",
  "MANGALORE REFINERY & PETROCHEMICALS LTD",
  "MANGALORE REFINERY AND PETROCHEMICALS",
  "MANKIND PHARMA ",
  "MANPASAND BEVERAGES LTD",
  "MANUFACTURERS EQUIPMENT AND SUPPLY COMPANY (MESCO)",
  "MARG LTD",
  "MARICO LTD",
  "MARKSANS PHARMA LTD",
  "MARSH INDIA INSURANCE BROKERS",
  "MARUTI SUZUKI INDIA LTD",
  "MASHREQ BANK",
  "MASHREQ INTERNATIONAL",
  "MASRAF AL RAYAN",
  "MASTEK",
  "MAX BUPA HEALTH",
  "MAX FINANCIAL SERVICES LTD",
  "MAX INDIA LTD",
  "MAX LIFE INSURANCE CO. LTD?",
  "MAZAGON DOCK ",
  "MCC PTA INDIA CORPORATION ",
  "MCKENSY",
  "MCLEOD RUSSEL INDIA LTD",
  "MCNALLY BHARAT ENGINEERING COMPANY LTD",
  "MEAIEED PETROCHEMICAL HOLDINGS CO",
  "MEGHMANI ORGANICS",
  "MEP INFRASTRUCTURE DEVELOPERS",
  "MERCATOR LIN",
  "MERCATOR LTD",
  "METITO OVERSEAS",
  "MICRO INKS ",
  "MICRO LABS ",
  "MICROSOFT CORPORATION INDIA ",
  "MINDA CORPORATION",
  "MINDA INDUSTRIES LTD",
  "MINDTREE LTD",
  "MITSUBISHI CORPORATION INDIA ",
  "MJUNCTION SERVICES",
  "MMTC LTD",
  "MNTSC",
  "MODEL ECONOMIC TOWNSHIP LIMITED",
  "MODERN ROAD MAKERS ",
  "MOIL LTD",
  "MONDELEZ INDIA FOODS ",
  "MONNET ISPAT & ENERGY LTD",
  "MONOTONA TYRES LTD., INDIA ",
  "MONROL",
  "MONROL ",
  "MONSANTO IND",
  "MONSANTO INDIA LIMITED",
  "MONSANTO INDIA LTD",
  "MONTECARLO",
  "MOSER BAER INDIA LTD",
  "MOTHER DAIRY FRUIT & VEGETABLES ",
  "MOTHERSON  SUMI SYSTEM LTD",
  "MOTHERSON SUMI SYSTEMS LTD",
  "MOTILAL OSWAL FINANCIAL SERVICES LTD",
  "MP PASCHIM KSHETRA VIDYUT VITARAN CO ",
  "MPHASIS LTD",
  "MRF LTD",
  "MUKAND ENGINEERS LTD.",
  "MUKAND GLOBAL FINANCE LTD.",
  "MUKAND INTERNATIONAL LTD.",
  "MUKAND LTD",
  "MULTI COMMODITY EXCHANGE OF INDIA LIMITED",
  "MUMBAI INTERNATIONAL AIRPORT ",
  "MUNDRA PORT",
  "MUNICH RE",
  "MUNJAL SHOWA LTD",
  "MUTHOOT FINANCE LTD",
  "MUTHOOT INSURANCE BROKERS LTD",
  "MUTHOOT RISK INSURANCE AND BROKING SERVICES PVT LTD",
  "MUTHOOT WEALTH MANAGEMENT SERVCES",
  "MVL LIMITED",
  "MYLAN LABORATORIES ",
  "NABHA POWER ",
  "NAGARJUNA FERTILIZERS & CHEMICALS ",
  "NAHAR INDUSTRIAL ENTERPRISES LTD",
  "NAHAR SPINNING MILLS LTD",
  "NAKHEEL",
  "NAKODA LTD",
  "NARAYANA HRUDAYALAYA LTD",
  "NAT STEEL HOLDINGS",
  "NATCO PHARMA LIMITED",
  "NATIONAL  ALUMINIUM CO",
  "NATIONAL ALUMINIUM COMPANY LTD",
  "NATIONAL BANK OF KUWAIT",
  "NATIONAL BUILDINGS CONSTRUCTION CORP",
  "NATIONAL FERTILIZERS LTD",
  "NATIONAL HANDLOOM DEVELOPMENT CORP ",
  "NATIONAL INSURANCE CO.",
  "NATIONAL MEDICAL CORPORATION LLC",
  "NATIONAL PEROXIDE",
  "NATIONAL SMALL INDUSTRIES CORPORATION ",
  "NATIONAL STEEL AND AGRO INDUSTRIES LTD",
  "NATSTEEL HOLDINGS",
  "NAVA BHARAT VENTURES LTD",
  "NAVIN FLUORINE INTERNATIONAL LTD.",
  "NAVKAR CORPORATION LTD",
  "NAVNEET EDUCATION LTD",
  "NAVNEET PUBL",
  "NBAD",
  "NBCC (INDIA) LTD",
  "NCC LTD",
  "NDTV LTD.",
  "NECTAR LIFESCIENCES LTD",
  "NELCO",
  "NELITO SYSTEMS",
  "NEOBIOCON DUBAI WAREHOUSE",
  "NESCO LIMITD",
  "NESTLE",
  "NESTLE INDIA LTD",
  "NETWORK18 MEDIA & INVESTMENTS LIMITED",
  "NEW HOLLAND FIAT INDIA ",
  "NEW INDIA ASSURANCE",
  "NEW INDIA CO-OPERATIVE BANK LIMITED",
  "NEYVELI LIGNITE CORPORATION LTD",
  "NHPC LTD",
  "NIIT LTD",
  "NIIT TECHNOLOGIES LTD",
  "NILKAMAL LTD",
  "NIRMA ",
  "NISSAN MOTOR INDIA PRIVATE LIMITED",
  "NISSAN RENAULT FINANCIAL SERVICES INDIA",
  "NJ ADVISORY SERVICES PRIVATE LIMTED",
  "NJ INSURANCE BROKERS PVT LTD",
  "NJ WEALTH",
  "NLC INDIA LTD",
  "NMC HOSPITAL",
  "NMDC LTD",
  "NOIDA TOL BR",
  "NOKIA SOLUTIONS & NETWORK INDIA ",
  "NORLAKE HOSPITALITY ",
  "NORTH EAST SMALL FINANCE BANK",
  "NOVARTIS IND",
  "NOVELIS INC",
  "NTPC LTD",
  "NTWK MED INV",
  "NUCLEAR POWER CORPORATION ",
  "NUMALIGARH REFINERY ",
  "NXT DIGITAL HITS",
  "OBEROI REALTY LIMITED",
  "OCL INDIA LTD",
  "OFFSHORE ENGINEERING AND MARKETING LTD",
  "OIL & NATURAL GAS CORP LTD",
  "OIL AND NATURAL GAS CORPORATION LTD",
  "OIL INDIA LTD",
  "OLA INDIA",
  "OLAM AGRO INDIA ",
  "OMAN INSURANCE",
  "OMAXE LTD",
  "ONE 97 COMMUNICATIONS LTD",
  "ONE97 ENTERPRISE MOBILE SOLUTIONS PVT LTD",
  "ONEWEB",
  "ONG CORP LTD",
  "ONMOBILE",
  "OOREDOO",
  "OPTO CIRCUITS INDIA LTD",
  "ORACLE",
  "ORACLE FINANCIAL SERVICES SOFTWARE LTD",
  "ORACLE INDIA ",
  "ORBITCO",
  "ORCHID CHEM",
  "ORIENT CEMENT LTD",
  "ORIENT EXCHANGE & FINANCIAL SERVICES (P) LTD",
  "ORIENT PAP&I",
  "ORIENTAL BANK OF COMMERCE",
  "ORIENTAL INSURANCE",
  "P D HINDUJA NATIONAL HOSPITAL AND MEDICAL RESEARCH CENTRE",
  "P&G HYGIENE AND HEALTH CARE",
  "P.D. RUIA KAYAKALP NATUROPATHY & HEALTH RESORT, INDIA",
  "PAGE INDUSTRIES LTD",
  "PANACEA BIOTECH",
  "PANTALOONS  RETAIL",
  "PARADEEP PHOSPHATES ",
  "PARAG MILK FOODS LTD",
  "PARLE BISCUITS ",
  "PARLE PRODUCTS ",
  "PARSVNATH",
  "PASCHIM GUJARAT VIJ CO ",
  "PATEL ENGINEERING LTD",
  "PATNI COMPUTERS LTD",
  "PAYTM",
  "PAYTM E-COMMERCE PVT LTD",
  "PAYTM ENTERTAINMENT LTD",
  "PAYTM FINANCIAL SERVICES LTD",
  "PAYTM GENERAL INSURANCE CORP LTD",
  "PAYTM LIFE INSURANCE CORP LTD",
  "PAYTM MOBILE SOLUTIONS PVT LTD",
  "PAYTM MONEY LTD",
  "PAYTM PAYMENTS BANK LTD",
  "PC JEWELLER LTD",
  "PC JEWELLER LTD.",
  "PEARL GLOBAL INDUSTRIES LTD",
  "PEC ",
  "PENLAND LTD",
  "PENNAR INDUSTRIES",
  "PEPSICO INDIA HOLDINGS ",
  "PERSISTENT SYSTEMS LTD",
  "PETROFAC",
  "PETRONET LNG LTD",
  "PFIZER LTD",
  "PFIZER LTD (INDIA)",
  "PHEROZE FRAMROZE & CO ",
  "PHILIPS ELECTRONICS INDIA ",
  "PHILLIPS CARBON BLACK LTD",
  "PHOENIX MILL",
  "PHOENIX MILLS LTD",
  "PI INDUSTRIES LTD",
  "PIAGGIO VEHICLES ",
  "PIDILITE INDUSTRIES LTD",
  "PIINDUSTRIES LIMITED",
  "PIPAVAV DEFENCE & OFFSHORE ENGG COMPANY",
  "PIPAVAVSHIP",
  "PIRAMAL ENTERPRISES LTD",
  "PIRAMAL GLASS LTD",
  "PIRAMAL HEALTH CARE",
  "PLETHICO PHARMACEUTICALS LTD",
  "PMC FINCORP LIMITED",
  "PNB HOUSING FINANCE LTD",
  "PNB METLIFE INDIA INSURANCE CO. LTD.",
  "PNC INFRATECH LTD",
  "POLARIS CONSULTING & SERVICES LTD",
  "POLARIS FINANCIAL TECHNOLOGY LTD",
  "POLARIS LAB",
  "POLICY BAZAR INSURANCE WEB AGGREGATOR PVT LTD",
  "POLYCAB INDIA",
  "POLYPLEX CORPORATION LTD",
  "POWER FINANCE CORP LTD",
  "POWER FINANCE CORPORATION LTD",
  "POWER GRID CORPORATION OF INDIA LTD",
  "POWER MECH PROJECTS",
  "POWERLINKS TRANSMISSION",
  "PRAJ INDUSTR",
  "PRAJ INDUSTRIES LTD",
  "PRATIBHA INDUSTRIES LTD",
  "PRESTIGE ESTATES PROJECTS LTD",
  "PRICEWATERHOUSE COOPERS",
  "PRIME FOCUS",
  "PRISM CEMENT LTD",
  "PRISM JOHNSON",
  "PRITHVI INFORMATION SOLUTIONS LTD",
  "PROCTER & GAMBLE HYGIENE & HEALTH CARE LTD",
  "PROVOGUE IND",
  "PRUDENT BROKING SERVICES PVT LTD",
  "PRUDENT CORPORATE ADVISORY SERVICES LTD",
  "PSL LTD",
  "PTC INDIA FINANCIAL SERVICES LTD",
  "PTC INDIA LTD",
  "PUNJ LLOYD LTD",
  "PUNJAB & SIND BANK",
  "PUNJAB NATIONAL BANK",
  "PUNJABNSIND",
  "PURAVANKARA",
  "PVR LIMITED",
  "PVR LTD",
  "QATAR ELECTRICITY AND WATERCO",
  "QATAR FUEL",
  "QATAR INSURANCE CO",
  "QATAR ISLAMIC BANK",
  "QATAR NATIONAL BANK (QNB)",
  "QBG GEOJIT FINANCIAL SERVICES LLC",
  "RADICO KHAIT",
  "RADICO KHAITAN LTD",
  "RAIL VIKAS NIGAM ",
  "RAIN COMMODI",
  "RAIN INDUSTRIES LIMITED",
  "RAJ WEST POWER ",
  "RAJASTHAN RAJYA VIDYUT PRASARAN NIGAM ",
  "RAJESH EXPORTS LIMITED",
  "RAKBANK",
  "RALLIS INDIA LTD",
  "RAMCO CEMENTS LTD",
  "RAMKY INFRASTRUCTURE LTD",
  "RANBAXY LABORATORIES LTD",
  "RANE HOLDINGS LTD",
  "RASHID HOSPITAL",
  "RASHTRIYA CHEMICALS & FERTILIZERS LTD",
  "RASHTRIYA ISPAT NIGAM ",
  "RATNAMANI METALS & TUBES LTD",
  "RATTANINDIA POWER LIMITED",
  "RAYCHEM RPG PRIVATE LIMITED",
  "RAYMOND APPAREL LTD. ",
  "RAYMOND LTD",
  "RAYMOND LUXURY COTTONS LTD. ",
  "RAYMOND UCO DENIM PVT. LTD.",
  "RBL BANK LTD",
  "RECKITT BENCKISER (I) ",
  "RECLTD ( RURAL ELECTRIFICIATION CORPORATION LTD ) ",
  "REDINGTON",
  "REDINGTON (INDIA) LIMITED",
  "REI AGRO LTD",
  "REISIX TENR",
  "RELAX PHARMACEUTICALS ",
  "RELAXO FOOTWEARS LTD",
  "RELIANCE  COMMUNICATIONS  LTD",
  "RELIANCE AEROSPACE TECHNOLOGIES LIMITED",
  "RELIANCE AMBIT TRADE PRIVATE LIMITED",
  "RELIANCE AROMATICS AND PETROCHEMICALS LIMITED",
  "RELIANCE BIG ENTERTAINMENT",
  "RELIANCE BRANDS LIMITED",
  "RELIANCE CAPITAL LTD",
  "RELIANCE CHEMICALS LIMITED",
  "RELIANCE CLOTHING INDIA PRIVATE LIMITED",
  "RELIANCE COMMERCIAL FINANCE",
  "RELIANCE COMMERCIAL LAND & INFRASTRUCTURE LIMITED",
  "RELIANCE COMTRADE PRIVATE LIMITED",
  "RELIANCE CORPORATE IT PARK LIMITED",
  "RELIANCE DEFENCE",
  "RELIANCE EMINENT TRADING & COMMERCIAL PRIVATE LIMITED",
  "RELIANCE ENERGY AND PROJECT DEVELOPMENT LIMITED",
  "RELIANCE ENERGY GENERATION AND DISTRIBUTION LIMITED",
  "RELIANCE ETHANE HOLDING PTE LTD.",
  "RELIANCE EXPLORATION & PRODUCTION DMCC",
  "RELIANCE GAS PIPELINES LIMITED",
  "RELIANCE GENERAL INSURANCE",
  "RELIANCE GLOBAL BUSINESS B.V.",
  "RELIANCE GLOBAL COMMERCIAL LIMITED",
  "RELIANCE GLOBAL ENERGY SERVICES LIMITED",
  "RELIANCE HOLDINGS USA, INC.",
  "RELIANCE INDUSTRIAL INFRASTRUCTURE LTD",
  "RELIANCE INDUSTRIAL INVESTMENTS AND HOLDINGS LIMITED",
  "RELIANCE INDUSTRIES LTD",
  "RELIANCE INFRA",
  "RELIANCE INFRASTRUCTURE LTD",
  "RELIANCE INNOVATIVE BUILDING SOLUTIONS PRIVATE LIMITED",
  "RELIANCE JIO INFOCOMM LIMITED",
  "RELIANCE JIO MEDIA PRIVATE LIMITED",
  "RELIANCE LIFE INSURANCE",
  "RELIANCE LIFESTYLE HOLDINGS LIMITED",
  "RELIANCE MARCELLUS HOLDING, LLC",
  "RELIANCE MONEY",
  "RELIANCE MUMBAI METRO",
  "RELIANCE MUTUAL FUND",
  "RELIANCE NAVAL AND ENGINEERING LTD",
  "RELIANCE PAYMENT SOLUTIONS LIMITED",
  "RELIANCE PETRO MARKETING LIMITED",
  "RELIANCE PETROINVESTMENTS LIMITED",
  "RELIANCE POLYOLEFINS LIMITED",
  "RELIANCE POWER LTD",
  "RELIANCE PROGRESSIVE TRADERS PRIVATE LIMITED",
  "RELIANCE PROLIFIC COMMERCIAL PRIVATE LIMITED",
  "RELIANCE PROLIFIC TRADERS PRIVATE LIMITED",
  "RELIANCE RETAIL FINANCE LIMITED",
  "RELIANCE RETAIL INSURANCE BROKING LIMITED",
  "RELIANCE RETAIL LIMITED",
  "RELIANCE RETAIL VENTURES LIMITED",
  "RELIANCE ROADS",
  "RELIANCE SECURITIES",
  "RELIANCE SIBUR ELASTOMERS PRIVATE LIMITED",
  "RELIANCE STRATEGIC INVESTMENTS LIMITED",
  "RELIANCE SUPPLY SOLUTIONS PRIVATE LIMITED",
  "RELIANCE TEXTILES LIMITED",
  "RELIANCE TRADING LIMITED",
  "RELIANCE UNIVERSAL COMMERCIAL LIMITED",
  "RELIANCE UNIVERSAL ENTERPRISES LIMITED",
  "RELIANCE UNIVERSAL TRADERS PRIVATE LIMITED",
  "RELIANCE USA GAS MARKETING LLC",
  "RELIANCE VANTAGE RETAIL LIMITED",
  "RELIANCE VENTURES LIMITED",
  "RELIANCE WORLD TRADE PRIVATE LIMITED",
  "RELIANCE-GRANDOPTICAL PRIVATE LIMITED",
  "RELIGARE ENTERPRISES LTD",
  "RELMEDIA",
  "RENAULT INDIA PRIVATE LIMITED",
  "RENAULT NISSAN AUTO INDIA PRIVATE LIMITED",
  "RENAULT NISSAN AUTOMOTIVE INDIA ",
  "RENAULT NISSAN TECHNICAL BUSINESS CENTRE INDIA",
  "RENUKA SUGAR",
  "REPCO HOME FINANCE LTD.",
  "RESPONSIVE INDUSTRIES LTD",
  "RICO AUTO INDUSTRIES LTD",
  "RING PLUS AQUA LTD. ",
  "RITES",
  "RIVOLI",
  "RIVOLI GROUP",
  "ROBERT BOSCH ENG & BUSINESS SOLUTIONS ",
  "ROHIT FERRO-TECH LTD",
  "ROLTA INDIA LTD",
  "ROOTS CORPORATION",
  "ROSY BLUE INDIA ",
  "ROVER LOGISTICS FREIGHT LLC",
  "ROYAL SUNDARAM GENERAL INSURANCE",
  "RPG LIFE SCIENCES LIMITED",
  "RPOWER",
  "RSWM LTD",
  "RUCHI INFRASTRUCTURE LTD",
  "RUCHI SOYA INDUSTRIES LTD",
  "RUNGTA MINES ",
  "RUPEE BOSS FINANCIAL SERVICES PVT. LTD.",
  "RURAL ELECTRIFICATION CORP LTD",
  "S BK BIKANER",
  "S H KELKAR AND COMPANY LTD",
  "S KUMARS NATIONWIDE LTD",
  "SABMILLER INDIA ",
  "SADBHAV ENGINEERING LTD",
  "SAHARA INDIA LIFE INSURANCE CO. LTD.",
  "SAKTHI SUGARS LTD",
  "SAMSUNG ELECTRONICS",
  "SAMSUNG INDIA ELECTRONICS",
  "SANDHAR TECHNOLOGIES",
  "SANDVIK ASIA ",
  "SANGAM (INDIA) LTD",
  "SANOFI INDIA LTD",
  "SANWARIA AGRO OILS LTD",
  "SAP INDIA PVT. LTD.",
  "SAP LABS PVT. LTD.",
  "SARASWAT CO-OPERATIVE BANK",
  "SARDA ENERGY & MINERALS",
  "SASKEN COMM",
  "SAVITA OIL TECHNOLOGIES LTD",
  "SB ENERGY ",
  "SBI LIFE INSURANCE CO. LTD?",
  "SBM INTERNATIONAL TRADING FZCO",
  "SCHAEFFLER INDIA LTD",
  "SCHLUMBERGER",
  "SCHNEIDER ELECTRIC INDIA PRIVATE ",
  "SCHNEIDER ELECTRIC INFRASTRUCTURE LTD.",
  "SECURENOW INSURANCE BROKER PVT LTD",
  "SECURITY & INTELLIGENCE SERVICES INDIA",
  "SECURITY PRINTING & MINTING CORP ",
  "SEL MANUFACTURING COMPANY LTD",
  "SEQUENT SCIENTIFIC LTD",
  "SERUM INSTITUTE OF INDIA ",
  "SESA GOA LTD",
  "SESA STERLITE LTD",
  "SHAKTI BHOG FOODS ",
  "SHANKARA BUILDING PRODUCTS",
  "SHAPOORJI PALLONJI & CO ",
  "SHARDA CROPCHEM LIMITED",
  "SHCIL SERVICES LIMITED",
  "SHEELA FOAM LTD",
  "SHELL",
  "SHELL MARKETS",
  "SHILPA MEDICARE LTD",
  "SHIPPING CORPORATION OF INDIA LTD",
  "SHIRPUR GOLD REFINERY",
  "SHIVVANI OIL",
  "SHOPPERS STOP LTD",
  "SHREE ASHTA",
  "SHREE BANKEY BEHARI EXPORTS ",
  "SHREE CEMENT LTD",
  "SHREE GANESH JEWELLERY HOUSE (I) LTD",
  "SHREE GLOBAL",
  "SHREE RENUKA SUGARS LTD",
  "SHRENUJ & COMPANY LTD",
  "SHRI LAKSHMI COTSYN LTD",
  "SHRIRAM CITY UNION FINANCE LTD.",
  "SHRIRAM EPC LTD",
  "SHRIRAM TRANSPORT FINANCE CO LTD",
  "SHRIRAM TRANSPORT FINANCE COMPANY LTD",
  "SHUBHALAKSHMI POLYESTERS ",
  "SIDDHI VINAYAK LOGISTIC ",
  "SIEMENS INDIA LTD",
  "SIEMENS LTD",
  "SILVER POINT PRINTING PRESS",
  "SILVER SPARK APPAREL LTD. ",
  "SIMPLEX INFRASTRUCTURES LTD",
  "SINTEX INDUSTRIES LTD",
  "SINTEX PLASTICS TECHNOLOGY",
  "SITI NETWORKS LTD",
  "SIYANA TRAVEL AND TOURISM LLC",
  "SIYARAM SILK MILLS",
  "SJVN LTD",
  "SKF INDIA LTD",
  "SKODA AUTO INDIA",
  "SKS MICRO",
  "SKS MICROFINANCE LIMITED",
  "SML ISUZU LTD.",
  "SNAPDEAL",
  "SOBHA LIMITED",
  "SOBHA LTD",
  "SOLAR INDUSTRIES INDIA LTD",
  "SOMANY CERAMICS LTD",
  "SONATA SOFTWARE LTD",
  "SONY INDIA ",
  "SOUTH INDIAN BANK LTD",
  "SOUTHERN PETROCHEMICALS INDUSTRIES CORP",
  "SOUTHERN ROADWAYS LIMITED",
  "SPICEJET LTD",
  "SREI INFRASTRUCTURE FINANCE LTD",
  "SRF LTD",
  "SRS LTD",
  "ST TRAD CORP",
  "STAN CHART",
  "STANDARD CHARTERED BANK",
  "STAR HEALTH AND ALLIED",
  "STAR INDIA ",
  "STAR UNION DAI-ICHI LIFE INSURANCE CO. LTD.",
  "STATE BANK OF BIKANER AND JAIPUR",
  "STATE BANK OF INDIA",
  "STATE BANK OF MYSORE",
  "STATE BANK OF TRAVANCORE",
  "STEEL AUTHORITY OF INDIA (SAIL) LTD",
  "STERLING & WILSON SOLAR",
  "STERLING AGRO INDUSTRIES ",
  "STERLITE  TECHNOLOGIES",
  "STERLITE BIOTEC",
  "STERLITE TECHNOLOGIES LTD.",
  "STOCKHOLDING CORPORTION OF INDIA LTD",
  "STRIDE ARCOLAB LIMITED",
  "STRIDES ARCO",
  "STRIDES PHARMA SCIENCE",
  "STRIDES SHASUN LTD",
  "SUBEX LTX",
  "SUDARSHAN CHEMICAL INDUSTRIES",
  "SUJANA METAL PRODUCTS LTD",
  "SUJANA TOWERS LTD",
  "SUJANA UNIVERSAL INDUSTRIES LTD",
  "SUN PHARMA ADVANCED RESEARCH CO LTD",
  "SUN PHARMACEUTICAL INDUSTRIES LTD",
  "SUN TV NETWORK LTD",
  "SUNDARAM ASSET MANAGEMENT COMPANY",
  "SUNDARAM AUTO COMPONENTS LIMITED - PLASTICS DIVISION",
  "SUNDARAM AUTO COMPONENTS LIMITED - RUBBER DIVISION",
  "SUNDARAM BNP PARIBAS FUND SERVICES",
  "SUNDARAM BNP PARIBAS HOME FINANCE",
  "SUNDARAM BRAKE LININGS LIMITED",
  "SUNDARAM BUSINESS SERVICES",
  "SUNDARAM CLAYTON LTD",
  "SUNDARAM DYNACAST PRIVATE LIMITED",
  "SUNDARAM FINANCE HOLDINGS LIMITED",
  "SUNDARAM FINANCE LIMITED",
  "SUNDARAM INDUSTRIES LIMITED - RUBBER DIVISION",
  "SUNDARAM INDUSTRIES LIMITED - TYRE SOLUTIONS DIVISION",
  "SUNDARAM INFOTECH",
  "SUNDARAM TEXTILES LIMITED",
  "SUNDRAM FASTENERS LIMITED",
  "SUNDRAM FASTENERS LTD",
  "SUNFLAG IRON & STEEL COMPANY LTD",
  "SUNPHA ADV",
  "SUNRISE ASIAN LIMITED",
  "SUNTECK REAL",
  "SUNTECK REALTY LTD",
  "SUPERTECH ",
  "SUPREME INDUSTRIES LTD",
  "SUPREME INFRASTRUCTURE INDIA LTD",
  "SUPREME PETROCHEM LTD",
  "SURANA INDUSTRIES LTD",
  "SURYA ROSHNI LTD",
  "SURYODAYA SMALL FINANCE BANK",
  "SUTLEJ TEXTILES & INDUSTRIES LTD",
  "SUVEN LIFE SCIENCES LTD",
  "SUZLON ENERGY LTD",
  "SWAN ENERGY LTD.",
  "SWISS RE",
  "SYBASE SOFTWARE INDIA PVT. LTD.",
  "SYMPHONY LTD",
  "SYNDICATE BANK",
  "SYNGENE INTERNATIONAL LTD",
  "SYNGENTA INDIA ",
  "SYNTEL ",
  "T V SUNDRAM IYENGAR & SONS PRIVATE LIMITED",
  "TAFE LIMITED",
  "TAFE MOTORS & TRACTORS ",
  "TAJ AIR",
  "TAKE SOLUTIONS LTD",
  "TAL MANUFACTURING SOLUTIONS",
  "TAMIL NADU NEWSPRINT AND PAPERS LTD",
  "TAMILNADU NEWSPRINT & PAPERS",
  "TARA JEWELS LTD",
  "TAS-AGT SYSTEMS",
  "TASEC LTD",
  "TATA ADVANCED MATERIALS",
  "TATA ADVANCED SYSTEMS",
  "TATA AFRICA HOLDINGS",
  "TATA AIA LIFE INSURANCE",
  "TATA AIG GENERAL INSURANCE",
  "TATA ASSET MANAGEMENT",
  "TATA AUTOCOMP SYSTEMS",
  "TATA BLUESCOPE STEEL",
  "TATA BUSINESS EXCELLENCE GROUP",
  "TATA BUSINESS SUPPORT SERVICES",
  "TATA CAPITAL",
  "TATA CERAMICS",
  "TATA CHEMICALS",
  "TATA CHEMICALS EUROPE",
  "TATA CHEMICALS LTD",
  "TATA CHEMICALS MAGADI",
  "TATA CHEMICALS NORTH AMERICA",
  "TATA CLASSEDGE",
  "TATA COFFEE LTD",
  "TATA COMMUNICATIONS LTD",
  "TATA CONSULTANCY SERVICES LTD",
  "TATA CONSULTING ENGINEERS",
  "TATA CUMMINS",
  "TATA DAEWOO COMMERCIAL VEHICLE COMPANY",
  "TATA ELXSI LTD",
  "TATA GLOBAL BEVERAGES LTD",
  "TATA HITACHI CONSTRUCTION MACHINERY",
  "TATA HOUSING DEVELOPMENT COMPANY",
  "TATA INDUSTRIAL SERVICES",
  "TATA INDUSTRIES",
  "TATA INTERACTIVE SYSTEMS",
  "TATA INTERNATIONAL",
  "TATA INTERNATIONAL AG",
  "TATA INVESTMENT CORP LTD",
  "TATA INVESTMENT CORPORATION",
  "TATA LIMITED",
  "TATA METALIKS",
  "TATA MOTORS",
  "TATA MOTORS EUROPEAN TECHNICAL CENTRE",
  "TATA MOTORS LTD",
  "TATA MOTORS LTD DVR",
  "TATA NET",
  "TATA NYK",
  "TATA PETRODYNE",
  "TATA PIGMENTS",
  "TATA POWER CO LTD",
  "TATA POWER DELHI DISTRIBUTION",
  "TATA POWER SOLAR SYSTEMS",
  "TATA POWER TRADING",
  "TATA PROJECTS",
  "TATA QUALITY MANAGEMENT SERVICES",
  "TATA REALTY AND INFRASTRUCTURE",
  "TATA SERVICES",
  "TATA SIA AIRLINES(VISTARA)",
  "TATA SKY",
  "TATA SONS",
  "TATA SPONGE IRON",
  "TATA SPONGE IRON LTD.",
  "TATA STEEL",
  "TATA STEEL EUROPE",
  "TATA STEEL KZN",
  "TATA STEEL LTD",
  "TATA STEEL PROCESSING AND DISTRIBUTION",
  "TATA STEEL THAILAND",
  "TATA STL",
  "TATA STRATEGIC MANAGEMENT GROUP",
  "TATA TEA LTD",
  "TATA TECHNOLOGIES",
  "TATA TELESERVICES",
  "TATA TELESERVICES (MAHARASHTRA)",
  "TATA TELESRV",
  "TATA UNISTORE",
  "TATAGLOBAL",
  "TATANET",
  "TAYO ROLLS",
  "TCS LTD.",
  "TEAM LEASE SERVICES",
  "TECH MAHINDRA LTD",
  "TECHNO ELECTRIC & ENGINEERING CO. LTD.",
  "TECPRO SYSTEMS LTD",
  "TEL EIGHTEEN",
  "TELECOMMUNICATIONS CONSULTANTS INDIA ",
  "TEXMACO RAIL & ENGINEERING LTD.",
  "THDC INDIA ",
  "THE COSMOS CO-OPERATIVE BANK LIMITED",
  "THE FERTILIZERS & CHEMICALS TRAVANCORE",
  "THE INDIA CEMENTS",
  "THE RAMCO CEMENTS LTD",
  "THE TINPLATE COMPANY OF INDIA",
  "THERMAX LTD",
  "THOMAS CK IN",
  "THOMAS COOK (INDIA) LTD",
  "THYROCARE TECHNOLOGIES LTD",
  "TIFFANY FOODS LTD",
  "TIME TECHNOPLAST LTD.",
  "TIMKEN INDIA LTD.",
  "TINPLATE COM",
  "TITAGARH WAGONS LTD",
  "TITAN COMPANY LTD",
  "TKM GLOBAL LOGISTICS",
  "TM INTERNATIONAL LOGISTICS",
  "TORRENT PHARMACEUTICALS LTD",
  "TORRENT POWER LTD",
  "TOYOTA KIRLOSKAR MOTOR ",
  "TRANSPORT CORPORATION OF INDIA LTD",
  "TRANSSTROY INDIA  LTD",
  "TRENT LTD",
  "TRF LTD",
  "TRIDENT LTD",
  "TRILLION GAS & OIL FIELD TRADING",
  "TRINITY INDIA LTD. ",
  "TRIVENI ENGINEERING & INDUSTRIES LTD",
  "TRIVENI TURBINE LIMITED",
  "TRL KROSAKI REFRACTORIES",
  "TTK PRESTIGE LTD",
  "TUBE INVESTMENTS OF INDIA LTD",
  "TULIP TELECOM LTD",
  "TURBO ENERGY LIMITED",
  "TV TODAY NETWORK",
  "TV18 BROADCAST LTD",
  "TVS AUTOMOBILE SOLUTIONS PRIVATE LIMITED",
  "TVS CREDIT SERVICES LIMITED",
  "TVS DYNAMIC GLOBAL FREIGHT SERVICES LIMITED",
  "TVS ELECTRONICS LIMITED",
  "TVS INFRASTRUCTURE LIMITED",
  "TVS INTERCONNECT SYSTEMS LIMITED",
  "TVS LOGISTICS SERVICES LIMITED",
  "TVS MOTOR CO LTD",
  "TVS SEWING NEEDLES LIMITED",
  "TVS SRICHAKRA LIMITED",
  "TVS SRICHAKRA LIMITED - MOULDED PRODUCTS DIVISION",
  "TVS SRICHAKRA LTD",
  "TVS SUPPLY CHAIN SOLUTIONS",
  "TWITTER",
  "UAE EXCHANGE",
  "UBER INDIA",
  "UCO BANK",
  "UFLEX LTD",
  "UJJIVAN FINANCIAL SERVICES LTD",
  "UJJIVAN SMALL FINANCE BANK",
  "ULTRATECH CEMENT LTD",
  "ULTRATECH CM",
  "UNICHEM LABORATORIES LTD",
  "UNION BANK OF INDIA",
  "UNION NATIONAL BANK",
  "UNITD SPIRITS",
  "UNITECH LTD",
  "UNITED BREWERIES LTD",
  "UNITED INDIA INSURANCE",
  "UNITED PHOSPOROUS INDIA LTD",
  "UNITED SPIRITS LTD",
  "UNITY INFRAPROJECTS LTD",
  "UP RAJYA VIDYUT UTPADAN NIGAM ",
  "UPL LTD",
  "URANUS INTRENATIONA LLC",
  "USHA MARTIN LTD",
  "USV ",
  "UTKAL ALUMINA INTERNATIONAL LIMITED",
  "UTKARSH MICRO FINANCE",
  "UTKARSH SMALL FINANCE BANK LTD",
  "UTTAM VALUE STEELS LTD",
  "UTTAR GUJARAT VIJ CO ",
  "UTV SOFTWARE",
  "V-GUARD INDUSTRIES LTD",
  "VA TECH WABAG LTD",
  "VAKRANGEE LTD",
  "VARDHMAN TEXTILES LTD",
  "VARROC ENGINEERING",
  "VARUN BEVERAGES LTD",
  "VARUN SHIPNG",
  "VASCON ENG",
  "VEDANTA LTD",
  "VENKATESHWARA HATCHERIES ",
  "VENKYS (INDIA)",
  "VIDEOCON INDUSTRIES LTD",
  "VIJAYA BANK",
  "VIKAS WSP LTD",
  "VIMAL OIL & FOODS LTD",
  "VINATI ORGANICS LTD",
  "VIOM NETWORKS ",
  "VIP INDUSTRI",
  "VIP INDUSTRIES LTD",
  "VIRAJ PROFILES LTD",
  "VODAFONE INDIA ",
  "VOLKSWAGEN GROUP SALES INDIA ",
  "VOLTAMP TRANSFORMERS LTD",
  "VOLTAS LTD",
  "VOLVO INDIA ",
  "VST INDUSTRIES LTD.",
  "VVF INDIA ",
  "WABCO INDIA LTD",
  "WADIA TECHNO-ENGINEERING SERVICES",
  "WALCHAND IN",
  "WALL STREET FINANCE",
  "WAY2WEALTH BROKERS PVT. LTD.",
  "WAY2WEALTH CAPITAL PVT. LTD.",
  "WAY2WEALTH COMMODITIES PVT. LTD.",
  "WAY2WEALTH DISTRIBUTORS PVT. LTD.",
  "WAY2WEALTH ENTERPRISES PVT. LTD.",
  "WAY2WEALTH INSURANCE BROKERS PVT. LTD.",
  "WAY2WEALTH SECURITIES PVT. LTD.",
  "WELCARE HOSPITAL",
  "WELCORP",
  "WELLKNOWN POLYESTERS ",
  "WELSPUN CORP LTD",
  "WELSPUN INDIA LTD.",
  "WESTCON MIDDLE EAST ",
  "WESTERN ELECTRICITY SUPPLY OF ORISSA ",
  "WESTLAND",
  "WESTLIFE DEVELOPMENT LTD",
  "WHEELS INDIA LIMITED",
  "WHIRLPOOL OF INDIA LTD",
  "WIPRO ENTERPRISES ",
  "WIPRO GE HEALTHCARE ",
  "WIPRO LTD",
  "WISE FINSERV INSURANCE MARKETING PVT LTD",
  "WNS",
  "WOCKHARDT LTD",
  "WONDERLA HOLIDAYS LTD",
  "WORLDS WINDOW IMPEX INDIA ",
  "WS RETAIL SERVICES ",
  "WYETH LTD",
  "X- CALIBUR CONSTRUCTION CHEMISTRY",
  "YATRA",
  "YBRANT DIGITAL LTD",
  "YES BANK LTD",
  "YOUTUBE",
  "ZAVERI & CO ",
  "ZAYAL UNIVERSITY",
  "ZEE ENTERTAINMENT ENTERPRISES LTD",
  "ZEE MEDIA CORPORATION PLD",
  "ZENSAR TECHNOLOGIES LTD",
  "ZUARI AGRO CHEMICALS LTD",
  "ZUARI INDUST",
  "ZYDUS WELL",
  "ZYDUS WELLNESS LTD",
  "ZYLOG SYSTEMS LTD",
];
