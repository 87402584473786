import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQuotes,
  setIsQuotesError,
  setIsQuotesLoading,
  setQuotes,
} from "../QuotePage/quotePageSlice";

import { updateFilterOptions } from "./serviceApi";

const quoteFilterSlice = createSlice({
  name: "quoteFilter",
  initialState: {
    filterValues: {
      coverUptoValues: [],
    },
    filter: {
      nomineeGets: "Lumpsum",
      coverAmount: "₹ 50 Lakhs",
      coverAmountMonthly: {
        value: "₹ 50 Thousand",
        term: "10 years",
        increaseIncome: "Please select",
      },
      coverAmountLumpsumMonthly: {
        coverAmount: "",
        coverAmountPercent: "25%",
        increaseIncome: "Please select",
        additionalIncome: {
          amount: "50000",
          period: "1 year",
        },
      },
      coverUpto: "",
      paymentTerm: {
        option: "Regular Pay",
        term: "5 Years",
      },
      morePlans: "Choose your plans",
    },
    subFilter: {
      payPremium: "",
      sortBy: "Price (low to high)",
    },
    popup: "",
    displayNomineeGets: "",
    returnOfPremium: false,
    lastFiltersData: false,
  },
  reducers: {
    setCoverUptoValues: (state, action) => {
      state.filterValues.coverUptoValues = action.payload;
    },
    setNomineeGets: (state, { payload }) => {
      state.filter.nomineeGets = payload.value;
    },
    setCoverAmount: (state, { payload }) => {
      state.filter.coverAmount = payload.value;
    },
    setCoverAmountMonthly: (state, action) => {
      state.filter.coverAmountMonthly = action.payload;
    },
    setCoverAmountLumpsumMonthly: (state, action) => {
      state.filter.coverAmountLumpsumMonthly = action.payload;
    },
    setCoverUpto: (state, { payload }) => {
      state.filter.coverUpto = payload.value;
    },
    setPaymentTermOption: (state, { payload }) => {
      state.filter.paymentTerm.option = payload.value;
    },
    setPaymentTermTerm: (state, { payload }) => {
      state.filter.paymentTerm.term = payload.value;
    },
    setMorePlans: (state, { payload }) => {
      state.filter.morePlans = payload.value;
    },
    setPayPremium: (state, action) => {
      state.subFilter.payPremium = action.payload;
    },
    setSortBy: (state, action) => {
      state.subFilter.sortBy = action.payload;
    },
    setPopup: (state, action) => {
      state.popup = action.payload;
    },
    setFilter: (state, { payload }) => {
      state.filter = { ...state.filter, ...payload };
    },
    setDisplayNomineeGets: (state, action) => {
      state.displayNomineeGets = action.payload;
    },
    setReturnOfPremium: (state, action) => {
      state.returnOfPremium = action.payload;
    },
    setLastFiltersData: (state, action) => {
      state.lastFiltersData = action.payload;
    },
  },
});

export const {
  setCoverUptoValues,
  setCoverUpto,
  setCoverAmount,
  setCoverAmountMonthly,
  setCoverAmountLumpsumMonthly,
  setMorePlans,
  setNomineeGets,
  setPayPremium,
  setPaymentTermOption,
  setPaymentTermTerm,
  setSortBy,
  setPopup,
  setFilter,
  setDisplayNomineeGets,
  setReturnOfPremium,
  setLastFiltersData,
} = quoteFilterSlice.actions;

// Update filters to database whenever user changes any filters.
export const updateFilters = data => async (dispatch, getState) => {
  if (!data.traceId)
    throw new Error("traceId is not present in data object for updateFilters");
  try {
    dispatch(setIsQuotesLoading("updating-filters"));
    dispatch(setQuotes([]));
    const { success } = await updateFilterOptions(data);

    if (success) {
      dispatch(
        fetchQuotes({
          traceId: data.traceId,
          activeICs: getState().quote.corporateJourney?.gameskraft_ic,
          active: getState().quote.corporateJourney?.is_gameskraft === "active",
        }),
      );
    } else {
      dispatch(setIsQuotesLoading(false));
      dispatch(
        setIsQuotesError({
          from: "updateFilters",
          message: "Something went wrong while updating filters",
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

// Update filters to database whenever user changes any filters.
export const updateFiltersNotQuotes = data => async dispatch => {
  if (!data.traceId)
    throw new Error("traceId is not present in data object for updateFilters");
  try {
    dispatch(setIsQuotesLoading("updating-filters"));
    dispatch(setQuotes([]));
    const { success } = await updateFilterOptions(data);

    if (success) {
      dispatch(setLastFiltersData(data));
    } else {
      dispatch(setIsQuotesLoading(false));
      dispatch(
        setIsQuotesError({
          from: "updateFilters",
          message: "Something went wrong while updating filters",
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export default quoteFilterSlice.reducer;

export const selectCoverAmount = state => state.quoteFilter.filter.coverAmount;

export const selectPaymentTermOption = state =>
  state.quoteFilter.filter.paymentTerm.option;
