import React from "react";
import styled from "styled-components";
export const BackButton = () => {
  return (
    <div style={{ lineHeight: "1.5" }}>
      <Button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="backSvg"
          viewBox="0 0 24 24"
          fill="#808080"
        >
          <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        back
      </Button>
    </div>
  );
};

const Button = styled.button`
  outline: none;
  position: absolute;
  left: 40px;
  top: 110px;
  border: none;
  background: none;
  color: #808080;
  font-size: 14px;
  font-family: "Inter-Regular";
  text-transform: uppercase;
  display: inline;
  &:active,
  &:focus {
    outline: none;
  }
  & svg {
    width: 15px;
    height: 20px;
    float: left;
    margin-top: 1px;
    margin-right: -3px;
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    display: inline;
    font-size: 14px;
    top: 125px;
    left: 50px;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 767px) {
    left: 15px;
    top: 80px;
    color: #808080;
    font-size: 14px;
    display: inline-block;
    text-transform: capitalize;
  }
`;
