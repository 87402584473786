import HttpClient from "../../api/httpClient";

const createUser = data =>
  HttpClient("common/create/user", { method: "POST", data });

const updateUser = data =>
  HttpClient("common/update/user", { method: "POST", data });

const checkpinCode = data =>
  HttpClient("common/check_pincode", { method: "POST", data });

const quotationData = data =>
  HttpClient("common/update_quotation", { method: "POST", data });

const contactme = data =>
  HttpClient("common/save_contact", { method: "POST", data });

const emailPNB = data =>
  HttpClient("common/check_pnb_domain", { method: "POST", data });

const micrositeData = data =>
  HttpClient("common/get_microsite_data", { method: "POST", data });
// const tracking = (data) =>
//   HttpClient("/common/create/user-journey", { method: "POST", data });
export {
  createUser,
  checkpinCode,
  updateUser,
  quotationData,
  contactme,
  emailPNB,
  micrositeData,
};
