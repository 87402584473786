import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import { saveMedicalDetails4 } from "./MedicalDetails4.slice";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {
  Form,
  FormGroup as FG,
  Label,
  TextAreaProposal,
  TextInput,
} from "../../../../components/Form/Form";
import "./medicalDetails4.scss";
import { dateUtil, numOnly } from "../../../../utils/inputUtils";
import { useForm } from "react-hook-form";
import { Summary } from "../../KotakLifeProposalPage/Health/style";
import { EditButton } from "../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import styled from "styled-components/macro";

const FormGroup = styled(FG)`
  height: auto !important;
`;

const MedicalDetails4 = ({ position, setPosition, index }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  const { medicalDetailsThree } = useSelector(state => state.medicalDetails3);
  const { medicalDetailsFour } = useSelector(state => state.medicalDetails4);
  const { medicalDetailsTwo } = useSelector(state => state.medicalDetailsTwo);
  const LastIndex = useSelector(state => state.iciciProposal?.position);
  const [summary, setSummary] = useState(false);
  const [disease, setDisease] = useState(
    medicalDetailsFour ? medicalDetailsFour.disease : false,
  );

  const [checkboxError, setCheckboxError] = useState(false);

  const [diseaseDetails, setDiseaseDetails] = useState({
    bp: medicalDetailsFour ? medicalDetailsFour.bp : false,
    surgery: medicalDetailsFour ? medicalDetailsFour.surgery : false,
    nervousDisorder: medicalDetailsFour
      ? medicalDetailsFour.nervousDisorder
      : false,
    liverDisorder: medicalDetailsFour
      ? medicalDetailsFour.liverDisorder
      : false,
    bloodDisorder: medicalDetailsFour
      ? medicalDetailsFour.bloodDisorder
      : false,
    cancer: medicalDetailsFour ? medicalDetailsFour.cancer : false,
    heartDisease: medicalDetailsFour ? medicalDetailsFour.heartDisease : false,
    diabetes: medicalDetailsFour ? medicalDetailsFour.diabetes : false,
    asthma: medicalDetailsFour ? medicalDetailsFour.asthma : false,
    gastroIntestinal: medicalDetailsFour
      ? medicalDetailsFour.gastroIntestinal
      : false,
    genitourinary: medicalDetailsFour
      ? medicalDetailsFour.genitourinary
      : false,
    hiv: medicalDetailsFour ? medicalDetailsFour.hiv : false,
    psychiatric: medicalDetailsFour ? medicalDetailsFour.psychiatric : false,
    other: medicalDetailsFour ? medicalDetailsFour.other : false,
    healthDeclaration: medicalDetailsFour
      ? medicalDetailsFour.healthDeclaration
      : false,
  });
  const [healthDeclaration, setHealthDeclaration] = useState(
    medicalDetailsFour ? medicalDetailsFour.healthDeclaration : false,
  );

  const handleChange = e => {
    setDiseaseDetails({
      ...diseaseDetails,
      [e.target.id]: e.target.checked,
    });
  };

  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };

  const handleDate = event => {
    dateUtil(event, date);
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    const newData = {
      ...data,

      disease,
      ...diseaseDetails,
      healthDeclaration,
    };
    // console.log(disease);
    const sendData = {
      diagnosedText: newData?.otherDiagnosedDetails,
      medicalCondition: newData.healthDeclaration
        ? newData.medicalCondition
        : null,
      dateDiagnosis: newData.healthDeclaration ? newData.diagnosisDate : null,
      treatmentPeriod: newData.healthDeclaration ? newData.treatmenTerm : null,
      treatmentDetails: newData.healthDeclaration
        ? newData.treatmenDetails
        : null,
      treatmentTest: newData.healthDeclaration ? newData.treatmenTest : null,
      anyDetails: newData.healthDeclaration ? newData.otherDetails : null,
      diagnosed: newData.disease ? "Y" : "N",
      hypertension: newData.bp ? "Y" : "N",
      surgery: newData.surgery ? "Y" : "N",
      stroke: newData.nervousDisorder ? "Y" : "N",
      jaundice: newData.liverDisorder ? "Y" : "N",
      anaemeia: newData.bloodDisorder ? "Y" : "N",
      cancer: newData.cancer ? "Y" : "N",
      chestPain: newData.heartDisease ? "Y" : "N",
      diabetes: newData.diabetes ? "Y" : "N",
      asthma: newData.asthma ? "Y" : "N",
      pancreatitis: newData.gastroIntestinal ? "Y" : "N",
      kidney: newData.genitourinary ? "Y" : "N",
      hiv: newData.hiv ? "Y" : "N",
      mental: newData.psychiatric ? "Y" : "N",
      other: newData.other ? "Y" : "N",
      healthDeclaration: newData.healthDeclaration ? "Y" : "N",
      currentMedicalCondition: newData.healthDeclaration
        ? newData.condtion
        : null,
      proposalJourneyStatus: iciciProposalForms.medicalQuestion4,
    };

    saveProposalForm(sendData, () => {
      dispatch(saveMedicalDetails4(newData));
      setSummary(true);
      dispatch(setPosition(position + 1));
    });
  };

  useEffect(() => {
    if (!disease) {
      setDiseaseDetails({
        bp: false,
        surgery: false,
        nervousDisorder: false,
        liverDisorder: false,
        bloodDisorder: false,
        cancer: false,
        heartDisease: false,
        diabetes: false,
        asthma: false,
        gastroIntestinal: false,
        genitourinary: false,
        hiv: false,
        psychiatric: false,
        other: false,
        healthDeclaration: false,
      });
    }
  }, [disease]);
  useEffect(() => {
    if (medicalDetailsFour) {
      if (position === index) {
        setSummary(false);
      }
      if (position > index) {
        setSummary(true);
      }
    }
  }, [position]);
  console.log(healthDeclaration);
  useEffect(() => {
    if (disease) {
      if (!Object.values(diseaseDetails).includes(true)) {
        setCheckboxError(true);
      } else {
        setCheckboxError(false);
      }
    } else setCheckboxError(false);
  }, [register, disease, diseaseDetails]);
  useEffect(() => {
    if (Object.values(diseaseDetails).includes(true) && !disease) {
      setDisease(true);
    }
  }, [diseaseDetails]);
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Medical Question - Set 4</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;
  const a = medicalDetailsTwo?.occupationHazard?.length
    ? medicalDetailsTwo?.occupationHazard?.length
    : 0;
  const b = medicalDetailsTwo?.employedInArmy?.length
    ? medicalDetailsTwo?.employedInArmy?.length
    : 0;
  const c = medicalDetailsTwo?.lostWeight?.length
    ? medicalDetailsTwo?.lostWeight?.length
    : 0;
  const d = medicalDetailsTwo?.familyDisorder?.length
    ? medicalDetailsTwo?.familyDisorder?.length
    : 0;
  const e = medicalDetailsTwo?.hospitalized?.length
    ? medicalDetailsTwo?.hospitalized?.length
    : 0;
  const f = medicalDetailsFour?.otherDiagnosedDetails?.length
    ? medicalDetailsFour?.otherDiagnosedDetails?.length
    : 0;
  const g = medicalDetailsThree?.disabilityText?.length
    ? medicalDetailsThree?.disabilityText?.length
    : 0;
  const h = medicalDetailsThree?.injuryText?.length
    ? medicalDetailsThree?.injuryText?.length
    : 0;
  const i = medicalDetailsThree?.gynecologicalComplicationsText?.length
    ? medicalDetailsThree?.gynecologicalComplicationsText?.length
    : 0;

  const totalCount = a + b + c + d + e + f + g + h + i;
  if (medicalDetailsThree) {
    return (
      <>
        <Card proposal>
          <CardTitle
            eligibilityTitle
            className="cardTitleProposal"
            // EC={summary ? true : undefined}
          >
            Medical Question - Set 4
            {summary && (
              <EditButton
                onClick={() => {
                  dispatch(setPosition(index));
                  setSummary(false);
                }}
              />
            )}
            {totalCount > 250 && LastIndex >= 13 ? (
              <h6
                style={{
                  color: "red",
                  marginLeft: "2rem",
                  padding: "15px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to
                mention the descriptions is 250 characters and{" "}
                {totalCount <= 250 ? 250 - totalCount : 0} character available
              </h6>
            ) : (
              <></>
            )}
          </CardTitle>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {!summary ? (
              <FormGroup style={{ textAlign: "left" }} xlg>
                <h6
                  style={{
                    color: totalCount <= 250 ? "#107591" : "red",
                    marginLeft: "2rem",
                    padding: "15px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <sup style={{ fontSize: "14px" }}>*</sup>Maximum limit to
                  mention the descriptions is 250 characters and{" "}
                  {totalCount <= 250 ? 250 - totalCount : 0} character available
                </h6>
                <div className="healthQuestion">
                  Have you ever suffered or being diagnosed with or been treated
                  for any of the following?
                </div>
                <RadioComponent
                  // label="Have you ever suffered or being diagnosed with or been treated for any of the following?"
                  value={disease}
                  updateChecked={setDisease}
                  name="disease"
                  // ref={register}
                  // block
                />
              </FormGroup>
            ) : (
              <Summary>
                <label className="label">
                  Have you ever suffered or being diagnosed with or been treated
                  for any of the following?
                </label>

                <p className="p">{disease ? "Yes" : "No"}</p>
              </Summary>
            )}
            {
              <>
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth firstcheckmargin"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="bp"
                      id="bp"
                      checked={diseaseDetails.bp}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="noDisease">
                      Hypertension/ High BP/ high cholesterol
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.bp && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Hypertension/ High BP/ high cholesterol
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="surgery"
                      id="surgery"
                      checked={diseaseDetails.surgery}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="surgery">
                      Undergone angioplasty, bypass surgery, heart surgery
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.surgery && (
                    <FormGroup md eligibilityPage>
                      <Summary>
                        <p className="p">
                          Undergone angioplasty, bypass surgery, heart surgery
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="nervousDisorder"
                      id="nervousDisorder"
                      checked={diseaseDetails.nervousDisorder}
                      onChange={e => {
                        handleChange(e);
                      }}
                    ></Checkbox>
                    <span
                      className="label checkBoxLabel"
                      htmlFor="nervousDisorder"
                    >
                      Nervous disorders/ stroke/ paralysis/ epilepsy
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.nervousDisorder && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Nervous disorders/ stroke/ paralysis/ epilepsy
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="liverDisorder"
                      id="liverDisorder"
                      checked={diseaseDetails.liverDisorder}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="liverDisorder"
                    >
                      Liver disorders/ Jaundice/ Hepatitis B or C
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.liverDisorder && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Liver disorders/ Jaundice/ Hepatitis B or C
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="cancer"
                      id="cancer"
                      checked={diseaseDetails.cancer}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="cancer">
                      Cancer, Tumor, Growth or Cyst of any Kind
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.cancer && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Cancer, Tumor, Growth or Cyst of any Kind
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="bloodDisorder"
                      id="bloodDisorder"
                      checked={diseaseDetails.bloodDisorder}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="liverDisorder"
                    >
                      Any blood disorders like Anaemeia, Thalassemia etc
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.bloodDisorder && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Any blood disorders like Anaemeia, Thalassemia etc
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="heartDisease"
                      id="heartDisease"
                      checked={diseaseDetails.heartDisease}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="heartDisease"
                    >
                      Chest Pain/ Heart Attack/ any other heart disease
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.heartDisease && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Chest Pain/ Heart Attack/ any other heart disease
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="diabetes"
                      id="diabetes"
                      checked={diseaseDetails.diabetes}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="liverDisorder"
                    >
                      Diabetes/ High Blood Sugar/ Sugar in Urine
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.diabetes && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Diabetes/ High Blood Sugar/ Sugar in Urine
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="asthma"
                      id="asthma"
                      checked={diseaseDetails.asthma}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="asthma">
                      Asthma, Tuberculosis or any other respiratory
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.asthma && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Asthma, Tuberculosis or any other respiratory
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="gastroIntestinal"
                      id="gastroIntestinal"
                      className="proposalCheckBox"
                      checked={diseaseDetails.gastroIntestinal}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="liverDisorder"
                    >
                      Any GastroIntestinal disorders like Pancreatitis, Colitis
                      etc.
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.gastroIntestinal && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Any GastroIntestinal disorders like Pancreatitis,
                          Colitis etc.
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="genitourinary"
                      id="genitourinary"
                      className="proposalCheckBox"
                      checked={diseaseDetails.genitourinary}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span
                      className="label checkBoxLabel"
                      htmlFor="genitourinary"
                    >
                      Genitourinary disorders related to kidney, prostate,
                      urinary system
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.genitourinary && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Genitourinary disorders related to kidney, prostate,
                          urinary system
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="hiv"
                      id="hiv"
                      className="proposalCheckBox"
                      checked={diseaseDetails.hiv}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="hiv">
                      HIV infection AIDS or positive test for HIV
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.hiv && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          HIV infection AIDS or positive test for HIV
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="psychiatric"
                      id="psychiatric"
                      checked={diseaseDetails.psychiatric}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="psychiatric">
                      Psychiatric or mental disorders
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.psychiatric && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">Psychiatric or mental disorders</p>
                      </Summary>
                    </FormGroup>
                  )
                )}
                {!summary ? (
                  <FormGroup
                    formPage
                    md
                    className="scheckboxhealth"
                    proposalCardCheck
                  >
                    <Checkbox
                      name="other"
                      id="other"
                      className="proposalCheckBox"
                      checked={diseaseDetails.other}
                      onChange={e => {
                        handleChange(e);
                      }}
                    />
                    <span className="label checkBoxLabel" htmlFor="other">
                      Any other disorder not mentioned above
                    </span>
                  </FormGroup>
                ) : (
                  diseaseDetails.other && (
                    <FormGroup md eligibilityPage medi4>
                      <Summary>
                        <p className="p">
                          Any other disorder not mentioned above
                        </p>
                      </Summary>
                    </FormGroup>
                  )
                )}

                {checkboxError && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "left",
                      minWidth: "100%",
                    }}
                  >
                    Select minimum one
                  </p>
                )}
                {Object.values(diseaseDetails).includes(true) &&
                  (!summary ? (
                    <FormGroup proposalCardL eligibilityPage>
                      <TextAreaProposal
                        md
                        type="text"
                        name="otherDiagnosedDetails"
                        id="otherDiagnosedDetails"
                        defaultValue={medicalDetailsFour?.otherDiagnosedDetails}
                        ref={register({ required: disease ? true : false })}
                        placeholder=" "
                      />
                      <Label medForm md htmlFor="otherDiagnosedDetails">
                        Diagnosed Details
                      </Label>
                      {errors.otherDiagnosedDetails && (
                        <p className="error">diagnosed details is required</p>
                      )}
                    </FormGroup>
                  ) : (
                    <Summary>
                      <label className="label">Diagnosed Details</label>
                      <p className="p">
                        {medicalDetailsFour?.otherDiagnosedDetails}
                      </p>
                    </Summary>
                  ))}
                {Object.values(diseaseDetails).includes(true) && (
                  <>
                    {!summary ? (
                      <FormGroup
                        className="healthDecmargin"
                        xlg
                        eligibilityPage
                        css={`
                          height: auto !important;
                        `}
                      >
                        <div className="healthQuestion">
                          Would you like to fill the Health Declaration Form ?
                        </div>
                        <RadioComponent
                          // label="Would you like to fill the Health Declaration Form ?"
                          value={healthDeclaration}
                          name="healthDeclaration"
                          updateChecked={setHealthDeclaration}
                          // block
                        />
                      </FormGroup>
                    ) : (
                      <Summary>
                        <label className="label">
                          Would you like to fill the Health Declaration Form ?
                        </label>

                        <p className="p">{healthDeclaration ? "Yes" : "No"}</p>
                      </Summary>
                    )}
                    {healthDeclaration && (
                      <>
                        {!summary ? (
                          <FormGroup
                            className="medFourDec"
                            proposalCardL
                            eligibilityPage
                          >
                            <TextInput
                              md
                              type="text"
                              name="medicalCondition"
                              id="medicalCondition"
                              defaultValue={
                                medicalDetailsFour?.medicalCondition
                              }
                              ref={register({ required: true })}
                              placeholder=" "
                            />
                            <Label medForm md htmlFor="medicalCondition">
                              What is the name of the medical condition?
                            </Label>
                            {errors.medicalCondition && (
                              <p className="error">
                                Medical condition is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              What is the name of the medical condition?
                            </label>
                            <p className="p">
                              {medicalDetailsFour.medicalCondition}
                            </p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup proposalCardL eligibilityPage>
                            <TextInput
                              md
                              type="text"
                              name="diagnosisDate"
                              id="diagnosisDate"
                              defaultValue={medicalDetailsFour?.diagnosisDate}
                              maxLength={10}
                              ref={attachRef}
                              placeholder=" "
                              onInput={numOnly}
                              onChange={handleDate}
                            />
                            <Label medForm md htmlFor="diagnosisDate">
                              What was the date of diagnosis?
                            </Label>
                            {errors.diagnosisDate && (
                              <p className="error">
                                Date of diagnosis is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              What was the date of diagnosis?
                            </label>
                            <p className="p">
                              {medicalDetailsFour?.diagnosisDate}
                            </p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup proposalCardL eligibilityPage>
                            <TextInput
                              md
                              type="text"
                              name="treatmenTerm"
                              id="treatmenTerm"
                              defaultValue={medicalDetailsFour?.treatmenTerm}
                              ref={register({ required: true })}
                              placeholder=" "
                            />
                            <Label medForm md htmlFor="treatmenTerm">
                              How long did the treatment go on for?
                            </Label>
                            {errors.treatmenTerm && (
                              <p className="error">
                                Treatment Term is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              How long did the treatment go on for?
                            </label>
                            <p className="p">
                              {medicalDetailsFour?.treatmenTerm}
                            </p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup
                            proposalCardL
                            eligibilityPage
                            css={`
                              & .medical-condition-text {
                                @media screen and (max-width: 370px) {
                                  margin-top: 47px;
                                }
                              }
                            `}
                          >
                            <TextAreaProposal
                              md
                              type="text"
                              name="condtion"
                              id="condtion"
                              defaultValue={medicalDetailsFour?.condtion}
                              ref={register({ required: true })}
                              placeholder=" "
                              className="medical-condition-text"
                            />
                            <Label medForm md htmlFor="condtion">
                              What is the current state of the medical
                              condition?
                            </Label>
                            {errors.condtion && (
                              <p className="error">
                                State of medical condition is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              What is the current state of the medical
                              condition?
                            </label>
                            <p className="p">{medicalDetailsFour?.condtion}</p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup proposalCardL eligibilityPage>
                            <TextAreaProposal
                              md
                              type="text"
                              name="treatmenDetails"
                              id="treatmenDetails"
                              defaultValue={medicalDetailsFour?.treatmenDetails}
                              ref={register({ required: true })}
                              placeholder=" "
                            />
                            <Label medForm md htmlFor="treatmenDetails">
                              Please provide details of the treatment
                            </Label>
                            {errors.treatmenDetails && (
                              <p className="error">
                                Treatment detail is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              Please provide details of the treatment
                            </label>
                            <p className="p">
                              {medicalDetailsFour?.treatmenDetails}
                            </p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup proposalCardL eligibilityPage>
                            <TextAreaProposal
                              md
                              type="text"
                              name="treatmenTest"
                              id="treatmenTest"
                              defaultValue={medicalDetailsFour?.treatmenTest}
                              ref={register({ required: true })}
                              placeholder=" "
                            />
                            <Label medForm md htmlFor="treatmenTest">
                              What tests have you undergone for treatment?
                            </Label>
                            {errors.treatmenTest && (
                              <p className="error">
                                Treatment test is required
                              </p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              What tests have you undergone for treatment?
                            </label>
                            <p className="p">
                              {medicalDetailsFour?.treatmenTest}
                            </p>
                          </Summary>
                        )}
                        {!summary ? (
                          <FormGroup proposalCardL eligibilityPage>
                            <TextAreaProposal
                              md
                              type="text"
                              name="otherDetails"
                              id="otherDetails"
                              defaultValue={medicalDetailsFour?.otherDetails}
                              ref={register({ required: true })}
                              placeholder=" "
                            />
                            <Label medForm md htmlFor="otherDetails">
                              Any other details you want to provide
                            </Label>
                            {errors.otherDetails && (
                              <p className="error">Other details is required</p>
                            )}
                          </FormGroup>
                        ) : (
                          <Summary>
                            <label className="label">
                              Any other details you want to provide
                            </label>
                            <p className="p">
                              {medicalDetailsFour?.otherDetails}
                            </p>
                          </Summary>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            }
            {!summary && (
              <FormGroup xlg formPage eligibilityPage>
                <button
                  disabled={
                    (disease && checkboxError) || !isValid ? true : false
                  }
                  type="submit"
                  className={
                    (disease && checkboxError) || !isValid
                      ? "btn btn--disabled btn--lg btnf uppercase bold proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg btnf uppercase bold proc formCardBtn propSubmit"
                  }
                >
                  PROCEED TO DOCUMENT SUBMISSION
                </button>
              </FormGroup>
            )}
          </Form>
        </Card>
      </>
    );
  }
};

export default MedicalDetails4;
