import React from "react";
import styled from "styled-components";
export const Step = ({ count, withContinue }) => {
  return <Stepper withContinue={withContinue}>Step {count} of 3</Stepper>;
};

const Stepper = styled.div`
  display: block;
  position: absolute;
  top: 111px;
  right: 39px;
  color: #808080;

  font-size: 14px;
  font-family: "Inter-Regular";

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    top: ${props => (props.withContinue ? "124px" : "124px")};
    right: 55px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
    position: absolute;
    top: 82px;
    right: 16px;
    color: #808080;
  }
`;
