export const data = [
  {
    type: "text",
    name: "idFirstName",
    id: "idFirstName",
    ename: "firstName",
    placeHolder: " ",
    autoComplete: "none",
    label: "First Name",
    readOnly: true,
  },
  {
    type: "text",
    name: "idLastName",
    id: "idLastName",
    ename: "lastName",
    placeHolder: " ",
    autoComplete: "none",
    label: "Last Name",
    readOnly: true,
  },
  {
    type: "text",
    name: "idDob",
    id: "idDob",
    ename: "dob",
    placeHolder: " ",
    autoComplete: "none",
    label: "Date of Birth (DD/MM/YYYY)",
    maxLength: "10",
    readOnly: true,
  },
];
