import React from "react";
import arrowSelect from "../../assets/img/arrow_selectBox.png";
import "./drop-down-head.scss";

function DropDownHead({
  text,
  handleOnClick,
  onClick,
  rotate,
  arrowIcon,
  title,
}) {
  return (
    <div
      title={title || text}
      className="drop-down-head"
      onClick={() => {
        onClick && onClick(text);
      }}
    >
      <div
        style={{ color: handleOnClick ? "lightgray" : "inherit" }}
        className="drop-down-head__text"
      >
        {text}
      </div>
      <img
        style={{ filter: handleOnClick ? "grayscale(100%) opacity(0.3)" : "" }}
        src={arrowIcon || arrowSelect}
        alt="arrow"
        className={rotate ? "rotate" : ""}
      />
    </div>
  );
}

export default DropDownHead;
