import { createSlice } from "@reduxjs/toolkit";

const selectedPlanForm = createSlice({
  name: "selectedPlanForm",
  initialState: {
    selectedPlanDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.selectedPlanDetails = action.payload;
    },
  },
});

export const { saveDetails } = selectedPlanForm.actions;

export const saveSelectedPlanForm = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default selectedPlanForm.reducer;
