import styled from "styled-components";

/* 
'Form Group' has 4 different props-(sm,md,lg,xlg) 
Form GROUP for PRoposal proposalCardL and  proposalCard
'Label' has 2 different props-(md,lg)
'TextInput' has 2 different props-(md,lg)
'Form' and 'FormWrapper' has no props


*/

const mobileLarge = "430px";
const mobileMaxWidth = "767px";

const colorblack = "#333";
const colorgreyblack = "#555";
const colorsecondary = "#107591";
const danger = "#d43d3d ";
export const Form = styled.form`
  display: flex;
  justify-content: ${props => (props.formCenter ? "center" : "space-between")};
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${props => props.uploadFile && "27px"};
  margin-top: ${props => props.topMar && "-27px"};
  @media (max-width: ${mobileMaxWidth}) {
    justify-content: center;
    padding: ${props => (props.greetingsPage ? "0 15px" : null)};
    margin-top: ${props => (props.greetingsPage ? "19px" : null)};
    margin-top: ${props => props.topMar && "0"};
    margin-bottom: ${props => props.uploadFile && "100px"};
    margin-bottom: ${props => props.topMar && "0"};
  }
`;

export const Form1 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: ${mobileMaxWidth}) {
    justify-content: center;
  }
`;

export const FormWrapper = styled.div`
  margin: 0 0 100px;
  padding: ${props => {
    if (props.modifyDetails) return "20px 42px 26px";
  }};
  margin: ${props => {
    if (props.modifyDetails) return "0px";
  }};
  @media (max-width: 767px) {
    padding: ${props => {
      if (props.greetForm1) return null;
      if (props.modifyDetails) return "0px";
      else return "10px";
    }};
    margin: ${props => {
      if (props.greetForm1) return "0 0 40px";
    }};
  }
  @media only screen and (min-width: 414px) and (max-width: 767px) {
    margin: ${props => {
      if (props.greetForm1) return "0 0 38px";
    }};
  }
  @media only screen and (min-width: 375px) and (min-height: 812px) and (max-width: 767px) {
    margin: ${props => {
      if (props.greetForm1) return "0 0 64px";
    }};
  }
  @media only screen and (min-width: 411px) and (max-width: 767px) {
    margin: ${props => {
      if (props.greetForm1) return "0 0 37px";
    }};
  }
`;
export const FormGroup = styled.div`
  position: relative;
  text-align: ${props => {
    if (props.eligibility) return "left";
    if (props.xlg) return "center";
    else return "left";
  }};
  width: ${props => {
    if (props.lg) return "69%";
    else if (props.md) return "49%";
    else if (props.cardElig) return "346px";
    else if (props.proposalCard) return "320px";
    else if (props.proposalCardL) return "100%";
    else if (props.sm) return "30%";
    else if (props.xlg) return "100%";
    else if (props.mds) return "323px";
  }};
  margin-bottom: ${props => props.modifyDetails && "24px"};
  margin-right: ${props => {
    if (props.margElg) return "2px";
    if (props.proposalCard) return "2px";
  }};

  min-height: ${props => {
    if (props.sm && props.eleligibilit) return "0";
    else if (props.cardElig) return "72px";
    else if (props.proposalCard) return "78px";
    else if (props.proposalCardCheck) return "74px !important";
    else if (props.proposalCardL) return "78px";
    if (props.eligHeight) return "72px";
    if (props.medi4) return "40px";
    if ((props.sm || props.lg || props.md) && props.eligibilityPage)
      return "70px";
    if (props.propSubWrap) return "56px";
    if (props.lg || props.sm) return "40px";
    if (props.Icici) return "60px";
    if (props.md) return "66px";
    if (props.checkBoxProp) return "74px";
    /* if (props.formPage) return "60px"; */
    if (props.mds) return "88px";
    else return "60px";
  }};
  &:after,
  &:before {
    content: ${props => {
      if (props.noBeforeAfter) return null;
      else return "";
    }};
    clear: both;
    display: table;
  }
  & .custom-drop-down {
    & .custom-drop-down__head {
      height: 45px;
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: ${props => {
      if (props.greetForm1) {
        return "322px";
      }
    }};
  }
  @media (min-width: 768px) and (max-width: 1177px) {
    width: ${props => {
      if (props.cardElig) {
        return "322px";
      } else if (props.proposalCard) return "310px";
    }};
  }
  @media (max-width: 760px) {
    min-height: 0px;
  }
  @media (max-width: ${mobileMaxWidth}) {
    width: ${props => {
      if (props.mob) {
        return "48%";
      } else return "100% !important";
    }};

    padding: ${props => {
      if (props.modifyDetails) {
        return "0 15px";
      }
    }};
  }

  @media (max-width: ${mobileMaxWidth}) {
    height: ${props => {
      if (props.mobheight) {
        return null;
      } else if (props.eligibilityPage) {
        return "60px";
      } else if (props.eligibilityCard) {
        return "60px";
      } else if (props.modifyDetails) {
        return "58px";
      } else if (props.mobileSm) {
        return "max-content";
      } else if (props.BenificiaryDetails) {
        return "472px";
      } else if (props.OtherInsurance) {
        return "220px";
      } else if (props.proposalPages) {
        return null;
      } else if (props.formPage) {
        return null;
      } else if (props.formPages) {
        return "70px";
      } else if (props.mob) {
        return "64px";
      } else return "78px";
    }};
    margin-bottom: ${props => {}};
    display: ${props => {
      if (props.hid) {
        return "none";
      }
    }};
    width: ${props => {
      if (props.eligibilityPage) {
        return "100%";
      } else return "";
    }};
    min-height: ${props => {
      if (props.modifyDetails) {
        return "60px";
      }
      if (props.mobheight) {
        return "78px";
      }
      if (props.mds) {
        return "63px";
      } else if (props.proposalCardL) {
        return "78px";
      } else if (props.proposalCard) {
        return "78px";
      } else if (props.proposalCardCheck) {
        return "47px !important";
      } else return "60px";
    }};
    padding: ${props => props.uploadFile && "0 10px"};
    top: ${props => props.uploadFile && "40px"};
    top: ${props => props.add_doc && "0px"};
  }
  @media (max-width: 350px) {
    height: ${props => {
      if (props.proposalPage) {
        return "100%";
      }
    }};
  }
`;

export const Label = styled.label`
&:before{
	content:${props => props.labelBefore && `\"${props.labelBefore}\"`};
	margin-left:${props => props.labelBefore && `-20px`};
	margin-right:${props => props.labelBefore && `3px`};
}
  color: ${props => {
    if (props.editMode) {
      return "#107591";
    }
    if (props.elig) return "#333";
    else if (props.md) {
      return "#333333";
    } else return colorblack;
  }};
  position: ${props => {
    if (props.sum) {
      return "static";
    } else return "absolute";
  }};
  font-family: ${props => {
    if (
      props.eligibilityPage ||
      props.lg ||
      props.elig2 ||
      props.formBuilerLabel
    ) {
      return "Inter-Regular";
    } else {
      return "Inter-Medium";
    }
  }};
  transition: all 0.3s;
  cursor: text;
  top: ${props => {
    if (props.lg) return "20%";
    if (props.md) return "12px";
  }};
  left: ${props => {
    if (props.contactPopUp) return "6.1%;";
    if (props.proposalInput) return "20px";
    if (props.frmCard) return "13.5px";
    if (props.lg) return "6.5%";
    if (props.maxReview) return "0px";
    if (props.md) return "15px";
  }};
  font-weight: ${props => {
    if (props.eligibilityPage) return "lighter";
    if (props.lg) return "6.5%";
    else if (props.md) return "15px";
  }};
  font-size: ${props => {
    if (props.form1Page) return "18px";
    if (props.lg) return "14px";
    if (props.eligibilityPage) return "14px";
    if (props.formBuilerLabel) return "14px";
    if (props.elig) return "14px";
    else if (props.md) return "12px";
  }};
  & > span {
    font-size: 14px;
  }
  /*@media (max-width: ${mobileLarge}) {
    font-size: 25px;
    margin: 2px 0;
  }*/
  @media (min-width: 768px) and (max-width: 991px) {
    top: ${props => {
      if (props.form1Page) return "18%";
    }};

    left: ${props => {
      if (props.form1Page) return "6.2%";
    }};
  }
  @media (max-width: 760px) {
    font-size: ${props => {
      if (props.lg) return "14px";
      else if (props.medForm) return "12px";
      else if (props.md) {
        if (props.elgText === "true") {
          return "14px";
        } else if (props.proposalInput) {
          return "14px";
        } else {
          return "12px";
        }
      }
    }}};
    left: ${props => {
      if (props.lg) return "4.8%";
    }};
    font-family: ${props => {
      if (props.elgText === "true") return "Inter-Regular";
    }};
    margin-top: ${props => {
      if (props.mobMargin) return "-3px";
    }};
  }
  @media (max-width: 350px) {
    font-size: ${props => {
      if (props.lg) return "15px";
      else if (props.md) return "12px";
    }};
  }
  @media only screen and (min-width: 414px) and (max-width: 767px) {
    left: ${props => {
      if (props.lg) return "4.1%";
    }};
  }
  @media only screen and (min-width: 411px) and (min-height: 731px) and (max-width: 767px) {
    left: ${props => {
      if (props.lg) return "4.2%";
    }};
  }
`;

export const TextInput = styled.input`
  appearance: none;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  word-spacing: ${props => props.dobSpace && "-3px"};
  overflow: ${props => {
    if (props.upl) return "hidden";
  }};
  padding: ${props => {
    if (props.contactPopUp) return "19px 35px 5px 20px";
    if (props.dob) return "22px 35px 5px 15px;";
    if (props.lg) {
      if (props.pop) {
        return "15px 35px 5px 15px;";
      } else {
        return "15px 35px 15px 15px;";
      }
    }
    if (props.md) return "10px 10px 0 8px";
  }};
  font-weight: 400;
  font-family: ${props => {
    if (props.lg) return "Inter-Regular";
  }};
  font-size: ${props => {
    if (props.formBuilerText) return "14px";
    if (props.lg) return "18px";
    if (props.md) return "15px";
  }};
  border: ${props => {
      if (props.error) return "2px";
      if (props.lg) return "1px";
      if (props.md) return "1px";
    }}
    solid ${props => (props.error === "danger" ? danger : "#E3E4E8")};
  height: ${props => {
    if (props.lg) return "60px";
    if (props.eligInput) return "48px";
    if (props.md) return "45px";
  }};

  & ~ label {
    pointer-events: none;
  }
  &:focus {
    outline: none;
    border: ${props => {
        if (props.error) return "2px solid";
        else return "2px solid";
      }}
      ${props => (props.error === "danger" ? danger : colorsecondary)};
    & ~ label {
      font-size: 12px;
      font-family: "Inter-Medium";
      top: ${props => {
        if (props.contactPopUp) return "7px";
        if (props.lg) return "5px";
        else return "3px";
      }};
      left: ${props => {
        if (props.contactPopUp) return "20px";
        if (props.lg) return "15px";
        else return "10px";
      }};
      /* font-weight: 600;*/
      color: ${colorsecondary};
      & span {
        font-size: 9px;
        font-weight: 800;
      }
    }
  }
  &:not(:placeholder-shown) ~ label {
    font-size: 12px;
    top: ${props => {
      if (props.contactPopUp) return "7px";
      if (props.lg) return "5px";
      else return "3px";
    }};
    left: ${props => {
      if (props.contactPopUp) return "20px";
      if (props.lg) return "15px";
      else return "10px";
    }};
    font-weight: 500;
    font-family: ${props => {
      if (props.formBuilerText) return "Inter-Regular";
      else return "Inter-Medium";
    }};
    color: #107591;
    & span {
      font-size: 9px;
    }
  }

  @media (max-width: ${mobileMaxWidth}) {
    font-size: 14px !important;
    height: 48px;
  }

  @media (max-width: 350px) {
    &:focus {
      & ~ label {
        font-size: 10px;
      }
    }
    &:not(:placeholder-shown) ~ label {
      font-size: 10px;
    }
  }

  @media (max-width: 760px) {
    height: 48px;
  } ;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: ${props =>
    props.md || props.proposer ? "space-between" : "center"};

  align-items: ${props => {
    if (props.tripleRadioBtn) return "center";
  }};

  width: ${props => {
    if (props.md && props.proposer) return "100%";
    if (props.mds) return "323px";
    if (props.md) return "47%";
    if (props.lg) return "100%";
  }};

  @media (min-width: 768px) and(max-width: 991px) {
    width: ${props => {
      if (props.greetForm1) {
        return "322px";
      }
    }};
  }

  margin-bottom: ${props => {
    if (props.tripleRadioBtn) return "10px";
  }};
  @media (max-width: ${mobileMaxWidth}) {
    width: ${props => (props.lg ? "25%" : "100%")};

    text-align: center;
    margin: 0 auto 45px;
  }

  @media (max-width: ${mobileMaxWidth}) {
    margin-bottom: 12px;
    width: 100%;
    /*margin-top: ${props => (props.mob ? null : "-19px")};*/
    margin-top: ${props => {
      if (props.mob) return null;
      if (props.radioForm2) return "-19px";
      if (props.center) return "-20px";
    }};
    padding: ${props => (props.mob ? null : "10px")};
  }
`;

export const RadioLabel = styled.label`
  font-size: 14px;
  min-width: 110px;
  border-radius: 4px;
  margin: ${props => {
    if (props.form2Page) return "5px";
    if (props.lg) return "10px";
  }};
  text-align: ${props => {
    if (props.payment) return "left";
    if (props.form1Page) return "left";
    else return "center";
  }};

  border: ${props => (props.payment ? "none" : "1px solid")}
    ${props => (props.error === "danger" ? danger : "#E3E4E8")};
  color: ${colorblack};
  cursor: pointer;
  transition: all 0.15s;
  height: ${props => {
    if (props.tripleRadioBtn) return "60px";
    if (props.proposal) return "54px";
    if (props.proposer) return "45px";
    if (props.sm || props.payment) return "60px";
    /* if (props.lg) return "180px"; */
    if (props.lg) return "154px";
  }};
  display: ${props => props.lg && "flex"};
  flex-direction: ${props => props.lg && "column"};
  align-items: ${props => props.lg && "center"};
  justify-content: ${props => props.lg && "space-around"};
  justify-content: ${props => props.form2Page && "space-between"};
  width: ${props => {
    if (props.tripleRadioBtn) return "30%";
    if (props.sm) return "48%";
    /* if (props.lg) return "23%"; */
    if (props.lg) return "124px";
    if (props.payment) return "90%";
    if (props.propos) return "49%";
  }};
  padding: ${props => {
    if (props.form1Page) return "null";
    if (props.proposer) return "10px 10px";
    if (props.proposal) return "10px 10px";
    if (props.sm) return "15px 15px";
    if (props.lg) return "0px 15px 20px 10px";
  }};
  font-size: ${props => {
    if (props.form1Page) return "18px";
    if (props.lg) return "20px";
  }};
  & svg {
    height: 55px;
    width: 40px;
    fill: ${colorsecondary};
    @media (max-width: 760px) {
      height: 46px;
      width: 65px;
      margin-top: -8px;
    }
    @media only screen and (min-width: 375px) and (max-width: 767px) {
      margin-left: -1px;
    }
    @media only screen and (min-width: 414px) and (max-width: 767px) {
      margin-left: -2px;
    }
    @media only screen and (min-width: 411px) and (max-width: 731px) {
      margin-left: -2px;
    }
  }
  .radiolabel__container {
    padding: 15px 15px;
    position: relative;
    .radiolabel__text {
      position: absolute;
      top: 14px;
    }
  }
  .radiolabel__formbuilder-container {
    padding: 0px;
    .radiolabel__formbuilder-text {
      top: 3px;
    }
  }
  .radiolabel__container:active {
    transform: translateY(1px) translateX(1px);
  }
  &:hover {
    background-color: #107591;

    color: #fff;
    & .radioBtnHover {
      background-color: #fff;

      border: 2px solid #107591;
      box-sizing: border-box;
    }

    & svg {
      fill: #fff;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: ${props => {
      if (props.greetForm1) {
        return "152px";
      }
    }};
  }
  @media (max-width: 767px) {
    .radiolabel__container {
      padding: unset;
      position: unset;
      .radiolabel__text {
        position: unset;
        font-size: 14px;
      }
    }
    width: ${props => props.lg && "100%"};
    padding: ${props => {
      if (props.form2Page) return "12px 25px 5px 19px";
      else return "10px 37px 5px 15px";
    }};
    flex-direction: row;
    &:not(:last-child) {
      margin-bottom: ${props => props.lg && "7px"};
    }
    min-height: 6px;
    height: ${props => {
      if (props.mobileSm) return "85px !important";
      else return "48px";
    }};
  }
  @media (max-width: 767px) {
    width: 48%;
    width: ${props => {
      if (props.tripleRadioBtn) return "100%";
    }};
    margin-bottom: ${props => {
      if (props.tripleRadioBtn) return "10px";
    }};
    font-size: 13px;
  }
`;

export const RadioLabelForm4 = styled.label`
  &:hover {
    background-color: #107591;
    border: solid 2px #107591;
    color: #fff;
    & span:last-child {
      background-color: #fff;
    }

    & svg {
      fill: #fff;
    }
  }

  font-size: 18px;
  min-width: 110px;
  border-radius: 4px;
  text-align: ${props => (props.payment ? "left" : "center")};
  border: ${props => (props.payment ? "none" : "1px solid")}
    ${props => (props.error === "danger" ? danger : "#e3e4e8")};
  color: #555;
  cursor: pointer;
  transition: all 0.15s;
  height: ${props => {
    if (props.sm || props.payment) return "60px";
    if (props.lg) return "222px";
  }};
  display: ${props => props.lg && "flex"};
  flex-direction: ${props => props.lg && "column"};
  align-items: ${props => props.lg && "center"};
  justify-content: ${props => props.lg && "space-around"};
  width: ${props => {
    if (props.sm) return "45%";
    if (props.lg) return "180px";
    if (props.payment) return "90%";
  }};
  display: flex;
  font-size: 16px;
  line-height: 20px;
  margin: ${props => {
    if (props.lg) return "6px";
  }};
  min-height: 60px;
  padding-top: 20px 15px 10px 10px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  & svg {
    height: 56px;
    margin-bottom: 25px;
    margin-bottom: ${props => {
      if (props.radio3) return "10px";
      else return "25px";
    }};
    fill: ${colorsecondary};
    min-height: 70px;
    margin-top: ${props => {
      if (props.form4Radio) return "-10px";
      if (props.radio2) return "-20px";
      if (props.radio3) return "-10px";
      else return "-30px";
    }};
    width: 60px;
    & input {
      margin-top: 10px;
    }

    @media (max-width: 760px) {
      height: 46px;
      width: 50px;
      min-height: 46px;
      margin-left: 18px;
      margin-bottom: ${props => {
        if (props.radio2 || props.radio3) return "2px";
        if (props.form4Radio) return "2px";
        else return "0px";
      }};
      margin-top: 0px;
    }
    @media only screen and (min-width: 375px) and (max-width: 767px) {
      margin-bottom: ${props => {
        if (props.form4Radio) return "2px";
      }};
    }
    @media only screen and (min-width: 414px) and (max-width: 767px) {
      margin-left: 15px;
    }
  }

  @media (max-width: 767px) {
    width: ${props => props.lg && "100%"};
    flex-direction: row;
    vertical-align: center;
    justify-content: space-around;
    align-items: center;
    min-height: 6px;
    height: 85px !important;
    font-size: 14px;
  }
`;
export const RadioButton = styled.span`
  display: ${props => {
    if (props.kotakAddress) return "none";
    else return "inline-block;";
  }};
  height: 22px;
  width: 22px;
  border: 2px solid #e3e4e8;
  border-radius: 50%;
  transform: translateY(3px);
  position: relative;
  top: ${props => {
    if (props.radio1Form4) return "21px";
    if (props.radio2Form4) return "22px";
    if (props.radio3Form4) return "20px";
  }};

  margin-right: ${props => {
    if (props.marginsm) return "7px";
  }};
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 22px;
    width: 22px;
    position: absolute;
    background-color: ${colorsecondary};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.1s;
  }

  @media (max-width: ${mobileMaxWidth}) {
    margin-top: ${props => {
      if (props.radioMrg) return "-2px";
      if (props.mtop) return "-8px";
      else return "0px";
    }};
    margin-right: ${props => {
      if (props.mtop) return "26px";
      if (props.marginsm) return "9px";
      if (props.radioMrg) return "1px";
    }};
    width: ${props => {
      if (props.form2) return "26px";
      if (
        props.marginsm ||
        props.radio3Form4 ||
        props.radio1Form4 ||
        props.radio2Form4
      )
        return "20px";
    }};
    height: ${props => {
      if (
        props.form2 ||
        props.marginsm ||
        props.radio3Form4 ||
        props.radio1Form4 ||
        props.radio2Form4
      )
        return "20px";
    }};
    top: ${props => {
      if (props.radio1Form4 || props.radio2Form4 || props.radio3Form4)
        return "0px";
    }};
    margin-bottom: ${props => {
      if (props.marginsm) return "-2px";
    }};
  }

  @media only screen and (min-width: 375px) and (max-width: 767px) {
    width: ${props => {
      if (props.form2) return "25px";
    }};
  }

  @media only screen and (min-width: 414px) and (max-width: 767px) {
    margin-right: ${props => {
      if (props.mtop) return "24px";
    }};
  }
`;

export const Radio = styled.input.attrs({
  type: "radio",
})`
  display: none;
  &:checked + label {
    border: 2px solid ${colorsecondary};
    color: white;
    background-color: ${colorsecondary};
  }
  &:checked + label svg {
    fill: white;
  }
  &:checked + label .radioBtnHover {
    background-color: white;
    border: 2px solid #107591;
    box-sizing: border-box;
  }
  &:checked + label span::after {
    opacity: 1;

    width: 10px;
    height: 10px;
    outline: none;
  }
  @media (max-width: ${mobileMaxWidth}) {
    margin-bottom: 0px;
  }
`;

export const AgeWrapper = styled.div`
  position: absolute;
  background-color: ${props => (props.md ? "transparent" : "#f1f2f6")};
  text-align: center;
  top: ${props => (props.md ? "53px" : "57px")};
  right: 5px;
  width: 85px;
  height: ${props => (props.md ? "20px" : "35px")};
  font-family: "Inter-Medium";
  padding: 7px 0;
  font-size: ${props => (props.md ? "12px" : "16px")};
  transform: translate(-10px, -45px);
  border-radius: 4px;
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 75px;
    height: 36px;
  }

  @media (max-width: ${mobileMaxWidth}) {
    font-size: 12px;
    height: 25px;
    width: 59px;
    top: ${props => (props.age ? null : "63px")};
    /* top: 63px;*/
    right: 1px;
  }
`;

export const DropDownWrapper = styled.div`
  margin: 320px auto 0;
  position: ${props => props.form3 && "relative"};
  border-radius: ${props => props.form3 && "4px"};
  margin: ${props => props.form3 && "26px auto 60px"};
  width: ${props => (props.form3 ? "320px" : "50%")};
  height: ${props => props.form3 && "60px"};

  & .custom-drop-down__head {
    width: 100%;
  }

  & > .custom-drop-down {
    & .custom-drop-down__head {
      & > .custom-drop-down__arrow {
        height: 24px;
        width: 24px;
        position: relative;
        top: 1px;
        left: -5px;
        @media (min-width: 768px) and (max-width: 991px) {
          top: 1px;
          left: -1px;
        }
        @media (max-width: 767px) {
          top: 0px;
          left: 0px;
          height: 16px !important;
          width: 16px !important;
        }
      }
      & > .custom-drop-down__arrow--invert {
        filter: invert(32%) sepia(22%) hue-rotate(163deg) saturate(4275%)
          brightness(76%) contrast(60%);
      }
    }
    .drop-down-items .drop-down-items__overflow .drop-down-items__item {
      padding: 15px 20px;
    }
    .drop-down-items__item--selected {
      padding: 15px 20px;
    }
    .drop-down-items__search input {
      height: 47px;
    }
    .drop-down-items__search {
      height: 45px;
    }
    .custom-drop-down__head--open {
      height: 60px;
      .custom-drop-down__selected-text {
        top: 0px;
      }
      .custom-drop-down__arrow {
        left: -4px;
      }
    }
    .custom-drop-down__selected-text {
      width: 90%;
      overflow: hidden;
      margin-left: 10px;
      position: relative;
      top: -1px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: ${props => props.form3 && "26px auto 60px"};
  }

  @media (max-width: ${mobileMaxWidth}) {
    width: 90%;
    margin: 26px auto 60px;
    & > .custom-drop-down {
      & .custom-drop-down__head {
        padding: 12px 13px 12px 8px;
        & > .custom-drop-down__arrow {
          height: 15px;
          width: 15px;
        }
        & > .custom-drop-down__arrow--invert {
          filter: invert(32%) sepia(22%) hue-rotate(163deg) saturate(4275%)
            brightness(76%) contrast(60%);
        }
      }
      .drop-down-items .drop-down-items__overflow .drop-down-items__item {
        padding: 11px 11px 11px 20px;
        font-size: 14px !important;
      }
      .drop-down-items__item--selected {
        padding: 12px 12px 12px 19px;
        font-size: 14px !important;
      }
      .drop-down-items__search input {
        height: 45px;
      }
      .drop-down-items__search {
        height: 43px;
      }
      .custom-drop-down__head--open {
        height: 47px;
        .custom-drop-down__selected-text {
          top: 3px;
        }
        .custom-drop-down__arrow {
          left: 3px;
        }
      }
      .custom-drop-down__selected-text {
        width: 90%;
        overflow: hidden;
        margin-left: 10px;
        font-size: 14px !important;
        position: relative;
        top: 1px;
        left: -3px;
      }
    }
  }
`;

export const CustomDropDownFont = styled.div`
  .custom-drop-down__selected-text,
  .small-custom-drop-down__selected-text {
    font-size: ${props =>
      props.CustomFontBold ? "12px" : props.rdr ? "14px" : "18px"};
    font-family: ${props => props.CustomFontBold && "Inter-Medium"};
    height: 23px;
  }

  .drop-down-items__item {
    font-size: ${props =>
      props.CustomFontBold ? "15px" : props.rdr ? "14px" : "18px"};
  }
  .drop-down-items__item--selected {
    font-size: ${props =>
      props.CustomFontBold ? "15px" : props.rdr ? "14px" : "18px"};
  }

  .small-custom-drop-down__head {
    border: ${props => {
      if (props.AddBorder) return "1px solid rgb(227, 228, 232) !important";
    }};
  }
  @media (max-width: ${mobileMaxWidth}) {
    .small-custom-drop-down__selected-text {
      font-size: ${props => (props.rdr ? "14px !important" : "")};
    }
    .drop-down-items__item--selected {
      font-size: ${props => (props.rdr ? "14px !important" : "")};
    }
    .drop-down-items__item {
      font-size: ${props => (props.rdr ? "14px !important" : "")};
    }
    .custom-drop-down__selected-text {
      font-size: ${props => (props.rdr ? "14px !important" : "")};
    }
  }
`;

export const QuotePageDropDown = styled.div`
  .mobile-filter {
    display: none;
  }

  @media (max-width: ${mobileMaxWidth}) {
    .custom-drop-down {
      display: ${props => {
        if (props.mobileSm) return "none";
      }};
    }
    .custom-drop-down__wrapper {
      display: ${props => {
        if (props.mobileSm) return "none";
      }};
    }
    .mobile-filter {
       {
        display: ${props => {
          if (props.mobileSm) return "flex";
        }};
      }
    }
  }
`;
export const TextInputElg = styled.input`
  appearance: none;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  word-spacing: ${props => props.dobSpace && "-3px"};
  padding: ${props => {
    if (props.proposal) return "28px 20px 9px 20px";
    else if (props.md) return "18px 8px 5px 14px";
  }};
  font-weight: 400;
  font-family: ${props => {
    if (props.lg) return "Inter-Regular";
  }};
  font-size: ${props => {
    if (props.lg) return "18px";
    if (props.md) return "14px";
    if (props.proposal) return "14px";
  }};

  border: ${props => {
      if (props.error) return "2px";
      if (props.lg) return "1px";
      if (props.proposal) return "1px";
      if (props.md) return "1px";
    }}
    solid ${props => (props.error === "danger" ? danger : "#E3E4E8")};
  height: ${props => {
    if (props.lg) return "60px";
    else if (props.proposal) return "54px";
    else if (props.md) return "48px";
  }};

  & ~ label {
    pointer-events: none;
    top: ${props => {
      if (props.proposal) return "16px";
      else return "12px";
    }};
  }
  &:focus {
    .eligibilityFormCard_dob {
      font-size: 8px !important;
      font-family: "Inter-Regular" !important;
    }
    outline: none;
    border: ${props => {
        if (props.error) return "2px solid";
        else return "1px solid";
      }}
      ${props => (props.error === "danger" ? danger : colorsecondary)};
    & ~ label {
      font-size: 12px;
      font-family: "Inter-Medium";
      top: ${props => {
        if (props.md) return "5px";
        else return "9px";
      }};
      left: ${props => {
        if (props.md) return "14px";
        else return "20px";
      }};
      /* font-weight: 600;*/
      font-family: "Inter-Regular";
      color: ${colorsecondary};
      & span {
        font-size: 9px;
        font-weight: 800;
      }
    }
  }
  &:not(:placeholder-shown) ~ label {
    font-size: 12px;
    top: ${props => {
      if (props.md) return "5px";
      else return "9px";
    }};
    left: ${props => {
      if (props.md) return "14px";
      else return "20px";
    }};
    font-weight: 500;
    font-family: "Inter-Regular";
    color: #107591;
    & span {
      font-size: 9px;
    }
  }

  @media (min-width: 768px) and (max-width: 1177px) {
    &:focus {
      & ~ label {
        top: ${props => {
          if (props.md) return "4px";
          else return "9px";
        }};
        left: ${props => {
          if (props.md) return "16px";
          else return "20px";
        }};
      }
    }
    &:not(:placeholder-shown) ~ label {
      top: ${props => {
        if (props.md) return "4px";
        else return "9px";
      }};
      left: ${props => {
        if (props.md) return "16px";
        else return "20px";
      }};
    }
    padding: ${props => {
      if (props.proposal) return "28px 20px 9px 20px";
      else if (props.md) return "18px 8px 5px 14px";
    }};
  }
`;

export const TextAreaProposal = styled.textarea`
	appearance: none;
	width: 664px;
	
	border-radius: 4px;
	background-color: rgba(227, 228, 232, 0.61);
	color: #333333;
  padding:  12px 16px ;
	font-weight: 400;
	font-family: ${props => {
    if (props.lg) return "Inter-Regular";
  }};
	font-size: 14px;
	margin-top:${props => {
    if (props.largeLabel) return "58px";
    else return "20px";
  }};
	margin-bottom:24px;
	border: ${props => {
    if (props.error) return "2px";
    else return "1px";
  }}
		solid ${props => (props.error === "danger" ? danger : "#E3E4E8")};
	height: 116px ;
	}};


	@media (min-width: 768px) and (max-width: 1177px) {
		width: 644px;
	}
	@media (max-width: 767px) {
		width:100%;
		height: 54px;
	}
	& ~ label {
		pointer-events: none;
		position:absolute;
    top: -4px;
    left: 0px;
    font-size: 14px
		}};
	}

	&:focus {
		outline: none;
		border: ${props => {
      if (props.error) return "2px solid";
      else return "1px solid";
    }}
			${props => (props.error === "danger" ? danger : colorsecondary)};
	
`;
