import React, { useState, useRef, useEffect } from "react";
import { useOutsideClick, useKeydownEvent } from "../../custom-hooks";
import { DropDownItems } from "../";
import arrowSelect from "../../assets/img/arrow_selectBox.png";
import arrowSelectElg from "../../assets/img/eligibility_24px.svg";

import "./custom-drop-down.scss";

function CustomDropDown({
  refState,
  items,
  searchable,
  searchStartsWith,
  customInput,
  min,
  max,
  multiple,
  selectedItem,
  className,
  expand,
  style,
  onChange,
  isOpen,
  notAllowed,
  validate,
  validateMessage,
  currentSelected,
  addOnBtn,
  addOnBtnOnClick,
  disabled,
  roundTo,
  qtsMob,
  elgMob,
  noArrow,
  mobDrop,
  scrollActiveToMiddle,
  proposalDropDown,
  propDropDown,
  dropDownTitle,
  eligibilityCard,
  annualIncome = false,
  landing,
  error,
  onBlur,
  wholeLife,
}) {
  const [open, setOpen] = useState(expand);
  const [selected, setSelected] = useState(
    selectedItem ? selectedItem : items[0],
  );

  const dropDownRef = useRef(null);

  useOutsideClick(dropDownRef, () => setOpen(false), refState);

  useKeydownEvent("Escape", () => setOpen(false));

  const handleItemClick = item => {
    setSelected(item);
    setOpen(false);
    onChange && onChange(item);
  };
  useEffect(() => {
    if (currentSelected) setSelected(currentSelected);
  }, [currentSelected]);
  useEffect(() => {
    isOpen && isOpen(open);
  }, [open, isOpen]);

  const AddOnButton = text => (
    <div
      className="drop-down-items__addon-btn"
      onClick={() => {
        setOpen(false);
        addOnBtnOnClick();
      }}
    >
      {text}
    </div>
  );
  const handleEnterPress = e => {
    if (disabled) return;
    if (e.key === "Enter" || e.key === "ArrowUp" || e.key === "ArrowDown") {
      setOpen(!open);
    }
  };
  return (
    <div
      tabIndex="0"
      className={
        mobDrop ? "custom-drop-down custom-drop-downPop" : "custom-drop-down "
      }
      ref={dropDownRef}
      onKeyDown={handleEnterPress}
      onBlur={onBlur}
    >
      <div
        className={`dropDownLabelProposal ${ eligibilityCard ? "dropDownLabelElig" : ""
          }`}
      >
        {" "}
        {dropDownTitle && !open ? dropDownTitle : ""}{" "}
      </div>
      {!open ? (
        <div
          // title={selected}
          className={`custom-drop-down__head ${ validate && notAllowed && notAllowed === selected && "border-red"
            } ${ className ? className : "" } ${ disabled ? "custom-drop-down__disabled" : ""
            } ${ elgMob ? "elgDropH" : "" }  ${ propDropDown ? "propDropDown" : ""
            } ${ error ? "custom-drop-down__head--error" : "" }`}
          style={style ? style : {}}
          onClick={() => setOpen(true)}
        >
          <span
            className={
              qtsMob === "true"
                ? "mobText custom-drop-down__selected-text "
                : elgMob === "true"
                  ? `mobTextElg custom-drop-down__selected-text  ${ eligibilityCard ? "dropDownContentElig" : ""
                  }`
                  : `custom-drop-down__selected-text  ${ proposalDropDown === "true" ? "proposalDropDown" : ""
                  } `
            }
          >
            {currentSelected || selected}
          </span>
          <img
            src={elgMob === "true" ? arrowSelectElg : arrowSelect}
            alt="arrow"
            className={
              noArrow === "true"
                ? "noArrorDrop"
                : `custom-drop-down__arrow ${ elgMob === "true" ? "custom-drop-down-elg__arrow" : ""
                }`
            }
          />

          { }
        </div>
      ) : (
        <div className="custom-drop-down__wrapper ">
          <div
            // title={selected}
            className={`custom-drop-down__head custom-drop-down__head--open ${ error ? "custom-drop-down__head--error" : ""
              }`}
            style={style ? style : {}}
            onClick={() => setOpen(false)}
          >
            <span
              className={
                qtsMob === "true"
                  ? "mobText custom-drop-down__selected-text "
                  : elgMob === "true"
                    ? `mobTextElg custom-drop-down__selected-text `
                    : `custom-drop-down__selected-text  ${ elgMob === "true" ? "custom-drop-down-elg__arrow" : ""
                    } `
              }
            >
              {currentSelected || selected}
            </span>
            <img
              src={elgMob === "true" ? arrowSelectElg : arrowSelect}
              alt="arrow"
              className={
                noArrow === "true"
                  ? "noArrorDrop "
                  : "custom-drop-down__arrow custom-drop-down__arrow--invert"
              }
            />
          </div>
          <DropDownItems
            qtsMob
            searchStartsWith={searchStartsWith}
            notAllowed={notAllowed && notAllowed}
            items={items}
            handleClick={handleItemClick}
            selected={selected}
            searchable={searchable}
            customInput={customInput}
            addOnBtn={addOnBtn && AddOnButton(addOnBtn)}
            min={min}
            max={max}
            multiple={multiple}
            roundTo={roundTo}
            scrollActiveToMiddle={scrollActiveToMiddle}
            dropDownRef={dropDownRef}
            annualIncome={annualIncome}
            setOpen={setOpen}
            wholeLife={wholeLife}
          />
        </div>
      )}

      {validate && (
        <p
          style={{
            fontSize: "14px",
            color: "#d43d3d",
            position: "absolute",
            fontFamily: "Inter-Regular",
          }}
        >
          {validateMessage ? validateMessage : "This is required*"}
        </p>
      )}
    </div>
  );
}

export default CustomDropDown;
