const AGE_PROOF = [
  "Aadhaar card",
  "Birth Certificate",
  "BFL Standard",
  "Baptism certificate",
  "Bonafide School Certificate",
  "Domicile certificate with communication address and photograph",
  "Permanent Driving License",
  "Service Record/Employer Certificate",
  "E.S.I.S Card",
  "Hospital Discharge card",
  "Higher Secondary Mark List/certified extract from school",
  "Id Cards issued by Defence",
  "Life Insurance Policy from LIC or any other private Life insurance company",
  "Marriage Certificate of Christians issued by a Roman Catholic Church",
  "Pan Card",
  "Passport",
  "Ration Card",
  "School/college certificate",
  "School Leaving certificate",
  "Voters Id",
  "Village Panchayat",
  "Written confirmation from the banks",
  "Not Required",
];
const ID_PROOF = [
  "Aadhaar card",
  "Bank Account Statement Not Older Than 6 Months",
  "Pan Card",
  "B.C From Village Panchayat Councillor or Corporator",
  "School or College Certificate",
  "School Transfer Certificate ",
  "Domiciles Certificate With Communication Address And Photograph",
  "Central or State Government Certified Id Proof",
  "Driving License",
  "Written Confirmation From The Banks Certifying Identity Proof ",
  "PIO Booklet For Returning NRIS",
  "Passport",
  "School Leaving",
];
const ADDRESS_PROOF = [
  "Aadhaar card",
  "Leave and Licence Agreement",
  "House Allotment Letter",
  "Utility Bill Gas",
  "Identity Cards Issued By Defence Department",
  "Certified From Service Register Of Govt. And Quasi Govt Org",
  "Employers Certificate",
  "Bank Account Statement Not Older Than 6 Months",
  "Telephone Bill Not Older Than 6 Months",
  "Electricity Bill",
  "Passport",
  "Driving License",
  "Written Confirmation From The Banks Certifying Identity Proof",
  "Lease Agreement Along With Rent Receipt Not Older Than 3 Months",
  "Central or State Government Certified Id Proof ",
  "Domiciles Certificate With Communication Address And Photograph",
  "B.C From Village Panchayat Councillor or Corporator",
  "Document with address issued by Central or State Gov",
  "Gram Panchayat Certificate(Rural)",
];
const INCOME_PROOF_A = [
  "The latest three years ITRs of Life Assured.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Copy of Land Documents in the name of Life Assured.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Form J.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Proof of agriculture income & Land Documents in the name of Life Assured / From J / tehsildhar income certificate.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
];
const INCOME_PROOF_B = [
  "The latest three years ITRs of Life Assured.",
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Certified copies of the Annual accounts of company for the last two years.",
  "Copies of latest audited profit and loss account and balance sheet for the last three years along with statement showing the capital account of the partners.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
];

const INCOME_PROOF_P = [
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Certified copies of the Annual accounts of company for the last two years.",
  "Copies of latest audited profit and loss account and balance sheet for the last three years along with statement showing the capital account of the partners.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
  "The latest three years ITRs of Life Assured.",
];
const INCOME_PROOF_R = [
  "Latest Form 16 or Form 16 A.",
  "Balance Sheet, Profit and Loss account of last three years.",
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "Bank account statement reflecting pension credit",
  "Copy of Land Documents in the name of Life Assured.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Form J.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest rent agreement along with Rent receipt of Last three months for address proof.",
  "Proof of agriculture income & Land Documents in the name of Life Assured / From J / tehsildhar income certificate.",
  "Proof of income of the employee in the form of latest Form 16, last 2 years individual income tax returns and last three months salary slips (certified by company).",
  "Statement of income, assets and liabilities from CA in BALIC format mentioning clearly the source of information.",
  "The latest three years ITRs of Life Assured.",
  "Salary Slip and Bank statement",
];
const INCOME_PROOF_SG = [
  "Latest Form 16 or Form 16 A.",
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Latest Employment contract letter on company letter head.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest three months salary slips of Life Assured.",
  "Proof of income of the employee in the form of latest Form 16, last 2 years individual income tax returns and last three months salary slips (certified by company).",
  "The latest three years ITRs of Life Assured.",
];
const INCOME_PROOF_SN = [
  "Latest Form 16 or Form 16 A.",
  "Bank Account Statement of past six months reflecting salary credit attested by bank authority.",
  "Bank Account Statement with Account no. and other details of Life Assured not older than 6 months.",
  "FINANCIAL ASSESSMENT REPORT BY REGIONAL HEAD/GENERAL MANAGER",
  "Latest Employment contract letter on company letter head.",
  "Latest ITR for financial Underwriting / Premium Paying Capacity.",
  "Latest three months salary slips of Life Assured.",
  "Proof of income of the employee in the form of latest Form 16, last 2 years individual income tax returns and last three months salary slips (certified by company).",
  "The latest three years ITRs of Life Assured.",
  "Salary Slip and Bank statement",
];

export {
  AGE_PROOF,
  ID_PROOF,
  ADDRESS_PROOF,
  INCOME_PROOF_SN,
  INCOME_PROOF_SG,
  INCOME_PROOF_R,
  INCOME_PROOF_P,
  INCOME_PROOF_B,
  INCOME_PROOF_A,
};
