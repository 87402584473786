import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import LaxmiGreeting from "../../../components/LaxmiGreeting/LaxmiGreeting";
import backArrow from "../../../assets/img/back_Arrow.svg";
import PlanCard from "../../../components/PlanCard/PlanCard";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import tooltip from "../../../assets/img/tooltipElig.svg";
import {
  medicalDetails1FormData,
  medicalDetails1FormData2,
} from "./formsdata/medicalDetails1";
import { FATCADetailsFormData } from "./formsdata/FATCADetails";
import {
  medicalDetails2Data,
  medicalDetails2bData,
} from "../BajaProposalPage/formsdata/MedicalDetails2";
import { medicalDetails3Data } from "../BajaProposalPage/formsdata/MedicalDetails3";
import { medicalDetails4Data } from "../BajaProposalPage/formsdata/MedicalDetails4";
import { covid19, covid19Form } from "../BajaProposalPage/formsdata/Covid-19";
import {
  BAJAJ_COUNTRIES,
  countries,
} from "../BajaProposalPage/formsdata/Countries.utils";
import { bajajContactDetailsData } from "../BajaProposalPage/formsdata/bajajContactDetails";
import { bajajNomineeDetailsData } from "../BajaProposalPage/formsdata/bajajNomineeDetails";
import { bajajPayerDetailsData } from "../BajaProposalPage/formsdata/bajajPayerDetails";
import { NRIAndPreviousInsuranceDetails } from "./formsdata/NRI&PreviousInsuranceDetails";
import { basicDetailsData } from "./formsdata/basicDetails";
import { occupationDetailsData } from "./formsdata/occupationDetails";
import Loader from "../../../components/Loader/Loader";
import { FormGroup } from "../../../components/Form/Form";
import SecureLS from "secure-ls";
import {
  setMedicalDetails1,
  setFATCADetails,
  setNomineeDetails,
  setContactDetails,
  setPayerDetails,
  setNriAndPrevDetails,
  setMedicalDetails2,
  setMedicalDetails3,
  setMedicalDetails4,
  setCovid19Details,
  setBasicDetails,
  setOccupationDetails,
  setBankAccountDetails,
  initLoader,
  saveBajajProposalDetails,
} from "./bajajProposalPage.slice";
import { bankAccountDetailsData } from "./formsdata/bankAccountDetails";
import {
  saveBajajPlanCardDetails,
  saveBajajSingleQuoteDetails,
} from "./bajajSingleQuotes/bajajSingleQuote.slice";
import {
  getMaritalStatusBajaj,
  getNationKotak,
  getPremiumPayerBajaj,
  getRelationBajaj,
  getRelationAppointeeBajaj,
  getEducationBajaj,
  getOccupBajaj,
  getIDProofBajaj,
  getAddressProofBajaj,
  getIncomeProofBajaj,
  getAgeProofBajaj,
  getIndTypeBajaj,
  getRenewBajaj,
  getTobaccoBajaj,
  getCountryOfResidence,
} from "../../../utils/bajajCodeUtils";
import useSaveProposalForm from "../useSaveProposalForm";
import {
  basicDetailsSendData,
  insuredContactSendData,
  payerSendData,
  nomineeSendData,
  bankDetailsSendData,
  occupationDetailsSendData,
  nriDetailsSendData,
  covid19sendData,
  medSet4sendData,
  medSet3sendData,
  medSet2sendData,
  medSet1sendData,
  factaSendData,
} from "./bajajSendData";

import {
  appendSendData,
  setPosition,
} from "../IcicProposalPage/IcicProposal.slice";
import NriOtherInsurance from "./NriOtherInsurance";
import MedicalDetails2 from "./bajajMedicalDetails2";
import {
  BAJAJCOUNTRIES,
  educationOptionsHdfc,
} from "../../../components/EligibilityCard/options";
import { useCustomLocation } from "../../../custom-hooks";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { setIsProposalSubmitted } from "../proposalPage.slice";
import { checkpinCode } from "../../EligibilityPage/serviceApi";
import {
  handleEligibilityGrid,
  selectEligibilityGrid,
} from "../../EligibilityPage/eligibilityCard.slice";
import NomineeForm from "./NomineeForm";
import { data } from "jquery";
import { getAllData } from "../../../api/getAllData";
const countiresWithoutIndia = () => {
  return countries.filter(data => {
    return data !== "India";
  });
};

const BajajProposalPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const { selectedQuote } = useSelector(state => state.quote);
  const { eligibilityDetails, regionDetails } = useSelector(
    state => state.eligibilityCard,
  );
  const { customerDetails, isWipro, isPnbPreference } = useSelector(
    state => state.greeting,
  );
  const { additionalRiders, selectedAddOns } = useSelector(
    ({ rider }) => rider,
  );

  const { position } = useSelector(state => state.iciciProposal);

  const {
    FATCADetails,
    medicalDetails1,
    contactDetails,
    payerDetails,
    nomineeDetails,
    nriAndPrevDetails,
    medicalDetails2,
    medicalDetails3,
    medicalDetails4,
    covid19Details,
    basicDetails,
    occupationDetails,
    bankAccountDetails,
    loading,
    response,
    error,
  } = useSelector(state => state.bajajProposalPageForm);

  const { bajajProposalData, ckyc, ckycLoading } = useSelector(
    ({ bajajProposalPageForm }) => bajajProposalPageForm,
  );

  const ls = new SecureLS();
  const applicationNumber = sessionStorage.getItem("appNo");
  const traceId = sessionStorage.getItem("traceId");
  const userId = sessionStorage.getItem("userId");

  const { bajajSingleQuote, bajajPlanCard } = useSelector(
    state => state.bajajSingleQuote,
  );
  const clickRef = useRef();
  const [apiData, setApiData] = useState({});
  const [productApi, setProductApi] = useState({});
  useEffect(() => {
    setApiData(bajajSingleQuote.quote_data);
    setProductApi(bajajSingleQuote.selected_product);
  }, [bajajSingleQuote]);

  const [noPreg, setNoPreg] = useState(true);
  useEffect(() => {
    if (
      customerDetails.gender !== "F" ||
      basicDetails?.meritalStatus !== "Married"
    ) {
      setNoPreg(false);
    } else {
      setNoPreg(true);
    }
  }, [basicDetails, customerDetails]);

  useEffect(() => {
    if (response?.status === true) {
      swal(response.message, {
        icon: "success",
      }).then(() => {
        clickRef.current.click();
        dispatch(setIsProposalSubmitted(true));
        // if (ckyc.status === "true") {
        //   history.push(`/thankyou?enquiryId=${ enquiryId }`);
        // } else history.push(`/documentUploadBajaj?enquiryId=${ enquiryId }`);
        history.push(`/otp-verification?enquiryId=${enquiryId}`);
      });
    }

    if (error?.status === false) {
      swal(error.message, {
        icon: "error",
      });
    }
  }, [response, error]);

  const { previousSendData } = useSelector(
    ({ iciciProposal }) => iciciProposal,
  );

  const { enquiryId } = useCustomLocation();

  const [areaList, setAreaList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const getRegion = async data => {
      try {
        const response = await checkpinCode(data);
        if (response.data) return response.data;
        alert("Something went wrong while fetching region details.");
      } catch (error) {
        alert("Something went wrong while fetching region details.");
        console.error(error);
      }
    };
    getRegion({
      pinCode: eligibilityDetails?.pinCode,
      state: eligibilityDetails?.state,
      city: eligibilityDetails?.selectedCity,
      companyAlias: "bajaj_life",
      traceId: enquiryId,
    }).then(regionDetails => {
      if (regionDetails.status) setAreaList(regionDetails.area);
    });
  }, []);

  const handleSubmit = () => {
    //     const sendData = {
    //       traceId: traceId,
    //       applicationNo: applicationNumber,

    //       /*
    // 			quoteId: "01", //ls
    // 			uniqid: "2119f2aa4cbd4fb46d1b",
    // 			user_id: userId,
    // 			afyp_value: 740.677966101695,
    // 			term_insu_id: "1", //api
    // 			policy_no: "", //api
    // 			txn_status: "",
    // 			return_data: "",
    // 			pay_option: "Regular Pay",
    // 			pay_frequency: "03",
    // 			product_name: "Life- Lump Sum", //api
    // 			plan_code: "", //api
    // 			product_ulip_variant: "",
    // 			product_protection_variant: "",
    // 			product_variant_name: "",
    // 			plan_name: "test_plan",
    // 			policy_term: "10",
    // 			pincode: "400089", //api
    // 			tobacco_habit: "N",
    // 			insured_dob: "2001-10-22", //api
    // 			insured_gender: "F", //api
    // 			pay_lumpsum: "0", //api
    // 			ppt: "10",
    // 			sum_assured: 10000000, //api
    // 			base_premium: 740.677966101695, //api
    // 			premium_amount: "874", //api

    // 			//***************/

    //       title: basicDetails?.title,
    //       firstName: basicDetails?.firstName,
    //       lastName: basicDetails?.lastName,
    //       gender: basicDetails?.gender === "Male" ? "M" : "F",
    //       dob: basicDetails["dob"]?.split("/").reverse().join("-"),
    //       birthPlace: basicDetails?.placeOfBirth,
    //       nationality: getNationKotak(basicDetails?.nationality),
    //       nationalityText: basicDetails?.nationality,
    //       countryOfResidence: getCountryOfResidence(
    //         basicDetails?.countryOfResidence
    //       ),
    //       countryOfResidenceText: basicDetails?.countryOfResidence,
    //       maritalStatus: getMaritalStatusBajaj(basicDetails?.meritalStatus),
    //       maritalStatusText: basicDetails?.meritalStatus,
    //       fatherName: basicDetails?.fatherName,
    //       motherName: basicDetails?.motherName,
    //       spouseName: basicDetails?.spouseName,
    //       spouseDob: basicDetails?.spouseDob,
    //       spouseBirthPlace: basicDetails?.spousePlaceOfBirth,
    //       pep: basicDetails?.IsPoliticallyExposed === "No" ? "N" : "Y",
    //       pepDesc: basicDetails?.politicalDescription,
    //       email: contactDetails?.email,

    //       commPer:
    //         contactDetails?.contactSamePermanentAddress === "Yes" ? "Y" : "N",
    //       commAddressDoorNo: contactDetails?.contactDoorNo,
    //       commAddressBuildingName: contactDetails?.contactBuilding,
    //       commAddressPlotNo: contactDetails?.cstreet,
    //       commAddressPlace: contactDetails?.cplace,
    //       commAddressPincode: contactDetails?.cpincode?.pincode,
    //       commAddressState: contactDetails?.cpincode?.state,
    //       commAddressCity: contactDetails?.cpincode?.city,
    //       commAddressArea: contactDetails?.clandmark,
    //       permanentAddressDoorNo:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.contactRDoorNo1,
    //       permanentAddressBuildingName:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.contactRBuilding1,
    //       permanentAddressPlotNo:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.crstreet1,
    //       permanentAddressPlace:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.crplace1,
    //       permanentAddressPincode:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.cpincode1.pincode,
    //       permanentAddressState:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.cpincode1.state, //pincodemaste
    //       permanentAddressCity:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.cpincode1.city, //pincodmaster
    //       permanentAddressArea:
    //         contactDetails?.contactSamePermanentAddress === "No" &&
    //         contactDetails?.cpincode1.city,
    //       premiumPaidBy: getPremiumPayerBajaj(payerDetails?.paidBy),
    //       premiumPaidByText: payerDetails?.paidBy,
    //       premiumPayerName: payerDetails?.PremiumPayername,
    //       premiumPayerRelationship: getRelationBajaj(payerDetails?.payerRelation),
    //       premiumPayerRelationshipText: payerDetails?.payerRelation,
    //       premiumPayerDob: payerDetails["payerDob"]?.split("/").reverse().join("-"),
    //       premiumPayerGender: payerDetails?.payerGender === "Male" ? "M" : "F",
    //       premiumPayerAddress: payerDetails?.PremiumPayerAddress,
    //       nomineeName: nomineeDetails?.nomineeName,
    //       nomineeBirthPlace: nomineeDetails?.nomineeBirthPlace,
    //       nomineeDob: nomineeDetails["ndob"]?.split("/").reverse().join("-"),
    //       nomineeRelationship: getRelationBajaj(
    //         nomineeDetails?.relationWithInsured
    //       ),
    //       nomineeRelationshipText: nomineeDetails?.relationWithInsured,
    //       appointeeName: nomineeDetails?.appointeeName,
    //       appointeeDob: nomineeDetails["adob"]?.split("/").reverse().join("-"),
    //       appointeeRelationToProposer: getRelationAppointeeBajaj(
    //         nomineeDetails?.arelationWithInsured
    //       ),
    //       appointeeRelationToProposerText: nomineeDetails?.arelationWithInsured,
    //       renewalDeposit: getRenewBajaj(bankAccountDetails?.renewalDeposit),
    //       renewalDepositText: bankAccountDetails?.renewalDeposit,
    //       bankName: bankAccountDetails?.bankName,
    //       branchName: bankAccountDetails?.branchName,
    //       accountNo: bankAccountDetails?.accountNo,
    //       micrCode: bankAccountDetails?.micrCode,
    //       accountType: bankAccountDetails?.accountType,
    //       accountTypeText: bankAccountDetails?.accountType,
    //       ifscCode: bankAccountDetails?.ifscCode,
    //       education: getEducationBajaj(occupationDetails?.education),
    //       educationText: occupationDetails?.education,
    //       pan: eligibilityDetails?.pan,
    //       occupation: getOccupBajaj(occupationDetails?.occupation),
    //       occupationText: occupationDetails?.occupation,
    //       annualIncome: occupationDetails?.annualIncome,
    //       incomeProof: getIncomeProofBajaj(occupationDetails?.incomeProof),
    //       incomeProofText: occupationDetails?.incomeProof,
    //       ageProof: getAgeProofBajaj(occupationDetails?.ageProof),
    //       ageProofText: occupationDetails?.ageProof,
    //       addressProof: getAddressProofBajaj(occupationDetails?.addressProof),
    //       addressProofText: occupationDetails?.addressProof,
    //       idProof: getIDProofBajaj(occupationDetails?.idProof),
    //       idProofText: occupationDetails?.idProof,
    //       industryType: getIndTypeBajaj(occupationDetails?.industryType),
    //       industryTypeText: occupationDetails?.industryType,
    //       armedForceBranch: occupationDetails?.branchOfArmedForces1,
    //       armedForceRank: occupationDetails?.rank1,
    //       armedForceLocation: occupationDetails?.currentLocationOfPosting1,
    //       combactRoleFlag: occupationDetails?.jobInvolvesCombactRole1,
    //       bombDisposalFlag: occupationDetails?.isPartOfBombDisposalSquad1,
    //       height: medicalDetails1?.height,
    //       weight: medicalDetails1?.weight,
    //       changedWeight: medicalDetails1?.weightChanged,
    //       changeInWeight: medicalDetails1?.gained1,
    //       causeChangeInWeight: medicalDetails1?.gained2,
    //       tobaccoProduct: getTobaccoBajaj(medicalDetails1?.usedAsProduct),
    //       tobaccoProductText: medicalDetails1?.usedAsProduct,
    //       tobaccoQuantity: medicalDetails1?.quantityT,
    //       tobaccoQuitSince: medicalDetails1?.ifQuit?.replace(/ /g, ""),
    //       alcoholHabit: medicalDetails1?.alcohol === "Yes" ? "Y" : "N",
    //       alcoholConsumption: medicalDetails1?.quantity,
    //       alcoholQuantity: medicalDetails1?.frequency,
    //       alcoholIncrease: medicalDetails1?.increased,
    //       alcoholIncreaseText: medicalDetails1?.increased,
    //       narcoticsHabit: medicalDetails2?.narcoticTreatment === "Y" ? "Y" : "N",
    //       narcoticsHabitText: medicalDetails2?.narcoticTreatmentText,
    //       criminalConvicted: medicalDetails2?.criminalCharges === "Y" ? "Y" : "N",
    //       criminalConvictedText: medicalDetails2?.criminalChargesText,
    //       adventureAvocation: medicalDetails2?.extremeSports === "Y" ? "Y" : "N",
    //       adventureAvocationText: medicalDetails2?.extremeSportsText,
    //       pregnantQuestion: medicalDetails2?.pregnantQuestion === "Y" ? "Y" : "N",
    //       gynecologicalQuestion:
    //         medicalDetails2?.gynecologicalQuestion === "Y" ? "Y" : "N",
    //       insuredHealthQuestion: medicalDetails2?.diagnosed === "Y" ? "Y" : "N", //doubt
    //       insuredHealthQuestionText: medicalDetails2?.diagnosedtext,
    //       insuranceCoverage: medicalDetails2?.insuranceCoverage,
    //       annualIncomeHusband: medicalDetails2?.annualIncomeHusband,
    //       chestPainQuestion: medicalDetails3?.Disease1 === "Y" ? "Y" : "N",
    //       chestPainDiseaseName: medicalDetails3?.Disease1DropDown,
    //       chestPainDiseaseDiagnosed: medicalDetails3?.Disease1Time,
    //       chestPainCurrMedication: medicalDetails3?.Disease1CurrentMedication,
    //       chestPainControlVal: medicalDetails3?.Disease1ControlValue,
    //       chestPainOtherComplication: medicalDetails3?.Disease1ControlComplication,
    //       angioplastyQuestion: medicalDetails3?.Disease2 === "Yes" ? "Y" : "N",
    //       angioplastyDiseaseName: medicalDetails3?.Disease2DropDown,
    //       angioplastyDiseaseDiagnosed: medicalDetails3?.Disease2Time,
    //       angioplastyCurrMedication: medicalDetails3?.Disease2Medication,
    //       angioplastyControlVal: medicalDetails3?.Disease2Value,
    //       angioplastyOtherComplication: medicalDetails3?.Disease2Complication,
    //       diabetesQuestion: medicalDetails3?.diseases3 === "Yes" ? "Y" : "N",
    //       diabetesQuestiondiabetesDiseaseName: medicalDetails3?.diseases3Diseases,
    //       diabetesQuestiondiabetesDiseaseDiagnosed: medicalDetails3?.diseases3Time,
    //       diabetesCurrMedication: medicalDetails3?.diseases3Medication,
    //       diabetesControlVal: medicalDetails3?.diseases3ControlValue,
    //       diabetesOtherComplication: medicalDetails3?.diseases3Complication,
    //       asthmaQuestion: medicalDetails3?.diseases4 === "Yes" ? "Y" : "N",
    //       asthmaQuestionasthmaDiseaseName: medicalDetails3?.diseases4Diseases,
    //       asthmaDiseaseDiagnosed: medicalDetails3?.diseases4Time,
    //       asthmaCurrMedication: medicalDetails3?.diseases4Medication,
    //       asthmaControlVal: medicalDetails3?.diseases4ControlValue,
    //       asthmaOtherComplication: medicalDetails3?.diseases4Complication,
    //       strokeQuestion: medicalDetails3?.diseases5 === "Yes" ? "Y" : "N",
    //       strokeDiseaseName: medicalDetails3?.diseases5Diseases,
    //       strokeDiseaseDiagnosed: medicalDetails3?.diseases5Time,
    //       strokeCurrMedication: medicalDetails3?.diseases5Medication,
    //       strokeControlVal: medicalDetails3?.diseases5ControlValue,
    //       strokeOtherComplication: medicalDetails3?.diseases5Complication,
    //       pancreaQuestion: medicalDetails3?.diseases6 === "Yes" ? "Y" : "N",
    //       pancreaDiseaseName: medicalDetails3?.diseases6Diseases,
    //       pancreaDiseaseDiagnosed: medicalDetails3?.diseases6Time,
    //       pancreaCurrMedication: medicalDetails3?.diseases6Medication,
    //       pancreaControlVal: medicalDetails3?.diseases6ControlValue,
    //       pancreaOtherComplication: medicalDetails3?.diseases6Complication,
    //       liverQuestion: medicalDetails3?.diseases7 === "Yes" ? "Y" : "N",
    //       liverDiseaseName: medicalDetails3?.diseases7Diseases,
    //       liverDiseaseDiagnosed: medicalDetails3?.diseases7Time,
    //       liverCurrMedication: medicalDetails3?.diseases7Medication,
    //       liverControlVal: medicalDetails3?.diseases7ControlValue,
    //       liverOtherComplication: medicalDetails3?.diseases7Complication,
    //       kidneyQuestion: medicalDetails4?.diseases8 === "Yes" ? "Y" : "N",
    //       kidneyDiseaseName: medicalDetails4?.diseases8Diseases,
    //       kidneyDiseaseDiagnosed: medicalDetails4?.diseases8Time,
    //       kidneyCurrMedication: medicalDetails4?.diseases8Medication,
    //       kidneyControlVal: medicalDetails4?.diseases8ControlValue,
    //       kidneyOtherComplication: medicalDetails4?.diseases8Complication,
    //       cancerQuestion: medicalDetails4?.diseases9 === "Yes" ? "Y" : "N",
    //       cancerDiseaseName: medicalDetails4?.diseases9Diseases,
    //       cancerDiseaseDiagnosed: medicalDetails4?.diseases9Time,
    //       cancerCurrMedication: medicalDetails4?.diseases9Medication,
    //       cancerControlVal: medicalDetails4?.diseases9ControlValue,
    //       cancerOtherComplication: medicalDetails4?.diseases9Complication,
    //       hivQuestion: medicalDetails4?.diseases10 === "Yes" ? "Y" : "N",
    //       hivDiseaseName: medicalDetails4?.diseases10Diseases,
    //       hivDiseaseDiagnosed: medicalDetails4?.diseases10Time,
    //       hivCurrMedication: medicalDetails4?.diseases10Medication,
    //       hivControlVal: medicalDetails4?.diseases10ControlValue,
    //       hivOtherComplication: medicalDetails4?.diseases10Complication,
    //       anemiaQuestion: medicalDetails4?.diseases11 === "Yes" ? "Y" : "N",
    //       anemiaDiseaseName: medicalDetails4?.diseases11Diseases,
    //       anemiaDiseaseDiagnosed: medicalDetails4?.diseases11Time,
    //       anemiaCurrMedication: medicalDetails4?.diseases11Medication,
    //       anemiaControlVal: medicalDetails4?.diseases11ControlValue,
    //       anemiaOtherComplication: medicalDetails4?.diseases11Complication,
    //       handicapQuestion: medicalDetails4?.diseases12 === "Yes" ? "Y" : "N",
    //       handicapDiseaseName: medicalDetails4?.diseases12Diseases,
    //       handicapDiseaseDiagnosed: medicalDetails4?.diseases12Time,
    //       handicapCurrMedication: medicalDetails4?.diseases12Medication,
    //       handicapControlVal: medicalDetails4?.diseases12ControlValue,
    //       handicapOtherComplication: medicalDetails4?.diseases12Complication,
    //       surgeryQuestion: medicalDetails4?.diseases13 === "Yes" ? "Y" : "N",
    //       surgeryDiseaseName: medicalDetails4?.diseases13Diseases,
    //       surgeryDiseaseDiagnosed: medicalDetails4?.diseases13Time,
    //       surgeryCurrMedication: medicalDetails4?.diseases13Medication,
    //       surgeryControlVal: medicalDetails4?.diseases13ControlValue,
    //       surgeryOtherComplication: medicalDetails4?.diseases13Complication,
    //       sensesQuestion: medicalDetails4?.diseases14 === "Yes" ? "Y" : "N",
    //       sensesDiseaseName: medicalDetails4?.diseases14Diseases,
    //       sensesDiseaseDiagnosed: medicalDetails4?.diseases14Time,
    //       sensesCurrMedication: medicalDetails4?.diseases14Medication,
    //       sensesControlVal: medicalDetails4?.diseases14ControlValue,
    //       sensesOtherComplication: medicalDetails4?.diseases14Complication,
    //       medicalExaminationQuestion:
    //         medicalDetails4?.diseases15 === "Yes" ? "Y" : "N",
    //       medicalExaminationQuestionText: medicalDetails4?.diseases15text,
    //       //	biCitizenship: "",
    //       citizenship: "IN", //doubt
    //       citizenshipText: "INDIAN",

    //       nriFlag: nriAndPrevDetails?.travelAbroad === "Yes" ? "Y" : "N",
    //       nriCountry: BAJAJ_COUNTRIES[nriAndPrevDetails?.countryName] || "",
    //       nriCountryText: nriAndPrevDetails?.countryName || "",
    //       nriCity: nriAndPrevDetails?.cityName || "",
    //       nriDuration: nriAndPrevDetails?.purpose || "",
    //       modifiedTerm: nriAndPrevDetails?.requestDeclined === "Yes" ? "Y" : "N",
    //       annualPremiumPolicy: nriAndPrevDetails?.annualPremium || "",

    //       eiaFlag: occupationDetails?.haveEIA === "Yes" ? "Y" : "N",
    //       eiaRepository: occupationDetails?.eiaInsuranceRepository,
    //       natureOfDuties: occupationDetails?.natureofDuties,
    //       employersName: occupationDetails?.employersName, //doubt
    //       employersAddress: occupationDetails?.employersAddress,
    //       residentOutside: FATCADetails?.resident === "Yes" ? "Y" : "N",
    //       residentCountry: FATCADetails?.resident1,
    //       //	residentCountryText:  FATCADetails?.resident1,
    //       residentCountryAddress: FATCADetails?.resident2,
    //       taxresidentOutside: FATCADetails?.tax === "Yes" ? "Y" : "N",
    //       taxresidentCountry: FATCADetails?.tax1,
    //       taxresidentCountryTin: FATCADetails?.tax2,
    //       telephoneOutside: FATCADetails?.telephoneNo === "Yes" ? "Y" : "N",
    //       telephoneMobile: FATCADetails?.telephoneNo1,
    //       telephoneLandline: FATCADetails?.telephoneNo2,
    //       accountOutside: FATCADetails?.instructions === "Yes" ? "Y" : "N", //doubt
    //       accountOutsideText: FATCADetails?.instructions1,
    //       attorneyOutside: FATCADetails?.attorney === "Yes" ? "Y" : "N",
    //       attorneyPerson: FATCADetails?.attorney1,
    //       attorneyAddress: FATCADetails?.attorney2,
    //       attorneyContact: FATCADetails?.attorney3,
    //       holdmailOutside: FATCADetails?.holdMail === "Yes" ? "Y" : "N",
    //       holdmailText: FATCADetails?.holdMail1,
    //       travelAbroad: covid19Details?.travelledAbroad === "Yes" ? "Y" : "N",
    //       abroadCountry: covid19Details?.pastTravelsCountries,
    //       abroadCity: covid19Details?.pastTravelsCities,
    //       abroadDateArr: covid19Details["pastTravelsDateArrived"]
    //         ?.split("/")
    //         .reverse()
    //         .join("-"),
    //       abroadDateDep: covid19Details["pastTravelsDateDeparted"]
    //         ?.split("/")
    //         .reverse()
    //         .join("-"),
    //       futureCountry: covid19Details?.futureTravelsCountries,
    //       futureCity: covid19Details?.futureTravelsCities,
    //       futureDateArr: covid19Details["futureTravelsDateArrived"]
    //         ?.split("/")
    //         .reverse()
    //         .join("-"),
    //       futureDateDep: covid19Details["futureTravelsDateDeparted"]
    //         ?.split("/")
    //         .reverse()
    //         .join("-"),
    //       covidOne: covid19Details?.covid19symtoms === "Yes" ? "Y" : "N",
    //       covidOneText: covid19Details?.covid19symtomstext,
    //       covidTwo:
    //         covid19Details?.covid19ContactWithInfected === "Yes" ? "Y" : "N",
    //       covidTwoText: covid19Details?.covid19ContactWithInfectedtext,
    //       covidThree: covid19Details?.covid19Test === "Yes" ? "Y" : "N",
    //       covidThreeText: covid19Details?.covid19Testtext,
    //       covidFour: covid19Details?.covid19Occupation === "Yes" ? "Y" : "N",
    //       covidFourText: covid19Details?.covid19Occupationtext,
    //       covidFive: covid19Details?.covid19doctor === "Yes" ? "Y" : "N",
    //       //	covidFive_text: covid19Details?.pastTravelsCountries,

    //       holdPolicy: nriAndPrevDetails?.haveOtherInsurance === "Yes" ? "Y" : "N",
    //       familyHealthQuestion:
    //         medicalDetails2?.haveFamilyDiseaseHistory === "Y" ? "Y" : "N",
    //       otherInsuranceCompany:
    //         nriAndPrevDetails?.haveOtherInsurance === "Yes"
    //           ? nriAndPrevDetails?.otherInsuranceList
    //           : null,
    //       familyDetails:
    //         medicalDetails2?.haveFamilyDiseaseHistory === "Y" //renameRequired
    //           ? medicalDetails2?.familyDiseaseHistoryList
    //           : null,

    //       /*
    // 			familyDetails: [
    // 				{
    //   familyMemberText : "Father",
    //   familyAge :50,
    //   familyHealthStatus : "Not Alive",
    //   familyAgeDie : "47",
    //  familyDeathCause : ""AL
    // }
    // 			],
    // */
    //       /*************************************
    // 			policy_start_date: "03-12-2020",
    // 			policy_end_date: "01-01-1970",
    // 			pg_failure_remark: "",
    // 			txnid: "2119f2aa4cbd4fb46d1b",
    // 			hash:
    // 				"fc1ea6ca503f82a6d12a6513b2b220bb06fd7bc85f6c43833a7d77b8748c1c997eb302ea46e154f2bd002644e7a097a71d31d3446329efd536ec2c9baa96231d",
    // 			soacorrelationid: 748309,
    // 			residential_status: "01",
    // 			proposal_date: "2020-12-03 10:27:04",
    // 			*/
    //       ...previousSendData,
    //     };
    setFormSubmitted(true);
    const bajajSendData = {
      traceId: enquiryId,
      applicationNo: applicationNumber,
      ...previousSendData,
    };
    Object.entries(bajajSendData).forEach(val => {
      const key = val[0];
      const value = val[1];
      if (typeof value === "string") {
        if (value?.includes("/") && value?.length === 10) {
          bajajSendData[key] = value?.split("/").reverse().join("-");
        }
      }
    });
    if (!bajajSendData.familyDetails) {
      if (bajajSendData.familyHealthDetailsJson)
        bajajSendData.familyDetails = JSON.parse(
          bajajSendData.familyHealthDetailsJson,
        );
    }
    dispatch(saveBajajProposalDetails(bajajSendData));
    dispatch(initLoader(true));

    setFormSubmitted(false);
  };
  const [expandForm, setExpandForm] = useState(0);
  const { annualIncome } = useSelector(
    ({ greeting }) => greeting.customerDetails,
  );

  const { riderGst } = useSelector(state => state.rider);

  const [agreeTerm, setAgreeTerm] = useState(false);
  const [agreeTerm1, setAgreeTerm1] = useState(false);
  const [agreeTerm2, setAgreeTerm2] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (eligibilityDetails?.isNRI === "YES") {
      if (agreeTerm && agreeTerm1 && agreeTerm2) {
        setAgree(true);
      } else {
        setAgree(false);
      }
    } else {
      if (agreeTerm && agreeTerm1) {
        setAgree(true);
      } else {
        setAgree(false);
      }
    }
  }, [agreeTerm, agreeTerm1, agreeTerm2]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  useEffect(() => {
    dispatch(
      handleEligibilityGrid({
        company_alias: "bajaj_life",
        trace_id: enquiryId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (position !== 13 && (agreeTerm1 || agreeTerm2 || agreeTerm)) {
      setAgreeTerm(false);
      setAgreeTerm1(false);
      setAgreeTerm2(false);
    }
  }, [position]);
  const {
    isLoading: isEligibilityGridLoading,
    isError,
    icValues: { educationICValues, occupationICValues },
  } = useICValues();

  if (isEligibilityGridLoading) return <Loader />;

  if (isError)
    return <p>Error while getting education and occupation IC values</p>;

  return (
    <>
      {(isLoading || ckycLoading) && <Loader />}
      {response?.proposalPdfUrl && (
        <>
          <a
            href={response?.proposalPdfUrl}
            style={{
              textDecoration: "none",
              color: "white",
              display: "hidden",
            }}
            target="_blank"
            ref={clickRef}
          />
        </>
      )}
      {/* <div className="eligibility__back elgBackBtn">
        <span>
          <Link to="/" className="btn--tertiary backBtn">
            {" "}
            <img
              src={backArrow}
              alt="arrow"
              style={{ height: "15px" }}
              className="backImg"
            />
            Back
          </Link>
        </span>
      </div> */}
      <div className="application_no">
        {<span>Application Number: {sessionStorage.getItem("appNo")}</span>}
      </div>
      <div className="container proposalContainer">
        <LaxmiGreeting />
        <div className="eligibility__header eligibility__main-header">
          <div className="eligibility__greeting">
            <h2 className="heading__secondary">
              Almost done! Help me with few more details.
            </h2>
          </div>
        </div>
        <div className="eligibility__body">
          <div className="eligibility__side">
            <PlanCard
              selectedQuote={selectedQuote}
              additionalRiders={additionalRiders}
              buyNowBtn={false}
              inbuiltCovers={selectedQuote.inbuilt_riders}
              addOnsList={selectedAddOns}
              additionalGst={riderGst}
            />
          </div>

          <div className="eligibility__main">
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={0}
              onSubmit={data => {
                saveProposalForm(basicDetailsSendData(data), () => {
                  dispatch(appendSendData(basicDetailsSendData(data)));
                  dispatch(setBasicDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO INSURED CONTACT DETAILS"}
              title={"Basic Details"}
              formItems={basicDetailsData({
                ...bajajProposalData,
                firstName: customerDetails.firstName,
                midddleName: customerDetails?.midddleName,
                lastName: customerDetails.lastName,
                gender: customerDetails.gender === "M" ? "Male" : "Female",
                dob: customerDetails.dob.split(" / ").join("/"),
                isNri: eligibilityDetails?.isNRI,
                selectedCountry: eligibilityDetails?.selectedCountry,
              })}
            />

            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={1}
              onSubmit={data => {
                saveProposalForm(insuredContactSendData(data), () => {
                  dispatch(appendSendData(insuredContactSendData(data)));
                  dispatch(setContactDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO PREMIUM PAYER DETAILS"}
              title={"Insured Contact Details"}
              formItems={bajajContactDetailsData({
                ...bajajProposalData,
                email: eligibilityDetails.secondary_email,
                phone: customerDetails.mobileNo,
                regionDetails: regionDetails.pinCodeMaster,
                pincode: eligibilityDetails?.pinCode,
                state: eligibilityDetails?.state,
                city: eligibilityDetails?.selectedCity,
                area: eligibilityDetails?.selectedArea,
                areaList: areaList,
              })}
            />

            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={2}
              onSubmit={data => {
                saveProposalForm(payerSendData(data), () => {
                  dispatch(appendSendData(payerSendData(data)));
                  dispatch(setPayerDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO NOMINEE DETAILS"}
              title={"Premium Payer Details"}
              formItems={bajajPayerDetailsData({
                ...bajajProposalData,
                firstName: eligibilityDetails.firstName,
                lastName: eligibilityDetails.lastName,
                gender: customerDetails.gender === "M" ? "Male" : "Female",
                dob: eligibilityDetails.dob,
              })}
            />
            {/* <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={3}
              onSubmit={data => {
                saveProposalForm(nomineeSendData(data), () => {
                  dispatch(appendSendData(nomineeSendData(data)));
                  dispatch(setNomineeDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO BANK ACCOUNT DETAILS"}
              title={"Nominee Details"}
              formItems={bajajNomineeDetailsData({
                ...bajajProposalData,
              })}
            /> */}
            {/* <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={2}
              onSubmit={data => {
                saveProposalForm(payerSendData(data), () => {
                  dispatch(appendSendData(payerSendData(data)));
                  dispatch(setPayerDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO NOMINEE DETAILS"}
              title={"Premium Payer Details"}
              formItems={bajajPayerDetailsData({
                ...bajajProposalData,
                firstName: eligibilityDetails.firstName,
                lastName: eligibilityDetails.lastName,
                gender: customerDetails.gender === "M" ? "Male" : "Female",
                dob: eligibilityDetails.dob,
              })}
            /> */}
            <NomineeForm
              proposalData={bajajProposalData}
              expand={position}
              index={3}
              setExpandForm={setExpandForm}
              onSubmit={nomineeSendData => {
                saveProposalForm(nomineeSendData, () => {
                  dispatch(appendSendData(nomineeSendData));
                  dispatch(setNomineeDetails(nomineeSendData));
                  dispatch(
                    // setPosition(isNri === "YES" ? position + 1 : position + 2)
                    setPosition(position + 1),
                  );
                });
              }}
            />
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={4}
              onSubmit={data => {
                saveProposalForm(bankDetailsSendData(data), () => {
                  dispatch(appendSendData(bankDetailsSendData(data)));
                  dispatch(setBankAccountDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO OCCUPATION DETAILS"}
              title={"Bank Account Details"}
              formItems={bankAccountDetailsData({
                ...bajajProposalData,
              })}
            />

            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={5}
              onSubmit={async data => {
                const sendData = await occupationDetailsSendData(
                  { ...data, ckyc },
                  {
                    educationICValues,
                    occupationICValues,
                  },
                );
                saveProposalForm(sendData, () => {
                  dispatch(appendSendData(sendData));
                  dispatch(setOccupationDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO NRI DETAILS"}
              title={"Occupation Details"}
              formItems={occupationDetailsData({
                ...bajajProposalData,
                occupation: eligibilityDetails.selectedOccupation,
                education: eligibilityDetails.selectedEducation,
                annualIncome: annualIncome,
                coverAmount: Number(
                  selectedQuote.sumAssured || selectedQuote.cover_amount,
                ),
                age: customerDetails.age,
                isWipro: isWipro,
                isPnbPreference: isPnbPreference,
                ckyc,
              })}
            />

            {/* <FormBuilder
              expand={position}
              setExpandForm={setExpandForm}
              index={0}
              onSubmit={(data) => {
                let index;
                // isNri === "YES" ? (index = 1) : (index = 2);
                saveProposalForm(nriDetailsSendData(data), () => {
                  dispatch(appendSendData(nriDetailsSendData(data)));
                  dispatch(setNriAndPrevDetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO FATCA DETAILS"}
              title={"NRI & Previous Insurance Details"}
              formItems={NRIAndPreviousInsuranceDetails({
                ...bajajProposalData,
                nri: isNri !== "YES",
              })}
            /> */}
            <NriOtherInsurance
              proposalData={bajajProposalData}
              expand={position}
              index={6}
              enquiryId={enquiryId}
              setExpandForm={setExpandForm}
              onSubmit={nriSendData => {
                saveProposalForm(nriSendData, () => {
                  dispatch(appendSendData(nriSendData));
                  dispatch(
                    // setPosition(isNri === "YES" ? position + 1 : position + 2)
                    setPosition(position + 1),
                  );
                });
              }}
            />
            {/* {eligibilityDetails.isNri === "YES" && ( */}
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={7}
              onSubmit={data => {
                saveProposalForm(factaSendData(data), () => {
                  dispatch(appendSendData(factaSendData(data)));
                  dispatch(setFATCADetails(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO Medical Questions - Set 1"}
              title={"FATCA Details"}
              formItems={FATCADetailsFormData({
                ...bajajProposalData,
                countries: Object.keys(BAJAJCOUNTRIES),
                isNri: eligibilityDetails?.isNRI,
                selectedCountry: eligibilityDetails?.selectedCountry,
              })}
            />
            {/* )} */}

            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={8}
              onSubmit={data => {
                saveProposalForm(medSet1sendData(data), () => {
                  dispatch(appendSendData(medSet1sendData(data)));
                  dispatch(setMedicalDetails1(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO Medical Questions - Set 2"}
              title={"Medical Questions - Set 1"}
              formItems={
                customerDetails.tobaccoHabit === "N"
                  ? medicalDetails1FormData({
                      ...bajajProposalData,
                      tobaccoHabit: bajajProposalData.tobaccoHabit
                        ? bajajProposalData.tobaccoHabit === "Y"
                          ? "Yes"
                          : "No"
                        : customerDetails.tobaccoHabit === "Y"
                        ? "Yes"
                        : "No",
                      gender:
                        customerDetails.gender === "M" ? "Male" : "Female",
                    })
                  : medicalDetails1FormData2({
                      ...bajajProposalData,
                      tobaccoHabit: bajajProposalData.tobaccoHabit
                        ? bajajProposalData.tobaccoHabit === "Y"
                          ? "Yes"
                          : "No"
                        : customerDetails.tobaccoHabit === "Y"
                        ? "Yes"
                        : "No",
                      gender:
                        customerDetails.gender === "M" ? "Male" : "Female",
                    })
              }
            />
            <MedicalDetails2
              proposalData={bajajProposalData}
              expand={position}
              setExpandForm={setExpandForm}
              index={9}
              onSubmit={data => {
                saveProposalForm(medSet2sendData(data), () => {
                  dispatch(appendSendData(medSet2sendData(data)));
                  dispatch(setMedicalDetails2(data));
                  dispatch(setPosition(position + 1));
                });
              }}
            />
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={10}
              onSubmit={data => {
                saveProposalForm(medSet3sendData(data), () => {
                  dispatch(appendSendData(medSet3sendData(data)));
                  dispatch(setMedicalDetails3(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO Medical Questions - Set 4"}
              title={"Medical Questions - Set 3"}
              formItems={medicalDetails3Data({ ...bajajProposalData })}
            />
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={11}
              onSubmit={data => {
                saveProposalForm(medSet4sendData(data), () => {
                  dispatch(appendSendData(medSet4sendData(data)));
                  dispatch(setMedicalDetails4(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO COVID-19"}
              title={"Medical Questions - Set 4"}
              formItems={medicalDetails4Data({ ...bajajProposalData })}
            />
            <FormBuilder
              proposalPages
              expand={position}
              setExpandForm={setExpandForm}
              index={12}
              onSubmit={data => {
                saveProposalForm(covid19sendData(data), () => {
                  dispatch(appendSendData(covid19sendData(data)));
                  dispatch(setCovid19Details(data));
                  dispatch(setPosition(position + 1));
                });
              }}
              submitButton={"PROCEED TO DOCUMENT SUBMISSION"}
              title={"COVID-19"}
              formItems={covid19Form({
                ...bajajProposalData,
                countries,
                age: customerDetails.age,
              })}
            />
            <div className="mo-2 d-flex justify-content-center">
              <div className="form-group u-mb-12">
                <div className="m-4 mo-1 form-check d-flex checkBoxWrapper checkBoxWrapProposal">
                  <div className="checkBoxElg">
                    <Checkbox
                      elg
                      name="agreeTerm"
                      checked={agreeTerm}
                      disabled={position !== 13}
                      id="agreeTerm"
                      onChange={e => setAgreeTerm(e.target.checked)}
                    ></Checkbox>
                  </div>
                  <div
                    className="form-check-label eligibility__footer dv elgTerms "
                    style={{ textAlign: "justify" }}
                  >
                    I confirm that the answers I have given are, to the best of
                    my knowledge, true, and that I have not withheld any
                    material information that may influence the assessment or
                    acceptance of this application.
                  </div>
                  <div
                    className="form-check-label eligibility__footer mv termsMob"
                    style={{ textAlign: "justify" }}
                  >
                    I accept Terms & Conditions
                    <CustomTooltip
                      rider="true"
                      id="RiderInbuilt__Tooltip"
                      place={"top"}
                      customClassName="mt-3  elgToolTipData"
                      Position={{ top: 20, left: 4 }}
                    >
                      <img
                        data-tip="<h3 >Terms & Conditions</h3> <div>I confirm that the answers I have given are, to the best of my knowledge, true, and that I have not withheld any material information that may influence the assessment or acceptance of this application. </div>"
                        data-html={true}
                        data-for="RiderInbuilt__Tooltip"
                        src={tooltip}
                        alt="tooltip"
                        className="termsTooltip"
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
            {eligibilityDetails?.isNRI === "YES" && (
              <div className="mo-2 d-flex justify-content-center">
                <div className="form-group u-mb-12">
                  <div className="m-4 mo-1 form-check d-flex checkBoxWrapper checkBoxWrapProposal">
                    <div className="checkBoxElg">
                      <Checkbox
                        elg
                        name="agreeTerm2"
                        checked={agreeTerm2}
                        disabled={position !== 13}
                        id="agreeTerm2"
                        onChange={e => setAgreeTerm2(e.target.checked)}
                      ></Checkbox>
                    </div>
                    <div
                      className="form-check-label eligibility__footer dv elgTerms "
                      style={{ textAlign: "justify" }}
                    >
                      I, the declarant named herein below, has made a proposal
                      for life insurance with Bajaj Allianz Life Insurance
                      Company Limited for issuance of a life insurance policy
                      denominated in Indian Rupee. Being a nonresident Indian
                      and generally residing outside India, I understand that
                      all premiums payable under the policy and all benefits
                      under the policy by Bajaj Allianz Life Insurance Company
                      shall be in Indian currency. I further understand that the
                      convertibility into foreign currency of benefits paid by
                      Bajaj Allianz Life Insurance Company Limited in Indian
                      currency shall be in accordance with the terms of Foreign
                      Exchange Management Act,1999 (“FEMA”) and other applicable
                      laws of India. Bajaj Allianz Life Insurance Company shall
                      not be responsible for any issues arising out of
                      convertibility into foreign currency of benefits under the
                      policy in accordance with the terms of FEMA another
                      applicable laws of India. The benefits under the policy
                      will be paid by Bajaj Allianz Life Insurance Company
                      Limited in Indian currency and in India.
                    </div>
                    <div
                      className="form-check-label eligibility__footer mv termsMob"
                      style={{ textAlign: "justify" }}
                    >
                      I accept Terms & Conditions
                      <CustomTooltip
                        rider="true"
                        id="RiderInbuilt__Tooltip"
                        place={"top"}
                        customClassName="mt-3  elgToolTipData"
                        Position={{ top: 20, left: 4 }}
                      >
                        <img
                          data-tip="<h3 >Terms & Conditions</h3> <div>I, the declarant named herein below, has made a proposal for life insurance with Bajaj Allianz Life Insurance Company Limited for issuance of a life insurance policy denominated in Indian Rupee. Being a nonresident Indian and generally residing outside India, I understand that all premiums payable under the policy and all benefits under the policy by Bajaj Allianz Life Insurance Company shall be in Indian currency. I further understand that the convertibility into foreign currency of benefits paid by Bajaj Allianz Life Insurance Company Limited in Indian currency shall be in accordance with the terms of Foreign Exchange Management Act,1999 (“FEMA”) and other applicable laws of India. Bajaj Allianz Life Insurance Company shall not be responsible for any issues arising out of convertibility into foreign currency of benefits under the policy in accordance with the terms of FEMA another applicable laws of India. The benefits under the policy will be paid by Bajaj Allianz Life Insurance Company Limited in Indian currency and in India.
												</div>"
                          data-html={true}
                          data-for="RiderInbuilt__Tooltip"
                          src={tooltip}
                          alt="tooltip"
                          className="termsTooltip"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mo-2 d-flex justify-content-center">
              <div className="form-group">
                <div className="m-4 mo-1 form-check d-flex checkBoxWrapper checkBoxWrapProposal">
                  <div className="checkBoxElg">
                    <Checkbox
                      elg
                      name="agreeTerm1"
                      checked={agreeTerm1}
                      disabled={position !== 13}
                      id="agreeTerm1"
                      onChange={e => setAgreeTerm1(e.target.checked)}
                    ></Checkbox>
                  </div>
                  <div
                    className="form-check-label eligibility__footer dv elgTerms "
                    style={{ textAlign: "justify" }}
                  >
                    I agree that this form will constitute part of my
                    application for insurance(s) and that failure to disclose
                    any material fact known to me may invalidate my
                    insurance(s).
                  </div>
                  <div
                    className="form-check-label eligibility__footer mv termsMob"
                    style={{ textAlign: "justify" }}
                  >
                    I accept Terms & Conditions
                    <CustomTooltip
                      rider="true"
                      id="RiderInbuilt__Tooltip"
                      place={"top"}
                      customClassName="mt-3  elgToolTipData"
                      Position={{ top: 20, left: 4 }}
                    >
                      <img
                        data-tip="<h3 >Terms & Conditions</h3> <div>I agree that this form will constitute part of my application for insurance(s) and that failure to disclose any material fact known to me may invalidate my insurance(s). </div>"
                        data-html={true}
                        data-for="RiderInbuilt__Tooltip"
                        src={tooltip}
                        alt="tooltip"
                        className="termsTooltip"
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
            {agree && (
              <div style={{ marginTop: "10px" }} className="proposalBtnWrapper">
                <FormGroup xlg eligibilityPage>
                  <button
                    type="submit"
                    disabled={formSubmitted}
                    onClick={handleSubmit}
                    className={
                      "mobilebtn btn btn--highlighted btn--lg uppercase bold buttton submitProposal"
                    }
                  >
                    <span className="proceed proposalSubmitText">Submit</span>
                  </button>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loader>Your proposal details are getting submitted.</Loader>}
    </>
  );
};

export default BajajProposalPage;

function useICValues() {
  const {
    isLoading: isEligibilityGridLoading,
    isUninitialized,
    data: eligibilityGrid,
  } = useSelector(selectEligibilityGrid);

  const icValues = {};

  if (isUninitialized || isEligibilityGridLoading)
    return { isLoading: true, icValues };

  if (!eligibilityGrid) return { isError: true, icValues };

  const { education_list, occupation_list } = eligibilityGrid;

  const educationICValues = education_list.reduce(
    (educationICValues, education) => {
      educationICValues[education.education] = education.education_ic_value;
      return educationICValues;
    },
    {},
  );

  const occupationICValues = occupation_list.reduce(
    (occupationICValues, occupation) => {
      occupationICValues[occupation.occupation] =
        occupation.occupation_ic_value;
      return occupationICValues;
    },
    {},
  );

  icValues.educationICValues = educationICValues;
  icValues.occupationICValues = occupationICValues;

  return { icValues };
}
