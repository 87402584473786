import React, { useEffect } from "react";
import exclamation from "../../assets/img/exclamation.svg";
import LaxmiGreeting from "../../components/LaxmiGreeting/LaxmiGreeting";
import { Button } from "./../../components/StyledButton/Button";
import {
  paymentStart,
  saveReciept,
} from "../../components/ReviewPopUp/payment.slice";
import { useSelector, useDispatch } from "react-redux";
// import SecureLS from "secure-ls";
import "./unsuccessful.scss";
import { getAllData } from "../../api/getAllData";
import {
  clearLoader,
  initLoader,
  initPaymentStatusLoader,
  saveAppNo,
  saveBiData,
  saveDetail,
  saveEligibilityDetails,
  saveResponse,
  setRepayLoader,
} from "../EligibilityPage/eligibilityCard.slice";
import { createRegionData } from "../GreetingPage/greeting.slice";
import { updateQuotation } from "../ProposalPage/serviceApi";
import { useCustomLocation } from "../../custom-hooks";
import Loader from "../../components/Loader/Loader";
import {
  paymentDetailsToIc,
  savePaymentDetails,
} from "../../components/ReviewPopUp/serviceApi";
import {
  setIsPaymentSuccess,
  setStatusLogged,
} from "../EligibilityPage/eligibilityPage.slice";
import SecureLS from "secure-ls";
import { useNavigation } from "../../custom-hooks/useNavigation";
const Unsuccessful = ({ history }) => {
  // const ls = new SecureLS();
  useNavigation();
  const dispatch = useDispatch();
  const { enquiryId, gotoPage, pathname, redirectTo } = useCustomLocation();
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const { response } = useSelector(state => state.payment);
  const { selectedQuote } = useSelector(state => state.quote);
  const { customerDetails } = useSelector(state => state.greeting);
  const searchParams = new URLSearchParams(window.location.search);
  const afflid = searchParams.get("afflid");
  const utm_medium = searchParams.get("utm_medium");
  const c_id = searchParams.get("c_id");
  const { biUrl, loader, rePayLoader, appNo } = useSelector(
    state => state.eligibilityCard,
  );
  // const { company_logo } = selectedQuote;

  const { firstName, lastName, email, mobileNo } = customerDetails;
  const ls = new SecureLS();
  const userId = sessionStorage.getItem("userId");
  const [isPaymentAlreadySuccess, setIsPaymentAlreadySuccess] =
    React.useState(false);

  useEffect(() => {
    dispatch(saveDetail(false));
    dispatch(saveBiData(false));
    dispatch(saveAppNo(false));
    dispatch(createRegionData(false));
    dispatch(saveResponse(false));
  }, []);

  useEffect(() => {
    // if (Object.keys(selectedQuote).length === 0) {
    dispatch(initLoader(true));
    dispatch(getAllData(history, null, enquiryId)).then(data => {
      dispatch(initLoader(false));
      if (data?.payment_status === "success") {
        setIsPaymentAlreadySuccess(true);
      }
      if (data?.last_visited?.otp === "true") {
        gotoPage(redirectTo.pages.documentuploadpage);
      } else if (data?.last_visited?.proposal === "true") {
        gotoPage(redirectTo.pages.otpverification);
      } else if (
        data?.payment_status !== "success" &&
        !data?.app_no &&
        data?.company_alias === "icici_pru"
      ) {
        gotoPage(redirectTo.pages.eligibilitypage);
      } else if (
        data?.company_alias === "bajaj_life" &&
        !data?.receipt_Url &&
        data?.last_visited?.save_logs === "true"
      ) {
        gotoPage(redirectTo.pages.transactionsuccessful);
      } else if (
        data?.company_alias === "bajaj_life" &&
        !data?.app_no &&
        data?.last_visited?.save_logs !== "true"
      ) {
        gotoPage(redirectTo.pages.eligibilitypage);
      }
    });
    // }
  }, []);

  // useEffect(() => {
  //   if (appNo && userId && !biUrl?.IpruRedirectionUrl) {
  //     dispatch(
  //       paymentStart({
  //         // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
  //         traceId: enquiryId,
  //         // appNo: sessionStorage.getItem("appNo"),
  //         // userId: sessionStorage.getItem("userId"),
  //         userId: userId,
  //         appNo: appNo,
  //         recurringPayment: "N",
  //       }),
  //     );
  //   }
  // }, [appNo, userId, biUrl]);

  const callApi = async () => {
    await updateQuotation({
      requestType: "payment",
    });
  };
  useEffect(() => {
    if (company_alias === "icici_pru" && isPaymentAlreadySuccess) {
      callApi();
    }
  }, [isPaymentAlreadySuccess]);

  useEffect(() => {
    if (response) {
      displayRazorPay(response);
    }
  }, [response]);

  const handleSubmit = e => {
    e.preventDefault();
    if (biUrl?.IpruRedirectionUrl) {
      window.location.assign(biUrl.IpruRedirectionUrl);
    } else
      dispatch(
        paymentStart({
          // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
          traceId: enquiryId,
          // appNo: sessionStorage.getItem("appNo"),
          // userId: sessionStorage.getItem("userId"),
          userId: userId,
          appNo: appNo,
          recurringPayment: "N",
        }),
      );
  };

  const displayRazorPay = async ({
    pgKey,
    pgOrderId,
    currency,
    paymentAmount,
    productName,
    PgCustomerId,
  }) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("failed");
      return;
    }
    const reloadPage = url => {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const options = {
      key: pgKey,
      amount: undefined,
      currency: undefined,
      name: selectedQuote?.company_name || selectedQuote?.companyName,
      description: productName,
      image: selectedQuote?.company_logo || selectedQuote?.companyLogo,
      order_id: pgOrderId,

      customer_id: undefined,
      recurring: undefined,
      prefill: {
        name: firstName + " " + lastName,
        email: saveEligibilityDetails?.secondary_email,
        contact: mobileNo,
      },
      handler: async function (response) {
        dispatch(setStatusLogged(true));
        fetch(`${process.env.REACT_APP_API_BASE_URL}common/save_logs`, {
          keepalive: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            traceId: enquiryId,
            paymentStatus: "success",
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            recurringPayment: "N",
          }),
        });
        const saveTransactionData = await savePaymentDetails({
          traceId: enquiryId,
          paymentStatus: "success",
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          recurringPayment: "N",
        });
        dispatch(initPaymentStatusLoader(true));
        // if (saveTransactionData.data.failureCount > 5) {
        //   reloadPage(`/eligibilitypage?enquiryId=${enquiryId}`);
        // }
        if (saveTransactionData.data.status) {
          // dispatch(initLoader(true));
          dispatch(initPaymentStatusLoader(true));
          dispatch(setRepayLoader(true));
          const paymentSuccessUpdate = await updateQuotation({
            traceId: enquiryId,
            requestType: "payment",
            paymentStatus: "success",
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            TotalAmount: paymentAmount,
          });
          dispatch(setIsPaymentSuccess(true));
          const { data } = await paymentDetailsToIc({
            applicationNo: appNo,
            traceId: enquiryId,
          });
          dispatch(clearLoader());
          if (data?.status) {
            dispatch(saveReciept(data));
            history.replace({
              pathname: "/transactionsuccessful",
              // search: `enquiryId=${search.slice(11, 29) || sessionStorage.getItem("traceId")}`,
              search: `${
                afflid
                  ? "afflid=" + afflid + "&"
                  : utm_medium
                  ? "utm_medium=" + utm_medium + "&"
                  : c_id
                  ? "c_id" + c_id + "&"
                  : ""
              }enquiryId=${enquiryId}`,
            });
          }
          dispatch(initPaymentStatusLoader(false));
        }
      },
      notes: {
        "note_key 1": "Beam me up Scotty",
        "note_key 2": "Tea. Earl Gray. Hot.",
      },

      theme: {
        color: "#F37254",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", async function (response) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}common/save_logs`, {
        keepalive: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          traceId: enquiryId,
          paymentStatus: "failed",
          error_code: response.error.code,
          error_description: response.error.description,
          error_source: response.error.source,
          error_step: response.error.step,
          error_reason: response.error.reason,
          error_metadata: response.error.metadata,
          razorpayOrderId: response.error.metadata?.order_id,
        }),
      });
      const saveData = await savePaymentDetails({
        // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
        traceId: enquiryId,
        paymentStatus: "failed",
        error_code: response.error.code,
        error_description: response.error.description,
        error_source: response.error.source,
        error_step: response.error.step,
        error_reason: response.error.reason,
        error_metadata: response.error.metadata,
        razorpayOrderId: response.error.metadata?.order_id,
      });
      if (saveData.data.failureCount >= 5) {
        reloadPage(
          `/transactionunsuccessful?${
            afflid
              ? "afflid=" + afflid + "&"
              : utm_medium
              ? "utm_medium=" + utm_medium + "&"
              : c_id
              ? "c_id" + c_id + "&"
              : ""
          }enquiryId=${enquiryId}`,
        );
      }
      // await updateQuotation({
      //   // traceId: search.slice(11, 29) || sessionStorage.getItem("traceId"),
      //   traceId: enquiryId,
      //   requestType: "payment",
      //   paymentStatus: "failed",
      //   errorCode: response.error.code,
      //   errorDescription: response.error.description,
      //   errorSource: response.error.source,
      //   errorStep: response.error.step,
      //   errorReason: response.error.reason,
      //   errorMetadata: response.error.metadata,
      //   TotalAmount: paymentAmount,
      // });
      history.push({
        pathname: "/transactionunsuccessful",
        // search: `enquiryId=${sessionStorage.getItem("traceId")}`,
        search: `${
          afflid
            ? "afflid=" + afflid + "&"
            : utm_medium
            ? "utm_medium=" + utm_medium + "&"
            : c_id
            ? "c_id" + c_id + "&"
            : ""
        }enquiryId=${enquiryId}`,
      });
    });

    rzp1.open();
  };

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.append(script);
    });
  };
  if (rePayLoader || loader) return <Loader />;
  return (
    <div className="unsuccessfulWrap">
      <div className="unsuccessfulLogo">
        <LaxmiGreeting />
      </div>
      <div className="unsuccessfulCheck center">
        <img src={exclamation} alt="exclamation" width="47" height="47" />
      </div>
      <div className="text-wrapper">
        <div className="unsuccessfulTitle">Your Payment is Unsuccessful!</div>
        <div className="unsuccessfulBtnWrap">
          <Button md primaryBlue padding onClick={handleSubmit}>
            TRY AGAIN
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Unsuccessful;
