import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMorePlans, setReturnOfPremium } from "../../quoteFilterSlice";

import "./more-plans.scss";

function MorePlans({ title, value }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setReturnOfPremium(true));
  });
  const handleCheckboxClick = () => {
    dispatch(setMorePlans({ value: "Choose your plans" }));
  };
  return (
    <div className="more-plans morePlansWrap">
      {/* <span className="more-plans__title morePlanTitle ">{title}</span> */}
      <span
        className="more-plans__checkbox-label morePlanCheck"
        onClick={handleCheckboxClick}
      >
        <label className="more-plans__value">
          <input type="checkbox" />
          Plans with {value}
        </label>
      </span>
    </div>
  );
}

export default MorePlans;
