import HttpClient from "../../../../api/httpClient";

// const createUser = (data) =>
//   HttpClient("common/create/user", { method: "POST", data });

const checkpinCode = data =>
  HttpClient("common/check_pincode", { method: "POST", data });

const checkPincodeMaster = data =>
  HttpClient("common/get_ic_pincode_master", { method: "POST", data });

export { checkpinCode, checkPincodeMaster };
