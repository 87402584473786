import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 16px;
  @media (max-width: 767px) {
    padding: ${props => {
      if (props.modifyDetails) {
        return "0px";
      }
    }};
  }
`;
export const HeadingWrapper = styled.div`
  width: 520px;
  margin: 35px auto 5px auto;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e3e4e8;

  @media (max-width: 767px) {
    margin: 0px;
    margin-top: 23px;
    margin-bottom: -10px;
    border-bottom: ${props => {
      if (props.modifyDetails) {
        return "none";
      }
    }};
  }
`;
export const Title = styled.h1`
  font-family: "Inter-SemiBold";
  font-size: 24px;
  line-height: 29px;
  color: #333;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`;
export const SubTitle = styled.h3`
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 20px;
  color: #333;
  @media (max-width: 767px) {
    max-width: 98vw;
    padding-right: 10px;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
  }
`;
export const FormWrapper = styled.form`
  padding: 0 60px;
  & div > button {
    width: 290px;
    height: 56px;
    margin: 50px 0;
    font-size: 20px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    & div > button {
      width: 100%;
    }
  }
`;

export const Question = styled.p`
  display: table-cell;
  vertical-align: top;
  width: 310px;
  padding: 0 12px 0 16px;
  ${"" /* width: ${(prop) => (prop.full ? "100%" : "59%")}; */}
  font-family: "Inter-Regular";
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #262626;
  & .SmallTextQuestion {
    float: left;
    font-size: 12px;
    line-height: 15px;
    color: #8d9194;
    width: 86%;
    margin-top: 8px;
    margin-left: 2px;
  }
  @media (max-width: 767px) {
    padding: 0 0 0px 0;
    font-size: 13px;
    line-height: 16px;
    display: block;
    min-height: auto;
    width: 310px;
  }
`;

export const QuestionWrapper = styled.div`
  display: table;
  width: 520px;
  margin: 34px auto 0 auto;
  & > button {
    font-size: 20px;
  }
  & > span {
    width: 59%;
    display: table-cell;
  }
  & > div {
    width: 196px;
    height: 40px;
    & .drop-down-items {
      max-height: 170px;
      &__search {
        margin-bottom: 0 !important;
        & input {
          height: 30px;
          font-size: 14px;
          background-size: 14px;
          background-position: right 4px top 50%, 0 0;
        }
      }
    }
    & .custom-drop-down__head {
      width: 100%;
      height: 100%;
      padding: 10px 8px;

      & .custom-drop-down__selected-text {
        line-height: 18px;
      }

      &--open {
        height: 40px;
      }

      &--open .custom-drop-down__selected-text {
        color: #00000099;
      }

      & .custom-drop-down__arrow {
        &,
        &--invert {
          filter: invert(32%) sepia(22%) hue-rotate(163deg) saturate(4275%)
            brightness(76%) contrast(60%);
          height: 15px;
          width: 15px;
        }
        &--invert {
          margin-bottom: 1px;
        }
        margin-right: -2px;
      }
    }
    & > div {
      width: 100%;
      height: 40px;
      & div {
        font-size: 14px;
        font-family: "Inter-Medium";
      }
      & span {
        color: #287692;
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 14px;
    display: table;
    width: 100% !important;
  }
`;
