/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPayPremiumMode } from "../../modules/QuotePage/helper";
import { setIsSalaried } from "../../modules/EligibilityPage/eligibilityCard.slice";
import { useCustomLocation } from "../../custom-hooks";
import { uploadQuote } from "../../modules/QuotePage/serviceApi";
import "./MaxDiscountPopup.scss";
import {
  sendEligibilityDetails,
  updateQuotation,
} from "../../modules/EligibilityPage/serviceApi";
import { getAge } from "../../utils/inputUtils";
import { useHistory } from "react-router";
import { getAllData } from "../../api/getAllData";
import { setSelectedAddOns } from "../../modules/RiderPage/RiderPage.slice";

const MaxDiscountPopup = ({ show, onClose, quote, handleClick }) => {
  const dispatch = useDispatch();
  const { enquiryId, pathname } = useCustomLocation();

  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );
  const sumAssured = quote?.cover_amount;

  const { isSalaried } = useSelector(state => state.eligibilityCard);

  const {
    filter: { coverUpto },
  } = useSelector(state => state.quoteFilter);
  const { customerDetails, startAnnualIncome } = useSelector(
    state => state.greeting,
  );
  const age = getAge(customerDetails.dob);
  const history = useHistory();

  const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  const payOption = paymentTerm?.option;
  const payTerm = paymentTerm?.term;
  const isSinglePay = paymentTerm.option === "Single Pay";

  const checkVideoMedical = () => {
    if (age <= 45) {
      return (
        parseInt(customerDetails?.annualIncome) >= 1000000 &&
        parseInt(quote?.sumAssured) <= 10000000 &&
        (customerDetails?.qualification === "Graduate" ||
          customerDetails?.qualification === "Post Graduate")
      );
    }
    return (
      age <= 45 &&
      parseInt(customerDetails?.annualIncome) >= 1000000 &&
      parseInt(quote?.sumAssured) <= 10000000 &&
      (customerDetails?.qualification === "Graduate" ||
        customerDetails?.qualification === "Post Graduate")
    );
  };
  const isSelfEmployed = useMemo(() => {
    if (checkVideoMedical()) return quote?.medicalCondition !== "Video Medical";
    else if (customerDetails?.termOccupation === "Self Employed")
      return (
        customerDetails?.qualification === "12th Pass" ||
        customerDetails?.qualification === "10th Pass"
      );
    else return false;
  }, [quote]);

  const discountPercentage = Math.ceil(quote?.premium_amount * (88 / 100));

  const discountPercentageRevert = Math.ceil(
    quote?.premium_amount * (100 / 88),
  );

  const payment = isSelfEmployed
    ? quote?.premium_amount - discountPercentage
    : discountPercentage - quote?.premium_amount;
  const finalPayment = quote?.premium_amount - payment;

  const [modeOfPayment, setModeOfPayment] = useState(
    getPayPremiumMode(
      paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );

  useEffect(() => {
    setModeOfPayment(
      getPayPremiumMode(
        paymentTerm.option === "Single Pay" ? "none" : payPremium,
      ),
    );
  }, [payPremium, paymentTerm]);
  const handleClose = () => {
    onClose(false);
  };
  useEffect(() => {
    handleChange();
  }, [quote]);
  const handleChange = () => {
    checkVideoMedical()
      ? quote?.medicalCondition === "Video Medical"
        ? dispatch(setIsSalaried("Y"))
        : dispatch(setIsSalaried("N"))
      : customerDetails?.termOccupation === "Self Employed"
      ? dispatch(setIsSalaried("N"))
      : dispatch(setIsSalaried("Y"));
  };

  return (
    <>
      <div
        className="modal fade show"
        id="maxLifeModal"
        role="dialog"
        style={{ display: show && "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content expertPopupWrap">
            <div className="expertTopWrap">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  handleClose();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                  fill="#333"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="modelWrap maxLifeWrap">
                  <div className="expertImg">
                    <div className="maxLifeTitle">
                      Congratulations! Being a salaried customer, you have
                      enjoyed a 12% discount on first year premium.
                    </div>
                    <div className="maxLifeSubTitle">
                      Please confirm us if you are salaried?
                    </div>
                    <div className="row">
                      <div className="smokingWrap">
                        <input
                          type="radio"
                          name="salaryDiscount"
                          value="Y"
                          id="salaryYes"
                          checked={isSalaried === "Y"}
                          onClick={e => dispatch(setIsSalaried(e.target.value))}
                        />
                        <label
                          className="ratioButton smokingInput form-control"
                          htmlFor="salaryYes"
                        >
                          <span className="smokingTxt">YES</span>

                          <span className="checkmark"></span>
                          <div className="salaryDisAmt">
                            ₹{" "}
                            {isSelfEmployed == true
                              ? finalPayment
                              : quote?.premium_amount}
                            {modeOfPayment}
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="salaryDiscount"
                          value="N"
                          id="salaryNo"
                          checked={isSalaried === "N"}
                          // defaultChecked={true}
                          onClick={e => dispatch(setIsSalaried(e.target.value))}
                        />
                        <label
                          className="ratioButton smokingInput form-control"
                          htmlFor="salaryNo"
                        >
                          <span className="smokingTxt">NO</span>

                          <span className="checkmark"></span>
                          <div className="salaryDisAmt">
                            ₹{" "}
                            {isSinglePay
                              ? Math.ceil(quote?.premium_amount * (100 / 98))
                              : isSelfEmployed
                              ? quote?.premium_amount
                              : discountPercentageRevert}{" "}
                            {modeOfPayment}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {quote?.medicalCondition === "Video Medical" &&
                  isSalaried === "N" ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: "#d43d3d",
                        marginTop: "15px",
                      }}
                    >
                      You need to undergo physical medical tests, as you are not
                      salaried
                    </div>
                  ) : quote?.medicalCondition === "Physical Medical" &&
                    isSalaried === "Y" &&
                    checkVideoMedical() ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: "#d43d3d",
                        marginTop: "15px",
                      }}
                    >
                      You need to undergo video medical tests, as you are
                      salaried
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="expertForm sendQuoteBtn">
                    <button
                      type="button"
                      className="activeBtn"
                      onClick={() => {
                        if (
                          quote?.medicalCondition === "Video Medical" &&
                          isSalaried === "N"
                        ) {
                          uploadQuote({
                            annualPremium: quote.annualPremium,
                            childProductName: quote.child_product_name,
                            cover_amount: quote.cover_amount,
                            integrationType: quote.integration_type,
                            masterProductName: quote.master_product_name,
                            medicalCondition: quote.medicalCondition,
                            paytypeOption:
                              quote.planTenure === "same" &&
                              coverUpto === "Whole Life"
                                ? "WL"
                                : "N",
                            planTenure: quote.planTenure,
                            policyTerm: `${parseInt(coverUpto) - age}`,
                            premiumAmount: quote.premium_amount,
                            sumAssured: quote.sumAssured,
                            termInsuId: quote.insurance_code,
                            totalPremiumGst: quote.premium_amount_gst,
                            isMaxLifeDiscount: isSalaried,
                            companyName: quote.company_name,
                            companyAlias: quote.company_alias,
                            requestType: "select_product",
                            traceId: enquiryId,
                          }).then(() => {
                            sendEligibilityDetails({
                              traceId: enquiryId,
                              requestType: "eligibility_details",
                              eligibleStatus: "YES",
                              company_alias: "max_life",
                              termOccupation: "Self Employed",
                              termOccupation_text: "Self Employed",
                            });
                            updateQuotation({
                              annualIncome:
                                customerDetails?.annualIncome < 10000000
                                  ? Math.floor(
                                      customerDetails?.annualIncome / 100000,
                                    ) *
                                      100000 <=
                                    startAnnualIncome
                                    ? String(startAnnualIncome)
                                    : String(
                                        Math.floor(
                                          customerDetails?.annualIncome /
                                            100000,
                                        ) * 100000,
                                      )
                                  : String(
                                      Math.floor(
                                        customerDetails?.annualIncome / 2500000,
                                      ) * 2500000,
                                    ),
                              traceId: enquiryId,
                              requestType: "get_products",
                              payfrequency: payPremium,
                              morePlans: quote.child_product_name,
                              sumAssured: String(sumAssured),
                              policyTerm: `${parseInt(coverUpto) - age}`,
                              payOption: payOption,
                              limitedPayTerm: payTerm,
                            }).then(() => {
                              dispatch(
                                getAllData(null, pathname, enquiryId),
                              ).then(() => {
                                history.push(`/quotes?enquiryId=${enquiryId}`);
                                dispatch(setSelectedAddOns([]));
                              });
                            });
                          });
                        } else if (
                          quote?.medicalCondition === "Physical Medical" &&
                          isSalaried === "Y" &&
                          checkVideoMedical()
                        ) {
                          uploadQuote({
                            annualPremium: quote.annualPremium,
                            childProductName: quote.child_product_name,
                            cover_amount: quote.cover_amount,
                            integrationType: quote.integration_type,
                            masterProductName: quote.master_product_name,
                            medicalCondition: quote.medicalCondition,
                            paytypeOption:
                              quote.planTenure === "same" &&
                              coverUpto === "Whole Life"
                                ? "WL"
                                : "N",
                            planTenure: quote.planTenure,
                            policyTerm: `${parseInt(coverUpto) - age}`,
                            premiumAmount: quote.premium_amount,
                            sumAssured: quote.sumAssured,
                            termInsuId: quote.insurance_code,
                            totalPremiumGst: quote.premium_amount_gst,
                            isMaxLifeDiscount: isSalaried,
                            companyName: quote.company_name,
                            companyAlias: quote.company_alias,
                            requestType: "select_product",
                            payfrequency: payPremium,
                            traceId: enquiryId,
                          }).then(() => {
                            sendEligibilityDetails({
                              traceId: enquiryId,
                              requestType: "eligibility_details",
                              eligibleStatus: "YES",
                              company_alias: "max_life",
                              termOccupation: "Salaried",
                              termOccupation_text: "Salaried",
                            });
                            updateQuotation({
                              annualIncome:
                                customerDetails?.annualIncome < 10000000
                                  ? Math.floor(
                                      customerDetails?.annualIncome / 100000,
                                    ) *
                                      100000 <=
                                    startAnnualIncome
                                    ? String(startAnnualIncome)
                                    : String(
                                        Math.floor(
                                          customerDetails?.annualIncome /
                                            100000,
                                        ) * 100000,
                                      )
                                  : String(
                                      Math.floor(
                                        customerDetails?.annualIncome / 2500000,
                                      ) * 2500000,
                                    ),
                              traceId: enquiryId,
                              requestType: "get_products",
                              morePlans: quote.child_product_name,
                              sumAssured: String(sumAssured),
                              policyTerm: `${parseInt(coverUpto) - age}`,
                              payOption: payOption,
                              payfrequency: payPremium,
                              limitedPayTerm: payTerm,
                            }).then(() => {
                              dispatch(
                                getAllData(null, pathname, enquiryId),
                              ).then(() => {
                                history.push(`/quotes?enquiryId=${enquiryId}`);
                                dispatch(setSelectedAddOns([]));
                              });
                            });
                          });
                        } else {
                          handleClick({
                            ...quote,
                            premium_amount:
                              isSalaried === "N"
                                ? isSinglePay
                                  ? Math.ceil(
                                      quote?.premium_amount * (100 / 98),
                                    )
                                  : isSelfEmployed
                                  ? quote?.premium_amount
                                  : discountPercentageRevert
                                : quote?.premium_amount,
                          });
                        }
                        onClose(false);
                      }}
                    >
                      {quote?.medicalCondition === "Video Medical" &&
                      isSalaried === "N"
                        ? "Switch to Physical Medical"
                        : quote?.medicalCondition === "Physical Medical" &&
                          isSalaried === "Y" &&
                          checkVideoMedical()
                        ? "Switch to Video Medical"
                        : "CONTINUE"}
                    </button>
                    <div
                      className="maxLifeBack"
                      data-dismiss="modal"
                      onClick={() => onClose(false)}
                    >
                      Back to quotes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaxDiscountPopup;
