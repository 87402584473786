import useOutsideClick from "./useOutsideClick";
import useKeydownEvent from "./useKeydownEvent";
import useDocumentTitle from "./useDocumentTitle";
import useQuoteBuyNowButton from "./useQuoteBuyNowButton";
import useCustomLocation from "./useCustomLocation";
import useDisplayNomineeGets from "./useDisplayNomineeGets";
import useWindowResize from "./useWindowResize";
import { useEffect } from "react";
import { useRef } from "react";

export {
  useOutsideClick,
  useKeydownEvent,
  useDocumentTitle,
  useQuoteBuyNowButton,
  useCustomLocation,
  useDisplayNomineeGets,
  useWindowResize,
};

export const useUrlQuery = name => {
  let params = new URLSearchParams(document.location.search);
  return params.get(name);
};
export const useDidMountEffect = (func = () => {}, deps = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
