import { createSlice } from "@reduxjs/toolkit";
import {
  getEducationDetails,
  getOrganizationType,
  getProposerIndustryType,
  getProposerOccupation,
} from "../../../../utils/inputUtils";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const proposalOccupationForm = createSlice({
  name: "proposalOccupationForm",
  initialState: {
    proposalOccupationDetails: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.proposalOccupationDetails = action.payload;
    },
  },
});
export const { saveDetails } = proposalOccupationForm.actions;

export const proposerOccupationSendData = data => ({
  pOccup: getProposerOccupation(data.occupation),
  pEducation: getEducationDetails(data.education),
  pOrganizationName: data.orgName,
  pOrganizationDesc: data.orgTypeDesc,
  pOrganizationType: getOrganizationType(data.orgType),
  pIncome: data.annualIncome,
  pIndustryDesc: data.indDesc,
  pIndustryType: getProposerIndustryType(data.indType),
  pIndustryQuestion: data.engagedIn ? "Yes" : "No",
  proposalJourneyStatus: iciciProposalForms.proposerOccupationDetails,
});

export const saveProposalOccupationDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(proposerOccupationSendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default proposalOccupationForm.reducer;
