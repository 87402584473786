import { createSlice } from "@reduxjs/toolkit";

const bajajDiscountSlice = createSlice({
  name: "bajajDiscountSlice",
  initialState: {
    openBajajDiscount: false,
    salaried: "Y",
    existingPolicy: "N",
    existingPolicyBajaj: "Y",
    fullyVaccinated: "Y",
    newBajajDiscount: {},
    bajajPremiumAmount: 0,
  },

  reducers: {
    setSalaried: (state, action) => {
      state.salaried = action.payload;
    },
    setExistingPolicy: (state, action) => {
      state.existingPolicy = action.payload;
    },
    setExistingPolicyBajaj: (state, action) => {
      state.existingPolicyBajaj = action.payload;
    },
    setFullyVaccinated: (state, action) => {
      state.fullyVaccinated = action.payload;
    },

    setNewBajajDiscount: (state, action) => {
      state.newBajajDiscount = action.payload;
    },

    setOpenBajajDiscount: (state, action) => {
      state.openBajajDiscount = action.payload;
    },

    setBajajPremiumAmount: (state, action) => {
      state.bajajPremiumAmount = action.payload;
    },

    resetBajajDiscountData: state => {
      state.salaried = "Y";
      state.existingPolicy = "N";
      state.existingPolicyBajaj = "Y";
      state.fullyVaccinated = "Y";
      state.newBajajDiscount = {};
      state.bajajPremiumAmount = 0;
    },
  },
});

export const {
  setSalaried,
  setExistingPolicy,
  setExistingPolicyBajaj,
  setFullyVaccinated,
  setNewBajajDiscount,
  setOpenBajajDiscount,
  setBajajPremiumAmount,
  resetBajajDiscountData,
} = bajajDiscountSlice.actions;

export default bajajDiscountSlice.reducer;
