/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectEligibilityGrid,
  selectIsSalaried,
} from "../../../modules/EligibilityPage/eligibilityCard.slice";
import { selectCustomerDetails } from "../../../modules/GreetingPage/greeting.slice";
import { numberToDigitWord } from "../../../modules/QuoteFilter/helper";
import { selectCoverAmount } from "../../../modules/QuoteFilter/quoteFilterSlice";
import { selectSelectedQuote } from "../../../modules/QuotePage/quotePageSlice";
import { extractNumbers } from "../../../utils";
import { getNumberfromString, getValue } from "../../../utils/inputUtils";

export const educationValuesMap = {
  "Post Graduate": "post_graduate",
  Graduate: "graduate",
  "12th Pass": "12th_pass",
  "10th Pass": "10th_pass",
};

const errorMessages = {
  invalidOccupation:
    "This Product is not available for the selected occupation",
  invalidEducation: "This Product is not available for the selected education",
  invalidAnnualIncome: `This product is not eligibile for the given annual income`,
  exceedsMaxCover: maxCover => {
    return (
      <>
        Basis the Education details provided by you, you are eligible to buy a
        maximum cover amount for {numberToDigitWord(parseInt(maxCover))}.
        <div style={{ marginTop: "1.2rem" }}>
          Please click on "Go back to quotes" to view updated cover amount.
        </div>
      </>
    );
  },
  exceedsMaxCover2: maxCover => {
    return (
      <>
        Basis the Occupation details provided by you, you are eligible to buy a
        maximum cover amount for {numberToDigitWord(parseInt(maxCover))}.
        <div style={{ marginTop: "1.2rem" }}>
          Please click on "Go back to quotes" to view updated cover amount.
        </div>
      </>
    );
  },
  exceedsMaxCoverForHouseWife: maxCover => {
    return (
      <>
        Basis the Occupation Details provided by you, you are eligible to buy a
        maximum cover amount for {numberToDigitWord(parseInt(maxCover))}.
        <div style={{ marginTop: "1.2rem" }}>
          Please click on "Go back to quotes" to view updated cover amount.
        </div>
      </>
    );
  },
  partiallyVaccinated: maxCover =>
    `As you are partially vaccinated, you are eligible for a maximum sum assured of ${numberToDigitWord(
      parseInt(maxCover),
    )}`,
};

const ageBands = [
  [18, 19],
  [20, 20],
  [21, 24],
  [25, 25],
  [26, 29],
  [30, 30],
  [31, 34],
  [35, 35],
  [36, 39],
  [40, 40],
  [41, 44],
  [45, 45],
  [46, 49],
  [50, 50],
  [51, 54],
  [55, 55],
  [56, 59],
  [60, 60],
  [61, 64],
  [65, 65],
];
function checkAgeBand(age, ageBand) {
  const [minAge, maxAge] = ageBand;

  return age >= minAge && age <= maxAge;
}

function getAgeBand(age) {
  const ageBand = ageBands.findIndex(ageBand => checkAgeBand(age, ageBand));
  if (ageBand === -1) return null;
  return `band_${ageBand + 1}`;
}

function useEligibilityGrid(gender, setColor) {
  const selectedQuote = useSelector(selectSelectedQuote);
  const [annualIncomeFlag, setAnnualIncomeFlag] = useState(false);

  const { company_alias } = selectedQuote;
  const [annualIncomeLimit, setAnnualIncomeLimit] = useState(false);
  let isSalaried = useSelector(selectIsSalaried);
  // isSalaried = isSalaried === "Y";
  const [newCover, setNewCover] = useState();
  const { filter } = useSelector(state => state.quoteFilter);

  const { age, isWipro } = useSelector(selectCustomerDetails);
  let coverAmount = useSelector(selectCoverAmount);

  coverAmount = parseInt(getNumberfromString(coverAmount));
  const [eligibilityErrors, setEligibilityErrors] = useState({
    education: "",
    occupation: "",
    annualIncome: "",
    generic: "",
  });

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { isLoading, isUninitialized, data } = useSelector(
    selectEligibilityGrid,
  );

  if (isLoading || isUninitialized) return { isLoading: true };

  const { occupation_list, education_list, eligibility_grid } = data;

  const getOccupationDropDown = () => {
    if (
      company_alias === "max_life" ||
      (company_alias === "icici_pru" &&
        coverAmount >= 15000000 &&
        filter?.paymentTerm?.option !== "Single Pay")
    ) {
      if (isSalaried === "Y") return ["Salaried"];
      return occupation_list
        .map(occupation => occupation.occupation)
        .filter(occupation => occupation !== "Salaried");
    }
    return occupation_list.map(occupation => occupation.occupation);
  };

  const occupationDropDown = getOccupationDropDown();

  // occupationDropDown remove Salaried -Government and Salaried - Non Government

  const filterBySalary =
    (company_alias === "bajaj_life" || company_alias === "kotak_life") &&
    isSalaried === "N"
      ? occupationDropDown.filter(
          occupation =>
            !["Salaried - Government", "Salaried - Non Government"].includes(
              occupation,
            ),
        )
      : (company_alias === "bajaj_life" || company_alias === "kotak_life") &&
        isSalaried === "Y" &&
        occupationDropDown.filter(occupation =>
          ["Salaried - Government", "Salaried - Non Government"].includes(
            occupation,
          ),
        );

  const educationDropDown = education_list.map(
    education => education.education,
  );

  const checkEligibility = ({
    education,
    occupation,
    annualIncome,
    pincode,
    vaccinationStatus,
  }) => {
    const education_value = getEducationValue(education);
    const occupation_value = getOccupationValue(occupation);

    const isVideo =
      (selectedQuote.medicalCondition === "Video Medical" ||
        selectedQuote.medicalCondition === "Tele Medical") &&
      company_alias !== "icici_pru";

    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "housewife" &&
      filter.morePlans === "Return of Premium"
    ) {
      setEligibilityErrors({
        occupation:
          "For selected occupation you cannot proceed with return of premium. Kindly proceed with the base plan.",
      });
      //color is set to blue in eligibility card file
      setColor(true);
      return;
    }
    const isEducationEligible = Object.keys(eligibility_grid)
      .filter(
        combinationName =>
          combinationName.endsWith(education_value) &&
          combinationName.startsWith(occupation_value),
      )
      .some(combinationName =>
        eligibility_grid[combinationName].some(item => {
          return (
            item.case_result === "Pass" &&
            (isVideo ? item["medical_type"] === "Video Medical" : true)
          );
        }),
      );
    if (education_value === "10th_pass" && company_alias === "pnb_metlife") {
      setShowDisclaimer(false);
      setEligibilityErrors({
        ...eligibilityErrors,
        education:
          "Selected IC doesn't provide products for selected education",
      });
    } else {
      if (
        company_alias !== "pnb_metlife" &&
        company_alias !== "bajaj_life" &&
        company_alias !== "kotak_life" &&
        isVideo
      ) {
        if (!isEducationEligible && occupation_value) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        }
      } else {
        setShowDisclaimer(company_alias === "bajaj_life" ? false : true);
        setEligibilityErrors({});
      }
    }

    if (
      (education_value === "10th_pass" ||
        education_value === "below_10th" ||
        education_value === "12th_pass" ||
        education_value === "diploma") &&
      company_alias === "bandhan_life"
    ) {
      setShowDisclaimer(false);
      setEligibilityErrors({
        // ...eligibilityErrors,
        education: "Plan is not available for selected education",
      });
    } else {
      if (
        company_alias !== "bandhan_life" &&
        company_alias !== "bajaj_life" &&
        company_alias !== "kotak_life" &&
        isVideo
      ) {
        if (!isEducationEligible && occupation_value) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        }
      } else {
        setShowDisclaimer(company_alias === "bajaj_life" ? false : true);
        setEligibilityErrors({});
      }
    }
    let data = eligibility_grid[`${occupation_value}+${education_value}`];

    if (!data) return;

    if (company_alias === "kotak_life") {
      if (
        (occupation_value === "self_employed" ||
          occupation_value === "professional") &&
        annualIncome >= 1000000
      ) {
        data = data.filter(item => {
          if (item.case_result === "Fail") {
            setEligibilityErrors({
              education: errorMessages.invalidEducation,
            });
          }
        });
      } else if (
        (occupation_value === "self_employed" ||
          occupation_value === "professional") &&
        annualIncome < 1000000
      ) {
        setEligibilityErrors({
          annualIncome: "Annual income should be minimum 10 lakhs",
        });
      } else if (occupation_value === "salaried") {
        if (annualIncome < 750000) {
          setEligibilityErrors({
            annualIncome: "Annual income should be minimum 8 lakhs",
          });
        } else {
          data = data.filter(item => {
            if (item.case_result === "Fail") {
              setEligibilityErrors({
                education: errorMessages.invalidEducation,
              });
            }
            return;
          });
        }
      }
      if (occupation_value === "housewife" && company_alias === "kotak_life") {
        setEligibilityErrors({
          occupation: errorMessages.invalidOccupation,
        });
      }
    }
    if (isVideo) {
      if (company_alias !== "pnb_metlife") {
        data = data.filter(
          item => item.medical_type === "Video Medical" || !item.medical_type,
        );
      } else {
        // PNB CR
        data = data.filter(item => {
          if (
            item.medical_type === "Video Medical" &&
            item.case_result === "Pass"
          ) {
            setShowDisclaimer(false);
            return item.medical_type === "Video Medical";
          } else if (
            item.medical_type === "Video Medical" &&
            item.case_result === "Fail"
          ) {
            if (education_value === "10th_pass") {
              setShowDisclaimer(false);
            } else {
              setShowDisclaimer(true);
              return item.medical_type === "Physical Medical";
            }
          }
        });
      }
    }
    if (!isVideo) {
      data = data.filter(
        item => item.medical_type === "Physical Medical" || !item.medical_type,
      );
    }

    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "housewife" &&
      coverAmount > 5000000
    ) {
      setAnnualIncomeLimit(errorMessages.exceedsMaxCoverForHouseWife(5000000));
    }
    if (
      company_alias === "bajaj_life" &&
      selectedQuote?.master_product_name === "eTouch" &&
      !["graduate", "post_graduate"]?.includes(education_value)
    ) {
      setEligibilityErrors({
        education: errorMessages?.invalidEducation,
      });
      return;
    }
    if (
      company_alias === "bajaj_life" &&
      selectedQuote?.master_product_name === "eTouch" &&
      !["salaried", "self_employed"]?.includes(occupation_value)
    ) {
      setEligibilityErrors({
        occupation: errorMessages?.invalidOccupation,
      });
      return;
    }
    if (
      company_alias === "hdfc_life" &&
      occupation_value === "housewife" &&
      coverAmount > 10000000
    ) {
      setAnnualIncomeLimit(errorMessages.exceedsMaxCoverForHouseWife(10000000));
      setNewCover(10000000);
    }
    if (!data) {
      setEligibilityErrors({
        generic: "Not eligible for Occupation and  Education.",
      });
      return;
    }
    if (
      occupation_value === "housewife" &&
      annualIncome < 500000 &&
      company_alias === "kotak_life"
    ) {
      setEligibilityErrors({
        annualIncome: "Husband's annual income should be minimum 5 lakhs",
      });
      return;
    }
    if (
      occupation_value === "housewife" &&
      annualIncome < 500000 &&
      (company_alias === "hdfc_life" ||
        company_alias === "bajaj_life" ||
        company_alias === "tata_aia_life")
    ) {
      setEligibilityErrors({
        annualIncome: "Husband's annual income should be minimum 5 lakhs",
      });
      return;
    }
    if (
      occupation_value === "housewife" &&
      annualIncome < 1000000 &&
      company_alias !== "pnb_metlife" &&
      company_alias !== "kotak_life" &&
      company_alias !== "max_life" &&
      company_alias !== "hdfc_life" &&
      company_alias !== "bajaj_life" &&
      company_alias !== "bajaj_life" &&
      company_alias !== "tata_aia_life"
    ) {
      setEligibilityErrors({
        annualIncome: "Husband's annual income should be minimum 10 lakhs",
      });
      return;
    }
    if (
      occupation_value === "housewife" &&
      annualIncome < 500000 &&
      company_alias === "max_life"
    ) {
      setEligibilityErrors({
        annualIncome: "Husband's annual income should be minimum 5 lakhs",
      });
      return;
    }

    if (
      isWipro &&
      annualIncome < 500000 &&
      isVideo &&
      company_alias === "max_life"
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 5 lakhs",
      });
      return;
    }
    if (
      !isWipro &&
      !isSalaried &&
      annualIncome < 1000000 &&
      isVideo &&
      (education_value === "12th_pass" || education_value === "10th_pass") &&
      company_alias === "max_life"
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 10 lakhs",
      });
      return;
    }
    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "agriculturist" &&
      annualIncome < 1000000
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 10 lakhs",
      });
      return;
    }
    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "self_employed" &&
      annualIncome < 500000
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 5 lakhs",
      });
      return;
    }
    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "self_employed" &&
      annualIncome < 1000000 &&
      education_value === "10th_pass"
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 10 lakhs",
      });
      return;
    }
    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "self_employed" &&
      annualIncome < 500000 &&
      education_value === "12th_pass"
    ) {
      setEligibilityErrors({
        annualIncome: "Annual income should be minimum 5 lakhs",
      });
      return;
    }
    if (age < 18 && age > 45 && company_alias === "max_life") {
      setEligibilityErrors({
        generic: "Age should be in range of 18-45 years",
      });
      return;
    }
    const { case_result } = data[0] || {};

    if (case_result === "Fail") {
      const isOccupationEligible = Object.keys(eligibility_grid)
        .filter(combinationName => combinationName.startsWith(occupation_value))
        .some(combinationName =>
          eligibility_grid[combinationName].some(item => {
            return (
              item.case_result === "Pass" &&
              (isVideo ? item["medical_type"] === "Video Medical" : true)
            );
          }),
        );

      if (
        education_value === "10th_pass" &&
        company_alias === "pnb_metlife" &&
        isOccupationEligible
      ) {
        setShowDisclaimer(false);
        setEligibilityErrors({
          ...eligibilityErrors,
          education:
            "Selected IC doesn't provide products for selected education",
        });
      } else {
        if (isOccupationEligible && !isEducationEligible) {
          setEligibilityErrors({
            education:
              company_alias === "bandhan_life"
                ? "Plan is not available for selected education"
                : errorMessages.invalidEducation,
          });
          return;
        } else if (
          occupation_value === "housewife" &&
          (education_value === "10th_pass" ||
            education_value === "below_10th" ||
            education_value === "12th_pass" ||
            education_value === "diploma") &&
          company_alias === "hdfc_life"
        ) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (
          occupation_value === "housewife" &&
          company_alias === "kotak_life" &&
          (education_value === "12th_pass" ||
            education_value === "10th_pass") &&
          (!isVideo ||
            (!isOccupationEligible && isVideo && coverAmount <= 10000000))
        ) {
          // setEligibilityErrors({
          //   education: errorMessages.invalidEducation,
          // });
        } else if (
          !isOccupationEligible &&
          occupation_value === "housewife" &&
          coverAmount > 10000000 &&
          company_alias === "kotak_life" &&
          isVideo
        ) {
          setAnnualIncomeLimit(
            errorMessages.exceedsMaxCoverForHouseWife(10000000),
          );
          setNewCover(10000000);
          setAnnualIncomeFlag(true);
        } else if (
          !isOccupationEligible &&
          occupation_value === "housewife" &&
          coverAmount > 5000000 &&
          company_alias === "bajaj_life" &&
          isVideo
        ) {
          setAnnualIncomeLimit(
            errorMessages.exceedsMaxCoverForHouseWife(5000000),
          );
          setNewCover(5000000);
          setAnnualIncomeFlag(true);
        }
        if (!isOccupationEligible && occupation_value !== "housewife") {
          setEligibilityErrors({
            occupation:
              company_alias === "bandhan_life"
                ? "Plan is not available for selected occupation"
                : errorMessages.invalidOccupation,
          });
        } else if (
          isOccupationEligible &&
          company_alias === "max_life" &&
          occupation_value === "housewife"
        ) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (
          !isEducationEligible &&
          occupation_value === "agriculturist" &&
          company_alias === "kotak_life"
        ) {
          setEligibilityErrors({
            occupation: errorMessages.invalidOccupation,
          });
        } else if (
          !isEducationEligible &&
          company_alias === "kotak_life" &&
          occupation_value !== "housewife"
        ) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (
          occupation_value === "housewife" &&
          company_alias === "kotak_life"
        ) {
          setEligibilityErrors({
            occupation: errorMessages.invalidOccupation,
          });
        } else if (
          company_alias === "tata_aia_life" &&
          occupation_value === "agriculturist" &&
          (education_value === "post_graduate" ||
            education_value === "graduate")
        ) {
          setEligibilityErrors({
            education: null,
          });
        } else if (!isEducationEligible && company_alias === "tata_aia_life") {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (!isOccupationEligible && company_alias === "tata_aia_life") {
          setEligibilityErrors({
            occupation: errorMessages.invalidOccupation,
          });
        } else if (!isEducationEligible && company_alias === "bajaj_life") {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (!isEducationEligible && company_alias === "icici_pru") {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else if (
          occupation_value === "housewife" &&
          (education_value === "10th_pass" ||
            education_value === "below_10th" ||
            education_value === "12th_pass" ||
            education_value === "diploma") &&
          company_alias === "hdfc_life"
        ) {
          setEligibilityErrors({
            education: errorMessages.invalidEducation,
          });
        } else {
          setEligibilityErrors({});
        }
      }
      return;
    }
    if (
      company_alias === "tata_aia_life" &&
      occupation_value === "housewife" &&
      (education_value === "post_graduate" || education_value === "graduate")
    ) {
      setEligibilityErrors({
        education: null,
      });
    }
    const validateAgeForCombination = ({ minimum_age, maximum_age }) =>
      age >= minimum_age && age <= maximum_age;
    const ageFilteredCombinations = data.filter(validateAgeForCombination);
    if (!ageFilteredCombinations[0]) {
      if (company_alias === "pnb_metlife") {
        if (education_value === "10th_pass") {
          setShowDisclaimer(false);
        } else if (
          occupation_value === "others" ||
          occupation_value === "student" ||
          occupation_value === "housewife"
        ) {
          setEligibilityErrors({
            occupation:
              "Selected IC doesn't provide products for selected occupation",
          });
          setShowDisclaimer(false);
        } else setShowDisclaimer(true);
      } else {
        if (case_result === "Fail") {
          setEligibilityErrors({
            generic: "This product is not eligible for your age",
          });
        }
      }
      if (
        company_alias === "tata_aia_life" &&
        occupation_value === "housewife" &&
        (education_value !== "post_graduate" ||
          education_value !== "graduate") &&
        occupation_value === "agriculturist" &&
        (education_value !== "post_graduate" || education_value !== "graduate")
      ) {
        setEligibilityErrors({
          generic: "",
        });
      }
      return;
    }
    const annualIncomeFilteredCombinations = ageFilteredCombinations.filter(
      ({ minimum_annual_income, maximum_annual_income }) =>
        annualIncome >= minimum_annual_income &&
        annualIncome <= maximum_annual_income,
    );

    if (!annualIncomeFilteredCombinations[0]) {
      if (company_alias === "pnb_metlife") {
        if (education_value === "10th_pass") {
          setShowDisclaimer(false);
        } else {
          setShowDisclaimer(true);
          setEligibilityErrors({
            annualIncome: errorMessages.invalidAnnualIncome,
          });
        }
      } else {
        if (
          !(
            company_alias === "kotak_life" &&
            400000 <= annualIncome &&
            annualIncome > 500000
          ) &&
          education_value != "post_graduate"
        ) {
          setEligibilityErrors({
            annualIncome: errorMessages.invalidAnnualIncome,
          });
        }
      }
      return;
    }
    const ageBand = getAgeBand(age);

    const { maximum_sum_assured, minimum_sum_assured, ...bands } =
      annualIncomeFilteredCombinations[0];

    if (
      vaccinationStatus === "Not Vaccinated" &&
      company_alias === "bajaj_life" &&
      coverAmount > 10000000
    ) {
      setNewCover(10000000);
      setAnnualIncomeLimit(errorMessages.partiallyVaccinated(10000000));
      setEligibilityErrors({});
      setAnnualIncomeFlag(true);
      return;
    } else if (
      vaccinationStatus === "Partially Vaccinated" &&
      company_alias === "bajaj_life" &&
      coverAmount > 10000000
    ) {
      setNewCover(10000000);
      setAnnualIncomeLimit(errorMessages.partiallyVaccinated(10000000));
      setEligibilityErrors({});
      setAnnualIncomeFlag(true);
      return;
    } else if (
      coverAmount > maximum_sum_assured ||
      annualIncome * bands[ageBand] < coverAmount
    ) {
      setNewCover(
        maximum_sum_assured < coverAmount
          ? maximum_sum_assured
          : annualIncome * bands[ageBand],
      );

      if (
        occupation_value !== "housewife" &&
        !(
          company_alias === "icici_pru" &&
          annualIncome >= 500000 &&
          coverAmount >= 5000000
        )
      ) {
        setAnnualIncomeLimit(
          errorMessages.exceedsMaxCover(
            maximum_sum_assured < coverAmount
              ? maximum_sum_assured
              : annualIncome * bands[ageBand],
          ),
        );
      }

      if (
        (occupation_value === "housewife" ||
          occupation_value === "agriculturist") &&
        !(company_alias === "icici_pru" && annualIncome < 500000)
      ) {
        setAnnualIncomeLimit(
          errorMessages.exceedsMaxCoverForHouseWife(
            maximum_sum_assured < coverAmount
              ? maximum_sum_assured
              : Math.floor((annualIncome * bands[ageBand]) / 500000) * 500000,
          ),
        );
      }
      if (annualIncome * bands[ageBand] < minimum_sum_assured) {
        setNewCover(minimum_sum_assured);
        setAnnualIncomeLimit(
          `Minimim Sum Assured available for this plan is ${getValue(
            minimum_sum_assured,
          )} but as per your eligibility you can opt for a maximum cover of ${getValue(
            annualIncome * bands[ageBand],
          )}.`,
        );
      }
      setEligibilityErrors({});
      // setAnnualIncomeFlag(true);
      return;
    }
    setAnnualIncomeFlag(false);
    if (!ageBand) {
      setEligibilityErrors({
        generic: "No age band found for the given age",
      });
      return;
    }

    setEligibilityErrors({});
  };

  const eligibilityFlag = !Object.values(eligibilityErrors).some(
    eligibilityError => !!eligibilityError,
  );
  const getEducationValue = text => {
    const educationObject = education_list.find(
      education => education.education === text,
    );
    if (educationObject) return educationObject.education_value;
  };

  const getOccupationValue = text => {
    if (text !== "Occupation") {
      if (text === "Self Employed / Professional") return "self_employed";
      return occupation_list.find(occupation => occupation.occupation === text)
        ?.occupation_value;
    }
  };

  const getEducationICValue = text => {
    const educationObject = education_list.find(
      education => education.education === text,
    );
    if (educationObject) return educationObject.education_ic_value;
  };

  const getOccupationICValue = text => {
    const occupationICValue = occupation_list.find(
      occupation => occupation.occupation === text,
    )?.occupation_ic_value;
    return occupationICValue;
  };
  return {
    occupationDropDown:
      gender === "F"
        ? parseInt(extractNumbers(filter.coverUpto)) === 99 ||
          parseInt(extractNumbers(filter.coverUpto)) === 100
          ? occupationDropDown.filter(item => item !== "Housewife")
          : occupationDropDown
        : occupationDropDown.filter(item => item !== "Housewife"),

    educationDropDown,
    eligibilityFlag,
    eligibilityErrors,
    checkEligibility,
    education_list,
    setEligibilityErrors,
    errorMessages,
    occupation_list,
    getEducationICValue,
    getOccupationICValue,
    annualIncomeLimit,
    setAnnualIncomeLimit,
    annualIncomeFlag,
    newCover,
    showDisclaimer,
    setNewCover,
    filterBySalary:
      gender === "M" && company_alias === "kotak_life"
        ? filterBySalary.filter(item => item !== "Housewife")
        : filterBySalary,
  };
}

export { useEligibilityGrid };
