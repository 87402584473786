import React, { Fragment, useEffect, useState } from "react";
import Popup from "./Popup";

import {
  FormWrapper,
  Wrapper,
  Title,
  SubTitle,
  QuestionWrapper,
  Question,
  HeadingWrapper,
} from "./Monthly.style";
import CustomDropDown from "../CutomDropDown/CustomDropDown";
import { Button } from "../StyledButton/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoverAmountMonthly,
  setNomineeGets,
  setPopup,
} from "../../modules/QuoteFilter/quoteFilterSlice";
import { getAmount, getNumberfromString } from "../../utils/inputUtils";
import {
  getMultiplier,
  getMultiplierMonthly,
  numberToDigitWord,
} from "../../modules/QuoteFilter/helper";
import SecureLS from "secure-ls";
// import swal from "sweetalert";
import "./Monthly.scss";

const Monthly = ({ show, onClose, shouldClose = "shouldClose" }) => {
  const dispatch = useDispatch();
  const ls = new SecureLS();
  const { coverAmountMonthly, paymentTerm } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter,
  );
  const { customerDetails } = useSelector(state => state.greeting);
  const [nomineeMonthlyIncome, setNomineeMonthlyIncome] = useState(
    coverAmountMonthly.value,
  );
  const [multiplier, setMultiplier] = useState(
    getMultiplierMonthly(customerDetails.dob, customerDetails.annualIncome),
  );
  const [term, setTerm] = useState(coverAmountMonthly.term);
  const [termArr, setTermArr] = useState(["10 years"]);
  const getMaxSS = (multiplier, term) => {
    if (
      (term && getNumberfromString(term) === "10") ||
      customerDetails.annualIncome >= 500000 ||
      !Math.min(...Object.values(multiplier))
    )
      return Math.max(...Object.values(multiplier));
    else return Math.min(...Object.values(multiplier));
  };
  const [increaseIncome, setIncreaseIncome] = useState(
    coverAmountMonthly.increaseIncome,
  );
  const [increaseIncomeValidate, setIncreaseIncomeValidate] = useState(false);
  const [max, setMax] = useState();
  // useEffect(() => {
  //   if (
  //     (paymentTerm.option === "Regular Pay" ||
  //       paymentTerm.option === "Limited Pay") &&
  //     getNumberfromString(nomineeMonthlyIncome) < 50000
  //   ) {
  //     setTermArr(["100 months"]);
  //     setTerm("100 months");
  //   } else setTermArr(["100 months", "10 years"]);
  // }, [multiplier, nomineeMonthlyIncome, paymentTerm]);
  useEffect(() => {
    let maximumAvailable = Object.keys(multiplier).filter(
      key => multiplier[key] === getMaxSS(multiplier, term),
    );

    if (increaseIncome !== "Yes") {
      let temp =
        getMaxSS(multiplier, term) / (12 * Number(getNumberfromString(term)));
      setMax(Math.floor(temp / 5000) * 5000);

      // if (term === "100 months") {
      //   setMax(Math.floor((multiplier.bandhan_life * 1.2) / 100));
      // }
    }
    if (increaseIncome === "Yes") {
      // let temp =
      //   (getMaxSS(multiplier) * 10) /
      //   (6 *
      //     Number(getNumberfromString(term)) *
      //     (Number(getNumberfromString(term)) + 19));
      // setMax(Math.floor(temp / 5000) * 5000);
      let temp =
        getMaxSS(multiplier, term) / (12 * Number(getNumberfromString(term)));
      setMax(Math.floor(temp / 5000) * 5000);
      // if (term === "100 months") setTerm(termArr[1]);
    }
  }, [multiplier, term, increaseIncome]);

  useEffect(() => {
    if (customerDetails.annualIncome) {
      setMultiplier(
        getMultiplierMonthly(customerDetails.dob, customerDetails.annualIncome),
      );
    }
  }, [customerDetails]);

  const handleSubmit = evt => {
    evt.preventDefault();
    if (increaseIncome === "Please select") setIncreaseIncomeValidate(true);
    else {
      dispatch(setNomineeGets({ value: "Monthly" }));
      setIncreaseIncomeValidate(false);
      dispatch(setPopup(""));
      dispatch(
        setCoverAmountMonthly({
          value: nomineeMonthlyIncome,
          term,
          increaseIncome,
        }),
      );
    }
  };
  // useEffect(() => {
  //   if (
  //     getNumberfromString(nomineeMonthlyIncome) >= max &&
  //     increaseIncome === "Yes" &&
  //     show
  //   ) {
  //     setNomineeMonthlyIncome(numberToDigitWord(max, 5000));
  //     swal(
  //       `Your monthly income has been reduced by ₹ ${(
  //         getNumberfromString(nomineeMonthlyIncome) - max
  //       ).toLocaleString("en-IN")} to avail increasing income`
  //     );
  //   }
  // }, [increaseIncome, max, term]);
  const content = (
    <Fragment>
      <Wrapper modifyDetails>
        <HeadingWrapper modifyDetails>
          <Title>Nominee Gets (Monthly option)</Title>
          <SubTitle>
            Your nominee gets a monthly income whenever you are not around
          </SubTitle>
        </HeadingWrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <QuestionWrapper>
            <Question>
              How much monthly income you want your nominee to receive?
            </Question>
            <div className="mobDropQuotes">
              <CustomDropDown
                qtsMob="true"
                items={getAmount(
                  50000,
                  max,
                  max,
                  10000,
                  "monthlyIncome",
                ).reverse()}
                currentSelected={nomineeMonthlyIncome}
                searchable
                // min={20000}
                // max={max}
                // customInput
                // multiple={5000}
                selectedItem={nomineeMonthlyIncome}
                onChange={value => setNomineeMonthlyIncome(value)}
              />
            </div>
          </QuestionWrapper>

          <QuestionWrapper>
            <Question>
              Your nominee gets a monthly income whenever you are not around
            </Question>
            <div className="mobDropQuotes">
              <CustomDropDown
                qtsMob="true"
                items={
                  increaseIncome !== "Yes"
                    ? termArr.filter(item => {
                        let maximumAvailable = Object.keys(multiplier).filter(
                          key => multiplier[key] === getMaxSS(multiplier, item),
                        );
                        let temp = "";
                        // if (item === "100 months") return true;
                        if (maximumAvailable.includes("max_life")) {
                          if (item === "10 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 96.9 / 5000,
                              ) * 5000;
                          if (item === "15 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 131.51 / 5000,
                              ) * 5000;
                          if (item === "20 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 159.29 / 5000,
                              ) * 5000;
                        } else {
                          temp =
                            getMaxSS(multiplier, item) /
                            (12 * Number(getNumberfromString(item)));
                          temp = Math.floor(temp / 5000) * 5000;
                        }

                        return (
                          Math.floor(temp / 5000) * 5000 >=
                          getNumberfromString(nomineeMonthlyIncome)
                        );
                      })
                    : termArr.filter(item => {
                        let maximumAvailable = Object.keys(multiplier).filter(
                          key => multiplier[key] === getMaxSS(multiplier, item),
                        );
                        let temp = "";
                        // if (item === "100 months") return false;
                        if (maximumAvailable.includes("max_life")) {
                          if (item === "10 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 136.99 / 5000,
                              ) * 5000;
                          if (item === "15 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 212.84 / 5000,
                              ) * 5000;
                          if (item === "20 years")
                            temp =
                              Math.floor(
                                getMaxSS(multiplier, item) / 287.6 / 5000,
                              ) * 5000;
                        } else {
                          temp =
                            (getMaxSS(multiplier, item) * 10) /
                            (6 *
                              Number(getNumberfromString(item)) *
                              (Number(getNumberfromString(item)) + 19));
                          temp = Math.floor(temp / 5000) * 5000;
                        }
                        return (
                          Math.floor(temp / 5000) * 5000 >=
                          getNumberfromString(nomineeMonthlyIncome)
                        );
                      })
                }
                currentSelected={term}
                selectedItem={term}
                onChange={value => setTerm(value)}
              />
            </div>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question full>
              Would you like to increase the monthly income every year?
              <span className="SmallTextQuestionMob">
                (This ensures that your nominee is able to fight the battle with
                inflation)
              </span>
              <div className="SmallTextQuestion">
                (This ensures that your nominee is able to fight the battle with
                inflation)
              </div>
            </Question>

            <div className="mobDropQuotes">
              <CustomDropDown
                qtsMob="true"
                items={term.includes("100") ? ["No"] : ["Yes", "No"]}
                selectedItem={increaseIncome}
                onChange={value => {
                  setIncreaseIncome(value);
                  setIncreaseIncomeValidate(false);
                }}
                validate={increaseIncomeValidate}
                validateMessage={"Please select yes or no."}
              />
            </div>
          </QuestionWrapper>
          <div className="u-tcenter monBtnWrapp ">
            <Button secondary lg padding className=" monBtn btnMobi">
              CONFIRM
            </Button>
          </div>
        </FormWrapper>
      </Wrapper>
    </Fragment>
  );
  let width;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
  } else if (window.matchMedia("(max-width: 1177px)").matches) {
    width = "674px";
  } else {
    width = "752px";
  }
  return (
    <Fragment>
      <Popup
        float="true"
        show={show}
        onClose={onClose}
        content={content}
        height="auto"
        width={width}
        size="true"
        shouldClose={shouldClose}
        paddingLeft="111px"
        paddingMob="4vw"
        mdClose="true"
      ></Popup>
    </Fragment>
  );
};

export default Monthly;
