import React, { useState, useEffect } from "react";

import { DropDownHead, CustomDropDown } from "../../../../components";
import { QuotePageDropDown } from "../../../../components/Form/Form";
import "./filter-menu.scss";
import MobileFilter from "./MobileFilter";
function FilterMenu({
  className,
  addOnBtn,
  customInput,
  min,
  max,
  multiple,
  roundTo,
  addOnBtnOnClick,
  label,
  style,
  handleOnClick,
  headText,
  selected,
  items,
  searchable,
  notAllowed,
  onChange,
  popup,
  scrollActiveToMiddle,
  title,
  searchStartsWith,
  singleOption,
  wholeLife,
}) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(
    headText ? headText : selected ? selected : items[0],
  );

  useEffect(
    () => setText(headText ? headText : selected ? selected : items[0]),
    [selected, items, headText],
  );

  const handleChange = value => {
    setText(value);
    onChange && onChange(value);
  };

  const [refState, setRefState] = useState();

  const callBackRef = ref => {
    setRefState(ref);
  };

  // const { isQuotesLoading, quotesOnCompare } = useSelector(
  //   ({ quote }) => quote,
  // );

  return (
    <div
      className={`filter-menu ${className || ""} `}
      style={{
        // pointerEvents: isQuotesLoading || quotesOnCompare ? "none" : "all",
        ...(style && style),
      }}
    >
      <div
        style={{ color: handleOnClick ? "lightgray" : "" }}
        className="filter-menu__label"
      >
        {label}
      </div>
      {!open ? (
        <DropDownHead
          title={title}
          handleOnClick={handleOnClick}
          text={text}
          onClick={text => {
            !handleOnClick
              ? singleOption
                ? setOpen(false)
                : setOpen(true)
              : handleOnClick();
          }}
        />
      ) : (
        !handleOnClick && (
          <QuotePageDropDown mobileSm>
            {open && <DropDownHead text={text} />}
            <MobileFilter
              open={open}
              setOpen={setOpen}
              callBackRef={callBackRef}
              items={items}
              handleChange={handleChange}
              label={label}
              wholeLife={wholeLife}
            />
            <CustomDropDown
              refState={refState}
              items={items}
              onChange={handleChange}
              selectedItem={selected}
              searchable={searchable}
              customInput={customInput}
              style={{ display: `${open ? "flex" : "none"} ` }}
              isOpen={value => setOpen(value)}
              expand
              notAllowed={notAllowed && notAllowed}
              addOnBtn={addOnBtn}
              addOnBtnOnClick={addOnBtnOnClick}
              min={min}
              max={max}
              searchStartsWith={searchStartsWith}
              roundTo={roundTo}
              wholeLife={wholeLife}
              multiple={multiple}
              scrollActiveToMiddle={scrollActiveToMiddle}
            />
          </QuotePageDropDown>
        )
      )}
      {popup && popup}
    </div>
  );
}

export default FilterMenu;
