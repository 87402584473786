/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getValue } from "./../../../utils/inputUtils";
import { useDispatch, useSelector } from "react-redux";
import { saveEligibilityMessage } from "../../../modules/EligibilityPage/eligibilityCard.slice";

const useCheckUserEligibility = ({
  companyAlias,
  selectedOccupation,
  selectedEducation,
  annualIncome,
  gender,
  sumAssured,
  customerDetails,
}) => {
  const { selectedAddOns } = useSelector(state => state.rider);
  console.log("annual", Number(annualIncome) < 1000000);
  // const riderMatches = additionalRiders === 0;

  const ADBUMatches = selectedAddOns.some(
    item => item.riderShortname === "ADBU",
  );
  const CIBFMatches = selectedAddOns.some(
    item =>
      item.riderShortname === "CIBF" &&
      parseInt(item.riderSumAssured) > 1000000,
  );

  const [notEligibleEducation, setNotEligibleEducation] = useState(false);
  const [isErrorForNRI, setIsErrorForNRI] = useState(false);
  const [notEligibleOccupation, setNotEligibleOccupation] = useState(false);
  const [notEligibleAnnualIncome, setNotEligibleAnnualIncome] = useState(false);
  const [notEligibleSumAssured, setNotEligibleSumAssured] = useState(false);
  const [notEligibleADBURider, setNotEligibleADBURider] = useState(false);
  const [notEligibleCIBFRider, setNotEligibleCIBFRider] = useState(false);
  const dispatch = useDispatch();
  const [annualIncomeErrorMessage, setAnnualIncomeErrorMessage] =
    useState(false);
  const [sumInsuredErrorMessage, setSumInsuredErrorMessage] = useState(false);
  const [riderNotAllowed, setRiderNotAllowed] = useState(false);
  const [riderNotAllowedMessage, setRiderNotAllowedMessage] = useState(false);
  const [ageNotAllowed, setAgeNotAllowed] = useState(false);
  const [ageNotAllowedMessage, setAgeNotAllowedMessage] = useState(false);
  useEffect(() => {
    setNotEligibleEducation(false);
    setNotEligibleOccupation(false);
    setNotEligibleAnnualIncome(false);
    setNotEligibleSumAssured(false);
    setAnnualIncomeErrorMessage(false);
    dispatch(saveEligibilityMessage(""));
  }, []);
  useEffect(() => {
    switch (companyAlias) {
      case "icici_pru":
        // if (selectedOccupation === "Housewife") {
        //   setNotEligibleOccupation(true);
        // }
        if (
          selectedOccupation === "Housewife" &&
          !["Graduate", "Post Graduate"].includes(selectedEducation)
        ) {
          setNotEligibleEducation(true);
        }

        if (selectedOccupation === "Housewife" && ADBUMatches) {
          setNotEligibleADBURider(true);
        }
        if (selectedOccupation === "Housewife" && CIBFMatches) {
          setNotEligibleCIBFRider(true);
        }

        if (selectedEducation === "Below 10th") {
          setNotEligibleEducation(true);
        }
        if (gender === "M" && selectedOccupation === "Housewife") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Student") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Retired") {
          setNotEligibleOccupation(true);
        }
        // if (selectedOccupation === "Agriculturist" && !riderMatches) {
        //   setRiderNotAllowed(true);
        //   setRiderNotAllowedMessage("Rider Not Allowed");
        // }
        if (
          selectedOccupation === "Agriculturist" &&
          (selectedEducation === "Post Graduate" ||
            selectedEducation === "Graduate") &&
          Number(sumAssured) !== 5000000
        ) {
          setNotEligibleSumAssured(true);
          setSumInsuredErrorMessage(
            "Policy is available for only sum assured " +
              getValue((5000000).toString()),
          );
        }
        if (
          selectedOccupation === "Housewife" &&
          Number(sumAssured) > 5000000
        ) {
          setNotEligibleSumAssured(true);
          setSumInsuredErrorMessage(
            "Policy is available for only sum assured less than " +
              getValue((5000000).toString()),
          );
        }

        if (
          selectedOccupation === "Agriculturist" &&
          selectedEducation !== "Post Graduate" &&
          selectedEducation !== "Graduate"
        ) {
          setNotEligibleEducation(true);
        }
        if (selectedOccupation === "Others") {
          setNotEligibleOccupation(true);
        } else {
          setNotEligibleEducation(false);
          setNotEligibleSumAssured(false);
          setNotEligibleOccupation(false);
          //setNotEligibleADBURider(false);
          //setNotEligibleCIBFRider(false);
        }
        // if (
        // 	selectedOccupation === "Salaried" &&
        // 	(selectedEducation === "Post Graduate" ||
        // 		selectedEducation === "Graduate") &&
        // 	Number(annualIncome) < 300000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((300000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Salaried" &&
        // 	(selectedEducation === "Diploma" ||
        // 		selectedEducation === "12th Pass" ||
        // 		selectedEducation === "10th Pass") &&
        // 	Number(annualIncome) < 500000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((500000).toString())
        // 	);
        // }
        // if (
        // 	(selectedOccupation === "Salaried" ||
        // 		selectedOccupation === "Armed Forces") &&
        // 	selectedEducation === "10th Pass" &&
        // 	Number(annualIncome) < 1000000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((1000000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Self Employed" &&
        // 	(selectedEducation === "Post Graduate" ||
        // 		selectedEducation === "Graduate") &&
        // 	Number(annualIncome) < 500000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((500000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Self Employed" &&
        // 	(selectedEducation === "Diploma" ||
        // 		selectedEducation === "12th Pass") &&
        // 	Number(annualIncome) < 600000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((600000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Self Employed" &&
        // 	selectedEducation === "10th Pass" &&
        // 	Number(annualIncome) < 1000000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((1000000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Professional" &&
        // 	(selectedEducation === "Post Graduate" ||
        // 		selectedEducation === "Graduate") &&
        // 	Number(annualIncome) < 300000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((300000).toString())
        // 	);
        // }
        // if (
        // 	selectedOccupation === "Professional" &&
        // 	(selectedEducation === "Diploma" ||
        // 		selectedEducation === "12th Pass" ||
        // 		selectedEducation === "10th Pass") &&
        // 	Number(annualIncome) < 500000
        // ) {
        // 	setNotEligibleAnnualIncome(true);
        // 	setAnnualIncomeErrorMessage(
        // 		"Policy is not available for annual income < " +
        // 			getValue((500000).toString())
        // 	);
        // }
        break;
      case "kotak_life":
        if (selectedOccupation === "Student") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Minor") {
          setNotEligibleOccupation(true);
        }
        if (selectedEducation === "10th Pass ") {
          setNotEligibleEducation(true);
        }
        if (selectedEducation === "Below 10th ") {
          setNotEligibleEducation(true);
        }
        if (
          selectedOccupation === "Salaried" &&
          (selectedEducation === "Post Graduate" ||
            selectedEducation === "Graduate" ||
            selectedEducation === "12th Pass") &&
          Number(annualIncome) < 300000
        ) {
          setNotEligibleAnnualIncome(true);
          setAnnualIncomeErrorMessage(
            "Policy is not available for annual income < " +
              getValue((300000).toString()),
          );
        }
        if (
          selectedOccupation === "Self Employed" &&
          (selectedEducation === "Post Graduate" ||
            selectedEducation === "Graduate" ||
            selectedEducation === "12th Pass") &&
          Number(annualIncome) < 300000
        ) {
          setNotEligibleAnnualIncome(true);
          setAnnualIncomeErrorMessage(
            "Policy is not available for annual income < " +
              getValue((300000).toString()),
          );
        }
        if (selectedOccupation === "Agriculturist" && selectedAddOns.length) {
          setRiderNotAllowed(true);
          setRiderNotAllowedMessage(
            "Riders are not allowed for the selected occupation",
          );
        } else {
          setNotEligibleEducation(false);
          setNotEligibleOccupation(false);
          setNotEligibleAnnualIncome(false);
          setRiderNotAllowed(false);
          setRiderNotAllowedMessage("");
          setAnnualIncomeErrorMessage("");
        }
        break;
      case "max_life":
        if (customerDetails.age < 18 || customerDetails.age > 45) {
          setAgeNotAllowed(true);
          setIsErrorForNRI(true);
          setAgeNotAllowedMessage(
            "For NRI,Age should be in range of 18-45 years",
          );
        } else if (sumAssured > 400000000) {
          setIsErrorForNRI(true);
          setNotEligibleSumAssured(true);
          setSumInsuredErrorMessage("Maximum 4cr allowed");
        } else if (Number(annualIncome) < 1000000) {
          setNotEligibleAnnualIncome(true);
          setAnnualIncomeErrorMessage("Minimum 10 Lacs Annual Income Required");
          setIsErrorForNRI(true);
        } else if (
          selectedEducation !== "Post Graduate" &&
          selectedEducation !== "Graduate"
        ) {
          setIsErrorForNRI(true);
          setNotEligibleEducation(true);
        } else if (
          selectedOccupation !== "Salaried" &&
          selectedOccupation !== "Professional"
        ) {
          setIsErrorForNRI(true);
          setNotEligibleOccupation(true);
        } else if (selectedAddOns.length) {
          setIsErrorForNRI(true);
          setRiderNotAllowed(true);
          setRiderNotAllowedMessage("Riders not allowed for NRI");
        } else {
          setNotEligibleEducation(false);
          setAgeNotAllowed(false);
          setAgeNotAllowedMessage("");
          setRiderNotAllowed(false);
          setAnnualIncomeErrorMessage("");
          setNotEligibleAnnualIncome(false);
          setRiderNotAllowedMessage("");
          setIsErrorForNRI(false);
          setNotEligibleOccupation(false);
          setNotEligibleSumAssured(false);
          setSumInsuredErrorMessage("");
        }

        break;
      // case "max_life":
      //   if (
      //     selectedOccupation === "Salaried" &&
      //     (selectedEducation === "10th Pass" ||
      //       selectedEducation === "Below 10th")
      //   ) {
      //     setNotEligibleEducation(true);
      //   }
      //   if (
      //     selectedOccupation === "Salaried" &&
      //     selectedEducation === "12th Pass" &&
      //     Number(annualIncome) < 1000000
      //   ) {
      //     setNotEligibleAnnualIncome(true);
      //   }
      //   if (
      //     (selectedOccupation === "Self Employed" ||
      //       selectedOccupation === "Professional") &&
      //     (selectedEducation === "12th Pass" ||
      //       selectedEducation === "10th Pass" ||
      //       selectedEducation === "Below 10th")
      //   ) {
      //     if (selectedEducation !== "12th Pass") setNotEligibleEducation(true);
      //   }

      //   if (
      //     selectedOccupation === "Self Employed" &&
      //     (selectedEducation === "12th Pass" ||
      //       selectedEducation === "10th Pass" ||
      //       selectedEducation === "Below 10th")
      //   ) {
      //     if (selectedEducation !== "12th Pass") setNotEligibleEducation(true);
      //   }
      //   if (selectedOccupation === "Student") {
      //     setNotEligibleOccupation(true);
      //   }
      //   break;

      // case "bajaj_life":
      //   if (selectedOccupation === "Student") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (selectedOccupation === "Retired") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (
      //     selectedOccupation === "Agriculture" &&
      //     !(
      //       selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate"
      //     )
      //   ) {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (
      //     selectedOccupation === "Agriculture" &&
      //     (selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate") &&
      //     Number(sumAssured) > 5000000
      //   ) {
      //     setNotEligibleSumAssured(true);
      //     setSumInsuredErrorMessage(
      //       "Policy is not available for sum assured more than " +
      //         getValue((5000000).toString()),
      //     );
      //   }
      //   // if (
      //   //   selectedEducation !== "Highest Education" &&
      //   //   !(
      //   //     selectedEducation === "Post Graduate" ||
      //   //     selectedEducation === "Graduate"
      //   //   ) &&
      //   //   !riderMatches
      //   // ) {
      //   //   console.log("rider", selectedEducation);
      //   //   console.trace();
      //   //   setRiderNotAllowed(true);
      //   //   setRiderNotAllowedMessage("Rider Not Allowed");
      //   // }

      //   if (selectedOccupation === "Unemployed") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (selectedEducation === "Non Matriculation") {
      //     setNotEligibleEducation(true);
      //   }
      //   if (
      //     selectedOccupation === "Housewife" &&
      //     !(
      //       selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate"
      //     )
      //   ) {
      //     setNotEligibleEducation(true);
      //   }
      //   // if (
      //   //   (selectedOccupation === "Housewife" ||
      //   //     selectedOccupation === "Agriculture") &&
      //   //   !riderMatches
      //   // ) {
      //   //   setRiderNotAllowed(true);
      //   //   console.trace();
      //   //   setRiderNotAllowedMessage("Rider Not Allowed");
      //   // }

      //   if (
      //     selectedOccupation === "Housewife" &&
      //     (selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate") &&
      //     Number(sumAssured) > 5000000
      //   ) {
      //     setNotEligibleSumAssured(true);
      //     setSumInsuredErrorMessage(
      //       "Policy is not available for sum assured more than " +
      //         getValue((5000000).toString()),
      //     );
      //   }

      //   // if (
      //   // 	(selectedOccupation === "Salaried (Govt.)" ||
      //   // 		selectedOccupation === "Salaried (Non Govt.)") &&
      //   // 	selectedEducation === "Masters and Above" &&
      //   // 	selectedEducation === "Graduation" &&
      //   // 	selectedEducation === "HSC" &&
      //   // 	Number(annualIncome) < 300000
      //   // ) {
      //   // 	setNotEligibleAnnualIncome(true);
      //   // 	setAnnualIncomeErrorMessage(
      //   // 		"Policy is not available for annual income < " +
      //   // 			getValue((300000).toString())
      //   // 	);
      //   // }
      //   // if (
      //   // 	(selectedOccupation === "Business" ||
      //   // 		selectedOccupation === "Professional") &&
      //   // 	selectedEducation === "Masters and Above" &&
      //   // 	selectedEducation === "Graduation" &&
      //   // 	selectedEducation === "HSC" &&
      //   // 	Number(annualIncome) < 500000
      //   // ) {
      //   // 	setNotEligibleAnnualIncome(true);
      //   // 	setAnnualIncomeErrorMessage(
      //   // 		"Policy is not available for annual income < " +
      //   // 			getValue((500000).toString())
      //   // 	);
      //   // }
      //   // if (
      //   // 	(selectedOccupation === "Salaried (Govt.)" ||
      //   // 		selectedOccupation === "Salaried (Non Govt.)" ||
      //   // 		selectedOccupation === "Business" ||
      //   // 		selectedOccupation === "Professional") &&
      //   // 	selectedEducation === "Matriculation" &&
      //   // 	Number(annualIncome) < 1000000
      //   // ) {
      //   // 	setNotEligibleAnnualIncome(true);
      //   // 	setAnnualIncomeErrorMessage(
      //   // 		"Policy is not available for annual income < " +
      //   // 			getValue((1000000).toString())
      //   // 	);
      //   // }
      //   // if (Number(annualIncome) < 500000 && !riderMatches) {
      //   //   setRiderNotAllowed(true);
      //   //   console.trace();
      //   //   setRiderNotAllowedMessage("Rider Not Allowed");
      //   // }
      //   if (
      //     selectedOccupation === "Income from other sources" &&
      //     (selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate") &&
      //     Number(sumAssured) > 5000000
      //   ) {
      //     setNotEligibleSumAssured(true);

      //     setSumInsuredErrorMessage(
      //       "Policy is not available for sum assured more than " +
      //         getValue((5000000).toString()),
      //     );
      //   }
      //   if (
      //     selectedOccupation === "Income from other sources" &&
      //     !(
      //       selectedEducation === "Post Graduate" ||
      //       selectedEducation === "Graduate"
      //     )
      //   ) {
      //     setNotEligibleEducation(true);
      //   }
      //   if (
      //     selectedOccupation === "Income from other sources" &&
      //     customerDetails.age > 60
      //   ) {
      //     setAgeNotAllowed(true);
      //     setAgeNotAllowedMessage("Policy is available for age less than 60");
      //   }

      //   break;

      // case "bandhan_life":
      //   if (selectedOccupation === "Woker/Labour") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (selectedOccupation === "Student") {
      //     setNotEligibleOccupation(true);
      //   }

      //   if (selectedOccupation === "Agriculturist") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (selectedOccupation === "Driver") {
      //     setNotEligibleOccupation(true);
      //   }
      //   if (selectedEducation === "10th Pass") {
      //     setNotEligibleEducation(true);
      //   }

      // if (
      // 	selectedOccupation === "Salaried" &&
      // 	(selectedEducation === "Post Graduate" ||
      // 		selectedEducation === "Professional Degree" ||
      // 		selectedEducation === "Graduate") &&
      // 	Number(annualIncome) < 200000
      // ) {
      // 	setNotEligibleAnnualIncome(true);
      // 	setAnnualIncomeErrorMessage(
      // 		"Policy is not available for annual income < " +
      // 			getValue((200000).toString())
      // 	);
      // }
      // if (
      // 	selectedOccupation === "Salaried" &&
      // 	selectedEducation === "Diploma" &&
      // 	Number(annualIncome) < 600000
      // ) {
      // 	setNotEligibleAnnualIncome(true);
      // 	setAnnualIncomeErrorMessage(
      // 		"Policy is not available for annual income < " +
      // 			getValue((600000).toString())
      // 	);
      // }
      // if (
      // 	selectedOccupation === "Salaried" &&
      // 	selectedEducation === "HSC" &&
      // 	Number(annualIncome) < 500000
      // ) {
      // 	setNotEligibleAnnualIncome(true);
      // 	setAnnualIncomeErrorMessage(
      // 		"Policy is not available for annual income < " +
      // 			getValue((500000).toString())
      // 	);
      // }
      // if (
      // 	(selectedOccupation === "Self Employed (Business)" ||
      // 		selectedOccupation === "Self Employed (Professional)" ||
      // 		selectedOccupation === "Police") &&
      // 	(selectedEducation === "Post Graduate" ||
      // 		selectedEducation === "Professional Degree" ||
      // 		selectedEducation === "Graduate") &&
      // 	Number(annualIncome) < 300000
      // ) {
      // 	setNotEligibleAnnualIncome(true);
      // 	setAnnualIncomeErrorMessage(
      // 		"Policy is not available for annual income < " +
      // 			getValue((300000).toString())
      // 	);
      // }
      // if (
      // 	(selectedOccupation === "Self Employed (Business)" ||
      // 		selectedOccupation === "Self Employed (Professional)" ||
      // 		selectedOccupation === "Police") &&
      // 	(selectedEducation === "Diploma" || selectedEducation === "HSC") &&
      // 	Number(annualIncome) < 500000
      // ) {
      // 	setNotEligibleAnnualIncome(true);
      // 	setAnnualIncomeErrorMessage(
      // 		"Policy is not available for annual income < " +
      // 			getValue((500000).toString())
      // 	);
      // }
      // break;
      case "bajaj_life":
        if (
          selectedOccupation === "Agriculturist" &&
          selectedAddOns.length &&
          !["HSC", "Matriculation", "Non Matriculation"]?.includes(
            selectedEducation,
          )
        ) {
          setRiderNotAllowed(true);
          setRiderNotAllowedMessage(
            "Riders are not allowed for the selected occupation",
          );
        }
        break;
      case "tata_aia_life":
        if (
          selectedOccupation === "Housewife" &&
          selectedEducation === "Below 10th"
        ) {
          setNotEligibleEducation(true);
        }
        if (
          selectedOccupation === "Others" &&
          selectedEducation === "Below 10th"
        ) {
          setNotEligibleEducation(true);
        }

        if (selectedOccupation === "Student") {
          setNotEligibleOccupation(true);
        }

        if (
          selectedOccupation === "Agriculturist" &&
          selectedEducation === "Below 10th"
        ) {
          setNotEligibleEducation(true);
        }
        if (
          selectedOccupation === "Retired" &&
          selectedEducation === "Below 10th"
        ) {
          setNotEligibleEducation(true);
        }

        if (
          selectedOccupation === "Business Owner" &&
          selectedEducation === "Below 10th"
        ) {
          setNotEligibleEducation(true);
        }

        if (selectedEducation === "Below 10th") {
          setNotEligibleEducation(true);
        }

        break;
      case "pnb_metlife":
        if (selectedOccupation === "Housewife") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Student") {
          setNotEligibleOccupation(true);
        }

        if (selectedOccupation === "Others") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Farmer") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Landowner") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Retired") {
          setNotEligibleOccupation(true);
        }
        if (selectedOccupation === "Unemployed") {
          setNotEligibleOccupation(true);
        }
        if (selectedEducation === "10th Pass") {
          setNotEligibleEducation(true);
        }
        if (selectedEducation === "Below 10th") {
          setNotEligibleEducation(true);
        }

        if (
          (selectedOccupation === "Service - Public Sector" ||
            selectedOccupation === "Service - Private Sector" ||
            selectedOccupation === "Service - Government Sector" ||
            selectedOccupation === "Service - Others" ||
            selectedOccupation === "Professional") &&
          (selectedEducation === "Post Graduate" ||
            selectedEducation === "Diploma" ||
            selectedEducation === "Graduate" ||
            selectedEducation === "12th Pass") &&
          Number(annualIncome) < 300000
        ) {
          setNotEligibleAnnualIncome(true);
          setAnnualIncomeErrorMessage(
            "Policy is not available for annual income < " +
              getValue((300000).toString()),
          );
        }

        if (
          (selectedOccupation === "Self Employed" ||
            selectedOccupation === "Business") &&
          (selectedEducation === "Post Graduate" ||
            selectedEducation === "Diploma" ||
            selectedEducation === "Graduate" ||
            selectedEducation === "12th Pass") &&
          Number(annualIncome) < 400000
        ) {
          setNotEligibleAnnualIncome(true);
          setAnnualIncomeErrorMessage(
            "Policy is not available for annual income < " +
              getValue((400000).toString()),
          );
        } else {
          setNotEligibleAnnualIncome(false);
          setAnnualIncomeErrorMessage("");
          setNotEligibleEducation(false);
          setNotEligibleOccupation(false);
        }

        break;
      default:
        console.error("error");
    }
  }, [
    selectedOccupation,
    selectedEducation,
    annualIncome,
    gender,
    sumAssured,
    selectedAddOns,
  ]);

  return {
    notEligibleEducation,
    notEligibleAnnualIncome,
    notEligibleOccupation,
    notEligibleSumAssured,
    isErrorForNRI,
    setNotEligibleEducation,
    setNotEligibleOccupation,
    setNotEligibleAnnualIncome,
    annualIncomeErrorMessage,
    setAnnualIncomeErrorMessage,
    setNotEligibleSumAssured,
    sumInsuredErrorMessage,
    setSumInsuredErrorMessage,
    riderNotAllowed,
    riderNotAllowedMessage,
    setRiderNotAllowedMessage,
    setRiderNotAllowed,
    notEligibleADBURider,
    notEligibleCIBFRider,
    setNotEligibleADBURider,
    setNotEligibleCIBFRider,
    ageNotAllowed,
    ageNotAllowedMessage,
    setAgeNotAllowed,
    setAgeNotAllowedMessage,
  };
};

export default useCheckUserEligibility;
