import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TransparentButton } from "../../components/StyledButton/Button";
import orangeArrow from "../../assets/img/orange-arrow.svg";

import { Error } from "../../components";
import Loader from "./../../components/Loader/Loader";
import {
  saveUserDetails,
  initCreateUser,
  createUserSuccess,
  getAffinityPreference,
} from "./greeting.slice";
import LaxmiGreeting from "../../assets/img/laxmiImg.png";
import { BackArrow } from "../ComparePage/SvgComp";
import { Container, Form, LaxmiImg, LaxmiWrap } from "./Form1.style";
import {
  BoldHeader,
  CheckMark,
  Continue,
  LightHeader,
  LightHeader2,
  Radio,
  RadioButton,
  RadioText,
  Row,
  SmokingWrap,
  SubHeader2,
} from "./Form2style";
import { BackButton } from "./BackButton";
import { Step } from "./Step";
import { DomainFooter } from "../../components/DomainFooter";

const Form2 = ({ history }) => {
  const { isError } = useSelector(({ quote }) => quote);
  const [withContinue, setWithContinue] = useState(false);
  const Details = useSelector(state => state.greeting.customerDetails);
  const { status, isLoading, traceId, emailDiscount, showFooter } = useSelector(
    state => state.greeting,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (status && Details?.tobaccoHabit) {
      history.push("/form3");
      dispatch(createUserSuccess());
    }
  }, [dispatch, history, status, Details?.tobaccoHabit]);

  const onChange = event => {
    const smoker = event.target.value;
    if (smoker) {
      dispatch(
        saveUserDetails({
          ...Details,
          traceId,
          tobaccoHabit: smoker,
          create: false,
        }),
      );

      dispatch(initCreateUser());
    }
  };
  useEffect(() => {
    if (Details.tobaccoHabit) {
      setWithContinue("true");
    }
  }, []);

  if (isError) return <Error errorMessage={isError} />;

  return (
    <>
      <Container>
        <Form>
          <LaxmiWrap>
            <LaxmiImg src={LaxmiGreeting}></LaxmiImg>
          </LaxmiWrap>
          <div>
            {Details.tobaccoHabit && (
              <Link to="/form3">
                <Continue>
                  <div>
                    <span>continue</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#f2581b"
                    >
                      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                </Continue>
              </Link>
            )}
            <SubHeader2>We are almost done.</SubHeader2>
            <BoldHeader>Do you chew or smoke tobacco?</BoldHeader>
            <LightHeader>
              Say 'No' if you have 'STOPPED' chewing/smoking tobacco in last 12
              months.
            </LightHeader>
            <LightHeader2>
              These would include products like cigarettes, e-cigarettes,
              cigars, pipes, chewing tobacco, nicotine gum or patches, etc.
            </LightHeader2>
            <Row>
              <SmokingWrap>
                <Radio
                  type="radio"
                  name="Smoking"
                  id="Y"
                  value="Y"
                  defaultChecked={Details?.tobaccoHabit === "Y" && true}
                  onClick={onChange}
                ></Radio>
                <RadioButton first htmlFor="Y">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#107591"
                    viewBox="0 0 24 24"
                  >
                    <path d="M2 16h15v3H2zm18.5 0H22v3h-1.5zM18 16h1.5v3H18zm.85-8.27c.62-.61 1-1.45 1-2.38C19.85 3.5 18.35 2 16.5 2v1.5c1.02 0 1.85.83 1.85 1.85S17.52 7.2 16.5 7.2v1.5c2.24 0 4 1.83 4 4.07V15H22v-2.24c0-2.22-1.28-4.14-3.15-5.03zm-2.82 2.47H14.5c-1.02 0-1.85-.98-1.85-2s.83-1.75 1.85-1.75v-1.5c-1.85 0-3.35 1.5-3.35 3.35s1.5 3.35 3.35 3.35h1.53c1.05 0 1.97.74 1.97 2.05V15h1.5v-1.64c0-1.81-1.6-3.16-3.47-3.16z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                  <RadioText>YES</RadioText>
                  <CheckMark></CheckMark>
                </RadioButton>
                <Radio
                  type="radio"
                  name="Smoking"
                  id="N"
                  value="N"
                  defaultChecked={Details?.tobaccoHabit === "N" && true}
                  onClick={onChange}
                ></Radio>
                <RadioButton htmlFor="N" no>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#107591"
                    width="48px"
                    height="48px"
                  >
                    <path d="M2 6l6.99 7H2v3h9.99l7 7 1.26-1.25-17-17zm18.5 7H22v3h-1.5zM18 13h1.5v3H18zm.85-8.12c.62-.61 1-1.45 1-2.38h-1.5c0 1.02-.83 1.85-1.85 1.85v1.5c2.24 0 4 1.83 4 4.07V12H22V9.92c0-2.23-1.28-4.15-3.15-5.04zM14.5 8.7h1.53c1.05 0 1.97.74 1.97 2.05V12h1.5v-1.59c0-1.8-1.6-3.16-3.47-3.16H14.5c-1.02 0-1.85-.98-1.85-2s.83-1.75 1.85-1.75V2c-1.85 0-3.35 1.5-3.35 3.35s1.5 3.35 3.35 3.35zm2.5 7.23V13h-2.93z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                  <RadioText>NO</RadioText>
                  <CheckMark></CheckMark>
                </RadioButton>
              </SmokingWrap>
            </Row>
            <Step count={2} withContinue={withContinue} />
            <Link to="/form1">
              <BackButton></BackButton>
            </Link>
          </div>
        </Form>
        {isLoading && <Loader />}
        {(emailDiscount || showFooter) && <DomainFooter />}
      </Container>
    </>
  );
};

export default Form2;
