import { createSlice } from "@reduxjs/toolkit";
import { singleQuote } from "../../serviceApi";
import SecureLS from "secure-ls";
const ls = new SecureLS();
const maxSingleQuoteSlice = createSlice({
  name: "maxSingleQuote",
  initialState: {
    maxSingleQuote: false,
    maxPlanCard: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.maxSingleQuote = action.payload;
    },
    saveMaxPlanDetails: (state, action) => {
      state.maxPlanCard = action.payload;
    },
  },
});

export const { saveDetails, saveMaxPlanDetails } = maxSingleQuoteSlice.actions;

export const saveMaxSingleQuoteDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveDetails(data.singleProductData.requestData.data));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export const saveMaxPlanCardDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveMaxPlanDetails(data.singleProductData));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default maxSingleQuoteSlice.reducer;
