import React from "react";

import "./sub-filter-wrap.scss";

function SubFilterWrap(props) {
  // const { isQuotesLoading } = useSelector(({ quote }) => quote);

  return (
    <div
      className={`sub-filter-wrap ${
        // isQuotesLoading ? 'block' : ''
        ""
      }`}
    >
      {props.children}
    </div>
  );
}

export default SubFilterWrap;
