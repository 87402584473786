export const medicalDetails3Data = data => [
  {
    id: "Disease1",
    label:
      "Chest Pain / Heart Attack / Blood Presure / High Cholosterol / other cardiovascular  disease or disorder?",
    items: ["Yes", "No"],
    value: data?.chestPainQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "Disease1DropDown",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Chest Pain",
                "Heart Attack",
                "High Cholosterol",
                "Blood Pressure",
                "other cardiovascular disease or disorder",
              ],
              value: data?.chestPainDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              searchable: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "Disease1Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.chestPainDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "Disease1CurrentMedication",
              label: "What is the current medication?",
              size: "md",
              value: data?.chestPainCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current Medication is Required",
                freeText: true,
              },
            },
            {
              id: "Disease1ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.chestPainControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "current control values is Required",
                freeText: true,
              },
            },
            {
              id: "Disease1ControlComplication",
              label: "Any other complication",
              size: "md",
              value: data?.chestPainOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "Disease2",
    label: "Angioplasty / Bypass Surgery / any other Heart related surgery?",
    items: ["Yes", "No"],
    value: data?.angioplastyQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "Disease2DropDown",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Angioplasty",
                "Bypass Surgery",
                "any other Heart related surgery",
              ],
              value: data?.angioplastyDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "Disease2Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.angioplastyDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "Disease2Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.angioplastyCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "Disease2Value",
              label: "What is the current control values?",
              size: "md",
              value: data?.angioplastyControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "Disease2Complication",
              label: "Any other complication",
              size: "md",
              value: data?.angioplastyOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases3",
    label:
      "Diabetes / High Blood Sugar / Sugar in Urine / Other Endocrine system disorders such as hypothyroidisam?",
    items: ["Yes", "No"],
    value: data?.diabetesQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases3Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Diabetes",
                "High Blood Sugar",
                "Sugar in Urine",
                "Other Endocrine system disorders such as hypothyroidisam",
              ],
              value: data?.diabetesDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases3Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.diabetesDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases3Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.diabetesCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases3ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.diabetesControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases3Complication",
              label: "Any other complication",
              size: "md",
              value: data?.diabetesOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases4",
    label: "Asthma / Tuberculosis / any other respiratory disorder?",
    items: ["Yes", "No"],
    value: data?.asthmaQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases4Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Asthma",
                "Tuberculosis",
                "any other respiratory disorder",
              ],
              value: data?.asthmaDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases4Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.asthmaDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases4Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.asthmaCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases4ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.asthmaControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases4Complication",
              label: "Any other complication",
              size: "md",
              value: data?.asthmaOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases5",
    label:
      "Stroke / Paralysis / Epilepsy / Heart Injury / Other Nervous disorder?",
    items: ["Yes", "No"],
    value: data?.strokeQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases5Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Stroke",
                "Paralysis",
                "Epilepsy",
                "Heart Injury",
                "Other Nervous disorder",
              ],
              value: data?.strokeDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              searchable: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases5Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.strokeDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases5Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.strokeCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases5ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.strokeControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases5Complication",
              label: "Any other complication",
              size: "md",
              value: data?.strokeOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases6",
    label:
      "Pancreatitis / Colitis / Recurrent Indigestion / Ulcers / other Gastrointestnal disorders?",
    items: ["Yes", "No"],
    value: data?.pancreaQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases6Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Pancreatitis",
                "Colitis",
                "Recurrent Indigestion",
                "Ulcers",
                "other Gastrointestnal disorders",
              ],
              value: data?.pancreaDiseaseName || "Select",
              searchable: true,
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases6Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.pancreaDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases6Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.pancreaCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases6ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.pancreaControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases6Complication",
              label: "Any other complication",
              size: "md",
              value: data?.pancreaOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
  {
    id: "diseases7",
    label: "Liver or gall bladder disorders / Jaundice / Hepatitis B or C?",
    items: ["Yes", "No"],
    value: data?.liverQuestion === "Y" ? "Yes" : "No",
    size: "xlg",
    type: "toggle",
    nextLine: true,
    alignLeft: true,
    extraMarginTop: true,
    addCorrespondingItemsOnChange: value => {
      switch (value) {
        case "Yes":
          return [
            {
              id: "diseases7Diseases",
              label: "Name Of Disease",
              size: "md",
              type: "select",
              items: [
                "Liver or gall bladder disorders",
                "Jaundice",
                "Hepatitis B or C",
              ],
              value: data?.liverDiseaseName || "Select",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Name Of Disease is required",
              },
            },
            {
              id: "diseases7Time",
              label: "When was the disease diagnosed",
              size: "md",
              value: data?.liverDiseaseDiagnosed || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
            {
              id: "diseases7Medication",
              label: "What is the current medication?",
              size: "md",
              value: data?.liverCurrMedication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current medication is Required",
                freeText: true,
              },
            },
            {
              id: "diseases7ControlValue",
              label: "What is the current control values?",
              size: "md",
              value: data?.liverControlVal || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "Current control values is Required",
                freeText: true,
              },
            },
            {
              id: "diseases7Complication",
              label: "Any other complication",
              size: "md",
              value: data?.liverOtherComplication || "",
              nextLine: true,
              alignLeft: true,
              extraMarginTop: true,
              validations: {
                required: "This Field is Required",
                freeText: true,
              },
            },
          ];
        default:
          return [];
      }
    },
  },
];
