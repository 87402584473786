import { yupResolver } from "@hookform/resolvers";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../assets/img/editIcon.svg";
import {
  dateUtil,
  numOnly,
  upperCase,
  noNum,
  noSpecial,
  noSpclChars,
  lowerCase,
} from "../../utils/inputUtils";
import Card from "../Card/Card";
import CardTitle from "../Card/CardTitle/CardTitle";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  AgeWrapper,
  TextInputElg,
} from "../Form/Form";
import { formCardSchema } from "./../../utils/validationSchema";
import { data } from "./data";
import "./FormCard.scss";
import { savePersonalDetails } from "../../modules/EligibilityPage/formCard.slice";
import SecureLS from "secure-ls";

const FormCard = props => {
  const ls = new SecureLS();
  const [panCountry, setPanCountry] = useState(true);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [age, setAge] = useState("");
  // const [customerDetails, setCustomerDetails] = useState({});
  const { customerDetails, isPnbPreference } = useSelector(
    ({ greeting }) => greeting,
  );

  let { details } = useSelector(state => state.formCard);
  if (details) {
    details = { ...details, dob: customerDetails.dob };
  }
  // const customerDetails = sessionStorage.getItem("customerDetails");
  const { eligibilityDetails } = useSelector(state => state.eligibilityCard);
  const { register, handleSubmit, errors, formState, setValue, trigger } =
    useForm({
      resolver: yupResolver(formCardSchema),
      mode: "onChange",
      defaultValues: {
        panCountry: true,
        affinityEmail: false,
      },
    });
  const [selectedQuote, setSelectedQuote] = useState({});

  useEffect(() => {
    setSelectedQuote(props.selectedQuote);
  }, [props.selectedQuote]);
  const premiumAmount = props.selectedQuote.premium_amount;

  useEffect(() => {
    register({ name: "panCountry" });
    register({ name: "affinityEmail" });
  }, []);

  useEffect(() => {
    if (
      selectedQuote.company_alias === "bandhan_life" ||
      selectedQuote.company_alias === "hdfc_life" ||
      selectedQuote.company_alias === "tata_aia_life" ||
      selectedQuote.company_alias === "kotak_life" ||
      selectedQuote.company_alias === "pnb_metlife" ||
      selectedQuote.company_alias === "bajaj_life" ||
      selectedQuote.company_alias === "max_life"
    ) {
      setValue("panCountry", false);
      trigger();
    } else {
      setValue("panCountry", true);
      trigger();
    }
    if (selectedQuote.company_alias === "icici_pru") {
      setPanCountry(false);
    }
  }, [selectedQuote.company_alias]);

  useEffect(() => {
    if (isPnbPreference) {
      setValue("affinityEmail", true);
      trigger();
    } else {
      setValue("affinityEmail", false);
      trigger();
    }
  }, [isPnbPreference]);

  // useEffect(() => {
  //   if (allDetails) {
  //     const {
  //       term_lead_first_name: firstName,
  //       term_lead_last_name: lastName,
  //       term_lead_mobile: mobileNo,
  //       term_lead_dob: Ndob,
  //       term_lead_email: email,
  //       term_lead_age: Nage,
  //     } = allDetails?.requestData?.data?.quote_data;

  //     const dob = Ndob.split('-').reverse().join('/');
  //     setCustomerDetails({ firstName, lastName, mobileNo, dob, email, Nage });
  //   }
  // }, [allDetails]);

  const { isValid } = formState;

  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };

  const handleChange = event => {
    setAge(dateUtil(event, date));
  };

  const onSubmit = data => {
    setEdit(false);
    data["dob"] = data["dob"].replace(/ /g, "");
    const da = data.dob.split("/").reverse().join("-");
    delete data.dob;
    if (data["pan"]) data["pan"] = data["pan"].toUpperCase();

    const newData = {
      ...eligibilityDetails,
      ...data,
      annualIncome: eligibilityDetails?.annualIncome,
      city: eligibilityDetails?.selectedCity,
      heartPatient: eligibilityDetails?.isHeartPatient,
      natureOfWork: eligibilityDetails?.selectedNatureOfWork,
      nri: eligibilityDetails?.isNRI,
      nriCountry: eligibilityDetails?.selectedCountry || "",
      pincode: eligibilityDetails?.pinCode,
      state: eligibilityDetails?.state,
      termEducation: eligibilityDetails?.termEducation,
      termEducation_text: eligibilityDetails?.selectedEducation || "",
      termOccupation: eligibilityDetails?.termOccupation,
      termOccupation_text: eligibilityDetails?.selectedOccupation || "",
      selectedCountry: eligibilityDetails?.selectedCountry || "",
      dob: da,
    };
    dispatch(savePersonalDetails(newData));
    const eligibilityCardDiv = document.getElementById("eligibility-card");

    if (eligibilityCardDiv) {
      eligibilityCardDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {details && !edit ? (
        <Card className="review-card-container-elg" elg>
          <CardTitle
            EC
            eligibilityTitle
            className="title-eligibility mobTitle-elig title-eligibility-prev"
          >
            Personal details
            <span
              style={{
                float: "right",
                fontSize: "12px",
                color: "#107591",
                cursor: "pointer",
              }}
              className="editBtnFormcard"
              onClick={() => setEdit(true)}
            >
              <img src={editIcon} alt="editIcon" />
              &nbsp;EDIT
            </span>
          </CardTitle>
          <div
            className="prevFormCard"
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
              minHeight: "216px",
            }}
          >
            {data.map(d => {
              //	{	d.name==="pan"&& details[d.name]==="" &&()}
              if (d.name === "pan" && panCountry) return <></>;
              return (
                <FormGroup
                  style={{ height: 48 }}
                  md
                  key={d.name}
                  editMode
                  className={`prevItemsFormCard`}
                >
                  {d.name === "dob" ? (
                    <Label elig2 md editMode htmlFor={d.name}>
                      Date of Birth
                      <span className="eligibilityFormCard_dob">
                        {" "}
                        (DD/MM/YYYY){" "}
                      </span>
                    </Label>
                  ) : (
                    <span
                      className={` ${
                        d.name === "pan" && details[d.name] === ""
                          ? "prevItemsFormCardHide"
                          : ""
                      }`}
                    >
                      <Label elig2 md editMode htmlFor={d.name}>
                        {d.name === "pan" && panCountry
                          ? d.label + " (optional)"
                          : d.label}
                      </Label>
                    </span>
                  )}
                  <span
                    className="data labelForm1"
                    style={{
                      top: "27px",
                      left: "15px",
                      position: "relative",
                      fontSize: 14,
                      wordBreak: "break-all",
                      whiteSpace: "normal",
                    }}
                  >
                    {details[d.name]}
                  </span>
                  {d.name === "dob"
                    ? (customerDetails?.age || age) && (
                        <AgeWrapper
                          // style={{
                          // 	top: "50px",
                          // 	right: "0px",
                          // 	width: "70px",
                          // 	height: "35px",
                          // }}
                          className="ageWrap card-dob-agewrapper"
                        >
                          {customerDetails?.age || age} Years
                        </AgeWrapper>
                      )
                    : null}
                </FormGroup>
              );
            })}
          </div>
        </Card>
      ) : edit ? (
        <Card className="first-card-container-elg" elg>
          <CardTitle
            eligibilityTitle
            className="title-eligibility mobTitle-elig title-eligibility-form1"
          >
            Personal details
          </CardTitle>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            {data.map(d => {
              if (d.name === "pan" && panCountry) return <></>;
              return (
                <FormGroup cardElig eligibilityPage key={d.id} margElg>
                  <TextInputElg
                    md
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    defaultValue={details[d.name]}
                    id={d.id}
                    maxLength={d.maxLength}
                    readOnly={d.readOnly}
                    style={{
                      textTransform:
                        ((d.name === "firstName" || d.name === "lastName") &&
                          "capitalize") ||
                        (d.name === "pan" && "uppercase"),
                    }}
                    // {premiumAmount === "50000" ? (d.name === "pans") : (d.name === "pan")}
                    onChange={d.name === "dob" ? handleChange : null}
                    // onInput={d.name === "mobileNo" ? numOnly : null}
                    placeholder={d.placeHolder}
                    //autoComplete={d.autoComplete}
                    // onKeyPress={d.name === "pan" ? noSpclChars : null}
                    onInput={e =>
                      d.name === "pan"
                        ? noSpclChars(e)
                        : d.name === "mobileNo"
                        ? numOnly(e)
                        : null
                    }
                    pattern={d.name === "mobileNo" ? "[5-9]{1}[0-9]{9}" : null}
                    ref={d.name === "dob" ? attachRef : register}
                    title={
                      (d.name === "secondary_email" || d.name === "pan") &&
                      "Please fill out this field"
                    }
                  />

                  {d.name === "dob" ? (
                    <Label md editMode htmlFor={d.name} eligibilityPage elig>
                      Date of Birth
                      <span className="eligibilityFormCard_dob">
                        {" "}
                        (DD/MM/YYYY){" "}
                      </span>
                    </Label>
                  ) : (
                    <Label eligibilityPage elig md htmlFor={d.id} frmCard>
                      {d.name === "pan" && panCountry
                        ? d.label + " (optional)"
                        : d.label}
                    </Label>
                  )}
                  {d.name === "dob"
                    ? age && (
                        <AgeWrapper
                          // style={{
                          // 	top: "50px",
                          // 	right: "0px",
                          // 	width: "70px",
                          // 	height: "35px",
                          // }}
                          className="ageWrap"
                        >
                          {age} Years
                        </AgeWrapper>
                      )
                    : null}
                  {errors[d.id] && (
                    <p className="error errorElg">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              );
            })}
            <FormGroup xlg eligibilityPage className="formCardBtnWrapper">
              <button
                type="submit"
                className="btn btn--highlighted btn--lg uppercase bold proc formCardBtn"
              >
                {!details ? "PROCEED TO ELIGIBILITY" : "SAVE"}
              </button>
            </FormGroup>
          </Form>
        </Card>
      ) : (
        <Card className="first-card-container-elg" elg>
          <CardTitle
            eligibilityTitle
            className="title-eligibility mobTitle-elig title-eligibility-form1"
          >
            Personal details
          </CardTitle>

          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            {data.map(d => {
              if (d.name === "pan" && panCountry) return <></>;
              return (
                <FormGroup cardElig eligibilityPage key={d.id} margElg>
                  <TextInputElg
                    md
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    readOnly={d.readOnly}
                    defaultValue={
                      eligibilityDetails?.isAgreeTerm === "YES"
                        ? d.vname === "work_email"
                          ? customerDetails[d.vname] ||
                            customerDetails.workEmail ||
                            customerDetails["email"]
                          : customerDetails[d.vname]
                        : d.name === "dob"
                        ? customerDetails[d.name].split(" / ").join("/")
                        : d.vname === "work_email"
                        ? customerDetails[d.vname] ||
                          customerDetails.workEmail ||
                          customerDetails["email"]
                        : customerDetails[d.vname]
                    }
                    maxLength={d.maxLength}
                    onChange={
                      d.name === "dob"
                        ? handleChange
                        : d.name === "pan"
                        ? upperCase
                        : d.name === "secondary_email"
                        ? lowerCase
                        : null
                    }
                    // onInput={d.name === "mobileNo" ? numOnly : null}
                    // onKeyPress={d.name === "pan" ? noSpclChars : null}
                    onInput={e =>
                      d.name === "pan"
                        ? noSpclChars(e)
                        : d.name === "mobileNo"
                        ? numOnly(e)
                        : null
                    }
                    onKeyDown={
                      premiumAmount > "50000" ? d.name["pans"] : d.name["pan"]
                    }
                    placeholder={d.placeHolder}
                    title={
                      (d.name === "secondary_email" || d.name === "pan") &&
                      "Please fill out this field"
                    }
                    //	autoComplete={d.autoComplete}
                    ref={d.name === "dob" ? attachRef : register}
                  />
                  {d.name === "dob" ? (
                    <Label
                      md
                      editMode
                      htmlFor={d.name}
                      eligibilityPage
                      frmCard
                      elig
                    >
                      Date of Birth
                      <span className="eligibilityFormCard_dob">
                        {" "}
                        (DD/MM/YYYY){" "}
                      </span>
                    </Label>
                  ) : (
                    <Label
                      md
                      htmlFor={d.id}
                      eligibilityPage
                      frmCard
                      elig
                      elgText={
                        d.name === "secondary_email" || d.name === "pan"
                          ? "true"
                          : "false"
                      }
                    >
                      {d.name === "pan" && panCountry
                        ? d.label + " (optional)"
                        : d.label}
                    </Label>
                  )}

                  {d.name === "dob"
                    ? (customerDetails?.age ||
                        age ||
                        eligibilityDetails.age) && (
                        <AgeWrapper
                          age
                          // style={{
                          // 	top: "50px",
                          // 	right: "0px",
                          // 	width: "70px",
                          // 	height: "35px",
                          // }}
                          className="ageWrap card-dob-agewrapper"
                        >
                          {customerDetails?.age ||
                            age ||
                            eligibilityDetails.age}{" "}
                          Years
                        </AgeWrapper>
                      )
                    : null}
                  {errors[d.id] && (
                    <p className="error errorElg">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              );
            })}
            <FormGroup xlg eligibilityPage className="formCardBtnWrapper">
              <button
                type="submit"
                className={
                  isValid
                    ? "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn"
                    : "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn"
                }
              >
                <span className="proceed prcdElgTxt">
                  {!details ? "PROCEED TO ELIGIBILITY" : "SAVE"}
                </span>
              </button>
            </FormGroup>
          </Form>
        </Card>
      )}
    </>
  );
};

export default FormCard;
