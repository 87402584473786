import { createSlice } from "@reduxjs/toolkit";

const proposalPage = createSlice({
  name: "proposalPage",
  initialState: {
    companyAlias: "",
    isLoading: "",
    isProposalSubmitted: false,
    isFeedbackGiven: false,
  },
  reducers: {
    setCompanyAlias: (state, action) => {
      state.companyAlias = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProposalSubmitted: (state, action) => {
      state.isProposalSubmitted = action.payload;
    },
    setIsFeedbackGiven: (state, action) => {
      state.isFeedbackGiven = action.payload;
    },
  },
});

export const {
  setCompanyAlias,
  setIsLoading,
  setIsProposalSubmitted,
  setIsFeedbackGiven,
} = proposalPage.actions;

export default proposalPage.reducer;
