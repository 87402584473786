import { createSlice } from "@reduxjs/toolkit";
import {
  saveProposal,
  singleQuote,
  submitProposal,
  updateQuotation,
} from "../serviceApi";
import SecureLS from "secure-ls";
import { setIsError } from "../../QuotePage/quotePageSlice";

const ls = new SecureLS();

const proposalPageForm = createSlice({
  name: "iciciProposal",
  initialState: {
    proposalDetails: false,
    error: false,
    response: false,
    loading: false,
    position: 0,
    previousSendData: {},
  },

  reducers: {
    saveDetails: (state, action) => {
      state.proposalDetails = action.payload;
    },
    saveResponse: (state, action) => {
      state.response = action.payload;
    },
    saveErrorDetails: (state, action) => {
      state.error = action.payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
    appendSendData: (state, action) => {
      state.previousSendData = { ...state.previousSendData, ...action.payload };
    },
    setPosition: (state, action) => {
      state.position = action.payload;
    },
  },
});

export const {
  saveDetails,
  saveResponse,
  saveErrorDetails,
  initLoader,
  appendSendData,
  setPosition,
} = proposalPageForm.actions;

export const saveProposalDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        const response = await saveProposal(data);
        if (response.data.status) {
          const responseData = await submitProposal({
            // traceId: sessionStorage.getItem("traceId"),
            traceId: data.traceId,
          });

          if (responseData.data.status) {
            await updateQuotation({
              // traceId: sessionStorage.getItem("traceId"),
              traceId: data.traceId,
              requestType: "proposal",
            });

            dispatch(initLoader(false));
            dispatch(saveResponse(responseData.data));
          }
          if (!responseData.data.status) {
            dispatch(initLoader(false));
            dispatch(saveErrorDetails(responseData.data));
          }
        }
        if (!response.data.status) {
          dispatch(initLoader(false));
          dispatch(saveErrorDetails(response.data));
        }
      }
    } catch (err) {
      dispatch(
        setIsError("Something went wrong while submitting proposal details"),
      );
    }
  };
};

export const iciciProposalForms = {
  proposerDetails: "proposerDetails",
  proposerFatcaDetails: "proposerFatcaDetails",
  proposerOccupationDetails: "proposerOccupationDetails",
  insuredDetails1: "insuredDetails1",
  insuredDetails2: "insuredDetails2",
  insuredOccupationDetails: "insuredOccupationDetails",
  otherInsuranceDetails: "otherInsuranceDetails",
  nomineeDetails: "nomineeDetails",
  identificationDetails: "identificationDetails",
  medicalQuestion1: "medicalQuestion1",
  medicalQuestion2: "medicalQuestion2",
  medicalQuestion3: "medicalQuestion3",
  medicalQuestion4: "medicalQuestion4",
};

export default proposalPageForm.reducer;
