import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";
import { saveInsuredDetails2 } from "./insuredDetails2.slice";
import {
  Form,
  FormGroup,
  Label,
  CustomDropDownFont,
  TextInput,
  TextInputElg,
} from "../../../../components/Form/Form";
import "./insuredDetails2.scss";
import { ToggleTile } from "../../components";
import { CustomDropDown } from "../../../../components";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import { noCopyAndPaste } from "../../../../utils/inputUtils";

const InsuredFtcaForm = ({ position, setPosition, index, insFor }) => {
  const NATIONALITY = ["Indian"];
  const RESIDENT_STATUS = ["Resident Indian"];
  const RESIDENT_STATUS_INDIAN = ["Resident Indian"];

  const dispatch = useDispatch();
  const { insuredDetails1 } = useSelector(state => state.insuredDetails1Form);
  const { insuredDetails2 } = useSelector(state => state.insuredDetails2Form);
  const { insuredFtcaDetails } = useSelector(state => state.insuredFtcaForm);

  const { details } = useSelector(state => state.formCard);

  const [edit, setEdit] = useState(false);

  const [nationality, setNationality] = useState(NATIONALITY[0]);
  const [disable, setDisable] = useState(false);
  const [residentStatus, setResidentStatus] = useState(
    insFor === "self" ? RESIDENT_STATUS[0] : RESIDENT_STATUS_INDIAN[0],
  );

  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (insFor === "self") setResidentStatus(RESIDENT_STATUS[0]);
    if (insFor === "dependent") setResidentStatus(RESIDENT_STATUS_INDIAN[0]);
  }, [insFor]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = () => {
    setEdit(false);
    const newData = {
      insNationality: nationality,
      insResidentStatus: residentStatus,
    };
    const sendData = {
      pNationality: newData.insNationality,
      country: newData.insResidentStatus || newData.isBirthCountry,
      proposalJourneyStatus: iciciProposalForms.insuredDetails2,
    };
    saveProposalForm(sendData, () => {
      dispatch(setPosition(position + 1));
      dispatch(saveInsuredDetails2(newData));
    });
  };

  useEffect(() => {
    if (insuredDetails2) {
      if (position === index) {
        setEdit(true);
      }
    }
  }, [position]);
  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Insured Details 2</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  if (insuredDetails1) {
    return (
      <>
        {insuredDetails2 && !edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 2
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEdit(true);
                  dispatch(setPosition(index));
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
            </CardTitle>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormGroup md eligibilityPage>
                <Label md editMode htmlFor="taxResident">
                  Select Your Nationality
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["nationality"]}
                </span>
              </FormGroup>
              <FormGroup md eligibilityPage>
                <Label md editMode htmlFor="taxResident">
                  Select Your Resident Status
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insuredFtcaDetails["residentStatus"]}
                </span>
              </FormGroup>
              {(insuredFtcaDetails.nationality === "Non Indian" ||
                insuredFtcaDetails.residentStatus ===
                "Non Resident Indian") && (
                  <>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Country of Nationality
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["nriNationality"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Country of Residence
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["nriCountryResidence"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Passport No
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["passport"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Purpose of your stay Abroad
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["purposeOfStay"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Employer/University Details
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["employUniDetails"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Date of Arriving in India
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["arrivingDate"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Date of leaving India After current visit
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["leavingDate"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Duration of Stay Abroad(Years)
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["durationStayAbroad"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Duration of Stay Abroad(Months)
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["durationStayAbroadMonthsData"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Intended Duration of Stay(Years)
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["intendedDurationStayAbroad"]}
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Intended Duration of Stay(Months)
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {
                          insuredFtcaDetails[
                          "intendeddurationStayAbroadMonthsData"
                          ]
                        }
                      </span>
                    </FormGroup>
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        NRI Travel Details
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["nriTravelDetailsData"]}
                      </span>
                    </FormGroup>
                    {insuredFtcaDetails?.nriTravelDetailsData === "Yes" && (
                      <FormGroup md formPage>
                        <Label md editMode htmlFor="taxResident">
                          My travel Details
                        </Label>
                        <span
                          className="data"
                          style={{
                            top: "32px",
                            left: "17px",
                            position: "relative",
                          }}
                        >
                          {insuredFtcaDetails["nriDetails"]}
                        </span>
                      </FormGroup>
                    )}
                    <FormGroup md formPage>
                      <Label md editMode htmlFor="taxResident">
                        Type Of Bank Account Held
                      </Label>
                      <span
                        className="data"
                        style={{
                          top: "32px",
                          left: "17px",
                          position: "relative",
                        }}
                      >
                        {insuredFtcaDetails["bankTypeData"]}
                      </span>
                    </FormGroup>
                  </>
                )}
            </div>
          </Card>
        ) : edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 2
            </CardTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Nationality
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Nationality"
                    propDropDown
                    items={NATIONALITY}
                    selectedItem={insuredFtcaDetails.insNationality}
                    onChange={e => setNationality(e)}
                    disabled
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Resident Status
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Resident Status"
                    propDropDown
                    items={
                      insFor === "self"
                        ? RESIDENT_STATUS
                        : RESIDENT_STATUS_INDIAN
                    }
                    disabled
                    selectedItem={insuredFtcaDetails.residentStatus}
                    onChange={e => setResidentStatus(e)}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {(insuredFtcaDetails.nationality === "Non Indian" ||
                insuredFtcaDetails.residentStatus ===
                "Non Resident Indian") && (
                  <>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Nationality
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Country of Nationality"
                          propDropDown
                          selectedItem={insuredFtcaDetails?.nriNationality}
                          disabled
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                      {errors["nriNationality"] && (
                        <p className="error">
                          {errors["nriNationality"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Residence
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Country of Residence"
                          propDropDown
                          disabled
                          selectedItem={insuredFtcaDetails?.nriCountryResidence}
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                      {errors["nriCountryResidence"] && (
                        <p className="error">
                          {errors["nriCountryResidence"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="passport"
                        id="passport"
                        readOnly
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.passport}
                        placeholder=" "
                        autoComplete="none"
                        ref={register}
                      />

                      <Label md htmlFor="passport">
                        Passport No
                      </Label>
                      {errors["passport"] && (
                        <p className="error">{errors["passport"].message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="purposeOfStay"
                        id="purposeOfStay"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.purposeOfStay}
                        placeholder=" "
                        autoComplete="none"
                        readOnly
                        ref={register}
                      />

                      <Label md htmlFor="purposeOfStay">
                        Purpose of your stay Abroad
                      </Label>
                      {errors["purposeOfStay"] && (
                        <p className="error">{errors["purposeOfStay"].message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="employUniDetails"
                        id="employUniDetails"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.employUniDetails}
                        placeholder=" "
                        autoComplete="none"
                        ref={register}
                        readOnly
                      />

                      <Label md htmlFor="employUniDetails">
                        Employer/University Details
                      </Label>
                      {errors["employUniDetails"] && (
                        <p className="error">
                          {errors["employUniDetails"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="arrdate"
                        name="arrivingDate"
                        placeholder=" "
                        maxLength="10"
                        onKeyDown={noCopyAndPaste}
                        readOnly
                        defaultValue={insuredFtcaDetails?.arrivingDate}
                      />
                      <Label md htmlFor="arrdate">
                        Date of Arriving in India
                      </Label>

                      {errors.arrivingDate && (
                        <p className="error">{errors.arrivingDate.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="leavedate"
                        name="leavingDate"
                        placeholder=" "
                        maxLength="10"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.leavingDate}
                        readOnly
                      />
                      <Label md htmlFor="leavedate">
                        Date of leaving India After current visit
                      </Label>

                      {errors.leavingDate && (
                        <p className="error">{errors.leavingDate.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        readOnly
                        name="durationStayAbroad"
                        id="durationStayAbroad"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.durationStayAbroad}
                        placeholder=" "
                        autoComplete="none"
                        ref={register}
                      />

                      <Label md htmlFor="durationStayAbroad">
                        Duration of Stay Abroad(Years)
                      </Label>
                      {errors["durationStayAbroad"] && (
                        <p className="error">
                          {errors["durationStayAbroad"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Duration of Stay Abroad(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Duration of Stay Abroad(Months)"
                          propDropDown
                          items={[
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "11",
                          ]}
                          selectedItem={
                            insuredFtcaDetails?.durationStayAbroadMonthsData
                          }
                          disabled
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        onKeyDown={noCopyAndPaste}
                        name="intendedDurationStayAbroad"
                        id="intendedDurationStayAbroad"
                        defaultValue={
                          insuredFtcaDetails?.intendedDurationStayAbroad
                        }
                        placeholder=" "
                        autoComplete="none"
                        maxLength={2}
                        readOnly
                        ref={register}
                        numOnly
                      />

                      <Label md htmlFor="intendedDurationStayAbroad">
                        Intended Duration of Stay(Years)
                      </Label>
                      {errors["intendedDurationStayAbroad"] && (
                        <p className="error">
                          {errors["intendedDurationStayAbroad"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Intended Duration of Stay(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Intended Duration of Stay(Months)"
                          propDropDown
                          items={[
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "11",
                          ]}
                          selectedItem={
                            insuredFtcaDetails?.intendeddurationStayAbroadMonthsData
                          }
                          disabled
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                    <FormGroup style={{ textAlign: "left" }} xlg formPage>
                      <ToggleTile
                        checked={
                          insuredFtcaDetails?.nriTravelDetailsData === "Yes"
                            ? true
                            : false
                        }
                        disabled
                        block
                        label="NRI Travel Details"
                      />
                    </FormGroup>
                    {insuredFtcaDetails.nriTravelDetailsData === "Yes" && (
                      <FormGroup proposalCard eligibilityPage>
                        <TextInputElg
                          proposal
                          type="text"
                          name="nriDetails"
                          id="nriDetails"
                          placeholder=" "
                          autoComplete="none"
                          ref={register}
                          readOnly
                          onKeyDown={noCopyAndPaste}
                          defaultValue={insuredFtcaDetails?.nriDetails}
                          numOnly
                        />

                        <Label md htmlFor="nriDetails">
                          My travel Details
                        </Label>
                        {errors["nriDetails"] && (
                          <p className="error">{errors["nriDetails"].message}</p>
                        )}
                      </FormGroup>
                    )}
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Type Of Bank Account Held
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Type Of Bank Account Held"
                          propDropDown
                          disabled
                          selectedItem={insuredFtcaDetails?.bankTypeData}
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                  </>
                )}
              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  className="btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                >
                  PROCEED TO INSURED OCCUPATION DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        ) : (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Insured Details 2
            </CardTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Nationality
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Nationality"
                    propDropDown
                    items={NATIONALITY}
                    disabled
                    currentSelected={insuredFtcaDetails?.nationality}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              <FormGroup proposalCard eligibilityPage>
                {/* <div className="upgradeDropList smallCustomDropDownSpace">
                  <div className="upgradeDropLabel">
                    Select Your Resident Status
                  </div>
                  <div className="filterMenuSelectWrap selectIncome"> */}
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Select Your Resident Status"
                    propDropDown
                    items={
                      insFor === "self"
                        ? RESIDENT_STATUS
                        : RESIDENT_STATUS_INDIAN
                    }
                    disabled
                    selectedItem={insuredFtcaDetails.residentStatus}
                    onChange={e => setResidentStatus(e)}
                  />
                </CustomDropDownFont>
                {/* </div>
                </div> */}
              </FormGroup>
              {(insuredFtcaDetails.nationality === "Non Indian" ||
                insuredFtcaDetails.residentStatus ===
                "Non Resident Indian") && (
                  <>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Nationality
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Country of Nationality"
                          propDropDown
                          disabled
                          selectedItem={insuredFtcaDetails?.nriNationality}
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                      {errors["nriNationality"] && (
                        <p className="error">
                          {errors["nriNationality"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Country of Residence
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Country of Residence"
                          propDropDown
                          selectedItem={insuredFtcaDetails?.nriCountryResidence}
                          disabled
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                      {errors["nriCountryResidence"] && (
                        <p className="error">
                          {errors["nriCountryResidence"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="passport"
                        id="passport"
                        readOnly
                        defaultValue={insuredFtcaDetails?.passport}
                        placeholder=" "
                        onKeyDown={noCopyAndPaste}
                        autoComplete="none"
                        ref={register}
                      />

                      <Label md htmlFor="passport">
                        Passport No
                      </Label>
                      {errors["passport"] && (
                        <p className="error">{errors["passport"].message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="purposeOfStay"
                        id="purposeOfStay"
                        defaultValue={insuredFtcaDetails?.purposeOfStay}
                        placeholder=" "
                        autoComplete="none"
                        readOnly
                        onKeyDown={noCopyAndPaste}
                        ref={register}
                      />

                      <Label md htmlFor="purposeOfStay">
                        Purpose of your stay Abroad
                      </Label>
                      {errors["purposeOfStay"] && (
                        <p className="error">{errors["purposeOfStay"].message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        name="employUniDetails"
                        id="employUniDetails"
                        defaultValue={insuredFtcaDetails?.employUniDetails}
                        placeholder=" "
                        autoComplete="none"
                        ref={register}
                        onKeyDown={noCopyAndPaste}
                        readOnly
                      />

                      <Label md htmlFor="employUniDetails">
                        Employer/University Details
                      </Label>
                      {errors["employUniDetails"] && (
                        <p className="error">
                          {errors["employUniDetails"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="arrdate"
                        name="arrivingDate"
                        placeholder=" "
                        maxLength="10"
                        readOnly
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.arrivingDate}
                        error={errors.dob && "danger"}
                      />
                      <Label md htmlFor="arrdate">
                        Date of Arriving in India
                      </Label>

                      {errors.arrivingDate && (
                        <p className="error">{errors.arrivingDate.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        id="leavedate"
                        name="leavingDate"
                        placeholder=" "
                        maxLength="10"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.leavingDate}
                        readOnly
                      />
                      <Label md htmlFor="leavedate">
                        Date of leaving India After current visit
                      </Label>

                      {errors.leavingDate && (
                        <p className="error">{errors.leavingDate.message}</p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        type="text"
                        readOnly
                        name="durationStayAbroad"
                        id="durationStayAbroad"
                        onKeyDown={noCopyAndPaste}
                        defaultValue={insuredFtcaDetails?.durationStayAbroad}
                        placeholder=" "
                        autoComplete="none"
                        maxLength={2}
                        ref={register}
                      />

                      <Label md htmlFor="durationStayAbroad">
                        Duration of Stay Abroad(Years)
                      </Label>
                      {errors["durationStayAbroad"] && (
                        <p className="error">
                          {errors["durationStayAbroad"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Duration of Stay Abroad(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Duration of Stay Abroad(Months)"
                          propDropDown
                          items={[
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "11",
                          ]}
                          selectedItem={
                            insuredFtcaDetails?.durationStayAbroadMonthsData
                          }
                          disabled
                        // onChange={(e) => setDurationStayAbroadMonths(e)}
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      <TextInputElg
                        proposal
                        // error={errors[d.id] && "danger"}
                        type="text"
                        onKeyDown={noCopyAndPaste}
                        name="intendedDurationStayAbroad"
                        id="intendedDurationStayAbroad"
                        // style={{ textTransform: "pan" && "uppercase" }}
                        defaultValue={
                          insuredFtcaDetails?.intendedDurationStayAbroad
                        }
                        placeholder=" "
                        autoComplete="none"
                        //   onInput={numOnly}
                        maxLength={2}
                        readOnly
                        ref={register}
                        numOnly
                      />

                      <Label md htmlFor="intendedDurationStayAbroad">
                        Intended Duration of Stay(Years)
                      </Label>
                      {errors["intendedDurationStayAbroad"] && (
                        <p className="error">
                          {errors["intendedDurationStayAbroad"].message}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Intended Duration of Stay(Months)
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Intended Duration of Stay(Months)"
                          propDropDown
                          items={[
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "11",
                          ]}
                          selectedItem={
                            insuredFtcaDetails?.intendeddurationStayAbroadMonthsData
                          }
                          disabled
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                    <FormGroup style={{ textAlign: "left" }} xlg formPage>
                      <ToggleTile
                        checked={
                          insuredFtcaDetails?.nriTravelDetailsData === "Yes"
                            ? true
                            : false
                        }
                        disabled
                        block
                        label="NRI Travel Details"
                      />
                    </FormGroup>
                    {insuredFtcaDetails.nriTravelDetailsData === "Yes" && (
                      <FormGroup proposalCard eligibilityPage>
                        <TextInputElg
                          proposal
                          type="text"
                          name="nriDetails"
                          id="nriDetails"
                          placeholder=" "
                          autoComplete="none"
                          onKeyDown={noCopyAndPaste}
                          ref={register}
                          defaultValue={insuredFtcaDetails?.nriDetails}
                          readOnly
                          numOnly
                        />

                        <Label md htmlFor="nriDetails">
                          My travel Details
                        </Label>
                        {errors["nriDetails"] && (
                          <p className="error">{errors["nriDetails"].message}</p>
                        )}
                      </FormGroup>
                    )}
                    <FormGroup proposalCard eligibilityPage>
                      {/* <div className="upgradeDropList smallCustomDropDownSpace">
                      <div className="upgradeDropLabel">
                        Type Of Bank Account Held
                      </div>
                      <div className="filterMenuSelectWrap selectIncome"> */}
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Type Of Bank Account Held"
                          propDropDown
                          disabled
                          selectedItem={insuredFtcaDetails?.bankTypeData}
                        />
                      </CustomDropDownFont>
                      {/* </div>
                    </div> */}
                    </FormGroup>
                  </>
                )}

              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  disabled={disable}
                  className={
                    disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                  }
                >
                  PROCEED TO INSURED OCCUPATION DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        )}
      </>
    );
  }
};

export default InsuredFtcaForm;
