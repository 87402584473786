import styled from "styled-components";
export const FooterWrap = styled.footer`
  min-height: 64px;
  border-top: solid 1px #e3e4e8;
  background-color: #f1f2f6;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px 30px;
  font-size: 14px;
  @media only screen and (max-width: 1177px) and (min-width: 768px) {
    height: 64px;
    font-size: 14px;
    padding: 21px 26px;
  }
  @media only screen and (max-width: 767px) {
    bottom: ${props => {
      if (props.mobileFooter) return "85px";
      else return "0px";
    }};
    position: ${props => props.mobilePosition && "relative"};
    bottom: ${props => props.mobilePosition && "-52px;"};
    padding: 18px 30px;
  }
`;
export const Row = styled.div`
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 15px;
  padding-left: 15px;
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media only screen and (max-width: 1177px) and (min-width: 768px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;
export const FooterBold = styled.div`
  padding: 0;
  float: left;
  color: #808080;
  width: 66.66666667%;
  @media only screen and (max-width: 1177px) and (min-width: 768px) {
    float: left;
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 14px;
    width: 100%;
  }
`;
export const FooterCopy = styled.div`
  padding: 0;
  text-align: right;
  font-family: "Inter-Regular";
  font-size: 12px;
  color: #808080;
  margin-top: 5px;
  float: left;
  width: 33.33333333%;
  @media only screen and (max-width: 1177px) and (min-width: 768px) {
    margin: 29px 0 8px 0;
    text-align: center;
    font-family: "Inter-Regular";
    font-size: 12px;
    color: #808080;
    float: left;
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
    font-size: 12px;
    width: 100%;
    text-align: left;
    & span {
      display: block;
    }
  }
`;
export const FooterInline = styled.div`
  &:first-child,
  &:last-child {
    width: 30%;
    @media only screen and (max-width: 1177px) and (min-width: 768px) {
      & {
        margin: 0;
        width: 32%;
      }
    }
  }

  display: inline;
  float: left;
  width: 40%;
  font-family: "Inter-Regular";
  @media only screen and (max-width: 1177px) and (min-width: 768px) {
    float: left;
    width: 36%;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    display: inline-block;
    margin-right: 0;
    width: 100% !important;
    font-size: 14px;
    padding-bottom: 4px;
    line-height: 20px;
    & span {
      font-size: 14px;
    }
  }
  & span {
    font-family: "Inter-Medium";
  }
`;
