import React, { useState, useEffect } from "react";
import PopupTarget from "./PopupTarget";
import styled from "styled-components";
import { useSelector } from "react-redux";

const CriticalPopup = ({ onClose }) => {
  const [type, setType] = useState("Critical");

  useEffect(() => {
    document.getElementById("critical").addEventListener("mousedown", evt => {
      evt.stopPropagation();
    });
  }, []);

  // useEffect(() => {
  //   let link = document.querySelector("[href='#critical']");
  //   if (link && link.innerText.includes("serious") && type !== "Serious") {
  //     setType("Serious");
  //   }
  // });

  const { criticalIllness } = useSelector(state => state.rider);

  const [illness1, setIllness1] = useState([]);
  const [illness2, setIllness2] = useState([]);
  useEffect(() => {
    if (criticalIllness.status) {
      let set1 = [],
        set2 = [];
      let length = criticalIllness.criticalIllnessData?.length;
      for (let i = 0; i < length; ++i) {
        if (i < length / 2) {
          set1.push(criticalIllness.criticalIllnessData[i]);
        } else {
          set2.push(criticalIllness.criticalIllnessData[i]);
        }
      }
      setIllness1(set1);
      setIllness2(set2);
      if (criticalIllness.criticalIllnessData?.length === 10) {
        setType("Serious");
      } else {
        setType("Critical");
      }
    }
  }, [criticalIllness]);

  const content = (
    <>
      <Heading>
        {criticalIllness?.criticalIllnessData?.length} {type} Illness Covered
      </Heading>
      <IllnessWrapper>
        <Wrapper>
          {illness1.map((item, index) => (
            <Illness
              key={index}
              dangerouslySetInnerHTML={{ __html: `${item}` }}
            ></Illness>
          ))}
        </Wrapper>
        <Wrapper2 start={illness1.length + 1}>
          {illness2.map((item, index) => (
            <Illness key={index}>{item}</Illness>
          ))}
        </Wrapper2>
      </IllnessWrapper>
    </>
  );
  let width;
  let height;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    height = "100%";
  } else if (window.matchMedia("(max-width: 991px)").matches) {
    width = "720px";
    height = "580px";
  } else {
    width = "810px";
    height = "580px";
  }
  return (
    <>
      <PopupTarget
        onClose={onClose}
        id="critical"
        width={width}
        content={content}
        maxHeight={height}
      ></PopupTarget>
    </>
  );
};

export default CriticalPopup;
const Heading = styled.div`
  text-align: center;
  width: 100%;
  padding: 23px 0;
  text-transform: capitalize;
  font-family: Inter-SemiBold;
  font-size: 20px;
  color: #333;
  border-bottom: solid 1px #e3e4e8;
`;
const Wrapper = styled.ol`
  width: 265px;
  line-height: 21px;
  text-transform: capitalize;
  display: inline-block;
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 298px;
  }
`;
const Wrapper2 = styled.ol`
  width: 300px;
  margin-left: 131px;
  display: inline-block;
  text-transform: capitalize;
  position: absolute;
  @media only screen and (max-width: 767px) {
    margin-left: 0;
    display: block;
    height: 100%;
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 300px;
    margin-left: 42px;
  }
`;
const Illness = styled.li`
  font-family: Inter-Regular;
  font-size: 13px;
  margin: 0;
  & ol {
    padding-left: 30px;
  }
`;

const IllnessWrapper = styled.div`
  width: 100%;
  padding: 30px 74px 20px 40px;
  margin-bottom: 10px;
  height: auto;
  max-height: 480px;
  overflow: auto;
  line-height: 21px;
  position: relative;
  @media only screen and (max-width: 767px) {
    height: 100%;
    padding: 20px 57px 20px 27px;
  }
`;
// <>
//   <h1
//     style={{
//       textAlign: "center",
//       borderBottom: "1px solid #666",
//       marginBottom: "15px",
//       color: "#107591",
//     }}
//   >
//     {illness.length} Critical Illness Covered
//   </h1>
//   <Wrapper>
//
//   </Wrapper>
// </>
