import styled from "styled-components";
export const ExecWrap = styled.div`
  width: 534px;
  text-align: center;
  margin: 0 auto 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const ExecWrapText = styled.div`
  width: 100%;
  font-family: "Inter-SemiBold";
  font-size: 20px;
  line-height: 30px;
  color: #152981;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
export const ExecWrap2 = styled.div`
  position: relative;
  z-index: 1;
  width: 534px;
  text-align: center;
  border: solid 1px #d2d3d4;
  border-top: 0;
  border-radius: 4px;
  margin: 0 auto 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const ExecWrapHr = styled.div`
  position: relative;
  width: 420px;
  margin: 0 auto;
  border-bottom: solid 1px #d0d2d9;
  z-index: -1;
  width: 100%;
  &::after {
    left: calc(50% - 160px);
    width: 318px;
    content: "";
    background-color: #fff;
    position: absolute;
    top: -10px;
    height: 22px;
    @media only screen and (max-width: 767px) {
      left: calc(50% - 122px);
      width: 244px;
    }
  }
`;
export const ExecWrapTitle = styled.div`
  width: 100%;
  font-family: "Inter-Regular";
  font-size: 13px;
  line-height: 1;
  color: #707070;
  margin-top: -6px;
  margin-bottom: 4px;
  z-index: 1;
  @media (max-width: 767px) {
    font-size: 10px;
  }
`;
export const ExecTable = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
export const ExecTableCell = styled.div`
  display: inline-block;
  width: 32%;
  margin: 7px 0;
  & img {
    width: 32px;
    height: 32px;
    vertical-align: unset;
  }
  & div {
    font-family: "Inter-Medium";
    font-size: 11px;
    line-height: 22px;
    color: #333;
  }
  @media (max-width: 767px) {
    width: 49%;
  }
`;
export const InputInfo = styled.div`
  display: inline-block;
  font-family: "Inter-Regular";
  font-size: 11px;
  line-height: 15px;
  color: #333;
  transform: translateY(-2px);
`;
export const LandingWrapper = styled.div`
  background-color: #f1f2f6;
  width: 534px;
  padding: 18px 32px;
  border: solid 1px #e3e4e8;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgb(21 41 129 / 14%);
  margin: -12px auto 8px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
  }
`;
export const LandingTitle = styled.div`
  float: left;
  width: 100%;
  font-family: "Inter-Medium";
  font-size: 13px;
  line-height: 24px;
  color: #333;
  margin-bottom: 12px;
  & svg {
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  @media (max-width: 767px) {
    line-height: 16px;
    & svg {
      width: 16px;
      height: 16px;
    }
    & span {
      font-size: 10px;
    }
  }
`;
export const TermAndConditions = styled.div`
  font-size: 12px;
  margin-top: 12px;
  font-family: "Inter-Regular";
  color: #808080;
  & a {
    text-decoration: underline;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    width: 300px;
    margin: 12px auto;
  }
`;
export const SocialUpdateWrap = styled.div`
  display: table;
  width: 201px;
  text-align: center;
  margin: 14px auto;
  & img {
    display: table-cell;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;
export const WhatsappTxt = styled.div`
  display: table-cell;
  font-family: "Inter-Regular";
  font-size: 11px;
  line-height: 1;
  color: #333;
  text-align: left;
  vertical-align: middle;
`;
