/* eslint-disable eqeqeq */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllData } from "../../../api/getAllData";
import { useCustomLocation } from "../../../custom-hooks";
import {
  sendEligibilityDetails,
  updateQuotation,
} from "../../../modules/EligibilityPage/serviceApi";
import { numberToDigitWord } from "../../../modules/QuoteFilter/helper";
import { setCoverAmount } from "../../../modules/QuoteFilter/quoteFilterSlice";
import {
  setQuotes,
  setSwitchToMedical,
} from "../../../modules/QuotePage/quotePageSlice";
import { setSelectedAddOns } from "../../../modules/RiderPage/RiderPage.slice";
import { getAge } from "../../../utils/inputUtils";
import PopupWithTitle from "../PopupWithTitle/PopupWithTitle";

const AnnualIncomePopup = ({
  show,
  setShow,
  annualIncome,
  newCover,
  vaccinationStatus,
  selectedOccupation,
  ...props
}) => {
  const dispatch = useDispatch();
  const { enquiryId, pathname } = useCustomLocation();
  const {
    filter: { coverUpto },
  } = useSelector(state => state.quoteFilter);
  const { customerDetails, startAnnualIncome } = useSelector(
    state => state.greeting,
  );
  const { selectedQuote } = useSelector(state => state.quote);
  const { filter } = useSelector(state => state?.quoteFilter);
  const { subFilter } = useSelector(state => state?.quoteFilter);
  const age = getAge(customerDetails.dob);
  const history = useHistory();
  const roundNewCover = Math.floor(newCover / 500000) * 500000;
  const { corporateJourney } = useSelector(state => state.quote);

  const setRoundNewCover = numberToDigitWord(Number(roundNewCover), 50000, 2);
  let temp = corporateJourney?.is_gameskraft;

  return (
    <>
      <PopupWithTitle
        title={
          show === "0" ||
          vaccinationStatus === "Partially Vaccinated" ||
          vaccinationStatus === "Not Vaccinated"
            ? "Vaccination Eligibility Criteria"
            : "Income Eligibility Criteria"
        }
        show={show}
        onClose={setShow}
        {...props}
        style={{
          minHeight: "200px",
        }}
      >
        <div>
          {vaccinationStatus !== "Not Vaccinated" ? (
            <div style={{ padding: "20px 24px 0" }}>{show}</div>
          ) : (
            <div style={{ padding: "20px 24px 0" }}>
              As you are not at all vaccinated, you are eligible for a maximum
              sum assured of ₹ 1 Crore
            </div>
          )}
          <div className={`nri-popup-btn-group `}>
            {!vaccinationStatus ? (
              <button
                className={`nri-popup-btn ${
                  vaccinationStatus === "Not Vaccinated"
                    ? "nri-popup-btn-primary"
                    : ""
                }`}
                onClick={() => setShow(false)}
              >
                OK, GOT IT
              </button>
            ) : (
              <></>
            )}
            {vaccinationStatus == "Not Vaccinated" && temp == "active" ? (
              <div />
            ) : (
              <button
                className="nri-popup-btn nri-popup-btn-primary"
                onClick={() => {
                  updateQuotation({
                    annualIncome:
                      annualIncome < 10000000
                        ? Math.floor(annualIncome / 100000) * 100000 <=
                          startAnnualIncome
                          ? String(startAnnualIncome)
                          : String(Math.floor(annualIncome / 100000) * 100000)
                        : String(Math.floor(annualIncome / 2500000) * 2500000),
                    traceId: enquiryId,
                    requestType: "get_products",
                    sumAssured:
                      vaccinationStatus === "Not Vaccinated"
                        ? String(selectedQuote.sumAssured)
                        : String(roundNewCover),
                    policyTerm:
                      coverUpto !== "Whole Life"
                        ? `${parseInt(coverUpto) - age}`
                        : 99,
                    payOption: filter?.paymentTerm?.option,
                    payfrequency: subFilter?.payPremium,
                    limitedPayTerm: filter?.paymentTerm?.term,
                    nomineeGets: filter?.nomineeGets,
                    termOccupation: customerDetails?.termOccupation,
                  }).then(() => {
                    dispatch(getAllData(null, pathname, enquiryId)).then(() => {
                      dispatch(
                        (selectedQuote?.company_alias === "bajaj_life" &&
                          vaccinationStatus === "Partially Vaccinated") ||
                          vaccinationStatus == "Not Vaccinated" ||
                          (selectedQuote?.company_alias === "hdfc_life" &&
                            selectedOccupation == "Self Employed")
                          ? setCoverAmount({ value: `${setRoundNewCover}` })
                          : selectedQuote?.company_alias === "tata_aia_life" &&
                            selectedOccupation === "Housewife" &&
                            selectedQuote.sumAssured > 5000000
                          ? setCoverAmount({ value: `${5000000}` })
                          : setCoverAmount({ value: `${setRoundNewCover}` }),
                      );
                      if (roundNewCover <= 10000000) {
                        dispatch(setSwitchToMedical(false));
                      }
                      history.push("/");
                      history.push(`/quotes?enquiryId=${enquiryId}`);
                      dispatch(setSelectedAddOns([]));
                      dispatch(setQuotes([]));
                    });
                    history.push({
                      pathname: "/quotes",
                      search: `enquiryId=${sessionStorage.getItem("traceId")}`,
                    });
                  });
                  setShow(false);
                }}
              >
                GO BACK TO QUOTES
              </button>
            )}
          </div>
        </div>
      </PopupWithTitle>
    </>
  );
};

export const NriMedicalPopup = ({
  show,
  setShow,
  annualIncome,
  setCheckNRI,
  ...props
}) => {
  const dispatch = useDispatch();
  const { enquiryId, pathname } = useCustomLocation();
  const {
    filter: { coverUpto },
  } = useSelector(state => state.quoteFilter);
  const { customerDetails, startAnnualIncome } = useSelector(
    state => state.greeting,
  );
  const { selectedQuote, lastFilteredData } = useSelector(state => state.quote);
  const age = getAge(customerDetails.dob);
  const history = useHistory();

  return (
    <>
      <PopupWithTitle
        title={"NRI Eligibility Criteria"}
        show={show}
        onClose={() => setShow && setCheckNRI(false)}
        {...props}
        style={{
          minHeight: "200px",
        }}
      >
        <div>
          <div style={{ padding: "20px 24px 0" }}>
            We regret to inform you that you won’t be able to buy ICICI
            Prudential Term Plan with Tele Medical while being a NRI.
          </div>
          <div className={`nri-popup-btn-group `}>
            <button
              className="nri-popup-btn nri-popup-btn-primary"
              onClick={async () => {
                await sendEligibilityDetails({
                  traceId: enquiryId,
                  requestType: "eligibility_details",
                  nri: "YES",
                  eligibleStatus: "YES",
                });
                updateQuotation({
                  annualIncome:
                    annualIncome < 10000000
                      ? Math.floor(annualIncome / 100000) * 100000 <=
                        startAnnualIncome
                        ? String(startAnnualIncome)
                        : String(Math.floor(annualIncome / 100000) * 100000)
                      : String(Math.floor(annualIncome / 2500000) * 2500000),
                  traceId: enquiryId,
                  requestType: "get_products",
                  sumAssured: String(selectedQuote.sumAssured),
                  policyTerm: `${parseInt(coverUpto) - age}`,
                  payOption: lastFilteredData?.payOption,
                  limitedPayTerm: lastFilteredData?.limitedPayTerm,
                }).then(() => {
                  dispatch(getAllData(null, pathname, enquiryId)).then(() => {
                    history.push("/");
                    history.push(`/quotes?enquiryId=${enquiryId}`);
                    dispatch(setSelectedAddOns([]));
                  });
                });
                setShow(false);
                setCheckNRI(false);
              }}
            >
              GO BACK TO QUOTES
            </button>
          </div>
        </div>
      </PopupWithTitle>
    </>
  );
};
export default AnnualIncomePopup;
