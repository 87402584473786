import { createSlice } from "@reduxjs/toolkit";
import { getBankDetails } from "../serviceApi";
import SecureLS from "secure-ls";
import swal from "sweetalert";
import { saveProposal, submitProposal, updateQuotation } from "../serviceApi";
import {
  getCountryOfResidence,
  getMaritalStatusBajaj,
  getNationKotak,
} from "../../../utils/bajajCodeUtils";
import {
  appendSendData,
  setPosition,
} from "../IcicProposalPage/IcicProposal.slice";
import { checkCKYC } from "./serviceApi";
const ls = new SecureLS();
const bajajProposalPageForm = createSlice({
  name: "bajajProposalPageForm",
  initialState: {
    FATCADetails: false,
    medicalDetails1: false,
    contactDetails: false,
    payerDetails: false,
    nomineeDetails: false,
    nriAndPrevDetails: false,
    medicalDetails2: false,
    medicalDetails3: false,
    medicalDetails4: false,
    covid19Details: false,
    basicDetails: false,
    occupationDetails: false,
    bankAccountDetails: false,
    loading: false,
    ckyc: {},
    ckycLoading: false,
    bajajProposalData: {},
  },

  reducers: {
    setBajajProposalData: (state, action) => {
      state.bajajProposalData = action.payload;
    },
    setFATCADetails: (state, action) => {
      state.FATCADetails = action.payload;
    },
    setMedicalDetails1: (state, action) => {
      state.medicalDetails1 = action.payload;
    },
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    setPayerDetails: (state, action) => {
      state.payerDetails = action.payload;
    },
    setNomineeDetails: (state, action) => {
      state.nomineeDetails = action.payload;
    },
    setNriAndPrevDetails: (state, action) => {
      state.nriAndPrevDetails = action.payload;
    },
    setMedicalDetails2: (state, action) => {
      state.medicalDetails2 = action.payload;
    },
    setMedicalDetails3: (state, action) => {
      state.medicalDetails3 = action.payload;
    },
    setMedicalDetails4: (state, action) => {
      state.medicalDetails4 = action.payload;
    },
    setCovid19Details: (state, action) => {
      state.covid19Details = action.payload;
    },
    setBasicDetails: (state, action) => {
      state.basicDetails = action.payload;
    },
    setOccupationDetails: (state, action) => {
      state.occupationDetails = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    initLoader: (state, action) => {
      state.loading = action.payload;
    },
    saveErrorDetails: (state, action) => {
      state.error = action.payload;
    },
    saveResponse: (state, action) => {
      state.response = action.payload;
    },
    initCKYCLoader: state => {
      state.ckycLoading = true;
    },
    clearCKYCLoader: state => {
      state.ckycLoading = false;
    },
    setCkycData: (state, { payload }) => {
      state.ckyc = payload;
    },
  },
});

export const COMPANY_LIST = [
  "Bajaj Allianz Life Insurance Co. Ltd.",
  "Birla Sun Life Insurance Co. Ltd",
  "HDFC Standard Life Insurance Co. Ltd",
  "ICICI Prudential Life Insurance Co. Ltd",
  "ING Vysya Life Insurance Company",
  "Life Insurance Corporation of India",
  "Max Life Insurance Co. Ltd",
  "PNB Metlife India Insurance Co. Ltd.",
  "Kotak Mahindra Old Mutual Life Insurance Limited",
  "SBI Life Insurance Co. Ltd",
  "Tata AIA Life Insurance Company Limited",
  "Reliance Life Insurance Company Limited",
  "Aviva Life Insurance Company India Limited",
  "Sahara India Life Insurance Co, Ltd.",
  "Shriram Life Insurance Co, Ltd.",
  "Bharti AXA Life Insurance Company Ltd.",
  "Future Generali India Life Insurance Company Limited",
  "IDBI Federal Life Insurance Company Ltd",
  "Canara HSBC Oriental Bank of Commerce Life Insurance Company",
  "BANDHAN Religare Life Insurance Company Limited.",
  "DLF Pramerica Life Insurance Co. Ltd",
  "Star Union Dai-ichi Life Insurance Co. Ltd.",
  "IndiaFirst Life Insurance Company Limited",
  "Edelweiss Tokio Life Insurance Co. Ltd.",
];

export const {
  setFATCADetails,
  setMedicalDetails1,
  setPayerDetails,
  setContactDetails,
  setNomineeDetails,
  setNriAndPrevDetails,
  setMedicalDetails2,
  setMedicalDetails3,
  setMedicalDetails4,
  setCovid19Details,
  setBasicDetails,
  setOccupationDetails,
  setBankAccountDetails,
  saveErrorDetails,
  saveResponse,
  initLoader,
  setBajajProposalData,
  setCkycData,
  initCKYCLoader,
  clearCKYCLoader,
} = bajajProposalPageForm.actions;
export const getCKYCData = (payload, isDoc) => {
  return async dispatch => {
    try {
      const { data } = await checkCKYC(payload);
      dispatch(clearCKYCLoader());
      if (data.status === "true") {
        swal({
          icon: "success",
          text: isDoc
            ? "Your documents are auto-updated here as per your CKYC! preview and upload"
            : "Ckyc verification success!",
        });
      } else
        swal({
          text: "Ckyc verification failed!",
        });
      dispatch(setCkycData(data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const saveBajajProposalDetails = data => {
  return async dispatch => {
    try {
      if (data) {
        const response = await saveProposal(data);
        if (response.data?.status) {
          const responseData = await submitProposal({
            traceId: sessionStorage.getItem("traceId"),
          });
          if (responseData.data?.status) {
            await updateQuotation({
              traceId: sessionStorage.getItem("traceId"),
              requestType: "proposal",
            }).then(() => {
              dispatch(initLoader(false));
              dispatch(saveResponse(responseData.data));
            });
          } else {
            dispatch(initLoader(false));
            dispatch(saveErrorDetails(response.data));
            swal(responseData.data.message, {
              icon: "error",
            });
            //.then(() => window.location.reload());
          }
        } else {
          dispatch(initLoader(false));
          dispatch(saveErrorDetails(response.data));
          swal(response.data.message, {
            icon: "error",
          });
          //.then(() => window.location.reload());
        }
      }
    } catch (err) {
      // dispatch(
      // 	setIsError("Something went wrong while submitting proposal details")
      // );
      console.error(err);
    }
  };
};

const getPosition = (proposalJourneyStatus, isNri) => {
  if (!proposalJourneyStatus) return 0;
  let lastPosition = 0;
  if (proposalJourneyStatus.basicDetails) lastPosition++;
  if (proposalJourneyStatus.insuredContactDetails) lastPosition++;
  if (proposalJourneyStatus.premiumPayerDetails) lastPosition++;
  if (proposalJourneyStatus.nomineeDetails) lastPosition++;
  if (proposalJourneyStatus.bankAccountDetails) lastPosition++;
  if (proposalJourneyStatus.occupationDetails) lastPosition++;
  if (proposalJourneyStatus.nriAndPreviousInsuranceDetails)
    // lastPosition = !isNri ? lastPosition + 2 : lastPosition + 1;
    lastPosition++;
  if (proposalJourneyStatus.fatcaDetails) lastPosition++;
  if (proposalJourneyStatus.medicalDetails1) lastPosition++;
  if (proposalJourneyStatus.medicalDetails2) lastPosition++;
  if (proposalJourneyStatus.medicalDetails3) lastPosition++;
  if (proposalJourneyStatus.medicalDetails4) lastPosition++;
  if (proposalJourneyStatus.covid19Details) lastPosition++;
  return lastPosition;
};

export const storeBajajProposalData = singleProductData => dispatch => {
  const { proposal_details } = singleProductData;
  const { nri } = singleProductData?.requestData?.data?.quote_data;
  const { proposalJourneyStatus } = JSON.parse(
    proposal_details.postPaymentJourney || JSON.stringify({}),
  );

  if (proposal_details) {
    proposal_details.nomineeDetails = JSON.parse(
      proposal_details?.nomineeDetails || JSON.stringify([]),
    );
    proposal_details.otherInsuranceCompny = JSON.parse(
      proposal_details?.otherInsuranceCompny || JSON.stringify([]),
    );
  }
  dispatch(setPosition(getPosition(proposalJourneyStatus, nri === "YES")));
  dispatch(setBajajProposalData(proposal_details));
  dispatch(appendSendData(proposal_details));
};
export const bajajRenewalDeposit = {
  "Online Mandate(BOB,KOTAK,PNB & OTHERS)": "NACH",
  "Credit card standing instruction": "CCSI",
  "Online Mandate(AXIS,HDFC,ICICI & SBI)": "EMAND",
};

export const basicDetailsSendData = (data = {}) => ({
  title: data?.title,
  firstName: data?.firstName,
  middleName: data?.middleName,
  lastName: data?.lastName,
  gender: data?.gender === "Male" ? "M" : "F",
  dob: data["dob"]?.split("/").reverse().join("-"),
  birthPlace: data?.placeOfBirth,
  nationality: getNationKotak(data?.nationality),
  nationalityText: data?.nationality,
  countryOfResidence: getCountryOfResidence(data?.countryOfResidence),
  countryOfResidenceText: data?.countryOfResidence,
  maritalStatus: getMaritalStatusBajaj(data?.meritalStatus),
  maritalStatusText: data?.meritalStatus,
  fatherName: data?.fatherName,
  motherName: data?.motherName,
  spouseName: data?.spouseName,
  spouseDob: data?.spouseDob,
  spouseBirthPlace: data?.spousePlaceOfBirth,
  pep: data?.IsPoliticallyExposed === "No" ? "N" : "Y",
  pepDesc: data?.politicalDescription,
  proposalJourneyStatus: "basicDetails",
});

export default bajajProposalPageForm.reducer;
