import React, { useState, useEffect, useCallback, useRef } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../assets/img/editIcon.svg";
import {
  dateUtil,
  getMaritalStatus,
  numOnly,
  onlyOneSpace,
} from "../../../../utils/inputUtils";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import location from "../../../../assets/img/location.svg";
import {
  Form,
  FormGroup,
  Label,
  TextInput,
  AgeWrapper,
  RadioContainer,
  Radio,
  RadioLabel,
  RadioButton,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import { proposerFormSchema } from "../../../../utils/validationSchema";
import { data, p_data } from "./data";
import { getRegion } from "../../../EligibilityPage/eligibilityCard.slice";
import "./proposerForm.scss";
import { proposerSendData, saveProposerDetails } from "./proposal.slice";
import CustomDropDown from "../../../../components/CutomDropDown/CustomDropDown";
import ToggleButton from "./../../../../components/ToggleButton/ToggleButton";
import SecureLS from "secure-ls";
import { ToggleTile } from "../../components";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import httpClient from "../../../../api/httpClient";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import { useUrlQuery } from "../../../../custom-hooks";
import { checkpinCode } from "../../../EligibilityPage/serviceApi";

const ProposerForm = ({ insFor = "self", position, setPosition, index }) => {
  const dispatch = useDispatch();
  const { proposerDetails } = useSelector(state => state.proposalForm);
  const [edit, setEdit] = useState(false);
  const [age, setAge] = useState("");
  const [politicallyExposed, setPoliticallyExposed] = useState(
    proposerDetails.isPoliticallyExposed === "Yes",
  );
  const [comm_per, setCommPer] = useState();
  const [martialStatus, setMartialStatus] = useState();
  const [gender, setGender] = useState(
    proposerDetails.gender === "M" ? "Male" : "Female",
  );

  const [errorMartialStatus, setErrorMartialStatus] = useState(null);
  const [test, setTest] = useState(false);
  const [disable, setDisable] = useState(false);

  const { selectedPlanDetails } = useSelector(state => state.selectedPlanForm);
  const ls = new SecureLS();

  const enquiryId = useUrlQuery("enquiryId");

  const { customerDetails } = useSelector(state => state.greeting);
  const { eligibilityDetails } = useSelector(state => state.eligibilityCard);

  const { selectedQuote } = useSelector(state => state.quote);

  // const regionDetails = sessionStorage.getItem("regionDetails");
  const { regionDetails } = useSelector(state => state.eligibilityCard);

  const [pinCode, setPinCode] = useState(
    (insFor === "self" && eligibilityDetails.pinCode) || null,
  );

  const [permPincode, setPermPincode] = useState(null);

  const MARTIAL_STATUS = [
    "Marital Status",
    "Single",
    "Married",
    "Widowed",
    "Divorced",
  ];

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    trigger,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(proposerFormSchema),
    mode: "onChange",
    defaultValues: {
      gender: insFor === "self" ? customerDetails["gender"] : "",
    },
  });

  const { isValid } = formState;

  useEffect(() => {
    if (proposerDetails.martialStatus) {
      setMartialStatus(proposerDetails.martialStatus);
    }
    if (proposerDetails.comm_per && proposerDetails.comm_per === "N") {
      setCommPer(false);
    } else {
      setCommPer(true);
    }
  }, [proposerDetails.comm_per, proposerDetails.martialStatus]);

  const checkPincode = async data => {
    try {
      const response = await checkpinCode(data);
      if (response.data) {
        setValue("p_state", response.data.state);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(errors, formState);
  const validateSelect = useCallback(() => {
    if (!martialStatus || martialStatus === "Marital Status*") {
      setErrorMartialStatus(true);
    } else {
      setErrorMartialStatus(false);
    }
  }, [martialStatus]);

  useEffect(() => {
    if (isValid) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [isValid]);

  useEffect(() => {
    register({ name: "maritalStatus" });
    setValue("maritalStatus", martialStatus);
  }, [customerDetails, martialStatus]);
  useEffect(() => {
    if (insFor === "self") {
      setValue("gender", customerDetails["gender"]);
    }
    register({ name: "gender" });
    setValue("gender", gender === "Male" ? "M" : "F");
  }, [customerDetails, insFor, gender]);

  useEffect(() => {
    if (test) {
      validateSelect();
      trigger();
    }
  }, [validateSelect, test]);

  useEffect(() => {
    if (permPincode?.length === 6) {
      const data = {
        pinCode: permPincode,
        companyAlias: selectedQuote?.company_alias,
        traceId: enquiryId,
      };
      checkPincode(data);
    }
  }, [dispatch, permPincode]);

  useEffect(() => {
    register("p_pincode");
    register("p_city");
    register("p_state");
    register("p_address_line_1");
    register("p_address_line_2");
    setValue("p_pincode", proposerDetails?.p_pincode || "");
    setValue("p_city", proposerDetails?.p_city || "");
    setValue("p_state", proposerDetails?.p_state || "");
    setValue("p_address_line_1", proposerDetails?.p_address_line_1 || "");
    setValue("p_address_line_2", proposerDetails?.p_address_line_2 || "");
  }, []);

  const useDidMountEffect = (func = () => {}, deps = []) => {
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
  };

  useEffect(() => {
    if (comm_per) {
      register("p_pincode");
      register("p_city");
      register("p_state");
      register("p_address_line_1");
      register("p_address_line_2");
      setValue("p_pincode", pinCode);
      setValue("p_state", getValues("state"));
      setValue("p_city", getValues("city"));
      setValue("p_address_line_1", getValues("addressLine1"));
      setValue("p_address_line_2", getValues("addressLine2"));
    }
  }, [comm_per, getValues(), dispatch]);

  useDidMountEffect(() => {
    if (!comm_per) {
      setValue("p_pincode", "");
      setValue("p_city", "");
      setValue("p_state", "");
      setValue("p_address_line_1", "");
      setValue("p_address_line_2", "");
    }
  }, [comm_per]);

  useEffect(() => {
    if (permPincode?.length === 6) {
      const data = {
        pinCode: permPincode,
        companyAlias: selectedQuote?.company_alias,
        traceId: enquiryId,
      };
      dispatch(getRegion(data));
    } else {
      setValue("p_state", "");
      setValue("p_city", "");
    }
  }, [dispatch, permPincode]);

  let date = "";
  const attachRef = ref => {
    register(ref);
    date = ref;
  };

  const handleChange = event => {
    setAge(dateUtil(event, date));
  };

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  const onSubmit = data => {
    let isPoliticallyExposed = "No";
    if (politicallyExposed) {
      isPoliticallyExposed = "Yes";
    }

    if (!politicallyExposed) {
      isPoliticallyExposed = "No";
    }

    setEdit(false);
    setTest(true);
    data["dob"] = data["dob"].replace(/ /g, "");
    const da = data.dob.split("/").reverse().join("-");
    delete data.dob;

    const newData = {
      ...data,
      martialStatus,
      isPoliticallyExposed,
      dob: da,
      comm_per: comm_per ? "Y" : "N",
      gender: insFor === "self" ? customerDetails["gender"] : data.gender,
      // proposerState: regionDetails.pinCodeMaster.state,
      proposerState: eligibilityDetails.state,
      // proposerCity: regionDetails.pinCodeMaster.city,
      //proposerCity: eligibilityDetails.selectedCity,
    };
    if (martialStatus && martialStatus !== "Marital Status*") {
      const sendData = proposerSendData(newData);
      saveProposalForm(sendData, () => {
        dispatch(saveProposerDetails(newData));
        dispatch(setPosition(position + 1));
      });
    }
  };

  useEffect(() => {
    if (proposerDetails) {
      if (position === index) {
        setEdit(true);
      }
    }
  }, [position]);

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC> Proposer Details</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  if (selectedPlanDetails)
    return (
      <>
        {proposerDetails && !edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer Details
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: "#107591",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEdit(true);
                  dispatch(setPosition(index));
                }}
              >
                <img src={editIcon} alt="editIcon" />
                &nbsp;EDIT
              </span>
            </CardTitle>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {data.map(d => (
                <FormGroup md formPage key={d.name}>
                  <Label md editMode htmlFor={d.name}>
                    {d.label}
                  </Label>
                  <span
                    className="data"
                    style={{ top: "32px", left: "17px", position: "relative" }}
                  >
                    {proposerDetails[d.name]}
                  </span>
                  {d.name === "dob"
                    ? age && (
                        <AgeWrapper
                          style={{
                            top: "50px",
                            right: "0px",
                            width: "70px",
                            height: "35px",
                          }}
                        >
                          {age} years
                        </AgeWrapper>
                      )
                    : null}
                </FormGroup>
              ))}
              <FormGroup md formPage>
                <Label md editMode htmlFor="comm_per">
                  Permanent address is same as mentioned above
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {proposerDetails["comm_per"]}
                </span>
              </FormGroup>
              {!comm_per &&
                p_data.map(d => (
                  <FormGroup md formPage key={d.name}>
                    <Label md editMode htmlFor={d.name}>
                      {d.label}
                    </Label>
                    <span
                      className="data"
                      style={{
                        top: "32px",
                        left: "17px",
                        position: "relative",
                      }}
                    >
                      {proposerDetails[d.name]}
                    </span>
                  </FormGroup>
                ))}

              {/* ------------------------------------------------ */}

              <FormGroup md formPage>
                <Label md editMode htmlFor="gender">
                  Gender
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {insFor === "self"
                    ? customerDetails["gender"] === "M"
                      ? "Male"
                      : "Female"
                    : proposerDetails["gender"] === "M"
                    ? "Male"
                    : "Female"}
                </span>
              </FormGroup>
              <FormGroup md formPage>
                <Label md editMode htmlFor="martialStatus">
                  Marital Status
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {proposerDetails["martialStatus"]}
                </span>
              </FormGroup>
              <FormGroup md formPage>
                <Label md editMode htmlFor="gender">
                  Are You a Politically Exposed Person?
                </Label>
                <span
                  className="data"
                  style={{ top: "32px", left: "17px", position: "relative" }}
                >
                  {proposerDetails["isPoliticallyExposed"]}
                </span>
              </FormGroup>
            </div>
          </Card>
        ) : edit ? (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer Details
            </CardTitle>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              tabIndex="1"
            >
              {data.map(d => (
                <FormGroup
                  proposalCard={d.name.includes("addressLine") ? false : true}
                  proposalCardL={d.name.includes("addressLine") ? true : false}
                  eligibilityPage
                  key={d.id}
                >
                  <TextInputElg
                    proposal
                    readOnly={d.readOnly}
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    defaultValue={proposerDetails[d.name]}
                    id={d.id}
                    onChange={
                      d.name === "dob"
                        ? handleChange
                        : d.name === "pinCode"
                        ? e => setPinCode(e.target.value)
                        : null
                    }
                    onKeyPress={onlyOneSpace}
                    onInput={
                      d.name === "mobileNo" || d.name === "pinCode"
                        ? numOnly
                        : null
                    }
                    style={{
                      textTransform:
                        (d.name === "firstName" || d.name === "lastName") &&
                        "capitalize",
                    }}
                    placeholder={d.placeHolder}
                    //  autoComplete={d.autoComplete}
                    autoComplete="none"
                    maxLength={d.maxLength || (d.name === "mobileNo" && 10)}
                    ref={d.name === "dob" ? attachRef : register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {d.name === "dob"
                    ? age && (
                        <AgeWrapper
                          style={{
                            top: "50px",
                            right: "0px",
                            width: "70px",
                            height: "35px",
                          }}
                        >
                          {age} years
                        </AgeWrapper>
                      )
                    : null}

                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}

              {/* ------------------------------------------------ */}

              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">
                  Permanent address is same as mentioned above
                </div>
                <RadioComponent
                  value={comm_per}
                  // className="toggle--sb"
                  name="comm_per"
                  // label="Are You a Politically Exposed Person?"
                  updateChecked={() => setCommPer(!comm_per)}
                />
              </FormGroup>
              {!comm_per &&
                p_data.map(d => (
                  <FormGroup
                    proposalCard={
                      d.name.includes("p_address_line") ? false : true
                    }
                    proposalCardL={
                      d.name.includes("p_address_line") ? true : false
                    }
                    eligibilityPage
                    key={d.id}
                  >
                    <TextInputElg
                      proposal
                      readOnly={d.readOnly}
                      error={errors[d.id] && "danger"}
                      type={d.type}
                      name={d.name}
                      defaultValue={proposerDetails[d.name]}
                      id={d.id}
                      onChange={
                        d.name === "p_pincode"
                          ? e => setPermPincode(e.target.value)
                          : null
                      }
                      onKeyPress={onlyOneSpace}
                      onInput={d.name === "p_pincode" ? numOnly : null}
                      placeholder={d.placeHolder}
                      //  autoComplete={d.autoComplete}
                      autoComplete="none"
                      maxLength={d.maxLength}
                      ref={register}
                    />

                    <Label md htmlFor={d.id}>
                      {d.label}
                    </Label>

                    {errors[d.id] && (
                      <p className="error">{errors[d.id].message}</p>
                    )}
                  </FormGroup>
                ))}

              {/* ------------------------------------------------ */}
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Gender"
                    propDropDown
                    items={["Male", "Female"]}
                    selectedItem={
                      insFor === "self"
                        ? customerDetails["gender"] === "M"
                          ? "Male"
                          : "Female"
                        : gender
                    }
                    currentSelected={
                      insFor === "self"
                        ? customerDetails["gender"] === "M"
                          ? "Male"
                          : "Female"
                        : gender
                    }
                    // disabled={insFor === "self" ? true : false}
                    //ref={register({ name: "gender" })}
                    // onChange={setValue("gender", customerDetails["gender"])}
                    onChange={e => {
                      //	setValue("gender", e === "Male" ? "M" : "F");
                      setGender(e);
                    }}
                    disabled={insFor === "self"}
                  />
                </CustomDropDownFont>
              </FormGroup>
              {/* <FormGroup md style={{ pointerEvents: "none" }}>
                <RadioContainer md proposer mob>
                  <Radio
                    type="radio"
                    id="Male"
                    name="gender"
                    value="M"
                    ref={register}
                    defaultChecked={proposerDetails["gender"] === "M"}
                  />
                  <RadioLabel
                    error={errors.gender && "danger"}
                    sm
                    proposer
                    htmlFor="Male"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      {" "}
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Male
                      </span>
                    </div>
                  </RadioLabel>
                  <Radio
                    type="radio"
                    id="Female"
                    name="gender"
                    ref={register}
                    value="F"
                    defaultChecked={proposerDetails["gender"] === "F"}
                  />
                  <RadioLabel
                    error={errors.gender && "danger"}
                    sm
                    proposer
                    htmlFor="Female"
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Female
                      </span>
                    </div>
                  </RadioLabel>
                  {errors.gender && (
                    <p className="errorG">{errors.gender.message}</p>
                  )}
                </RadioContainer>
              </FormGroup> */}
              <FormGroup proposalCard md formPage>
                <CustomDropDownFont rdr CustomFontBold>
                  <CustomDropDown
                    dropDownTitle="Your Marital Status"
                    propDropDown
                    searchable={true}
                    items={MARTIAL_STATUS}
                    error={!!errors.maritalStatus && "danger"}
                    selectedItem={proposerDetails.martialStatus}
                    onChange={e => {
                      setValue("maritalStatus", e);
                      trigger("maritalStatus");
                      setMartialStatus(e);
                    }}
                  />
                </CustomDropDownFont>
                {errorMartialStatus || errors?.maritalStatus ? (
                  <p className="error">{errors?.maritalStatus?.message}</p>
                ) : null}
              </FormGroup>
              <FormGroup className="flexAlignment" xlg formPage noBeforeAfter>
                <div className="radioText">
                  Are You a Politically Exposed Person?
                </div>
                <RadioComponent
                  value={politicallyExposed}
                  // className="toggle--sb"
                  name="politicallyExposed"
                  // label="Are You a Politically Exposed Person?"
                  updateChecked={() =>
                    setPoliticallyExposed(!politicallyExposed)
                  }
                />
              </FormGroup>
              <FormGroup xlg propSubWrap formPage>
                <button
                  type="submit"
                  disabled={disable}
                  className={
                    disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc  formCardBtn propSubmit"
                  }
                >
                  PROCEED TO PROPOSER FATCA DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        ) : (
          <Card proposal>
            <CardTitle eligibilityTitle className="cardTitleProposal">
              Proposer Details
            </CardTitle>

            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              tabIndex="1"
            >
              {data.map(d => (
                <FormGroup
                  proposalCard={d.name.includes("addressLine") ? false : true}
                  proposalCardL={d.name.includes("addressLine") ? true : false}
                  eligibilityPage
                  key={d.id}
                >
                  <TextInputElg
                    proposal
                    error={errors[d.id] && "danger"}
                    type={d.type}
                    name={d.name}
                    id={d.id}
                    defaultValue={
                      insFor === "self"
                        ? d.name === "email"
                          ? eligibilityDetails["secondary_email"]
                          : eligibilityDetails[d.name]
                        : "" ||
                          (insFor === "dependent" && d.name === "pinCode"
                            ? "" //eligibilityDetails["pinCode"]
                            : "")
                    }
                    readOnly={
                      d.readOnly
                      //  ||	(insFor === "dependent" && d.name === "pinCode")
                    }
                    onChange={
                      d.name === "dob"
                        ? handleChange
                        : d.name === "pinCode"
                        ? e => setPinCode(e.target.value)
                        : null
                    }
                    onKeyPress={onlyOneSpace}
                    onInput={
                      d.name === "mobileNo" || d.name === "pinCode"
                        ? numOnly
                        : null
                    }
                    placeholder={d.placeHolder}
                    // autoComplete={d.autoComplete}
                    maxLength={d.maxLength}
                    autoComplete="none"
                    ref={d.name === "dob" ? attachRef : register}
                  />

                  <Label md htmlFor={d.id}>
                    {d.label}
                  </Label>
                  {d.name === "dob"
                    ? age && (
                        <AgeWrapper
                          style={{
                            top: "50px",
                            right: "0px",
                            width: "70px",
                            height: "35px",
                          }}
                        >
                          {age} years
                        </AgeWrapper>
                      )
                    : null}

                  {errors[d.id] && (
                    <p className="error">{errors[d.id].message}</p>
                  )}
                </FormGroup>
              ))}

              {/* ---------------------------------------------------------------------------------------------------- */}
              <FormGroup
                //
                className="flexAlignment"
                xlg
                formPage
                noBeforeAfter
              >
                <div className="radioText">
                  Permanent address is same as mentioned above
                </div>
                <RadioComponent
                  value={comm_per}
                  // className="toggle--sb"
                  name="comm_per"
                  updateChecked={() => setCommPer(!comm_per)}
                />
              </FormGroup>

              {!comm_per &&
                p_data.map(d => (
                  <FormGroup
                    proposalCard={
                      d.name.includes("p_address_line") ? false : true
                    }
                    proposalCardL={
                      d.name.includes("p_address_line") ? true : false
                    }
                    eligibilityPage
                    key={d.id}
                  >
                    <TextInputElg
                      proposal
                      error={errors[d.id] && "danger"}
                      type={d.type}
                      name={d.name}
                      id={d.id}
                      onChange={
                        d.name === "p_pincode"
                          ? e => setPermPincode(e.target.value)
                          : null
                      }
                      onKeyPress={onlyOneSpace}
                      onInput={d.name === "pinCode" ? numOnly : null}
                      readOnly={d.readOnly}
                      placeholder={d.placeHolder}
                      maxLength={d.maxLength}
                      // autoComplete={d.autoComplete}
                      autoComplete="none"
                      ref={register}
                    />

                    <Label md htmlFor={d.id}>
                      {d.label}
                    </Label>
                    {errors[d.id] && (
                      <p className="error">{errors[d.id].message}</p>
                    )}
                  </FormGroup>
                ))}

              {/* ----------------------------------------------------------------------------------------------------- */}
              <FormGroup proposalCard eligibilityPage>
                <CustomDropDownFont rdr>
                  <CustomDropDown
                    dropDownTitle="Gender"
                    propDropDown
                    items={["Male", "Female"]}
                    selectedItem={
                      insFor === "self"
                        ? customerDetails["gender"] === "M"
                          ? "Male"
                          : "Female"
                        : gender
                    }
                    currentSelected={
                      insFor === "self"
                        ? customerDetails["gender"] === "M"
                          ? "Male"
                          : "Female"
                        : gender
                    }
                    // disabled={insFor === "self" ? true : false}
                    //	ref={register}
                    onChange={e => {
                      //	setValue("gender", e === "Male" ? "M" : "F");
                      setGender(e);
                    }}
                    disabled={insFor === "self"}
                  />
                </CustomDropDownFont>
              </FormGroup>
              {/* <FormGroup md>
                <RadioContainer md proposer mob>
                  <Radio
                    type="radio"
                    id="Male"
                    name="gender"
                    value="M"
                    readOnly={insFor === "self"}
                    defaultChecked={
                      insFor === "self" && customerDetails["gender"] === "M"
                    }
                    ref={register}
                  />
                  <RadioLabel
                    error={errors.gender && "danger"}
                    sm
                    proposer
                    htmlFor="Male"
                    style={{
                      textAlign: "unset",
                      pointerEvents: insFor === "self" && "none",
                    }}
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      {" "}
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Male
                      </span>
                    </div>
                  </RadioLabel>
                  <Radio
                    type="radio"
                    id="Female"
                    name="gender"
                    ref={register}
                    readOnly={insFor === "self"}
                    value="F"
                    defaultChecked={
                      insFor === "self" && customerDetails["gender"] === "F"
                    }
                  />
                  <RadioLabel
                    error={errors.gender && "danger"}
                    sm
                    proposer
                    htmlFor="Female"
                    style={{
                      textAlign: "unset",
                      pointerEvents: insFor === "self" && "none",
                    }}
                  >
                    <div className="radiolabel__container radiolabel__formbuilder-container noselect">
                      <RadioButton
                        className="radioBtnHover"
                        marginsm
                      ></RadioButton>
                      <span className="radiolabel__text radiolabel__formbuilder-text">
                        Female
                      </span>
                    </div>
                  </RadioLabel>
                  {errors.gender && (
                    <p className="errorG">{errors.gender.message}</p>
                  )}
                </RadioContainer>
              </FormGroup> */}
              <FormGroup proposalCard md formPage>
                <CustomDropDownFont CustomFontBold rdr>
                  <CustomDropDown
                    dropDownTitle="Your Marital Status"
                    propDropDown
                    items={MARTIAL_STATUS}
                    searchable={true}
                    selected
                    error={!!errors.maritalStatus && "danger"}
                    onChange={e => {
                      setValue("maritalStatus", e);
                      trigger("maritalStatus");
                      setMartialStatus(e);
                    }}
                  />
                </CustomDropDownFont>
                {errorMartialStatus || errors?.maritalStatus ? (
                  <p className="error">{errors?.maritalStatus?.message}</p>
                ) : null}
              </FormGroup>
              <FormGroup
                //
                className="flexAlignment"
                xlg
                formPage
                noBeforeAfter
              >
                <div className="radioText">
                  Are You a Politically Exposed Person?
                </div>
                <RadioComponent
                  value={politicallyExposed}
                  // className="toggle--sb"
                  // label="Are You a Politically Exposed Person?"
                  name="politicallyExposed"
                  updateChecked={() =>
                    setPoliticallyExposed(!politicallyExposed)
                  }
                />
              </FormGroup>

              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  // disabled={disable}
                  className={
                    disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                  }
                >
                  PROCEED TO PROPOSER FATCA DETAILS
                </button>
              </FormGroup>
            </Form>
          </Card>
        )}
      </>
    );
};
export default ProposerForm;

//pincode removed

// {d.name === "pinCode" && (
// 	<span
// 		className="region-ec"
// 		style={{
// 			position: "relative",
// 			top: "26px",
// 			left: "30px",
// 		}}
// 	>
// 		<img src={location} alt="location" />
// 		{/* {regionDetails.pinCodeMaster.city}, */}
// 		{/* {eligibilityDetails.selectedCity}, */}
// 		{eligibilityDetails.state}

// 	</span>
// )}
