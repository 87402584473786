import HttpClient from "../../api/httpClient";

// const createUser = (data) =>
//   HttpClient("common/create/user", { method: "POST", data });

const checkpinCode = data =>
  HttpClient("common/get_ic_pincode_mapping", { method: "POST", data });

const sendEligibilityDetails = data => {
  return HttpClient("common/eligiblity_details", { method: "POST", data });
};

export const singleQuote = data =>
  HttpClient("common/single_product", {
    method: "POST",
    data,
  });

const BiGenerate = data =>
  HttpClient("common/generate_bi", { method: "POST", data });

const appNoGenerate = data =>
  HttpClient("common/generate_application_no", { method: "POST", data });

export const updateQuotation = data =>
  HttpClient("common/update_quotation", { method: "POST", data });
export const getRedirectionUrl = data =>
  HttpClient("common/get_redirection_url", { method: "POST", data });

export const checkEligibility = data =>
  HttpClient("common/check_eligibility", { method: "POST", data });

export const getEligibilityGrid = data =>
  HttpClient("common/getEligibilityGrid", { method: "POST", data });

export const checkICICIEligibility = data =>
  HttpClient("common/check_ipru_eligibility", { method: "POST", data });

export { checkpinCode, BiGenerate, sendEligibilityDetails, appNoGenerate };
