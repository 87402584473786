import { combineReducers } from "redux";
import {
  eligibilityCardSlice,
  eligibilityPageSlice,
  formCardSlice,
  greetingSlice,
  quoteFilterSlice,
  quotePageSlice,
  proposalSlice,
  insuredFtcaSlice,
  insuredOccupationSlice,
  idFormSlice,
  otherInsuranceSlice,
  medicalDetails3Slice,
  medicalDetails4Slice,
  medicalDetailsOneSlice,
  medicalDetailsTwoSlice,
  nomineeFormSlice,
  paymentSlice,
  riderPageSlice,
  insuredDetails1Slice,
  insuredDetails2Slice,
  proposalOccupationSlice,
  selectedPlanFormSlice,
  medicalFormCenterSlice,
  kotakPersonalOneSlice,
  kotakPersonalTwoSlice,
  kotakPersonalThreeSlice,
  healthDetailsOneSlice,
  healthDetailsTwoSlice,
  kotaknomineeFormSlice,
  iciciProposalSlice,
  proposalPageSlice,
  kotakSingleProductSlice,
  iciciSingleProductSlice,
  maxLifeProposalPageSlice,
  kotakProposalPageSlice,
  healthDetailsThreeSlice,
  maxnomineeFormSlice,
  officialAddressSlice,
  bajajProposalPageSlice,
  documentUploadSlice,
  maxSingleQuoteSlice,
  bajajSingleQuoteSlice,
  birlaProposalPageSlice,
  maxMedicalCenterSlice,
  bajajDiscountSlice,
  kotakDiscountSlice,
  maxDiscountSlice,
  iciciDiscountSlice,
} from "../modules";
import otpSlice from "../modules/otp/otp-slice";

export default combineReducers({
  documentUpload: documentUploadSlice,
  eligibilityPage: eligibilityPageSlice,
  quoteFilter: quoteFilterSlice,
  quote: quotePageSlice,
  greeting: greetingSlice,
  formCard: formCardSlice,
  eligibilityCard: eligibilityCardSlice,
  proposalForm: proposalSlice,
  insuredFtcaForm: insuredFtcaSlice,
  insuredOccupationForm: insuredOccupationSlice,
  idForm: idFormSlice,
  otherInsuranceForm: otherInsuranceSlice,
  medicalDetails3: medicalDetails3Slice,
  medicalDetails4: medicalDetails4Slice,
  medicalDetailsOne: medicalDetailsOneSlice,
  medicalDetailsTwo: medicalDetailsTwoSlice,
  nomineeForm: nomineeFormSlice,
  // ckycForm: ckycFormSlice,
  insuredDetails1Form: insuredDetails1Slice,
  payment: paymentSlice,
  rider: riderPageSlice,
  insuredDetails2Form: insuredDetails2Slice,
  proposalOccupationForm: proposalOccupationSlice,
  selectedPlanForm: selectedPlanFormSlice,
  medicalFormCenter: medicalFormCenterSlice,

  // employmentDetails: employmentDetailsSlice,
  // hdfcContactForm: hdfcContactFormSlice,
  healthDetailsOne: healthDetailsOneSlice,
  healthDetailsTwo: healthDetailsTwoSlice,
  kotakPersonalOne: kotakPersonalOneSlice,
  kotakPersonalTwo: kotakPersonalTwoSlice,
  kotakPersonalThree: kotakPersonalThreeSlice,
  // hdfcnomineeForm: hdfcnomineeFormSlice,
  kotaknomineeForm: kotaknomineeFormSlice,
  iciciProposal: iciciProposalSlice,
  // hdfcSingleQuote: hdfcSingleQuoteSlice,
  proposalPage: proposalPageSlice,
  kotakSingleProduct: kotakSingleProductSlice,
  iciciSingleProduct: iciciSingleProductSlice,
  // hdfcProposalPageForm: hdfcProposalPageSlice,
  maxLifeProposalPageForm: maxLifeProposalPageSlice,
  kotakProposalPageForm: kotakProposalPageSlice,
  healthDetailsThree: healthDetailsThreeSlice,
  maxnomineeForm: maxnomineeFormSlice,
  officialAddress: officialAddressSlice,
  maxSingleQuote: maxSingleQuoteSlice,
  bajajProposalPageForm: bajajProposalPageSlice,
  bajajSingleQuote: bajajSingleQuoteSlice,
  birlaProposalPageForm: birlaProposalPageSlice,
  maxMedicalCenter: maxMedicalCenterSlice,
  // // kotakFactaForm: kotakFactaFormSlice,
  kotakDiscount: kotakDiscountSlice,
  otp: otpSlice,
  bajajDiscount: bajajDiscountSlice,
  maxDiscount: maxDiscountSlice,
  iciciDiscount: iciciDiscountSlice,
});
