import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardTitle from "../../../../components/Card/CardTitle/CardTitle";
import CustomDropDown from "../../../../components/CutomDropDown/CustomDropDown";
import {
  medicalDetailsOneSendData,
  saveMedicalDetailsOne,
} from "./MedicalDetailsOne.slice";

import {
  CONSUMED_AS,
  HEIGHT_LIST,
  QUANTITY_PER_DAY,
  WEIGHT_LIST,
  TOBACCO_CONSUMED_AS,
  TOBACCO_QUANTITY_PER_DAY,
} from "./data";
import {
  Form,
  FormGroup as FG,
  Label,
  TextInput,
  CustomDropDownFont,
  TextInputElg,
} from "../../../../components/Form/Form";
import {
  EditButton,
  SummaryTile,
  TitleForm,
  ToggleTile,
} from "../../components";
import "./medical-details-one.scss";
import {
  getAge,
  getAlcoholConsumed,
  getAlcoholQuantity,
  getTobacooConsumed,
  getTobacooQuantity,
  heightInCm,
  noCopyAndPaste,
  numOnly,
  removeKg,
} from "../../../../utils/inputUtils";
import {
  // saveIciciPlanCardDetails,
  saveIciciSingleQuoteDetails,
} from "../iciciSingleProduct/iciciSingleProduct.slice";
import RadioComponent from "../../../../components/RadioContainer/RadioContainer";
import useSaveProposalForm from "../../useSaveProposalForm";
import Loader from "../../../../components/Loader/Loader";
import { iciciProposalForms } from "../IcicProposal.slice";
import styled from "styled-components/macro";

const FormGroup = styled(FG)`
  height: auto !important;
`;

const MedicalDetailsOne = ({ position, setPosition, index }) => {
  const dispatch = useDispatch();
  const { otherInsuranceDetails } = useSelector(
    state => state.otherInsuranceForm,
  );
  const { idDetails } = useSelector(state => state.idForm);

  const { customerDetails } = useSelector(state => state.greeting);

  const { medicalDetailsOne } = useSelector(state => state.medicalDetailsOne);
  const [summary, setSummary] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [disable3, setDisable3] = useState(false);

  const [height, setHeight] = useState(
    medicalDetailsOne ? medicalDetailsOne.height : "Height (Ft/Inches)",
  );
  const [weight, setWeight] = useState(
    medicalDetailsOne ? medicalDetailsOne.weight : "Weight (Kilograms)",
  );
  const [consumedAs, setConsumedAs] = useState(
    medicalDetailsOne ? medicalDetailsOne.consumedAs : "Alcohol Consumed As",
  );
  const [quantityPerDay, setQuantiyPerDay] = useState(
    medicalDetailsOne ? medicalDetailsOne.quantityPerDay : "Quantity Per Day",
  );
  const [years, setYears] = useState(
    medicalDetailsOne ? medicalDetailsOne.years : "",
  );
  const [yearsError, setYearsError] = useState(false);

  const [tobacco, setTobacco] = useState(
    customerDetails.tobaccoHabit === "N" ? false : true,
  );
  const [alcohol, setAlcohol] = useState(
    medicalDetailsOne ? medicalDetailsOne.alcohol : false,
  );
  const [narcotics, setNarcotics] = useState(
    medicalDetailsOne ? medicalDetailsOne.narcotics : false,
  );

  const { iciciSingleProduct } = useSelector(state => state.iciciSingleProduct);
  const [apiData, setApiData] = useState({});

  const [tobaccoConsumedAs, setTobaccoConsumedAs] = useState(
    medicalDetailsOne.tabaccoConsumedAs
      ? medicalDetailsOne.tabaccoConsumedAs
      : "Tobacco Consumed As",
  );

  const [tobaccoQuantityPerDay, setTobaccoQuantiyPerDay] = useState(
    medicalDetailsOne.tobaccoQuantityPerDay
      ? medicalDetailsOne.tobaccoQuantityPerDay
      : "Tobacco Quantity Per Day",
  );

  const [tobaccoStartDate, setTobaccoStartDate] = useState(
    medicalDetailsOne.tobaccoStartDate
      ? medicalDetailsOne.tobaccoStartDate
      : "",
  );
  useEffect(() => {
    setApiData(iciciSingleProduct.quote_data);
  }, [iciciSingleProduct]);

  useEffect(() => {
    dispatch(saveIciciSingleQuoteDetails());
  }, []);

  useEffect(() => {
    if (height === "Height (Ft/Inches)" || weight === "Weight (Kilograms)") {
      setDisable1(false);
    } else {
      setDisable1(true);
    }
  }, [
    height,
    weight,
    tobacco,
    alcohol,
    disable2,
    disable1,
    disable3,
    setDisable1,
  ]);

  useEffect(() => {
    if (
      (tobacco && tobaccoConsumedAs === "Tobacco Consumed As") ||
      (tobacco && tobaccoQuantityPerDay === "Tobacoo Quantity Per Day") ||
      (tobacco && tobaccoStartDate === "")
    ) {
      setDisable2(false);
    } else {
      setDisable2(true);
    }
  }, [
    tobacco,
    alcohol,
    tobaccoConsumedAs,
    tobaccoQuantityPerDay,
    tobaccoStartDate,
    disable2,
    disable1,
    disable3,
    setDisable2,
  ]);

  const { dob } = useSelector(
    state => state.eligibilityCard.eligibilityDetails,
  );

  const age = getAge(dob);

  useEffect(() => {
    if (
      (alcohol && consumedAs === "Alcohol Consumed As") ||
      (alcohol && quantityPerDay === "Quantity Per Day") ||
      (alcohol && (years === "" || years > age))
    ) {
      setDisable3(false);
    } else {
      setDisable3(true);
    }
  }, [
    alcohol,
    narcotics,
    quantityPerDay,
    consumedAs,
    years,
    disable2,
    disable1,
    disable3,
    setDisable3,
    age,
  ]);

  useEffect(() => {
    if (alcohol && years > age) {
      setYearsError("Years cannot exceed customer age");
    } else {
      setYearsError(false);
    }
  }, [alcohol, years]);

  useEffect(() => {
    if (disable1 && disable2 && disable3) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [disable2, disable1, disable3, disable]);

  useEffect(() => {
    if (medicalDetailsOne) {
      if (position === index) {
        setSummary(false);
      }
      if (position > index) {
        setSummary(true);
      }
    }
  }, [position]);

  const { saveProposalForm, isLoading } = useSaveProposalForm();

  // if (position < index) return <TitleForm title="Medical Details 1" />;
  const handleSubmit = event => {
    event.preventDefault();
    const newData = {
      height,
      weight,
      tobacco,
      alcohol,
      narcotics,
      tobaccoConsumedAs:
        tobaccoConsumedAs && tobacco ? tobaccoConsumedAs : false,
      tobaccoQuantity:
        tobaccoQuantityPerDay && tobacco ? tobaccoQuantityPerDay : false,
      tobaccoStartDate: tobaccoStartDate && tobacco ? tobaccoStartDate : false,
      years: years !== "" && alcohol ? years : false,
      consumedAs:
        consumedAs !== "Alcohol Consumed As" && alcohol ? consumedAs : false,
      quantityPerDay:
        quantityPerDay !== "Quantity Per Day" && alcohol
          ? quantityPerDay
          : false,
    };

    const sendData = medicalDetailsOneSendData(newData);

    saveProposalForm(sendData, () => {
      dispatch(saveMedicalDetailsOne(newData));
      setSummary(true);
      dispatch(setPosition(position + 1));
    });
  };

  const TitleForm = (
    <div>
      <Card className="proposalTitleCard">
        <CardTitle EC>Medical Question - Set 1</CardTitle>
      </Card>
    </div>
  );

  if (isLoading) return <Loader />;

  if (position < index) return TitleForm;

  if (idDetails) {
    return (
      <div className="medical-details-one">
        <Card proposal>
          <CardTitle
            eligibilityTitle={summary ? true : undefined}
            className="cardTitleProposal"
          >
            Medical Question - Set 1
            {summary && (
              <EditButton
                onClick={() => {
                  dispatch(setPosition(index));
                  setSummary(false);
                }}
              />
            )}
          </CardTitle>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormGroup proposalCard eligibilityPage>
              {!summary ? (
                <div className="filterMenuSelectWrap selectIncome">
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      dropDownTitle="Your Height"
                      propDropDown
                      searchable
                      items={HEIGHT_LIST}
                      selectedItem={height}
                      notAllowed="Height (Ft/Inches)"
                      onChange={setHeight}
                    />
                  </CustomDropDownFont>
                </div>
              ) : (
                <SummaryTile label="Height (Ft/Inches)" data={height} />
              )}
            </FormGroup>
            <FormGroup proposalCard eligibilityPage>
              <div className="filterMenuSelectWrap selectIncome">
                {!summary ? (
                  <CustomDropDownFont rdr>
                    <CustomDropDown
                      dropDownTitle="Your Weight"
                      propDropDown
                      searchable
                      items={WEIGHT_LIST}
                      selectedItem={weight}
                      notAllowed="Weight (Kilograms)"
                      onChange={setWeight}
                    />
                  </CustomDropDownFont>
                ) : (
                  <SummaryTile label="Weight (Kilograms)" data={weight} />
                )}
              </div>
            </FormGroup>
            <FormGroup lg className="mobblankspcace">
              {!summary ? (
                <>
                  <FormGroup
                    xlg
                    formPage
                    className="form-group--1 proposalInput"
                    style={{
                      diaplay: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="healthQuestion">
                      Do you consume or have ever consumed tobacco?
                    </div>
                    <RadioComponent
                      value={tobacco}
                      name="tobacco"
                      // className="toggle--sb"
                      // label="Do you consume or have ever consumed tobacco?"
                      updateChecked={value => setTobacco(value)}
                      disableRadio={
                        apiData && apiData.term_tobacco_habit === "N"
                          ? true
                          : false
                      }
                    />
                  </FormGroup>
                </>
              ) : (
                <SummaryTile
                  sum
                  label="Do you consume or have ever consumed tobacco?"
                  //data={tobacco ? "Yes" : "No"}
                  data={
                    apiData && apiData.term_tobacco_habit === "N" ? "No" : "Yes"
                  }
                />
              )}
            </FormGroup>
            {tobacco && (
              <>
                <FormGroup proposalCard eligibilityPage>
                  <div className="filterMenuSelectWrap selectIncome">
                    {!summary ? (
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Tobacco Comsumed As"
                          propDropDown
                          id="tobaccoConsumedAs"
                          name="tobaccoConsumedAs"
                          searchable
                          items={TOBACCO_CONSUMED_AS}
                          selectedItem={tobaccoConsumedAs}
                          notAllowed="Tobacco Consumed As"
                          onChange={setTobaccoConsumedAs}
                        />
                      </CustomDropDownFont>
                    ) : (
                      <SummaryTile
                        label="Tobacco Consumed As"
                        data={tobaccoConsumedAs}
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  <div className="filterMenuSelectWrap selectIncome">
                    {!summary ? (
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Tobacco Quantity Per Day"
                          propDropDown
                          id="tobaccoQuantity"
                          name="tobaccoQuantity"
                          searchable
                          items={TOBACCO_QUANTITY_PER_DAY}
                          selectedItem={tobaccoQuantityPerDay}
                          notAllowed="Tobacco Quantity Per Day"
                          onChange={setTobaccoQuantiyPerDay}
                        />
                      </CustomDropDownFont>
                    ) : (
                      <SummaryTile
                        label="Tobacco Quantity Per Day"
                        data={tobaccoQuantityPerDay}
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  {!summary ? (
                    <>
                      <TextInputElg
                        proposal
                        type="text"
                        id="tobaccoStartDate"
                        name="c"
                        placeholder=" "
                        maxLength="2"
                        onInput={numOnly}
                        onKeyDown={noCopyAndPaste}
                        defaultValue={tobaccoStartDate}
                        onChange={e => setTobaccoStartDate(e.target.value)}
                      />
                      <Label md htmlFor="tobaccoStartDate">
                        Since How many years?
                      </Label>
                    </>
                  ) : (
                    <SummaryTile
                      label="Since How many years?"
                      data={tobaccoStartDate}
                    />
                  )}
                </FormGroup>
              </>
            )}

            <FormGroup
              xlg
              formPage
              className="form-group--1 proposalInput"
              style={{
                diaplay: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {!summary ? (
                <>
                  <div className="healthQuestion">
                    Do you consume or have ever consumed Alcohol?
                  </div>
                  <RadioComponent
                    // id="alcohol"
                    name="alcohol"
                    // className="toggle--sb"
                    value={alcohol}
                    // label="Do you consume or have ever consumed Alcohol?"
                    updateChecked={value => setAlcohol(value)}
                  />
                </>
              ) : (
                <SummaryTile
                  label="Do you consume or have ever consumed Alcohol?"
                  data={alcohol ? "Yes" : "No"}
                />
              )}
            </FormGroup>
            {alcohol && (
              <>
                <FormGroup proposalCard eligibilityPage>
                  <div className="filterMenuSelectWrap selectIncome">
                    {!summary ? (
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Alcohol Consumed As"
                          propDropDown
                          id="consumed"
                          name="consumed"
                          searchable
                          items={CONSUMED_AS}
                          selectedItem={consumedAs}
                          notAllowed="Alcohol Consumed As"
                          onChange={setConsumedAs}
                        />
                      </CustomDropDownFont>
                    ) : (
                      <SummaryTile
                        label="Alcohol Consumed As"
                        data={consumedAs}
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  <div className="filterMenuSelectWrap selectIncome">
                    {!summary ? (
                      <CustomDropDownFont rdr>
                        <CustomDropDown
                          dropDownTitle="Quantity Per Day"
                          propDropDown
                          id="quantity"
                          name="quantity"
                          searchable
                          items={QUANTITY_PER_DAY}
                          selectedItem={quantityPerDay}
                          notAllowed="Quantity Per Day"
                          onChange={setQuantiyPerDay}
                        />
                      </CustomDropDownFont>
                    ) : (
                      <SummaryTile
                        label="Quantity per days"
                        data={quantityPerDay}
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup proposalCard eligibilityPage>
                  {!summary ? (
                    <>
                      <TextInputElg
                        proposal
                        type="text"
                        id="years"
                        name="years"
                        placeholder=" "
                        maxLength="2"
                        onInput={numOnly}
                        onKeyDown={noCopyAndPaste}
                        defaultValue={years}
                        onChange={e => setYears(e.target.value)}
                      />
                      <Label md htmlFor="years">
                        Since How many years?
                      </Label>
                      {yearsError && <p className="errorG">{yearsError}</p>}
                    </>
                  ) : (
                    <SummaryTile label="Since How many years?" data={years} />
                  )}
                </FormGroup>
              </>
            )}
            <FormGroup
              xlg
              formPage
              className="form-group--1 proposalInput"
              style={{
                diaplay: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {!summary ? (
                <>
                  <div className="healthQuestion">
                    Do you consume or have ever consumed Narcotics?
                  </div>
                  <RadioComponent
                    value={narcotics}
                    name="narcotics"
                    // className="toggle--sb"
                    // label=" Do you consume or have ever consumed Narcotics?"
                    updateChecked={value => setNarcotics(value)}
                  />
                </>
              ) : (
                <SummaryTile
                  label=" Do you consume or have ever consumed Narcotics?"
                  data={narcotics ? "Yes" : "No"}
                />
              )}
            </FormGroup>
            {!summary && (
              <FormGroup xlg formPage propSubWrap>
                <button
                  type="submit"
                  disabled={!disable}
                  className={
                    !disable
                      ? "btn btn--disabled btn--lg uppercase bold buttton proc disButtonElg formCardBtn propSubmit"
                      : "btn btn--highlighted btn--lg uppercase bold buttton proc formCardBtn propSubmit"
                  }
                >
                  PROCEED TO MEDICAL QUESTION - SET 2
                </button>
              </FormGroup>
            )}
          </Form>
        </Card>
      </div>
    );
  }
};

export default MedicalDetailsOne;
