import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  FormGroup,
  Label,
  // TextInput,
  TextInputElg,
  CustomDropDownFont,
} from "../../../../components/Form/Form";
// import SmallCustomDropDown from "../../../../components/SmallCustomDropDown/SmallCustomDropDown";

import {
  removeFromOtherInsuranceList,
  updateOtherInsuranceList,
} from "./otherInsuranceDetails.slice";
import { COMPANY } from "./constant";
import { noCopyAndPaste, numOnly } from "./../../../../utils/inputUtils";
import { Button } from "../../../../components/StyledButton/Button";
import { CustomDropDown } from "../../../../components";

const OtherInsurance = ({ sumAssured, insuranceCompany, id, onError }) => {
  const dispatch = useDispatch();

  const [sumAssuredText, setSumAssuredtext] = useState(sumAssured);
  const [insuranceCompanyText, setInsuranceCompanyText] = useState(
    insuranceCompany || "Select Insurance Company",
  );

  const [sumAssuredError, setSumAssuredError] = useState("");
  const [companyError, setCompanyError] = useState("");

  return (
    <div className="other-insurance">
      <FormGroup
        proposalCard
        eligibilityPage
        className="otherInsuranceInputBox"
      >
        {/* <div className="upgradeDropList smallCustomDropDownSpacin">
          <div className="upgradeDropLabel">COMPANY NAME</div>
          <div className="filterMenuSelectWrap selectIncome"> */}
        <CustomDropDownFont rdr>
          <CustomDropDown
            dropDownTitle="Company Name"
            propDropDown
            items={COMPANY.map(a => a.toUpperCase()).filter(item => {
              if (item.includes("ICICI")) {
                return false;
              } else return true;
            })}
            selectedItem={insuranceCompanyText || "Select Insurance Company"}
            searchable
            onChange={value => {
              setInsuranceCompanyText(value);
              if (value === "Select Insurance Company") {
                setCompanyError("Please select Insurance Comapny");
                onError(true);
              } else if (
                !(sumAssuredText >= 500000 && sumAssuredText <= 1000000000)
              ) {
                setSumAssuredError(
                  "Sum Assured Amount must be between 5 Lakhs and 100 Crore",
                );
                onError(true);
              } else {
                setCompanyError("");
                setSumAssuredError("");
                onError(false);
                dispatch(
                  updateOtherInsuranceList({
                    id,
                    sumAssured: sumAssuredText,
                    insuranceCompany: value,
                  }),
                );
              }
            }}
          />
        </CustomDropDownFont>
        {/* </div> */}
        {companyError && <p className="error">{companyError}</p>}
        {/* </div> */}
      </FormGroup>
      <FormGroup
        proposalCard
        eligibilityPage
        className="otherInsuranceInputBox"
      >
        <TextInputElg
          proposal
          type="text"
          maxLength="10"
          name={id}
          id={id}
          onInput={numOnly}
          onKeyDown={noCopyAndPaste}
          autoComplete="off"
          placeholder=" "
          onChange={evt => {
            setSumAssuredtext(evt.target.value);
          }}
          value={sumAssuredText}
          onBlur={evt => {
            if (insuranceCompanyText === "Select Insurance Company") {
              setCompanyError("Please select Insurance Comapny");
              onError(true);
            } else if (
              !(evt.target.value >= 500000 && evt.target.value <= 1000000000)
            ) {
              setSumAssuredError(
                "Sum Assured Amount must be between 5 Lakhs and 100 Crore",
              );
              onError(true);
            } else {
              setCompanyError("");
              setSumAssuredError("");
              onError(false);
              dispatch(
                updateOtherInsuranceList({
                  id,
                  sumAssured: evt.target.value,
                  insuranceCompany: insuranceCompanyText,
                }),
              );
            }
          }}
        />
        <Label md htmlFor={id} style={{ pointerEvents: "none" }}>
          Sum Assured
        </Label>
        {sumAssuredError && <p className="error">{sumAssuredError}</p>}
      </FormGroup>

      <FormGroup>
        <Button
          type="submit"
          className="btn--red"
          onClick={() => {
            dispatch(removeFromOtherInsuranceList(id));
          }}
        >
          Remove
        </Button>
      </FormGroup>
    </div>
  );
};

export default OtherInsurance;
