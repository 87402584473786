import React from "react";
import "styled-components/macro";
import "./loader.scss";

const Loader = ({
  style = {},
  bounceStyle = {},
  children,
  message,
  postion,
}) => {
  return (
    <div className="loader" style={style}>
      {message && <div>{message}</div>}
      <div
        className="spinner"
        css={`
          transform: translate(
            -50%,
            ${postion === "smallBox" ? "-60%" : "-50%"}
          );
          left: 50%;
          top: 50;
          width: max-content;
          height: auto;
          margin: 0;
          @media (max-width: 820px) {
            height: 7px;
          }
          @media (max-width: 420px) {
            height: 9px;
          }
        `}
      >
        <div className="bounce1" style={bounceStyle}></div>
        <div className="bounce2" style={bounceStyle}></div>
        <div className="bounce3" style={bounceStyle}></div>
        <p
          css={`
            width: 100%;
          `}
        >
          {children}
        </p>
      </div>
    </div>
  );
};

export default Loader;
