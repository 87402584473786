import { createSlice } from "@reduxjs/toolkit";
import { singleQuote } from "../../serviceApi";
import SecureLS from "secure-ls";
const ls = new SecureLS();
const bajajSingleQuoteSlice = createSlice({
  name: "bajajSingleQuote",
  initialState: {
    bajajSingleQuote: false,
    bajajPlanCard: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.bajajSingleQuote = action.payload;
    },
    saveBajajPlanDetails: (state, action) => {
      state.bajajPlanCard = action.payload;
    },
  },
});

export const { saveDetails, saveBajajPlanDetails } =
  bajajSingleQuoteSlice.actions;

export const saveBajajSingleQuoteDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveDetails(data.singleProductData.requestData.data));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export const saveBajajPlanCardDetails = data => {
  return async dispatch => {
    try {
      const { data } = await singleQuote({ traceId: sessionStorage.getItem("traceId") });
      dispatch(saveBajajPlanDetails(data.singleProductData));
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default bajajSingleQuoteSlice.reducer;
