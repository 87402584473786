import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IcicProposalPage from "./IcicProposalPage/IcicProposalPage";
import { useHistory, useLocation } from "react-router-dom";
// import HdfcProposalPage from "./Hdfcproposalpage/HdfcProposalPage";
import MaxLifeProposalPage from "./MaxLifeProposalPage/MaxLifeProposalPage";
import KotakProposalPage from "./KotakLifeProposalPage/kotakProposalPage";
import Loader from "../../components/Loader/Loader";
import { Error } from "../../components";
import { getAllData } from "../../api/getAllData";
import BajajProposalPage from "./BajaProposalPage/BajajProposalPage";
import {
  useCustomLocation,
  useDocumentTitle,
  useUrlQuery,
} from "../../custom-hooks";
import {
  saveBiData,
  saveResponse,
} from "../EligibilityPage/eligibilityCard.slice";
import { createRegionData } from "../GreetingPage/greeting.slice";
import { saveSelectedPlanForm } from "./IcicProposalPage/SelectedPlanForm/SelectedPlanForm.slice";
import { useNavigation } from "../../custom-hooks/useNavigation";
import { setIsLoading } from "./proposalPage.slice";

const getProposalPage = companyAlias => {
  switch (companyAlias) {
    case "icici_pru":
      return <IcicProposalPage />;
    // case "hdfc_life":
    //   return <HdfcProposalPage />;
    case "kotak_life":
      return <KotakProposalPage />;
    case "max_life":
      return <MaxLifeProposalPage />;
    case "bajaj_life":
      return <BajajProposalPage />;
    default:
      return <div>Requested Page Not Found</div>;
  }
};
const ProposalPage = () => {
  useDocumentTitle("Proposer Details");
  useNavigation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const history = useHistory();

  const { isError } = useSelector(state => state.quote);

  const { isLoading } = useSelector(({ proposalPage }) => proposalPage);
  const { gotoPage, redirectTo } = useCustomLocation();
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const { gender } = useSelector(({ greeting }) => greeting.customerDetails);

  const { eligibilityDetails } = useSelector(
    ({ eligibilityCard }) => eligibilityCard,
  );
  const enquiryId = useUrlQuery("enquiryId");
  useEffect(() => {
    dispatch(saveBiData(false));
    dispatch(createRegionData(false));
    dispatch(saveResponse(false));
  }, []);

  const education = eligibilityDetails?.selectedEducation;
  const occupation = eligibilityDetails?.selectedOccupation;

  useEffect(() => {
    if (
      company_alias === "icici_pru" &&
      gender === "F" &&
      occupation === "Housewife" &&
      ["Graduate", "Post Graduate"].includes(education)
    ) {
      dispatch(saveSelectedPlanForm("dependent"));
    } else {
      dispatch(saveSelectedPlanForm("self"));
    }
  }, [company_alias, education, gender, occupation]);
  const { corporateJourney } = useSelector(state => state.quote);

  let temp = corporateJourney?.is_gameskraft;
  useEffect(() => {
    dispatch(getAllData(history, pathname, enquiryId)).then(data => {
      dispatch(setIsLoading(true));
      if (data?.last_visited?.otp === "true") {
        gotoPage(redirectTo.pages.documentuploadpage);
      } else if (data?.last_visited?.proposal === "true") {
        gotoPage(redirectTo.pages.otpverification);
      } else if (
        temp == "active" &&
        data?.company_alias === "bajaj_life" &&
        data?.receiptUrl == null
      ) {
      } else if (
        data?.payment_status !== "success" &&
        !data?.app_no &&
        data?.company_alias === "icici_pru"
      ) {
        gotoPage(redirectTo.pages.eligibilitypage);
      } else if (
        data?.payment_status !== "success" &&
        data?.app_no &&
        data?.company_alias === "icici_pru"
      ) {
        gotoPage(redirectTo.pages.transactionunsuccessful);
      } else if (
        data?.company_alias === "bajaj_life" &&
        !data?.receipt_Url &&
        data?.last_visited?.save_logs === "true"
      ) {
        gotoPage(redirectTo.pages.transactionsuccessful);
      } else if (
        data?.company_alias === "bajaj_life" &&
        !data?.receipt_Url &&
        data?.app_no &&
        data?.last_visited?.save_logs !== "true"
      ) {
        gotoPage(redirectTo.pages.transactionunsuccessful);
      } else if (
        data?.company_alias === "bajaj_life" &&
        !data?.app_no &&
        data?.last_visited?.save_logs !== "true"
      ) {
        gotoPage(redirectTo.pages.eligibilitypage);
      }
      dispatch(setIsLoading(false));
    });
  }, []);

  if (isLoading) return <Loader />;

  if (isError) return <Error errorMessage={isError} />;
  // else if (!regionDetails) return <Loader />;

  return getProposalPage(company_alias);
};

export default props => {
  const { isDocumentUploaded, isProposalSubmitted } = useNavigation();
  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  if (company_alias !== "icici_pru")
    if (isDocumentUploaded || isProposalSubmitted) return <Loader />;
  return <ProposalPage {...props} />;
};
