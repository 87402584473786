import { getBankDetails } from "../../serviceApi";

export const bankAccountDetailsData = data => [
  {
    id: "renewalDeposit",
    alignLeft: true,
    size: "md",
    type: "select",
    value: data?.renewalDepositText || "Select",
    items: [
      "Online Mandate(BOB,KOTAK,PNB & OTHERS)",
      "Credit card standing instruction",
      "Online Mandate(AXIS,HDFC,ICICI & SBI)",
    ],
    label: "Renewal Deposit",
    validations: {
      required: "Renewal Deposit is required",
    },
    setOtherItemValueOnChange() {
      return [
        {
          ref: "branchName",
          value: "",
        },
        {
          ref: "bankName",
          value: "",
        },
        {
          ref: "micrCode",
          value: "",
        },
        {
          ref: "ifscCode",
          value: "",
        },
        {
          ref: "accountType",
          value: "Select",
        },
        {
          ref: "accountNo",
          value: "",
        },
      ];
    },
  },
  {
    id: "ifscCode",
    alignLeft: true,
    size: "md",
    type: "text",
    label: "IFSC Code",
    uppercase: true,
    value: data?.ifscCode || "",
    validations: {
      //required: "This flied is required",
      required: "IFSC Code is required",
      maxLength: 11,
      minLength: 11,
      invalid: "IFSC code must be 11 characters long",
      custom: [
        {
          pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
          message: "Enter valid IFSC code",
        },
      ],
    },
    setOtherItemValueOnChangeAsync: async value => {
      try {
        console.log(value);
        if (value.length === 11) {
          const { data } = await getBankDetails({ bankIfsc: value });
          if (data.status)
            return [
              {
                ref: "branchName",
                value: data.bankBranch || data.branchName,
              },
              {
                ref: "bankName",
                value: data.bankName || data.bankName,
              },
              {
                ref: "micrCode",
                value: data.bankMicr || data.micrCode,
              },
            ];
          alert(data.errors.bankIfsc[0]);
          return [];
        }
        return [];
      } catch (error) {
        alert("Something went wrong while getting bank details");
        console.error(error);
        return "api error";
      }
    },
  },
  {
    id: "bankName",
    alignLeft: true,
    size: "md",
    type: "text",
    value: data?.bankName || "",
    label: "Bank Name",

    readOnly: true,
  },
  {
    id: "branchName",
    alignLeft: true,
    value: data?.branchName || "",
    size: "md",
    type: "text",
    label: "Branch Name",
    readOnly: true,
  },
  {
    id: "micrCode",
    alignLeft: true,
    size: "md",
    type: "text",
    value: data?.micrCode || "",
    label: "MICR Code",
    readOnly: true,
  },
  {
    id: "accountType",
    alignLeft: true,
    size: "md",
    type: "select",
    value: data?.accountType || "Select",
    items: ["Saving", "Current"],
    label: "Account Type",
    validations: {
      //required: "This flied is required",
      required: "Account Type is required",
    },
  },
  {
    id: "accountNo",
    alignLeft: true,
    size: "md",
    type: "text",
    label: "Account No.",
    uppercase: true,
    value: data?.accountNo || "",
    validations: {
      onlyAlphaNoSpace: true,
      minLength: 9,
      maxLength: 18,
      //required: "This flied is required",
      required: "Account No is required",
      invalid: "Invalid account number",
    },
  },
];
