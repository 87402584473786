import { createSlice } from "@reduxjs/toolkit";
import { appendSendData, iciciProposalForms } from "../IcicProposal.slice";

const medicalDetails3 = createSlice({
  name: "medicalDetails",
  initialState: {
    medicalDetailsThree: false,
  },

  reducers: {
    saveDetails: (state, action) => {
      state.medicalDetailsThree = action.payload;
    },
  },
});

export const { saveDetails } = medicalDetails3.actions;

export const medicalDetails3SendData = (data = {}) => {
  console.log({ data });
  return {
    gynecologicalDiseaseText: data.gynecologicalComplicationsText,
    physicalDisabiltytext: data.disabilityText,
    durationWeeksNo: data.pregnentDuration,
    selfAccidentText: data.injuryText,
    selfAccident: data.injury ? "Y" : "N",
    physicalDisabilty: data.disability ? "Y" : "N",
    selfPregnant: data.pregnent ? "Y" : "N",
    gynecologicalDisease: data.gynecologicalComplications ? "Y" : "N",
    covidTwo: data.covidIsolateWithSymptom ? "Y" : "N",
    covidOne: data.covid ? "Y" : "N",
    covidThree: data.covidIsolate ? "Y" : "N",
    covidFour: data.covidSymptom ? "Y" : "N",
    covidFive: data.cOccupation ? "Y" : "N",
    covidOneDescription: data.covidOneDescription,
    covidTwoDescription: data.covidTwoDescription,
    covidThreeDescription: data.covidThreeDescription,
    covidFourDescription: data.covidFourDescription,
    covidFiveDescription: data.covidFiveDescription,
    covidSix: data.covidSix ? "Y" : "N",
    covidSixSubOne: data.covidSixSubone ? "Y" : "N",
    covidSixSubOneA: data.covidSixSuboneA,
    covidSixSubOneB: data.covidSixSuboneB,
    covidSixSubOneC: data.covidSixSuboneC ? "Y" : "N",
    covidSeven: data.covidSeven ? "Y" : "N",
    covidEight: data.covidEight ? "Y" : "N",
    covidNine: data.covidNine ? "Y" : "N",
    covidTen: data.covidTen ? "Y" : "N",
    covidTenSubOne: data.covidTenSubOne,
    covidTenSubThree: data.covidTenSubThree,
    covidTenSubTwo: data.covidTenSubTwo,
    covidEleven: data.covidEleven ? "Y" : "N",
    covidTwelve: data.covidTwelve ? "Y" : "N",
    covidTwelveSubOne: data.covidTwelveSubone ? "Y" : "N",
    covidTwelveSubOneText: data.covidTwelveSuboneText,
    currentlyInvestigated: data.currentlyInvestigated ? "Yes" : "No",
    investigationDtls: data.investigationDtls,
    proposalJourneyStatus: iciciProposalForms.medicalQuestion3,
  };
};

export const saveMedicalDetails3 = data => {
  return async dispatch => {
    try {
      if (data) {
        dispatch(saveDetails(data));
        dispatch(appendSendData(medicalDetails3SendData(data)));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };
};

export default medicalDetails3.reducer;
