import React from "react";
import { useSelector } from "react-redux";

import "./filter-menu-wrap.scss";

function FilterMenuWrap({ ...props }) {
  const { isQuotesLoading, quotesOnCompare } = useSelector(
    ({ quote }) => quote,
  );

  return (
    <div
      className="filter-menu-wrap"
      style={{
        cursor: isQuotesLoading || quotesOnCompare ? "default" : "default",
      }}
    >
      {props.children}
    </div>
  );
}

export default FilterMenuWrap;
