import React, { useRef, useEffect, useState, useCallback } from "react";
import DOMPurify from "dompurify";
import CriticalPopup from "../../Popup/CriticalPopup";
import {
  getCriticalIllness,
  // getSeriousIllness,
} from "../../../modules/RiderPage/RiderPage.slice";
import { useDispatch } from "react-redux";
export default function KnowYourPlan({
  inbuiltCoverages,
  additionalCoverages,
  additionalFeatures,
  quote,
}) {
  const questions = useRef("");
  const nav = useRef("");
  const [answer, setAnswer] = useState([]);
  const dispatch = useDispatch();
  const assignRef = useCallback(ref => {
    if (ref) setAnswer(prev => [...prev, ref]);
  }, []);
  useEffect(() => {
    answer.forEach(item => {
      if (item && item.innerText.includes("critical")) {
        // item.addEventListener("click", () =>
        // 	dispatch(getCriticalIllness({ companyAlias: quote.company_alias }))
        // );
        dispatch(getCriticalIllness({ companyAlias: quote.company_alias }));
      }
      if (item && item.innerText.includes("serious")) {
        // item.addEventListener("click", () =>
        // 	dispatch(getSeriousIllness({ companyAlias: quote.company_alias }))
        // );
        dispatch(getCriticalIllness({ companyAlias: quote.company_alias }));
      }
    });
  }, [answer, quote, dispatch]);
  const show = (e, question) => {
    for (let i = 0; i < questions.current.children.length; i++) {
      questions.current.children[i].style.display =
        question - 1 !== i ? "none" : "block";
    }
    for (let i = 0; i < nav.current.children.length; i++) {
      i !== e
        ? nav.current.children[i].classList.remove("active")
        : nav.current.children[i].classList.add("active");
    }
  };

  const stringToHTML = str => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return {
      __html: DOMPurify.sanitize(doc.body.innerHTML, { ADD_ATTR: ["target"] }),
    };
  };
  return (
    <div>
      <CriticalPopup />
      <div id="kyp" className="tab-pane">
        <div className="kypMenuWrap">
          <ul className="nav nav-tabs" ref={nav}>
            {inbuiltCoverages?.length && (
              <div className="kypMenuDivider">Inbuilt Coverages</div>
            )}
            {inbuiltCoverages?.map(({ plan_title }, idx) => (
              <li
                key={idx}
                className={idx === 0 ? "active" : ""}
                onClick={() => {
                  show(idx + 1, String(idx + 1));
                }}
              >
                {plan_title}
              </li>
            ))}

            {additionalCoverages?.length && (
              <div className="kypMenuDivider">Additional Coverages</div>
            )}
            {additionalCoverages?.map(({ plan_title }, idx) => (
              <li
                key={idx}
                onClick={() => {
                  show(
                    inbuiltCoverages.length + idx + 2,
                    String(inbuiltCoverages.length + idx + 1),
                  );
                }}
              >
                {plan_title}
              </li>
            ))}
            {additionalFeatures?.length && (
              <div className="kypMenuDivider">Additional Features</div>
            )}
            {additionalFeatures?.map(({ plan_title }, idx) => (
              <li
                key={idx}
                onClick={() => {
                  show(
                    (additionalCoverages?.length || -1) +
                      inbuiltCoverages.length +
                      idx +
                      3,
                    String(
                      (additionalCoverages?.length || 0) +
                        inbuiltCoverages.length +
                        idx +
                        1,
                    ),
                  );
                }}
              >
                {plan_title}
              </li>
            ))}
          </ul>
        </div>

        <div className="kypContentWrap mt-2">
          <div className="tab-content" ref={questions}>
            {Object.keys(quote.plan_details).map(plan =>
              quote.plan_details[plan].map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={`tab-pane ${
                      idx === 0 && plan === "InbuiltCoverages" ? "active" : ""
                    }`}
                    id="db"
                  >
                    <div className="tabHead">
                      {" "}
                      {item.plan_title}
                      {"    "}
                      {quote?.rider_brochure &&
                      quote?.rider_brochure[item.plan_title] ? (
                        <>
                          &nbsp;&nbsp;&nbsp;{" "}
                          <a
                            className="download__brochure"
                            rel="noopener noreferrer"
                            target="_blank"
                            download
                            href={
                              quote?.rider_brochure[item.plan_title] ||
                              "https:elephant.in"
                            }
                          >
                            Download Rider Brochure
                          </a>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    <div
                      className="tabAnswer"
                      ref={assignRef}
                      id="db"
                      dangerouslySetInnerHTML={stringToHTML(item?.plan_desc)}
                    ></div>
                    {item.plan_title ===
                      "Income Benefit On Accidental Disability" ||
                    item.plan_title === "Cancer Cover" ? (
                      <></>
                    ) : (
                      <p
                        className="p_tag"
                        style={{
                          fontSize: "8px",
                          position: "absolute",
                          bottom: "50px",
                        }}
                      >
                        Please refer/read, policy/ rider brochure carefully for
                        more details on policy terms, conditions, exclusions,
                        limitations
                      </p>
                    )}
                  </div>
                );
              }),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
