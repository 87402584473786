/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
// import DOMPurify from "dompurify";
import downloadIcon from "../../assets/img/download.svg";
import HdfcLogo from "../../assets/img/hdfcLogo.png";
import Button from "./../Button/Button";
import tick from "../../assets/img/tick.svg";
import blueClose from "../../assets/img/closeButtonBlue.svg";
// import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import "./PlanCard.scss";
// import { Tooltip } from "./../Tooltip/Tooltip";
import tooltip from "../../assets/img/transparent-tooltip.svg";
import "styled-components/macro";
import Knowmore from "../KnowMorePopup/knowmore";
import { prodName } from "./productName";
import arrowSelect from "../../assets/img/arrow_selectBox.png";
import {
  getPayPremiumMode,
  getPayPremiumModeRider,
  getPayPremiumModeMobileRider,
  getPayPremiumModeTabRider,
} from "../../modules/QuotePage/helper.js";
import { useLocation } from "react-router-dom";
import CustomTooltip from "./../Tooltip/CustomTooltip";
import { useDisplayNomineeGets } from "../../custom-hooks";
import { setInbuiltCovers } from "../../modules/RiderPage/RiderPage.slice";
import { numberToDigitWord } from "../../modules/QuoteFilter/helper";
import { selectSelectedQuote } from "../../modules/QuotePage/quotePageSlice.js";
const PlanCard = props => {
  const arrowRef = useRef();
  // const mobRef = useRef();
  // const tabRef = useRef();
  const [reLoaded, setReLoaded] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [cardOpen, setCardOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  // const { isSalaried } = useSelector(state => state.eligibilityCard);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  const selectedQuoteToBuy = useSelector(selectSelectedQuote);
  const {
    brochureUrl,
    companyLogo,
    basePremiumAmount,
    coverUpto,
    companyName,
    masterProductName,
    // childProductName,
  } = props.selectedQuote ? props.selectedQuote : "";
  const { payPremium } = useSelector(
    ({ quoteFilter }) => quoteFilter.subFilter,
  );

  useEffect(() => {
    dispatch(setInbuiltCovers(selectedQuote.inbuilt_riders));
  }, [selectedQuote]);
  const {
    brochure,
    company_logo,
    premium_amount,
    premium_amount_gst,
    company_name,
    master_product_name,
    // child_product_name,
  } = props.selectedQuote ? props.selectedQuote : "";
  const location = useLocation();

  const { filter } = useSelector(state => state.quoteFilter);
  const [showKnowMorePopup, setShowKnowMorePopup] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState(
    getPayPremiumModeRider(
      filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );

  // const { paymentTerm } = useSelector(({ quoteFilter }) => quoteFilter.filter);
  // const isSinglePay = paymentTerm.option === "Single Pay";

  const discountedPremium = base => {
    if (base) {
      return Number(premium_amount - premium_amount_gst || basePremiumAmount);
    } else {
      return Number(premium_amount || basePremiumAmount);
    }
  };

  const discountedGst = () => {
    return premium_amount_gst || basePremiumAmount;
  };

  useEffect(() => {
    setModeOfPayment(
      getPayPremiumModeRider(
        filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
      ),
    );
  }, [filter, payPremium]);
  const [modeOfPaymentPopup, setModeOfPaymentPopup] = useState(
    getPayPremiumMode(
      filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
    ),
  );
  useEffect(() => {
    setModeOfPaymentPopup(
      getPayPremiumMode(
        filter.paymentTerm.option === "Single Pay" ? "none" : payPremium,
      ),
    );
  }, [payPremium]);
  const { displayNomineeGets } = useDisplayNomineeGets();

  let mobileTerm = (
    <div
      className="mobileTerm"
      css={`
        @media only screen and (min-width: 768px) and (max-width: 1177px) {
          width: 100%;
        }
      `}
    >
      <div className="row">
        <div
          className="col-6 tabletPlancard-title"
          css={`
            @media only screen and (min-width: 768px) and (max-width: 1177px) {
              max-width: 260px;
            }
          `}
        >
          <h4 className="mb-1 ">
            <b className="formLeftPlanName">{company_name || companyName}</b>
          </h4>
          <h4>
            {`${masterProductName || master_product_name} (${prodName(
              selectedQuote?.company_alias,
              selectedQuote?.child_product_name,
              props.addOnsList,
              selectedQuoteToBuy,
            )})`}
          </h4>

          <div className={cardOpen ? "tabKnowMore" : "tabKnowMoreHide"}>
            <div
              className="plan-card__know-more "
              style={{ fontSize: "12px" }}
              onClick={() => {
                setShowKnowMorePopup(true);
              }}
            >
              Know more
            </div>
            <Knowmore
              onClose={setShowKnowMorePopup}
              show={showKnowMorePopup && width < 1177}
              price={`₹ ${discountedPremium().toLocaleString(
                "en-IN",
              )}${modeOfPaymentPopup}`}
              ls={props.ls}
              logoText={master_product_name}
              coverUpto={
                selectedQuote?.differentTenureCU
                  ? selectedQuote?.differentTenureCU + " years"
                  : filter?.coverUpto || coverUpto
              }
              policyURL={selectedQuote.brochure}
              nomineeGets="₹50 LAKHS (LUMPSUM)"
              logoUrl={company_logo || props.selectedQuote?.companyLogo}
              history={props.history}
              buynow={props.buyNowBtn}
              quote={selectedQuote || props.selectedQuote}
              nextPage={props.nextPage}
              childName={`(${prodName(
                selectedQuote?.company_alias,
                selectedQuote?.child_product_name,
                props.addOnsList,
                selectedQuoteToBuy,
              )})`}
            />
          </div>
        </div>

        <div className="col-2 coverTerm tabletUi">
          <h5 className="mb-1 paddTab">
            <b className="formLeftPlanNameCaptions">COVER UPTO</b>
          </h5>
          <span className="d-block ct-period tabCoverNom">
            {(selectedQuote.differentTenureCU &&
              `${selectedQuote.differentTenureCU} years`) ||
              filter.coverUpto ||
              coverUpto}
          </span>
        </div>

        <div
          className="col-4 tabletUi nomTablet"
          css={`
            @media only screen and (min-width: 768px) and (max-width: 1177px) {
              max-width: 180px;
              margin-left: 30px;
            }
          `}
        >
          <h5 className="mb-1 paddTab">
            <b className="formLeftPlanNameCaptions">
              {filter?.morePlans === "Survival Benefits"
                ? "Life Assured Gets"
                : "NOMINEE GETS"}
            </b>
          </h5>
          <span className="d-block ct-period tabCoverNom">
            {/* {selectedQuote.company_alias === "tata_aia_life" ? (
              <>
                <div>{getValue(selectedQuote.tata_basic_sum_assured)} + </div>
                <div>
                  {getValue(selectedQuote.tata_rider_sum_assured)} (CPR benefit
                  amount)
                </div>
              </>
            ) : (
              displayNomineeGets
            )} */}
            {selectedQuote.company_alias === "bandhan_life"
              ? numberToDigitWord(parseInt(selectedQuote?.sumAssured)) +
                " (Lumpsum)"
              : displayNomineeGets}
          </span>
        </div>
      </div>
    </div>
  );

  const seeMore = e => {
    let eve = e.target.innerText === "See more";
    setCardOpen(eve);
    e.target.innerText = eve ? "See less" : "See more";
    arrowRef.current.className = eve ? "fa-arrow-down open" : "fa-arrow-down";
    //	mobRef.current.style.display = !eve ? "block" : "none";
    setReLoaded(true);
  };

  useEffect(() => {
    setSelectedQuote(props.selectedQuote);
  }, [props.selectedQuote]);

  const PriceDetail = ({ text, amount }) => (
    <div class="formLeftPreTxt">
      <span>{text} </span>
      <span class="formLeftPreAmt">{amount}</span>
    </div>
  );

  return (
    <>
      <div className="card-container planCardWidth">
        <div
          className="card-content plan-card-container"
          css={`
            @media only screen and (min-width: 768px) and (max-width: 1177px) {
              padding: 24px 16px !important;
            }
            @media (max-width: 767px) {
              padding: 18px 15px !important;
            }
          `}
        >
          <div className="mobHeaderRider">
            <div className="card-title">
              <div className="d-flex logoHead">
                <div>
                  <img
                    className={`plan-card-logo ${selectedQuote.company_alias} `}
                    src={company_logo || companyLogo || HdfcLogo}
                    alt="no"
                  />
                </div>
                <div className="ml-3 plancard-title nonTabletUi">
                  <span className="m-0 companyName">
                    {company_name || companyName}
                  </span>
                  <div className="subPlanName">{`${
                    masterProductName || master_product_name
                  } (${prodName(
                    selectedQuote?.company_alias,
                    selectedQuote?.child_product_name, // "",
                    props.addOnsList,
                    selectedQuoteToBuy,
                  )})`}</div>

                  <div
                    className="plan-card__know-more"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      setShowKnowMorePopup(true);
                    }}
                  >
                    Know more
                  </div>
                  <Knowmore
                    onClose={setShowKnowMorePopup}
                    show={showKnowMorePopup && width > 1177}
                    price={`₹ ${discountedPremium().toLocaleString(
                      "en-IN",
                    )}${modeOfPaymentPopup}`}
                    ls={props.ls}
                    policyURL={selectedQuote.brochure}
                    logoText={master_product_name}
                    childName={`(${prodName(
                      selectedQuote?.company_alias,
                      selectedQuote?.child_product_name,
                      props.addOnsList,
                      selectedQuoteToBuy,
                    )})`}
                    coverUpto={
                      selectedQuote?.differentTenureCU
                        ? selectedQuote?.differentTenureCU + " years"
                        : filter?.coverUpto || coverUpto
                    }
                    nomineeGets="₹50 LAKHS (LUMPSUM)"
                    logoUrl={company_logo || props.selectedQuote?.companyLogo}
                    history={props.history}
                    buynow={props.buyNowBtn}
                    quote={selectedQuote || props.selectedQuote}
                    nextPage={props.nextPage}
                  />
                </div>
                {mobileTerm}
              </div>
            </div>
            <div
              className={
                cardOpen ? "hideAmountCard" : "showAmountCard amountCardMobile"
              }
            >
              <div className="ct-period mobUiAmount mobileCt-period padl">
                ₹{" "}
                {(
                  discountedPremium() + Number(props.additionalRiders || 0)
                ).toLocaleString("en-IN")}
                <span>{getPayPremiumModeTabRider(modeOfPayment)}</span>
              </div>
            </div>
            <div
              className={
                !cardOpen
                  ? "hideAmountCard"
                  : "showAmountCard margMob mobCoverOpen"
              }
            >
              <div className="col-5 mobCover">
                <h5 className="mb-2 mobCoverUpto">
                  <b>Cover Upto</b>
                </h5>
                <span className="d-block ct-period ">
                  {(selectedQuote.differentTenureCU &&
                    `${selectedQuote.differentTenureCU} years`) ||
                    filter.coverUpto ||
                    coverUpto}
                </span>
              </div>
            </div>
          </div>
          <div className="ct-period tabletUi mobileCt-period padl tabPremAmtPayyable">
            <div
              className={
                cardOpen
                  ? "tabPremPayyableHide"
                  : `tabPremPayyable ${selectedQuote.company_alias}`
              }
            >
              <h5 className="mb-1 riderPremTab">
                <b className="tabPremPay">Total Premium Payable</b>
              </h5>
              <div className="formLeftPlanSubName">
                ₹{" "}
                {(
                  discountedPremium() + Number(props.additionalRiders || 0)
                ).toLocaleString("en-IN")}
                <span>{getPayPremiumModeTabRider(modeOfPayment)}</span>
              </div>
            </div>
          </div>
          <div id="planCardDetails" className={`${!reLoaded ? "mobCard" : ""}`}>
            <div className="card-body">
              <div className="row nonTabletUi secHead">
                <div className="col-5 pcCover">
                  <h5 className="mb-2 formleftTermHead">
                    <b>COVER UPTO</b>
                  </h5>
                  <span className="d-block ct-period">
                    {(selectedQuote.differentTenureCU &&
                      `${selectedQuote.differentTenureCU} years`) ||
                      filter.coverUpto ||
                      coverUpto}
                  </span>
                </div>
                <div
                  className={`col-7 nomMobUi ${selectedQuote?.company_alias}`}
                  css={`
                    @media (max-width: 767px) {
                      min-width: 100%;
                      margin-top: 0;
                    }
                  `}
                >
                  <h5 className="mb-2 formleftTermHead">
                    <b className="pcNomGets">
                      {filter?.morePlans === "Survival Benefits"
                        ? "Life Assured Gets"
                        : "NOMINEE GETS"}
                    </b>
                    <b className="mobNomGets">
                      {filter?.morePlans === "Survival Benefits"
                        ? "Life Assured Gets"
                        : "Nominee Gets"}
                    </b>
                  </h5>
                  <span className="d-block ct-period ">
                    {/* {selectedQuote.company_alias === "tata_aia_life" ? (
                      <>
                        <div>
                          {getValue(selectedQuote.tata_basic_sum_assured)} +{" "}
                        </div>
                        <div>
                          {getValue(selectedQuote.tata_rider_sum_assured)} (CPR
                          benefit amount)
                        </div>
                      </>
                    ) : (
                      displayNomineeGets
                    )} */}
                    {selectedQuote.company_alias === "bandhan_life"
                      ? numberToDigitWord(parseInt(selectedQuote?.sumAssured)) +
                        " (Lumpsum)"
                      : displayNomineeGets}
                  </span>
                </div>
              </div>
            </div>
            <span
              className="show-collapse"
              css={`
                @media only screen and (min-width: 768px) and (max-width: 1177px) {
                  position: absolute;
                  right: 18px;
                  margin-top: 0;
                  margin-right: 0;
                  top: 68px;
                }
              `}
            >
              <button
                data-toggle="collapse"
                data-target="#planCardDetails"
                onClick={e => {
                  seeMore(e);
                }}
                className="closeBtnTab"
              >
                <img
                  src={blueClose}
                  alt="close"
                  className={cardOpen ? "closeBtnBlue" : "closeHide"}
                />
              </button>
            </span>
            <h3 className="mt-4 formLeftPreHead">
              <b>Premium</b>
            </h3>
            <hr className="mt-1 hrMob" />
            <div className="row price__detail__mob">
              <PriceDetail
                text="Base Plan Premium"
                amount={`₹${discountedPremium(true).toLocaleString("en-IN")}`}
              />

              {props.addOnsList?.length
                ? props.addOnsList?.map((item, index) => (
                    <PriceDetail
                      text={item.rider_name || item.riderName}
                      amount={`₹${Number(
                        item.premium - item.gst ||
                          item.ridersPremiumAmount - item.gst ||
                          0,
                      ).toLocaleString("en-IN")}`}
                    />
                  ))
                : ""}

              <PriceDetail
                text="GST"
                amount={`₹${(
                  Number(discountedGst()) + Number(props.additionalGst)
                ).toLocaleString("en-IN")}`}
              />
            </div>
            <div
              className="pcPremAmt d-flex flex-column pay-amt "
              style={{ fontSize: "16px" }}
            >
              <span className="totalPremium">TOTAL PREMIUM PAYABLE</span>
              <span className="priceOfPremium">
                ₹{" "}
                {(
                  discountedPremium() + Number(props.additionalRiders || 0)
                ).toLocaleString("en-IN")}
                <span>{modeOfPayment}</span>
              </span>
            </div>
            <div
              className="mobPremAmt d-flex flex-column pay-amt "
              style={{ fontSize: "16px" }}
            >
              <div style={{ display: "flex" }}>
                <span className="totalPremium">TOTAL PREMIUM</span>
                <span className="priceOfPremium">
                  ₹{" "}
                  {(
                    discountedPremium() + Number(props.additionalRiders || 0)
                  ).toLocaleString("en-IN")}
                </span>
              </div>
              <span className="modeMobile">
                {getPayPremiumModeMobileRider(modeOfPayment)}
              </span>
            </div>
            <div
              className="tabPremAmt d-flex flex-column pay-amt "
              style={{ fontSize: "16px" }}
            >
              <div style={{ display: "flex" }}>
                <span className="totalPremium">TOTAL PREMIUM PAYABLE</span>
                <span className="premAmtTab">
                  ₹{" "}
                  {(
                    discountedPremium() + Number(props.additionalRiders || 0)
                  ).toLocaleString("en-IN")}
                  <span>{modeOfPayment}</span>
                </span>
              </div>
            </div>
            <div className="mt-4 inbuiltCover sideHeads">
              Inbuilt Cover{" "}
              <CustomTooltip
                rider="true"
                id="RiderInbuilt__Tooltip"
                place={width < 768 ? "top" : "right"}
                customClassName="mt-3 riderPageTooltip "
                Position={{ top: width < 768 ? 25 : 15, right: 5 }}
              >
                <img
                  data-tip="<h3 >Inbuilt Cover</h3> <div>These are the benefits freely added </br> under the plan</div>"
                  data-html={true}
                  data-for="RiderInbuilt__Tooltip"
                  src={tooltip}
                  alt="tooltip"
                  className="toolTipRiderChild"
                />
              </CustomTooltip>
            </div>

            <hr className="hrMob" />
            <div
              style={{ display: "flex", flexDirection: "column" }}
              css={`
                @media (max-width: 767px) {
                  line-height: 16px;
                  & .tickUi {
                    top: 2px;
                  }
                }
              `}
            >
              {props?.inbuiltCovers?.length ? (
                props.inbuiltCovers.map((item, i) => {
                  return (
                    <div
                      //	className="coverItems"
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      css={`
                        @media (max-width: 767px) {
                          margin-bottom: 8px;
                        }
                      `}
                    >
                      <div>
                        {" "}
                        <span className="ct-period">
                          {item.rider_name}{" "}
                          <CustomTooltip
                            rider="true"
                            id="RiderInbuiltItems__Tooltip"
                            place={width < 768 ? "top" : "right"}
                            customClassName="mt-3 riderPageTooltip "
                            Position={{ top: width < 768 ? 25 : 10, right: 5 }}
                          >
                            <img
                              data-tip={`<h3>${item.rider_name}</h3><div>${
                                item.rider_description || item.riderDescription
                              }</div>`}
                              data-html={true}
                              data-for="RiderInbuiltItems__Tooltip"
                              src={tooltip}
                              alt="tooltip"
                              className="toolTipRiderChild"
                            />
                          </CustomTooltip>
                        </span>
                      </div>
                      <span>
                        <img className="tickUi" src={tick} alt="tick" />
                      </span>
                    </div>
                  );
                })
              ) : (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="noPlanSelect"> No Inbuilt Covers </span>
                  </div>
                </>
              )}
            </div>

            {props.selectedQuote?.company_alias === "bandhan_life" ||
            props.selectedQuote?.company_alias === "tata_aia_life" ? (
              <div
                className="addOns sideHeads"
                css={`
                  @media (max-width: 767px) {
                    margin-top: 15px;
                  }
                `}
              ></div>
            ) : (
              <div
                className="addOns sideHeads"
                css={`
                  @media (max-width: 767px) {
                    margin-top: 15px;
                  }
                `}
              >
                Add-ons{" "}
                <CustomTooltip
                  rider="true"
                  id="RiderAddon__Tooltip"
                  place={width < 768 ? "top" : "right"}
                  customClassName="mt-3 riderPageTooltip"
                  Position={{ top: width < 768 ? 25 : 15, right: 5 }}
                >
                  <img
                    data-tip="<h3 >Add-ons</h3> <div>	These are some coverages offered </br>by the insurance companies at an </br> additional cost</div>"
                    data-html={true}
                    data-for="RiderAddon__Tooltip"
                    src={tooltip}
                    alt="tooltip"
                  />
                </CustomTooltip>
              </div>
            )}

            <hr className="hrMob" />
            {props.addOnsList?.length ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {props?.addOnsList?.map((item, i) => {
                  return (
                    <div
                      //		className="coverItems"
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      css={`
                        @media (max-width: 767px) {
                          line-height: 16px;
                          margin-bottom: 8px;
                        }
                      `}
                    >
                      <div className="addOnItems">
                        {" "}
                        <span
                          style={{
                            display: "inline-block",
                            justifyContent: "space-between",
                          }}
                          className="ct-period"
                        >
                          {item.rider_name || item.riderName}{" "}
                          <CustomTooltip
                            rider="true"
                            id="RiderAddonItems__Tooltip"
                            place={width < 768 ? "top" : "right"}
                            customClassName=" mt-3 riderPageTooltip "
                            Position={{ top: width < 768 ? 25 : 5, right: 5 }}
                          >
                            <img
                              data-tip={`<h3>${
                                item.rider_name || item.riderName
                              }</h3><div>${
                                item.rider_description || item.riderDescription
                              }</div>`}
                              data-html={true}
                              data-for="RiderAddonItems__Tooltip"
                              src={tooltip}
                              alt="tooltip"
                              className="toolTipRiderChild"
                            />
                          </CustomTooltip>
                        </span>
                      </div>
                      <span>
                        <img className="tickUi" src={tick} alt="tick" />
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {props.selectedQuote?.company_alias === "bandhan_life" ||
                props.selectedQuote?.company_alias === "tata_aia_life" ? (
                  <span className="noPlanSelect"></span>
                ) : (
                  <span className="noPlanSelect"> No add-ons selected </span>
                )}
              </div>
            )}
            <div className="m-4 d-flex justify-content-center proceed-button ">
              <div
                className={
                  location.pathname?.toLowerCase() === "/riderPage"
                    ? "hideBtnDwn"
                    : ""
                }
              >
                <a href={brochure || brochureUrl} download target="blank">
                  <Button className="downloadBroucher">
                    <span className="downloadBroucherText">
                      {" "}
                      Download Brochure
                    </span>
                    <img
                      src={downloadIcon}
                      alt="download icon"
                      className="ml-3 image downloadIcon"
                    />
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="seeMoreBtn">
            <span className="show-collapse see-less">
              <button
                data-toggle="collapse"
                data-target="#planCardDetails"
                onClick={e => {
                  seeMore(e);
                }}
              >
                {!cardOpen ? "See more" : "See less"}
              </button>
              <span>
                <img
                  src={arrowSelect}
                  ref={arrowRef}
                  className="fa-arrow-down seeMoreArrow"
                  alt="down-arrow"
                  width="15px"
                  style={{ marginLeft: "5px" }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanCard;
