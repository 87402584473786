import React, { useEffect, useRef, useState } from "react";
import {
  Containero,
  FormContainero,
  Avataro,
  ErrorMsg,
  CustomInput,
  ErrorNew,
} from "./otpStyle";
import { Col, Row } from "react-bootstrap";
import Button1 from "./Button1";

import laxmiImg from "../../assets/img/laxmiImg.png";
import { useLocation, useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { generateOTP, verfiyOTP, resendOTP, clear } from "./otp-slice";
import { getAllData } from "../../api/getAllData";
import { initLoader } from "../EligibilityPage/eligibilityCard.slice";
import Loader from "../../components/Loader/Loader";
import swal from "sweetalert";
import { updateQuotation } from "../EligibilityPage/serviceApi";
import { useNavigation } from "../../custom-hooks/useNavigation";

function check(nom) {
  return (
    nom?.length === 10 &&
    nom.match(/[6-9]{1}[0-9]{9}$/) &&
    Number(nom) !== 9999999999 &&
    Number(nom) !== 6666666666 &&
    Number(nom) !== 7777777777 &&
    Number(nom) !== 8888888888
  );
}
function errMesaage(nom) {
  if (!nom || nom?.length === 0) {
    return "Mobile No. is Required";
  }
  if (nom?.length !== 10) {
    return "Mobile No. should be 10 digits";
  }
  if (
    Number(nom) === 9999999999 ||
    Number(nom) === 6666666666 ||
    Number(nom) === 7777777777 ||
    Number(nom) === 8888888888
  ) {
    return "Mobile No. cannot be all same digits";
  }
  if (!nom.match(/[6-9]{1}[0-9]{9}$/)) {
    return "Please enter valid mobile no.";
  }

  return "Please enter valid mobile no.";
}

function OTP() {
  useNavigation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiryId");
  const { verified, company_alias } = useSelector(state => state.otp);
  const loading = useSelector(state => state.eligibilityCard);
  const { customerDetails } = useSelector(state => state.greeting);

  const [mobileInput, setMobileInput] = useState({
    no: null,
    status: false,
    error: false,
    message: "",
  });
  const [check1, setCheck1] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);

  useEffect(() => {
    dispatch(initLoader(true));
    dispatch(getAllData(history, "/otp-verification", enquiry_id)).then(() => {
      dispatch(initLoader(false));
    });
  }, []);

  useEffect(() => {
    if (customerDetails?.mobileNo) {
      setMobileInput(prev => ({ ...prev, no: customerDetails?.mobileNo }));
      setCheck1(true);
    }
  }, [customerDetails?.mobileNo]);

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();

  const handleInput = e => {
    const no = e.target.value;
    if (mobileInput.error) {
      if (check(no)) {
        setMobileInput(prev => ({ ...prev, no: no, error: false }));
        setCheck1(true);
        return;
      }
    }

    setCheck1(false);
    setMobileInput(prev => ({ ...prev, no: no }));
  };

  const handleFocusOut = e => {
    setMobileInput(prev => ({
      ...prev,
      error: check(prev.no) ? false : true,
      message: errMesaage(prev.no),
    }));
  };

  const editNumber = () => {
    setMobileInput(prev => ({
      ...prev,
      status: false,
      error: prev.no?.length === 10 ? false : true,
    }));
    setCheck1(false);
  };

  const generateOtp = () => {
    setMobileInput(prev => ({
      ...prev,
      status: check(prev.no) ? true : false,
      error: check(prev.no) ? false : true,
      message: errMesaage(prev.no),
    }));

    if (check(mobileInput.no)) {
      setCheck1(true);
      dispatch(
        generateOTP({
          mobile_no: mobileInput.no,
          enquiry_id,
        }),
      );
    } else {
      setCheck1(false);
    }
  };

  const otpResend = () => {
    otp1.current.value = null;
    otp2.current.value = null;
    otp3.current.value = null;
    otp4.current.value = null;
    dispatch(
      resendOTP({
        enquiry_id,
        mobile_no: mobileInput.no,
      }),
    );

    setResendOtp(true);
    setTimeout(() => {
      setResendOtp(false);
    }, 2000);
  };

  const verifyOtp = () => {
    if (
      otp1.current.value &&
      otp2.current.value &&
      otp3.current.value &&
      otp4.current.value
    ) {
      dispatch(
        verfiyOTP({
          enquiry_id,
          mobile_no: mobileInput.no,
          otp:
            otp1.current.value +
            otp2.current.value +
            otp3.current.value +
            otp4.current.value,
        }),
      ).then(async res => {
        if (!res) {
          otp1.current.value = null;
          otp2.current.value = null;
          otp3.current.value = null;
          otp4.current.value = null;
        } else {
          await updateQuotation({
            traceId: enquiry_id,
            requestType: "otp",
          });
          swal("Mobile Number Verified");
          dispatch(getAllData()).then(() => {
            if (company_alias === "bajaj_life") {
              history.push(`/documentUploadBajaj?enquiryId=${enquiry_id}`);
            } else if (company_alias === "icici_pru") {
              history.push(`/documentuploadpage?enquiryId=${enquiry_id}`);
            }
          });
        }
      });
    }
  };

  const nextKey = e => {
    let key = e.keyCode || e.which;
    if (e.target.value) {
      if (e.target.name === "otp1") {
        otp2.current.focus();
      }
      if (e.target.name === "otp2") {
        otp3.current.focus();
      }
      if (e.target.name === "otp3") {
        otp4.current.focus();
      }
    }
  };

  const numOnly = event => {
    let key = event.keyCode || event.which;

    if (
      (!event.shiftKey && key >= 48 && key <= 57) ||
      (!event.shiftKey && key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      // key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 35 ||
      key === 37 ||
      key === 39
      // key === 144
    ) {
      if (key == 8) {
        if (event.target.name === "otp2") {
          event.preventDefault();
          if (otp2.current.value) {
            otp2.current.value = null;
            otp1.current.focus();
          }
        }
        if (event.target.name === "otp3") {
          event.preventDefault();
          if (otp3.current.value) {
            otp3.current.value = null;
            otp2.current.focus();
          }
        }
        if (event.target.name === "otp4") {
          event.preventDefault();
          if (otp4.current.value) {
            otp4.current.value = null;
            otp3.current.focus();
          }
        }
      } else if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
        if (event.target.name === "otp1") {
          otp1.current.value = event.key;
        }
        if (event.target.name === "otp2") {
          otp2.current.value = event.key;
        }
        if (event.target.name === "otp3") {
          otp3.current.value = event.key;
        }
        if (event.target.name === "otp4") {
          otp4.current.value = event.key;
        }
      }
    } else {
      event.preventDefault();
    }
  };

  if (loading?.loader) {
    return <Loader />;
  }

  return (
    <Containero>
      <FormContainero width={"1140px"}>
        <Row>
          <Col>
            <div className="otp-avatar">
              <Avataro src={laxmiImg} alt="avatarImage" />
            </div>
          </Col>
        </Row>

        <div
          className="tab"
          style={{ display: "block", left: "0px", minHeight: "527px" }}
        >
          <div className="stepper-boldHeader">
            To ensure a secure submission of your application, please confirm
            your mobile number.
          </div>
          <div className="otpInputWrap">
            <CustomInput
              autoComplete={false}
              name="mob"
              autocomplete="off"
              maxLength="10"
              onKeyDown={numOnly}
              type="tel"
              // defaultValue={'9987777747'}
              value={mobileInput.no}
              red={mobileInput.error}
              onChange={handleInput}
              onBlur={handleFocusOut}
              className="mobOtpNumberInput form-control"
              required=""
              disabled={mobileInput.status || check1}
            />
            {(mobileInput.status || check1) && (
              <div
                className="otpNumberEdit"
                style={{ display: "inline-block" }}
                onClick={editNumber}
              >
                <svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    d="M3,17.25v3.75h3.75l11.06,-11.06l-3.75,-3.75l-11.06,11.06Zm17.71,-10.21c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34c-0.39,-0.39 -1.02,-0.39 -1.41,0l-1.83,1.83l3.75,3.75l1.83,-1.83Z"
                    fill="#969799"
                  ></path>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
              </div>
            )}

            {mobileInput.error && <ErrorMsg>{mobileInput.message}</ErrorMsg>}

            {mobileInput.status && (
              <>
                <div className="otpTitle" style={{ display: "block" }}>
                  Enter 4 Digit OTP
                </div>
                <div
                  className="otpWrap"
                  id="otp-container"
                  style={{ display: "inline-block" }}
                >
                  <input
                    autoComplete={false}
                    autoFocus
                    name="otp1"
                    type="tel"
                    ref={otp1}
                    maxLength="1"
                    onKeyPress={nextKey}
                    onKeyDown={numOnly}
                  />
                  <input
                    autoComplete={false}
                    name="otp2"
                    type="tel"
                    ref={otp2}
                    maxLength="1"
                    onKeyPress={nextKey}
                    onKeyDown={numOnly}
                  />
                  <input
                    autoComplete={false}
                    name="otp3"
                    type="tel"
                    ref={otp3}
                    maxLength="1"
                    onKeyPress={nextKey}
                    onKeyDown={numOnly}
                  />
                  <input
                    autoComplete={false}
                    name="otp4"
                    type="tel"
                    ref={otp4}
                    maxLength="1"
                    onKeyPress={nextKey}
                    onKeyDown={numOnly}
                  />
                  <span className="fieldError"></span>
                </div>
              </>
            )}
            <div>
              <Button1
                className={`${
                  (!mobileInput.status || mobileInput.erro) && `buttonSubmit2`
                } buttonSubmit ${mobileInput.error && `buttonSubmit3`}`}
                type="button"
                buttonStyle="custom"
                backgroundhex={check(mobileInput.no) ? "#f2581b" : "#DCDDE0"}
                hoverhex={check(mobileInput.no) ? "#f2581b" : "#DCDDE0"}
                bordercolor={check(mobileInput.no) ? "#f2581b" : "#DCDDE0"}
                borderRadius="4px"
                color={check(mobileInput.no) ? "white" : "#969799"}
                onClick={!mobileInput.status ? generateOtp : verifyOtp}
              >
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  {mobileInput.status ? "SUBMIT" : "GENERATE OTP "}
                </span>
              </Button1>
            </div>
            {mobileInput.status && (
              <>
                <div
                  className="otpNotReceivedWrap"
                  style={{ display: "block" }}
                >
                  <div className="otpNotReceivedTxt">
                    Not recieved OTP?
                    <span
                      className="otpNotReceivedBtn"
                      css={`
                        font-size: 12px;
                      `}
                      onClick={otpResend}
                    >
                      Resend OTP
                    </span>
                  </div>
                </div>

                <div className={`otpSentMsg ${resendOtp ? "resendShow" : ""}`}>
                  OTP has been sent again
                </div>
              </>
            )}
          </div>
        </div>
      </FormContainero>
    </Containero>
  );
}

export default OTP;
