import { useState } from "react";
import { extractNumbers } from "../utils";

function useAmountInput(defaultValue = "") {
  const [value, setValue] = useState(
    defaultValue === ""
      ? ""
      : parseInt(extractNumbers(defaultValue)).toLocaleString("en-In"),
  );

  const handleChange = evt => {
    let { value: givenValue } = evt.target;

    // givenValue = givenValue.replaceAll(" ", "");

    // if (isNumber(givenValue) || givenValue === "") {
    //   setValue(givenValue);
    // }

    if (givenValue === "") {
      setValue("");
      return;
    }

    const intValue = parseInt(extractNumbers(givenValue));

    if (isNaN(intValue)) return;

    setValue(intValue.toLocaleString("en-In"));
  };

  return {
    value,
    handleChange,
    valueInDigits: parseInt(extractNumbers(value)),
  };
}

export { useAmountInput };
