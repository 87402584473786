import React, { useEffect, useState } from "react";
import httpClient from "../../api/httpClient";
import { useCustomLocation } from "../../custom-hooks";
import Loader from "../Loader/Loader";

import Laxmi from "../../assets/img/laxmiImg.png";

import "./rating.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateQuotation } from "./../../modules/ProposalPage/serviceApi";
import { getAllData } from "../../api/getAllData";
import { useNavigation } from "../../custom-hooks/useNavigation";
import { setIsFeedbackGiven } from "../../modules/ProposalPage/proposalPage.slice";

function Rating({
  title,
  worstLabel,
  bestLabel,
  errorText = "Please select",
  isError = false,
  name,
  ...props
}) {
  return (
    <div className="ratingCont">
      <div className="ratingTitle">{title}</div>
      <div className="rating-box">
        <div className="rating-container">
          {[5, 4, 3, 2, 1].map(val => (
            <RatingStar key={val} name={name} value={val} {...props} />
          ))}
        </div>
      </div>
      <div className="rateTxt">
        {worstLabel} <span>{bestLabel}</span>
      </div>
      {isError && <div className="ratingErrorMsg">{errorText}</div>}
    </div>
  );
}

function RatingStar({ name, value, ...props }) {
  return (
    <>
      <input
        type="radio"
        name={name}
        value={value}
        id={`${name}-${value}`}
        {...props}
      />
      <label htmlFor={`${name}-${value}`}>★</label>
    </>
  );
}

function FeedLightHead() {
  const { appNo } = useSelector(({ eligibilityCard }) => eligibilityCard);
  const { company_name } = useSelector(({ quote }) => quote.selectedQuote);

  return (
    <>
      <div className="stepper-subHeader feedBack-boldFont">
        Thank you for choosing Elephant.in
      </div>
      <div className="feedLightHead">
        Your proposal has been submitted successfully and application no. is{" "}
        {appNo}.
        <br />
        {company_name} will review your proposal and we will get back to you
        shortly.
      </div>
    </>
  );
}

function FeedbackSuccess() {
  const { firstName } = useSelector(({ greeting }) => greeting.customerDetails);
  return (
    <div className="feedRatingWrap feedRatingSuc">
      <div className="ratingSucessCont">
        <span>Dear {firstName},</span>
        <span>
          Thank you so much for taking the time to share your experience with
          us. We value your thoughts and strive to provide you with the best
          customer experience possible.
        </span>
        <div className="visitTxt">Visit Our Products</div>
        <div className="visitLinks">
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/Forms/frm_Motor_Quotation.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Car Insurance
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/Two_Wheeler/Frm_TW_Quotation.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bike Insurance
            </a>
          </div>
          <div>
            <a
              href="https://preprod.elephant.in/term-life-Insurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Life Insurance
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=5&amp;catname=TravelInsurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Travel Insurance
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=6&amp;catname=HomeInsurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Home Insurance
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=7&amp;catname=CORONAVIRUS%20PROTECT"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cornona Virus Protect
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=4&amp;catname=HealthInsurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Health Insurance
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=8&amp;catname=HEALTHBOOSTER%20+"
              target="_blank"
              rel="noopener noreferrer"
            >
              Health Booster +
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=9&amp;catname=CRITICALILLNESS"
              target="_blank"
              rel="noopener noreferrer"
            >
              Critical illness
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=10&amp;catname=PERSONALACCIDENT"
              target="_blank"
              rel="noopener noreferrer"
            >
              Personal Accident
            </a>
          </div>
          <div>
            <a
              href="https://elephant.in/AllianceIB-WorksiteGen/forms/Frm_Get_in_Touch.aspx?catid=11&amp;catname=CYBERPROTECT"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cyber Protect
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeedbackForm() {
  const dispatch = useDispatch();
  const [overallExp, setOverallExp] = useState(null);
  const [easyBuy, setEasyBuy] = useState(null);
  const [recommendUs, setRecommendUs] = useState(null);
  const [custsupExp, setCustsupExp] = useState(null);

  const [isError, setIsError] = useState(false);

  const { isFeedbackGiven } = useSelector(({ proposalPage }) => proposalPage);

  const [feedbackSuccess, setFeedbackSuccess] = useState(isFeedbackGiven);
  const [isLoading, setIsLoading] = useState(false);

  const { enquiryId } = useCustomLocation();

  const isFalsy = (...vals) => {
    for (const val of vals) if (!val) return true;
    return false;
  };

  function handleSubmit(evt) {
    evt.preventDefault();

    if (isFalsy(overallExp, easyBuy, recommendUs, custsupExp)) {
      setIsError(true);
    } else {
      submitFeedback();
    }
  }

  async function submitFeedback() {
    try {
      setIsLoading(true);
      const response = await httpClient("common/customer_feedback", {
        method: "POST",
        data: {
          traceId: enquiryId,
          overallExperience: overallExp,
          easyToBuy: easyBuy,
          recommendUs: recommendUs,
          customerSupportExperience: custsupExp,
        },
      });
      if (response.success) {
        await updateQuotation({
          requestType: "customer_feedback",
          traceId: enquiryId,
        });
        setIsLoading(false);
        setFeedbackSuccess(true);
        dispatch(setIsFeedbackGiven(true));
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }
  if (isLoading) return <Loader />;
  if (feedbackSuccess) return <FeedbackSuccess />;
  return (
    <form className="feedRatingWrap feedRatingForm" onSubmit={handleSubmit}>
      <Rating
        title="Overall Experience"
        bestLabel="Excellent"
        worstLabel="Poor"
        name="overallExp"
        onChange={evt => setOverallExp(evt.target.value)}
        isError={isError && !overallExp}
      />
      <Rating
        title="Easy to Buy"
        bestLabel="Yes"
        worstLabel="No"
        name="easyBuy"
        onChange={evt => setEasyBuy(evt.target.value)}
        isError={isError && !easyBuy}
      />
      <Rating
        title="Recommend Us"
        bestLabel="Yes"
        worstLabel="No"
        name="recommendUs"
        onChange={evt => setRecommendUs(evt.target.value)}
        isError={isError && !recommendUs}
      />
      <Rating
        title="Customer Support Experience"
        bestLabel="Excellent"
        worstLabel="Poor"
        name="custsupExp"
        onChange={evt => setCustsupExp(evt.target.value)}
        isError={isError && !custsupExp}
      />
      <div className="rateSubmitWrp">
        <button type="submit" className="proceedNext">
          Submit
        </button>
      </div>
    </form>
  );
}

export function FeedbackRatings({ history }) {
  useNavigation();
  const { appNo } = useSelector(({ eligibilityCard }) => eligibilityCard);
  const { enquiryId, pathname } = useCustomLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!appNo) {
      dispatch(getAllData(history, pathname, enquiryId));
      return;
    }
    // const disableBack = (e) => {
    //   e.preventDefault();
    //   history.go(1);
    // };
    // window.addEventListener("popstate", disableBack);
    // return () => window.removeEventListener("popstate", disableBack);
  }, [appNo, dispatch, enquiryId, history, pathname]);
  if (!appNo) return <Loader />;
  return (
    <>
      <div className="feedback-wrap">
        <div className="laxIconWrap">
          <img className="laxIcon" src={Laxmi} alt="laxmi" />
        </div>
        <div className="mainCheckIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="47"
            viewBox="0 0 47 47"
          >
            <path
              id="prefix__Path_824"
              d="M0 0h47v47H0z"
              data-name="Path 824"
              style={{ fill: "none" }}
            ></path>
            <path
              id="prefix__Path_825"
              d="M21.583 2a19.583 19.583 0 1 0 19.584 19.583A19.59 19.59 0 0 0 21.583 2zm-3.916 29.375l-9.792-9.792 2.761-2.761 7.03 7.011L32.53 10.969l2.761 2.781z"
              data-name="Path 825"
              transform="translate(1.917 1.917)"
              style={{ fill: "#65c100" }}
            ></path>
          </svg>
        </div>
        <FeedLightHead />
        <div className="customWrap">
          <div className="stepper-subHeader boldFontHelp">
            Help us help you and enjoy a better experience!
          </div>
          <FeedbackForm />
        </div>
      </div>
    </>
  );
}

export default Rating;
