import React from "react";
import styled from "styled-components";

const Checkbox = ({ checked, onChange }) => {
  return (
    <>
      <UpgradeCheckBox>
        <div class="custom-checkbox">
          <input
            class="custom-checkbox-input"
            type="checkbox"
            id="whatsapp-Check"
            checked={checked}
            onChange={onChange}
          />
          <label class="custom-checkbox-label" for="whatsapp-Check">
            <label
              class="custom-checkbox-label-aux"
              for="whatsapp-Check"
            ></label>
          </label>
        </div>
      </UpgradeCheckBox>
    </>
  );
};

export default Checkbox;
const UpgradeCheckBox = styled.div`
  & {
    display: table-cell;
    margin-left: 12px;
  }
  & .custom-checkbox {
    float: left;
    width: 30px;
    height: 16px;
    overflow: hidden;
    border-radius: 20px;
    margin: 0;
  }
  & .custom-checkbox-input {
    display: none;
  }
  & .custom-checkbox-label {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    background-color: #dddddd;
    transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    margin: 0;
  }
  & .custom-checkbox-label-aux {
    top: 2px;
    left: 3px;
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    cursor: pointer;
    border-radius: 100%;
    transition: left 0.3s;
    -o-transition: left 0.3s;
    background-color: white;
    -moz-transition: left 0.3s;
    -webkit-transition: left 0.3s;
    box-shadow: 0px 1px 5px 0px rgb(56 55 56 / 61%);
    -moz-box-shadow: 0px 1px 5px 0px rgba(56, 55, 56, 0.61);
    -webkit-box-shadow: 0px 1px 5px 0px rgb(56 55 56 / 61%);
  }
  & .custom-checkbox-input:checked + .custom-checkbox-label {
    background-color: #107591;
    border: none;
  }
  &
    .custom-checkbox-input:checked
    + .custom-checkbox-label
    .custom-checkbox-label-aux {
    left: 16px;
  }
`;
