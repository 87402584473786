import React from "react";

export const Help = (
  <svg
    className="help-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g transform="translate(-.5 -.5)">
      <g transform="translate(1 1)">
        <circle cx="6" cy="6" r="6" fill="none" stroke="#5b6466"></circle>
        <path
          fill="#5b6466"
          d="M7.359 8.409h1.025v-.06c0-1.359 1.615-.983 1.615-2.6A1.79 1.79 0 0 0 8.025 4 2 2 0 0 0 6 5.265l1.06.453a.951.951 0 0 1 .914-.684.749.749 0 0 1 .826.726c0 .966-1.444.649-1.444 2.589zm.513 2.354a.752.752 0 1 0-.752-.752.72.72 0 0 0 .751.752z"
          data-name="?-copy"
          transform="translate(-2.056 -1.37)"
        ></path>
      </g>
    </g>
  </svg>
);

export const CloseButton = (
  <svg
    className="compareRemovePlan"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#000"
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    <path d="M0 0h24v24H0z" fill="none"></path>
  </svg>
);

export const MedicalTest = (
  <svg
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="40"
  >
    <g fill="#5b6466">
      <path d="M20.5,24h-17c-0.276,0 -0.5,-0.224 -0.5,-0.5v-17c0,-0.133 0.053,-0.26 0.146,-0.354l6,-6c0.094,-0.093 0.221,-0.146 0.354,-0.146h11c0.276,0 0.5,0.224 0.5,0.5v23c0,0.276 -0.224,0.5 -0.5,0.5Zm-16.5,-1h16v-22h-10.293l-5.707,5.707v16.293Z"></path>
      <path d="M9.5,7h-6c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5.5v-5.5c0,-0.276 0.224,-0.5 0.5,-0.5c0.276,0 0.5,0.224 0.5,0.5v6c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,9h-11c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h11c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,6h-5c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h5c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,12h-11c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h11c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,15h-11c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h11c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,18h-11c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h11c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
      <path d="M17.5,21h-11c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5h11c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5Z"></path>
    </g>
  </svg>
);
