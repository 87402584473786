import React, { useEffect, useState } from "react";
import CardTitle from "../Card/CardTitle/CardTitle";
import editIcon from "../../assets/img/editIcon.svg";
import Card from "../Card/Card";
// import location from "../../assets/img/location.svg";
import {
  AgeWrapper,
  CustomDropDownFont,
  FormGroup as FG,
  Label,
  Radio,
  RadioButton,
  RadioContainer,
  RadioLabel,
  TextInput,
  TextInputElg,
  TextAreaProposal,
} from "../Form/Form";
// import SmallCustomDropDown from "../SmallCustomDropDown/SmallCustomDropDown";
// import ToggleButton from "../ToggleButton/ToggleButton";
import { CustomDropDown } from "../../components";
import RadioComponent from "../RadioContainer/RadioContainer";
import {
  dateUtil,
  monthUtil,
  // yearUtils,
  // normalDateUtils,
  // normalYearUtils,
  generateRandomString,
  getAge,
  alphaNumeric,
  numOnlyWithShifDisable,
  numOnlyWithShifDisableWithDelete,
  noNumSpace,
  noNum,
  upperCase,
  upperCaseAll,
  capitalizeSentenceJoin,
  getAgeYear,
  onlyAlphaNoSpace,
  onlyOneSpace,
} from "../../utils/inputUtils";

import "./form-builder.scss";
import { checkpinCode } from "../../modules/EligibilityPage/serviceApi";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../StyledButton/Button";
import moment from "moment";
import { setPosition } from "../../modules/ProposalPage/IcicProposalPage/IcicProposal.slice";
import styled from "styled-components";
import { useCustomLocation } from "../../custom-hooks";
import {
  getCKYCData,
  initCKYCLoader,
} from "../../modules/ProposalPage/BajaProposalPage/bajajProposalPage.slice";

const FormGroup = styled(FG)`
  height: auto !important;
  min-height: max-content !important;
  margin-top: 20px;
`;

function FormBuilder({
  title,
  submitButton,
  formItems,
  onSubmit,
  expand,
  index,
  setExpandForm,
}) {
  const [summary, setSummary] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const { customerDetails } = useSelector(state => state.greeting);
  const { enquiryId } = useCustomLocation();
  useEffect(() => {
    if (index === expand) setSummary(false);
    if (expand > index) setSummary(true);
  }, [index, expand]);

  const { company_alias } = useSelector(({ quote }) => quote.selectedQuote);
  const [formItemsIn, setFormItemsIn] = useState([]);

  const preprocessFormItems = () => {
    let newFormItems = [...(formItemsIn.length ? formItemsIn : formItems)];

    (formItemsIn.length ? formItemsIn : formItems).forEach((formItem, idx) => {
      if (
        formItem.type === "toggle" &&
        formItem.addCorrespondingItemsOnChange
      ) {
        if (
          formItem.addCorrespondingItemsOnChange(formItem.value).length &&
          !formItem
            .addCorrespondingItemsOnChange(formItem.value)
            .every(val => formItemsIn.some(innerVal => innerVal.id === val.id))
        ) {
          const index = newFormItems.findIndex(i => i.id === formItem.id);
          newFormItems.splice(
            index + 1,
            0,
            ...formItem
              .addCorrespondingItemsOnChange(formItem.value)
              .map(thisFormItem => {
                const newFormItem = {
                  ...thisFormItem,
                  ref: formItem.ref
                    ? [...formItem.ref, formItem.id]
                    : [formItem.id],
                };
                if (formItem.collectionId && formItem.setId) {
                  newFormItem.collectionId = formItem.collectionId;
                  newFormItem.setId = formItem.setId;
                  newFormItem.itemId = thisFormItem.id;
                  newFormItem.id = formItem.id + generateRandomString(10);
                }
                return newFormItem;
              }),
          );
        }
      }
      if (
        formItem.type === "select" &&
        formItem.addCorrespondingItemsOnChange
      ) {
        if (
          formItem.addCorrespondingItemsOnChange(formItem.value).length &&
          !formItem
            .addCorrespondingItemsOnChange(formItem.value)
            .every(val => formItemsIn.some(innerVal => innerVal.id === val.id))
        ) {
          const index = newFormItems.findIndex(i => i.id === formItem.id);
          newFormItems.splice(
            index + 1,
            0,
            ...formItem
              .addCorrespondingItemsOnChange(formItem.value)
              .map(thisFormItem => {
                const newFormItem = {
                  ...thisFormItem,
                  ref: formItem.ref
                    ? [...formItem.ref, formItem.id]
                    : [formItem.id],
                };
                return newFormItem;
              }),
          );
        }
      }
      if (
        formItem.type === "dob" &&
        formItem.addFormOnChange &&
        formItem.addCorrespondingItemsOnChange
      ) {
        if (
          formItem.addFormOnChange(formItem.value).length &&
          !formItem
            .addCorrespondingItemsOnChange(formItem.value)
            .every(val => formItemsIn.some(innerVal => innerVal.id === val.id))
        ) {
          newFormItems = [
            ...newFormItems,
            ...formItem.addFormOnChange(formItem.value).map(fi => {
              return {
                ...fi,
                ref: fi.ref ? [...fi.ref, formItem.id] : [formItem.id],
              };
            }),
          ];
        }
      }
    });
    return newFormItems;
  };

  useEffect(() => {
    setFormItemsIn(preprocessFormItems());
  }, []);

  const [collections, setCollections] = useState(
    preprocessFormItems().reduce((collections, formItem) => {
      if (formItem.type === "collection") {
        const setId = generateRandomString(10);
        return [
          ...collections,
          {
            ...formItem,
            items: [
              ...formItem.items.map(item => ({
                ...item,
                collectionId: formItem.id,
                setId,
                itemId: item.id,
              })),
              {
                id: formItem.id + "remove",
                type: "button",
                size: "xlg",
                customClassName: formItem.customClassName,
                collectionId: formItem.id,
                value: formItem.removeButton,
                setId,
                removeItemsOnClick: [setId],
              },
            ],
          },
        ];
      }
      return collections;
    }, []),
  );

  const [collectionLengths, setCollectionLengths] = useState(
    collections.reduce((lengths, collection) => ({ [collection.id]: 1 }), {}),
  );

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    if (collections[0]) {
      if (
        data[collections[0].trigger] === "Yes" &&
        !formItemsIn.some(item =>
          item.collectionId ? item.collectionId === collections[0].id : false,
        )
      ) {
        const newFormItems = [...formItemsIn];

        const index = newFormItems.findIndex(
          item => item.id === collections[0].trigger,
        );

        newFormItems.splice(
          index + 1,
          0,
          ...[
            ...collections[0].items.map(item => ({
              ...item,
              ref: [collections[0].trigger],
            })),
            {
              id: collections[0].id + "add",
              collectionId: collections[0].id,
              type: "button",
              customClassName: `formbuilder__addButton ${
                "test" + collections[0].id
              }`,
              size: "xlg",
              value: collections[0].addButton,
              ref: [collections[0].trigger],
              addItemsOnClick() {
                const setId = generateRandomString(10);
                return collections[0].items.map(item => {
                  if (item.type === "button")
                    return {
                      ...item,
                      id: String(item.id + setId) + "remove",
                      ref: [collections[0].trigger],
                      removeItemsOnClick: [setId],
                      setId,
                    };
                  return {
                    ...item,
                    id: String(item.id + setId),
                    ref: [collections[0].trigger],
                    setId,
                    itemId: item.id,
                  };
                });
              },
            },
          ],
        );

        setFormItemsIn(newFormItems);
      } else if (
        data[collections[0].trigger] === "No" &&
        formItemsIn.some(item =>
          item.collectionId ? item.collectionId === collections[0].id : false,
        )
      ) {
        setErrors([]);
        setFormItemsIn(
          formItemsIn.filter(formItem =>
            formItem.ref
              ? !formItem?.ref?.includes(collections[0].trigger)
              : true,
          ),
        );
        setCollectionLengths({
          ...collectionLengths,
          [collections[0].id]: 1,
        });
      }
    }
  }, [collections, data]);

  const handleClick = () => {
    let initialErrors = errors;

    const types = ["text", "email", "pan", "mobile"];

    let sumOf = 0;

    formItemsIn.forEach(formItem => {
      const { validations, id, type } = formItem;

      if (validations?.required && !data[id])
        initialErrors = {
          ...initialErrors,
          [formItem.id]: validations?.required || "This field is required",
        };
      if (formItem.type === "pincode" && !data[id].pincode)
        initialErrors = {
          ...initialErrors,
          [formItem.id]: validations?.required || "This field is required",
        };
      if (
        formItem.type === "select" &&
        validations?.required &&
        ((!formItem.items?.includes(data[id]) && formItem?.items?.length) ||
          data[id] === "Select")
      ) {
        initialErrors = {
          ...initialErrors,
          [formItem.id]: validations?.required,
        };
      }
      if (
        formItem.type === "checkbox" &&
        validations?.required &&
        data[id].length === 0
      )
        initialErrors = {
          ...initialErrors,
          [formItem.id]: validations?.required,
        };
      if (formItem.collectionId) {
        if (collections[0]?.sumOf?.[0]["id"] === formItem.itemId) {
          sumOf += Number(formItem.value);
        }
      }
    });

    // if (sumOf !== Number(collections[0]?.sumOf[0]["sum"])) {
    //   formItemsIn.forEach((item) => {
    //     if (item.itemId === collections[0]?.sumOf[0]["id"]) {
    //       initialErrors = {
    //         ...initialErrors,
    //         [item.id]: collections[0]?.sumOf[0]["error"],
    //       };
    //     }
    //   });
    // } else {
    //   formItemsIn.forEach((item) => {
    //     if (item.itemId === collections[0]?.sumOf[0]["id"]) {
    //       delete initialErrors[item.id];
    //     }
    //   });
    // }
    Object.keys(initialErrors).forEach(item => {
      const index = formItemsIn.findIndex(i => i.id === item);
      if (index === -1) delete initialErrors[item];
    });
    setErrors(initialErrors);

    const undefinedErrors = { ...initialErrors };

    Object.keys(undefinedErrors).forEach(
      err => undefinedErrors[err] === undefined && delete undefinedErrors[err],
    );

    // if (Object.keys(initialErrors).length === 0) {
    if (Object.keys(undefinedErrors).length === 0) {
      const sendData = { ...data };
      const collectionsData = {};
      collections.forEach(collection => (collectionsData[collection.id] = {}));

      formItemsIn.forEach(item => {
        if (item.type === "checkbox") {
          if (item.variables) {
            item.variables.forEach((variable, idx) => {
              sendData[variable] = sendData[item.id].includes(item.value[idx]);
            });
            delete sendData[item.id];
          }
        }
        if (item.type === "select" && item.internalItems) {
          sendData[item.id] =
            item.internalItems[item.items.indexOf(item.value)];
        }
        if (item.collectionId && item.type !== "button") {
          collectionsData[item.collectionId][item.setId] = {
            ...collectionsData[item.collectionId][item.setId],
            [item.itemId]: item.internalItems
              ? item.internalItems[item.items.indexOf(item.value)]
              : item.value,
          };
          delete sendData[item.id];
        }
      });
      collections.forEach(collection => {
        sendData[collection.id] = Object.values(collectionsData[collection.id]);
      });

      onSubmit(sendData);
      setSummary(true);
      setErrors({});
      setFormSubmitted(true);
      setExpandForm(index + 1);
    }
  };

  useEffect(() => {
    const newData = {};
    formItemsIn.forEach(formItem => {
      if (formItem.type !== "title" && formItem.type !== "button") {
        if (formItem.type === "pincode")
          newData[formItem.id] = {
            pincode: formItem.value,
            city: formItem.city,
            state: formItem.state,
            stateCode: formItem.stateCode,
            traceId: enquiryId,
          };
        else if (formItem.type === "collection") newData[formItem.id] = [];
        else newData[formItem.id] = formItem.value || "";
      }
    });
    setData(newData);

    let newErrors = { ...errors };

    formItemsIn.forEach(formItem => {
      if (formItem.validations) {
        if (errors[formItem.id]) {
          const validations = formItem.validations;
          if (formItem.value === "")
            newErrors = {
              ...newErrors,
              [formItem.id]: validations?.required || "This field is required",
            };
          else if (formItem.id === formItem.validations?.isBefore?.id[0]) {
            delete newErrors[formItem.validations?.isBefore?.id[1]];
          } else if (formItem.id === formItem.validations?.isAfter?.id[0])
            delete newErrors[formItem.validations?.isAfter?.id[1]];
          else if (formItem.id === formItem.validations?.isSameOrBefore?.id[0])
            delete newErrors[formItem.validations?.isSameOrBefore?.id[1]];
          else if (formItem.id === formItem.validations?.isSameOrAfter?.id[0])
            delete newErrors[formItem.validations?.isSameOrAfter?.id[1]];
          // else delete newErrors[formItem.id];
        }
      }
    });
    setErrors(newErrors);
  }, [formItemsIn]);

  function validate(value, formItem, data) {
    const { id, validations, type, label, secondMob } = formItem;
    if (formItem.getValidations) {
      const validations = formItem.getValidations();

      if (validations?.required && (value === "" || value === undefined))
        setErrors({
          ...errors,
          [id]: validations?.required || "This field is required",
        });
    } else if (validations?.fullName) {
      if (!value.match(/^[a-zA-Z\.\'\-]{1,50}(?: [a-zA-Z\.\'\-]{1,50})+$/))
        setErrors({ ...errors, [id]: "Please enter full name" });
    } else if (formItem?.validations?.notAllowed) {
      if (value === validations?.notAllowed[0])
        setErrors({ ...errors, [id]: validations?.notAllowed[1] });
    } else if (
      formItem.validations?.required &&
      (value === "" || value === undefined)
    )
      setErrors({
        ...errors,
        [id]: validations?.required || "This field is required",
      });
    if (
      validations?.inRange &&
      !moment(value, "DD/MM/YYYY").isBetween(...validations?.inRange?.range)
    ) {
      setErrors({
        ...errors,
        [id]: validations?.inRange?.message,
      });
    }
    //
    if (validations?.isAfter) {
      const des = validations?.isAfter?.id.map(item => data[item]);
      if (
        des &&
        moment(des[0], "DD/MM/YYYY").isAfter(
          moment(des[1], "DD/MM/YYYY"),
          "days",
        )
      ) {
        setErrors({
          ...errors,
          [id]: validations?.isAfter?.message,
        });
      }
    }
    if (validations?.isSameOrAfter) {
      const des = validations?.isSameOrAfter?.id.map(item => data[item]);
      if (
        des &&
        moment(des[0], "DD/MM/YYYY").isSameOrAfter(
          moment(des[1], "DD/MM/YYYY"),
          "days",
        )
      ) {
        setErrors({
          ...errors,
          [id]: validations?.isSameOrAfter?.message,
        });
      }
    }
    if (validations?.isBefore) {
      const des = validations?.isBefore?.id.map(item => data[item]);
      if (
        des &&
        moment(des[0], "DD/MM/YYYY").isBefore(
          moment(des[1], "DD/MM/YYYY"),
          "days",
        )
      ) {
        setErrors({
          ...errors,
          [id]: validations?.isBefore?.message,
        });
      }
    }
    if (validations?.isSameOrBefore) {
      const des = validations?.isSameOrBefore?.id.map(item => data[item]);
      if (
        des &&
        moment(des[0], "DD/MM/YYYY").isSameOrBefore(
          moment(des[1], "DD/MM/YYYY"),
          "days",
        )
      ) {
        setErrors({
          ...errors,
          [id]: validations?.isSameOrBefore?.message,
        });
      }
    }
    //
    if (
      validations?.disableFuture &&
      moment(value, "DD/MM/YYYY").isAfter(moment())
    )
      setErrors({
        ...errors,
        [id]: "Future dates are not allowed",
      });
    if (
      validations?.BeforeCovid &&
      moment(value, "DD/MM/YYYY").isBefore(moment("01/01/2020"), "day")
    )
      setErrors({
        ...errors,
        [id]: "Dates are not allowed before 01/01/2020",
      });
    else if (type === "mobile") {
      if (value.length !== 10)
        setErrors({ ...errors, [id]: "Mobile No. should be 10 digits" });
      else if (!value.match(/[5-9]{1}[0-9]{9}$/))
        setErrors({ ...errors, [id]: validations?.invalid });
      else if (secondMob && value === data?.mobileNo)
        setErrors({
          ...errors,
          [id]: "Alternate Mobile No should not be same as primary ",
        });
    } else if (type === "pan") {
      if (
        !value.match(/^([a-zA-Z]{3}[P]{1}[a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$/)
      )
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (type === "pincode" && value.length !== 6) {
      setErrors({
        ...errors,
        [id]: validations?.invalid || "Please provide valid pincode",
      });
    } else if (type === "license") {
      if (value.match(/^([A-Z]{2})(\d{2})(\d{4})(\d{7})$/))
        setErrors({ ...errors, [id]: "please enter valid License number" });
    } else if (type === "passport") {
      if (!value.match(/^[A-Za-z]{1}[0-9]{7}$/))
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (type === "aadhar") {
      if (!value.match(/^[0-9]{4}$/))
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (type === "voterId") {
      if (!value.match(/^([a-zA-Z]){3}([0-9]){7}?$/))
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (type === "annIncome") {
      if (value < 300000) setErrors({ ...errors, [id]: validations?.invalid });
    } else if (type === "email") {
      if (
        !value.match(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        )
      )
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (
      label?.toLowerCase() === "first name" ||
      label?.toLowerCase() === "last name"
    ) {
      if (value.includes(" "))
        setErrors({ ...errors, [id]: validations?.invalid });
      else if (!value.match(/^[A-Za-z]+$/))
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (label.toLowerCase().includes("name")) {
      if (validations?.allowSpace && !value.match(/^[A-Za-z]+$/))
        setErrors({ ...errors, [id]: validations?.invalid });
      else if (!value.match(/^[A-Za-z]+$/))
        setErrors({ ...errors, [id]: validations?.invalid });
    } else if (value.length < validations?.minLength) {
      setErrors({ ...errors, [id]: validations?.invalid });
    }

    if (validations?.custom) {
      validations.custom.forEach(item => {
        if (item.notAllowed) {
          if (value === item.value) {
            setErrors({
              ...errors,
              [id]: item.message || "Please Enter valid value",
            });
          } else {
            setErrors({
              ...errors,
              [id]: undefined,
            });
          }
        }
        if (item.pattern) {
          if (!value.match(item.pattern))
            setErrors({
              ...errors,
              [id]: item.message || "Please Enter valid value",
            });
        }
        if (item.min) {
          if (value < item.min)
            setErrors({
              ...errors,
              [id]:
                item.message || `Please enter value greater than ${item.min}`,
            });
        }
        if (item.max) {
          if (value > item.max)
            setErrors({
              ...errors,
              [id]:
                item.message || `Please enter value greater than ${item.max}`,
            });
        }
        if (item.test) {
          if (!item.test(value, data))
            setErrors({
              ...errors,
              [id]: item.message || `Test case failed`,
            });
        }
      });
    }
    if (validations && type !== "email" && type !== "normalDate") {
      if (!validations?.freeText)
        if (!value.match(/^(\w+\s)*\w+$|^$/))
          setErrors({
            ...errors,
            [id]: "Input field can't start or end with space or contain special characters",
          });
    }
  }

  const renderFormItem = (formItem, idx) => {
    const {
      label,
      value,
      type,
      error,
      items,
      id,
      ref,
      readOnly,
      addCorrespondingItemsOnChange,
      size,
      alignLeft,
      extraMargin,
      extraMarginTop,
      extraRadioMargin,
      reduceMinHeight,
      alignCenter,
      disabled,
      nextLine,
      validations,
      setOtherItemValueOnChange,
      setOtherItemValueOnChangeAsync,
      showAgeLabel,
      addFormOnChange,
      white,
      displayFlex,
      flexCol,
      widthFull,
      reduceMinHeights,
      extraMarginBottom,
      secondMob,
      removeItemsOnClick,
      addItemsOnClick,
      uppercase,
      customClassName,
      ageValidation,
      labelBefore,
      orderedLabel,
      smallToggle,
      leftCheck,
      onPincodeFetch,
      largeLabel,
      verifyCKYC,
      message,
    } = formItem;

    const types = [
      "text",
      "email",
      "pan",
      "mobile",
      "license",
      "passport",
      "voterId",
      "annIncome",
      "aadhar",
      "textArea",
    ];
    console.log(errors);
    const handleOnChange = value => {
      if (types.includes(type) || !type) {
        const caret = value.target.selectionStart;
        const element = value.target;
        window.requestAnimationFrame(() => {
          element.selectionStart = caret;
          element.selectionEnd = caret;
          element.setSelectionRange(caret, caret);
        });
      }

      if (type === "checkbox") {
        let newFormItems = formItemsIn;
        newFormItems[idx] = {
          ...newFormItems[idx],
          value: newFormItems[idx].value.includes(value)
            ? newFormItems[idx].value.filter(val => val !== value)
            : [...newFormItems[idx].value, value],
        };
        setFormItemsIn([...newFormItems]);
        if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors({ ...newErrors });
        }

        if (addCorrespondingItemsOnChange) {
          const newErrors = errors;
          formItemsIn.forEach(formItem => {
            if (formItem?.ref?.includes(id)) {
              delete newErrors[formItem.id];
            }
          });
          setErrors(newErrors);
          newFormItems = newFormItems.filter(formItem => {
            if (formItem.ref) return !formItem.ref.includes(id);
            return true;
          });
          const itemsToAdd = addCorrespondingItemsOnChange(
            newFormItems[idx].value,
          );
          if (itemsToAdd) {
            newFormItems.splice(
              idx + 1,
              0,
              ...itemsToAdd.map(formItem => {
                return { ...formItem, ref: ref ? [...ref, id] : [id] };
              }),
            );
            setFormItemsIn([...newFormItems]);
          } else if (
            addCorrespondingItemsOnChange(value).length === 0 ||
            !addCorrespondingItemsOnChange(value)
          ) {
            setFormItemsIn(
              newFormItems.filter(formItem => {
                if (formItem.ref) return !formItem.ref.includes(id);
                return true;
              }),
            );
          }
        }
      } else if (type === "select") {
        const newFormItems = formItemsIn;
        newFormItems[idx] = { ...newFormItems[idx], value };
        setFormItemsIn([...newFormItems]);
        if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors({ ...newErrors });
        }
      } else if (types.includes(type) || !type) {
        if (
          value.target.value.length === 1 &&
          (label.toLowerCase() === "first name" ||
            label.toLowerCase() === "last name" ||
            label.toLowerCase().includes("name"))
        ) {
          const newFormItems = formItemsIn;
          const newName = value.target.value.toUpperCase();
          newFormItems[idx] = { ...newFormItems[idx], value: newName };
          setFormItemsIn([...newFormItems]);
          const newErrors = errors;
          delete newErrors[id];
          setErrors(newErrors);
        } else {
          const newFormItems = formItemsIn;
          newFormItems[idx] = {
            ...newFormItems[idx],
            value: uppercase
              ? value.target.value.toUpperCase()
              : value.target.value,
          };
          setFormItemsIn([...newFormItems]);
          if (errors[id]) {
            const newErrors = errors;
            delete newErrors[id];
            setErrors(newErrors);
          }
        }
      } else if (type === "dob") {
        const newFormItems = formItemsIn;
        const age = dateUtil(value, value.target.value, true);
        newFormItems[idx] = { ...newFormItems[idx], value: value.target.value };
        setFormItemsIn([...newFormItems]);
        if (age <= 0 && value.target.value.length === 10) {
          setErrors({
            ...errors,
            [id]: validations?.invalid || "Please provide valid dob",
          });
          return;
        } else if (age > 100 && value.target.value.length === 10) {
          setErrors({
            ...errors,
            [id]: validations?.invalid || "Please provide valid dob",
          });
          return;
        } else if (validations?.age) {
          if (validations?.age?.gt) {
            const [gt, message] = validations.age.gt;
            if (age <= gt && value.target.value.length === 10) {
              setErrors({
                ...errors,
                [id]: message || `Age should be greater than ${gt}`,
              });
              return;
            }
          }
          if (validations?.age?.lt) {
            const [lt, message] = validations.age.gt;
            if (age >= lt && value.target.value.length === 10) {
              setErrors({
                ...errors,
                [id]: message || `Age should be less than ${lt}`,
              });
              return;
            }
          }
        }
        if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors(newErrors);
        }
      } else if (type === "dob2") {
        const newFormItems = formItemsIn;
        monthUtil(value, value.target.value);
        newFormItems[idx] = { ...newFormItems[idx], value: value.target.value };
        setFormItemsIn([...newFormItems]);
        if (value.target.value.length === 8)
          setErrors({
            ...errors,
            [id]: validations?.invalid || "Please provide date",
          });
        else if (getAgeYear(value.target.value) > customerDetails?.age) {
          setErrors({
            ...errors,
            [id]:
              validations?.invalid ||
              "Please provide date after your Date of Birth",
          });
        } else if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors(newErrors);
        }
      } else if (type === "normalDate") {
        const newFormItems = formItemsIn;
        dateUtil(value, value.target.value);
        newFormItems[idx] = { ...newFormItems[idx], value: value.target.value };
        setFormItemsIn([...newFormItems]);
        if (value.target.value.length !== 10)
          setErrors({
            ...errors,
            [id]: validations?.invalid || "Please provide valid date",
          });
        else if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors(newErrors);
        }
      } else if (type === "pincode") {
        const enteredPincode = value.target.value;
        let newFormItems = formItemsIn;
        newFormItems[idx] = {
          ...newFormItems[idx],
          value: enteredPincode,
          city: "",
          state: "",
        };
        setFormItemsIn([...newFormItems]);
        if (errors[id]) {
          const newErrors = errors;
          delete newErrors[id];
          setErrors(newErrors);
        }
        const getRegion = async data => {
          try {
            const response = await checkpinCode(data);
            if (response.data) return response.data;
            alert("Something went wrong while fetching region details.");
          } catch (error) {
            alert("Something went wrong while fetching region details.");
            console.error(error);
          }
        };
        const handlePincodeChange = async () => {
          const regionDetailsData = await getRegion({
            pinCode: enteredPincode,
            companyAlias: company_alias, //replave it with company_alias
            traceId: enquiryId,
            type: "perm_add",
          });
          const regionDetailsArea = await getRegion({
            pinCode: enteredPincode,
            city: regionDetailsData?.city[0],
            companyAlias: company_alias,
            traceId: enquiryId,
          });
          if (regionDetailsData?.status) {
            newFormItems[idx] = {
              ...newFormItems[idx],
              // city: regionDetailsData.pinCodeMaster.city,
              state: regionDetailsData?.state,
              traceId: enquiryId,
              // stateCode: regionDetailsData.pinCodeMaster.state_code,
              // cityCode: regionDetailsData.pinCodeMaster.city_code,
            };
            newFormItems = newFormItems.map(fi => {
              if (fi.id === newFormItems[idx]?.scaRef?.state) {
                return {
                  ...fi,
                  value: regionDetailsData?.state
                    ? capitalizeSentenceJoin(regionDetailsData?.state)
                    : "",
                };
              }
              if (fi.id === newFormItems[idx]?.scaRef?.city) {
                return {
                  ...fi,
                  value: regionDetailsData?.city[0]
                    ? capitalizeSentenceJoin(regionDetailsData?.city[0])
                    : "",
                  items: regionDetailsData?.city
                    ? regionDetailsData?.city.map(item =>
                        capitalizeSentenceJoin(item),
                      )
                    : [],
                };
              }
              if (fi.id === newFormItems[idx]?.scaRef?.area) {
                return {
                  ...fi,
                  items: regionDetailsArea?.area
                    ? regionDetailsArea?.area.map(item =>
                        capitalizeSentenceJoin(item),
                      )
                    : [],
                };
              }
              return fi;
            });
            setFormItemsIn(newFormItems);
            // onPincodeFetch(
            //   regionDetailsData.pinCodeMaster,
            //   setFormItemsIn,
            //   formItemsIn
            // );
          } else {
            const newErrors = { ...errors };
            newErrors[id] = validations?.invalid || regionDetailsData.errors;
            setErrors(newErrors);
          }
        };
        if (enteredPincode.length === 6) handlePincodeChange();
      } else {
        const newFormItems = formItemsIn;
        if (value) {
          newFormItems[idx] = { ...newFormItems[idx], value: value };
          setFormItemsIn([...newFormItems]);
        } else setFormItemsIn([...newFormItems]);
      }

      if (type !== "checkbox") {
        if (addCorrespondingItemsOnChange) {
          const newErrors = errors;
          formItemsIn.forEach(formItem => {
            if (formItem?.ref?.includes(id)) {
              delete newErrors[formItem.id];
            }
          });
          setErrors(newErrors);
          let newFormItems = formItemsIn.filter(formItem => {
            if (formItem.ref) return !formItem.ref.includes(id);
            return true;
          });
          newFormItems = newFormItems.map(formItem => {
            let newFormItem = formItem;
            if (formItem.getReadOnly) {
              newFormItem = {
                ...newFormItem,
                readOnly: formItem.getReadOnly(),
              };
            }
            if (formItem.getValue) {
              newFormItem = { ...newFormItem, value: formItem.getValue() };
            }
            return newFormItem;
          });
          if (
            addCorrespondingItemsOnChange(
              type === "checkbox" ? data[id] : value,
            )
          ) {
            newFormItems.splice(
              idx + 1,
              0,
              ...addCorrespondingItemsOnChange(value).map(thisFormItem => {
                const newFormItem = {
                  ...thisFormItem,
                  ref: ref ? [...ref, id] : [id],
                };
                if (formItem.collectionId && formItem.setId) {
                  newFormItem.collectionId = formItem.collectionId;
                  newFormItem.setId = formItem.setId;
                  newFormItem.itemId = thisFormItem.id;
                  newFormItem.id = id + generateRandomString(10);
                }
                return newFormItem;
              }),
            );

            setFormItemsIn([...newFormItems]);
          }
          if (addCorrespondingItemsOnChange(value).length === 0) {
            setFormItemsIn(
              newFormItems.filter(formItem => {
                if (formItem.ref) return !formItem.ref.includes(id);
                return true;
              }),
            );
          }
        }

        if (setOtherItemValueOnChange) {
          if (setOtherItemValueOnChange(value?.target?.value || value, data)) {
            const newFormItems = formItemsIn;
            setOtherItemValueOnChange(
              value?.target?.value || value,
              data,
            ).forEach(item => {
              const { ref, value: refValue } = item;
              const refIndex = newFormItems.findIndex(
                formItem => formItem.id === ref,
              );
              newFormItems[refIndex] = {
                ...newFormItems[refIndex],
                value: refValue,
              };
            });
            setFormItemsIn([...newFormItems]);
          }
        }

        if (setOtherItemValueOnChangeAsync) {
          const a = async () => {
            const arr = await setOtherItemValueOnChangeAsync(
              value?.target?.value || value,
              data,
            );
            if (arr) {
              const newFormItems = formItemsIn;

              if (arr === "api error") {
                newFormItems[idx].value = "";
              } else {
                arr.forEach(item => {
                  const { ref, value: refValue, items: refItems } = item;
                  const refIndex = newFormItems.findIndex(
                    formItem => formItem.id === ref,
                  );
                  newFormItems[refIndex] = {
                    ...newFormItems[refIndex],
                    value: refValue,
                  };
                  if (
                    newFormItems[refIndex].type &&
                    newFormItems[refIndex]?.type === "select" &&
                    refItems
                  ) {
                    newFormItems[refIndex] = {
                      ...newFormItems[refIndex],
                      items: refItems,
                    };
                  }
                });
              }
              setFormItemsIn([...newFormItems]);
            }
          };
          a();
        }

        if (addFormOnChange) {
          const newErrors = errors;
          formItemsIn.forEach(formItem => {
            if (formItem?.ref?.includes(id)) {
              delete newErrors[formItem.id];
            }
          });
          setErrors(newErrors);
          const newFormItems = formItemsIn.filter(formItem => {
            if (formItem.ref) return !formItem.ref.includes(id);
            return true;
          });
          if (addFormOnChange(value?.target?.value || value)) {
            setFormItemsIn([
              ...newFormItems,
              ...addFormOnChange(value?.target?.value || value).map(
                formItem => {
                  return { ...formItem, ref: ref ? [...ref, id] : [id] };
                },
              ),
            ]);
          }
          if (addFormOnChange(value?.target?.value || value).length === 0) {
            setFormItemsIn(
              newFormItems.filter(formItem => {
                if (formItem.ref) return !formItem.ref.includes(id);
                return true;
              }),
            );
          }
        }
      }
    };

    const handleButtonClick = () => {
      if (removeItemsOnClick) {
        const newErrors = errors;
        removeItemsOnClick.forEach(id => newErrors[id] && delete newErrors[id]);
        setErrors(newErrors);

        let newFormItems = formItemsIn.filter(
          formItem =>
            !removeItemsOnClick.includes(formItem.setId || formItem.id),
        );

        if (collectionLengths[formItem.collectionId] === 1) {
          newFormItems = newFormItems.map(item => {
            if (formItem.ref.includes(item.id)) return { ...item, value: "No" };
            return item;
          });
        }
        setFormItemsIn(newFormItems);
        if (collectionLengths[formItem.collectionId] !== 1) {
          setCollectionLengths({
            ...collectionLengths,
            [formItem.collectionId]:
              collectionLengths[formItem.collectionId] - 1,
          });
        }
      }
      if (addItemsOnClick) {
        let collectionLength = 0;
        if (formItem.collectionId)
          collectionLength = collectionLengths[formItem.collectionId];
        if (collectionLength < collections[0].max) {
          const index = formItemsIn.findIndex(formItem => formItem.id == id);
          const newFormItems = formItemsIn;
          newFormItems.splice(index, 0, ...addItemsOnClick());
          setFormItemsIn(newFormItems);
          setCollectionLengths({
            ...collectionLengths,
            [formItem.collectionId]:
              collectionLengths[formItem.collectionId] + 1,
          });
        }
      }
    };

    if (summary) {
      if (data[id] === "Select") return null;
      if (type === "button") return null;
      if (type === "section") {
        return <div style={{ width: "100%", marginTop: "12px" }}>{label}</div>;
      }
      if (type === "title")
        return (
          <CardTitle
            key={id}
            style={{ width: "100%" }}
            EC
            className="propCardTitle"
          >
            {value}
          </CardTitle>
        );
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            marginTop: extraMarginTop ? "10px" : null,
            // margin: extraMargin ? "10px" : null,
            width: type === "checkbox" ? "100%" : smallToggle ? "320px" : null,
            display: type === "checkbox" ? "flex" : null,
            textAlign: alignLeft ? "left" : "center",
            textTransform: type === "pan" ? "uppercase" : "",
            // margin: extraMargin ? "10px" : null,
          }}
        >
          <Label
            md
            style={{ position: "relative", top: "unset", left: "unset" }}
            editMode
          >
            {label}
          </Label>
          <span
            className="data"
            //  style={{ top: "32px", left: "17px", position: "relative" }}
            style={{
              display: "flex",
              flexDirection: "column",
              lineBreak: "anywhere",
            }}
          >
            {type === "checkbox"
              ? data[id]?.map((item, index) => (
                  <span style={{ marginRight: "10px" }}>{item}</span>
                ))
              : type === "pincode"
              ? data[id]?.pincode
              : data[id]}
          </span>
          {/* {type === "pincode" && (
            <span
              className={
                "region-ec " +
                (data[id]?.city?.length > 10 ? " regionEligSmall" : "")
              }
            >
              <img src={location} alt="location" />
              {data[id]?.city}{data[id]?.state}
            </span>
          )} */}
          {type === "dob" && (
            <AgeWrapper className="form-builder-age-wrapper ageWrapForm" md>
              {getAge(data[id])} years
            </AgeWrapper>
          )}
        </FormGroup>
      );
    }

    if (type === "hr")
      return (
        <div
          style={{
            width: "99%",
            height: "1px",
            backgroundColor: "#000",
            margin: "30px auto",
          }}
        ></div>
      );

    if (type === "title")
      return (
        <CardTitle
          key={id || index}
          style={{ width: "100%" }}
          className="propCardTitle"
        >
          {value}
        </CardTitle>
      );
    if (type === "section") {
      return <div style={{ width: "100%", marginTop: "12px" }}>{label}</div>;
    }
    if (types.includes(type) || !type)
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            cursor: readOnly ? "default" : "text",
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0" : "78px",
            marginTop: extraMarginTop ? "10px" : null,
          }}
        >
          {type === "textArea" ? (
            <TextAreaProposal
              md
              text="text"
              largeLabel={largeLabel}
              name={label}
              id={id}
              value={data[id] || ""}
              placeholder=" "
              // maxLength={type === "mobile" ? 10 :  validations?.maxLength}
              minLength={validations?.minLength}
              readOnly={readOnly}
              onChange={handleOnChange}
              onBlur={evt => {
                validate(evt.target.value, formItem, data);
              }}
            />
          ) : (
            <TextInputElg
              style={{
                pointerEvents: readOnly ? "none" : "all",
                textTransform: type === "pan" ? "uppercase" : "",
              }}
              onKeyDown={evt => {
                if (validations?.onlyAlphaSpace) {
                  noNumSpace(evt);
                } else if (validations?.noSpecialCharacters) {
                  alphaNumeric(evt);
                }
                if (validations?.onlyAlphaNoSpace) {
                  onlyAlphaNoSpace(evt);
                }
                if (validations?.disableSpace) {
                  noNum(evt);
                }
              }}
              onKeyPress={onlyOneSpace}
              text="text"
              name={label}
              id={id}
              value={data[id] || ""}
              formBuilerText
              proposal
              placeholder=" "
              maxLength={
                type === "mobile"
                  ? 10
                  : validations?.maxLength
                  ? validations?.maxLength
                  : 25
              }
              onInput={evt => {
                if (validations?.uppercase) {
                  upperCase(evt);
                }
                if (validations?.capitalize) {
                  upperCaseAll(evt);
                }
                if (
                  label.toLowerCase() === "first name" ||
                  label.toLowerCase() === "last name" ||
                  label.toLowerCase().includes("name")
                ) {
                  upperCaseAll(evt);
                }
                if (validations?.numOnly || type === "mobile")
                  numOnlyWithShifDisableWithDelete(evt);
              }}
              // maxLength={type === "mobile" ? 10 :  validations?.maxLength}
              minLength={validations?.minLength}
              readOnly={readOnly}
              onChange={handleOnChange}
              onBlur={evt => {
                validate(evt.target.value, formItem, data);
              }}
            />
          )}

          <Label md htmlFor={label} formBuilerLabel>
            {label}
          </Label>
          {verifyCKYC && (
            <button
              className="form-builder-verify-button"
              disabled={data[id]?.length < 10 || errors[id]}
              onClick={() => {
                dispatch(initCKYCLoader());
                dispatch(
                  getCKYCData({
                    traceId: enquiryId,
                    inputiidno: data[id],
                  }),
                );
              }}
            >
              Check CKYC
            </button>
          )}
          {message ? (
            <div style={{ fontSize: "10px", position: "absolute" }}>
              * {message}
            </div>
          ) : (
            <></>
          )}
          {errors[id] && (
            <p className="errorG formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );

    if (type === "dob")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0" : "78px",
          }}
        >
          <TextInputElg
            style={{ cursor: readOnly ? "default" : "text" }}
            type="text"
            name={label}
            formBuilerText
            proposal
            formPage
            id={id}
            value={value}
            onChange={handleOnChange}
            placeholder=" "
            autoComplete="none"
            readOnly={readOnly}
            maxLength={10}
            onBlur={evt => {
              const day = evt.target.value.slice(0, 2);
              const month = evt.target.value.slice(3, 5);
              const year = evt.target.value.slice(6);
              if (evt.target.value === "")
                setErrors({ ...errors, [id]: validations?.required });
              else if (evt.target.value.length !== 10)
                setErrors({
                  ...errors,
                  [id]: validations?.invalid || "Please enter valid date",
                });
              else if (month > 12 || month < 1 || day < 1 || day > 31)
                setErrors({
                  ...errors,
                  [id]: validations?.invalid || "Please enter valid date",
                });
              else {
                const givenDate = Date.parse(`${year}/${month}/${day}`);
                if (validations?.shouldBeFuture && givenDate < Date.now()) {
                  setErrors({
                    ...errors,
                    [id]: validations?.shouldBeFuture || "Invalid date",
                  });
                } else if (
                  validations?.shouldBePast &&
                  givenDate > Date.now()
                ) {
                  setErrors({
                    ...errors,
                    [id]: validations?.shouldBePast || "Invalid date",
                  });
                }
              }
              const ageValidationCheck = ageVariant => {
                return (
                  ageValidation &&
                  ageValidation[ageVariant] !== null &&
                  showAgeLabel &&
                  data[id] &&
                  data[id].length === 10
                );
              };

              // ageValidationCheck(1) &&
              // !(getAge(data[id]) <= ageValidation[1]) &&
              // setErrors({
              // 	...errors,
              // 	[id]: `age should be less than or equal to ${ageValidation[1]}`,
              // });

              // ageValidationCheck(0)	 &&
              // !(getAge(data[id]) >= ageValidation[0]) &&
              // setErrors({
              // 	...errors,
              // 	[id]: `age should be more than or equal to ${ageValidation[0]}`,
              // });

              ageValidationCheck(0) &&
                !(getAge(data[id]) >= ageValidation[0]) &&
                setErrors({
                  ...errors,
                  [id]: `age should be more than or equal to ${ageValidation[0]}`,
                });

              ageValidationCheck(1) &&
                !(getAge(data[id]) <= ageValidation[1]) &&
                setErrors({
                  ...errors,
                  [id]: `age should be less than or equal to ${ageValidation[1]}`,
                });
            }}
          />
          <Label md htmlFor={label} formBuilerLabel>
            {label}
          </Label>

          {showAgeLabel && data[id] && data[id].length === 10 && (
            <AgeWrapper className="form-builder-age-wrapper ageWrapForm" md>
              {getAge(data[id])} years
            </AgeWrapper>
          )}
          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );
    if (type === "dob2")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
          }}
        >
          <TextInputElg
            dobSpace
            style={{ cursor: readOnly ? "default" : "text" }}
            type="text"
            name={label}
            formBuilerText
            proposal
            id={id}
            value={value}
            onChange={handleOnChange}
            placeholder=" "
            autoComplete="none"
            readOnly={readOnly}
            maxLength={9}
            onBlur={evt => {
              const month = evt.target.value.slice(0, 2);
              const year = evt.target.value.slice(3);
              if (evt.target.value === "")
                setErrors({ ...errors, [id]: validations?.required });
              else if (evt.target.value.length !== 9)
                setErrors({
                  ...errors,
                  [id]:
                    validations?.invalid || "Please enter valid date (mm/yyyy)",
                });
              else if (month > 12 || month < 1)
                setErrors({
                  ...errors,
                  [id]:
                    validations?.invalid || "Please enter valid date (mm/yyyy)",
                });
              else {
                const givenDate = Date.parse(`${year}/${month}`);
                if (validations?.shouldBeFuture && givenDate < Date.now()) {
                  setErrors({
                    ...errors,
                    [id]: validations?.shouldBeFuture || "Invalid date",
                  });
                } else if (
                  validations?.shouldBePast &&
                  givenDate > Date.now()
                ) {
                  setErrors({
                    ...errors,
                    [id]: validations?.shouldBePast || "Invalid date",
                  });
                }
              }
            }}
          />
          <Label md htmlFor={label} formBuilerLabel>
            {label}
          </Label>

          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );
    if (type === "normalDate")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
          }}
        >
          <TextInputElg
            style={{ cursor: readOnly ? "default" : "text" }}
            type="text"
            name={label}
            formBuilerText
            proposal
            id={id}
            value={value}
            onChange={handleOnChange}
            placeholder=" "
            autoComplete="none"
            readOnly={readOnly}
            maxLength={10}
            onBlur={evt => {
              const givenDate = moment(evt.target.value, "DD/MM/YYYY");
              if (evt.target.value === "")
                setErrors({ ...errors, [id]: validations?.required });
              else if (!givenDate.isValid())
                setErrors({ ...errors, [id]: "Please provide valid date" });
              else validate(evt.target.value, formItem, data);
            }}
          />
          <Label md htmlFor={label} formBuilerLabel>
            {label}
          </Label>

          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );

    if (type === "pincode")
      return (
        <FormGroup
          proposalCard
          eligibilityPage
          style={{ cursor: readOnly ? "default" : "pointer" }}
        >
          <TextInputElg
            style={{ pointerEvents: readOnly ? "none" : "all" }}
            formBuilerText
            proposal
            type="text"
            name={label}
            maxLength={6}
            readOnly={readOnly}
            id={id}
            value={data[id]?.pincode || ""}
            placeholder=" "
            autoComplete="none"
            onChange={handleOnChange}
            onInput={numOnlyWithShifDisable}
            onBlur={evt => {
              validate(evt.target.value, formItem, data);
            }}
          />
          <Label md htmlFor={label} formBuilerLabel>
            {label}
          </Label>
          {/* {data[id]?.state && (
            <span
              className={
                "region-ec regionFormBuilder" +
                (data[id]?.city?.length > 10 ? " regionEligSmall" : "")
              }
            >
              <img
                src={location}
                alt="location"
                className="pincodeImgFormBuilder"
              />
              {data[id]?.city}{data[id]?.state}
            </span>
          )} */}
          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );

    if (type === "select")
      return (
        <FormGroup
          key={crypto.randomUUID()}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
            marginTop: extraMarginTop ? "10px" : null,
          }}
        >
          <div style={{ cursor: readOnly ? "default" : "pointer" }}>
            <div className="filterMenuSelectWrap selectIncome">
              <CustomDropDownFont rdr>
                <CustomDropDown
                  dropDownTitle={label}
                  propDropDown
                  formBuilderDropdown="true"
                  items={items || []}
                  searchStartsWith={true}
                  selectedItem={data[id] || value}
                  readOnly={readOnly}
                  currentSelected={data[id] || value}
                  onChange={handleOnChange}
                  disabled={readOnly}
                  searchable={formItem.searchable}
                />
              </CustomDropDownFont>
            </div>
          </div>

          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}

          {message ? (
            <div style={{ fontSize: "10px", position: "absolute" }}>
              * {message}
            </div>
          ) : (
            <></>
          )}
        </FormGroup>
      );
    if (type === "toggle")
      return (
        <FormGroup
          key={id}
          proposalCardL
          eligibilityPage
          className={smallToggle ? "radioComponent " : "radioComponentLarge "}
          style={{
            textAlign: alignLeft ? "left" : "center",
            // margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
            display: alignCenter ? "flex" : null,
            alignItems: alignCenter ? "center" : null,
            marginTop: extraMarginTop ? "10px" : null,
            marginBottom: extraMarginBottom ? "10px" : null,
            marginLeft: orderedLabel && "19px",
          }}
        >
          <div className="questionRadio formBuilderRadioQues">{label}</div>
          <RadioComponent
            value={readOnly ? value === "Yes" : data[id] === "Yes"}
            name={id}
            updateChecked={e => {
              handleOnChange(e ? "Yes" : "No");
              validate(e ? "Yes" : "No", formItem, data);
            }}
            disableRadio={readOnly}
          />
          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );
    if (type === "radio")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
          }}
        >
          {/* <div>{label}</div> */}
          <RadioContainer
            md
            proposer
            mob
            style={{
              cursor: readOnly ? "default" : "pointer",
            }}
          >
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <Radio
                  type="radio"
                  id={item}
                  name={id}
                  value={item}
                  checked={data[id] === item || value === item}
                  onClick={evt => handleOnChange(evt.target.value)}
                />
                <RadioLabel
                  sm
                  proposer
                  htmlFor={item}
                  error={errors[id] && "danger"}
                  style={{
                    textAlign: "unset",
                    pointerEvents: readOnly ? "none" : "all",
                    marginBottom: extraRadioMargin ? "10px" : null,
                  }}
                  className="formBuilderRadio"
                >
                  <div className="radiolabel__container radiolabel__formbuilder-container noselect ">
                    {" "}
                    <RadioButton
                      className="radioBtnHover"
                      marginsm
                    ></RadioButton>
                    <span className="radiolabel__text radiolabel__formbuilder-text">
                      {item}
                    </span>
                  </div>
                </RadioLabel>
              </React.Fragment>
            ))}

            {errors[id] && (
              <p className="errorP formbuilder__error">{errors[id]}</p>
            )}
          </RadioContainer>
        </FormGroup>
      );

    if (type === "checkbox")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          className="checkBoxFormBuilder"
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : null,
            display: displayFlex ? "flex" : null,
            flexDirection: flexCol ? "column" : null,
            width: widthFull ? "100%" : null,
            whiteSpace: "nowrap",
            marginLeft: leftCheck ? "30px" : "null",
          }}
        >
          <div>{label}</div>
          {items.map((item, index) => (
            <span
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "flexStart",
              }}
              className="checkBoxWrap"
              key={index}
            >
              <input
                type="checkbox"
                className="checkBoxFormBuilder proposalCheckBox"
                name={id}
                id={item}
                value={item}
                defaultChecked={value.includes(item)}
                onClick={evt => {
                  handleOnChange(item);
                }}
              />
              <label className="label checkBoxLabel" htmlFor={item}>
                {item}
              </label>
            </span>
          ))}
          {errors[id] && (
            <p className="errorP formbuilder__error">{errors[id]}</p>
          )}
        </FormGroup>
      );

    if (type === "label")
      return (
        <FormGroup
          key={id}
          proposalCard={size === "sm"}
          proposalCard={size === "md"}
          proposalCardL={size === "lg"}
          proposalCardL={size === "xlg"}
          eligibilityPage
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
            marginTop: extraMarginTop ? "10px" : null,
            marginLeft: labelBefore ? "21px" : orderedLabel ? "20px" : null,

            width: labelBefore ? "91%" : null,
          }}
        >
          <Label
            style={{ fontFamily: "Inter-Regular" }}
            className="mr-4"
            labelBefore={labelBefore ? labelBefore : undefined}
          >
            {value}
          </Label>
        </FormGroup>
      );

    if (type === "button")
      return (
        <FormGroup
          key={id}
          sm={size === "sm"}
          md={size === "md"}
          lg={size === "lg"}
          xlg={size === "xlg"}
          className={customClassName}
          elig
          style={{
            textAlign: alignLeft ? "left" : "center",
            margin: extraMargin ? "10px" : null,
            minHeight: reduceMinHeight ? "0px" : "78px",
            marginTop: extraMarginTop ? "10px" : null,
          }}
        >
          <Button className="formbuilder__btn" onClick={handleButtonClick}>
            {value}
          </Button>
        </FormGroup>
      );

    if (type === "null") return <div style={{ display: "none" }}></div>;
  };

  const { position } = useSelector(state => state.iciciProposal);

  const dispatch = useDispatch();

  const EditButton = (
    <span
      style={{
        float: "right",
        fontSize: "12px",
        color: "#107591",
        cursor: "pointer",
        fontWeight: "300",
      }}
      className="editBtnFormBuilder"
      onClick={() => {
        setSummary(false);
        setExpandForm(index);
        dispatch(setPosition(index));
      }}
    >
      <img src={editIcon} alt="editIcon" />
      &nbsp;EDIT
    </span>
  );

  const cardTitle = (
    <CardTitle className="propCardTitle">
      {title}
      {summary && expand > index && EditButton}
    </CardTitle>
  );

  useEffect(() => {
    if (!formSubmitted || index > expand) {
      if (Object.keys(data).length !== 0) {
        setFormItemsIn(
          preprocessFormItems().map(formItem => {
            if (formItem.type === "select") {
              if (!formItem?.items?.includes(data[formItem.id]))
                return formItem;
            }
            if (formItem.readOnly) return formItem;
            // if (formItem.type !== "pincode")
            //   return { ...formItem, value: data[formItem.id] };
            if (formItem.type !== "pincode") return formItem;
            return { ...formItem, value: data[formItem.id].pincode };
          }),
        );
      } else setFormItemsIn(preprocessFormItems());
      setErrors({});
    }
  }, [formItems]);

  if (index > expand)
    return <Card className="closeCardProposal">{cardTitle}</Card>;

  return (
    <div className="proposal-form">
      <Card proposal>
        <div className="propCardTitle">{cardTitle}</div>
        <div
          key={index}
          className="form-items"
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {formItemsIn?.map(renderFormItem, index)}
        </div>
        {!summary && (
          <FormGroup xlg formPage style={{ marginTop: "10px" }} propSubWrap>
            <button
              type="submit"
              className="mobilebtn btn btn--highlighted btn--lg uppercase bold buttton submitProposal propSubmit"
              onClick={handleClick}
              //  style={{padding: "0px"}}
            >
              {submitButton}
            </button>
          </FormGroup>
        )}
      </Card>
    </div>
  );
}

export default FormBuilder;
