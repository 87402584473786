import React from "react";

import "./card-highlight.scss";

function CardHighlight({ text }) {
  return (
    <div className="card-highlight">
      <svg
        className="card-highlight__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 30"
      >
        <path
          fill="#107591"
          d="M24 0H6a.472.472 0 0 0-.5.5v29a.55.55 0 0 0 .3.5 1.268 1.268 0 0 0 .6-.1l8.6-8.6 8.6 8.6c.1.1.2.1.4.1h.2a.55.55 0 0 0 .3-.5V.5A.472.472 0 0 0 24 0zm-2.8 9l-3.4 2.5 1.3 4c.1.2 0 .4-.2.6a.5.5 0 0 1-.6 0L15 13.5 11.6 16a.637.637 0 0 1-.6 0c-.2-.1-.2-.4-.2-.6l1.3-4L8.8 9c-.2-.2-.3-.4-.2-.6a.55.55 0 0 1 .5-.3h4.2l1.3-4c.1-.4.8-.4 1 0l1.3 4h4.2a.55.55 0 0 1 .5.3 2.092 2.092 0 0 1-.4.6z"
          data-name="Bookmark 3"
          transform="translate(-5.5)"
        ></path>
      </svg>
      <span className="card-highlight__text">{text}</span>
    </div>
  );
}

export default CardHighlight;
