import React from "react";
import "./cardTitle.scss";

const CardTitle = ({ children, EC, style, className, eligibilityTitle }) => {
  return (
    <div
      className={
        EC
          ? "card-title--card-ec"
          : eligibilityTitle
          ? "card-titleElg card-title"
          : "card-title"
      }
      style={style && style && { ...style, ...{ marginTop: "20px" } }}
    >
      {eligibilityTitle ? (
        <div className={className}>{children}</div>
      ) : (
        <strong>{children}</strong>
      )}
    </div>
  );
};

export default CardTitle;
