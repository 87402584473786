import styled from "styled-components";

export const Heading = styled.h1`
  & .edit-icon {
    margin: 0 4px 0 0;
  }

  font-size: 20px;

  padding: 20px 0;
  margin-bottom: 3px;
  font-family: Inter-SemiBold;
  color: #333333;
  @media (max-width: 767px) {
    & .edit-icon {
      margin: 0 4px 0 0;
      position: relative;
      top: -1px;
      height: 17px;
      width: 17px;
    }

    font-size: 16px;
    line-height: 24px;
    color: #333;

    padding: ${props =>
      props.modifyDetails ? "26px 0 7px 14px" : "padding: 20px 0 0 0"};
  }
`;
export const Wrapper = styled.div`
  padding: 30px 42px;
`;
export const InnerWrapper = styled.div`
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const WrapCenter = styled.div`
  text-align: center;
`;
export const Container = styled.div`
  width: 47%;
  & p {
    margin: 0;
    color: #287692;
    font-size: 12px;
    font-family: "Inter-Medium";
  }
  margin-bottom: 48px;
  @media (max-width: 767px) {
    width: 98%;
    margin-bottom: 12px;
    padding: 0px;
  }
`;
