import React, { Fragment, useEffect, useRef, useState } from "react";
import Popup from "./Popup";

import {
  CoverAmountPercentWrap,
  FormWrapper,
  HeadingWrapper,
  InfoWrap,
  PercentDropWrap,
  Question,
  QuestionWrapper,
  SubTitle,
  Title,
  Wrapper,
  TitleMob,
} from "./LumpSum.style";

import CustomDropDown from "../CutomDropDown/CustomDropDown";
import { Button } from "../StyledButton/Button";
// import { useOutsideClick } from "../../custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoverAmountLumpsumMonthly,
  setNomineeGets,
  setPopup,
} from "../../modules/QuoteFilter/quoteFilterSlice";
import {
  amountInNumbers,
  // getMaxLumpsumCover,
  getMultiplier,
  getMultiplierMonthly,
  // numberToDigitWord,
  // numberToWords,
} from "../../modules/QuoteFilter/helper";
import {
  getAmount,
  getNumberfromString,
  getValue,
  numOnly,
  numOnlyWithShifDisable,
} from "../../utils/inputUtils";
import swal from "sweetalert";

import "./lumpsum.scss";

import "styled-components/macro";

const LumpSum = ({ show, onClose, shouldClose = "shouldClose" }) => {
  const dispatch = useDispatch();
  const { customerDetails } = useSelector(state => state.greeting);
  const [multiplier, setMultiplier] = useState(
    getMultiplier(customerDetails?.dob, customerDetails?.annualIncome),
  );
  const [specificMultiplier, setSpecificMultiplier] = useState(
    getMultiplierMonthly(customerDetails?.dob, customerDetails?.annualIncome),
  );
  const [max, setMax] = useState(
    Number(customerDetails?.annualIncome) * multiplier,
  );
  useEffect(() => {
    if (customerDetails.annualIncome) {
      setMultiplier(
        getMultiplier(customerDetails.dob, customerDetails.annualIncome),
      );
      setSpecificMultiplier(
        getMultiplierMonthly(customerDetails.dob, customerDetails.annualIncome),
      );
    }
  }, [customerDetails.annualIncome, customerDetails.dob]);
  useEffect(() => {
    if (customerDetails.annualIncome)
      setMax(Number(customerDetails.annualIncome) * multiplier);
  }, [multiplier, customerDetails]);
  const { coverAmountLumpsumMonthly } = useSelector(
    ({ quoteFilter }) => quoteFilter.filter,
  );

  const [coverAmount, setCoverAmount] = useState(
    coverAmountLumpsumMonthly.coverAmount,
  );
  useEffect(() => {
    setCoverAmount(coverAmountLumpsumMonthly.coverAmount);
  }, [coverAmountLumpsumMonthly.coverAmount]);

  const [coverAmountPercent, setCoverAmountPercent] = useState(
    coverAmountLumpsumMonthly.coverAmountPercent,
  );

  const [amount, setAmount] = useState(
    coverAmountLumpsumMonthly.additionalIncome.amount,
  );

  const [period, setPeriod] = useState(
    coverAmountLumpsumMonthly.additionalIncome.period,
  );

  const [increaseIncome, setIncreaseIncome] = useState(
    coverAmountLumpsumMonthly.increaseIncome,
  );
  const [lumpSum, setLumpSum] = useState();
  const [increaseIncomeValidate, setIncreaseIncomeValidate] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPercentDrop, setShowPercentDrop] = useState(false);

  const [disableIncreseIcome, setDisableIncreseIcome] = useState(true);

  const [additionalMonthlyIncomeText, setAdditionalMonthlyIncomeText] =
    useState("");

  const [additionalMonthlyIncome, setAdditionalMonthlyIncome] =
    useState("Please select");

  const [additionalMonthlyIncomeValidate, setAdditionalMonthlyIncomeValidate] =
    useState(false);

  const [showTotalAmount, setShowTotalAmount] = useState(true);

  const [monthlyIncomeOptions, setMonthlyIncomeOptions] = useState([
    `₹ ${Math.floor(
      (((100 - Number(coverAmountPercent.slice(0, 2))) / 100) *
        amountInNumbers(coverAmount)) /
        120,
    )}/month (10 years)`,
  ]);

  const inputRef = useRef(null);

  // useOutsideClick(inputRef, () => setShowPercentDrop(false));

  const handleOptionClick = value => {
    // window.alert("work");
    setCoverAmountPercent(value);
    // setShowPercentDrop(false);
  };
  // useEffect(() => {
  //   if (coverAmountPercent === "100%" && increaseIncome === "Yes") {
  //     let temp = (0.4 * getNumberfromString(coverAmount)) / 100;
  //     if (
  //       temp * 136.99 >=
  //       specificMultiplier - getNumberfromString(coverAmount)
  //     ) {
  //       swal(
  //         `To avail 100% Lumpsum plus additional monthly income your lumpsum cover amount is reduced by ${numberToDigitWord(
  //           600000 - 0
  //         )} as per your eligibility.`
  //       );
  //     }
  //   }
  // }, [coverAmountPercent, coverAmount, increaseIncome]);
  const handleSubmit = evt => {
    evt.preventDefault();

    if (additionalMonthlyIncome === "Please select") {
      setAdditionalMonthlyIncomeValidate(true);
    } else if (increaseIncome === "Please select")
      setIncreaseIncomeValidate(true);
    else {
      setIncreaseIncomeValidate(false);
      dispatch(setNomineeGets({ value: "Lumpsum + Monthly Income" }));
      dispatch(setPopup(""));
      dispatch(
        setCoverAmountLumpsumMonthly({
          coverAmount,
          coverAmountPercent,
          increaseIncome,
          additionalIncome: { amount, period },
        }),
      );
    }
  };
  useEffect(() => {
    if (Number(getNumberfromString(coverAmount)) > max) {
      setCoverAmount(getValue(max));
      swal(
        `Your maximum cover amount limit has been reduced by\n ${getValue(
          getNumberfromString(coverAmount) - max,
        )}`,
        {
          closeOnClickOutside: false,
        },
      );
    }
  }, [max, coverAmount]);

  useEffect(() => {
    if (coverAmountPercent !== "10%") {
      setDisableIncreseIcome(true);
      setIncreaseIncome("No");
    } else {
      setDisableIncreseIcome(false);
      setIncreaseIncome("Please select");
    }
  }, [coverAmountPercent]);

  useEffect(() => {
    // let value = Math.floor(
    //   (max - Number(getNumberfromString(coverAmount))) /
    //     (Number(getNumberfromString(period)) * 12),
    // );

    if (additionalMonthlyIncome === "Custom value") {
      if (Number(additionalMonthlyIncomeText) < 50000) {
        setErrorMsg(`Minimum required amount is 50 thousand`);
      } else setErrorMsg("");
    } else setErrorMsg("");
  }, [additionalMonthlyIncomeText, period, coverAmount, max, increaseIncome]);

  const termArr = [
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "6 years",
    "7 years",
    "8 years",
    "9 years",
    "10 years",
    "11 years",
    "12 years",
    "13 years",
    "14 years",
    "15 years",
    "16 years",
    "17 years",
    "18 years",
    "19 years",
    "20 years",
  ];
  useEffect(() => {
    setAdditionalMonthlyIncome("Please select");
  }, [coverAmount, coverAmountPercent]);
  useEffect(() => {
    const monthlyList = [];
    if (coverAmountPercent !== "%" && coverAmountPercent) {
      const x = Number(coverAmountPercent.match(/\d+/)[0]);
      //ICICI-Done
      if (x >= 5 && x <= 95 && x % 5 === 0) {
        let temp = (getNumberfromString(coverAmount) * (100 - x)) / 100 / 120;
        if (increaseIncome !== "Yes")
          monthlyList.push(`₹ ${Math.round(temp)}/month (10 years)`);
      }
      //HDFC C2PP Income Option -Done
      // if (x === 10) {
      // 	let temp = (getNumberfromString(coverAmount) * (100 - x)) / 100 / 180;
      // 	monthlyList.push(
      // 		`₹ ${
      // 			Number.isInteger(temp) ? temp : temp.toFixed(2)
      // 		}/month (15 years)`
      // 	);
      // }
      // Kotak  Both -Done
      if (x === 10) {
        let temp = 0.06 * getNumberfromString(coverAmount);
        temp = (temp * 8.22) / 100;

        monthlyList.push(
          `₹ ${
            Number.isInteger(temp) ? temp : temp.toFixed(2)
          }/month (15 years)`,
        );
      }
      //Max Life
      // if (x === 100) {
      // 	let temp = (0.4 * getNumberfromString(coverAmount)) / 100;
      // 	setMax(getMaxLumpsumCover(specificMultiplier.max_life, increaseIncome));
      // 	if (
      // 		temp * (increaseIncome === "Yes" ? 136.99 : 96.9) <=
      // 		specificMultiplier.max_life - getNumberfromString(coverAmount)
      // 	)
      // 		monthlyList.push(
      // 			`₹ ${
      // 				Number.isInteger(temp) ? temp : temp.toFixed(2)
      // 			}/month (10 years)`
      // 		);
      // }
      //PNB Life
      if (
        x >= 10 &&
        x <= 90 &&
        x % 10 === 0 &&
        getNumberfromString(coverAmount) <= 15000000
      ) {
        let temp = (getNumberfromString(coverAmount) * (100 - x)) / 100;
        temp = (temp * 1.1022) / 100;
        if (increaseIncome !== "Yes")
          monthlyList.push(`₹ ${Math.round(temp)}/month (10 years)`);
        else setAdditionalMonthlyIncome("Please select");
      }

      //Bandhan
      // if (x && x < 100) {
      //   let temp = (getNumberfromString(coverAmount) * (100 - x)) / 100;
      //   temp = (temp * 1.2) / 100;
      //   if (increaseIncome !== "Yes")
      //     monthlyList.push(`₹ ${Math.round(temp)}/month (100 months)`);
      //   else setAdditionalMonthlyIncome("Please select");
      // }
      //HDFC C2PP Income Plus Option -Done
      // if (x === 100) {
      // 	let temp = (0.5 * getNumberfromString(coverAmount)) / 100;

      // 	if (temp * 120 <= max - getNumberfromString(coverAmount))
      // 		monthlyList.push(
      // 			`₹ ${Number.isInteger(temp) ? temp : temp.toFixed(2)
      // 			}/month (10 years)`
      // 		);
      // 	monthlyList.push("Custom value");
      // }
    }
    setMonthlyIncomeOptions(monthlyList);
    // if (coverAmountPercent === "10%")
    //   setMonthlyIncomeOptions([
    //     `₹ ${Math.floor(
    //       (((100 - Number(coverAmountPercent.slice(0, -1))) / 100) *
    //         amountInNumbers(coverAmount)) /
    //         120
    //     )}/month (10 years)`,
    //     `₹ ${Math.floor(
    //       (((100 - 10) / 100) * amountInNumbers(coverAmount)) / (15 * 12)
    //     )}/month (15 years)`,
    //   ]);
    // else if (coverAmountPercent === "100%")
    //   setMonthlyIncomeOptions([
    //     `₹ ${Math.floor(
    //       (amountInNumbers(coverAmount) * 0.5) / (10 * 12)
    //     )}/month (10 years)`,
    //     "Custom value",
    //   ]);
    // else if (
    //   coverAmountPercent.slice(0, -1) >= 5 &&
    //   coverAmountPercent.slice(0, -1) <= 95
    // )
    //   setMonthlyIncomeOptions([
    //     `₹ ${Math.floor(
    //       (((100 - Number(coverAmountPercent.slice(0, -1))) / 100) *
    //         amountInNumbers(coverAmount)) /
    //         120
    //     )}/month (10 years)`,
    //   ]);
    // else setMonthlyIncomeOptions([]);
  }, [coverAmountPercent, coverAmount, increaseIncome]);
  const percentDrop = (
    <PercentDropWrap ref={inputRef}>
      <div className="percent-drop-title">Most entered values:</div>
      <div className="percent-drop-option-wrap">
        <div
          className="percent-drop-option"
          onClick={() => handleOptionClick("25%")}
        >
          25%
        </div>
        <div
          className="percent-drop-option"
          onClick={() => handleOptionClick("50%")}
        >
          {" "}
          50%
        </div>
        <div
          className="percent-drop-option"
          onClick={() => handleOptionClick("75%")}
        >
          {" "}
          75%
        </div>
        {/* <div
          className="percent-drop-option"
          onClick={() => handleOptionClick("100%")}
        >
          {" "}
          100%
        </div> */}
      </div>
    </PercentDropWrap>
  );

  const content = (
    <Fragment>
      <Wrapper
        modifyDetails
        css={`
          position: relative;
          min-height: 100%;
        `}
      >
        <HeadingWrapper modifyDetails>
          <Title>
            <div> Nominee Gets</div>
            <div>
              (Lumpsum + Monthly <span className="monthlyIncomeH">Income</span>{" "}
              option)
            </div>
          </Title>
          <TitleMob>
            <div> Nominee Gets (Lumpsum + Monthly option)</div>
          </TitleMob>

          <SubTitle>
            You can decide your nominee to get full lumpsum or part of it along
            with monthly income
          </SubTitle>
        </HeadingWrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <QuestionWrapper>
            <Question>Please choose the cover amount</Question>
            <div className="mobDropQuotes">
              <CustomDropDown
                mobDrop="true"
                qtsMob="true"
                items={getAmount(
                  2500000,
                  max,
                  max,
                  500000,
                  "coverQuotes",
                ).reverse()}
                selectedItem={coverAmount}
                onChange={value => {
                  setCoverAmount(value);
                }}
                currentSelected={coverAmount}
                searchable
                // customInput
                // min={2500000}
                // max={max}
                // multiple={500000}
                // roundTo={2}
              />
            </div>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>
              What percentage of the cover amount would you like your nominee to
              receive at one go?
            </Question>
            <CoverAmountPercentWrap>
              <input
                className="cover-amt-percent"
                type="text"
                maxLength="3"
                value={coverAmountPercent}
                onInput={numOnly}
                onChange={evt => {
                  if (parseInt(evt.target.value) >= 100)
                    setCoverAmountPercent("95");
                  else setCoverAmountPercent(evt.target.value);
                }}
                onBlur={evt => {
                  setTimeout(() => {
                    setShowPercentDrop(false);
                  }, 500);
                  if (coverAmountPercent === "") setCoverAmountPercent("0%");
                  if (parseInt(evt.target.value) % 5) {
                    setCoverAmountPercent(
                      Math.min(Math.floor(evt.target.value / 5) * 5, 95) + "%",
                    );
                    return;
                  } else if (!coverAmountPercent.includes("%"))
                    setCoverAmountPercent(evt.target.value + "%");
                  if (Number(coverAmountPercent.match(/\d+/)[0]) >= 100)
                    setCoverAmountPercent("95%");
                  if (Number(coverAmountPercent.match(/\d+/)[0])) {
                    let x = Number(evt.target.value.match(/\d+/)[0]);
                    setCoverAmountPercent(x + "%");
                  }
                }}
                onFocus={() => setShowPercentDrop(true)}
              />
              {showPercentDrop && percentDrop}
            </CoverAmountPercentWrap>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question full>
              Would you like to increase the monthly income every year?
              <span className="SmallTextQuestionMob">
                (This ensures that your nominee is able to fight the battle with
                inflation)
              </span>
              <div className="SmallTextQuestion">
                (This ensures that your nominee is able to fight the battle with
                inflation)
              </div>
            </Question>

            <div className="mobDropQuotes">
              <CustomDropDown
                mobDrop="true"
                qtsMob="true"
                selectedItem={increaseIncome}
                currentSelected={increaseIncome}
                items={["Yes", "No"]}
                onChange={value => {
                  setIncreaseIncome(value);
                  setIncreaseIncomeValidate(false);
                }}
                validate={increaseIncomeValidate}
                validateMessage="Please select yes or no."
                disabled={disableIncreseIcome}
                noArrow={disableIncreseIcome ? "true" : "false"}
              />
            </div>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>
              Choose the {coverAmountPercent === "100%" && "additional"} monthly
              income that you want your nominee to receive
            </Question>
            <div className="mobDropQuotes">
              <CustomDropDown
                mobDrop="true"
                qtsMob="true"
                items={monthlyIncomeOptions}
                selectedItem={additionalMonthlyIncome}
                currentSelected={additionalMonthlyIncome}
                onChange={value => {
                  setAdditionalMonthlyIncomeValidate(false);
                  setAdditionalMonthlyIncome(value);
                  if (value !== "Custom value") {
                    setPeriod(value.split("(")[1].replace(")", ""));
                    setAmount(value.slice(2, value.indexOf("/")));
                    setLumpSum(
                      (Number(getNumberfromString(coverAmount)) *
                        parseInt(coverAmountPercent)) /
                        100,
                    );
                  } else {
                    setPeriod("1 years");
                  }
                  setShowTotalAmount(value !== "Custom value");
                }}
                validate={additionalMonthlyIncomeValidate}
                validateMessage="Please select"
              />
            </div>
          </QuestionWrapper>

          {additionalMonthlyIncome === "Custom value" && (
            <InfoWrap className="info-wrap-custom-value">
              <QuestionWrapper className="info-wrap-question-wrapper">
                <p className="additional-income-text">
                  Please select the monthly amount & period to receive as
                  additional income
                </p>
                <div
                  className="info-wrap-options-wrap"
                  style={{
                    display: "flex",
                    width: "70%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <input
                      className="custom-monthly-income-text"
                      placeholder="Enter amount"
                      type="text"
                      value={additionalMonthlyIncomeText}
                      onChange={evt =>
                        setAdditionalMonthlyIncomeText(evt.target.value)
                      }
                      onBlur={() => {
                        setShowTotalAmount(additionalMonthlyIncomeText !== "");
                        setAmount(additionalMonthlyIncomeText);
                      }}
                      onInput={numOnlyWithShifDisable}
                    />
                    {errorMsg && (
                      <p
                        className="errorG"
                        style={{ textTransform: "initial", fontSize: "10px" }}
                      >
                        {errorMsg}
                      </p>
                    )}
                  </div>
                  <CustomDropDown
                    mobDrop="true"
                    items={termArr.filter(
                      item =>
                        Number(additionalMonthlyIncomeText) *
                          Number(getNumberfromString(item)) *
                          12 +
                          Number(getNumberfromString(coverAmount)) <=
                        max,
                    )}
                    onChange={value => setPeriod(value)}
                  />
                </div>
              </QuestionWrapper>
            </InfoWrap>
          )}
          {showTotalAmount && additionalMonthlyIncome !== "Please select" && (
            <InfoWrap
              marginTop
              style={{
                marginTop: `${
                  additionalMonthlyIncome === "Custom value" ? "0" : "32px"
                }`,
              }}
            >
              <p className="total-amt">TOTAL PAYABLE AMOUNT</p>
              <strong className="total-amt-value">
                {additionalMonthlyIncome !== "Custom value"
                  ? coverAmountPercent === "100%"
                    ? coverAmount
                    : Math.round(lumpSum).toLocaleString("en-IN")
                  : coverAmount}{" "}
                (LUMPSUM) + ₹{" "}
                {additionalMonthlyIncome === "Custom value"
                  ? `${amount}`
                  : `${amount}`}
                /MONTH FOR {period}{" "}
                {additionalMonthlyIncome === "Custom value"
                  ? "(ADDITIONAL)"
                  : "(MONTHLY)"}
              </strong>
            </InfoWrap>
          )}

          <div
            className="detailsBottomBtnLumpsum u-tcenter"
            css={`
              position: static;
              margin-top: 30px;
            `}
          >
            <Button
              disabled={errorMsg ? true : false}
              type="submit"
              secondary
              lg
              padding
              className=" btnLump"
            >
              Confirm
            </Button>
          </div>
        </FormWrapper>
      </Wrapper>
    </Fragment>
  );
  let width;
  let height;
  if (window.matchMedia("(max-width: 767px)").matches) {
    width = "100%";
    height = "100%";
  } else if (window.matchMedia("(max-width: 1177px)").matches) {
    width = "674px";
    height = "auto";
  } else {
    width = "752px";
    height = "auto";
  }
  return (
    <Fragment>
      <Popup
        float="true"
        show={show}
        onClose={onClose}
        content={content}
        height={height}
        width={width}
        size="true"
        shouldClose={shouldClose}
        paddingLeft="111px"
        paddingMob="4vw"
        closeBtnClass="lumbCloseBtn"
        overflowAuto
      ></Popup>
    </Fragment>
  );
};

export default LumpSum;
